/**
 * New widget to change delivery status
 */
import React, { useState, useCallback, useRef } from 'react'
import { Select, Skeleton } from 'antd'
import { createPluggableWidget } from 'factory/createPluggableWidget'
import { useApiService } from 'hooks/useApiService'
import { useTranslation } from 'hooks/useTranslation'
import { getChangeDeliveryStatusReasons } from 'services/fulfillmentApi/getChangeDeliveryStatusReasons'
import { putUpdateDeliveryStatus } from 'services/fulfillmentApi/putUpdateDeliveryStatus'
import { PluggableWidget } from 'types/herocare'
import { Button, Flex, Notification, Text } from 'shared'

type ChangeDeliveryStatusProps = {}

export const ChangeDeliveryStatus: PluggableWidget<ChangeDeliveryStatusProps> =
  createPluggableWidget(
    ({ globalEntityId: entityId, delivery, sdk, onQuit, orderId }) => {
      const { t } = useTranslation()

      const [selectedReason, setSelectedReason] = useState('')

      // This to work around twice firing of dropdown 'open' event when dropdown is opened using just using keyboard. See below
      const isUpdateReasonReported = useRef(false)

      const errorNotification = useCallback(() => {
        Notification.error({ message: t('widgets.change_delivery_status.messages.error') })
      }, [t])

      // Get change delivery status reasons list
      const { data: changeDeliveryStatusReasonsList, loading: changeDeliveryStatusReasonsLoading } =
        useApiService({
          service: getChangeDeliveryStatusReasons,
          params: {
            entityId,
          },
          shouldLoad: Boolean(entityId),
          onError: () => {
            errorNotification()
            onQuit()
          },
        })

      // change delivery status service
      const { loadService: executePutDeliveryStatus, loading: isUpdatingDeliveryStatus } =
        useApiService({
          service: putUpdateDeliveryStatus,
          deps: [],
          autoLoad: false,
        })

      const handleSaveClick = async () => {
        sdk.captureUserAction('CONFIRM_UPDATE_STATUS', { reportToEts: true })

        // success message copy according to current state
        let successMessage
        switch (delivery.state) {
          case 'accepted':
          case 'near_pickup':
            successMessage = t('widgets.change_delivery_status.messages.success.courier_picked_up')
            break
          case 'picked_up':
          case 'left_pickup':
          case 'near_dropoff':
            successMessage = t('widgets.change_delivery_status.messages.success.order_delivered')
            break
        }

        // status the delivery will be changed to
        let payloadToStatus: 'picked_up' | 'completed'
        switch (delivery.state) {
          case 'accepted':
          case 'near_pickup':
            payloadToStatus = 'picked_up'
            break
          case 'picked_up':
          case 'left_pickup':
          case 'near_dropoff':
            payloadToStatus = 'completed'
            break
        }

        try {
          const res = await executePutDeliveryStatus({
            entityId,
            deliveryId: delivery.id,
            orderId,
            payload: {
              from_status: delivery.state,
              to_status: payloadToStatus,
              reason: selectedReason,
            },
          })
          sdk.captureUserAction('CONFIRM_UPDATE_STATUS_SUCCESS', {
            reportToEts: true,
            eventDetails: {
              status_updated_to: payloadToStatus,
              update_reason: selectedReason,
            },
          })
          sdk.eventEmitter.dispatchEvent({
            name: 'CHANGE_DELIVERY_STATUS_SUCCESS',
            payload: { orderId: res.data.order_id },
          })
          Notification.success({ message: successMessage })
          onQuit(true)
        } catch (error) {
          errorNotification()
          onQuit()
        }
      }

      // initial loading state
      if (changeDeliveryStatusReasonsLoading) {
        return <Skeleton active />
      }

      return (
        <div>
          <Flex flexDirection='column' gap='8px'>
            {/* Title */}
            <Text.Primary>{t('widgets.change_delivery_status.subtitle')}</Text.Primary>

            {/* Select dropdown */}
            <Select
              onDropdownVisibleChange={(open) => {
                if (open && !isUpdateReasonReported.current) {
                  // Only track opening of the dropdown.
                  sdk.captureUserAction('OPEN_DROPDOWN_UPDATE_STATUS_REASON', {
                    reportToEts: true,
                  })
                }
                // keep track of open state. Since open event is trigger twice without a close event in b/w. ( When opened using enter )
                isUpdateReasonReported.current = open
              }}
              onChange={(e: string) => {
                sdk.captureUserAction('SELECT_UPDATE_STATUS_REASON', { reportToEts: true })
                setSelectedReason(e)
              }}
              placeholder={t('select_labels.select_reason')}
              disabled={isUpdatingDeliveryStatus}
              options={changeDeliveryStatusReasonsList.next_step_reasons.map((reason) => {
                return { label: reason, value: reason }
              })}
            ></Select>
          </Flex>

          {/* Action buttons */}
          <Flex justifyContent='flex-end' gap='8px' pt='24px'>
            <Button type='outlined' onClick={() => onQuit()} disabled={isUpdatingDeliveryStatus}>
              {t('Interface.Cancel')}
            </Button>
            <Button
              type='solid'
              key='submit'
              onClick={handleSaveClick}
              disabled={!selectedReason}
              loading={isUpdatingDeliveryStatus}
            >
              {t('Interface.Save')}
            </Button>
          </Flex>
        </div>
      )
    },
    {
      deriveInitialViewState() {
        return {
          destroyable: false,
        }
      },
    },
  )
