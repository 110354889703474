import React from 'react'
import { Space, Typography } from 'antd'
import { createUseStyles } from 'react-jss'
import { Button, ButtonProps } from 'shared'

const { Text } = Typography

export const useStyles = createUseStyles({
  actionContainer: {
    width: '140px',
    textAlign: 'center',
  },
  actionButton: {
    padding: '0px',
  },
  actionText: {
    fontSize: '12px',
  },
})

interface DeviceActionProps {
  title: string
  description: string
  icon: ButtonProps['icon']
  onClick: () => void
}

export const DeviceAction: React.FC<DeviceActionProps> = ({
  title,
  description,
  icon,
  onClick,
}) => {
  const classes = useStyles()

  return (
    <Space className={classes.actionContainer} direction='vertical'>
      <Button
        className={classes.actionButton}
        icon={icon}
        iconSize={12}
        block
        type='outlined'
        onClick={onClick}
      >
        {title}
      </Button>
      <Text className={classes.actionText} type={'secondary'}>
        {description}
      </Text>
    </Space>
  )
}
