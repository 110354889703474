export enum OrderStatuses {
  created = 'CREATED',
  delivered = 'DELIVERED',
  pickedUp = 'PICKED_UP',
  accepted = 'ACCEPTED',
  rejected = 'REJECTED',
  cancelled = 'CANCELLED',
  mistake = 'MISTAKE_ERROR',
  transmitted = 'TRANSMITTED',
  expired = 'EXPIRED',
  failed = 'FAILED',
  delayed = 'DELAYED',
  orderPrepared = 'ORDER_PREPARED',
  sentToTransmission = 'SENT_TO_TRANSMISSION',
  reachingTransmissionFailed = 'REACHING_TRANSMISSION_FAILED',
  sendingToVendor = 'SENDING_TO_VENDOR',
  displayedAtVendor = 'DISPLAYED_AT_VENDOR',
  courierNearPickup = 'COURIER_NEAR_PICK_UP',
  courierLeftPickup = 'COURIER_LEFT_PICK_UP',
  courierNearDropoff = 'COURIER_NEAR_DROP_OFF',
  courierAcceptedDelivery = 'COURIER_ACCEPTED_DELIVERY',
  sentToLogistics = 'SENT_TO_LOGISTICS',
  estimated = 'Estimated to be Delivered',
  pickupEstimated = 'Estimated to be Picked-up',
  promised = 'Promised to be Delivered',
  unknown = 'UNKNOWN',

  /**
   * this means that no payment was got for a pending order after a certain amount of time
   */
  pendingOrderFailed = 'PENDING_ORDER_FAILED',
  pending = 'PENDING',
  placed = 'PLACED',

  primaryDeliveryPinAcknowledged = 'PRIMARY_DELIVERY_PIN_ACKNOWLEDGED',
  primaryDeliveryPinValidated = 'PRIMARY_DELIVERY_PIN_VALIDATED',
}

export enum MiniOrderStatuses {
  cancelled = 'Cancelled',
  delivered = 'Delivered',
  delayed = 'Delayed',
  near_pick_up = 'Courier Near Pick Up',
  accepted = 'Accepted',
  picked_up = 'Picked Up',
  courier_accepted = 'Courier Accepted Delivery',
  sent_to_transmission = 'Sent To Transmission',
}

export enum CancellationReasons {
  mistakeError = 'MISTAKE_ERROR',
  neverDelivered = 'NEVER_DELIVERED',
  foodQualitySpillige = 'FOOD_QUALITY_SPILLAGE',
  wrongOrderItemsDelivered = 'WRONG_ORDER_ITEMS_DELIVERED',
  deliveryEtaTooLong = 'DELIVERY_ETA_TOO_LONG',
  noCourier = 'NO_COURIER',
  voucherNotApplied = 'VOUCHER_NOT_APPLIED',
  orderModificationNotPossible = 'ORDER_MODIFICATION_NOT_POSSIBLE',
  duplicateOrder = 'DUPLICATE_ORDER',
}
