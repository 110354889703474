/**
 * TextBlockView: renders a textual field whose arrangement depends on the passed props
 * it is either a single text field, a text field wrapped with a tooltip, or a text field with links to the passed sources
 * */

// libs
import React, { useState, useEffect } from 'react'
// types
import OrderLink from 'types/riderService/orderLink'
import OrderDataPointIdentifiers from 'types/riderService/orderDataPointIdentifiers'
// styles
import { createUseStyles } from 'react-jss'
import styles from './TextBlockView.styles'
import { Typography, Button, Tooltip } from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons'
import customerDataPointIdentifiers from 'types/riderService/customerDataPointIdentifiers'
import PhoneNumber from 'components/PhoneNumber/PhoneNumber'
import riderDataPointIdentifiers from 'types/riderService/riderDataPointIdentifiers'
import VendorDataPointIdentifiers from 'types/riderService/vendorDataPointIdentifiers'
import { useCaptureUserAction } from 'hooks/events/useCaptureUserAction'

const useStyles = createUseStyles(styles)

const dialClickEventNames = {
  [customerDataPointIdentifiers.customer_phone]: 'CustomerWidgetCustomerPhoneClicked',
  [riderDataPointIdentifiers.rider_phone]: 'RiderWidgetRiderPhoneClicked',
  [VendorDataPointIdentifiers.vendor_phone]: 'VendorWidgetVendorPhoneClicked',
}

interface Props {
  label: string
  data?: string | number
  identifier: string
  links?: OrderLink[]
}

const TextBlockView: React.FC<Props> = ({ label, data, identifier, links }) => {
  const classes = useStyles()
  const { Text } = Typography

  const captureUserAction = useCaptureUserAction()

  // check if current data is delivery instructions
  // modify text to a certain length if so
  const [isDeliveryInst, setIsDeliveryInst] = useState(false)
  const [deliveryInstDisplaySection, setDeliveryInstDisplaySection] = useState('')
  useEffect(() => {
    if (identifier === OrderDataPointIdentifiers.delivery_instructions) {
      setIsDeliveryInst(true)
      setDeliveryInstDisplaySection(`${data.toString().slice(0, 43)}...`)
    }
  }, [identifier, data])

  const handleLinkClick = (clickedLink: string) => {
    if (clickedLink === 'Hurrier') {
      return captureUserAction('OrderSummaryHurrierLinkClicked')
    }
    return captureUserAction('OrderSummaryPandoraBackofficeLinkClicked')
  }

  const isPhoneNumber = () =>
    identifier === customerDataPointIdentifiers.customer_phone ||
    identifier === riderDataPointIdentifiers.rider_phone ||
    identifier === VendorDataPointIdentifiers.vendor_phone

  return (
    <div className={classes.textBlock}>
      <Text className={classes.labelText}>{`${label}: `}</Text>

      {data &&
        !isDeliveryInst &&
        (isPhoneNumber() ? (
          <PhoneNumber
            linkTarget='_blank'
            value={data}
            onDialClick={() => captureUserAction(dialClickEventNames[identifier])}
          />
        ) : (
          <Text className={classes.dataText}>{data}</Text>
        ))}

      {data && isDeliveryInst && (
        <div>
          <Tooltip title={data}>
            <Text className={classes.dataText}>
              {deliveryInstDisplaySection}
              <InfoCircleOutlined className={classes.icon} />
            </Text>
          </Tooltip>
        </div>
      )}

      {!data && links && links.length > 0 && (
        <React.Fragment>
          {links.map((link: OrderLink, idx: number) => (
            <React.Fragment key={idx}>
              {idx > 0 ? <Text className={classes.separator}>|</Text> : null}
              <Button
                type='link'
                className={classes.linkButton}
                onClick={() => handleLinkClick(link.linkText)}
                href={link.link}
                target={link.link}
              >
                {link.linkText}
              </Button>
            </React.Fragment>
          ))}
        </React.Fragment>
      )}
    </div>
  )
}
export default TextBlockView
