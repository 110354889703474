import { UnifiedIconName } from 'contexts/IconPacksProvider'
import { PaymentMedium } from 'types/api/orderApi/order'
import capitalize from 'lodash/capitalize'

type Meta = {
  icon: UnifiedIconName
  label: string
}

export const getPaymentMediumMeta = (paymentMedium: PaymentMedium): Meta => {
  switch (paymentMedium) {
    case PaymentMedium.WALLET_REFUND:
      return { label: 'Wallet', icon: 'WalletOutlined' }
    case PaymentMedium.GIFT_CARD:
      return { label: 'Gift Card', icon: 'GiftCardIcon' }
    case PaymentMedium.VOUCHER:
      return { label: 'Voucher', icon: 'VoucherIcon' }
    case PaymentMedium.NOT_DEFINED_YET:
      return { label: 'Predefined', icon: 'DigitalPaymentIcon' }
    default:
      return { label: capitalize(paymentMedium), icon: 'DigitalPaymentIcon' }
  }
}
