import React from 'react'
import { createUseStyles } from 'react-jss'
import { Typography } from 'antd'
import { getAgentNameFromId } from '../../utils/string'
const { Text } = Typography

const useStyles = createUseStyles({
  agentNameText: {
    color: 'rgba(0, 0, 0, 0.55)',
    fontSize: '12px',
    textTransform: 'capitalize',
    whiteSpace: 'nowrap',
  },
})

interface Props {
  agentId: string
}

export const AgentName: React.FC<Props> = ({ agentId }) => {
  const classes = useStyles()
  const agentName = getAgentNameFromId(agentId)

  return <Text className={classes.agentNameText}>{agentName}</Text>
}
