import { ApiErrorConfig } from './voucherApiErrorConfig'

export const vendorApiErrorConfig: ApiErrorConfig<'VendorApi'> = {
  VendorApi: {
    addressInVendorDeliveryArea: {
      400: {
        '*': {
          screen: 'warning',
          heading: 'Unable to validate address',
          copyText:
            'Failed to validate address against delivery area of vendor{{dot}} You do not have the permission required',
        },
      },
      401: {
        '*': {
          screen: 'warning',
          heading: "Couldn't validate address",
          copyText:
            'Failed to validate address against delivery area of vendor{{dot}} You do not have the permission required',
        },
      },
      500: {
        '*': {
          screen: 'error',
          heading: "Couldn't validate address against delivery zone",
          copyText: 'Error HTTP 500 has occured{{dot}} Come back later',
        },
      },
      503: {
        '*': {
          screen: 'error',
          heading: "Couldn't validate address against delivery zone",
          copyText: 'Error HTTP 503 has occured{{dot}} Come back later',
        },
      },
    },
    getVendor: {
      400: {
        '*': {
          screen: 'error',
          heading: 'Vendor data unavailable',
          copyText: 'An error has occured',
        },
      },
      401: {
        '*': {
          screen: 'warning',
          heading: 'Vendor data unavailable',
          copyText: 'An error has occured{{dot}} Select refresh to try again',
        },
      },
      403: {
        '*': {
          screen: 'instruct',
          heading: 'Permission required',
          copyText: 'Ask a supervisor to check Oneview permissions then refresh',
        },
      },
      404: {
        '*': {
          screen: 'no-data',
          heading: 'No Vendor found',
          copyText: '',
        },
      },
      500: {
        '*': {
          screen: 'error',
          heading: 'Vendor data unavailable',
          copyText: 'An error has occured',
        },
      },
    },
  },
}
