import React, { useState } from 'react'
import { FileImageOutlined } from '@ant-design/icons'
import { ItemImageUrl } from 'types/api/orderApi/order'
import { Tooltip } from 'antd'
import useItemImageStyles from './styles'
import { ItemImage } from './ItemImage'
import { ImageLoadFailed } from './ImageLoadFailed'
import { useTranslation } from 'hooks/useTranslation'
import { text } from 'theme'
import { createUseStyles } from 'react-jss'
import { useSdk } from 'contexts/SdkProvider'

type Props = {
  imageUrls?: ItemImageUrl[]
}

const useIconStyles = createUseStyles({
  disabled: {
    color: text.disabled,
  },
})

export const ItemImageIcon = ({ imageUrls = [] }: Props) => {
  const { t } = useTranslation()
  const classes = useItemImageStyles()

  const iconStyles = useIconStyles()

  const isItemImageAvailable = imageUrls.length && imageUrls[0].url !== ''

  const placeholderText = 'loading...'

  const [toolTipTitle, setTooltipTitle] = useState<React.ReactNode>(() => {
    return !isItemImageAvailable ? t('OrderWidget.Tabs.Items.No image found') : placeholderText
  })

  const handleImageLoadError = () => {
    setTooltipTitle(<ImageLoadFailed />)
  }

  const { captureUserAction } = useSdk()

  return (
    <>
      <Tooltip
        trigger={isItemImageAvailable ? 'click' : 'hover'}
        overlayClassName={isItemImageAvailable ? classes.imageTooltip : ''}
        title={toolTipTitle}
        placement='left'
        onVisibleChange={(visible) => {
          if (visible && isItemImageAvailable) {
            captureUserAction('OPEN_PROOF_OF_DELIVERY', {
              reportToEts: true,
            })
          }

          if (visible && toolTipTitle === placeholderText) {
            setTooltipTitle(<ItemImage image={imageUrls[0]} onError={handleImageLoadError} />)
          }
        }}
      >
        <FileImageOutlined className={!isItemImageAvailable ? iconStyles.disabled : undefined} />
      </Tooltip>
    </>
  )
}
