export enum ChatParticipant {
  customer = 'customer',
  rider = 'rider',
  vendor = 'vendor',
}

export enum ChatEventType {
  joined = 'joined',
  message = 'message',
  image = 'image',
}

export enum ChatEventStatus {
  sent = 'sent',
  read = 'read',
}

export interface BaseChatEventTypeData {
  channel_id: string
  status: ChatEventStatus
  timestamp: number
  event_type: ChatEventType
  sender_nickname: string
  message_id: string
}

export interface JoinedChatEventTypeData extends BaseChatEventTypeData {
  event_type: ChatEventType.joined
  content: {
    id: string
    nickname: string
    type: ChatParticipant
  }
}

export interface MessageChatEventTypeData extends BaseChatEventTypeData {
  event_type: ChatEventType.message
  content: string
  content_type: string
  language: string
  message: string
  sender_id: string
  sender_type: ChatParticipant

  supported_languages: string[]
  translations: Record<string, string>
}

export interface ImageChatEventTypeData extends BaseChatEventTypeData {
  event_type: ChatEventType.image
  content: { caption: string; url: string }
  content_type: string
  language: string
  message: string
  sender_id: string
  sender_type: ChatParticipant
}

export interface RiderChatsData {
  channel: Partial<{
    brand: string
    correlation: string
    created_at: string
    freeze_at: string
    id: string
    last_activity: string
    last_read_timestamp: number
  }>
  users: Array<{
    created_at: string
    id: string
    nickname: string
    type: ChatParticipant
  }>

  events: Array<JoinedChatEventTypeData | MessageChatEventTypeData | ImageChatEventTypeData>
}
