/**
 * Customer Widget: runs entity config checks and renders tabs: Summary, Vouchers and Wallet when available
 * */

// libs
import React, { useState, useEffect, useContext } from 'react'
// configs
import {
  allowedCustomerWidgetTabs,
  allowedScrollablePanelWidgets,
} from 'entityConfig/allowedConfigValues'

// contexts and types
import { DataContext } from 'contexts/data/DataContext'
import CustomerTabs from 'types/widgets/customer/tabs'
import { LinesOfBusiness } from 'types/session/linesOfBusiness'

// hooks
import { useTranslation } from 'hooks/useTranslation'
import { useEntityConfig } from 'hooks/useEntityConfig'
import { useSessionState } from 'hooks/useSessionState'

// styles
import { Tabs } from 'antd'

import WarningMessage from 'components/WarningMessage'

import { useActiveTab } from 'hooks/useActiveTab'
import { useGetValidFeatures } from 'hooks/useGetValidFeatures'
import { usePluginUtils } from 'hooks/usePluginUtils'
import { PluginContainer } from 'components/PluginContainer'
import CustomerWidgetHeader from './CustomerWidgetHeader'
import WidgetStaticError from 'components/WidgetStaticError'
import LoadingView from 'components/LoadingView'
import { useUiV1TopLevelTabStyles } from 'hooks/styles/useUiV1TopLevelTabStyles'
import { RootWidgetView } from 'components/RootWidgetView'
import { builtinWidgetNames, widgetTypes } from 'types'

const Customer: React.FC = () => {
  const { TabPane } = Tabs

  const { getWidgetConfig } = useEntityConfig()
  const getValidFeatures = useGetValidFeatures()

  const { isTabAPlugin, getTabPluginConfig } = usePluginUtils()

  const customerConfig = getWidgetConfig('customer')

  // pull language content
  const { t } = useTranslation()

  // pull data state from data context
  const { dataState } = useContext(DataContext)

  const { orderId, lineOfBusiness, globalEntityId } = useSessionState()

  const [isFraud, setIsFraud] = useState<boolean>(false)

  // run beta flag checks on tabs and set initial tab
  const [activeTab, setActiveTab] = useActiveTab<CustomerTabs | string>('CustomerTab')
  const { compensatedVoucher, fullyRefundedVoucher } = dataState
  const [customerWidgetTabOrder, setCustomerWidgetTabOrder] = useState<string[]>()

  useEffect(() => {
    if (customerConfig?.tab_order) {
      const widgetTabs = customerConfig.tab_order.filter((tab) => tab.active !== false)
      const tabs = getValidFeatures(widgetTabs)
      setCustomerWidgetTabOrder(tabs)

      if ((compensatedVoucher || fullyRefundedVoucher) && tabs.includes(CustomerTabs.vouchers)) {
        setActiveTab(CustomerTabs.vouchers)
      } else {
        setActiveTab(CustomerTabs[tabs[0]])
      }
    }
  }, [compensatedVoucher, fullyRefundedVoucher, setActiveTab, getValidFeatures, customerConfig])

  const tabStyles = useUiV1TopLevelTabStyles()

  if (lineOfBusiness === LinesOfBusiness.riderV2 && !dataState.order) {
    return (
      <div>
        <CustomerWidgetHeader
          customerId={dataState?.customer?.customer_id}
          fraudConfig={customerConfig?.FraudFlag}
          onFraudCheck={setIsFraud}
          globalEntityId={globalEntityId}
          orderId={orderId}
        />
        {dataState.isLoadingOrder ? (
          <LoadingView text={t('Interface.Loading')} />
        ) : (
          <WidgetStaticError
            content={[
              t('Widgets Common.Couldnt find order {{orderId}} in country {{entityId}}.', {
                useLastKeyAsFallback: true,
                replace: { orderId, entityId: globalEntityId },
              }),
              t('Widgets Common.Check the order ID and country, then search for the order again'),
            ]}
            title={t('Widgets Common.Order not found')}
          />
        )}
      </div>
    )
  }

  // get fraud flag

  return (
    <div>
      <React.Fragment>
        <CustomerWidgetHeader
          customerId={dataState?.customer?.customer_id}
          fraudConfig={customerConfig?.FraudFlag}
          onFraudCheck={setIsFraud}
          orderId={orderId}
          globalEntityId={globalEntityId}
        />
        {lineOfBusiness === LinesOfBusiness.riderV2 && <WarningMessage />}

        {/* render available customer widget tabs */}
        <Tabs
          activeKey={activeTab}
          className={tabStyles.tabs}
          onChange={(tab: string) => {
            switch (tab) {
              case CustomerTabs.summary:
                setActiveTab(CustomerTabs[tab], true)
                break

              case CustomerTabs.profile:
              case CustomerTabs.vouchers:
                setActiveTab(CustomerTabs[tab], true, {
                  is_fraud: isFraud,
                })
                break
              default:
                if (isTabAPlugin(allowedScrollablePanelWidgets.customer, tab)) {
                  setActiveTab(tab, true)
                } else {
                  setActiveTab(CustomerTabs[tab], true)
                }
            }
          }}
          type='card'
          size={'small'}
        >
          {customerWidgetTabOrder
            ? customerWidgetTabOrder.map((tab: string) => {
                switch (tab) {
                  case allowedCustomerWidgetTabs.summary:
                    return (
                      <TabPane tab={t('Widgets Common.Summary')} key='summary'>
                        <RootWidgetView
                          widgetDefinition={{
                            type: widgetTypes.builtin,
                            widget_name: builtinWidgetNames.customerDetails,
                          }}
                          screenName='customer_widgets'
                        />
                      </TabPane>
                    )
                  case allowedCustomerWidgetTabs.profile:
                    return (
                      <TabPane tab={t('Customer Widget.Tabs.Profile.Title')} key='profile'>
                        <RootWidgetView
                          widgetDefinition={{
                            type: widgetTypes.builtin,
                            widget_name: builtinWidgetNames.customerAttributes,
                          }}
                          screenName='customer_widgets'
                        />
                      </TabPane>
                    )
                  case allowedCustomerWidgetTabs.vouchers:
                    return (
                      <TabPane tab={t('Customer Widget.Tabs.Vouchers.Title')} key='vouchers'>
                        <RootWidgetView
                          widgetDefinition={{
                            type: widgetTypes.builtin,
                            widget_name: builtinWidgetNames.customerVouchers,
                          }}
                          screenName='customer_widgets'
                        />
                      </TabPane>
                    )
                  case allowedCustomerWidgetTabs.wallet:
                    return (
                      <TabPane tab={t('Customer Widget.Tabs.Wallet.Title')} key='wallet'>
                        <RootWidgetView
                          widgetDefinition={{
                            type: widgetTypes.builtin,
                            widget_name: builtinWidgetNames.customerWallet,
                          }}
                          screenName='customer_widgets'
                        />
                      </TabPane>
                    )
                }

                if (isTabAPlugin(allowedScrollablePanelWidgets.customer, tab)) {
                  const pluginTab = getTabPluginConfig(allowedScrollablePanelWidgets.customer, tab)
                  return (
                    <TabPane tab={t(pluginTab.title)} key={pluginTab.plugin_code}>
                      <PluginContainer pluginCode={pluginTab.plugin_code} />
                    </TabPane>
                  )
                }

                return null
              })
            : null}
        </Tabs>
      </React.Fragment>
    </div>
  )
}

export default Customer
