export default {
  modal: {
    display: 'flex',
    flexDirection: 'column',
    minWidth: 500,
  },
  titleHolder: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#1295e6',
    padding: 20,
    margin: -24,
  },
  modalTitle: {
    fontSize: '16px',
    color: '#fff',
    marginTop: '7px',
  },
  stepsContainer: {
    minWidth: '500px',
    margin: '10px 0px',
  },
  contentContainer: {
    width: '90%',
    minWidth: '500px',
    margin: '15px 5% 15px 5%',
  },
  // LOADING
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
  },
  spinner: {
    marginBottom: '18px',
  },
  loadingMainText: {
    color: '#a6a6a6',
    fontSize: '16px',
  },
  loadingSuccess: {
    color: '#2b9e3a',
    fontSize: '14px',
  },
  loadingText: {
    color: '#828281',
    fontSize: '15px',
    marginTop: '20px',
  },
  // SUCCESS
  resultSubtextHolder: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  resultSubtext: {
    fontSize: '14px',
    color: 'rgba(0, 0, 0, 0.45)',
  },
  successfulVoucherContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItem: 'center',
    width: '100%',
    padding: '0px 20px 20px 20px',
  },
  subtitle: {
    fontSize: '16px',
    color: 'rgba(0, 0, 0, 0.45)',
  },
  resultVoucherSubtext: {
    marginTop: '10px',
    fontSize: '16px',
    color: 'rgba(0, 0, 0, 0.45)',
  },
  resultVoucherCode: {
    marginTop: '7px',
    fontSize: '20px',
    color: 'rgba(0, 0, 0, 0.65)',
  },
  sectionCopyText: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '13px',
    fontWeight: 400,
    color: '#2A8FF7',
    margin: '10px 0px 10px 0px',
  },
  successfulVoucherAlert: {
    marginTop: '20px',
    textAlign: 'left',
  },
  successAlert: {
    margin: '40px 0px 15px 0px',
  },
  successButtonContainer: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    marginTop: '25px',
  },
  quitButton: {
    marginRight: '5px',
  },
}
