import { VendorDeviceList } from 'services/vendorApi/getVendorDevices'
import { VendorDevice } from '../../widgets/VendorDevices/DeviceCard/DeviceCard'

export function transformDeviceList(transformDeviceList: VendorDeviceList): VendorDevice[] {
  return Object.entries(transformDeviceList.deviceStatuses).map(([deviceId, device]) => ({
    deviceId,
    lpvId: transformDeviceList.lpvId,
    ...device,
  }))
}
