import React from 'react'
import { createCustomSvgIcon } from 'factory/createCustomSvgIcon'

export const MapMarkerIcon = createCustomSvgIcon({
  viewBoxWidth: 10,
  viewBoxHeight: 12,
  content: (
    <>
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M4.75016 0C2.12798 0.00303095 0.00303095 2.12798 0 4.75016C0 8.02377 3.26911 10.9944 4.27064 11.8269C4.54847 12.0577 4.95135 12.0577 5.22918 11.8269C6.22921 10.9944 9.50032 8.02277 9.50032 4.74966C9.49701 2.12767 7.37215 0.00303039 4.75016 0ZM4.75018 7.25026C3.36942 7.25026 2.25009 6.13093 2.25009 4.75018C2.25009 3.36942 3.36942 2.25009 4.75018 2.25009C6.13093 2.25009 7.25026 3.36942 7.25026 4.75018C7.25026 5.41324 6.98686 6.04915 6.518 6.518C6.04915 6.98686 5.41324 7.25026 4.75018 7.25026Z'
        fill='currentColor'
      />
    </>
  ),
})
