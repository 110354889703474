import React from 'react'
import { createUseStyles } from 'react-jss'

// Components
import { Typography } from 'antd'

// Relative path
import styles from './OrderID.styles'
import { useEntityConfig } from '../../../hooks/useEntityConfig'
import { useCanDisplayDataPoint } from '../../../hooks/useGetValidFeatures'

const useStyles = createUseStyles(styles)
const { Text } = Typography

type Props = {
  orderNumber: string
}

function OrderNumber({ orderNumber }: Props) {
  const classes = useStyles()
  const canDisplayDataPoint = useCanDisplayDataPoint()

  const { getWidgetConfig } = useEntityConfig()

  const {
    tab_configs: {
      summary: { order_number },
    },
  } = getWidgetConfig('order')

  const isAlways = order_number.displayRule.includes('always')
  const value = isAlways ? `#${orderNumber || ''}` : orderNumber
  const canDisplay = canDisplayDataPoint(value, order_number)

  if (!canDisplay) return null

  return (
    <Text type='secondary' data-testid={'order-number'} className={classes.orderNumber}>
      {isAlways ? `${value}` : `#${value}`}
    </Text>
  )
}

export default OrderNumber
