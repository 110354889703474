export default {
  paymentDataContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    padding: '15px',
    backgroundColor: '#fff',
    borderRadius: '3px',
  },
}
