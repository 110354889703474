/**
 * New Comment Modal
 * renders the content of new comment action -> text area and checkboxes
 * posts new comment upon action completion
 * */

// libs
import React, { useState, useEffect, useContext, useImperativeHandle } from 'react'
// configs
import { NewCommentFieldValues } from 'entityConfig/allowedConfigValues'
// contexts and types
import { DataContext } from 'contexts/data/DataContext'
import { DataAction } from 'contexts/data/types'
import {
  SingleCommentAction,
  SingleCommentType,
  SingleComment,
} from 'types/api/commentApi/getOrderComments'
// hooks
import { useTranslation } from 'hooks/useTranslation'
// utils
// styles
import { Typography, Button, Input, Result, Checkbox } from 'antd'
import { createUseStyles } from 'react-jss'
import styles from './NewCommentModalView.styles'
// components
import { postNewComment } from 'services/commentApi/postNewComment'
import { useApiService } from 'hooks/useApiService'
import { WidgetErrorHandler } from 'components/WidgetErrorHandler/widgetErrorHandler'
import { NewCommentConfig } from 'contexts/entity/types'
import { EMPTY_CATCH_CALLBACK } from 'constants/constants'
import { useCaptureUserAction } from 'hooks/events/useCaptureUserAction'
import { readAuthPayload } from 'utils/authHelpers'
import { SessionContext } from 'contexts/session/SessionContext'
import { createPluggableWidget } from 'factory/createPluggableWidget'

const useStyles = createUseStyles(styles)

export const NewCommentWidgetView = createPluggableWidget<NewCommentConfig>(
  ({ config, globalEntityId, caseId, onQuit, sdk }, ref) => {
    const classes = useStyles()
    const { Text } = Typography
    const { TextArea } = Input

    // pull translations
    const { t } = useTranslation()
    const captureUserAction = useCaptureUserAction()

    // pull the session
    const {
      sessionState: { orderId, userId, vendorId, riderId: riderIdV2 },
    } = useContext(SessionContext)

    // pull dataState
    const { dataState, dataDispatch } = useContext(DataContext)
    const { SET_NEW_COMMENT } = DataAction

    const [isOrderChecked, setIsOrderChecked] = useState(false)
    const [showOrderCheckbox, setShowOrderCheckbox] = useState(false)

    const [isBackOfficeChecked, setIsBackOfficeChecked] = useState(false)
    const [showBackOfficeCheckbox, setShowBackOfficeCheckbox] = useState(false)

    const [isCustomerChecked, setIsCustomerChecked] = useState(false)
    const [showCustomerCheckbox, setShowCustomerCheckbox] = useState(false)

    const [isVendorChecked, setIsVendorChecked] = useState(false)
    const [showVendorCheckbox, setShowVendorCheckbox] = useState(false)

    const [isRiderChecked, setIsRiderChecked] = useState(false)
    const [showRiderCheckbox, setShowRiderCheckbox] = useState(false)

    const [agentId, setAgentId] = useState('')
    const [customerId, setCustomerId] = useState('')
    const [riderId, setRiderId] = useState(riderIdV2 || '')

    // figure out how checkboxes should behave for the entity and set relevant IDs
    useEffect(() => {
      // receives the checkbox and its corresponded local state updaters -> checks entity config and updates local state accordingly
      const setCheckboxAvailability = (
        checkbox: string,
        isChecked: (arg: boolean) => void,
        isShow: (arg: boolean) => void,
      ) => {
        if (checkbox === NewCommentFieldValues.showChecked) {
          isChecked(true)
          isShow(true)
        } else if (checkbox === NewCommentFieldValues.showUnchecked) {
          isChecked(false)
          isShow(true)
        } else if (checkbox === NewCommentFieldValues.noShowLinked) {
          isChecked(true)
          isShow(false)
        } else if (checkbox === NewCommentFieldValues.noShowUnlinked) {
          isChecked(false)
          isShow(false)
        }
      }

      const { agentEmail } = readAuthPayload()
      // set agent email from browser
      if (agentEmail) {
        setAgentId(agentEmail)
      }

      // call setCheckboxAvailability for each checkbox and pass to it the relevant local state updaters, if relevant ID is found
      if (orderId && dataState.order) {
        setCheckboxAvailability(config.order, setIsOrderChecked, setShowOrderCheckbox)

        setCheckboxAvailability(
          config.back_office,
          setIsBackOfficeChecked,
          setShowBackOfficeCheckbox,
        )

        if (dataState?.customer?.customer_id) {
          setCustomerId(dataState.customer.customer_id)
          setCheckboxAvailability(config.customer, setIsCustomerChecked, setShowCustomerCheckbox)
        } else if (userId) {
          setCustomerId(userId)
          setCheckboxAvailability(config.customer, setIsCustomerChecked, setShowCustomerCheckbox)
        }
      } else if (dataState?.customer?.customer_id || userId) {
        setCustomerId(dataState?.customer?.customer_id ? dataState.customer.customer_id : userId)
        setIsCustomerChecked(true)
      }

      if (vendorId) {
        setCheckboxAvailability(config.vendor, setIsVendorChecked, setShowVendorCheckbox)
      }

      if (riderIdV2) {
        setCheckboxAvailability(config.rider, setIsRiderChecked, setShowRiderCheckbox)
      } else if (
        dataState?.fulfillment?.deliveries &&
        dataState?.fulfillment?.deliveries.length > 0 &&
        dataState?.fulfillment?.deliveries[0].courier?.id
      ) {
        setRiderId(dataState.fulfillment.deliveries[0].courier.id.toString())
        setCheckboxAvailability(config.rider, setIsRiderChecked, setShowRiderCheckbox)
      }
    }, [dataState, userId, config, globalEntityId, orderId, vendorId, riderIdV2])

    // new comment typed by agent
    const [newComment, setNewComment] = useState('')
    const {
      loadService: executePostNewComment,
      status: postNewCommentStatus,
      error: postNewCommentError,
      loading: isPostingNewComment,
      clearError: clearPostNewCommentError,
    } = useApiService({
      service: postNewComment,
      deps: [],
      autoLoad: false,
    })

    // fired when new comment save clicked -> posts the new comment
    const handleNewCommentSaveClick = async () => {
      captureUserAction('ActionsNewCommentSaveCommentClicked', {
        eventDetails: {
          global_entity_id: globalEntityId,
          case_id: caseId,
          order_id: orderId,
        },
      })
      const comment: SingleComment = {
        action: SingleCommentAction.manual_comment,
        type: SingleCommentType.manual,
        contact_id: caseId,
        global_entity_id: globalEntityId,
        agent_id: agentId,
        additional_data: {
          manual_comment: {
            text: newComment,
          },
        },
      }

      if (isOrderChecked && orderId) {
        comment.order_id = orderId
      }

      if (isCustomerChecked && customerId) {
        comment.customer_id = customerId
      }

      if (isVendorChecked && vendorId) {
        comment.vendor_id = vendorId
      }

      if (isRiderChecked && riderId) {
        comment.rider_id = riderId
      }

      return executePostNewComment({
        entityId: globalEntityId,
        comment,
      })
        .then(({ data }) => {
          sdk.eventEmitter.dispatchEvent({
            name: 'NEW_COMMENT_SUCCESS',
            payload: { orderId },
          })
          dataDispatch({
            type: SET_NEW_COMMENT,
            payload: { newComment: data },
          })
        })
        .catch(EMPTY_CATCH_CALLBACK)
    }

    const handleCheckboxClicked = (e: any, checkboxName: string) => {
      switch (checkboxName) {
        case 'order':
          setIsOrderChecked(e.target.checked)
          captureUserAction('ActionsNewCommentCheckboxOrderClicked')
          break
        case 'customer':
          setIsCustomerChecked(e.target.checked)
          captureUserAction('ActionsNewCommentCheckboxCustomerClicked')
          break
        case 'vendor':
          setIsVendorChecked(e.target.checked)
          break
        case 'rider':
          setIsRiderChecked(e.target.checked)
          break
        case 'backoffice':
          setIsBackOfficeChecked(e.target.checked)
          break
        default:
          break
      }
    }

    const isSuccess = postNewCommentStatus === 'success'

    const shouldNotDisplayContent =
      config.order === NewCommentFieldValues.noShowUnlinked &&
      config.customer === NewCommentFieldValues.noShowUnlinked &&
      config.vendor === NewCommentFieldValues.noShowUnlinked &&
      config.rider === NewCommentFieldValues.noShowUnlinked &&
      config.back_office === NewCommentFieldValues.noShowUnlinked

    const handleOkButtonClick = () => onQuit(true)

    const handleQuitButtonClick = () => onQuit()

    useImperativeHandle(
      ref,
      () => {
        return {
          onXButtonClick: () => {
            return isSuccess || !newComment
          },
        }
      },
      [newComment, isSuccess],
    )

    return (
      <WidgetErrorHandler
        errorPayload={postNewCommentError?.errorPayload}
        displayType='overlay'
        onQuit={onQuit}
        onBack={clearPostNewCommentError}
        loading={isPostingNewComment}
      >
        {() => {
          if (isSuccess) {
            return (
              <Result
                status='success'
                title={t('Widgets Common.Success')}
                subTitle={t('Actions Widget.Actions.New Comment.Comment created successfully')}
                extra={[
                  <Button onClick={handleOkButtonClick} type='primary' key='quit'>
                    {t('Interface.OK')}
                  </Button>,
                ]}
              />
            )
          }

          if (shouldNotDisplayContent) {
            return (
              <Result
                status='warning'
                title={t('Actions Widget.Actions.New Comment.New Comment is not permitted')}
                subTitle={t(
                  'Actions Widget.Actions.New Comment.It looks like the current entity allows no linking of comment to order, customer, vendor, rider, or back office',
                )}
                extra={[
                  <Button onClick={handleQuitButtonClick} type='primary' key='quit'>
                    {t('Messages.Process Quit.Quit')}
                  </Button>,
                ]}
              />
            )
          }

          return (
            <div className={classes.commentHolder}>
              <Text className={classes.commentTitle}>
                {`${t('Actions Widget.Actions.New Comment.Comment')}:`}
              </Text>
              <TextArea
                placeholder={t('Actions Widget.Actions.New Comment.Title')}
                autoSize={{ minRows: 6, maxRows: 6 }}
                value={newComment}
                onChange={(e) => setNewComment(e.target.value)}
              />

              {/* ASSOCIATION MESSAGE -> if no checkboxes displayed but at least one of them checked, render a message */}
              {!showOrderCheckbox &&
              !showCustomerCheckbox &&
              !showVendorCheckbox &&
              !showRiderCheckbox &&
              !showBackOfficeCheckbox &&
              (isOrderChecked ||
                isCustomerChecked ||
                isVendorChecked ||
                isRiderChecked ||
                isBackOfficeChecked) ? (
                <Text className={classes.explanationForApac}>
                  {`${t(
                    'Actions Widget.Actions.New Comment.This comment will be displayed under the following sections',
                  )}:`}
                  {isOrderChecked && ` ${t('Order Widget.Order')}`}

                  {isCustomerChecked && isOrderChecked
                    ? `, ${t('Customer Widget.Customer')}`
                    : isCustomerChecked
                    ? ` ${t('Customer Widget.Customer')}`
                    : null}
                  {isVendorChecked && (isOrderChecked || isCustomerChecked)
                    ? `, ${t('Vendor Widget.Vendor')}`
                    : isVendorChecked
                    ? ` ${t('Vendor Widget.Vendor')}`
                    : null}
                  {isRiderChecked && (isOrderChecked || isCustomerChecked || isVendorChecked)
                    ? `, ${t('Rider Widget.Rider')}`
                    : isRiderChecked
                    ? ` ${t('Rider Widget.Rider')}`
                    : null}
                  {isBackOfficeChecked &&
                  (isOrderChecked || isCustomerChecked || isVendorChecked || isRiderChecked)
                    ? `, ${t('History of Events Widget.Back Office')}`
                    : isRiderChecked && !riderIdV2
                    ? ` ${t('History of Events Widget.Back Office')}`
                    : null}
                </Text>
              ) : !orderId && !vendorId ? (
                <Text className={classes.explanationForApac}>
                  {`${t(
                    'Actions Widget.Actions.New Comment.This comment will be displayed under the following sections',
                  )}: ${t('Customer Widget.Customer')}`}
                </Text>
              ) : null}

              {/* CHECKBOXES */}
              {(showOrderCheckbox && orderId) ||
              (showCustomerCheckbox && customerId) ||
              (showVendorCheckbox && vendorId) ||
              (showRiderCheckbox && riderId) ||
              showBackOfficeCheckbox ? (
                <div className={classes.checkboxesFornonApacHolder}>
                  {/* SELECT MESSAGE -> display it if at least 1 checkbox is available */}
                  <Text className={classes.explanationForNonApac}>
                    {`${t(
                      'Actions Widget.Actions.New Comment.Select where this comment should be displayed',
                    )}:`}
                  </Text>

                  {/* checkbox for ORDER */}
                  {showOrderCheckbox ? (
                    <div className={classes.checkboxesContainer}>
                      <Checkbox
                        onChange={(e) => handleCheckboxClicked(e, 'order')}
                        checked={isOrderChecked}
                      >
                        {`${t('Order Widget.Order')}: `}
                        <Text className={classes.checkboxIds}>{orderId}</Text>
                      </Checkbox>
                    </div>
                  ) : null}

                  {/* checkbox for CUSTOMER */}
                  {showCustomerCheckbox ? (
                    <div className={classes.checkboxesContainer}>
                      <Checkbox
                        onChange={(e) => handleCheckboxClicked(e, 'customer')}
                        checked={isCustomerChecked}
                      >
                        {`${t('Customer Widget.Customer')}: `}
                        <Text className={classes.checkboxIds}>{customerId}</Text>
                      </Checkbox>
                    </div>
                  ) : null}

                  {/* checkbox for VENDOR */}
                  {showVendorCheckbox ? (
                    <div className={classes.checkboxesContainer}>
                      <Checkbox
                        onChange={(e) => handleCheckboxClicked(e, 'vendor')}
                        checked={isVendorChecked}
                      >
                        {`${t('Vendor Widget.Vendor')}: `}
                        <Text className={classes.checkboxIds}>{vendorId}</Text>
                      </Checkbox>
                    </div>
                  ) : null}

                  {/* checkbox for RIDER */}
                  {showRiderCheckbox ? (
                    <div className={classes.checkboxesContainer}>
                      <Checkbox
                        onChange={(e) => handleCheckboxClicked(e, 'rider')}
                        checked={isRiderChecked}
                      >
                        {`${t('Rider Widget.Rider')}: `}
                        <Text className={classes.checkboxIds}>{riderId}</Text>
                      </Checkbox>
                    </div>
                  ) : null}
                </div>
              ) : null}

              {/* checkbox for BackOffice */}
              {showBackOfficeCheckbox ? (
                <div className={classes.checkboxesContainer}>
                  <Checkbox
                    onChange={(e) => handleCheckboxClicked(e, 'backoffice')}
                    checked={isBackOfficeChecked}
                  >
                    {`${t('History of Events Widget.Back Office')}: `}
                    <Text className={classes.checkboxIds}>
                      {t('History of Events Widget.Back Office')}
                    </Text>
                  </Checkbox>
                </div>
              ) : null}

              {/* SAVE button */}
              <div className={classes.saveButtonHolder}>
                <Button
                  type='primary'
                  onClick={handleNewCommentSaveClick}
                  disabled={
                    !newComment ||
                    ((!isOrderChecked || !orderId) &&
                      (!isCustomerChecked || !customerId) &&
                      (!isVendorChecked || !vendorId) &&
                      (!isRiderChecked || !riderId))
                  }
                >
                  {t('Interface.Save')}
                </Button>
              </div>
            </div>
          )
        }}
      </WidgetErrorHandler>
    )
  },

  {
    category: 'comment',
    deriveConfig({ entityConfig }) {
      return entityConfig.fixed_panel_config.widgets_configs.actions.new_comment_config
    },
  },
)
