import React from 'react'
import { Typography } from 'antd'
import { createUseStyles } from 'react-jss'
import { useTranslation } from 'hooks/useTranslation'
import { SingleComment } from 'types/api/commentApi/getOrderComments'
import styles from './Comments.styles'
import { CommentView, commentTagType } from './CommentView'
import { WidgetSize } from 'types/widgets/historyOfEvents/tabs'

const { Text } = Typography

const useStyles = createUseStyles(styles)

type Props = {
  comment: SingleComment
  shouldCommentBeHighlighted: boolean
  mode: WidgetSize
}

const DeliveryAddressComment = ({ comment, shouldCommentBeHighlighted, mode }: Props) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <CommentView
      mode={mode}
      comment={comment}
      shouldCommentBeHighlighted={shouldCommentBeHighlighted}
      tag={{
        type: commentTagType.thunderbolt,
        text: t('History of Events Widget.Event Types.Change Address'),
      }}
    >
      <Text className={classes.commentText}>
        <Text>{` ${t('History of Events Widget.Content.Changed delivery address to')}`}</Text>
        <Text strong>
          {` ${comment.additional_data.modify_delivery_address.new.formatted_address}. `}
        </Text>
        <Text>{`${t('History of Events Widget.Content.Initial delivery address')}: `}</Text>
        <Text strong>
          {`${comment.additional_data.modify_delivery_address.old.formatted_address}.`}
        </Text>
      </Text>
    </CommentView>
  )
}

export default DeliveryAddressComment
