import { ArrowLeftOutlined } from '@ant-design/icons'
import { Input, Select, Space } from 'antd'
import { OrdersList } from 'components/OrdersList/OrdersList'
import { Button } from 'shared/Button'
import { PopupWidgetContainer } from 'shared/PopupWidgetContainer'
import { Text } from 'shared/Text'
import { LAST_ORDERS_WIDGET_MAX_WIDTH, LAST_ORDERS_WIDGET_WIDTH } from 'constants/constants'
import { SearchType, useSearchPanel, UseSearchPanelProps } from 'hooks/search/useSearchPanel'
import { useTranslation } from 'hooks/useTranslation'
import React from 'react'
import ReactCountryFlag from 'react-country-flag'
import { createUseStyles } from 'react-jss'
import { text } from 'theme'
import { ErrorRenderer } from 'types/herocare'
import { getEntityCountryCode } from 'utils/getEntityCountryCode'
import { TabName } from 'types'

export interface SearchPanelProps extends UseSearchPanelProps {
  searchByOptions: Map<SearchType, string>
  ErrorRenderer: ErrorRenderer
  currentOrderId: string

  activeTab: TabName
  showResultsInModal?: boolean
}

const useStyles = createUseStyles({
  searchFieldsContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  searchFieldsSpacer: {
    display: 'flex',
  },
  searchTypeSelector: {
    minWidth: 200,
  },
  searchInput: {
    minWidth: 246,
  },
  searchBtnContainer: {
    marginTop: 16,
  },

  searchError: {
    marginTop: 16,
    maxWidth: 400,
    textAlign: 'center',
  },

  searchedTermHeader: {
    marginBottom: 16,
    display: 'flex',
    alignItems: 'center',
  },

  modal: {
    display: 'flex',
    flexDirection: 'column',
    minWidth: 500,
  },

  titleHolder: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#1295e6',
    padding: 20,
    margin: -24,
  },
})

export const SearchPanel = ({
  searchByOptions,
  lob,
  ErrorRenderer,
  currentOrderId,
  showResultsInModal,
  defaultEntityId,
  defaultSearchType,
  onChangeEvent,
  onSearch,
  activeTab,
  ...others
}: SearchPanelProps) => {
  const { t } = useTranslation()
  const styles = useStyles()

  const {
    availableEntitiesToSearch,
    allowSearchByCustomerPhone,
    selectedEntity,
    onEntityChange,
    loading,
    selectedSearchType,
    onSearchTypeChange,
    searchValue,
    onSearchValueChange,
    handleSubmit,

    errorField,
    errorMessage,

    placeholder,

    showSearchResultsPage,
    onSearchResultsPageClose,

    searchFilters,
  } = useSearchPanel({
    defaultEntityId,
    defaultSearchType,
    onChangeEvent,
    onSearch,
    lob,
  })

  const enabledSearchOptions = Array.from(searchByOptions.keys()).filter((searchType) => {
    if (searchType !== SearchType.CustomerPhoneNumber) {
      return true
    }
    return allowSearchByCustomerPhone
  })

  const showSearchFields = !showSearchResultsPage

  const RenderOrdersList = () => (
    <OrdersList
      filters={searchFilters}
      lob={lob}
      ErrorRenderer={ErrorRenderer}
      entityId={selectedEntity}
      currentOrderId={currentOrderId}
      activeTab={activeTab}
    />
  )

  return (
    <div {...others}>
      {showSearchFields && (
        <div className={styles.searchFieldsContainer}>
          <Space className={styles.searchFieldsSpacer}>
            {/* entity menu */}
            <Select
              disabled={loading}
              data-test-id='entity-selector'
              onSelect={onEntityChange}
              placeholder={t('select_labels.select_search_country')}
              value={selectedEntity || undefined}
              status={errorField === 'entity_selector' ? 'error' : undefined}
              showSearch
            >
              {availableEntitiesToSearch.map((entityId, idx) => {
                const country2LetterCode = getEntityCountryCode(entityId)
                return (
                  <Select.Option value={entityId} key={entityId}>
                    <Space size={4}>
                      <ReactCountryFlag
                        svg
                        countryCode={country2LetterCode || ''}
                        title={entityId}
                        style={{ width: '20px', height: '16px' }}
                      />
                      <span>{country2LetterCode}</span>
                    </Space>
                  </Select.Option>
                )
              })}
            </Select>

            {/* search option */}
            <Select
              disabled={loading}
              data-test-id='search-type-selector'
              onSelect={onSearchTypeChange}
              placeholder={t('select_labels.select_search_type')}
              className={styles.searchTypeSelector}
              value={selectedSearchType || undefined}
              status={errorField === 'search_type_selector' ? 'error' : undefined}
            >
              {enabledSearchOptions.map((searchType, idx) => {
                return (
                  <Select.Option value={searchType} key={searchType}>
                    {t(searchByOptions.get(searchType))}
                  </Select.Option>
                )
              })}
            </Select>

            {/* search input */}
            <Input
              data-test-id='search-input-field'
              placeholder={placeholder}
              allowClear
              value={searchValue}
              disabled={loading}
              onChange={(e) => onSearchValueChange(e.target.value)}
              className={styles.searchInput}
              status={errorField === 'search_input' ? 'error' : undefined}
            />
          </Space>

          {errorMessage && (
            <div className={styles.searchError}>
              <Text.Primary color={text.danger}>{errorMessage}</Text.Primary>
            </div>
          )}

          <div className={styles.searchBtnContainer}>
            <Button type='solid' onClick={handleSubmit} loading={loading} data-test-id='search-btn'>
              {t('Interface.Search')}
            </Button>
          </div>
        </div>
      )}

      {showSearchResultsPage && showResultsInModal && (
        <PopupWidgetContainer
          widgetContainerType='modal'
          width={LAST_ORDERS_WIDGET_WIDTH}
          maxWidth={LAST_ORDERS_WIDGET_MAX_WIDTH}
          title={t('Last Orders Widget.Last Orders')}
          onClose={onSearchResultsPageClose}
          visible
        >
          <RenderOrdersList />
        </PopupWidgetContainer>
      )}

      {showSearchResultsPage && !showResultsInModal && (
        <div>
          <div className={styles.searchedTermHeader}>
            <Space size={8}>
              <Button icon={ArrowLeftOutlined} type='link' onClick={onSearchResultsPageClose} />{' '}
              <Text.Primary>
                {t('widgets.search.search_results_for', {
                  replace: {
                    searchTerm: searchValue,
                  },
                })}
              </Text.Primary>
            </Space>
          </div>

          <RenderOrdersList />
        </div>
      )}
    </div>
  )
}
