export default {
  loadingHolder: {
    marginTop: '25px',
  },
  contentHolder: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  menuItem: {
    fontSize: '13px',
    borderBottom: '1px #F0F0F0 solid',
  },
  dropdownButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    fontSize: '14px',
    color: 'rgba(0, 0, 0, 0.65)',
    width: '70%',
  },
  dropdownIcon: {
    color: 'rgba(0, 0, 0, 0.25)',
  },
  commentsHolder: {
    display: 'flex',
    flexDirection: 'column',
    height: '500px',
    maxHeight: '500px',
    overflow: 'scroll',
  },
  singleCommentHolder: {
    padding: '5px 0px 5px 5px',
    marginTop: '5px',
  },
  singleCommentHolderHighlighted: {
    backgroundColor: '#F6FFED',
    padding: '5px 0px 5px 5px',
    marginTop: '5px',
  },
  commentHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'baseline',
    marginBottom: '5px',
  },
  commentDateText: {
    fontSize: '11px',
    color: 'rgba(0, 0, 0, 0.45)',
  },
  commentText: {
    fontSize: '13px',
    color: 'rgba(0, 0, 0, 0.85)',
  },
  filterContainer: {
    width: '270px',
    backgroundColor: 'rgba(0, 0, 0, 0.09)',
    padding: '0px 8px 8px 8px',
  },
  dropdown: {
    width: '240px',
  },
}
