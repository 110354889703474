export function calculateToppingsPrice(item: any): number {
  const options = item?.options || []
  return options
    .filter((option) => ['bundle_item', 'topping'].includes(option.type))
    .reduce((sum, option) => {
      const unitPrice = option.unit_price || 0
      const quantity = option.quantity || 1
      const total = (unitPrice + calculateToppingsPrice(option)) * quantity
      return sum + total
    }, 0)
}

export function calculateItemCost(item) {
  const unitPrice = item.unit_price || 0
  const quantity = item.quantity || 1
  const unitToppingsPrice = calculateToppingsPrice(item)
  const totalPriceWithToppings = quantity * (unitPrice + unitToppingsPrice)
  return {
    itemPrice: unitPrice,
    toppingsPrice: item.is_bundle ? 0 : unitToppingsPrice,
    totalPrice: totalPriceWithToppings,
  }
}
