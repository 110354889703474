import { SessionContext } from 'contexts/session/SessionContext'
import { useContext } from 'react'

export const useSessionState = () => {
  return useContext(SessionContext).sessionState
}

export const useReadSessionState = () => {
  return useContext(SessionContext).readSession
}
