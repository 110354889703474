export enum ProductTargetApiResponsesT {
  item = 'item',
  bundle_item = 'bundle_item',
  topping = 'topping',
}

export enum UpdateActionApiResponsesT {
  reduce = 'reduce',
  increase = 'increase',
  add = 'add',
  remove = 'remove',
  priceIncrease = 'price-increase',
  priceReduce = 'price-reduce',
  weightIncrease = 'weight-increase',
  weightReduce = 'weight-reduce',
}

export enum ProductTargetTranslationKeys {
  item = 'Item',
  bundle = 'Bundle',
  bundle_item = 'Bundle Item',
  topping = 'Topping',
}

export enum UpdateActionTranslationKeysT {
  reduced = 'Reduced',
  increased = 'Increased',
  added = 'Added',
  removed = 'Removed',
}

export enum ProductSourceT {
  vendor = 'vendor',
  customer = 'customer',
  logistics = 'logistics',
  customer_contact_center = 'customer_contact_center',
  vendor_contact_center = 'vendor_contact_center',
}
