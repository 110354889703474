import React from 'react'
import { PhoneNumbersColumnContent } from 'utils/dataTransformers/transformVendorSummary'
import { List, Button, Collapse } from 'antd'
import { Notification } from 'shared/Notification'
import PhoneNumber from 'components/PhoneNumber/PhoneNumber'
import { unknownDataPoint } from 'utils/dataTransformers/constants'
import { useTranslation } from 'hooks/useTranslation'
import { VendorContactTypes } from 'types/api/vendorApi/vendor'
import { CopyOutlined } from '@ant-design/icons'
import { createUseStyles } from 'react-jss'
import styles from './PhoneNumbers.styles'
import { useCaptureUserAction } from 'hooks/events/useCaptureUserAction'

interface Props {
  isCopyAllowed?: boolean
  phoneNumbers: PhoneNumbersColumnContent
}

const useStyles = createUseStyles(styles)

const PhoneNumbers: React.FC<Props> = ({ phoneNumbers, isCopyAllowed = false }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const captureUserAction = useCaptureUserAction()

  const getPhoneNumberTypeLabel = (key: VendorContactTypes) => {
    let phoneTypeLabel = ''
    switch (key) {
      case VendorContactTypes.Hotline:
        phoneTypeLabel = `${t('Vendor Widget.Hotline')}`
        break
      case VendorContactTypes.Kitchen:
        phoneTypeLabel = `${t('Vendor Widget.Kitchen')}`
        break
      case VendorContactTypes.Manager:
        phoneTypeLabel = `${t('Vendor Widget.Manager')}`
        break
      case VendorContactTypes.Primary:
        phoneTypeLabel = `${t('Vendor Widget.Primary')}`
        break
      case VendorContactTypes.Secondary:
        phoneTypeLabel = `${t('Vendor Widget.Secondary')}`
        break
      default:
        return t('Vendor Widget.Phone')
    }
    return `${phoneTypeLabel} ${t('Vendor Widget.Number')}`
  }

  const handleCopyToClipboard = (item: any) => {
    const success = () => {
      Notification.success({
        message: t('Widgets Common.Number Copied'),
      })
      captureUserAction('VendorSummaryMobileNumberCopyClicked')
    }

    navigator.clipboard.writeText(item.value.toString()).then(
      () =>
        item.value.toString()?.length > 0
          ? success()
          : Notification.warning({ message: t('Widgets Common.Nothing to Copy') }),
      () => Notification.error({ message: t('Widgets Common.Cannot Copy') }),
    )
  }

  if (phoneNumbers.value.length === 1) {
    return (
      <List.Item className={classes.listItem}>
        <List.Item.Meta
          title={
            <React.Fragment>
              <PhoneNumber
                value={phoneNumbers.value[0]}
                onDialClick={() => captureUserAction('VendorWidgetVendorPhoneClicked')}
              />
              {isCopyAllowed && phoneNumbers.value[0] !== unknownDataPoint ? (
                <Button type='link' onClick={() => handleCopyToClipboard(phoneNumbers)}>
                  <CopyOutlined />
                  {t('Widgets Common.Copy')}
                </Button>
              ) : null}
            </React.Fragment>
          }
          description={getPhoneNumberTypeLabel(phoneNumbers.key)}
        />
      </List.Item>
    )
  }

  return (
    <List.Item style={styles.listItem}>
      <List.Item.Meta
        title={
          <Collapse expandIconPosition='end' bordered={false}>
            <Collapse.Panel
              key='1'
              style={styles.collapsePanel}
              header={
                <div style={styles.collapsePanelHeaderContainer}>
                  <PhoneNumber
                    value={phoneNumbers.value[0]}
                    onDialClick={() => captureUserAction('VendorWidgetVendorPhoneClicked')}
                  />
                  <Button
                    type='link'
                    onClick={(e) => {
                      handleCopyToClipboard(phoneNumbers)
                      e.stopPropagation()
                    }}
                  >
                    <CopyOutlined />
                    {t('Widgets Common.Copy')}
                  </Button>
                </div>
              }
            >
              {phoneNumbers.value.length > 1 && (
                <div style={styles.alternativeNumberContainer}>
                  {phoneNumbers.value.slice(1).map((v) => (
                    <div key={v}>
                      <PhoneNumber
                        value={v}
                        onDialClick={() => captureUserAction('VendorWidgetVendorPhoneClicked')}
                      />
                      <Button
                        type='link'
                        onClick={(e) => {
                          handleCopyToClipboard(phoneNumbers)
                          e.stopPropagation()
                        }}
                      >
                        <CopyOutlined />
                        {t('Widgets Common.Copy')}
                      </Button>
                    </div>
                  ))}
                </div>
              )}
            </Collapse.Panel>
          </Collapse>
        }
        description={getPhoneNumberTypeLabel(phoneNumbers.key)}
      />
    </List.Item>
  )
}

export default PhoneNumbers
