/**
 * Full Refund Modal, rendered upon click in actions
 * renders a modal for fully refunding customer
 * */

// libs
import React, { useContext, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react'
// contexts and types
import { DataContext } from 'contexts/data/DataContext'
import { DataAction } from 'contexts/data/types'
import { SessionContext } from 'contexts/session/SessionContext'
import { PartialRefund, RefundCommon, RefundIssueType } from 'contexts/entity/types'
// hooks
import { useTranslation } from 'hooks/useTranslation'
// utils
import { createCompensationStartAndEndDates } from 'utils/createCompensationStartAndEndDates'
import fixNumber from 'utils/fixNumber'
// components
import Refund from './Refund'
import InfoAlertView from 'components/InfoAlertView'
import { useGetCustomerCompensationsAndRefunds } from 'hooks/apiHooks/useGetCustomerCompensationsAndRefunds'
import { WidgetErrorHandler } from 'components/WidgetErrorHandler/widgetErrorHandler'
import { availableRefundMethods } from 'entityConfig/allowedConfigValues'
import { AvailableRefundMethod, RefundBreakdown, RefundTarget } from 'types/actions/partialRefund'
import { useApiService } from 'hooks/useApiService'
import { createVoucher, VoucherPurpose } from 'services/VoucherApi/createVoucher'
import { useClearCommentsFromDataContext } from 'hooks/useClearCommentsFromDataContext'
import { useCopyVoucher } from 'hooks/useCopyVoucher'
import { ORDER_ID_PLACEHOLDER } from 'constants/translation'
import { createRefund } from 'services/paymentApi/createRefund'
import {
  ApiResult,
  apiResultReporter,
} from '../../PartialRefund/PartialRefundModalView/Result/Result'
import BankRefundResponse from './Result/BankRefundResponse'
import OtherRefundResponse from './Result/OtherRefundResponse'
import VoucherRefundResponse from './Result/VoucherRefundResponse'
import { useIsDataPointValid } from 'hooks/useGetValidFeatures'
import { useSendEventToEvts } from 'hooks/events/useSendEventToEvts'
import Issue from '../../RefundCommon/Issue/Issue'
import { Steps } from 'antd'
import { createUseStyles } from 'react-jss'
import styles from './FullRefundModalView.styles'
import {
  GetProposedCompensationApiResponse,
  getProposedCompensationValue,
} from 'services/VoucherApi/getProposedCompensationValue'
import { calculateRefundAmountForVoucherOrAuto } from 'utils/calculateRefundAmountForVoucherOrAuto'
import { EMPTY_CATCH_CALLBACK } from 'constants/constants'
import { getOrderPurchaseDetails } from 'services/paymentApi/getOrderPurchaseDetails'
import calculateRefundAmountForPspAndWallet from 'utils/calculateRefundAmountForPspAndWallet'
import { useForceUpdate } from 'contexts/useForceUpdate'
import { handleOrderPurchaseError, handleOrderPurchaseResponse } from 'utils/handleOrderPurchaseApi'
import { getOrderPaidValue } from 'utils/getters/getOrderPaidValue'
import isCOD from 'utils/order/isCOD'
import { createPluggableWidget } from 'factory/createPluggableWidget'

const useStyles = createUseStyles(styles)

export const FullRefundView = createPluggableWidget<{
  utc_zone: string
  useCcrWhitelistForFullRefundIssues: boolean
  refund_common: RefundCommon
  partial_refund: PartialRefund
}>(
  ({ onQuit, config, ccrCode, sdk }, ref) => {
    // pull translations
    const { t } = useTranslation()

    const forceUpdate = useForceUpdate()

    const classes = useStyles()

    const apiResultsRef = useRef<{ results: ApiResult[]; refundBreakdown: RefundBreakdown[] }>({
      results: [],
      refundBreakdown: [],
    })

    const isDataPointValid = useIsDataPointValid()

    // pull actions config from context
    const {
      utc_zone,
      useCcrWhitelistForFullRefundIssues,
      refund_common: {
        minOrderValue,
        granularity_value,
        compensation_percent,
        min_voucher_value,
        pspAndWalletMinCompensationAmount,
        pspAndWalletCompensationPercent,
        pspAndWalletGranularity,
        autoGranularity,
        autoCompensationPercent,
        minAutoValue,
        isMinOrderValueStatic,
        available_issue_types,
        voucherValidity,
        description_prefix_full,
        voucher_payment_types,
        available_refund_methods,
      },
      partial_refund: { refundAmountPrecision, defaultCompensationIssue },
    } = config

    // pull session context
    const {
      sessionState: { orderId, caseId, globalEntityId, userId },
    } = useContext(SessionContext)

    const sendEventToEvts = useSendEventToEvts()

    // pull currency & vendor from data context
    const {
      dataDispatch,
      dataState: {
        order,
        customer,
        currency,
        customerRefunds,
        customerCompensations,
        customerExploitStatus,
      },
    } = useContext(DataContext)
    const { SET_AUTO_FULL_REFUND_COMMENT } = DataAction

    const { SET_FULLY_REFUNDED_VOUCHER, SET_COMPENSATED_VOUCHER } = DataAction

    const totalDamagedValue = getOrderPaidValue({ order })

    const {
      loadService: executeGetProposedCompensationValue,
      status: getProposedCompensationValueStatus,
      error: getProposedCompensationValueError,
      clearError: clearGetProposedCompensationValueError,
    } = useApiService({
      service: getProposedCompensationValue,
      deps: [],
      autoLoad: false,
    })

    const refundMethods = useMemo(() => {
      const customerPaymentMethod = customer?.payment?.payment_method
      return available_refund_methods.filter((method) => {
        if (!method.active) {
          return false
        }

        const isAllowedPaymentType = method.refundable_payment_types?.length
          ? method.refundable_payment_types?.includes(customerPaymentMethod)
          : true
        return isDataPointValid(method.betaRequirement) && isAllowedPaymentType
      })
    }, [customer, available_refund_methods, isDataPointValid])
    const customerPaymentMethod = order?.customer?.payment?.payment_method

    const [voucherAmount, setVoucherAmount] = useState(0.0)
    const [currentStepIndex, _setCurrentStepIndex] = useState(0)
    const [selectedIssueType, setSelectedIssueType] = useState<RefundIssueType>(null)
    const [selectedRefundMethod, setSelectedRefundMethod] = useState<AvailableRefundMethod>()
    const [totalRefundAmount, setTotalRefundAmount] = useState(0.0)
    const [refundMethodValidationError, setRefundMethodValidationError] = useState('')
    const [voucherMinOrderValue, setVoucherMinOrderValue] = useState(1.0)
    const [voucherMaxOrderValue, setVoucherMaxOrderValue] = useState(0)
    const [additionalNotes, setAdditionalNotes] = useState('')

    const [proposedCompensation, setProposedCompensation] =
      useState<GetProposedCompensationApiResponse>(null)

    // check if entities allows only specific payment types and update state accordingly
    const { voucherPaymentTypes, voucherBodyDescription, voucherBeginDate, voucherEndDate } =
      useMemo(() => {
        let voucherPaymentTypes = t(
          'Customer Widget.Tabs.Vouchers.Voucher Details.All Payment Types',
        )
        const voucherBodyDescription = description_prefix_full || ''
        let voucherBeginDate = ''
        let voucherEndDate = ''

        if (voucher_payment_types?.length > 0) {
          voucherPaymentTypes = voucher_payment_types.map((pt) => pt.display_name).join(', ')
        }

        if (voucherValidity?.value) {
          // validity dates
          const { nowText, laterText } = createCompensationStartAndEndDates(
            voucherValidity.value,
            voucherValidity.unit,
          )
          voucherBeginDate = nowText
          voucherEndDate = laterText
        }

        if (selectedIssueType?.use_auto_comp_proposed_validity_dates && proposedCompensation) {
          voucherEndDate = proposedCompensation.expired_at
        }

        return { voucherPaymentTypes, voucherBodyDescription, voucherBeginDate, voucherEndDate }
      }, [
        voucherValidity,
        proposedCompensation,
        selectedIssueType,
        description_prefix_full,
        voucher_payment_types,
        t,
      ])

    const clearCommentsFromDataContext = useClearCommentsFromDataContext()
    const copyVoucher = useCopyVoucher()

    const {
      loading: loadingCustomerCompensationsAndRefunds,
      error: loadCustomerCompensationsAndRefundsError,
      loadService: retryLoadCustomerCompensationsAndRefunds,
    } = useGetCustomerCompensationsAndRefunds()

    const { loadService: executeGetOrderPurchaseDetails, loading: isValidatingRefundMethod } =
      useApiService({
        service: getOrderPurchaseDetails,
        deps: [],
        autoLoad: false,
      })

    const isUsingTicketAsFallback = useMemo(() => {
      return (
        !isValidatingRefundMethod &&
        refundMethodValidationError &&
        selectedRefundMethod?.useTicketAsFallback
      )
    }, [isValidatingRefundMethod, refundMethodValidationError, selectedRefundMethod])

    const {
      loadService: executeCreateVoucher,
      loading: creatingVoucher,
      clearError: clearCreateVoucherError,
      status: createVoucherStatus,
    } = useApiService({
      service: createVoucher,
      autoLoad: false,
      deps: [],
    })

    const {
      loadService: executeCreateRefund,
      loading: creatingRefund,
      clearError: clearCreateRefundError,
      status: createRefundStatus,
    } = useApiService({
      service: createRefund,
      autoLoad: false,
      deps: [],
    })

    // effect to calculate voucher compensation amount and total amount
    useEffect(() => {
      let calculatedTotalAmount = 0.0
      let calculatedCompAmount = 0.0

      if (proposedCompensation) {
        calculatedTotalAmount = totalDamagedValue
        calculatedCompAmount = proposedCompensation.value
      }
      // reset refund method validation errors
      setRefundMethodValidationError('')

      switch (selectedRefundMethod?.method) {
        case availableRefundMethods.voucher:
          if (!proposedCompensation) {
            const { totalAmountRounded } = calculateRefundAmountForVoucherOrAuto(
              totalDamagedValue,
              compensation_percent,
              granularity_value,
              min_voucher_value,
            )
            calculatedTotalAmount = totalAmountRounded
            calculatedCompAmount = totalAmountRounded
          } else {
            calculatedTotalAmount = totalDamagedValue + proposedCompensation.value
            calculatedCompAmount = totalDamagedValue + proposedCompensation.value
          }
          break
        case availableRefundMethods.bankAccount:
          if (!proposedCompensation) {
            const { totalAmountRounded } = calculateRefundAmountForVoucherOrAuto(
              totalDamagedValue,
              compensation_percent,
              granularity_value,
              min_voucher_value,
            )
            calculatedCompAmount = totalAmountRounded - totalDamagedValue
            calculatedTotalAmount = totalAmountRounded - calculatedCompAmount
          }
          break
        case availableRefundMethods.auto:
          if (!proposedCompensation) {
            const { totalAmountRounded, compAmount } = calculateRefundAmountForVoucherOrAuto(
              totalDamagedValue,
              autoCompensationPercent,
              autoGranularity,
              minAutoValue,
            )
            calculatedTotalAmount = totalAmountRounded
            calculatedCompAmount = compAmount
          } else {
            calculatedTotalAmount = totalDamagedValue + proposedCompensation.value
            calculatedCompAmount = proposedCompensation.value
          }
          break
        case availableRefundMethods.source:
        case availableRefundMethods.wallet:
          if (!proposedCompensation) {
            const { refundAmountWithVoucher, refundAmountToWalletOrPsp } =
              calculateRefundAmountForPspAndWallet(
                totalDamagedValue,
                pspAndWalletCompensationPercent,
                pspAndWalletGranularity,
                pspAndWalletMinCompensationAmount,
              )
            // if method is Wallet or Source -> set reported items' amount as total amount of refund to wallet or psp
            calculatedTotalAmount = refundAmountToWalletOrPsp
            calculatedCompAmount = refundAmountWithVoucher
          }
          executeGetOrderPurchaseDetails({
            entityId: globalEntityId,
            orderId,
            customerId: userId,
          })
            .then(
              handleOrderPurchaseResponse({
                t,
                action: 'fullRefund',
                refundMethod: selectedRefundMethod,
                calculatedTotalAmount,
                setRefundMethodValidationError,
              }),
            )
            .catch(
              handleOrderPurchaseError({
                t,
                refundMethod: selectedRefundMethod,
                setRefundMethodValidationError,
              }),
            )
          break
      }
      setTotalRefundAmount(calculatedTotalAmount)
      setVoucherAmount(calculatedCompAmount)

      // use proposed value if it's enabled else if it's static use config value else calculate dynamic value
      if (selectedIssueType?.use_auto_comp_proposed_min_order_value && proposedCompensation) {
        setVoucherMinOrderValue(Number(fixNumber(proposedCompensation.minimum_order_value)))
      } else if (isMinOrderValueStatic) {
        setVoucherMinOrderValue(Number(minOrderValue ? fixNumber(minOrderValue) : 1.0))
      } else {
        // if MOV is not static, set it to min voucher value + min order val
        setVoucherMinOrderValue(Number(fixNumber(calculatedTotalAmount + minOrderValue)))
      }

      if (selectedIssueType?.use_auto_comp_proposed_max_order_value && proposedCompensation) {
        setVoucherMaxOrderValue(Number(fixNumber(proposedCompensation.maximum_order_value)))
      }
    }, [
      order,
      selectedIssueType,
      autoCompensationPercent,
      autoGranularity,
      minAutoValue,
      granularity_value,
      totalDamagedValue,
      proposedCompensation,
      minOrderValue,
      min_voucher_value,
      compensation_percent,
      selectedRefundMethod,
      isMinOrderValueStatic,
      pspAndWalletCompensationPercent,
      pspAndWalletGranularity,
      pspAndWalletMinCompensationAmount,
      executeGetOrderPurchaseDetails,
      globalEntityId,
      orderId,
      t,
      userId,
      setTotalRefundAmount,
      setVoucherAmount,
      setVoucherMinOrderValue,
    ])

    const refundItems = useMemo(() => {
      const items: Array<string> = []
      // map over items and their toppings; and push them to refundItems their quantity times
      if (order?.order?.items && order?.order?.items.length > 0) {
        order.order.items.forEach((i: any) => {
          if ((i.name || i.display_name) && i.quantity) {
            for (let x = 0; x < i.quantity; x++) {
              items.push(i.display_name ? i.display_name : i.name)
            }
          }

          if (i.options && i.options.length > 0) {
            i.options.forEach((t: any) => {
              if (t.type === 'topping' && t.name && t.quantity) {
                for (let x = 0; x < t.quantity; x++) {
                  items.push(`topping ${t.name} in ${i.display_name ? i.display_name : i.name}`)
                }
              }
            })
          }
        })
      }
      return items
    }, [order])

    const setCurrentStepIndex = (index: number) => {
      switch (index) {
        case 0:
          clearGetProposedCompensationValueError()
          setProposedCompensation(null)
          _setCurrentStepIndex(index)
          break
        case 1:
          if (selectedIssueType?.use_auto_comp_proposed_value && selectedIssueType?.ccr_code) {
            executeGetProposedCompensationValue({
              customerId: userId,
              entityId: globalEntityId,
              orderId,
              totalOrderValue: totalDamagedValue,
              damagedValue: totalDamagedValue,
              issueType: selectedIssueType,
            })
              .then(({ data }) => {
                setProposedCompensation(data)
              })
              .catch(EMPTY_CATCH_CALLBACK)
              .finally(() => _setCurrentStepIndex(index))
          } else {
            _setCurrentStepIndex(index)
          }
      }
    }

    const compensationValue = useMemo(() => {
      switch (selectedRefundMethod?.method) {
        case availableRefundMethods.voucher:
          return voucherAmount - totalDamagedValue
        default:
          return voucherAmount
      }
    }, [voucherAmount, totalDamagedValue, selectedRefundMethod])

    const refundAmountWithoutCompensation = useMemo(() => {
      switch (selectedRefundMethod?.method) {
        case availableRefundMethods.voucher:
          return totalDamagedValue
        default:
          return totalRefundAmount
      }
    }, [totalRefundAmount, totalDamagedValue, selectedRefundMethod])

    // VOUCHER
    const [createdVoucher, setCreatedVoucher] = useState<any>(null)

    // fired when Refund clicked
    const handleRefundClick = async () => {
      let succeeded = false
      let voucherCreated = false
      let voucherSuccessDetails = null

      const postRefundAsync = (target: RefundTarget) => {
        const amount = Number(fixNumber(totalRefundAmount, refundAmountPrecision))
        const paymentMedium =
          target !== RefundTarget.Ticket
            ? customerPaymentMethod
            : isCOD(customerPaymentMethod)
            ? 'Bank Account'
            : customerPaymentMethod

        return executeCreateRefund({
          entityId: globalEntityId,
          orderId,
          currency,
          amount,
          description: `Refund to ${target} for order ${orderId}`,

          refundPurpose: 'order_refund',
          target,
          refundItems,
          contactId: caseId,
          contactReason: selectedIssueType?.issue,
          removedItems: [],
          withItemRemoval: false,
          payment_medium: paymentMedium,
          additional_notes: additionalNotes,
        })
          .then(async ({ data }) => {
            const refundBreakdown = data.refundBreakDown || []
            apiResultsRef.current.refundBreakdown = refundBreakdown

            apiResultReporter.reportRefund(apiResultsRef.current.results, {
              status: 'success',
              refundBreakdown,
              refundTarget: target,
            })
          })
          .catch((ex) => {
            apiResultReporter.reportRefund(apiResultsRef.current.results, {
              status: 'failed',
              errorPayload: ex.errorPayload,
              refundBreakdown: [],
              refundTarget: target,
            })
            forceUpdate()
            // throwing to stop further actions
            throw ex
          })
      }

      const postVoucherAsync = () => {
        let voucherPurpose: VoucherPurpose =
          totalRefundAmount - totalDamagedValue === 0 ? 'refund' : 'refund_compensate'

        if (selectedRefundMethod?.method !== availableRefundMethods.voucher) {
          voucherPurpose = 'compensate'
        }

        // refund items are all order items and toppings
        return executeCreateVoucher({
          customerId: userId,
          entityId: globalEntityId,
          orderId,
          caseId,
          ccrCode: selectedIssueType?.ccr_code,
          contactReason: selectedIssueType?.issue,
          // this field is required to create voucher, so we are using default compensation issue to avoid error
          compensationPurpose: selectedIssueType?.compensation_issue || defaultCompensationIssue,
          refundPurpose: 'order_refund',
          refundItems,

          includedCompensationValue: Number(fixNumber(totalRefundAmount - totalDamagedValue)),

          compensationToken: proposedCompensation?.compensation_token || '',

          voucherDetails: {
            purpose: voucherPurpose,
            channel: 'channel_customer_service',
            beginDate: voucherBeginDate,
            endDate: voucherEndDate,
            minOrderValue: voucherMinOrderValue,
            maxOrderValue: voucherMaxOrderValue,
            value: Number(fixNumber(voucherAmount)),
            currency: currency,
            type: 'amount',
            description: voucherBodyDescription.replace(ORDER_ID_PLACEHOLDER, orderId),
            paymentTypes: voucher_payment_types,
          },

          clientParams: {
            context: 'Refund',
          },
        })
          .then(({ data }) => {
            voucherCreated = true
            setCreatedVoucher(data)
            voucherSuccessDetails = data
            apiResultReporter.reportVoucherCreation(apiResultsRef.current.results, {
              status: 'success',
              details: {
                voucherCode: data.code,
                currency,
                refundAmount: voucherAmount,
              },

              copyDetailsHandler() {
                copyVoucher(data, currency)
              },
            })

            dataDispatch({
              type: SET_AUTO_FULL_REFUND_COMMENT,
              payload: { autoFullRefundComment: data },
            })
            clearCommentsFromDataContext()
          })
          .catch((ex) => {
            apiResultReporter.reportVoucherCreation(apiResultsRef.current.results, {
              status: 'failed',
              errorPayload: ex.errorPayload,
            })

            forceUpdate()
            return Promise.reject(ex)
          })
      }

      apiResultsRef.current.results = []
      apiResultsRef.current.refundBreakdown = []

      try {
        if (isUsingTicketAsFallback) {
          await postRefundAsync(RefundTarget.Ticket)
          succeeded = true
          if (voucherAmount) {
            await postVoucherAsync()
          }
        } else {
          switch (selectedRefundMethod?.method) {
            case availableRefundMethods.voucher:
              await postVoucherAsync()
              succeeded = true
              break
            case availableRefundMethods.wallet:
            case availableRefundMethods.auto:
            case availableRefundMethods.source:
            case availableRefundMethods.bankAccount:
              const target =
                selectedRefundMethod?.method === availableRefundMethods.bankAccount
                  ? RefundTarget.Ticket
                  : (selectedRefundMethod?.method.toUpperCase() as RefundTarget)
              await postRefundAsync(target)
              succeeded = true
              if (voucherAmount) {
                await postVoucherAsync()
              }
              break
          }
        }
      } catch (e) {
        // do nothing
      }

      if (succeeded) {
        sdk.eventEmitter.dispatchEvent({
          name: 'FULL_REFUND_SUCCESS',
          payload: { orderId },
        })
      }

      let refundValue = refundAmountWithoutCompensation
      let paymentMedium: string = selectedRefundMethod.method
      let refundDetails = null

      const refundBreakdown = apiResultsRef.current.refundBreakdown
      const isSelectedMethodNotVoucher =
        selectedRefundMethod?.method !== availableRefundMethods.voucher

      if (refundBreakdown.length) {
        paymentMedium = refundBreakdown.length > 1 ? 'MIXED' : refundBreakdown[0].method
        refundValue = refundBreakdown.reduce((result, current) => {
          return result + current.amount.amount
        }, 0)

        refundDetails = refundBreakdown.reduce((result, current) => {
          result[current.method] = current.amount.amount
          return result
        }, {})
      }

      // report event to ets
      sendEventToEvts('ActionsFullRefundDone', {
        eventDetails: {
          actionFailed: !succeeded,
          issue_type: selectedIssueType?.issue,
        },
        contactDetails: {
          customer_exploit_status: customerExploitStatus,
        },
        financial: {
          paymentMedium,
          currencyCode: currency,
          compensationValue: Number(fixNumber(compensationValue, refundAmountPrecision)),
          refundValue: Number(fixNumber(refundValue, refundAmountPrecision)),
          details: {
            proposed_value: proposedCompensation?.value,
            trigger_id: proposedCompensation?.trigger_id,
            voucher_code: voucherSuccessDetails?.code,
            voucherCreated: isSelectedMethodNotVoucher && voucherCreated,

            refund_details: refundDetails,
          },
        },
      })
    }

    const apiResults = apiResultsRef.current.results

    const isSuccess = createRefundStatus === 'success' || createVoucherStatus === 'success'

    useImperativeHandle(
      ref,
      () => {
        return {
          onXButtonClick: () => {
            return selectedIssueType === null || isSuccess
          },

          onBeforeClose: () => {
            if (createdVoucher) {
              dataDispatch({
                type: SET_FULLY_REFUNDED_VOUCHER,
                payload: { fullyRefundedVoucher: createdVoucher },
              })
              dataDispatch({
                type: SET_COMPENSATED_VOUCHER,
                payload: { compensatedVoucher: undefined },
              })
            }
          },
        }
      },
      [
        selectedIssueType,
        dataDispatch,
        createdVoucher,
        SET_COMPENSATED_VOUCHER,
        SET_FULLY_REFUNDED_VOUCHER,
        isSuccess,
      ],
    )

    return (
      <WidgetErrorHandler
        errorPayload={apiResults[0]?.errorPayload}
        displayType='overlay'
        onQuit={onQuit}
        onBack={() => {
          clearCreateRefundError()
          clearCreateVoucherError()
          apiResultsRef.current = {
            refundBreakdown: [],
            results: [],
          }
          forceUpdate()
        }}
        onRetry={handleRefundClick}
        loading={{
          [t('Interface.Loading customer previous compensations and refunds')]:
            loadingCustomerCompensationsAndRefunds,
          [t('Interface.Getting proposed voucher value from autocomp')]:
            getProposedCompensationValueStatus === 'loading',
          [t('Interface.Creating refund case')]:
            creatingRefund &&
            (selectedRefundMethod?.method === 'BANK_ACCOUNT' || isUsingTicketAsFallback),
          [t('Interface.Creating refund')]:
            creatingVoucher || (creatingRefund && selectedRefundMethod?.method !== 'BANK_ACCOUNT'),
        }}
      >
        {() => {
          if (createRefundStatus === 'success' && isUsingTicketAsFallback) {
            return (
              <BankRefundResponse
                onClose={onQuit}
                currency={currency}
                copyVoucher={copyVoucher}
                createdVoucher={createdVoucher}
                voucherAmount={voucherAmount}
                totalRefundAmount={totalRefundAmount}
              />
            )
          }

          if (
            createVoucherStatus === 'success' &&
            selectedRefundMethod.method === availableRefundMethods.voucher
          ) {
            return (
              <VoucherRefundResponse
                onClose={onQuit}
                currency={currency}
                copyVoucher={copyVoucher}
                voucherAmount={voucherAmount}
                createdVoucher={createdVoucher}
              />
            )
          }

          if (
            createVoucherStatus === 'success' &&
            selectedRefundMethod.method === availableRefundMethods.bankAccount
          ) {
            return (
              <BankRefundResponse
                onClose={onQuit}
                currency={currency}
                copyVoucher={copyVoucher}
                createdVoucher={createdVoucher}
                voucherAmount={voucherAmount}
                totalRefundAmount={totalRefundAmount}
              />
            )
          }

          if (createRefundStatus === 'success') {
            return (
              <OtherRefundResponse
                onClose={onQuit}
                currency={currency}
                copyVoucher={copyVoucher}
                createdVoucher={createdVoucher}
                voucherAmount={voucherAmount}
                refundMethod={selectedRefundMethod.method}
                totalRefundAmount={totalRefundAmount}
              />
            )
          }

          return (
            <>
              {/* handle errors relating to getProposedVoucherValue */}
              <WidgetErrorHandler
                errorPayload={getProposedCompensationValueError?.errorPayload}
                displayType={'mini'}
              />
              {/* show errors related to loading of customer previous compensations and refunds */}
              <WidgetErrorHandler
                errorPayload={loadCustomerCompensationsAndRefundsError?.errorPayload}
                displayType='mini'
                onRetry={retryLoadCustomerCompensationsAndRefunds}
              />

              {/* Banner */}
              {((customerCompensations && customerCompensations.length > 0) ||
                (customerRefunds && customerRefunds.length > 0)) && (
                <InfoAlertView
                  utcZone={utc_zone}
                  compensations={customerCompensations ? customerCompensations : null}
                  refunds={customerRefunds ? customerRefunds : null}
                  parent='FullRefund'
                />
              )}

              <div className={classes.stepsContainer}>
                <Steps current={currentStepIndex}>
                  <Steps.Step
                    title={t('Actions Widget.Actions.Partial Refund.Issue')}
                    description={t(
                      selectedIssueType
                        ? `Actions Widget.Actions.Compensation.Issue Types.${selectedIssueType.issue_translation_key}`
                        : ``,
                    )}
                  />
                  <Steps.Step
                    title={t('Actions Widget.Actions.Partial Refund.Refund')}
                    description={
                      selectedRefundMethod?.method === availableRefundMethods.noRefund ? (
                        <span style={{ textDecoration: 'line-through' }}>
                          ${currency} {fixNumber(totalRefundAmount, refundAmountPrecision)}
                        </span>
                      ) : (
                        `${currency} ${fixNumber(totalRefundAmount, refundAmountPrecision)}`
                      )
                    }
                  />
                </Steps>
              </div>

              {currentStepIndex === 0 ? (
                <Issue
                  actionName={'FullRefund'}
                  useCcrWhitelistForIssues={useCcrWhitelistForFullRefundIssues}
                  setCurrentStepIndex={setCurrentStepIndex}
                  issueTypes={available_issue_types}
                  selectedIssueType={selectedIssueType}
                  setSelectedIssueType={setSelectedIssueType}
                />
              ) : null}

              {currentStepIndex === 1 ? (
                <Refund
                  useTicketAsFallback={isUsingTicketAsFallback}
                  totalDamagedValue={totalDamagedValue}
                  refundMethods={refundMethods}
                  voucherAmount={voucherAmount}
                  setCurrentStepIndex={setCurrentStepIndex}
                  additionalNotes={additionalNotes}
                  customerPaymentMethod={customerPaymentMethod}
                  setAdditionalNotes={setAdditionalNotes}
                  setVoucherAmount={setVoucherAmount}
                  selectedRefundMethod={selectedRefundMethod}
                  setSelectedRefundMethod={setSelectedRefundMethod}
                  handleRefundClick={handleRefundClick}
                  setTotalRefundAmount={setTotalRefundAmount}
                  totalRefundAmount={totalRefundAmount}
                  voucherBeginDate={voucherBeginDate}
                  voucherEndDate={voucherEndDate}
                  voucherMinOrderValue={voucherMinOrderValue}
                  setVoucherMinOrderValue={setVoucherMinOrderValue}
                  voucherPaymentTypes={voucherPaymentTypes}
                  isValidatingRefundMethod={isValidatingRefundMethod}
                  refundMethodValidationError={refundMethodValidationError}
                />
              ) : null}
            </>
          )
        }}
      </WidgetErrorHandler>
    )
  },
  {
    category: 'action',
    deriveConfig({ entityConfig }) {
      return {
        utc_zone: entityConfig.utc_zone,
        useCcrWhitelistForFullRefundIssues: entityConfig.useCcrWhitelistForFullRefundIssues,
        refund_common: entityConfig.fixed_panel_config.widgets_configs.actions.refund_common,
        partial_refund: entityConfig.fixed_panel_config.widgets_configs.actions.partial_refund,
      }
    },
  },
)
