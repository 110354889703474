/**
 * Component to show a single change among the set of basket update.
 */
import React, { useCallback, useMemo, useState } from 'react'
import { Col, InputNumber, Row } from 'antd'
import Tree, { DataNode } from 'antd/lib/tree'
import { useTranslation } from 'hooks/useTranslation'
import { createUseStyles } from 'react-jss'
import { text } from 'theme'
import { Button, Popconfirm, SmartToolTip, Text } from 'shared'
import styles from './SingleChangeView.styles'
import { SDK } from 'types'
import { BasketUpdateTransformedChange } from 'hooks/useTransformBasketUpdatesV2'
import { ProductToRestore } from 'services/ordersApi/restoreOrderItems'

const useStyles = createUseStyles(styles)

type Props = {
  change: BasketUpdateTransformedChange
  currency: string
  sdk: SDK
  allowRestore?: boolean
  onRestore?: (item: ProductToRestore) => void
  onRestoreClick?: () => void
}

const restoreTitle = (target: string) => {
  if (target === 'item') {
    return 'Order Widget.Tabs.Basket Updates.Actions.Restore this item to the basket?'
  }
  if (target === 'topping') {
    return 'Order Widget.Tabs.Basket Updates.Actions.Restore this topping to the basket?'
  }
}

const ItemQuantityInput = ({
  maxQuantity,
  onChange,
}: {
  maxQuantity: number
  onChange?: (value: number) => void
}) => {
  const { t } = useTranslation()
  const classes = useStyles()

  const inputRef = useCallback((inputElement: HTMLInputElement) => {
    if (inputElement) {
      inputElement.focus({ preventScroll: true })
    }
  }, [])

  return (
    <InputNumber
      className={classes.restoreNumberInput}
      onChange={(value) => onChange?.(value)}
      ref={inputRef}
      min={0}
      max={maxQuantity}
      defaultValue={maxQuantity}
      addonAfter={<span>{t('out of {{maxQuantity}}', { replace: { maxQuantity } })}</span>}
    />
  )
}

const SingleChangeView: React.FC<Props> = ({
  change,
  currency,
  sdk,
  allowRestore,
  onRestore,
  onRestoreClick,
}) => {
  const classes = useStyles()
  const {
    lineItemId,
    name,
    target,
    targetAction,
    item_data,
    partialText,
    targetPriceChange,
    maxRestorableQuantity,
    quantityInBasket,
    unitPrice,
  } = change ?? {}
  const { t } = useTranslation()

  const [restorableQuantity, setRestorableQuantity] = useState<number>(maxRestorableQuantity || 0)
  const showQuantityInput = Boolean(maxRestorableQuantity)

  const toppingsIds = item_data?.toppings?.map((topping) => topping.id)

  //Construct tree node in case of item with toppings.
  const treeItemWithToppings: DataNode[] = useMemo(() => {
    if (target === 'item' && item_data?.toppings?.length) {
      return [
        {
          title: name,
          key: 'parent',
          switcherIcon: <></>,
          isLeaf: true,
          children: item_data.toppings.map((topping, idx) => {
            return {
              key: `leaf${idx}`,
              title: topping?.name,
              switcherIcon: <></>,
              selectable: false,
            }
          }),
        },
      ]
    }
    return []
  }, [item_data, name, target])

  return (
    <Row justify='space-between' gutter={[8, 8]}>
      <Col flex={'200px'}>
        <Row>
          <Text.Primary color={text.primary}>{targetAction}</Text.Primary>
        </Row>
        {/* Show additional data in case action is reduce or increase */}
        {partialText && (
          <Row>
            <Text.Secondary color={text.secondary}>{partialText}</Text.Secondary>
          </Row>
        )}
      </Col>
      <Col flex={'auto'}>
        <Row>
          {treeItemWithToppings?.length ? (
            // Item with toppings
            <Tree
              checkable={false}
              selectable={false}
              defaultExpandAll={true}
              showLine={true}
              treeData={treeItemWithToppings}
              switcherIcon={<></>}
              className={classes.treeParent}
            />
          ) : target === 'item' ? (
            // Item with no topping
            <Text.Primary pl={'4px'} className='test-aks' color={text.primary}>
              {name}
            </Text.Primary>
          ) : (
            // Topping. Show topping and below it the item it belongs to.
            <Row>
              <Col span={24}>
                <Text.Primary pl={'4px'} color={text.primary}>
                  {name}
                </Text.Primary>
              </Col>
              <Col span={24}>
                <Text.Secondary pl={'4px'}>
                  {t('Order Widget.Tabs.Basket Updates.Item')}: {item_data?.name}
                </Text.Secondary>
              </Col>
            </Row>
          )}
        </Row>
      </Col>
      <Col>
        <Row align='middle' justify='end'>
          <Col>
            <Text.Secondary color={text.secondary}>{currency}</Text.Secondary>
          </Col>

          <Col>
            <SmartToolTip
              title={
                Boolean(unitPrice) &&
                unitPrice !== targetPriceChange &&
                t('Item price {{currency}} {{unitPrice}}', { replace: { currency, unitPrice } })
              }
            >
              <Text.Primary color={text.primary} pl={'4px'}>
                {sdk.numberFormatter.formatDigit(targetPriceChange)}
              </Text.Primary>
            </SmartToolTip>
          </Col>
        </Row>
        {allowRestore ? (
          <Row align='middle' justify='end'>
            <Popconfirm
              disableConfirm={showQuantityInput && restorableQuantity === 0}
              icon={null}
              placement='topLeft'
              title={
                <>
                  <p className={classes.popconfirmTitle}>{t(restoreTitle(target))}</p>
                  {showQuantityInput && (
                    <ItemQuantityInput
                      onChange={setRestorableQuantity}
                      maxQuantity={maxRestorableQuantity}
                    />
                  )}
                </>
              }
              onConfirm={() =>
                onRestore({
                  line_item_uuid: lineItemId,
                  quantity: restorableQuantity + quantityInBasket,
                  restore_toppings: {
                    apply: Boolean(toppingsIds?.length),
                    topping_ids: toppingsIds,
                  },
                })
              }
            >
              <Button
                type={'link'}
                size='small'
                onClick={onRestoreClick}
                disabled={!maxRestorableQuantity}
              >
                {t('Order Widget.Tabs.Basket Updates.Actions.Restore')}
              </Button>
            </Popconfirm>
          </Row>
        ) : null}
      </Col>
    </Row>
  )
}

export default SingleChangeView
