// libs
import React, { useState, useEffect, useContext } from 'react'
// contexts and types
import { EntityContext } from 'contexts/entity/EntityContext'
import { RiderServiceContext } from 'contexts/riderService/RiderServiceContext'
import OrderLink from 'types/riderService/orderLink'
import OrderDataPointIdentifiers from 'types/riderService/orderDataPointIdentifiers'
// hooks
import { useTranslation } from 'hooks/useTranslation'
import useDataPointAvailabilityCheck from 'hooks/useDataPointAvailabilityCheck'
// utils
import checkDataPointAvailability from 'utils/checkDataPointAvailability'
// styles
import { createUseStyles } from 'react-jss'
import styles from './Content.styles'
import { Divider, Button, Spin, Typography } from 'antd'
import { CopyOutlined } from '@ant-design/icons'
// components
import TextBlockView from 'components/TextBlockView'
import TagsView from 'components/TagsView'
import MenuView from 'components/MenuView'
import { Notification } from 'shared/Notification'
import ChatTranscript from './ChatTranscript'
import { useIsDataPointValid } from 'hooks/useGetValidFeatures'
import { useCaptureUserAction } from 'hooks/events/useCaptureUserAction'

const useStyles = createUseStyles(styles)

interface Props {
  isLoadingStatus: boolean
  isLoadingFulfillment: boolean
}

const Content: React.FC<Props> = ({ isLoadingStatus, isLoadingFulfillment }) => {
  const classes = useStyles()
  const { Text } = Typography

  // pull translations
  const { t } = useTranslation()

  // pull data state and dispatch from data context
  const {
    riderServiceState: { order, orderStatus, orderFulfillment, riderCustomerChatTranscript },
  } = useContext(RiderServiceContext)

  const captureUserAction = useCaptureUserAction()

  // pull entity context
  const { entityState } = useContext(EntityContext)
  const isDataPointValid = useIsDataPointValid()

  const [availableOrderTags, setAvailableOrderTags] = useState<string[]>([])
  useEffect(() => {
    const tags: string[] = []

    // corporate tag checks
    const corporateTagConfig = entityState.entityConfig.miniOrder.corporate_tag
    const isCorporateTagAllowed = isDataPointValid(corporateTagConfig.betaRequirement)
    if (isCorporateTagAllowed) {
      const isCorporateDataPoint = checkDataPointAvailability(
        corporateTagConfig.displayRule,
        order?.orderTags?.corporate ? true : false,
      )

      if (isCorporateDataPoint) {
        tags.push(order.orderTags.corporate)
      }
    }

    // preorder tag checks
    const preorderTagConfig = entityState.entityConfig.miniOrder.preorder_tag
    const isPreorderTagAllowed = isDataPointValid(preorderTagConfig.betaRequirement)
    if (isPreorderTagAllowed) {
      const isPreorderDataPoint = checkDataPointAvailability(
        preorderTagConfig.displayRule,
        order?.orderTags?.preOrder && order?.orderTags?.preOrder !== null ? true : false,
      )
      if (isPreorderDataPoint) {
        tags.push(order.orderTags.preOrder)
      }
    }

    setAvailableOrderTags(tags)
  }, [order, entityState, isDataPointValid])

  const [availableOrderLinks, setAvailableOrderLinks] = useState<OrderLink[]>([])
  useEffect(() => {
    const links: OrderLink[] = []

    // hurrier link checks
    const hurrierLinkConfig = entityState.entityConfig.miniOrder.link_to_hurrier
    const isHurrierAllowed = isDataPointValid(hurrierLinkConfig.betaRequirement)
    if (isHurrierAllowed) {
      const isHurrierDataPoint = checkDataPointAvailability(
        hurrierLinkConfig.displayRule,
        hurrierLinkConfig?.linkToSource ? true : false,
      )
      if (isHurrierDataPoint) {
        links.push({
          link: `${hurrierLinkConfig.linkToSource}${order.orderId}`,
          linkText: 'Hurrier',
        })
      }
    }

    // backend link checks
    const backendLinkConfig = entityState.entityConfig.miniOrder.link_to_backend
    const isBackendAllowed = isDataPointValid(backendLinkConfig.betaRequirement)
    if (isBackendAllowed) {
      const isBackendDataPoint = checkDataPointAvailability(
        backendLinkConfig.displayRule,
        backendLinkConfig?.linkToSource ? true : false,
      )
      if (isBackendDataPoint) {
        links.push({
          link: `${backendLinkConfig.linkToSource}${order.orderId}`,
          linkText: 'Backend',
        })
      }
    }

    setAvailableOrderLinks(links)
  }, [order, entityState, isDataPointValid])

  const [mostRecentOrderStatusHistory, setMostRecentOrderStatusHistory] = useState<
    Array<{
      status: string
      timestamp: string
    }>
  >([])
  useEffect(() => {
    if (orderStatus?.orderStatus?.length > 0) {
      return setMostRecentOrderStatusHistory(orderStatus.orderStatus)
    }

    if (order?.orderStatus?.length > 0) {
      return setMostRecentOrderStatusHistory(order.orderStatus)
    }
  }, [order, orderStatus])

  const [isRiderChatTranscript, setIsRiderChatTranscript] = useState(null)
  useEffect(() => {
    const riderChatTranscriptConfig = entityState.entityConfig.miniOrder.riderChatTranscript

    let isRiderChatTranscriptAllowed = false
    if (riderChatTranscriptConfig) {
      const isChatTranscriptBetaGranted = isDataPointValid(
        riderChatTranscriptConfig.betaRequirement,
      )

      if (isChatTranscriptBetaGranted) {
        isRiderChatTranscriptAllowed = checkDataPointAvailability(
          riderChatTranscriptConfig.displayRule,
          riderCustomerChatTranscript ? true : false,
        )
      }
    }

    setIsRiderChatTranscript(isRiderChatTranscriptAllowed)
  }, [isDataPointValid, entityState, riderCustomerChatTranscript])

  const handleCopyToClipboard = (item: string) => {
    captureUserAction('OrderSummaryOrderNumberCopyClicked')
    const success = () => Notification.success({ message: t('Messages.Copied') })
    const warning = () => Notification.warning({ message: t('Widgets Common.Cannot Copy') })

    navigator.clipboard.writeText(item.toString()).then(
      () => success(),
      () => warning(),
    )
  }

  // check data point availability
  const isCopyToClipboard = useDataPointAvailabilityCheck(
    entityState.entityConfig.miniOrder.copy_to_clipboard,
    true,
    [entityState],
  )

  const isPaymentMethod = useDataPointAvailabilityCheck(
    entityState.entityConfig.miniOrder.payment_method,
    order?.paymentMethod ? true : false,
    [entityState, order],
  )

  const isDeliveryInst = useDataPointAvailabilityCheck(
    entityState.entityConfig.miniOrder.delivery_inst,
    order?.deliveryInstructions ? true : false,
    [entityState, order],
  )

  const isOrderStatus = useDataPointAvailabilityCheck(
    entityState.entityConfig.miniOrder.order_status,
    order?.orderStatus ? true : false,
    [entityState, order],
  )

  const isRiderEvents = useDataPointAvailabilityCheck(
    entityState.entityConfig.miniOrder.rider_events,
    orderFulfillment?.riderEvents ? true : false,
    [entityState, order],
  )

  const isItems = useDataPointAvailabilityCheck(
    entityState.entityConfig.miniOrder.items,
    order?.items ? true : false,
    [entityState, order],
  )

  const isTotalPrice = useDataPointAvailabilityCheck(
    entityState.entityConfig.miniOrder.total_price,
    order?.totalPrice ? true : false,
    [entityState, order],
  )

  return (
    <div className={classes.orderBody}>
      {/* order ID */}
      {order?.orderId && (
        <React.Fragment>
          <div className={classes.orderIdHolder}>
            <div>
              <TextBlockView
                label={t('Order Widget.Order ID')}
                data={order.orderId}
                identifier={OrderDataPointIdentifiers.order_id}
              />
            </div>

            {isCopyToClipboard ? (
              <Button type='link' size='large' onClick={() => handleCopyToClipboard(order.orderId)}>
                <CopyOutlined />
              </Button>
            ) : null}
          </div>
          <Divider className={classes.idDivider} />
        </React.Fragment>
      )}

      {/* Order Tags */}
      {availableOrderTags && availableOrderTags.length > 0 ? (
        <React.Fragment>
          <TagsView label={t('Order Widget.Order Tags')} tags={availableOrderTags} />
          <Divider className={classes.divider} />
        </React.Fragment>
      ) : null}

      {/* order payment method */}
      {order?.paymentMethod && isPaymentMethod ? (
        <React.Fragment>
          <TextBlockView
            label={t('Order Widget.Tabs.Summary.Payment Method')}
            data={order.paymentMethod}
            identifier={OrderDataPointIdentifiers.payment_method}
          />
          <Divider className={classes.divider} />
        </React.Fragment>
      ) : null}

      {/* delivery inst */}
      {order?.deliveryInstructions && isDeliveryInst ? (
        <React.Fragment>
          <TextBlockView
            label={t('Order Widget.Tabs.Summary.Delivery Instructions')}
            data={order.deliveryInstructions}
            identifier={OrderDataPointIdentifiers.delivery_instructions}
          />
          <Divider className={classes.deliveryInstDivider} />
        </React.Fragment>
      ) : null}

      {/*  order status history */}
      {mostRecentOrderStatusHistory?.length > 0 && isOrderStatus ? (
        <React.Fragment>
          {isLoadingStatus ? (
            <div className={classes.spinnerHolder}>
              <Spin className={classes.spinner} size='small' />
              <Text className={classes.loadingText}>{`${t(
                'Interface.Loading Order Status',
              )}...`}</Text>
              <Divider className={classes.statusDivider} />
            </div>
          ) : (
            <React.Fragment>
              <MenuView
                label={t('Order Widget.Order Status')}
                dropwdownContent={mostRecentOrderStatusHistory}
              />
              <Divider className={classes.statusDivider} />
            </React.Fragment>
          )}
        </React.Fragment>
      ) : null}

      {/* rider events */}
      {orderFulfillment?.riderEvents?.length > 0 && isRiderEvents ? (
        <React.Fragment>
          {isLoadingFulfillment ? (
            <div className={classes.spinnerHolder}>
              <Spin className={classes.spinner} size='small' />
              <Text className={classes.loadingText}>{`${t(
                'Interface.Loading Rider Events',
              )}...`}</Text>
              <Divider className={classes.riderEventsDivider} />
            </div>
          ) : (
            <React.Fragment>
              <MenuView
                label={t('Rider Widget.Rider Events')}
                dropwdownContent={orderFulfillment.riderEvents}
              />
              <Divider className={classes.riderEventsDivider} />
            </React.Fragment>
          )}
        </React.Fragment>
      ) : null}

      {/* item count */}
      {order?.items.toString() && isItems ? (
        <React.Fragment>
          <TextBlockView
            label={t('Order Widget.Tabs.Items.Title')}
            data={order.items}
            identifier={OrderDataPointIdentifiers.items}
          />
          <Divider className={classes.divider} />
        </React.Fragment>
      ) : null}

      {/* total price */}
      {order?.totalPrice.toString() && order?.currency && isTotalPrice ? (
        <React.Fragment>
          <TextBlockView
            label={t('Order Widget.Tabs.Items.Total Price')}
            data={`${order.currency} ${order.totalPrice}`}
            identifier={OrderDataPointIdentifiers.total_price}
          />
          <Divider className={classes.divider} />
        </React.Fragment>
      ) : null}

      {/* chat transcript */}
      {isRiderChatTranscript && (
        <React.Fragment>
          <ChatTranscript />
          <Divider className={classes.divider} />
        </React.Fragment>
      )}

      {/* Links to open order */}
      {availableOrderLinks && availableOrderLinks.length > 0 && (
        <TextBlockView
          label={t('Order Widget.Open Order In')}
          links={availableOrderLinks}
          identifier={OrderDataPointIdentifiers.open_order_in}
        />
      )}
    </div>
  )
}

export default Content
