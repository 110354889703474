import { useMemo } from 'react'
import moment from 'moment-timezone'
import { useSessionState } from 'hooks/useSessionState'

interface FormatOptions {
  utcZone?: string
  format?: string
}

const DEFAULT_TIME_FORMAT = 'h:mm A'
const DEFAULT_DATE_FORMAT = 'D.M.YYYY'

const DEFAULT_DATETIME_FORMAT = 'D.M.YYYY, h:mm A'

export const useDateTimeFormatter = () => {
  const { utcZone: defaultUtcZone } = useSessionState()

  return useMemo(() => {
    return {
      formatTime(time: string | number | Date, opts?: FormatOptions) {
        if (!time) {
          return ''
        }
        const utcZone: string = opts?.utcZone || defaultUtcZone
        return moment(time)
          .tz(utcZone)
          .format(opts?.format || DEFAULT_TIME_FORMAT)
      },

      formatDate(time: string | number | Date, opts?: FormatOptions) {
        if (!time) {
          return ''
        }
        const utcZone: string = opts?.utcZone || defaultUtcZone
        return moment(time)
          .tz(utcZone)
          .format(opts?.format || DEFAULT_DATE_FORMAT)
      },

      formatDateTime(time: string | number | Date, opts?: FormatOptions) {
        if (!time) {
          return ''
        }
        const utcZone: string = opts?.utcZone || defaultUtcZone
        return moment(time)
          .tz(utcZone)
          .format(opts?.format || DEFAULT_DATETIME_FORMAT)
      },

      /**
       * returns the milliseconds difference between two given times
       * @param time1
       * @param time2
       * @param opts
       */
      getTimeDifference(time1: string, time2?: string): number {
        time2 = time2 || new Date().toISOString()
        return moment(time2).diff(time1, 'milliseconds')
      },
    }
  }, [defaultUtcZone])
}
