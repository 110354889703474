/**
 * BasketUpdates pulls order updates from data context and renders them through BasketUpdatesView
 * */

// libs
import React, { useEffect, useContext } from 'react'
// contexts and types
import { DataContext } from 'contexts/data/DataContext'
import { OrderSingleBasketUpdate } from 'types/api/orderApi/orderBasketUpdate'
import { UpdateActionApiResponsesT } from 'types/widgets/order/orderBasketUpdates'
// hooks
import { useTranslation } from 'hooks/useTranslation'
// utils
// styles
import { createUseStyles } from 'react-jss'
import styles from './BasketUpdates.styles'
import { Result, Timeline } from 'antd'
import { ShoppingCartOutlined } from '@ant-design/icons'
// components
import BasketUpdatesView from './BasketUpdatesView'
import { useCaptureUserAction } from 'hooks/events/useCaptureUserAction'

const useStyles = createUseStyles(styles)

const BasketUpdates: React.FC = () => {
  const classes = useStyles()

  // pull translations
  const { t } = useTranslation()
  const captureUserAction = useCaptureUserAction()

  // pull orderBasketUpdates from data context
  const {
    dataState: { orderBasketUpdates },
  } = useContext(DataContext)

  // track the tab click
  useEffect(() => {
    // tracking event details
    let numberOfChanges = 0
    let numberOfChangeSets = 0
    let containsAdd = 0
    let containsRemove = 0
    let containsIncrease = 0
    let containsDecrease = 0

    if (orderBasketUpdates?.length) {
      // set number of changes and change sets
      numberOfChanges = orderBasketUpdates.length
      numberOfChangeSets = orderBasketUpdates.reduce((acc: number, cv: OrderSingleBasketUpdate) => {
        if (cv?.products?.length) {
          acc = acc + cv.products.length

          // figure out which action is observed how many times
          for (const product of cv.products) {
            switch (product.action) {
              case UpdateActionApiResponsesT.add:
                containsAdd++
                break
              case UpdateActionApiResponsesT.remove:
                containsRemove++
                break
              case UpdateActionApiResponsesT.increase:
                containsIncrease++
                break
              case UpdateActionApiResponsesT.reduce:
                containsDecrease++
                break
              default:
                break
            }
          }
        }

        return acc
      }, 0)
    }

    // track event
    captureUserAction('OrderTabBasketUpdatesClicked', {
      eventDetails: {
        numberOfChanges,
        numberOfChangeSets,
        containsAdd,
        containsRemove,
        containsIncrease,
        containsDecrease,
      },
    })
    // eslint-disable-next-line
  }, [])

  return (
    <div className={classes.basketUpdateContainer}>
      {/* NO Basket Update Message */}
      {(!orderBasketUpdates || !orderBasketUpdates.length) && (
        <Result
          icon={<ShoppingCartOutlined />}
          title={t('Order Widget.Tabs.Basket Updates.No basket updates')}
          subTitle={`${t('Order Widget.Tabs.Basket Updates.There has not been any changes')}.`}
        />
      )}

      {/* Basket updates rendered through BasketUpdatesView */}
      {orderBasketUpdates?.length
        ? orderBasketUpdates.map((update: OrderSingleBasketUpdate, idx: number) => (
            <Timeline key={`${update.timestamp}-${idx}`}>
              <BasketUpdatesView update={update} />
            </Timeline>
          ))
        : null}
    </div>
  )
}

export default BasketUpdates
