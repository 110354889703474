/**
 * dumb component rendering an err icon and passed error text vertically
 * renders a button with the passed buttonText and callBack func
 * */

// libs
import React from 'react'
// contexts and types
import { ErrorLogEntry } from 'types/error'
// hooks
import { useTranslation } from 'hooks/useTranslation'
// styles
import styles from './ErrorView.styles'
import { createUseStyles } from 'react-jss'
import { Result, Button } from 'antd'

const useStyles = createUseStyles(styles)

interface Props {
  errLogEntry: ErrorLogEntry
  buttonText?: string
  buttonCallBack?: () => void
}

const ErrorView: React.FC<Props> = ({ errLogEntry, buttonText, buttonCallBack }) => {
  const classes = useStyles()

  // pull language content
  const { t } = useTranslation()

  return (
    <div className={classes.container}>
      {errLogEntry.status === 404 ? (
        <Result
          title={`${t('Interface.No Data Found')}!`}
          subTitle={errLogEntry.message}
          extra={
            buttonText &&
            buttonCallBack && [
              <Button type='primary' key='console' onClick={buttonCallBack}>
                {buttonText}
              </Button>,
            ]
          }
        ></Result>
      ) : (
        <Result
          status={'error'}
          title={errLogEntry.status.toString()}
          subTitle={errLogEntry.message}
          extra={
            buttonText &&
            buttonCallBack && [
              <Button type='primary' key='console' onClick={buttonCallBack}>
                {buttonText}
              </Button>,
            ]
          }
        ></Result>
      )}
    </div>
  )
}

export default ErrorView
