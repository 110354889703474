import React, { createContext, useReducer, Dispatch, useMemo } from 'react'
import { entityReducer } from './EntityReducer'
import { InitialEntityState, EntityActions, EntityConfig } from './types'

const initialEntityState = {
  entityConfig: null,
}

const EntityContext = createContext<{
  entityState: InitialEntityState
  entityDispatch: Dispatch<EntityActions>
}>({
  entityState: initialEntityState,
  entityDispatch: () => null,
})

const EntityProvider: React.FC<{
  entityConfig?: Partial<EntityConfig>
}> = ({ children, entityConfig = null }) => {
  const [entityState, entityDispatch] = useReducer(entityReducer, {
    entityConfig: entityConfig as EntityConfig,
  })

  const value = useMemo(() => ({ entityState, entityDispatch }), [entityState, entityDispatch])

  return <EntityContext.Provider value={value}>{children}</EntityContext.Provider>
}

export { EntityProvider, EntityContext }
