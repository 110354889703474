/**
 * environment functions
 * checks and determines the environment & region, and returns the appropriate config values
 * configs include: Google SSO client ID, Datadog application ID, client Token, env context, and mercury root URL
 * */

import { getSearchParams } from 'utils/getSearchParams'

export enum ApiMocks {
  getCustomerWalletTransactions = 'getCustomerWalletTransactions',
  getRider = 'getRider',
}

let parsedEnvironments: {
  apiMocks: Set<ApiMocks>
  useAdminApiConfigCountryList: Set<string>
  awsApiEnabledCountries: Set<string>
} & {
  [p in
    | 'googleMapsApiKey'
    | 'pdGeoLocatorApiKey'
    | 'sso_client_id'
    | 'dd_applicationId'
    | 'dd_clientToken'
    | 'dd_env_context'
    | 'clientId'
    | 'rsClientId'
    | 'vsClientId'
    | 'rsV2ClientId'
    | 'clientIdV2'
    | 'rsV2ClientIdV2'
    | 'vsclientIdV2'
    | 'oneviewApiRoot'
    | 'oneviewAuthApiRoot'
    | 'oneviewAuthApiRootV2'
    | 'oneviewOrderApiRoot'
    | 'oneviewFulfillmentApiRoot'
    | 'oneviewVendorApiRoot'
    | 'oneviewPaymentApiRoot'
    | 'oneviewVoucherApiRoot'
    | 'oneviewCommentApiRoot'
    | 'oneviewCustomerApiRoot'
    | 'oneviewAdminApiRoot'
    | 'oneviewConfigApiRoot'
    | 'oneviewAdminApiJwtToken'

    // permissions || roles
    | 'roleApacDefaultView'
    | 'roleApacWalletView'
    | 'cancelOrderPermissions'
    | 'betaPermissionsNamespace'
    | 'oneviewTeamContactEmail'
    | 'salesforceCaseUrl'
    | 'oneviewApiRootAWS'
    | 'etsBaseUrl'
    | 'dataDogAppVersion'
    | 'awsApiBetaPermissionName']: string
} & {
  [p in
    | 'useAdminApiConfig'
    | 'migrationDisableValidation'
    | 'pluginDevelopment'
    | 'sendEventsToEvts'
    | 'reportEventsToDatadog'
    | 'oneviewAuthApiAWSEnabled']: boolean
}

const COMMA_SEPARATOR = /\s*,\s*/gim
const SPACE_SEPARATOR = /\s+/gim

const createSet = <T>(
  val: string,
  opts?: {
    lowercase?: boolean
    separator?: string | RegExp
    uppercase?: boolean
  },
) => {
  const separator = opts?.separator || COMMA_SEPARATOR
  const setValues = (val || '')
    .split(separator)
    .map((val) => {
      val = val.trim()
      if (opts?.lowercase) {
        val = val.toLowerCase()
      } else if (opts?.uppercase) {
        val = val.toUpperCase()
      }

      return val as any as T
    })
    .filter(Boolean)

  return new Set(setValues)
}

function environment() {
  if (!parsedEnvironments) {
    const isE2eTest = typeof window !== 'undefined' && getSearchParams().has('e2e')
    parsedEnvironments = {
      awsApiEnabledCountries: createSet(process.env.REACT_APP_AWS_API_ENABLED_COUNTRIES, {
        uppercase: true,
        separator: COMMA_SEPARATOR,
      }),
      useAdminApiConfig: process.env.REACT_APP_USE_ADMIN_API_CONFIG === 'true',
      useAdminApiConfigCountryList: createSet(
        process.env.REACT_APP_USE_ADMIN_API_CONFIG_COUNTRY_LIST,
        { lowercase: true, separator: SPACE_SEPARATOR },
      ),
      googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
      pdGeoLocatorApiKey: process.env.REACT_APP_PD_GEO_LOCATOR_API_KEY,

      sso_client_id: process.env.REACT_APP_SSO_CLIENT_ID,
      dd_applicationId: process.env.REACT_APP_DATADOG_APP_ID,
      dd_clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN,
      dd_env_context: process.env.REACT_APP_DATADOG_ENV_CONTEXT,
      clientId: process.env.REACT_APP_X_CLIENT_ID,
      rsClientId: process.env.REACT_APP_X_CLIENT_ID_RS,
      vsClientId: process.env.REACT_APP_X_CLIENT_ID_VS,
      rsV2ClientId: process.env.REACT_APP_X_CLIENT_ID_RS_V2,
      clientIdV2: process.env.REACT_APP_X_CLIENT_ID_CS_V2,
      rsV2ClientIdV2: process.env.REACT_APP_X_CLIENT_ID_RS2_V2,
      vsclientIdV2: process.env.REACT_APP_X_CLIENT_ID_VS_V2,
      oneviewApiRoot: process.env.REACT_APP_API_ROOT,
      oneviewAuthApiRoot: process.env.REACT_APP_AUTH_API_ROOT,
      oneviewAuthApiRootV2: process.env.REACT_APP_AUTH_API_ROOT_V2,
      oneviewOrderApiRoot: process.env.REACT_APP_ORDER_API_ROOT,
      oneviewFulfillmentApiRoot: process.env.REACT_APP_FULFILLMENT_API_ROOT,
      oneviewVendorApiRoot: process.env.REACT_APP_VENDOR_API_ROOT,
      oneviewPaymentApiRoot: process.env.REACT_APP_PAYMENT_API_ROOT,
      oneviewVoucherApiRoot: process.env.REACT_APP_VOUCHER_API_ROOT,
      oneviewCommentApiRoot: process.env.REACT_APP_COMMENT_API_ROOT,
      oneviewCustomerApiRoot: process.env.REACT_APP_CUSTOMER_API_ROOT,
      oneviewAdminApiRoot: process.env.REACT_APP_ADMIN_API_ROOT,
      oneviewConfigApiRoot: process.env.REACT_APP_CONFIG_API_ROOT,
      oneviewAdminApiJwtToken: process.env.REACT_APP_ADMIN_API_JWT_TOKEN,
      roleApacDefaultView: process.env.REACT_APP_ROLE_APAC_DEFAULT_VIEW,
      roleApacWalletView: process.env.REACT_APP_ROLE_APAC_WALLET_VIEW,
      cancelOrderPermissions: process.env.REACT_APP_CANCEL_ORDER_PERMISSION,
      betaPermissionsNamespace: process.env.REACT_APP_BETA_TEST_PERMISSIONS,
      migrationDisableValidation: process.env.MIGRATION_DISABLE_VALIDATION === 'true',
      pluginDevelopment: process.env.REACT_APP_PLUGIN_DEVELOPMENT === 'true',
      oneviewTeamContactEmail: process.env.REACT_APP_ONEVIEW_TEAM_CONTACT_EMAIL,
      salesforceCaseUrl: process.env.REACT_APP_SALESFORCE_CASE_URL,
      oneviewApiRootAWS: process.env.REACT_APP_API_ROOT_AWS,

      awsApiBetaPermissionName: process.env.REACT_APP_AWS_API_BETA_PERMISSION_NAME,

      oneviewAuthApiAWSEnabled: process.env.REACT_APP_AUTH_API_AWS_ENABLED === 'true',

      /**
       * controls if events are sent to event tracking service
       */
      sendEventsToEvts: process.env.REACT_APP_SEND_EVENTS_TO_EVTS === 'true' && !isE2eTest,

      reportEventsToDatadog:
        process.env.REACT_APP_SEND_EVENTS_TO_DATADOG !== 'false' &&
        !isE2eTest &&
        process.env.NODE_ENV === 'production',

      /**
       * event tracking service base url
       */
      etsBaseUrl: process.env.REACT_APP_ETS_BASE_URL || 'https://helpcenter-stg.deliveryhero.io',

      apiMocks: createSet<ApiMocks>(process.env.REACT_APP_API_MOCKS),
      dataDogAppVersion: process.env.REACT_APP_DATADOG_APP_VERSION,
    }
  }
  return parsedEnvironments
}

export default environment
