import { useGetRiderStatusTextAndColor } from 'hooks/useGetRiderStatusTextAndColor'
import { useCallback } from 'react'
import { CourierEventItem, DeliveryItem } from 'types/api/fulfillmentApi/fulfillment'
import { RiderStatuses } from 'types/widgets/rider/riderStatuses'

/**
 * get more metadata relating to delivery current status
 */
export const useGetDeliveryStatusMetadata = () => {
  const getRiderStatusTextAndColor = useGetRiderStatusTextAndColor()

  return useCallback(
    ({
      delivery,
    }: {
      delivery: DeliveryItem
    }): { status: CourierEventItem; statusText: string; statusColor: string } => {
      const courierEvents = delivery?.courier_events ?? []

      const lastEvent = courierEvents[0] ?? ({ name: RiderStatuses.unknown } as CourierEventItem)

      const { color, text } = getRiderStatusTextAndColor(lastEvent.name)

      return { status: lastEvent, statusColor: color, statusText: text }
    },
    [getRiderStatusTextAndColor],
  )
}
