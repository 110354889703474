import { OrderStatuses } from 'types/widgets/order/orderStatuses'

// Constants
import { StatusColors } from 'theme'
import { logError } from 'utils/reporting/logError'
import { useCallback } from 'react'
import { useTranslation } from 'hooks/useTranslation'
import { CheckCircleFilled, CloseCircleFilled, ExclamationCircleFilled } from '@ant-design/icons'

export type TagColours = typeof StatusColors[keyof typeof StatusColors]

export function useGetOrderStatusTextAndColor() {
  const { t } = useTranslation()

  return useCallback(
    (status: OrderStatuses) => {
      if (!status || status === OrderStatuses.placed) {
        return {
          text: t('Order Widget.Tabs.Status.Order Statuses.Placed'),
          color: StatusColors.green,
          icon: CheckCircleFilled,
        }
      }

      let statusColor: TagColours
      let statusText: string
      let statusIcon = undefined

      switch (status) {
        case OrderStatuses.delivered:
          statusText = t('Order Widget.Tabs.Status.Order Statuses.Order delivered')
          statusColor = StatusColors.green
          statusIcon = CheckCircleFilled
          break
        case OrderStatuses.promised:
          statusText = t('Order Widget.Tabs.Status.Order Statuses.Promised to be delivered')
          statusColor = StatusColors.green
          statusIcon = CheckCircleFilled
          break
        case OrderStatuses.pickedUp:
          statusText = t('Order Widget.Tabs.Status.Order Statuses.Courier picked up')
          statusColor = StatusColors.green
          statusIcon = CheckCircleFilled
          break
        case OrderStatuses.courierNearPickup:
          statusText = t('Order Widget.Tabs.Status.Order Statuses.Courier near pick up')
          statusColor = StatusColors.green
          statusIcon = CheckCircleFilled
          break
        case OrderStatuses.courierLeftPickup:
          statusText = t('Order Widget.Tabs.Status.Order Statuses.Courier left pick up')
          statusColor = StatusColors.green
          statusIcon = CheckCircleFilled
          break
        case OrderStatuses.courierNearDropoff:
          statusText = t('Order Widget.Tabs.Status.Order Statuses.Courier near dropoff')
          statusColor = StatusColors.green
          statusIcon = CheckCircleFilled
          break
        case OrderStatuses.orderPrepared:
          statusText = t('Order Widget.Tabs.Status.Order Statuses.Vendor prepared order')
          statusColor = StatusColors.green
          statusIcon = CheckCircleFilled
          break
        case OrderStatuses.accepted:
          statusText = t('Order Widget.Tabs.Status.Order Statuses.Vendor accepted')
          statusColor = StatusColors.green
          statusIcon = CheckCircleFilled
          break
        case OrderStatuses.transmitted:
          statusText = t('Order Widget.Tabs.Status.Order Statuses.Order transmitted')
          statusColor = StatusColors.green
          statusIcon = CheckCircleFilled
          break
        case OrderStatuses.delayed:
          statusText = t('Order Widget.Tabs.Status.Order Statuses.Order delayed')
          statusColor = StatusColors.orange
          statusIcon = ExclamationCircleFilled
          break
        case OrderStatuses.sentToTransmission:
          statusText = t('Order Widget.Tabs.Status.Order Statuses.Order sent to transmission')
          statusColor = StatusColors.green
          statusIcon = CheckCircleFilled
          break
        case OrderStatuses.sendingToVendor:
          statusText = t('Order Widget.Tabs.Status.Order Statuses.Order sending to vendor')
          statusColor = StatusColors.green
          statusIcon = CheckCircleFilled
          break
        case OrderStatuses.displayedAtVendor:
          statusText = t('Order Widget.Tabs.Status.Order Statuses.Order displayed at vendor')
          statusColor = StatusColors.orange
          break
        case OrderStatuses.sentToLogistics:
          statusText = t('Order Widget.Tabs.Status.Order Statuses.Order sent to logistics')
          statusColor = StatusColors.green
          statusIcon = CheckCircleFilled
          break
        case OrderStatuses.courierAcceptedDelivery:
          statusText = t('Order Widget.Tabs.Status.Order Statuses.Courier accepted')
          statusColor = StatusColors.green
          statusIcon = CheckCircleFilled
          break
        case OrderStatuses.rejected:
          statusText = t('Order Widget.Tabs.Status.Order Statuses.Rejected')
          statusColor = StatusColors.red
          statusIcon = CloseCircleFilled
          break
        case OrderStatuses.expired:
          statusText = t('Order Widget.Tabs.Status.Order Statuses.Expired')
          statusColor = StatusColors.red
          statusIcon = CloseCircleFilled
          break
        case OrderStatuses.cancelled:
          statusText = t('Order Widget.Tabs.Status.Order Statuses.Order cancelled')
          statusColor = StatusColors.red
          statusIcon = CloseCircleFilled
          break
        case OrderStatuses.failed:
          statusText = t('Order Widget.Tabs.Status.Order Statuses.Failed')
          statusColor = StatusColors.red
          statusIcon = CloseCircleFilled
          break
        case OrderStatuses.reachingTransmissionFailed:
          statusText = t('Order Widget.Tabs.Status.Order Statuses.Reaching Transmission Failed')
          statusColor = StatusColors.red
          statusIcon = CloseCircleFilled
          break

        case OrderStatuses.pendingOrderFailed:
          statusText = t('Order Widget.Tabs.Status.Order Statuses.Pending order failed')
          statusColor = StatusColors.red
          statusIcon = CloseCircleFilled
          break

        case OrderStatuses.pending:
          statusText = t('Order Widget.Tabs.Status.Order Statuses.Payment pending')
          statusColor = StatusColors.orange
          break

        case OrderStatuses.created:
          statusText = t('Order Widget.Tabs.Status.Order Statuses.Order created')
          statusColor = StatusColors.green
          statusIcon = CheckCircleFilled
          break

        case OrderStatuses.primaryDeliveryPinAcknowledged:
          statusText = t('Order Widget.Tabs.Status.Order Statuses.POD PIN required')
          statusColor = StatusColors.orange
          statusIcon = CheckCircleFilled
          break

        case OrderStatuses.primaryDeliveryPinValidated:
          statusText = t('Order Widget.Tabs.Status.Order Statuses.POD PIN validated')
          statusColor = StatusColors.green
          statusIcon = CheckCircleFilled
          break

        default:
          logError({
            type: 'unknown-order-status',
            status: status,
          })
          statusText = `Unknown: (${status})`
          statusColor = StatusColors.red
      }

      return {
        text: statusText,
        color: statusColor,
        icon: statusIcon,
      }
    },
    [t],
  )
}
