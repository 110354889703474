import React from 'react'
import { createPluggableWidget } from 'factory/createPluggableWidget'
import { BuiltinWidgetConfigs } from 'types'
import { useApiService } from 'hooks/useApiService'
import { getVendorShippingAddresses } from 'services/vendorApi/getVendorShippingAddresses'
import { createUseStyles } from 'react-jss'
import { AddressCard } from './AddressCard/AddressCard'
import { transformVendorAddress } from '../../utils/dataTransformers/transformVendorAddress'

export const useStyles = createUseStyles({
  divider: {
    margin: '16px 0',
  },
  addressContainer: {
    paddingBottom: '16px',

    '&:not(:first-child)': {
      paddingTop: '16px',
    },

    '&:not(:last-child)': {
      borderBottom: '1px solid rgba(0, 0, 0, .06)',
    },
  },
})

export const VendorAddresses = createPluggableWidget<BuiltinWidgetConfigs['vendor_addresses']>(
  ({ config, globalVendorId, globalEntityId, sdk, ErrorRenderer }) => {
    const { checkDisplayRules } = sdk
    const classes = useStyles()

    const {
      data: vendorAddresses,
      error: vendorAddressesError,
      loading: isLoading,
    } = useApiService({
      service: getVendorShippingAddresses,
      params: {
        entityId: globalEntityId,
        globalVendorId,
      },

      deps: [globalEntityId, globalVendorId],
    })

    const showMap = checkDisplayRules({
      displayRules: config?.locationMap,
    }).visible

    const addresses = vendorAddresses?.map((address) => transformVendorAddress(address))

    return (
      <ErrorRenderer loading={isLoading} errorPayload={vendorAddressesError?.errorPayload}>
        {() => (
          <section>
            {addresses?.map((address) => (
              <article className={classes.addressContainer}>
                <AddressCard key={address.id} address={address} showMap={showMap} />
              </article>
            ))}
          </section>
        )}
      </ErrorRenderer>
    )
  },
  {
    deriveConfig({ entityConfig }) {
      return entityConfig.layout_v2.builtin_widgets_configs.vendor_addresses
    },
    deriveSubjectsRequirements() {
      return {
        all_of: ['globalVendorId'],
      }
    },
    category: 'data_lookup',
  },
)
