import React from 'react'
import { Col, Row } from 'antd'
import { useTranslation } from 'hooks/useTranslation'
import { useDateTimeFormatter } from 'hooks/formatters/useDateTimeFormatter'
import { DeliveryIssue } from 'types/api/fulfillmentApi/fulfillment'
import { Text } from 'shared'

type Props = {
  issue: DeliveryIssue
}

export const SingleIssue = ({ issue }: Props) => {
  const { t } = useTranslation()
  const formatter = useDateTimeFormatter()
  const { type, updated_at } = issue
  return (
    <Row justify='space-between'>
      <Col>
        <Text.Text>{t(`widgets.order_deliveries.delivery_issues.${type}`)}</Text.Text>
      </Col>
      <Col>
        <Text.Secondary>
          {formatter.formatTime(updated_at)} {formatter.formatDate(updated_at)}
        </Text.Secondary>
      </Col>
    </Row>
  )
}
