import React, { FC } from 'react'
import { Tooltip, TooltipProps } from 'antd'

type Props = TooltipProps & {
  disabled?: boolean
}

export const SmartToolTip: FC<Props> = ({ disabled, title, children, ...tooltipProps }) => {
  if (disabled || !title) {
    return <>{children}</>
  }
  return (
    <Tooltip title={title} {...tooltipProps}>
      {children}
    </Tooltip>
  )
}
