import { ApiService } from 'services/authApi/getPermissions'
export interface ChangeDeliveryStatusReasonsList {
  next_step_reasons: string[]
}

export const getChangeDeliveryStatusReasons: ApiService<
  {
    entityId: string
  },
  ChangeDeliveryStatusReasonsList
> = async (createClient, options) => {
  const { entityId, clientParams } = options

  const uri = `/FulfillmentAPI/v1/delivery/${entityId}/status-update-reasons`
  return createClient({
    endpointName: 'getChangeDeliveryStatusReasons',
    expectedResponseStatusCode: 200,
    ...clientParams,
  }).get(uri)
}
