import { useSessionState } from 'hooks/useSessionState'
import React, { createContext, FC, useCallback, useContext, useMemo, useRef, useState } from 'react'

import { getPluginMetadata } from 'services/configApi/getPluginMetadata'
import { useApiClientCreator } from 'contexts/apiClientCreator/ApiClientCreatorContext'
import loadScript from 'utils/loadScript'
import { pascalCase } from 'utils/pascalCase'
import {
  builtinWidgetNames,
  WidgetSubjects,
  widgetTypes,
  PluggableWidgetDefinition,
  PluggableWidget,
  WidgetView,
  WidgetMetadata,
} from 'types/unitedUiConfig'
import { builtInWidgets } from './builtinWidgets'
import { useForceUpdate } from 'contexts/useForceUpdate'
import { getMatchingViewForSubjects } from './getMatchingView'
import { Spin } from 'antd'
import { EntityContext } from 'contexts/entity/EntityContext'
import { EntityConfig } from 'contexts/entity/types'
import environment from 'envConfig'
import { plugins as localPlugins } from 'plugins'
import { getWidgetId } from 'utils/getters/getWidgetId'
import * as ReactIs from 'react-is'
import { assignWidgetCategory } from './assignWidgetCategory'
import { Notification } from 'shared'
import { useCreatePerformanceMeasure } from 'hooks/useCreatePerformanceMeasure'

export interface WidgetViewManagerContextValue {
  /**
   * slowly loads plugin info/metadata and script if not loaded,
   * using browser's prefetch capacity
   *
   * this is not implemented yet, we need back team to free up the plugin
   * info endpoint
   */
  prefetchWidget: (
    widgetDefinition: PluggableWidgetDefinition,
  ) => Promise<{ widget: PluggableWidget<any>; metadata: WidgetMetadata<any> }>

  /*
   * instantly loads plugin metadata if not loaded,
   */
  loadWidgetInfo: (
    widgetDefinition: PluggableWidgetDefinition,
  ) => Promise<{ metadata: WidgetMetadata<any> }>

  /*
   * instantly loads plugin metadata and script if not loaded,
   */
  loadWidget: (
    widgetDefinition: PluggableWidgetDefinition,
  ) => Promise<{ widget: PluggableWidget<any>; metadata: WidgetMetadata<any> }>

  /**
   * instantly loads the plugin metadata and script if not loaded, resolves the given subjects, and starts the plugin.
   */
  activateWidgetView: (
    widgetDefinition: PluggableWidgetDefinition,
    opts?: {
      /**
       * if root view, it will only return the view, it will not add it to the
       * list of widgetViews, as they are handled different
       */
      isARootView?: boolean

      parentViewId?: number

      config?: any

      subjects?: Partial<WidgetSubjects>

      destroyable?: boolean

      /**
       * called when the widget gets closed
       */
      onClose?: WidgetView['onClose']

      /**
       * called when the widget gets hidden
       */
      onHide?: WidgetView['onHide']
    },
  ) => Promise<WidgetView>

  /**
   * closes/destroys a plugin view
   */
  destroyWidgetView: (viewId: number) => boolean

  /**
   * hides a plugin view without destroying it
   */
  deactivateWidgetView: (viewId: number) => boolean

  /**
   * plugin views
   */
  widgetViews: WidgetView[]
}

const WidgetViewManagerContext = createContext<WidgetViewManagerContextValue>(undefined)

export const WidgetViewManager: FC = ({ children }) => {
  const [spin, setSpin] = useState(false)

  const widgetsComponentsRef = useRef<Map<string | builtinWidgetNames, PluggableWidget<any>>>(
    new Map(),
  )
  const widgetsInfosRef = useRef<Map<string | builtinWidgetNames, WidgetMetadata<any>>>(new Map())

  // const prefetchedPluginsInfosRef = useRef<Set<string>>(new Set())

  const widgetsViewIdRef = useRef(1)

  const {
    entityState: { entityConfig },
  } = useContext(EntityContext)

  // we do not want our callbacks to depend on state changes
  const stateRef = useRef<{
    widgetViews: WidgetView[]
  }>({
    widgetViews: [],
  })

  const forceUpdate = useForceUpdate()
  const { createClient } = useApiClientCreator()

  const { globalEntityId, lineOfBusiness } = useSessionState()

  const createPerformanceMeasure = useCreatePerformanceMeasure()

  // loads widget info
  const loadWidgetInfo = useCallback<WidgetViewManagerContextValue['loadWidgetInfo']>(
    async (widgetDefinition) => {
      const widgetId = getWidgetId(widgetDefinition)

      if (widgetsInfosRef.current.has(widgetId)) {
        return { metadata: widgetsInfosRef.current.get(widgetId) }
      }

      const performanceMeasure = createPerformanceMeasure(
        `${widgetId}.widget-info-load`,
        'WIDGET_INFO_LOADING_TIME',
        {
          eventDetails: {
            widgetId,
          },
        },
      )

      performanceMeasure.start()
      if (widgetDefinition.type === widgetTypes.builtin) {
        if (builtInWidgets.has(widgetDefinition.widget_name)) {
          const { metadata } = builtInWidgets.get(widgetDefinition.widget_name)
          const clonedMetadata = { ...metadata }
          widgetsInfosRef.current.set(widgetId, clonedMetadata)
          performanceMeasure.end()
          return { metadata: clonedMetadata }
        }
        throw new Error(`Could not load ${widgetId} widget metadata. No such builtin widget found`)
      }

      try {
        const { data } = await getPluginMetadata(createClient, {
          pluginCode: widgetDefinition.plugin_code,
          lineOfBusiness,
          globalEntityId,
        })
        widgetsInfosRef.current.set(widgetId, data)
        performanceMeasure.end()

        return { metadata: data }
      } catch (ex) {
        throw new Error(
          `Could not load ${widgetDefinition.plugin_code} widget metadata. No such plugin found`,
          {
            cause: ex,
          },
        )
      }
    },
    [createPerformanceMeasure, createClient, globalEntityId, lineOfBusiness],
  )

  // use this to load widgets instantly
  const loadWidget = useCallback<WidgetViewManagerContextValue['loadWidget']>(
    async (widgetDefinition) => {
      const widgetId = getWidgetId(widgetDefinition)

      // return loaded widget if exists
      if (widgetsComponentsRef.current.has(widgetId)) {
        return {
          widget: widgetsComponentsRef.current.get(widgetId),
          metadata: widgetsInfosRef.current.get(widgetId),
        }
      }

      const totalLoadTimePerformanceMeasure = createPerformanceMeasure(
        `${widgetId}.widget-load`,
        'WIDGET_LOADING_TIME',
        {
          eventDetails: {
            widgetId,
          },
        },
      )
      const codeLoadTimePerformanceMeasure = createPerformanceMeasure(
        `${widgetId}.widget-code-load`,
        'WIDGET_CODE_LOADING_TIME',
        {
          eventDetails: {
            widgetId,
          },
        },
      )

      totalLoadTimePerformanceMeasure.start()
      const { metadata } = await loadWidgetInfo(widgetDefinition)

      codeLoadTimePerformanceMeasure.start()

      // load builtin widget if it is a builtin widget
      if (widgetDefinition.type === widgetTypes.builtin) {
        const { component } = builtInWidgets.get(widgetDefinition.widget_name)
        assignWidgetCategory(widgetId, component)
        widgetsComponentsRef.current.set(widgetId, component)

        totalLoadTimePerformanceMeasure.end()
        codeLoadTimePerformanceMeasure.end()
        return { metadata, widget: component }
      }

      // if running in plugin dev mode, and plugin exists
      if (environment().pluginDevelopment) {
        const pluginDetails = localPlugins.get(widgetDefinition.plugin_code)
        if (pluginDetails && pluginDetails.plugin) {
          assignWidgetCategory(widgetId, pluginDetails.plugin)
          widgetsComponentsRef.current.set(widgetId, pluginDetails.plugin)

          totalLoadTimePerformanceMeasure.end()
          codeLoadTimePerformanceMeasure.end()
          return {
            widget: pluginDetails.plugin,
            metadata: {
              authorEmail: pluginDetails.manifest.authorEmail,
              config: pluginDetails.config,
              scriptUrl: '',
              supportSlackChannel: pluginDetails.manifest.slackChannel,
            },
          }
        }
      }
      try {
        await loadScript(metadata.scriptUrl, {})
        codeLoadTimePerformanceMeasure.end()
      } catch (ex) {
        throw new Error(
          `Could not load or parse script for ${widgetDefinition.plugin_code} widget from network`,
          {
            cause: ex,
          },
        )
      }

      const exportName = pascalCase(widgetDefinition.plugin_code)
      const plugin = window[exportName].Plugin

      assignWidgetCategory(widgetId, plugin)

      widgetsComponentsRef.current.set(widgetId, plugin)
      totalLoadTimePerformanceMeasure.end()

      return { widget: plugin, metadata }
    },
    [createPerformanceMeasure, loadWidgetInfo],
  )

  // we can use this to prefetch plugins, implementation is not complete
  const prefetchWidget = useCallback<WidgetViewManagerContextValue['prefetchWidget']>(
    async (widgetDefinition) => {
      const widgetId = getWidgetId(widgetDefinition)
      if (widgetsComponentsRef.current.has(widgetId)) {
        return {
          widget: widgetsComponentsRef.current.get(widgetId),
          metadata: widgetsInfosRef.current.get(widgetId),
        }
      }
      // TODO: finish implementation here;
    },
    [],
  )

  // activates a widget view, and brings it to live
  const activateWidgetView = useCallback<WidgetViewManagerContextValue['activateWidgetView']>(
    async (widgetDefinition, opts) => {
      const { widgetViews } = stateRef.current
      const {
        config: givenConfig,
        parentViewId,
        isARootView,
        destroyable,
        onClose,
        onHide,
      } = opts || {}

      const subjects = opts?.subjects || {}
      const widgetId = getWidgetId(widgetDefinition)

      // reactivate this view if we have it loaded previously with the same subjects
      const matchingView = getMatchingViewForSubjects(widgetId, widgetViews, subjects)
      if (matchingView) {
        stateRef.current.widgetViews = widgetViews.map((view) => ({
          ...view,
          isActiveView: view.id === matchingView.id,
        }))

        forceUpdate()
        return { ...matchingView, isActiveView: true }
      }

      const performanceMeasure = createPerformanceMeasure(
        `${widgetId}.widget-activation`,
        'WIDGET_ACTIVATION_TIME',
        {
          eventDetails: {
            widgetId,
          },
        },
      )
      performanceMeasure.start()

      // spin when activating a non root view
      if (!isARootView) {
        setSpin(true)
      }

      try {
        // load widget if not loaded
        const { widget, metadata } = await loadWidget(widgetDefinition)

        // resolve plugin config, compute builtin plugin config
        // if config is not explicitly passed in
        let resolvedConfig = givenConfig || metadata.config || {}

        if (widget.deriveConfig) {
          resolvedConfig = widget.deriveConfig({
            entityConfig: entityConfig as EntityConfig,
            lob: lineOfBusiness,
          })
        }

        const parentView = widgetViews.find((view) => view.id === parentViewId)

        const view: WidgetView = {
          id: null,
          parentViewId: parentView?.id || null,
          childViewIds: new Set(),
          widgetId,
          Widget: widget,
          metadata: {
            ...metadata,
            config: resolvedConfig,
          },
          subjects,
          isActiveView: true,
          labelTranslationKey: widgetDefinition.label?.label_translation_key,

          implementsHandle: widget.$$typeof === ReactIs.ForwardRef,
          destroyable,
          elevated: Boolean(widgetDefinition.elevated),
          onClose,
          onHide,
        }

        // root plugin lifecycle does not need handling, return it to caller
        if (isARootView) {
          performanceMeasure.end()
          return view
        }

        view.id = widgetsViewIdRef.current++

        // set relationship b/w parent and child view
        if (parentView) {
          parentView.childViewIds.add(view.id)
        }

        stateRef.current.widgetViews = widgetViews
          .map((view) => ({ ...view, isActiveView: false }))
          .concat(view)

        performanceMeasure.end()
        forceUpdate()
        return view
      } catch (ex) {
        if (!isARootView) {
          Notification.error({
            message: `Failed to activate widget view`,
            description: ex?.message,
          })
        } else {
          throw ex
        }
      } finally {
        if (!isARootView) {
          setSpin(false)
        }
      }
    },

    [forceUpdate, loadWidget, entityConfig, lineOfBusiness, createPerformanceMeasure],
  )

  // destroy a plugin view
  const destroyWidgetView = useCallback<WidgetViewManagerContextValue['destroyWidgetView']>(
    (viewId) => {
      let currentViews = stateRef.current.widgetViews

      // view does not exist, do nothing
      const pluginView = currentViews.find((view) => view.id === viewId)
      if (!pluginView) {
        return true
      }

      const parentView = currentViews.find((view) => view.id === pluginView.parentViewId)

      // remove view and all its child views
      currentViews = stateRef.current.widgetViews = currentViews.filter((view) => {
        return view.id !== pluginView.id && !pluginView.childViewIds.has(view.id)
      })

      // transfer control to parent view if exists
      if (parentView) {
        currentViews = stateRef.current.widgetViews = currentViews.map((view) => {
          if (view.id === parentView.id) {
            return {
              ...view,
              isActiveView: true,
            }
          }
          return view
        })
      }

      pluginView.onClose?.()

      forceUpdate()
      return true
    },
    [forceUpdate],
  )

  // hides a plugin
  const deactivateWidgetView = useCallback<WidgetViewManagerContextValue['destroyWidgetView']>(
    (viewId) => {
      let currentViews = stateRef.current.widgetViews

      // do nothing if plugin does not exist
      const pluginView = currentViews.find((view) => view.id === viewId)
      if (!pluginView) {
        return true
      }

      const parentView = currentViews.find((view) => view.id === pluginView.parentViewId)

      // deactivate the plugin
      currentViews = stateRef.current.widgetViews = currentViews.map((view) => {
        if (view !== pluginView) {
          return view
        }
        return {
          ...view,
          isActiveView: false,
        }
      })

      // transfer control to parent view if any
      if (parentView) {
        currentViews = stateRef.current.widgetViews = currentViews.map((current) => {
          if (current === parentView) {
            return {
              ...parentView,
              isActiveView: true,
            }
          }
          return current
        })
      }

      pluginView.onHide?.()

      forceUpdate()
      return true
    },
    [forceUpdate],
  )

  const { widgetViews } = stateRef.current

  const contextValue = useMemo((): WidgetViewManagerContextValue => {
    return {
      prefetchWidget,
      loadWidget,
      activateWidgetView,
      widgetViews,
      destroyWidgetView,
      loadWidgetInfo,
      deactivateWidgetView,
    }
  }, [
    activateWidgetView,
    prefetchWidget,
    widgetViews,
    destroyWidgetView,
    loadWidget,
    loadWidgetInfo,
    deactivateWidgetView,
  ])

  return (
    <WidgetViewManagerContext.Provider value={contextValue}>
      <Spin spinning={spin}>{children}</Spin>
    </WidgetViewManagerContext.Provider>
  )
}

export const useWidgetViewManager = () => {
  return useContext(WidgetViewManagerContext)
}
