import React from 'react'
import { Typography } from 'antd'
import { createUseStyles } from 'react-jss'
import { useTranslation } from 'hooks/useTranslation'
import { SingleComment } from 'types/api/commentApi/getOrderComments'
import styles from './Comments.styles'
import { commentTagType, CommentView } from './CommentView'
import { WidgetSize } from 'types/widgets/historyOfEvents/tabs'

const { Text } = Typography

const useStyles = createUseStyles(styles)

type Props = {
  comment: SingleComment
  shouldCommentBeHighlighted: boolean
  mode: WidgetSize
}

const DeliveryCoordinatesComment = ({ comment, shouldCommentBeHighlighted, mode }: Props) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { old: oldAddress, new: newAddress } = comment?.additional_data?.modify_delivery_address
  return (
    <CommentView
      mode={mode}
      comment={comment}
      shouldCommentBeHighlighted={shouldCommentBeHighlighted}
      tag={{
        type: commentTagType.thunderbolt,
        text: t('History of Events Widget.Event Types.Change delivery pin'),
      }}
    >
      <Text className={classes.commentText}>
        <Text>
          {`${t('History of Events Widget.Event Types.Change delivery pin')} (${
            oldAddress?.latitude
          },${oldAddress?.longitude}) → (${newAddress?.latitude},${newAddress?.longitude})`}
        </Text>
      </Text>
    </CommentView>
  )
}

export default DeliveryCoordinatesComment
