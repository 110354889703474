import { useAuthProvider } from 'contexts/auth/AuthProvider'
import { useCallback } from 'react'
import { readAuthPayload } from 'utils/authHelpers'

export const useGetUserPermissions = () => {
  const { isLoggedIn } = useAuthProvider()

  return useCallback(
    (entityId: string): string[] => {
      if (!isLoggedIn) {
        return []
      }
      const allPermissions = readAuthPayload().permissions || {}
      return allPermissions[entityId] || []
    },
    [isLoggedIn],
  )
}

// If user have any of the given permissions return true
export const useUserHaveAny = () => {
  const getUserPermissions = useGetUserPermissions()
  return useCallback(
    (entityId: string, permissions: string[]) => {
      const userPermissions = getUserPermissions(entityId)
      if (!userPermissions) {
        return false
      }
      return !!permissions.find((permission) => userPermissions.includes(permission))
    },
    [getUserPermissions],
  )
}

// If user have all of the given permissions return true
export const useUserHaveAll = () => {
  const getUserPermissions = useGetUserPermissions()
  return useCallback(
    (entityId: string, permissions: string[]) => {
      const userPermissions = getUserPermissions(entityId)
      if (!userPermissions) return false
      for (const permission of permissions) {
        if (!userPermissions.includes(permission)) {
          return false
        }
      }
      return true
    },
    [getUserPermissions],
  )
}
