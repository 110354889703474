import React from 'react'
import { Typography } from 'antd'
import { createUseStyles } from 'react-jss'

import { SingleComment } from 'types/api/commentApi/getOrderComments'
import { useTranslation } from 'hooks/useTranslation'
import { commentTagType, CommentView } from 'components/Comments/CommentView'
import { getAgentNameFromId } from 'utils/string'
import styles from './Comments.styles'
import { WidgetSize } from 'types/widgets/historyOfEvents/tabs'

const { Text } = Typography

const useStyles = createUseStyles(styles)

type Props = {
  comment: SingleComment
  shouldCommentBeHighlighted: boolean
  mode: WidgetSize
}

export const UpdateVendorScheduleComment = ({
  comment,
  shouldCommentBeHighlighted,
  mode,
}: Props) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <CommentView
      mode={mode}
      comment={comment}
      shouldCommentBeHighlighted={shouldCommentBeHighlighted}
      tag={{
        type: commentTagType.message,
        text: t('History of Events Widget.Event Types.Update Vendor Schedule'),
      }}
    >
      <Text className={classes.commentText}>
        <Text>
          {getAgentNameFromId(comment.agent_id)}{' '}
          {t('History of Events Widget.Content.Updated schedule for vendor')} {comment.vendor_id}{' '}
        </Text>
        <Text>
          {t('History of Events Widget.Content.For calendar name')}:{' '}
          {comment.additional_data?.update_vendor_schedule?.calendar_name}{' '}
        </Text>
        <Text>
          {t('History of Events Widget.Content.And calendar ID')}:{' '}
          {comment.additional_data?.update_vendor_schedule?.calendar_id}{' '}
        </Text>
        <Text>
          {t('History of Events Widget.Content.With a reason')}:{' '}
          {comment.additional_data?.update_vendor_schedule?.reason}
        </Text>
      </Text>
    </CommentView>
  )
}
