import { SDK } from 'types'
import { DeliveryItem } from 'types/api/fulfillmentApi/fulfillment'
import { FulfillmentApiResponse, OrderApiResponse } from 'types/herocare'
import {
  calculateOrderDeliveryTimestamps,
  formatDelayTime,
} from 'utils/order/calculateOrderDeliveryTimestamps'

type Props = {
  order: OrderApiResponse
  fulfillment?: FulfillmentApiResponse
  datetimeFormatter: SDK['datetimeFormatter']
}

export const getOrderDelayTime = ({ order, fulfillment, datetimeFormatter }: Props) => {
  const deliveries: DeliveryItem[] = fulfillment?.deliveries ?? []

  // if there are no deliveries available
  if (!deliveries?.length) {
    const { delayInMinutes } = calculateOrderDeliveryTimestamps({
      order,
      fulfillment,
      datetimeFormatter,
    })
    return formatDelayTime(delayInMinutes)
  }

  // calculate delay time for eash delivery
  const delayByDeliveries = deliveries.map((delivery) => {
    const { delayInMinutes } = calculateOrderDeliveryTimestamps({
      order,
      fulfillment,
      delivery,
      datetimeFormatter,
    })
    return delayInMinutes
  })

  // get max delay time among all deliveries
  const maxDelayInMinutes = Math.max(...delayByDeliveries)

  return formatDelayTime(maxDelayInMinutes)
}
