import { allowedScrollablePanelWidgets } from 'entityConfig/allowedConfigValues'
import { Host, Language, TabName, UiVersion, WidgetContainerType } from 'types'
import { LinesOfBusiness } from 'types/session/linesOfBusiness'

export interface SessionState {
  /**
   * this is the order id from the url, if it does not exist, it is the latest vendor's order id
   * or the rider's current order.
   */
  orderId?: string
  caseId?: string
  sessionId?: string

  /**
   * user id is the customer id as provided in the url, or the order customer id
   */
  userId?: string

  /**
   * the rider id is the rider id as provided in the url
   */
  riderId?: string

  /**
   * this is the rider's current order id if present
   */
  riderCurrentOrderId?: string

  /**
   * this is the rider's current order vendor id
   */
  riderCurrentOrderVendorId?: string

  globalEntityId?: string

  lineOfBusiness?: LinesOfBusiness

  lang?: Language

  platform?: string

  ccrCode?: string

  /**
   * vendor id is the vendor's id provided in the url, or the order's vendor id
   */
  vendorId?: string

  globalVendorId?: string

  /**
   * sometimes we want to load entity config from a different entity id.
   */
  entityConfigId?: string

  uiVersion?: UiVersion
  utcZone?: string

  /**
   * widget container type
   */
  widgetContainerType?: WidgetContainerType

  host?: Host

  /**
   * indicates the active scrollable panel widget
   */
  activeScrollablePanelWidget?: allowedScrollablePanelWidgets

  /**
   * indicates the active tab in v2
   */
  activeTab?: TabName
}

// action types
export enum SessionAction {
  SET_SESSION = 'SET_SESSION',
}

type ActionMap<M extends { [index: string]: any }> = {
  [Key in keyof M]: M[Key] extends undefined
    ? { type: Key }
    : {
        type: Key
        payload: M[Key]
      }
}

export type SessionActions = ActionMap<SessionPayload>[keyof ActionMap<SessionPayload>]

export type SessionPayload = {
  [SessionAction.SET_SESSION]: SessionState
}
