import { WidgetDefinition, widgetTypes } from 'types/unitedUiConfig'

/**
 * Extracts widget name from widget definition, e.g. for testing purposes
 * @param {WidgetDefinition} widget
 * @returns {string} presumably unique string describing the widget
 */
export function getWidgetId(widget: WidgetDefinition) {
  if (widget) {
    if (widget.type === widgetTypes.tabbedWidgetsContainer) {
      return widgetTypes.tabbedWidgetsContainer
    }

    if (widget.type === widgetTypes.builtin) {
      return widget.widget_name
    }

    if (widget.type === widgetTypes.plugin) {
      return widget.plugin_code
    }
  }

  return undefined
}
