import { Space } from 'antd'
import classNames from 'classnames'
import React, { CSSProperties, ReactNode, useMemo } from 'react'
import { createUseStyles } from 'react-jss'
import { ActionHandler, builtinWidgetNames } from 'types/unitedUiConfig'
import { ActionHandlerButton, ActionHandlerButtonProps } from '../ActionHandlerButton'
import { SizeType } from 'antd/lib/config-provider/SizeContext'
import { useSdk } from 'contexts/SdkProvider'
interface UseStylesProps {
  marginTop?: number
  size?: SizeType
  justifyContent?: CSSProperties['justifyContent']
  marginBottom?: number
}

const useStyles = createUseStyles({
  container: ({ marginTop = 20, marginBottom = 0, justifyContent = 'center' }: UseStylesProps) => {
    return {
      marginTop,
      display: 'flex',
      alignItems: 'center',
      justifyContent,
      marginBottom,
      flexWrap: 'wrap',
    }
  },
})

export interface WidgetActionHandlersProps
  extends UseStylesProps,
    Pick<ActionHandlerButtonProps, 'activationSubjects'> {
  className?: string
  actionHandlers: (ActionHandler & Pick<ActionHandlerButtonProps, 'label'>)[]

  addonActions?: Array<ReactNode>
}

export const WidgetActionHandlers = ({
  actionHandlers,
  className,
  marginTop,
  marginBottom,
  justifyContent,
  activationSubjects,
  size,
  ...others
}: WidgetActionHandlersProps) => {
  const styles = useStyles({
    marginTop,
    justifyContent,
    marginBottom,
  })
  const { checkDisplayRules, widgetId } = useSdk()

  const { delivery } = activationSubjects || {}

  const actionHandlersWithDisplayRulesResult = useMemo(() => {
    return (actionHandlers || []).map((current) => {
      return {
        ...current,
        displayRulesResult: checkDisplayRules({
          displayRules: current.display_rules,
          opts: { delivery, widgetName: widgetId as builtinWidgetNames },
        }),
      }
    })
  }, [actionHandlers, delivery, checkDisplayRules, widgetId])

  const hasVisibleActionHandlers = actionHandlersWithDisplayRulesResult.find(
    (current) => current.displayRulesResult.visible,
  )

  if (!hasVisibleActionHandlers) {
    return null
  }

  return (
    <Space className={classNames('action-handleers', styles.container, className)} {...others}>
      {actionHandlersWithDisplayRulesResult.map((current, index) => {
        if (!current.displayRulesResult.visible) {
          return null
        }
        return (
          <ActionHandlerButton
            actionHandler={current}
            key={current.action.label_translation_key || current.action.icon_name || index}
            activationSubjects={activationSubjects}
            label={current?.label}
            size={size}
          />
        )
      })}
    </Space>
  )
}
