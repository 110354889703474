import { DataPointWithBetaRequirementConfig } from 'contexts/entity/types'
import { allowedDataPointValues } from 'entityConfig/allowedConfigValues'
import { useIsDataPointValid } from 'hooks/useGetValidFeatures'
import { useCallback } from 'react'

const isEmptyValue = (value) => value === null || value === undefined || value === '' || value === 0
/**
 * creates a filter than can be used to filter out renderable data point fields
 * based on the config of such datapoints
 * @returns
 */
export const useCreateFilterRenderableDataPoint = () => {
  const isDataPointValid = useIsDataPointValid()
  return useCallback(
    (
      config: {
        [p in string]: DataPointWithBetaRequirementConfig | any
      },
      opts?: {
        dropItemsWithEmptyValue?: boolean
      },
    ) => {
      return (item: { key: string; value?: any; label?: any }) => {
        const { dropItemsWithEmptyValue = false } = opts || {}
        if (!item) {
          return false
        }

        const dataPointConfig: DataPointWithBetaRequirementConfig = config?.[item.key]
        if (dataPointConfig && !isDataPointValid(dataPointConfig.betaRequirement)) {
          return false
        }

        const displayRule = dataPointConfig?.displayRule || []

        if (displayRule.includes(allowedDataPointValues.never_display)) {
          return false
        }

        if (
          (displayRule.includes(allowedDataPointValues.display_when_data_found) ||
            dropItemsWithEmptyValue) &&
          isEmptyValue(item.value)
        ) {
          return false
        }

        return true
      }
    },
    [isDataPointValid],
  )
}
