/**
 * expects fulfillment API's response
 * creates 2 arrays with appropriate key/value pairs to be used in Rider V2 widget Summary Tab
 * */

import { unknownDataPoint } from './constants'
import { TransformedDataPoint } from 'types/dataTransformers/generic'

function transformRiderSummary(rider: any, t: any) {
  // set columns' data accordingly
  const transformedLeftColumnData: TransformedDataPoint[] = [
    {
      key: t('Rider Widget.Rider Name'),
      value: rider?.name ?? unknownDataPoint,
    },
    {
      key: t('Rider Widget.Phone Number'),
      value: rider?.phone ?? unknownDataPoint,
    },
    {
      key: t('Rider Widget.Status'),
      value: rider?.status ?? unknownDataPoint,
    },
    {
      key: t('Rider Widget.City'),
      value: rider?.city ?? unknownDataPoint,
    },
    {
      key: t('Rider Widget.Contract Type'),
      value: rider?.contractType ?? unknownDataPoint,
    },
  ]

  const transformedRightColumnData: TransformedDataPoint[] = [
    {
      key: t('Rider Widget.Vertical Type'),
      value: rider?.vertical_type ?? unknownDataPoint,
    },
    {
      key: t('Rider Widget.Current Shift'),
      value: rider?.currentShift ?? unknownDataPoint,
    },
    {
      key: t('Rider Widget.Vehicle Type'),
      value: rider?.vehicle ?? unknownDataPoint,
    },
  ]

  // return columns' data
  return { transformedLeftColumnData, transformedRightColumnData }
}

export default transformRiderSummary
