import { useContext } from 'react'
import { DataContext } from 'contexts/data/DataContext'
import { DataAction } from 'contexts/data/types'
import { SessionContext } from 'contexts/session/SessionContext'
import { useApiService } from 'hooks/useApiService'
import { getTicketDetails } from 'services/ticketApi/getTicketDetails'
import { CustomerApiResponse } from 'types/api/orderApi/order'

const { SET_TICKET, SET_CUSTOMER } = DataAction

export function useLoadTicket() {
  const {
    sessionState: { globalEntityId, caseId, uiVersion, host },
  } = useContext(SessionContext)

  const { dataDispatch, readDataState } = useContext(DataContext)

  return useApiService({
    service: getTicketDetails,
    params: {
      entityId: globalEntityId,
      caseId,
    },
    shouldLoad: Boolean(uiVersion === 'v2' && host === 'herocare' && globalEntityId && caseId),
    deps: [globalEntityId, caseId],

    onSuccess(res) {
      const ticket = res.data

      dataDispatch({
        type: SET_TICKET,
        payload: { ticket },
      })

      // set customer data from ticket if not set
      const { customer } = readDataState()
      if (!customer && ticket.customer) {
        dataDispatch({
          type: SET_CUSTOMER,
          payload: {
            customer: {
              customer_id: ticket.customer.id,
              profile: {
                ...ticket.customer,
              },
            } as CustomerApiResponse,
          },
        })
      }
    },
  })
}
