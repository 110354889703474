import { OrderApiResponse } from 'types/api/orderApi/order'
import { DeliveryItem } from 'types/api/fulfillmentApi/fulfillment'
import { DeliveryStatusItem, getOrderDeliveryStatuses } from 'utils/order/getOrderDeliveryStatuses'
import { useSdk } from 'contexts/SdkProvider'
import { getLastValue } from 'utils/array'

export const useCurrentDeliveryStatus = ({
  order,
  delivery,
}: {
  delivery?: DeliveryItem
  order: OrderApiResponse
}) => {
  const sdk = useSdk()
  const statusHistory = getOrderDeliveryStatuses({ delivery, order })
  const currentStatus = getLastValue<DeliveryStatusItem>(statusHistory)
  const meta =
    currentStatus.source === 'order'
      ? sdk.getOrderStatusTextAndColor({ orderStatus: currentStatus.status })
      : sdk.getRiderStatusTextAndColor({ riderStatus: currentStatus.status })

  return {
    ...currentStatus,
    ...meta,
  }
}
