export default {
  container: {
    display: 'flex',
    alignItems: 'baseline',
    width: '100%',
  },
  labelText: {
    fontSize: '13px',
    color: 'rgba(0, 0, 0, 0.45)',
    marginRight: '3px',
  },
}
