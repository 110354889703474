import { ApiService } from 'services/authApi/getPermissions'
import { Voucher } from '../../types/api/voucherApi/voucher'

export const getCustomerVouchers: ApiService<
  {
    entityId: string
    customerId: string
  },
  Voucher[]
> = (createClient, options) => {
  const { entityId, customerId, clientParams } = options

  const uri = `/VoucherAPI/v1/customer/${entityId}/${customerId}/wallet`

  return createClient({
    endpointName: 'getCustomerVouchers',
    expectedResponseStatusCode: 200,
    ...clientParams,
  }).get(uri)
}
