import { createUseStyles } from 'react-jss'

export default createUseStyles({
  emptyStyles: {
    display: 'flex',
    alignItems: 'center',
    '& .ant-empty-image': {
      margin: '10px 20px',
      width: 40,
      height: 25,
    },
  },
})
