import { fontWeights } from 'theme'

export default {
  setHeaderContainer: {
    borderRadius: '6px',
    '& > .ant-card-head': {
      borderRadius: '6px 6px 0 0',
      fontWeight: fontWeights.normal,
      minHeight: '40px',
      padding: '0 8px',
      height: '40px',
      '& > .ant-card-head-wrapper': {
        height: '40px',
        '& > .ant-card-head-title, &> .ant-card-extra': {
          padding: 0,
        },
      },
    },
    '& > .ant-card-body': {
      borderRadius: '0 0 6px 6px',
      padding: '8px',
    },
  },
}
