import React from 'react'
import { useCaptureUserAction } from 'hooks/events/useCaptureUserAction'
import { PlaceholderMap } from 'components/PlaceholderMap/PlaceholderMap'

export type Props = {
  onViewMap?: () => void
  containerClassName?: string
  canViewMap?: boolean
}

export const PlaceholderDeliveryMap = ({ onViewMap, canViewMap, containerClassName }: Props) => {
  const captureUserAction = useCaptureUserAction()

  const handleViewMapClick = () => {
    onViewMap?.()

    captureUserAction('OPEN_VIEW_MAP', {
      screenDetails: {
        section: 'Deliveries',
        tab: 'Order',
      },
    })
  }

  return (
    <PlaceholderMap
      canViewMap={canViewMap}
      containerClassName={containerClassName}
      onViewMap={handleViewMapClick}
    />
  )
}
