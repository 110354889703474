/**
 * Hook to get voucher table row data from voucher
 * */

// libs
import { useContext } from 'react'
// contexts and types
import { DataContext } from 'contexts/data/DataContext'
// utils
import useTransformCustomerVoucher from 'hooks/useTransformCustomerVoucher'
import { Voucher, VoucherValueDataPoint } from 'types/api/voucherApi/voucher'

export const useVoucherRow = (vouchers: Voucher[]) => {
  const { dataState } = useContext(DataContext)
  const { compensatedVoucher, fullyRefundedVoucher } = dataState

  const transformCustomerVoucher = useTransformCustomerVoucher()

  return vouchers.map((voucher) => {
    const voucherDataPoints = transformCustomerVoucher(voucher)

    const validDates = voucherDataPoints.find((dp) => dp.id === 'valid_dates')
    const value = voucherDataPoints.find((dp) => dp.id === 'value') as VoucherValueDataPoint
    const code = voucherDataPoints.find((dp) => dp.id === 'code')
    const description = voucherDataPoints.find((dp) => dp.id === 'description')
    const minimumOrderValue = voucherDataPoints.find(
      (dp) => dp.id === 'min_order_value',
    ) as VoucherValueDataPoint

    // figure out if this is a newly created voucher to alter row background color
    let isNewlyCreatedVoucher = false
    if (
      (compensatedVoucher?.code && compensatedVoucher.code === voucher.code) ||
      (fullyRefundedVoucher?.code && fullyRefundedVoucher.code === voucher.code)
    ) {
      isNewlyCreatedVoucher = true
    }

    return {
      col0: {
        status: voucher?.status,
        description,
        code,
      },
      col1: { minimumOrderValue },
      col2: { validDates },
      col3: { value, voucher },
      isNewlyCreatedVoucher,
      key: voucher?.code,
      voucher,
    }
  })
}
