/**
 * initial entry component to SPA
 * checks localStorage for JWT: renders Google SSO login if JWt is expired or not found
 * */
// libs
import React, { useCallback, useEffect, useRef, useState } from 'react'
import environment from '../envConfig'
import datadogInitiator from 'browserMonitoring/datadogInitiator'

// styles
import 'antd/dist/antd.min.css'
import 'css/override.css'
import styles from './App.styles'
import { createUseStyles } from 'react-jss'
import { Button, Typography } from 'antd'

// components
import { useTranslation } from 'hooks/useTranslation'
import { useAuthProvider } from 'contexts/auth/AuthProvider'

import { useApiClientCreator } from 'contexts/apiClientCreator/ApiClientCreatorContext'
import { getPermissions, getPermissionsV2 } from 'services/authApi/getPermissions'
import jwtDecode from 'jwt-decode'
import { ApiErrorPayload } from 'types/error'
import { FullPageLoadingScreen } from 'components/FullPageLoadingScreen/FullPageLoadingScreen'
import { refreshJwt } from 'utils/refreshJwt'
import { useSearchParams } from 'hooks/useSearchParams'
import { postSsoLogin } from 'services/herocareApi/postSsoLogin'
import { getCookieByName } from 'utils/authHelpers'
import { constructUrl } from 'utils/getSearchParams'
import { getSearchParams } from 'utils/getSearchParams'
import { useCaptureUserAction } from 'hooks/events/useCaptureUserAction'
import { useLogout } from 'hooks/auth/useLogout'
import { useLogin } from 'hooks/auth/useLogin'
import EntityPanel from './EntityPanel'
import noop from 'lodash/noop'

const useStyles = createUseStyles(styles)

export const App: React.FC = () => {
  const searchParams = useSearchParams()
  const [isRefreshingJwt, setIsRefreshingJwt] = useState(false)

  const [isLogginIn, setIsLogginIn] = useState(false)

  const [error, setError] = useState<{
    title?: string
    message: string
    type: 'getPermissionFailed' | 'GoogleAuthSdkLoadFailed' | 'GoogleLoginFailed'
  }>(null)

  const initializedGoogledAuthSdkRef = useRef(false)

  const { isLoggedIn, authDispatch, readAuthState } = useAuthProvider()
  const logout = useLogout()
  const login = useLogin()

  const { createClient } = useApiClientCreator()
  const captureUserAction = useCaptureUserAction()

  // pull translations
  const { t } = useTranslation()

  const onGoogleSigninCallback = useCallback(
    async (credentialResponse: GoogleCredentialResponse) => {
      const { credential } = credentialResponse
      const { email, name } = jwtDecode(credential) as GoogleCredentialJwtPayload

      getPermissions(createClient, {
        globalEntityId: getSearchParams().getGlobalEntityId(),
        googleUser: {
          tokenId: credential,
          profileObj: {
            email,
            name,
          },
        },
      })
        .then((res) => {
          login({
            agentEmail: email,
            agentName: name,
            ...res.data,
          })
        })
        .catch((ex) => {
          setError({
            type: 'getPermissionFailed',
            message: (ex.errorPayload as ApiErrorPayload).errorMessage,
          })
        })
        .finally(() => setIsLogginIn(false))
    },
    [createClient, login],
  )

  /**
   * start signin process
   */
  const startSignin = useCallback(() => {
    captureUserAction('LoginAttempt')
    setError(null)
    setIsLogginIn(true)

    window.google.accounts.id.prompt((notification) => {
      // good events
      if (notification.isDismissedMoment() || notification.isDisplayed()) {
        return
      }

      let errorMessage = 'Unknwon error occurred{{dot}}Ensure you have network connection'

      if (notification.isNotDisplayed()) {
        switch (notification.getNotDisplayedReason()) {
          case 'browser_not_supported':
            errorMessage = 'Your Browser is not supported'
            break

          case 'suppressed_by_user':
            errorMessage = 'You suppressed the login prompt dialogue'
            break

          case 'unregistered_origin':
            errorMessage = 'Origin is not registered'
            break

          case 'secure_http_required':
            errorMessage = 'Https is required, please switch to https'
            break

          case 'invalid_client':
            errorMessage = 'Client id is invalid'
            break

          case 'missing_client_id':
            errorMessage = 'Client id is missing'
            break

          case 'unknown_reason':
            errorMessage = 'Unknown error occured'
            break

          case 'opt_out_or_no_session':
            errorMessage = 'No google session found'
            break
        }
      }

      if (notification.isSkippedMoment()) {
        switch (notification.getSkippedReason()) {
          case 'auto_cancel':
          case 'user_cancel':
          case 'tap_outside':
            errorMessage = 'Error occured. You cancelled login process'
            break

          case 'issuing_failed':
            errorMessage = 'Could not issue credentials'
            break
        }
      }

      setIsLogginIn(false)
      setError({ message: errorMessage, type: 'GoogleLoginFailed' })
    })
  }, [captureUserAction])

  /**
   * checks if google auth sdk is loaded successfully or not.
   */
  const checkGoogleAuthSdkLoadState = useCallback(() => {
    return new Promise((resolve, reject) => {
      let googleAuthSdkLoadCheckCount = 0
      const check = () => {
        googleAuthSdkLoadCheckCount++
        if (window.google?.accounts?.id) {
          return resolve(true)
        }
        if (googleAuthSdkLoadCheckCount >= 4) {
          reject()
        }
        setTimeout(() => check(), 300)
      }

      check()
    })
  }, [])

  /**
   * notify cli of outcome
   */
  const notifyCli = useCallback(
    async (status: 'success' | 'error', token: string) => {
      return postSsoLogin(createClient, {
        port: searchParams.getCliSsoPort(),
        status,
        token,
      }).catch(noop)
    },
    [createClient, searchParams],
  )

  // initialize monitoring
  useEffect(() => {
    datadogInitiator.init()
    captureUserAction('PAGE_DISPLAYED')
  }, [captureUserAction])

  // only runs on first render
  useEffect(() => {
    // refresh agent jwt token the first time they come into app if agent is logged in
    if (isLoggedIn) {
      setIsRefreshingJwt(true)
      refreshJwt(createClient, authDispatch)
        .then(() => {
          if (searchParams.isCliSso()) {
            notifyCli('success', readAuthState().jwt)
          }
        })
        .catch(logout)
        .finally(() => setIsRefreshingJwt(false))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // ensure state cannot overlap
  useEffect(() => {
    if (isLoggedIn && isLogginIn) {
      setIsLogginIn(false)
    }
  }, [isLoggedIn, isLogginIn])

  useEffect(() => {
    if (isLoggedIn) {
      return
    }

    const loginViaOkta = async () => {
      const cfCookie = getCookieByName('CF_Authorization')
      if (!cfCookie) {
        return Promise.reject('no cf cookie found')
      }
      const res = await getPermissionsV2(createClient, {
        globalEntityId: getSearchParams().getGlobalEntityId(),
        tokenId: cfCookie,
      })

      login(res.data)
    }

    setIsLogginIn(true)
    loginViaOkta()
      .catch(() => {
        // fallback to google auth sdk
        if (initializedGoogledAuthSdkRef.current === false) {
          checkGoogleAuthSdkLoadState()
            .then(() => {
              window.google.accounts.id.initialize({
                auto_select: true,
                client_id: environment().sso_client_id,
                ux_mode: 'popup',
                callback: onGoogleSigninCallback,
              })
              initializedGoogledAuthSdkRef.current = true
            })
            .catch(() => {
              setError({
                type: 'GoogleAuthSdkLoadFailed',
                message: 'Unable to load Google Auth SDK. Please reload your screen',
              })
            })
        }
      })
      .finally(() => setIsLogginIn(false))
  }, [isLoggedIn, onGoogleSigninCallback, createClient, login, checkGoogleAuthSdkLoadState])

  const onCliSSoExit = () => {
    const url = constructUrl({
      remove: ['cliSso', 'cliSsoPort'],
    })
    window.location.replace(url)
  }

  const refreshPage = () => {
    window.location.reload()
  }

  const classes = useStyles()
  const { Text } = Typography

  if (isRefreshingJwt) {
    return <FullPageLoadingScreen />
  }

  if (isLogginIn) {
    return (
      <FullPageLoadingScreen>
        <Text>
          <Button className={classes.button} type='primary' onClick={startSignin}>
            {t('Login.Click to try again if login takes long')}
          </Button>
        </Text>
      </FullPageLoadingScreen>
    )
  }

  if (isLoggedIn) {
    if (searchParams.isCliSso()) {
      return (
        <div className={classes.container}>
          <Text className={classes.welcomeText}>{t('Login.Logged in to CLI')}</Text>
          <Text>{t('Login.Return to CLI to continue')}</Text>
          <Button className={classes.button} type='primary' onClick={onCliSSoExit}>
            {t('Login.Exit')}
          </Button>
        </div>
      )
    }
    return <EntityPanel />
  }

  if (error) {
    switch (error.type) {
      case 'GoogleAuthSdkLoadFailed':
        return (
          <div className={classes.container}>
            <Text className={classes.welcomeText}>{t('Login.Google Auth Failed')}</Text>
            <Text>{t(`Login.${error.message}`)}</Text>
            <Button className={classes.button} type='primary' onClick={refreshPage}>
              {t('Interface.Refresh Now')}
            </Button>
          </div>
        )

      default:
        return (
          <div className={classes.container}>
            <Text className={classes.welcomeText}>{t('Login.Login Failed')}</Text>
            <Text>
              {t(`Login.Failed to process login`)}. {t(`Login.${error.message}`)}
            </Text>
            <Button className={classes.button} type='primary' onClick={startSignin}>
              {t('Interface.Try Again')}
            </Button>
          </div>
        )
    }
  }

  return (
    <div className={classes.container}>
      <Text className={classes.welcomeText}>{t('Login.Welcome to OneView Mercury')}</Text>
      <Text>{t('Login.Please Sign In with Your Work Email')}</Text>
      <Button className={classes.button} type='primary' onClick={startSignin}>
        {t('Login.Sign In')}
      </Button>
    </div>
  )
}
