import { RiderStatuses } from 'types/widgets/rider/riderStatuses'

// Constants
import { useTranslation } from 'hooks/useTranslation'
import { CheckCircleFilled, CloseCircleFilled, ExclamationCircleFilled } from '@ant-design/icons'
import { useCallback } from 'react'
import { StatusColors } from 'theme'

type TagColours = typeof StatusColors[keyof typeof StatusColors]

export function useGetRiderStatusTextAndColor() {
  const { t } = useTranslation()

  return useCallback(
    (status: string) => {
      if (!status) {
        return {
          text: t('Order Widget.Tabs.Status.Rider Statuses.Notified'),
          color: StatusColors.red,
        }
      }
      let statusColor: TagColours = StatusColors.red
      let statusText = ''
      let statusIcon = undefined

      switch (status) {
        case RiderStatuses.delivered:
          statusText = t('Order Widget.Tabs.Status.Rider Statuses.Order delivered')
          statusColor = StatusColors.green
          statusIcon = CheckCircleFilled
          break
        case RiderStatuses.pickupTimeUpdated:
          statusText = t('Order Widget.Tabs.Status.Rider Statuses.Courier pickup time updated')
          statusColor = StatusColors.orange
          statusIcon = ExclamationCircleFilled
          break
        case RiderStatuses.pickedUp:
          statusText = t('Order Widget.Tabs.Status.Rider Statuses.Courier picked up')
          statusColor = StatusColors.green
          statusIcon = CheckCircleFilled
          break
        case RiderStatuses.accepted:
          statusText = t('Order Widget.Tabs.Status.Rider Statuses.Courier accepted')
          statusColor = StatusColors.green
          statusIcon = CheckCircleFilled
          break
        case RiderStatuses.undispatched:
          statusText = t('Order Widget.Tabs.Status.Rider Statuses.Courier undispatched')
          statusColor = StatusColors.red
          statusIcon = CloseCircleFilled
          break
        case RiderStatuses.manualUndispatched:
          statusText = t('Order Widget.Tabs.Status.Rider Statuses.Courier manual undispatched')
          statusColor = StatusColors.red
          statusIcon = CloseCircleFilled
          break
        case RiderStatuses.rejected:
          statusText = t('Order Widget.Tabs.Status.Rider Statuses.Courier rejected')
          statusColor = StatusColors.red
          statusIcon = CloseCircleFilled
          break
        case RiderStatuses.canceled:
          statusText = t('Order Widget.Tabs.Status.Rider Statuses.Courier cancelled')
          statusColor = StatusColors.red
          statusIcon = CloseCircleFilled
          break
        case RiderStatuses.notified:
          statusText = t('Order Widget.Tabs.Status.Rider Statuses.Courier notified')
          statusColor = StatusColors.orange
          break
        case RiderStatuses.nearVendor:
          statusText = t('Order Widget.Tabs.Status.Rider Statuses.Courier near vendor')
          statusColor = StatusColors.green
          statusIcon = CheckCircleFilled
          break
        case RiderStatuses.leftPickup:
          statusText = t('Order Widget.Tabs.Status.Rider Statuses.Courier left pickup')
          statusColor = StatusColors.green
          statusIcon = CheckCircleFilled
          break
        case RiderStatuses.nearCustomer:
          statusText = t('Order Widget.Tabs.Status.Rider Statuses.Courier near customer')
          statusColor = StatusColors.green
          statusIcon = CheckCircleFilled
          break
        default:
          statusText = t('Order Widget.Tabs.Status.Rider Statuses.Unknown')
      }
      return {
        text: statusText,
        color: statusColor,
        icon: statusIcon,
      }
    },
    [t],
  )
}
