export default {
  modal: {
    display: 'flex',
    flexDirection: 'column',
    minWidth: 500,
  },
  titleHolder: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#1295e6',
    padding: 20,
    margin: -24,
  },
  modalTitle: {
    fontSize: '16px',
    color: '#fff',
    marginTop: '7px',
  },
  commentHolder: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0px 15px 0px 15px',
  },
  commentTitle: {
    fontSize: '14px',
    fontWeight: 600,
    color: 'rgba(0, 0, 0, 0.85)',
    padding: '5px 0px 5px 0px',
  },
  explanationForApac: {
    fontSize: '14px',
    color: 'rgba(0, 0, 0, 0.65)',
    padding: '25px 0px 25px 0px',
  },
  checkboxesFornonApacHolder: {
    display: 'flex',
    flexDirection: 'column',
    padding: '25px 0px 25px 0px',
  },
  explanationForNonApac: {
    fontSize: '14px',
    color: 'rgba(0, 0, 0, 0.65)',
    marginBottom: '15px',
  },
  checkboxesContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '7px',
  },
  saveButtonHolder: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '7px',
  },
  checkboxIds: {
    fontWeight: 600,
    color: 'rgba(0, 0, 0, 0.85)',
  },
}
