export default {
  panelHeaderContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  panelHeaderContainerInitial: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  panelHeaderTitles: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#fafafa',
    },
  },
  panelHeaderTitlesHighlight: {
    cursor: 'pointer',
    backgroundColor: '#F6FFED',
    borderBottom: '1px solid #ededed',
  },
  panelHeaderDataText: {
    fontSize: '13px',
  },
  panelHeaderDataExpirationText: {
    fontSize: '13px',
  },
  panelHeaderInfoText: {
    fontSize: '13px',
  },
  moreInfoButton: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    fontSize: '10px',
  },
  // MODAL STYLES
  voucherModal: {
    minWidth: 500,
  },
  titleHolder: {
    margin: '-16px -24px',
    height: '60px',
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#1295e6',
    padding: '0 16px',
  },
  modalTitle: {
    color: '#fff',
    fontSize: '20px',
    fontWeight: '500',
  },
  descCol: {
    display: 'flex',
    flexDirection: 'column',
    whiteSpace: 'nowrap',
  },
  validDatesCol: {
    display: 'flex',
    flexDirection: 'column',
    gap: '4px',
    fontSize: '12px',
  },
  startDate: {
    whiteSpace: 'nowrap',
  },
  iconPaddingRight: {
    paddingRight: '3px',
  },
  notApplicable: {
    color: '#0000008c',
  },
  applicable: {
    color: '#000000d9',
  },
  currency: {
    color: '#0000008c',
    fontSize: '12px',
  },
  voucherCode: {
    paddingLeft: '5px',
    fontSize: '12px',
  },
  voucherValue: {
    display: 'flex',
    alignItems: 'center',
  },
  cashbackIcon: {
    width: '24px',
    height: '24px',
    display: 'inline-block',
    paddingLeft: '4px',
  },
  cellStatusContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
}
