/**
 * Table components to show list of voucher
 * */

// libs
import React, { useContext, useState } from 'react'
// configs
// contexts and types
import { EntityContext } from 'contexts/entity/EntityContext'
// hooks
import { useTranslation } from 'hooks/useTranslation'
// utils
// styles
import { createUseStyles } from 'react-jss'
import styles from './VoucherTable.styles'
import { useCanDisplayDataPoint } from 'hooks/useGetValidFeatures'
import { Voucher } from 'types/api/voucherApi/voucher'
import { Table } from 'antd'
import { useVoucherRow } from '../../../../../hooks/useVoucherRowData'
import {
  DescriptionAndStatus,
  MinimumOrderValue,
  ValidDates,
  VoucherValue,
} from '../VoucherTableCellComponents'
import cx from 'classnames'
import { Statuses } from 'types/dataTransformers/customerVouchers'
import { useCaptureUserAction } from 'hooks/events/useCaptureUserAction'
import { PopupWidgetContainer } from 'shared/PopupWidgetContainer'
import SingleVoucherView from 'widgets/Actions/SearchVoucher/SearchVoucherView/VoucherView/SingleVoucherView'
import { WidgetContainerType } from 'types'

const useStyles = createUseStyles(styles)

interface VoucherTableProps {
  listOfVouchers: Voucher[]
  containerType: WidgetContainerType
}
export const VoucherTable: React.FC<VoucherTableProps> = ({ listOfVouchers, containerType }) => {
  const classes = useStyles()

  // pull language content
  const { t } = useTranslation()

  // pull entity config for vouchers from context
  const {
    entityState: {
      entityConfig: {
        scrollable_panel_config: {
          widgets_configs: {
            customer: {
              tab_configs: { vouchers },
            },
          },
        },
      },
    },
  } = useContext(EntityContext)

  const canDisplayDataPoint = useCanDisplayDataPoint()

  const canDisplayVoucherValue = canDisplayDataPoint(null, vouchers.value)
  const canDisplayVoucherValidDates = canDisplayDataPoint(null, vouchers.valid_dates)
  const canDisplayMinOrder = canDisplayDataPoint(null, vouchers.min_order_value)

  const transformedVouchers = useVoucherRow(listOfVouchers)

  // fired after search is clicked -> finds the searched voucher by code and sets local states accordingly
  const captureUserAction = useCaptureUserAction()

  const [searchedVoucher, setSearchedVoucher] = useState(null)

  const handleVoucherRowClick = async (voucher) => {
    setSearchedVoucher(voucher)
    captureUserAction('CustomerVoucherMoreBtnClicked', {
      eventDetails: {
        searched_voucher: voucher,
      },
    })
  }

  return (
    <React.Fragment>
      {searchedVoucher ? (
        <PopupWidgetContainer
          visible
          title={`${t('Widgets Common.Voucher Code')}: ${searchedVoucher?.code}`}
          onClose={() => setSearchedVoucher(null)}
          widgetContainerType={containerType}
        >
          <SingleVoucherView voucher={searchedVoucher} />
        </PopupWidgetContainer>
      ) : null}

      <Table
        className={classes.containerTable}
        dataSource={transformedVouchers}
        pagination={false}
        rowClassName={(record) => {
          const highlight = record?.isNewlyCreatedVoucher
          const applicable = record?.col0?.status === Statuses.applicable
          const className = cx({
            [classes.panelHeaderTitlesHighlight]: highlight,
            [classes.panelHeaderTitles]: !highlight,
            [classes.applicable]: applicable,
            [classes.notApplicable]: !applicable,
          })
          return className
        }}
        onRow={(record) => {
          return {
            onClick: () => {
              handleVoucherRowClick(record.voucher)
            },
          }
        }}
      >
        <Table.Column
          key='first_col'
          title=''
          dataIndex='col0'
          render={(props) => <DescriptionAndStatus {...props} />}
        />

        {canDisplayMinOrder && (
          <Table.Column
            className={classes.minOrder}
            key='second_col'
            title={t('Customer Widget.Tabs.Vouchers.Min order value')}
            dataIndex='col1'
            render={(props) => <MinimumOrderValue {...props} />}
          />
        )}
        {canDisplayVoucherValidDates && (
          <Table.Column
            className={classes.validDates}
            key='third_col'
            title={t('Customer Widget.Tabs.Vouchers.Validity Dates')}
            dataIndex='col2'
            render={(props) => <ValidDates {...props} />}
          />
        )}
        {canDisplayVoucherValue && (
          <Table.Column
            className={classes.voucherValue}
            key='fourth_col'
            title={t('Customer Widget.Tabs.Vouchers.Voucher value')}
            dataIndex='col3'
            render={(props) => <VoucherValue {...props} />}
          />
        )}
      </Table>
    </React.Fragment>
  )
}
