import { ApiErrorConfig } from './voucherApiErrorConfig'

export const authApiErrorConfig: ApiErrorConfig<'AuthApi'> = {
  AuthApi: {
    refreshPermissions: {
      400: {
        '*': {
          screen: 'error',
          heading: 'Your sesson has expired',
          copyText: 'Please sign in again to continue',
        },
      },
    },
  },
}
