import React from 'react'
import { createCustomSvgIcon } from 'factory/createCustomSvgIcon'

export const CircularArrowCheckIcon = createCustomSvgIcon({
  viewBoxWidth: 15,
  viewBoxHeight: 16,
  content: (
    <>
      <g clipPath='url(#circulararrowcheckicon-clip1)'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M1.17557 8.0967C1.17557 11.2574 3.77759 13.8298 7 13.8298C10.2224 13.8298 12.8244 11.2574 12.8244 8.0967C12.8244 6.29829 11.9837 4.69208 10.6632 3.63922C10.4098 3.4372 10.369 3.06898 10.5719 2.81676C10.7749 2.56455 11.1448 2.52386 11.3981 2.72588C12.9831 3.98963 14 5.92551 14 8.0967C14 11.9149 10.8603 15 7 15C3.13967 15 -1.34854e-07 11.9149 -3.01753e-07 8.0967C-4.60609e-07 4.4625 2.84436 1.49245 6.4472 1.21463L6.23033 1.00101C5.99958 0.77371 5.99763 0.403247 6.22596 0.173552C6.4543 -0.0561421 6.82646 -0.0580876 7.05721 0.169208L8.48214 1.57283C8.59369 1.68271 8.65646 1.83243 8.65649 1.98865C8.65651 2.14486 8.59378 2.2946 8.48227 2.40451L7.05733 3.80897C6.82665 4.03633 6.45449 4.0345 6.22609 3.80487C5.99768 3.57524 5.99952 3.20478 6.2302 2.97741L6.85109 2.36545C3.69781 2.44324 1.17557 4.98485 1.17557 8.0967ZM4.20615 7.42879C4.44036 7.20503 4.81245 7.21263 5.03723 7.44577L6.61424 9.08139L9.15003 6.07094C9.35861 5.82333 9.72935 5.79091 9.9781 5.99853C10.2269 6.20616 10.2594 6.5752 10.0508 6.82282L7.09417 10.3329C6.98691 10.4603 6.83032 10.5362 6.66348 10.5418C6.49664 10.5473 6.33529 10.482 6.2197 10.3621L4.18909 8.25608C3.96431 8.02294 3.97194 7.65255 4.20615 7.42879Z'
          fill='currentColor'
        />
      </g>
      <defs>
        <clipPath id='circulararrowcheckicon-clip1'>
          <rect width='16' height='16' fill='none' />
        </clipPath>
      </defs>
    </>
  ),
})
