import React, { FC } from 'react'
import styles from './FullPageLoadingScreen.styles'
import { createUseStyles } from 'react-jss'
import { useTranslation } from 'hooks/useTranslation'
import LoadingView from 'components/LoadingView'

const useStyles = createUseStyles(styles)

export const FullPageLoadingScreen: FC<{ text?: string }> = ({ children, text }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  return (
    <div className={classes.container}>
      <div>
        <LoadingView text={`${text || t('Interface.Loading')}...`} />
        {children}
      </div>
    </div>
  )
}
