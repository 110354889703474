import { builtinWidgetNames, PluggableWidget, WidgetMetadata } from 'types/unitedUiConfig'
import { CancelOrderView } from 'widgets/Actions/CancelOrder/CancelOrderView/CancelOrderView'
import { ChangeCookingInstructionsView } from 'widgets/Actions/ChangeCookingInstructions/ModalView/ModalView'
import { ChangeDeliveryTimeView } from 'widgets/Actions/ChangeDeliveryTime/ModalContent/ModalContent'
import { CompensationView } from 'widgets/Actions/Compensation/Content/Content'
import { FullRefundView } from 'widgets/Actions/FullRefund/FullRefundModalView/FullRefundModalView'
import { ModifyDeliveryAddressView } from 'widgets/Actions/ModifyDeliveryAddress/ModifyDeliveryAddressView/ModifyDeliveryAddressView'
import { MoveDeliveryPendingToQueue } from 'widgets/Actions/MoveDeliveryPendingToQueue/MoveDeliveryPendingToQueue'
import { NewCommentWidgetView } from 'widgets/Actions/NewComment/NewCommentModalView/NewCommentModalView'
import { PartialRefundView } from 'widgets/Actions/PartialRefund/PartialRefundModalView/PartialRefundModalView'
import { SearchOrderView } from 'widgets/Actions/SearchOrder/SearchOrderView/SearchOrderView'
import { SearchVendorView } from 'widgets/Actions/SearchVendor/SearchVendorView/SearchVendorView'
import { SearchVoucherView } from 'widgets/Actions/SearchVoucher/SearchVoucherView/SearchVoucherView'
import Wallet from 'widgets/Customer/NewWallet/Wallet'
import Profile from 'widgets/Customer/Profile'
import Summary from 'widgets/Customer/Summary'
import Voucher from 'widgets/Customer/Voucher'
import HistoryOfEvents from 'widgets/HistoryOfEvents'
import { LastOrdersV2 } from 'widgets/LastOrdersV2/LastOrdersV2'
import BasketUpdatesV2 from 'widgets/Order/BasketUpdatesV2'
import { Deliveries } from 'widgets/Deliveries/Deliveries'
import Invoice from 'widgets/Order/Invoice'
import { SummaryV2 } from 'widgets/Order/SummaryV2/SummaryV2'
import ItemsV2 from 'widgets/Order/ItemsV2'
import Items from 'widgets/Order/Items'
import RiderStackedOrders from 'widgets/RiderStackedOrders/RiderStackedOrders'
import { ResolutionBar } from 'widgets/ResolutionBar/ResolutionBar'
import { DeliveriesV2 } from 'widgets/DeliveriesV2/DeliveriesV2'
import { VendorSummary } from 'widgets/vendorSummary'
import { ChangeDropoffPin } from 'widgets/ChangeDropoffPin/ChangeDropoffPin'
import RiderUpcomingShifts from 'widgets/RiderUpcomingShifts/RiderUpcomingShifts'
import { ChangeDeliveryStatus } from 'widgets/ChangeDeliveryStatus'
import { VendorContacts } from 'widgets/VendorContacts'
import { RiderBreak } from 'widgets/RiderBreak'
import { VendorDevices } from 'widgets/VendorDevices'
import { VendorAddresses } from 'widgets/VendorAddresses'
import { VendorAvailability } from 'widgets/VendorAvailability'
import { SummaryPanel } from 'widgets/SummaryPanel'
import { VendorInvoice } from 'widgets/VendorInvoice'
import { VendorPayout } from 'widgets/VendorPayout'
import { LiveTracking } from 'widgets/LiveTracking'
import { SwitchPayable } from 'widgets/Actions/SwitchPayable/SwitchPayable'

const defaultMetadata: WidgetMetadata<any> = {
  authorEmail: '',
  config: null,
  scriptUrl: '',
  supportSlackChannel: '',
}

export const builtInWidgets: Map<
  builtinWidgetNames,
  {
    component: PluggableWidget<any>
    metadata: WidgetMetadata<any>
  }
> = new Map([
  [
    builtinWidgetNames.resolutionBar,
    {
      component: ResolutionBar,
      metadata: defaultMetadata,
    },
  ],

  // cancel order
  [
    builtinWidgetNames.cancelOrder,
    {
      component: CancelOrderView,
      metadata: defaultMetadata,
    },
  ],
  // search order
  [
    builtinWidgetNames.searchOrder,
    {
      component: SearchOrderView,
      metadata: defaultMetadata,
    },
  ],
  // search vendor
  [
    builtinWidgetNames.searchVendor,
    {
      component: SearchVendorView,
      metadata: defaultMetadata,
    },
  ],

  // search voucher
  [
    builtinWidgetNames.searchVoucher,
    {
      component: SearchVoucherView,
      metadata: defaultMetadata,
    },
  ],

  // new comment
  [
    builtinWidgetNames.newComment,
    {
      component: NewCommentWidgetView,
      metadata: defaultMetadata,
    },
  ],

  // modify delivery address
  [
    builtinWidgetNames.modifyDeliveryAddress,
    {
      component: ModifyDeliveryAddressView,
      metadata: defaultMetadata,
    },
  ],

  //  move delivery to queue
  [
    builtinWidgetNames.moveDeliveryPendingToQueue,
    {
      component: MoveDeliveryPendingToQueue,
      metadata: defaultMetadata,
    },
  ],

  // change cooking instructions
  [
    builtinWidgetNames.changeCookingInstructions,
    {
      component: ChangeCookingInstructionsView,
      metadata: defaultMetadata,
    },
  ],

  // change delivery time
  [
    builtinWidgetNames.changeDeliveryTime,
    {
      component: ChangeDeliveryTimeView,
      metadata: defaultMetadata,
    },
  ],

  // compensation
  [
    builtinWidgetNames.compensation,
    {
      component: CompensationView,
      metadata: defaultMetadata,
    },
  ],

  // full refund
  [
    builtinWidgetNames.fullRefund,
    {
      component: FullRefundView,
      metadata: defaultMetadata,
    },
  ],

  // partial refund
  [
    builtinWidgetNames.partialRefund,
    {
      component: PartialRefundView,
      metadata: defaultMetadata,
    },
  ],

  // orderDeliveries
  [
    builtinWidgetNames.orderDeliveries,
    {
      component: Deliveries,
      metadata: defaultMetadata,
    },
  ],

  [
    builtinWidgetNames.orderDeliveriesV2,
    {
      component: DeliveriesV2,
      metadata: defaultMetadata,
    },
  ],

  // activity
  [
    builtinWidgetNames.activity,
    {
      component: HistoryOfEvents,
      metadata: defaultMetadata,
    },
  ],

  // last orders
  [
    builtinWidgetNames.lastOrders,
    {
      component: LastOrdersV2,
      metadata: defaultMetadata,
    },
  ],

  // order basket update

  [
    builtinWidgetNames.orderUpdates,
    {
      component: BasketUpdatesV2,
      metadata: defaultMetadata,
    },
  ],

  // order summary
  [
    builtinWidgetNames.orderSummary,
    {
      component: SummaryV2,
      metadata: defaultMetadata,
    },
  ],

  // order invoice

  [
    builtinWidgetNames.orderInvoice,
    {
      component: Invoice,
      metadata: defaultMetadata,
    },
  ],

  // customer details
  [
    builtinWidgetNames.customerDetails,
    {
      component: Summary,
      metadata: defaultMetadata,
    },
  ],

  // customer attributes
  [
    builtinWidgetNames.customerAttributes,
    {
      component: Profile,
      metadata: defaultMetadata,
    },
  ],
  // order items
  [
    builtinWidgetNames.orderItems,
    {
      component: Items,
      metadata: defaultMetadata,
    },
  ],

  // order items v2
  [
    builtinWidgetNames.orderItemsV2,
    {
      component: ItemsV2,
      metadata: defaultMetadata,
    },
  ],

  // customer wallet
  [
    builtinWidgetNames.customerWallet,
    {
      component: Wallet,
      metadata: defaultMetadata,
    },
  ],

  // customer vouchers
  [
    builtinWidgetNames.customerVouchers,
    {
      component: Voucher,
      metadata: defaultMetadata,
    },
  ],

  // rider Stacked orders
  [
    builtinWidgetNames.riderStackedOrders,
    {
      component: RiderStackedOrders,
      metadata: defaultMetadata,
    },
  ],
  // rider upcoming shifts
  [
    builtinWidgetNames.riderUpcomingShifts,
    {
      component: RiderUpcomingShifts,
      metadata: defaultMetadata,
    },
  ],
  // change delivery address pin location
  [
    builtinWidgetNames.changeDropoffPin,
    {
      component: ChangeDropoffPin,
      metadata: defaultMetadata,
    },
  ],
  // summary panel
  [
    builtinWidgetNames.summaryPanel,
    {
      component: SummaryPanel,
      metadata: defaultMetadata,
    },
  ],
  // move delivery to next step
  [
    builtinWidgetNames.changeDeliveryStatus,
    {
      component: ChangeDeliveryStatus,
      metadata: defaultMetadata,
    },
  ],
  // vendor summary
  [
    builtinWidgetNames.vendorSummary,
    {
      component: VendorSummary,
      metadata: defaultMetadata,
    },
  ],
  // vendor devices
  [
    builtinWidgetNames.vendorDevices,
    {
      component: VendorDevices,
      metadata: defaultMetadata,
    },
  ],
  // vendor contacts
  [
    builtinWidgetNames.vendorContacts,
    {
      component: VendorContacts,
      metadata: defaultMetadata,
    },
  ],
  // vendor addresses
  [
    builtinWidgetNames.vendorAddresses,
    {
      component: VendorAddresses,
      metadata: defaultMetadata,
    },
  ],
  [
    builtinWidgetNames.riderBreak,
    {
      component: RiderBreak,
      metadata: defaultMetadata,
    },
  ],
  [
    builtinWidgetNames.vendorAvailability,
    {
      component: VendorAvailability,
      metadata: defaultMetadata,
    },
  ],
  [
    builtinWidgetNames.vendorInvoice,
    {
      component: VendorInvoice,
      metadata: defaultMetadata,
    },
  ],
  [
    builtinWidgetNames.vendorPayout,
    {
      component: VendorPayout,
      metadata: defaultMetadata,
    },
  ],
  [
    builtinWidgetNames.switch_payable,
    {
      component: SwitchPayable,
      metadata: defaultMetadata,
    },
  ],
  [
    builtinWidgetNames.liveTracking,
    {
      component: LiveTracking,
      metadata: defaultMetadata,
    },
  ],
])
