export default {
  container: {
    margin: '16px',
  },
  icon: {
    color: 'orange',
  },
  text: {
    paddingLeft: '4px',
    color: 'rgba(0,0,0,.65)',
  },
}
