import { useCallback } from 'react'
/**
 * Component which creates tooltip UI from order's delivery attribute
 */
import { useTranslation } from 'hooks/useTranslation'
import { DeliveryLocation } from 'types/api/orderApi/order'
import { TransformedDataPoint } from 'types/dataTransformers/generic'

export const useGetAddressDetailsToolTipContent = () => {
  const { t } = useTranslation()

  return useCallback(
    (location: DeliveryLocation) => {
      if (!location) return null
      const fullAddressFields: TransformedDataPoint[] = [
        {
          key: t('Widgets Common.Address Details.Address Text'),
          value: location?.address_text ? location.address_text : '',
        },
        {
          key: t('Widgets Common.Address Details.Street'),
          value: location?.street ? location.street : '',
        },
        {
          key: t('Widgets Common.Address Details.Street No'),
          value: location?.street_number ? location.street_number : '',
        },
        {
          key: t('Widgets Common.Address Details.Building'),
          value: location?.building ? location.building : '',
        },
        {
          key: t('Widgets Common.Address Details.District'),
          value: location?.district ? location.district : '',
        },
        {
          key: t('Widgets Common.Address Details.Block'),
          value: location?.block ? location.block : '',
        },
        {
          key: t('Widgets Common.Address Details.City'),
          value: location?.city ? location.city : '',
        },
        {
          key: t('Widgets Common.Address Details.Postal Code'),
          value: location?.postal_code ? location.postal_code : '',
        },
        {
          key: t('Widgets Common.Address Details.Description'),
          value: location?.description ? location.description : '',
        },
        {
          key: t('Widgets Common.Address Details.Geohash'),
          value: location?.geohash ? location.geohash : '',
        },
        {
          key: t('Widgets Common.Address Details.Latitude'),
          value: location?.latitude ? location.latitude : '',
        },
        {
          key: t('Widgets Common.Address Details.Longitude'),
          value: location?.longitude ? location.longitude : '',
        },
        {
          key: t('Widgets Common.Address Details.Entrance'),
          value: location?.entrance ? location.entrance : '',
        },
        {
          key: t('Widgets Common.Address Details.Unit'),
          value: location?.unit ? location.unit : '',
        },
        {
          key: t('Widgets Common.Address Details.Intercom'),
          value: location?.intercom ? location.intercom : '',
        },
        {
          key: t('Widgets Common.Address Details.Floor'),
          value: location?.floor ? location.floor : '',
        },
      ].filter((current) => !!current.value)

      return fullAddressFields
    },
    [t],
  )
}
