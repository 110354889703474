/**
 * tags container for order summary
 * runs config checks and renders available tags
 * */

// libs
import React from 'react'
// configs
import { allowedDataPointValues } from 'entityConfig/allowedConfigValues'
// contexts and types
import { VendorWidgetSummary } from 'contexts/entity/types'
// utils
//styles
import { createUseStyles } from 'react-jss'
import styles from './Tags.styles'
import { useIsDataPointValid } from 'hooks/useGetValidFeatures'
import SingleTag from 'components/Tags/SingleTag'

const useStyles = createUseStyles(styles)

interface Props {
  tags: Array<any>
  summary: VendorWidgetSummary
}

const Tags: React.FC<Props> = ({ tags, summary }) => {
  const classes = useStyles()
  const isDataPointValid = useIsDataPointValid()

  // expects a config array to run entity checks, tag to return, and index to pass to the return DOM el
  const checkTagAvailability = (
    config: Array<string>,
    tag: { value: string | boolean; key: string },
    index: number,
  ) => {
    // check if entity config includes never_display or display_when_data_found (and if value exists)
    if (
      config.includes(allowedDataPointValues.never_display) ||
      (config.includes(allowedDataPointValues.display_when_data_found) && !tag.value)
    ) {
      // return nothing in either case
      return null
    } else {
      // otherwise return tag element with the passed tag key
      return <SingleTag value={String(tag.value)} key={index} />
    }
  }

  return (
    <div className={classes.tagsContainer}>
      {/* map through tags and run entity checks on each */}
      {tags &&
        tags.map((tag: any, index: number) => {
          switch (tag.key) {
            case 'is_active':
              const isActiveAllowed = isDataPointValid(summary.is_active.betaRequirement)

              if (isActiveAllowed) {
                return checkTagAvailability(summary.is_active.displayRule, tag, index)
              }
              return null
            case 'is_halal':
              const isHalalAllowed = isDataPointValid(summary.is_halal.betaRequirement)

              if (isHalalAllowed) {
                return checkTagAvailability(summary.is_halal.displayRule, tag, index)
              }
              return null
            default:
              return checkTagAvailability([], tag, index)
          }
        })}
    </div>
  )
}

export default Tags
