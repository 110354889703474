/**
 * BasketUpdatesSingleProduct expects a single product to render within a timeline Item
 * */

// libs
import React, { useState, useEffect, useContext } from 'react'
// contexts and types
import { DataContext } from 'contexts/data/DataContext'
import { BasketProduct, BasketProductItemDataToppings } from 'types/api/orderApi/orderBasketUpdate'
import {
  ProductTargetApiResponsesT,
  ProductTargetTranslationKeys,
  UpdateActionApiResponsesT,
  UpdateActionTranslationKeysT,
} from 'types/widgets/order/orderBasketUpdates'
// hooks
import { useTranslation } from 'hooks/useTranslation'
import useFixNumber from 'hooks/useFixNumber'

// styles
import { createUseStyles } from 'react-jss'
import styles from './BasketUpdatesSingleProduct.styles'
import { Typography, Tooltip } from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons'
import { logError } from 'utils/reporting/logError'

const useStyles = createUseStyles(styles)

const BasketUpdatesSingleProduct: React.FC<{
  idx: number
  product: BasketProduct
  dateAndTime: string
}> = ({ idx, product, dateAndTime }) => {
  const classes = useStyles()
  const { Text } = Typography

  // pull language content
  const { t } = useTranslation()

  const fixNumber = useFixNumber()

  const {
    dataState: { currency },
  } = useContext(DataContext)

  // constructed target text, such as 'Item Reduced', 'Topping Removed'
  const [target, setTarget] = useState('')
  useEffect(() => {
    if (product?.action && product?.target) {
      // construct the translation key -> starting with either Topping or Item
      let targetTextTranslationKey: string = ProductTargetTranslationKeys[product.target]

      if (!targetTextTranslationKey) {
        targetTextTranslationKey = ProductTargetTranslationKeys.item
        logError({ type: 'missing-product-item-translation-key', product_target: product.target })
      }

      if (
        product.target === ProductTargetApiResponsesT.item &&
        product.item_data?.bundle_items?.length
      ) {
        targetTextTranslationKey = ProductTargetTranslationKeys.bundle
      }

      // appends to the key the action as key
      switch (product.action) {
        case UpdateActionApiResponsesT.reduce:
          targetTextTranslationKey = `${targetTextTranslationKey} ${UpdateActionTranslationKeysT.reduced}`
          break
        case UpdateActionApiResponsesT.increase:
          targetTextTranslationKey = `${targetTextTranslationKey} ${UpdateActionTranslationKeysT.increased}`
          break
        case UpdateActionApiResponsesT.add:
          targetTextTranslationKey = `${targetTextTranslationKey} ${UpdateActionTranslationKeysT.added}`
          break
        case UpdateActionApiResponsesT.remove:
          targetTextTranslationKey = `${targetTextTranslationKey} ${UpdateActionTranslationKeysT.removed}`
          break
        default:
          break
      }

      // set translated target
      const targetText = t(`Order Widget.Tabs.Basket Updates.Actions.${targetTextTranslationKey}`)
      setTarget(targetText)
    }
  }, [product, t])

  // target display name (the updated item/topping name) pulled
  const [targetDisplayName, setTargetDisplayName] = useState('')
  useEffect(() => {
    if (product?.name) {
      setTargetDisplayName(product?.name)
    }
  }, [product])

  // Quantities after and before the update pulled
  const [quantityAfter, setQuantityAfter] = useState('')
  const [quantityBefore, setQuantityBefore] = useState('')
  useEffect(() => {
    // when quantity_after is 0; quantityAfter is not set since it is not supposed to be shown
    if (product?.quantity_after) {
      setQuantityAfter(product.quantity_after.toString())
    }

    // quantityBefore is always shown, if found in api response
    if (product?.quantity_before.toString()) {
      setQuantityBefore(product.quantity_before.toString())
    }
  }, [product])

  // Prices after and before the update pulled
  const [priceAfter, setPriceAfter] = useState('')
  const [priceBefore, setPriceBefore] = useState('')
  useEffect(() => {
    const totalPriceOfAProduct =
      product.total_price_change / (product.quantity_after - product.quantity_before)

    // when quantity_after is 0; priceAfter is not calculated since it is not supposed to be shown
    if (product?.quantity_after && product?.unit_price?.toString()) {
      const priceAfterCalculated = fixNumber(product.quantity_after * totalPriceOfAProduct)
      setPriceAfter(priceAfterCalculated)
    }

    // quantity_before is always shown, if found in api response
    if (product?.quantity_before?.toString() && product?.unit_price?.toString()) {
      const priceBeforeCalculated = fixNumber(product.quantity_before * totalPriceOfAProduct)
      setPriceBefore(priceBeforeCalculated)
    }
  }, [fixNumber, product])

  // Tooltip -> determine if it's needed for the current action and what the content should be
  const [isTooltip, setIsTooltip] = useState(false)
  const [tooltipText, setTooltipText] = useState('')
  useEffect(() => {
    // if target is item and it has toppings
    if (
      product?.target === ProductTargetApiResponsesT.item &&
      product?.item_data?.toppings?.length
    ) {
      setIsTooltip(true)

      const allToppings = product.item_data.toppings.reduce(
        (acc: string, topping: BasketProductItemDataToppings, i: number) => {
          if (i === 0) {
            acc = topping.name
          } else {
            acc = `${acc}, ${topping.name}`
          }

          return acc
        },
        '',
      )

      const tooltipTextConstructed = `${t(
        'Order Widget.Tabs.Basket Updates.Toppings',
      )}: ${allToppings}`
      setTooltipText(tooltipTextConstructed)
    }

    // if target is topping and it has a parent item
    if (product?.target === ProductTargetApiResponsesT.topping && product?.item_data?.name) {
      setIsTooltip(true)
      const tooltipTextConstructed = `${t('Order Widget.Tabs.Basket Updates.Parent Item')}: ${
        product.item_data.name
      }`
      setTooltipText(tooltipTextConstructed)
    }
  }, [product, t])

  return (
    <div key={idx} className={idx === 0 ? classes.firstItemEntry : classes.itemEntry}>
      <div className={classes.topRow}>
        <div className={classes.topRowTargetHolder}>
          {/* TARGET */}
          <Text className={classes.topRowTargetText}>{target}</Text>

          {/* ITEM/TOPPING NAMe */}
          <div className={classes.displayNameAndTooltipHolder}>
            <Text className={classes.topRowTargetContentText}>
              {targetDisplayName ? targetDisplayName : t('Messages.Unknown')}
            </Text>
            {/* TOOLTIP if available */}
            {isTooltip && (
              <Tooltip title={tooltipText} className={classes.tooltip}>
                <InfoCircleOutlined />
              </Tooltip>
            )}
          </div>
        </div>

        {/*  DATE: only in the top row */}
        {idx === 0 && (
          <div>
            <Text className={classes.topRowDateText}>{dateAndTime}</Text>
          </div>
        )}
      </div>

      <div className={classes.contentRow}>
        {/* QUANTITIES */}
        {(quantityBefore || quantityAfter) && (
          <div className={classes.quantitiesHolder}>
            {/* original and new quantities */}
            {quantityBefore && (
              <div className={classes.singleDataFieldHolder}>
                <Text className={classes.bodyTextLabel}>
                  {`${t('Order Widget.Tabs.Basket Updates.Original Quantity')}:`}
                  &nbsp;
                </Text>
                <Text className={classes.bodyTextData}>{quantityBefore}</Text>
              </div>
            )}

            {quantityAfter && (
              <div className={classes.singleDataFieldHolder}>
                <Text className={classes.bodyTextLabel}>
                  {`${t('Order Widget.Tabs.Basket Updates.New Quantity')}:`}
                  &nbsp;
                </Text>
                <Text className={classes.bodyTextData}>{quantityAfter}</Text>
              </div>
            )}
          </div>
        )}

        {/* PRICES */}
        {currency && (priceBefore || priceAfter) && (
          <div className={classes.pricesHolder}>
            {/* original and new prices */}
            {priceBefore && (
              <div className={classes.singleDataFieldHolder}>
                <Text className={classes.bodyTextLabel}>
                  {`${t('Order Widget.Tabs.Basket Updates.Original Price')}:`}
                  &nbsp;
                </Text>
                <Text className={classes.bodyTextData}>{`${currency} ${priceBefore}`}</Text>
              </div>
            )}

            {priceAfter && (
              <div className={classes.singleDataFieldHolder}>
                <Text className={classes.bodyTextLabel}>
                  {`${t('Order Widget.Tabs.Basket Updates.New Price')}:`}
                  &nbsp;
                </Text>
                <Text className={classes.bodyTextData}>{`${currency} ${priceAfter}`}</Text>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  )
}

export default BasketUpdatesSingleProduct
