import { VendorAddress } from 'services/vendorApi/getVendorShippingAddresses'
import { Address, VendorAddressType } from '../../widgets/VendorAddresses/AddressCard/AddressCard'

const AddressTypeMap: Record<string, VendorAddressType> = {
  'Billing Address': 'billing',
  'Shipping Address': 'shipping',
  'Restaurant Address': 'restaurant',
}

export function transformVendorAddress(rawAddress: VendorAddress): Address {
  const addressTypes =
    rawAddress.address_type?.split(';')?.map((type) => AddressTypeMap[type] ?? type) ?? []

  return {
    id: rawAddress.id,
    name: rawAddress.name,
    addressLine: rawAddress.address_line,
    addressTypes: addressTypes,
    areaName: rawAddress.area_name,
    areaType: rawAddress.area_type,
    countryCode: rawAddress.country_code,
    countryName: rawAddress.country_name,
    grid: rawAddress.grid,
    latitude: rawAddress.latitude,
    longitude: rawAddress.longitude,
    postalCode: rawAddress.postal_code,
  }
}
