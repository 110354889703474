import { useTranslation } from 'hooks/useTranslation'
import {
  BasketProduct,
  BasketProductItemData,
  BasketUpdateSource,
  OrderSingleBasketUpdate,
} from 'types/api/orderApi/orderBasketUpdate'
import { logError } from 'utils/reporting/logError'
import { useEntityUtcZone } from './useEntityUtcZone'
import { SDK } from 'types'
import { OrderApiResponse } from 'types/herocare'

export type BasketUpdateTransformedChange = {
  id: string
  lineItemId: string
  name: string
  target: string
  targetAction: string
  partialText: string
  action: BasketProduct['action']
  targetPriceChange: number
  unitPrice: number
  item_data: BasketProductItemData
  maxRestorableQuantity: number
  quantityInBasket: number
}

export type ChangeSet = {
  source: string
  sourceKey: BasketUpdateSource
  reason: string
  time: string
  priceChange: number
  changes: BasketUpdateTransformedChange[]
}

type Props = {
  order: OrderApiResponse
  sdk: SDK
  currency: string
}

const updateSourceTranslationKeys: Map<OrderSingleBasketUpdate['source'], string> = new Map([
  ['vendor', 'Order Widget.Tabs.Basket Updates.Vendor'],
  ['customer', 'Order Widget.Tabs.Basket Updates.Customer'],
  ['logistics', 'Order Widget.Tabs.Basket Updates.Logistics'],
  ['customer_contact_center', 'Order Widget.Tabs.Basket Updates.Customer Service'],
  ['vendor_contact_center', 'Order Widget.Tabs.Basket Updates.Vendor Service'],
])

const implementedActions = new Set<BasketProduct['action']>([
  'remove',
  'add',
  'reduce',
  'increase',
  'price-increase',
  'price-reduce',
  'weight-increase',
  'weight-reduce',
])

const targetTranslationKeyPrefixes: Map<BasketProduct['target'], string> = new Map([
  ['item', 'Item'],
  ['topping', 'Topping'],
  ['bundle', 'Bundle'],
  ['bundle_item', 'Bundle Item'],
])

const actionTranslationKeyPrefixes: Map<BasketProduct['action'], string> = new Map([
  ['reduce', 'Reduced'],
  ['remove', 'Removed'],
  ['increase', 'Increased'],
  ['add', 'Added'],
])

const useTransformBasketUpdatesV2 = ({ order, sdk, currency }: Props) => {
  const DATE_TIME_FORMAT = 'D.M.YYYY, H:mm A'

  const utc_zone = useEntityUtcZone()
  const { t } = useTranslation()
  const {
    basket_updates: basketUpdates,
    order: { items: basketItems },
  } = order

  let updatesSortedNewestToOldest: OrderSingleBasketUpdate[] = []
  //Sort in "latest first" order
  if (basketUpdates?.length) {
    updatesSortedNewestToOldest = basketUpdates.sort(
      (a: OrderSingleBasketUpdate, b: OrderSingleBasketUpdate) =>
        b.timestamp < a.timestamp ? -1 : b.timestamp > a.timestamp ? 1 : 0,
    )
  }

  // Extract the bits relevant to UI. Format and translate
  const changeSets: ChangeSet[] = updatesSortedNewestToOldest.map((update) => {
    const { reason, timestamp, products = [], price_after, price_before } = update ?? {}

    return {
      source: t(updateSourceTranslationKeys.get(update.source) || 'Messages.unknown'),
      sourceKey: update.source,
      reason: reason,
      time: sdk.datetimeFormatter.formatDateTime(timestamp, {
        utcZone: utc_zone,
        format: DATE_TIME_FORMAT,
      }),
      priceChange: price_after.total_order_value - price_before.total_order_value,
      changes: products
        .filter((product) => {
          if (!product) {
            return false
          }

          // if product change action is not implemented, log error to datadog and drop the change
          if (!implementedActions.has(product.action)) {
            logError({
              type: 'unknown-basket-update-product-action',
              product_action: product.action,
            })
            return false
          }

          return true
        })
        .map((product) => {
          const {
            id,
            line_item_id,
            action,
            quantity_after,
            quantity_before,
            item_data,
            name,
            total_price_change,
            weight_after,
            weight_before,
            unit_price,
          } = product

          let target = product?.target

          if (target === 'item' && item_data?.bundle_items?.length) {
            target = 'bundle'
          }

          const actionTranslationKeyPrefix = actionTranslationKeyPrefixes.get(action)
          let targetTranslationKeyPrefix = targetTranslationKeyPrefixes.get(target)

          if (!targetTranslationKeyPrefix) {
            targetTranslationKeyPrefix = targetTranslationKeyPrefixes.get('item')
            logError({
              type: 'missing-product-item-translation-key',
              product_target: target,
            })
          }

          const quantityInBasket =
            basketItems?.find((item) => item?.line_item_id === line_item_id)?.quantity || 0
          let targetActionText: string
          let partialText: string
          const maxRestorableQuantity: number =
            order.restorable_items?.[line_item_id]?.quantity || 0
          let weightChange: number | string

          switch (action) {
            case 'price-increase':
              targetActionText = t(`Order Widget.Tabs.Basket Updates.Actions.Price increased`)
              break

            case 'price-reduce':
              targetActionText = t(`Order Widget.Tabs.Basket Updates.Actions.Price reduced`)
              break

            case 'weight-increase':
              targetActionText = t(`Order Widget.Tabs.Basket Updates.Actions.Weight increased`)
              break

            case 'weight-reduce':
              targetActionText = t(`Order Widget.Tabs.Basket Updates.Actions.Weight reduced`)
              break

            default:
              targetActionText = t(
                `Order Widget.Tabs.Basket Updates.Actions.${targetTranslationKeyPrefix} ${actionTranslationKeyPrefix}`,
              )
              break
          }

          // Extra information in case of additions and reduction. ( i.e , nether complete removal or nor totally new addition )
          switch (action) {
            case 'reduce':
              partialText = `${t(`Order Widget.Tabs.Basket Updates.Actions.Removed`)} ${
                quantity_before - quantity_after
              }/${quantity_before} ${t(`Order Widget.Tabs.Basket Updates.items`)}`

              break

            case 'increase':
              partialText = `${t(`Order Widget.Tabs.Basket Updates.Actions.Added`)} ${
                quantity_after - quantity_before
              } ${t(`Order Widget.Tabs.Basket Updates.items`)}`
              break

            case 'weight-increase':
            case 'weight-reduce':
              weightChange = weight_after.value - weight_before.value
              if (Number(weightChange.toFixed(4)) === 0) {
                partialText = t(`Order Widget.Tabs.Basket Updates.Change negligible`)
              } else {
                partialText = `${weightChange < 0 ? '-' : '+'}${Math.abs(weightChange).toFixed(4)}${
                  weight_after.unit
                }`
              }
              break

            default:
              break
          }

          return {
            id,
            lineItemId: line_item_id,
            name,
            target,
            targetAction: targetActionText,
            partialText,
            action,
            maxRestorableQuantity,
            quantityInBasket,
            unitPrice: unit_price,
            targetPriceChange: total_price_change,
            item_data,
          }
        }),
    }
  })

  const totalAmountChange = changeSets.reduce((acc, changeSet) => {
    return acc + Number(changeSet.priceChange)
  }, 0)

  return {
    grandTotalPriceChange: { currency, amount: totalAmountChange },
    changeSets,
  }
}

export default useTransformBasketUpdatesV2
