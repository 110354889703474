import { ApiErrorConfig } from './voucherApiErrorConfig'

export const ordersApiErrorConfig: ApiErrorConfig<'OrdersApi'> = {
  OrdersApi: {
    postOrderCancellation: {
      400: {
        '*': {
          screen: 'error',
          heading: `Couldn't cancel order`,
          copyText: 'An error has occured{{dot}} Come back later',
        },
        ALREADY_CANCELLED: {
          screen: 'error',
          heading: 'Order already cancelled',
          copyText: 'Refresh Oneview to see the latest order status',
        },
        CANCEL_INELIGIBLE: {
          screen: 'config',
          heading: `Against business rules`,
          copyText: `Cancelling this order isn't possible due to local platform's business rules`,
        },
      },
      401: {
        '*': {
          screen: 'warning',
          heading: `Couldn't cancel order`,
          copyText: 'An error has occured{{dot}} Go back to try again or select Quit to exit',
        },
      },
      403: {
        '*': {
          screen: 'instruct',
          heading: `Permission required`,
          copyText: `Couldn't cancel order{{dot}} Ask a supervisor to check the Oneview permissions and try again`,
        },
      },
      422: {
        ALREADY_CANCELLED: {
          screen: 'error',
          heading: 'Order already cancelled',
          copyText: `Refresh Oneview to see the latest order status`,
        },
        ORDER_TOO_OLD: {
          screen: 'error',
          heading: 'Order placed too long ago',
          copyText:
            "Couldn't cancel as too much time has passed after placing order{{dot}} Ask a supervisor for help cancelling this order",
        },
        CANCEL_INELIGIBLE: {
          screen: 'config',
          heading: `Against business rules`,
          copyText: `Cancelling this order isn't possible due to local platform's business rules`,
        },
      },
      423: {
        ORDER_LOCKED: {
          screen: 'error',
          heading: 'Order locked',
          copyText: `Order is locked right now and cannot be cancelled, try in few seconds`,
        },
      },
      500: {
        '*': {
          screen: 'error',
          heading: `Couldn't cancel order`,
          copyText: `An error has occured{{dot}} Come back later`,
        },
      },
    },

    removeOrderItems: {
      '400': {
        '*': {
          screen: 'error',
          heading: `Couldn't remove the items`,
          copyText: 'An error has occured{{dot}} Come back later',
        },
      },
      '401': {
        '*': {
          screen: 'warning',
          heading: `Authorization failed`,
          copyText:
            'You are not authorised to remove the items{{dot}} Ask a supervisor to check OneView permissions and try again',
        },
      },
      '403': {
        '*': {
          screen: 'instruct',
          heading: 'Permission required',
          copyText:
            'Could not remote the items{{dot}} Ask a supervisor to check OneView permissions and try again',
        },
      },
      '500': {
        '*': {
          screen: 'error',
          heading: `Couldn't remove the items`,
          copyText: 'An error has occured{{dot}} Come back later',
        },
      },

      '422': {
        INVALID_ITEM: {
          screen: 'warning',
          heading: `Couldn't remove the items`,
          copyText: 'Item/topping you want to remove has been removed earlier',
        },
      },
    },

    reportMissingItems: {
      '400': {
        '*': {
          screen: 'error',
          heading: `Couldn't create Wastage case in SF`,
          copyText: 'An error has occured{{dot}} Come back later',
        },
      },
      '401': {
        '*': {
          screen: 'warning',
          heading: `Authorization failed`,
          copyText:
            'You are not authorised to create Wastage case in SF{{dot}} Ask a supervisor to check OneView permissions and try again',
        },
      },
      '403': {
        '*': {
          screen: 'instruct',
          heading: 'Permission required',
          copyText:
            'You are not authorised to create Wastage case in SF{{dot}} Ask a supervisor to check OneView permissions and try again',
        },
      },
      '500': {
        '*': {
          screen: 'error',
          heading: `Couldn't create Wastage case in SF`,
          copyText: 'An error has occured{{dot}} Come back later',
        },
      },
    },

    patchDeliveryAddress: {
      '400': {
        OUT_OF_DELIVERY_AREA: {
          screen: 'warning',
          heading: 'Cannot change delivery address',
          copyText:
            'The new entered address is outside of vendor delivery area, hence it cannot be changed',
        },

        INVALID_ORDER_STATUS: {
          screen: 'error',
          heading: 'Cannot change delivery address',
          copyText:
            'The delivered address cannot be changed when the order status is {{orderStatus}}',
        },
        NOT_OWN_DELIVERY: {
          screen: 'error',
          heading: 'Cannot change delivery address',
          copyText: 'The delivered address can be changed for OwnDelivery (OD) orders only',
        },
        LAT_LONG_MANDATORY: {
          screen: 'alert',
          heading: 'Cannot change delivery address',
          copyText:
            'The delivery address can only be changed when latitute and longitude are found{{dot}} Please go back and try again',
        },
      },
      '404': {
        '*': {
          screen: 'error',
          heading: 'Cannot change delivery address',
          copyText: 'The order is not found in Logistics database',
        },
      },
      '401': {
        '*': {
          screen: 'warning',
          heading: 'Cannot change delivery address',
          copyText: 'Authorization failed{{dot}} Go back to try again or select Quit to exit',
        },
      },
      '409': {
        '*': {
          screen: 'alert',
          heading: 'Cannot change delivery address',
          copyText:
            'Previous modification is still ongoing{{dot}} Go back to try again in a bit or select Quit to exit',
        },
      },

      '429': {
        '*': {
          screen: 'alert',
          heading: 'Cannot change delivery address',
          copyText:
            'Too many concurrent requests{{dot}} Go back to try again in a bit or select Quit to exit',
        },
      },
      '500': {
        '*': {
          screen: 'error',
          heading: 'Cannot change delivery address',
          copyText: 'An error has occured{{dot}} Tech will look into this',
        },
      },
    },

    patchDeliveryInstructions: {
      '400': {
        INVALID_ORDER_STATUS: {
          screen: 'error',
          heading: 'Cannot change delivery instructions',
          copyText:
            'The delivered instructions cannot be changed when the order status is {{orderStatus}}',
        },
        NOT_OWN_DELIVERY: {
          screen: 'error',
          heading: 'Cannot change delivery instuctions',
          copyText: 'The delivered instructions can be changed for OwnDelivery (OD) orders only',
        },
        NO_DATA_CHANGED: {
          screen: 'warning',
          heading: 'Cannot change delivery instructions',
          copyText:
            'The new delivered instructions are the same as the old one, hence no modification is happening',
        },
      },
      '404': {
        '*': {
          screen: 'error',
          heading: 'Cannot change delivery instructions',
          copyText: 'The order is not found in Logistics database',
        },
      },
      '401': {
        '*': {
          screen: 'warning',
          heading: 'Cannot change delivery instructions',
          copyText: 'Authorization failed{{dot}} Go back to try again or select Quit to exit',
        },
      },
      '409': {
        '*': {
          screen: 'alert',
          heading: 'Cannot change delivery instructions',
          copyText:
            'Previous modification is still ongoing{{dot}} Go back to try again in a bit or select Quit to exit',
        },
      },

      '429': {
        '*': {
          screen: 'alert',
          heading: 'Cannot change delivery instructions',
          copyText:
            'Too many concurrent requests{{dot}} Go back to try again in a bit or select Quit to exit',
        },
      },
      '500': {
        '*': {
          screen: 'error',
          heading: 'Cannot change delivery instructions',
          copyText: 'An error has occured{{dot}} Tech will look into this',
        },
      },
    },

    patchCookingInstructions: {
      '400': {
        '*': {
          screen: 'error',
          heading: "Couldn't change cooking instructions",
          copyText: 'An error has occured{{dot}} Come back later',
        },
        NO_DATA_CHANGED: {
          screen: 'warning',
          heading: "Couldn't change cooking instructions",
          copyText: 'New cooking instructions entered matches the existing instructions',
        },
        INVALID_ORDER_STATUS: {
          screen: 'error',
          heading: "Couldn't change cooking instructions",
          copyText:
            'The cooking instructions cannot be changed when the order status is {{orderStatus}}',
        },
        VENDOR_VERTICAL_NOT_SUPPORTED: {
          screen: 'warning',
          heading: "Couldn't change cooking instructions",
          copyText: 'The cooking instructions can only be changed for restaurant orders',
        },
      },
      '409': {
        '*': {
          screen: 'alert',
          heading: 'Change already in progress',
          copyText:
            'Cooking instructions not changed{{dot}} The previous change is still in progress{{dot}} Resubmit in a few minutes',
        },
      },

      '429': {
        '*': {
          screen: 'alert',
          heading: "Couldn't change cooking instructions",
          copyText: 'Too many concurrent requests{{dot}} Resubmit in a few minutes',
        },
      },
      '500': {
        '*': {
          screen: 'error',
          heading: "Couldn't change cooking instructions",
          copyText: 'An error has occured{dot} Come back later',
        },
      },
    },

    patchDeliveryTime: {
      '400': {
        '*': {
          screen: 'error',
          heading: 'Cannot change delivery ETA',
          copyText: 'An error has occured{{dot}} Tech will look into this',
        },
        NOT_OWN_DELIVERY: {
          screen: 'error',
          heading: 'Vendor delivery order',
          copyText:
            "Delivery time not changed{{dot}} Delivery time can't be changed as this order is Vendor delivery",
        },

        INVALID_ORDER_STATUS: {
          screen: 'error',
          heading: "Couldn't change delivery time",
          copyText: "Delivery time can't be changed as order status is {{orderStatus}}",
        },
        DELIVERY_TIME_NOT_IN_FUTURE: {
          screen: 'warning',
          heading: 'Delivery must be in the future',
          copyText:
            'Delivery time not changed{{dot}} New delivery time must be in the future and not equal to current promised delivery time{{dot}}',
        },
        DELIVERY_TIME_LIMIT_REACHED: {
          screen: 'warning',
          heading: 'Delivery must be within next 14 days',
          copyText:
            'Delivery time not changed{{dot}} New delivery time must be in the next 14 days',
        },
        NOT_PRE_ORDER: {
          screen: 'error',
          heading: 'Order must be pre-order',
          copyText:
            'Delivery time not changed{{dot}} Delivery time can only be changed on pre-orders',
        },
      },
      '409': {
        '*': {
          screen: 'alert',
          heading: 'Change already in progress',
          copyText:
            'Delivery time not changed{{dot}} The previous change is still in progress{{dot}} Resubmit in a few minutes',
        },
      },

      '429': {
        '*': {
          screen: 'alert',
          heading: "Couldn't change delivery time",
          copyText: 'Too many concurrent requests{{dot}} Resubmit in a few minutes',
        },
      },
      '500': {
        '*': {
          screen: 'error',
          heading: "Couldn't change delivery time",
          copyText: 'An error has occured{{dot}} Come back later',
        },
      },
    },

    getOrder: {
      '400': {
        '*': {
          screen: 'error',
          heading: 'Order unavailable',
          copyText: 'An error has occured{{dot}} Come back later',
        },
      },

      '401': {
        '*': {
          screen: 'warning',
          heading: 'Order unavailable',
          copyText: 'An error has occured{{dot}} Select refresh to try again',
        },
      },

      '403': {
        '*': {
          screen: 'instruct',
          heading: 'Permission required',
          copyText: 'Ask a supervisor to check Oneview permissions then refresh',
        },
      },

      '404': {
        '*': {
          screen: 'error',
          heading: 'Order not found',
          copyText:
            'Couldn\'t find order "{{orderId}}" in country "{{countryCode}}"{{dot}} Check order ID and country, then search order again',
        },
      },

      '500': {
        '*': {
          screen: 'error',
          heading: 'Order unavailable',
          copyText: 'An error has occured{{dot}} Come back later',
        },
      },
    },
    getOrderFlags: {
      '400': {
        '*': {
          screen: 'error',
          heading: 'Order flags unavailable',
          copyText: 'An error has occured{{dot}} Come back later',
        },
      },

      '401': {
        '*': {
          screen: 'warning',
          heading: 'Order flags unavailable',
          copyText: 'An error has occured{{dot}} Select refresh to try again',
        },
      },

      '403': {
        '*': {
          screen: 'instruct',
          heading: 'Permission required',
          copyText: 'Ask a supervisor to check Oneview permissions then refresh',
        },
      },

      '404': {
        '*': {
          screen: 'error',
          heading: 'Order flags not found',
          copyText:
            'Couldn\'t find order flags for order "{{orderId}}" in country "{{countryCode}}"{{dot}} Check order ID and country, then search order again',
        },
      },

      '500': {
        '*': {
          screen: 'error',
          heading: 'Order flags unavailable',
          copyText: 'An error has occured{{dot}} Come back later',
        },
      },
    },

    getOrderStatusHistory: {
      '400': {
        '*': {
          screen: 'error',
          heading: 'Status history unavailable',
          copyText: 'An error has occured',
        },
      },
      '401': {
        '*': {
          screen: 'warning',
          heading: 'Status history unavailable',
          copyText: 'An error has occured{{dot}} Select refresh to try again',
        },
      },
      '404': {
        '*': {
          screen: 'no-data',
          heading: 'No status published yet',
          copyText: '',
        },
      },
      '500': {
        '*': {
          screen: 'error',
          heading: 'Status history unavailable',
          copyText: 'An error has occured',
        },
      },
    },

    getBackOfficeOrderComments: {
      401: {
        '*': {
          screen: 'warning',
          heading: 'Backoffice comments unavailable',
          copyText: 'An error has occured{{dot}} Select refresh to try again',
        },
      },
      403: {
        '*': {
          screen: 'instruct',
          heading: 'Permission required',
          copyText: 'Ask a supervisor to check Oneview permissions',
        },
      },
      404: {
        '*': {
          screen: 'no-data',
          heading: 'No Backoffice comments found',
          copyText: '',
        },
      },
      500: {
        '*': {
          screen: 'error',
          heading: 'Backoffice comments unavailable',
          copyText: 'An error has occured',
        },
      },
      503: {
        '*': {
          screen: 'error',
          heading: 'Backoffice comments unavailable',
          copyText: 'An error has occured',
        },
      },
    },

    getLastOrders: {
      400: {
        '*': {
          screen: 'error',
          heading: 'Last orders unavailable',
          copyText: 'An error has occured{{dot}} Come back later',
        },
      },
      401: {
        '*': {
          screen: 'warning',
          heading: 'Last orders unavailable',
          copyText: 'An error has occured{{dot}} Select refresh to try again',
        },
      },
      403: {
        '*': {
          screen: 'instruct',
          heading: 'Permission required',
          copyText: 'Ask a supervisor to check Oneview permissions',
        },
      },
      404: {
        '*': {
          screen: 'no-data',
          heading: 'No matching orders found',
          copyText: '',
        },
      },
      500: {
        '*': {
          screen: 'error',
          heading: 'Last orders unavailable',
          copyText: 'An error has occured',
        },
      },
    },
  },
}
