import { useCallback } from 'react'
import { CustomerFraudStatusApiResponse } from 'services/customerApi/getCustomerFraudStatus'
import { useSendEventToEvts } from './useSendEventToEvts'
import { ApiVersionNumber } from 'types'

export const useCreateReportCustomerExploitStatus = () => {
  const sendEventToEts = useSendEventToEvts()
  return useCallback(
    (
      customerFraudStatus: CustomerFraudStatusApiResponse,
      opts: { apiVersion: ApiVersionNumber },
    ) => {
      const { apiVersion } = opts
      const { recommendation_id, error, result } = customerFraudStatus

      const customer_exploit_status: string = String(result) || 'null'

      sendEventToEts('CustomerExploitStatusShown', {
        eventDetails: {
          exploit_recommendation_id: recommendation_id,
          endpoint_version: apiVersion,
          exploit_status_error_occured: error || 'no',
        },
        contactDetails: {
          customer_exploit_status,
        },
      })
    },
    [sendEventToEts],
  )
}
