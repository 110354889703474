import React from 'react'
import { Tooltip } from 'antd'
import { useCanDisplayDataPoint } from 'hooks/useGetValidFeatures'
import { DataPointWithBetaRequirementConfig } from 'contexts/entity/types'
import { TransformedDataPoint } from 'types/dataTransformers/generic'
import { Text } from '../../shared/Text'
import { DataPoint } from '../../shared/DataPoint'
import FraudFlagIcon from 'widgets/Customer/FraudFlagIcon'
import { WarningOutlined } from '@ant-design/icons'
import { useTranslation } from 'hooks/useTranslation'
import { text } from 'theme'
import { summaryWidgetFields } from 'entityConfig/dataPoints/customer'

type Props = {
  item: TransformedDataPoint
  config?: DataPointWithBetaRequirementConfig
  fraudStatus?: string
  errorStatusCode?: number
}

const CustomerNameDataPointListItem = ({
  item,
  config,
  fraudStatus = null,
  errorStatusCode = null,
}: Props) => {
  const { t } = useTranslation()

  const canDisplayDataPoint = useCanDisplayDataPoint()
  const canDisplay = canDisplayDataPoint(
    item.value,
    config || { displayRule: [], betaRequirement: [] },
  )

  if (!canDisplay) {
    return (
      <DataPoint
        type='default'
        label={item.label || item.key}
        value='-'
        name={summaryWidgetFields.customerName}
      />
    )
  }

  const dataPointValue = (
    <Text.Primary fontWeight='400' color={text.primary}>
      {item.value}
      <span style={{ marginLeft: '8px' }}>
        {fraudStatus && <FraudFlagIcon status={fraudStatus} />}
        {errorStatusCode !== null && (
          <Tooltip
            title={t('Widgets Common.Error HTTP{{errorCode}} Couldn’t get fraud status', {
              replace: { errorCode: errorStatusCode },
            })}
          >
            <WarningOutlined />
          </Tooltip>
        )}
      </span>
    </Text.Primary>
  )

  return (
    <DataPoint
      type='custom'
      label={item.label || item.key}
      render={dataPointValue}
      name={summaryWidgetFields.customerName}
    />
  )
}

export default CustomerNameDataPointListItem
