import { useContext } from 'react'
import { DataContext } from 'contexts/data/DataContext'
import { DataAction } from 'contexts/data/types'
import { SessionContext } from 'contexts/session/SessionContext'
import { useApiService } from 'hooks/useApiService'
import { getOrder } from 'services/ordersApi/getOrder'
import { SessionAction } from 'contexts/session/types'

const { SET_RIDER_CURRENT_ORDER } = DataAction

/**
 * this loads the riders current order
 */
export function useLoadRiderCurrentOrder() {
  const {
    sessionDispatch,
    sessionState: { globalEntityId, riderCurrentOrderId },
  } = useContext(SessionContext)

  const { dataDispatch } = useContext(DataContext)

  const riderCurrentOrderInit = useApiService({
    service: getOrder,

    params: {
      orderId: riderCurrentOrderId,
      entityId: globalEntityId,
      clientParams: { cacheable: true },
    },

    shouldLoad: Boolean(riderCurrentOrderId && globalEntityId),

    onSuccess: (res) => {
      dataDispatch({
        type: SET_RIDER_CURRENT_ORDER,
        payload: { riderCurrentOrder: res.data },
      })

      // set rider current vendor id
      if (res.data.vendor?.id) {
        sessionDispatch({
          type: SessionAction.SET_SESSION,
          payload: { riderCurrentOrderVendorId: res.data.vendor.id },
        })
      }
    },
  })
  return riderCurrentOrderInit
}
