import { ApiService } from 'services/authApi/getPermissions'
import { AddressInVendorDeliveryAreaApiResponse } from 'types/api/vendorApi/vendor'

export const addressInVendorDeliveryArea: ApiService<
  {
    entityId: string
    vendorId: string

    latitude: number
    longitude: number
  },
  AddressInVendorDeliveryAreaApiResponse
> = (createClient, options) => {
  const { entityId, latitude, longitude, vendorId, clientParams } = options

  const uri = `/VendorAPI/v1/vendors/${entityId}/${vendorId}/in-delivery-area/${latitude}/${longitude}`

  return createClient({
    endpointName: 'addressInVendorDeliveryArea',
    ...clientParams,
  }).get(uri)
}
