import { OrderApiResponse, OrderHistoryItem } from 'types/api/orderApi/order'
import { OrderStatuses } from 'types/widgets/order/orderStatuses'
import { useCallback } from 'react'
import { useTranslation } from 'hooks/useTranslation'
import { useGetOrderStatusTextAndColor } from '../getters/useGetOrderStatusTextAndColor'

export function useGetOrderStatusMetadata() {
  const { t } = useTranslation()

  const getOrderStatusTextAndColor = useGetOrderStatusTextAndColor()

  return useCallback(
    (
      order: OrderApiResponse,
    ): {
      description: string
      cancellationReason: {
        title: string
        description: string
      }
      reason: string
      reasonLabel: string
      status: OrderHistoryItem
      statusText: string
      statusColor: string
    } => {
      const statusHistory = order?.status_history || []
      const isOrderPaymentPending = order?.pending

      const cancellationReason = {
        title: '',
        description: '',
      }

      if (
        isOrderPaymentPending &&
        !statusHistory.some((current) => current.status === OrderStatuses.pendingOrderFailed)
      ) {
        const { text, color } = getOrderStatusTextAndColor(OrderStatuses.pending)

        return {
          description: t('Order Widget.Tabs.Status.Order Statuses.Payment pending'),

          reason: '',
          reasonLabel: '',

          cancellationReason,

          status: {
            status: OrderStatuses.pending,
          },
          statusColor: color,
          statusText: text,
        }
      }

      const lastStatusHistory = statusHistory[statusHistory.length - 1] || null

      let statusDescription = ''
      let reason = ''
      let reasonLabel = ''

      const status = lastStatusHistory?.status || OrderStatuses.placed

      switch (status) {
        case OrderStatuses.cancelled:
          const reasonCode =
            lastStatusHistory.cancelled?.details?.reason || lastStatusHistory.cancelled?.reason
          cancellationReason.title = t(
            `Order Widget.Tabs.Summary.Cancellation reasons.${reasonCode}.Reason`,
          )
          cancellationReason.description = t(
            `Order Widget.Tabs.Summary.Cancellation reasons.${reasonCode}.Description`,
          )
          break

        case OrderStatuses.pendingOrderFailed:
          statusDescription = t(
            'Order Widget.Tabs.Status.Order Statuses.Payment failed{{dot}} Fraud or prank order',
          )
          reasonLabel = t('Order Widget.Tabs.Summary.Payment failure reason')
          reason =
            lastStatusHistory?.pending_order_failed?.reason ||
            t('Order Widget.Tabs.Summary.Fraud or prank order')
          break
      }

      const { text, color } = getOrderStatusTextAndColor(status)
      return {
        statusColor: color,
        statusText: text,
        description: statusDescription || text,
        reasonLabel,
        reason,
        cancellationReason,
        status: {
          ...lastStatusHistory,
          status,
        },
      }
    },
    [t, getOrderStatusTextAndColor],
  )
}
