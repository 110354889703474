/**
 * custom hook providing a consistent API clients
 * for top level clients, use useFetchData which returns loading, error, and data state
 * for other levels, use createClient with appropriate provider config
 * */

// libs
import { useState, useEffect, useContext } from 'react'
import axios, { AxiosInstance, CancelToken } from 'axios'
import axiosRetry from 'axios-retry'
// contexts and types
import { ApiContext } from 'contexts/api/ApiContext'
import { ApiContextProps } from 'contexts/api/types'
// utils
import createOneviewHeaders from 'utils/oneviewApi/createOneviewHeaders'
import ensureJwtIsValid from 'utils/oneviewApi/ensureJwtIsValid'

interface ApiError extends Error {
  response: {
    status: number
  }
}

// DEPRECATED. Use useApiClientCreator instead
export function createClient(
  config: ApiContextProps = { host: '', retry: 3 },
  cancelToken?: CancelToken,
): AxiosInstance {
  const client = axios.create({
    baseURL: config.host,
    cancelToken,
  })
  axiosRetry(client, {
    retries: config.retry,
  })
  return client
}

export function useFetchData<T>(
  path: string,
  config?: ApiContextProps,
  deps: React.DependencyList = [],
) {
  const [isLoading, setIsLoading] = useState(false)
  const [data, setData] = useState<T | undefined>()
  const [error, setError] = useState<ApiError | undefined>()

  const providerConfig = useContext(ApiContext)
  const clientConfig = Object.assign({}, providerConfig, config)

  useEffect(() => {
    let unmounted = false

    ensureJwtIsValid().then((tokenIsValid) => {
      if (tokenIsValid) {
        setIsLoading(true)

        const client = createClient(clientConfig)
        const headers = createOneviewHeaders()

        client
          .get(path, headers)
          .then((res) => {
            if (unmounted) return
            setIsLoading(false)
            setData(res.data)
          })
          .catch((e) => {
            if (unmounted) return
            setIsLoading(false)
            setError(e)
          })
      }
    })

    const cleanup = () => {
      unmounted = true
    }

    return cleanup
    // eslint-disable-next-line
  }, deps)

  return {
    data,
    error,
    isLoading,
  }
}
