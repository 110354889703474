import { ApiService } from 'services/authApi/getPermissions'

export type UnifiedVendorAvailabilityStatus = 'Closed' | 'Open' | 'Unavailable' | 'Offline'

export type VendorAvailabilityScheduleStatus =
  | 'VENDOR_SCHEDULE_STATUS_OPEN'
  | 'VENDOR_SCHEDULE_STATUS_INVALID'
  | 'VENDOR_SCHEDULE_STATUS_CLOSED'
  | 'VENDOR_SCHEDULE_STATUS_OFFLINE'
  | 'VENDOR_SCHEDULE_STATUS_SCHEDULE_NOT_FOUND'

export type VendorAvailabilityGeoStatus =
  | 'CUSTOMER_VENDOR_GEO_STATUS_INVALID'
  | 'CUSTOMER_VENDOR_GEO_STATUS_AVAILABLE'
  | 'CUSTOMER_VENDOR_GEO_STATUS_OUTSIDE_DELIVERY_AREA'
  | 'CUSTOMER_VENDOR_GEO_STATUS_VENDOR_UNDER_LOCKDOWN'
  | 'CUSTOMER_VENDOR_GEO_STATUS_CUSTOMER_UNDER_LOCKDOWN'
  | 'CUSTOMER_VENDOR_GEO_STATUS_VENDOR_UNDER_SHRINKING'
  | 'CUSTOMER_VENDOR_GEO_STATUS_CLOSED_FOR_CUSTOMER'

export type VendorAvailabilityOfflineStatus =
  | 'OFFLINE_STATUS_UNAVAILABLE'
  | 'OFFLINE_STATUS_INVALID'
  | 'OFFLINE_STATUS_AVAILABLE'

export type VendorAvailabilityOfflineReason =
  | 'TOO_BUSY_NO_DRIVERS'
  | 'TOO_BUSY_KITCHEN'
  | 'UPDATES_IN_MENU'
  | 'UNREACHABLE'
  | 'TECHNICAL_PROBLEM'
  | 'CLOSED'
  | 'OTHER'
  | 'TOO_MANY_REJECTED_ORDERS'
  | 'ORDER_FAILURE'
  | 'COURIER_DELAYED_AT_PICKUP'
  | 'RESTRICTED_VISIBILITY'
  | 'BAD_WEATHER'
  | 'HOLIDAY_SPECIAL_DAY'
  | 'ONBOARDING'
  | 'READY_TO_GO_ONLINE'
  | 'OFFBOARDING'
  | 'RETENTION'
  | 'COMPLIANCE_ISSUES'
  | 'OWNERSHIP_CHANGE'
  | 'REFURBISHMENT'
  | 'FOOD_HYGIENE'
  | 'FRAUD'
  | 'RELIGIOUS_OBSERVANCE'
  | 'CHECK_IN_REQUIRED'
  | 'CHECK_IN_FAILED'
  | 'AREA_DISRUPTION'
  | 'CHECK_OUT'

export interface VendorAvailability {
  vendor_id: string
  schedule_status: VendorAvailabilityScheduleStatus
  geo_status: VendorAvailabilityGeoStatus
  offline_status: VendorAvailabilityOfflineStatus
  offline_reason?: string
  fallback_pricing_response: {
    delivery_fee: number
    minimum_order_value: number
  }
  vendor_fee?: {
    vendor_id: string
    currency: string
    delivery_fee: {
      total: number
      travel_time: number
      fleet_utilization: number
      basket_value_fee: {
        current_fee_value: number
        next_fee_value: number
        next_threshold: number
        difference_threshold: number
      }
      minimum_delivery_fee: {
        type: string
        basket_value_threshold: number
        delivery_fee_total: number
        discount: number
      }
      standard_fee: {
        total: number
      }
    }
    minimum_order_value: {
      total: number
      travel_time: number
      fleet_utilization: number
    }
    service_fee: {
      total: number
      setup_value: number
      type: string
      min_value: number
      max_value: number
    }
    fee_reason: {
      fee_reason_type: VendorAvailabilityOfflineReason
    }
  }
  fallback_time_estimate_response: {
    delivery_time: number
    applied_delivery_area_event_id: string
    available_at_in_vendor_local_time: string
  }
}

export const getVendorAvailability: ApiService<
  { entityId: string; vendorId?: string },
  VendorAvailability
> = (createClient, options) => {
  const { entityId, clientParams, vendorId } = options
  const uri = `/VendorAPI/v1/vendors/${entityId}/${vendorId}/availability`

  return createClient({
    endpointName: 'getVendorAvailability',
    expectedResponseStatusCode: 200,
    ...clientParams,
  })
    .post(uri)
    .then((res) => {
      return {
        ...res,
        data: res.data.vendor,
      }
    })
}
