import React from 'react'
import { createUseStyles } from 'react-jss'
import { Alert, Divider, Typography } from 'antd'

import fixNumber from 'utils/fixNumber'
import VoucherDetails from './VoucherDetails'
import { useTranslation } from 'hooks/useTranslation'
import styles from './Refund.styles'

const useStyles = createUseStyles(styles)

type Props = {
  currency: string
  totalRefundAmount: number
  totalRemovalAmount: number
  compensationAmount: number
  refundAmountPrecision: number
  voucherMinOrderValue: number
  voucherPaymentTypes: string
  voucherBeginDate: string
  voucherEndDate: string
  useFlexibleAmount?: boolean
}

const PspWalletRefundMethod = ({
  currency,
  totalRefundAmount,
  compensationAmount,
  totalRemovalAmount,
  refundAmountPrecision,
  voucherMinOrderValue,
  voucherPaymentTypes,
  voucherBeginDate,
  voucherEndDate,
  useFlexibleAmount,
}: Props) => {
  const classes = useStyles()
  const { Text } = Typography

  // pull translations
  const { t } = useTranslation()
  return (
    <React.Fragment>
      {/* total removal amount reported in Report */}
      {!useFlexibleAmount ? (
        <div className={classes.textBlock}>
          <Text className={classes.titleText}>
            {`${t('Actions Widget.Actions.Full Refund.Items Amount')}:`}
          </Text>
          <Text className={classes.dataText}>{`${currency} ${fixNumber(
            totalRemovalAmount,
            refundAmountPrecision,
          )}`}</Text>
        </div>
      ) : null}

      {useFlexibleAmount ? (
        <div className={classes.textBlock}>
          <Text className={classes.titleText}>
            {`${t('Actions Widget.Actions.Full Refund.Refund Amount')}:`}
          </Text>
          <Text className={classes.dataText}>{`${currency} ${fixNumber(
            totalRefundAmount,
            refundAmountPrecision,
          )}`}</Text>
        </div>
      ) : null}

      {compensationAmount && compensationAmount > 0 ? (
        <div className={classes.textBlock}>
          <Text className={classes.titleText}>
            {`${t('Actions Widget.Actions.Full Refund.Compensation Amount')}:`}
          </Text>
          <Text className={classes.dataText}>{`${currency} ${fixNumber(
            compensationAmount,
            refundAmountPrecision,
          )}`}</Text>
        </div>
      ) : null}

      {/* total refund amount -> either only wallet/source or wallet/source + voucher */}
      <div className={classes.textBlockTotalAmount}>
        {compensationAmount && compensationAmount > 0 ? (
          <React.Fragment>
            <Text className={classes.titleText}>
              {`${t(
                'Actions Widget.Actions.Partial Refund.Total Refund and Compensation Amount',
              )}:`}
            </Text>
            <Text className={classes.dataTextTotalAmount}>{`${currency} ${fixNumber(
              totalRefundAmount + compensationAmount,
              refundAmountPrecision,
            )}`}</Text>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Text className={classes.titleText}>
              {`${t('Actions Widget.Actions.Full Refund.Total Refund Amount')}:`}
            </Text>
            <Text className={classes.dataTextTotalAmount}>{`${currency} ${fixNumber(
              totalRefundAmount,
              refundAmountPrecision,
            )}`}</Text>
          </React.Fragment>
        )}
      </div>

      <Divider />
      {/* details of Voucher, in addition to refund to wallet/source */}
      {compensationAmount && compensationAmount > 0 ? (
        <React.Fragment>
          <VoucherDetails
            currency={currency}
            totalRefundAmount={fixNumber(compensationAmount, refundAmountPrecision)}
            voucherMinOrderValue={fixNumber(voucherMinOrderValue, refundAmountPrecision)}
            voucherPaymentTypes={voucherPaymentTypes}
            validDates={`${voucherBeginDate} – ${voucherEndDate}`}
          />
          <div className={classes.alertContainer}>
            <Alert
              message={t(
                'Actions Widget.Actions.Full Refund.The customer will receive the compensation voucher immediately',
              )}
              type='info'
              showIcon
            />
          </div>
        </React.Fragment>
      ) : null}
    </React.Fragment>
  )
}

export default PspWalletRefundMethod
