import React from 'react'
import { createUseStyles } from 'react-jss'
import { Flex, Text, Button } from 'shared'
import { CheckOutlined } from 'Icons'
import { Divider, Timeline } from 'antd'
import styles from './RiderBreakSuccess.styles'
import { useTranslation } from 'hooks/useTranslation'

const useStyles = createUseStyles(styles)

type RiderBreakSuccessProps = {
  duration: string
  reason: string
  comment: string | undefined
  onDone: () => void
}
export const RiderBreakSuccess: React.FC<RiderBreakSuccessProps> = ({
  duration,
  reason,
  comment = '',
  onDone,
}) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <Flex flexDirection='column' rowGap='24px' py='24px' pl='24px'>
      <Timeline>
        <Timeline.Item
          dot={
            <Flex className={classes.greenCheckCircledIconContainer}>
              <CheckOutlined className={classes.greenCheckCircledIcon} />
            </Flex>
          }
        >
          <Flex columnGap='24px' ml='24px'>
            <Flex flexDirection='column' rowGap='8px'>
              <Text.Primary fontSize='24px' fontWeight='500' lineHeight='32px'>
                {t(`widgets.rider_break.success.heading`)}
              </Text.Primary>

              <Flex></Flex>
            </Flex>
          </Flex>
        </Timeline.Item>
        {/* Second row */}
        <Timeline.Item
          dot={
            <Flex>
              <CheckOutlined className={classes.checkIcon} />
            </Flex>
          }
        >
          <Flex flexDirection='column' rowGap='8px' ml='24px'>
            <Text.Primary fontSize='14px' fontWeight='400'>
              {t(`widgets.rider_break.success.message`)}{' '}
              <Text.Primary fontWeight={'700'} display='inline-block'>
                {duration}
              </Text.Primary>
            </Text.Primary>
            <Divider className={classes.dividerMargin} />
            <Flex flexDirection='column' rowGap={'16px'}>
              <Flex flexDirection='column'>
                <Flex>
                  <Text.Secondary>{t(`widgets.rider_break.labels.reason`)}</Text.Secondary>
                </Flex>
                <Flex>{reason}</Flex>
              </Flex>
              {comment && (
                <Flex flexDirection='column'>
                  <Flex>
                    <Text.Secondary>{t(`widgets.rider_break.comment`)}</Text.Secondary>
                  </Flex>
                  <Flex>{comment}</Flex>
                </Flex>
              )}
            </Flex>
          </Flex>
        </Timeline.Item>
      </Timeline>

      <Flex justifyContent='flex-end'>
        <Button type='solid' onClick={onDone}>
          {t('Interface.Done')}
        </Button>
      </Flex>
    </Flex>
  )
}
