import React, { ReactNode, useMemo, useRef, useState } from 'react'
import { Tabs } from 'antd'
import { UnifiedIcon } from 'shared/UnifiedIcon'
import {
  ScreenName,
  TabbedWidgetsContainerWidgetDefinition,
  WidgetName,
} from 'types/unitedUiConfig'

import { RootWidgetView } from 'components/RootWidgetView'
import { useTabsStyles } from 'hooks/styles/useTabsStyles'
import { useForceUpdate } from 'contexts/useForceUpdate'
import { getWidgetId } from 'utils/getters/getWidgetId'
import { useCheckDisplayRules } from 'hooks/useCheckDisplayRules'
import { RootWidgetContainer } from 'components/RootWidgetView/RootWidgetContainer'
import { getWidgetCategory } from 'utils/getters/getWidgetCategory'
import { useCaptureUserAction } from 'hooks/events/useCaptureUserAction'
import { useWidgetLabelTranslator } from 'hooks/widgetView/useWidgetLabelTranslator'

interface TabbedWidgetsProps {
  widgetDefinition: TabbedWidgetsContainerWidgetDefinition
  screenName: ScreenName
}

export const TabbedWidgets = ({ widgetDefinition, screenName }: TabbedWidgetsProps) => {
  const widgetLabelTranslator = useWidgetLabelTranslator()
  const { label, tabbed_widgets, collapse_state, elevated } = widgetDefinition

  const containerlabel = widgetLabelTranslator.translate(label.label_translation_key)

  const checkDisplayRules = useCheckDisplayRules()
  const captureUserAction = useCaptureUserAction()

  const visibleWidgets = useMemo(() => {
    return tabbed_widgets
      .filter((current) => checkDisplayRules(current.display_rules).visible)
      .map((current) => {
        const widgetId = getWidgetId(current)
        return {
          widgetId,
          widgetCategory: getWidgetCategory(widgetId as WidgetName),
          widget: current,
        }
      })
  }, [tabbed_widgets, checkDisplayRules])

  const forceUpdate = useForceUpdate()

  const tabMetaDefinitionsRef = useRef<Map<string, { title: ReactNode }>>(
    new Map(
      visibleWidgets.map(({ widgetId, widget }) => [
        widgetId,
        { title: widgetLabelTranslator.translate(widget.label.label_translation_key) },
      ]),
    ),
  )

  const styles = useTabsStyles({ xPadding: 0, borderWidth: 1 })

  const [activeTab, setActiveTab] = useState(visibleWidgets[0]?.widgetId)

  const tabMetaDefinitions = tabMetaDefinitionsRef.current

  if (visibleWidgets.length === 0) {
    return null
  }

  const onTabChange = (tabWidgetId) => {
    const { widgetCategory, widgetId } = visibleWidgets.find(
      (current) => current.widgetId === tabWidgetId,
    )
    captureUserAction('OPEN_WIDGET', {
      eventDetails: {
        widget_id: widgetId,
        widget_category: widgetCategory,
      },
    })
    setActiveTab(tabWidgetId)
  }

  return (
    <RootWidgetContainer
      widgetId={getWidgetId(widgetDefinition)}
      label={containerlabel}
      hideLabel={!checkDisplayRules(label.display_rules).visible}
      iconName={label.icon_name}
      collapseState={collapse_state}
      paddingTop={0}
      actionHandlers={label.action_handlers}
      elevated={Boolean(elevated)}
      renderState='rendered'
    >
      <Tabs activeKey={activeTab} onChange={onTabChange} className={styles.tabs} tabBarGutter={32}>
        {visibleWidgets.map(({ widget, widgetId }) => {
          return (
            <Tabs.TabPane
              key={widgetId}
              tab={
                <div role='button' className='widget-tab-button' data-widget-name={widgetId}>
                  <UnifiedIcon icon={widget.label.icon_name} mr={8} />
                  {tabMetaDefinitions.get(widgetId).title}
                </div>
              }
            >
              <RootWidgetView
                widgetDefinition={widget}
                screenName={screenName}
                tabContainerLabel={containerlabel}
                onStateChange={(state) => {
                  // update tab title if it changes
                  const tabMetaDefinition = tabMetaDefinitions.get(widgetId)
                  if (tabMetaDefinition.title !== state.title) {
                    tabMetaDefinition.title = state.title
                    forceUpdate()
                  }
                }}
                hideLabel
                marginTop={0}
                paddingTop={16}
              />
            </Tabs.TabPane>
          )
        })}
      </Tabs>
    </RootWidgetContainer>
  )
}
