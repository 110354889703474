/**
 * expects order API's response
 * alters and returns the response with unique order item IDs and item option IDs
 * */

import { OrderApiResponse, ItemsItem, ItemOption } from 'types/api/orderApi/order'

function createOrderItemAndToppingIndexes(order: OrderApiResponse) {
  // check if items exist in the order
  if (order && order.order && order.order.items && order.order.items.length > 0) {
    // if they do, map over them
    order.order.items.forEach((item: ItemsItem) => {
      // random number attached to generated item IDs
      const randomIdSectionForItem = Math.floor(Math.random() * Math.floor(1000))
      const uniqueItemId = `${randomIdSectionForItem}-${item.id}`
      item.id = uniqueItemId

      // check if options exist in an item
      if (item.options && item.options.length > 0) {
        // if they do, map over them
        item.options.forEach((option: ItemOption) => {
          // random number attached to generated topping IDs
          const randomIdSectionForTopping = Math.floor(Math.random() * Math.floor(1000))
          const uniqueOptionId = `${randomIdSectionForTopping}-${option.id}`
          option.id = uniqueOptionId
        })
      }
    })
  }

  return order
}

export default createOrderItemAndToppingIndexes
