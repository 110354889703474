export default {
  container: {
    padding: '30px 30px 30px 30px',
  },
  titlesItemLabelAndValueContainer: {
    padding: '8px 10px 8px 16px',
    backgroundColor: '#fafafa',
  },
  titlesItemText: {
    color: 'rgba(0, 0, 0, 0.85)',
    fontSize: '13px',
  },
  labelHolder: {
    display: 'flex',
    flexDirection: 'column',
    width: '250px',
    minWidth: '250px',
    marginLeft: 12,
  },
  topingsHolder: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: '40px',
    marginTop: '10px',
  },
  descLabel: {
    display: 'flex',
    alignItems: 'baseline',
    color: 'rgba(0, 0, 0, 0.65)',
    fontSize: '13px',
  },
  icons: {
    marginRight: '7px',
    fontSize: '10px',
    color: 'rgba(0, 0, 0, 0.85)',
  },
  descLabelTopping: {
    color: 'rgba(0, 0, 0, 0.65)',
    fontSize: '13px',
    marginBottom: '7px',
  },
  buttonHolder: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '50px',
  },
  successMaintext: {
    color: 'rgba(0, 0, 0, 0.85)',
    fontSize: '24px',
  },
  successHolder: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    margin: '20px 0px 0px 0px',
  },
  successSubtext: {
    color: 'rgba(0, 0, 0, 0.65)',
    fontSize: '14px',
  },
  successButtonHolder: {
    display: 'flex',
    justifyContent: 'center',
    margin: '30px 0px 0px 0px',
  },
  successButton: {
    width: '50px',
  },
}
