// all available configurable data points in customer widget

// summary tab
export enum summaryWidgetFields {
  customerName = 'customerName',
  phoneNumber = 'phoneNumber',
  customerTags = 'customerTags',
  customerId = 'customerId',
  emailId = 'emailId',
  address = 'address',
}
export const fieldsConfig = [
  summaryWidgetFields.customerName,
  summaryWidgetFields.emailId,
  summaryWidgetFields.customerId,
  summaryWidgetFields.phoneNumber,
  summaryWidgetFields.address,
  summaryWidgetFields.customerTags,
]
