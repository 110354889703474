import React from 'react'
import { useDeliveryPanelStyles } from './styles'
import { Col, Row } from 'antd'
import { getPublicUrl } from 'utils/getPublicUrl'
import { useTranslation } from 'hooks/useTranslation'
import { Text } from 'shared'

type Props = {
  message?: string
}

export const NoDeliveryPanelHeader = ({ message }: Props) => {
  const { t } = useTranslation()
  const classes = useDeliveryPanelStyles()
  return (
    <Row className={classes.header} gutter={[20, 0]} justify='center' align='middle'>
      <Col>
        <img src={getPublicUrl('imgs/Empty.png')} alt='Empty data illustration' />
      </Col>
      <Col>
        <Text.Primary>
          {message ||
            t('widgets.order_deliveries.deliveries.rider_info_not_available_after_6_hours')}
        </Text.Primary>
      </Col>
    </Row>
  )
}
