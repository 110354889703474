/**
 * Compensation modal, rendered upon click in actions
 * renders a modal where compensation is executed
 * */

// libs
import React, { useState, useContext, useMemo, useEffect } from 'react'
import { createUseStyles } from 'react-jss'
import { Typography, Button, Input, Tooltip } from 'antd'
import { EditOutlined } from '@ant-design/icons'

// contexts and types
import { LoadState } from 'types'
import { ApiErrorPayload } from 'types/error'
import { CompensationMethod } from 'types/actions/compensation'
import VoucherApiSingleVoucher from 'types/api/voucherApi/getSingleVoucher'
import { IssueType } from 'contexts/entity/types'
import { DataContext } from 'contexts/data/DataContext'
import { AvailableCompensationMethods } from 'entityConfig/allowedConfigValues'

// hooks
import { useTranslation } from 'hooks/useTranslation'
import { useFixedPanelActionsWidgetConfigs } from 'hooks/useFixedPanelActionsWidgetConfig'
import { useEntityUtcZone } from 'hooks/useEntityUtcZone'
import { useGetCustomerCompensationsAndRefunds } from 'hooks/apiHooks/useGetCustomerCompensationsAndRefunds'
import { useCaptureUserAction } from 'hooks/events/useCaptureUserAction'

// utils
import fixNumber from 'utils/fixNumber'
import { filterByCcrWhitelist } from 'utils/ccrWhitelistFilter'
import { calculateGranularValue } from 'utils/calculateGranularValue'

// constants
import {
  SHOW_COMP_VALUE_EDITOR,
  COMP_VALUE_ID,
  EDIT_COMP_VALUE_ID,
  COMP_VALUE_EDIT_MSG_ID,
  COMP_VALUE_INPUT_ID,
  COMP_MOV_ID,
  CREATE_COMP_ID,
  COMP_VOUCHER_VALIDITY_ID,
} from 'constants/widgetIds/compensationWidgetIds'

// styles
import styles from './CompensationModalView.styles'

// components
import { IssueTypeSelect } from './IssueTypeSelect'
import { CompensationMethodSelect } from './CompensationMethodSelect'
import Confirmation from './Confirmation'
import InfoAlertView from 'components/InfoAlertView'
import { WidgetErrorHandler } from 'components/WidgetErrorHandler/widgetErrorHandler'

const useStyles = createUseStyles(styles)

interface Props {
  issueTypes: IssueType[]
  compensationMethods: CompensationMethod[]

  selectedIssueType: IssueType
  setSelectedIssueType: (issue: IssueType) => void

  isVerifyingRefundMethods: boolean
  isLoadingProposedValue: boolean
  getProposedVoucherError: {
    errorPayload: ApiErrorPayload
  }

  minOrderValue: number

  minVoucherValue: number
  maxVoucherValue: number

  voucherValue: number
  setVoucherValue: (number) => void

  allowVoucherEdit: boolean
  onQuit: () => void

  selectedCompensationMethod: CompensationMethod
  setSelectedCompensationMethod: (method: CompensationMethod) => void

  creatingVoucher: boolean
  compensatingToWallet: boolean

  clearErrors: () => void

  processCompensation: () => void

  createdVoucher: VoucherApiSingleVoucher

  errorPayload: ApiErrorPayload

  createCompensationToWalletStatus: LoadState
  createVoucherStatus: LoadState

  ccrCode: string

  useCcrWhitelistForCompensationIssues: boolean

  voucherBeginDate: string
  voucherEndDate: string
  paymentTypes: string
}

const CompensationModalView: React.FC<Props> = ({
  issueTypes,
  compensationMethods,

  minOrderValue,

  isVerifyingRefundMethods,
  isLoadingProposedValue,
  getProposedVoucherError,

  setSelectedIssueType,
  selectedIssueType,

  voucherValue,
  setVoucherValue,
  allowVoucherEdit,

  onQuit,

  selectedCompensationMethod,
  setSelectedCompensationMethod,

  creatingVoucher,
  compensatingToWallet,

  clearErrors,
  processCompensation,
  createdVoucher,

  errorPayload,

  ccrCode,
  createCompensationToWalletStatus,
  createVoucherStatus,
  useCcrWhitelistForCompensationIssues,

  voucherBeginDate,
  voucherEndDate,
  paymentTypes,
}) => {
  const classes = useStyles()
  const { Text } = Typography

  const captureUserAction = useCaptureUserAction()

  // pull language content
  const { t } = useTranslation()

  const availableIssueTypes = useMemo(
    () =>
      filterByCcrWhitelist<IssueType>(useCcrWhitelistForCompensationIssues, issueTypes, ccrCode),
    [issueTypes, ccrCode, useCcrWhitelistForCompensationIssues],
  )

  useEffect(() => {
    if (!selectedIssueType && availableIssueTypes.length === 1) {
      setSelectedIssueType(availableIssueTypes[0])
    }
  }, [availableIssueTypes, selectedIssueType, setSelectedIssueType])

  // pull compensation values per entity from context
  const { compensation } = useFixedPanelActionsWidgetConfigs()

  const utc_zone = useEntityUtcZone()

  // pull currency from data context
  const {
    dataState: {
      currency,
      customerCompensations = [],
      customerRefunds = [],
      recommendedCompensation,
    },
  } = useContext(DataContext)

  const { error: getCustomerCompensationsAndRefundsError } = useGetCustomerCompensationsAndRefunds()

  const { maxVoucherValue = 1, minVoucherValue = 0, granularity } = compensation

  const isIssueTypeSelected = selectedIssueType !== null

  // fired when edit icon is clicked for compensation value
  const [isEditValue, setIsEditValue] = useState(false)

  // fires when continue button is clicked -> alters the state to render confirmation screen
  const [isConfirmation, setIsConfirmation] = useState(false)

  /**
   * capture user actions
   */
  const handleContinueClick = () => {
    setIsConfirmation(true)

    // report findings
    captureUserAction('ActionsCompensationContinueClicked', {
      eventDetails: {
        numberOfCompensations: customerCompensations.length,
        numberOfRefunds: customerRefunds.length,
        selectedCompensationReason: selectedIssueType.issue,
        selectedCompensationReasonAlreadyCompensated:
          customerCompensations.find(
            (current) => current.compensation_purpose === selectedIssueType.issue,
          ) !== null,
      },
    })
  }

  // fired when OK is clicked after editing voucher value -> closes the input field
  const handleValueEditExit = () => {
    const voucherValueRounded = calculateGranularValue(voucherValue, granularity)
    setVoucherValue(voucherValueRounded)
    setIsEditValue(false)
  }

  const { hasCapError, capErrorMessage, capValueNotice } = useMemo(() => {
    if (recommendedCompensation) {
      return {
        hasCapError: false,
        capErrorMessage: '',
        capValueNotice: '',
      }
    }
    const maxValue = selectedIssueType?.maxVoucherValue || maxVoucherValue
    const minValue = selectedIssueType?.minVoucherValue || minVoucherValue

    let capErrorMessage = ''

    // update state
    if (voucherValue <= 0) {
      capErrorMessage = t('Actions Widget.Actions.Compensation.Value must be greater than 0')
    } else if (voucherValue > maxValue) {
      capErrorMessage = t('Actions Widget.Actions.Compensation.Cap Value Exceeded')
    } else if (voucherValue < minValue) {
      capErrorMessage = `${t(
        'Actions Widget.Actions.Compensation.Min Value for Entity',
      )}: ${fixNumber(minValue)}`
    }

    return {
      hasCapError: capErrorMessage !== '',
      capErrorMessage,
      capValueNotice: `${t(
        'Actions Widget.Actions.Compensation.Cap Value',
      )}: ${currency} ${maxValue}`,
    }
  }, [
    voucherValue,
    minVoucherValue,
    maxVoucherValue,
    selectedIssueType,
    t,
    currency,
    recommendedCompensation,
  ])

  const hasSuccessfullyCompleted =
    createCompensationToWalletStatus === 'success' || createVoucherStatus === 'success'

  return (
    <WidgetErrorHandler
      errorPayload={errorPayload}
      displayType='overlay'
      onQuit={onQuit}
      onBack={clearErrors}
      loading={
        isLoadingProposedValue ||
        isVerifyingRefundMethods ||
        creatingVoucher ||
        compensatingToWallet
      }
    >
      {() => {
        return (
          <>
            <WidgetErrorHandler
              errorPayload={getCustomerCompensationsAndRefundsError?.errorPayload}
              displayType='mini'
            />

            {isConfirmation ? (
              <Confirmation
                isSuccess={hasSuccessfullyCompleted}
                createdVoucher={createdVoucher}
                processCompensation={processCompensation}
                selectedCompensationMethod={selectedCompensationMethod.method}
                onQuit={onQuit}
                setIsConfirmation={setIsConfirmation}
                currency={currency}
              />
            ) : null}

            {!isConfirmation ? (
              <React.Fragment>
                {/* Banner */}
                {(customerCompensations?.length > 0 || customerRefunds?.length > 0) && (
                  <InfoAlertView
                    utcZone={utc_zone}
                    compensations={customerCompensations ? customerCompensations : null}
                    refunds={customerRefunds ? customerRefunds : null}
                    parent='Compensation'
                  />
                )}

                {/* ISSUE TYPE Dropdown */}
                <IssueTypeSelect
                  selectedIssueType={selectedIssueType}
                  issueTypes={availableIssueTypes}
                  setIssueType={setSelectedIssueType}
                />

                {/* COMP METHOD Dropdown */}
                <CompensationMethodSelect
                  isIssueTypeSelected={isIssueTypeSelected}
                  selectedCompensationMethod={selectedCompensationMethod}
                  compensationMethods={compensationMethods}
                  setCompensationMethod={setSelectedCompensationMethod}
                />

                {/* CONTENT BODY */}
                <div className={classes.modalContent}>
                  {/* compensation value */}
                  <div>
                    <div className={classes.rowContainer}>
                      <Text
                        className={
                          isIssueTypeSelected ? classes.bodyTitleActive : classes.bodyTitleInactive
                        }
                      >
                        {`${t('Actions Widget.Actions.Compensation.Compensation Value')}:`}
                      </Text>
                      {isEditValue && allowVoucherEdit ? (
                        <div className={classes.editSectionContainer}>
                          <div className={classes.editSection}>
                            <Text className={classes.editTextCurrency}>{currency}</Text>
                            <Input
                              className={classes.editInputNumber}
                              type='number'
                              step={compensation.granularity.toString()}
                              defaultValue={voucherValue}
                              id={COMP_VALUE_INPUT_ID}
                              onChange={(e) => {
                                const val = e.target.value
                                setVoucherValue(Number.parseFloat(val) || 0)
                              }}
                            />
                            <Button
                              type='primary'
                              onClick={handleValueEditExit}
                              disabled={hasCapError}
                              id={EDIT_COMP_VALUE_ID}
                            >
                              {t('Interface.OK')}
                            </Button>
                          </div>
                          <div className={classes.capValueContainer}>
                            <Text
                              className={
                                hasCapError
                                  ? classes.inputFieldCapValueAlert
                                  : classes.inputFieldCapValue
                              }
                              id={COMP_VALUE_EDIT_MSG_ID}
                            >
                              {capErrorMessage || capValueNotice}
                            </Text>
                          </div>
                        </div>
                      ) : (
                        <Text
                          className={
                            isIssueTypeSelected ? classes.bodyDataActive : classes.bodyDataInactive
                          }
                          id={COMP_VALUE_ID}
                        >
                          {`${currency} ${fixNumber(voucherValue)}`}
                          {allowVoucherEdit && (
                            <EditOutlined
                              className={classes.editIcon}
                              onClick={isIssueTypeSelected ? () => setIsEditValue(true) : null}
                              id={SHOW_COMP_VALUE_EDITOR}
                            />
                          )}
                        </Text>
                      )}
                    </div>

                    {/* handle error for get proposed voucher value */}
                    <WidgetErrorHandler
                      errorPayload={getProposedVoucherError?.errorPayload}
                      displayType='mini'
                    />
                  </div>

                  {selectedCompensationMethod.method === AvailableCompensationMethods.voucher &&
                  isIssueTypeSelected ? (
                    <React.Fragment>
                      {minOrderValue ? (
                        <div className={classes.rowContainer} id={COMP_MOV_ID}>
                          <Text
                            className={
                              isIssueTypeSelected
                                ? classes.bodyTitleActive
                                : classes.bodyTitleInactive
                            }
                          >
                            {`${t(
                              'Actions Widget.Actions.Compensation.Voucher Minimum Order Value (MOV)',
                            )}:`}
                          </Text>
                          <Text
                            className={
                              isIssueTypeSelected
                                ? classes.bodyDataActive
                                : classes.bodyDataInactive
                            }
                          >
                            {`${currency}: ${fixNumber(minOrderValue)}`}
                          </Text>
                        </div>
                      ) : null}

                      {/* voucher validity */}
                      {voucherBeginDate && voucherEndDate ? (
                        <div className={classes.rowContainer} id={COMP_VOUCHER_VALIDITY_ID}>
                          <Text
                            className={
                              isIssueTypeSelected
                                ? classes.bodyTitleActive
                                : classes.bodyTitleInactive
                            }
                          >
                            {`${t('Actions Widget.Actions.Compensation.Voucher Validity')}:`}
                          </Text>
                          <Text
                            className={
                              isIssueTypeSelected
                                ? classes.bodyDataActive
                                : classes.bodyDataInactive
                            }
                          >
                            {`${voucherBeginDate} - ${voucherEndDate}`}
                          </Text>
                        </div>
                      ) : null}

                      <div className={classes.rowContainer}>
                        <Text
                          className={
                            isIssueTypeSelected
                              ? classes.bodyTitleActive
                              : classes.bodyTitleInactive
                          }
                        >
                          {`${t('Customer Widget.Tabs.Vouchers.Voucher Details.Payment Types')}:`}
                        </Text>
                        <Text
                          className={
                            isIssueTypeSelected ? classes.bodyDataActive : classes.bodyDataInactive
                          }
                        >
                          {paymentTypes}
                        </Text>
                      </div>
                    </React.Fragment>
                  ) : null}
                </div>

                {/* BUTTONS */}
                <div className={classes.buttonHolder}>
                  <Tooltip
                    title={
                      !isIssueTypeSelected
                        ? t('Actions Widget.Actions.Compensation.Please select an issue type')
                        : hasCapError
                        ? capErrorMessage
                        : ''
                    }
                  >
                    <Button
                      type='primary'
                      onClick={handleContinueClick}
                      disabled={!isIssueTypeSelected || !voucherValue || hasCapError}
                      id={CREATE_COMP_ID}
                    >
                      {t('Interface.Continue')}
                    </Button>
                  </Tooltip>
                </div>
              </React.Fragment>
            ) : null}
          </>
        )
      }}
    </WidgetErrorHandler>
  )
}

export default CompensationModalView
