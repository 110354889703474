export default {
  idText: {
    fontSize: '14px',
    marginLeft: '10px',
    fontWeight: '500',
  },
  button: {
    paddingLeft: '4px',
    paddingRight: '4px',
  },
}
