import { createUseStyles } from 'react-jss'

export default createUseStyles({
  container: {
    display: 'flex',
    alignItems: 'start',
    borderTop: '1px solid #f0f0f0',
    padding: '12px 0 0 0',
    margin: '12px 0 0 0',
  },
  tag: {
    borderRadius: '50px',
    padding: '0 10px',
    '&:hover': {
      cursor: 'pointer',
    },
    margin: 0,
  },
  errorText: {
    lineHeight: '22px',
  },
  toggleBtn: {
    height: '22px',
  },
})
