import React from 'react'
import { TranslateFunction, useTranslation } from 'hooks/useTranslation'
import { OrderApiResponse } from 'types/api/orderApi/order'
import { OrderStatuses } from 'types/widgets/order/orderStatuses'
import { TimelineItem, TimelineMeta } from 'widgets/Deliveries/types'
import { DeliveryItem, FulfillmentApiResponse } from 'types/api/fulfillmentApi/fulfillment'
import UpdatedDeliveryTime from 'widgets/Deliveries/UpdatedDeliveryTime/UpdatedDeliveryTime'
import { getUpdatedDeliveryTime } from '../../utils/order/getUpdatedDeliveryTime'
import {
  getOrderEstimationStatusForTimeline,
  getOrderPromisedStatusForTimeline,
} from '../../utils/order/getOrderEstimationStatusForTimeline'
import { pickPrimaryDelivery } from 'utils/pickPrimaryDelivery'
import { SDK } from 'types'
import { text } from 'theme'
import { calculateOrderDeliveryTimestamps } from 'utils/order/calculateOrderDeliveryTimestamps'
import { deduplicateStatusHistory } from 'utils/order/deduplicateStatusHistory'

type Props = {
  sdk: SDK
  t?: TranslateFunction
  order: OrderApiResponse
  delivery?: DeliveryItem
  fulfillment: FulfillmentApiResponse
}

const getCreatedStatusForTimeline = ({
  order,
  sdk,
}: Omit<Props, 'fulfillment' | 't'>): TimelineItem => {
  const orderPlaceDate = sdk.datetimeFormatter.formatDate(order.place_timestamp)
  const createdStatus = sdk.getOrderStatusTextAndColor({ orderStatus: OrderStatuses.created })
  return {
    key: order.place_timestamp,
    isMilestone: true,
    icon: createdStatus.icon,
    status: {
      main: {
        color: text.disabled,
        text: createdStatus.text,
      },
    },
    time: {
      main: {
        text: sdk.datetimeFormatter.formatTime(order.place_timestamp),
      },
      secondary: [{ text: orderPlaceDate, color: text.secondary }],
    },
  }
}

export const getOrderPendingStatusForTimeline = ({
  sdk,
  order,
}: Omit<Props, 'fulfillment' | 't'>) => {
  const alreadyFailed = order?.status_history?.some(
    (item) => item.status === OrderStatuses.pendingOrderFailed,
  )
  if (order.pending && !alreadyFailed) {
    const { color, text, icon } = sdk.getOrderStatusTextAndColor({
      orderStatus: OrderStatuses.pending,
    })
    return {
      key: `${order.place_timestamp}_order`,
      isCurrent: true,
      isMilestone: true,
      icon,
      status: {
        main: { color, text },
      },
      time: {
        main: {
          text: sdk.datetimeFormatter.formatTime(order.place_timestamp),
        },
      },
    }
  }

  return null
}

export const getOrderStatusHistoryForTimeline = ({
  order,
  fulfillment,
  delivery,
  t,
  sdk,
}: Props) => {
  const deliveryProvider = order.delivery.provider
  const updatedDeliveryTime = getUpdatedDeliveryTime({ order, fulfillment, sdk })
  const orderPlaceDate = sdk.datetimeFormatter.formatDate(order.place_timestamp)
  // trackStatusDate will keep track of date change in order status history
  // We will use it to show date only on status where date changed and then afterwards it will
  // not show new date just as in design.
  let trackStatusDate = orderPlaceDate

  const { delayTime, promisedDropOffTime } = calculateOrderDeliveryTimestamps({
    order,
    fulfillment,
    delivery,
    datetimeFormatter: sdk.datetimeFormatter,
  })
  const formattedPromisedTime = sdk.datetimeFormatter.formatTime(promisedDropOffTime)
  const formattedPromisedDate = sdk.datetimeFormatter.formatDate(promisedDropOffTime)
  const isPickupDelivery = deliveryProvider === 'pickup'

  const milestones = [
    OrderStatuses.created,
    OrderStatuses.accepted,
    OrderStatuses.courierAcceptedDelivery,
    OrderStatuses.delivered,
  ]

  const timeline: TimelineItem[] = []

  const history = deduplicateStatusHistory(order?.status_history ?? [])

  history.forEach((historyItem, idx) => {
    const statusMeta = sdk.getOrderStatusTextAndColor({ orderStatus: historyItem.status })
    const statusDate = sdk.datetimeFormatter.formatDate(historyItem.timestamp)

    let hasStatusDateChanged = false
    // has date changed from last tracked date to show status time with new date.
    if (trackStatusDate !== statusDate) {
      trackStatusDate = statusDate
      hasStatusDateChanged = true
    }

    const isMilestone = milestones.some((milestone) => historyItem.status === milestone)

    const secondaryTime: TimelineMeta[] = []
    const secondaryText: TimelineMeta[] = []

    const isCurrent = idx === history.length - 1

    let mainText = statusMeta.text
    let showPromisedTimeAndDelay = false

    switch (historyItem.status) {
      case OrderStatuses.cancelled:
        const reasonCode = historyItem.cancelled?.details?.reason || historyItem.cancelled?.reason
        const reason = t(`Order Widget.Tabs.Summary.Cancellation reasons.${reasonCode}.Reason`)

        showPromisedTimeAndDelay = true
        secondaryTime.push({ text: '' }) // add empty space to fix alignment for promised delivery time
        secondaryText.push({
          text: reason,
          color: text.danger,
        })
        break

      case OrderStatuses.pendingOrderFailed:
        secondaryText.push({
          text:
            historyItem.pending_order_failed?.reason ||
            t('Order Widget.Tabs.Summary.Fraudulent customer'),
          color: text.danger,
        })
        break

      case OrderStatuses.delivered:
        showPromisedTimeAndDelay = true
        break

      case OrderStatuses.pickedUp:
        if (isPickupDelivery) {
          showPromisedTimeAndDelay = true
          mainText = t('Order Widget.Tabs.Status.Order Statuses.Completed')
        }
        break
    }

    if (showPromisedTimeAndDelay) {
      secondaryText.push({
        text: t('Order Widget.Tabs.Status.Order Statuses.Promised'),
        tooltip: updatedDeliveryTime && <UpdatedDeliveryTime deliveryTime={updatedDeliveryTime} />,
      })
      secondaryTime.push({ text: formattedPromisedTime })
      if (formattedPromisedDate !== orderPlaceDate && !hasStatusDateChanged) {
        secondaryTime.push({
          text: formattedPromisedDate,
          color: text.secondary,
        })
      }
    }

    if (hasStatusDateChanged) {
      secondaryText.push({ text: '' })
      secondaryTime.push({
        text: sdk.datetimeFormatter.formatDate(historyItem.timestamp),
        color: text.secondary,
      })
    }

    const item: TimelineItem = {
      key: `${historyItem.timestamp}_${idx}`,
      isCurrent: isCurrent,
      isMilestone: isCurrent || isMilestone,
      icon: statusMeta.icon,
      status: {
        main: {
          color: isCurrent ? statusMeta.color : text.disabled,
          text: mainText,
          fontWeight: isCurrent ? '600' : '400',
        },
        secondary: secondaryText,
      },
      time: {
        main: {
          text: sdk.datetimeFormatter.formatTime(historyItem.timestamp),
        },
        secondary: secondaryTime,
        extra: showPromisedTimeAndDelay ? delayTime : null,
      },
    }

    timeline.push(item)
  })

  return timeline
}

export const useOrderHistoryTimeline = ({ order, fulfillment, sdk }: Props) => {
  const { t } = useTranslation()
  const primaryDelivery = pickPrimaryDelivery(fulfillment?.deliveries ?? [])

  const timeline: TimelineItem[] = []

  timeline.push(getCreatedStatusForTimeline({ sdk, order }))
  const statusHistory = order?.status_history || []

  // handle pending order
  if (
    order.pending &&
    !statusHistory.find((item) => item.status === OrderStatuses.pendingOrderFailed)
  ) {
    const { color, text, icon } = sdk.getOrderStatusTextAndColor({
      orderStatus: OrderStatuses.pending,
    })
    timeline.push({
      key: `${order.place_timestamp}_order`,
      isCurrent: true,
      isMilestone: true,
      icon,
      status: {
        main: { color, text },
      },
      time: {
        main: {
          text: sdk.datetimeFormatter.formatTime(order.place_timestamp),
        },
      },
    })
    return timeline
  }

  timeline.push(
    ...getOrderStatusHistoryForTimeline({
      order,
      fulfillment,
      t,
      delivery: primaryDelivery,
      sdk,
    }),
  )

  const estimation = getOrderEstimationStatusForTimeline({
    t,
    sdk,
    order,
    fulfillment,
    delivery: primaryDelivery,
  })

  const promised = getOrderPromisedStatusForTimeline({
    t,
    sdk,
    order,
    fulfillment,
    delivery: primaryDelivery,
  })

  if (promised) {
    timeline.push(promised)
  } else if (estimation) {
    timeline.push(estimation)
  }

  return timeline
}
