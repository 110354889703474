/**
 * LocationPin: a dumy component rendering markers with the passed lat and lon
 * it renders either icons of customer, vendor and rider; or pink and purple pins
 * */

// libs
import React from 'react'
// styles
import { createUseStyles } from 'react-jss'
import styles from './LocationPin.styles'
// hooks
import { useTranslation } from 'hooks/useTranslation'
// styles
import { Tooltip, Typography } from 'antd'
// assets
import CustomerPin from 'assets/mapMarkers/CustomerPin.svg'
import RiderPin from 'assets/mapMarkers/RiderPin.svg'
import VendorPin from 'assets/mapMarkers/VendorPin.svg'
import PinkIcon from 'assets/pins/PinkBig.png'
import PurpleIcon from 'assets/pins/PurpleBig.png'
import RiderPoint from 'assets/mapMarkers/RiderPoint.svg'
import VendorPoint from 'assets/mapMarkers/VendorPoint.svg'
import CustomerPoint from 'assets/mapMarkers/CustomerPoint.svg'
import TrajectoryPoint from 'assets/mapMarkers/TrajectoryPoint.svg'

const useStyles = createUseStyles(styles)

interface Props {
  lat: number
  lng: number
  marker:
    | 'vendor'
    | 'customer'
    | 'rider'
    | 'trajectoryPoint'
    | 'vendorPoint'
    | 'riderPoint'
    | 'customerPoint'
  tooltipText?: string | null
  color?: string | null
}

const LocationPin: React.FC<Props> = ({ lat, lng, marker, tooltipText, color }) => {
  const classes = useStyles()
  const { Text } = Typography

  // pull language content
  const { t } = useTranslation()

  let tooltipTitle: string
  let icon: any
  let className = classes.icon

  switch (marker) {
    case 'customer':
      tooltipTitle = t('Order Widget.Tabs.Status.Map.CustomerMarker')
      icon = CustomerPin
      break

    case 'rider':
      tooltipTitle = t('Order Widget.Tabs.Status.Map.RiderMarker')
      icon = RiderPin
      break

    case 'vendor':
      tooltipTitle = t('Order Widget.Tabs.Status.Map.VendorMarker')
      icon = VendorPin
      break
    case 'customerPoint':
      icon = CustomerPoint
      className = classes.point
      break
    case 'vendorPoint':
      icon = VendorPoint
      className = classes.point
      break
    case 'riderPoint':
      icon = RiderPoint
      className = classes.point
      break
    case 'trajectoryPoint':
      icon = TrajectoryPoint
      className = classes.point
      break
  }

  return (
    <div className={classes.container}>
      {color ? (
        <img
          className={className}
          src={color === 'pink' ? PinkIcon : PurpleIcon}
          alt='customer pin icon'
        />
      ) : (
        <Tooltip
          title={
            Boolean(tooltipTitle || tooltipText) ? (
              <div className={classes.tooltipDivision}>
                {tooltipTitle && <Text className={classes.tooltipTitle}>{tooltipTitle}:</Text>}
                {tooltipText && (
                  <Text strong className={classes.tooltipValue}>
                    {tooltipText}
                  </Text>
                )}
              </div>
            ) : null
          }
        >
          <img className={className} src={icon} alt='customer pin icon' />
        </Tooltip>
      )}
    </div>
  )
}

export default LocationPin
