import { useGetOrderStatusMetadata } from 'hooks/dataTransformers/useGetOrderStatusMetadata'
import { useGetOrderStatusTextAndColor } from 'hooks/getters/useGetOrderStatusTextAndColor'
import { useCheckDisplayRules } from 'hooks/useCheckDisplayRules'
import { useGetRiderStatusTextAndColor } from 'hooks/useGetRiderStatusTextAndColor'
import { SDK, WidgetName } from 'types'
import { useCallback } from 'react'
import { useCaptureUserAction } from 'hooks/events/useCaptureUserAction'
import { useWidgetViewManager } from 'contexts/widgetViewManager'
import { getWidgetId } from 'utils/getters/getWidgetId'
import { getWidgetCategory } from 'utils/getters/getWidgetCategory'

/**
 * proxy handler for activating widgets
 */
export const useActivateWidgetViewProxy = () => {
  const captureUserAction = useCaptureUserAction()
  const { activateWidgetView } = useWidgetViewManager()

  return useCallback<typeof activateWidgetView>(
    (widgetDefinition, opts) => {
      const widgetId = getWidgetId(widgetDefinition)
      captureUserAction('OPEN_WIDGET', {
        eventDetails: {
          widget_id: widgetId,
          widget_category: getWidgetCategory(widgetId as WidgetName),
        },
      })

      return activateWidgetView(widgetDefinition, opts)
    },
    [captureUserAction, activateWidgetView],
  )
}

/**
 * use to check display rules proxy
 */
export const useCheckDisplayRulesProxy = () => {
  const checkDisplayRules = useCheckDisplayRules()

  return useCallback<SDK['checkDisplayRules']>(
    ({ displayRules, opts }) => {
      return checkDisplayRules(displayRules, opts)
    },
    [checkDisplayRules],
  )
}

/**
 * get order status text and color api proxy
 */
export const useGetOrderStatusTextAndColorProxy = () => {
  const getOrderStatusTextAndColor = useGetOrderStatusTextAndColor()
  return useCallback<SDK['getOrderStatusTextAndColor']>(
    ({ orderStatus }) => getOrderStatusTextAndColor(orderStatus),
    [getOrderStatusTextAndColor],
  )
}

/**
 * get order status metadata api proxy
 */
export const useGetOrderStatusMetadataProxy = () => {
  const getOrderStatusMetadata = useGetOrderStatusMetadata()
  return useCallback<SDK['getOrderStatusMetadata']>(
    ({ order }) => getOrderStatusMetadata(order),
    [getOrderStatusMetadata],
  )
}

/**
 * get rider status text and color api proxy
 */
export const useGetRiderStatusTextAndColorProxy = () => {
  const getRiderStatusTextAndColor = useGetRiderStatusTextAndColor()
  return useCallback<SDK['getRiderStatusTextAndColor']>(
    ({ riderStatus }) => getRiderStatusTextAndColor(riderStatus),
    [getRiderStatusTextAndColor],
  )
}
