import React from 'react'

// Components
import { Typography } from 'antd'
import { FrownOutlined } from '@ant-design/icons'

// Hooks
import { createUseStyles } from 'react-jss'

// Relative path
import styles from './WidgetStaticError.styles'

const useStyles = createUseStyles(styles)
const { Text } = Typography

type Props = {
  title: string
  content: string[]
}

const WidgetStaticError = ({ title, content }: Props) => {
  const classes = useStyles()

  return (
    <div className={classes.container}>
      <FrownOutlined style={{ fontSize: '40px', color: '#BFBFBF' }} />
      <Text className={classes.title}>{title}</Text>
      {content.map((line, index) => (
        <Text key={`static-error-line-${index}`} className={classes.content}>
          {line}
        </Text>
      ))}
    </div>
  )
}

export default WidgetStaticError
