import React, { useState } from 'react'
import { CustomerChatListItem } from 'types/api/ticketApi/getOrderTickets'
import styles from './CustomerTranscriptView.styles'
import { createUseStyles } from 'react-jss'
import { Badge } from 'antd'
import ChatRoomCard from './ChatRoomCard'
import { useTranslation } from 'hooks/useTranslation'
import { useSessionState } from 'hooks/useSessionState'
import { SingleChatTranscript } from './SingleChatTranscript/SingleChatTranscript'

const useStyles = createUseStyles(styles)

interface CustomerChatTranscriptHeaderParams {
  orderId: string
  numberOfChats: number
}

const CustomerChatTranscriptHeader = ({
  orderId,
  numberOfChats,
}: CustomerChatTranscriptHeaderParams) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <p className={classes.header}>
      <span>
        {t('From')} <span className={classes.bold}>{`${t('order')} ${orderId}`}</span>
      </span>
      <Badge className={classes.numberTag} count={numberOfChats} />
    </p>
  )
}

interface CustomerTranscriptViewParams {
  chatList: CustomerChatListItem[]
  orderId: string
}

export const CustomerTranscriptView = ({ chatList, orderId }: CustomerTranscriptViewParams) => {
  const classes = useStyles()
  const { globalEntityId } = useSessionState()

  const [selectedRoom, setSelectedRoom] = useState<CustomerChatListItem | null>(null)

  const handleSelectRoom = (id: string | null) => {
    if (!id) {
      setSelectedRoom(null)
    }
    setSelectedRoom(chatList.find((room) => room.id === id))
  }

  return (
    <div className={classes.container}>
      {!selectedRoom && (
        <>
          <CustomerChatTranscriptHeader orderId={orderId} numberOfChats={chatList.length} />
          <div className={classes.cardsContainer}>
            {chatList.map((item) => {
              return <ChatRoomCard key={item.id} item={item} handleSelectRoom={handleSelectRoom} />
            })}
          </div>
        </>
      )}

      {selectedRoom && (
        <SingleChatTranscript
          entityId={globalEntityId}
          onBack={() => handleSelectRoom(null)}
          selectedRoom={selectedRoom}
        />
      )}
    </div>
  )
}
