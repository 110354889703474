import classNames from 'classnames'
import { createDeclarativeUseStyle } from 'factory/createDeclarativeUseStyle'
import React from 'react'
import { Property } from 'csstype'
import { SlimHtmlProps } from 'types'

export interface BoxStyleOptions {
  /**
   * color
   */
  color?: Property.Color

  /**
   * background color
   */
  bgColor?: string

  /**
   * margin top
   */
  mt?: string | number

  /**
   * margin bottom
   */
  mb?: string | number

  /**
   * margin left
   */
  ml?: string | number

  /**
   * margin right
   */
  mr?: string | number

  /**
   * margin left and right
   */
  mx?: string | number

  /**
   * display
   */
  display?: Property.Display

  /**
   * padding top
   */
  pt?: string | number

  /**
   * padding left
   */
  pl?: string | number

  /**
   * padding right
   */
  pr?: string | number

  /**
   * padding bottom
   */
  pb?: string | number

  /**
   * padding
   */
  p?: string | number

  /**
   * padding left and right
   */
  px?: string | number

  /**
   * padding top and bottom
   */
  py?: string | number

  /**
   * line Height
   */
  lineHeight?: string | number

  /**
   * font size
   */
  fontSize?: string | number

  /**
   * font weight
   */
  fontWeight?: '300' | '400' | '500' | '600' | '700'

  /**
   * flex direction
   */
  flexDirection?: Property.FlexDirection

  /**
   * row gap
   */
  rowGap?: Property.RowGap

  /**
   * column gap
   */
  columnGap?: Property.ColumnGap

  /**
   * gap
   */
  gap?: Property.Gap

  /**
   * align items
   */
  alignItems?: Property.AlignItems

  /**
   * justify content
   */
  justifyContent?: Property.JustifyContent

  overflow?: Property.Overflow

  textOverflow?: 'ellipsis' | 'clip'

  cursor?: Property.Cursor

  /**
   * height
   */
  h?: string | number

  /**
   * min height
   */
  minH?: string | number

  /**
   * width
   */
  w?: string | number

  /**
   * minimum width
   */
  minW?: string | number

  /**
   * border radius
   */
  borderRadius?: string | number

  whiteSpace?: Property.WhiteSpace

  /**
   * border
   */
  b?: string

  /**
   * top border
   */
  bt?: string

  /**
   * right border
   */
  br?: string

  /**
   * bottom border
   */
  bb?: string

  /**
   * left border
   */
  bl?: string
}

// TODO: simplify this property listing using a loop
const useStyles = createDeclarativeUseStyle<keyof BoxStyleOptions>([
  {
    key: 'bgColor',
    properties: 'backgroundColor',
  },
  {
    key: 'mt',
    properties: 'marginTop',
  },
  {
    key: 'mb',
    properties: 'marginBottom',
  },

  {
    key: 'mr',
    properties: 'marginRight',
  },

  {
    key: 'ml',
    properties: 'marginLeft',
  },

  {
    key: 'mx',
    properties: ['marginLeft', 'marginRight'],
  },

  {
    key: 'pl',
    properties: 'paddingLeft',
  },
  {
    key: 'pr',
    properties: 'paddingRight',
  },

  {
    key: 'pt',
    properties: 'paddingTop',
  },
  {
    key: 'pb',
    properties: 'paddingBottom',
  },

  {
    key: 'p',
    properties: 'padding',
  },

  {
    key: 'py',
    properties: ['paddingTop', 'paddingBottom'],
  },

  {
    key: 'px',
    properties: ['paddingLeft', 'paddingRight'],
  },

  {
    key: 'w',
    properties: 'width',
  },

  {
    key: 'minW',
    properties: 'minWidth',
  },

  {
    key: 'h',
    properties: 'height',
  },

  {
    key: 'minH',
    properties: 'minHeight',
  },
  {
    key: 'b',
    properties: 'border',
  },
  {
    key: 'bt',
    properties: 'borderTop',
  },
  {
    key: 'br',
    properties: 'borderRight',
  },
  {
    key: 'bb',
    properties: 'borderBottom',
  },
  {
    key: 'bl',
    properties: 'borderLeft',
  },

  'fontSize',

  'fontWeight',

  'flexDirection',

  'rowGap',

  'columnGap',

  'gap',

  'alignItems',

  'justifyContent',

  'lineHeight',

  'display',

  'color',

  'overflow',

  'textOverflow',

  'cursor',

  'borderRadius',

  'whiteSpace',
])

export const Box = ({
  className,
  color,
  bgColor,
  Component = 'div',
  ml,
  mt,
  mb,
  mr,
  display,
  pt,
  pl,

  pr,
  p,
  lineHeight,
  alignItems,
  justifyContent,
  fontWeight,
  fontSize,
  flexDirection,
  w,
  minW,
  h,
  minH,
  gap,
  rowGap,
  columnGap,
  overflow,
  textOverflow,
  cursor,
  py,
  px,
  pb,
  borderRadius,
  whiteSpace,
  b,
  bt,
  br,
  bb,
  bl,
  mx,
  ...others
}: SlimHtmlProps<BoxStyleOptions>) => {
  const cx = useStyles({
    color,
    bgColor,
    ml,
    mt,
    mb,
    mr,
    display,
    pl,
    pt,
    pr,
    pb,
    lineHeight,
    alignItems,
    justifyContent,
    p,
    fontSize,
    fontWeight,
    w,
    minW,
    flexDirection,
    rowGap,
    columnGap,
    gap,
    overflow,
    textOverflow,
    cursor,
    px,
    py,
    h,
    minH,
    borderRadius,
    whiteSpace,
    b,
    bt,
    br,
    bb,
    bl,
    mx,
  })
  return <Component className={classNames(className, cx.className)} {...others} />
}
