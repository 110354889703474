// libs
import React, { useState, useContext } from 'react'
// contexts and types
import { SessionContext } from 'contexts/session/SessionContext'
import { RiderServiceContext } from 'contexts/riderService/RiderServiceContext'
import { RiderServiceAction } from 'contexts/riderService/types'
import { SingleCancellationReason } from 'types/actions/cancelOrder'
import { CancellationApiResponses } from 'types/actions/cancelOrder'
// hooks
import { useTranslation } from 'hooks/useTranslation'
import { createClient } from 'hooks/useFetchData'
// utils
import environment from 'envConfig'
import createOneviewHeaders from 'utils/oneviewApi/createOneviewHeaders'
import processApiErrors from 'utils/oneviewApi/processApiErrors'
import ensureJwtIsValid from 'utils/oneviewApi/ensureJwtIsValid'
// styles
import { createUseStyles } from 'react-jss'
import styles from './Content.styles'
import { Button, Typography, Result } from 'antd'
import { CloseOutlined } from '@ant-design/icons'
// components
import LoadingView from 'components/LoadingView'
import MiniErrorView from 'components/MiniErrorView'
// assets
import orderErrorIcon from 'assets/error/orderError.png'
import CancellationReasonDropdown from 'components/CancelOrder/CancellationReasonDropdown'
import { useCaptureUserAction } from 'hooks/events/useCaptureUserAction'

const useStyles = createUseStyles(styles)

interface Props {
  availableCancellationReasons: SingleCancellationReason[]
}

const Content: React.FC<Props> = ({ availableCancellationReasons }) => {
  const classes = useStyles()
  const { Text } = Typography
  // pull language content
  const { t } = useTranslation()

  // pull order and globalentity ID from session context
  const {
    sessionState: { orderId, globalEntityId, caseId },
  } = useContext(SessionContext)

  const { riderServiceDispatch } = useContext(RiderServiceContext)
  const { SET_IS_CANCEL_ORDER } = RiderServiceAction

  const [isCancellingOrder, setIsCancellingOrder] = useState(false)
  const [cancelOrderError, setCancelOrderError] = useState(false)
  const [errorStatus, setErrorStatus] = useState<number>(null)
  const [isPostSuccess, setIsPostSuccess] = useState(false)
  const [postResponse, setPostResponse] = useState('')
  const [selectedCancelReason, setSelectedCancelReason] = useState('')

  const captureUserAction = useCaptureUserAction()

  const handleActionClose = (isOkButton?: boolean) => {
    if (isOkButton) {
      captureUserAction('ActionsCancelOrderOkButtonClicked')
    } else {
      if (errorStatus) {
        captureUserAction('ActionsCancelOrderFailedCancellationXButtonClicked', {
          eventDetails: {
            code: errorStatus,
          },
        })
      } else {
        captureUserAction('ActionsCancelOrderXButtonClicked', {
          eventDetails: {
            reason: selectedCancelReason,
          },
        })
      }
    }

    handleGoBackClickAfterError()
    riderServiceDispatch({
      type: SET_IS_CANCEL_ORDER,
      payload: { isCancelOrder: false },
    })
  }

  const handleGoBackClickAfterError = () => {
    setIsCancellingOrder(false)
    setCancelOrderError(false)
    setIsPostSuccess(false)
    setPostResponse('')
    setSelectedCancelReason('')
  }

  const selectCancellationReason = (reason: SingleCancellationReason) => {
    captureUserAction('ActionsCancelOrderReasonSelected', {
      eventDetails: {
        initial: selectedCancelReason,
        selected: reason.reason,
      },
    })
    setSelectedCancelReason(reason.reason)
  }

  const handleCancellationConfirmation = async () => {
    // set loading screen
    setIsCancellingOrder(true)

    // ensure JWT is valid before firing API client
    const isTokensValid = await ensureJwtIsValid(caseId)

    // async func to call the post client
    const postOrderCancelAsync = async () => {
      const uri = `${
        environment().oneviewApiRoot
      }/OrdersAPI/v1/orders/${globalEntityId}/${orderId}/cancellation?reason=${selectedCancelReason}`
      const headers = createOneviewHeaders()

      await createClient()
        .post(uri, null, headers)
        .then((value) => {
          // when cancel is successful, set states for success, displayed msg, and loading spinner
          if (value && value.status === 200) {
            captureUserAction('CancelOrder', {
              eventDetails: {
                successfully_cancelled: true,
                cancel_reason: selectedCancelReason,
                api_response: value.status,
              },
            })

            setIsPostSuccess(true)
            setPostResponse(
              t(
                'Actions Widget.Actions.Cancel Order.Cancellation Results.Order successfully cancelled',
              ),
            )
          }
        })
        .catch((err) => {
          // check if err response data and status exist -> if not, set generic message to display to user
          if (err && err.response && err.response.data && err.response.status) {
            setErrorStatus(err.response.status)
            // when status code exist and it's 400, check the failure reason to set an appropriate msg for user
            if (err.response.status === 400) {
              if (
                err.response.data &&
                err.response.data.error === CancellationApiResponses.cancelled
              ) {
                setPostResponse(
                  t(
                    'Actions Widget.Actions.Cancel Order.Cancellation Results.This order cannot be cancelled because it has already been cancelled',
                  ),
                )
              } else if (
                err.response.data &&
                err.response.data.error === CancellationApiResponses.ineligible
              ) {
                setPostResponse(
                  t(
                    'Actions Widget.Actions.Cancel Order.Cancellation Results.Order cancellation is ineligible, please go back and try again',
                  ),
                )
              } else if (
                err.response.data &&
                err.response.data.error === CancellationApiResponses.invalid
              ) {
                setPostResponse(
                  t(
                    'Actions Widget.Actions.Cancel Order.Cancellation Results.Invalid reason for cancelling order, please go back and try again with a valid reason',
                  ),
                )
              }
              // if status code is other than 400, set not found err message for user
            } else if (err.response.status === CancellationApiResponses.notFound) {
              setPostResponse(
                t(
                  'Actions Widget.Actions.Cancel Order.Cancellation Results.Order could not be found, please go back and try again',
                ),
              )
            }
          } else {
            setPostResponse(
              t(
                'Actions Widget.Actions.Cancel Order.Cancellation Results.Order could not be cancelled at this time, please go back and try again',
              ),
            )
          }
          processApiErrors(t, err, 'CancelOrder', orderId, globalEntityId, true)
          setIsPostSuccess(false)
          setCancelOrderError(true)
        })
        .finally(() => {
          setIsCancellingOrder(false)
        })
    }

    // fire post cancel order client if tokens are valid
    if (isTokensValid) {
      postOrderCancelAsync()
    }
  }

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <Text className={classes.title}>{t('Actions Widget.Actions.Cancel Order.Title')}</Text>
        <CloseOutlined className={classes.closeIcon} onClick={() => handleActionClose(false)} />
      </div>

      {/* loading spinner */}
      {isCancellingOrder && <LoadingView text={`${t('Interface.Please Wait')}...`} />}

      {/* mini error view: pass message if found in api response, otherwise generic */}
      {cancelOrderError && !isCancellingOrder && (
        <React.Fragment>
          <MiniErrorView
            icon={orderErrorIcon}
            errorMessage={
              postResponse
                ? postResponse
                : t(
                    'Actions Widget.Actions.Cancel Order.Cancellation Results.Order could not be cancelled at this time, please go back and try again',
                  )
            }
          />
          <div className={classes.buttonHolder}>
            <Button type='primary' onClick={handleGoBackClickAfterError}>
              {t('Interface.Go Back')}
            </Button>
          </div>
        </React.Fragment>
      )}

      {/* Dropdown content */}
      {!cancelOrderError && !isCancellingOrder && !isPostSuccess && (
        <CancellationReasonDropdown
          lob={'rider'}
          orderId={orderId}
          isCancellingOrder={isCancellingOrder}
          handleCancelOrder={handleCancellationConfirmation}
          availableCancellationReasons={availableCancellationReasons}
          selectCancellationReason={selectCancellationReason}
          selectedReason={selectedCancelReason}
        />
      )}

      {/* post is done either with success or err */}
      {isPostSuccess && !isCancellingOrder && !cancelOrderError && (
        <div className={classes.resultHolder}>
          <Result
            status={isPostSuccess ? 'success' : 'error'}
            title={isPostSuccess ? t('Widgets Common.Success') : t('Widgets Common.Error')}
            subTitle={postResponse}
          />
          <div className={classes.buttonHolder}>
            <Button type='primary' onClick={() => handleActionClose(true)}>
              {t('Interface.OK')}
            </Button>
          </div>
        </div>
      )}
    </div>
  )
}

export default Content
