import React from 'react'
import { useTranslation } from 'react-i18next'
import { Text } from 'shared/Text'
import { Dot } from 'shared/Dot'
import { Space, Tag } from 'antd'
import { primary, text } from 'theme'
import { useHistoryStyles } from './RiderTabName.styles'

type Props = {
  isPrimary: boolean
  index: number
  isActive?: boolean
  status: {
    color: string
    text: string
  }
}

export const RiderTabName = ({ isPrimary, index, status, isActive }: Props) => {
  const classes = useHistoryStyles()

  const { t } = useTranslation()
  const textColor = isActive ? primary.primary6 : text.primary

  return (
    <Space>
      <Dot color={status.color} style={{ marginBottom: '1px' }} />
      <Text.Primary color={textColor}>
        {t(`Widgets Common.Rider`)} {index} ({status.text})
      </Text.Primary>
      {isPrimary && (
        <Tag className={classes.primaryTag}>
          <Text.Primary fontSize={12}>
            {t(`widgets.order_deliveries.deliveries.primary`)}
          </Text.Primary>
        </Tag>
      )}
    </Space>
  )
}
