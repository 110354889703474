// libs
import React, { useMemo, useState } from 'react'
import jp from 'jsonpath'
// context and types
import { CustomerWidgetProfile } from 'contexts/entity/types'
// hooks
import { useTranslation } from 'hooks/useTranslation'
// utils
// styles
import { createUseStyles } from 'react-jss'
import styles from './ProfileView.styles'
import { Button, Descriptions } from 'antd'
// compopnents
import { sortDesc } from 'utils/sortComparison'
import { logError } from 'utils/reporting/logError'
import { Stack } from 'shared/Stack'
import { Box } from 'shared/Box'
import { Dot } from 'shared/Dot'
import { DEFAULT_DOT_COLOR } from 'constants/constants'
import { useCheckDisplayRules } from 'hooks/useCheckDisplayRules'
import { GetCustomerProfileResponse } from 'types/api/customerApi/getCustomerProfile'
import { Text } from 'shared/Text'
import { text } from 'theme'
import { PopupWidgetContainer } from 'shared/PopupWidgetContainer'
import { WidgetContainerType } from 'types'

const useStyles = createUseStyles(styles)

type ProfileViewProps = {
  config: CustomerWidgetProfile
  customerProfile: GetCustomerProfileResponse
  widgetContainerType: WidgetContainerType
}

const ProfileView: React.FC<ProfileViewProps> = ({
  config,
  customerProfile,
  widgetContainerType,
}) => {
  const classes = useStyles()
  // pull language content
  const { t } = useTranslation()

  const checkDisplayRules = useCheckDisplayRules()

  const [showAll, setShowAll] = useState(false)

  // pull data state and dispatch from data context
  // const { dataState } = useContext(DataContext)

  const { data_points, layout_col_count = 3 } = config

  const availableDataPoints = useMemo(() => {
    if (Array.isArray(data_points)) {
      return data_points.filter((current) => {
        return (
          current.active !== false &&
          checkDisplayRules({
            beta_requirements: {
              value: current.betaRequirement,
            },
          }).visible
        )
      })
    }
    return []
  }, [data_points, checkDisplayRules])

  const displays = useMemo(() => {
    return availableDataPoints.reduce((result, dataPoint) => {
      // TODO: check why this jp.query returns array of result
      let dataPointValue = jp.query(customerProfile, dataPoint.field)

      if (Array.isArray(dataPointValue)) {
        dataPointValue = dataPointValue[0]
      }

      if (typeof dataPointValue === 'undefined') {
        logError({
          type: 'unknwown-customer-profile-field',
          field: dataPoint.field,
        })
        return result
      }

      if (dataPointValue === null) {
        dataPointValue = 'null'
      }

      let fieldValue
      let color

      if (dataPoint.display_type === 'tag_display_by_data_equality') {
        const constraint = (dataPoint.constraints || []).find(
          (current) =>
            dataPointValue.toString().toLowerCase() === current.data.toString().toLowerCase(),
        )
        if (constraint) {
          color = constraint.tag_color
          fieldValue = t(
            `Customer Widget.Tabs.Profile.Tag Labels.${constraint.tag_translation_key}`,
          )
        }
      } else if (dataPoint.display_type === 'tag_display_by_data_range') {
        const constraint = sortDesc(dataPoint.constraints || [], (item) => item.data).find(
          (current) => dataPointValue >= current.data,
        )
        if (constraint) {
          color = constraint.tag_color
          fieldValue = `${dataPointValue} - ${t(
            `Customer Widget.Tabs.Profile.Tag Labels.${constraint.tag_translation_key}`,
          )}`
        }
      } else if (dataPoint.display_type === 'tag_display_by_data_value') {
        const constraint = dataPoint.constraints
        color = constraint.tag_color
        fieldValue = dataPointValue
      }

      // if no constraint is matched, default render
      if (!fieldValue) {
        fieldValue = dataPointValue.toString()
      }
      if (!color) {
        color = DEFAULT_DOT_COLOR
      }

      result.push({
        fieldLabel: t(`Customer Widget.Tabs.Profile.${dataPoint.field_translation_key}`),
        fieldValue,
        color,
      })

      return result
    }, [] as Array<{ fieldLabel: string; fieldValue: string; color: string }>)
  }, [availableDataPoints, customerProfile, t])

  return (
    <div>
      {displays.length === 0 && (
        <div>
          <Text.Text>{t('Interface.No Data Found')}</Text.Text>
        </div>
      )}
      {displays.length > 0 && (
        <Stack itemsWidth={`${100 / layout_col_count}%`}>
          {displays.slice(0, 6).map((parameters, index) => (
            <Stack.Item key={index}>
              <Text.Primary color={text.secondary}>{parameters.fieldLabel}</Text.Primary>
              <Box display='flex' alignItems='center'>
                <Dot color={parameters.color} />
                <Text.Primary ml={8}>{parameters.fieldValue}</Text.Primary>
              </Box>
            </Stack.Item>
          ))}
        </Stack>
      )}

      {displays.length > 6 && (
        <div className={classes.viewMoreButtonHolder}>
          <Button type='default' size='small' onClick={() => setShowAll(true)}>
            {t('Interface.View more')}
          </Button>
        </div>
      )}

      {showAll && (
        <PopupWidgetContainer
          visible
          title={t(`Customer Widget.Tabs.Profile.Title`)}
          onClose={() => setShowAll(false)}
          widgetContainerType={widgetContainerType}
        >
          <Descriptions column={1} bordered>
            {displays.map((current, index) => (
              <Descriptions.Item
                key={index}
                className={classes.modalProfileItemRow}
                label={
                  <div className={classes.modalProfileItemLabel}>
                    <Text.Text>{current.fieldLabel}</Text.Text>
                  </div>
                }
              >
                <Text.Text>{current.fieldValue}</Text.Text>
              </Descriptions.Item>
            ))}
          </Descriptions>
        </PopupWidgetContainer>
      )}
    </div>
  )
}

export default ProfileView
