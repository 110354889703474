import { CustomerApiResponse, OrderApiResponse } from 'types/api/orderApi/order'
import { FulfillmentApiRiderResponse } from 'types/api/riderApi/rider'
import { TicketDetailsApiResponse } from 'types/api/ticketApi/getTicketDetails'
import { VendorApiResponse } from 'types/api/vendorApi/vendor'
import { LinesOfBusiness } from 'types/session/linesOfBusiness'
import { DeliveryItem, FulfillmentApiResponse } from 'types/api/fulfillmentApi/fulfillment'

export enum DeliveryProviders {
  platformDelivery = 'platform_delivery',
  pickDelivery = 'pickup',
  vendorDelivery = 'vendor_delivery',
  partnerDelivery = 'partner_delivery',
  dineIn = 'dine_in',
}

export type PhoneDecorator = 'microbase' | 'tel-link' | 'none'

export type Language =
  | 'en'
  | 'es'
  | 'ko'
  | 'sv'
  | 'fi'
  | 'de'
  | 'hu'
  | 'tr'
  | 'cs'
  | 'ja'
  | 'ar'
  | 'th'
  | 'gr'
  | 'vi'

export type TabName = 'ticket' | 'orders' | 'profile' | 'activity' | 'plugins'

export type ButtonType = 'text' | 'solid' | 'default' | 'link' | 'outlined'

export type ButtonColor = 'primary' | 'danger'

export type Host = 'salesforce' | 'herocare'

export type WidgetContainerType = 'modal' | 'drawer'

export type WidgetCollapseState = 'disabled' | 'expanded' | 'collapsed'

export type UiVersion = 'v1' | 'v2'

export type WidgetName =
  | 'search_vendor_panel'
  | 'search_order_panel'
  | 'resolution_bar'
  | 'cancel_order'
  | 'change_cooking_instructions'
  | 'change_delivery_time'
  | 'change_delivery_status'
  | 'compensation'
  | 'full_refund'
  | 'partial_refund'
  | 'modify_delivery_address'
  | 'new_comment'
  | 'search_order'
  | 'search_vendor'
  | 'search_voucher'
  | 'order_items'
  | 'order_items_v2'
  | 'order_summary'
  | 'order_invoice'
  | 'order_updates'
  | 'customer_details'
  | 'customer_attributes'
  | 'customer_vouchers'
  | 'customer_wallet'
  | 'activity'
  | 'last_orders'
  | 'order_deliveries'
  | 'order_deliveries_v2'
  | 'summary_panel'
  | 'vendor_summary'
  | 'vendor_availability'
  | 'vendor_invoice'
  | 'vendor_payout'
  | 'vendor_devices'
  | 'vendor_contacts'
  | 'vendor_addresses'
  | 'change_dropoff_pin'
  | 'rider_break'
  | 'move_delivery_pending_to_queue'
  | 'live_tracking'
  | 'switch_payable'
  | 'dh.herocare.summary-panel-plugin'
  | 'dh.pandora.location-customer-address'
  | 'dh.pandago-c2c.recipient'
  | 'dh.pandora.subscription-plugin'
  | 'dh.talabat.cancel-order'
  | 'dh.herocare.wrap-up-plugin'
  | 'dh.herocare.reassign-order-plugin'
  | 'dh.herocare.ticket-escalate-plugin'
  | 'dh.herocare.other-tickets-plugin'
  | 'dh.herocare.ticket-details-plugin'
  | 'dh.herocare.add-rider-plugin'
  | 'dh.herocare.update-order-payable'
  | 'dh.herocare.vendor-summary-plugin'
  | 'dh.herocare.vendor-schedule-plugin'
  | 'dh.herocare.vendor-update-availability-plugin'
  | 'dh.herocare.vendor-contacts-plugin'
  | 'dh.herocare.vendor-device-plugin'
  | 'dh.herocare.vendor-payment-plugin'
  | 'dh.herocare.rider-summary-plugin'
  | 'dh.herocare.live-tracking-plugin'
  | 'dh.herocare.rider-events-plugin'
  | 'dh.herocare.vendor-availability-logs-plugin'

export type WidgetRenderState =
  | 'loading'
  | 'load-error'
  | 'pending-requirements'
  | 'failed-requirements'
  | 'rendering'
  | 'rendered'
  | 'crashed'

export enum widgetTypes {
  builtin = 'builtin',
  plugin = 'plugin',
  tabbedWidgetsContainer = 'tabbed_widgets_container',
}

export enum TimeUnits {
  yrs = 'yrs',
  mons = 'mons',
  wks = 'wks',
  days = 'days',
  hrs = 'hrs',
  mins = 'mins',
  secs = 'secs',
}

export enum builtinWidgetNames {
  searchVendorPanel = 'search_vendor_panel',
  searchOrderPanel = 'search_order_panel',

  resolutionBar = 'resolution_bar',

  cancelOrder = 'cancel_order',
  changeCookingInstructions = 'change_cooking_instructions',
  changeDeliveryTime = 'change_delivery_time',
  compensation = 'compensation',
  fullRefund = 'full_refund',
  partialRefund = 'partial_refund',
  modifyDeliveryAddress = 'modify_delivery_address',
  newComment = 'new_comment',

  searchOrder = 'search_order',
  searchVendor = 'search_vendor',
  searchVoucher = 'search_voucher',

  // summary panel
  summaryPanel = 'summary_panel',

  // order  widgets
  orderItems = 'order_items',
  orderItemsV2 = 'order_items_v2',
  // order widgets
  orderSummary = 'order_summary',
  orderInvoice = 'order_invoice',

  // basket update
  orderUpdates = 'order_updates',

  // customer profile widgets
  customerDetails = 'customer_details',
  customerAttributes = 'customer_attributes',
  customerVouchers = 'customer_vouchers',
  customerWallet = 'customer_wallet',

  // history of events / activity
  activity = 'activity',

  lastOrders = 'last_orders',

  orderDeliveries = 'order_deliveries',
  orderDeliveriesV2 = 'order_deliveries_v2',

  vendorSummary = 'vendor_summary',
  vendorContacts = 'vendor_contacts',
  vendorAddresses = 'vendor_addresses',
  vendorAvailability = 'vendor_availability',
  vendorInvoice = 'vendor_invoice',

  vendorPayout = 'vendor_payout',
  switch_payable = 'switch_payable',

  // vendor devices
  vendorDevices = 'vendor_devices',

  // rider stacked orders
  riderStackedOrders = 'rider_stacked_orders',

  // rider upcoming shifts
  riderUpcomingShifts = 'rider_upcoming_shifts',

  // change delivery pin
  changeDropoffPin = 'change_dropoff_pin',

  // Move Delivery To Queue Action
  moveDeliveryPendingToQueue = 'move_delivery_pending_to_queue',

  // widget to put rider on break
  riderBreak = 'rider_break',

  // Change delivery status action
  changeDeliveryStatus = 'change_delivery_status',

  // set primary delivery action
  setPrimaryDelivery = 'set_primary_delivery',

  // live tracking
  liveTracking = 'live_tracking',
}

export enum dataPointRequirements {
  always = 'always',
  never = 'never',
  neverDisplay = 'never_display',
  displayWhenDataFound = 'display_when_data_found',
}

export enum fraudStatusResults {
  green = 'green',
  red = 'red',
  orange = 'orange',
}

export enum orderStages {
  confirmation = 'CONFIRMATION',
  postDelivery = 'POST_DELIVERY',
  delivery = 'DELIVERY',
  preparation = 'PREPARATION',
}

export enum orderPropertyFilter {
  CustomerCode = 'filter.customer.profile.code',
  CustomerEmail = 'filter.customer.profile.email',
  CustomerPhoneNumber = 'filter.customer.profile.phone',
  VendorId = 'filter.vendor.id',
  OrderIds = 'filter.order.ids',
}

export enum Params {
  orderId = 'orderId',
  riderId = 'riderId',
  vendorId = 'vendorId',
  customerId = 'customerId',
  caseId = 'caseId',
}

export interface WidgetSubjects {
  order?: OrderApiResponse
  rider?: FulfillmentApiRiderResponse
  riderCurrentOrder?: OrderApiResponse
  riderCurrentVendor?: VendorApiResponse
  orderFulfillment?: FulfillmentApiResponse

  vendor?: VendorApiResponse
  customer?: CustomerApiResponse
  ticket?: TicketDetailsApiResponse
  delivery?: DeliveryItem

  ccrCode?: string
  globalVendorId?: string
  globalEntityId?: string
  lob?: LinesOfBusiness

  vendorId?: string
  customerId?: string
  riderId?: string
  orderId?: string
  caseId?: string

  uiVersion?: UiVersion
  containerType?: WidgetContainerType
  activeTab?: TabName

  lang?: Language
}

export type OverridableWidgetSubjects = Partial<Pick<WidgetSubjects, 'containerType' | 'delivery'>>

export type WidgetSubject = Exclude<keyof WidgetSubjects, 'lang'>
