import React from 'react'
import { createCustomSvgIcon } from 'factory/createCustomSvgIcon'

export const ChefHatIcon = createCustomSvgIcon({
  viewBoxWidth: 14,
  viewBoxHeight: 14,
  content: (
    <>
      <path
        d='M2.81576 10.5712L11.2989 10.5647M6.99965 0.583252C8.37398 0.583252 9.52189 1.54575 9.80134 2.8298C10.1647 2.73196 10.5439 2.70629 10.9172 2.75425C11.2905 2.80221 11.6507 2.92287 11.9772 3.10934C12.3036 3.2958 12.59 3.54442 12.8199 3.841C13.0498 4.13758 13.2187 4.47632 13.3171 4.83786C13.4154 5.1994 13.4412 5.57668 13.393 5.94814C13.3448 6.3196 13.2235 6.67798 13.0361 7.00282C12.8487 7.32765 12.5988 7.61258 12.3008 7.84133C12.0027 8.07008 11.6623 8.23817 11.2989 8.33601V13.4166H2.7004V8.33601C2.33704 8.23808 1.99662 8.06989 1.69859 7.84105C1.40056 7.61222 1.15074 7.32721 0.963404 7.00231C0.776068 6.67741 0.654882 6.31898 0.606765 5.94748C0.558648 5.57598 0.584542 5.19869 0.682968 4.83715C0.781394 4.4756 0.950425 4.13689 1.18041 3.84034C1.4104 3.5438 1.69683 3.29523 2.02336 3.10883C2.34989 2.92243 2.71013 2.80185 3.08349 2.75398C3.45686 2.7061 3.83604 2.73186 4.1994 2.8298C4.33829 2.19353 4.69182 1.6237 5.20124 1.21501C5.71065 0.806329 6.34528 0.583392 6.99965 0.583252Z'
        stroke='currentColor'
        strokeOpacity='0.55'
        strokeLinecap='round'
        strokeLinejoin='round'
        fill='none'
      />
    </>
  ),
})
