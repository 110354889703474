import { ApiService } from 'services/authApi/getPermissions'
import { FulfillmentApiResponse } from 'types/api/fulfillmentApi/fulfillment'
import { logUnknowRiderError } from 'utils/logUnknownRiderError'

export const getOrderFulfillment: ApiService<
  {
    entityId: string
    orderId: string
  },
  FulfillmentApiResponse
> = (createClient, options) => {
  const {
    entityId,
    orderId,

    clientParams,
  } = options

  const uri = `/FulfillmentAPI/v1/orders/${entityId}/${orderId}`

  return createClient({
    endpointName: 'getOrderFulfillment',
    expectedResponseStatusCode: 200,
    ...clientParams,
    onSuccess: logUnknowRiderError,
  }).get(uri)
}
