import React from 'react'
import { createCustomSvgIcon } from 'factory/createCustomSvgIcon'

export const ArrowDownIcon = createCustomSvgIcon({
  viewBoxWidth: 10,
  viewBoxHeight: 10,
  content: (
    <>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M2.41439 6.94461C2.02386 7.33514 1.46984 7.41428 1.17695 7.12139C0.884058 6.8285 0.963203 6.27448 1.35373 5.88395L4.18215 3.05553C4.42548 2.8122 4.73228 2.68976 4.99984 2.70287C5.26749 2.68961 5.57449 2.81205 5.81794 3.0555L8.64636 5.88393C9.03689 6.27446 9.11603 6.82848 8.82314 7.12137C8.53025 7.41426 7.97623 7.33512 7.5857 6.94459L5.00006 4.35894L2.41439 6.94461Z'
        fill='currentColor'
        fillOpacity='0.55'
      />
    </>
  ),
})
