/**
 * Mini-HistoryOfEvents Customer Tab
 * pulls from API and displays order-related events with filtering options
 * */

// libs
import React, { useState, useEffect, useContext } from 'react'
// contexts and types
import { SessionContext } from 'contexts/session/SessionContext'
import { DataContext } from 'contexts/data/DataContext'
import { RiderServiceContext } from 'contexts/riderService/RiderServiceContext'
import { EntityContext } from 'contexts/entity/EntityContext'
import {
  GetOrderCommentsResponse,
  SingleComment,
  SingleCommentAction,
  ModifyCallBackStatuses,
} from 'types/api/commentApi/getOrderComments'
import {
  AvailableCommentActionsTypes,
  AvailableTypesLocal,
  CurrentEvent,
  SingleAvailableTimeFrame,
  AvailableFilterTimeFrames,
} from 'types/widgets/historyOfEvents/availableCommentActionsTypes'
import { CancellationReason } from 'types/widgets/historyOfEvents/cancelActionComment'
// hooks
import { useFetchData } from 'hooks/useFetchData'
import { useTranslation } from 'hooks/useTranslation'
//utils
import environment from 'envConfig'
import processApiErrors from 'utils/oneviewApi/processApiErrors'
import compareDateWithNow from 'utils/compareDateWithNow'
import modifyUTC from 'utils/modifyUTC'
import capitalizeFirstLetters from 'utils/capitalizeFirstLetters'
// styles
import { createUseStyles } from 'react-jss'
import styles from './CustomerHistory.styles'
import { Typography, Menu, Button, Tag, Result } from 'antd'
import { DownOutlined, UpOutlined, CommentOutlined } from '@ant-design/icons'
// components
import LoadingView from 'components/LoadingView'
import ErrorView from 'components/ErrorView'
import { useCaptureUserAction } from 'hooks/events/useCaptureUserAction'
import { InlineDropdown } from 'shared/InlineDropdown'

const useStyles = createUseStyles(styles)
interface Props {
  intervalState: number
  isOptionalFilters: boolean
  isAnOptionalFilterSelected: boolean
  setIsAnOptionalFilterSelected: (arg: boolean) => void
}

const CustomerHistory: React.FC<Props> = ({
  intervalState,
  isOptionalFilters,
  isAnOptionalFilterSelected,
  setIsAnOptionalFilterSelected,
}) => {
  const classes = useStyles()
  const { Text } = Typography

  // pull language content
  const { t } = useTranslation()

  const captureUserAction = useCaptureUserAction()

  const {
    entityState: {
      entityConfig: {
        utc_zone,
        fixed_panel_config: {
          widgets_configs: {
            actions: { cancel_order },
          },
        },
      },
    },
  } = useContext(EntityContext)

  // pull rider context
  const {
    riderServiceState: { newCommentId },
  } = useContext(RiderServiceContext)

  // pull orderId & globalentity ID from session context
  const {
    sessionState: { orderId, globalEntityId, userId },
  } = useContext(SessionContext)

  // pull dataState
  const { dataState } = useContext(DataContext)

  // fetch order-related events and pull loading, err, and data state from fetch hook
  // reRenderOrderWidget is passed in dep arr to trigger re-fire of the hook upon fetch failure
  const [reRenderOrderHistory, setReRenderOrderHistory] = useState<boolean>(false)
  const { isLoading, error, data } = useFetchData<boolean | any | GetOrderCommentsResponse>(
    `${environment().oneviewApiRoot}${
      environment().oneviewCommentApiRoot
    }/customer/${globalEntityId}/${userId}`,
    null,
    [
      reRenderOrderHistory,
      intervalState,
      dataState.newComment,
      dataState.autoMissingItemReportComment,
      dataState.autoCancelOrderComment,
      dataState.autoCompensationComment,
      dataState.autoPartialRefundComment,
      dataState.autoFullRefundComment,
      dataState.autoChangeAddressComment,
      dataState.autoChangeInstructionsComment,
      dataState.autoModifyCallbackComment,
    ],
  )

  // selected filter -> initially set to All Comments | refreshed when getOrderComments called periodically
  const [selectedFilter, setSelectedFilter] = useState<string>('')
  useEffect(() => {
    setSelectedFilter(t('History of Events Widget.All Comments'))
  }, [intervalState, t, dataState.newComment])

  // the number displayed next to the default/selected filter in dropdown
  const [numberOfCommentsDisplayedInFilter, setNumberOfCommentsDisplayedInFilter] =
    useState<number>(0)

  // the number displayed next to the default/selected filter in dropdown
  const [immutableNumberOfCommentsReceived, setImmutableNumberOfCommentsReceived] =
    useState<number>(0)

  // action types that are found in API response -> acc. to this, dropdown options are shaped
  const [availableCommentActionsTypes, setAvailableCommentActionsTypes] = useState<
    Array<AvailableTypesLocal>
  >([])

  // comments that are displayed -> initially all comments and changes upon filter selection
  const [commentsDisplayed, setCommentsDisplayed] = useState<Array<SingleComment>>([])

  useEffect(() => {
    if (data) {
      // filter data comments/total, if we are receiving comments of modify_callback
      // set displayed number of comments in filter (All -> total by default)
      if (data.total && data.comments && data.comments.length > 0) {
        data.comments = data.comments.filter((comment: SingleComment) => {
          if (comment?.action === SingleCommentAction.modify_callback) {
            data.total = data.total - 1
            return (
              comment.additional_data?.modify_callback?.status !==
                ModifyCallBackStatuses.MODIFIED &&
              comment.additional_data?.modify_callback?.status !==
                ModifyCallBackStatuses.CONFIRMED &&
              comment.additional_data?.modify_callback?.status !== ModifyCallBackStatuses.REQUESTED
            )
          }
          return comment
        })
        setNumberOfCommentsDisplayedInFilter(data.total)
        setImmutableNumberOfCommentsReceived(data.total)
      }

      // create available types object each with their amount
      const availableTypes: Array<AvailableTypesLocal> = [
        {
          type: AvailableCommentActionsTypes.cancel_order,
          amount: 0,
        },
        {
          type: AvailableCommentActionsTypes.compensation,
          amount: 0,
        },
        {
          type: AvailableCommentActionsTypes.manual_comment,
          amount: 0,
        },
        {
          type: AvailableCommentActionsTypes.system_error,
          amount: 0,
        },
        {
          type: AvailableCommentActionsTypes.change_address,
          amount: 0,
        },
        {
          type: AvailableCommentActionsTypes.change_instructions,
          amount: 0,
        },
        {
          type: AvailableCommentActionsTypes.report_missing_items,
          amount: 0,
        },
        {
          type: AvailableCommentActionsTypes.full_refund,
          amount: 0,
        },
        {
          type: AvailableCommentActionsTypes.partial_refund,
          amount: 0,
        },
      ]

      // map over comments -> determine which types exist in which amount and update availableTypes accordingly
      if (data.comments) {
        const commentsSorted = data.comments.sort(
          (a, b) => -a.created_at.localeCompare(b.created_at),
        )

        setCommentsDisplayed(commentsSorted)

        commentsSorted.forEach((comment: SingleComment) => {
          switch (comment.action) {
            case SingleCommentAction.cancel_order:
              availableTypes.forEach((availableType: AvailableTypesLocal) => {
                if (availableType.type === AvailableCommentActionsTypes.cancel_order) {
                  availableType.amount++
                }
              })
              break

            case SingleCommentAction.compensation:
              availableTypes.forEach((availableType: AvailableTypesLocal) => {
                if (availableType.type === AvailableCommentActionsTypes.compensation) {
                  availableType.amount++
                }
              })
              break

            case SingleCommentAction.manual_comment:
              availableTypes.forEach((availableType: AvailableTypesLocal) => {
                if (availableType.type === AvailableCommentActionsTypes.manual_comment) {
                  availableType.amount++
                }
              })
              break

            case SingleCommentAction.modify_callback:
              availableTypes.forEach((availableType: AvailableTypesLocal) => {
                if (availableType.type === AvailableCommentActionsTypes.system_error) {
                  availableType.amount++
                }
              })
              break

            case SingleCommentAction.modify_delivery_address:
              availableTypes.forEach((availableType: AvailableTypesLocal) => {
                if (availableType.type === AvailableCommentActionsTypes.change_address) {
                  availableType.amount++
                }
              })
              break

            case SingleCommentAction.modify_delivery_instructions:
              availableTypes.forEach((availableType: AvailableTypesLocal) => {
                if (availableType.type === AvailableCommentActionsTypes.change_instructions) {
                  availableType.amount++
                }
              })
              break

            case SingleCommentAction.report_missing_items:
              availableTypes.forEach((availableType: AvailableTypesLocal) => {
                if (availableType.type === AvailableCommentActionsTypes.report_missing_items) {
                  availableType.amount++
                }
              })
              break

            case SingleCommentAction.voucher_refund:
              // if case voucher refund, figure out if it's partial or full refund and update AvailableCommentActionsTypes accordingly
              let refundPurpose = 'item'
              if (comment.additional_data.voucher_refund.refund_purpose === 'order_refund') {
                refundPurpose = 'order'
              }

              // update either Partial or Full refund amount in availableTypes to be displayed
              availableTypes.forEach((availableType: AvailableTypesLocal) => {
                if (
                  refundPurpose === 'item' &&
                  availableType.type === AvailableCommentActionsTypes.partial_refund
                ) {
                  availableType.amount++
                } else if (
                  refundPurpose === 'order' &&
                  availableType.type === AvailableCommentActionsTypes.full_refund
                ) {
                  availableType.amount++
                }
              })
              break

            case SingleCommentAction.payment_refund:
              availableTypes.forEach((availableType: AvailableTypesLocal) => {
                if (availableType.type === AvailableCommentActionsTypes.partial_refund) {
                  availableType.amount++
                }
              })
              break
            default:
              break
          }
        })

        // set availableTypes to local state to be rendered
        setAvailableCommentActionsTypes(availableTypes)
      }
    }
  }, [data])

  // Comment Type Dropdown
  const [isDropdownButtonActive, setIsDropdownButtonActive] = useState<boolean>(false)
  const [isTimeframeDropdownButtonActive, setIsTimeframeDropdownButtonActive] =
    useState<boolean>(false)

  const handleDropdownButtonClick = (e: any) => {
    e.preventDefault()
    setIsDropdownButtonActive(true)
  }
  const handleTimeframeDropdownButtonClick = (e: any) => {
    e.preventDefault()
    setIsTimeframeDropdownButtonActive(true)
  }

  const [selectedTimeframe, setSelectedTimeframe] = useState<string>(
    t('History of Events Widget.Timeframes.All (Last 90 Days)'),
  )

  const availableTimeFrames: Array<SingleAvailableTimeFrame> = [
    {
      value: AvailableFilterTimeFrames.last_seven,
      day_limit: 7,
    },
    {
      value: AvailableFilterTimeFrames.last_fourteen,
      day_limit: 14,
    },
    {
      value: AvailableFilterTimeFrames.last_thirty,
      day_limit: 30,
    },
    {
      value: AvailableFilterTimeFrames.all,
      day_limit: 90,
    },
  ]

  const [currentFilterTimeframe, setCurrentFilterTimeframe] = useState<SingleAvailableTimeFrame>()
  const [currentEvent, setCurrentEvent] = useState<CurrentEvent>()

  useEffect(() => {
    if (data) {
      setCurrentFilterTimeframe({
        value: AvailableFilterTimeFrames.all,
        day_limit: 90,
      })
      setCurrentEvent({
        filterType: t('History of Events Widget.All Comments'),
        displayedFilterType: t('History of Events Widget.All Comments'),
        typeAmount: data.total,
      })
    }
  }, [data, t])

  const [isFiltering, setIsFiltering] = useState<boolean>(false)

  const handleFilterSelection = (
    filterType: string,
    displayedFilterType: string,
    typeAmount: number,
    timeframe: SingleAvailableTimeFrame,
  ) => {
    setIsFiltering(true)
    // disable dropdown
    setIsDropdownButtonActive(false)
    setIsTimeframeDropdownButtonActive(false)

    // find out selected filter through displayed user string and set selectedUSerFilter by SingleCommentAction enum
    let selectedUSerFilter = ''
    if (displayedFilterType === AvailableCommentActionsTypes.report_missing_items) {
      selectedUSerFilter = SingleCommentAction.report_missing_items
    } else if (displayedFilterType === AvailableCommentActionsTypes.compensation) {
      selectedUSerFilter = SingleCommentAction.compensation
    } else if (displayedFilterType === AvailableCommentActionsTypes.cancel_order) {
      selectedUSerFilter = SingleCommentAction.cancel_order
    } else if (displayedFilterType === AvailableCommentActionsTypes.change_address) {
      selectedUSerFilter = SingleCommentAction.modify_delivery_address
    } else if (displayedFilterType === AvailableCommentActionsTypes.change_instructions) {
      selectedUSerFilter = SingleCommentAction.modify_delivery_instructions
    } else if (displayedFilterType === AvailableCommentActionsTypes.system_error) {
      selectedUSerFilter = SingleCommentAction.modify_callback
    } else if (displayedFilterType === AvailableCommentActionsTypes.manual_comment) {
      selectedUSerFilter = SingleCommentAction.manual_comment
    } else if (displayedFilterType === AvailableCommentActionsTypes.partial_refund) {
      selectedUSerFilter = SingleCommentAction.voucher_refund
    }

    // indicate a filter is selected to be able to display a 'Clear' option
    if (
      filterType === t('History of Events Widget.All Comments') &&
      timeframe.value === AvailableFilterTimeFrames.all
    ) {
      setIsAnOptionalFilterSelected(false)
    } else {
      setIsAnOptionalFilterSelected(true)
    }

    let commentsAfterFiltering: Array<SingleComment> = []
    // filter comments -> if All selected, set all available comments
    if (filterType === t('History of Events Widget.All Comments')) {
      commentsAfterFiltering = data.comments
      setSelectedFilter(t('History of Events Widget.All Comments'))
    } else {
      commentsAfterFiltering = data.comments
        .sort((a, b) => -a.created_at.localeCompare(b.created_at))
        .filter((comment: SingleComment) => {
          // if full refund is selected, filter only voucher_refund by its purpose
          if (displayedFilterType === AvailableCommentActionsTypes.full_refund) {
            return comment.additional_data.voucher_refund?.refund_purpose === 'order_refund'
            // if voucher_refund selected -> filter by its purpose as well as include payment_refund (always partial)
          } else if (selectedUSerFilter === SingleCommentAction.voucher_refund) {
            return (
              (comment.action === SingleCommentAction.voucher_refund &&
                comment.additional_data.voucher_refund?.refund_purpose === 'item_refund') ||
              comment.action === SingleCommentAction.payment_refund
            )
          } else {
            // otherwise selected filter must match commet's action type
            return comment.action === selectedUSerFilter
          }
        })
      // set selected filter's name and amount
      setSelectedFilter(t(`History of Events Widget.Event Types.${displayedFilterType}`))
    }

    setCurrentEvent({ filterType, displayedFilterType, typeAmount })

    //////////////// TIMEFRAME FILTER ////////////////
    let selectedTimeFrameInLocal = ''
    switch (timeframe.value) {
      case AvailableFilterTimeFrames.last_seven:
        selectedTimeFrameInLocal = t('History of Events Widget.Timeframes.Last 7 Days')

        commentsAfterFiltering = commentsAfterFiltering.filter((comment: SingleComment) =>
          compareDateWithNow(comment.created_at, 168),
        )

        break
      case AvailableFilterTimeFrames.last_fourteen:
        selectedTimeFrameInLocal = t('History of Events Widget.Timeframes.Last 14 Days')

        commentsAfterFiltering = commentsAfterFiltering.filter((comment: SingleComment) =>
          compareDateWithNow(comment.created_at, 336),
        )

        break
      case AvailableFilterTimeFrames.last_thirty:
        selectedTimeFrameInLocal = t('History of Events Widget.Timeframes.Last 30 Days')

        commentsAfterFiltering = commentsAfterFiltering.filter((comment: SingleComment) =>
          compareDateWithNow(comment.created_at, 720),
        )

        break
      case AvailableFilterTimeFrames.all:
        selectedTimeFrameInLocal = t('History of Events Widget.Timeframes.All (Last 90 Days)')
        break
      default:
        break
    }

    setSelectedTimeframe(selectedTimeFrameInLocal)
    setCurrentFilterTimeframe({
      value: timeframe.value,
      day_limit: timeframe.day_limit,
    })

    // update comments displayed and the displayed number
    setCommentsDisplayed(commentsAfterFiltering)
    setNumberOfCommentsDisplayedInFilter(typeAmount)

    setIsFiltering(false)
  }

  useEffect(() => {
    if (!isAnOptionalFilterSelected && immutableNumberOfCommentsReceived) {
      handleFilterSelection(
        t('History of Events Widget.All Comments'),
        t('History of Events Widget.All Comments'),
        immutableNumberOfCommentsReceived,
        {
          value: AvailableFilterTimeFrames.all,
          day_limit: 90,
        },
      )
    }
    // eslint-disable-next-line
  }, [isAnOptionalFilterSelected, immutableNumberOfCommentsReceived, t])

  return (
    <div>
      {/* return loading spinner while fetching data */}
      {(isLoading || isFiltering) && (
        <div className={classes.loadingHolder}>
          <LoadingView text={`${t('Interface.Loading Customer Events')}...`} />
        </div>
      )}

      {/* return err message if data fetch fails */}
      {error && !isLoading && !isFiltering && (
        <ErrorView
          errLogEntry={processApiErrors(
            t,
            error,
            'GetCustomerComments',
            orderId,
            globalEntityId,
            true,
          )}
          buttonText={t('Interface.Try Again')}
          buttonCallBack={() => setReRenderOrderHistory(!reRenderOrderHistory)}
        />
      )}

      {/* CONTENT No Comment Msg -> if data pulled successfully BUT comments exist */}
      {!isLoading &&
        !isFiltering &&
        !error &&
        (!data || immutableNumberOfCommentsReceived === 0) && (
          <Result
            icon={<CommentOutlined />}
            title={t('History of Events Widget.No Comment Found')}
            subTitle={t(
              'History of Events Widget.There is no comment associated with the current customer',
            )}
          />
        )}

      {/* CONTENT with comments -> if data pulled successfully AND comments exist */}
      {!isLoading && !isFiltering && !error && data && immutableNumberOfCommentsReceived > 0 && (
        <React.Fragment>
          <div className={classes.contentHolder}>
            {isOptionalFilters && (
              <div className={classes.filterContainer}>
                {/* COMMENT TYPE FILTER DROPDOWN */}
                <InlineDropdown
                  className={classes.dropdown}
                  overlayStyle={{ width: '100%' }}
                  arrow={true}
                  overlay={
                    // renders filtering options as menu items
                    <Menu>
                      {selectedFilter !== t('History of Events Widget.All Comments') && (
                        <Menu.Item
                          className={classes.menuItem}
                          onClick={() =>
                            handleFilterSelection(
                              t('History of Events Widget.All Comments'),
                              t('History of Events Widget.All Comments'),
                              immutableNumberOfCommentsReceived,
                              currentFilterTimeframe,
                            )
                          }
                        >
                          {`${t(
                            'History of Events Widget.All Comments',
                          )} (${immutableNumberOfCommentsReceived})`}
                        </Menu.Item>
                      )}

                      {availableCommentActionsTypes &&
                        availableCommentActionsTypes.map(
                          (availableType: AvailableTypesLocal, idx: number) => {
                            // return type as menu item only when its amount is greater than xero
                            if (availableType.amount > 0) {
                              const typeInUserLanguage = t(
                                `History of Events Widget.Event Types.${availableType.type}`,
                              )
                              return (
                                <Menu.Item
                                  key={idx}
                                  className={classes.menuItem}
                                  onClick={() => {
                                    captureUserAction('HistoryOfEventsCustomerEventsFilterClicked')
                                    handleFilterSelection(
                                      availableType.type.replace(/ /g, '_').toLowerCase(),
                                      availableType.type,
                                      availableType.amount,
                                      currentFilterTimeframe,
                                    )
                                  }}
                                >
                                  {`${typeInUserLanguage} (${availableType.amount})`}
                                </Menu.Item>
                              )
                            }
                            return null
                          },
                        )}
                    </Menu>
                  }
                  trigger={['click']}
                >
                  <Button
                    type='default'
                    size='small'
                    onClick={(e) => handleDropdownButtonClick(e)}
                    className={classes.dropdownButton}
                  >
                    {`${selectedFilter} (${numberOfCommentsDisplayedInFilter})`}

                    {isDropdownButtonActive ? (
                      <UpOutlined className={classes.dropdownIcon} />
                    ) : (
                      <DownOutlined className={classes.dropdownIcon} />
                    )}
                  </Button>
                </InlineDropdown>

                {/* DATE FILTER DROPDOWN */}
                <InlineDropdown
                  className={classes.dateFilterDropdown}
                  arrow={true}
                  overlay={
                    // renders filtering options as menu items
                    <Menu>
                      {availableTimeFrames &&
                        availableTimeFrames.map(
                          (timeframe: SingleAvailableTimeFrame, idx: number) => {
                            switch (timeframe.value) {
                              case AvailableFilterTimeFrames.last_seven:
                                return (
                                  <Menu.Item
                                    key={idx}
                                    className={classes.menuItem}
                                    onClick={() => {
                                      captureUserAction(
                                        'HistoryOfEventsCustomerTimeframeFilterClicked',
                                      )
                                      handleFilterSelection(
                                        currentEvent.filterType,
                                        currentEvent.displayedFilterType,
                                        currentEvent.typeAmount,
                                        timeframe,
                                      )
                                    }}
                                  >
                                    {t('History of Events Widget.Timeframes.Last 7 Days')}
                                  </Menu.Item>
                                )
                              case AvailableFilterTimeFrames.last_fourteen:
                                return (
                                  <Menu.Item
                                    key={idx}
                                    className={classes.menuItem}
                                    onClick={() => {
                                      captureUserAction(
                                        'HistoryOfEventsCustomerTimeframeFilterClicked',
                                      )
                                      handleFilterSelection(
                                        currentEvent.filterType,
                                        currentEvent.displayedFilterType,
                                        currentEvent.typeAmount,
                                        timeframe,
                                      )
                                    }}
                                  >
                                    {t('History of Events Widget.Timeframes.Last 14 Days')}
                                  </Menu.Item>
                                )
                              case AvailableFilterTimeFrames.last_thirty:
                                return (
                                  <Menu.Item
                                    key={idx}
                                    className={classes.menuItem}
                                    onClick={() => {
                                      captureUserAction(
                                        'HistoryOfEventsCustomerTimeframeFilterClicked',
                                      )
                                      handleFilterSelection(
                                        currentEvent.filterType,
                                        currentEvent.displayedFilterType,
                                        currentEvent.typeAmount,
                                        timeframe,
                                      )
                                    }}
                                  >
                                    {t('History of Events Widget.Timeframes.Last 30 Days')}
                                  </Menu.Item>
                                )
                              case AvailableFilterTimeFrames.all:
                                return (
                                  <Menu.Item
                                    key={idx}
                                    className={classes.menuItem}
                                    onClick={() => {
                                      captureUserAction(
                                        'HistoryOfEventsCustomerTimeframeFilterClicked',
                                      )
                                      handleFilterSelection(
                                        currentEvent.filterType,
                                        currentEvent.displayedFilterType,
                                        currentEvent.typeAmount,
                                        timeframe,
                                      )
                                    }}
                                  >
                                    {t('History of Events Widget.Timeframes.All (Last 90 Days)')}
                                  </Menu.Item>
                                )
                              default:
                                return null
                            }
                          },
                        )}
                    </Menu>
                  }
                  trigger={['click']}
                >
                  <Button
                    type='default'
                    size='small'
                    onClick={(e) => handleTimeframeDropdownButtonClick(e)}
                    className={classes.dropdownButton}
                  >
                    {selectedTimeframe}

                    {isTimeframeDropdownButtonActive ? (
                      <UpOutlined className={classes.dropdownIcon} />
                    ) : (
                      <DownOutlined className={classes.dropdownIcon} />
                    )}
                  </Button>
                </InlineDropdown>
              </div>
            )}
          </div>

          {/* COMMENTS */}
          <div className={classes.commentsHolder}>
            {commentsDisplayed && commentsDisplayed.length > 0 ? (
              commentsDisplayed.map((comment: SingleComment, idx: number) => {
                let orderIdOfComment = ''
                let shouldCommentBeHighlighted = false

                if (comment.order_id) {
                  orderIdOfComment = comment.order_id
                }

                if (newCommentId && newCommentId === comment.id) {
                  shouldCommentBeHighlighted = true
                }

                switch (comment.action) {
                  case SingleCommentAction.cancel_order:
                    if (
                      dataState?.autoCancelOrderComment &&
                      comment?.order_id &&
                      dataState.autoCancelOrderComment === comment.order_id
                    ) {
                      shouldCommentBeHighlighted = true
                    }

                    // set initial cancellation reason to what API returns
                    let cancellationReasonToDisplay = comment.additional_data.cancel_order.reason

                    // check if entity wants to display the reason returned bu API
                    // useReasonToDisplayCancelComment being truthy means entity wants display reason to be pulled from config
                    if (cancel_order?.useReasonToDisplayCancelComment) {
                      // find matching id and update cancellationReasonToDisplay
                      const reasonFound = cancel_order.availableCancellationReasons.find(
                        (reason: CancellationReason) =>
                          reason.reason === comment.additional_data.cancel_order.reason,
                      )
                      if (reasonFound) {
                        cancellationReasonToDisplay = reasonFound.translation_key
                      }
                    }
                    return (
                      <div
                        key={idx}
                        className={
                          shouldCommentBeHighlighted
                            ? classes.singleCommentHolderHighlighted
                            : classes.singleCommentHolder
                        }
                      >
                        <div className={classes.commentHeader}>
                          <Text className={classes.commentDateText}>
                            {modifyUTC(comment.created_at, utc_zone)}
                          </Text>
                          <Tag color='blue'>
                            {t('History of Events Widget.Event Types.Cancel Order')}
                          </Tag>
                        </div>
                        <Text className={classes.commentText}>
                          <Text>
                            {`${comment.agent_id
                              .split('@')[0]
                              .split('')
                              .join('')
                              .replace(/\./g, ' ')} ${t(
                              'History of Events Widget.Content.Cancelled order due to',
                            )} ${cancellationReasonToDisplay.replace(/_/g, ' ').toLowerCase()}.`}
                          </Text>
                        </Text>
                        <br />

                        {orderIdOfComment ? (
                          <Text className={classes.orderIdText}>
                            {`${t('Order Widget.Order ID')}: ${orderIdOfComment}`}
                          </Text>
                        ) : null}
                      </div>
                    )

                  case SingleCommentAction.compensation:
                    if (
                      dataState?.autoCompensationComment &&
                      comment?.additional_data?.compensation?.voucher_code &&
                      dataState.autoCompensationComment.code ===
                        comment.additional_data.compensation.voucher_code
                    ) {
                      shouldCommentBeHighlighted = true
                    }

                    return (
                      <div
                        key={idx}
                        className={
                          shouldCommentBeHighlighted
                            ? classes.singleCommentHolderHighlighted
                            : classes.singleCommentHolder
                        }
                      >
                        <div className={classes.commentHeader}>
                          <Text className={classes.commentDateText}>
                            {modifyUTC(comment.created_at, utc_zone)}
                          </Text>
                          <Tag color='blue'>
                            {t('History of Events Widget.Event Types.Compensation')}
                          </Tag>
                        </div>
                        <Text className={classes.commentText}>
                          <Text>
                            {`${comment.agent_id
                              .split('@')[0]
                              .split('')
                              .join('')
                              .replace(/\./g, ' ')} ${t(
                              'History of Events Widget.Content.Created voucher',
                            )} `}
                          </Text>
                          <Text strong>
                            {`${comment.additional_data.compensation.voucher_currency} ${comment.additional_data.compensation.voucher_value}`}
                          </Text>
                          <Text>{` ${t('History of Events Widget.Content.For reason')} `}</Text>
                          <Text strong>
                            {`${comment.additional_data.compensation.compensation_purpose?.replace(
                              /_/g,
                              ' ',
                            )}.`}
                          </Text>
                          <Text>{` ${t('History of Events Widget.Content.Voucher Code')}: `}</Text>
                          <Text strong>
                            {`${comment.additional_data.compensation.voucher_code}.`}
                          </Text>
                        </Text>
                        <br />
                        {orderIdOfComment ? (
                          <Text className={classes.orderIdText}>
                            {`${t('Order Widget.Order ID')}: ${orderIdOfComment}`}
                          </Text>
                        ) : null}
                      </div>
                    )

                  case SingleCommentAction.manual_comment:
                    return (
                      <div
                        key={idx}
                        className={
                          shouldCommentBeHighlighted
                            ? classes.singleCommentHolderHighlighted
                            : classes.singleCommentHolder
                        }
                      >
                        <div className={classes.commentHeader}>
                          <Text className={classes.commentDateText}>
                            {modifyUTC(comment.created_at, utc_zone)}
                          </Text>
                          <Tag color='blue'>
                            {t('History of Events Widget.Event Types.Manual Comment')}
                          </Tag>
                        </div>
                        <Text className={classes.commentText}>
                          <Text>
                            {`${comment.agent_id
                              .split('@')[0]
                              .split('')
                              .join('')
                              .replace(/\./g, ' ')}: `}
                          </Text>
                          <Text>{comment.additional_data.manual_comment.text}</Text>
                        </Text>
                        <br />
                        {orderIdOfComment ? (
                          <Text className={classes.orderIdText}>
                            {`${t('Order Widget.Order ID')}: ${orderIdOfComment}`}
                          </Text>
                        ) : null}
                      </div>
                    )

                  case SingleCommentAction.modify_callback:
                    if (
                      comment?.additional_data?.modify_callback?.status ===
                        ModifyCallBackStatuses.CONFIRMED ||
                      comment?.additional_data?.modify_callback?.status ===
                        ModifyCallBackStatuses.MODIFIED ||
                      comment?.additional_data?.modify_callback?.status ===
                        ModifyCallBackStatuses.REQUESTED
                    ) {
                      return null
                    } else {
                      if (
                        dataState?.autoModifyCallbackComment &&
                        orderId &&
                        dataState.autoModifyCallbackComment === orderId
                      ) {
                        shouldCommentBeHighlighted = true
                      }

                      return (
                        <div
                          key={idx}
                          className={
                            shouldCommentBeHighlighted
                              ? classes.singleCommentHolderHighlighted
                              : classes.singleCommentHolder
                          }
                        >
                          <div className={classes.commentHeader}>
                            <Text className={classes.commentDateText}>
                              {modifyUTC(comment.created_at, utc_zone)}
                            </Text>
                            <Tag color='blue'>
                              {t('History of Events Widget.Event Types.System Error')}
                            </Tag>
                          </div>
                          <Text className={classes.commentText}>
                            <Text>
                              {`${comment.agent_id
                                .split('@')[0]
                                .split('')
                                .join('')
                                .replace(/\./g, ' ')} ${t(
                                'History of Events Widget.Content.Changed delivery address failed due to',
                              )} `}
                            </Text>
                            <Text strong>{`${t(
                              'History of Events Widget.Content.System Error',
                            )}.`}</Text>
                          </Text>
                          <br />
                          {orderIdOfComment ? (
                            <Text className={classes.orderIdText}>
                              {`${t('Order Widget.Order ID')}: ${orderIdOfComment}`}
                            </Text>
                          ) : null}
                        </div>
                      )
                    }

                  case SingleCommentAction.modify_delivery_address:
                    if (
                      dataState?.autoChangeAddressComment &&
                      orderId &&
                      dataState.autoChangeAddressComment === orderId
                    ) {
                      shouldCommentBeHighlighted = true
                    }

                    return (
                      <div
                        key={idx}
                        className={
                          shouldCommentBeHighlighted
                            ? classes.singleCommentHolderHighlighted
                            : classes.singleCommentHolder
                        }
                      >
                        <div className={classes.commentHeader}>
                          <Text className={classes.commentDateText}>
                            {modifyUTC(comment.created_at, utc_zone)}
                          </Text>
                          <Tag color='blue'>
                            {t('History of Events Widget.Event Types.Change Address')}
                          </Tag>
                        </div>
                        <Text className={classes.commentText}>
                          <Text>
                            {`${comment.agent_id
                              .split('@')[0]
                              .split('')
                              .join('')
                              .replace(/\./g, ' ')} ${t(
                              'History of Events Widget.Content.Changed delivery address to',
                            )} `}
                          </Text>
                          <Text strong>
                            {` ${comment.additional_data.modify_delivery_address.new.formatted_address}. `}
                          </Text>
                          <Text>{`${t(
                            'History of Events Widget.Content.Initial delivery address',
                          )}: `}</Text>
                          <Text strong>
                            {`${comment.additional_data.modify_delivery_address.old.formatted_address}.`}
                          </Text>
                        </Text>
                        <br />
                        {orderIdOfComment ? (
                          <Text className={classes.orderIdText}>
                            {`${t('Order Widget.Order ID')}: ${orderIdOfComment}`}
                          </Text>
                        ) : null}
                      </div>
                    )

                  case SingleCommentAction.modify_delivery_instructions:
                    if (
                      dataState?.autoChangeInstructionsComment &&
                      orderId &&
                      dataState.autoChangeInstructionsComment === orderId
                    ) {
                      shouldCommentBeHighlighted = true
                    }

                    return (
                      <div
                        key={idx}
                        className={
                          shouldCommentBeHighlighted
                            ? classes.singleCommentHolderHighlighted
                            : classes.singleCommentHolder
                        }
                      >
                        <div className={classes.commentHeader}>
                          <Text className={classes.commentDateText}>
                            {modifyUTC(comment.created_at, utc_zone)}
                          </Text>
                          <Tag color='blue'>
                            {t('History of Events Widget.Event Types.Change Instructions')}
                          </Tag>
                        </div>
                        <Text className={classes.commentText}>
                          <Text>
                            {`${comment.agent_id
                              .split('@')[0]
                              .split('')
                              .join('')
                              .replace(/\./g, ' ')} ${t(
                              'History of Events Widget.Content.Changed delivery instructions to',
                            )} `}
                          </Text>
                          <Text strong>
                            {`${comment.additional_data.modify_delivery_instructions.new}.`}
                          </Text>
                          <Text>{`${t(
                            'History of Events Widget.Content.Initial delivery instructions',
                          )}: `}</Text>
                          <Text strong>
                            {`${comment.additional_data.modify_delivery_instructions.old}.`}
                          </Text>
                        </Text>
                        <br />
                        {orderIdOfComment ? (
                          <Text className={classes.orderIdText}>
                            {`${t('Order Widget.Order ID')}: ${orderIdOfComment}`}
                          </Text>
                        ) : null}
                      </div>
                    )

                  case SingleCommentAction.report_missing_items:
                    if (
                      dataState?.autoMissingItemReportComment &&
                      comment?.additional_data?.report_missing_items?.sf_case_id &&
                      dataState.autoMissingItemReportComment ===
                        comment.additional_data.report_missing_items.sf_case_id
                    ) {
                      shouldCommentBeHighlighted = true
                    }

                    return (
                      <div
                        key={idx}
                        className={
                          shouldCommentBeHighlighted
                            ? classes.singleCommentHolderHighlighted
                            : classes.singleCommentHolder
                        }
                      >
                        <div className={classes.commentHeader}>
                          <Text className={classes.commentDateText}>
                            {modifyUTC(comment.created_at, utc_zone)}
                          </Text>
                          <Tag color='blue'>
                            {t('History of Events Widget.Event Types.Report Missing Items')}
                          </Tag>
                        </div>
                        <Text className={classes.commentText}>
                          <Text>
                            {`${comment.agent_id
                              .split('@')[0]
                              .split('')
                              .join('')
                              .replace(/\./g, ' ')} ${t(
                              'History of Events Widget.Content.Reported',
                            )} `}
                          </Text>
                          <Text strong>
                            {/* @TODO: clear this mess */}
                            {`${
                              comment?.additional_data?.report_missing_items?.missing_items?.join(
                                ', ',
                              ) || 'some items'
                            }. `}
                          </Text>
                          <Text>{`${t(
                            'History of Events Widget.Content.Wastage Case ID',
                          )}: `}</Text>
                          <Text strong>
                            {`${comment.additional_data.report_missing_items.sf_case_id}.`}
                          </Text>
                        </Text>
                        <br />
                        {orderIdOfComment ? (
                          <Text className={classes.orderIdText}>
                            {`${t('Order Widget.Order ID')}: ${orderIdOfComment}`}
                          </Text>
                        ) : null}
                      </div>
                    )

                  case SingleCommentAction.voucher_refund:
                    if (
                      (dataState?.autoPartialRefundComment?.code &&
                        comment?.additional_data?.voucher_refund?.voucher_code &&
                        dataState.autoPartialRefundComment.code ===
                          comment.additional_data.voucher_refund.voucher_code) ||
                      (dataState?.autoFullRefundComment?.code &&
                        comment?.additional_data?.voucher_refund?.voucher_code &&
                        dataState.autoFullRefundComment.code ===
                          comment.additional_data.voucher_refund.voucher_code)
                    ) {
                      shouldCommentBeHighlighted = true
                    }

                    return (
                      <div
                        key={idx}
                        className={
                          shouldCommentBeHighlighted
                            ? classes.singleCommentHolderHighlighted
                            : classes.singleCommentHolder
                        }
                      >
                        <div className={classes.commentHeader}>
                          <Text className={classes.commentDateText}>
                            {modifyUTC(comment.created_at, utc_zone)}
                          </Text>
                          <Tag color='blue'>
                            {comment.additional_data.voucher_refund.refund_purpose === 'item_refund'
                              ? t('History of Events Widget.Event Types.Partial Refund')
                              : t('History of Events Widget.Event Types.Full Refund')}
                          </Tag>
                        </div>
                        <Text className={classes.commentText}>
                          <Text>
                            {`${comment.agent_id
                              .split('@')[0]
                              .split('')
                              .join('')
                              .replace(/\./g, ' ')} did ${
                              comment.additional_data.voucher_refund.refund_purpose ===
                              'item_refund'
                                ? t('History of Events Widget.Content.Partial')
                                : t('History of Events Widget.Content.Full')
                            } ${t('History of Events Widget.Content.Refund')} `}
                          </Text>
                          <Text strong>
                            {`${comment.additional_data.voucher_refund.voucher_currency} ${
                              comment.additional_data.voucher_refund.voucher_value
                            } ${t('History of Events Widget.Content.With voucher')}. `}
                          </Text>
                          <Text>{`${t('History of Events Widget.Content.Voucher Code')}: `}</Text>
                          <Text strong>
                            {`${comment.additional_data.voucher_refund.voucher_code}. `}
                          </Text>
                        </Text>

                        {comment?.additional_data?.voucher_refund?.contact_reason &&
                          `${t(
                            'Actions Widget.Actions.Compensation.Issue Type',
                          )}: ${capitalizeFirstLetters(
                            comment.additional_data.voucher_refund.contact_reason.replace(
                              /_/g,
                              ' ',
                            ),
                          )}.`}
                        <br />
                        {orderIdOfComment ? (
                          <Text className={classes.orderIdText}>
                            {`${t('Order Widget.Order ID')}: ${orderIdOfComment}`}
                          </Text>
                        ) : null}
                      </div>
                    )

                  case SingleCommentAction.payment_refund:
                    return (
                      <div
                        key={idx}
                        className={
                          shouldCommentBeHighlighted
                            ? classes.singleCommentHolderHighlighted
                            : classes.singleCommentHolder
                        }
                      >
                        <div className={classes.commentHeader}>
                          <Text className={classes.commentDateText}>
                            {modifyUTC(comment.created_at, utc_zone)}
                          </Text>
                          <Tag color='blue'>
                            {t('History of Events Widget.Event Types.Partial Refund')}
                          </Tag>
                        </div>
                        <Text className={classes.commentText}>
                          <Text>
                            {`${comment.agent_id
                              .split('@')[0]
                              .split('')
                              .join('')
                              .replace(/\./g, ' ')} ${t(
                              'History of Events Widget.Content.Issued partial refund',
                            )} `}
                          </Text>

                          {comment?.additional_data?.payment_refund?.refund_currency &&
                          comment?.additional_data?.payment_refund?.refund_amount &&
                          comment?.additional_data?.payment_refund?.refund_target ? (
                            <React.Fragment>
                              <Text strong>
                                {`${comment.additional_data.payment_refund.refund_currency} ${comment.additional_data.payment_refund.refund_amount} `}
                              </Text>
                              <Text>{`${t('History of Events Widget.Content.To')} `}</Text>
                              <Text strong>
                                {`${comment.additional_data.payment_refund.refund_target.toLowerCase()}`}
                              </Text>
                            </React.Fragment>
                          ) : null}
                        </Text>
                        <br />
                        {orderIdOfComment ? (
                          <Text className={classes.orderIdText}>
                            {`${t('Order Widget.Order ID')}: ${orderIdOfComment}`}
                          </Text>
                        ) : null}
                      </div>
                    )

                  default:
                    return null
                }
              })
            ) : (
              <Result
                icon={<CommentOutlined />}
                title={t('History of Events Widget.Ups!')}
                subTitle={t('History of Events Widget.No comment found in the selected timeframe')}
              />
            )}
          </div>
        </React.Fragment>
      )}
    </div>
  )
}

export default CustomerHistory
