export default {
  container: {
    width: '100%',
    '& .ant-space': {
      width: '100%',
    },
    '& .ant-space-item': {
      width: '100%',
    },
  },
  descriptionItem: {
    '& .ant-select': {
      width: '100%',
    },
    '& .ant-typography': {
      color: 'rgba(0, 0, 0, 0.55)',
    },
    '& .ant-select-single:not(.ant-select-customize-input) .ant-select-selector': {
      height: 'auto',
      maxHeight: '45px',
    },
    '& .ant-select-selection-placeholder ': {
      color: 'rgba(0, 0, 0, 0.25)',
    },
  },
  customLabelDuration: {
    fontSize: '14px',
    lineHeight: '22px',
  },
  customLabelAdditionalInfo: {
    fontSize: '12px',
    lineHeight: '20px',
  },
}
