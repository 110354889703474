/**
 * Rider widget
 * runs config checks and renders available tabs
 * */

// libs
import React, { useContext, useMemo } from 'react'
// configs
import { allowedDataPointValues } from 'entityConfig/allowedConfigValues'
// contexts and types
import { DataContext } from 'contexts/data/DataContext'
import { SessionContext } from 'contexts/session/SessionContext'
import { TransformedDataPoint } from 'types/dataTransformers/generic'
import { RiderWidgetSummary } from 'contexts/entity/types'
// hooks
import { useTranslation } from 'hooks/useTranslation'
import { useNewDesign } from 'hooks/useNewDesign'
// utils
import listDataFieldReturnElement from 'utils/listDataFieldReturnElement'
import transformRiderSummary from 'utils/dataTransformers/transformRiderSummary'
import classnames from 'classnames'

// styles
import { createUseStyles } from 'react-jss'
import styles from './Summary.styles'
import { List, Tag } from 'antd'
import { RightCircleOutlined } from '@ant-design/icons'
import ProofOfDelivery from 'widgets/Order/Summary/ProofOfDelivery'
import { useCanDisplayDataPoint, useIsDataPointValid } from 'hooks/useGetValidFeatures'
import { useGetRiderStatusTextAndColor } from 'hooks/useGetRiderStatusTextAndColor'
import { useCaptureUserAction } from 'hooks/events/useCaptureUserAction'
import PhoneDataPointListItem from '../../../components/DataPoint/PhoneDataPointListItem'

const useStyles = createUseStyles(styles)

type RiderSummaryProps = {
  riderSummary: RiderWidgetSummary
  noRiderData?: boolean
  deliveryId?: number
}
const Summary: React.FC<RiderSummaryProps> = ({
  riderSummary: summary,
  deliveryId,
  noRiderData = false,
}) => {
  const classes = useStyles()

  // pull language content
  const { t } = useTranslation()

  const getDeliveryStatusTagParams = useGetRiderStatusTextAndColor()

  // pull data context
  const {
    dataState: { fulfillment },
  } = useContext(DataContext)

  // pull caseId from SessionContext
  const {
    sessionState: { caseId },
  } = useContext(SessionContext)

  const { leftColumn, rightColumn } = useMemo((): {
    leftColumn: TransformedDataPoint[]
    rightColumn: TransformedDataPoint[]
  } => {
    if (noRiderData) {
      return { leftColumn: [], rightColumn: [] }
    }
    const { transformedLeftColumnData, transformedRightColumnData } = transformRiderSummary(
      fulfillment,
      t,
      deliveryId,
    )
    return { leftColumn: transformedLeftColumnData, rightColumn: transformedRightColumnData }
  }, [noRiderData, fulfillment, t, deliveryId])

  const canDisplayDataPoint = useCanDisplayDataPoint()
  const isDataPointValid = useIsDataPointValid()
  const captureUserAction = useCaptureUserAction()

  const { isNewDesignAllowed } = useNewDesign()
  const isProofOfDeliveryEnabled =
    caseId &&
    summary.proof_of_delivery.displayRule.includes(allowedDataPointValues.always) &&
    isDataPointValid(summary.proof_of_delivery.betaRequirement)

  const hadDeliveryInfo = leftColumn.length && rightColumn.length

  const handleDialClick = () => {
    captureUserAction('RiderWidgetRiderPhoneClicked')
  }

  const handlePhoneCopyClick = () => {
    captureUserAction('RiderSummaryMobileNumberCopyClicked')
  }

  return (
    <div className={classes.summaryContainer}>
      {noRiderData || !hadDeliveryInfo ? (
        caseId && (
          <div>
            <ProofOfDelivery />
          </div>
        )
      ) : (
        <div className={classes.listsContainer}>
          <div className={classes.listHolder}>
            <List
              className={classnames({ [classes.list]: isNewDesignAllowed })}
              size='small'
              itemLayout='horizontal'
              dataSource={leftColumn}
              renderItem={(item: TransformedDataPoint) => {
                switch (item.key) {
                  case t('Rider Widget.Rider Name'):
                    const isNameAllowed = isDataPointValid(summary.name.betaRequirement)

                    if (isNameAllowed) {
                      return listDataFieldReturnElement(summary.name.displayRule, item)
                    }
                    return null

                  case t('Rider Widget.Phone Number'):
                    const canCopy = canDisplayDataPoint(item.value, summary.copy_to_clipboard)

                    return (
                      <PhoneDataPointListItem
                        item={item}
                        config={summary.phone}
                        onDialClick={handleDialClick}
                        canCopy={canCopy}
                        onCopyClick={handlePhoneCopyClick}
                      />
                    )
                  default:
                    return listDataFieldReturnElement([], item)
                }
              }}
            />
          </div>
          <div className={classes.listHolder}>
            <List
              className={classnames({ [classes.list]: isNewDesignAllowed })}
              size='small'
              itemLayout='horizontal'
              dataSource={rightColumn}
              renderItem={(item: TransformedDataPoint) => {
                switch (item.key) {
                  case t('Rider Widget.Status'):
                    const { color, text } = getDeliveryStatusTagParams(String(item.value))
                    return (
                      <div>
                        <List.Item>
                          <List.Item.Meta
                            title={
                              <Tag color={color}>
                                <span>{text}</span>
                                <RightCircleOutlined />
                              </Tag>
                            }
                            description={item.key}
                          />
                        </List.Item>
                      </div>
                    )
                  case t('Rider Widget.Vehicle Type'):
                    const isVehicleTypeAllowed = isDataPointValid(
                      summary.vehicle_type.betaRequirement,
                    )

                    if (isVehicleTypeAllowed) {
                      return listDataFieldReturnElement(summary.vehicle_type.displayRule, item)
                    }
                    return null

                  default:
                    return listDataFieldReturnElement([], item)
                }
              }}
            />
            {isProofOfDeliveryEnabled && <ProofOfDelivery />}
          </div>
        </div>
      )}
    </div>
  )
}

export default Summary
