import React from 'react'
import useItemImageStyles from './styles'
import { WarningFilled } from '@ant-design/icons'
import { useTranslation } from 'hooks/useTranslation'

export const ImageLoadFailed = () => {
  const { t } = useTranslation()
  const classes = useItemImageStyles()
  return (
    <div className={classes.itemImageFailed}>
      <div className='placeholder'>
        <img src='/imgs/placeholder.png' alt='Placeholder' />
      </div>
      <p>
        <span className='icon'>
          <WarningFilled />
        </span>
        <span>{t('OrderWidget.Tabs.Items.Image cannot be loaded')}</span>
      </p>
    </div>
  )
}
