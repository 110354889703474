import { Select, Space } from 'antd'
import { useSdk } from 'contexts/SdkProvider'
import moment, { Moment } from 'moment'
import React, { useRef } from 'react'
import { createUseStyles } from 'react-jss'
import { DatePicker, Text } from 'shared'

const useStyles = createUseStyles({
  filterContainer: {
    marginBottom: '28px',
  },
})

export type InvoiceFiltersProps = {
  date: string
  onDateChange: (date: Date) => void
}

export const InvoicesFilters = ({ date, onDateChange }: InvoiceFiltersProps) => {
  const currentDateRef = useRef(moment())
  const { captureUserAction, t } = useSdk()

  const classes = useStyles()

  const onChange = (date: Moment) => {
    onDateChange(new Date(date.toISOString()))

    captureUserAction('SELECT_DATE', {
      reportToEts: true,
      eventDetails: {
        month: date.format('YYYY-MM'),
      },
    })
  }

  const disableDate = (date: Moment) => {
    return date > currentDateRef.current.endOf('month')
  }
  return (
    <section className={classes.filterContainer}>
      <Space>
        <Text.Text>{t('Search by')}</Text.Text>

        <Select defaultValue='month' options={[{ value: 'month', label: t('Month') }]} />

        <DatePicker
          onChange={onChange}
          picker='month'
          value={moment(date)}
          allowClear={false}
          disabledDate={disableDate}
        />
      </Space>
    </section>
  )
}
