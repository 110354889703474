import React from 'react'
import { ActionHandler, OverridableWidgetSubjects, builtinWidgetNames } from 'types/unitedUiConfig'

import { Button } from '../Button'
import { getWidgetId } from 'utils/getters/getWidgetId'
import { SizeType } from 'antd/lib/config-provider/SizeContext'
import { useSdk } from 'contexts/SdkProvider'

export interface ActionHandlerButtonProps {
  activationSubjects?: OverridableWidgetSubjects
  actionHandler: ActionHandler
  label?: string
  size?: SizeType
}

/**
 * an action handler button is a wrapper around uui button,
 * specifically for rendering an action handler
 */
export const ActionHandlerButton = ({
  actionHandler,
  activationSubjects,
  label,
  size = 'middle',
}: ActionHandlerButtonProps) => {
  const { action, handler, display_rules } = actionHandler
  const { checkDisplayRules, activateWidgetView, t } = useSdk()

  const widgetId = getWidgetId(handler)

  const { delivery } = activationSubjects || {}
  const { enabled, visible, reason } = checkDisplayRules({
    displayRules: display_rules,
    opts: {
      delivery,
      widgetName: widgetId as builtinWidgetNames,
    },
  })

  if (!visible) {
    return null
  }

  label = label || t(action.label_translation_key)

  return (
    <Button
      disabled={!enabled}
      tooltip={reason}
      size={size}
      onClick={(e) => {
        e.stopPropagation()
        activateWidgetView(handler, {
          subjects: activationSubjects,
        })
      }}
      color={action.button_color}
      icon={action.icon_name}
      type={action.button_type}
      data-test-id={widgetId}
      className='action-handler'
      data-widget-name={widgetId}
    >
      {label}
    </Button>
  )
}
