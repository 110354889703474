import { useCallback } from 'react'
import { UseCaptureUserActionOpts, useCaptureUserAction } from './events/useCaptureUserAction'

/**
 * measures performance using performance timing api, and tracks to datadog
 * @returns
 */
export const useCreatePerformanceMeasure = () => {
  const captureUserAction = useCaptureUserAction()
  return useCallback(
    (metricName: string, eventName: string, opts?: UseCaptureUserActionOpts) => {
      const startName = metricName + '.start'
      const measureName = metricName + '.duration'

      return {
        /**
         * starts the measurement
         */
        start() {
          performance.mark(startName)
        },

        /**
         * ends the measurement and tracks measured value to datadog.
         * the tracked duration value is set in event.details.duration
         */
        end() {
          const result = performance.measure(measureName, { start: startName })

          captureUserAction(eventName, {
            // Performance events should not be sent to ETS
            reportToEts: false,

            ...opts,
            eventDetails: {
              ...opts?.eventDetails,
              duration: result.duration,
            },
          })

          return result
        },

        /**
         * clears marks from performance timeline
         */
        clear() {
          performance.clearMarks(startName)
        },
      }
    },
    [captureUserAction],
  )
}
