/**
 * BasketUpdatesView is dumy component receiving and rendering a timeline item UI for a single order update
 * */

// libs
import React, { useState, useEffect } from 'react'
// contexts and types
import { OrderSingleBasketUpdate, BasketProduct } from 'types/api/orderApi/orderBasketUpdate'
import { ProductSourceT } from 'types/widgets/order/orderBasketUpdates'
// utils
import modifyUTC from 'utils/modifyUTC'
// hooks
import { useTranslation } from 'hooks/useTranslation'

// styles
import { createUseStyles } from 'react-jss'
import styles from './BasketUpdatesView.styles'
import { Timeline, Typography, Divider } from 'antd'
// components
import BasketUpdatesSingleProduct from './BasketUpdatesSingleProduct'
import { useEntityUtcZone } from 'hooks/useEntityUtcZone'

const useStyles = createUseStyles(styles)

const BasketUpdatesView: React.FC<{ update: OrderSingleBasketUpdate }> = ({ update }) => {
  const classes = useStyles()
  const { Item } = Timeline
  const { Text } = Typography

  // pull language content
  const { t } = useTranslation()

  const utc_zone = useEntityUtcZone()

  const [dateAndTime, setDateAndTime] = useState('')
  useEffect(() => {
    if (update?.timestamp) {
      const timeInLocal = modifyUTC(update.timestamp, utc_zone)
      setDateAndTime(timeInLocal)
    }
  }, [update, utc_zone])

  const [performedBy, setPerformedBy] = useState('')
  useEffect(() => {
    let updatePerformer = ''

    if (update?.source) {
      if (update.source === ProductSourceT.vendor) {
        updatePerformer = t('Order Widget.Tabs.Basket Updates.Vendor')
      } else if (update.source === ProductSourceT.customer) {
        updatePerformer = t('Order Widget.Tabs.Basket Updates.Customer')
      } else if (update.source === ProductSourceT.logistics) {
        updatePerformer = t('Order Widget.Tabs.Basket Updates.Logistics')
      } else if (update.source === ProductSourceT.customer_contact_center) {
        updatePerformer = t('Order Widget.Tabs.Basket Updates.Customer Service')
      } else if (update.source === ProductSourceT.vendor_contact_center) {
        updatePerformer = t('Order Widget.Tabs.Basket Updates.Vendor Service')
      } else {
        updatePerformer = t('Messages.Unknown')
      }
    }

    setPerformedBy(updatePerformer)
  }, [update, t])

  return (
    <React.Fragment>
      <Item className={classes.item}>
        {update?.products?.length &&
          update.products.map((product: BasketProduct, idx: number) => (
            <BasketUpdatesSingleProduct
              key={idx}
              idx={idx}
              product={product}
              dateAndTime={dateAndTime}
            />
          ))}
        <div className={classes.footer}>
          <Text className={classes.performedByLabel}>
            {`${t('Order Widget.Tabs.Basket Updates.Performed By')}`}
          </Text>
          <Text className={classes.topRowTargetContentText}>{performedBy}</Text>
        </div>
      </Item>
      <Divider className={classes.divider} />
    </React.Fragment>
  )
}

export default BasketUpdatesView
