/**
 * Rider Transformer for v2
 */
import { FulfillmentApiRiderResponse } from 'types/api/riderApi/rider'
// utils
import modifyUTC from 'utils/modifyUTC'
import prettifyString from 'utils/prettifyString'

function transformRider(rider: FulfillmentApiRiderResponse, utcZone: string) {
  let currentShift = ''

  if (rider?.courier?.shift_starts_at && rider?.courier?.shift_ends_at) {
    const start = modifyUTC(rider.courier.shift_starts_at, utcZone)
    const end = modifyUTC(rider.courier.shift_ends_at, utcZone)
    currentShift = `${start} – ${end}`
  }

  let status = ''
  if (rider?.courier?.courier_status) {
    status = prettifyString(rider.courier.courier_status)
  }

  let contractType = ''
  if (rider?.courier?.contract_type) {
    contractType = prettifyString(rider.courier.contract_type)
  }

  const transformedRider = {
    id: rider?.courier?.id || '',
    status,
    contractType,
    city: rider?.courier?.courier_starting_point || '',
    vehicle: rider?.courier?.vehicle_type || '',
    phone: rider?.courier?.phone_number || '',
    currentShift,
    name: rider?.courier?.name || '',
    currentOrder: rider?.current_order,
  }

  return transformedRider
}

export default transformRider
