/**
 * tags container for order summary
 * runs config checks and renders available tags
 * */

// libs
import React from 'react'

//styles
import { createUseStyles } from 'react-jss'

// Relative Path
import styles from './Tags.styles'
import CountryFlag from './CountryFlag'
import SingleTag from 'components/Tags/SingleTag'
import { useCanDisplayDataPoint, useIsDataPointValid } from 'hooks/useGetValidFeatures'
import { DataPointWithBetaRequirementConfig, OrderWidgetSummary } from 'contexts/entity/types'
import { orderSummaryDataPoints } from '../../../../entityConfig/dataPoints/order'

const useStyles = createUseStyles(styles)

interface Props {
  tags: Array<any>
  summary: OrderWidgetSummary
}

const Tags: React.FC<Props> = ({ tags, summary }) => {
  const classes = useStyles()

  const isDataPointValid = useIsDataPointValid()

  const isNewDesignAllowed = isDataPointValid(summary.newDesign?.betaRequirement)

  const canDisplayDataPoint = useCanDisplayDataPoint()
  // expects a config array to run entity checks, tag to return, and index to pass to the return DOM el
  const checkTagAvailability = (
    config: DataPointWithBetaRequirementConfig,
    tag: { value: string | boolean; key: string },
    index: number,
  ) => {
    // check if entity config includes never_display or display_when_data_found (and if value exists)
    const canDisplay = canDisplayDataPoint(tag.value, config)
    if (!canDisplay) {
      // return nothing in either case
      return null
    } else {
      if (!tag.value) {
        return null
      }
      // otherwise return tag element with the passed tag key
      return <SingleTag value={String(tag.value)} key={index} />
    }
  }

  return (
    <div className={classes.tagsContainer}>
      {/* map through tags and run entity checks on each */}
      {tags &&
        tags.map((tag: any, index: number) => {
          switch (tag.key) {
            case orderSummaryDataPoints.tags.joker:
              return checkTagAvailability(summary.discount_joker, tag, index)
            case orderSummaryDataPoints.tags.corporate:
              return checkTagAvailability(summary.corporate, tag, index)
            case orderSummaryDataPoints.tags.preOrder:
              return checkTagAvailability(summary.pre_order, tag, index)
            case orderSummaryDataPoints.tags.application:
              return checkTagAvailability(summary.application, tag, index)
            case orderSummaryDataPoints.tags.deliveryProvider:
              return checkTagAvailability(summary.delivery_provider, tag, index)
            case orderSummaryDataPoints.tags.platform:
              return checkTagAvailability(summary.platform, tag, index)
            case orderSummaryDataPoints.tags.splitDelivery:
              return checkTagAvailability(summary.split_delivery, tag, index)
            default:
              return checkTagAvailability({ displayRule: [], betaRequirement: [] }, tag, index)
          }
        })}
      {isNewDesignAllowed && <CountryFlag />}
    </div>
  )
}

export default Tags
