import { SessionContext } from 'contexts/session/SessionContext'
import { useApiService } from 'hooks/useApiService'
import { useContext } from 'react'
import { getVendorContacts } from 'services/vendorApi/getVendorContacts'

export function useLoadVendorContacts() {
  const {
    sessionState: { globalVendorId, globalEntityId },
  } = useContext(SessionContext)

  return useApiService({
    service: getVendorContacts,
    params: {
      globalEntityId,
      globalVendorId,
    },
    shouldLoad: Boolean(globalVendorId && globalEntityId),
  })
}
