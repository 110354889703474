import { ApiErrorConfig } from './voucherApiErrorConfig'

export const customerApiErrorConfig: ApiErrorConfig<'CustomerApi'> = {
  CustomerApi: {
    getRiderChats: {
      400: {
        '*': {
          screen: 'error',
          heading: 'Chat transcript unavailable',
          copyText: 'Error HTTP 400 has occured',
        },
      },
      401: {
        '*': {
          screen: 'warning',
          heading: 'Chat transcript unavailable',
          copyText: 'Error HTTP 401 has occured',
        },
      },
      404: {
        '*': {
          screen: 'no-data',
          heading: 'No chat record found',
          copyText: '',
        },
      },
    },
    getCustomerFraudStatus: {
      400: {
        '*': {
          screen: 'error',
          heading: 'Fraud status unavailable due to error{{dot}}',
          copyText: 'GetCustomerFraudStatus HTTP 400',
        },
      },
      401: {
        '*': {
          screen: 'warning',
          heading: 'Error in getting results{{dot}} Refresh to try again',
          copyText: 'GetCustomerFraudStatus HTTP 401',
        },
      },
      403: {
        '*': {
          screen: 'instruct',
          heading: 'Permission required to get fraud status',
          copyText: 'Ask a supervisor to check Oneview permissions',
        },
      },
      500: {
        '*': {
          screen: 'error',
          heading: 'Error in getting results{{dot}} Refresh to try again',
          copyText: "Couldn't get fraud status due to error HTTP 500",
        },
      },
      503: {
        '*': {
          screen: 'error',
          heading: 'Error in getting results{{dot}} Refresh to try again',
          copyText: "Couldn't get fraud status due to error HTTP 503",
        },
      },
    },
    getCustomerProfile: {
      401: {
        '*': {
          screen: 'warning',
          heading: 'Customer profile unavailable',
          copyText: 'An error has occured{{dot}} Select refresh to try again',
        },
      },
      403: {
        '*': {
          screen: 'instruct',
          heading: 'Permission required to get customer profile',
          copyText: 'Ask a supervisor to check Oneview permissions',
        },
      },
      404: {
        '*': {
          screen: 'no-data',
          heading: 'No customer profile found',
          copyText: '',
        },
      },
    },
  },
}
