/**
 * expects an array of features and all ccr levels
 * check if action should be disabled if given ccr is not in ccrWhitelist
 * of the action config
 * */

import { FeatureWithBetaRequirementConfig } from 'contexts/entity/types'
import { DisabledFeature } from 'types/actions/feature'

function getDisabledActionsDueToCcrWhitelist(code: string) {
  return (features: FeatureWithBetaRequirementConfig[]): DisabledFeature[] => {
    return features.reduce((list, { feature, ccrWhitelist }) => {
      const hasCcrWhitelist = ccrWhitelist && ccrWhitelist.length
      if (!hasCcrWhitelist) {
        return list
      }

      if (!code) {
        list.push(
          new DisabledFeature({
            name: feature,
            message: 'Messages.Action is only allowed with specific CCRs',
            options: { ccr: code },
          }),
        )
      } else if (code && !ccrWhitelist.includes(code)) {
        list.push(
          new DisabledFeature({
            name: feature,
            message: 'Messages.Action is not allowed for given CCR',
            options: { ccr: code },
          }),
        )
      }

      return list
    }, [])
  }
}

export default getDisabledActionsDueToCcrWhitelist
