/**
 * HistoryOfEvents Order Tab
 * pulls from API and displays order-related events with filtering options
 * */

// libs
import React, { useState, useEffect, useContext } from 'react'
// contexts and types
import { SessionContext } from 'contexts/session/SessionContext'
import { DataContext } from 'contexts/data/DataContext'
import {
  SingleComment,
  SingleCommentAction,
  ModifyCallBackStatuses,
} from 'types/api/commentApi/getOrderComments'
// hooks
import { useTranslation } from 'hooks/useTranslation'
import { useEntityUtcZone } from 'hooks/useEntityUtcZone'
// styles
import { Empty } from 'antd'
import { createUseStyles } from 'react-jss'
// utils
// Relative Path
import styles from './OrderHistory.styles'
import { useApiService } from 'hooks/useApiService'
import { getOrderComments } from 'services/commentApi/getOrderComments'
import { WidgetErrorHandler } from 'components/WidgetErrorHandler/widgetErrorHandler'
import { CancelOrderActionConfig } from 'contexts/entity/types'
// Comment components
import {
  CallbackComment,
  CancelOrderComment,
  CompensationComment,
  CookingInstructionComment,
  CreateDeliveryComment,
  DeliveryAddressComment,
  DeliveryCoordinatesComment,
  DeliveryInstructionsComment,
  DeliveryTimeComment,
  DispatcherComment,
  ManualComment,
  MissingItemsComment,
  PaymentCompensationComment,
  PaymentRefundComment,
  ReassignDeliveryComment,
  RefundRequestComment,
  RemoveItemsComment,
  TicketRefundComment,
  UpdateOrderFlagComment,
  VoucherRefundComment,
  ChangeDeliveryStatusComment,
  SetPrimaryDeliveryComment,
  RestoreOrderItemsComment,
} from '../../../components/Comments'
import { AvailableFilterTimeFrames } from 'types/widgets/historyOfEvents/availableCommentActionsTypes'
import { useFilteredComments } from 'hooks/useFilteredComments'
import { WidgetSize } from 'types/widgets/historyOfEvents/tabs'
import { useGetApiVersionConfig } from 'hooks/useApiVersionConfig'
import { SelfServiceComment } from 'components/Comments/SelfServiceComment'

const useStyles = createUseStyles(styles)

export const SIMPLE_ITEM_DETAILS_STRING_LIMIT = 20

interface Props {
  intervalState: number
  cancelOrder: CancelOrderActionConfig
  timeFilter: AvailableFilterTimeFrames
  mode: WidgetSize
}

const OrderHistory: React.FC<Props> = (props) => {
  const { intervalState, cancelOrder: cancel_order, timeFilter, mode } = props

  const getApiVersionConfig = useGetApiVersionConfig()
  const apiVersion = getApiVersionConfig('getOrderComments')

  const classes = useStyles()
  // pull language content
  const { t } = useTranslation()
  // const captureUserAction = useCaptureUserAction()

  const utc_zone = useEntityUtcZone()

  // pull orderId & globalentity ID from session context
  const {
    sessionState: { orderId, globalEntityId },
  } = useContext(SessionContext)

  // pull dataState
  const { dataState } = useContext(DataContext)

  const {
    loading: isLoading,
    error,
    loadService: loadOrderComments,
    data,
  } = useApiService({
    service: getOrderComments,
    params: {
      entityId: globalEntityId,
      orderId,
      apiVersion,
    },
    deps: [
      intervalState,
      dataState.newComment,
      dataState.autoChangeDeliveryTimeComment,
      dataState.autoMissingItemReportComment,
      dataState.autoCancelOrderComment,
      dataState.autoCompensationComment,
      dataState.autoPartialRefundComment,
      dataState.autoFullRefundComment,
      dataState.autoChangeAddressComment,
      dataState.autoChangeInstructionsComment,
      dataState.autoModifyCallbackComment,
      dataState.autoChangeCookingInstComment,
      dataState.autoRemoveItemsComment,
    ],
  })

  // unfiltered data result count
  const [totalUnfilteredItems, setTotalUnfilteredItems] = useState(0)

  useEffect(() => {
    setTotalUnfilteredItems(data?.comments?.length)
  }, [data])

  // make sure only one comment highlighted at a time
  let highlightedCommentCount = 0
  let highlightedDeliveryInstCommentCount = 0
  let highlightedDeliveryAddressCommentCount = 0
  let highlightedItemRemovalCommentCount = 0

  const commentsDisplayed = useFilteredComments(data?.comments, timeFilter)

  return (
    <div className={classes.orderHistoryContainer}>
      <WidgetErrorHandler
        loading={isLoading}
        errorPayload={error?.errorPayload}
        loadingText={t('Interface.Loading Order Events')}
        onRetry={loadOrderComments}
      >
        {() => {
          if (totalUnfilteredItems === 0) {
            return (
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={t(
                  'History of Events Widget.There is no comment associated with the current order',
                )}
              />
            )
          }
          return (
            <React.Fragment>
              {/* COMMENTS */}
              <div className={classes.commentsHolder}>
                {commentsDisplayed && commentsDisplayed.length > 0 ? (
                  commentsDisplayed.map((comment: SingleComment, idx: number) => {
                    // find out if comment should be highlighted
                    let shouldCommentBeHighlighted = false

                    switch (comment.action) {
                      case SingleCommentAction.modify_promised_delivery_time:
                        if (
                          !highlightedCommentCount &&
                          dataState?.autoChangeDeliveryTimeComment &&
                          comment?.order_id &&
                          dataState.autoChangeDeliveryTimeComment === comment.order_id
                        ) {
                          highlightedCommentCount = highlightedCommentCount + 1
                          shouldCommentBeHighlighted = true
                        }

                        return (
                          <DeliveryTimeComment
                            mode={mode}
                            key={idx}
                            comment={comment}
                            utc_zone={utc_zone}
                            shouldCommentBeHighlighted={shouldCommentBeHighlighted}
                          />
                        )

                      case SingleCommentAction.cancel_order:
                        if (
                          dataState?.autoCancelOrderComment &&
                          comment?.order_id &&
                          dataState.autoCancelOrderComment === comment.order_id
                        ) {
                          shouldCommentBeHighlighted = true
                        }
                        // set initial cancellation reason to what API returns
                        return (
                          <CancelOrderComment
                            mode={mode}
                            key={idx}
                            comment={comment}
                            cancel_order={cancel_order}
                            shouldCommentBeHighlighted={shouldCommentBeHighlighted}
                          />
                        )

                      case SingleCommentAction.modify_cooking_instructions:
                        if (
                          !highlightedCommentCount &&
                          dataState?.autoChangeCookingInstComment &&
                          comment?.order_id &&
                          dataState.autoChangeCookingInstComment === comment.order_id
                        ) {
                          highlightedCommentCount = highlightedCommentCount + 1
                          shouldCommentBeHighlighted = true
                        }

                        return (
                          <CookingInstructionComment
                            mode={mode}
                            key={idx}
                            comment={comment}
                            shouldCommentBeHighlighted={shouldCommentBeHighlighted}
                          />
                        )
                      case SingleCommentAction.restore_order_items:
                        return (
                          <RestoreOrderItemsComment
                            mode={mode}
                            key={idx}
                            comment={comment}
                            shouldCommentBeHighlighted={shouldCommentBeHighlighted}
                          />
                        )

                      case SingleCommentAction.compensation:
                        if (
                          (dataState?.autoCompensationComment &&
                            comment?.additional_data?.compensation?.voucher_code &&
                            dataState.autoCompensationComment.code ===
                              comment.additional_data.compensation.voucher_code) ||
                          (!highlightedCommentCount && dataState?.autoCompensationComment) ||
                          dataState?.compensatedVoucher?.code ===
                            comment?.additional_data?.compensation?.voucher_code ||
                          (!highlightedCommentCount && dataState?.autoCompensationComment)
                        ) {
                          highlightedCommentCount = highlightedCommentCount + 1
                          shouldCommentBeHighlighted = true
                        }

                        return (
                          <CompensationComment
                            mode={mode}
                            key={idx}
                            comment={comment}
                            shouldCommentBeHighlighted={shouldCommentBeHighlighted}
                          />
                        )

                      case SingleCommentAction.manual_comment:
                        if (
                          dataState?.newComment?.comment &&
                          dataState.newComment.comment.id === comment.id
                        ) {
                          shouldCommentBeHighlighted = true
                        }

                        return (
                          <ManualComment
                            mode={mode}
                            key={idx}
                            comment={comment}
                            shouldCommentBeHighlighted={shouldCommentBeHighlighted}
                          />
                        )

                      case SingleCommentAction.modify_callback:
                        if (
                          comment?.additional_data?.modify_callback?.status ===
                            ModifyCallBackStatuses.CONFIRMED ||
                          comment?.additional_data?.modify_callback?.status ===
                            ModifyCallBackStatuses.MODIFIED ||
                          comment?.additional_data?.modify_callback?.status ===
                            ModifyCallBackStatuses.REQUESTED
                        ) {
                          return null
                        } else {
                          if (
                            dataState?.autoModifyCallbackComment &&
                            orderId &&
                            dataState.autoModifyCallbackComment === orderId
                          ) {
                            shouldCommentBeHighlighted = true
                          }

                          return (
                            <CallbackComment
                              mode={mode}
                              key={idx}
                              comment={comment}
                              shouldCommentBeHighlighted={shouldCommentBeHighlighted}
                            />
                          )
                        }

                      case SingleCommentAction.modify_delivery_address:
                        if (
                          !highlightedDeliveryAddressCommentCount &&
                          dataState?.autoChangeAddressComment &&
                          orderId &&
                          dataState.autoChangeAddressComment === orderId &&
                          dataState?.modifiedDeliverySettings?.formatted_address
                        ) {
                          shouldCommentBeHighlighted = true
                          highlightedDeliveryAddressCommentCount++
                        }

                        return (
                          <DeliveryAddressComment
                            mode={mode}
                            key={idx}
                            comment={comment}
                            shouldCommentBeHighlighted={shouldCommentBeHighlighted}
                          />
                        )
                      case SingleCommentAction.modify_delivery_coordinates:
                        return (
                          <DeliveryCoordinatesComment
                            mode={mode}
                            key={idx}
                            comment={comment}
                            shouldCommentBeHighlighted={shouldCommentBeHighlighted}
                          />
                        )

                      case SingleCommentAction.modify_delivery_instructions:
                        if (
                          !highlightedDeliveryInstCommentCount &&
                          dataState?.autoChangeInstructionsComment &&
                          orderId &&
                          dataState.autoChangeInstructionsComment === orderId &&
                          dataState?.modifiedDeliverySettings?.instructions
                        ) {
                          shouldCommentBeHighlighted = true
                          highlightedDeliveryInstCommentCount++
                        }

                        return (
                          <DeliveryInstructionsComment
                            mode={mode}
                            key={idx}
                            comment={comment}
                            shouldCommentBeHighlighted={shouldCommentBeHighlighted}
                          />
                        )

                      case SingleCommentAction.report_missing_items:
                        if (
                          dataState?.autoMissingItemReportComment &&
                          comment?.additional_data?.report_missing_items?.sf_case_id &&
                          dataState.autoMissingItemReportComment ===
                            comment.additional_data.report_missing_items.sf_case_id
                        ) {
                          shouldCommentBeHighlighted = true
                        }

                        return (
                          <MissingItemsComment
                            mode={mode}
                            key={idx}
                            comment={comment}
                            shouldCommentBeHighlighted={shouldCommentBeHighlighted}
                          />
                        )

                      case SingleCommentAction.voucher_refund:
                        if (
                          (dataState?.autoPartialRefundComment?.code &&
                            comment?.additional_data?.voucher_refund?.voucher_code &&
                            dataState.autoPartialRefundComment.code ===
                              comment.additional_data.voucher_refund.voucher_code) ||
                          (dataState?.autoFullRefundComment?.code &&
                            comment?.additional_data?.voucher_refund?.voucher_code &&
                            dataState.autoFullRefundComment.code ===
                              comment.additional_data.voucher_refund.voucher_code) ||
                          dataState.fullyRefundedVoucher?.code ===
                            comment?.additional_data?.voucher_refund?.voucher_code
                        ) {
                          shouldCommentBeHighlighted = true
                        }

                        return (
                          <VoucherRefundComment
                            mode={mode}
                            key={idx}
                            comment={comment}
                            shouldCommentBeHighlighted={shouldCommentBeHighlighted}
                          />
                        )

                      case SingleCommentAction.ticket_refund:
                        return (
                          <TicketRefundComment
                            mode={mode}
                            key={idx}
                            utc_zone={utc_zone}
                            comment={comment}
                            shouldCommentBeHighlighted={false}
                          />
                        )

                      case SingleCommentAction.refund_request:
                        shouldCommentBeHighlighted = false

                        return (
                          <RefundRequestComment
                            mode={mode}
                            key={idx}
                            comment={comment}
                            shouldCommentBeHighlighted={shouldCommentBeHighlighted}
                          />
                        )

                      case SingleCommentAction.payment_refund:
                        if (
                          highlightedCommentCount < 1 &&
                          dataState?.autoPartialRefundComment &&
                          dataState.autoPartialRefundComment.amount?.amount.toString() &&
                          dataState.autoPartialRefundComment.amount?.currency
                        ) {
                          shouldCommentBeHighlighted = true
                          highlightedCommentCount++
                        }

                        return (
                          <PaymentRefundComment
                            mode={mode}
                            key={idx}
                            comment={comment}
                            shouldCommentBeHighlighted={shouldCommentBeHighlighted}
                          />
                        )

                      case SingleCommentAction.payment_compensation:
                        if (!highlightedCommentCount && dataState?.refundToWalletOrPspDetails) {
                          highlightedCommentCount = highlightedCommentCount + 1
                          shouldCommentBeHighlighted = true
                        }

                        return (
                          <PaymentCompensationComment
                            mode={mode}
                            key={idx}
                            comment={comment}
                            shouldCommentBeHighlighted={shouldCommentBeHighlighted}
                          />
                        )

                      case SingleCommentAction.remove_items:
                        if (
                          highlightedItemRemovalCommentCount < 1 &&
                          dataState?.autoRemoveItemsComment
                        ) {
                          shouldCommentBeHighlighted = true
                          highlightedItemRemovalCommentCount++
                        }

                        return (
                          <RemoveItemsComment
                            mode={mode}
                            key={idx}
                            comment={comment}
                            shouldCommentBeHighlighted={shouldCommentBeHighlighted}
                          />
                        )

                      case SingleCommentAction.dispatcher_note:
                        return <DispatcherComment mode={mode} key={idx} comment={comment} />

                      case SingleCommentAction.reassign_delivery:
                        return (
                          <ReassignDeliveryComment
                            mode={mode}
                            key={idx}
                            comment={comment}
                            showRiderId={true}
                          />
                        )

                      case SingleCommentAction.create_delivery:
                        return <CreateDeliveryComment mode={mode} key={idx} comment={comment} />

                      case SingleCommentAction.update_order_flag:
                        return <UpdateOrderFlagComment mode={mode} key={idx} comment={comment} />

                      case SingleCommentAction.delivery_status_update:
                        return (
                          <ChangeDeliveryStatusComment mode={mode} key={idx} comment={comment} />
                        )
                      case SingleCommentAction.set_primary_delivery:
                        return <SetPrimaryDeliveryComment mode={mode} key={idx} comment={comment} />

                      case SingleCommentAction.self_service_refund:
                      case SingleCommentAction.self_service_compensation:
                        return <SelfServiceComment mode={mode} key={idx} comment={comment} />

                      default:
                        return null
                    }
                  })
                ) : (
                  <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description={t(
                      'History of Events Widget.No comment found in the selected timeframe',
                    )}
                  />
                )}
              </div>
            </React.Fragment>
          )
        }}
      </WidgetErrorHandler>
    </div>
  )
}

export default OrderHistory
