export default {
  modalCustomerService: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    minWidth: 280,
  },
  modalRiderService: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    width: 280,
    minWidth: 280,
  },
  titleHolder: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#1295e6',
    padding: 12,
    marginTop: -16,
    marginBottom: -16,
    marginLeft: -24,
    marginRight: -24,
  },
  modalTitle: {
    fontSize: '16px',
    color: '#fff',
    marginTop: '7px',
  },
  contentContainer: {},
  inputAndMapHolder: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  inputHolder: {
    width: '57%',
    minWidth: 260,
    margin: '3px 20px 0px 0px',
    paddingRight: '30px',
  },
  mapHolder: {
    flex: '1',
  },

  resultTitleHolder: {
    display: 'flex',
    flexDirection: 'column',
  },
  resultVoucherSection: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  successText: {
    color: 'rgba(0, 0, 0, 0.85)',
    fontSize: '27px',
  },
  resultSubtext: {
    color: 'rgba(0, 0, 0, 0.45)',
    fontSize: '14px',
  },
  resultAddressContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '100%',
    marginTop: '20px',
  },
  resultSubdata: {
    color: 'rgba(0, 0, 0, 0.85)',
    fontSize: '14px',
    marginLeft: '3px',
  },
  errorSubtextHolder: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '20px',
  },
  errorSubtext: {
    fontSize: '14px',
    color: 'rgba(0, 0, 0, 0.45)',
  },
  errorDeliveryAddressContainer: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    marginTop: '20px',
  },
  errorDeliveryAddressTitle: {
    fontSize: '14px',
    color: 'rgba(0, 0, 0, 0.45)',
    marginRight: '3px',
  },
  errorDeliveryAddressData: {
    fontSize: '14px',
    color: 'rgba(0, 0, 0, 0.85)',
  },
}
