import React from 'react'
import { createUseStyles } from 'react-jss'
import { Card as AntCard } from 'antd'
import classnames from 'classnames'
import type { CardProps as AntCardProps } from 'antd'

import styles from './index.styles'

const useStyles = createUseStyles(styles)

export interface CardProps extends AntCardProps {
  bgColor?: string

  // Uses the primary color as the border color
  // @defaultValue false
  highlighted?: boolean
}

export const Card: React.FC<CardProps> = ({ className, ...props }) => {
  const classes = useStyles(props)
  const { highlighted, bgColor, ...antCardProps } = props

  return <AntCard className={classnames(classes.card, className)} {...antCardProps} />
}
