import React, { CSSProperties, FC, MouseEventHandler, ReactNode } from 'react'

export interface CustomIconProps {
  size?: number
  color?: string
  className?: string
  style?: CSSProperties
  onClick?: MouseEventHandler
}

export interface CustomIcon extends FC<CustomIconProps> {
  isCustomIcon?: boolean
}

export const createCustomSvgIcon = ({
  viewBoxWidth,
  viewBoxHeight,
  content,
}: {
  /**
   * icon view box width
   */
  viewBoxWidth: number

  /**
   * icon view box height
   */
  viewBoxHeight: number

  /**
   * svg content
   */
  content: ReactNode
}): CustomIcon => {
  const Icon: CustomIcon = ({ size = 16, style, className, color }) => {
    return (
      <svg
        viewBox={`0 0 ${viewBoxWidth} ${viewBoxHeight}`}
        fill={color || 'currentColor'}
        width={size}
        height={'100%'}
        className={className}
        style={style}
      >
        {content || null}
      </svg>
    )
  }
  Icon.isCustomIcon = true
  return Icon
}

export const PlaceholderSvgIcon = createCustomSvgIcon({
  viewBoxHeight: 24,
  viewBoxWidth: 24,
  content: null,
})
