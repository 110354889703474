import { ApiService } from 'services/authApi/getPermissions'
import { GetOrderCommentsResponse } from 'types/api/commentApi/getOrderComments'
import { OrderCommentsApiParams } from './types'
import { buildCommentApiUrl } from './helper'

export const getOrderComments: ApiService<OrderCommentsApiParams, GetOrderCommentsResponse> = (
  createClient,
  options,
) => {
  const { orderId, clientParams, ...apiParams } = options

  const uri = buildCommentApiUrl(apiParams, 'order', orderId)

  return createClient({
    endpointName: 'getOrderComments',
    expectedResponseStatusCode: 200,
    ...clientParams,
  }).get(uri)
}
