import { useCallback } from 'react'
import { FulfillmentApiRiderResponse } from 'types/herocare'
import { useGetDeliveryStatusMetadata } from './useGetDeliveryStatusMetadata'

/**
 * get more metadata relating to rider current status
 */
export const useGetRiderStatusMetadata = () => {
  const getDeliveryStatusMetadata = useGetDeliveryStatusMetadata()

  return useCallback(
    ({ rider }: { rider: FulfillmentApiRiderResponse }) => {
      const courierId = rider?.courier?.id
      const delivery = rider?.current_order?.deliveries?.find(
        (delivery) => delivery.courier?.id === courierId,
      )
      return getDeliveryStatusMetadata({ delivery })
    },
    [getDeliveryStatusMetadata],
  )
}
