import type { AxiosRequestConfig, AxiosResponse } from 'axios'
import type { ApiClientCreator } from 'contexts/apiClientCreator/ApiClientCreatorContext'
import type { useGetOrderStatusMetadata } from 'hooks/dataTransformers/useGetOrderStatusMetadata'
import type { useGetOrderStatusTextAndColor } from 'hooks/getters/useGetOrderStatusTextAndColor'
import type { CheckDisplayRulesOpts, useCheckDisplayRules } from 'hooks/useCheckDisplayRules'
import type { getOrderStage } from 'utils/order/getOrderStage'
import type { useGetRiderStatusTextAndColor } from 'hooks/useGetRiderStatusTextAndColor'

import type { getCustomerName } from 'utils/getCustomerName'
import type { getOrderEligibilities } from 'utils/order/getOrderEligibilities'
import type { getPaymentTextAndIcon, PaymentType } from 'utils/getters/getPaymentTextAndIcon'

import type { replaceUrl } from 'utils/getSearchParams'
import type { interpolate } from 'utils/string/interpolate'
import type { getPodPinStatus } from 'utils/order/getPodPinStatus'
import type { WidgetViewManagerContextValue } from 'contexts/widgetViewManager'
import type { useNumberFormatter } from 'hooks/formatters/useNumberFormatter'
import type { useDateTimeFormatter } from 'hooks/formatters/useDateTimeFormatter'
import type { useGetOrderPaymentMethodsTextsAndIcons } from 'hooks/useGetOrderPaymentMethodsTextsAndIcons'

import type { useGetDeliveryStatusMetadata } from 'hooks/getters/useGetDeliveryStatusMetadata'
import type { useGetRiderStatusMetadata } from 'hooks/getters/useGetRiderStatusMetadata'
import type { VendorApiResponse } from '../api/vendorApi/vendor'
import type { TicketDetailsApiResponse } from '../api/ticketApi/getTicketDetails'
import type { DisplayRules } from './displayRules'

import type { FulfillmentApiResponse } from '../api/fulfillmentApi/fulfillment'
import type { CustomerApiResponse, OrderApiResponse, OrderHistoryItem } from '../api/orderApi/order'
import type { FulfillmentApiRiderResponse } from '../api/riderApi/rider'

import type { OrderStatuses } from '../widgets/order/orderStatuses'
import type { RiderStatuses } from '../widgets/rider/riderStatuses'
import type { WidgetViewState } from './widgetView'
import type { useCaptureUserAction } from 'hooks/events/useCaptureUserAction'
import type { useSendEventToEvts } from 'hooks/events/useSendEventToEvts'
import type { useTranslation } from 'hooks/useTranslation'
import type { PhoneDecorator, TabName } from './enums'
import type { EventEmitterNamespace } from 'utils/eventEmitter'
import type { getOrderDelayTime } from 'utils/getters/getOrderDelayTime'
import type { useGetApiVersionConfig } from 'hooks/useApiVersionConfig'

export enum SDK_EVENTS {
  SET_ACTIVE_TAB,
  SET_VIEW_STATE,
  SET_TICKET,
  SET_ORDER,
  SET_VENDOR,
  SET_RIDER,
}

export type GenericSdkEvent<Type extends SDK_EVENTS, Payload> = {
  type: Type
  payload: Payload
}

export type SDKEventDispatch =
  | GenericSdkEvent<SDK_EVENTS.SET_ACTIVE_TAB, TabName>
  | GenericSdkEvent<SDK_EVENTS.SET_VIEW_STATE, Partial<WidgetViewState>>
  | GenericSdkEvent<SDK_EVENTS.SET_TICKET, TicketDetailsApiResponse>
  | GenericSdkEvent<SDK_EVENTS.SET_ORDER, OrderApiResponse>
  | GenericSdkEvent<SDK_EVENTS.SET_RIDER, FulfillmentApiRiderResponse>
  | GenericSdkEvent<SDK_EVENTS.SET_VENDOR, VendorApiResponse>

export interface SDK {
  widgetId: string

  caseId: string

  hideMapByDefault: boolean

  phoneDecorator: PhoneDecorator

  eventEmitter: EventEmitterNamespace
  t: ReturnType<typeof useTranslation>['t']

  getApiVersionConfig: ReturnType<typeof useGetApiVersionConfig>

  captureUserAction: ReturnType<typeof useCaptureUserAction>

  sendEtsEvent: ReturnType<typeof useSendEventToEvts>

  activateWidgetView: WidgetViewManagerContextValue['activateWidgetView']

  numberFormatter: ReturnType<typeof useNumberFormatter>

  datetimeFormatter: ReturnType<typeof useDateTimeFormatter>

  /**
   * use to check display rules,
   *
   * note: the signature is the same like _checkDisplayRules,
   * but it is necessary to override it, so that it is decoupled
   */
  checkDisplayRules: (opts: {
    displayRules: DisplayRules
    opts?: CheckDisplayRulesOpts
  }) => ReturnType<ReturnType<typeof useCheckDisplayRules>>

  /**
   * get order payment methods texts and icons
   */
  getOrderPaymentMethodsTextsAndIcons: ReturnType<typeof useGetOrderPaymentMethodsTextsAndIcons>

  /**
   * get more metadata relating to delivery current status
   */
  getDeliveryStatusMetadata: ReturnType<typeof useGetDeliveryStatusMetadata>

  /**
   * get more metadata relating to rider current status
   */
  getRiderStatusMetadata: ReturnType<typeof useGetRiderStatusMetadata>

  /**
   * get order status text and color
   */
  getOrderStatusTextAndColor: (opts: {
    orderStatus: OrderStatuses
  }) => ReturnType<ReturnType<typeof useGetOrderStatusTextAndColor>>

  /**
   * returns more metadata regarding the current status of an order
   */
  getOrderStatusMetadata: (opts: {
    order: OrderApiResponse
  }) => ReturnType<ReturnType<typeof useGetOrderStatusMetadata>>

  getPodPinStatus: (opts: { order: OrderApiResponse }) => ReturnType<typeof getPodPinStatus>

  /**
   * returns order stage
   */
  getOrderStage: (opts: {
    orderStatusHistory: OrderHistoryItem[]
  }) => ReturnType<typeof getOrderStage>

  /**
   * returns order eligibilities
   */
  getOrderEligibilities: (opts: {
    order: OrderApiResponse
  }) => ReturnType<typeof getOrderEligibilities>

  getOrderDelayTime: (props: {
    order: OrderApiResponse
    fulfillment?: FulfillmentApiResponse
    datetimeFormatter: SDK['datetimeFormatter']
  }) => ReturnType<typeof getOrderDelayTime>
  /**
   * returns array of order tags
   */
  getOrderTags: (opts: {
    order: OrderApiResponse
    fulfillment?: FulfillmentApiResponse
  }) => string[]

  /**
   * returns order total paid value
   * @param opts
   */
  getOrderPaidValue(opts: { order: OrderApiResponse }): number

  /**
   * get rider status text and color
   */
  getRiderStatusTextAndColor: (opts: {
    riderStatus: RiderStatuses
  }) => ReturnType<ReturnType<typeof useGetRiderStatusTextAndColor>>

  /**
   * get payment type text label and icon
   */
  getPaymentTextAndIcon: (opts: {
    paymentType: PaymentType
  }) => ReturnType<typeof getPaymentTextAndIcon>

  /**
   * method to create client for making api requests to internal apis
   */
  createApiClient: ApiClientCreator

  /**
   * returns customer name
   */
  getCustomerName: (opts: {
    customer: CustomerApiResponse
    fallbackValue?: string
  }) => ReturnType<typeof getCustomerName>

  /**
   * reloads the page
   */
  reloadPage: () => void

  /**
   * dispatch state
   */
  dispatch: (event: SDKEventDispatch) => void

  /**
   * method for making proxy api request to external apis
   */
  callProxyApi: <ResponseDataType>(opts: {
    config: Omit<AxiosRequestConfig, 'url'>
    entityId: string
    remotePath: string
    operationId: string
  }) => Promise<AxiosResponse<ResponseDataType>>

  /**
   * navigates to new page
   */
  navigateToPage: (options: Parameters<typeof replaceUrl>[0]) => void

  /**
   * interpolates a string
   */
  interpolateString: (
    str: string,
    replacerValues: Parameters<typeof interpolate>[1],
    options: Parameters<typeof interpolate>[2],
  ) => string

  /**
   * log and track errors to datadog
   * @param name - error name
   */
  logError: (
    name: string,
    opts: {
      error: Error
      [p: string]: any
    },
  ) => void
}
