import React from 'react'
import { createCustomSvgIcon } from 'factory/createCustomSvgIcon'

export const PedestrianIcon = createCustomSvgIcon({
  viewBoxWidth: 11,
  viewBoxHeight: 12,
  content: (
    <>
      <path
        d='M7.61103 2.45609C8.32207 2.45609 8.89848 1.90627 8.89848 1.22804C8.89848 0.549814 8.32207 0 7.61103 0C6.89998 0 6.32357 0.549814 6.32357 1.22804C6.32357 1.90627 6.89998 2.45609 7.61103 2.45609Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M1.71008 2.14207C1.32513 2.09699 0.97475 2.3581 0.927484 2.72529L0.505306 6.00498C0.45804 6.37216 0.731788 6.70637 1.11674 6.75146L3.67246 7.05078C4.0004 7.08919 4.30325 6.90538 4.41498 6.62309C4.63106 6.73046 4.87616 6.8103 5.14111 6.86027C5.12835 7.09041 5.18132 7.31115 5.28521 7.50463C5.19194 7.87343 5.07039 8.30448 4.93338 8.7524C4.67505 9.59696 4.38223 10.4352 4.16565 10.9347C3.99636 11.3252 4.18275 11.7751 4.58195 11.9396C4.98115 12.1041 5.442 11.9209 5.61128 11.5305C5.8611 10.9542 6.17317 10.0537 6.43777 9.18865C6.53599 8.86755 6.63057 8.54195 6.71457 8.23178C7.23842 9.11132 7.61341 10.2215 7.51482 11.2701C7.48017 11.6385 7.76524 11.964 8.15153 11.9971C8.53782 12.0301 8.87906 11.7582 8.9137 11.3897C9.05425 9.89499 8.48718 8.39457 7.75233 7.27877C7.77216 7.21294 7.78666 7.1447 7.79531 7.07445L7.97038 5.65294C8.37291 6.01936 8.86104 6.3747 9.46213 6.68649C9.80285 6.86322 10.2293 6.74303 10.4145 6.41803C10.5998 6.09303 10.4738 5.6863 10.1331 5.50956C9.14485 4.99696 8.53906 4.34205 8.10553 3.73254C8.05199 3.55172 7.95717 3.38579 7.8303 3.24588C7.43533 2.7435 6.54292 2.62498 5.58582 2.79097C5.3153 2.85548 5.07917 2.92388 4.87309 2.99604C4.82333 2.71069 4.58211 2.47844 4.26581 2.4414L1.71008 2.14207ZM4.56311 5.62818L4.77041 4.01775C4.92837 3.93088 5.17023 3.8299 5.54166 3.72707C5.52214 3.79227 5.50784 3.85982 5.49928 3.92935L5.24811 5.96879C5.02189 5.91806 4.83957 5.83939 4.70769 5.74984C4.65048 5.711 4.60253 5.67059 4.56311 5.62818Z'
        fill='currentColor'
      />
    </>
  ),
})
