import React, { useEffect, useImperativeHandle, useState } from 'react'
import { Tabs } from 'antd'
import { BuiltinWidgetConfigs, SDK_EVENTS } from 'types'
import { DeliveryItem } from 'types/api/fulfillmentApi/fulfillment'
import { useLoadOrderStatusHistory } from 'hooks/apiHooks/useLoadOrderStatusHistory'
import { useApiService } from 'hooks/useApiService'
import { useTranslation } from 'hooks/useTranslation'
import { getOrderFulfillment } from 'services/fulfillmentApi/getOrderFulfillment'
import { createPluggableWidget } from 'factory/createPluggableWidget'
import { useHistoryStyles } from './History.styles'
import OrderHistory from './OrderHistory/OrderHistory'
import RiderHistory from './RiderHistory/RiderHistory'
import { RiderTabName } from './RiderTabPane/RiderTabName'

export const Deliveries = createPluggableWidget<BuiltinWidgetConfigs['order_deliveries']>(
  ({ config, ErrorRenderer, sdk, order }, ref) => {
    const { t } = useTranslation()
    const classes = useHistoryStyles()
    const [deliveries, setDeliveries] = useState<DeliveryItem[]>([])
    const [activeTabKey, setActiveTabKey] = useState<string>('order_history_tab')

    const [isMapVisible, setIsMapVisible] = useState(!sdk.hideMapByDefault)

    const handleViewMap = () => {
      setIsMapVisible(true)
    }

    const {
      loading: isLoadingFulfillment,
      data: fulfillment,
      loadService: refreshFulfillment,
    } = useApiService({
      service: getOrderFulfillment,
      params: {
        entityId: order?.global_entity_id,
        orderId: order?.order_id,
      },
      shouldLoad: Boolean(order?.global_entity_id && order?.order_id),
      deps: [order?.global_entity_id, order?.order_id],

      onSuccess: ({ data }) => {
        const deliveries = (data?.deliveries ?? []).filter((delivery) => !!delivery.courier)
        if (deliveries.length) {
          const count = ` (${deliveries.length})`
          sdk.dispatch({
            type: SDK_EVENTS.SET_VIEW_STATE,
            payload: {
              title: t(`widget_labels.order_deliveries`) + count,
            },
          })
        }
        setDeliveries(deliveries)
      },
    })

    useEffect(() => {
      const listener = sdk.eventEmitter.addEventListener({
        name: 'CHANGE_DELIVERY_TIME_SUCCESS',
        callback: () => {
          refreshFulfillment()
        },
      })

      return () => {
        sdk.eventEmitter.removeEventListener(listener)
      }
    }, [refreshFulfillment, sdk.eventEmitter])

    const {
      error: errorLoadingOrderStatusHistory,
      isLoading: isLoadingOrderStatusHistory,
      loadService: refreshOrderStatusHistory,
    } = useLoadOrderStatusHistory()

    useEffect(() => {
      if (activeTabKey.startsWith('rider_history_tab_')) {
        const riderNumber = Number(activeTabKey.split('rider_history_tab_')[1])
        sdk.captureUserAction('OPEN_TAB_RIDER', {
          eventDetails: {
            rider_number: riderNumber,
          },
        })
      }
    }, [activeTabKey, sdk])

    useImperativeHandle(
      ref,
      () => {
        return {
          onRefresh: () => {
            sdk.captureUserAction('REFRESH_DELIVERIES')
            refreshFulfillment()
            refreshOrderStatusHistory()
          },
        }
      },
      [refreshFulfillment, refreshOrderStatusHistory, sdk],
    )

    const isLoading = isLoadingFulfillment || isLoadingOrderStatusHistory

    return (
      <ErrorRenderer
        errorPayload={errorLoadingOrderStatusHistory?.errorPayload}
        loading={isLoading}
      >
        {() => (
          <Tabs className={classes.tabs} activeKey={activeTabKey} onChange={setActiveTabKey}>
            <Tabs.TabPane tab={t(`Widgets Common.Order`)} key={'order_history_tab'}>
              <OrderHistory
                sdk={sdk}
                order={order}
                fulfillment={fulfillment}
                isMapVisible={isMapVisible}
                onViewMap={handleViewMap}
                config={config.order_history_tab}
              />
            </Tabs.TabPane>
            {deliveries.map((delivery, idx) => {
              const meta = sdk.getDeliveryStatusMetadata({ delivery })
              const tabKey = `rider_history_tab_${idx + 1}`
              const TabName = (
                <RiderTabName
                  isPrimary={Boolean(delivery?.primary_delivery)}
                  index={idx + 1}
                  isActive={activeTabKey === tabKey}
                  status={{ color: meta.statusColor, text: meta.statusText }}
                />
              )
              return (
                <Tabs.TabPane tab={TabName} key={tabKey}>
                  <RiderHistory
                    sdk={sdk}
                    order={order}
                    fulfillment={fulfillment}
                    delivery={delivery}
                    isMapVisible={isMapVisible}
                    onViewMap={handleViewMap}
                    config={config.riders_history_tab}
                  />
                </Tabs.TabPane>
              )
            })}
          </Tabs>
        )}
      </ErrorRenderer>
    )
  },

  {
    category: 'data_lookup',
    deriveConfig({ entityConfig }) {
      return entityConfig.layout_v2.builtin_widgets_configs.order_deliveries
    },
    deriveInitialViewState() {
      return {
        paddingTop: 0,
      }
    },
  },
)
