import { useAuthProvider } from 'contexts/auth/AuthProvider'
import { useCaptureUserAction } from 'hooks/events/useCaptureUserAction'
import { useCallback } from 'react'

export const useLogout = () => {
  const { authDispatch } = useAuthProvider()
  const captureUserAction = useCaptureUserAction()

  return useCallback(() => {
    captureUserAction('LogoutUser', {
      eventDetails: {
        message: 'RefreshJwtFailed',
      },
    })
    authDispatch({
      type: 'logout',
    })
  }, [authDispatch, captureUserAction])
}
