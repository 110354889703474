export default {
  labelText: {
    fontSize: '13px',
    color: 'rgba(0, 0, 0, 0.45)',
    marginRight: '3px',
  },
  dataText: {
    fontSize: '14px',
    color: 'rgba(0, 0, 0, 0.65)',
  },
  menuItem: {
    display: 'flex',
    height: 55,
    minHeight: 55,
  },
  menuItemCol: {
    display: 'flex',
    flexDirection: 'column',
  },
  icon: {
    color: '#1890FF',
    marginLeft: '7px',
    marginTop: -14,
  },
  statusText: {
    fontSize: '13px',
    color: 'rgba(0, 0, 0, 0.65)',
  },
  timestampText: {
    fontSize: '12px',
    color: 'rgba(0, 0, 0, 0.45)',
    marginTop: -20,
  },
}
