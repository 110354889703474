import { text, gray, primary } from 'theme'

export default {
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
  },
  headerContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    color: text.secondary,
    margin: '-24px -24px 0 -24px',
    padding: '12px 24px',
    borderBottom: `1px solid ${gray.gray4}`,
  },
  bodyContainer: {
    marginBlock: '16px',
    display: 'flex',
    flexDirection: 'column',
  },
  infoContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    color: text.secondary,
    margin: '8px',
    '& > p': {
      margin: '0',
    },
  },
  loadingContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '25vh',
  },
  headerBackIcon: {
    color: primary.primary7,
    border: 'none',
    padding: 0,
    boxShadow: 'none',
    height: 'auto',
  },
  bold: {
    fontWeight: '500',
    color: text.primary,
  },
  chatCards: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
  },
  orderBadge: {
    paddingInline: '8px',
    color: text.primary,
    backgroundColor: gray.gray2,
    border: `1px solid ${gray.gray5}`,
    borderRadius: '11px',
    marginTop: '8px',
  },
}
