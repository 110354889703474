// libs
import React from 'react'
// hooks
import { useTranslation } from 'hooks/useTranslation'
// styles
import { createUseStyles } from 'react-jss'
import styles from './Actions.styles'
import { Button } from 'antd'

const useStyles = createUseStyles(styles)

interface Props {
  handleNewAddressSaveClick: () => void
  newDeliveryInstructions: string
  currentDeliveryInstructions: string
  isNewAddressSelected: boolean
  isNewAddressDeliverable: boolean
  validateAddress: boolean
  ignoreVendorDeliveryAreaValidation: boolean
}

const Actions: React.FC<Props> = ({
  handleNewAddressSaveClick,
  newDeliveryInstructions,
  currentDeliveryInstructions,
  isNewAddressSelected,
  isNewAddressDeliverable,
  validateAddress,
  ignoreVendorDeliveryAreaValidation,
}) => {
  const classes = useStyles()

  // pull translations
  const { t } = useTranslation()

  const saveButtonIsDisabled = () => {
    if (isNewAddressSelected) {
      return (validateAddress && isNewAddressDeliverable) ||
        ignoreVendorDeliveryAreaValidation ||
        !validateAddress
        ? false
        : true
    }
    if (newDeliveryInstructions) {
      return newDeliveryInstructions === currentDeliveryInstructions
    }
    return true
  }

  return (
    <div className={classes.footer}>
      <Button disabled={saveButtonIsDisabled()} type='primary' onClick={handleNewAddressSaveClick}>
        {t('Interface.Save')}
      </Button>
    </div>
  )
}
export default Actions
