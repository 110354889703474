import {
  ALFRED_UNAUTHORIZED,
  ERROR_REFUND_COMPENSATION_PAYMENT_NOT_COMPLETED,
} from 'constants/errorResponseCodes'
import { availableRefundMethods } from 'entityConfig/allowedConfigValues'
import { TranslateFunction } from 'hooks/useTranslation'
import { FulFilledApiResponse } from 'services/authApi/getPermissions'
import { OrderPurchaseDetails } from 'services/paymentApi/getOrderPurchaseDetails'
import { AvailableRefundMethod } from 'types/actions/partialRefund'

export const OrderPurchaseTargetMap = {
  fullRefund: 'full_refund_targets',
  partialRefund: 'refund_targets',
  compensation: 'compensation_targets',
}

export const handleOrderPurchaseError = ({
  t,
  refundMethod,
  setRefundMethodValidationError,
}: {
  t: TranslateFunction
  refundMethod: AvailableRefundMethod
  setRefundMethodValidationError?: (error: string) => void
}) => {
  return (error: any) => {
    const useTicketAsFallback = refundMethod.useTicketAsFallback
    const errorCode = error?.errorPayload?.errorCode
    let refundMethodError = ''
    // How do we know if payment not captured by alfred
    if (errorCode === ALFRED_UNAUTHORIZED) {
      refundMethodError = useTicketAsFallback
        ? 'Actions Widget.Actions.Partial Refund.A refund case will be created because Alfred authorization failed'
        : 'Actions Widget.Actions.Partial Refund.Authorization failed, ask a supervisor to check Alfred permissions and try again later'
    } else {
      refundMethodError = useTicketAsFallback
        ? 'Actions Widget.Actions.Partial Refund.Refund to {{method}} is not allowed for this order, a refund case will be created'
        : 'Actions Widget.Actions.Partial Refund.Refund to {{method}} is not allowed for this order, please try different refund method'
    }
    if (setRefundMethodValidationError && refundMethodError) {
      setRefundMethodValidationError(
        t(refundMethodError, {
          useLastKeyAsFallback: true,
          replace: {
            method: refundMethod.method,
          },
        }),
      )
    }
  }
}

export const handleOrderPurchaseResponse = ({
  t,
  refundMethod,
  action,
  calculatedTotalAmount,
  setRefundMethodValidationError,
}: {
  t: TranslateFunction
  action: string
  calculatedTotalAmount: number
  refundMethod: AvailableRefundMethod
  setRefundMethodValidationError?: (error: string) => void
}) => {
  return (response: FulFilledApiResponse<OrderPurchaseDetails>) => {
    const { data: purchaseDetails } = response

    const { amount: maxRefundAmount } = purchaseDetails?.purchase?.amount || {}
    const { amount: refundedAmount = 0 } = purchaseDetails?.purchase?.refundedAmount || {}
    const useTicketAsFallback = refundMethod.useTicketAsFallback
    let refundMethodError = ''

    const refundActionTarget = OrderPurchaseTargetMap[action]
    const refundDetails = purchaseDetails[refundActionTarget]

    const maxRefundAmountExceeded =
      maxRefundAmount && refundedAmount + calculatedTotalAmount > maxRefundAmount

    if (maxRefundAmountExceeded) {
      refundMethodError = useTicketAsFallback
        ? 'Actions Widget.Actions.Partial Refund.A refund case will be created because max refund amount has been exceeded'
        : 'Actions Widget.Actions.Partial Refund.Refund to {{method}} is not allowed because max refund amount has been exceeded'
    } else if (
      (!refundDetails?.psp?.allowed && refundMethod.method === availableRefundMethods.source) ||
      (!refundDetails?.wallet?.allowed && refundMethod.method === availableRefundMethods.wallet)
    ) {
      if (refundDetails?.wallet?.reason === ERROR_REFUND_COMPENSATION_PAYMENT_NOT_COMPLETED) {
        refundMethodError = useTicketAsFallback
          ? 'Messages.A refund case will be created because Payment status is not paid'
          : 'Messages.Refund to {{method}} is not allowed because Payment status is not paid'
      } else {
        refundMethodError = useTicketAsFallback
          ? 'Actions Widget.Actions.Partial Refund.Refund to {{method}} is not allowed for this order, a refund case will be created'
          : 'Actions Widget.Actions.Partial Refund.Refund to {{method}} is not allowed for this order, please try different refund method'
      }
    }

    if (!refundMethodError) {
      return
    }

    if (setRefundMethodValidationError)
      setRefundMethodValidationError(
        t(refundMethodError, {
          useLastKeyAsFallback: true,
          replace: {
            method: refundMethod.method,
          },
        }),
      )
  }
}
