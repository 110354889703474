import { primary } from 'theme'

export default {
  emptyContactsContainer: {
    padding: '16px 12px',
  },
  emptyCard: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '12px',
    padding: '24px',
  },
  title: {
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '24px',
    color: 'rgba(0, 0, 0, 0.85)',
  },
  subtitle: {
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '22px',
    color: 'rgba(0, 0, 0, 0.55)',
  },
  actionsContainer: {
    margin: '8px 0',
    display: 'flex',
    flexDirection: 'row-reverse',
  },
  addActionButton: {
    color: primary.primary6,
    border: 'none',
    boxShadow: 'none',
  },
}
