import React from 'react'
import { Typography } from 'antd'
import { createUseStyles } from 'react-jss'
import { useTranslation } from 'hooks/useTranslation'
import capitalizeFirstLetters from 'utils/capitalizeFirstLetters'
import { SingleComment } from 'types/api/commentApi/getOrderComments'
import ReportVoucherPaymentRefundItems from '../../widgets/HistoryOfEvents/OrderHistory/ReportVoucherPaymentRefundItems'
import styles from './Comments.styles'
import environment from '../../envConfig'
import { commentTagType, CommentView } from './CommentView'
import { WidgetSize } from 'types/widgets/historyOfEvents/tabs'

const { Text } = Typography

const useStyles = createUseStyles(styles)

type Props = {
  utc_zone: string
  comment: SingleComment
  withOrderId?: boolean
  onOrderIdClick?: (id: string) => void
  shouldCommentBeHighlighted: boolean
  mode: WidgetSize
}

const TicketRefundComponent = ({
  comment,
  shouldCommentBeHighlighted,
  onOrderIdClick,
  withOrderId,
  mode,
}: Props) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const isPartialRefund =
    comment.additional_data.ticket_refund_request.refund_purpose === 'item_refund'

  const refundTicketId = comment.additional_data.ticket_refund_request.ticket_id
  const refundTicketUrl = environment().salesforceCaseUrl?.replace('{caseid}', refundTicketId)

  return (
    <CommentView
      mode={mode}
      comment={comment}
      shouldCommentBeHighlighted={shouldCommentBeHighlighted}
      tag={{
        type: commentTagType.thunderbolt,
        text: t('History of Events Widget.Event Types.Refund Request'),
      }}
    >
      <div>
        <Text className={classes.commentText}>
          <Text>
            {` ${t('History of Events Widget.Content.requested')} ${
              isPartialRefund
                ? t('History of Events Widget.Content.Partial')
                : t('History of Events Widget.Content.Full')
            } ${t('History of Events Widget.Content.Refund')} `}
          </Text>
          <Text strong>
            {`${comment.additional_data.ticket_refund_request.refund_currency} ${
              comment.additional_data.ticket_refund_request.refund_amount
            } ${t('History of Events Widget.Content.via')} `}
            {comment.additional_data.ticket_refund_request.payment_medium}
          </Text>
          {isPartialRefund && (
            <ReportVoucherPaymentRefundItems
              data={comment?.additional_data?.ticket_refund_request}
            />
          )}
        </Text>
        <Text className={classes.commentText}>
          <Text>{` ${t('History of Events Widget.Content.Refund Ticket ID')}: `}</Text>
          <Text strong>
            <a href={refundTicketUrl} target='_blank' rel='noreferrer'>
              {refundTicketId}.
            </a>
          </Text>
          <span>
            {comment?.additional_data?.ticket_refund_request?.contact_reason &&
              ` ${t('Actions Widget.Actions.Compensation.Issue Type')}: ${capitalizeFirstLetters(
                comment.additional_data.ticket_refund_request.contact_reason.replace(/_/g, ' '),
              )}.`}
            {withOrderId && (
              <div className={classes.orderIdText} onClick={() => onOrderIdClick(comment.order_id)}>
                {`${t('Order Widget.Order ID')}: ${comment.order_id}`}
              </div>
            )}
          </span>
        </Text>
      </div>
    </CommentView>
  )
}

export default TicketRefundComponent
