export function downloadFileBlob({ file, fileName = '' }: { file: Blob; fileName?: string }) {
  const url = window.URL.createObjectURL(file)
  const aElement = document.createElement('a')

  aElement.style.display = 'none'
  aElement.href = url
  aElement.download = fileName

  document.body.appendChild(aElement)
  aElement.click()

  window.URL.revokeObjectURL(url)
  aElement.remove()
}
