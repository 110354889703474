import { ApiErrorConfig } from './voucherApiErrorConfig'

export const fulfillmentApiErrorConfig: ApiErrorConfig<'FulfillmentApi'> = {
  FulfillmentApi: {
    getOrderFulfillment: {
      '400': {
        '*': {
          screen: 'error',
          heading: 'Rider data unavailable',
          copyText: 'An error has occured',
        },
      },
      '401': {
        '*': {
          screen: 'warning',
          heading: 'Rider data unavailable',
          copyText: 'An error has occured{{dot}} Select refresh to try again',
        },
      },
      '403': {
        '*': {
          screen: 'instruct',
          heading: 'Permission required',
          copyText: 'Ask a supervisor to check Oneview permissions then refresh',
        },
      },
      '404': {
        '*': {
          screen: 'no-data',
          heading: 'No rider assigned',
          copyText: '',
        },
      },
      '500': {
        '*': {
          screen: 'error',
          heading: 'Rider data unavailable',
          copyText: 'An error has occured',
        },
      },
    },
    getProofOfDelivery: {
      '400': {
        '*': {
          screen: 'error',
          heading: 'Proof of delivery unavailable',
          copyText: 'Error HTTP 400 has occured',
        },
      },
      '401': {
        '*': {
          screen: 'warning',
          heading: 'Proof of delivery unavailable',
          copyText: 'Error HTTP 401 has occured',
        },
      },
      '403': {
        '*': {
          screen: 'instruct',
          heading: 'Permission required',
          copyText: 'Ask supervisor to check Oneview permissions then refresh',
        },
      },
      '404': {
        '*': {
          screen: 'no-data',
          heading: 'No proof of delivery found',
          copyText: '',
        },
      },
      '500': {
        '*': {
          screen: 'error',
          heading: 'Proof of delivery unavailable',
          copyText: 'Error HTTP 500 has occured',
        },
      },
      '503': {
        '*': {
          screen: 'error',
          heading: 'Proof of delivery unavailable',
          copyText: 'Error HTTP 503 has occured',
        },
      },
    },
  },
}
