import React from 'react'
import styles from './CustomerTranscriptView.styles'
import { createUseStyles } from 'react-jss'
import { CustomerChatListItem } from 'types/api/ticketApi/getOrderTickets'
import { useTranslation } from 'hooks/useTranslation'
import { UnifiedIcon } from 'shared'
import { getCcrItemByCode } from 'utils/ccr/getCcrItemByCode'

const useStyles = createUseStyles(styles)

interface ChatCardParams {
  item: CustomerChatListItem
  handleSelectRoom: (id: string | null) => void
}

const ChatRoomCard = ({ item, handleSelectRoom }: ChatCardParams) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const title =
    getCcrItemByCode({ ccrCode: item.metadata.ccr })?.name || 'error: cannot resolve ccr'

  return (
    <div className={classes.chatRoomCard} onClick={() => handleSelectRoom(item.id)}>
      <div>
        <p className={classes.chatRoomCardTitle}>{t(`${title}`)}</p>
        <p className={classes.chatRoomCardSubtext}>{`${t('with')} ${item.customer.email}`}</p>
      </div>
      <div className={classes.chatRoomCardIcon}>
        <UnifiedIcon icon={'RightOutlined'} size={14} />
      </div>
    </div>
  )
}

export default ChatRoomCard
