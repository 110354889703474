/**
 * expects order API's response & entity's UTC offset
 * creates 3 arrays with appropriate key/value pairs to be used in Order widget Summary Tab
 * */
import { FulfillmentApiResponse } from 'types/api/fulfillmentApi/fulfillment'
import { orderSummaryDataPoints } from 'entityConfig/dataPoints/order'
import { RiderStatuses } from 'types/widgets/rider/riderStatuses'

/**
 * getSplitDeliveryTags will always return one or no tag.
 */
export function getSplitDeliveryTags(fulfillment: FulfillmentApiResponse, label: string) {
  const { deliveries = [] } = fulfillment ?? {}
  const activeDeliveries = deliveries.filter(
    (delivery) => !delivery.courier_events?.some((event) => event.name === RiderStatuses.canceled),
  )
  return activeDeliveries.length > 1
    ? [{ label, key: orderSummaryDataPoints.tags.splitDelivery, value: 'Split Delivery' }]
    : []
}
