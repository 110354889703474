import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Typography, Row, Col, Button } from 'antd'
import { SelectBox } from 'shared'
import { SingleCancellationReason } from '../../types/actions/cancelOrder'
import styles from './CancellationReasonDropdown.styles'
import { createUseStyles } from 'react-jss'
import { useTranslation } from 'hooks/useTranslation'
import { useCaptureUserAction } from 'hooks/events/useCaptureUserAction'

const useStyles = createUseStyles(styles)

const { Text } = Typography

type Option = {
  value: string
  label: string
}

type Props = {
  orderId: string
  lob: 'rider' | 'customer'
  className?: string
  isCancellingOrder: boolean
  handleCancelOrder: () => void
  availableCancellationReasons: Array<SingleCancellationReason>
  selectCancellationReason: (SingleCancellationReason) => void
  selectedReason: string
}

const CancellationReasonDropdown = ({
  lob,
  orderId,
  handleCancelOrder,
  isCancellingOrder,
  selectCancellationReason,
  selectedReason,
  availableCancellationReasons,
  ...otherProps
}: Props) => {
  const forMini = lob === 'rider'
  const classes = useStyles({ forMini })
  const { t } = useTranslation()

  const captureUserAction = useCaptureUserAction()

  // Filter text for cancellation reasons
  const [filterText, setFilterText] = useState<string>('')

  const handleReasonSelection = useCallback(
    (option: Option) => {
      const reason = availableCancellationReasons.find(({ reason }) => reason === option.value)
      if (filterText) {
        captureUserAction('ActionsCancelOrderFilterUsed', {
          eventDetails: {
            text: filterText,
          },
        })
      }
      setFilterText('')
      selectCancellationReason(reason)
    },
    [selectCancellationReason, availableCancellationReasons, captureUserAction, filterText],
  )

  const isOnlyOneReasonAvailable = availableCancellationReasons.length === 1
  useEffect(() => {
    if (!selectedReason && isOnlyOneReasonAvailable) {
      const { reason, reason_translation_key } = availableCancellationReasons[0]

      handleReasonSelection({
        value: reason,
        label: t(
          `Actions Widget.Actions.Cancel Order.Cancellation Reasons.${reason_translation_key}`,
          reason_translation_key,
        ),
      })
    }
  }, [
    availableCancellationReasons,
    selectedReason,
    handleReasonSelection,
    isOnlyOneReasonAvailable,
    t,
  ])

  const handleDropdownClick = useCallback(
    (isVisible) => {
      if (isVisible) {
        captureUserAction('ActionsCancelOrderDropdownClicked')
      }

      return isVisible
    },
    [captureUserAction],
  )

  const options: Option[] = useMemo(
    () =>
      availableCancellationReasons.map((reason) => ({
        value: reason.reason,
        label: t(
          `Actions Widget.Actions.Cancel Order.Cancellation Reasons.${reason.reason_translation_key}`,
          reason.reason_translation_key,
        ),
      })),
    [availableCancellationReasons, t],
  )

  return (
    <div className={classes.container}>
      <Row align='middle'>
        <Col span={forMini ? 24 : 5} offset={forMini ? 0 : 3}>
          <Text className={classes.label} strong>
            {t('Actions Widget.Actions.Cancel Order.Cancel Reason')}:
          </Text>
        </Col>
        <Col span={forMini ? 24 : 12}>
          <SelectBox
            disabled={isOnlyOneReasonAvailable}
            testId='reason-selector'
            placeholder={t('Actions Widget.Actions.Cancel Order.Select A Cancel Reason')}
            options={options}
            {...otherProps}
            onSelectChange={handleReasonSelection}
            onDropdownVisibleChange={handleDropdownClick}
            onSearch={setFilterText}
          />
        </Col>
      </Row>
      {selectedReason ? (
        <React.Fragment>
          <Row align='middle'>
            <Col span={forMini ? 12 : 5} offset={forMini ? 0 : 3}>
              <Text strong>Order ID: </Text>
            </Col>
            <Col span={12}>
              <Text>{orderId}</Text>
            </Col>
          </Row>
          <div className={classes.buttonContainer}>
            <Button
              type='primary'
              data-test-id='cancel-order-btn'
              disabled={isCancellingOrder}
              onClick={handleCancelOrder}
            >
              {t('Actions Widget.Actions.Cancel Order.Title')}
            </Button>
          </div>
        </React.Fragment>
      ) : null}
    </div>
  )
}

export default CancellationReasonDropdown
