/**
 * LastOrders Widget: fetches previous orders of customer
 * renders prev orders as a list
 * */

// libs
import React, { useContext, useMemo } from 'react'
// contexts and types
import { DataContext } from 'contexts/data/DataContext'
import { DataAction } from 'contexts/data/types'
import { SessionContext } from 'contexts/session/SessionContext'
import { LinesOfBusiness } from 'types/session/linesOfBusiness'
// hooks
import { useTranslation } from 'hooks/useTranslation'
// utils
// styles
import { createUseStyles } from 'react-jss'
import styles from './LastOrders.styles'
import { Button } from 'antd'
// components
import FallBackView from 'components/FallBackView'
import LastOrdersView from './LastOrdersView'
import { useApiService } from 'hooks/useApiService'
import { GetOrderPropertyFilterParams } from 'services/ordersApi/getLastOrders'
import { WidgetErrorHandler } from 'components/WidgetErrorHandler/widgetErrorHandler'
import { useOrderPropertyFilterByLob } from 'hooks/useOrderPropertyFilterByLob'
import { getLastOrdersV2 } from 'services/ordersApi/getLastOrdersV2'
import { useWidgetViewManager } from 'contexts/widgetViewManager'
import { builtinWidgetNames, widgetTypes } from 'types/herocare'

const useStyles = createUseStyles(styles)

export enum LastOrdersContexts {
  SearchActionResults = 'SearchActionResults',
  SearchPageResults = 'SearchPageResults',
  MoreLastOrders = 'MoreLastOrders',
}

type LastOrdersProps = {
  lob: LinesOfBusiness
}

const LastOrders: React.FC<LastOrdersProps> = ({ lob }) => {
  const classes = useStyles()

  // pull language content
  const { t } = useTranslation()
  const { activateWidgetView } = useWidgetViewManager()

  // pull data state and dispatch from data context
  const {
    dataState: { orderError, previousOrders, isOrderCancelled },
    dataDispatch,
  } = useContext(DataContext)
  const { SET_PREV_ORDERS } = DataAction

  // pull globalentity ID from session context
  const {
    sessionState: { globalEntityId },
  } = useContext(SessionContext)

  const defaultFilters = useMemo(
    (): GetOrderPropertyFilterParams => ({
      include_pending_order: true,
    }),
    [],
  )

  const filters = useOrderPropertyFilterByLob(defaultFilters)

  const {
    loading: isLoading,
    error,
    loadService: loadOrders,
  } = useApiService({
    service: getLastOrdersV2,
    params: {
      entityId: globalEntityId,
      propertyFilters: filters,
      pageSize: 5,
    },
    deps: [globalEntityId, filters, isOrderCancelled],
    shouldLoad: filters !== null,
    onSuccess({ data }) {
      const lastOrders = data.orders
      dataDispatch({
        type: SET_PREV_ORDERS,
        payload: { previousOrders: lastOrders },
      })
    },
  })

  return (
    <WidgetErrorHandler
      loading={isLoading}
      loadingText={t('Interface.Loading Last Orders of Customer')}
      errorPayload={orderError || error?.errorPayload}
      onRetry={loadOrders}
    >
      {() => {
        if (!previousOrders?.length) {
          return (
            <FallBackView
              title={`${t('Interface.No Data Found')}!`}
              subTitle={t('Messages.The requested resource could not be found')}
            />
          )
        }
        return (
          <div>
            <div className={classes.lastOrdersHeader}>
              <p className={classes.lastOrdersTitle}>{t('Last Orders Widget.Last Orders')}</p>
              <Button
                type='default'
                size='small'
                onClick={() => {
                  activateWidgetView({
                    type: widgetTypes.builtin,
                    widget_name: builtinWidgetNames.lastOrders,
                    label: {
                      label_translation_key: 'widget_labels.last_orders',
                    },
                  })
                }}
              >
                {t('Last Orders Widget.View more')}
              </Button>
            </div>
            <LastOrdersView lob={lob} />
          </div>
        )
      }}
    </WidgetErrorHandler>
  )
}

export default LastOrders
