import { InitialState, RiderServiceAction, RiderServiceActions } from './types'

const {
  SET_IS_NEW_COMMENT,
  SET_IS_CANCEL_ORDER,
  SET_NEW_COMMENT_ID,
  SET_ORDER,
  SET_ORDER_ERR,
  SET_ORDER_STATUS,
  SET_ORDER_STATUS_ERR,
  SET_ORDER_FULFILLMENT,
  SET_ORDER_FULFILLMENT_ERR,
  SET_RIDER,
  SET_RIDER_ERR,
  SET_VENDOR,
  SET_VENDOR_ERR,
  SET_CUSTOMER,
  SET_CUSTOMER_ERR,
  SET_RIDER_CUSTOMER_CHAT,
} = RiderServiceAction

export const riderServiceReducer = (
  riderServiceState: InitialState,
  action: RiderServiceActions,
) => {
  switch (action.type) {
    case SET_IS_NEW_COMMENT:
      return {
        ...riderServiceState,
        isNewComment: action.payload.isNewComment,
      }
    case SET_IS_CANCEL_ORDER:
      return {
        ...riderServiceState,
        isCancelOrder: action.payload.isCancelOrder,
      }
    case SET_NEW_COMMENT_ID:
      return {
        ...riderServiceState,
        newCommentId: action.payload.newCommentId,
      }
    case SET_ORDER:
      return {
        ...riderServiceState,
        order: action.payload.order,
      }
    case SET_ORDER_ERR:
      return {
        ...riderServiceState,
        orderError: action.payload.orderError,
      }
    case SET_ORDER_STATUS:
      return {
        ...riderServiceState,
        orderStatus: action.payload.orderStatus,
      }
    case SET_ORDER_STATUS_ERR:
      return {
        ...riderServiceState,
        orderStatusError: action.payload.orderStatusError,
      }
    case SET_ORDER_FULFILLMENT:
      return {
        ...riderServiceState,
        orderFulfillment: action.payload.orderFulfillment,
      }
    case SET_ORDER_FULFILLMENT_ERR:
      return {
        ...riderServiceState,
        orderFulfillmentError: action.payload.orderFulfillmentError,
      }
    case SET_RIDER:
      return {
        ...riderServiceState,
        rider: action.payload.rider,
      }
    case SET_RIDER_ERR:
      return {
        ...riderServiceState,
        riderError: action.payload.riderError,
      }
    case SET_VENDOR:
      return {
        ...riderServiceState,
        vendor: action.payload.vendor,
      }
    case SET_VENDOR_ERR:
      return {
        ...riderServiceState,
        vendorError: action.payload.vendorError,
      }
    case SET_CUSTOMER:
      return {
        ...riderServiceState,
        customer: action.payload.customer,
      }
    case SET_CUSTOMER_ERR:
      return {
        ...riderServiceState,
        customerError: action.payload.customerError,
      }
    case SET_RIDER_CUSTOMER_CHAT:
      return {
        ...riderServiceState,
        riderCustomerChatTranscript: action.payload.riderCustomerChatTranscript,
      }
    default:
      return riderServiceState
  }
}
