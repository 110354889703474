import { ApiService } from 'services/authApi/getPermissions'

export type VendorContact = {
  contact_role?: string
  email?: string
  first_name?: string
  hotline?: string
  id?: string
  last_name?: string
  middle_name?: string
  mobile_phone?: string
  phone?: string
  salutation?: string
  suffix?: string
}

export interface VendorContactsResponse {
  data: VendorContact[]
}

export const getVendorContacts: ApiService<
  { globalEntityId: string; globalVendorId: string },
  VendorContactsResponse
> = (createClient, options) => {
  const { globalEntityId, clientParams, globalVendorId } = options
  const uri = `/VendorAPI/v1/vendors/${globalEntityId}/${globalVendorId}/contacts`

  return createClient({
    endpointName: 'getVendorContacts',
    expectedResponseStatusCode: 200,
    ...clientParams,
  }).get<VendorContactsResponse>(uri)
}
