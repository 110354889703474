/**
 * Removed Items Details of single comment
 * expects array of objects (full removed item details with removed toppings details)
 * */

import React from 'react'

// Types
import { SingleRemovedItem } from 'types/api/commentApi/getOrderComments'

// Utils
import commaDotChain from 'utils/commaDotChain'

// Components
import { Typography } from 'antd'

const { Text } = Typography

type Props = {
  items: SingleRemovedItem[]
  withoutQuantity?: boolean
}

function FullItemDetails({ items, withoutQuantity }: Props) {
  return (
    <>
      {items.map((removedItem, idx) => {
        if (removedItem.removed_quantity) {
          return (
            <Text strong key={removedItem.id}>
              {`${removedItem.removed_quantity} X ${removedItem.name}${commaDotChain(
                idx + 1 !== items.length,
              )}`}
            </Text>
          )
        }

        if (removedItem.removed_toppings?.length) {
          return (
            <React.Fragment key={removedItem.id}>
              {removedItem.removed_toppings.map((topping, i) => {
                // For 'remove items' notification we are not showing quantity
                const quantityPrefix = !withoutQuantity ? `${topping.removed_quantity} X ` : ''
                return (
                  <React.Fragment key={topping.id}>
                    <Text strong>
                      {`${quantityPrefix}${topping.name}${
                        i + 1 !== removedItem?.removed_toppings?.length ? ', ' : ''
                      }`}
                    </Text>
                    <Text>{` from ${removedItem.name}. `}</Text>
                  </React.Fragment>
                )
              })}
            </React.Fragment>
          )
        }

        if (removedItem.partial_item_issue) {
          const { quantity, description } = removedItem.partial_item_issue
          const quantityPrefix = !withoutQuantity ? `${quantity} X ` : ''
          return (
            <React.Fragment key={removedItem.id}>
              <Text strong>{`${quantityPrefix}${description}`}</Text>
              <Text>{` from ${removedItem.name}. `}</Text>
            </React.Fragment>
          )
        }

        return null
      })}
    </>
  )
}

export default FullItemDetails
