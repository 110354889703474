import React, { useMemo } from 'react'
import { useStyles } from './PickAndDropAddress.styles'
import { ArrowRightOutlined } from '@ant-design/icons'
import { Text } from 'shared'
import { useTranslation } from 'hooks/useTranslation'
import { VendorLocation } from 'types/api/vendorApi/vendor'
import { DeliveryLocation } from 'types/api/orderApi/order'
import { DeliveryInstruction } from '../DeliveryInstruction'
import { DeliveryInstructionsTags } from '../DeliveryInstructionsTags'
import { BuiltinWidgetConfigs } from 'types'
import { useSdk } from 'contexts/SdkProvider'
import { text } from 'theme'

type Props = {
  dropoff?: DeliveryLocation
  pickup?: VendorLocation
  config: BuiltinWidgetConfigs['order_deliveries_v2']
  deliveryInstructionsTags: string[]
}

export const PickAndDropAddress = ({
  dropoff,
  pickup,
  config,
  deliveryInstructionsTags,
}: Props) => {
  const sdk = useSdk()
  const { t } = useTranslation()
  const classes = useStyles()

  const canDisplayDropoffInstruction = useMemo(() => {
    const deliveryIssuesDisplayRules = config.data_points?.find(
      (data_point) => data_point.name === 'dropoff_instruction',
    )?.display_rules

    return sdk.checkDisplayRules({
      displayRules: deliveryIssuesDisplayRules,
    }).visible
  }, [config.data_points, sdk])

  const canDisplayPickupInstruction = useMemo(() => {
    const deliveryIssuesDisplayRules = config.data_points?.find(
      (data_point) => data_point.name === 'pickup_instruction',
    )?.display_rules

    return sdk.checkDisplayRules({
      displayRules: deliveryIssuesDisplayRules,
    }).visible
  }, [config.data_points, sdk])

  const canDisplayDeliveryInstructionsTags = useMemo(() => {
    const { display_rules } =
      config.data_points?.find((dataPoint) => dataPoint.name === 'delivery_instructions_tags') ?? {}

    const { visible } = sdk.checkDisplayRules({
      displayRules: display_rules,
    })

    return visible
  }, [config.data_points, sdk])

  return (
    <div className={classes.container}>
      <div className={classes.addressBox}>
        <Text.Primary className={classes.addressTitle}>
          {t('widgets.order_deliveries.addresses.pick_up_address')}
        </Text.Primary>
        <p className={classes.addressText}>{pickup?.address_text}</p>
        {canDisplayPickupInstruction && (
          <DeliveryInstruction instruction={pickup?.rider_instructions} />
        )}
        <div className={classes.transfer}>
          <ArrowRightOutlined style={{ color: text.secondary }} />
        </div>
      </div>
      <div className={classes.addressBox}>
        <Text.Primary className={classes.addressTitle}>
          {t('widgets.order_deliveries.addresses.drop_off_address')}
        </Text.Primary>
        <p className={classes.addressText}>{dropoff?.address_text}</p>
        {canDisplayDeliveryInstructionsTags && (
          <DeliveryInstructionsTags tags={deliveryInstructionsTags} />
        )}
        {canDisplayDropoffInstruction && <DeliveryInstruction instruction={dropoff?.description} />}
      </div>
    </div>
  )
}
