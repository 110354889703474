import React from 'react'
import { Table as AntDTable } from 'antd'
import { TableProps as AntTableProps } from 'antd/lib/table'
import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles({
  container: {
    '& .ant-table-cell:not(:first-child)': {
      textAlign: 'center',
    },
    '& .ant-table-container table>thead>tr:first-child th:first-child': {
      borderTopLeftRadius: '5px',
    },
    '& .ant-table-container table>thead>tr:first-child th:last-child': {
      borderTopRightRadius: '5px',
    },
    '& .ant-table-container table>tbody>tr:last-child td:first-child': {
      borderBottomLeftRadius: '5px',
    },
    '& .ant-table-container table>tbody>tr:last-child td:last-child': {
      borderBottomRightRadius: '5px',
    },
    '& .ant-table-content > table': {
      borderRadius: '5px',
    },
    '& .ant-table-container ': {
      borderRadius: '5px',
    },
    '& .ant-table ': {
      borderRadius: '5px',
    },
  },
})

export type TableProps = AntTableProps<object>
export const Table: React.FC<TableProps> = (props) => {
  const classes = useStyles()
  return <AntDTable className={classes.container} {...props} />
}
