/**
 * Rider Panel component designed to hold and render Rider Service Navbar tabs
 * */

// libs
import React, { useState, useEffect, useContext } from 'react'
// configs
import { allowedRiderServiceNavbarTabs } from 'entityConfig/allowedConfigValues'
// contexts and types
import { EntityContext } from 'contexts/entity/EntityContext'
import { SessionContext } from 'contexts/session/SessionContext'
import { SessionAction } from 'contexts/session/types'
import { RiderServiceContext } from 'contexts/riderService/RiderServiceContext'
import { RiderServiceAction } from 'contexts/riderService/types'
import { LinesOfBusiness } from 'types/session/linesOfBusiness'
import NavbarTabs from 'types/riderService/navbarTabs'
// hooks
import { useTranslation } from 'hooks/useTranslation'
import useRefreshPermissions from 'hooks/useRefreshPermissions'
import { createClient } from 'hooks/useFetchData'
// utils
import environment from 'envConfig'
import getPlatformByGei from 'utils/getPlatformByGei'
import processApiErrors from 'utils/oneviewApi/processApiErrors'
import createOneviewHeaders from 'utils/oneviewApi/createOneviewHeaders'
import transformOrder from 'utils/miniUtils/transformOrder'
import transformRider from 'utils/miniUtils/transformRider'
import transformVendor from 'utils/miniUtils/transformVendor'
import transformCustomer from 'utils/miniUtils/transformCustomer'
import ensureJwtIsValid from 'utils/oneviewApi/ensureJwtIsValid'

// styles
import styles from './RiderPanel.styles'
import { createUseStyles } from 'react-jss'
import { Tabs } from 'antd'
// components
import LoadingView from 'components/LoadingView'
import MiniErrorView from 'components/MiniErrorView'
import Actions from 'miniWidgets/Actions'
import Order from 'miniWidgets/Order'
import Rider from 'miniWidgets/Rider'
import Vendor from 'miniWidgets/Vendor'
import Customer from 'miniWidgets/Customer'
import HistoryOfEvents from 'miniWidgets/HistoryOfEvents'
import NewComment from 'miniWidgets/Actions/NewComment'
import CancelOrder from 'miniWidgets/Actions/CancelOrder'
// assets
import orderErrorIcon from 'assets/error/orderError.png'
import orderIconActive from 'assets/order/Blue.png'
import customerIconActive from 'assets/customer/Blue.png'
import riderIconActive from 'assets/rider/Blue.png'
import vendorIconActive from 'assets/vendor/Blue.png'
import historyOfEventsIconActive from 'assets/historyOfEvents/Blue.png'
import orderIconInactive from 'assets/order/Gray.png'
import customerIconInactive from 'assets/customer/Gray.png'
import riderIconInactive from 'assets/rider/Gray.png'
import vendorIconInactive from 'assets/vendor/Gray.png'
import historyOfEventsIconInactive from 'assets/historyOfEvents/Gray.png'
import { useGetValidFeatures } from 'hooks/useGetValidFeatures'
import { useCaptureUserAction } from 'hooks/events/useCaptureUserAction'
import { Language } from 'types'
import { getCcrItemByName } from 'utils/ccr/getCcrItemByName'

const useStyles = createUseStyles(styles)

const RiderPanel: React.FC = () => {
  const classes = useStyles()
  const { TabPane } = Tabs

  // pull translations
  const { t } = useTranslation()

  const { entityState } = useContext(EntityContext)
  const getValidFeatures = useGetValidFeatures()

  // pull session
  const {
    sessionState: { orderId, globalEntityId, riderId, caseId },
    sessionDispatch,
  } = useContext(SessionContext)
  const { SET_SESSION } = SessionAction

  // pull rider state
  const {
    riderServiceState: { rider, riderError, isNewComment, newCommentId, isCancelOrder },
    riderServiceDispatch,
  } = useContext(RiderServiceContext)
  const {
    SET_ORDER,
    SET_ORDER_ERR,
    SET_CUSTOMER,
    SET_CUSTOMER_ERR,
    SET_RIDER,
    SET_RIDER_ERR,
    SET_VENDOR,
    SET_VENDOR_ERR,
  } = RiderServiceAction

  // useRefreshPermissions fetches new token, if current is expired
  const { isRefreshTokenExpired } = useRefreshPermissions([orderId, globalEntityId])

  const captureUserAction = useCaptureUserAction()

  // if current refresh token is expired, redirect user to the root
  useEffect(() => {
    if (isRefreshTokenExpired) {
      window.location.reload()
    }
  }, [isRefreshTokenExpired])

  // run beta flag check on each fixed panel widget to be rendered
  const [activeTab, setActiveTab] = useState<NavbarTabs>()
  const [widgetOrder, setWidgetOrder] = useState<string[]>()
  useEffect(() => {
    if (entityState?.entityConfig?.mininav?.tab_order) {
      const config = entityState.entityConfig.mininav.tab_order
      const widgets = getValidFeatures(config)

      if (newCommentId) {
        setActiveTab(NavbarTabs.historyOfEvents)
      } else {
        setActiveTab(NavbarTabs[widgets[0]])
      }

      setWidgetOrder(widgets)
    }
  }, [entityState, newCommentId, getValidFeatures])

  // fired upon a Tab click
  const handleNavbarClick = (tab: string) => {
    setActiveTab(NavbarTabs[tab])
    captureUserAction('NavbarClicked', {
      eventDetails: {
        widget_name: tab,
      },
    })
  }

  // pull rider, order, vendor, customer consequatively
  const [isLoading, setIsLoading] = useState(true)
  const [notPermittedMessage, setNotPermittedMessage] = useState(null)
  const [noRiderIdFoundErr, setNoRiderIdFoundErr] = useState(false)
  useEffect(() => {
    const getVendorAsync = async (vendorId: string) => {
      const uri = `${environment().oneviewApiRoot}${
        environment().oneviewVendorApiRoot
      }/vendors/${globalEntityId}/${vendorId}`

      const headers = createOneviewHeaders()

      await createClient()
        .get(uri, headers)
        .then((value) => {
          if (value && value.status === 200 && value.data) {
            const vendorTransformed = transformVendor(value.data)
            riderServiceDispatch({
              type: SET_VENDOR,
              payload: { vendor: vendorTransformed },
            })
          }
        })
        .catch((err) => {
          // create err log entry and set it to local state
          const vendorError = processApiErrors(t, err, 'GetVendor', orderId, globalEntityId, true)
          riderServiceDispatch({
            type: SET_VENDOR_ERR,
            payload: { vendorError: vendorError },
          })
        })
        .finally(() => {
          setIsLoading(false)
        })
    }

    const getOrderAsync = async (orderCode: string) => {
      const uri = `${environment().oneviewApiRoot}${
        environment().oneviewOrderApiRoot
      }/orders/${globalEntityId}/${orderCode}`
      const headers = createOneviewHeaders()

      await createClient()
        .get(uri, headers)
        .then((value) => {
          if (value && value.status === 200 && value.data) {
            const orderDataTransformed = transformOrder(value.data)
            const customertransformed = transformCustomer(value.data)

            // set order and customer to context
            riderServiceDispatch({
              type: SET_ORDER,
              payload: { order: orderDataTransformed },
            })

            riderServiceDispatch({
              type: SET_CUSTOMER,
              payload: { customer: customertransformed },
            })

            // set session
            const search = window.location.search
            const params = new URLSearchParams(search)
            const language = params.get('lang')
            const ccr1 = params.get('ccr1')
            const ccr2 = params.get('ccr2')
            const ccr3 = params.get('ccr3')
            const ccrCode = params.get('ccrCode')

            // get vendor, if vendor ID found
            if (value.data?.vendor?.id) {
              getVendorAsync(value.data.vendor.id)
            }

            const session = {
              userId: value.data.customer?.customer_id || '',
              riderId: riderId || '',
              orderId: orderCode,
              caseId: caseId || '',
              globalEntityId: globalEntityId || '',
              lineOfBusiness: LinesOfBusiness.rider,
              lang: language as Language,
              ccrCode: ccrCode || getCcrItemByName({ ccr1, ccr2, ccr3 })?.code || '',
              platform: getPlatformByGei(LinesOfBusiness.rider, globalEntityId),
            }

            if (!language) {
              delete session.lang
            }

            sessionDispatch({
              type: SET_SESSION,
              payload: session,
            })
          }
        })
        .catch((err) => {
          const orderError = processApiErrors(t, err, 'GetOrder', orderId, globalEntityId, true)
          riderServiceDispatch({
            type: SET_ORDER_ERR,
            payload: { orderError: orderError },
          })
          riderServiceDispatch({
            type: SET_CUSTOMER_ERR,
            payload: { customerError: orderError },
          })
          setIsLoading(false)
        })
    }

    const getRiderAsync = async () => {
      const uri = `${environment().oneviewApiRoot}${
        environment().oneviewFulfillmentApiRoot
      }/couriers/${globalEntityId}/${riderId}`

      const headers = createOneviewHeaders()

      await createClient()
        .get(uri, headers)
        .then((value) => {
          if (value && value.status === 200 && value.data) {
            const ridertransformed = transformRider(value.data, entityState?.entityConfig?.utc_zone)
            riderServiceDispatch({
              type: SET_RIDER,
              payload: { rider: ridertransformed },
            })

            setNoRiderIdFoundErr(false)

            // get order, if orderID is found
            if (value.data.current_order?.order_id) {
              getOrderAsync(value.data.current_order?.order_id)
            } else {
              riderServiceDispatch({
                type: SET_ORDER_ERR,
                payload: { orderError: true },
              })
              riderServiceDispatch({
                type: SET_CUSTOMER_ERR,
                payload: { customerError: true },
              })
              riderServiceDispatch({
                type: SET_VENDOR_ERR,
                payload: { vendorError: true },
              })
              setIsLoading(false)
            }
          }
        })
        .catch((err) => {
          if (err?.response?.status && err?.response?.status === 403) {
            setNotPermittedMessage(
              t(
                'Messages.The requested resource is forbidden for the user, please contact a supervisor',
              ),
            )
          }

          const riderError = processApiErrors(
            t,
            err,
            'GetCourierDetails',
            orderId,
            globalEntityId,
            true,
          )
          riderServiceDispatch({
            type: SET_RIDER_ERR,
            payload: { riderError },
          })
          setIsLoading(false)
        })
    }

    const isTokensValid = ensureJwtIsValid(caseId)
    if (isTokensValid) {
      if (riderId && !rider && entityState?.entityConfig) {
        getRiderAsync()
      } else if (!riderId && !rider) {
        setNoRiderIdFoundErr(true)
        setIsLoading(false)
      }
    }
    // eslint-disable-next-line
  }, [
    rider,
    caseId,
    globalEntityId,
    riderId,
    orderId,
    t,
    riderServiceDispatch,
    sessionDispatch,
    SET_ORDER,
    SET_ORDER_ERR,
    SET_CUSTOMER,
    SET_CUSTOMER_ERR,
    SET_RIDER,
    SET_RIDER_ERR,
    SET_VENDOR,
    SET_VENDOR_ERR,
    SET_SESSION,
    entityState,
  ])

  // map through widget_line to render widgets in custom order, if widget available for entity
  return (
    <React.Fragment>
      {/* return loading spinner while fetching data */}
      {(!widgetOrder || isLoading) && <LoadingView text={`${t('Interface.Please Wait')}...`} />}

      {noRiderIdFoundErr && !isLoading && (
        <MiniErrorView
          icon={orderErrorIcon}
          errorMessage={`${t('Messages.No Rider ID found')}!`}
          errorSubtext={t('Messages.We did not receive a rider ID')}
        />
      )}

      {riderError && notPermittedMessage && !isLoading && (
        <MiniErrorView
          icon={orderErrorIcon}
          errorMessage={`${t('Messages.Permission denied')}!`}
          errorSubtext={`${notPermittedMessage}.`}
        />
      )}

      {riderError && !notPermittedMessage && !isLoading && (
        <MiniErrorView
          icon={orderErrorIcon}
          errorMessage={t('Messages.No Rider Found')}
          errorSubtext={`${t('Messages.We could not find a rider associated with this id')}: ${
            riderId ? riderId : ''
          }`}
        />
      )}

      {widgetOrder &&
        !isNewComment &&
        !isCancelOrder &&
        !isLoading &&
        !riderError &&
        !noRiderIdFoundErr && (
          <div className={classes.riderPanelHolder}>
            <Actions />
            <div className={classes.tabsHolder}>
              <Tabs
                activeKey={activeTab}
                onChange={(tab: string) => handleNavbarClick(tab)}
                type='card'
                size={'small'}
              >
                {widgetOrder.map((tab: string) => {
                  switch (tab) {
                    case allowedRiderServiceNavbarTabs.order:
                      return (
                        <TabPane
                          tab={
                            <img
                              className={classes.navbarItemIcon}
                              src={
                                activeTab === NavbarTabs.order ? orderIconActive : orderIconInactive
                              }
                              alt='order icon'
                            />
                          }
                          key={allowedRiderServiceNavbarTabs.order}
                        >
                          <Order />
                        </TabPane>
                      )
                    case allowedRiderServiceNavbarTabs.rider:
                      return (
                        <TabPane
                          tab={
                            <img
                              className={classes.navbarItemIcon}
                              src={
                                activeTab === NavbarTabs.rider ? riderIconActive : riderIconInactive
                              }
                              alt='order icon'
                            />
                          }
                          key={allowedRiderServiceNavbarTabs.rider}
                        >
                          <Rider />
                        </TabPane>
                      )
                    case allowedRiderServiceNavbarTabs.vendor:
                      return (
                        <TabPane
                          tab={
                            <img
                              className={classes.navbarItemIcon}
                              src={
                                activeTab === NavbarTabs.vendor
                                  ? vendorIconActive
                                  : vendorIconInactive
                              }
                              alt='order icon'
                            />
                          }
                          key={allowedRiderServiceNavbarTabs.vendor}
                        >
                          <Vendor />
                        </TabPane>
                      )
                    case allowedRiderServiceNavbarTabs.customer:
                      return (
                        <TabPane
                          tab={
                            <img
                              className={classes.navbarItemIcon}
                              src={
                                activeTab === NavbarTabs.customer
                                  ? customerIconActive
                                  : customerIconInactive
                              }
                              alt='order icon'
                            />
                          }
                          key={allowedRiderServiceNavbarTabs.customer}
                        >
                          <Customer />
                        </TabPane>
                      )
                    case allowedRiderServiceNavbarTabs.historyOfEvents:
                      return (
                        <TabPane
                          tab={
                            <img
                              className={classes.navbarItemIcon}
                              src={
                                activeTab === NavbarTabs.historyOfEvents
                                  ? historyOfEventsIconActive
                                  : historyOfEventsIconInactive
                              }
                              alt='history of events icon'
                              data-test-id='history-of-events-tab-mini1view'
                            />
                          }
                          key={allowedRiderServiceNavbarTabs.historyOfEvents}
                        >
                          <HistoryOfEvents />
                        </TabPane>
                      )
                    default:
                      return null
                  }
                })}
              </Tabs>
            </div>
          </div>
        )}

      {isNewComment && !isLoading && !riderError && <NewComment />}
      {isCancelOrder && !isNewComment && !isLoading && !riderError && <CancelOrder />}
    </React.Fragment>
  )
}

export default RiderPanel
