import { useApiClientCreator } from 'contexts/apiClientCreator/ApiClientCreatorContext'
import { SDK } from 'types'
import { useCallback } from 'react'

export const useCallProxyApi = () => {
  const { createClient } = useApiClientCreator()

  return useCallback<SDK['callProxyApi']>(
    ({ config, entityId, remotePath, operationId }) => {
      const { method, data, ...others } = config
      return createClient({ endpointName: 'proxyApi' }).request({
        method,
        data: { data },
        // !!IMPORTANT, do not use env variable here.
        url: `/ConfigAPI/proxy/${operationId}/${entityId}?remotePath=${remotePath}`,
        ...others,
      })
    },
    [createClient],
  )
}
