import React, { useCallback, useMemo, useState } from 'react'
import { createUseStyles } from 'react-jss'
import moment from 'moment'
import { Col, Divider, Radio, Row, Space } from 'antd'
import { Button, Table, TableProps, Text, UnifiedIcon, UnifiedIconType } from 'shared'
import classNames from 'classnames'
import { useSdk } from 'contexts/SdkProvider'
import {
  InvoiceItem,
  transformVendorInvoices,
} from 'utils/dataTransformers/transformVendorInvoices'
import { VendorInvoice } from 'services/vendorApi/getVendorInvoices'
import { green, primary, red } from 'theme'

type FilterTypes = InvoiceItem['type'] | 'all'

export type VendorPaymentProps = {
  invoices: VendorInvoice[]
  selectedDate: string
  pageSize?: number
  filesDownloading?: string[]
  onDownloadInvoice?: (fileName: string) => void
}

const useStyles = createUseStyles({
  typeTabs: {
    marginBottom: '16px',
  },
  divider: {
    margin: '14px 0',
  },
  summaryIcon: {
    color: `${red.red5}`,
  },

  invoiceIcon: {
    color: `${green.green6}`,
  },

  actionButton: {
    color: primary.primary5,
    borderRadius: '4px',
  },
})

function filterInvoices(items: InvoiceItem[], filter: FilterTypes) {
  if (filter === 'all') {
    return items
  }
  return items.filter((item) => item.type === filter)
}

function invoiceSorter(a: VendorInvoice, b: VendorInvoice) {
  // Sort by generation time in *Descending order*.
  // We can use lexicographical sort because generated_at is a ISO 8601 Date ending with 'Z'.
  // See https://en.wikipedia.org/wiki/ISO_8601#General_principles
  if (a.generated_at < b.generated_at) {
    return 1
  } else if (a.generated_at > b.generated_at) {
    return -1
  }

  return 0
}

export const VendorInvoices = ({
  invoices,
  selectedDate,
  pageSize,
  filesDownloading,
  onDownloadInvoice,
}: VendorPaymentProps) => {
  const [filter, setFilter] = useState<FilterTypes>('all')
  const classes = useStyles()
  const { captureUserAction, t } = useSdk()

  const items = transformVendorInvoices(invoices)
  const filteredItems = filterInvoices(items, filter)

  const handleDownloadInvoice = useCallback(
    (invoice: InvoiceItem) => {
      onDownloadInvoice(invoice.fileName)

      switch (invoice.fileFormat) {
        case 'pdf':
          captureUserAction('DOWNLOAD_INVOICE_PDF', { reportToEts: true })
          break
        case 'xls':
        case 'xlsx':
          captureUserAction('DOWNLOAD_INVOICE_XLS', { reportToEts: true })
          break
        default:
          break
      }
    },
    [captureUserAction, onDownloadInvoice],
  )

  const columns = useMemo((): TableProps['columns'] => {
    return [
      {
        title: t('Invoice date'),
        dataIndex: 'date',
        key: 'date',
        defaultSortOrder: 'descend',
        sorter: invoiceSorter,
      },
      {
        title: t('Invoice ID'),
        dataIndex: 'name',
        key: 'id',
      },
      {
        title: t('Invoice type'),
        dataIndex: 'type',
        key: 'type',
        align: 'left',
        render: (type: InvoiceItem['type'], item: InvoiceItem) => {
          let icon: UnifiedIconType = 'FileTextOutlined'

          // We assume all PDF files are Summary files and xls(x) files are Detailed invoices
          const typeLabel = {
            summary: t('Summary'),
            invoice: t('Detailed Invoice'),
          }[type]

          switch (item.fileFormat) {
            case 'pdf':
              icon = 'FilePdfOutlined'
              break
            case 'xls':
            case 'xlsx':
              icon = 'FileExcelOutlined'
              break
            default:
              icon = 'FileTextOutlined'
              break
          }

          return (
            <Space>
              <UnifiedIcon
                size={18}
                icon={icon}
                className={classNames({
                  [classes.invoiceIcon]: type === 'invoice',
                  [classes.summaryIcon]: type === 'summary',
                })}
              />

              <Text.Primary>{typeLabel}</Text.Primary>
            </Space>
          )
        },
      },
      {
        title: '',
        dataIndex: 'actions',
        key: 'actions',
        render: (_: InvoiceItem['type'], item: InvoiceItem) => {
          const isLoading = filesDownloading?.includes(item.fileName)

          return (
            <Button
              loading={isLoading}
              className={classes.actionButton}
              icon='DownloadOutlined'
              onClick={() => handleDownloadInvoice(item)}
            />
          )
        },
      },
    ]
  }, [
    classes.actionButton,
    classes.invoiceIcon,
    classes.summaryIcon,
    filesDownloading,
    handleDownloadInvoice,
    t,
  ])

  return (
    <div>
      <section>
        <Text.Primary>
          {t('Invoices results for')} <strong>"{moment(selectedDate).format('MMMM YYYY')}"</strong>
        </Text.Primary>
      </section>

      <Divider className={classes.divider} />

      <section>
        <Row justify='space-between'>
          <Col>
            <Text.Primary>
              {filteredItems?.length} {t('items')}
            </Text.Primary>
          </Col>

          <Col>
            <Radio.Group
              value={filter}
              className={classes.typeTabs}
              onChange={({ target }) => setFilter(target.value)}
            >
              <Radio.Button value='all'>{t('All')}</Radio.Button>
              <Radio.Button value='summary'>{t('Summary')}</Radio.Button>
              <Radio.Button value='invoice'>{t('Invoices')}</Radio.Button>
            </Radio.Group>
          </Col>
        </Row>
      </section>

      <section>
        <Table
          size='small'
          bordered={true}
          dataSource={filteredItems}
          columns={columns}
          pagination={{ pageSize }}
        />
      </section>
    </div>
  )
}
