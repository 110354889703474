import React from 'react'
import { createUseStyles } from 'react-jss'
import { useTranslation } from 'hooks/useTranslation'
import { useCaptureUserAction } from 'hooks/events/useCaptureUserAction'

const useStyles = createUseStyles({
  orderId: {
    fontSize: '12px',
    whiteSpace: 'nowrap',
    display: 'flex',
    justifyContent: 'left',
    alignItems: 'center',
  },
  label: {
    color: 'rgba(0, 0, 0, 0.55)',
  },
  id: {
    color: 'rgba(0, 0, 0, 0.85)',
    paddingLeft: '3px',
  },
})

interface Props {
  orderId?: string
}

export const OrderIdDisplay: React.FC<Props> = ({ orderId }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const captureUserAction = useCaptureUserAction()

  const handleCommentOrderIdClicked = () => {
    captureUserAction('HistoryOfEventsCustomerOrderIdClicked', {
      eventDetails: {
        comment_id: orderId,
      },
    })
  }

  return (
    orderId && (
      <span className={classes.orderId}>
        <span className={classes.label}>{t('Order Widget.Order ID')}:</span>
        <span className={classes.id} onClick={handleCommentOrderIdClicked}>
          {orderId}
        </span>
      </span>
    )
  )
}
