import React from 'react'

// Components
import { Timeline, Typography } from 'antd'

// Utils
import { createUseStyles } from 'react-jss'
import { transformOrderTimeline } from 'utils/dataTransformers/transformOrderTimeline'

// Hooks
import { useTranslation } from 'hooks/useTranslation'

// Types
import { OrderApiResponse } from 'types/api/orderApi/order'
import { FulfillmentApiResponse } from 'types/api/fulfillmentApi/fulfillment'
import { TimelineItem } from 'types/dataTransformers/orderStatus'

// Relative Path
import styles from './Timeline.styles'
import UpdatedDeliveryTime from './UpdatedDeliveryTime'

const useStyles = createUseStyles(styles)

type Props = {
  order: OrderApiResponse
  utc_zone: string
  fulfillment?: FulfillmentApiResponse
}

const { Text } = Typography

function DeliveryTimeline({ order, utc_zone, fulfillment }: Props) {
  const classes = useStyles()
  const { t } = useTranslation()

  const items: TimelineItem[] = transformOrderTimeline(order, fulfillment, utc_zone, t)

  return (
    <Timeline className={classes.timeline}>
      {items &&
        items.map((item) => (
          <Timeline.Item key={item.label}>
            <Text className={classes.itemLabel}>{item.label}</Text>
            {item.date && <Text>{item.date}</Text>}
            {item.newDeliveryTime ? (
              item.time && (
                <Text>
                  {item.time} <UpdatedDeliveryTime deliveryTime={item.newDeliveryTime} />
                </Text>
              )
            ) : (
              <Text>{item.time}</Text>
            )}
            {item.delay && (
              <Text data-testid='delayColor' type={item.delayTextType}>
                {item.delayNumber < 0
                  ? item.delay
                  : item.delayNumber === 0
                  ? t('Order Widget.Tabs.Summary.On Time')
                  : `+ ${item.delay}`}
              </Text>
            )}
            {item.info && <Text className={classes.infoText}>{item.info}</Text>}
          </Timeline.Item>
        ))}
    </Timeline>
  )
}

export default DeliveryTimeline
