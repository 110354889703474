/**
 * util function creating validity dates for compensating a voucher
 * start date is now, end date is calcualted based on number of valid days/months
 * */
import { DateTime } from 'luxon'

// caculate voucher validity based on input which is specified either to be month or unit
export function createCompensationStartAndEndDates(value: number, unit: 'month' | 'day') {
  return {
    nowText: DateTime.now().toFormat('yyyy-LL-dd'),
    laterText: DateTime.now()
      .plus({
        months: unit === 'month' ? value : 0,
        days: unit === 'day' ? value : 0,
      })
      .toFormat('yyyy-LL-dd'),
  }
}
