import { white } from 'theme'

export default {
  flex: {
    display: 'flex',
  },
  justifyEnd: {
    justifyContent: 'end',
  },

  mlAuto: {
    marginLeft: 'auto',
  },

  mt18: {
    marginTop: '18px',
  },

  mb16: {
    marginBottom: '16px',
  },

  block: {
    display: 'block',
  },

  resetAntCollapse: {
    backgroundColor: white,
    '& .ant-collapse-header': {
      paddingLeft: '0px',
      paddingRight: '0px',
      borderBottom: '1px solid #f0f0f0',
    },
    '& :hover': {
      backgroundColor: '#FAFAFA',
    },
  },

  /**
   * ant-collapse-item
   */
  panel: {
    boxShadow: '0px -1px 0px 0px #F0F0F0 inset',
    border: '0px !important',

    '& > .ant-collapse-header': {
      maxHeight: 'unset !important',
      paddingLeft: '0px !important',
      backgroundColor: `${white} !important`,
    },

    '& .ant-collapse-arrow': {
      right: '0px !important',
    },

    '& .ant-collapse-content-box': {
      padding: '0px !important',
    },
  },

  toggleIcon: {},

  searchField: {
    borderRadius: '4px',
  },
}
