/**
 * compares the passed date with Now
 * returns boolean, indicating < or > between the 2 dates
 * */

function compareDateWithNow(date: string, hours: number) {
  const dateNowInSeconds = new Date().getTime() / 1000
  const passedDateInSeconds = new Date(date).getTime() / 1000

  const differenceBetweenTwoDatesInSeconds = Math.abs(dateNowInSeconds - passedDateInSeconds)

  let differenceBetweenTwoDatesInHours = 0

  if (differenceBetweenTwoDatesInSeconds > 60) {
    differenceBetweenTwoDatesInHours = Math.floor(differenceBetweenTwoDatesInSeconds / 3600)
  }

  return differenceBetweenTwoDatesInHours < hours
}

export default compareDateWithNow
