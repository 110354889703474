// Types
import {
  OrderTransaction,
  GlobalPaymentType,
  TransactionStatusDisplay,
} from 'types/api/orderApi/order'
import isCOD from './order/isCOD'
import { PaymentMethods } from 'contexts/entity/types'

type TransactionFinderCondition = Partial<Pick<OrderTransaction, 'payment_type' | 'status'>>

const createFinder = <T, C = Partial<T>>(targets: T[]) => {
  return {
    get(condition: C): T {
      if (targets.length < 1 || !condition) {
        return null
      }
      const keys = Object.keys(condition)

      return (
        keys.length > 0 &&
        targets.find((target) => {
          return keys.every((key) => condition[key] === target[key])
        })
      )
    },

    has(condition: C) {
      const data = this.get(condition)
      return data !== null && data !== undefined
    },

    getAny(conditions: Array<C>): T {
      if (targets.length < 1 || conditions.length < 1) {
        return null
      }
      return this.get(conditions.find((condition) => this.get(condition)))
    },

    hasAny(conditions: Array<C>) {
      return this.getAny(conditions) !== null
    },
  }
}

export default function findMainPaymentMethodTransactionWithStatus(
  transactions: OrderTransaction[],
  maxPendingTimeInHours = 24,
  paymentMethod: PaymentMethods,
): {
  status: TransactionStatusDisplay
  transaction: OrderTransaction | null
} {
  if (
    paymentMethod === PaymentMethods.WALLET ||
    paymentMethod === PaymentMethods.balance ||
    paymentMethod === PaymentMethods.Invoice
  ) {
    return { status: 'paid', transaction: null }
  }

  if (!transactions || transactions.length < 1) {
    return { status: 'unknown', transaction: null }
  }

  const transactionFinder = createFinder(transactions)

  // failed condition 1-2: preauthorization=failure || authorization=failure
  const failedCondition1_2: TransactionFinderCondition[] = [
    { payment_type: GlobalPaymentType.PREAUTHORIZATION, status: 'failure' },
    { payment_type: GlobalPaymentType.AUTHORIZATION, status: 'failure' },
  ]

  if (transactionFinder.hasAny(failedCondition1_2)) {
    return {
      status: 'failed',
      transaction: transactionFinder.getAny(failedCondition1_2),
    }
  }

  //failed condition 3: preauthorization=pending && no preauthoriation=success
  // && maxPendingTimeInHours has been exceeded
  if (
    transactionFinder.has({
      payment_type: GlobalPaymentType.PREAUTHORIZATION,
      status: 'pending',
    }) &&
    !transactionFinder.has({
      payment_type: GlobalPaymentType.PREAUTHORIZATION,
      status: 'success',
    })
  ) {
    const transaction = transactionFinder.get({
      payment_type: GlobalPaymentType.PREAUTHORIZATION,
      status: 'pending',
    })
    const timeDiffInHours =
      (new Date().getTime() - new Date(transaction.timestamp).getTime()) / (1000 & (60 * 60))
    if (timeDiffInHours > maxPendingTimeInHours) {
      return {
        status: 'failed',
        transaction: transaction,
      }
    }
  }

  //failed condition 4: authorization=pending && no authoriation=success
  // && maxPendingTimeInHours has been exceeded
  if (
    transactionFinder.has({
      payment_type: GlobalPaymentType.AUTHORIZATION,
      status: 'pending',
    }) &&
    !transactionFinder.has({
      payment_type: GlobalPaymentType.AUTHORIZATION,
      status: 'success',
    })
  ) {
    const transaction = transactionFinder.get({
      payment_type: GlobalPaymentType.AUTHORIZATION,
      status: 'pending',
    })
    const timeDiffInHours =
      (new Date().getTime() - new Date(transaction.timestamp).getTime()) / (1000 & (60 * 60))
    if (timeDiffInHours > maxPendingTimeInHours) {
      return {
        status: 'failed',
        transaction: transaction,
      }
    }
  }

  // aborted condition 1: reversal=success
  // && payment_method = cash
  if (
    transactionFinder.has({
      payment_type: GlobalPaymentType.REVERSAL,
      status: 'success',
    })
  ) {
    const transaction = transactionFinder.get({
      payment_type: GlobalPaymentType.REVERSAL,
      status: 'success',
    })
    if (isCOD(transaction.payment_method))
      return {
        status: 'aborted',
        transaction: transaction,
      }
  }

  // paid condition 1-2
  const paidCondition1_2: TransactionFinderCondition[] = [
    { payment_type: GlobalPaymentType.PREAUTHORIZATION, status: 'success' },
    { payment_type: GlobalPaymentType.AUTHORIZATION, status: 'success' },
  ]

  if (transactionFinder.hasAny(paidCondition1_2)) {
    return {
      status: 'paid',
      transaction: transactionFinder.getAny(paidCondition1_2),
    }
  }

  // reserved condition 1-2
  const reservedCondition1_2: TransactionFinderCondition[] = [
    { payment_type: GlobalPaymentType.PREAUTHORIZATION, status: 'pending' },
    { payment_type: GlobalPaymentType.AUTHORIZATION, status: 'pending' },
  ]

  if (transactionFinder.hasAny(reservedCondition1_2)) {
    return {
      status: 'reserved',
      transaction: transactionFinder.getAny(reservedCondition1_2),
    }
  }

  // unknown
  return { status: 'unknown', transaction: null }
}
