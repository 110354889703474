export default {
  title: {
    display: 'flex',
    flexFlow: 'wrap',
  },
  text: {
    display: 'inline-block',
    fontWeight: '400',
  },
  textOverflow: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  copy: {
    marginLeft: 4,
  },
}
