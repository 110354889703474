/**
 * Content component getting suggested voucher values from auto-comp
 * renders CompensationModalView with props depending on auto-comp result
 * */

// libs
import React, { useState, useEffect, useContext, useImperativeHandle, useMemo } from 'react'

// contexts and types
import { DataContext } from 'contexts/data/DataContext'

// components
import CompensationModalView from './CompensationModalView'
import { useSessionState } from 'hooks/useSessionState'
import {
  GetProposedCompensationApiResponse,
  getProposedCompensationValue,
} from 'services/VoucherApi/getProposedCompensationValue'
import { AvailableCompensationMethods } from 'entityConfig/allowedConfigValues'
import { Compensation, CompensationMethod, IssueType } from 'contexts/entity/types'

import { getOrderPurchaseDetails } from 'services/paymentApi/getOrderPurchaseDetails'
import { useApiService } from 'hooks/useApiService'
import { DataAction } from 'contexts/data/types'
import { EMPTY_CATCH_CALLBACK } from 'constants/constants'
import { useCaptureUserAction } from 'hooks/events/useCaptureUserAction'
import { Result } from 'antd'
import { useTranslation } from 'hooks/useTranslation'
import { createCompensationToWallet } from 'services/paymentApi/createCompensationToWallet'
import { createVoucher } from 'services/VoucherApi/createVoucher'
import { createCompensationStartAndEndDates } from 'utils/createCompensationStartAndEndDates'
import { ORDER_ID_PLACEHOLDER } from 'constants/translation'
import { useClearCommentsFromDataContext } from 'hooks/useClearCommentsFromDataContext'
import fixNumber from 'utils/fixNumber'
import { useSendEventToEvts } from 'hooks/events/useSendEventToEvts'
import { createPluggableWidget } from 'factory/createPluggableWidget'

export const CompensationView = createPluggableWidget<{
  compensation: Compensation
  useCcrWhitelistForCompensationIssues: boolean
}>(
  ({ onQuit, config, order, ccrCode, sdk }, ref) => {
    // pull orderId & globalentity ID from session context
    const { orderId, userId, globalEntityId, caseId, platform } = useSessionState()

    const captureUserAction = useCaptureUserAction()
    const sendEventToEvts = useSendEventToEvts()

    const { t } = useTranslation()

    // pull compensation values per entity from context
    const {
      availableIssueTypes,
      minVoucherValue,
      maxVoucherValue,
      available_compensation_methods,
      voucherValidity,
      voucher_payment_types,
      descriptionPrefix,
      isMinOrderValueStatic,
    } = config.compensation

    const {
      SET_RECOMMENDED_COMPENSATION,
      SET_AUTO_COMPENSATION_COMMENT,
      SET_RECEIVED_COMPENSATION_SUGGESTIONS,
      SET_REFUND_TO_WALLET_OR_PSP_DETAILS,
    } = DataAction

    const { loadService: executeGetOrderPurchaseDetails, status: getOrderPurchaseDetailsStatus } =
      useApiService({
        service: getOrderPurchaseDetails,
        deps: [],
        autoLoad: false,
      })

    const {
      loadService: executeGetProposedCompensationValue,
      status: getProposedCompensationValueStatus,
      error: getProposedVoucherError,
      clearError: clearGetProposedVoucherError,
    } = useApiService({
      service: getProposedCompensationValue,
      deps: [],
      autoLoad: false,
    })

    const {
      loadService: executeCreateCompensationToWallet,
      status: createCompensationToWalletStatus,
      error: createCompensationToWalletError,
      clearError: clearCreateCompensationToWalletrError,
      loading: compensatingToWallet,
    } = useApiService({
      service: createCompensationToWallet,
      autoLoad: false,
      deps: [],
    })

    const {
      loadService: executeCreateVoucher,
      status: createVoucherStatus,
      error: createVoucherError,
      clearError: clearCreateVoucherError,
      loading: creatingVoucher,
    } = useApiService({
      service: createVoucher,
      autoLoad: false,
      deps: [],
    })

    const {
      dataState: { currency, recommendedCompensation, customerExploitStatus },
      dataDispatch,
    } = useContext(DataContext)

    const clearCommentsFromDataContext = useClearCommentsFromDataContext()

    const [selectedIssueType, setSelectedIssueType] = useState<IssueType>(null)
    const [voucherValue, setVoucherValue] = useState(minVoucherValue)

    const [compensationMethods, setCompensationMethods] = useState<CompensationMethod[]>(
      available_compensation_methods || [],
    )

    const [proposedCompensation, setProposedCompensation] =
      useState<GetProposedCompensationApiResponse>(null)

    const [allowVoucherEdit, setAllowVoucherEdit] = useState(true)
    const [selectedCompensationMethod, setSelectedCompensationMethod] = useState(
      compensationMethods[0],
    )
    const [createdVoucher, setCreatedVoucher] = useState(null)

    const { paymentTypes, beginDate, endDate } = useMemo(() => {
      let validityBeginDate
      let validityEndDate

      if (voucherValidity?.value) {
        const { nowText, laterText } = createCompensationStartAndEndDates(
          voucherValidity.value,
          voucherValidity.unit,
        )

        validityBeginDate = nowText
        validityEndDate = laterText
      }

      if (selectedIssueType?.use_auto_comp_proposed_validity_dates && proposedCompensation) {
        validityEndDate = proposedCompensation.expired_at
      }

      return {
        paymentTypes: voucher_payment_types.length
          ? voucher_payment_types.map((current) => current.display_name).join(', ')
          : t(
              'Actions Widget.Actions.Compensation.This voucher is applicable for all payment types',
            ),

        beginDate: validityBeginDate,
        endDate: validityEndDate,
      }
    }, [voucher_payment_types, t, voucherValidity, proposedCompensation, selectedIssueType])

    //effect determining if API allows compensation to wallet
    useEffect(() => {
      if (!available_compensation_methods.length) {
        return
      }

      // check if entity has wallet compensation enabled -> if so, call purchase endpoint to see if API allows wallet comp
      const walletCompensationFound = available_compensation_methods.find(
        (method: CompensationMethod) => method.method === AvailableCompensationMethods.wallet,
      )

      if (!walletCompensationFound) {
        return
      }

      executeGetOrderPurchaseDetails({
        entityId: globalEntityId,
        orderId,
        customerId: userId,
      })
        .then(({ data }) => {
          if (!data.compensation_targets?.wallet?.allowed) {
            const methods = available_compensation_methods.filter(
              (current) => current.method !== AvailableCompensationMethods.wallet,
            )

            setCompensationMethods(methods)
            setSelectedCompensationMethod(methods[0])
          }
        })
        .catch(EMPTY_CATCH_CALLBACK)
    }, [
      orderId,
      available_compensation_methods,
      executeGetOrderPurchaseDetails,
      globalEntityId,
      userId,
    ])

    /**
     * when an issue type is selected
     * @param index
     */
    const onIssueTypeSelected = (issueType: IssueType) => {
      setSelectedIssueType(issueType)
      setVoucherValue(issueType.minVoucherValue || minVoucherValue)
      setAllowVoucherEdit(true)

      captureUserAction('ActionsCompensationIssueTypeSelected', {
        eventDetails: { issue_type: issueType.issue },
      })

      clearGetProposedVoucherError()
      dataDispatch({
        type: SET_RECOMMENDED_COMPENSATION,
        payload: {
          recommendedCompensation: null,
        },
      })

      if (!issueType.useGetProposedValue) {
        return
      }

      executeGetProposedCompensationValue({
        entityId: globalEntityId,
        customerId: userId,
        issueType: issueType,
        orderId,
        totalOrderValue: order?.order?.order_value,
        damagedValue: order?.order?.order_value,

        clientParams: {
          context: 'Compensation',
        },
      })
        .then(({ data }) => {
          dataDispatch({
            type: SET_RECOMMENDED_COMPENSATION,
            payload: {
              recommendedCompensation: data,
            },
          })
          setVoucherValue(data.value)
          setProposedCompensation(data)
          setAllowVoucherEdit(false)
        })
        .catch(EMPTY_CATCH_CALLBACK)
    }

    const voucherDescription = descriptionPrefix.replace(ORDER_ID_PLACEHOLDER, orderId)
    let minOrderValue = 0

    if (selectedIssueType?.use_auto_comp_proposed_min_order_value && proposedCompensation) {
      minOrderValue = proposedCompensation.minimum_order_value
    } else if (isMinOrderValueStatic) {
      minOrderValue = config.compensation.minOrderValue
    } else {
      minOrderValue = voucherValue + config.compensation.minOrderValue
    }

    let maxOrderValue = 0

    if (selectedIssueType?.use_auto_comp_proposed_max_order_value && proposedCompensation) {
      maxOrderValue = proposedCompensation.maximum_order_value
    }

    const processCompensation = async () => {
      const autoCompEventId: string =
        recommendedCompensation?.event_id || 'manually edited by agent'
      let voucherSuccessDetails = null

      const execute = async () => {
        // set loading state
        captureUserAction('ActionsCompensationConfirmationContinueButtonClicked')

        if (selectedCompensationMethod.method === AvailableCompensationMethods.voucher) {
          return executeCreateVoucher({
            entityId: globalEntityId,
            customerId: userId,
            orderId,
            caseId,
            ccrCode: selectedIssueType.ccr_code,
            compensationPurpose: selectedIssueType.issue,
            proposalEventId: autoCompEventId,
            compensationToken: proposedCompensation?.compensation_token || '',
            platform,

            voucherDetails: {
              purpose: 'compensate',
              beginDate,
              endDate,
              channel: 'channel_customer_service',
              currency,
              description: voucherDescription,
              minOrderValue,
              maxOrderValue,
              paymentTypes: voucher_payment_types,
              value: voucherValue,
            },
          }).then(({ data }) => {
            voucherSuccessDetails = data
            dataDispatch({
              type: SET_AUTO_COMPENSATION_COMMENT,
              payload: { autoCompensationComment: data },
            })
            // clean other auto comments from DataContext
            clearCommentsFromDataContext()

            // clear ato-comp values from state
            dataDispatch({
              type: SET_RECEIVED_COMPENSATION_SUGGESTIONS,
              payload: {
                receivedCompensationSuggestions: undefined,
              },
            })

            setCreatedVoucher(data as any)
          })
        }

        if (selectedCompensationMethod.method === AvailableCompensationMethods.wallet) {
          return executeCreateCompensationToWallet({
            entityId: globalEntityId,
            customerId: userId,
            orderId,
            caseId,
            ccrCode: selectedIssueType.ccr_code,
            proposalEventId: autoCompEventId,
            compensationToken: proposedCompensation?.compensation_token || '',
            compensationPurpose: selectedIssueType.issue,
            value: Number(fixNumber(voucherValue)),
            currency,
          }).then((res) => {
            // set auto comment for Compensation to Wallet to data context in order to trigger a refresh in history of events
            dataDispatch({
              type: SET_REFUND_TO_WALLET_OR_PSP_DETAILS,
              payload: { refundToWalletOrPspDetails: true },
            })

            // clean other auto comments from DataContext
            dataDispatch({
              type: SET_AUTO_COMPENSATION_COMMENT,
              payload: { autoCompensationComment: undefined },
            })

            clearCommentsFromDataContext()

            // clear ato-comp values from state
            dataDispatch({
              type: SET_RECEIVED_COMPENSATION_SUGGESTIONS,
              payload: {
                receivedCompensationSuggestions: undefined,
              },
            })

            return res
          })
        }
      }

      let failed = false

      try {
        await execute()
      } catch (ex) {
        failed = true
      }

      if (!failed) {
        sdk.eventEmitter.dispatchEvent({
          name: 'COMPENSATION_SUCCESS',
          payload: { orderId },
        })
      }

      // report event to event tracking service

      sendEventToEvts('ActionsCompensationDone', {
        eventDetails: {
          actionFailed: failed,
          issue_type: selectedIssueType?.issue,
        },
        contactDetails: {
          customer_exploit_status: customerExploitStatus,
        },
        financial: {
          paymentMedium: selectedCompensationMethod.method,
          currencyCode: currency,
          compensationValue: Number(voucherValue),
          refundValue: undefined,
          details: {
            proposed_value: recommendedCompensation ? recommendedCompensation.value : null,
            voucher_code: voucherSuccessDetails?.code,
            trigger_id: recommendedCompensation?.trigger_id,
          },
        },
      })
    }

    useImperativeHandle(
      ref,
      () => {
        return {
          onXButtonClick: () => {
            return (
              selectedIssueType === null ||
              createCompensationToWalletStatus === 'success' ||
              createVoucherStatus === 'success'
            )
          },
        }
      },
      [selectedIssueType, createCompensationToWalletStatus, createVoucherStatus],
    )

    if (compensationMethods.length === 0) {
      return (
        <Result
          status='error'
          title={t('errors.no_compensation_methods_configured.title')}
          subTitle={t('errors.no_compensation_methods_configured.description')}
        />
      )
    }

    return (
      <CompensationModalView
        isVerifyingRefundMethods={getOrderPurchaseDetailsStatus === 'loading'}
        isLoadingProposedValue={getProposedCompensationValueStatus === 'loading'}
        // order value constraints
        minOrderValue={minOrderValue}
        // is loading proposed voucher value
        getProposedVoucherError={getProposedVoucherError}
        onQuit={onQuit}
        // issue types
        issueTypes={availableIssueTypes}
        // selected issue type index
        selectedIssueType={selectedIssueType}
        setSelectedIssueType={onIssueTypeSelected}
        compensationMethods={compensationMethods}
        // min and max voucher values
        minVoucherValue={minVoucherValue}
        maxVoucherValue={maxVoucherValue}
        // voucher value
        voucherValue={voucherValue}
        setVoucherValue={setVoucherValue}
        allowVoucherEdit={allowVoucherEdit}
        selectedCompensationMethod={selectedCompensationMethod}
        setSelectedCompensationMethod={setSelectedCompensationMethod}
        creatingVoucher={creatingVoucher}
        compensatingToWallet={compensatingToWallet}
        clearErrors={() => {
          clearCreateCompensationToWalletrError()
          clearCreateVoucherError()
        }}
        processCompensation={processCompensation}
        errorPayload={
          createCompensationToWalletError?.errorPayload || createVoucherError?.errorPayload
        }
        createdVoucher={createdVoucher}
        createCompensationToWalletStatus={createCompensationToWalletStatus}
        createVoucherStatus={createVoucherStatus}
        useCcrWhitelistForCompensationIssues={config.useCcrWhitelistForCompensationIssues}
        ccrCode={ccrCode}
        paymentTypes={paymentTypes}
        voucherBeginDate={beginDate}
        voucherEndDate={endDate}
      />
    )
  },
  {
    category: 'action',
    deriveConfig({ entityConfig }) {
      return {
        compensation: entityConfig.fixed_panel_config.widgets_configs.actions.compensation,
        useCcrWhitelistForCompensationIssues: entityConfig.useCcrWhitelistForCompensationIssues,
      }
    },
  },
)
