/**
 * expects orderStatusHistory and order from Order API; fulfillment API response; and entity's UTC offset
 * returns a status history arr and delivery time objs with all UTC modified for the entity
 * */

import { isFuture, differenceInMinutes } from 'date-fns'
import modifyUTC from 'utils/modifyUTC'
import { Times } from 'types/dataTransformers/orderStatus'
import { OrderApiResponse } from 'types/api/orderApi/order'
import {
  CourierEventItem,
  DeliveryItem,
  FulfillmentApiResponse,
} from 'types/api/fulfillmentApi/fulfillment'
import { RiderStatuses } from 'types/widgets/rider/riderStatuses'
import { allowedDataPointValues } from 'entityConfig/allowedConfigValues'
import { useCallback } from 'react'
import { useIsDataPointValid } from 'hooks/useGetValidFeatures'
import { useTranslation } from 'hooks/useTranslation'
import { useGetRiderStatusTextAndColor } from '../useGetRiderStatusTextAndColor'

const useConfigAllowsDisplay = () => {
  const isDataPointValid = useIsDataPointValid()
  return useCallback(
    ({
      betaRequirement = [],
      displayRule = [],
    }: {
      displayRule: string[]
      betaRequirement: string[]
    }) => {
      return (
        isDataPointValid(betaRequirement) &&
        !displayRule.includes(allowedDataPointValues.never_display)
      )
    },
    [isDataPointValid],
  )
}

export const useTransformRiderStatusHistory = () => {
  const configAllowsDisplay = useConfigAllowsDisplay()
  const getDeliveryStatusTagParams = useGetRiderStatusTextAndColor()

  const { t } = useTranslation()
  return useCallback(
    (
      delivery: DeliveryItem,
      fulfillment: FulfillmentApiResponse,
      order: OrderApiResponse,
      utc_zone: string,
      statusConfig: any,
    ) => {
      // time now
      const now = new Date()

      const riderEvents = delivery.courier_events || []

      // returned data structure
      const transformedHistory = {
        statusHistory: [],
        promisedDropoffTime: {
          value: t(`Order Widget.Tabs.Status.Order Statuses.${Times.dropOff}`),
          date: '',
          isInFuture: true,
          thirtyMinsOrLongerInThePast: false,
        },
        estimatedDropoffTime: {
          value: t(`Order Widget.Tabs.Status.Order Statuses.${Times.estDeliver}`),
          date: '',
          timeDifference: '',
        },
        estimatedPickupTime: {
          value: t(`Order Widget.Tabs.Status.Order Statuses.${Times.estPick}`),
          date: '',
        },
      }

      // map over statuses and push them to transformedHistory.statusHistory
      riderEvents.forEach((event: CourierEventItem) => {
        const { text } = getDeliveryStatusTagParams(event.name)

        transformedHistory.statusHistory.push({
          value: text,
          date: modifyUTC(event.timestamp, utc_zone),
        })
      })

      const estimatedPickUpTime = delivery?.latest_estimated_pickup_time?.arriving_at
      const promisedDropOffTime =
        fulfillment?.promised_delivery_at || order?.promised_customer_timestamp || ''
      let estimatedDropOffTime = delivery?.latest_estimated_dropoff_time?.arriving_at

      let isPromisedDropOffTimeInFuture = false
      let isPromisedDropOffTimeLaterThan30mins = false

      // use model_prediction_median_bound_minutes if it exists
      if (fulfillment?.model_prediction_median_bound_minutes) {
        estimatedDropOffTime = new Date(
          Date.now() + fulfillment?.model_prediction_median_bound_minutes * 60 * 1000,
        ).toUTCString()
      }

      // set in future and later than 30 minutes
      if (promisedDropOffTime) {
        isPromisedDropOffTimeInFuture = isFuture(new Date(promisedDropOffTime))
        isPromisedDropOffTimeLaterThan30mins =
          differenceInMinutes(now, new Date(promisedDropOffTime)) >= 30
      }

      // update properties on return obj
      transformedHistory.promisedDropoffTime.isInFuture = isPromisedDropOffTimeInFuture
      transformedHistory.promisedDropoffTime.thirtyMinsOrLongerInThePast =
        isPromisedDropOffTimeLaterThan30mins

      // update the time diff between estimated drop off time and promised drop off time
      if (estimatedDropOffTime && promisedDropOffTime) {
        const minuteDiffBetweenEstimatedVsPromisedDropOffTime = differenceInMinutes(
          new Date(estimatedDropOffTime),
          new Date(promisedDropOffTime),
        )

        if (minuteDiffBetweenEstimatedVsPromisedDropOffTime < 60) {
          transformedHistory.estimatedDropoffTime.timeDifference = `${t(
            'Order Widget.Tabs.Status.Delayed',
          )}: ${minuteDiffBetweenEstimatedVsPromisedDropOffTime} ${t(
            'Order Widget.Tabs.Status.Minute',
          )}`
        } else {
          const minutes = minuteDiffBetweenEstimatedVsPromisedDropOffTime % 60
          const hours = Math.floor(minuteDiffBetweenEstimatedVsPromisedDropOffTime / 60)

          transformedHistory.estimatedDropoffTime.timeDifference = `${t(
            'Order Widget.Tabs.Status.Delayed',
          )}: ${hours} hrs, ${minutes} mins`
        }
      }

      // set estimated pick up and drop off time
      transformedHistory.estimatedPickupTime.date = modifyUTC(estimatedPickUpTime, utc_zone)
      transformedHistory.promisedDropoffTime.date = modifyUTC(promisedDropOffTime, utc_zone)
      transformedHistory.estimatedDropoffTime.date = modifyUTC(estimatedDropOffTime, utc_zone)

      transformedHistory.estimatedPickupTime.date = modifyUTC(
        delivery?.latest_estimated_pickup_time?.arriving_at,
        utc_zone,
      )

      const lastRiderStatus = riderEvents[0]

      transformedHistory.statusHistory.reverse()

      const deliveryIsInProgress = ![
        RiderStatuses.canceled,
        RiderStatuses.delivered,
        RiderStatuses.rejected,
      ].includes(lastRiderStatus?.name as any)

      if (
        estimatedPickUpTime &&
        !riderEvents.find(({ name }) => name === RiderStatuses.pickedUp) &&
        configAllowsDisplay(statusConfig.estimated_pickup_time)
      ) {
        transformedHistory.statusHistory.push(transformedHistory.estimatedPickupTime)
      }

      if (
        estimatedDropOffTime &&
        deliveryIsInProgress &&
        configAllowsDisplay(statusConfig.estimated_dropoff_time)
      ) {
        transformedHistory.statusHistory.push(transformedHistory.estimatedDropoffTime)
      }

      if (
        promisedDropOffTime &&
        deliveryIsInProgress &&
        configAllowsDisplay(statusConfig.promised_dropoff_time)
      ) {
        transformedHistory.statusHistory.push(transformedHistory.promisedDropoffTime)
      }

      return { transformedHistory }
    },
    [t, configAllowsDisplay, getDeliveryStatusTagParams],
  )
}
