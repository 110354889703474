import environment, { ApiMocks } from 'envConfig'
import { ApiService } from 'services/authApi/getPermissions'
import { FulfillmentApiRiderResponse } from 'types/api/riderApi/rider'
import mockData from './mock.json'

export const getRider: ApiService<
  {
    entityId: string
    riderId: string
  },
  FulfillmentApiRiderResponse
> = (createClient, options) => {
  const { entityId, riderId, clientParams } = options

  const uri = `/FulfillmentAPI/v1/couriers/${entityId}/${riderId}`

  return createClient({
    endpointName: 'getRider',
    expectedResponseStatusCode: 200,
    mockedResponse: environment().apiMocks.has(ApiMocks.getRider)
      ? { data: mockData.data as FulfillmentApiRiderResponse }
      : null,
    ...clientParams,
  }).get(uri)
}
