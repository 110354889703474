import React from 'react'
import { createCustomSvgIcon } from 'factory/createCustomSvgIcon'

export const WeightIcon = createCustomSvgIcon({
  viewBoxWidth: 14,
  viewBoxHeight: 14,
  content: (
    <>
      <path
        d='M13.0938 7.27344L11.3438 2.89844C11.3053 2.80207 11.2338 2.72254 11.142 2.67405C11.0502 2.62557 10.9442 2.61128 10.8429 2.63375L7.43751 3.39062V2.1875C7.43751 2.07147 7.39142 1.96019 7.30937 1.87814C7.22732 1.79609 7.11604 1.75 7.00001 1.75C6.88398 1.75 6.7727 1.79609 6.69065 1.87814C6.6086 1.96019 6.56251 2.07147 6.56251 2.1875V3.58641L2.96735 4.38539C2.89804 4.4007 2.83349 4.43265 2.77927 4.47848C2.72506 4.52431 2.68282 4.58264 2.65618 4.64844V4.65172L0.90618 9.02344C0.885245 9.07558 0.874658 9.13131 0.875008 9.1875C0.875008 10.4623 2.21704 10.9375 3.06251 10.9375C3.90798 10.9375 5.25001 10.4623 5.25001 9.1875C5.25036 9.13131 5.23977 9.07558 5.21884 9.02344L3.6597 5.12805L6.56251 4.48438V11.375H5.68751C5.57148 11.375 5.4602 11.4211 5.37815 11.5031C5.2961 11.5852 5.25001 11.6965 5.25001 11.8125C5.25001 11.9285 5.2961 12.0398 5.37815 12.1219C5.4602 12.2039 5.57148 12.25 5.68751 12.25H8.31251C8.42854 12.25 8.53982 12.2039 8.62187 12.1219C8.70392 12.0398 8.75001 11.9285 8.75001 11.8125C8.75001 11.6965 8.70392 11.5852 8.62187 11.5031C8.53982 11.4211 8.42854 11.375 8.31251 11.375H7.43751V4.28859L10.2266 3.66953L8.78118 7.27344C8.76025 7.32558 8.74966 7.38131 8.75001 7.4375C8.75001 8.71227 10.092 9.1875 10.9375 9.1875C11.783 9.1875 13.125 8.71227 13.125 7.4375C13.1254 7.38131 13.1148 7.32558 13.0938 7.27344ZM3.06251 10.0625C2.65071 10.0625 1.81782 9.86508 1.75384 9.26188L3.06251 5.99047L4.37118 9.26188C4.3072 9.86508 3.47431 10.0625 3.06251 10.0625ZM10.9375 8.3125C10.5257 8.3125 9.69282 8.11508 9.62884 7.51188L10.9375 4.24047L12.2462 7.51188C12.1822 8.11508 11.3493 8.3125 10.9375 8.3125Z'
        fill='currentColor'
        fillOpacity='0.55'
      />
    </>
  ),
})
