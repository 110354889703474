import { Modal, Typography } from 'antd'
import React, { FC, useEffect, useLayoutEffect, useRef } from 'react'
import { createUseStyles } from 'react-jss'
import { SideDrawer } from '../SideDrawer'
import { ClickAwayListener } from '../ClickAwayListener'
import { WidgetContainerType } from 'types'
import { SIDEDRAWER_WIDTH } from 'constants/constants'

const useStyles = createUseStyles({
  container: (opts: { maxWidth: string | number }) => {
    return {
      maxWidth: opts.maxWidth || undefined,
    }
  },
  titleHolder: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#1295e6',
    padding: 20,
    margin: -24,
  },
  modalTitle: {
    fontSize: '16px',
    color: '#fff',
    marginTop: '7px',
  },
})

export interface PopupWidgetContainerProps {
  widgetContainerType: WidgetContainerType

  title: string

  width?: number | string
  maxWidth?: number | string

  visible?: boolean

  /**
   * if given
   * there will be a discard button in a side drawer container and called when it gets clicked
   *
   * for modal, it will be called when the x button is clicked if given,
   * else onClose will be called.
   * @returns
   */
  onClose?: () => void

  /**
   * this is called when the x button of side drawer container is clicked,
   *
   * for modal containers (x button is same as close), onClose is called if given,
   * else this is called
   * @returns
   */
  onHide?: () => void
}

export const PopupWidgetContainer: FC<PopupWidgetContainerProps> = ({
  widgetContainerType,
  visible,
  title,
  width,
  maxWidth,
  onClose,
  onHide,
  children,
  ...props
}) => {
  const sideDrawerContainerRef = useRef<HTMLDivElement>()

  const classes = useStyles({ maxWidth })

  // when visibility changes
  useLayoutEffect(() => {
    if (visible) {
      document.body.classList.add('stop-scroll')
    } else {
      document.body.classList.remove('stop-scroll')
    }
  }, [visible])

  // when unmounted
  useEffect(() => {
    return () => {
      document.body.classList.remove('stop-scroll')
    }
  }, [])

  /**
   * this forces the modal or side drawer to be rendered inline
   * @returns
   */
  const getSideDrawerContainer = () => sideDrawerContainerRef.current

  switch (widgetContainerType) {
    case 'modal':
      return (
        <ClickAwayListener
          listen={false}
          onClickAway={onHide || onClose}
          ref={sideDrawerContainerRef}
          {...props}
        >
          <Modal
            visible={visible}
            centered
            width={width || 650}
            className={classes.container}
            title={
              <div className={classes.titleHolder}>
                <Typography.Text strong className={classes.modalTitle}>
                  {title}
                </Typography.Text>
              </div>
            }
            onCancel={onClose || onHide}
            footer={null}
            maskStyle={{ backgroundColor: '#04294a', opacity: 0.94 }}
            maskClosable={false}
            getContainer={getSideDrawerContainer}
          >
            {children}
          </Modal>
        </ClickAwayListener>
      )

    default:
      return (
        <ClickAwayListener
          onClickAway={onHide}
          listen={visible}
          ref={sideDrawerContainerRef}
          {...props}
        >
          <SideDrawer
            placement='right'
            visible={visible}
            title={title}
            destroyOnClose={false}
            onClose={onClose}
            onHide={onHide}
            getContainer={getSideDrawerContainer}
            width={width || SIDEDRAWER_WIDTH}
          >
            {children}
          </SideDrawer>
        </ClickAwayListener>
      )
  }
}
