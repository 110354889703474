// libs
import { useEffect, useRef, useCallback } from 'react'
import { AxiosResponse } from 'axios'
// utils
import {
  ApiService,
  ApiServiceParams,
  FulFilledApiResponse,
  RejectedApiResponse,
} from 'services/authApi/getPermissions'
import { useForceUpdate } from '../contexts/useForceUpdate'
import { useApiClientCreator } from 'contexts/apiClientCreator/ApiClientCreatorContext'
import { ApiErrorPayload } from 'types/error'
import { LoadState, PromiseValue } from 'types'
import { useUpdatedRef } from './useUpdatedRef'
import { sleep } from 'utils/sleep'
import { EMPTY_CATCH_CALLBACK } from 'constants/constants'
import { UnmountError } from 'Errors/UnmountError'

export const useApiService = <ParamsType, DataType = {}>(options: {
  /**
   * the service
   */
  service: ApiService<ParamsType, DataType>

  /**
   * service parameters
   */
  params?: ApiServiceParams<ParamsType, DataType>

  /**
   * if true, it will auto load for the first time and when data changes.
   */
  autoLoad?: boolean

  /**
   * if true, it will delete already loaded data
   * before starting to execute new load call, default is true
   *
   * if false, existing loaded data will not be cleared, and refreshing will be true,
   * while loading will be false
   */
  clearDataBeforeLoad?: boolean

  /**
   * boolean that determines if the request can be performed
   */
  shouldLoad?: boolean

  /**
   * amount of seconds to delay request, good for simulating
   */
  delayRequest?: number

  /**
   * deps is an array of values that if it changes, the hook is retriggered
   * and a refetch is made.
   *
   * by default, all api service params are turned as dependencies
   */
  deps?: Array<any>

  /**
   * called the request succeeds
   */
  onSuccess?: (res: FulFilledApiResponse<DataType>) => void

  /**
   * called when the request errors out
   */
  onError?: (res: RejectedApiResponse<DataType>) => void

  /**
   * called after onSuccess/onError with an object parameter that contains
   * an outcome field
   */
  onAfterLoad?: (
    params:
      | {
          outcome: 'success'
          res: PromiseValue<ReturnType<ApiService<ParamsType, DataType>>>
        }
      | {
          outcome: 'error'
          res: {
            response: PromiseValue<ReturnType<ApiService<ParamsType, DataType>>>
            errorPayload: ApiErrorPayload
          }
        },
  ) => void

  /**
   * called before a load operation is carried.
   */
  onBeforeLoad?: (opts: { isFirstLoad: boolean }) => Promise<any> | void

  /**
   * allows you to transform the response data
   */
  transformResponse?: (res: FulFilledApiResponse<DataType>) => FulFilledApiResponse<DataType>
}) => {
  const currentRequestRef = useRef<ReturnType<typeof options.service>>(null)
  const updatedOptionsRef = useUpdatedRef(options)
  const { autoLoad = true, delayRequest = 0, deps = [], shouldLoad = true, params = {} } = options

  const stateRef = useRef<{
    status: LoadState
    data: DataType
    error: {
      response: AxiosResponse<any>
      errorPayload: ApiErrorPayload
    }
  }>({
    status: !shouldLoad ? 'disabled' : autoLoad ? 'loading' : 'default',
    data: null,
    error: null,
  })

  const internalStateRef = useRef({
    loadSuccessCount: 0,
    unmounted: false,
    lastRequestId: 0,
  })

  const forceUpdate = useForceUpdate()
  const { createClient } = useApiClientCreator()

  const clearError = useCallback(() => {
    if (stateRef.current.error) {
      stateRef.current.status = 'default'
      stateRef.current.error = null
      forceUpdate()
    }
  }, [forceUpdate])

  const loadService = useCallback<
    (overrideParams?: Partial<typeof options.params>) => ReturnType<typeof options.service>
  >(
    async (newParams) => {
      const {
        params,
        service,
        onBeforeLoad,
        clearDataBeforeLoad = true,
      } = updatedOptionsRef.current

      const { unmounted } = internalStateRef.current

      if (unmounted) {
        return currentRequestRef.current
      }

      const id = ++internalStateRef.current.lastRequestId
      stateRef.current.error = null

      if (clearDataBeforeLoad) {
        stateRef.current.data = null
      }

      stateRef.current.status = stateRef.current.data === null ? 'loading' : 'refreshing'

      if (delayRequest) {
        await sleep(delayRequest)
      }

      await onBeforeLoad?.({
        isFirstLoad: id === 1,
      })

      forceUpdate()

      currentRequestRef.current = service(createClient, {
        ...params,
        ...newParams,
      })
        .then((res) => {
          const { onSuccess, onAfterLoad, transformResponse } = updatedOptionsRef.current

          if (transformResponse) {
            res = transformResponse(res)
          }

          if (unmounted) {
            return Promise.reject(new UnmountError())
          }

          if (internalStateRef.current.lastRequestId !== id) {
            return res
          }

          onSuccess?.(res)

          onAfterLoad?.({
            outcome: 'success',
            res,
          })

          ++internalStateRef.current.loadSuccessCount
          stateRef.current.data = res.data
          stateRef.current.status = 'success'

          forceUpdate()

          return res
        })
        .catch((ex) => {
          if (unmounted) {
            return Promise.reject(new UnmountError())
          }

          if (internalStateRef.current.lastRequestId !== id) {
            return Promise.reject(ex)
          }

          const { onError, onAfterLoad } = updatedOptionsRef.current

          onError?.(ex)
          onAfterLoad?.({
            outcome: 'error',
            res: ex,
          })

          stateRef.current.error = ex
          stateRef.current.status = 'error'

          forceUpdate()

          return Promise.reject(ex)
        })

      return currentRequestRef.current
    },
    [createClient, delayRequest, forceUpdate, updatedOptionsRef],
  )

  const dependencies = [
    ...Object.keys(params)
      .filter((key) => {
        return key !== 'clientParams'
      })
      .map((key) => params[key]),
    ...deps,
  ]

  useEffect(() => {
    if (!shouldLoad || !autoLoad) {
      return
    }

    loadService().catch(EMPTY_CATCH_CALLBACK)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autoLoad, shouldLoad, loadService, ...dependencies])

  // record when unmount happens
  useEffect(() => {
    const internalState = internalStateRef.current
    return () => {
      internalState.unmounted = true
    }
  }, [])

  const { data, error, status } = stateRef.current

  return {
    loading: status === 'loading',
    data,
    error,
    loadService,
    clearError,
    status,
    refreshing: status === 'refreshing',
    loadDisabled: status === 'disabled',
  }
}
