import React, { MouseEventHandler } from 'react'
import { Col, Row } from 'antd'
import { createUseStyles } from 'react-jss'
import { DownOutlined, UpOutlined } from '@ant-design/icons'
import { useTranslation } from 'hooks/useTranslation'
import { Text } from 'shared'

const useStyles = createUseStyles({
  toggleBtn: {
    cursor: 'pointer',
  },
})

type Props = {
  isExpanded: boolean
  onClick?: MouseEventHandler
}

export const ExpandOrCollapseButton = ({ isExpanded, onClick }: Props) => {
  const { t } = useTranslation()
  const styles = useStyles()

  return (
    <Text.Secondary onClick={onClick} className={styles.toggleBtn}>
      <Row align='middle' gutter={[5, 0]}>
        <Col>{isExpanded ? t('label.show less') : t('label.show more')}</Col>
        <Col>{isExpanded ? <UpOutlined /> : <DownOutlined />}</Col>
      </Row>
    </Text.Secondary>
  )
}
