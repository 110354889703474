/**
 * Component to render an icon with a corresponding label
 */

import React from 'react'
import { createUseStyles } from 'react-jss'

type LabelledIconProps = {
  icon?: React.ReactNode
  iconColor?: string
  label?: string
  labelColor?: string
}

const useStyles = createUseStyles({
  container: {
    display: 'inline-flex',
    gap: '5px',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    fontSize: '13px',
  },
  iconWrapper: {
    color: ({ iconColor }: LabelledIconProps) => iconColor,
  },
  labelWrapper: {
    color: ({ labelColor }: LabelledIconProps) => labelColor,
  },
})

export const LabelledIcon: React.FC<LabelledIconProps> = ({
  icon,
  iconColor,
  label,
  labelColor,
}) => {
  const classes = useStyles({ iconColor, labelColor })
  return (
    <span className={classes.container}>
      {Boolean(icon) && <span className={classes.iconWrapper}>{icon}</span>}
      {Boolean(label) && <span className={classes.labelWrapper}>{label}</span>}
    </span>
  )
}
