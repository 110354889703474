/**
 * Summary tab for Vendor widget
 * renders 2 lists as columns for available data for entity
 * */

// libs
import React, { useState, useEffect, useContext } from 'react'
// configs
import { allowedDataPointValues } from 'entityConfig/allowedConfigValues'
// contexts and types
import { DataContext } from 'contexts/data/DataContext'
import { TransformedDataPoint, TranformedTags } from 'types/dataTransformers/generic'
import { VendorWidgetSummary } from 'contexts/entity/types'
// hooks
import { useTranslation } from 'hooks/useTranslation'
import { useNewDesign } from 'hooks/useNewDesign'
// utils
import transformVendorSummary, {
  PhoneNumbersColumnContent,
} from 'utils/dataTransformers/transformVendorSummary'
import classnames from 'classnames'

// styles
import { createUseStyles } from 'react-jss'
import styles from './Summary.styles'
import { List } from 'antd'
// components
import Tags from './Tags'
import AnchorView from 'components/AnchorView'
import PhoneNumbers from './PhoneNumbers/PhoneNumbers'
import { useIsDataPointValid } from 'hooks/useGetValidFeatures'
import { useEntityConfig } from '../../../hooks/useEntityConfig'
import { getOrderEligibilities } from '../../../utils/order/getOrderEligibilities'
import { useCaptureUserAction } from 'hooks/events/useCaptureUserAction'

const useStyles = createUseStyles(styles)

type VendorSummaryProps = {
  summary: VendorWidgetSummary
}

const Summary: React.FC<VendorSummaryProps> = ({ summary }) => {
  const classes = useStyles()

  // pull language content
  const { t } = useTranslation()
  const captureUserAction = useCaptureUserAction()

  // pull data context
  const {
    dataState: { vendor, order },
  } = useContext(DataContext)

  const {
    entityConfig: { enableVendorEligibilities },
  } = useEntityConfig()

  // transform data for the tab's 2 columns
  const [leftColumn, setLeftColumn] = useState<Array<TransformedDataPoint>>()
  const [rightColumn, setRightColumn] = useState<PhoneNumbersColumnContent[]>()
  const [tags, setTags] = useState<TranformedTags[]>()
  const isDataPointValid = useIsDataPointValid()

  const { isNewDesignAllowed } = useNewDesign()

  useEffect(() => {
    const { vendorEligibilities } = getOrderEligibilities({ order })
    if (vendor) {
      const { transformedLeftColumnData, transformedRightColumnData, tagsData } =
        transformVendorSummary(
          vendor,
          t,
          !summary.multiple_vendor_phone_numbers.displayRule.includes(
            allowedDataPointValues.never_display,
          ),
          summary.multiple_vendor_phone_numbers.phoneNumberTypes,
        )
      setLeftColumn(transformedLeftColumnData)
      setRightColumn(transformedRightColumnData)
      const eligibilities: TranformedTags[] = enableVendorEligibilities ? vendorEligibilities : []
      setTags([...eligibilities, ...tagsData])
    }
  }, [
    order,
    vendor,
    t,
    enableVendorEligibilities,
    summary.multiple_vendor_phone_numbers.displayRule,
    summary.multiple_vendor_phone_numbers.phoneNumberTypes,
  ])

  const handleVendorFELinkClickedCallback = (vendorName: string | number) => {
    captureUserAction('VendorSummaryFELinkClicked', {
      eventDetails: {
        vendor_name: vendorName,
      },
    })
  }

  return (
    <div className={classes.summaryContainer}>
      <div className={classes.listsContainer}>
        <div className={classes.listHolder}>
          <List
            className={classnames({ [classes.list]: isNewDesignAllowed })}
            size='small'
            itemLayout='horizontal'
            dataSource={leftColumn}
            renderItem={(item: TransformedDataPoint) => {
              if (item.key === t('Vendor Widget.Vendor Name')) {
                return (
                  <List.Item>
                    <List.Item.Meta
                      title={
                        <AnchorView
                          text={item.value as string}
                          href={vendor.vendor_detail_page_slug}
                          target='_blank'
                          clickCallBack={() => {
                            handleVendorFELinkClickedCallback(item.value as string)
                          }}
                        />
                      }
                      description={item.key}
                    />
                  </List.Item>
                )
              } else {
                return (
                  <List.Item>
                    <List.Item.Meta title={item.value} description={item.key} />
                  </List.Item>
                )
              }
            }}
          />
        </div>
        <div className={classes.listHolder}>
          <List
            className={classnames({ [classes.list]: isNewDesignAllowed })}
            size='small'
            itemLayout='horizontal'
            dataSource={rightColumn}
            renderItem={(item: PhoneNumbersColumnContent) => {
              const isCopyAllowed = isDataPointValid(summary.copy_to_clipboard.betaRequirement)
              return summary.copy_to_clipboard.displayRule.includes(
                allowedDataPointValues.never_display,
              ) ? null : (
                <PhoneNumbers isCopyAllowed={isCopyAllowed} phoneNumbers={item} />
              )
            }}
          />
        </div>
        <Tags tags={tags} summary={summary} />
      </div>
    </div>
  )
}

export default Summary
