import React from 'react'
import { Box, BoxStyleOptions } from '../Box'
import { SlimHtmlProps } from 'types'

export interface DotProps extends BoxStyleOptions {
  /**
   * dot size
   */
  size?: number
  /**
   * the color of the dot
   */
  color?: string
}

export const Dot = ({
  className,
  size = 8,
  color = 'green',
  Component = 'span',
  children,
  ...others
}: SlimHtmlProps<DotProps>) => {
  const dot = (
    <Box
      h={size}
      w={size}
      bgColor={color}
      borderRadius='50%'
      display='inline-block'
      {...(children ? {} : others)}
    />
  )

  if (children) {
    return (
      <Box display='inline-flex' alignItems='center' columnGap={'8px'} {...others}>
        {dot} {children}
      </Box>
    )
  }

  return dot
}
