export default {
  panelTitlesContainer: {
    display: 'flex',
    width: '100%',
    paddingLeft: '30px',
  },
  paneTitleText: {
    fontSize: '14px',
    width: '34%',
    minWidth: '25%',
  },
}
