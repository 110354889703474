import React, { useMemo } from 'react'
import { CommentTag, CommentTags } from './CommentTags'
import { CommentsSkeleton } from './CommentsSkeleton'
import { useSdk } from 'contexts/SdkProvider'
import { useLoadOrderComments } from 'hooks/apiHooks/useLoadOrderComments'
import { transformUniqueOrderComments } from 'utils/comments'

export const CommentsSummary = () => {
  const { eventEmitter } = useSdk()

  const { loading, error, data: orderComments } = useLoadOrderComments()

  const handleCommentTagClick = (tag: CommentTag) => {
    eventEmitter.dispatchEvent({
      name: 'COMMENT_TAG_CLICKED',
      payload: {
        commentId: tag.id,
      },
    })
  }

  const commentTags: CommentTag[] = useMemo(() => {
    return transformUniqueOrderComments(orderComments?.comments)
  }, [orderComments?.comments])

  if (loading) return <CommentsSkeleton />

  return (
    <CommentTags tags={commentTags} onClick={handleCommentTagClick} hasError={Boolean(error)} />
  )
}
