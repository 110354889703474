import React from 'react'
import { useTranslation } from 'react-i18next'
import capitalize from 'lodash/capitalize'
import { DataPointProps, DataPoints } from 'shared'
import type {
  IconRenderer,
  OrderApiResponse,
  VendorApiResponse,
  FulfillmentApiResponse,
  FulfillmentApiRiderResponse,
} from 'types/herocare'
import { useSdk } from 'contexts/SdkProvider'
import { BuiltinWidgetConfigs } from 'types'

type Props = {
  config: BuiltinWidgetConfigs['summary_panel']
  order: OrderApiResponse
  rider: FulfillmentApiRiderResponse
  fulfillment?: FulfillmentApiResponse
  vendor: VendorApiResponse
  IconRenderer: IconRenderer
}

const SummaryPanelDataPoints = ({ config, order, vendor, rider, fulfillment }: Props) => {
  const { t } = useTranslation()
  const sdk = useSdk()

  const orderNumber = order?.ov_meta_data?.order_number

  const dataPoints = config.data_points.map(
    ({ name, display_rules, label_translation_key }): DataPointProps => {
      switch (name) {
        case 'order_id':
          return {
            name,
            display_rules,
            type: 'default',
            value: order?.order_id,
            label: t(label_translation_key),
            postfix: orderNumber ? `#${orderNumber}` : null,
            allowCopy: true,
            copyEventName: 'COPY_ORDER_ID',
          }

        case 'payment_method':
          return {
            name,
            display_rules,
            type: 'payment_methods',
            value: sdk.getOrderPaymentMethodsTextsAndIcons({ order }),
            label: t(label_translation_key),
          }

        case 'order_status':
          return {
            name,
            display_rules,
            type: 'status',
            value: {
              ...sdk.getOrderStatusMetadata({ order }),
              delayTime: sdk.getOrderDelayTime({
                order,
                fulfillment,
                datetimeFormatter: sdk.datetimeFormatter,
              }),
            },
            label: t(label_translation_key),
          }

        case 'vertical':
          return {
            name,
            display_rules,
            type: 'default',
            value: capitalize(vendor?.vertical_type),
            label: t(label_translation_key),
          }

        case 'delivery_type':
          return {
            name,
            display_rules,
            type: 'default',
            value: capitalize(order?.delivery?.provider?.replace(/_/, ' ')),
            label: t(label_translation_key),
          }

        case 'order_tags':
          return {
            name,
            display_rules,
            type: 'tags',
            value: sdk.getOrderTags({ order, fulfillment }),
            label: t(label_translation_key),
          }

        case 'rider_id':
          return {
            name,
            display_rules,
            type: 'default',
            value: rider?.courier?.id.toString(),
            label: t(label_translation_key),
            allowCopy: true,
            copyEventName: 'COPY_RIDER_ID',
          }

        case 'rider_status': {
          const riderTotalOrders = rider?.courier?.routes?.length

          let riderStatusValuePluralized = ''
          if (riderTotalOrders <= 1) {
            riderStatusValuePluralized = ` - ${riderTotalOrders} ${t(
              'widgets.order_summary.order_assigned',
            )}`
          }
          if (riderTotalOrders > 1) {
            riderStatusValuePluralized = ` - ${riderTotalOrders} ${t(
              'widgets.order_summary.orders_assigned',
            )}`
          }

          return {
            name,
            display_rules,
            type: 'default',
            value: capitalize(rider?.courier?.courier_status) + riderStatusValuePluralized,
            label: t(label_translation_key),
          }
        }

        case 'rider_event':
          return {
            name,
            display_rules,
            type: 'status',
            value: sdk.getRiderStatusMetadata({ rider }),
            label: t(label_translation_key),
          }
        default:
          return null
      }
    },
  )

  return <DataPoints dataPoints={dataPoints} />
}

export default SummaryPanelDataPoints
