import { useApiClientCreator } from 'contexts/apiClientCreator/ApiClientCreatorContext'
import { SDK, WidgetViewState } from 'types'
import { useCallback, useMemo } from 'react'
import { useDateTimeFormatter } from 'hooks/formatters/useDateTimeFormatter'
import { useNumberFormatter } from 'hooks/formatters/useNumberFormatter'
import { useSendEventToEvts } from '../events/useSendEventToEvts'
import { useCaptureUserAction } from 'hooks/events/useCaptureUserAction'
import { useCallProxyApi } from './useCallProxyApi'
import { staticMethods } from './staticMethods'
import {
  useActivateWidgetViewProxy,
  useCheckDisplayRulesProxy,
  useGetOrderStatusMetadataProxy,
  useGetOrderStatusTextAndColorProxy,
  useGetRiderStatusTextAndColorProxy,
} from './hookProxies'
import { useGetDeliveryStatusMetadata } from 'hooks/getters/useGetDeliveryStatusMetadata'
import { useGetRiderStatusMetadata } from 'hooks/getters/useGetRiderStatusMetadata'
import { useDispatch } from './useDispatch'
import { useWidgetScreen } from 'contexts/ViewScreenProvider'
import { useTranslation } from 'hooks/useTranslation'
import { useEventEmitter } from 'hooks/useEventEmitter'
import { useGetOrderPaymentMethodsTextsAndIcons } from 'hooks/useGetOrderPaymentMethodsTextsAndIcons'
import { useEntityConfig } from 'hooks/useEntityConfig'
import { useSessionState } from 'hooks/useSessionState'
import { useGetApiVersionConfig } from 'hooks/useApiVersionConfig'
import { logError } from 'utils/reporting/logError'

export interface SdkConstructProps {
  setViewState?: (viewState: WidgetViewState) => void
}

export const useSdkConstruct = (opts?: SdkConstructProps) => {
  const { widgetId } = useWidgetScreen()
  const { caseId } = useSessionState()
  const {
    entityConfig: { layout_v2 },
  } = useEntityConfig()
  const { setViewState } = opts || {}

  const { t } = useTranslation()

  const eventEmitter = useEventEmitter(widgetId)
  const dispatch = useDispatch({ setViewState })
  const sendEtsEvent = useSendEventToEvts()
  const captureUserAction = useCaptureUserAction()

  const { createClientFactory } = useApiClientCreator()

  const checkDisplayRules = useCheckDisplayRulesProxy()
  const getOrderStatusTextAndColor = useGetOrderStatusTextAndColorProxy()
  const getOrderStatusMetadata = useGetOrderStatusMetadataProxy()

  const getRiderStatusTextAndColor = useGetRiderStatusTextAndColorProxy()

  const getDeliveryStatusMetadata = useGetDeliveryStatusMetadata()
  const getOrderPaymentMethodsTextsAndIcons = useGetOrderPaymentMethodsTextsAndIcons()
  const getRiderStatusMetadata = useGetRiderStatusMetadata()

  const datetimeFormatter = useDateTimeFormatter()
  const numberFormatter = useNumberFormatter()

  const activateWidgetView = useActivateWidgetViewProxy()

  const callProxyApi = useCallProxyApi()

  const phone_decorator = layout_v2?.phone_decorator
  const maps = layout_v2?.maps

  const getApiVersionConfig = useGetApiVersionConfig()

  const logErrorCb = useCallback<SDK['logError']>((name, opts) => {
    if (opts.error instanceof Error) {
      const { error, ...others } = opts
      logError({ type: name as any, ...others, originalError: error })
    }
  }, [])

  // widgets now use a unique api client, it does not share network cache
  // with other widgets
  const createClient = useMemo(() => {
    return createClientFactory({ widgetId })
  }, [widgetId, createClientFactory])

  const sdk = useMemo<SDK>(() => {
    return {
      hideMapByDefault: checkDisplayRules({ displayRules: maps?.hide_by_default }).enabled,
      widgetId,
      phoneDecorator: phone_decorator,
      caseId,

      eventEmitter,
      activateWidgetView,
      callProxyApi,
      captureUserAction,
      sendEtsEvent,

      checkDisplayRules,
      createApiClient: createClient,
      datetimeFormatter,
      numberFormatter,

      getApiVersionConfig,

      getOrderStatusTextAndColor,
      getOrderStatusMetadata,
      getOrderPaymentMethodsTextsAndIcons,

      getRiderStatusTextAndColor,
      getDeliveryStatusMetadata,
      getRiderStatusMetadata,

      dispatch,
      t,
      logError: logErrorCb,

      ...staticMethods,
    }
  }, [
    widgetId,
    caseId,
    phone_decorator,
    eventEmitter,
    activateWidgetView,
    callProxyApi,
    checkDisplayRules,
    createClient,
    datetimeFormatter,
    numberFormatter,

    captureUserAction,
    sendEtsEvent,

    getApiVersionConfig,
    getOrderStatusTextAndColor,
    getOrderStatusMetadata,
    getOrderPaymentMethodsTextsAndIcons,

    getRiderStatusTextAndColor,
    getDeliveryStatusMetadata,
    getRiderStatusMetadata,

    dispatch,
    t,
    maps,
    logErrorCb,
  ])

  return sdk
}
