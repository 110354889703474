import { Button, Result } from 'antd'
import React from 'react'
import { logError } from 'utils/reporting/logError'

export class ErrorBoundary extends React.Component<{}, { hasError?: boolean }> {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true }
  }

  componentDidCatch(error) {
    logError({
      originalError: error,
      type: 'white-screen-error',
      message: error.message,
      error: error.stack,
    })
  }

  render() {
    if (this.state.hasError) {
      return (
        <Result
          status={500}
          title='Fatal Error'
          subTitle='Sorry, but a fatal error has occured. Tech will look into this'
          extra={
            <Button
              type='primary'
              onClick={() => {
                window.location.reload()
              }}
            >
              Reload
            </Button>
          }
        />
      )
    }

    return <>{this.props.children}</>
  }
}
