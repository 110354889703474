import { Descriptions } from 'antd'
import React from 'react'
import { logError } from 'utils/reporting/logError'

export class WidgetErrorBoundary extends React.Component<
  {
    widgetId: string
    contactEmail: string
    supportSlackChannel: string

    onCrash?: () => void
  },
  { error: boolean }
> {
  constructor(props) {
    super(props)
    this.state = { error: false }
  }

  static getDerivedStateFromError() {
    return { error: true }
  }

  componentDidCatch(error) {
    const { widgetId, onCrash } = this.props
    logError({
      type: 'widget-error',
      widget_id: widgetId,
      error,
    })
    onCrash?.()
  }

  render() {
    const { error } = this.state
    const { contactEmail, supportSlackChannel, widgetId, children } = this.props

    if (error) {
      return (
        <div>
          <p>This View has crashed. Contact the widget ownership team for help</p>
          <Descriptions title='Details'>
            <Descriptions.Item label='WidgetId'>{widgetId}</Descriptions.Item>
            {contactEmail && (
              <Descriptions.Item label='ContactEmail'>{contactEmail}</Descriptions.Item>
            )}
            {supportSlackChannel && (
              <Descriptions.Item label='SlackChannel'>{supportSlackChannel}</Descriptions.Item>
            )}
          </Descriptions>
        </div>
      )
    } else {
      return children as any
    }
  }
}
