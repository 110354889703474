import { ApiService } from 'services/authApi/getPermissions'
import { OrderHistoryItem } from 'types/api/orderApi/order'

export const getOrderStatusHistory: ApiService<
  {
    entityId: string
    orderId: string
  },
  OrderHistoryItem[]
> = (createClient, options) => {
  const { entityId, orderId, clientParams } = options
  const uri = `OrdersAPI/v1/orders/${entityId}/${orderId}/status_history`

  return createClient({
    endpointName: 'getOrderStatusHistory',
    expectedResponseStatusCode: 200,
    ...clientParams,
  }).get(uri)
}
