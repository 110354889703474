import React, { useState } from 'react'
import fixNumber from 'utils/fixNumber'
import { createUseStyles } from 'react-jss'
import Text from 'antd/lib/typography/Text'

import styles from './Refund.styles'
import { useTranslation } from 'hooks/useTranslation'
import { DownOutlined, UpOutlined } from '@ant-design/icons'
import { Button } from 'antd'

const useStyles = createUseStyles(styles)

type Props = {
  currency: string
  voucherAmount: number
  voucherMinOrderValue: number
  voucherBeginDate: string
  voucherEndDate: string
  voucherPaymentTypes: string
}

const VoucherDetails = ({
  currency,
  voucherAmount,
  voucherEndDate,
  voucherBeginDate,
  voucherPaymentTypes,
  voucherMinOrderValue,
}: Props) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const [showVoucherDetails, setShowVoucherDetails] = useState(false)

  const handleToggleVoucherDetails = () => {
    setShowVoucherDetails(!showVoucherDetails)
  }

  return (
    <>
      <Button
        type={'text'}
        onClick={handleToggleVoucherDetails}
        className={classes.voucherDetailsBtn}
      >
        {showVoucherDetails
          ? t('Actions Widget.Actions.Partial Refund.Hide voucher details')
          : t('Actions Widget.Actions.Partial Refund.Show voucher details')}
        {showVoucherDetails ? (
          <UpOutlined className={classes.voucherDetailsBtnIcon} />
        ) : (
          <DownOutlined className={classes.voucherDetailsBtnIcon} />
        )}
      </Button>
      {showVoucherDetails && (
        <>
          <div className={classes.textBlock}>
            <Text className={classes.titleText}>{`${t('Widgets Common.Voucher Value')}:`}</Text>
            <Text className={classes.dataText}>{`${currency} ${fixNumber(voucherAmount)}`}</Text>
          </div>

          <div className={classes.textBlock}>
            <Text className={classes.titleText}>
              {`${t('Customer Widget.Tabs.Vouchers.Voucher Details.Min Order Value')}:`}
            </Text>
            <Text className={classes.dataText}>{`${currency} ${fixNumber(
              voucherMinOrderValue,
            )}`}</Text>
          </div>

          <div className={classes.textBlock}>
            <Text className={classes.titleText}>
              {`${t('Customer Widget.Tabs.Vouchers.Valid Dates')}:`}
            </Text>
            <Text className={classes.dataText}>{`${voucherBeginDate} – ${voucherEndDate}`}</Text>
          </div>

          <div className={classes.textBlock}>
            <Text className={classes.titleText}>
              {`${t('Customer Widget.Tabs.Vouchers.Voucher Details.Payment Types')}:`}
            </Text>
            <Text className={classes.dataText}>{voucherPaymentTypes}</Text>
          </div>
        </>
      )}
    </>
  )
}

export default VoucherDetails
