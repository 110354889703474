import { ApiService } from 'services/authApi/getPermissions'
import { PostItemRemovalBody } from 'types/actions/partialRefund'

export const removeOrderItems: ApiService<
  {
    entityId: string
    orderId: string
  } & PostItemRemovalBody,
  {
    id: string
  }
> = (createClient, options) => {
  const {
    clientParams,
    entityId,
    orderId,

    ...body
  } = options

  const uri = `/OrdersAPI/v1/orders/${entityId}/${orderId}/items/remove`

  return createClient({
    endpointName: 'removeOrderItems',
    responseMustHaveData: true,
    ...clientParams,
  }).post(uri, body)
}
