/**
 *
 * */

// libs
import React, { useContext } from 'react'
// contexts and types
import { DataContext } from 'contexts/data/DataContext'
// hooks
import { useTranslation } from 'hooks/useTranslation'
// styles
import { createUseStyles } from 'react-jss'
import styles from './PaymentView.styles'
import { Collapse, Descriptions, Result } from 'antd'
// components
import Titles from './Titles'
import PaymentRow from './PaymentRow'
import PaymentDetails from './PaymentDetails'
import { OrderWidgetItems, OrderWidgetPayment } from 'contexts/entity/types'

const useStyles = createUseStyles(styles)

type Props = {
  items: OrderWidgetItems
  payment: OrderWidgetPayment
}

const PaymentView: React.FC<Props> = ({ payment, items }) => {
  const classes = useStyles()
  const { Panel } = Collapse

  // pull translations
  const { t } = useTranslation()

  // pull data dispatch from data context
  const { dataState } = useContext(DataContext)

  return (
    <div>
      {dataState.payment?.paymentHistory?.length > 0 &&
      dataState.payment?.paymentDetails?.length > 0 ? (
        <React.Fragment>
          <Collapse>
            {/* first panel reserved for static table titles */}
            <Panel key='1' collapsible='disabled' showArrow={false} header={<Titles />}></Panel>
            {/* map over and render each payment as panel header */}
            {dataState.payment.paymentHistory.map((payment: any, idx: number) => (
              <Panel key={(idx + 2).toString()} header={<PaymentRow payment={payment} />}>
                <Descriptions bordered size='small' column={1}>
                  {/* map over and render each payment method as description item*/}
                  {payment.breakDown.map((b: any, index: number) => (
                    <Descriptions.Item key={index} label={b.method}>
                      {b.amount}
                    </Descriptions.Item>
                  ))}
                </Descriptions>
              </Panel>
            ))}
          </Collapse>

          {/* payment details passed to child */}
          <div className={classes.paymentDataContainer}>
            <PaymentDetails
              paymentDetails={dataState.payment.paymentDetails}
              payment={payment}
              items={items}
            />
          </div>
        </React.Fragment>
      ) : (
        <Result
          title={t('Order Widget.Tabs.Payment.No Payment History')}
          subTitle={t(
            'Order Widget.Tabs.Payment.No payment history found for the current customer',
          )}
        ></Result>
      )}
    </div>
  )
}

export default PaymentView
