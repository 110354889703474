export const apacCustomerEntities = {
  FP_SG: 'fp_sg',
  FP_HK: 'fp_hk',
  FP_MY: 'fp_my',
  FP_PH: 'fp_ph',
  FP_TW: 'fp_tw',
  FP_TH: 'fp_th',
  FP_PK: 'fp_pk',
  FP_BD: 'fp_bd',
  FP_KH: 'fp_kh',
  FP_LA: 'fp_la',
  FP_MM: 'fp_mm',
  FP_JP: 'fp_jp',
  TEST_VA: 'test_va',
}

export const peyaCustomerEntities = {
  PY_AR: 'py_ar',
  PY_BO: 'py_bo',
  PY_CL: 'py_cl',
  PY_CR: 'py_cr',
  PY_GT: 'py_gt',
  PY_HN: 'py_hn',
  PY_DO: 'py_do',
  PY_PY: 'py_py',
  PY_UY: 'py_uy',
  PY_VE: 'py_ve',
  AP_PA: 'ap_pa',
  PY_PE: 'py_pe',
  PY_EC: 'py_ec',
  PY_SV: 'py_sv',
  PY_NI: 'py_ni',
}

export const euCustomerEntities = {
  FP_RO: 'fp_ro',
  FP_BG: 'fp_bg',
  DJ_CZ: 'dj_cz',
  NP_HU: 'np_hu',
  OP_SE: 'op_se',
  PO_FI: 'po_fi',
  FO_NO: 'fo_no',
  FP_DE: 'fp_de',
  MJM_AT: 'mjm_at',
  EF_GR: 'ef_gr',
  FY_CY: 'fy_cy',
  FP_SK: 'fp_sk',
}

export const menaCustomerEntities = {
  HF_EG: 'hf_eg',
  TB_AE: 'tb_ae',
  TB_BH: 'tb_bh',
  TB_JO: 'tb_jo',
  TB_KW: 'tb_kw',
  TB_OM: 'tb_om',
  TB_QA: 'tb_qa',
  TB_SA: 'tb_sa',
  TB_IQ: 'tb_iq',
  HS_BH: 'hs_bh',
  HS_SA: 'hs_sa',
}
