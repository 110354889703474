import { ApiService } from 'services/authApi/getPermissions'

export const performDeviceAction: ApiService<
  {
    entityId: string
    vendorId: string
    deviceId: string
    action: 'RESTART' | 'RESET' | 'INSTALL'
  },
  unknown
> = (createClient, options) => {
  const { entityId, clientParams, vendorId, deviceId, action } = options
  const uri = `/VendorAPI/v1/vendors/${entityId}/${vendorId}/devices/${deviceId}/action`

  return createClient({
    endpointName: 'performDeviceAction',
    expectedResponseStatusCode: 200,
    ...clientParams,
  }).post(uri, {
    action,
  })
}
