export default {
  timeline: {
    display: 'flex',
    marginTop: '20px',
    '& .ant-timeline-item': {
      flexGrow: 1,
      flexBasis: 0,
    },
    '& .ant-timeline-item-content': {
      marginLeft: 0,
      position: 'static',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    '& .ant-timeline-item-head': {
      left: '50%',
      transform: 'translate(-50%)',
      width: '20px',
      height: '20px',
    },
    '& .ant-timeline-item-tail': {
      left: '50%',
      borderLeft: 'none',
      borderTop: '2px solid #f0f0f0',
      width: 'calc(100% - 10px)',
      height: 0,
    },
  },
  itemLabel: {
    marginBottom: '30px',
    marginTop: '-25px',
    color: 'rgba(0, 0, 0, 0.45)',
  },
  infoText: {
    textAlign: 'center',
    maxWidth: '130px',
    color: 'rgba(0, 0, 0, 0.25)',
  },
}
