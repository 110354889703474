import React from 'react'
import { createCustomSvgIcon } from 'factory/createCustomSvgIcon'

export const TabLinkIcon = createCustomSvgIcon({
  viewBoxWidth: 36,
  viewBoxHeight: 36,
  content: (
    <>
      <path d='M29.5423 4.4H22.0909V1.6H33C33.7732 1.6 34.4 2.2268 34.4 3V13.3962H31.6V6.30499L18.1558 19.3264L16.2078 17.3151L29.5423 4.4ZM1.60001 5.18868C1.60001 4.41548 2.22681 3.78868 3.00001 3.78868H19.3636V6.58868H4.40001V30.6H29.9636V16.1321H32.7636V32C32.7636 32.7732 32.1368 33.4 31.3636 33.4H3.00001C2.22681 33.4 1.60001 32.7732 1.60001 32V5.18868Z' />
    </>
  ),
})
