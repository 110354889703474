export default {
  collapse: {
    padding: 0,
    marginLeft: -8,
    width: '95%',
    minWidth: 270,
  },
  deliveryInstructionText: {
    fontSize: '13px',
    color: ' rgba(0, 0, 0, 0.45)',
  },
  deliveryInstructionsHolder: {
    marginTop: '24px',
    position: 'relative',
    zIndex: 1,
    marginLeft: 5,
    width: '100%',
    minWidth: 265,
  },
  deliveryInstructionTextWithoutAccordion: {
    fontSize: '13px',
    color: ' rgba(0, 0, 0, 0.45)',
    marginBottom: 5,
  },
  textAreaWithoutAccordion: {
    minWidth: 220,
    marginBottom: 10,
  },
}
