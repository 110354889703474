export default {
  column: {
    '&::before': {
      width: '0 !important',
    },
  },
  columnRight: {
    textAlign: 'right',
  },
  table: {
    '& .ant-table-thead > tr > th': {
      padding: '8px',
    },
    '& .ant-table-tbody > tr > td': {
      padding: '8px',
    },
  },
  logo: {
    width: '22px',
    height: '22px',
    marginRight: '10px',
  },
  wideLogo: {
    width: '31px',
  },
  rowWithoutBorder: {
    '& > td': {
      borderBottom: 'none !important',
    },
  },
  spingWrapper: {
    height: '200px',
    widht: '100%',
    position: 'relative',
  },
}
