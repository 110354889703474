import { CommentsApiParams } from './types'

export const buildCommentApiUrl = (
  params: CommentsApiParams,
  targetType: string,
  targetId: string,
) => {
  const { apiVersion = 1, entityId, limit = 1000, endTimestamp = '' } = params

  let uri = `/CommentAPI/v${apiVersion}/${targetType}/${entityId}/${targetId}`

  if (apiVersion === 2) {
    uri += `?limit=${limit}&end_timestamp=${endTimestamp}`
  }

  return uri
}
