import React, { useMemo, useContext } from 'react'

// Components
import { Table } from 'antd'

// Contexts
import { SessionContext } from 'contexts/session/SessionContext'

// Types
import { OrderApiResponse } from 'types/api/orderApi/order'

// Hooks
import usePaymentMethods from 'hooks/usePaymentMethods'
import useFixNumber from 'hooks/useFixNumber'

// Utils
import { createUseStyles } from 'react-jss'
import findMainPaymentMethodTransactionWithStatus from 'utils/findMainPaymentMethodTransactionWithStatus'

// Relative Path
import styles from './PaymentMethod.styles'
import SinglePaymentWidget from './SinglePaymentWidget'
import RefundSinglePaymentWidget from './RefundSinglePaymentWidget'
import { StatusWidgetRefund, StatusWidgetTransaction } from './StatusWidget'
import { useTranslation } from 'hooks/useTranslation'
import { useApiService } from 'hooks/useApiService'
import { getOrderTransactions } from 'services/paymentApi/getOrderTransactions'
import { getOrderCompensationsAndRefunds } from 'services/VoucherApi/getOrderCompensationsAndRefunds'
import { WidgetErrorHandler } from 'components/WidgetErrorHandler/widgetErrorHandler'
import { PaymentMethods } from 'contexts/entity/types'

interface PaymentMethodProps {
  order?: OrderApiResponse
  maxPendingTimeInHours: number
}

const useStyles = createUseStyles(styles)

function PaymentMethod({ order, maxPendingTimeInHours }: PaymentMethodProps) {
  const classes = useStyles()
  const { t } = useTranslation()

  const fixNumber = useFixNumber()

  const {
    sessionState: { orderId, globalEntityId },
  } = useContext(SessionContext)

  const {
    loading: isLoadingTransactions,
    data: orderTransactions,
    loadService: refreshOrderTransactions,
    // error: orderTransactionsError,
  } = useApiService({
    service: getOrderTransactions,
    params: {
      entityId: globalEntityId,
      orderId,
    },
    deps: [globalEntityId, orderId],
  })

  const {
    loading: isLoadingRefundsAndCompensations,
    data: refundsAndCompensations,
    loadService: refreshRefundsAndCompensations,
    // error: refundsAndCompensationsError,
  } = useApiService({
    service: getOrderCompensationsAndRefunds,
    params: {
      entityId: globalEntityId,
      orderId,
    },
    deps: [globalEntityId, orderId],
  })

  const { mainPaymentMethod, secondaryPaymentMethods } = usePaymentMethods()

  const columns = useMemo(
    () => [
      {
        title: t('Order Widget.Tabs.Invoice.Type'),
        dataIndex: 'type',
        key: 'type',
        bordered: false,
        className: classes.column,
      },
      {
        title: t('Order Widget.Tabs.Invoice.Method'),
        dataIndex: 'paymentMethod',
        key: 'paymentMethod',
        bordered: false,
        className: classes.column,
      },
      {
        title: t('Order Widget.Tabs.Invoice.Current Status'),
        dataIndex: 'status',
        key: 'status',
        bordered: false,
        className: classes.column,
      },
      {
        title: t('Order Widget.Tabs.Invoice.Amount'),
        dataIndex: 'amount',
        key: 'amount',
        bordered: false,
        // className: classes.columnRight,
        align: 'right' as const,
      },
    ],
    // eslint-disable-next-line
    [],
  )

  // // @ts-ignore
  // let typedFakeRefunds: RefundsCompensationsApiResponse = fakeRefunds

  const dataSource = useMemo(() => {
    let returnArr = []
    if (refundsAndCompensations?.refunds) {
      const sortedDataRefunds = refundsAndCompensations.refunds.sort(
        (a, b) =>
          new Date(b.processed_timestamp).getTime() - new Date(a.processed_timestamp).getTime(),
      )

      returnArr = sortedDataRefunds.map((refund, i) => ({
        key: refund.processed_timestamp,
        type: 'Refund',
        paymentMethod: (
          <RefundSinglePaymentWidget
            refund={refund}
            paymentMethod={mainPaymentMethod.payment_method as PaymentMethods}
          />
        ),
        status: <StatusWidgetRefund refund={refund} />,
        amount: `${refund.currency_code} ${fixNumber(refund.refund_value)}`,
        lastRefundRow: i === refundsAndCompensations.refunds.length - 1,
      }))
    }

    if (mainPaymentMethod) {
      const { status, transaction } = findMainPaymentMethodTransactionWithStatus(
        orderTransactions?.transactions,
        maxPendingTimeInHours,
        mainPaymentMethod.payment_method as PaymentMethods,
      )

      returnArr.push({
        key: '1',
        type: 'Payment',
        paymentMethod: (
          <SinglePaymentWidget icon={mainPaymentMethod.icon} label={mainPaymentMethod.label} />
        ),
        status: <StatusWidgetTransaction status={status} transaction={transaction} />,
        amount: `${order.currency} ${fixNumber(mainPaymentMethod.amount)}`,
      })

      secondaryPaymentMethods.forEach((secondaryPaymentMethod) => {
        returnArr.push({
          key: '2',
          type: 'Payment',
          paymentMethod: (
            <SinglePaymentWidget
              icon={secondaryPaymentMethod.icon}
              label={secondaryPaymentMethod.label}
            />
          ),
          status: <StatusWidgetTransaction status={status} transaction={transaction} />,
          amount: `${order.currency} ${fixNumber(secondaryPaymentMethod.amount)}`,
        })
      })
    }

    return returnArr
    // eslint-disable-next-line
  }, [
    mainPaymentMethod,
    secondaryPaymentMethods,
    refundsAndCompensations,
    orderTransactions,
    maxPendingTimeInHours,
    order.currency,
  ])

  return (
    <WidgetErrorHandler
      loading={isLoadingRefundsAndCompensations || isLoadingTransactions}
      // TODO: Handle error properly to let agent to know if something went wrong with request.
      // We don't want to hide payment method section if have request return error response.
      errorPayload={null}
      onRetry={() => Promise.all([refreshRefundsAndCompensations(), refreshOrderTransactions()])}
      displayType='mini'
    >
      {() => (
        <>
          <Table
            data-test-id='order-invoice-payment-table'
            dataSource={dataSource}
            className={classes.table}
            columns={columns}
            pagination={false}
          />
        </>
      )}
    </WidgetErrorHandler>
  )
}

export default PaymentMethod
