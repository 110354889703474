import React, { useCallback } from 'react'
import { Empty, Space } from 'antd'
import { BuiltinWidgetConfigs } from 'types'
import { VendorAvailabilityStatusItem } from 'types/api/vendorApi/vendorAvailabilityStatus'
import { VendorAvailabilitySchedule, getVendorSchedule } from 'services/vendorApi/getVendorSchedule'
import { closingReason } from 'constants/closingReason'
import { useSdk } from 'contexts/SdkProvider'
import {
  CustomDataPointRenderProps,
  DataPointProps,
  DataPoints,
  SteppedProgressCircle,
} from 'shared'
import {
  TransformedVendorSchedule,
  transformVendorSchedule,
} from 'utils/vendor/transformVendorSchedule'
import moment from 'moment'
import { StatusColors } from 'theme'
import { useSessionState } from 'hooks/useSessionState'
import { getVendorAvailabilityStatus } from 'services/vendorApi/getVendorAvailabilityStatus'

interface AvailabilityProps {
  config: BuiltinWidgetConfigs['vendor_availability']
  latestStatus: VendorAvailabilityStatusItem
}
export const Availability: React.FC<AvailabilityProps> = ({ config, latestStatus = null }) => {
  const { t, createApiClient } = useSdk()
  const { vendorId, globalEntityId } = useSessionState()

  const getSchedule = useCallback(async (): Promise<{ data: VendorAvailabilitySchedule }> => {
    const { data } = await getVendorSchedule(createApiClient, {
      entityId: globalEntityId,
      vendorId,
    })
    return { data }
  }, [createApiClient, globalEntityId, vendorId])

  const getAvailabilityStatus = useCallback(async (): Promise<VendorAvailabilityStatusItem> => {
    const { data } = await getVendorAvailabilityStatus(createApiClient, {
      entityId: globalEntityId,
      vendorId,
    })
    return data[0] ?? null
  }, [createApiClient, globalEntityId, vendorId])

  const closingSource =
    latestStatus?.modifiedBy ?? latestStatus?.closedReason
      ? t('widgets.vendor_availability.vendor_monitor')
      : '-'

  function getClosingReason({ closedReason, availabilityState }: VendorAvailabilityStatusItem) {
    if (!closedReason && availabilityState === 'CLOSED') {
      return t('widgets.vendor_availability.outside_schedule')
    }

    return closingReason[closedReason] ? t(closingReason[closedReason]) : null
  }

  const getVendorClosedUntil = useCallback(async () => {
    const { data } = await getSchedule()

    return {
      statusColor: StatusColors.red,
      statusText: latestStatus?.closedUntil
        ? moment.tz(latestStatus?.closedUntil, data?.time_zone).format('DD.MM.YYYY, h:mm A')
        : '-',
    }
  }, [getSchedule, latestStatus?.closedUntil])

  const getVendorOpenUntil = useCallback(async () => {
    const { data } = await getSchedule()
    const { currentShift } = transformVendorSchedule(data)

    const endShiftDate = currentShift?.to
      ? moment.tz(currentShift.to, 'HH:mm', data.time_zone).format('DD.MM.YYYY, h:mm A')
      : '-'

    return {
      statusColor: StatusColors.green,
      statusText: endShiftDate,
    }
  }, [getSchedule])

  const getTimeToClose = useCallback(async () => {
    const [scheduleResult, availabilityResult] = await Promise.allSettled([
      transformVendorSchedule((await getSchedule()).data),
      getAvailabilityStatus(),
    ])

    if (scheduleResult.status === 'rejected') {
      throw scheduleResult.reason
    }

    return {
      schedule: scheduleResult.value,
      // Availability can be ignored if we don't have a status
      availability: availabilityResult.status === 'fulfilled' ? availabilityResult.value : null,
    }
  }, [getAvailabilityStatus, getSchedule])

  const dataPoints = config?.data_points?.map(
    ({ name, display_rules, label_translation_key }): DataPointProps => {
      switch (name) {
        //ClosedVendorDataPoints
        case 'closed_until':
          return {
            name,
            label: t(label_translation_key),
            type: 'status',
            display_rules,
            value: null,
            loader: getVendorClosedUntil,
          }
        case 'closing_reason':
          return {
            name,
            label: t(label_translation_key),
            type: 'default',
            display_rules,
            value: latestStatus ? getClosingReason(latestStatus) : null,
          }
        case 'closed_by':
          return {
            name,
            label: t(label_translation_key),
            type: 'default',
            display_rules,
            value: closingSource,
          }
        //OpenVendorDataPoints
        case 'open_until':
          return {
            name,
            label: t(label_translation_key),
            type: 'status',
            display_rules,
            value: null,
            loader: getVendorOpenUntil,
          }
        case 'next_opening':
          return {
            name,
            label: t(label_translation_key),
            type: 'default',
            display_rules,
            value: '29.04.2021, 11:45 PM (Mock)',
          }
        case 'time_left_to_close':
          return {
            display_rules,
            type: 'custom',
            value: null,
            loader: getTimeToClose,
            name,
            label: t(label_translation_key),
            render: ({
              value,
            }: CustomDataPointRenderProps<{
              schedule: TransformedVendorSchedule
              availability: VendorAvailabilityStatusItem
            }>) => {
              const { schedule, availability } = value
              const { currentShift, scheduleTimezone } = schedule ?? {}
              const isAvailable = !availability || availability.availabilityState === 'OPEN'

              if (!currentShift || !isAvailable) {
                return '-'
              }

              const startOfShiftDate = moment.tz(currentShift.from, 'HH:mm', scheduleTimezone)
              const endOfShiftDate = moment.tz(currentShift.to, 'HH:mm', scheduleTimezone)
              const vendorLocalDate = moment.tz(scheduleTimezone)
              const shiftTotalTime = endOfShiftDate.diff(startOfShiftDate)
              const remainingTime = endOfShiftDate.diff(vendorLocalDate)

              const timeLeftToClose = moment.utc(remainingTime).format('HH [hr] mm [min]')

              return (
                <Space size={8}>
                  <SteppedProgressCircle
                    total={shiftTotalTime}
                    progress={shiftTotalTime - remainingTime}
                    step={100 / 6}
                  />
                  {timeLeftToClose}
                </Space>
              )
            },
          }
        default:
          return null
      }
    },
  )

  const closedVendorAvailabilityDataPoints = ['closed_until', 'closing_reason', 'closed_by']
  const openVendorAvailabilityDataPoints = ['open_until', 'next_opening', 'time_left_to_close']

  if (!latestStatus) {
    return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
  }

  if (latestStatus?.availabilityState === 'OPEN') {
    return (
      <DataPoints
        dataPoints={dataPoints.filter(
          (item) => item && openVendorAvailabilityDataPoints.includes(item.name),
        )}
      />
    )
  }

  return (
    <DataPoints
      dataPoints={dataPoints.filter(
        (item) => item && closedVendorAvailabilityDataPoints.includes(item.name),
      )}
    />
  )
}
