import { createPluggableWidget } from 'factory/createPluggableWidget'
import React, { useEffect } from 'react'
import { StatusScreen } from './StatusScreen/StatusScreen'
import { Result, Skeleton } from 'antd'
import { createUseStyles } from 'react-jss'
import { useApiService } from 'hooks/useApiService'
import { putUpdateDeliveryStatus } from 'services/fulfillmentApi/putUpdateDeliveryStatus'
import { useTranslation } from 'hooks/useTranslation'

const useStyles = createUseStyles({
  loadingContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    width: '100%',
    margin: '16px',
  },
})

type MoveDeliveryPendingToQueueProps = {}

export const MoveDeliveryPendingToQueue = createPluggableWidget<MoveDeliveryPendingToQueueProps>(
  ({ globalEntityId: entityId, delivery, orderFulfillment, sdk, onQuit }) => {
    const classes = useStyles()

    const { t } = useTranslation()

    const { status, loadService: updateDeliveryStatus } = useApiService({
      service: putUpdateDeliveryStatus,
      deps: [],
      autoLoad: false,
      params: {
        entityId,
        orderId: orderFulfillment.order_id,
        deliveryId: delivery.id,
        payload: {
          from_status: 'pending',
          to_status: 'queued',
        },
      },
      onSuccess: ({ data }) => {
        sdk.captureUserAction('CONFIRM_MOVE_TO_QUEUE_SUCCESS', { reportToEts: true })
        sdk.eventEmitter.dispatchEvent({
          name: 'MOVE_DELIVERY_PENDING_TO_QUEUE_SUCCESS',
          payload: { orderId: data.order_id },
        })
      },
    })

    useEffect(() => {
      updateDeliveryStatus()
    }, [updateDeliveryStatus])

    if (status === 'loading' || status === 'refreshing') {
      return (
        <div className={classes.loadingContainer}>
          <Skeleton active />
        </div>
      )
    }

    if (status === 'success') {
      return (
        <StatusScreen
          status='success'
          handleClose={() => {
            sdk.captureUserAction('ACKNOWLEDGE_MOVE_TO_QUEUE', { reportToEts: true })
            onQuit(true)
          }}
        />
      )
    }

    if (status === 'disabled') {
      return <Result status={'warning'} title={t('This Action is disabled')} />
    }

    // i.e. status === 'error' || 'default'
    return <StatusScreen status='error' handleClose={onQuit} />
  },
)
