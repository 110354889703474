import { SelfServiceIcon } from 'Icons/SelfServiceIcon'
import { CommentViewNote } from 'components/Comments/CommentView'
import { CustomIcon } from 'factory/createCustomSvgIcon'
import { TranslateFunction, useTranslation } from 'hooks/useTranslation'
import React, { ReactNode, useCallback } from 'react'
import { Text } from 'shared'
import { ArrayValue } from 'types'
import {
  SelfServiceRefundOrCompensationResponseType,
  SingleComment,
  SingleCommentAction,
  SingleCommentSelfServiceRefundOrCompensation,
} from 'types/api/commentApi/getOrderComments'
import { getCcrItemByCode } from 'utils/ccr/getCcrItemByCode'

export interface TransformedComment {
  Icon: CustomIcon
  author: string

  orderId?: string

  comment?: ReactNode

  tag: {
    text: string
  }

  voucherCode?: string
  issueType?: string

  notes: Array<CommentViewNote>

  failed_reason?: string

  fallbackCaseIds?: Array<{
    label: string
    caseId: string
  }>
}

const getRefundOrCompensationSelfServiceActionNotes = (
  selfServiceAction: SingleCommentSelfServiceRefundOrCompensation,
  t: TranslateFunction,
) => {
  let isSuccess = false
  const notes: ArrayValue<TransformedComment['notes']>[] = []

  const responseType =
    selfServiceAction.event.details.response_type.toLocaleLowerCase() as SelfServiceRefundOrCompensationResponseType

  const fallbackType = (selfServiceAction.event.details.fallback.reason || '').toLowerCase()

  const fallbackCaseIds: TransformedComment['fallbackCaseIds'] = []

  switch (responseType) {
    case 'added_to_wallet':
    case 'voucher_created':
    case 'online_payment_refunded':
    case 'unknonwn':
    case 'reactive_compensation_recommendation':
      isSuccess = true
      break

    case 'case_created':
    case 'fraud_case_created':
    case 'route_to_chat':
      notes.push({
        type: 'successNote',
        note: t(`widgets.activity.error.${fallbackType}`),
        details: t(`widgets.activity.error_details.${fallbackType}`),
      })
      break

    case 'already_added_to_wallet':
    case 'already_refunded_automatically':
    case 'already_routed_to_chat':
    case 'auto_partial_refund_not_possible':
    case 'case_already_created':
    case 'voucher_already_created':
    case 'no_recommendation':
    case 'order_already_compensated_external':
    case 'order_already_refunded_external':
    case 'max_amount_exceed':
    case 'fraud_check_block':
    case 'wallet_creation_failed':
    case 'voucher_creation_failed':
      notes.push({
        type: 'errorNote',
        note: t(`widgets.activity.error.${responseType}`),
        details: t(`widgets.activity.error_details.${responseType}`),
      })
      break

    case 'max_amount_exceed_case_created':
      notes.push({
        type: 'errorNote',
        note: t(`widgets.activity.error.max_amount_exceed`),
        details: t(`widgets.activity.error_details.max_amount_exceed`),
      })
      break

    case 'voucher_creation_failed_case_created':
      notes.push({
        type: 'errorNote',
        note: t(`widgets.activity.error.voucher_creation_failed`),
        details: t(`widgets.activity.error_details.voucher_creation_failed`),
      })
      break

    case 'wallet_creation_failed_case_created':
      notes.push({
        type: 'errorNote',
        note: t(`widgets.activity.error.wallet_creation_failed`),
        details: t(`widgets.activity.error_details.wallet_creation_failed`),
      })
      break
  }

  const relatedIds = selfServiceAction.event.details.related_ids

  const caseIdAndLabelTranslationSuffices = [
    [relatedIds.agent_case_id, 'agent_case'],
    [relatedIds.wastage_case_id, 'wastage_case'],
    [relatedIds.finance_case_id, 'finance_case'],
  ]

  for (const [caseId, labelTranslationSuffix] of caseIdAndLabelTranslationSuffices) {
    if (caseId) {
      fallbackCaseIds.push({
        caseId,
        label: t(`widgets.activity.fallback_case_ids.${labelTranslationSuffix}`),
      })
    }
  }

  return { isSuccess, notes, fallbackCaseIds }
}

export const useCreateCommentTransformer = () => {
  const { t } = useTranslation()

  return useCallback(
    (comment: SingleComment): TransformedComment => {
      const { self_service_refund, self_service_compensation } = comment.additional_data

      const commentCommonData = {
        Icon: SelfServiceIcon,
        author: t('widgets.activity.self_service'),
      }

      if (comment.action === SingleCommentAction.self_service_compensation) {
        const paymentMethod = self_service_compensation.financial.payment_medium
        const ccrCode = self_service_compensation.event.details.reason_code || ''

        const { isSuccess, notes, fallbackCaseIds } = getRefundOrCompensationSelfServiceActionNotes(
          self_service_compensation,
          t,
        )

        return {
          ...commentCommonData,
          orderId: self_service_compensation.order?.id,
          comment: (
            <>
              {isSuccess ? (
                <Text.Primary Component='span'>
                  {t(`widgets.activity.comment_fragments.compensation_success`)}
                </Text.Primary>
              ) : (
                <Text.Primary Component='span'>
                  {t(`widgets.activity.comment_fragments.compensation_failed`)}
                </Text.Primary>
              )}{' '}
              <Text.Primary Component='span' fontWeight='700'>
                {t(`widgets.activity.amounts.${paymentMethod}`, {
                  replace: {
                    currency: self_service_compensation.financial.currency_code,
                    amount: self_service_compensation.financial.refund_value,
                  },
                })}
              </Text.Primary>
            </>
          ),
          issueType: getCcrItemByCode({ ccrCode })?.name || '',

          voucherCode: self_service_compensation.financial.details.vouchers?.refund?.code,

          tag: {
            text: t(`widgets.activity.actions.compensation`),
          },

          notes,

          fallbackCaseIds,
        }
      }

      if (comment.action === SingleCommentAction.self_service_refund) {
        const isFullRefund = self_service_refund.financial.details.is_full_refund
        const isPartialRefund = !isFullRefund

        const ccrCode = self_service_refund.event.details.reason_code || ''
        const paymentMethod = self_service_refund.financial.payment_medium

        const { isSuccess, notes, fallbackCaseIds } = getRefundOrCompensationSelfServiceActionNotes(
          self_service_refund,
          t,
        )

        return {
          ...commentCommonData,

          orderId: self_service_refund.order?.id,

          comment: (
            <>
              {isSuccess && (
                <Text.Primary Component='span'>
                  {isPartialRefund
                    ? t(`widgets.activity.comment_fragments.partial_refund_success`)
                    : t(`widgets.activity.comment_fragments.full_refund_success`)}
                </Text.Primary>
              )}{' '}
              {!isSuccess && (
                <Text.Primary Component='span'>
                  {isPartialRefund
                    ? t(`widgets.activity.comment_fragments.partial_refund_failed`)
                    : t(`widgets.activity.comment_fragments.full_refund_failed`)}
                </Text.Primary>
              )}{' '}
              <Text.Primary Component='span' fontWeight='700'>
                {t(`widgets.activity.amounts.${paymentMethod}`, {
                  replace: {
                    currency: self_service_refund.financial.currency_code,
                    amount: self_service_refund.financial.refund_value,
                  },
                })}
              </Text.Primary>
            </>
          ),

          issueType: getCcrItemByCode({ ccrCode })?.name || '',

          voucherCode: self_service_refund.financial.details.vouchers?.refund?.code,

          tag: {
            text: t(`widgets.activity.actions.${isFullRefund ? 'full_refund' : 'partial_refund'}`),
          },

          notes,

          fallbackCaseIds,
        }
      }

      return null
    },
    [t],
  )
}
