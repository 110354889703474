import { useContext } from 'react'

import { DataContext } from 'contexts/data/DataContext'
import { getMainPaymentMethod } from 'utils/getters/getMainPaymentMethod'
import { getSecondaryPaymentMethods } from 'utils/getters/getSecondaryPaymentMethods'
import { PaymentMethods } from 'contexts/entity/types'

function usePaymentMethods() {
  const {
    dataState: { order },
  } = useContext(DataContext)

  return {
    mainPaymentMethod: getMainPaymentMethod(order),
    secondaryPaymentMethods: getSecondaryPaymentMethods(order).filter((secondaryPaymentMethod) => {
      return secondaryPaymentMethod.payment_method !== PaymentMethods.No_Payment_Zero_Payable_Amount
    }),
  }
}

export default usePaymentMethods
