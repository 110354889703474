import React from 'react'
import { Progress, ProgressProps } from 'antd'
import { createUseStyles } from 'react-jss'
import { green, gray, red } from 'theme'

const useStyles = createUseStyles({
  progressContainer: {
    '& .ant-progress-circle-trail': {
      stroke: (critical: boolean) => (critical ? red.red5 : green.green6),
      strokeWidth: '20px',
    },
  },
})

export interface SteppedProgressCircleProps extends Omit<ProgressProps, 'percent'> {
  progress: number
  total: number
  step: number
}

function getPercentageProgressInSteps(progress: number, total: number, step: number) {
  const percentage = (progress / total) * 100

  if (percentage > 100) return 100
  if (percentage < 0) return 0

  if (percentage > 0 && percentage < step) {
    return step
  } else {
    return Math.round(percentage / step) * step
  }
}

export const SteppedProgressCircle = ({
  progress,
  total,
  step = 100 / 4,
  strokeColor = gray.gray5,
  width = 20,
  strokeWidth = 22,
  showInfo = false,
}: SteppedProgressCircleProps) => {
  const percentageProgress = getPercentageProgressInSteps(progress, total, step)

  const remaining = 100 - percentageProgress

  const critical = remaining <= step

  const classes = useStyles(critical)

  return (
    <Progress
      type='circle'
      className={classes.progressContainer}
      percent={percentageProgress}
      strokeColor={strokeColor}
      width={width}
      strokeWidth={strokeWidth}
      showInfo={showInfo}
    />
  )
}
