import { EditOutlined, InfoCircleFilled } from '@ant-design/icons'
import { Button, Checkbox, Input, Typography } from 'antd'
import classNames from 'classnames'
import { useCaptureUserAction } from 'hooks/events/useCaptureUserAction'
import { useTranslation } from 'hooks/useTranslation'
import React from 'react'
import { createUseStyles } from 'react-jss'
import { ItemStructured } from 'types/actions/partialRefund'
import fixNumber from 'utils/fixNumber'
import { calculateMissingOrderItemTotalPrice } from 'utils/orderItems/orderItemPricings'
import { restructureOrderItem } from 'utils/orderItems/restructureOrderItem'
import styles from './PartialItemSelection.styles'

const useStyles = createUseStyles(styles)

interface PartialItemSelectionProps {
  item: ItemStructured
  checkboxClassName: string
  partialItemRefundPercentage: number
  refundAmountPrecision: number
  currency: string
  setItem: (item: ItemStructured) => void
  spacing: number
}

export const PartialItemSelection = ({
  item,
  checkboxClassName,
  refundAmountPrecision,
  partialItemRefundPercentage,
  currency,
  setItem,
  spacing,
}: PartialItemSelectionProps) => {
  const { Text } = Typography
  const { t } = useTranslation()

  const cx = useStyles({
    spacing,
  })

  const captureUserAction = useCaptureUserAction()

  const disabled = item.selected || item.parent_item_selected || item.parent_item_partially_selected

  return (
    <div className={cx.partialHolder}>
      <div className={cx.partialContainerWithoutInput}>
        <div className={cx.partialRowNameHolder}>
          <Checkbox
            disabled={disabled}
            checked={item.partially_selected}
            className={checkboxClassName}
            onChange={() => {
              captureUserAction('ActionsPartialRefundRemoveSelectOnPartialItem')
              setItem(
                restructureOrderItem(item, {
                  type: 'toggle_partial_item_selection',
                }),
              )
            }}
          >
            {t('Actions Widget.Actions.Partial Refund.Partial item issue')}
          </Checkbox>

          {!item.partially_selected ? (
            <Text
              className={classNames(cx.partialDescriptionTextForName, {
                selected: item.selected,
                disabled,
              })}
            >
              <InfoCircleFilled className={cx.partialDescriptionIcon} />
              {t('Actions Widget.Actions.Partial Refund.Describe the missing items')}
            </Text>
          ) : null}
        </div>
        <div className={cx.partialRowPriceHolder}>
          <Text
            className={classNames(cx.partialDescriptionPriceText, {
              selected: item.selected,
              disabled,
            })}
          >{`${currency} ${fixNumber(
            calculateMissingOrderItemTotalPrice(
              {
                ...item,
                partially_selected: true,
              },
              {
                considerPartialSelection: true,
                partialItemRefundPercentage,
              },
            ),
            refundAmountPrecision,
          )}`}</Text>
          <Text
            className={classNames(cx.partialDescriptionTextForPrice, {
              selected: item.selected,
              disabled,
            })}
          >
            <InfoCircleFilled className={cx.partialDescriptionIcon} />
            {t('Actions Widget.Actions.Partial Refund.%{{percent}} of total item amount', {
              replace: {
                percent: partialItemRefundPercentage,
              },
            })}
          </Text>
        </div>
      </div>

      {/* PARTIAL description input: rendered only if the current partial selected and the input is activated */}
      {item.partially_selected && item.activate_partial_input && (
        <div className={cx.partialDescriptionHolder}>
          <Text className={cx.descriptionText}>{`${t(
            'Actions Widget.Actions.Partial Refund.Description',
          )}:`}</Text>
          <div className={cx.partialInputHolder}>
            <div className={cx.partialInputAndRequiredholder}>
              <Input
                className={cx.partialDescriptionInput}
                value={item.partial_description || ''}
                size='middle'
                placeholder={`${t('Actions Widget.Actions.Partial Refund.Partial Item')}...`}
                onChange={(e) => {
                  setItem(
                    restructureOrderItem(item, {
                      type: 'change_partial_item_description',
                      value: e.target.value,
                    }),
                  )
                }}
              />
              {item.options.length === 0 && (
                <Text className={cx.requiredText}>
                  {`*${t('Actions Widget.Actions.Partial Refund.Field Required')}`}
                </Text>
              )}
            </div>

            <Button
              className={cx.partialOkButton}
              type='primary'
              size='small'
              onClick={() => {
                setItem(
                  restructureOrderItem(item, {
                    type: 'toggle_partial_item_description_input',
                  }),
                )
              }}
            >
              {t('Interface.OK')}
            </Button>
          </div>
        </div>
      )}

      {item.partially_selected && !item.activate_partial_input && (
        <div className={cx.partialCompletedDescriptionHolder}>
          <Text className={cx.descriptionText}>
            {`${t('Actions Widget.Actions.Partial Refund.Description')}: ${
              item.partial_description
            }`}
          </Text>
          <EditOutlined
            className={cx.editDescriptionIcon}
            width='1.5em'
            height='1.5em'
            onClick={() => {
              setItem(
                restructureOrderItem(item, {
                  type: 'toggle_partial_item_description_input',
                }),
              )
            }}
          />
        </div>
      )}
    </div>
  )
}
