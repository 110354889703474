import React from 'react'
import styles from './SingleChatTranscript.styles'
import { createUseStyles } from 'react-jss'
import { SingleChatBody } from './SingleChatBody'
import { SingleChatHeader } from './SingleChatHeader'
import { Spin } from 'antd'
import { useApiService } from 'hooks/useApiService'
import { getCustomerChatHistory } from 'services/ticketApi/getCustomerChatHistory'
import { CustomerChatListItem } from 'types/api/ticketApi/getOrderTickets'
import { getCcrItemByCode } from 'utils/ccr/getCcrItemByCode'

const useStyles = createUseStyles(styles)

interface SingleChatTranscriptParams {
  entityId: string
  onBack: () => void
  selectedRoom: CustomerChatListItem
}

export const SingleChatTranscript = ({
  entityId,
  onBack,
  selectedRoom,
}: SingleChatTranscriptParams) => {
  const classes = useStyles()

  const {
    chat_info: { room_id: roomID, room_name: roomName },
    order_id: orderId,
    ccr: ccrCode,
  } = selectedRoom?.metadata

  const roomTitle = getCcrItemByCode({ ccrCode })?.name || 'Error: unable to resolve ccr'

  const { data: chatData, status: chatStatus = 'loading' } = useApiService({
    service: getCustomerChatHistory,
    autoLoad: true,
    params: { entityId, department: 'CS', roomID, roomName },
    deps: [entityId, roomID, roomName],
    shouldLoad: Boolean(roomID) && Boolean(roomName),
  })

  return (
    <div className={classes.container}>
      <SingleChatHeader orderId={orderId} roomTitle={roomTitle} onBack={onBack} />
      {chatStatus === 'loading' && (
        <div data-testid='loading-container' className={classes.loadingContainer}>
          <Spin size='large' />
        </div>
      )}

      {chatStatus === 'success' && (
        <SingleChatBody
          chatData={chatData}
          customerEmail={selectedRoom?.customer?.email}
          orderId={orderId}
        />
      )}
    </div>
  )
}
