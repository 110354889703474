// libs
import React, { useCallback, useState } from 'react'
// contexts and types
import { SingleChatEventLocal } from 'types/components/chatTranscriptView'
// hooks
import { useTranslation } from 'hooks/useTranslation'
// styles
import { createUseStyles } from 'react-jss'
import styles from './SingleChatView.styles'
import { Typography, Divider, Image } from 'antd'
// assets
import doubleCheckGray from 'assets/doubleCheck/doubleCheckGray.png'
import doubleCheckWhite from 'assets/doubleCheck/doubleCheckWhite.png'
import singleCheckWhite from 'assets/singleCheck/singleCheckWhite.png'
import { ChatEventStatus, ChatEventType, ChatParticipant } from 'types/api/riderApi/riderChats'
import classNames from 'classnames'

interface Props {
  chatEvent: SingleChatEventLocal
  selectedLanguage?: string
  rider: string
}

const useStyles = createUseStyles(styles)

const SingleChatView: React.FC<Props> = ({ chatEvent, selectedLanguage, rider }) => {
  const [imageLoadFailed, setImageLoadFailed] = useState(false)
  const isCustomer = chatEvent.sender === ChatParticipant.customer
  const isRider = chatEvent.sender === ChatParticipant.rider

  const isRead = chatEvent.status === ChatEventStatus.read

  const cx = useStyles()
  const { Text } = Typography

  // pull language content
  const { t } = useTranslation()

  const translatedMessage =
    chatEvent.eventType === ChatEventType.message && selectedLanguage
      ? chatEvent.translations[selectedLanguage]
      : null

  const messageTextClassName = classNames(cx.messageText, { isRider, isCustomer })

  const onImageLoadError = useCallback((e) => {
    setImageLoadFailed(true)
  }, [])

  return (
    <div
      className={classNames(cx.chatContainer, {
        isCustomer,
        isRider,
      })}
    >
      <div
        className={classNames(cx.chatInnerContainer, {
          isCustomer,
          isRider,
        })}
      >
        {/* sender */}
        <Text className={cx.senderName}>
          {isCustomer ? t('Customer Widget.Customer') : rider ? rider : t('Rider Widget.Rider')}
        </Text>

        {/* render message */}
        {chatEvent.eventType === ChatEventType.message && (
          <div className={classNames(cx.messageBox, { isRider, isCustomer })}>
            <Text className={messageTextClassName}>{chatEvent.message}</Text>

            {translatedMessage ? (
              <>
                <Divider className={classNames(cx.translationDivider, { isRider, isCustomer })} />
                <Text className={messageTextClassName}>{translatedMessage}</Text>
              </>
            ) : null}

            {/* chat message footer */}
            <div className={cx.chatMessageFooter}>
              {/* indicate translation, if passed */}
              <Text className={classNames(cx.messageDate, { isRider, isCustomer })}>
                {translatedMessage
                  ? `${chatEvent.timestamp} • ${t('Order Widget.Tabs.Summary.TRANSLATED')}`
                  : chatEvent.timestamp}
              </Text>
              {/* indicate isRead */}
              {isRead && (
                <img
                  className={cx.icon}
                  src={
                    isCustomer && isRead
                      ? doubleCheckGray
                      : !isCustomer && isRead
                      ? doubleCheckWhite
                      : singleCheckWhite
                  }
                  alt='check sign'
                />
              )}
            </div>
          </div>
        )}

        {/* render chat image */}
        {chatEvent.eventType === ChatEventType.image && !imageLoadFailed && (
          <div className={cx.imageBox}>
            <a href={chatEvent.image.url} target='_blank' rel='noreferrer'>
              <Image
                src={chatEvent.image.url}
                alt={chatEvent.image.url}
                onError={onImageLoadError}
              />
            </a>
          </div>
        )}

        {/* render chat image not available */}
        {chatEvent.eventType === ChatEventType.image && imageLoadFailed && (
          <div className={''}>
            <Text className={cx.imageNotAvailableText}>
              {t('Interface.Sent image not available')}
            </Text>
          </div>
        )}
      </div>
    </div>
  )
}

export default SingleChatView
