import { ApiService } from 'services/authApi/getPermissions'
import { Compensation, Refund } from 'types/api/voucherApi/getCompensationsAndRefunds'

export const getCustomerCompensationsAndRefunds: ApiService<
  {
    entityId: string
    customerId: string

    /**
     * if given, it only returns
     * compensations and refunds for the given order
     *
     * there is the getOrderCompensationsAndRefunds method, that
     * does the same thing
     */
    orderId?: string
  },
  {
    compensations: Compensation[]
    refunds: Refund[]
  }
> = (createClient, options) => {
  const { entityId, orderId, customerId, clientParams } = options

  // client details
  const uri = `/VoucherAPI/v2/customer/${entityId}/${customerId}/compensations`

  return createClient({
    endpointName: 'getCustomerCompensationsAndRefunds',

    expectedResponseStatusCode: 200,
    responseMustHaveData: true,
    ...clientParams,
  })
    .get(uri)
    .then((res) => {
      const compensations: Compensation[] = res.data.compensations || []
      const refunds: Refund[] = res.data.refunds || []

      const filterData = <T extends { order_id: string }>(data: Array<T>) => {
        if (orderId) {
          return data.filter((current) => current.order_id === orderId)
        }
        return data
      }

      return {
        ...res,
        data: {
          compensations: filterData(compensations),
          refunds: filterData(refunds),
        },
      }
    })
}
