import { Space } from 'antd'
import classNames from 'classnames'
import React, { PropsWithChildren } from 'react'
import { createUseStyles } from 'react-jss'
import { UnifiedIcon } from 'shared/UnifiedIcon'
import { text } from 'theme'
import { SlimHtmlProps } from 'types'

const useStyles = createUseStyles({
  link: {
    color: `${text.link} !important`,
  },
})

export type LinkProps = SlimHtmlProps<{}, 'a'>

export const Link = ({ children, href, className, ...rest }: PropsWithChildren<LinkProps>) => {
  const styles = useStyles()
  return (
    <a href={href} className={classNames(styles.link, className)} {...rest}>
      <Space size={4}>
        {children} <UnifiedIcon icon='TabLinkIcon' size={12} />
      </Space>
    </a>
  )
}
