import { createUseStyles } from 'react-jss'
import { gray, white } from 'theme'

export interface UseTabStylesOptions {
  /**
   * tabs margin top
   */
  marginTop?: string | number

  /**
   * background color of each tab button
   */
  backgroundColor?: string

  activeBackgroundColor?: string

  /**
   * border radius to apply on tab items
   */
  tabBorderRadius?: number

  /**
   * border width, defualts to 1
   */
  borderWidth?: number

  /**
   * if true, tab items width will expand to fill container
   *
   * ant tab nav operations will be disabled, because somehow, the tabs
   * flicker, due to bug in antd tabs
   */
  flexItems?: boolean

  /**
   * defines tab left and right padding
   */
  xPadding?: number

  /**
   * defines tab top and bottom padding
   */
  yPadding?: number
}

export const useTabsStyles = createUseStyles({
  tabs: (opts: UseTabStylesOptions) => {
    const {
      flexItems = false,
      tabBorderRadius = 0,
      backgroundColor = white,
      activeBackgroundColor = white,
      marginTop = 0,
      borderWidth = 0,
      xPadding = 16,
      yPadding = 12,
    } = opts || {}
    return {
      marginTop,

      '& > .ant-tabs-content-holder': {
        backgroundColor: white,
      },

      '& > .ant-tabs-nav .ant-tabs-nav-list': {
        width: '100%',
      },

      '& > .ant-tabs-nav .ant-tabs-tab-btn': {
        display: 'flex',
        alignItems: 'center',
      },

      '& > .ant-tabs-nav .ant-tabs-tab': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flex: flexItems ? 1 : undefined,
        backgroundColor: `${backgroundColor} !important`,
        borderRadius: `${tabBorderRadius}px ${tabBorderRadius}px 0px 0px !important`,
        paddingTop: `${yPadding} !important`,
        paddingBottom: `${yPadding} !important`,
        paddingLeft: `${xPadding}px !important`,
        paddingRight: `${xPadding}px !important`,
        borderBottom: `${borderWidth}px solid ${gray.gray4} !important`,
      },

      '& > .ant-tabs-nav .ant-tabs-tab.ant-tabs-tab-active': {
        backgroundColor: `${activeBackgroundColor} !important`,
      },

      '& > .ant-tabs-nav': {
        marginBottom: 0,
      },

      '& > .ant-tabs-nav > .ant-tabs-nav-operations': {
        display: flexItems ? 'none !important' : undefined,
      },
    }
  },

  tabButton: {
    display: 'flex',
    columnGap: '8px',
    alignItems: 'center',

    '& .anticon': {
      margin: 0,
    },
  },
})
