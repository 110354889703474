import { useWidgetViewManager } from 'contexts/widgetViewManager'
import React, { FC, useCallback, useState } from 'react'
import { ProcessQuitConfirmation } from 'components/ProcessQuitConfirmation/ProcessQuitConfirmation'

import { PluggableWidgetProps, WidgetView } from 'types/unitedUiConfig'
import { WidgetErrorBoundary } from 'components/ErrorBoundary/WidgetErrorBoundary'
import { useWidgetViewState } from 'hooks/widgetView/useWidgetViewState'
import { logError } from 'utils/reporting/logError'
import { UnifiedIcon } from 'shared/UnifiedIcon'
import { WidgetErrorHandler } from 'components/WidgetErrorHandler/widgetErrorHandler'
import { WidgetDataRequirementsValidation } from 'components/WidgetDataRequirementsValidation/WidgetDataRequirementsValidation'
import { PopupWidgetContainer } from 'shared/PopupWidgetContainer'
import { useSessionState } from 'hooks/useSessionState'
import { ViewScreenProvider } from 'contexts/ViewScreenProvider'
import { useCaptureUserAction } from 'hooks/events/useCaptureUserAction'
import { useIconPacks } from 'contexts/IconPacksProvider'
import { computeWidgetRenderState } from 'utils/widgets/computeWidgetRenderState'
import { useWidgetLabelTranslator } from 'hooks/widgetView/useWidgetLabelTranslator'

const View: FC<{
  view: WidgetView
}> = ({ view }) => {
  const { destroyWidgetView, deactivateWidgetView } = useWidgetViewManager()
  const captureUserAction = useCaptureUserAction()

  const {
    Widget,
    id: viewId,
    isActiveView,
    metadata,
    labelTranslationKey,
    parentViewId,
    widgetId,
    implementsHandle,
    elevated,
  } = view

  const [isProcessingQuitConfirmation, setIsProcessingQuitConfirmation] = useState(false)

  const {
    viewState,
    subjects,
    subjectsValidationResult,
    handle,
    sdkConstructParams,
    sdk,
    onViewCrash,
  } = useWidgetViewState({
    labelTranslationKey,
    subjects: view.subjects,
    deriveInitialViewState: Widget.deriveInitialViewState,
    deriveSubjectsRequirements: Widget.deriveSubjectsRequirements,
    destroyable: view.destroyable,
    config: metadata.config,
    elevated,
  })

  const iconPacks = useIconPacks()

  const { destroyable, actionState, crashed } = viewState

  /**
   * hide the view
   */
  const onHideButtonClick = useCallback(() => {
    captureUserAction('HIDE_WIDGET', {
      eventDetails: {
        widget_id: widgetId,
      },
    })
    try {
      handle.current?.onBeforeHide?.()
    } catch (ex) {
      logError({
        type: 'widget_runtime_error',
        originalError: ex,
      })
    }
    deactivateWidgetView(viewId)
  }, [widgetId, viewId, deactivateWidgetView, handle, captureUserAction])

  /**
   * Quit the view (through X button, Cancel, Quit...etc)
   */
  const onQuit = useCallback(
    (isAcknowledgement = false) => {
      if (!isAcknowledgement) {
        captureUserAction('TERMINATE_WIDGET', {
          eventDetails: {
            widget_id: widgetId,
            button_label: null,
            action_state: actionState,
          },
        })
      }

      try {
        handle.current?.onBeforeClose?.()
      } catch (ex) {
        logError({
          type: 'widget_runtime_error',
          originalError: ex,
        })
      }
      destroyWidgetView(viewId)
    },
    [widgetId, viewId, captureUserAction, destroyWidgetView, handle, actionState],
  )

  /**
   * called when user clicks on the x button of the container
   */
  const onCloseButtonClick = useCallback(() => {
    if (isProcessingQuitConfirmation || crashed) {
      return onQuit()
    }

    if (!handle.current?.onXButtonClick) {
      return onQuit()
    }

    let quit = false
    try {
      quit = handle.current.onXButtonClick()
    } catch (ex) {
      logError({
        type: 'widget_runtime_error',
        originalError: ex,
      })
      quit = true
    }
    if (quit) {
      onQuit()
    } else {
      setIsProcessingQuitConfirmation(true)
    }
  }, [onQuit, crashed, isProcessingQuitConfirmation, handle])

  // user decides not to quit
  const onUserConfirmsToStay = useCallback(() => setIsProcessingQuitConfirmation(false), [])

  const widgetParams: PluggableWidgetProps<{}> = {
    onQuit,
    onHide: onHideButtonClick,
    parentViewId,
    sdkConstructParams,
    ...subjects,
    ...metadata,
    sdk,
    iconPacks,
    IconRenderer: UnifiedIcon,
    ErrorRenderer: WidgetErrorHandler,
    widgetId: view.widgetId,
  }

  return (
    <PopupWidgetContainer
      title={viewState.title}
      width={viewState.width}
      maxWidth={viewState.maxWidth}
      visible={isActiveView}
      widgetContainerType={subjects.containerType}
      onClose={destroyable && onCloseButtonClick}
      onHide={onHideButtonClick}
      data-widget-name={widgetId}
      data-widget-render-state={computeWidgetRenderState({
        crashed,
        subjectsValidationStatus: subjectsValidationResult.status,
      })}
    >
      <WidgetErrorBoundary
        widgetId={widgetId}
        supportSlackChannel={metadata.supportSlackChannel}
        contactEmail={metadata.authorEmail}
        onCrash={onViewCrash}
      >
        {/* validate widget data requirement */}
        <WidgetDataRequirementsValidation validationResult={subjectsValidationResult}>
          {/* process quit confirmation */}
          <ProcessQuitConfirmation
            process={isProcessingQuitConfirmation}
            onStay={onUserConfirmsToStay}
            onQuit={onQuit}
          >
            <Widget {...widgetParams} ref={implementsHandle ? handle : undefined} />
          </ProcessQuitConfirmation>
        </WidgetDataRequirementsValidation>
      </WidgetErrorBoundary>
    </PopupWidgetContainer>
  )
}

export const WidgetViewPanel = () => {
  const { widgetContainerType } = useSessionState()
  const { widgetViews } = useWidgetViewManager()

  const widgetLabelTranslator = useWidgetLabelTranslator()

  return (
    <div id='widgets-view-panel'>
      {widgetViews.map((view) => (
        <ViewScreenProvider
          screenName={view.subjects?.containerType || widgetContainerType}
          widgetId={view.widgetId}
          label={widgetLabelTranslator.translate(view.labelTranslationKey)}
          key={view.id}
        >
          <View view={view} />
        </ViewScreenProvider>
      ))}
    </div>
  )
}
