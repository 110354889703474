import React from 'react'
import { createUseStyles } from 'react-jss'
import { Collapse as AntCollapse } from 'antd'
import type { CollapseProps as AntCollapseProps } from 'antd'
import { DownOutlined } from '@ant-design/icons'

import styles from './index.styles'
import { Panel } from './Panel'

const useStyles = createUseStyles(styles)

export interface CollapseProps extends AntCollapseProps {
  // @defaultValue `'primary'`
  type?: 'primary' | 'secondary'

  // Header's background color
  bgColor?: string

  // Align the header and content containers to offset the icon space in the header
  offsetIcon?: boolean

  // Removes borders on entire collapse component
  removeBorders?: boolean

  // Content's background color
  contentBgColor?: string
}

type CollapseType = CollapseProps['type']

function isBordered(type: CollapseType) {
  return type !== 'secondary'
}

interface CollapseInterface extends React.FC<CollapseProps> {
  Panel: typeof Panel
}

/** Extends Ant Design's Collapsible component. Adds variations and custom styles. */
export const Collapse: CollapseInterface = (props) => {
  const classes = useStyles(props)
  const { type, ...otherProps } = props

  const collapseClassName =
    {
      primary: classes.collapsePrimary,
      secondary: classes.collapseSecondary,
    }[type] ?? classes.collapsePrimary

  return (
    <AntCollapse
      className={collapseClassName}
      expandIconPosition='end'
      bordered={isBordered(type)}
      expandIcon={({ isActive }) => <DownOutlined rotate={isActive ? 180 : 0} />}
      {...otherProps}
    />
  )
}

Collapse.Panel = Panel
