import { DataContext } from 'contexts/data/DataContext'
import { DataAction } from 'contexts/data/types'
import { SessionContext } from 'contexts/session/SessionContext'
import { SessionAction } from 'contexts/session/types'
import { SDK, SDK_EVENTS, WidgetViewState } from 'types'
import { useCallback, useContext } from 'react'

export const useDispatch = (opts?: { setViewState?: (viewState: WidgetViewState) => void }) => {
  const { sessionDispatch } = useContext(SessionContext)
  const { dataDispatch } = useContext(DataContext)

  const { setViewState } = opts || {}

  return useCallback<SDK['dispatch']>(
    (event) => {
      switch (event.type) {
        // update active tab
        case SDK_EVENTS.SET_ACTIVE_TAB:
          sessionDispatch({
            type: SessionAction.SET_SESSION,
            payload: {
              activeTab: event.payload,
            },
          })
          break

        // update view state
        case SDK_EVENTS.SET_VIEW_STATE:
          setViewState && setViewState(event.payload)
          break

        case SDK_EVENTS.SET_TICKET:
          dataDispatch({
            type: DataAction.SET_TICKET,
            payload: {
              ticket: event.payload,
            },
          })
          break

        case SDK_EVENTS.SET_ORDER:
          dataDispatch({
            type: DataAction.SET_ORDER,
            payload: {
              order: event.payload,
            },
          })
          break

        case SDK_EVENTS.SET_VENDOR:
          dataDispatch({
            type: DataAction.SET_VENDOR,
            payload: {
              vendor: event.payload,
            },
          })
          break

        case SDK_EVENTS.SET_RIDER:
          dataDispatch({
            type: DataAction.SET_RIDER,
            payload: {
              rider: event.payload,
            },
          })
          break
      }
    },
    [setViewState, dataDispatch, sessionDispatch],
  )
}
