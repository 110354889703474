import i18n from 'i18next'
import React, { PropsWithChildren, useMemo } from 'react'
import { I18nextProvider } from 'react-i18next'
import { Language } from 'types'

export type Translation = {
  [p: string]: string | Translation
}

export interface TranslationProviderProps {
  translations: Partial<Record<Language, Translation>>
  lang: Language
}

export const TranslationProvider = ({
  translations,
  lang,
  children,
}: PropsWithChildren<TranslationProviderProps>) => {
  const instance = useMemo(() => {
    const instance = i18n.createInstance()

    instance.init({
      fallbackLng: 'en',
      debug: false,
      resources: Object.keys(translations).reduce((result, lang: Language) => {
        result[lang] = {
          translation: translations[lang],
        }
        return result
      }, {} as { [p in Language]: { translation: Translation } }),
      lng: lang, // current language
      interpolation: {
        escapeValue: false,
      },
    })

    return instance
    // we disable hook dependencies, as this is initialized only once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lang])

  return <I18nextProvider i18n={instance}>{children}</I18nextProvider>
}
