import { ApiService } from 'services/authApi/getPermissions'
import { SetPrimaryDeliveryResponse } from 'types/api/fulfillmentApi/fulfillment'
import { logUnknowRiderError } from 'utils/logUnknownRiderError'

export const putSetPrimaryDelivery: ApiService<
  {
    entityId: string
    orderId: string
    deliveryId: number
  },
  SetPrimaryDeliveryResponse
> = (createClient, options) => {
  const {
    entityId,
    orderId,
    deliveryId,

    clientParams,
  } = options

  const uri = `/FulfillmentAPI/v1/orders/${entityId}/${orderId}/delivery/${deliveryId}/primary`

  return createClient({
    endpointName: 'putSetPrimaryDelivery',
    expectedResponseStatusCode: 200,
    ...clientParams,
    onSuccess: logUnknowRiderError,
  }).put<SetPrimaryDeliveryResponse>(uri)
}
