import { PaymentMethods } from 'contexts/entity/types'
import { UnifiedIconName } from 'contexts/IconPacksProvider'
import capitalize from 'lodash/capitalize'

type Meta = {
  icon: UnifiedIconName
  label: string
}

export const getPaymentMethodMeta = (paymentMethod: PaymentMethods): Meta => {
  switch (paymentMethod) {
    case PaymentMethods.COD:
    case PaymentMethods.CASH:
    case PaymentMethods.Efectivo:
      return { label: 'Cash', icon: 'CashIcon' }
    case PaymentMethods.Visa:
    case PaymentMethods.CREDITCARD:
    case PaymentMethods.Mastercard:
    case PaymentMethods.credit_card:
    case PaymentMethods.Spreedly_AR:
    case PaymentMethods.Spreedly_CL:
    case PaymentMethods.VisaNet_VISA_DEBIT:
    case PaymentMethods.VisaNet_VISA_CREDIT:
    case PaymentMethods.VisaNet_VISA_PREPAID:
    case PaymentMethods.cybersource_creditcard:
    case PaymentMethods.Spreedly_AR_VISA_DEBIT:
    case PaymentMethods.Spreedly_PE_VISA_DEBIT:
    case PaymentMethods.Spreedly_CL_VISA_CREDIT:
    case PaymentMethods.Spreedly_AR_VISA_CREDIT:
    case PaymentMethods.Spreedly_CL_MASTERCARD_CREDIT:
    case PaymentMethods.Spreedly_AR_MASTERCARD_CREDIT:
    case PaymentMethods.Spreedly_AR_AMERICAN_EXPRESS_CREDIT:
      return { label: capitalize(paymentMethod), icon: 'CreditCardIcon' }
    case PaymentMethods.No_Payment_Zero_Payable_Amount:
      return { label: 'Voucher', icon: 'VoucherIcon' }
    case PaymentMethods.Paypal:
      return { label: 'PayPal', icon: 'PayPalIcon' }
    case PaymentMethods.WALLET:
    case PaymentMethods.balance:
      return { label: 'Wallet', icon: 'WalletOutlined' }
    case PaymentMethods.ApplePay:
    case PaymentMethods.APPLE_PAY:
      return { label: 'Apple Pay', icon: 'ApplePayIcon' }
    case PaymentMethods.GooglePay:
      return { label: 'GooglePay', icon: 'GooglePayIcon' }
    case PaymentMethods.gift_card:
      return { label: 'Gift Card', icon: 'GiftCardIcon' }
    case PaymentMethods.Invoice:
      return { label: 'Corporate Allowance', icon: 'AllowanceIcon' }
    case PaymentMethods.KNET:
    case PaymentMethods.Klarna:
    case PaymentMethods.Sofort:
    case PaymentMethods.Molpay:
    case PaymentMethods.BENEFIT:
    case PaymentMethods.adyen_hpp_swish:
    case PaymentMethods.adyen_hpp_vipps:
    case PaymentMethods.adyen_hpp_boost:
    case PaymentMethods.WebPay_CL:
    case PaymentMethods.reddot_paylah:
    case PaymentMethods.antfinancial_gcash:
    case PaymentMethods.antfinancial_bkash:
    case PaymentMethods.antfinancial_truemoney:
    case PaymentMethods.checkout_psp_mobilepay:
    case PaymentMethods.checkout_psp_online_banking:
    default:
      return { label: capitalize(paymentMethod), icon: 'DigitalPaymentIcon' }
  }
}
