import { SingleComment, SingleCommentSource } from 'types/api/commentApi/getOrderComments'
import { CommentTag } from 'widgets/SummaryPanel/components/CommentsSummary/CommentTags'

export const transformUniqueOrderComments = (comments: SingleComment[] = []) => {
  if (!comments.length) return []

  const tagsMap = comments.reduce((obj, comment) => {
    if (!obj[comment.action]) {
      obj[comment.action] = {
        id: comment.id,
        label: comment.action,
        count: 0,
        source: comment.source ?? SingleCommentSource.oneview,
        timestamp: comment.created_at,
      }
    }
    const currentValue = obj[comment.action]

    currentValue.count += 1
    currentValue.timestamp =
      currentValue.timestamp > comment.created_at ? comment.created_at : currentValue.timestamp

    return obj
  }, {} as Record<string, CommentTag>)
  // sort by oldest first
  return Object.values(tagsMap).sort((a, b) => (a.timestamp > b.timestamp ? 1 : -1))
}
