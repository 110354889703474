export enum PaymentStatusesApiResponse {
  refundShopToWallet = 'RefundShopToWallet',
  refundShopToSource = 'RefundShopToSource',
  partialRefundShopToWallet = 'PartialRefundShopToWallet',
  partialRefundShopToSource = 'PartialRefundShopToSource',
}

export interface PaymentT {
  paymentHistory: PaymentHistory[]
  paymentDetails: PaymentDetails[]
}

interface PaymentHistory {
  date: string
  status: string
  amount: string
  breakDown: Breakdown[]
}

interface Breakdown {
  method: string
  amount: string
}
interface PaymentDetails {
  label: string
  value: string
}
