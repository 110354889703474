export default {
  listItem: {
    '& .ant-list-item': {
      padding: '8px 16px',
    },
    '& .ant-list-item-meta-content': {
      display: 'flex',
      flexDirection: 'column-reverse',
    },
  },
}
