import React from 'react'
import { Typography } from 'antd'
import { useTranslation } from 'hooks/useTranslation'
import { createUseStyles } from 'react-jss'
import { SingleComment } from 'types/api/commentApi/getOrderComments'
import capitalizeFirstLetters from 'utils/capitalizeFirstLetters'
import { getAgentNameFromId } from 'utils/string'
import ReportVoucherPaymentRefundItems from 'widgets/HistoryOfEvents/OrderHistory/ReportVoucherPaymentRefundItems'
import { commentTagType, CommentView } from './CommentView'
import styles from './Comments.styles'
import { WidgetSize } from 'types/widgets/historyOfEvents/tabs'

const { Text } = Typography
const useStyles = createUseStyles(styles)

type Props = {
  comment: SingleComment
  shouldCommentBeHighlighted: boolean
  mode: WidgetSize
}

const VoucherRefundComment = ({ comment, shouldCommentBeHighlighted, mode }: Props) => {
  const { t } = useTranslation()
  const classes = useStyles()

  const isPartialRefund = comment.additional_data.voucher_refund.refund_purpose === 'item_refund'

  return (
    <CommentView
      mode={mode}
      comment={comment}
      shouldCommentBeHighlighted={shouldCommentBeHighlighted}
      tag={{
        type: commentTagType.thunderbolt,
        text: isPartialRefund
          ? t('History of Events Widget.Event Types.Partial Refund')
          : t('History of Events Widget.Event Types.Full Refund'),
      }}
    >
      <Text className={classes.commentText}>
        <Text>
          {`${getAgentNameFromId(comment.agent_id)} ${t(
            'History of Events Widget.Content.Issued',
          )} ${
            isPartialRefund
              ? t('History of Events Widget.Content.Partial')
              : t('History of Events Widget.Content.Full')
          } ${t('History of Events Widget.Content.Refund')} `}
        </Text>
        <Text strong>
          {`${comment.additional_data.voucher_refund.voucher_currency} ${
            comment.additional_data.voucher_refund.voucher_value
          } ${t('History of Events Widget.Content.With voucher')} `}
        </Text>
        {isPartialRefund && (
          <ReportVoucherPaymentRefundItems data={comment?.additional_data?.voucher_refund} />
        )}
        <Text>{`${t('History of Events Widget.Content.Voucher Code')}: `}</Text>
        <Text strong>{`${comment.additional_data.voucher_refund.voucher_code}.`}</Text>
      </Text>
      <span>
        {comment?.additional_data?.voucher_refund?.contact_reason &&
          ` ${t('Actions Widget.Actions.Compensation.Issue Type')}: ${capitalizeFirstLetters(
            comment.additional_data.voucher_refund.contact_reason.replace(/_/g, ' '),
          )}.`}
      </span>
    </CommentView>
  )
}

export default VoucherRefundComment
