import { VoucherDataPoint } from 'types/api/voucherApi/voucher'

// May become configurable in future.
const defaultOrder = [
  // 'status', already in heading
  'description',
  'code',
  'valid_dates',
  'value',
  'min_order_value',
  'max_order_value',
  'expedition_types',
  'payment_types',
  'vertical_types',
  'schedules',
  'type',
  'order_id',
  'customer_code',
  'is_new_customer_only',
  'platforms',
  'vendors',
  'countries',
  'cities',
  'own_delivery_only',
  'opening_hour',
  'closing_hour',
  'times_per_user',
  'purpose',
  'cuisine',
  'max_discount_amount',
  'currency',
  'paid_by',
]

/**Function which sorts the VoucherDetails points array in given order of keys */
export const sortVoucherFields = (
  input: VoucherDataPoint[],
  order: string[] = defaultOrder,
): VoucherDataPoint[] => {
  const map = new Map()
  const output: VoucherDataPoint[] = []

  input.forEach((datum) => map.set(datum.id, datum))
  // order of keys to create final output array
  const finalOrderofKeys = new Set([...order, ...map.keys()])
  finalOrderofKeys.forEach((key) => {
    const item = map.get(key)
    // the item referred by default keys might not exists in input, so check
    if (item) {
      output.push(map.get(key))
    }
  })
  return output
}
