import { useCheckDisplayRules } from 'hooks/useCheckDisplayRules'
import React, { useContext, useImperativeHandle, useMemo, useState } from 'react'
import { DisplayRules, orderStages } from 'types/unitedUiConfig'
// contexts and types
import { DataContext } from 'contexts/data/DataContext'
import { DataAction } from 'contexts/data/types'
// hooks
import { useTranslation } from 'hooks/useTranslation'
// utils
import { sleep } from 'utils/sleep'
// styles
import { createUseStyles } from 'react-jss'
import styles from './CancelOrderView.styles'
import { Result, Button } from 'antd'

// components
import CancellationReasonDropdown from 'components/CancelOrder/CancellationReasonDropdown'
import { postOrderCancellation } from 'services/ordersApi/postOrderCancellation'
import { useClearCommentsFromDataContext } from 'hooks/useClearCommentsFromDataContext'

import { useApiService } from 'hooks/useApiService'
import { WidgetErrorHandler } from 'components/WidgetErrorHandler/widgetErrorHandler'
import { EMPTY_CATCH_CALLBACK } from 'constants/constants'
import { useCaptureUserAction } from 'hooks/events/useCaptureUserAction'
import { createPluggableWidget } from 'factory/createPluggableWidget'

const useStyles = createUseStyles(styles)

export interface CancelOrderReasonDefinition {
  reason: string
  reason_translation_key: string
  display_rules: DisplayRules
}

export const CancelOrderView = createPluggableWidget<{ reasons: CancelOrderReasonDefinition[] }>(
  ({ config, order, globalEntityId, onQuit, sdk }, ref) => {
    const { reasons } = config
    const { order_id: orderId } = order

    const checkDisplayRules = useCheckDisplayRules()

    const availableCancellationReasons = useMemo(() => {
      return reasons.filter((reason) => {
        return checkDisplayRules(reason.display_rules).enabled
      })
    }, [reasons, checkDisplayRules])

    const classes = useStyles()

    // pull language content
    const { t } = useTranslation()

    // pull data state and dispatch
    const { dataDispatch } = useContext(DataContext)

    const captureUserAction = useCaptureUserAction()

    const clearCommentsFromDataContext = useClearCommentsFromDataContext()

    const { SET_IS_ORDER_CANCELLED, SET_AUTO_CANCEL_ORDER_COMMENT } = DataAction

    // local state to hold selected cancel reason by user
    const [selectedCancelReason, setSelectedCancelReason] =
      useState<CancelOrderReasonDefinition>(null)
    const [orderUpdateStarted, setOrderUpdateStarted] = useState(false)

    // fired when a cancellation reason is selected
    const selectCancellationReason = (reason: CancelOrderReasonDefinition) => {
      captureUserAction('ActionsCancelOrderReasonSelected', {
        eventDetails: {
          initial: selectedCancelReason,
          selected: reason.reason,
        },
      })
      setSelectedCancelReason(reason)
    }

    const {
      loadService: executePostOrderCancellation,
      loading: isCancellingOrder,
      error: cancelOrderError,
      clearError: clearCancelOrderError,
      status: cancelStatus,
    } = useApiService({
      service: postOrderCancellation,
      autoLoad: false,
      deps: [],
    })

    // fired when confirmed -> sets loading spinner and posts cancelOrder
    const handleCancellationConfirmation = async () => {
      // TODO: remove state when the delay is removed
      setOrderUpdateStarted(true)

      executePostOrderCancellation({
        entityId: globalEntityId,
        orderId,
        cancelReason: selectedCancelReason.reason,
      })
        // HOTFIX: forcing a delay of 1.5 secs on the status update to allow the cancellation to be propagated.
        // This will be removed once the issue is resolved on the OrderAPI side or a better solution is found.
        // See https://jira.deliveryhero.com/browse/IVU-1921
        // TODO: check up on this, to see if it is okay to remove this short sleep
        .then(() => sleep(1_500))
        .then(() => {
          dataDispatch({
            type: SET_AUTO_CANCEL_ORDER_COMMENT,
            payload: { autoCancelOrderComment: orderId },
          })
          dataDispatch({
            type: SET_IS_ORDER_CANCELLED,
            payload: { isOrderCancelled: true },
          })
          clearCommentsFromDataContext()

          sdk.eventEmitter.dispatchEvent({
            name: 'CANCEL_ORDER_SUCCESS',
            payload: { orderId },
          })

          captureUserAction('CancelOrder', {
            eventDetails: {
              successfully_cancelled: true,
              cancel_reason: selectedCancelReason.reason,
              api_response: 200,
            },
          })
        })
        .catch(EMPTY_CATCH_CALLBACK)
        .finally(() => {
          setOrderUpdateStarted(false)
        })
    }

    const handleOkButtonClick = () => {
      captureUserAction('ActionsCancelOrderOkButtonClicked')
      onQuit(true)
    }

    // implement handles
    useImperativeHandle(
      ref,
      () => {
        return {
          onXButtonClick: () => {
            return !selectedCancelReason || cancelStatus === 'success'
          },
        }
      },
      [selectedCancelReason, cancelStatus],
    )

    return (
      <WidgetErrorHandler
        errorPayload={cancelOrderError?.errorPayload}
        loading={isCancellingOrder || orderUpdateStarted}
        displayType='overlay'
        onQuit={onQuit}
        onBack={clearCancelOrderError}
      >
        {() => {
          if (cancelStatus === 'success') {
            return (
              <React.Fragment>
                <Result
                  status={'success'}
                  title={t('Widgets Common.Success')}
                  subTitle={t(
                    'Actions Widget.Actions.Cancel Order.Cancellation Results.Order successfully cancelled',
                  )}
                />
                <div className={classes.buttonHolder}>
                  <Button type='primary' onClick={handleOkButtonClick}>
                    {t('Interface.OK')}
                  </Button>
                </div>
              </React.Fragment>
            )
          }

          return (
            <CancellationReasonDropdown
              lob={'customer'}
              orderId={orderId}
              isCancellingOrder={false}
              handleCancelOrder={handleCancellationConfirmation}
              selectCancellationReason={selectCancellationReason}
              selectedReason={selectedCancelReason?.reason}
              availableCancellationReasons={availableCancellationReasons}
            />
          )
        }}
      </WidgetErrorHandler>
    )
  },

  {
    category: 'action',
    deriveConfig({ entityConfig, lob }) {
      const { useCcrWhitelistForCancelOrderIssues } = entityConfig
      const { availableCancellationReasons, useStage } =
        entityConfig.fixed_panel_config.widgets_configs.actions.cancel_order

      return {
        reasons: availableCancellationReasons.map((value) => {
          return {
            reason: value.reason,
            reason_translation_key: value.translation_key,

            display_rules: {
              order_stages: {
                value: (value.stages || []) as orderStages[],
                ignore: !useStage,
              },
              delivery_providers: {
                value: value.providers || [],
                ignore: false,
              },
              ccr_whitelist: {
                value: value.ccrWhitelist || [],
                ignore: !useCcrWhitelistForCancelOrderIssues,
              },
            },
          }
        }),
      }
    },
  },
)
