import { SDK } from 'types'
import capitalize from 'lodash/capitalize'
import { getCustomerName } from 'utils/getCustomerName'
import { replaceUrl } from 'utils/getSearchParams'
import { getPaymentTextAndIcon } from 'utils/getters/getPaymentTextAndIcon'
import { getSplitDeliveryTags } from 'utils/getters/getSplitDeliveryTags'
import { getOrderEligibilities } from 'utils/order/getOrderEligibilities'
import { getOrderStage } from 'utils/order/getOrderStage'
import { getPodPinStatus } from 'utils/order/getPodPinStatus'
import { interpolate } from 'utils/string/interpolate'
import { getOrderPaidValue } from 'utils/getters/getOrderPaidValue'
import { getOrderDelayTime } from 'utils/getters/getOrderDelayTime'

export const staticMethods: Pick<
  SDK,
  | 'getOrderDelayTime'
  | 'getOrderStage'
  | 'getOrderEligibilities'
  | 'getPodPinStatus'
  | 'getCustomerName'
  | 'reloadPage'
  | 'navigateToPage'
  | 'interpolateString'
  | 'getOrderTags'
  | 'getPaymentTextAndIcon'
  | 'getOrderPaidValue'
> = {
  /**
   * get order delay time proxy
   */
  getOrderDelayTime({ order, fulfillment, datetimeFormatter }) {
    return getOrderDelayTime({ order, fulfillment, datetimeFormatter })
  },
  /**
   * get order stage proxy
   */
  getOrderStage({ orderStatusHistory }) {
    return getOrderStage(orderStatusHistory)
  },

  /**
   * get pod pin status proxy
   */
  getPodPinStatus({ order }) {
    return getPodPinStatus(order)
  },

  /**
   * get order eligibilities proxy
   */
  getOrderEligibilities,

  /**
   * get customer name proxy
   */
  getCustomerName({ customer, fallbackValue }) {
    return getCustomerName(customer, fallbackValue)
  },

  /**
   * reload page
   */
  reloadPage() {
    window.location.reload()
  },

  /**
   * navigates to new page
   */
  navigateToPage(options) {
    return replaceUrl(options)
  },

  /**
   * interpolates a string
   */
  interpolateString(str, replacerValues, options) {
    return interpolate(str, replacerValues, options)
  },

  /**
   * get order tags
   */
  getOrderTags({ order, fulfillment }) {
    let tags: string[] = []

    if (order) {
      if (order.vendor?.charges?.joker_discount_fee) {
        tags.push('Joker')
      }
      if (order.corporate) {
        tags.push('Corporate')
      }
      if (order.preorder) {
        tags.push('Pre-Order')
      }
      if (order.customer?.channel?.type) {
        tags.push(capitalize(order.customer.channel.type))
      }

      const splitDeliveryTags = getSplitDeliveryTags(fulfillment, 'Split Delivery')
      if (splitDeliveryTags.length) {
        tags.push(splitDeliveryTags[0].label)
      }

      const priorityDeliveryFee = order.delivery?.priority_fee
      if (typeof priorityDeliveryFee === 'number' && priorityDeliveryFee > 0) {
        tags.push('Priority Delivery')
      }
    }

    const { orderEligibilities } = getOrderEligibilities({ order })

    if (orderEligibilities?.length) {
      tags = tags.concat(orderEligibilities.map((item) => item.value))
    }

    return tags
  },

  /**
   * get payment type text label and icon proxy
   */
  getPaymentTextAndIcon({ paymentType }) {
    return getPaymentTextAndIcon(paymentType)
  },

  getOrderPaidValue,
}
