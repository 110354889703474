export enum SectionFilter {
  order = 'Order',
  customer = 'Customer',
  rider = 'Rider',
  vendor = 'Vendor',
  back_office = 'Back Office',
}

export enum TimeframeFilter {
  last_seven = 'Last 7 Days',
  last_fourteen = 'Last 14 Days',
  last_thirty = 'Last 30 Days',
  all = 'All (Last 90 Days)',
}

export enum TypeFilter {
  all = 'All',
}
