import { ApiService } from 'services/authApi/getPermissions'

export type RiderBreakType = 'break' | 'tmp'

export const putRiderOnBreak: ApiService<
  {
    entityId: string
    riderId: string
    duration: number // duration in minutes
    reason: string
    comment: string
    type: RiderBreakType
  },
  any
> = (createClient, options): Promise<any> => {
  const { entityId, riderId, clientParams = {}, ...body } = options

  const uri = `FulfillmentAPI/v1/couriers/${entityId}/${riderId}/start-break`

  return createClient({
    endpointName: 'putRiderOnBreak',
    expectedResponseStatusCode: 200,
    ...clientParams,
  }).put(uri, body)
}
