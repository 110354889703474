import { FeatureWithBetaRequirementConfig } from 'contexts/entity/types'
import { DisplayRules } from 'types/herocare'
import { DeliveryProviders, TimeUnits } from 'types/unitedUiConfig'

export const transformFeatureToDisplayRules = (
  feature: FeatureWithBetaRequirementConfig,
): DisplayRules => {
  const result: DisplayRules = {}

  if (!feature) {
    return result
  }

  if (feature.active === false) {
    result.hide = true
  }

  result.beta_requirements = {
    value: feature.betaRequirement || [],
  }

  result.ccr_whitelist = {
    value: feature.ccrWhitelist || [],
  }

  // payment methods
  result.payment_methods = {
    value: feature.paymentMethodMustBeOneOfRequirement || [],
  }

  // cancel reasons
  result.cancel_reasons = {
    value: feature.cancelReasonMustBeOneOfRequirement || [],
  }

  // order status history rules
  result.order_status_history_whitelist = {
    value: feature.orderStatusMustExistRequirement || [],
  }
  result.order_status_history_blacklist = {
    value: feature.orderStatusMustNotExistRequirement || [],
  }

  // courier events rules
  result.courier_events_whitelist = {
    value: feature.delivery_status_rule?.deliveryStatusMustExistRequirement || [],
  }
  result.courier_events_blacklist = {
    value: feature.delivery_status_rule?.deliveryStatusMustNotExistRequirement || [],
  }

  // max order age
  result.max_order_age = {
    value: feature.maxOrderAgeInHours || 0,
    unit: TimeUnits.hrs,
  }

  // delivery providers rules
  result.delivery_providers_rules = (feature.delivery_provider_rules || []).map((feature) => {
    return {
      delivery_providers: {
        value: [feature.provider as DeliveryProviders],
      },
      ...transformFeatureToDisplayRules({ ...feature, feature: 'random', betaRequirement: [] }),
    }
  })

  return result
}
