import { ApiService } from 'services/authApi/getPermissions'
import { RiderChatsData } from 'types/api/riderApi/riderChats'

export const getRiderChats: ApiService<
  {
    entityId: string
    orderId: string
  },
  RiderChatsData
> = (createClient, options) => {
  const { entityId, orderId, clientParams } = options

  const uri = `/CustomerAPI/v1/order/${entityId}/${orderId}/chats/rider`

  return createClient({
    endpointName: 'getRiderChats',
    expectedResponseStatusCode: 200,
    ...clientParams,
  }).get(uri)
}
