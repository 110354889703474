import React from 'react'
// hooks
import { useTranslation } from 'hooks/useTranslation'
// utils
// styles
import { createUseStyles } from 'react-jss'
import styles from './Status.styles'
import { Result } from 'antd'
// components
import { MapView, MapViewProps } from 'components/MapView/MapView'
import { ZoomInOutlined } from '@ant-design/icons'

const useStyles = createUseStyles(styles)

export interface MapProps extends MapViewProps {
  isStatusHistoryAvailable: boolean
}

export const Map = ({ isStatusHistoryAvailable, ...props }: MapProps) => {
  const classes = useStyles()

  // pull language content
  const { t } = useTranslation()

  return (
    <div className={classes.mapHolder}>
      <MapView
        {...props}
        defaultZoom={18}
        containerClassnameKey={isStatusHistoryAvailable ? 'smallContainer' : 'container'}
        emptyView={
          <Result
            icon={<ZoomInOutlined />}
            subTitle={t('Messages.No courier, customer or vendor location found')}
          />
        }
      />
    </div>
  )
}
