import { createUseStyles } from 'react-jss'

export const useUiV1TopLevelTabStyles = createUseStyles({
  tabs: {
    '& .ant-tabs-content': {
      paddingLeft: '12px',
      paddingRight: '12px',
    },
  },
})
