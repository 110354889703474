import { ApiService } from 'services/authApi/getPermissions'
import { OrderBilling, OrderBillingResponse } from 'types/api/orderApi/orderBilling'

export const getOrderBilling: ApiService<
  { globalEntityId: string; orderId: string; vendorId: string },
  OrderBilling
> = (createClient, options) => {
  const { globalEntityId, orderId, vendorId, clientParams } = options

  const uri = `/OrdersAPI/v1/orders/${globalEntityId}/${orderId}/billing/vendor/${vendorId}`

  return createClient({
    endpointName: 'getOrderBilling',
    expectedResponseStatusCode: 200,
    ...clientParams,
  })
    .get<OrderBillingResponse>(uri)
    .then((res) => {
      return {
        ...res,
        data: res?.data?.billing_order,
      }
    })
}
