export default {
  tagsContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    flexWrap: 'wrap',
    width: '100%',
  },
  tag: {
    marginTop: '2%',
    textTransform: 'capitalize',
  },
}
