import React from 'react'
import { createUseStyles } from 'react-jss'
import { Divider, Typography } from 'antd'

import fixNumber from 'utils/fixNumber'
import VoucherDetails from './VoucherDetails'
import { useTranslation } from 'hooks/useTranslation'
import styles from './Refund.styles'

const useStyles = createUseStyles(styles)

type Props = {
  currency: string
  totalRefundAmount: number
  totalRemovalAmount: number
  compensationAmount: number
  refundAmountPrecision: number
  voucherMinOrderValue: number
  voucherPaymentTypes: string
  voucherBeginDate: string
  voucherEndDate: string
  useFlexibleAmount?: boolean
}

const VoucherRefundMethod = ({
  currency,
  totalRefundAmount,
  compensationAmount,
  totalRemovalAmount,
  refundAmountPrecision,
  voucherMinOrderValue,
  voucherPaymentTypes,
  voucherBeginDate,
  voucherEndDate,
  useFlexibleAmount,
}: Props) => {
  const classes = useStyles()
  const { Text } = Typography

  // pull translations
  const { t } = useTranslation()
  return (
    <React.Fragment>
      {/* item info -> item, compensation and total refund amounts */}
      {!useFlexibleAmount ? (
        <div className={classes.textBlock}>
          <Text className={classes.titleText}>
            {`${t('Actions Widget.Actions.Full Refund.Items Amount')}:`}
          </Text>
          <Text className={classes.dataText}>{`${currency} ${fixNumber(
            totalRemovalAmount,
            refundAmountPrecision,
          )}`}</Text>
        </div>
      ) : null}
      {useFlexibleAmount ? (
        <div className={classes.textBlock}>
          <Text className={classes.titleText}>
            {`${t('Actions Widget.Actions.Full Refund.Refund Amount')}:`}
          </Text>
          <Text className={classes.dataText}>{`${currency} ${fixNumber(
            totalRefundAmount,
            refundAmountPrecision,
          )}`}</Text>
        </div>
      ) : null}
      <div className={classes.textBlock}>
        <Text className={classes.titleText}>
          {`${t('Actions Widget.Actions.Full Refund.Compensation Amount')}:`}
        </Text>
        <Text className={classes.dataText}>{`${currency} ${fixNumber(
          compensationAmount,
          refundAmountPrecision,
        )}`}</Text>
      </div>
      <div className={classes.textBlockTotalAmount}>
        <Text className={classes.titleText}>
          {`${t('Actions Widget.Actions.Partial Refund.Total Refund and Compensation Amount')}:`}
        </Text>
        <Text className={classes.dataTextTotalAmount}>{`${currency} ${fixNumber(
          totalRefundAmount,
          refundAmountPrecision,
        )}`}</Text>
      </div>

      <Divider />
      <VoucherDetails
        currency={currency}
        totalRefundAmount={fixNumber(totalRefundAmount, refundAmountPrecision)}
        voucherMinOrderValue={fixNumber(voucherMinOrderValue, refundAmountPrecision)}
        voucherPaymentTypes={voucherPaymentTypes}
        validDates={`${voucherBeginDate} – ${voucherEndDate}`}
      />
    </React.Fragment>
  )
}

export default VoucherRefundMethod
