// libs
import React, { useState, useContext } from 'react'

// contexts and types
import { RiderServiceContext } from 'contexts/riderService/RiderServiceContext'
import { SessionContext } from 'contexts/session/SessionContext'
import { EntityContext } from 'contexts/entity/EntityContext'
// hooks
import { useTranslation } from 'hooks/useTranslation'
import { createClient } from 'hooks/useFetchData'
import { RiderServiceAction } from 'contexts/riderService/types'

// utils
import capitalizeFirstLetters from 'utils/capitalizeFirstLetters'
import environment from 'envConfig'
import createOneviewHeaders from 'utils/oneviewApi/createOneviewHeaders'
import processApiErrors from 'utils/oneviewApi/processApiErrors'
import ensureJwtIsValid from 'utils/oneviewApi/ensureJwtIsValid'

// styles
import { createUseStyles } from 'react-jss'
import styles from './ChatTranscript.styles'
import { Button, Menu, Modal } from 'antd'
import { WechatOutlined } from '@ant-design/icons'
// components
import ChatTranscriptView from 'components/ChatTranscriptView'
import LoadingView from 'components/LoadingView'
import ErrorView from 'components/ErrorView'
import { useRiderChatsStates } from 'hooks/useRiderChatsState'
import { useCaptureUserAction } from 'hooks/events/useCaptureUserAction'
import { InlineDropdown } from 'shared/InlineDropdown'

const useStyles = createUseStyles(styles)

const ChatTranscript: React.FC = () => {
  const classes = useStyles()

  // pull language content
  const { t } = useTranslation()

  // pull orderId from session context
  const {
    sessionState: { orderId, globalEntityId, caseId },
  } = useContext(SessionContext)

  // pull partial refund configs
  const {
    entityState: {
      entityConfig: { utc_zone },
    },
  } = useContext(EntityContext)

  const captureUserAction = useCaptureUserAction()

  // pull riderCustomerChatTranscript from data context
  const {
    riderServiceState: { riderCustomerChatTranscript },
    riderServiceDispatch,
  } = useContext(RiderServiceContext)
  const { SET_RIDER_CUSTOMER_CHAT } = RiderServiceAction
  const [isChatTrans, setIsChatTrans] = useState(false)

  const handleChatTranscriptClick = () => {
    if (error) {
      captureUserAction('OrderTabChatTranscriptErrorScreenXButtonClicked')
    } else {
      captureUserAction('OrderTabChatTranscriptXButtonClicked')
    }
    setIsChatTrans(false)
  }
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [error, setError] = useState<any>(null)

  const riderChatsState = useRiderChatsStates(riderCustomerChatTranscript, utc_zone)

  const { setSelectedLanguage, selectedLanguage, availableTranslatedLanguages } = riderChatsState

  // fired when a translations is selected or hide translations clicked
  const handleCancelReasonSelection = (language: string) => {
    if (language) {
      setSelectedLanguage(language)
      return
    }

    setSelectedLanguage('')
  }

  const handleFetchAndOpenChatTranscript = async () => {
    setIsChatTrans(true)
    captureUserAction('OrderTabViewChatTransriptClicked')

    const getRiderCustomerChatTranscript = async () => {
      const uri = `${environment().oneviewApiRoot}${
        environment().oneviewCustomerApiRoot
      }/order/${globalEntityId}/${orderId}/chats/rider`

      const headers = createOneviewHeaders()

      setError(null)
      setIsLoading(true)
      await createClient()
        .get(uri, headers)
        .then((value) => {
          if (value?.status === 200 && value?.data) {
            // set rider customer chat transcript to context
            riderServiceDispatch({
              type: SET_RIDER_CUSTOMER_CHAT,
              payload: { riderCustomerChatTranscript: value.data },
            })
          }
        })
        .catch((err) => {
          setError(err)
          // when err, process err to log it & update data context
          processApiErrors(t, err, 'GetCustomerRiderChatsByOrder', orderId, globalEntityId, true)
          riderServiceDispatch({
            type: SET_RIDER_CUSTOMER_CHAT,
            payload: { riderCustomerChatTranscript: undefined },
          })
        })
        .finally(() => {
          setIsLoading(false)
        })
    }

    // ensure JWT is valid before firing API client
    const isTokensValid = await ensureJwtIsValid(caseId)

    if (isTokensValid) {
      getRiderCustomerChatTranscript()
    }
  }

  const errorCallBackHandle = () => {
    captureUserAction('OrderTabChatTranscriptErrorScreenTryAgainButtonClicked')
    handleFetchAndOpenChatTranscript()
  }

  const handleClickShowTranslation = () => {
    captureUserAction('OrderTabChatTranscriptShowTranslationClicked')
  }

  const handleClickHideTranslation = () => {
    captureUserAction('OrderTabChatTranscriptHideTranslationClicked')
    handleCancelReasonSelection('')
  }

  return (
    <>
      <Modal
        title={
          availableTranslatedLanguages?.length ? (
            <div>
              <InlineDropdown
                className={classes.dropdown}
                overlay={
                  <Menu className={classes.dropdownMenu}>
                    {/* display a hide translations option, if there is already a selected one */}
                    {selectedLanguage && (
                      <Menu.Item
                        key={0}
                        className={classes.dropdownMenuItem}
                        onClick={handleClickHideTranslation}
                      >
                        {t('Order Widget.Tabs.Summary.Hide translations')}
                      </Menu.Item>
                    )}

                    {/* map over and display each translation key */}
                    {availableTranslatedLanguages.map((lang: string, idx: number) => (
                      <Menu.Item
                        className={classes.dropdownMenuItem}
                        key={idx + 1}
                        onClick={() => handleCancelReasonSelection(lang)}
                      >
                        {capitalizeFirstLetters(lang)}
                      </Menu.Item>
                    ))}
                  </Menu>
                }
                trigger={['click']}
              >
                <Button
                  type='link'
                  size='small'
                  className={classes.button}
                  onClick={handleClickShowTranslation}
                >
                  {/* dropdown button text is either selected translation language or show translations */}
                  {selectedLanguage
                    ? `${t('Order Widget.Tabs.Summary.Translation')}: ${capitalizeFirstLetters(
                        selectedLanguage,
                      )}`
                    : t('Order Widget.Tabs.Summary.Show Translations')}
                </Button>
              </InlineDropdown>
            </div>
          ) : (
            <div className={classes.titleSeparator}></div>
          )
        }
        className={classes.modal}
        visible={isChatTrans}
        width={305}
        onCancel={handleChatTranscriptClick}
        footer={null}
        destroyOnClose
        maskStyle={{ backgroundColor: '#04294a', opacity: 1 }}
      >
        {isLoading && <LoadingView text={`${t('Interface.Please Wait')}...`} />}
        {!isLoading && !!riderCustomerChatTranscript && !error && (
          <ChatTranscriptView
            isRiderService
            orderId={orderId}
            chatTranscripts={riderCustomerChatTranscript}
            utcZone={utc_zone}
          />
        )}
        {!!error && (
          <ErrorView
            errLogEntry={processApiErrors(
              t,
              error,
              'GetCustomerRiderChatsByOrder',
              orderId,
              globalEntityId,
              true,
            )}
            buttonText={t('Interface.Try Again')}
            buttonCallBack={errorCallBackHandle}
          />
        )}
      </Modal>
      <div className={classes.chatTranscriptHolder}>
        <Button
          type='link'
          className={classes.chatTranscriptButton}
          onClick={handleFetchAndOpenChatTranscript}
        >
          <WechatOutlined className={classes.chatTranscriptIcon} />
          {t('Order Widget.Tabs.Summary.View Chat Transcript')}
        </Button>
      </div>
    </>
  )
}

export default ChatTranscript
