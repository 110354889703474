/**
 * dumb component rendering a button opening up a modal
 * */

// libs
import React from 'react'
// styles
import { createUseStyles } from 'react-jss'
import styles from './ModalButtonView.styles'
import { Button } from 'antd'
import { SmartToolTip } from 'shared/SmartTooltip'

const useStyles = createUseStyles(styles)

interface Props {
  buttonText: string
  callBack: (e) => void
  name?: string
  disabled?: boolean
  reason?: string
}

const ModalButtonView: React.FC<Props> = ({
  buttonText,
  reason,
  disabled,
  callBack,
  name,
  ...others
}) => {
  const classes = useStyles()

  return (
    <SmartToolTip title={disabled && reason}>
      <Button
        type='primary'
        size='small'
        onClick={callBack}
        name={name}
        disabled={disabled}
        {...others}
        className={classes.buttonAndModalContainer}
      >
        {buttonText}
      </Button>
    </SmartToolTip>
  )
}

export default ModalButtonView
