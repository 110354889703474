import React from 'react'
import { text } from 'theme'
import { SlimHtmlProps } from 'types'
import { Box, BoxStyleOptions } from '../Box'

const Primary = ({
  color = text.primary,
  lineHeight = '22px',
  fontSize = '14px',
  ...props
}: SlimHtmlProps<BoxStyleOptions>) => (
  <Box color={color} lineHeight={lineHeight} fontSize={fontSize} {...props} />
)

const Secondary = ({
  color = text.secondary,
  lineHeight = '20px',
  fontSize = '12px',
  ...props
}: SlimHtmlProps<BoxStyleOptions>) => (
  <Box color={color} lineHeight={lineHeight} fontSize={fontSize} {...props} />
)

export const Text = {
  Text: Box,
  Primary,
  Secondary,
}
