import { DataContext } from 'contexts/data/DataContext'
import { DataAction } from 'contexts/data/types'
import { SessionContext } from 'contexts/session/SessionContext'
import { SessionAction } from 'contexts/session/types'
import { useApiService } from 'hooks/useApiService'
import { useContext } from 'react'
import { getVendor } from 'services/vendorApi/getVendor'

const { SET_VENDOR } = DataAction

export function useLoadVendor() {
  const {
    sessionState: { globalEntityId, vendorId, globalVendorId },
    sessionDispatch,
  } = useContext(SessionContext)

  const {
    dataDispatch,
    dataState: { vendor },
  } = useContext(DataContext)

  let shouldLoad = Boolean(globalVendorId || vendorId)

  if (vendor && vendor.vendor_id === vendorId) {
    shouldLoad = false
  }

  return useApiService({
    service: getVendor,

    params: {
      entityId: globalEntityId,
      vendorId,
      globalVendorId,
      clientParams: {
        cacheable: true,
      },
    },

    shouldLoad,

    onSuccess(res) {
      dataDispatch({
        type: SET_VENDOR,
        payload: { vendor: res.data },
      })

      sessionDispatch({
        type: SessionAction.SET_SESSION,
        payload: {
          globalVendorId: res.data.global_vendor_id || globalVendorId,
          vendorId: res.data.vendor_id || vendorId,
        },
      })
    },
  })
}
