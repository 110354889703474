/**
 * Modift Delivery Address, rendered upon click in actions
 * renders a modal with a map and locations of old and new addresses
 * */

// libs
import React, { useState, useEffect, useContext, useRef, useImperativeHandle } from 'react'
// contexts and types
import { SessionContext } from 'contexts/session/SessionContext'
import { DataContext } from 'contexts/data/DataContext'
import { DataAction } from 'contexts/data/types'
import { SingleCountry } from 'types/actions/countries'
import { ModifyAddressPostBody } from 'types/api/orderApi/order'
import { LinesOfBusiness } from 'types/session/linesOfBusiness'

// hooks
import { useTranslation } from 'hooks/useTranslation'
// utils
import getDifferenceInCoordinates from 'utils/getDifferenceInCoordinates'
import countries from 'utils/countries'

// styles
import { createUseStyles } from 'react-jss'
import styles from './ModifyDeliveryAddressView.styles'
import { Typography, Result, Button } from 'antd'
// compponents
import MainInput from './MainInput'
import GoogleMap from './GoogleMap'
import NewAddress from './NewAddress'
import DeliveryInstructions from './DeliveryInstructions'
import Actions from './Actions'
import { patchDeliveryAddress } from 'services/ordersApi/patchDeliveryAddress'
import { useClearCommentsFromDataContext } from 'hooks/useClearCommentsFromDataContext'
import { patchDeliveryInstructions } from 'services/ordersApi/patchDeliveryInstructions'
import { useOrderStatus } from 'hooks/useOrderStatus'
import { useApiService } from 'hooks/useApiService'
import { WidgetErrorHandler } from 'components/WidgetErrorHandler/widgetErrorHandler'
import { useCaptureUserAction } from 'hooks/events/useCaptureUserAction'
import { ChangeDeliveryAddress } from 'contexts/entity/types'
import { createPluggableWidget } from 'factory/createPluggableWidget'

const useStyles = createUseStyles(styles)

export const ModifyDeliveryAddressView = createPluggableWidget<ChangeDeliveryAddress>(
  ({ onQuit, config, order, vendor, lob, globalEntityId, sdk }, ref) => {
    const classes = useStyles()
    const { Text } = Typography

    // pull translations
    const { t } = useTranslation()

    const clearCommentsFromDataContext = useClearCommentsFromDataContext()

    const orderStatus = useOrderStatus()

    // pull order ID from session context
    const {
      sessionState: { orderId },
    } = useContext(SessionContext)

    // pull dataState and dispatch from data context
    const { dataDispatch } = useContext(DataContext)
    const captureUserAction = useCaptureUserAction()

    const {
      SET_MODIFIED_ORDER_ADDRESS,
      SET_AUTO_CHANGE_ADDRESS_COMMENT,
      SET_AUTO_CHANGE_INSTRUCTIONS_COMMENT,
      SET_AUTO_CALLBACK_COMMENT,
    } = DataAction

    const suggestionsShownRef = useRef(false)

    const setSuggestionsShown = () => (suggestionsShownRef.current = true)

    // determine country
    const [currentCountryOfOrder, setCurrentCountryOfOrder] = useState('')
    useEffect(() => {
      countries?.forEach((c: SingleCountry) => {
        if (c.entityId === globalEntityId) {
          setCurrentCountryOfOrder(c.entityName)
        }
      })
    }, [globalEntityId])

    const [isNewAddressDeliverable, setIsNewAddressDeliverable] = useState(false)

    const [currentDeliveryAddress, setCurrentDeliveryAddress] = useState('')
    const [currentDeliveryInstructions, setCurrentDeliveryInstructions] = useState('')
    const [currentAddressLat, setCurrentAddressLat] = useState<number>(null)
    const [currentAddressLng, setCurrentAddressLng] = useState<number>(null)

    const [newAddressLat, setNewAddressLat] = useState<number>(null)
    const [newAddressLng, setNewAddressLng] = useState<number>(null)
    const [newAddressMain, setNewAddressMain] = useState('')
    const [newAddressFloor, setNewAddressFloor] = useState('')
    const [newAddressCompany, setNewAddressCompany] = useState('')
    const [newAddressApartment, setNewAddressApartment] = useState('')
    const [newAddressStreetNumber, setNewAddressStreetNumber] = useState('')
    const [newAddressStreet, setNewAddressStreet] = useState('')
    const [newAddressPostalcode, setNewAddressPostalcode] = useState('')
    const [isDeliveryInstructionsInputActive, setIsDeliveryInstructionsInputActive] =
      useState(false)
    const [newDeliveryInstructions, setNewDeliveryInstructions] = useState('')

    // calls google MAPs to get address suggestions
    const [isNewAddressSelected, setisNewAddressSelected] = useState(false)

    // effect to set current address, lat and lng, and instructions
    useEffect(() => {
      if (order?.delivery?.location?.address_text) {
        setCurrentDeliveryAddress(order.delivery.location.address_text)
      }
      if (order?.delivery?.location?.description) {
        setCurrentDeliveryInstructions(order.delivery.location.description)
        setNewDeliveryInstructions(order.delivery.location.description)
      }
      if (order?.delivery?.location?.latitude) {
        setCurrentAddressLat(order.delivery.location.latitude)
      }
      if (order?.delivery?.location?.longitude) {
        setCurrentAddressLng(order.delivery.location.longitude)
      }
    }, [order])

    // calcualte the difference in KM between current and new address
    const [differenceBetweenAddressesInKm, setDifferenceBetweenAddressesInKm] =
      useState<number>(null)
    useEffect(() => {
      if (isNewAddressSelected) {
        // set 2 variables according to config: they are coordinates either for new address or vendor's location
        let latForDistanceCalculation = 0
        let lngForDistanceCalculation = 0

        if (config.showDistanceFromOldToNewAddress) {
          latForDistanceCalculation = currentAddressLat
          lngForDistanceCalculation = currentAddressLng
        }

        if (config.showDistanceFromSourceToNewAddress) {
          if (vendor?.location?.latitude && vendor?.location?.longitude) {
            latForDistanceCalculation = vendor.location.latitude
            lngForDistanceCalculation = vendor.location.longitude
          }
        }

        if (latForDistanceCalculation && lngForDistanceCalculation) {
          setDifferenceBetweenAddressesInKm(
            getDifferenceInCoordinates(
              newAddressLat,
              newAddressLng,
              latForDistanceCalculation,
              lngForDistanceCalculation,
            ),
          )
        }
      }
      // eslint-disable-next-line
    }, [isNewAddressSelected, currentAddressLat, currentAddressLng, newAddressLat, newAddressLng])

    // fired when one iof the extra address fields changed -> sets the state of the relevant field
    const handleExtraInputFieldChange = (e: any, field: string) => {
      if (field === 'floor') {
        setNewAddressFloor(e.target.value)
      } else if (field === 'company') {
        setNewAddressCompany(e.target.value)
      } else if (field === 'apartment') {
        setNewAddressApartment(e.target.value)
      }
    }

    const handleDeliveryInstructionsChange = (e: any) => {
      setNewDeliveryInstructions(e.target.value)
    }

    // fired when Save is clicked -> fires client with new address
    const [successfulUpdateRequestBody, setSuccessfulUpdateRequestBody] =
      useState<ModifyAddressPostBody>(null)

    const ignoreAddressValidation =
      (lob === LinesOfBusiness.rider || lob === LinesOfBusiness.riderV2) &&
      (typeof config.ignoreVendorDeliveryAreaValidation === 'boolean'
        ? config.ignoreVendorDeliveryAreaValidation
        : true)

    const {
      loadService: executePatchDeliveryAddress,
      status: patchDeliveryAddressStatus,
      error: patchDeliveryAddressError,
      loading: isPatchingDeliveryAddress,
      clearError: clearPatchDeliveryAddressError,
    } = useApiService({
      service: patchDeliveryAddress,
      deps: [],
      autoLoad: false,
    })

    const {
      loadService: executePatchDeliveryInstructions,
      status: patchDeliveryInstructionsStatus,
      error: patchDeliveryInstructionsError,
      loading: isPatchingDeliveryInstructions,
      clearError: clearPatchDeliveryInstructionsError,
    } = useApiService({
      service: patchDeliveryInstructions,
      deps: [],
      autoLoad: false,
    })

    const handleNewAddressSaveClick = async () => {
      // async func posting voucher
      const patchNewAddress = async () => {
        // client details
        const body: any = {
          latitude: newAddressLat,
          longitude: newAddressLng,
          formatted_address: newAddressMain,
          company: newAddressCompany ? newAddressCompany : '',
          street: newAddressStreet ? newAddressStreet : '',
          number: newAddressStreetNumber ? newAddressStreetNumber : '',
          floor: newAddressFloor ? newAddressFloor : '',
          suburb: '',
          block: '',
          building: '',
          apartment: newAddressApartment ? newAddressApartment : '',
          entrance: '',
          intercom: '',
          zipcode: newAddressPostalcode ? newAddressPostalcode : '',
          skip_delivery_area_validation: ignoreAddressValidation,
        }

        if (newDeliveryInstructions && newDeliveryInstructions !== currentDeliveryInstructions) {
          body.instructions = newDeliveryInstructions
        }

        await executePatchDeliveryAddress({
          entityId: globalEntityId,
          orderId,
          ...body,

          // used for translation
          orderStatus,
        })
          .then(() => {
            sdk.eventEmitter.dispatchEvent({
              name: 'CHANGE_ADDRESS_SUCCESS',
              payload: { orderId },
            })
            captureUserAction('ActionsChangeAddressSuccess', {
              eventDetails: {
                'address-modified': !(body.instructions && !newAddressMain),
                'floor-is-set': !!body.floor,
                'appartment-is-set': !!body.apartment,
                'company-is-set': !!body.company,
                'delivery-instructions-modified': !(!body.instructions && newAddressMain),
              },
            })
            setSuccessfulUpdateRequestBody(body)

            dataDispatch({
              type: SET_AUTO_CHANGE_ADDRESS_COMMENT,
              payload: { autoChangeAddressComment: orderId },
            })
            dataDispatch({
              type: SET_AUTO_CALLBACK_COMMENT,
              payload: { autoModifyCallbackComment: undefined },
            })

            clearCommentsFromDataContext()

            // set delivery inst comment if it changed
            if (newDeliveryInstructions) {
              dataDispatch({
                type: SET_AUTO_CHANGE_INSTRUCTIONS_COMMENT,
                payload: { autoChangeInstructionsComment: orderId },
              })
            }
          })
          .catch((err) => {
            captureUserAction('ActionsChangeAddressError', {
              eventDetails: {
                'address-modified': !(body.instructions && !newAddressMain),
                'floor-is-set': !!body.floor,
                'appartment-is-set': !!body.apartment,
                'company-is-set': !!body.company,
                'delivery-instructions-modified': !(!body.instructions && newAddressMain),
                error: err.response.status,
              },
            })
            // set comment for modify callback and clean other auto comments from state
            dataDispatch({
              type: SET_AUTO_CALLBACK_COMMENT,
              payload: { autoModifyCallbackComment: orderId },
            })
            dataDispatch({
              type: SET_AUTO_CHANGE_ADDRESS_COMMENT,
              payload: { autoChangeAddressComment: undefined },
            })
            dataDispatch({
              type: SET_AUTO_CHANGE_INSTRUCTIONS_COMMENT,
              payload: { autoChangeInstructionsComment: undefined },
            })

            clearCommentsFromDataContext()
          })
      }

      const patchNewInstructions = () => {
        const body: ModifyAddressPostBody = {
          instructions: newDeliveryInstructions || currentDeliveryInstructions,
        }

        return executePatchDeliveryInstructions({
          entityId: globalEntityId,
          orderId,
          ...body,
          // used for translation
          orderStatus,
        })
          .then(() => {
            setSuccessfulUpdateRequestBody(body)
            sdk.eventEmitter.dispatchEvent({
              name: 'CHANGE_DELIVERY_INSTRUCTIONS_SUCCESS',
              payload: { orderId },
            })
            dataDispatch({
              type: SET_AUTO_CHANGE_INSTRUCTIONS_COMMENT,
              payload: { autoChangeInstructionsComment: orderId },
            })
            dataDispatch({
              type: SET_AUTO_CALLBACK_COMMENT,
              payload: { autoModifyCallbackComment: undefined },
            })

            clearCommentsFromDataContext()
          })
          .catch(() => {
            // set comment for modify callback and clean other auto comments from state
            dataDispatch({
              type: SET_AUTO_CALLBACK_COMMENT,
              payload: { autoModifyCallbackComment: orderId },
            })
            dataDispatch({
              type: SET_AUTO_CHANGE_ADDRESS_COMMENT,
              payload: { autoChangeAddressComment: undefined },
            })
            dataDispatch({
              type: SET_AUTO_CHANGE_INSTRUCTIONS_COMMENT,
              payload: { autoChangeInstructionsComment: undefined },
            })

            clearCommentsFromDataContext()
          })
      }

      captureUserAction('ActionsChangeAddressSaveButtonClicked')
      const { validateAddress } = config
      if (
        isNewAddressSelected &&
        ((validateAddress && isNewAddressDeliverable) ||
          !validateAddress ||
          ignoreAddressValidation)
      ) {
        // if new address selected, post new address -> either with new delivery instructions or old delivery instructions
        patchNewAddress()
      } else {
        // if address is not selected, post new instructions
        patchNewInstructions()
      }
    }

    // fired when X icon is clicked -> cleans to input related state
    const handleClearAddressClick = () => {
      setisNewAddressSelected(false)
      setNewAddressLat(null)
      setNewAddressLng(null)
      setNewAddressMain('')
      setNewAddressFloor('')
      setNewAddressCompany('')
      setNewAddressApartment('')
      setNewAddressStreetNumber('')
      setNewAddressStreet('')
      setNewAddressPostalcode('')
    }

    const isSuccess =
      patchDeliveryAddressStatus === 'success' || patchDeliveryInstructionsStatus === 'success'

    // implement handles
    useImperativeHandle(
      ref,
      () => {
        return {
          onXButtonClick: () => {
            if (isSuccess) {
              return true
            }

            return (
              !newAddressMain &&
              (!newDeliveryInstructions || newDeliveryInstructions === currentDeliveryInstructions)
            )
          },

          onBeforeClose: () => {
            captureUserAction('ActionsChangeAddressCancelFlowButtonClicked', {
              eventDetails: {
                suggestionsShown: suggestionsShownRef.current,
              },
            })
            if (successfulUpdateRequestBody) {
              dataDispatch({
                type: SET_MODIFIED_ORDER_ADDRESS,
                payload: { modifiedDeliverySettings: successfulUpdateRequestBody },
              })
            }
          },
        }
      },
      [
        captureUserAction,
        newAddressMain,
        newDeliveryInstructions,
        currentDeliveryInstructions,
        successfulUpdateRequestBody,
        SET_MODIFIED_ORDER_ADDRESS,
        dataDispatch,
        isSuccess,
      ],
    )

    return (
      <WidgetErrorHandler
        errorPayload={
          patchDeliveryAddressError?.errorPayload || patchDeliveryInstructionsError?.errorPayload
        }
        displayType='overlay'
        onQuit={() => onQuit(true)}
        onBack={() => {
          clearPatchDeliveryAddressError()
          clearPatchDeliveryInstructionsError()
        }}
        loading={isPatchingDeliveryAddress || isPatchingDeliveryInstructions}
        loadingText={t('Actions Widget.Actions.Change Address.Updating delivery adresss')}
      >
        {() => {
          if (isSuccess) {
            return (
              <Result
                status='success'
                title={
                  <div className={classes.resultTitleHolder}>
                    <Text className={classes.successText}>{t('Widgets Common.Success')}</Text>
                    <div className={classes.resultVoucherSection}>
                      <Text className={classes.resultSubtext}>
                        {`${t(
                          'Actions Widget.Actions.Change Address.You have updated the delivery settings successfully',
                        )}:`}
                      </Text>
                    </div>
                  </div>
                }
                subTitle={
                  <React.Fragment>
                    {newAddressMain && newAddressMain !== currentDeliveryAddress && (
                      <div className={classes.resultAddressContainer}>
                        <Text className={classes.resultSubtext}>{`${t(
                          'Actions Widget.Actions.Change Address.Delivery Address',
                        )}:`}</Text>
                        <Text className={classes.resultSubdata}>{newAddressMain}</Text>
                      </div>
                    )}

                    {newDeliveryInstructions &&
                      newDeliveryInstructions !== currentDeliveryInstructions && (
                        <div className={classes.resultAddressContainer}>
                          <Text className={classes.resultSubtext}>{`${t(
                            'Actions Widget.Actions.Change Address.Delivery Instructions',
                          )}:`}</Text>
                          <Text className={classes.resultSubdata}>{newDeliveryInstructions}</Text>
                        </div>
                      )}
                  </React.Fragment>
                }
                extra={[
                  <Button type='primary' key='console' onClick={() => onQuit(true)}>
                    {t('Interface.OK')}
                  </Button>,
                ]}
              ></Result>
            )
          }

          return (
            <div className={classes.contentContainer}>
              <div className={classes.inputAndMapHolder}>
                <div className={classes.inputHolder}>
                  {/* MAIN INPUT */}
                  <MainInput
                    vendor={vendor}
                    order={order}
                    currentDeliveryAddress={currentDeliveryAddress}
                    isNewAddressSelected={isNewAddressSelected}
                    setisNewAddressSelected={setisNewAddressSelected}
                    differenceBetweenAddressesInKm={differenceBetweenAddressesInKm}
                    handleClearAddressClick={handleClearAddressClick}
                    setNewAddressMain={setNewAddressMain}
                    setNewAddressLat={setNewAddressLat}
                    setNewAddressLng={setNewAddressLng}
                    setNewAddressStreetNumber={setNewAddressStreetNumber}
                    setNewAddressStreet={setNewAddressStreet}
                    setNewAddressPostalcode={setNewAddressPostalcode}
                    isDeliveryInstructionsInputActive={isDeliveryInstructionsInputActive}
                    setIsDeliveryInstructionsInputActive={setIsDeliveryInstructionsInputActive}
                    currentCountryOfOrder={currentCountryOfOrder}
                    showDistanceFromOldToNewAddress={config.showDistanceFromOldToNewAddress}
                    showDistanceFromSourceToNewAddress={config.showDistanceFromSourceToNewAddress}
                    validateAddress={config.validateAddress}
                    setIsNewAddressDeliverable={setIsNewAddressDeliverable}
                    lob={lob}
                    setSuggestionsShown={setSuggestionsShown}
                  />

                  {/* NEW ADDRESS */}
                  {isNewAddressSelected ? (
                    <NewAddress
                      isNewAddressDeliverable={isNewAddressDeliverable}
                      newAddressFloor={newAddressFloor}
                      newAddressCompany={newAddressCompany}
                      newAddressApartment={newAddressApartment}
                      newAddressStreetNumber={newAddressStreetNumber}
                      newAddressStreet={newAddressStreet}
                      newAddressPostalcode={newAddressPostalcode}
                      handleExtraInputFieldChange={handleExtraInputFieldChange}
                      validateAddress={config.validateAddress}
                    />
                  ) : null}

                  {/* DELIVERY INSTRUCTIONS */}
                  <DeliveryInstructions
                    setIsDeliveryInstructionsInputActive={setIsDeliveryInstructionsInputActive}
                    newDeliveryInstructions={newDeliveryInstructions}
                    handleDeliveryInstructionsChange={handleDeliveryInstructionsChange}
                    shouldDisplayAccordion={config.shouldDisplayAccordion}
                  />
                </div>

                <div className={classes.mapHolder}>
                  {/* MAP -> pass to map different centerLat and centerLng, depending on whether new address coordinates found */}
                  {currentAddressLat && currentAddressLng && !newAddressLng && !newAddressLat && (
                    <GoogleMap
                      centerLat={currentAddressLat}
                      centerLng={currentAddressLng}
                      currentAddressLat={currentAddressLat}
                      currentAddressLng={currentAddressLng}
                      newAddressLat={newAddressLat}
                      newAddressLng={newAddressLng}
                      lineOfBusiness={lob}
                    />
                  )}
                  {currentAddressLat && currentAddressLng && newAddressLng && newAddressLat && (
                    <GoogleMap
                      centerLat={newAddressLat}
                      centerLng={newAddressLng}
                      currentAddressLat={currentAddressLat}
                      currentAddressLng={currentAddressLng}
                      newAddressLat={newAddressLat}
                      newAddressLng={newAddressLng}
                      lineOfBusiness={lob}
                    />
                  )}
                </div>
              </div>

              {/* BUTTONS */}
              <Actions
                handleNewAddressSaveClick={handleNewAddressSaveClick}
                newDeliveryInstructions={newDeliveryInstructions}
                currentDeliveryInstructions={currentDeliveryInstructions}
                isNewAddressSelected={isNewAddressSelected}
                isNewAddressDeliverable={isNewAddressDeliverable}
                validateAddress={config.validateAddress}
                ignoreVendorDeliveryAreaValidation={ignoreAddressValidation}
              />
            </div>
          )
        }}
      </WidgetErrorHandler>
    )
  },

  {
    category: 'action',
    deriveConfig({ entityConfig }) {
      return entityConfig.fixed_panel_config.widgets_configs.actions.change_delivery_address
    },
    deriveInitialViewState({ lob }) {
      switch (lob) {
        case LinesOfBusiness.rider:
          return {
            width: 280,
          }

        default:
          return {
            width: 800,
          }
      }
    },
  },
)
