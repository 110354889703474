import { ApiService } from 'services/authApi/getPermissions'
import { GetOrderCommentsResponse } from 'types/api/commentApi/getOrderComments'

export const getBackOfficeOrderComments: ApiService<
  {
    entityId: string
    orderId: string
  },
  GetOrderCommentsResponse
> = (createClient, options) => {
  const { entityId, orderId, clientParams } = options

  const uri = `/OrdersAPI/v1/orders/${entityId}/${orderId}/comment`

  return createClient({
    endpointName: 'getBackOfficeOrderComments',
    expectedResponseStatusCode: 200,
    ...clientParams,
  }).get(uri)
}
