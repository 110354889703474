import classNames from 'classnames'
import SingleTag from 'components/Tags/SingleTag'
import { useSdk } from 'contexts/SdkProvider'
import { TranslateFunction } from 'hooks/useTranslation'
import React from 'react'
import { createUseStyles } from 'react-jss'
import { VendorSummaryResponse } from 'services/vendorApi/getVendorSummary'
import { Text } from 'shared'
import { CopyableText } from 'shared/CopyableText'
import { gray, text } from 'theme'
import { VendorApiResponse } from 'types/herocare'

const useStyles = createUseStyles({
  headerContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '4px',
  },
  summaryRow: {
    display: 'flex',
    gap: '8px',
    alignItems: 'flex-end',
    flex: 1,
  },
  vendorName: {
    fontSize: '20px',
    fontWeight: '500',
    lineHeight: '28px',
    marginRight: '4px',
    maxWidth: '50%',
  },
  spaceBetween: {
    justifyContent: 'space-between',
  },
  faded: {
    color: text.secondary,
  },
  dotSeparated: {
    gap: '4px',
    '& > *:not(:last-child):after': {
      content: "'•'",
      display: 'inline-block',
      fontSize: '14px',
      marginLeft: '4px',
    },
  },
  tags: {
    display: 'flex',
    flexDirection: 'row',
    gap: '4px',
    marginTop: '4px',
  },
  customTag: {
    background: gray.gray2,
  },
})
interface VendorSummaryHeaderCardProps {
  vendor: VendorApiResponse
  vendorSummary: VendorSummaryResponse
}

function getTags({
  vendor,
  vendorSummary,
  t,
}: {
  vendor: VendorApiResponse
  vendorSummary: VendorSummaryResponse
  t: TranslateFunction
}) {
  const vertical = {
    type: 'vendor-vertical',
    name: t('widgets.vendor_summary.common.vertical'),
    value: t(vendor?.vertical_type),
  }

  const verticalSegment = {
    type: 'vendor-vertical-segment',
    name: t('widgets.vendor_summary.common.vertical_segment'),
    value: t(vendorSummary?.vertical_segment),
  }

  const halal = {
    type: 'halal',
    name: t('widgets.vendor_summary.common.halal'),
    value: vendor?.halal && t('widgets.vendor_summary.common.halal'),
  }

  return [vertical, verticalSegment, halal]
}

export const VendorSummaryHeaderCard = ({
  vendor,
  vendorSummary,
}: VendorSummaryHeaderCardProps) => {
  const classes = useStyles()
  const { t } = useSdk()

  const tags = getTags({ vendor, vendorSummary, t })

  return (
    <div className={classes.headerContainer}>
      <div className={classNames(classes.summaryRow, classes.spaceBetween)}>
        <div className={classes.summaryRow}>
          <Text.Primary className={classes.vendorName}>{vendor?.name}</Text.Primary>
          <CopyableText
            type='text'
            className={classes.faded}
            copyableText={vendor?.vendor_id}
            copyEventName='COPY_VENDOR_ID'
          />
          <CopyableText
            type='text'
            className={classes.faded}
            copyableText={vendor?.global_vendor_id}
            prefix={`${t('widgets.vendor_summary.common.Global')}:`}
            copyEventName='COPY_GLOBAL_VENDOR_ID'
          />
        </div>
      </div>
      <div className={classNames(classes.summaryRow, classes.dotSeparated)}>
        <Text.Primary>{t(vendorSummary?.vendor_type)}</Text.Primary>
        {vendorSummary?.parent_name && (
          <Text.Primary>
            {`${t('widgets.vendor_summary.common.Parent')}: ${vendorSummary?.parent_name}`}
          </Text.Primary>
        )}
        {vendorSummary?.vendor_grade && (
          <Text.Primary>
            {`${t('widgets.vendor_summary.common.Grade')}: ${t(vendorSummary?.vendor_grade)}`}
          </Text.Primary>
        )}
      </div>
      <div className={classes.tags}>
        {tags?.map(
          (tag) =>
            tag?.value && (
              <SingleTag
                key={tag?.type}
                value={tag?.value}
                showTooltip={false}
                className={classes.customTag}
              />
            ),
        )}
      </div>
    </div>
  )
}
