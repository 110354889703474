import { DownOutlined } from '@ant-design/icons'
import { Collapse } from 'antd'
import classNames from 'classnames'
import { useTranslation } from 'hooks/useTranslation'
import React, { FC, useState } from 'react'
import { createUseStyles } from 'react-jss'
import { UnifiedIcon } from '../../shared/UnifiedIcon'
import { useWidgetContainerStyles } from 'hooks/styles/useWidgetContainerStyles'
import { WidgetActionHandlers } from '../../shared/WidgetActionHandlers'
import { Button } from '../../shared/Button'
import { ActionHandler, WidgetCollapseState, WidgetRenderState, WidgetSubjects } from 'types'
import { UnifiedIconName } from 'contexts/IconPacksProvider'
import { fontWeights, gray } from 'theme'
import { useCaptureUserAction } from 'hooks/events/useCaptureUserAction'

const useStyles = createUseStyles({
  antCollapse: ({
    paddingLeft,
    paddingRight,
    paddingTop,
    marginTop,
  }: {
    paddingLeft: number
    paddingRight: number
    paddingTop: number
    marginTop: number
  }) => ({
    border: 0,

    marginTop,

    '& > .ant-collapse-item': {
      border: 0,
    },

    '& .ant-collapse-content': {
      border: 0,
    },

    '& .ant-collapse-content > .ant-collapse-content-box': {
      padding: `${paddingTop}px ${paddingRight}px 0px ${paddingRight}px`,
    },

    '& .ant-collapse-header': {
      backgroundColor: gray.gray3,
      borderRadius: '4px !important',
      padding: `8px 40px 8px ${paddingLeft}px !important`,
      alignItems: 'center !important',
      maxHeight: '40px',
    },

    '& .ant-collapse-header .ant-collapse-arrow': {
      right: '12px !important',
    },
  }),

  label: {
    fontWeight: fontWeights.medium,
    fontSize: 16,
    lineHeight: '24px',
    color: gray.gray10,
  },
})

export interface RootWidgetContainerProps {
  /**
   * the default collapse state of the widget
   */
  collapseState?: WidgetCollapseState

  /**
   * hide label, if true, there will be no display of the section label,
   * and the widget will not be collapsible too
   */
  hideLabel?: boolean

  /**
   * widget label,
   */
  label: string

  /**
   * icon name
   */
  iconName: UnifiedIconName

  className?: string

  /**
   * if widget can be refreshed, provide an on refresh callback
   * @returns
   */
  onRefresh?: () => void

  /**
   * action handlers rendered next to the label,
   *
   * tab widgets render action handlers inside the tab, on the top
   */
  actionHandlers: ActionHandler[]

  /**
   * widget activation subjects
   */
  activationSubjects?: WidgetSubjects

  /**
   * possibility to override padding top
   */
  paddingTop?: number

  /**
   * override widget default margin top
   */
  marginTop?: number

  /**
   * if widget is elevated
   */
  elevated?: boolean

  renderState: WidgetRenderState

  widgetId: string
}

export const RootWidgetContainer: FC<RootWidgetContainerProps> = ({
  className,
  iconName,
  label,
  children,
  onRefresh,

  collapseState,

  hideLabel,
  paddingTop,
  marginTop,
  actionHandlers,
  activationSubjects,
  elevated,
  widgetId,
  renderState,
}) => {
  const captureUserAction = useCaptureUserAction()
  const disableCollapse = collapseState === 'disabled' || hideLabel || elevated

  const notCollapsibleContainerStyles = useWidgetContainerStyles({
    withBoxShadow: elevated,
    marginTop: marginTop ?? 16,
    paddingTop,
  })

  const collapsibleContainerStyles = useStyles({
    paddingLeft: 12,
    paddingRight: 12,
    paddingTop: paddingTop ?? 16,
    marginTop: marginTop ?? 16,
  })

  const [isExpanded, setIsExpanded] = useState(collapseState === 'expanded')

  const { t } = useTranslation()

  if (disableCollapse) {
    return (
      <div
        data-widget-name={widgetId}
        data-widget-render-state={renderState}
        className={classNames('widget', notCollapsibleContainerStyles.styles, className)}
      >
        <WidgetActionHandlers
          actionHandlers={actionHandlers}
          marginBottom={16}
          marginTop={0}
          justifyContent='end'
          activationSubjects={activationSubjects}
        />
        {children}
      </div>
    )
  }

  return (
    <div data-widget-name={widgetId} data-widget-render-state={renderState} className='widget'>
      <Collapse
        defaultActiveKey={isExpanded ? ['1'] : []}
        onChange={() => {
          setIsExpanded(!isExpanded)
          captureUserAction(isExpanded ? 'COLLAPSE_WIDGET' : 'EXPAND_WIDGET', {
            eventDetails: {
              widget_id: widgetId,
              button_label: null,
            },
          })
        }}
        expandIconPosition='end'
        className={classNames('widget', collapsibleContainerStyles.antCollapse, className)}
        expandIcon={() => <DownOutlined rotate={isExpanded ? 180 : 0} size={15} height={15} />}
      >
        <Collapse.Panel
          header={
            <span className={collapsibleContainerStyles.label}>
              <UnifiedIcon icon={iconName} mr={8} /> {label}
            </span>
          }
          key='1'
          extra={[
            onRefresh && (
              <Button
                data-test-id='refresh-btn'
                type='link'
                onClick={(e) => {
                  e.stopPropagation()
                  onRefresh()
                }}
                key={'refresh-button'}
                icon={'SyncOutlined'}
              >
                {t('Interface.Refresh')}
              </Button>
            ),
            <WidgetActionHandlers
              marginTop={0}
              actionHandlers={actionHandlers}
              key={'action-handlers'}
              activationSubjects={activationSubjects}
            />,
          ].filter(Boolean)}
        >
          {children}
        </Collapse.Panel>
      </Collapse>
    </div>
  )
}
