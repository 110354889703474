export default {
  riderBody: {
    marginTop: '20px',
  },
  divider: {
    padding: 0,
    margin: '12px 0px 12px 0px',
  },
  riderPhoneHolder: {
    display: 'flex',
    alignItems: 'baseline',
  },
  textBlockHolder: {
    width: '70%',
  },
  labelText: {
    fontSize: '13px',
    color: 'rgba(0, 0, 0, 0.45)',
    marginRight: '3px',
  },
}
