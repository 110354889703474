import { createUseStyles } from 'react-jss'
import { gray, shadow } from 'theme'

export const useStyles = createUseStyles({
  container: {
    border: `1px solid ${gray.gray5}`,
    borderRadius: '4px',
    display: 'flex',
    flexWrap: 'nowrap',
    boxShadow: shadow.level1,
  },
  addressBox: {
    position: 'relative',
    padding: '12px 16px',
    width: '50%',
    fontSize: '14px',
    '&:first-child': {
      marginRight: '16px',
      borderRight: `1px solid ${gray.gray5}`,
    },
  },
  addressTitle: {
    fontWeight: 'bold',
  },
  addressText: {
    marginBottom: '8px',
  },
  transfer: {
    top: '10px',
    textAlign: 'center',
    position: 'absolute',
    width: '32px',
    height: '24px',
    border: `1px solid ${gray.gray5}`,
    borderRadius: '50px',
    backgroundColor: 'white',
    right: '-16px',
  },
})
