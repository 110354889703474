import { Col, Row, RowProps } from 'antd'
import { useSdk } from 'contexts/SdkProvider'
import React from 'react'
import { Box, BoxStyleOptions } from 'shared/Box'
import { DataPoint, DataPointProps } from 'shared/DataPoint'

export interface DataPointsProps extends BoxStyleOptions {
  dataPoints: Array<DataPointProps>

  columnCount?: 2 | 3 | 4 | 6

  gutter?: RowProps['gutter']
}

export const DataPoints = ({
  dataPoints,
  columnCount = 3,
  gutter = [20, 20],
  ...props
}: DataPointsProps) => {
  const { checkDisplayRules } = useSdk()
  const filteredDataPoints = dataPoints.filter(
    (current) =>
      current &&
      checkDisplayRules({
        displayRules: current.display_rules,
        opts: {
          dataPointValue: current.value,
        },
      }).visible,
  )

  if (filteredDataPoints.length === 0) {
    return null
  }
  return (
    <Box {...props}>
      <Row gutter={gutter}>
        {filteredDataPoints.map((dataPoint) => {
          return (
            <Col span={dataPoint.colSpan ?? 24 / columnCount} key={`${dataPoint.name}`}>
              <DataPoint {...dataPoint} />
            </Col>
          )
        })}
      </Row>
    </Box>
  )
}
