import React from 'react'
import { Typography } from 'antd'
import { createUseStyles } from 'react-jss'
import { useTranslation } from 'hooks/useTranslation'
import capitalizeFirstLetters from 'utils/capitalizeFirstLetters'
import { AdditionalDataPaymentRefund, SingleComment } from 'types/api/commentApi/getOrderComments'
import ReportVoucherPaymentRefundItems from '../../widgets/HistoryOfEvents/OrderHistory/ReportVoucherPaymentRefundItems'
import styles from './Comments.styles'
import { commentTagType, CommentView } from './CommentView'
import { WidgetSize } from 'types/widgets/historyOfEvents/tabs'

const { Text } = Typography

const useStyles = createUseStyles(styles)

type Props = {
  comment: SingleComment
  shouldCommentBeHighlighted: boolean
  mode: WidgetSize
}

type RefundTextProps = {
  refundData: AdditionalDataPaymentRefund
  isFullRefund: boolean
}

const RefundTargetLookup = {
  GIFT_CARD: 'gift card',
} as const

const RefundText = ({ refundData, isFullRefund }: RefundTextProps) => {
  const { t } = useTranslation()

  if (!refundData) {
    return null
  }

  const currency = refundData.refund_currency
  const amount = refundData.refund_amount
  const target = refundData.refund_target

  if (!currency || !amount || !target) {
    return null
  }

  const targetText = RefundTargetLookup[target] ?? target.toLowerCase()

  return (
    <React.Fragment>
      <Text strong>{`${currency} ${amount} `}</Text>
      <Text>{`${t('History of Events Widget.Content.To')} `}</Text>
      <Text strong>{targetText}</Text>
      {!isFullRefund ? <ReportVoucherPaymentRefundItems data={refundData} /> : null}
    </React.Fragment>
  )
}

const PaymentRefundComment = ({ comment, shouldCommentBeHighlighted, mode }: Props) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const refundData = comment?.additional_data?.payment_refund
  const isFullRefund = refundData?.refund_purpose === 'order_refund'

  return (
    <CommentView
      mode={mode}
      comment={comment}
      shouldCommentBeHighlighted={shouldCommentBeHighlighted}
      tag={{
        type: commentTagType.thunderbolt,
        text: isFullRefund
          ? t('History of Events Widget.Event Types.Full Refund')
          : t('History of Events Widget.Event Types.Partial Refund'),
      }}
    >
      <Text className={classes.commentText}>
        <Text>{` ${
          isFullRefund
            ? t('History of Events Widget.Content.Issued full refund')
            : t('History of Events Widget.Content.Issued partial refund')
        } `}</Text>

        <RefundText refundData={refundData} isFullRefund={isFullRefund} />

        <span>
          {refundData?.contact_reason &&
            ` ${t('Actions Widget.Actions.Compensation.Issue Type')}: ${capitalizeFirstLetters(
              refundData.contact_reason.replace(/_/g, ' '),
            )}.`}
        </span>
      </Text>
    </CommentView>
  )
}

export default PaymentRefundComment
