import React from 'react'
import { SDK } from 'types'
import { text } from 'theme'
import { OrderApiResponse } from 'types/api/orderApi/order'
import { OrderStatuses } from 'types/widgets/order/orderStatuses'
import { TimelineItem, TimelineMeta } from 'widgets/Deliveries/types'
import { TranslateFunction, useTranslation } from 'hooks/useTranslation'
import { getUpdatedDeliveryTime } from '../../utils/order/getUpdatedDeliveryTime'
import { DeliveryItem, FulfillmentApiResponse } from 'types/api/fulfillmentApi/fulfillment'
import UpdatedDeliveryTime from 'widgets/Deliveries/UpdatedDeliveryTime/UpdatedDeliveryTime'
import {
  getOrderEstimationStatusForTimeline,
  getOrderPromisedStatusForTimeline,
} from '../../utils/order/getOrderEstimationStatusForTimeline'

import { calculateOrderDeliveryTimestamps } from 'utils/order/calculateOrderDeliveryTimestamps'
import { DeliveryStatusItem, getOrderDeliveryStatuses } from 'utils/order/getOrderDeliveryStatuses'
import { differenceInDays, parseISO } from 'date-fns'

export const getDeliveryStatusTimelineItem = ({
  t,
  sdk,
  status,
  delayTime,
  isCurrent,
  diffInDays,
  isMilestone,
  promisedDate,
  promisedTime,
  orderPlaceDate,
  hasDateChanged,
  isPickupDelivery,
  commitedPickupTime,
  updatedDeliveryTime,
  isFirstStatus,
}: {
  sdk: SDK
  delayTime: any
  isCurrent?: boolean
  t: TranslateFunction
  diffInDays?: number
  promisedTime: string
  promisedDate: string
  orderPlaceDate: string
  isMilestone?: boolean
  hasDateChanged?: boolean
  isPickupDelivery?: boolean
  status: DeliveryStatusItem
  commitedPickupTime: string
  updatedDeliveryTime: string
  isFirstStatus?: boolean
}) => {
  const statusMeta =
    status.source === 'order'
      ? sdk.getOrderStatusTextAndColor({ orderStatus: status.status })
      : sdk.getRiderStatusTextAndColor({ riderStatus: status.status })

  const secondaryTime: TimelineMeta[] = []
  const secondaryText: TimelineMeta[] = []

  let mainText = statusMeta.text
  let showPromisedTimeAndDelay = false

  switch (status.status) {
    case OrderStatuses.cancelled:
      const reasonCode = status.cancelled?.details?.reason || status.cancelled?.reason
      const reason = t(`Order Widget.Tabs.Summary.Cancellation reasons.${reasonCode}.Reason`)

      showPromisedTimeAndDelay = true
      secondaryTime.push({ text: '' }) // add empty space to fix alignment for promised delivery time
      secondaryText.push({
        text: reason,
        color: text.danger,
      })
      break

    case OrderStatuses.pendingOrderFailed:
      secondaryText.push({
        text:
          status.pending_order_failed?.reason || t('Order Widget.Tabs.Summary.Fraudulent customer'),
        color: text.danger,
      })
      break

    case OrderStatuses.delivered:
      showPromisedTimeAndDelay = true
      break

    case OrderStatuses.pickedUp:
      if (isPickupDelivery) {
        showPromisedTimeAndDelay = true
        mainText = t('Order Widget.Tabs.Status.Order Statuses.Completed')
      }
      break
  }

  if (showPromisedTimeAndDelay) {
    secondaryText.push({
      text: t('Order Widget.Tabs.Status.Order Statuses.Promised'),
      tooltip: updatedDeliveryTime && <UpdatedDeliveryTime deliveryTime={updatedDeliveryTime} />,
    })

    secondaryTime.push({ text: promisedTime })

    if (promisedDate !== orderPlaceDate) {
      secondaryText.push({ text: '' })
      secondaryTime.push({
        text: promisedDate,
        color: text.secondary,
      })
    }
    if (delayTime?.text) {
      secondaryText.push({ text: '' })
      secondaryTime.push(delayTime)
    }
  }

  if (isFirstStatus) {
    secondaryText.push({ text: '' })
    secondaryTime.push({
      text: sdk.datetimeFormatter.formatDate(status.timestamp),
      color: text.secondary,
    })
  }

  if (status.status === OrderStatuses.pickedUp && commitedPickupTime) {
    secondaryText.push({
      text: t('Order Widget.Tabs.Status.Order Statuses.Commited pick up'),
    })

    secondaryTime.push({
      text: sdk.datetimeFormatter.formatTime(commitedPickupTime),
    })
  }

  const timelineItem: TimelineItem = {
    key: `${status.status}-${status.timestamp}`,
    isCurrent: isCurrent,
    isDiffDay: hasDateChanged && !isFirstStatus,
    isMilestone: isMilestone || isCurrent,
    icon: statusMeta.icon,
    status: {
      main: {
        text: mainText,
        color: isCurrent ? statusMeta.color : text.disabled,
        fontWeight: isCurrent ? '600' : '400',
      },
      secondary: secondaryText,
    },
    time: {
      main: {
        diffInDays,
        text: sdk.datetimeFormatter.formatTime(status.timestamp),
      },
      secondary: secondaryTime,
    },
  }
  return timelineItem
}

export const useDeliveryHistoryTimeline = ({
  sdk,
  order,
  delivery,
  fulfillment,
}: {
  sdk: SDK
  delivery: DeliveryItem
  order: OrderApiResponse
  fulfillment: FulfillmentApiResponse
}) => {
  const { t } = useTranslation()
  const deliveryProvider = order.delivery.provider
  const updatedDeliveryTime = getUpdatedDeliveryTime({ order, fulfillment, sdk })
  const statusHistory = getOrderDeliveryStatuses({ delivery, order })
  const { delayTime, promisedDropOffTime } = calculateOrderDeliveryTimestamps({
    order,
    fulfillment,
    delivery,
    datetimeFormatter: sdk.datetimeFormatter,
  })
  const orderPlaceDate = sdk.datetimeFormatter.formatDate(order.place_timestamp)
  const formattedPromisedTime = sdk.datetimeFormatter.formatTime(promisedDropOffTime)
  const formattedPromisedDate = sdk.datetimeFormatter.formatDate(promisedDropOffTime)
  const isPickupDelivery = deliveryProvider === 'pickup'
  // trackStatusDate will keep track of date change in order status history
  // We will use it to show date only on status where date changed and then afterwards it will
  // not show new date just as in design.
  let trackStatusDate: Date = null

  const milestones = [
    OrderStatuses.created,
    OrderStatuses.pendingOrderFailed,
    OrderStatuses.accepted,
    OrderStatuses.courierAcceptedDelivery,
    OrderStatuses.delivered,
  ]

  const timeline: TimelineItem[] = statusHistory.map((status, idx) => {
    const statusDate: Date = parseISO(status.timestamp)
    const diffInDays = differenceInDays(statusDate, trackStatusDate) || 0

    let hasDateChanged = false
    // has date changed from last tracked date to show status time with new date.
    if (trackStatusDate !== statusDate) {
      trackStatusDate = statusDate
      hasDateChanged = true
    }

    return getDeliveryStatusTimelineItem({
      t,
      sdk,
      status,
      delayTime,
      diffInDays,
      orderPlaceDate,
      hasDateChanged,
      updatedDeliveryTime,
      isFirstStatus: idx === 0,
      promisedTime: formattedPromisedTime,
      promisedDate: formattedPromisedDate,
      isPickupDelivery: isPickupDelivery,
      isCurrent: idx === statusHistory.length - 1,
      commitedPickupTime: delivery?.latest_estimated_pickup_time.arriving_at,
      isMilestone: milestones.some((milestone) => status.status === milestone),
    })
  })

  const estimation = getOrderEstimationStatusForTimeline({
    t,
    sdk,
    order,
    fulfillment,
    delivery,
    forRiderHistory: true,
  })

  const promised = getOrderPromisedStatusForTimeline({
    t,
    sdk,
    order,
    fulfillment,
    delivery,
  })

  if (promised) {
    timeline.push(promised)
  } else if (estimation) {
    timeline.push(estimation)
  }

  return timeline
}
