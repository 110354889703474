import { LinesOfBusiness } from 'types'
import { CcrItem, getLobGlobalCcrList } from './getCcrItemByName'

function findCcrItemByCode({
  ccrCode,
  ccrList,
}: {
  ccrList: CcrItem[]
  ccrCode: string
}): CcrItem | null {
  const match = /(\d+)([a-z]+)\.(\d+)/i.exec(ccrCode)

  if (!match) {
    return null
  }

  const levelSearch = [ccrCode, match[1] + match[2], match[1]]

  let result: CcrItem = null
  let currentLevelList = ccrList

  do {
    const currentLevelCode = levelSearch.pop()
    const searchResult = currentLevelList.find((current) => current.code === currentLevelCode)
    if (searchResult) {
      currentLevelList = searchResult.children || []
      if (levelSearch.length === 0) {
        result = searchResult
      }
    } else {
      currentLevelList = []
    }
  } while (levelSearch.length && currentLevelList.length)

  return result
}

export function getCcrItemByCode({
  lob = 'all',
  ccrCode,
}: {
  lob?: LinesOfBusiness | 'all'
  ccrCode: string
}) {
  return findCcrItemByCode({ ccrCode, ccrList: getLobGlobalCcrList(lob) })
}
