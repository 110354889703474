import { useCallback, useState } from 'react'
import capitalize from 'lodash/capitalize'
import { useCaptureUserAction } from './events/useCaptureUserAction'

export const useActiveTab = <T extends string>(
  action: 'OrderTab' | 'CustomerTab' | 'VendorTab' | 'RiderTab',
  defaultValue?: T,
): [
  T,
  (
    tab: T,
    isUserClick?: boolean,
    analyticsParams?: Record<string, number | string | boolean>,
  ) => void,
] => {
  const [activeTab, _setActiveTab] = useState(defaultValue)
  const captureUserAction = useCaptureUserAction()

  const setActiveTab = useCallback(
    (tab: T, isUserClick?: boolean, analyticsParams = {}) => {
      if (isUserClick) {
        const actionToCapture = `${action}${tab.split('_').map(capitalize).join('')}TabClicked`

        captureUserAction(actionToCapture, {
          eventDetails: analyticsParams,
        })
      }
      _setActiveTab(tab)
    },
    [_setActiveTab, action, captureUserAction],
  )

  return [activeTab, setActiveTab]
}
