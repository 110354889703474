// libs
import React, { useContext } from 'react'
// contexts and types
import { EntityContext } from 'contexts/entity/EntityContext'
import { RiderServiceContext } from 'contexts/riderService/RiderServiceContext'
import customerDataPointIdentifiers from 'types/riderService/customerDataPointIdentifiers'
// hooks
import { useTranslation } from 'hooks/useTranslation'
import useDataPointAvailabilityCheck from 'hooks/useDataPointAvailabilityCheck'
// utils
// styles
import { createUseStyles } from 'react-jss'
import styles from './Content.styles'
import { Divider, Button } from 'antd'
import { CopyOutlined } from '@ant-design/icons'
// components
import TextBlockView from 'components/TextBlockView'
import { Notification } from 'shared/Notification'
import { useCaptureUserAction } from 'hooks/events/useCaptureUserAction'

const useStyles = createUseStyles(styles)

const Content: React.FC = () => {
  const classes = useStyles()

  // pull translations
  const { t } = useTranslation()

  // pull entity context
  const { entityState } = useContext(EntityContext)

  // pull data state and dispatch from data context
  const {
    riderServiceState: { customer },
  } = useContext(RiderServiceContext)

  const isNameAllowed = useDataPointAvailabilityCheck(
    entityState.entityConfig.miniCustomer.customerName,
    customer?.customerName ? true : false,
    [entityState, customer],
  )

  const isAddressAllowed = useDataPointAvailabilityCheck(
    entityState.entityConfig.miniCustomer.customerAddress,
    customer?.customerAddress ? true : false,
    [entityState, customer],
  )

  const isPhoneAllowed = useDataPointAvailabilityCheck(
    entityState.entityConfig.miniCustomer.customerPhone,
    customer?.customerPhone ? true : false,
    [entityState, customer],
  )

  const isCopyToClipboard = useDataPointAvailabilityCheck(
    entityState.entityConfig.miniCustomer.copy_to_clipboard,
    true,
    [entityState],
  )

  const captureUserAction = useCaptureUserAction()

  const handleCopyToClipboard = (item: string) => {
    captureUserAction('CustomerSummaryCopyMobileClicked')
    const success = () => Notification.success({ message: t('Messages.Copied') })
    const warning = () => Notification.warning({ message: t('Widgets Common.Cannot Copy') })

    navigator.clipboard.writeText(item.toString()).then(
      () => success(),
      () => warning(),
    )
  }

  return (
    <div className={classes.customerBody}>
      {/* customer ID */}
      {customer?.customerId && (
        <React.Fragment>
          <TextBlockView
            label={t('Customer Widget.Customer ID')}
            data={customer.customerId}
            identifier={customerDataPointIdentifiers.customer_id}
          />
          <Divider className={classes.divider} />
        </React.Fragment>
      )}

      {/* customer name */}
      {customer?.customerName && isNameAllowed ? (
        <React.Fragment>
          <TextBlockView
            label={t('Widgets Common.Name')}
            data={customer.customerName}
            identifier={customerDataPointIdentifiers.customer_name}
          />

          <Divider className={classes.divider} />
        </React.Fragment>
      ) : null}

      {/* customer address */}
      {customer?.customerAddress && isAddressAllowed ? (
        <React.Fragment>
          <TextBlockView
            label={t('Widgets Common.Address')}
            data={customer.customerAddress}
            identifier={customerDataPointIdentifiers.customer_address}
          />
          <Divider className={classes.divider} />
        </React.Fragment>
      ) : null}

      {/* customer phone number */}
      {customer?.customerPhone && isPhoneAllowed ? (
        <React.Fragment>
          <div className={classes.orderIdHolder}>
            <div className={classes.textBlockHolder}>
              <TextBlockView
                label={t('Vendor Widget.Phone')}
                data={customer.customerPhone}
                identifier={customerDataPointIdentifiers.customer_phone}
              />
            </div>

            {isCopyToClipboard ? (
              <Button
                type='link'
                size='large'
                onClick={() => handleCopyToClipboard(customer.customerPhone)}
              >
                <CopyOutlined />
              </Button>
            ) : null}
          </div>
          <Divider className={classes.divider} />
        </React.Fragment>
      ) : null}
    </div>
  )
}

export default Content
