import { ApiService } from 'services/authApi/getPermissions'

import { OrderStatuses } from 'types/widgets/order/orderStatuses'

export const patchDeliveryInstructions: ApiService<
  {
    entityId: string
    orderId: string

    orderStatus: OrderStatuses

    instructions?: string
  },
  {
    success: 'true' | 'false'
  }
> = (createClient, options) => {
  const {
    entityId,
    orderId,

    orderStatus,
    clientParams,

    ...body
  } = options

  const uri = `OrdersAPI/v1/orders/${entityId}/${orderId}/dropoff-instructions`
  const { tParams, ...otherClientParams } = clientParams || {}

  return createClient({
    endpointName: 'patchDeliveryInstructions',

    expectedResponseStatusCode: 200,
    validateData: (data) => {
      if (data.success !== 'true') {
        return `Success response data success field is not valid. Expected true but got ${data.success}`
      }
      return true
    },
    tParams: {
      orderStatus,
      ...tParams,
    },
    ...otherClientParams,
  }).patch(uri, body)
}
