import { LinesOfBusiness } from 'types'
import customerGlobalCRList from 'globalCCRList.json'
import vendorGlobalCRList from 'globalVCRList.json'

export type CcrItem = {
  name: string
  code: string
  children?: CcrItem[]
}

export type CcrLevels = {
  ccr1: string
  ccr2: string
  ccr3: string
}

export function findCcrItemByName({
  ccr1,
  ccr2,
  ccr3,
  ccrList,
}: CcrLevels & { ccrList: CcrItem[] }): CcrItem | null {
  const noResult = null

  const level1 = ccrList.find((ccr) => ccr.name.toLowerCase() === ccr1?.toLowerCase())
  if (!level1) {
    return noResult
  }

  const level2 = level1.children.find((ccr) => ccr.name.toLowerCase() === ccr2?.toLowerCase())
  if (!level2) {
    return noResult
  }

  const level3 = level2.children.find((ccr) => ccr.name.toLowerCase() === ccr3?.toLowerCase())
  if (!level3) {
    return noResult
  }

  return level3
}

export const getLobGlobalCcrList = (lob: LinesOfBusiness | 'all') => {
  let globalCRList: CcrItem[]

  switch (lob) {
    case LinesOfBusiness.vendor:
      globalCRList = vendorGlobalCRList
      break

    case LinesOfBusiness.customer:
    case LinesOfBusiness.rider:
      globalCRList = customerGlobalCRList
      break

    case 'all':
      globalCRList = [...vendorGlobalCRList, ...customerGlobalCRList]
      break

    default:
      globalCRList = []
  }

  return globalCRList
}

export function getCcrItemByName({
  ccr1,
  ccr2,
  ccr3,
  lob = LinesOfBusiness.customer,
}: CcrLevels & { lob?: LinesOfBusiness | 'all' }) {
  return findCcrItemByName({ ccr1, ccr2, ccr3, ccrList: getLobGlobalCcrList(lob) })
}
