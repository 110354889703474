import React from 'react'

// Types
import {
  AdditionalDataVoucherRefund,
  AdditionalDataPaymentRefund,
  AdditionalDataRefundRequest,
  AdditionalDataTicketRefund,
} from 'types/api/commentApi/getOrderComments'

// Components
import { Typography } from 'antd'

// Relative Path
import FullItemDetails from './ItemDetails/FullItemDetails'
import SimpleItemDetails from './ItemDetails/SimpleItemDetails'

type Props = {
  data:
    | AdditionalDataVoucherRefund
    | AdditionalDataPaymentRefund
    | AdditionalDataRefundRequest
    | AdditionalDataTicketRefund
  limit?: number
}

const { Text } = Typography

function ReportVoucherRefundItems({ data, limit }: Props) {
  if (data?.removed_items?.length) {
    return (
      <Text>
        {' for '}
        <FullItemDetails items={data.removed_items} />{' '}
      </Text>
    )
  }

  if (data?.refund_items?.length) {
    return (
      <Text>
        {' for '}
        <SimpleItemDetails items={data.refund_items} limit={limit} />{' '}
      </Text>
    )
  }

  return null
}

export default ReportVoucherRefundItems
