import React from 'react'
import { useCanDisplayDataPoint } from 'hooks/useGetValidFeatures'
import { DataPointWithBetaRequirementConfig } from 'contexts/entity/types'
import { TransformedDataPoint } from 'types/dataTransformers/generic'
import SingleTag from '../Tags/SingleTag'
import { DataPoint } from 'shared/DataPoint'
import { summaryWidgetFields } from 'entityConfig/dataPoints/customer'

type Props = {
  item: TransformedDataPoint
  config?: DataPointWithBetaRequirementConfig
}

const WidgetTagsListItem = ({ item, config }: Props) => {
  const canDisplayDataPoint = useCanDisplayDataPoint()
  const canDisplay = canDisplayDataPoint(
    item.value,
    config || { displayRule: [], betaRequirement: [] },
  )

  if (!canDisplay) {
    return (
      <DataPoint
        type='default'
        label={item.label || item.key}
        value='-'
        name={summaryWidgetFields.customerTags}
      />
    )
  }

  if (!Array.isArray(item.value)) {
    const value = String(item.value)
    const tag = { key: value, value }

    return (
      <DataPoint
        type='custom'
        label={item.label || item.key}
        render={<SingleTag value={tag.value} />}
        name={summaryWidgetFields.customerTags}
      />
    )
  }

  const dataPointValue = (value) => (
    <>
      {value.map((tag) => {
        return <SingleTag key={tag.key} value={String(tag.value ?? tag.label)} />
      })}
    </>
  )

  return (
    <DataPoint
      type='custom'
      label={item.label || item.key}
      render={dataPointValue(item.value)}
      name={summaryWidgetFields.customerTags}
    />
  )
}

export default WidgetTagsListItem
