import get from 'lodash/get'
import { apiErrorConfigs } from 'apiErrorConfigs/apiErrorConfigs'
import { ApiEndpointErrorConfig } from 'apiErrorConfigs/voucherApiErrorConfig'
import { ApiErrorPayload } from 'types/error'

export type ApiErrorDisplayType = 'full' | 'regular' | 'mini' | 'overlay' | 'missing-api-parameters'

export interface ApiErrorConfigOverrideOpts {
  /**
   * indicates if go back option should be enabled when onRetry callback is provided
   */
  enableGoBack?: boolean

  /**
   * indicates if retry option should be enabled when onBack callback is provided
   */
  enableRetry?: boolean
}

export const getApiErrorConfig = (
  errorPayload: ApiErrorPayload,
  displayType: ApiErrorDisplayType,
  overrides?: ApiErrorConfigOverrideOpts,
): ApiEndpointErrorConfig | null => {
  if (!errorPayload) {
    return null
  }

  const {
    namespace,
    endpointName,
    statusCode,
    errorCode = '*',
    context,
    id,
    tParams,
  } = errorPayload

  const { enableGoBack, enableRetry } = overrides || {}

  const baseTarget = `${namespace}.${endpointName}.${statusCode}`
  const targetsToCheck: string[] = []

  if (context) {
    targetsToCheck.push(`${baseTarget}.${context}/${errorCode}`)
  }
  targetsToCheck.push(`${baseTarget}.${errorCode}`)
  if (errorCode !== '*') {
    targetsToCheck.push(`${baseTarget}.*`) //fallback
  }

  let errorConfig: ApiEndpointErrorConfig
  let contextPast = ''

  for (let i = 0; i < targetsToCheck.length; i++) {
    errorConfig = get(apiErrorConfigs, targetsToCheck[i], null)
    if (errorConfig) {
      break
    }
  }

  if (!errorConfig) {
    // this is a fallback error
    errorConfig = {
      heading: 'Error occured',
      copyText: `Error occured while trying to ${endpointName}`,
      screen: 'warning',
    }
  }

  switch (context) {
    case 'Compensation':
      contextPast = 'compensated'
      break
    case 'Refund':
      contextPast = 'refunded'
      break
    case 'cancelOrder':
      contextPast = 'cancelled'
      break
  }

  const result = {
    id: id ? id.toString() : Date.now().toString(),
    context,
    tParams,

    /**
     * enable go back to try again
     */
    enableGoBack:
      enableGoBack ||
      (displayType === 'overlay' && !['error', 'config'].includes(errorConfig.screen)),

    /**
     * by default, we enable retry for timed out [408] requests
     * and permission [401, 403, 412] errors
     */
    enableRetry:
      enableRetry ?? (displayType !== 'overlay' || [408, 403, 401, 412].includes(statusCode)),

    /**
     * by default, we refresh permission before retrying for [403, 412, 401] responses
     */
    refreshPermissionBeforeRetry: [403, 401, 412].includes(statusCode),

    contextPast,

    ...errorConfig,
  }

  return result
}
