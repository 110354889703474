/**
 * defines and exports the EntityPanel, which is a wrapper for the navbar, widget and fixed panels.
 * this component pulls the entity config from Admin API, sets the refs for widget panel's widgets and provides scroll functionality.
 * */

// libs
import React, { useContext } from 'react'
// contexts and types
import { SessionContext } from 'contexts/session/SessionContext'
import { EntityContext } from 'contexts/entity/EntityContext'
import { LinesOfBusiness } from 'types/session/linesOfBusiness'
// hooks
import { useTranslation } from 'hooks/useTranslation'
// utils
// styles
import { createUseStyles } from 'react-jss'
import styles from './EntityPanel.styles'
import { Result } from 'antd'

// components
import RiderPanel from 'App/RiderPanel'
import { getEntityConfig } from 'services/configApi/getEntityConfig'
import { useApiService } from 'hooks/useApiService'
import { FullPageLoadingScreen } from 'components/FullPageLoadingScreen/FullPageLoadingScreen'
import { WidgetErrorHandler } from 'components/WidgetErrorHandler/widgetErrorHandler'

import { SessionAction } from 'contexts/session/types'
import { EntityAction } from 'contexts/entity/types'
import { UUILayout } from 'UUILayout'
import { SearchVendorPanel } from 'App/SearchPanel/SearchVendorPanel'
import { SearchOrderPanel } from 'App/SearchPanel/SearchOrderPanel'
import { V1Panel } from 'App/V1Panel/V1Panel'
import {
  allowedFixedPanelWidgets,
  allowedScrollablePanelWidgets,
} from 'entityConfig/allowedConfigValues'
import { useCheckDisplayRules } from 'hooks/useCheckDisplayRules'

const useStyles = createUseStyles(styles)

/**
 * the entity panel loads the entity configuration
 * @returns
 */
const EntityPanel: React.FC = () => {
  const classes = useStyles()

  // pull language content
  const { t } = useTranslation()

  const {
    sessionState: {
      orderId,
      userId,
      lineOfBusiness,
      entityConfigId,
      vendorId,
      globalVendorId,
      globalEntityId,
      widgetContainerType,
      uiVersion,
    },
    sessionDispatch,
  } = useContext(SessionContext)

  const { entityDispatch, entityState } = useContext(EntityContext)

  const checkDisplayRules = useCheckDisplayRules()

  const {
    loading,
    loadService: reloadConfig,
    error,
  } = useApiService({
    service: getEntityConfig,
    deps: [entityConfigId, lineOfBusiness],

    params: {
      lineOfBusiness,
      entityId: entityConfigId,
    },

    shouldLoad: Boolean(entityConfigId && lineOfBusiness),

    clearDataBeforeLoad: true,

    onSuccess: (res) => {
      const entityConfig = res.data

      // vendor lob has this hardcoded
      if (lineOfBusiness === LinesOfBusiness.vendor) {
        entityConfig.scrollable_panel_config = {
          ...entityConfig.scrollable_panel_config,
          widget_order: [
            {
              feature: allowedScrollablePanelWidgets.vendor,
              betaRequirement: [],
            },
            {
              feature: allowedScrollablePanelWidgets.order,
              betaRequirement: [],
            },
            {
              feature: allowedScrollablePanelWidgets.customer,
              betaRequirement: [],
            },
            {
              feature: allowedScrollablePanelWidgets.rider,
              betaRequirement: [],
            },
          ],
        }

        entityConfig.fixed_panel_config = {
          ...entityConfig.fixed_panel_config,
          widget_order: [
            {
              feature: allowedFixedPanelWidgets.actions,
              betaRequirement: [],
            },
            {
              feature: allowedFixedPanelWidgets.lastOrders,
              betaRequirement: [],
            },
            {
              feature: allowedFixedPanelWidgets.newComment,
              betaRequirement: [],
            },
          ],
        }
      }

      sessionDispatch({
        type: SessionAction.SET_SESSION,
        payload: {
          utcZone: entityConfig.utc_zone,
        },
      })

      let resolvedUiVersion = uiVersion

      if (resolvedUiVersion === 'v2' && !entityConfig.layout_v2) {
        resolvedUiVersion = 'v1'
      } else if (!resolvedUiVersion) {
        if (
          entityConfig.layout_v2 &&
          checkDisplayRules(entityConfig.layout_v2.display_rules).enabled
        ) {
          resolvedUiVersion = 'v2'
        } else {
          resolvedUiVersion = 'v1'
        }
      }

      sessionDispatch({
        type: SessionAction.SET_SESSION,
        payload: {
          uiVersion: resolvedUiVersion,
        },
      })

      const layoutConfig = resolvedUiVersion === 'v2' ? entityConfig.layout_v2 : entityConfig

      // set widget container
      if (
        layoutConfig.widget_container_type &&
        checkDisplayRules(layoutConfig.widget_container_type.display_rules).enabled
      ) {
        sessionDispatch({
          type: SessionAction.SET_SESSION,
          payload: {
            widgetContainerType: layoutConfig.widget_container_type.container_type,
          },
        })
      }

      // commit entity config to state
      entityDispatch({
        type: EntityAction.SET_ENTITY_CONFIG,
        payload: {
          entityConfig,
        },
      })
    },
  })

  if (loading) {
    return <FullPageLoadingScreen text={t('Interface.Loading entity config')} />
  }

  if (entityState.entityConfig === null && entityConfigId) {
    return (
      <WidgetErrorHandler
        onRetry={reloadConfig}
        displayType='full'
        errorPayload={error?.errorPayload}
      />
    )
  }

  let showSearchPanels = !entityConfigId

  if (!showSearchPanels && uiVersion === 'v1') {
    showSearchPanels = // customer lob specific
      (lineOfBusiness === LinesOfBusiness.customer && !orderId && !userId) ||
      // vendor lob  specific
      (lineOfBusiness === LinesOfBusiness.vendor && !vendorId && !globalVendorId && !orderId)
  }

  if (showSearchPanels) {
    switch (lineOfBusiness) {
      case LinesOfBusiness.vendor:
        return (
          <div className={classes.searchPageContainer}>
            <Result
              title={`${t('Messages.No Vendor Found')}!`}
              subTitle={t('Messages.Search for vendor')}
            />
            <SearchVendorPanel
              lob={lineOfBusiness}
              defaultEntityId={globalEntityId}
              ErrorRenderer={WidgetErrorHandler}
              currentOrderId={orderId}
              showResultsInModal
              activeTab={null}
            />
          </div>
        )

      default:
        return (
          <div className={classes.searchPageContainer}>
            <Result
              title={`${t('Messages.No order available')}!`}
              subTitle={t('Messages.Search for an order or customer')}
              status={404}
            />
            <SearchOrderPanel
              lob={lineOfBusiness}
              defaultEntityId={globalEntityId}
              ErrorRenderer={WidgetErrorHandler}
              currentOrderId={orderId}
              showResultsInModal
              activeTab={null}
            />
          </div>
        )
    }
  }

  let content

  if (uiVersion === 'v2') {
    content = <UUILayout />
  } else if (lineOfBusiness !== LinesOfBusiness.rider) {
    content = <V1Panel />
  } else {
    content = (
      <div className={classes.riderContainer}>
        <RiderPanel />
      </div>
    )
  }

  // render main ui
  return (
    <div id='mercury' data-version={uiVersion} data-container-type={widgetContainerType}>
      {content}
    </div>
  )
}

export default EntityPanel
