import React, { useImperativeHandle, useMemo, useState, useEffect } from 'react'
import { BuiltinWidgetConfigs, SDK_EVENTS } from 'types'
import { WidgetActionHandlers } from 'shared'
import { getOrderFulfillment } from 'services/fulfillmentApi/getOrderFulfillment'
import { DeliveryItem } from 'types/api/fulfillmentApi/fulfillment'
import { RiderStatuses } from 'types/widgets/rider/riderStatuses'
import { Notifications } from 'widgets/Deliveries/Notifications/Notifications'
import { useTranslation } from 'hooks/useTranslation'
import { useApiService } from 'hooks/useApiService'
import { useLoadOrderStatusHistory } from 'hooks/apiHooks/useLoadOrderStatusHistory'
import { createPluggableWidget } from 'factory/createPluggableWidget'
import { useDeliveriesStyles } from './styles'
import { PickAndDropAddress } from './PickAndDropAddress'
import { DeliveryPanelList, NoDeliveryPanel } from './DeliveryPanel'

export const DeliveriesV2 = createPluggableWidget<BuiltinWidgetConfigs['order_deliveries_v2']>(
  ({ config, ErrorRenderer, sdk, order, vendor }, ref) => {
    const { t } = useTranslation()
    const classes = useDeliveriesStyles()

    const [isMapVisible, setIsMapVisible] = useState(!sdk.hideMapByDefault)

    const {
      loading: isLoadingFulfillment,
      data: fulfillment,
      loadService: refreshFulfillment,
    } = useApiService({
      service: getOrderFulfillment,
      params: {
        entityId: order?.global_entity_id,
        orderId: order?.order_id,
      },
      shouldLoad: Boolean(order?.global_entity_id && order?.order_id),
      deps: [order?.global_entity_id, order?.order_id],

      onSuccess: ({ data }) => {
        const deliveries = (data?.deliveries ?? []).filter((delivery) => !!delivery.courier)
        if (deliveries.length) {
          const count = ` (${deliveries.length})`
          sdk.dispatch({
            type: SDK_EVENTS.SET_VIEW_STATE,
            payload: {
              title: t(`widget_labels.order_deliveries`) + count,
            },
          })
        }
      },
    })

    const {
      error: errorLoadingOrderStatusHistory,
      isLoading: isLoadingOrderStatusHistory,
      loadService: refreshOrderStatusHistory,
    } = useLoadOrderStatusHistory()

    useImperativeHandle(
      ref,
      () => {
        return {
          onRefresh: () => {
            sdk.captureUserAction('REFRESH_DELIVERIES')
            refreshFulfillment()
            refreshOrderStatusHistory()
          },
        }
      },
      [refreshFulfillment, refreshOrderStatusHistory, sdk],
    )

    useEffect(() => {
      const listener = sdk.eventEmitter.addEventsListener({
        names: [
          'CHANGE_DELIVERY_TIME_SUCCESS',
          'ADD_RIDER_SUCCESS',
          'MOVE_DELIVERY_PENDING_TO_QUEUE_SUCCESS',
          'CHANGE_DELIVERY_STATUS_SUCCESS',
          'SET_PRIMARY_DELIVERY_SUCCESS',
        ],
        callback: () => {
          refreshFulfillment()
        },
      })

      return () => {
        sdk.eventEmitter.removeEventListeners(listener)
      }
    }, [refreshFulfillment, sdk.eventEmitter])

    useEffect(() => {
      const interval = setInterval(() => {
        refreshFulfillment()
        refreshOrderStatusHistory()
      }, 60000 * 2)
      return () => clearInterval(interval)
    }, [refreshFulfillment, refreshOrderStatusHistory])

    const handleViewMap = () => {
      setIsMapVisible(true)
    }

    const canDisplayNotifications = useMemo(() => {
      const deliveryIssuesDisplayRules = config.data_points?.find(
        (data_point) => data_point.name === 'customer_notifications',
      )?.display_rules
      return sdk.checkDisplayRules({
        displayRules: deliveryIssuesDisplayRules,
      })
    }, [config.data_points, sdk])

    const { primaryDelivery, activeDeliveries, inactiveDeliveries } = useMemo(() => {
      const deliveries: DeliveryItem[] = fulfillment?.deliveries || []

      return deliveries.reduce(
        (result, delivery) => {
          const cancelled = delivery?.courier_events?.some((event) =>
            [
              RiderStatuses.canceled,
              RiderStatuses.manualUndispatched,
              RiderStatuses.undispatched,
            ].includes(event.name),
          )

          if (delivery.primary_delivery) {
            result.primaryDelivery = delivery
          } else if (cancelled) {
            result.inactiveDeliveries.push(delivery)
          } else {
            result.activeDeliveries.push(delivery)
          }

          return result
        },
        { primaryDelivery: null, activeDeliveries: [], inactiveDeliveries: [] },
      )
    }, [fulfillment?.deliveries])

    const showActionHandlers = Boolean(config.action_handlers?.length)

    const deliveryInstructionsTags = order?.delivery?.delivery_instructions_tags ?? []

    const isLoading = isLoadingFulfillment || isLoadingOrderStatusHistory

    return (
      <ErrorRenderer
        loading={isLoading}
        errorPayload={errorLoadingOrderStatusHistory?.errorPayload}
      >
        {() => (
          <div className={classes.container}>
            {showActionHandlers && (
              <WidgetActionHandlers marginTop={0} actionHandlers={config.action_handlers} />
            )}
            <div className={classes.addressSection}>
              <PickAndDropAddress
                pickup={vendor?.location}
                dropoff={order?.delivery?.location}
                deliveryInstructionsTags={deliveryInstructionsTags}
                config={config}
              />
            </div>
            {canDisplayNotifications && (
              <div className={classes.notificationSection}>
                <Notifications notifications={fulfillment?.customer_notifications} />
              </div>
            )}
            {Boolean(primaryDelivery) ? (
              <DeliveryPanelList
                config={config.order_delivery}
                isMapVisible={isMapVisible}
                defaultActiveKey={[String(primaryDelivery?.id)]}
                order={order}
                onViewMap={handleViewMap}
                fulfillment={fulfillment}
                deliveries={[primaryDelivery, ...activeDeliveries]}
                issues={fulfillment.issues}
                label={t('widgets.order_deliveries.deliveries.riders')}
              />
            ) : (
              <NoDeliveryPanel order={order} config={config.order_delivery} />
            )}
            {Boolean(inactiveDeliveries.length) && (
              <DeliveryPanelList
                config={config.order_delivery}
                isMapVisible={isMapVisible}
                defaultActiveKey={[]}
                order={order}
                onViewMap={handleViewMap}
                fulfillment={fulfillment}
                deliveries={inactiveDeliveries}
                issues={fulfillment.issues}
                label={t('widgets.order_deliveries.deliveries.cancelled_riders')}
              />
            )}
          </div>
        )}
      </ErrorRenderer>
    )
  },
  {
    category: 'data_lookup',
    deriveConfig({ entityConfig }) {
      return entityConfig.layout_v2.builtin_widgets_configs.order_deliveries_v2
    },
    deriveInitialViewState() {
      return {
        paddingTop: 0,
      }
    },
  },
)
