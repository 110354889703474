/**
 * util function to figure out Platform, i.e. Apac, Peya, Mena, EU
 * runs the check by LOB and if current entity exists in LOB's entity files in Mercury
 * */

import {
  apacCustomerEntities,
  peyaCustomerEntities,
  euCustomerEntities,
  menaCustomerEntities,
} from 'entityConfig/customerEntities'
import {
  apacRiderEntities,
  peyaRiderEntities,
  euRiderEntities,
  menaRiderEntities,
} from 'entityConfig/riderEntities'
import { apacRiderV2Entities, euRiderV2Entities } from 'entityConfig/riderV2Entities'
import { AgentPlatform } from 'types/session/platforms'
import { LinesOfBusiness } from 'types/session/linesOfBusiness'

function getPlatformByGei(lineOfBusiness: string, globalEntityId: string) {
  // if lob is rider, check rider entities; otherwise customer entities
  if (lineOfBusiness === LinesOfBusiness.rider) {
    if (apacRiderEntities && apacRiderEntities[globalEntityId]) {
      return AgentPlatform.apac
    } else if (peyaRiderEntities && peyaRiderEntities[globalEntityId]) {
      return AgentPlatform.peya
    } else if (euRiderEntities && euRiderEntities[globalEntityId]) {
      return AgentPlatform.eu
    } else if (menaRiderEntities && menaRiderEntities[globalEntityId]) {
      return AgentPlatform.mena
    }
  } else if (lineOfBusiness === LinesOfBusiness.riderV2) {
    if (apacRiderV2Entities && apacRiderV2Entities[globalEntityId]) {
      return AgentPlatform.apac
    }
    if (euRiderV2Entities && euRiderV2Entities[globalEntityId]) {
      return AgentPlatform.eu
    }
  } else {
    if (apacCustomerEntities && apacCustomerEntities[globalEntityId]) {
      return AgentPlatform.apac
    } else if (peyaCustomerEntities && peyaCustomerEntities[globalEntityId]) {
      return AgentPlatform.peya
    } else if (euCustomerEntities && euCustomerEntities[globalEntityId]) {
      return AgentPlatform.eu
    } else if (menaCustomerEntities && menaCustomerEntities[globalEntityId]) {
      return AgentPlatform.mena
    }
  }
}

export default getPlatformByGei
