/**
 * Change Cooking Instructions Modal
 * includes the logic, inputs and api client
 * */

import React, { useState, useEffect, useContext, useImperativeHandle } from 'react'
// contexts & types
import { DataContext } from 'contexts/data/DataContext'
import { SessionContext } from 'contexts/session/SessionContext'
import { OrderItemT, PatchItemT, ToppingT } from 'types/widgets/order/orderItem'
import { DataAction } from 'contexts/data/types'
// hooks
import { useTranslation } from 'hooks/useTranslation'
// utils
// styles
import { createUseStyles } from 'react-jss'
import styles from './ModalView.styles'
import { Typography, Descriptions, Button, Result } from 'antd'
import { RightOutlined, DownOutlined } from '@ant-design/icons'
// components
import ItemCookingInstructionInput from './ItemCookingInstructionInput'
import { useClearCommentsFromDataContext } from 'hooks/useClearCommentsFromDataContext'
import { patchCookingInstructions } from 'services/ordersApi/patchCookingInstructions'
import { useOrderStatus } from 'hooks/useOrderStatus'
import { useApiService } from 'hooks/useApiService'
import { WidgetErrorHandler } from 'components/WidgetErrorHandler/widgetErrorHandler'
import { useCaptureUserAction } from 'hooks/events/useCaptureUserAction'
import { createPluggableWidget } from 'factory/createPluggableWidget'

const useStyles = createUseStyles(styles)

export const ChangeCookingInstructionsView = createPluggableWidget<{}>(
  ({ onQuit, globalEntityId, sdk }, ref) => {
    const classes = useStyles()
    const { Text } = Typography

    // pull language content
    const { t } = useTranslation()
    const clearCommentsFromDataContext = useClearCommentsFromDataContext()
    const orderStatus = useOrderStatus()

    // pull order from data context
    const {
      dataState: { orderItems },
      dataDispatch,
    } = useContext(DataContext)
    const { SET_AUTO_CHANGE_COOKING_INST_COMMENT, SET_ITEMS_WITH_COOKING_INST_CHANGED } = DataAction

    // pull order ID from session context
    const {
      sessionState: { orderId },
    } = useContext(SessionContext)

    const captureUserAction = useCaptureUserAction()

    const [itemsWithInstructions, setItemsWithInstructions] = useState<Array<OrderItemT>>([])
    const [shouldSaveButtonBeDisabled, setShouldSaveButtonBeDisabled] = useState(true)
    useEffect(() => {
      setShouldSaveButtonBeDisabled(true)
      if (itemsWithInstructions && itemsWithInstructions.length > 0) {
        itemsWithInstructions.forEach((item: OrderItemT) => {
          orderItems.forEach((originalItem: OrderItemT) => {
            if (item.id === originalItem.id) {
              if (item.customer_notes !== originalItem.customer_notes) {
                setShouldSaveButtonBeDisabled(false)
              }
            }
          })
        })
      } else {
        setShouldSaveButtonBeDisabled(true)
      }
    }, [itemsWithInstructions, orderItems])

    const [itemIdsWithToppingsDisplayed, setItemIdsWithToppingsDisplayed] = useState<string[]>([])
    const handleIconClick = (id: string, shouldDisplay: boolean) => {
      if (shouldDisplay) {
        setItemIdsWithToppingsDisplayed([...itemIdsWithToppingsDisplayed, id])
      } else {
        setItemIdsWithToppingsDisplayed(
          itemIdsWithToppingsDisplayed.filter((i: string) => i !== id),
        )
      }
    }

    const {
      loadService: executePatchCookingInstructions,
      status: patchStatus,
      error: patchCookingInstructionsError,
      clearError: clearPatchCookingInstructionsError,
      loading: isPatchingCookingInstructions,
    } = useApiService({
      service: patchCookingInstructions,
      deps: [],
      autoLoad: false,
    })

    const handleSaveClick = async () => {
      captureUserAction('ActionsChangeCookingInstructionsSubmitted')
      // construct producst from itemsWithInstructions to be sent to api
      const products: Array<PatchItemT> = []
      for (const item of itemsWithInstructions) {
        const itemStructured = {
          id: item.id.split('-')[1],
          line_item_id: item.line_item_id,
          comment: item.customer_notes,
        }
        products.push(itemStructured)
      }

      await executePatchCookingInstructions({
        entityId: globalEntityId,
        orderId,
        products,
        orderStatus,
      })
        .then(() => {
          sdk.eventEmitter.dispatchEvent({
            name: 'CHANGE_COOKING_INSTRUCTIONS_SUCCESS',
            payload: { orderId },
          })
          captureUserAction('ActionsChangeCookingInstructionsSuccess')
          clearCommentsFromDataContext()

          dataDispatch({
            type: SET_AUTO_CHANGE_COOKING_INST_COMMENT,
            payload: { autoChangeCookingInstComment: orderId },
          })

          dataDispatch({
            type: SET_ITEMS_WITH_COOKING_INST_CHANGED,
            payload: { itemsWithCookingInstructionChanged: products },
          })
        })
        .catch((err) => {
          captureUserAction('ActionsChangeCookingInstructionsError', {
            eventDetails: {
              error: err.response.status || null,
            },
          })
        })
    }

    const handleSuccessOkClick = () => {
      captureUserAction('ActionsChangeCookingInstructionsSuccessOkButtonClicked')
      onQuit(true)
    }

    useImperativeHandle(
      ref,
      () => {
        return {
          onXButtonClick: () => {
            return (
              !itemsWithInstructions ||
              itemsWithInstructions.length === 0 ||
              patchStatus === 'success'
            )
          },
        }
      },
      [itemsWithInstructions, patchStatus],
    )

    return (
      <WidgetErrorHandler
        errorPayload={patchCookingInstructionsError?.errorPayload}
        displayType='overlay'
        onQuit={onQuit}
        onBack={clearPatchCookingInstructionsError}
        loading={isPatchingCookingInstructions}
      >
        {() => {
          if (patchStatus === 'success') {
            return (
              <Result
                status={'success'}
                title={
                  <Text className={classes.successMaintext}>
                    {`${t(
                      'Actions Widget.Actions.Change Cooking Instructions.Cooking instruction saved',
                    )}!`}
                  </Text>
                }
                subTitle={
                  <div className={classes.successHolder}>
                    <Text className={classes.successSubtext}>
                      {`${t(
                        'Actions Widget.Actions.Change Cooking Instructions.You have updated the cooking instructions successfully',
                      )}.`}
                    </Text>
                    <div className={classes.successButtonHolder}>
                      <Button
                        className={classes.successButton}
                        type='primary'
                        onClick={handleSuccessOkClick}
                      >
                        {t('Interface.OK')}
                      </Button>
                    </div>
                  </div>
                }
              />
            )
          }

          return (
            <React.Fragment>
              <Descriptions bordered column={1} size='small'>
                {/* TITLES Item */}
                <Descriptions.Item
                  style={{ margin: 0, padding: 0 }}
                  label={
                    <div className={classes.titlesItemLabelAndValueContainer}>
                      <Text className={classes.titlesItemText}>
                        {t('Actions Widget.Actions.Change Cooking Instructions.Item')}
                      </Text>
                    </div>
                  }
                >
                  <div className={classes.titlesItemLabelAndValueContainer}>
                    <Text className={classes.titlesItemText}>
                      {t('Actions Widget.Actions.Change Cooking Instructions.Cooking Instruction')}
                    </Text>
                  </div>
                </Descriptions.Item>

                {/* ORDER Items */}
                {orderItems && orderItems.length > 0
                  ? orderItems.map((item: OrderItemT) => {
                      const shouldDisplayToppingsToggle = item.options ? true : false

                      return (
                        <Descriptions.Item
                          style={{ padding: 0, margin: 0 }}
                          key={item.id}
                          label={
                            <div className={classes.labelHolder}>
                              <Text className={classes.descLabel}>
                                {/* TOGGLE icons for items with toppings */}
                                {shouldDisplayToppingsToggle &&
                                itemIdsWithToppingsDisplayed.includes(item.id) ? (
                                  <RightOutlined
                                    className={classes.icons}
                                    onClick={() => handleIconClick(item.id, false)}
                                  />
                                ) : shouldDisplayToppingsToggle &&
                                  !itemIdsWithToppingsDisplayed.includes(item.id) ? (
                                  <DownOutlined
                                    className={classes.icons}
                                    onClick={() => handleIconClick(item.id, true)}
                                  />
                                ) : null}
                                {item.display_name ? item.display_name : item.name}
                              </Text>

                              {/* TOPPINGS section */}
                              {itemIdsWithToppingsDisplayed.includes(item.id) ? (
                                <div className={classes.topingsHolder}>
                                  {item.options
                                    ? item.options.map((option: ToppingT) => (
                                        <Text key={option.id} className={classes.descLabelTopping}>
                                          {`${
                                            option.immutable_quantity
                                              ? option.immutable_quantity
                                              : option.quantity
                                          } X ${option.name}`}
                                        </Text>
                                      ))
                                    : null}
                                </div>
                              ) : null}
                            </div>
                          }
                        >
                          <ItemCookingInstructionInput
                            item={item}
                            itemsWithInstructions={itemsWithInstructions}
                            setItemsWithInstructions={setItemsWithInstructions}
                          />
                        </Descriptions.Item>
                      )
                    })
                  : null}
              </Descriptions>
              <div className={classes.buttonHolder}>
                <Button
                  type='primary'
                  disabled={shouldSaveButtonBeDisabled}
                  onClick={handleSaveClick}
                >
                  {t('Interface.Save')}
                </Button>
              </div>
            </React.Fragment>
          )
        }}
      </WidgetErrorHandler>
    )
  },

  {
    category: 'action',
  },
)
