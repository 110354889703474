export default {
  orderContainer: {
    margin: '0px 3px 0px 3px',
  },
  titleHolder: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'baseline',
  },
  sectionTitle: {
    fontSize: '18px',
    color: 'rgba(0, 0, 0, 0.85)',
  },
  divider: {
    padding: 0,
    margin: '12px 0px 12px 0px',
  },
}
