import React from 'react'
import { Tooltip } from 'antd'
import { createUseStyles } from 'react-jss'
import { PhoneOutlined } from '@ant-design/icons'
import { useTranslation } from 'hooks/useTranslation'
import { gray } from 'theme'

const useStyles = createUseStyles({
  phoneIcon: {
    borderRadius: 4,
    border: `1px solid ${gray.gray5}`,
    fontSize: '14px',
    padding: 5,
    boxShadow: '0 2px 0 rgba(0,0,0,.045)',
  },
})

export const PhoneIcon = () => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <Tooltip title={t('Widgets Common.Call')}>
      <PhoneOutlined className={classes.phoneIcon} />
    </Tooltip>
  )
}
