import { useCallback, useMemo } from 'react'
import { TranslateFunction } from 'hooks/useTranslation'
import { RiderShift } from 'types/api/riderApi/rider'
import moment from 'moment-timezone'
import { useDateTimeFormatter } from 'hooks/formatters/useDateTimeFormatter'

type Props = {
  riderShifts: RiderShift[]
  t: TranslateFunction
  utcZone: string
}

type RiderShiftTransformedType = {
  id: number
  /* reminder message, if the shift is starting/ending soon */
  reminder: string
  start: {
    time: string
    date: string
  }
  end: {
    time: string
    date: string
  }
  zone: string
}

export const useTranformRiderShifts = ({
  riderShifts,
  t,
  utcZone,
}: Props): RiderShiftTransformedType[] => {
  const { formatTime, formatDate } = useDateTimeFormatter()

  // formatter needed later
  const dateTimeFormatter = useCallback(
    (timestamp) => {
      return {
        time: formatTime(timestamp, {
          format: 'LT',
        }),
        date: formatDate(timestamp, {
          format: 'D MMM',
        }),
      }
    },
    [formatDate, formatTime],
  )

  return useMemo(() => {
    return riderShifts.map(({ id, started_at, ended_at, starting_point }) => {
      let reminder = ''

      // convert to moment
      const now = moment().tz(utcZone)
      const startDate = moment(started_at).tz(utcZone)
      const endDate = moment(ended_at).tz(utcZone)

      // If time to shift start is less than 60 min, show start message.
      // else if time to shift end < 60 mins, show end message.
      // Otherwise show nothing
      const startDiffFromNow = startDate.diff(now, 'minute')
      const endDiffFromNow = endDate.diff(now, 'minute')
      if (startDiffFromNow > 0 && startDiffFromNow <= 60) {
        reminder = `${t('widgets.rider_upcoming_shifts.starting_in')} ${startDiffFromNow} ${t(
          'widgets.rider_upcoming_shifts.mins',
        )}`
      } else if (endDiffFromNow > 0 && endDiffFromNow <= 60) {
        reminder = `${t('widgets.rider_upcoming_shifts.ending_in')} ${endDiffFromNow} ${t(
          'widgets.rider_upcoming_shifts.mins',
        )}`
      }

      return {
        id,
        reminder,
        zone: starting_point?.name,
        start: dateTimeFormatter(started_at),
        end: dateTimeFormatter(ended_at),
      }
    })
  }, [dateTimeFormatter, riderShifts, t, utcZone])
}
