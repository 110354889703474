import React from 'react'
import { createUseStyles } from 'react-jss'
import styles from './Contacts.styles'
import { UnifiedIcon } from 'shared'
import { useSdk } from 'contexts/SdkProvider'

const useStyles = createUseStyles(styles)

export const NoContactsCard = () => {
  const classes = useStyles()
  const { t } = useSdk()

  return (
    <div className={classes.emptyContactsContainer}>
      <div className={classes.emptyCard}>
        <UnifiedIcon icon='QuestionCircleOutlined' mr={4} size={21} />
        <div className={classes.title}>{t('No contacts loaded')}</div>
        <div className={classes.subtitle}>{t('Please reload the page or try again later')}</div>
      </div>
    </div>
  )
}
