import { text } from 'theme'

interface StyleProps {
  width: number
}

export default {
  container: (props: StyleProps) => {
    return {
      margin: '0 auto',
      width: props.width,
      padding: '48px',
    }
  },
  header: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },

  heading: {
    fontSize: '23px',
    lineHeight: '43.2px',
    marginTop: '21px',
    color: 'rgba(0, 0, 0, 0.85)',
  },

  itemDescription: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'no-wrap',
    fontSize: '14px',
    lineHeight: '22px',
    color: 'rgba(0, 0, 0, 0.65)',

    margin: '0px',
    '&.error': {
      fontWeight: 600,
      color: '#000',
    },
    '& + &': {
      marginTop: '4px',
    },
  },

  buttonsContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '32px',
  },

  mr8: {
    marginRight: '8px',
  },

  mt8: {
    marginTop: '20px',
  },

  mt20: {
    marginTop: '20px',
  },

  mt32: {
    marginTop: '32px',
  },

  mb16: {
    marginBottom: '16px',
  },

  notice: {
    color: text.secondary,
    background: 'none',
    border: 0,
    padding: 0,

    '& .ant-alert-message': {
      color: text.secondary,
      marginLeft: 8,
    },

    '& + &': {
      marginTop: 4,
    },
  },
}
