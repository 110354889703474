import React, { useState, createContext, FunctionComponent, useEffect } from 'react'
import type * as Icons from 'Icons'
import { CustomIcon, PlaceholderSvgIcon } from 'factory/createCustomSvgIcon'
import type { AccountBookFilled as AntdIcon } from '@ant-design/icons'
import { useContextProvider } from 'contexts/useContextProvider'

export type UnifiedIconName = keyof typeof Icons | 'default' | 'PlaceholderIcon'

export interface IconProviderState {
  ready: boolean
  setReady: () => void
}

export type IconPacks = Record<UnifiedIconName, typeof AntdIcon | CustomIcon>

export const IconPacksProviderContext = createContext<IconPacks>(undefined)

/**
 * used to access state
 */
export const useIconPacks = () => {
  return useContextProvider(IconPacksProviderContext, 'IconPacksProvider')
}

/**
 * IconPack provider is used to lazy load all svg icons used by your application. there are already default icon packs used by the project
 * supply a loader, that imports and returns your icon packs.
 *
 * you can also supply initial icons packs as iconPack
 * @param param0
 * @returns
 */

export const IconPacksProvider: FunctionComponent<{
  /**
   * initialial icon pack
   */
  iconPacks?: IconPacks

  /**
   * icon packs loader. called on mount
   */
  loader?: () => Promise<IconPacks>
}> = ({ children, iconPacks, loader }) => {
  const [icons, setIcons] = useState<IconPacks>(() => {
    //avoid recreating the icon packs object
    if (iconPacks && iconPacks.PlaceholderIcon) {
      return iconPacks
    }
    return {
      ...iconPacks,
      PlaceholderIcon: PlaceholderSvgIcon,
    }
  })

  useEffect(
    () => {
      if (loader) {
        loader().then((result) => {
          setIcons((currentIcons) => {
            return {
              ...currentIcons,
              ...result,
            }
          })
        })
      }
    },
    // only initialize once
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  return (
    <IconPacksProviderContext.Provider value={icons}>{children}</IconPacksProviderContext.Provider>
  )
}
