import React, { useContext } from 'react'

// Components
import ReactCountryFlag from 'react-country-flag'

// Contexts
import { SessionContext } from 'contexts/session/SessionContext'
import { createUseStyles } from 'react-jss'
import styles from '../Tags.styles'
import SingleTag from '../../../../../components/Tags/SingleTag'

const useStyles = createUseStyles(styles)

function CountryFlag() {
  const classes = useStyles()
  // pull orderId & globalentity ID from session context
  const {
    sessionState: { globalEntityId },
  } = useContext(SessionContext)

  const countryCode = globalEntityId?.split('_')[1] || ''

  return (
    <>
      <SingleTag value={countryCode} />
      <ReactCountryFlag
        countryCode={countryCode}
        svg
        className={classes.flagIcon}
        title={countryCode}
      />
    </>
  )
}

export default CountryFlag
