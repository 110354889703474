import React from 'react'

// Components
import { Typography } from 'antd'

// Hooks
import { useTranslation } from 'hooks/useTranslation'

// Utils
import { createUseStyles } from 'react-jss'

// Relative Path
import styles from './CSWidgetHeading.styles'

const useStyles = createUseStyles(styles)
const { Text } = Typography

interface CSWidgetHeadingInterface {
  id?: string | number
  title: string
  idLabel?: string
}

function CSWidgetHeading({ title, id, idLabel = 'Order Widget.ID' }: CSWidgetHeadingInterface) {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <div className={classes.wrapper}>
      <Text className={classes.customerTitle}>{t(title)}</Text>
      {id && (
        <Text type='secondary' className={classes.idText}>
          {`${t(idLabel)}: ${id}`}
        </Text>
      )}
    </div>
  )
}

export default CSWidgetHeading
