import environment from 'envConfig'
import { readAgentBetaPermissions } from 'hooks/useIsBetaPermissionGranted'

/**
 * if useAwsApi is explicitly set to true or false, it will be obeyed.
 *
 * if not, it will determine the correct baseUrl to use
 */
export function getBaseUrl({
  globalEntityId,
  useAwsApi,
}: {
  globalEntityId: string
  useAwsApi?: boolean
}) {
  if (!environment().oneviewApiRootAWS) {
    useAwsApi = false
  }

  if (typeof useAwsApi !== 'boolean') {
    if (
      environment().awsApiEnabledCountries.has(globalEntityId) ||
      environment().awsApiEnabledCountries.has('ALL')
    ) {
      useAwsApi = true
    } else if (
      environment().awsApiBetaPermissionName &&
      readAgentBetaPermissions(globalEntityId).has(environment().awsApiBetaPermissionName)
    ) {
      useAwsApi = true
    } else {
      useAwsApi = false
    }
  }

  return useAwsApi ? environment().oneviewApiRootAWS : environment().oneviewApiRoot
}
