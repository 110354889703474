/**
 * Confirmation component for Compensation
 * renders a confirmation message with options to proceed and cancel
 * */

// libs
import React, { useContext } from 'react'
// contexts and types
import { DataContext } from 'contexts/data/DataContext'
import { DataAction } from 'contexts/data/types'
import { AvailableCompensationMethods } from 'entityConfig/allowedConfigValues'

// hooks
import { useTranslation } from 'hooks/useTranslation'
// utils
// styles
import { createUseStyles } from 'react-jss'
import styles from './Confirmation.styles'
import { Button, Typography, Result } from 'antd'
import { CopyOutlined } from '@ant-design/icons'
// components
import {
  COMP_CONFIRMATION_SCREEN_ID,
  CREATE_COMP_ID,
} from 'constants/widgetIds/compensationWidgetIds'
import VoucherApiSingleVoucher from 'types/api/voucherApi/getSingleVoucher'
import { useCopyVoucher } from 'hooks/useCopyVoucher'
import { useCaptureUserAction } from 'hooks/events/useCaptureUserAction'

const useStyles = createUseStyles(styles)
interface Props {
  processCompensation: () => void
  isSuccess: boolean
  selectedCompensationMethod: string
  onQuit: (isAcknowledgement?: boolean) => void
  setIsConfirmation: (arg: boolean) => void

  currency: string

  createdVoucher: VoucherApiSingleVoucher
}

const Confirmation: React.FC<Props> = ({
  selectedCompensationMethod,
  onQuit,
  setIsConfirmation,
  currency,
  isSuccess,
  processCompensation,
  createdVoucher,
}) => {
  const classes = useStyles()
  const { Text } = Typography

  // pull language content
  const { t } = useTranslation()

  // pull dataState from data context
  const { dataDispatch } = useContext(DataContext)
  const { SET_COMPENSATED_VOUCHER, SET_FULLY_REFUNDED_VOUCHER } = DataAction

  const copyVoucher = useCopyVoucher()
  const captureUserAction = useCaptureUserAction()

  // fired when OK clicked after successful creation -> resets the state & closes the modal
  const handleSuccessOkClick = () => {
    // set the created voucher to data context to disable Compensation button and highlight new voucher
    dataDispatch({
      type: SET_COMPENSATED_VOUCHER,
      payload: { compensatedVoucher: createdVoucher },
    })
    dataDispatch({
      type: SET_FULLY_REFUNDED_VOUCHER,
      payload: { fullyRefundedVoucher: undefined },
    })
    captureUserAction('ActionsCompensationConfirmationOkButtonClicked', {
      eventDetails: {
        selectedMethod: selectedCompensationMethod,
      },
    })
    onQuit(true)
  }

  return (
    <div className={classes.confirmationContainer} id={COMP_CONFIRMATION_SCREEN_ID}>
      {/* voucher success */}
      {isSuccess && selectedCompensationMethod === AvailableCompensationMethods.voucher && (
        <Result
          status='success'
          title={
            <div className={classes.resultTitleHolder}>
              <Text className={classes.successText}>{`${t('Widgets Common.Success')}!`}</Text>

              {createdVoucher?.code && (
                <React.Fragment>
                  <div className={classes.resultVoucherSection}>
                    <Text className={classes.resultVoucherInfoTitle}>
                      {`${t('Widgets Common.Voucher Code')}:`}
                    </Text>
                    <Text className={classes.resultVoucherInfoData}>{createdVoucher.code}</Text>
                  </div>
                  <Text className={classes.sectionCopyText}>
                    <Button type='link' onClick={() => copyVoucher(createdVoucher, currency)}>
                      {t('Actions Widget.Actions.Compensation.Copy Voucher Info')}
                      <CopyOutlined />
                    </Button>
                  </Text>
                </React.Fragment>
              )}
            </div>
          }
          subTitle={`${t(
            'Actions Widget.Actions.Compensation.Created successfully, it will be shown immediately in the customer wallet',
          )}.`}
          extra={[
            <Button type='primary' key='console' onClick={handleSuccessOkClick}>
              {t('Interface.OK')}
            </Button>,
          ]}
        />
      )}

      {/* wallet success */}
      {isSuccess && selectedCompensationMethod === AvailableCompensationMethods.wallet && (
        <Result
          status='success'
          title={
            <div className={classes.resultTitleHolder}>
              <Text className={classes.successText}>{`${t('Widgets Common.Success')}!`}</Text>
            </div>
          }
          subTitle={`${t(
            'Actions Widget.Actions.Compensation.Compensated successfully, it will be shown immediately in the customer wallet',
          )}.`}
          extra={[
            <Button type='primary' key='console' onClick={handleSuccessOkClick}>
              {t('Interface.OK')}
            </Button>,
          ]}
        />
      )}

      {!isSuccess && (
        <React.Fragment>
          <Text className={classes.title}>{t('Widgets Common.Confirmation')}</Text>
          <Text className={classes.message}>
            {selectedCompensationMethod === AvailableCompensationMethods.voucher
              ? `${t(
                  'Actions Widget.Actions.Compensation.You are about to issue a compensation voucher, it will be shown immediately in the customer wallet',
                )}.`
              : `${t(
                  'Actions Widget.Actions.Compensation.You are about to issue compensation, it will be shown immediately in the customer wallet',
                )}.`}
          </Text>
          <div className={classes.buttonsHolder}>
            <Button type='default' onClick={() => setIsConfirmation(false)}>
              {t('Interface.Go Back')}
            </Button>
            <Button
              type='primary'
              onClick={processCompensation}
              className={classes.continueButton}
              id={CREATE_COMP_ID}
            >
              {t('Interface.Continue')}
            </Button>
          </div>
        </React.Fragment>
      )}
    </div>
  )
}

export default Confirmation
