import { createPluggableWidget } from 'factory/createPluggableWidget'
import React, { useImperativeHandle } from 'react'

export type PluginType = 'tab' | 'action'

export type PluginTargetWidget = 'order' | 'customer' | 'vendor' | 'rider'

/**
 * this is a test plugin
 */
export const Plugin = createPluggableWidget<{}>(
  ({ order }, ref) => {
    useImperativeHandle(
      ref,
      () => {
        return {
          onXButtonClick: () => true,
          onBeforeHide: () => {
            // runs before hide
          },
        }
      },
      [],
    )

    return (
      <div>
        <h1>
          Welcome to @herocare/herocare-plugin-demo. Below is a JSON of order with ID v4xz-we6d:
        </h1>
        <div>
          <pre>{JSON.stringify(order, null, 2)}</pre>
        </div>
      </div>
    )
  },
  {
    category: 'action',
  },
)
