import React, { useState } from 'react'
import styles from './ContactForm.styles'
import { createUseStyles } from 'react-jss'
import { Button, Input, Select } from 'antd'
import { useSdk } from 'contexts/SdkProvider'
import { TransformedVendorContact } from 'utils/dataTransformers/transformVendorContacts'

const useStyles = createUseStyles(styles)

type ContactFormParams = {
  contact: TransformedVendorContact | undefined | null
  onSubmit: (contact: TransformedVendorContact) => void
  onCancel: () => void
}

type FieldName = 'email' | 'name' | 'phone' | 'role'

const initialValue: Record<FieldName, string> = { name: '', email: '', phone: '', role: '' }

export const ContactForm = ({ contact, onSubmit, onCancel }: ContactFormParams) => {
  const [formValues, setFormValues] = useState<TransformedVendorContact>(
    contact === undefined ? initialValue : contact,
  )
  const classes = useStyles()
  const { t } = useSdk()

  const handleFieldChange = (fieldName: FieldName, value: string) => {
    setFormValues((prevState) => ({ ...prevState, [fieldName]: value }))
  }

  return (
    <div className={classes.container}>
      <div className={classes.formContainer}>
        <div className={classes.formField}>
          <div>{t('Name')}</div>
          <Input
            value={formValues.name}
            onChange={(e) => handleFieldChange('name', e.target.value)}
          />
        </div>
        <div className={classes.formField}>
          <div>{t('Role')}</div>
          <Select
            options={[
              //this should be dynamic/configurable
              { label: t('Manager'), value: 'manager' },
              { label: t('Owner'), value: 'owner' },
            ]}
            value={formValues.role}
            onSelect={(value) => handleFieldChange('role', value)}
          />
        </div>
        <div className={classes.formField}>
          <div>{t('Phone Number')}</div>
          <Input
            value={formValues.phone}
            onChange={(e) => handleFieldChange('phone', e.target.value)}
          />
        </div>
        <div className={classes.formField}>
          <div>{t('Email')}</div>
          <Input
            value={formValues.email}
            onChange={(e) => handleFieldChange('email', e.target.value)}
          />
        </div>
      </div>
      <div className={classes.actionButtonsContainer}>
        <Button color='primary' type='default' onClick={() => onSubmit(formValues)}>
          {t('Submit')}
        </Button>
        <Button onClick={onCancel}>{t('Cancel')}</Button>
      </div>
    </div>
  )
}
