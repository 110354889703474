import { useAuthProvider } from 'contexts/auth/AuthProvider'
import environment from 'envConfig'
import { useCallback } from 'react'
import { readAuthPayload } from 'utils/authHelpers'
import { useReadSessionState } from './useSessionState'

export const readAgentBetaPermissions = (globalEntityId: string): Set<string> => {
  const allPermissions = readAuthPayload().permissions || {}
  const globalTestPermissions = allPermissions[environment().betaPermissionsNamespace] || []

  const entityLevelPermissions = (globalEntityId && allPermissions[globalEntityId]) || []

  return new Set(globalTestPermissions.concat(entityLevelPermissions))
}

export const useIsBetaPermissionGranted = () => {
  const { isLoggedIn } = useAuthProvider()
  const readSessionState = useReadSessionState()

  return useCallback(
    (betaRequirements: string[]) => {
      if (!betaRequirements.length) {
        return true
      }

      if (!isLoggedIn) {
        return false
      }

      const betaPermissions = readAgentBetaPermissions(readSessionState().globalEntityId)

      return (
        betaPermissions.size > 0 &&
        betaRequirements.every((requirement) => betaPermissions.has(requirement))
      )
    },
    [isLoggedIn, readSessionState],
  )
}
