import React, { useContext } from 'react'
import { Typography } from 'antd'
import { createUseStyles } from 'react-jss'

import { SingleComment } from 'types/api/commentApi/getOrderComments'
import { useTranslation } from 'hooks/useTranslation'
import { commentTagType, CommentView } from 'components/Comments/CommentView'
import { getAgentNameFromId } from 'utils/string'
import styles from './Comments.styles'
import { WidgetSize } from 'types/widgets/historyOfEvents/tabs'
import { DataContext } from 'contexts/data/DataContext'
import { commaAndChainItems } from 'utils/commaAndDotChain'

const { Text } = Typography

const useStyles = createUseStyles(styles)

type Props = {
  comment: SingleComment
  shouldCommentBeHighlighted: boolean
  mode: WidgetSize
}

export const RestoreOrderItemsComment = ({ comment, shouldCommentBeHighlighted, mode }: Props) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const {
    dataState: { order },
  } = useContext(DataContext)

  const additionalData = comment.additional_data.restore_order_items

  if (!additionalData || !additionalData.items || additionalData.items.length === 0) {
    return null
  }

  const restoredItems = additionalData.items.map((restoredItem) => {
    const lineItemId = restoredItem.line_item_id
    return {
      lineItemId,
      name: order.order.items.find((item) => item.line_item_id === lineItemId).name,
      quantityRestored: restoredItem.quantity,
      restoredToppings: restoredItem.restore_toppings,
    }
  })

  return (
    <CommentView
      mode={mode}
      comment={comment}
      shouldCommentBeHighlighted={shouldCommentBeHighlighted}
      tag={{
        type: commentTagType.thunderbolt,
        text: t('History of Events Widget.Event Types.Restore Order Items'),
      }}
    >
      <Text className={classes.commentText}>
        <Text>
          {`${getAgentNameFromId(comment.agent_id)} 
          ${t('History of Events Widget.Content.Restored')} `}
        </Text>

        {restoredItems?.map((restoredItem, index) => {
          return (
            <span key={restoredItem.lineItemId}>
              <Text strong>{`${restoredItem?.quantityRestored} X ${restoredItem.name}`}</Text>
              <Text>{`${commaAndChainItems(index + 1 !== restoredItems.length, { t })}`}</Text>
              {restoredItem.restoredToppings?.apply ? (
                <Text>
                  {t('History of Events Widget.Content.With {{numberOfToppings}} toppings', {
                    replace: {
                      numberOfToppings: restoredItem.restoredToppings?.topping_ids?.length,
                    },
                  })}
                </Text>
              ) : null}
            </span>
          )
        })}
      </Text>
    </CommentView>
  )
}
