// libs
import React from 'react'
import { Moment } from 'moment'
// styles
import { TimePicker } from 'antd'

interface Props {
  handleDateChange: (arg: Moment, secArg: boolean) => void
  defaultValue: Moment
  selectedDeliveryDate: Moment
  disabledHours: () => number[]
  disabledMinutes: () => number[]
}

const TimePickerView: React.FC<Props> = ({
  handleDateChange,
  defaultValue,
  selectedDeliveryDate,
  disabledHours,
  disabledMinutes,
}) => {
  return (
    <TimePicker
      allowClear={false}
      showNow={false}
      format='HH:mm'
      minuteStep={5}
      defaultValue={defaultValue}
      value={selectedDeliveryDate ? selectedDeliveryDate : null}
      disabledHours={disabledHours}
      disabledMinutes={disabledMinutes}
      onChange={(date) => handleDateChange(date, true)}
      onSelect={(date) => handleDateChange(date, true)}
    />
  )
}

export default TimePickerView
