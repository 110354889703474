import { ApiService } from 'services/authApi/getPermissions'
import { OrderTicketsResponse } from 'types/api/ticketApi/getOrderTickets'

export const getOrderTickets: ApiService<
  {
    entityId: string
    orderId: string
    department?: 'CS' | 'RS'
    type?: 'chat' | string
    direction?: 'asc' | 'desc'
  },
  OrderTicketsResponse
> = (createClient, options) => {
  const { entityId, orderId, department = '', direction = '', type = '', clientParams } = options

  const uri = `/TicketAPI/v1/ticket/${entityId}/order/${orderId}/tickets?department=${department}&type=${type}&direction=${direction}`

  return createClient({
    endpointName: 'getTicketsByOrder',
    expectedResponseStatusCode: 200,
    ...clientParams,
  }).get(uri)
}
