import React, { useMemo } from 'react'
import { Statuses } from 'types/dataTransformers/customerVouchers'
import { CheckCircleFilled, ClockCircleOutlined, StopOutlined } from '@ant-design/icons'
import { Tooltip } from 'antd'
import { useTranslation } from 'hooks/useTranslation'
import { LabelledIcon } from 'shared/LabelledIcon'

type VoucherStatusAndCountProp = {
  count?: number | string
  status: Statuses
  showStatusLabel?: boolean
  tooltip?: string
}
export const VoucherStatusAndCount: React.FC<VoucherStatusAndCountProp> = ({
  status,
  count = '',
  showStatusLabel = true,
}) => {
  const iconColor = useMemo(() => {
    let iconColor = '#8C8C8C'
    switch (status) {
      case Statuses.applicable:
        iconColor = '#52C41A'
        break
      case Statuses.used:
        iconColor = '#00000040'
        break
      case Statuses.expired:
        iconColor = '#8C8C8C'
        break
      case Statuses.notApplicable:
        iconColor = '#8C8C8C'
        break
      default:
        break
    }
    return iconColor
  }, [status])

  const { t } = useTranslation()

  const translatedStatusText = useMemo(() => {
    let statusText = ''
    switch (status) {
      case Statuses.applicable:
        statusText = t('Customer Widget.Tabs.Vouchers.Voucher Statuses.Applicable')
        break
      case Statuses.expired:
        statusText = t('Customer Widget.Tabs.Vouchers.Voucher Statuses.Expired')
        break
      case Statuses.notApplicable:
        statusText = t('Customer Widget.Tabs.Vouchers.Voucher Statuses.Not applicable')
        break
      case Statuses.used:
        statusText = t('Customer Widget.Tabs.Vouchers.Voucher Statuses.Used')
        break

      default:
        break
    }
    return statusText
  }, [status, t])
  const label = showStatusLabel ? `${count} ${translatedStatusText}` : ''
  return (
    <LabelledIcon
      icon={
        <Tooltip title={translatedStatusText}>
          <div>
            {status === Statuses.applicable && <CheckCircleFilled />}
            {status === Statuses.expired && <ClockCircleOutlined />}
            {status === Statuses.notApplicable && <StopOutlined />}
            {status === Statuses.used && <CheckCircleFilled />}
          </div>
        </Tooltip>
      }
      iconColor={iconColor}
      label={label}
    />
  )
}

/**Icon based on given status */
type VoucherIconProps = Pick<VoucherStatusAndCountProp, 'status' | 'showStatusLabel'>

export const VoucherStatusIcon: React.FC<VoucherIconProps> = ({
  status,
  showStatusLabel = true,
}) => <VoucherStatusAndCount status={status} showStatusLabel={showStatusLabel} />
