import trimEnd from 'lodash/trimEnd'
import trimStart from 'lodash/trimStart'

export const getPublicUrl = (path: string): string => {
  const base = (process.env as any).PUBLIC_URL || '/'
  const trimmedPath = trimStart(path, '/')
  const trimmedBase = trimEnd(base, '/')

  return `${trimmedBase}/${trimmedPath}`
}
