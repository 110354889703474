import { primary } from 'theme'

export default {
  textBlock: {
    display: 'flex',
    alignItems: 'baseline',
    width: '100%',
  },
  labelText: {
    fontSize: '13px',
    color: 'rgba(0, 0, 0, 0.45)',
    marginRight: '5px',
  },
  dataText: {
    fontSize: '14px',
    color: 'rgba(0, 0, 0, 0.65)',
  },
  separator: {
    fontSize: '14px',
    color: 'rgba(0, 0, 0, 0.45)',
  },
  icon: {
    marginLeft: '7px',
    color: primary.primary6,
  },
  linkButton: {
    padding: 0,
  },
}
