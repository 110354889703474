import { useTranslation } from 'hooks/useTranslation'
import fixNumber from 'utils/fixNumber'
import modifyUTC from 'utils/modifyUTC'
import { useEntityConfig } from './useEntityConfig'
import { useCanDisplayDataPoint } from './useGetValidFeatures'
import { unknownDataPoint } from '../utils/dataTransformers/constants'
import {
  Schedule,
  Voucher,
  VoucherConstraints,
  VoucherDataPoint,
  VoucherDateDataPoint,
  VoucherValueDataPoint,
} from '../types/api/voucherApi/voucher'
import { VoucherTypes } from '../types/dataTransformers/customerVouchers'
import { dayDifferenceFromNow } from 'utils/dayDifferenceFromNow'

const useTransformCustomerVoucher = () => {
  const { t } = useTranslation()
  const canDisplayDataPoint = useCanDisplayDataPoint()

  const {
    entityConfig: {
      utc_zone,
      scrollable_panel_config: {
        widgets_configs: {
          customer: {
            tab_configs: { vouchers },
          },
        },
      },
    },
  } = useEntityConfig()
  return (voucher: Voucher) => {
    const code: VoucherDataPoint = {
      id: 'code',
      canDisplay: canDisplayDataPoint(voucher.code, vouchers.code),
      label: t('Customer Widget.Tabs.Vouchers.Code'),
      value: voucher.code || unknownDataPoint,
    }
    const orderId: VoucherDataPoint = {
      id: 'order_id',
      canDisplay: canDisplayDataPoint(voucher.order_id, vouchers.order_id),
      label: t('Customer Widget.Tabs.Vouchers.Voucher Details.Order ID'),
      value: voucher.order_id || unknownDataPoint,
    }

    const isPercentage = voucher.type === 'percentage'
    const isDeliveryFee = voucher.type === 'delivery_fee' || voucher.value === '0' // From AC, value 0 denotes Delivery free voucher
    const isCashback =
      voucher.type === VoucherTypes.cashback_amount ||
      voucher.type === VoucherTypes.cashback_percentage

    const isValue = !(isPercentage || isDeliveryFee || isCashback)
    const value: VoucherValueDataPoint = {
      id: 'value',
      canDisplay: canDisplayDataPoint(voucher.value, vouchers.value),
      label: t('Customer Widget.Tabs.Vouchers.Value'),
      isCashback,
      isValue,
      value: isPercentage
        ? `${voucher.value}%`
        : !isDeliveryFee
        ? `${fixNumber(voucher.value)}`
        : t('Customer Widget.Tabs.Vouchers.Voucher Details.Delivery fee'),
    }

    const type: VoucherDataPoint = {
      id: 'type',
      canDisplay: canDisplayDataPoint(voucher.type, vouchers.type),
      label: t('Customer Widget.Tabs.Vouchers.Type'),
      value: voucher.type || unknownDataPoint,
    }

    const description: VoucherDataPoint = {
      id: 'description',
      canDisplay: canDisplayDataPoint(voucher.description, vouchers.description),
      label: t('Customer Widget.Tabs.Vouchers.Voucher Details.Description'),
      value:
        voucher.description ||
        t('Customer Widget.Tabs.Vouchers.Voucher Details.No Voucher Description Defined'),
    }
    const customerId: VoucherDataPoint = {
      id: 'customer_id',
      canDisplay: canDisplayDataPoint(voucher.customer_code, vouchers.customer_id),
      label: t('Customer Widget.Customer ID'),
      value: voucher.customer_code || unknownDataPoint,
    }

    const purpose: VoucherDataPoint = {
      id: 'purpose',
      canDisplay: canDisplayDataPoint(voucher.purpose, vouchers.purpose),
      label: t('Customer Widget.Tabs.Vouchers.Voucher Details.Purpose'),
      value: voucher.purpose || unknownDataPoint,
    }

    const used: VoucherDataPoint = {
      id: 'used',
      canDisplay: canDisplayDataPoint(voucher.used, vouchers.used),
      label: t('Customer Widget.Tabs.Vouchers.Voucher Details.Used'),
      value: voucher.used ?? unknownDataPoint,
    }

    const maxDiscountAmount: VoucherDataPoint = {
      id: 'max_discount_amount',
      canDisplay: canDisplayDataPoint(
        voucher.maximum_discount_amount,
        vouchers.max_discount_amount,
      ),
      label: t('Customer Widget.Tabs.Vouchers.Voucher Details.Max Discount Amount'),
      value: voucher.maximum_discount_amount
        ? voucher.maximum_discount_amount
        : t('Customer Widget.Tabs.Vouchers.Voucher Details.No Max Discount Amount Defined'),
    }

    const sponsorRatio: VoucherDataPoint = {
      id: 'sponsor_ratio',
      canDisplay: canDisplayDataPoint(voucher.localentity_sponsor_ratio, vouchers.sponsor_ratio),
      label: t('Customer Widget.Tabs.Vouchers.Voucher Details.Platform Voucher Sponsoring'),
      value: voucher.localentity_sponsor_ratio
        ? `${voucher.localentity_sponsor_ratio}%`
        : unknownDataPoint,
    }

    const unlimitedUsage: VoucherDataPoint = {
      id: 'unlimited_usage',
      canDisplay: canDisplayDataPoint(voucher.unlimited_usage, vouchers.unlimited_usage),
      label: t('Customer Widget.Tabs.Vouchers.Voucher Details.Unlimited Usage'),
      value: voucher.unlimited_usage ? t('Widgets Common.True') : t('Widgets Common.False'),
    }

    const paidBy: VoucherDataPoint = {
      id: 'paid_by',
      canDisplay: canDisplayDataPoint(voucher.paid_by, vouchers.paid_by),
      label: t('Customer Widget.Tabs.Vouchers.Voucher Details.Paid By'),
      value: voucher.paid_by || unknownDataPoint,
    }

    const startDate = voucher.constraints.find((c) => c.type === 'start_date')
    const expDate = voucher.constraints.find((c) => c.type === 'expiration_date')
    const dayDiff = dayDifferenceFromNow(expDate.value, utc_zone)
    const startDateValue = startDate
      ? modifyUTC(startDate.value, utc_zone, 'DD.MM.YYYY').split(', ')[0]
      : ''
    const expDateValue = expDate
      ? modifyUTC(expDate.value, utc_zone, 'DD.MM.YYYY').split(', ')[0]
      : ''
    const validDates: VoucherDateDataPoint = {
      id: 'valid_dates',
      canDisplay:
        canDisplayDataPoint(startDate, vouchers.valid_dates) &&
        canDisplayDataPoint(expDate, vouchers.valid_dates),
      label: t('Customer Widget.Tabs.Vouchers.Voucher Details.Valid Dates'),
      value: `${startDateValue} - ${expDateValue}`,
      start_date: startDateValue,
      exp_date: expDateValue,
      dayDiff,
    }

    const opening_hour: { type: string; value: Schedule[] } = voucher.constraints.find(
      (c) => c.type === 'opening_hour',
    )
    const closing_hour: { type: string; value: Schedule[] } = voucher.constraints.find(
      (c) => c.type === 'closing_hour',
    )
    const schedules: { type: string; value: Schedule[] } = voucher.constraints.find(
      (c) => c.type === 'schedules',
    )

    const days = [
      'Widgets Common.Weekdays.Sunday',
      'Widgets Common.Weekdays.Monday',
      'Widgets Common.Weekdays.Tuesday',
      'Widgets Common.Weekdays.Wednesday',
      'Widgets Common.Weekdays.Thursday',
      'Widgets Common.Weekdays.Friday',
      'Widgets Common.Weekdays.Saturday',
    ]

    const schedule: VoucherDataPoint = {
      id: 'schedules',
      canDisplay: canDisplayDataPoint(schedules, vouchers.schedules),
      label: t('Customer Widget.Tabs.Vouchers.Voucher Details.Schedule'),
      value: schedules?.value?.length
        ? t('Customer Widget.Tabs.Vouchers.Voucher Details.View Schedule')
        : opening_hour && closing_hour
        ? t('Customer Widget.Tabs.Vouchers.Voucher Details.Valid Everyday') +
          `: ${opening_hour.value} - ${closing_hour.value}`
        : unknownDataPoint,
      children: schedules?.value?.map(
        (s) => `${t(days[s.weekday])}: ${s.opening_time} - ${s.closing_time}`,
      ),
    }

    const allowedConstraints = [
      'start_date',
      'expiration_date',
      'opening_hour',
      'closing_hour',
      'schedules',
      'minimum_order_value',
      'maximum_order_value',
      'is_new_customer_only',
      'vertical_types',
      'platforms',
      'expedition_types',
      'vendors',
      'vendor_chains',
      'payment_types',
      'customer_quantity',
      'quantity',
      'countries',
      'cities',
      'cuisine',
      'own_delivery_only',
      'times_per_user',
    ]

    const constraints = allowedConstraints.map((key: VoucherConstraints) => {
      let constraint = voucher.constraints.find((constraint) => constraint.type === key)
      if (!constraint) {
        constraint = { type: key, value: null }
      }
      switch (key) {
        case 'minimum_order_value':
          const isValue = Boolean(constraint.value)
          return {
            id: 'min_order_value',
            canDisplay: canDisplayDataPoint(constraint.value, vouchers.min_order_value),
            label: t('Customer Widget.Tabs.Vouchers.Voucher Details.Min Order Value'),
            value: constraint.value ? fixNumber(constraint.value) : '-',
            isValue,
          } as VoucherValueDataPoint
        case 'maximum_order_value':
          return {
            id: 'max_order_value',
            canDisplay: canDisplayDataPoint(constraint.value, vouchers.max_order_value),
            label: t('Customer Widget.Tabs.Vouchers.Voucher Details.Max Order Value'),
            value: constraint.value
              ? fixNumber(constraint.value)
              : t(
                  'Customer Widget.Tabs.Vouchers.Voucher Details.No Max Order Value for Voucher Defined',
                ),
          } as VoucherDataPoint
        case 'cities':
          return {
            id: 'cities',
            canDisplay: canDisplayDataPoint(constraint.value, vouchers.cities),
            label: t('Customer Widget.Tabs.Vouchers.Voucher Details.Cities'),
            value: constraint.value?.length
              ? constraint.value.join(', ')
              : t('Customer Widget.Tabs.Vouchers.Voucher Details.Applicable to All Cities'),
          } as VoucherDataPoint
        case 'countries':
          return {
            id: 'countries',
            canDisplay: canDisplayDataPoint(constraint.value, vouchers.countries),
            label: t('Customer Widget.Tabs.Vouchers.Voucher Details.Countries'),
            value: constraint.value?.length
              ? constraint.value.join(', ')
              : t('Customer Widget.Tabs.Vouchers.Voucher Details.Applicable to All Countries'),
          } as VoucherDataPoint
        case 'vendors':
          return {
            id: 'vendors',
            canDisplay: canDisplayDataPoint(constraint.value, vouchers.vendors),
            label: t('Customer Widget.Tabs.Vouchers.Voucher Details.Relevant Vendors'),
            value: constraint.value?.length
              ? t('Customer Widget.Tabs.Vouchers.Voucher Details.Number of Applicable Vendors') +
                ': ' +
                constraint.value.length
              : t('Customer Widget.Tabs.Vouchers.Voucher Details.Applicable to All Vendors'),
            children: constraint.value?.map((vendor) => `${vendor.name} (${vendor.code})`),
          } as VoucherDataPoint
        case 'vendor_chains':
          return {
            id: 'vendor_chains',
            canDisplay: canDisplayDataPoint(constraint.value, vouchers.vendor_chains),
            label: t('Customer Widget.Tabs.Vouchers.Voucher Details.Vendor Chains'),
            value: constraint.value?.length
              ? constraint.value.join(', ')
              : t('Customer Widget.Tabs.Vouchers.Voucher Details.Applicable to All Vendor Chains'),
          } as VoucherDataPoint
        case 'payment_types':
          return {
            id: 'payment_types',
            canDisplay: canDisplayDataPoint(constraint.value, vouchers.payment_types),
            label: t('Customer Widget.Tabs.Vouchers.Voucher Details.Payment Types'),
            value: constraint.value?.length
              ? constraint.value.map((pt) => pt.name).join(', ')
              : t('Customer Widget.Tabs.Vouchers.Voucher Details.All Payment Types'),
          } as VoucherDataPoint
        case 'vertical_types':
          return {
            id: 'vertical_types',
            canDisplay: canDisplayDataPoint(constraint.value, vouchers.vertical_types),
            label: t('Customer Widget.Tabs.Vouchers.Voucher Details.Vertical Types'),
            value: constraint.value?.length
              ? constraint.value.join(', ')
              : t('Customer Widget.Tabs.Vouchers.Voucher Details.All Vertical Types'),
          }
        case 'quantity':
          return {
            id: 'quantity',
            canDisplay: canDisplayDataPoint(constraint.value, vouchers.quantity),
            label: t('Customer Widget.Tabs.Vouchers.Voucher Details.Quantity'),
            value: constraint.value || 1,
          } as VoucherDataPoint
        case 'customer_quantity':
          return {
            id: 'customer_quantity',
            canDisplay: canDisplayDataPoint(constraint.value, vouchers.customer_quantity),
            label: t('Customer Widget.Tabs.Vouchers.Voucher Details.Customer Quantity'),
            value: constraint.value || 1,
          } as VoucherDataPoint
        case 'is_new_customer_only':
          return {
            id: 'is_new_customer_only',
            canDisplay: canDisplayDataPoint(constraint.value, vouchers.is_new_customer_only),
            label: t('Customer Widget.Tabs.Vouchers.Voucher Details.New Customer Only'),
            value: constraint.value ? t('Widgets Common.True') : t('Widgets Common.False'),
          } as VoucherDataPoint
        case 'own_delivery_only':
          return {
            id: 'own_delivery_only',
            canDisplay: canDisplayDataPoint(constraint.value, vouchers.own_delivery_only),
            label: t('Customer Widget.Tabs.Vouchers.Voucher Details.Own Delivery Only'),
            value: constraint.value ? t('Widgets Common.True') : t('Widgets Common.False'),
          } as VoucherDataPoint
        case 'times_per_user':
          return {
            id: 'times_per_user',
            canDisplay: canDisplayDataPoint(constraint.value, vouchers.times_per_user),
            label: t('Customer Widget.Tabs.Vouchers.Voucher Details.Times Per User'),
            value: String(constraint.value || 0),
          } as VoucherDataPoint
        case 'expedition_types':
          return {
            id: 'expedition_types',
            canDisplay: canDisplayDataPoint(constraint.value, vouchers.expedition_types),
            label: t('Customer Widget.Tabs.Vouchers.Voucher Details.Expedition Types'),
            value: constraint.value?.length
              ? constraint.value.join(', ')
              : t('Customer Widget.Tabs.Vouchers.Voucher Details.Delivery, Pickup'),
          } as VoucherDataPoint
        case 'platforms':
          return {
            id: 'platforms',
            canDisplay: canDisplayDataPoint(constraint.value, vouchers.platforms),
            label: t('Customer Widget.Tabs.Vouchers.Voucher Details.Platforms'),
            value: constraint.value?.length
              ? constraint.value.join(', ')
              : t('Customer Widget.Tabs.Vouchers.Voucher Details.All Platform Types'),
          } as VoucherDataPoint
        case 'cuisine':
          return {
            id: 'cuisine',
            canDisplay: canDisplayDataPoint(constraint.value, vouchers.cuisine),
            label: t('Customer Widget.Tabs.Vouchers.Voucher Details.Cuisine'),
            value: constraint.value || unknownDataPoint,
          } as VoucherDataPoint
        default:
          return null
      }
    })

    return [
      code,
      description,
      validDates,
      schedule,
      type,
      value,
      orderId,
      customerId,
      ...constraints.filter(Boolean),
      maxDiscountAmount,
      used,
      paidBy,
      purpose,
      sponsorRatio,
      unlimitedUsage,
    ] as VoucherDataPoint[]
  }
}

export default useTransformCustomerVoucher
