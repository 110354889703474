// libs
import React, { useContext } from 'react'
// contexts and types
import { RiderServiceContext } from 'contexts/riderService/RiderServiceContext'
// hooks
import { useTranslation } from 'hooks/useTranslation'

// styles
import { createUseStyles } from 'react-jss'
import styles from './Customer.styles'
import { Typography } from 'antd'
// assets
import orderErrorIcon from 'assets/error/orderError.png'
// components
import MiniErrorView from 'components/MiniErrorView'
import Content from './Content'

const useStyles = createUseStyles(styles)

const Customer = () => {
  const classes = useStyles()
  const { Text } = Typography

  // pull translations
  const { t } = useTranslation()

  // pull data state and dispatch from data context
  const {
    riderServiceState: { customer, customerError },
  } = useContext(RiderServiceContext)

  return (
    <div className={classes.customerContainer}>
      {customer && !customerError && (
        <React.Fragment>
          <Text className={classes.sectionTitle}>{t('Customer Widget.Customer')}</Text>
          <Content />
        </React.Fragment>
      )}

      {!customer && customerError && (
        <MiniErrorView icon={orderErrorIcon} errorMessage={t('Messages.No Customer Found')} />
      )}
    </div>
  )
}

export default Customer
