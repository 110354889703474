/**
 * checks the passed displayRule and return a boolean indicating whether data point should be rendered
 * */

import { allowedDataPointValues } from 'entityConfig/allowedConfigValues'

function checkDataPointAvailability(displayRule: string[], isDataReceived: boolean): boolean {
  // no show
  if (displayRule?.includes(allowedDataPointValues.never_display)) {
    return false
  }

  // show when data found
  if (displayRule?.includes(allowedDataPointValues.display_when_data_found)) {
    return isDataReceived
  }

  // show in any way
  return true
}

export default checkDataPointAvailability
