import React, { useState } from 'react'
import { Popover, Space } from 'antd'
import { createUseStyles } from 'react-jss'
import { Button, CopyButton, PhoneDialButton } from 'shared'
import { useSdk } from 'contexts/SdkProvider'
import { VendorApiResponse, VendorContact, VendorContactTypes } from 'types/api/vendorApi/vendor'

type VendorPhoneNumbersProps = {
  vendor: VendorApiResponse
}

type CopyablePhoneNumberProps = VendorContact

const useStyles = createUseStyles({
  phoneNumber: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 8,
  },
  popoverTriggerButton: {
    width: '100%',
    height: '22px',
    textAlign: 'start',
  },
  popover: {
    '& .ant-popover-inner': {
      borderRadius: 4,
    },
  },
})

const CopyablePhoneNumber = ({ phone, type }: CopyablePhoneNumberProps) => {
  const { captureUserAction, caseId, phoneDecorator } = useSdk()
  const classes = useStyles()

  const onDial = () => {
    captureUserAction('DIAL_VENDOR_PHONE_NUMBER', { reportToEts: true, eventDetails: { type } })
  }
  const onCopy = () => {
    captureUserAction('COPY_VENDOR_PHONE_NUMBER', { reportToEts: true, eventDetails: { type } })
  }

  return (
    <div className={classes.phoneNumber}>
      <span>{phone}</span>
      <PhoneDialButton
        caseId={caseId}
        decorator={phoneDecorator}
        phoneNumber={phone}
        onClick={onDial}
      />
      <CopyButton text={phone} onCopy={onCopy} />
    </div>
  )
}

export const VendorPhoneNumbers = ({ vendor }: VendorPhoneNumbersProps) => {
  const [isVisible, setIsVisible] = useState<boolean>(false)

  const classes = useStyles()
  const { t, captureUserAction } = useSdk()

  const primaryPhone =
    vendor.contact.phone ??
    vendor.transmission.contacts?.find((phone) => phone?.type === VendorContactTypes.Primary)
      .phone ??
    vendor.transmission.contact ??
    null

  const secondaryPhones =
    vendor.transmission.contacts?.filter((phone) => phone?.type === VendorContactTypes.Secondary) ??
    []

  const showMorePhones = secondaryPhones.length > 0

  const onViewMore = () => {
    setIsVisible(true)
    captureUserAction('VIEW_MORE_VENDOR_PHONE_NUMBERS', { reportToEts: true })
  }

  return (
    <Space size={0} direction='vertical'>
      <CopyablePhoneNumber phone={primaryPhone} type={VendorContactTypes.Primary} />
      {showMorePhones && (
        <div onMouseLeave={() => setIsVisible(false)}>
          <Popover
            showArrow
            visible={isVisible}
            trigger={'click'}
            placement='bottomLeft'
            align={{ targetOffset: [16, 10] }}
            overlayClassName={classes.popover}
            content={
              <Space size={12} direction='vertical'>
                {secondaryPhones.map((phoneNumber, index) => (
                  <CopyablePhoneNumber key={index} {...phoneNumber} />
                ))}
              </Space>
            }
          >
            <Button type='link' className={classes.popoverTriggerButton} onClick={onViewMore}>
              {t('View {{numberOfSecondaryPhones}} more', {
                replace: { numberOfSecondaryPhones: secondaryPhones.length },
              })}
            </Button>
          </Popover>
        </div>
      )}
    </Space>
  )
}
