import React from 'react'
import { createCustomSvgIcon } from 'factory/createCustomSvgIcon'

export const MapPinIcon = createCustomSvgIcon({
  viewBoxWidth: 14,
  viewBoxHeight: 14,
  content: (
    <>
      <path
        d='M6.96403 6.92797C8.55498 6.92797 9.84469 5.63826 9.84469 4.04731C9.84469 2.45637 8.55498 1.16666 6.96403 1.16666C5.37309 1.16666 4.08337 2.45637 4.08337 4.04731C4.08337 5.63826 5.37309 6.92797 6.96403 6.92797ZM6.24387 8.3683C6.24387 7.97057 6.5663 7.64814 6.96403 7.64814C7.36177 7.64814 7.6842 7.97057 7.6842 8.3683V12.1132C7.6842 12.5109 7.36177 12.8333 6.96403 12.8333C6.5663 12.8333 6.24387 12.5109 6.24387 12.1132V8.3683Z'
        fill='currentColor'
      />
    </>
  ),
})
