import React, { useState } from 'react'
import { Space, Typography } from 'antd'
import { useTranslation } from 'react-i18next'
import { createUseStyles } from 'react-jss'

import {
  ReinstallDataDescription,
  RemoteControlDescription,
  ResetDescription,
  WipeDataDescription,
} from './ActionDescription'
import { Button, PopupWidgetContainer } from 'shared'
import { DeviceActionName } from '../DeviceActions/DeviceActions'

const { Text } = Typography

const useStyles = createUseStyles({
  descriptionContainer: {
    marginBottom: '24px',
  },
  actionsContainer: {
    width: '100%',
    justifyContent: 'center',
  },
  actionsFeedback: {
    width: '100%',
    marginTop: '8px',
    display: 'flex',
    justifyContent: 'center',
  },
})

export interface ActionConfirmationPopupProps {
  action: DeviceActionName
  onConfirm?: () => Promise<unknown>
  onClose?: () => void
}

const actionTitleMap = {
  RESTART: 'widgets.vendor_devices.restart.heading',
  RESET: 'widgets.vendor_devices.reset.heading',
  INSTALL: 'widgets.vendor_devices.install.heading',
  REMOTE_CONTROL: 'widgets.vendor_devices.remote_control.heading',
}

const actionDescriptionMap = {
  RESTART: ResetDescription,
  RESET: WipeDataDescription,
  INSTALL: ReinstallDataDescription,
  REMOTE_CONTROL: RemoteControlDescription,
}

export const ActionConfirmationPopup: React.FC<ActionConfirmationPopupProps> = ({
  action,
  onConfirm,
  onClose,
}) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const [actionStatus, setActionStatus] = useState<'idle' | 'loading' | 'error'>('idle')
  const [errorMessage, setErrorMessage] = useState(null)

  const title = t(actionTitleMap[action])
  const Description = actionDescriptionMap[action]

  const handleConfirm = () => {
    setActionStatus('loading')

    onConfirm()
      .then(() => {
        setActionStatus('idle')
      })
      .catch(() => {
        setActionStatus('error')
        setErrorMessage(t('Messages.Something went wrong'))
      })
  }

  return (
    <PopupWidgetContainer visible widgetContainerType='drawer' title={title} onClose={onClose}>
      <section className={classes.descriptionContainer}>
        <Description />
      </section>

      <Space className={classes.actionsContainer}>
        <Button type='default' block={true} onClick={onClose}>
          {t('Interface.Cancel')}
        </Button>
        <Button
          type='solid'
          block={true}
          loading={actionStatus === 'loading'}
          onClick={handleConfirm}
        >
          {t('Widgets Common.Confirm')}
        </Button>
      </Space>
      {actionStatus === 'error' ? (
        <div className={classes.actionsFeedback}>
          <Text type='danger'>{errorMessage}</Text>
        </div>
      ) : null}
    </PopupWidgetContainer>
  )
}
