const formats = new Map([
  ['{{', '}}'],
  ['{', '}'],
])

/**
 * runs replace operation on the given string
 */
export const interpolate = (
  str: string,
  values: Record<string, string>,
  opts?: {
    format?: '{{' | '{'
    /**
     * defines the default value used if no value is provided. default behaviour is to leave the pattern
     * un modified
     */
    defaultValue?: null | string | number
  },
) => {
  const { format = '{{', defaultValue } = opts || {}
  const start = format
  const end = formats.get(start)

  const regex = new RegExp(`${start}([\\w+]+)${end}`, 'ig')
  return str.replace(regex, (match, name) => {
    if (typeof values[name] !== 'undefined') {
      return values[name]
    }

    if (defaultValue === null || defaultValue === undefined) {
      return match
    }

    return defaultValue.toString()
  })
}
