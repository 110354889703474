import React from 'react'
import { Row, Col, Typography } from 'antd'
import { useTranslation } from 'react-i18next'
import { createUseStyles } from 'react-jss'
import { BuiltinWidgetConfigs } from 'types'
import { useCheckDisplayRules } from 'hooks/useCheckDisplayRules'
import { DeviceAction } from './DeviceAction'

const { Text } = Typography

export type DeviceActionName = 'RESTART' | 'RESET' | 'INSTALL' | 'REMOTE_CONTROL'

export const ActionLabelMap: Record<DeviceActionName, string> = {
  RESET: 'widgets.vendor_devices.reset.name',
  RESTART: 'widgets.vendor_devices.restart.name',
  INSTALL: 'widgets.vendor_devices.install.name',
  REMOTE_CONTROL: 'widgets.vendor_devices.remote_control.name',
}

export const useStyles = createUseStyles({
  actionsTitle: {
    marginBottom: '16px',
  },
})

export interface DeviceActionsProps {
  config: BuiltinWidgetConfigs['vendor_devices']
  onPerformAction: ({ action }: { action: DeviceActionName }) => void
}

export const DeviceActions: React.FC<DeviceActionsProps> = ({ config, onPerformAction }) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const checkDisplayRules = useCheckDisplayRules()

  return (
    <section>
      <div className={classes.actionsTitle}>
        <Text strong>{t('widgets.vendor_devices.device_actions')}</Text>
      </div>

      <Row justify={'center'} gutter={8}>
        {checkDisplayRules(config.actions.restart).visible && (
          <Col>
            <DeviceAction
              title={t(ActionLabelMap.RESET)}
              description={t('widgets.vendor_devices.reset.caption')}
              icon={'DeleteOutlined'}
              onClick={() => onPerformAction({ action: 'RESET' })}
            />
          </Col>
        )}

        {checkDisplayRules(config.actions.install).visible && (
          <Col>
            <DeviceAction
              title={t(ActionLabelMap.INSTALL)}
              description={t('widgets.vendor_devices.install.caption')}
              icon={'AppVersionIcon'}
              onClick={() => onPerformAction({ action: 'INSTALL' })}
            />
          </Col>
        )}

        {checkDisplayRules(config.actions.remote_control).visible && (
          <Col>
            <DeviceAction
              title={t(ActionLabelMap.REMOTE_CONTROL)}
              description={t('widgets.vendor_devices.remote_control.caption')}
              icon={'NetworkServerIcon'}
              onClick={() => onPerformAction({ action: 'REMOTE_CONTROL' })}
            />
          </Col>
        )}

        {checkDisplayRules(config.actions.reset).visible && (
          <Col>
            <DeviceAction
              title={t(ActionLabelMap.RESTART)}
              description={t('widgets.vendor_devices.restart.caption')}
              icon={'SyncOutlined'}
              onClick={() => onPerformAction({ action: 'RESTART' })}
            />
          </Col>
        )}
      </Row>
    </section>
  )
}
