import React from 'react'
import truncate from 'lodash/truncate'
import { Tag, TagProps, Tooltip } from 'antd'
import { createUseStyles } from 'react-jss'
import classNames from 'classnames'

type Props = TagProps & {
  value: string
  tooltip?: string
  showTooltip?: boolean
}

const useStyles = createUseStyles({
  tag: {
    margin: '2px 0px 0px 0px',
    borderRadius: '50px',
    padding: '0px 10px',
    textTransform: 'capitalize',
  },
})

const SingleTag = ({ value, tooltip, className, showTooltip = true, ...tagProps }: Props) => {
  const classes = useStyles()

  const valueIsLong = value?.length > 50
  const displayedValue = valueIsLong ? truncate(value, { length: 50 }) : value
  const displayedTooltip = (!valueIsLong && tooltip) ?? value

  return (
    <Tooltip title={showTooltip && displayedTooltip}>
      <Tag className={classNames(classes.tag, className)} {...tagProps}>
        {displayedValue}
      </Tag>
    </Tooltip>
  )
}

export default SingleTag
