import { gray } from 'theme'

export default {
  antCollapse: {
    '& .ant-collapse-header': {
      backgroundColor: '#ffffff !important',
      borderBottom: `1px solid ${gray.gray4}`,
      borderRadius: '4px !important',
    },
  },
  listItem: {
    '&.ant-list-item': {
      padding: '8px 8px 8px 32px',
    },
  },
  arrow: {
    marginLeft: '5px',
  },
}
