import { ApiService } from 'services/authApi/getPermissions'
import { OrderTransactionApiResponse } from 'types/api/orderApi/order'

export const getOrderTransactions: ApiService<
  {
    entityId: string
    orderId: string
  },
  OrderTransactionApiResponse
> = (createClient, options) => {
  const { entityId, orderId, clientParams } = options

  const uri = `/PaymentAPI/v1/order/${entityId}/${orderId}/transactions`

  return createClient({
    endpointName: 'getOrderTransactions',
    ...clientParams,
  }).get(uri)
}
