/**
 * Wallet Tab
 * renders customer wallet info as a list
 * */

// libs
import React, { useCallback, useContext, useMemo, useState, useEffect } from 'react'
// contexts and types
import { DataContext } from 'contexts/data/DataContext'
import { DataAction } from 'contexts/data/types'
// hooks
import { useTranslation } from 'hooks/useTranslation'
// utils
// components
// import WalletView from './WalletView'
import { useApiService } from 'hooks/useApiService'
import { getCustomerWallet } from 'services/paymentApi/getCustomerWallet'
import { WidgetErrorHandler } from 'components/WidgetErrorHandler/widgetErrorHandler'
import { createUseStyles } from 'react-jss'
import styles from './Wallet.style'
import classNames from 'classnames'
import { Input, Typography, Collapse, Pagination, Empty } from 'antd'
import { UpOutlined } from '@ant-design/icons'
import { useNumberFormatter } from 'hooks/formatters/useNumberFormatter'
import { getCustomerWalletTransactions } from 'services/paymentApi/getCustomerTransactions'
import { PanelHeader } from './PanelHeader/PanelHeader'
import { useCaptureUserAction } from 'hooks/events/useCaptureUserAction'
import { PanelDetails } from './PanelDetails/PanelDetails'
import {
  TransformedCustomerWalletTransaction,
  useTransformCustomerWalletTransactions,
} from 'hooks/dataTransformers/useTransformCustomerWalletTransactions'
import { createPluggableWidget } from 'factory/createPluggableWidget'

const useStyles = createUseStyles(styles)
const { Search } = Input
const { Text } = Typography

const { Panel } = Collapse

const pageSizeOptions = [5, 8, 10, 20, 50, 100]

export const Wallet = createPluggableWidget<{}>(
  ({ customerId, globalEntityId, sdk }) => {
    // pull data state and dispatch from data context
    const { dataDispatch } = useContext(DataContext)
    const { SET_WALLET } = DataAction

    const [page, setPage] = useState(1)
    const [pageSize, setPageSize] = useState(8)

    const [searchTerm, setSearchTerm] = useState('')

    // pull language content
    const { t } = useTranslation()

    const hasCustomer = Boolean(customerId)

    const { data: customerWallet, loadService: reloadCustomerWallet } = useApiService({
      service: getCustomerWallet,
      params: {
        customerId,
        entityId: globalEntityId,
      },
      shouldLoad: hasCustomer,
      deps: [customerId, globalEntityId],
      onSuccess(res) {
        dataDispatch({
          type: SET_WALLET,
          payload: { wallet: res.data },
        })
      },
    })

    const {
      data: transactionData,
      loading: isLoadingTransactions,
      error: loadTransactionsError,
      loadService: reloadTransactions,
    } = useApiService({
      service: getCustomerWalletTransactions,
      params: {
        customerId,
        entityId: globalEntityId,
      },
      deps: [customerId, globalEntityId],
      shouldLoad: hasCustomer,
    })

    useEffect(() => {
      const callback = () => {
        reloadCustomerWallet()
        reloadTransactions()
      }
      const fullRefundListener = sdk.eventEmitter.addEventListener({
        name: 'FULL_REFUND_SUCCESS',
        callback,
      })

      const partialRefundListener = sdk.eventEmitter.addEventListener({
        name: 'PARTIAL_REFUND_SUCCESS',
        callback,
      })

      return () => {
        sdk.eventEmitter.removeEventListener(fullRefundListener)
        sdk.eventEmitter.removeEventListener(partialRefundListener)
      }
    }, [reloadCustomerWallet, reloadTransactions, sdk.eventEmitter])

    const cx = useStyles()
    const numberFormatter = useNumberFormatter()
    const captureUserAction = useCaptureUserAction()

    const transformCustomerWalletTransactions = useTransformCustomerWalletTransactions()

    const transactions = useMemo(
      () => transformCustomerWalletTransactions(transactionData?.data),
      [transactionData, transformCustomerWalletTransactions],
    )

    const filteredTransactions = useMemo(() => {
      if (!searchTerm) {
        return transactions
      }

      const lowercasedTerm = searchTerm.toLowerCase()
      const searchFields: Array<keyof TransformedCustomerWalletTransaction> = [
        'platformReferenceId',
        'transactionDate',
        'transactionTime',
        'title',
        'amountFormated',
        'vendor',
        'description',
      ]

      return transactions.filter((transaction) => {
        return searchFields.some((field) => {
          return transaction[field]
            ? transaction[field].toString().toLowerCase().includes(lowercasedTerm)
            : false
        })
      })
    }, [transactions, searchTerm])

    const pageTransactions = useMemo(() => {
      const startIndex = (page - 1) * pageSize
      return filteredTransactions.slice(startIndex, startIndex + pageSize)
    }, [filteredTransactions, page, pageSize])

    const onCollapseChange = useCallback(() => {
      captureUserAction('CustomerWalletCollapseArrowClicked')
    }, [captureUserAction])

    const onChange = useCallback((page, pageSize) => {
      setPage(page)
      setPageSize(pageSize)
    }, [])

    const onSearch = useCallback(
      (term: string) => {
        setSearchTerm(term)
        setPage(1)
        if (term) {
          captureUserAction('CustomerWalletSearchTriggered', {
            eventDetails: {
              searchTerm: term,
            },
          })
        }
      },
      [captureUserAction],
    )

    return (
      <WidgetErrorHandler
        loading={isLoadingTransactions}
        errorPayload={loadTransactionsError?.errorPayload}
        loadingText={t('Interface.Loading Customer Wallet Transactions')}
        onRetry={reloadTransactions}
        missingParameters={[!hasCustomer && 'customerId']}
      >
        {() => (
          <div>
            <div className={classNames(cx.flex, cx.mb16)}>
              {customerWallet?.aggregatedBalances && (
                <div>
                  <Text type='secondary'>{t('Customer Widget.Tabs.Wallet.Current balance')}</Text>
                  <Text className={classNames(cx.block)}>
                    {numberFormatter.formatMoney(customerWallet.aggregatedBalances.primary)}
                  </Text>
                </div>
              )}

              <div className={cx.mlAuto}>
                <Search
                  placeholder={t('Interface.Search')}
                  onSearch={onSearch}
                  allowClear
                  className={cx.searchField}
                />
              </div>
            </div>

            {/* render items */}
            {pageTransactions.length === 0 ? (
              <Empty
                description={
                  searchTerm ? (
                    <>
                      <Text type='secondary'>
                        {t('Interface.No transactions found matching search criteria')}
                      </Text>
                      <br />
                      <Text type='secondary'>{t('Interface.Check input and try again')}</Text>
                    </>
                  ) : (
                    <Text type='secondary'>{t('Interface.No transactions found')}</Text>
                  )
                }
              />
            ) : (
              <Collapse
                expandIconPosition='end'
                expandIcon={({ isActive }) => (
                  <UpOutlined className={cx.toggleIcon} rotate={isActive ? 0 : 180} />
                )}
                className={cx.resetAntCollapse}
                bordered={false}
                onChange={onCollapseChange}
              >
                {pageTransactions.map((transaction) => {
                  return (
                    <Panel
                      collapsible={transaction.showDetails ? undefined : 'disabled'}
                      showArrow={transaction.showDetails}
                      header={<PanelHeader transaction={transaction} />}
                      key={transaction.id}
                      className={cx.panel}
                    >
                      {transaction.showDetails ? <PanelDetails transaction={transaction} /> : null}
                    </Panel>
                  )
                })}
              </Collapse>
            )}

            {/* pagination */}
            {filteredTransactions.length > 0 ? (
              <div className={classNames(cx.flex, cx.mt18, cx.justifyEnd)}>
                <Pagination
                  pageSize={pageSize}
                  total={filteredTransactions.length}
                  current={page}
                  onChange={onChange}
                  pageSizeOptions={pageSizeOptions}
                />
              </div>
            ) : null}
          </div>
        )}
      </WidgetErrorHandler>
    )
  },
  {
    category: 'data_lookup',
    deriveConfig() {
      return {}
    },
    deriveSubjectsRequirements() {
      return {
        all_of: ['customerId'],
      }
    },
  },
)

export default Wallet
