import React, { useMemo } from 'react'
import { CustomerChatMessageData } from 'types/api/ticketApi/getCustomerChatHistory'
import styles from './ChatCard.styles'
import { createUseStyles } from 'react-jss'
import moment from 'moment'
import { Image } from 'antd'
import { parseChatMessageString } from 'utils/parseChatMessageString'

const useStyles = createUseStyles(styles)
interface ChatCardParams {
  message: CustomerChatMessageData
  replyStart: boolean
}

export const ChatCard = ({ message, replyStart }: ChatCardParams) => {
  const classes = useStyles({
    align: 'right',
    replyStart,
  })

  const messageTime = moment(message.created_at).format('LT')

  const chatMessageElements = useMemo(
    () => parseChatMessageString(message.content),
    [message.content],
  )

  return (
    <div className={classes.cardContainer}>
      {replyStart && (
        <div className={classes.chatCardInfo}>
          <span>{message.sender}</span>
        </div>
      )}
      <div className={classes.chatCardBubble}>
        <div className={classes.chatCardMessage}>
          <span>
            {chatMessageElements?.map(({ type, content }) => {
              if (type === 'link') {
                return (
                  <a href={content} target='_blank' rel='noreferrer'>
                    {content}
                  </a>
                )
              }
              if (type === 'image') {
                return <Image src={content} alt={content} />
              }
              return content
            })}
          </span>
        </div>
        <div className={classes.chatCardTime}>
          <span>{messageTime}</span>
        </div>
      </div>
    </div>
  )
}
