import React from 'react'
import { SearchType, SearchPanelHandler } from 'hooks/search/useSearchPanel'
import { replaceUrl } from 'utils/getSearchParams'
import { SearchPanel, SearchPanelProps } from './SearchPanel'
import { builtinWidgetNames } from 'types'
import { ViewScreenAndSdkProvider } from 'contexts/ViewScreenAndSdkProvider'

const searchOptions: SearchPanelProps['searchByOptions'] = new Map([
  [SearchType.OrderId, 'widgets.search.search_options.order_id'],
  [SearchType.CustomerEmail, 'widgets.search.search_options.customer_email'],
  [SearchType.CustomerPhoneNumber, 'widgets.search.search_options.customer_phone_number'],
])

export const SearchOrderPanel = (
  props: Omit<SearchPanelProps, 'defaultSearchType' | 'searchByOptions'>,
) => {
  const onSearch: SearchPanelHandler = (opts) => {
    const { selectedSearchType, selectedEntityId, searchValue, defaultHandler } = opts
    switch (selectedSearchType) {
      case SearchType.OrderId:
        replaceUrl({
          params: {
            orderId: searchValue,
            globalEntityId: selectedEntityId,
            tab: props.activeTab,
          },
        })
        break

      default:
        defaultHandler()
    }
  }

  return (
    <ViewScreenAndSdkProvider screenName='main' widgetId={builtinWidgetNames.searchOrderPanel}>
      <SearchPanel
        {...props}
        searchByOptions={searchOptions}
        defaultSearchType={SearchType.OrderId}
        onSearch={onSearch}
        data-test-id='search-order-panel'
      />
    </ViewScreenAndSdkProvider>
  )
}
