import { useContext, useCallback, useMemo } from 'react'

import { EntityContext } from 'contexts/entity/EntityContext'
import { useGetValidFeatures } from 'hooks/useGetValidFeatures'
import { WidgetObjectType, WidgetName, EntityConfig } from 'contexts/entity/types'
import { ApiEndpointName } from 'contexts/apiClientCreator/ApiClientCreatorContext'
import { ApiEndpointConfig } from 'types'

export function useEntityConfig() {
  const {
    entityState: { entityConfig },
  } = useContext(EntityContext)

  const apiVersions = useMemo(() => {
    const apiVersions = entityConfig?.layout_v2?.api_versions || []
    return apiVersions.reduce((result, apiVersion) => {
      return { ...result, [apiVersion.name]: apiVersion }
    }, {} as Record<ApiEndpointName, ApiEndpointConfig>)
  }, [entityConfig?.layout_v2?.api_versions])

  const getWidgetConfig = useCallback(
    <T extends WidgetName>(widgetKey: T): WidgetObjectType<T> | null => {
      const fixedPanelWidgets = entityConfig?.fixed_panel_config?.widgets_configs
      const scrollablePanelWidgets = entityConfig?.scrollable_panel_config?.widgets_configs

      const widgets = { ...fixedPanelWidgets, ...scrollablePanelWidgets }

      if (!widgets[widgetKey]) {
        return null
      }

      return widgets[widgetKey] as WidgetObjectType<T>
    },
    [entityConfig],
  )

  const availableActions = useMemo(() => {
    return entityConfig?.fixed_panel_config?.widgets_configs?.actions?.available_actions ?? []
  }, [entityConfig])

  return {
    getWidgetConfig,
    apiVersions,
    availableActions,
    entityConfig: entityConfig || ({} as EntityConfig),
  }
}

export function useValidActions() {
  const { availableActions } = useEntityConfig()
  const getValidFeatures = useGetValidFeatures()

  const validActions = useMemo(
    () => getValidFeatures(availableActions),
    [availableActions, getValidFeatures],
  )

  return { validActions }
}
