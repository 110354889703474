import React, { useCallback, useMemo } from 'react'
import { createUseStyles } from 'react-jss'
import { Typography } from 'antd'
import { SelectBox } from 'shared'
import type { SelectOption } from 'shared'
import { useTranslation } from 'hooks/useTranslation'
import styles from '../Issue.styles'
import { RefundIssueType } from 'contexts/entity/types'

const useStyles = createUseStyles(styles)
const { Text } = Typography

type Props = {
  selectedIssueType: RefundIssueType
  issueTypes: RefundIssueType[]
  onSelect: (issue: RefundIssueType) => void
  onClick: (isVisible: boolean) => void
}

export const IssueTypeSelect = ({ selectedIssueType, issueTypes, onSelect, onClick }: Props) => {
  const classes = useStyles()
  const { t } = useTranslation()

  // Preselect single available issue type
  const isOnlyOneIssueAvailable = issueTypes.length === 1

  const mapIssueToOption = useCallback(
    (issue: RefundIssueType): SelectOption => ({
      value: issue.issue,
      label: t(`Actions Widget.Actions.Compensation.Issue Types.${issue.issue_translation_key}`),
    }),
    [t],
  )

  const selectedIssueOption = selectedIssueType ? mapIssueToOption(selectedIssueType) : undefined

  const availableIssueTypeOptions = useMemo(
    () => issueTypes.map(mapIssueToOption),
    [issueTypes, mapIssueToOption],
  )

  const handleSelectIssueType = useCallback(
    (issueTypeOption: SelectOption) => {
      const selectedIssue = issueTypes.find(
        (issueType) => issueType.issue === issueTypeOption.value,
      )

      return onSelect(selectedIssue)
    },
    [issueTypes, onSelect],
  )

  const label = t('Actions Widget.Actions.Compensation.Issue Type')
  const placeholder = t('Actions Widget.Actions.Compensation.Select an option')

  return (
    <div className={classes.dropdownHolder}>
      <Text className={classes.issueTypeText}>{label}:</Text>
      <SelectBox
        disabled={isOnlyOneIssueAvailable}
        testId='issue-type-dropdown'
        options={availableIssueTypeOptions}
        value={selectedIssueOption}
        placeholder={placeholder}
        onSelectChange={handleSelectIssueType}
        onDropdownVisibleChange={onClick}
      />
    </div>
  )
}
