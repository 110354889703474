import { ApiClientCreator } from 'contexts/apiClientCreator/ApiClientCreatorContext'
import { ApiService } from 'services/authApi/getPermissions'
import { OrderApiResponse } from 'types/api/orderApi/order'
import { orderPropertyFilter } from 'types/herocare'
import { getLastOrdersV2, OrderApiV2Response } from './getLastOrdersV2'

export const getLatestVendorOrder: ApiService<
  {
    entityId: string
    vendorId: string
  },
  OrderApiResponse
> = (createClient, options) => {
  const {
    entityId,
    vendorId,

    clientParams,
  } = options

  const { validateData, mockedResponse, ...restOfClientParams } = clientParams || {}
  return getLastOrdersV2(createClient as unknown as ApiClientCreator<OrderApiV2Response>, {
    entityId,
    propertyFilters: {
      [orderPropertyFilter.VendorId]: vendorId,
    },
    pageSize: 1,

    clientParams: {
      ...restOfClientParams,
      mockedResponse: mockedResponse
        ? {
            data: {
              next_key: '',
              orders: [mockedResponse.data],
            },
          }
        : null,

      validateData: (data) => {
        if (data.orders.length === 0) {
          return 404
        }
        if (validateData) {
          return validateData(data.orders[0])
        }
        return true
      },
    },
  }).then((res) => {
    return {
      ...res,
      data: res.data.orders[0],
    }
  })
}
