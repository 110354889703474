import React from 'react'
import { createCustomSvgIcon } from 'factory/createCustomSvgIcon'

export const BasketIcon = createCustomSvgIcon({
  viewBoxWidth: 12,
  viewBoxHeight: 14,
  content: (
    <>
      <path d='M10.8005 3.87695H8.67554V3.62695C8.67554 2.03945 7.38804 0.751953 5.80054 0.751953C4.21304 0.751953 2.92554 2.03945 2.92554 3.62695V3.87695H0.800537C0.523975 3.87695 0.300537 4.10039 0.300537 4.37695V12.752C0.300537 13.0285 0.523975 13.252 0.800537 13.252H10.8005C11.0771 13.252 11.3005 13.0285 11.3005 12.752V4.37695C11.3005 4.10039 11.0771 3.87695 10.8005 3.87695ZM4.05054 3.62695C4.05054 2.65977 4.83335 1.87695 5.80054 1.87695C6.76773 1.87695 7.55054 2.65977 7.55054 3.62695V3.87695H4.05054V3.62695ZM10.1755 12.127H1.42554V5.00195H2.92554V6.37695C2.92554 6.4457 2.98179 6.50195 3.05054 6.50195H3.92554C3.99429 6.50195 4.05054 6.4457 4.05054 6.37695V5.00195H7.55054V6.37695C7.55054 6.4457 7.60679 6.50195 7.67554 6.50195H8.55054C8.61929 6.50195 8.67554 6.4457 8.67554 6.37695V5.00195H10.1755V12.127Z' />
    </>
  ),
})
