export default {
  summaryContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  listsContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
  },
  listHolder: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'start',
    width: '44%',
    minWidth: '200px',
    marginRight: '2%',

    '& .ant-list-items > * + *': {
      marginTop: '16px',
    },
  },
  listHolderFlex: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'start',
    flex: 'auto',
    minWidth: '200px',
    maxWidth: '50%',
    marginRight: '2%',
    marginLeft: '1%',

    '& .ant-list-items > * + *': {
      marginTop: '16px',
    },
  },

  addressTextTooltipHolder: {
    display: 'flex',
    padding: '5px',
  },
  addressTextTooltipLabel: {
    width: '100px',
    minWidth: '100px',
    color: '#f6f6f6',
    fontWeight: 600,
  },
  addressTextTooltipValue: {
    color: '#f6f6f6',
  },
  timeline: {
    display: 'flex',
    marginTop: '20px',

    '& .ant-timeline-item': {
      flexGrow: 1,
      flexBasis: 0,
    },
    '& .ant-timeline-item-content': {
      marginLeft: 0,
      position: 'static',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    '& .ant-timeline-item-head': {
      left: '50%',
      transform: 'translate(-50%)',
      width: '20px',
      height: '20px',
    },
    '& .ant-timeline-item-tail': {
      left: '50%',
      borderLeft: 'none',
      borderTop: '2px solid #f0f0f0',
      width: 'calc(100% - 10px)',
      height: 0,
    },
  },
  itemLabel: {
    marginBottom: '30px',
    marginTop: '-25px',
    color: 'rgba(0, 0, 0, 0.45)',
  },
  list: {
    '& .ant-list-item-meta-content': {
      display: 'flex',
      flexDirection: 'column-reverse',
    },
    '& .ant-list-item': {
      border: 'none',
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
}
