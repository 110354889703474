import React from 'react'
import { createCustomSvgIcon } from 'factory/createCustomSvgIcon'

export const ExportIcon = createCustomSvgIcon({
  viewBoxWidth: 12,
  viewBoxHeight: 12,
  content: (
    <>
      <g clipPath='url(#clip0_3281_30826_export_icon)'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M9.84741 1.46654H7.36363V0.533203H11C11.2577 0.533203 11.4667 0.742137 11.4667 0.99987V4.46528H10.5333V2.10153L6.05194 6.442L5.40259 5.77158L9.84741 1.46654ZM0.533325 1.72943C0.533325 1.4717 0.742259 1.26276 0.999992 1.26276H6.45454V2.1961H1.46666V10.1999H9.98787V5.37723H10.9212V10.6665C10.9212 10.9243 10.7123 11.1332 10.4545 11.1332H0.999992C0.742259 11.1332 0.533325 10.9243 0.533325 10.6665V1.72943Z'
        />
      </g>
      <defs>
        <clipPath id='clip0_3281_30826_export_icon'>
          <rect width='12' height='12' fill='white' />
        </clipPath>
      </defs>
    </>
  ),
})
