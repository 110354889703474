/**
 * expects order API's response
 * creates items array and payment obj to be used in Order widget Items Tab
 * */
import { ItemsItem, ItemOption, OrderItemLocalizedName } from 'types/api/orderApi/order'
import fixNumber from 'utils/fixNumber'

interface ItemCategoryObject {
  id: string
  name: string
  attributes: {
    display_name: {
      en: string
    }
  }
  status: 'Active'
  path: string

  ancestors?: ItemCategoryObject[]
}

export const getItemLocalizedNames = (
  item: { name: string; display_name?: string; names?: Array<OrderItemLocalizedName> },
  locale: string,
  enabledItemLocales: string[],
) => {
  const result = { name: item.display_name || item.name, localeName: '', localizedNames: [] }

  const names = item.names || []
  names
    .map((current) => {
      return { name: current.value, locale: current.locale.split(/[-_]/)[0] }
    })
    .forEach((current) => {
      if (current.locale === locale) {
        result.localeName = current.name
      }
      if (
        enabledItemLocales &&
        enabledItemLocales.includes(current.locale) &&
        current.name &&
        // backend also, sometimes, sends the english name as the localized name,
        result.name !== current.name &&
        // backend sends No Name for certain locales
        current.name !== 'No Name'
      ) {
        result.localizedNames.push(current.name)
      }
    })

  return result
}

// @TODO: Remove isNewItemsOrderDesignAvailable when betaPermission_NewOrderItems is not required anymore.
function transformOrderItems(
  items: ItemsItem[],
  opts: {
    isNewItemsOrderDesignAvailable: boolean
    currency_digits_after_comma: number
    enabled_item_locales?: string[]
    locale: string
  },
) {
  const {
    currency_digits_after_comma,
    enabled_item_locales = [],
    isNewItemsOrderDesignAvailable,
    locale,
  } = opts

  const defaultZero = Number(0).toFixed(currency_digits_after_comma)

  // map over order items: create i obj: push i to items to be returned

  return (items || []).map((item: ItemsItem) => {
    const toppings = transformItemOptions(item, item.options || [], {
      currency_digits_after_comma,
      enabled_item_locales,
      locale,
    })

    const toppingsPrice: string = toppings
      ? fixNumber(calculateTotalOptionsPrice(toppings), currency_digits_after_comma)
      : defaultZero

    const totalPrice: number =
      (Number(item.unit_price) + Number(toppingsPrice)) * Number(item.quantity)

    const attributes = item.attributes || []
    const features = item.features || []

    const categories: string[] = []

    const masterAttribute = attributes.find(
      (current) => current.attribute_type === 'master_category',
    )

    if (masterAttribute) {
      try {
        const category: ItemCategoryObject = JSON.parse(masterAttribute.name)
        const levelOneAncestor = (category.ancestors || []).shift()
        categories.push(levelOneAncestor?.name || category.name)
      } catch (ex) {
        categories.push(masterAttribute.name)
      }
    } else {
      attributes.forEach((attribute) => {
        if (attribute.attribute_type === 'category') {
          categories.push(attribute.name)
        }
      })
    }

    const { name, localeName, localizedNames } = getItemLocalizedNames(
      item,
      locale,
      enabled_item_locales,
    )

    const i = {
      id: item.id,
      quantity: item.quantity,
      alteredQuantity: item.quantity,
      specialInstructions: item.customer_notes ? item.customer_notes : '',
      originalName: name,
      localeName,
      // this name is used by order items v1
      name: `${item.quantity} ${isNewItemsOrderDesignAvailable ? 'x' : 'X'} ${name}`,
      localizedNames,
      price: fixNumber(item.unit_price, currency_digits_after_comma),
      toppingsPrice: toppingsPrice,
      totalPrice: fixNumber(totalPrice, currency_digits_after_comma),
      toppings,
      isBundle: item.is_bundle,
      category: categories.join(' / '),
      isSoldByWeight: features.includes('sold_by_weight'),
      customerNotes: item.customer_notes,
      requirement: item.requirement,
      imageUrls: item.image_urls || [],
    }
    return i
  })
}

// expects options arr of an item
// checks if option is topping or bundle_item and returns total price of all options
function calculateTotalOptionsPrice(options: Array<ItemOption>) {
  return options.reduce((acc: number, cv: any) => {
    acc += cv.totalPrice ? Number(cv.totalPrice) : 0
    return acc
  }, 0.0)
}

// expects options arr of an item
// checks if option is topping and returns a new arr with topping name and price
function transformItemOptions(
  item: ItemsItem | ItemOption,
  options: Array<ItemOption>,
  opts: {
    currency_digits_after_comma: number
    enabled_item_locales?: string[]
    locale: string
  },
) {
  const { currency_digits_after_comma, locale, enabled_item_locales } = opts

  const defaultZero = Number(0).toFixed(currency_digits_after_comma)

  return options
    .filter((option) => ['topping', 'bundle_item'].includes(option.type))
    .map((option) => {
      const quantity = option.quantity ? option.quantity : 1
      const unitPrice = option.unit_price || 0.0

      const toppings = transformItemOptions(option, option?.options ?? [], opts)

      const toppingsPrice: string = toppings
        ? fixNumber(calculateTotalOptionsPrice(toppings), currency_digits_after_comma)
        : defaultZero

      const totalPrice: number = (Number(unitPrice) + Number(toppingsPrice)) * Number(quantity)

      const { name, localeName, localizedNames } = getItemLocalizedNames(
        option,
        locale,
        enabled_item_locales,
      )

      return {
        id: option.id,
        parentItemId: item.id,
        parentItemName: (item as ItemsItem).display_name || item.name,
        name: option.name,
        originalName: name,
        localeName,
        localizedNames,
        toppingsPrice,
        price: fixNumber(unitPrice, currency_digits_after_comma),
        quantity,
        totalPrice: fixNumber(totalPrice, currency_digits_after_comma),
        isBundle: false,
        category: '',
        isSoldByWeight: false,

        toppings,
        imageUrls: option?.image_urls || [],
      }
    })
}

export default transformOrderItems
