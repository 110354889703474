export default {
  text: {
    marginLeft: '8px',
  },
  paymentMethodWrapper: {
    marginRight: '15px',
    display: 'flex',
    alignItems: 'center',
  },
  wrapper: {
    display: 'flex',
    flexFlow: 'wrap',
  },
  list: {
    padding: '8px 16px',
    '& .ant-list-item-meta-content': {
      display: 'flex',
      flexDirection: 'column-reverse',
    },
  },
  paymentIcon: {
    width: '22px',
    height: '22px',
  },
}
