import { gray, primary, text } from 'theme'

type ChatCardProps = {
  align: 'right' | 'left'
  replyStart: boolean
}

export default {
  cardContainer: (props: ChatCardProps) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: '2px',
    maxWidth: '50%',
    fontSize: '12px',
    fontWeight: '400px',
    color: text.secondary,
    lineHeight: '20px',
    alignSelf: props.align === 'left' ? 'start' : 'end',
    alignItems: props.align === 'left' ? 'start' : 'end',
  }),
  chatCardInfo: {},
  chatCardBubble: (props: ChatCardProps) => ({
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: props.align === 'left' ? gray.gray4 : primary.primary2,
    borderRadius: '8px',
    borderTopLeftRadius: props.replyStart && props.align === 'left' ? 0 : '8px',
    borderTopRightRadius: props.replyStart && props.align === 'right' ? 0 : '8px',
  }),
  chatCardMessage: (props: ChatCardProps) => ({
    padding: '8px 12px',
    fontSize: '14px',
    lineHeight: '22px',
    color: text.primary,
    alignSelf: props.align === 'left' ? 'start' : 'end',
    overflowWrap: 'anywhere',
  }),
  chatCardTime: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
    padding: '0 8px 4px ',
  },
}
