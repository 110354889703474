/**
 * Checks if customer payment method is cash on delivery and returns boolean value
 * */

function isCOD(paymentMethod: string): boolean {
  if (paymentMethod) {
    return ['COD', 'CASH'].includes(paymentMethod.toUpperCase())
  }
  return false
}

export default isCOD
