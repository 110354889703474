import React, { useCallback, useState } from 'react'
import { createPluggableWidget } from 'factory/createPluggableWidget'
import { useApiService } from 'hooks/useApiService'
import { createUseStyles } from 'react-jss'
import { Col, Row, Space, Typography } from 'antd'
import { Button, ErrorScreen, SuccessScreen, UnifiedIcon } from 'shared'
import { gold } from 'theme'
import { getOrderFlags } from 'services/ordersApi/getOrderFlags'
import { updateOrderFlags } from 'services/ordersApi/updateOrderFlags'
import { TranslateFunction } from 'hooks/useTranslation'

const useStyles = createUseStyles({
  icon: {
    color: gold.gold5,
    fontSize: '22px',
  },
})
const getLabel = (payable: boolean, { t }: { t: TranslateFunction }) =>
  payable ? t('Order Widget.Payable') : t('Order Widget.Not Payable')

export const SwitchPayable = createPluggableWidget(
  ({ sdk, globalEntityId, order, ErrorRenderer, onQuit }) => {
    const [isPayable, setIsPayable] = useState<boolean>()
    const classes = useStyles()
    const { t, captureUserAction } = sdk

    const { loading: isOrderFlagsLoading, error: orderFlagsError } = useApiService({
      service: getOrderFlags,
      params: { entityId: globalEntityId, orderId: order.order_id },
      autoLoad: true,
      onSuccess: ({ data }) => setIsPayable(data.is_billable),
      deps: [globalEntityId, order.order_id],
    })

    const {
      loading: isUpdateOrderFlagsLoading,
      status: updateOrderFlagsStatus,
      loadService: updatePayable,
    } = useApiService({
      service: updateOrderFlags,
      params: { entityId: globalEntityId, orderId: order.order_id, isBillable: !isPayable },
      autoLoad: false,
      onSuccess: ({ data }) => {
        setIsPayable(data.is_billable)
        captureUserAction('CONFIRM_SWITCH_PAYABLE_SUCCESS', {
          reportToEts: true,
          eventDetails: {
            switch_to: getLabel(data.is_billable, { t }),
          },
        })
      },
    })

    const updatePayoutStatus = useCallback(() => {
      captureUserAction('CONFIRM_SWITCH_PAYABLE', {
        reportToEts: true,
        eventDetails: {
          switch_to: getLabel(!isPayable, { t }),
        },
      })
      updatePayable()
    }, [captureUserAction, isPayable, t, updatePayable])

    const onCancel = () => onQuit()

    return (
      <ErrorRenderer loading={isOrderFlagsLoading} errorPayload={orderFlagsError?.errorPayload}>
        {() => {
          if (updateOrderFlagsStatus === 'success') {
            return (
              <SuccessScreen
                orderId={order.order_id}
                title={t(`widgets.switch_payable.Payout status switched to {{payable_status}}`, {
                  replace: {
                    payable_status: `"${getLabel(isPayable, { t })}"`,
                  },
                })}
                onDone={onCancel}
              />
            )
          }

          if (updateOrderFlagsStatus === 'error') {
            return <ErrorScreen title={t('Messages.Something went wrong')} onDone={onCancel} />
          }

          return (
            <div>
              <Row gutter={[16, 8]}>
                <Col>
                  <UnifiedIcon
                    icon='ExclamationCircleOutlined'
                    size={22}
                    className={classes.icon}
                  />
                </Col>
                <Col>
                  <Row>
                    <Typography.Title level={5}>
                      {t('Messages.Process Quit.Are you sure?')}
                    </Typography.Title>
                  </Row>

                  <Row>
                    <Col>
                      <Typography.Text>
                        {t(
                          "widgets.switch_payable.This order's payout status will be switched to ",
                        )}
                        <Typography.Text strong>"{getLabel(!isPayable, { t })}"</Typography.Text>
                      </Typography.Text>
                    </Col>
                  </Row>
                </Col>
              </Row>

              <Row justify='end'>
                <Col>
                  <Space direction='horizontal'>
                    <Button onClick={onCancel}>{t('Interface.Cancel')}</Button>
                    <Button
                      type='solid'
                      onClick={updatePayoutStatus}
                      loading={isUpdateOrderFlagsLoading}
                    >
                      {t('Interface.Submit')}
                    </Button>
                  </Space>
                </Col>
              </Row>
            </div>
          )
        }}
      </ErrorRenderer>
    )
  },
)
