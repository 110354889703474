/**
 * Wiget to Change delivery address location pin ( lat and lng on map )
 */

import React, { useMemo, useState } from 'react'
import { createUseStyles } from 'react-jss'
import styles from './ChangeDropoffPin.styles'
import { createPluggableWidget } from 'factory/createPluggableWidget'
import MapsPinChange from './MapsPinChange'
import { Flex, Button, Text, WidgetActionFail } from 'shared'
import { Alert, Divider, Input, Skeleton } from 'antd'
import { useApiService } from 'hooks/useApiService'
import { patchDeliveryAddress } from 'services/ordersApi/patchDeliveryAddress'
import RiderChangePinSuccess from './ChangeDropoffPinSuccess/ChangeDropoffPinSuccess'
import { MapMarkerIcon } from 'Icons'
import { useTranslation } from 'hooks/useTranslation'
import { BuiltinWidgetConfigs, LinesOfBusiness } from 'types'
import { addressInVendorDeliveryArea } from 'services/vendorApi/addressInVendorDeliveryArea'
import { EMPTY_CALLBACK } from 'constants/constants'

const useStyles = createUseStyles(styles)

export const ChangeDropoffPin = createPluggableWidget<BuiltinWidgetConfigs['change_dropoff_pin']>(
  ({ order, onQuit, globalEntityId, orderId, sdk, config }) => {
    const { ignoreVendorDeliveryAreaValidation, validateAddress } = config
    const { address_text = '', latitude, longitude } = order?.delivery?.location

    const { t } = useTranslation()
    const classes = useStyles()

    // New lat,lng. Gets set either by map or two input boxes
    const [newLat, setNewLat] = useState<string>(String(latitude ?? ''))
    const [newLng, setNewLng] = useState<string>(String(longitude ?? ''))

    // Validate over network and set this boolean
    const [isNewAddressDeliverable, setIsNewAddressDeliverable] = useState<boolean>()

    // If new lat, lng are non empty AND are not equal to current lat, lng
    const isNewLocationSet = useMemo(() => {
      return (
        newLat !== '' &&
        newLng !== '' &&
        (newLat !== String(latitude) || newLng !== String(longitude))
      )
    }, [latitude, longitude, newLat, newLng])

    // Service to validate given lat,lng to be in vendor delivery area.
    const { loading: checkAddressInVendorDeliveryAreaLoading } = useApiService({
      service: addressInVendorDeliveryArea,
      // Trigger address validation service for newlat, lng. When they are different than existing lat, lng
      deps: [newLat, newLng],
      autoLoad: true,
      shouldLoad: isNewLocationSet && validateAddress,
      params: {
        entityId: globalEntityId,
        latitude: parseFloat(newLat),
        longitude: parseFloat(newLng),
        vendorId: order?.vendor?.id,
      },
      onBeforeLoad: () => {
        setIsNewAddressDeliverable(false)
      },
      onSuccess: (res) => {
        if (res?.data?.is_deliverable) {
          setIsNewAddressDeliverable(true)
        }
      },
    })

    // Service to update delivery pin ( it's same service which changes the address )
    const {
      loadService: executePatchDeliveryAddress,
      status: patchDeliveryAddressStatus,
      error: patchDeliveryAddressError,
      loading: isPatchingDeliveryAddressLoading,
    } = useApiService({
      service: patchDeliveryAddress,
      deps: [],
      autoLoad: false,
      onSuccess() {
        sdk.captureUserAction('CONFIRM_CHANGE_PIN_SUCCESS', { reportToEts: true })
        sdk.eventEmitter.dispatchEvent({
          name: 'CHANGE_ADDRESS_SUCCESS',
          payload: { orderId },
        })
      },
    })

    // Save button disable logic.
    let saveButtonIsDisabled = true

    if (isNewLocationSet) {
      if (!validateAddress || ignoreVendorDeliveryAreaValidation) {
        // if not validataion is in place.
        saveButtonIsDisabled = false
      } else if (validateAddress && isNewAddressDeliverable) {
        // if the address is valid
        saveButtonIsDisabled = false
      }
    }

    const handleSaveClick = async () => {
      sdk.captureUserAction('CONFIRM_CHANGE_PIN', { reportToEts: true })
      // send new lat, lng to server.
      const body = {
        latitude: parseFloat(newLat),
        longitude: parseFloat(newLng),
        skip_delivery_area_validation: ignoreVendorDeliveryAreaValidation,
      }
      await executePatchDeliveryAddress({
        entityId: globalEntityId,
        orderId,
        ...body,
      }).catch(EMPTY_CALLBACK)
    }

    // Address status text
    const getAddressStatusText = () => {
      if (checkAddressInVendorDeliveryAreaLoading) {
        return `${t('Interface.Loading')}...`
      }
      if (!validateAddress) {
        return t('Actions Widget.Actions.Change Address.Address not verified against delivery zone')
      }
      if (isNewAddressDeliverable) {
        return t('Actions Widget.Actions.Change Address.Address within delivery zone')
      }
      return t('Actions Widget.Actions.Change Address.Address not within delivery zone')
    }

    // Busy ( over network call )
    if (isPatchingDeliveryAddressLoading) {
      return <Skeleton active />
    }

    // Error screen
    if (patchDeliveryAddressError) {
      return (
        <WidgetActionFail
          heading={t('widgets.change_dropoff_pin.fail.heading')}
          subHeading={t('widgets.change_dropoff_pin.fail.message')}
          onDone={() => {
            onQuit()
          }}
        />
      )
    }

    // Success
    if (patchDeliveryAddressStatus === 'success') {
      return (
        <RiderChangePinSuccess
          lat={newLat}
          lng={newLng}
          onDone={() => {
            sdk.captureUserAction('ACKNOWLEDGE_CHANGE_PIN', { reportToEts: true })
            onQuit(true)
          }}
        />
      )
    }

    // Main UI, i.e, info, form and map.
    return (
      <Flex flexDirection='column' gap='16px'>
        <Flex columnGap='24px'>
          {/* Form */}
          <Flex flexDirection='column' rowGap='16px' w='50%'>
            <Text.Primary>{t('widgets.change_dropoff_pin.address')}</Text.Primary>
            <Flex flexDirection='row' columnGap='8px'>
              <Flex h='24px' w='24px' justifyContent='center' alignItems='center'>
                <MapMarkerIcon
                  className={isNewLocationSet ? classes.pinIconGray : classes.pinIconPink}
                />
              </Flex>
              <Flex flexDirection='column'>
                <Flex color='gray' fontSize='12px' h='24px'>
                  <Text.Secondary>
                    {t('widgets.change_dropoff_pin.delivery_address')}
                  </Text.Secondary>
                </Flex>
                <Flex>{address_text}</Flex>
              </Flex>
            </Flex>

            {/* New addres pin, just to denote new lat,lng. Kinda act like visual dirty indicator */}
            {isNewLocationSet && (
              // Show only if lat/lng changed
              <Flex flexDirection='row' columnGap='8px' alignItems='center'>
                <Flex h='24px' w='24px' justifyContent='center' alignItems='center'>
                  <MapMarkerIcon className={classes.pinIconPink} />
                </Flex>
                <Text.Secondary>{t('widgets.change_dropoff_pin.new_pin_location')}</Text.Secondary>
              </Flex>
            )}

            <Divider style={{ margin: '2px' }} />

            {/* Lat lng input boxes */}
            <Flex columnGap='16px'>
              <Flex flexDirection='column' rowGap='8px' w='50%'>
                <Text.Primary>{t('widgets.change_dropoff_pin.latitude')}</Text.Primary>
                <Input
                  type='text'
                  value={newLat}
                  onChange={(e) => {
                    setNewLat(e.target.value)
                  }}
                />
              </Flex>
              <Flex flexDirection='column' rowGap='8px' w='50%'>
                <Text.Primary>{t('widgets.change_dropoff_pin.longitude')}</Text.Primary>
                <Input
                  type='text'
                  value={newLng}
                  onChange={(e) => {
                    setNewLng(e.target.value)
                  }}
                />
              </Flex>
            </Flex>
          </Flex>

          {/* Map */}
          <Flex w='50%' minH='200px'>
            <MapsPinChange
              lat={latitude} // initial value of latitude
              lng={longitude} // initial value of longitude
              onPinMoved={({ lat, lng }) => {
                setNewLat(String(lat))
                setNewLng(String(lng))
              }}
            />
          </Flex>
        </Flex>

        {/* Show info related to address validation */}
        {isNewLocationSet && (
          <Flex py='8px' gap='8px'>
            <Alert
              className={classes.alert}
              message={
                <Text.Primary className={classes.alertText}>{getAddressStatusText()}</Text.Primary>
              }
              type={isNewAddressDeliverable ? 'success' : 'warning'}
              showIcon
            />
          </Flex>
        )}

        {/* Action buttons */}
        <Flex justifyContent='flex-end' py='8px' gap='8px'>
          <Button type='outlined' onClick={() => onQuit()}>
            {t('Interface.Cancel')}
          </Button>
          <Button
            type='solid'
            onClick={handleSaveClick}
            disabled={saveButtonIsDisabled || checkAddressInVendorDeliveryAreaLoading}
          >
            {t('Interface.Save')}
          </Button>
        </Flex>
      </Flex>
    )
  },
  {
    deriveConfig({ entityConfig, lob }) {
      const config = entityConfig?.layout_v2?.builtin_widgets_configs?.change_dropoff_pin || {}

      // ignoreVendorDeliveryAreaValidation config is only applicable for RS lobs.
      const derivedIgnoreVendorDeliveryAreaValidation =
        [LinesOfBusiness.rider, LinesOfBusiness.riderV2].includes(lob) &&
        (typeof config.ignoreVendorDeliveryAreaValidation === 'boolean'
          ? config.ignoreVendorDeliveryAreaValidation
          : true)

      return {
        ignoreVendorDeliveryAreaValidation: derivedIgnoreVendorDeliveryAreaValidation,
        validateAddress: Boolean(config?.validateAddress),
      }
    },
  },
)
