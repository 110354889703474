type EventPayload<N, P> = P & { eventName?: N }

interface EventPayloads {
  COMMENT_TAG_CLICKED: EventPayload<'COMMENT_TAG_CLICKED', { commentId: string }>
  PARTIAL_REFUND_SUCCESS: EventPayload<'PARTIAL_REFUND_SUCCESS', { orderId: string }>
  FULL_REFUND_SUCCESS: EventPayload<'FULL_REFUND_SUCCESS', { orderId: string }>
  CANCEL_ORDER_SUCCESS: EventPayload<'CANCEL_ORDER_SUCCESS', { orderId: string }>
  COMPENSATION_SUCCESS: EventPayload<'COMPENSATION_SUCCESS', { orderId: string }>
  CHANGE_ADDRESS_SUCCESS: EventPayload<'CHANGE_ADDRESS_SUCCESS', { orderId: string }>
  CHANGE_DELIVERY_INSTRUCTIONS_SUCCESS: EventPayload<
    'CHANGE_DELIVERY_INSTRUCTIONS_SUCCESS',
    { orderId: string }
  >
  CHANGE_DELIVERY_TIME_SUCCESS: EventPayload<'CHANGE_DELIVERY_TIME_SUCCESS', { orderId: string }>
  CHANGE_COOKING_INSTRUCTIONS_SUCCESS: EventPayload<
    'CHANGE_COOKING_INSTRUCTIONS_SUCCESS',
    { orderId: string }
  >
  NEW_COMMENT_SUCCESS: EventPayload<'NEW_COMMENT_SUCCESS', { orderId: string }>
  ADD_RIDER_SUCCESS: EventPayload<'ADD_RIDER_SUCCESS', { orderId: string }>
  MOVE_DELIVERY_PENDING_TO_QUEUE_SUCCESS: EventPayload<
    'MOVE_DELIVERY_PENDING_TO_QUEUE_SUCCESS',
    { orderId: string }
  >
  RIDER_BREAK_STATUS_CHANGE: EventPayload<'RIDER_BREAK_STATUS_CHANGE', { riderId: string }>
  CHANGE_DELIVERY_STATUS_SUCCESS: EventPayload<
    'CHANGE_DELIVERY_STATUS_SUCCESS',
    { orderId: string }
  >
  SET_PRIMARY_DELIVERY_SUCCESS: EventPayload<'SET_PRIMARY_DELIVERY_SUCCESS', { orderId: string }>
}

export type EmittedEventName = keyof EventPayloads

export type GenericEventCallback<N extends EmittedEventName> = (
  event: CustomEvent<EventPayloads[N]>,
) => void

interface GenericEventListener<N extends EmittedEventName> {
  name: N
  namespace?: string
  callback?: GenericEventCallback<N>
}
interface GenericEventDispatcher<N extends EmittedEventName> {
  name: N
  payload?: EventPayloads[N]
}

export type EventsListener = Omit<GenericEventListener<EmittedEventName>, 'name'> & {
  names: EmittedEventName[]
  namespace?: string
  callback?: GenericEventCallback<EmittedEventName>
}

export type EventListener = GenericEventListener<EmittedEventName>

export type EventDispatcher = GenericEventDispatcher<EmittedEventName>

const GLOBAL = 'global'

export interface IEventEmitter {
  addEventListener(listener: EventListener): EventListener
  addEventsListener(eventsListener: EventsListener): EventListener[]

  dispatchEvent(dispatcher: EventDispatcher)

  removeEventListener(listener: EventListener)
  removeAllListeners(namespace?: string)
  removeEventListeners(eventsListeners: EventListener[])
}

export class EventEmitterNamespace implements IEventEmitter {
  constructor(
    private namespace: string,
    private eventEmitter: EventEmitter,
    private subscriber?: (eventName: EventDispatcher) => void,
  ) {}

  dispatchEvent(dispatcher: EventDispatcher) {
    this.eventEmitter.dispatchEvent(dispatcher)
    if (this.subscriber) {
      this.subscriber(dispatcher)
    }
  }
  addEventListener(listener: EventListener): EventListener {
    return this.eventEmitter.addEventListener({ ...listener, namespace: this.namespace })
  }
  addEventsListener(listener: EventsListener): EventListener[] {
    return this.eventEmitter.addEventsListener({ ...listener, namespace: this.namespace })
  }
  removeEventListener(listener: EventListener) {
    this.eventEmitter.removeEventListener(listener)
  }
  removeEventListeners(listeners: EventListener[]) {
    this.eventEmitter.removeEventListeners(listeners)
  }
  removeAllListeners() {
    this.eventEmitter.removeAllListeners(this.namespace)
  }
}

export class EventEmitter implements IEventEmitter {
  private eventTarget: EventTarget
  private controllers: Map<string, AbortController>
  constructor() {
    this.eventTarget = new EventTarget()
    this.controllers = new Map([[GLOBAL, new AbortController()]])
  }

  private setNamespace(namespace: string) {
    if (!namespace) throw new Error('namespace should be valid')

    if (!this.controllers.has(namespace)) {
      this.controllers.set(namespace, new AbortController())
    }
  }

  addEventListener({ name, callback, namespace = GLOBAL }: EventListener): EventListener {
    this.setNamespace(namespace)
    this.eventTarget.addEventListener(name, callback, {
      signal: this.controllers.get(namespace).signal,
    })
    return { name, callback, namespace }
  }

  addEventsListener({ names, namespace, callback }: EventsListener): EventListener[] {
    return names.map((name) => this.addEventListener({ name, namespace, callback }))
  }

  removeEventListeners(listeners: EventListener[]) {
    listeners.forEach((listener) => this.removeEventListener(listener))
  }

  removeEventListener({ name, callback }: EventListener) {
    this.eventTarget.removeEventListener(name, callback)
  }

  dispatchEvent({ name, payload }: EventDispatcher) {
    this.eventTarget.dispatchEvent(
      new CustomEvent(name, { detail: { ...payload, eventName: name } }),
    )
  }

  removeAllListeners(namespace = GLOBAL) {
    this.controllers.get(namespace)?.abort()
  }
}

export const eventEmitter = new EventEmitter()
