import React from 'react'
import { Button } from 'antd'
import { useTranslation } from 'hooks/useTranslation'
import { createUseStyles } from 'react-jss'
import styles from './StatusScreen.styles'
import { MessageStatus } from '../types'

const useStyles = createUseStyles(styles)

type StatusScreenProps = {
  status: MessageStatus
  handleClose: () => void
}

const statusMessage: Record<MessageStatus, string> = {
  success: 'Delivery moved to queue',
  error: 'Delivery move to queue failed',
}

export const StatusScreen = ({ status, handleClose }: StatusScreenProps) => {
  const { t } = useTranslation()
  const classes = useStyles({ status })

  // TODO: use the shared component to show error and success screens instead of <Result />

  return (
    <div className={classes.container}>
      <div className={classes.statusMessageContainer}>{t(statusMessage[status])}</div>
      <div className={classes.actionButtons}>
        <Button type='primary' key='close' onClick={handleClose}>
          {t('Done')}
        </Button>
      </div>
    </div>
  )
}
