/**
 * Search Voucher modal, rendered upon click in actions
 * renders a modal with a choice to search by voucher code and global entity ID
 * */

// libs
import React, { useState, useEffect, useImperativeHandle } from 'react'
// contexts and types
import { SingleCountry } from 'types/actions/countries'
// hooks
import { useTranslation } from 'hooks/useTranslation'
// utils
import environment from 'envConfig'
import countries from 'utils/countries'
// styles
import { createUseStyles } from 'react-jss'
import styles from './SearchVoucherView.styles'
import { Typography, Input, Button, Select } from 'antd'
// components
import SingleVoucherView from './VoucherView/SingleVoucherView'
// utils
import { readAuthPayload } from 'utils/authHelpers'
import { useApiService } from '../../../../hooks/useApiService'
import { getVoucherByCode } from '../../../../services/VoucherApi/getVoucherByCode'
import { EMPTY_CATCH_CALLBACK } from 'constants/constants'
import { useCaptureUserAction } from 'hooks/events/useCaptureUserAction'
import { PluggableWidget } from 'types/unitedUiConfig'
import { WidgetErrorHandler } from 'components/WidgetErrorHandler/widgetErrorHandler'
import { SDK_EVENTS } from 'types'
import { createPluggableWidget } from 'factory/createPluggableWidget'

const useStyles = createUseStyles(styles)

export const SearchVoucherView: PluggableWidget<{}> = createPluggableWidget(
  ({ globalEntityId, sdk }, ref) => {
    const classes = useStyles()
    const { Text } = Typography
    const { Option } = Select

    // pull translations
    const { t } = useTranslation()

    const captureUserAction = useCaptureUserAction()

    // get available entities for agent to be able to search
    const [availableEntitiesToSearch, setAvailableEntitiesToSearch] = useState<string[]>([])
    useEffect(() => {
      setAvailableEntitiesToSearch(
        readAuthPayload().namespaces.filter(
          (current) => current !== environment().betaPermissionsNamespace,
        ),
      )
    }, [])

    const [voucherSearch, setVoucherSearch] = useState('') // code searched by agent
    const [region, setRegion] = useState(globalEntityId || '') // region selected by agent

    // fired after search is clicked -> finds the searched voucher by code and sets local states accordingly
    const {
      loadService,
      loading: isSearching,
      error: searchVoucherError,
      data: voucher,
      clearError: clearSearchVoucherError,
    } = useApiService({
      autoLoad: false,
      service: getVoucherByCode,
      params: {
        entityId: region,
        voucherCode: voucherSearch,
      },
      deps: [],

      onSuccess: (res) => {
        const voucher = res.data
        sdk.dispatch({
          type: SDK_EVENTS.SET_VIEW_STATE,
          payload: {
            title: `${t('Widgets Common.Voucher Code')}: ${voucher.name}`,
          },
        })
      },
    })

    const handleSearch = async () => {
      await loadService({ voucherCode: voucherSearch, entityId: region }).catch(
        EMPTY_CATCH_CALLBACK,
      )
    }

    const handleChangeCountry = (e: string) => {
      captureUserAction('ActionsSearchVoucherCountryChanged', {
        eventDetails: {
          prev_region: region,
          current_region: e,
        },
      })
      setRegion(e)
    }

    useImperativeHandle(
      ref,
      () => {
        return {
          onXButtonClick: () => {
            return !voucherSearch
          },
        }
      },
      [voucherSearch],
    )

    return (
      <WidgetErrorHandler
        loading={isSearching}
        errorPayload={searchVoucherError?.errorPayload}
        displayType='overlay'
        loadingText={t('Interface.Loading')}
        onBack={clearSearchVoucherError}
        enableGoBack
        enableRetry={false}
      >
        {() => {
          if (voucher) {
            return <SingleVoucherView voucher={voucher as any} />
          }
          return (
            <div>
              <div className={classes.modalContent}>
                <div className={classes.searchContainer}>
                  <Text strong className={classes.text}>
                    {`${t('Widgets Common.Voucher Code')}:`}
                  </Text>
                  <Input
                    className={classes.findVoucherSearch}
                    placeholder={`${t('Widgets Common.Voucher Code')}...`}
                    value={voucherSearch}
                    onChange={(e: any) => setVoucherSearch(e.target.value)}
                  />
                </div>
                <div className={classes.searchContainer}>
                  <Text strong className={classes.text}>
                    {`${t('Widgets Common.Country')}:`}
                  </Text>
                  <Select
                    defaultValue={region}
                    className={classes.regionSelect}
                    onChange={(e: string) => handleChangeCountry(e)}
                  >
                    {countries?.map((c: SingleCountry) => {
                      if (availableEntitiesToSearch.includes(c.entityId)) {
                        return (
                          <Option key={c.entityId} value={c.entityId}>
                            {c.entityId}
                          </Option>
                        )
                      }
                      return null
                    })}
                  </Select>
                </div>
              </div>
              <div className={classes.buttonContainer}>
                <Button type='primary' onClick={handleSearch} disabled={!Boolean(voucherSearch)}>
                  {t('Interface.Search')}
                </Button>
              </div>
            </div>
          )
        }}
      </WidgetErrorHandler>
    )
  },

  {
    category: 'data_lookup',
  },
)
