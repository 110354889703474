import { AuthPayload, useAuthProvider } from 'contexts/auth/AuthProvider'
import { useCaptureUserAction } from 'hooks/events/useCaptureUserAction'
import { useCallback } from 'react'

export const useLogin = () => {
  const { authDispatch } = useAuthProvider()
  const captureUserAction = useCaptureUserAction()

  return useCallback(
    (payload: AuthPayload) => {
      authDispatch({
        type: 'login',
        payload,
      })
      captureUserAction('LoginSuccess')
    },
    [authDispatch, captureUserAction],
  )
}
