import { useContextProvider } from 'contexts/useContextProvider'
import { SDK } from 'types'
import React, { createContext, PropsWithChildren } from 'react'

const SDKContext = createContext<SDK>(undefined)

export type SdkProviderProps = PropsWithChildren<{ sdk: SDK }>

export const SdkProvider = ({ sdk, children }: SdkProviderProps) => {
  return <SDKContext.Provider value={sdk}>{children}</SDKContext.Provider>
}

export const useSdk = () => useContextProvider(SDKContext, 'SdkProvider')
