export default {
  loaderContainer: {
    padding: '20px 0px',
    display: 'flex',
    minWidth: '100%',
    position: 'relative',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '100%',
  },

  errorContainer: {
    display: 'flex',
    justifyContent: 'center',
    width: 'inherit',
  },

  icon: {
    display: 'flex',
    justifyContent: 'center',

    '& > img': {
      width: 'auto',
    },
  },

  fullTitle: {
    fontSize: '23px',
    lineHeight: '43px',
    fontWeight: 400,
    textAlign: 'center',
    color: 'rgba(0,0,0,.85)',
    marginBottom: '8px',
  },

  regularTitle: {
    fontSize: '14px',
    lineHeight: '22px',
    fontWeight: 600,
    textAlign: 'center',
    color: 'rgba(0,0,0,.65)',
    marginBottom: '8px',
  },

  miniTitle: {
    fontSize: '14px',
    lineHeight: '22px',
    fontWeight: 400,
    color: 'rgba(0,0,0,.65)',
    marginBottom: '0px',
  },

  subtitle: {
    fontSize: '14px',
    lineHeight: '22px',
    textAlign: 'center',
    color: 'rgba(0,0,0,.65)',
    margin: '0px',
    '& + &': {
      marginTop: '8px',
    },
  },

  secondaryText: {
    marginTop: '8px',
    fontSize: '11px',
    lineHeight: '22px',
    fontWeight: '400',
    color: 'rgba(0, 0, 0, 0.45)',
    textAlign: 'center',
  },

  miniAlert: {
    padding: '8px 16px',
    marginBottom: '8px',
    marginTop: '8px',
  },

  miniAlertIconHolder: {
    display: 'inline-flex',
    paddingTop: '4px',
  },

  miniDescription: {
    fontSize: '14px',
    lineHeight: '22px',
    fontWeight: 400,
    color: 'rgba(0,0,0,.45)',
    marginBottom: '0px',
  },

  miniNoDataContainer: {
    display: 'flex',
    alignItems: 'center',
  },

  miniNoDataIconHolder: {
    display: 'flex',
    '& > svg': {
      width: '32px',
      height: '22px',
    },
  },

  miniNoDataTitle: {
    fontSize: '14px',
    lineHeight: '22px',
    color: 'rgba(0, 0, 0, 0.45)',
    margin: '0px 0px 0px 8px',
  },

  mlAuto: {
    marginLeft: 'auto',
  },

  wFull: {
    width: '100%',
  },

  reloadIconButton: {
    '& svg': {
      width: '18px',
      height: '18px',
    },
  },

  modalContent: {
    padding: '54px 49px',
  },

  modalIcon: {
    display: 'flex',
    justifyContent: 'center',

    '& > img': {
      width: 'auto',
    },
  },

  modalHeading: {
    marginTop: '22px',
    fontSize: '23px',
    lineHeight: '43px',
    textAlign: 'center',
    fontWeight: '400',
  },

  modalCopyText: {
    fontSize: '14px',
    lineHeight: '22px',
    fontWeight: '400',
    color: 'rgba(0, 0, 0, 0.65)',
    textAlign: 'center',

    '& + &': {
      marginTop: '20px',
    },
  },

  modalSecondaryText: {
    marginTop: '8px',
    fontSize: '11px',
    lineHeight: '22px',
    fontWeight: '400',
    color: 'rgba(0, 0, 0, 0.45)',
    textAlign: 'center',
  },

  modalButtonContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '48px',

    '& > button + button': {
      marginLeft: '8px',
    },
  },
}
