import React from 'react'
import styles from './ContactCard.styles'
import { createUseStyles } from 'react-jss'
import { useSdk } from 'contexts/SdkProvider'
import { Button, DataPoint } from 'shared'
import { TransformedVendorContact } from 'utils/dataTransformers/transformVendorContacts'
import { text } from 'theme'
import classNames from 'classnames'

const useStyles = createUseStyles(styles)

interface ContactCardProps extends TransformedVendorContact {
  showEditContactButton: boolean
  handleEditContact: (contactId: string) => void
}

export const ContactCard = ({
  id,
  name,
  phone,
  email,
  role,
  showEditContactButton,
  handleEditContact,
}: ContactCardProps) => {
  const classes = useStyles({ editable: Boolean(id) })
  const { t } = useSdk()

  return (
    <div className={classes.cardContainer}>
      <div className={classes.cardBody}>
        <div className={classes.cardBodyGroup}>
          <DataPoint
            alignment='horizontal'
            type='default'
            name='vendor-contact-name'
            label=''
            value={name}
          />
          <DataPoint
            alignment='horizontal'
            type='default'
            name='vendor-contact-role'
            value={role}
            color={text.secondary}
            label={''}
            size={12}
          />
        </div>
        <div className={classes.cardBodyGroup}>
          <DataPoint
            alignment='horizontal'
            type='tel'
            name='vendor-contact-phone'
            label=''
            value={phone}
            decorator='tel-link'
            allowCopy
            copyEventName='COPY_VENDOR_PHONE_NUMBER'
            dialEventName='DIAL_VENDOR_PHONE_NUMBER'
          />
          <DataPoint
            alignment='horizontal'
            type='default'
            name='vendor-contact-email'
            label=''
            value={email}
            allowCopy
            copyEventName='COPY_VENDOR_EMAIL'
          />
        </div>
      </div>
      <div className={classes.actionButtonsContainer}>
        {showEditContactButton && (
          <Button
            className={classNames(classes.editButton, 'editButton')}
            onClick={() => handleEditContact(id)}
          >
            {t('Edit')}
          </Button>
        )}
      </div>
    </div>
  )
}
