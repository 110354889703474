import { useApiService } from 'hooks/useApiService'
import { useState } from 'react'
import { GetOrderPropertyFilterParams } from 'services/ordersApi/getLastOrders'
import { getLastOrdersV2, OrderApiV2Response } from 'services/ordersApi/getLastOrdersV2'
import { LinesOfBusiness } from 'types/herocare'

export interface UseLoadLastOrdersProps {
  entityId: string
  lob: LinesOfBusiness

  /**
   * search filters, make sure to memoize this before passing it here
   */
  filters: GetOrderPropertyFilterParams

  // if true, `ov_meta_data` will be included in the order data. Including the metadata increases the response time
  includeMetadata?: boolean
}

export const useLoadLastOrders = (opts: UseLoadLastOrdersProps) => {
  const { entityId, filters, includeMetadata = false } = opts
  const [data, setData] = useState<OrderApiV2Response>({
    orders: [],
    next_key: null,
  })

  const {
    data: discardedData,
    loadService,
    ...rest
  } = useApiService({
    service: getLastOrdersV2,

    params: {
      entityId,
      propertyFilters: filters,
      pageSize: 30,
      nextKey: null,
      metadata: includeMetadata,
    },

    deps: [entityId, filters],
    shouldLoad: Boolean(entityId && filters),

    onSuccess: (res) => {
      setData((current) => {
        return {
          orders: current.orders.concat(res.data.orders),
          next_key: res.data.next_key,
        }
      })
    },
  })

  return {
    lastOrders: data.orders,
    hasMorePages: Boolean(data.next_key),
    loadOrders: loadService,
    loadMorePages: () => loadService({ nextKey: data.next_key }),
    ...rest,
  }
}
