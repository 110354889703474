import React, { useMemo } from 'react'
import { Collapse } from 'antd'
import { NoDeliveryPanelHeader } from './NoDeliveryPanelHeader'
import { useDeliveryPanelStyles } from './styles'
import { DeliveryPanelBody } from './DeliveryPanelBody'
import { OrderApiResponse } from 'types/herocare'
import { OrderDeliveryConfigs } from 'types'
import { useTranslation } from 'hooks/useTranslation'
import { getOrderDeliveryStatuses } from 'utils/order/getOrderDeliveryStatuses'
import { OrderStatuses } from 'types/widgets/order/orderStatuses'

const { Panel } = Collapse

type Props = {
  order: OrderApiResponse
  config: OrderDeliveryConfigs
}

export const NoDeliveryPanel = ({ order, config }: Props) => {
  const { t } = useTranslation()
  const classes = useDeliveryPanelStyles()
  const statusHistory = getOrderDeliveryStatuses({ order })

  const message = useMemo(() => {
    const pickupDelivery = order?.delivery?.provider === 'pickup'
    const vendorDelivery = order?.delivery?.provider === 'vendor_delivery'

    const courierAcceptedDelivery = statusHistory.find(
      ({ status }) => status === OrderStatuses.courierAcceptedDelivery,
    )

    if (pickupDelivery) {
      return t('widgets.order_deliveries.deliveries.rider_info_not_available_for_pickup_order')
    }

    if (vendorDelivery) {
      return t(
        'widgets.order_deliveries.deliveries.rider_info_not_available_for_vendor_delivery_order',
      )
    }

    if (!courierAcceptedDelivery) {
      return t('widgets.order_deliveries.deliveries.no_rider_dispatched_yet')
    }
    return t('widgets.order_deliveries.deliveries.rider_info_not_available_after_6_hours')
  }, [order, statusHistory, t])

  return (
    <div className={classes.deliveries}>
      <Collapse
        defaultActiveKey={'no-delivery-panel'}
        className={classes.container}
        collapsible='disabled'
      >
        <Panel
          className='panel'
          key={'no-delivery-panel'}
          showArrow={false}
          header={<NoDeliveryPanelHeader message={message} />}
        >
          <DeliveryPanelBody order={order} config={config} />
        </Panel>
      </Collapse>
    </div>
  )
}
