import { createUseStyles } from 'react-jss'
import { primary } from 'theme'

export const useStyles = createUseStyles({
  container: {
    border: `1px solid ${primary.primary3}`,
    backgroundColor: '#E6F7FF',
    borderRadius: '4px',
    display: 'flex',
    padding: '8px 16px 8px 8px',
  },
  icon: {
    marginRight: '8px',
    color: primary.primary7,
  },
})
