export const sortAsc = <T>(
  items: T[],
  transformer: (T: T) => number = (arg) => arg as any as number,
) => {
  return items.slice(0).sort((a, b) => {
    return transformer(a) - transformer(b)
  })
}

export const sortDesc = <T>(
  items: T[],
  transformer: (T: T) => number = (arg) => arg as any as number,
) => {
  return items.slice(0).sort((a, b) => {
    return transformer(b) - transformer(a)
  })
}
