import React from 'react'
import { Typography } from 'antd'
import { createUseStyles } from 'react-jss'

import { SingleComment } from 'types/api/commentApi/getOrderComments'
import { useTranslation } from 'hooks/useTranslation'
import { commentTagType, CommentView } from 'components/Comments/CommentView'
import { getAgentNameFromId } from 'utils/string'
import styles from './Comments.styles'
import { WidgetSize } from 'types/widgets/historyOfEvents/tabs'

const { Text } = Typography

const useStyles = createUseStyles(styles)

type Props = {
  comment: SingleComment
  shouldCommentBeHighlighted: boolean
  mode: WidgetSize
}

const stateMap = {
  BUSY: 'Busy',
  CLOSED_UNTIL: 'Closed Until',
  CLOSED_TODAY: 'Closed Today',
  OPEN: 'Open',
  CLOSED: 'Closed',
}

export const UpdateVendorAvailabilityComment = ({
  comment,
  shouldCommentBeHighlighted,
  mode,
}: Props) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const additionalData = comment.additional_data?.update_vendor_availability

  const state = stateMap[additionalData?.state] ?? additionalData?.state

  return (
    <CommentView
      mode={mode}
      comment={comment}
      shouldCommentBeHighlighted={shouldCommentBeHighlighted}
      tag={{
        type: commentTagType.message,
        text: t('History of Events Widget.Event Types.Update Vendor Availability'),
      }}
    >
      <Text className={classes.commentText}>
        <Text>
          {getAgentNameFromId(comment.agent_id)}{' '}
          {t('History of Events Widget.Content.Updated availability for vendor')}{' '}
          {comment.vendor_id}{' '}
        </Text>

        {state ? (
          <>
            <Text>{t('History of Events Widget.Content.To')}: </Text>
            <Text strong>"{t(state)}" </Text>
          </>
        ) : null}

        {additionalData?.reason ? (
          <Text>
            {t('History of Events Widget.Content.With a reason')}: {additionalData?.reason}
          </Text>
        ) : null}
      </Text>
    </CommentView>
  )
}
