enum VendorDataPointIdentifiers {
  vendor_id = 'vendor_id',
  vendor_name = 'vendor_name',
  vendor_address = 'vendor_address',
  vendor_phone = 'vendor_phone',
  vendor_vertical_type = 'vendor_vertical_type',
  is_vendor_active = 'is_vendor_active',
}

export default VendorDataPointIdentifiers
