import { SessionAction, SessionActions, SessionState } from './types'

const { SET_SESSION } = SessionAction

export const sessionReducer = (sessionState: SessionState, action: SessionActions) => {
  switch (action.type) {
    case SET_SESSION:
      return {
        ...sessionState,
        ...action.payload,
      }

    default:
      return sessionState
  }
}
