import { ApiService } from 'services/authApi/getPermissions'

export type ProductToRestore = {
  line_item_uuid: string
  quantity: number
  restore_toppings?: {
    apply?: boolean
    topping_ids?: string[]
  }
}
type Metadata = {
  /**
   * email id of the agent who is restoring the item
   */
  email?: string
}

export const restoreOrderItems: ApiService<
  {
    entityId: string
    orderId: string
    productsToRestore: ProductToRestore[]
    metadata: Metadata
  },
  {
    id: string
  }
> = (createClient, options) => {
  const { clientParams, entityId, orderId, productsToRestore, metadata } = options

  const body = {
    products: productsToRestore,
    metadata,
  }

  const uri = `/OrdersAPI/v1/orders/${entityId}/${orderId}/items/restore`

  return createClient({
    endpointName: 'restoreOrderItems',
    ...clientParams,
  }).post(uri, body)
}
