import { DeliveryStatus } from '../fulfillmentApi/fulfillment'

export interface GetOrderCommentsResponse {
  comments: Array<SingleComment>
  total: number
}

export interface SingleCommentBody {
  comment: SingleComment
}

export interface SingleComment {
  id?: string
  created_at?: string
  action: SingleCommentAction
  type: SingleCommentType
  client_id?: string
  contact_id: string
  global_entity_id: string
  agent_id: string
  order_id?: string
  vendor_id?: string
  customer_id?: string
  rider_id?: string
  source?: SingleCommentSource
  additional_data?: SingleCommentAdditionalData
}

export enum SingleCommentSource {
  oneview = 'oneview',
  helpcenter = 'helpcenter',
  autocomp = 'autocomp',
}

export enum SingleCommentAction {
  system_error = 'system_error',
  reassign_delivery = 'reassign_delivery',
  create_delivery = 'create_delivery',
  execute_device_action = 'execute_device_action',
  get_device_remote_control_link = 'get_device_remote_control_link',
  report_missing_items = 'report_missing_items',
  compensation = 'compensation',
  voucher_refund = 'voucher_refund',
  payment_refund = 'payment_refund',
  refund_request = 'refund_request',
  ticket_refund = 'ticket_refund',
  payment_compensation = 'payment_compensation',
  cancel_order = 'cancel_order',
  remove_items = 'remove_items',
  modify_delivery_address = 'modify_delivery_address',
  modify_delivery_coordinates = 'modify_delivery_coordinates',
  modify_delivery_instructions = 'modify_delivery_instructions',
  modify_cooking_instructions = 'modify_cooking_instructions',
  modify_promised_delivery_time = 'modify_promised_delivery_time',
  modify_callback = 'modify_callback',
  manual_comment = 'manual_comment',
  dispatcher_note = 'dispatcher_note',
  update_vendor_schedule = 'update_vendor_schedule',
  update_vendor_special_schedule = 'update_vendor_special_schedule',
  update_vendor_availability = 'update_vendor_availability',
  update_order_flag = 'update_order_flag',
  start_rider_break = 'start_rider_break',
  end_rider_break = 'end_rider_break',
  self_service_compensation = 'self_service_compensation',
  self_service_refund = 'self_service_refund',
  delivery_status_update = 'delivery_status_update',
  set_primary_delivery = 'set_primary_delivery',
  restore_order_items = 'restore_order_items',
}

export enum SingleCommentType {
  automated = 'automated',
  manual = 'manual',
}

export interface SingleCommentAdditionalData {
  ticket_refund_request?: AdditionalDataTicketRefund
  report_missing_items?: AdditionalDataReportMissingItems
  compensation?: AdditionalDataCompensation
  voucher_refund?: AdditionalDataVoucherRefund
  payment_refund?: AdditionalDataPaymentRefund
  cancel_order?: AdditionalDataCancelOrder
  modify_delivery_address?: AdditionalModifyDeliveryAddress
  modify_delivery_instructions?: AdditionalModifyDeliveryInstructions
  modify_callback?: AdditionalModifyModifyCallback
  manual_comment?: AdditionalNote
  modify_cooking_instructions?: AdditionalModifyCookingInstructions
  modify_promised_delivery_time?: AdditionalModifyPromisedDeliveryTime
  payment_compensation?: AdditionalPaymentCompensation
  remove_items?: AdditionalRemoveItems
  restore_order_items?: AdditionalRestoreOrderItems
  refund_request?: AdditionalDataRefundRequest
  dispatcher_note?: AdditionalNote
  reassign_delivery?: AdditionalDataReassignDelivery
  create_delivery?: AdditionalDataCreateDelivery
  update_vendor_availability?: AdditionalDataUpdateVendorAvailability
  update_vendor_schedule?: AdditionalDataUpdateVendorSchedule
  update_vendor_special_schedule?: AdditionalDataUpdateVendorSpecialSchedule
  update_order_flag?: AdditionalDataUpdateOrderFlag
  execute_device_action?: AdditionalDataExecuteDeviceAction
  get_device_remote_control_link?: AdditionalDataGetDeviceRemoteControlAction
  delivery_status_update?: AdditionalDataChangeDeliveryStatusAction
  set_primary_delivery?: AdditionalDataSetPrimaryDeliveryAction
  start_rider_break?: AdditionalDataStartRiderBreakAction

  self_service_refund?: SingleCommentSelfServiceRefundOrCompensation
  self_service_compensation?: SingleCommentSelfServiceRefundOrCompensation
}

export interface AdditionalDataReassignDelivery {
  reason: string
  delivery_id: string
}

export interface AdditionalDataCreateDelivery {
  reason: string
}

export interface AdditionalDataRefundRequest {
  contact_reason: string
  refund_amount: number
  refund_currency: string
  refund_id: string
  refund_purpose: string
  refund_items?: string[]
  removed_items: SingleRemovedItem[]
}

export interface AdditionalDataReportMissingItems {
  contact_reason?: string
  sf_case_id: string
  missing_items: Array<string>
  removed_items?: SingleRemovedItem[]
}

interface AdditionalDataCompensation {
  compensation_purpose: string
  voucher_code: string
  voucher_value: string
  voucher_currency: string
}

export interface AdditionalDataVoucherRefund {
  contact_reason?: string
  refund_purpose: string
  voucher_code: string
  voucher_value: string
  voucher_currency: string
  included_compensation: number
  removed_items?: SingleRemovedItem[]
  refund_items?: string[]
}

export interface AdditionalDataPaymentRefund {
  contact_reason?: string
  refund_amount: number
  refund_currency: string
  refund_target: string
  refund_purpose: string
  removed_items?: SingleRemovedItem[]
  refund_items?: string[]
}

export interface AdditionalDataTicketRefund {
  contact_reason?: string
  payment_medium: string
  refund_amount: number
  refund_currency: string
  refund_notes: string
  refund_purpose: string
  removed_items?: SingleRemovedItem[]
  refund_items?: string[]
  ticket_id: string
  ticket_platform: string
}

interface AdditionalDataCancelOrder {
  reason: string
}

interface AdditionalModifyDeliveryAddress {
  update_request_id: string
  old: OldAndNewDeliveryAddress
  new: OldAndNewDeliveryAddress
}

interface AdditionalModifyDeliveryInstructions {
  update_request_id: string
  old: string
  new: string
}

interface AdditionalModifyModifyCallback {
  update_request_id: string
  status: ModifyCallBackStatuses
}

interface AdditionalNote {
  text: string
}

interface AdditionalModifyCookingInstructions {
  new: NewCookingInstruction[]
}

export interface NewCookingInstruction {
  id: string
  comment: string
  line_item_id?: string
  name?: string
}

interface AdditionalModifyPromisedDeliveryTime {
  new: string
  old: string
  update_request_id: string
}

interface AdditionalPaymentCompensation {
  compensation_amount: number
  compensation_currency: string
  compensation_purpose: string
  compensation_target: string
}

interface AdditionalRemoveItems {
  contact_reason?: string
  removed_items?: SingleRemovedItem[]
}

interface SingleRestoredItem {
  line_item_id: string
  quantity: number
  restore_toppings?: {
    apply?: boolean
    topping_ids?: string[]
  }
}

interface AdditionalRestoreOrderItems {
  items?: SingleRestoredItem[]
}

export interface SingleRemovedItem {
  id: string
  initial_quantity: number
  line_item_id: string
  name: string
  removed_quantity: number
  partial_item_issue?: PartialItemIssue
  removed_toppings: RemovedItemTopping[]
  initial_toppings?: RemovedItemTopping[]
}

export interface PartialItemIssue {
  value: number
  quantity: number
  description: string
}

export interface RemovedItemTopping {
  id: string
  name: string
  initial_quantity: number
  removed_quantity: number
}

interface OldAndNewDeliveryAddress {
  company: string
  street: string
  number: string
  block: string
  building: string
  apartment: string
  entrance: string
  intercom: string
  floor: string
  formatted_address: string
  suburb: string
  zipcode: string
  latitude: string
  longitude: string
  instructions: string
}

export interface AdditionalDataUpdateVendorAvailability {
  state: string
  reason: string
}

export interface AdditionalDataUpdateVendorSchedule {
  calendar_id: string
  calendar_name: string
  reason: string
}

export interface AdditionalDataUpdateVendorSpecialSchedule {
  reason: string
  action: string
  special_schedule_id: string
  special_schedule_name: string
}

export interface AdditionalDataUpdateOrderFlag {
  reason: string
  is_billable: boolean
  is_wastage: boolean
  is_manual: boolean
  is_receiptable: boolean
}

export interface AdditionalDataExecuteDeviceAction {
  action: 'RESTART' | 'RESET' | 'INSTALL' | 'REMOTE_CONTROL'
  device_id: string
}

export interface AdditionalDataGetDeviceRemoteControlAction {
  device_id: string
}

export interface AdditionalDataChangeDeliveryStatusAction {
  reason: string
  to_status: Extract<DeliveryStatus, 'queued' | 'picked_up' | 'completed'>
  from_status: Omit<DeliveryStatus, 'completed'>
}
export interface AdditionalDataSetPrimaryDeliveryAction {
  old_rider_id: string
  new_rider_id: string
}

export interface AdditionalDataStartRiderBreakAction {
  duration: number
  reason: string
  type: 'break' | 'tmp'
  comment: string
}

export enum ModifyCallBackStatuses {
  CONFIRMED = 'CONFIRMED',
  FAILED = 'FAILED',
  MODIFIED = 'MODIFIED',
  REQUESTED = 'REQUESTED',
}

export interface GetVendorCommentsResponse {
  comments: Array<SingleComment>
  total: number
}

export type SelfServiceRefundOrCompensationResponseType =
  | 'reactive_compensation_recommendation'
  | 'voucher_created'
  | 'added_to_wallet'
  | 'online_payment_refunded'
  | 'unknonwn'
  | 'route_to_chat'
  | 'case_created'
  | 'case_already_created'
  | 'voucher_already_created'
  | 'voucher_creation_failed_case_created'
  | 'wallet_creation_failed_case_created'
  | 'route_to_chat'
  | 'already_routed_to_chat'
  | 'fraud_case_created'
  | 'fraud_check_block'
  | 'max_amount_exceed'
  | 'max_amount_exceed_case_created'
  | 'order_already_refunded_external'
  | 'order_already_compensated_external'
  | 'already_added_to_wallet'
  | 'already_refunded_automatically'
  | 'auto_partial_refund_not_possible'
  | 'no_recommendation'
  | 'wallet_creation_failed'
  | 'voucher_creation_failed'
export interface SingleCommentSelfServiceRefundOrCompensation {
  global_entity_id: string
  timestamp: string
  event_type: string
  user?: {
    id: string
  }
  order?: {
    id: string
    status: string
    timestamp: string
  }
  contact?: {
    id: string
    details:
      | 'ChatSalesforce'
      | 'ReactiveCompensation'
      | 'ProactiveCompensation'
      | 'AdditionalCompensation'
      | 'autoPartialRefund'
  }

  product: {
    name: 'oneview' | 'helpcenter' | 'autocomp'
    version: string
  }

  event: {
    id: string
    name: 'REFUND_BACKEND' | 'COMPENSATION_BACKEND'

    details: {
      /**
       * reason code, issue type
       */
      reason_code: string
      /**
       * user provided description for action
       */
      user_description: string
      /**
       * image provided by customer as proof of claim
       */
      user_image_url: string

      refund_method: 'voucher' | 'online_payment' | 'auto' | 'wallet'

      refund_items: Object

      response_status_code?: string

      response_type: SelfServiceRefundOrCompensationResponseType

      flags?: {
        initiated_by_customer: boolean
        customer_reported_damaged_item?: boolean
        customer_reported_missing_item?: boolean
      }

      fallback?: {
        type: 'CASE' | 'CHAT' | 'NO_FALLBACK'
        reason:
          | 'voucher_creation_failed'
          | 'bad_request'
          | 'wallet_creation_failed'
          | 'automatic_partial_refund_failed'
          | 'online_payment_failed'
          | 'user_fraudulent'
          | 'max_amount_exceed'
          | 'cts_fetch_incentives_failed'
          | 'autocomp_fetch_fraud_check_failed'
      }

      cases?: Partial<
        Record<
          'finance_case' | 'wastage_case' | 'agent_case',
          {
            id?: string
          }
        >
      >

      related_ids?: Record<
        | 'finance_case_id'
        | 'wastage_case_id'
        | 'agent_case_id'
        | 'transaction_id'
        | 'helpcenter_session_id'
        | 'fraud_recommendation_id'
        | 'compensation_recommendation_id'
        | 'refund_event_id'
        | 'compensation_event_id',
        string
      >
    }
  }

  financial?: {
    id: string

    payment_medium: 'voucher' | 'online_payment' | 'wallet' | 'auto'

    currency_code: string

    compensation_value: number

    refund_value: number

    processed_timestamp: string

    details?: {
      is_pending?: boolean

      refund_items_counts?: number

      is_full_refund?: boolean

      is_item_removed?: boolean

      refund_purpose?: string

      compensation_purpose?: string

      compensation_category?: string

      compensation_type?: string

      compensation_value?: number

      min_order_value?: number

      max_order_value?: number

      max_discount_amount?: number

      vouchers?: Partial<
        Record<
          'refund' | 'compensation',
          {
            id: string
            type: string
            code: string
            value: number
            processed_timestamp?: string
            validity_start_time: string
            validity_end_time: string
          }
        >
      >
    }
  }
}
