import { DataContext } from 'contexts/data/DataContext'
import { DataAction } from 'contexts/data/types'
import { SessionContext } from 'contexts/session/SessionContext'
import { useApiService } from 'hooks/useApiService'
import { useContext } from 'react'
import { getVendor } from 'services/vendorApi/getVendor'

const { SET_RIDER_CURRENT_ORDER_VENDOR } = DataAction

/**
 * loads rider current order vendor
 */
export function useLoadRiderCurrentOrderVendor() {
  const {
    sessionState: { globalEntityId, riderCurrentOrderVendorId },
  } = useContext(SessionContext)

  const { dataDispatch } = useContext(DataContext)

  const riderCurrentOrderVendorInit = useApiService({
    service: getVendor,

    params: {
      entityId: globalEntityId,
      vendorId: riderCurrentOrderVendorId,
      clientParams: {
        cacheable: true,
      },
    },
    shouldLoad: Boolean(globalEntityId && riderCurrentOrderVendorId),
    onSuccess(res) {
      dataDispatch({
        type: SET_RIDER_CURRENT_ORDER_VENDOR,
        payload: { riderCurrentOrderVendor: res.data },
      })
    },
  })

  return riderCurrentOrderVendorInit
}
