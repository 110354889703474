import React from 'react'
import { Typography } from 'antd'
import { createUseStyles } from 'react-jss'
import { useTranslation } from 'hooks/useTranslation'
import { SingleComment } from 'types/api/commentApi/getOrderComments'
import styles from './Comments.styles'
import { CancellationReason } from '../../types/widgets/historyOfEvents/cancelActionComment'
import { CancelOrderActionConfig } from '../../contexts/entity/types'
import { commentTagType, CommentView } from './CommentView'
import { WidgetSize } from 'types/widgets/historyOfEvents/tabs'

const { Text } = Typography

const useStyles = createUseStyles(styles)

type Props = {
  comment: SingleComment
  cancel_order: CancelOrderActionConfig
  shouldCommentBeHighlighted: boolean
  mode: WidgetSize
}

const CancelOrderComment = ({ comment, cancel_order, shouldCommentBeHighlighted, mode }: Props) => {
  const classes = useStyles()
  const { t } = useTranslation()

  let cancellationReasonToDisplay = comment.additional_data.cancel_order.reason

  // check if entity wants to display the reason returned by API
  // useReasonToDisplayCancelComment being truthy means entity wants display reason to be pulled from config
  if (cancel_order?.useReasonToDisplayCancelComment) {
    // find matching id and update cancellationReasonToDisplay
    const reasonFound = cancel_order.availableCancellationReasons.find(
      (reason: CancellationReason) => reason.reason === comment.additional_data.cancel_order.reason,
    )

    if (reasonFound) {
      cancellationReasonToDisplay = reasonFound.translation_key
    }
  }

  return (
    <CommentView
      mode={mode}
      comment={comment}
      shouldCommentBeHighlighted={shouldCommentBeHighlighted}
      tag={{
        type: commentTagType.thunderbolt,
        text: t('History of Events Widget.Event Types.Cancel Order'),
      }}
    >
      <Text className={classes.commentText}>
        <Text>
          {` ${t(
            'History of Events Widget.Content.Cancelled order due to',
          )} ${cancellationReasonToDisplay.replace(/_/g, ' ').toLowerCase()}.`}
        </Text>
      </Text>
    </CommentView>
  )
}

export default CancelOrderComment
