import { FulfillmentApiResponse } from 'types/api/fulfillmentApi/fulfillment'
import { RiderStatuses } from 'types/widgets/rider/riderStatuses'
import { logError } from 'utils/reporting/logError'

export const logUnknowRiderError = (response: FulfillmentApiResponse) => {
  const deliveries = response?.deliveries
  if (!deliveries?.length) {
    return
  }
  deliveries.forEach((delivery) => {
    delivery.courier_events?.forEach((event) => {
      if (
        !Object.values(RiderStatuses).includes(event.name) ||
        event.name === RiderStatuses.unknown
      ) {
        logError({
          type: 'unknown-rider-status',
          status: event.name,
        })
      }
    })
  })
}
