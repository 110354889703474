import { gray, white } from 'theme'

export default {
  container: {
    backgroundColor: gray.gray4,
    margin: '0px auto',
    padding: '0px 10px 10px 10px',
    position: 'relative',
  },

  contentWrapper: {
    backgroundColor: white,
    padding: '16px',
  },

  sticky: {
    position: 'sticky',
    top: 0,
    zIndex: 10,
    backgroundColor: gray.gray4,
  },

  resolutionBar: {
    composes: '$contentWrapper',
    padding: '12px 16px',
    borderBottom: `1px solid ${gray.gray4}`,
  },

  summaryPanel: {
    composes: '$contentWrapper',
    minHeight: 160,
    borderRadius: '0px 0px 6px 6px',
  },

  tabsPane: {
    composes: '$contentWrapper',
    minHeight: 'calc(100vh - 220px)',
    padding: '0px 16px',
  },
}
