export default {
  container: {
    display: 'flex',
  },
  tooltipDivision: {
    display: 'flex',
    flexDirection: 'column',
    padding: '3px',
  },
  tooltipTitle: {
    color: '#f6f6f6',
    fontSize: '12px',
    marginBottom: '5px',
  },
  tooltipValue: {
    color: '#fff',
    fontSize: '13px',
  },
  icon: {
    position: 'absolute',
    bottom: 14,
    left: -20,
    height: '38px',
  },
  point: {
    position: 'absolute',
    bottom: -6,
    left: -10,
    height: '18px',
  },
}
