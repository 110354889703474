import { datadogRum } from '@datadog/browser-rum'
import { useReadSessionState } from 'hooks/useSessionState'
import { useCallback } from 'react'
import { useSendEventToEvts } from './useSendEventToEvts'
import { usePrepareEventScreenPayload } from './usePrepareEventScreenPayload'
import { readAuthPayload } from 'utils/authHelpers'
import { effectiveLob } from 'constants/lineOfBusiness'

export interface UseCaptureUserActionOpts {
  reportToEts?: boolean
  screenDetails?: Record<string, any>
  eventDetails?: Record<string, any>
}

export const useCaptureUserAction = () => {
  const readSessionState = useReadSessionState()
  const sendEventToEvts = useSendEventToEvts()

  const prepareEventScreenPayload = usePrepareEventScreenPayload()

  return useCallback(
    async (metricName: string, opts: UseCaptureUserActionOpts = {}) => {
      const { agentEmail } = readAuthPayload()
      const { globalEntityId, orderId, caseId, lineOfBusiness, uiVersion, platform, host } =
        readSessionState()

      const { reportToEts = true, screenDetails, eventDetails = {} } = opts

      try {
        // report to datadog
        datadogRum.addAction(metricName, {
          globalEntityId,
          orderId,
          caseId,
          platform,
          agentEmail,
          effectiveLob: effectiveLob[lineOfBusiness],
          effectiveUiVersion: uiVersion,
          effectiveHost: host ?? 'unknown',
          ...eventDetails,
          screen: prepareEventScreenPayload(screenDetails),
        })

        if (reportToEts) {
          sendEventToEvts(metricName, opts)
        }
      } catch (ex) {
        console.log(ex)
      }
    },
    [readSessionState, sendEventToEvts, prepareEventScreenPayload],
  )
}
