// libs
import React, { useState, useEffect, useContext } from 'react'
// configs
import { NewCommentFieldValues } from 'entityConfig/allowedConfigValues'
// context and types
import { RiderServiceContext } from 'contexts/riderService/RiderServiceContext'
import { RiderServiceAction } from 'contexts/riderService/types'
import { EntityContext } from 'contexts/entity/EntityContext'
import { SessionContext } from 'contexts/session/SessionContext'
import { AgentPlatform } from 'types/session/platforms'
// hooks
import { createClient } from 'hooks/useFetchData'
import useRefreshPermissions from 'hooks/useRefreshPermissions'
import { useTranslation } from 'hooks/useTranslation'
// utils
import environment from 'envConfig'
import createOneviewHeaders from 'utils/oneviewApi/createOneviewHeaders'
import processApiErrors from 'utils/oneviewApi/processApiErrors'
import ensureJwtIsValid from 'utils/oneviewApi/ensureJwtIsValid'
// styles
import { createUseStyles } from 'react-jss'
import styles from './NewComment.styles'
import { Button, Typography, Input, Checkbox } from 'antd'
import { CloseOutlined } from '@ant-design/icons'
// component
import LoadingView from 'components/LoadingView'
import MiniErrorView from 'components/MiniErrorView'
// assets
import orderErrorIcon from 'assets/error/orderError.png'
import { readAuthPayload } from 'utils/authHelpers'

const useStyles = createUseStyles(styles)

const NewComment: React.FC = () => {
  const classes = useStyles()
  const { Text } = Typography
  const { TextArea } = Input

  // pull language content
  const { t } = useTranslation()

  // pull actions config from context
  const {
    entityState: {
      entityConfig: {
        fixed_panel_config: {
          widgets_configs: {
            actions: { new_comment_config },
          },
        },
      },
    },
  } = useContext(EntityContext)

  // pull rider context
  const { riderServiceState, riderServiceDispatch } = useContext(RiderServiceContext)
  const { SET_IS_NEW_COMMENT, SET_NEW_COMMENT_ID } = RiderServiceAction

  // pull the session
  const {
    sessionState: { orderId, globalEntityId, caseId, platform },
  } = useContext(SessionContext)

  const [isTokenExpired] = useState(false)
  const { isRefreshTokenExpired } = useRefreshPermissions([isTokenExpired])
  useEffect(() => {
    if (isRefreshTokenExpired) {
      window.location.reload()
    }
  }, [isRefreshTokenExpired])

  const [newComment, setNewComment] = useState('')

  const [isOrderChecked, setIsOrderChecked] = useState(false)
  const [showOrderCheckbox, setShowOrderCheckbox] = useState(false)

  const [isBackOfficeChecked, setIsBackOfficeChecked] = useState(false)
  const [showBackOfficeCheckbox, setShowBackOfficeCheckbox] = useState(false)

  const [isCustomerChecked, setIsCustomerChecked] = useState(false)
  const [showCustomerCheckbox, setShowCustomerCheckbox] = useState(false)

  const [isVendorChecked, setIsVendorChecked] = useState(false)
  const [showVendorCheckbox, setShowVendorCheckbox] = useState(false)

  const [isRiderChecked, setIsRiderChecked] = useState(false)
  const [showRiderCheckbox, setShowRiderCheckbox] = useState(false)

  const [agentId, setAgentId] = useState('')
  const [customerId, setCustomerId] = useState('')
  const [vendorId, setVendorId] = useState('')
  const [riderId, setRiderId] = useState('')

  // figure out how checkboxes should behave for the entity and set relevant IDs
  useEffect(() => {
    // receives the checkbox and its corresponded local state updaters -> checks entity config and updates local state accordingly
    const setCheckboxAvailability = (
      checkbox: string,
      isChecked: (arg: boolean) => void,
      isShow: (arg: boolean) => void,
    ) => {
      if (checkbox === NewCommentFieldValues.showChecked) {
        isChecked(true)
        isShow(true)
      } else if (checkbox === NewCommentFieldValues.showUnchecked) {
        isChecked(false)
        isShow(true)
      } else if (checkbox === NewCommentFieldValues.noShowLinked) {
        isChecked(true)
        isShow(false)
      } else if (checkbox === NewCommentFieldValues.noShowUnlinked) {
        isChecked(false)
        isShow(false)
      }
    }

    const { agentEmail } = readAuthPayload()
    // set agent email from browser
    if (agentEmail) {
      setAgentId(agentEmail)
    }

    // call setCheckboxAvailability for each checkbox and pass to it the relevant local state updaters, if relevant ID is found
    if (orderId) {
      setCheckboxAvailability(new_comment_config.order, setIsOrderChecked, setShowOrderCheckbox)

      setCheckboxAvailability(
        new_comment_config.back_office,
        setIsBackOfficeChecked,
        setShowBackOfficeCheckbox,
      )

      if (riderServiceState?.customer?.customerId) {
        setCustomerId(riderServiceState.customer.customerId)
        setCheckboxAvailability(
          new_comment_config.customer,
          setIsCustomerChecked,
          setShowCustomerCheckbox,
        )
      }

      if (riderServiceState?.vendor?.vendorId) {
        setVendorId(riderServiceState.vendor.vendorId)
        setCheckboxAvailability(
          new_comment_config.vendor,
          setIsVendorChecked,
          setShowVendorCheckbox,
        )
      }

      if (riderServiceState?.rider?.id) {
        setRiderId(riderServiceState?.rider?.id)
        setCheckboxAvailability(new_comment_config.rider, setIsRiderChecked, setShowRiderCheckbox)
      }
    } else if (riderServiceState.rider) {
      // if no order found but there is rider:
      setRiderId(riderServiceState?.rider?.id)

      // determine which config to be used and set rider comment availability through setCheckboxAvailability
      let riderCommentLinkConfigAccToPlatform = NewCommentFieldValues.noShowLinked
      if (platform === AgentPlatform.peya || platform === AgentPlatform.mena) {
        riderCommentLinkConfigAccToPlatform = NewCommentFieldValues.showUnchecked
      }

      setCheckboxAvailability(
        riderCommentLinkConfigAccToPlatform,
        setIsRiderChecked,
        setShowRiderCheckbox,
      )

      setCheckboxAvailability(
        NewCommentFieldValues.noShowUnlinked,
        setIsBackOfficeChecked,
        setShowBackOfficeCheckbox,
      )
    }
  }, [riderServiceState, new_comment_config, globalEntityId, orderId, platform])

  const handleActionClose = () => {
    setIsOrderChecked(false)
    setShowOrderCheckbox(false)
    setIsCustomerChecked(false)
    setShowCustomerCheckbox(false)
    setIsVendorChecked(false)
    setShowVendorCheckbox(false)
    setIsRiderChecked(false)
    setShowRiderCheckbox(false)
    setAgentId('')
    setCustomerId('')
    setVendorId('')
    setRiderId('')
    setNewComment('')
    setNewCommentError(null)

    riderServiceDispatch({
      type: SET_IS_NEW_COMMENT,
      payload: { isNewComment: false },
    })
  }

  // fired when new comment save clicked -> posts the new comment
  const [isPostingComment, setIsPostingComment] = useState(false)
  const [newCommentError, setNewCommentError] = useState(null)
  const handleNewCommentSaveClick = async () => {
    setIsPostingComment(true)
    // ensure JWT is valid before firing API client
    const isTokensValid = await ensureJwtIsValid(caseId)

    const postNewCommentAsync = async () => {
      const headers = createOneviewHeaders()
      const uri = `${environment().oneviewApiRoot}${
        environment().oneviewCommentApiRoot
      }/comment/${globalEntityId}`

      // post body CORE
      const body: any = {
        comment: {
          action: 'manual_comment',
          type: 'manual',
          contact_id: caseId,
          global_entity_id: globalEntityId,
          agent_id: agentId,
          additional_data: {
            manual_comment: {
              text: newComment,
            },
          },
        },
      }

      if (isOrderChecked && orderId) {
        body.comment.order_id = orderId
      }

      if (isCustomerChecked && customerId) {
        body.comment.customer_id = customerId
      }

      if (isVendorChecked && vendorId) {
        body.comment.vendor_id = vendorId
      }

      if (isRiderChecked && riderId) {
        body.comment.rider_id = riderId.toString()
      }

      await createClient()
        .post(uri, body, headers)
        .then((value) => {
          if (value && value.status === 201) {
            riderServiceDispatch({
              type: SET_NEW_COMMENT_ID,
              payload: { newCommentId: value.data.comment.id },
            })
          }
        })
        .catch((err) => {
          const errEntry = processApiErrors(t, err, 'CreateComment', orderId, globalEntityId, true)
          setNewCommentError(errEntry)
        })
        .finally(() => {
          riderServiceDispatch({
            type: SET_IS_NEW_COMMENT,
            payload: { isNewComment: false },
          })
          setIsPostingComment(false)
        })
    }

    // if current token valid, post items
    if (isTokensValid) {
      postNewCommentAsync()
    }
  }

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <Text className={classes.title}>{t('Actions Widget.Actions.New Comment.Title')}</Text>
        <CloseOutlined className={classes.closeIcon} onClick={handleActionClose} />
      </div>

      {isPostingComment && <LoadingView text={`${t('Interface.Please Wait')}...`} />}

      {newCommentError && !isPostingComment && (
        <React.Fragment>
          <MiniErrorView
            icon={orderErrorIcon}
            errorMessage={`${t(
              'Actions Widget.Actions.New Comment.Failed to create a new comment',
            )}!`}
          />
          <div className={classes.buttonHolder}>
            <Button type='primary' onClick={handleNewCommentSaveClick}>
              {t('Interface.Go Back')}
            </Button>
          </div>
        </React.Fragment>
      )}

      {!newCommentError && !isPostingComment && (
        <React.Fragment>
          <div className={classes.commentBody}>
            <Text className={classes.commentTitle}>
              {`${t('Actions Widget.Actions.New Comment.Comment')}:`}
            </Text>
            <TextArea
              className={classes.textArea}
              placeholder={t('Actions Widget.Actions.New Comment.Title')}
              autoSize={{ minRows: 6, maxRows: 6 }}
              value={newComment}
              onChange={(e) => setNewComment(e.target.value)}
            />
          </div>

          {!showOrderCheckbox &&
          !showCustomerCheckbox &&
          !showVendorCheckbox &&
          !showRiderCheckbox &&
          !showBackOfficeCheckbox &&
          (isOrderChecked ||
            isCustomerChecked ||
            isVendorChecked ||
            isRiderChecked ||
            isBackOfficeChecked) ? (
            <div className={classes.explanationHolder}>
              <Text className={classes.explanationForApac}>
                {`${t(
                  'Actions Widget.Actions.New Comment.This comment will be displayed under the following sections',
                )}:`}
                {isOrderChecked && ` ${t('Order Widget.Order')}`}

                {isCustomerChecked && isOrderChecked
                  ? `, ${t('Customer Widget.Customer')}`
                  : isCustomerChecked
                  ? ` ${t('Customer Widget.Customer')}`
                  : null}

                {isVendorChecked && (isOrderChecked || isCustomerChecked)
                  ? `, ${t('Vendor Widget.Vendor')}`
                  : isVendorChecked
                  ? ` ${t('Vendor Widget.Vendor')}`
                  : null}

                {isRiderChecked && (isOrderChecked || isCustomerChecked || isVendorChecked)
                  ? `, ${t('Rider Widget.Rider')}`
                  : isRiderChecked
                  ? ` ${t('Rider Widget.Rider')}`
                  : null}

                {isBackOfficeChecked && (isOrderChecked || isCustomerChecked || isVendorChecked)
                  ? `, ${t('History of Events Widget.Back Office')}`
                  : null}
              </Text>
            </div>
          ) : !orderId ? (
            <div className={classes.explanationHolder}>
              <Text className={classes.explanationForApac}>
                {`${t(
                  'Actions Widget.Actions.New Comment.This comment will be displayed under the following sections',
                )}: ${t('Customer Widget.Customer')}`}
              </Text>
            </div>
          ) : null}

          {(showOrderCheckbox && orderId) ||
          (showCustomerCheckbox && customerId) ||
          (showVendorCheckbox && vendorId) ||
          (showRiderCheckbox && riderId) ||
          showBackOfficeCheckbox ? (
            <div className={classes.checkboxesFornonApacHolder}>
              {/* SELECT MESSAGE -> display it if at least 1 checkbox is available */}
              <Text className={classes.explanationForNonApac}>
                {`${t(
                  'Actions Widget.Actions.New Comment.Select where this comment should be displayed',
                )}:`}
              </Text>

              {/* checkbox for ORDER */}
              {showOrderCheckbox ? (
                <div className={classes.checkboxesContainer}>
                  <Checkbox
                    onChange={(e) => setIsOrderChecked(e.target.checked)}
                    checked={isOrderChecked}
                  >
                    {`${t('Order Widget.Order')}: `}
                    <Text className={classes.checkboxIds}>{orderId}</Text>
                  </Checkbox>
                </div>
              ) : null}

              {/* checkbox for CUSTOMER */}
              {showCustomerCheckbox ? (
                <div className={classes.checkboxesContainer}>
                  <Checkbox
                    onChange={(e) => setIsCustomerChecked(e.target.checked)}
                    checked={isCustomerChecked}
                  >
                    {`${t('Customer Widget.Customer')}: `}
                    <Text className={classes.checkboxIds}>{customerId}</Text>
                  </Checkbox>
                </div>
              ) : null}

              {/* checkbox for VENDOR */}
              {showVendorCheckbox ? (
                <div className={classes.checkboxesContainer}>
                  <Checkbox
                    onChange={(e) => setIsVendorChecked(e.target.checked)}
                    checked={isVendorChecked}
                  >
                    {`${t('Vendor Widget.Vendor')}: `}
                    <Text className={classes.checkboxIds}>{vendorId}</Text>
                  </Checkbox>
                </div>
              ) : null}

              {/* checkbox for RIDER */}
              {showRiderCheckbox ? (
                <div className={classes.checkboxesContainer}>
                  <Checkbox
                    onChange={(e) => setIsRiderChecked(e.target.checked)}
                    checked={isRiderChecked}
                  >
                    {`${t('Rider Widget.Rider')}: `}
                    <Text className={classes.checkboxIds}>{riderId}</Text>
                  </Checkbox>
                </div>
              ) : null}

              {/* checkbox for BackOffice */}
              {showBackOfficeCheckbox ? (
                <div className={classes.checkboxesContainer}>
                  <Checkbox
                    onChange={(e) => setIsBackOfficeChecked(e.target.checked)}
                    checked={isBackOfficeChecked}
                  >
                    {`${t('History of Events Widget.Back Office')}`}
                  </Checkbox>
                </div>
              ) : null}
            </div>
          ) : null}

          <div className={classes.saveButtonHolder}>
            <Button
              type='primary'
              onClick={handleNewCommentSaveClick}
              disabled={
                !newComment ||
                ((!isOrderChecked || !orderId) &&
                  (!isCustomerChecked || !customerId) &&
                  (!isVendorChecked || !vendorId) &&
                  (!isRiderChecked || !riderId))
              }
            >
              {t('Interface.Save')}
            </Button>
          </div>
        </React.Fragment>
      )}
    </div>
  )
}

export default NewComment
