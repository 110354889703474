import moment from 'moment'
import { VendorInvoice, VendorInvoiceFileType } from 'services/vendorApi/getVendorInvoices'

type InvoiceItemType = 'summary' | 'invoice'

type InvoiceFileType = 'pdf' | 'xls' | 'xlsx'

export type InvoiceItem = {
  name: string
  date: string
  fileName: string
  fileFormat: InvoiceFileType
  type: InvoiceItemType
  generatedAt: string
}
export function transformVendorInvoices(rawInvoices: VendorInvoice[] = []): InvoiceItem[] {
  return rawInvoices?.flatMap((invoice) => {
    const items: InvoiceItem[] = Object.entries(invoice.files).map(
      ([type, fileName]: [VendorInvoiceFileType, string]) => ({
        name: invoice.id,
        // TODO: Localize formatted dates
        date: moment(invoice.generated_at).format('DD.MM.YYYY'),
        generatedAt: invoice.generated_at,
        fileName: fileName,
        fileFormat: type,

        // We assume all PDF files are Summary files and xls(x) files are Detailed invoices
        type: type === 'pdf' ? 'summary' : 'invoice',
      }),
    )

    return items
  })
}
