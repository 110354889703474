/**
 * Profile Tab
 * makes a call to customerAPI and renders relevant customer info dependant on the config
 * */

// libs
import React, { useContext } from 'react'
// contexts and types
import { DataContext } from 'contexts/data/DataContext'
import { DataAction } from 'contexts/data/types'
import { CustomerWidgetProfile } from 'contexts/entity/types'
// hooks
import { useTranslation } from 'hooks/useTranslation'

// components
import ProfileView from './ProfileView/ProfileView'
import { useApiService } from 'hooks/useApiService'
import { getCustomerProfile } from 'services/customerApi/getCustomerProfile'
import { WidgetErrorHandler } from 'components/WidgetErrorHandler/widgetErrorHandler'
import { PluggableWidget } from 'types/unitedUiConfig'
import { createPluggableWidget } from 'factory/createPluggableWidget'

const Profile: PluggableWidget<CustomerWidgetProfile> = createPluggableWidget(
  ({ config, customerId, globalEntityId, caseId, containerType }) => {
    // pull language content
    const { t } = useTranslation()

    // pull data state and dispatch from data context
    const { dataDispatch } = useContext(DataContext)
    const { SET_CUSTOMER_PROFILE } = DataAction

    const hasCaseId = Boolean(caseId)
    const hasCustomer = Boolean(customerId)

    const {
      loading: isLoading,
      error,
      loadService: refresh,
      data,
    } = useApiService({
      service: getCustomerProfile,
      params: {
        customerId,
        entityId: globalEntityId,
      },
      shouldLoad: hasCaseId && hasCustomer,
      onSuccess(res) {
        // this dispatch is kept for compatibility with old layout
        dataDispatch({
          type: SET_CUSTOMER_PROFILE,
          payload: { customerProfile: res.data },
        })
      },
    })

    return (
      <WidgetErrorHandler
        loading={isLoading}
        errorPayload={error?.errorPayload}
        loadingText={t('Interface.Loading Customer Profile')}
        onRetry={refresh}
        missingParameters={[!hasCustomer && 'customerId', !hasCaseId && 'caseId']}
      >
        {() => (
          <ProfileView config={config} customerProfile={data} widgetContainerType={containerType} />
        )}
      </WidgetErrorHandler>
    )
  },

  {
    category: 'data_lookup',
    deriveConfig({ entityConfig }) {
      return entityConfig.scrollable_panel_config.widgets_configs.customer.tab_configs.profile
    },
    deriveSubjectsRequirements() {
      return {
        all_of: ['customerId', 'caseId'],
      }
    },
  },
)

export default Profile
