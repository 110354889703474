/**
 * Mini-HistoryOfEvents BackOffice Comments Tab
 * pulls from API and displays order-related events with filtering options
 * */

// libs
import React, { useState, useEffect, useContext } from 'react'
// contexts and types
import { SessionContext } from 'contexts/session/SessionContext'
// hooks
import { useFetchData } from 'hooks/useFetchData'
import useRefreshPermissions from 'hooks/useRefreshPermissions'
import { useTranslation } from 'hooks/useTranslation'
//utils
import environment from 'envConfig'
import processApiErrors from 'utils/oneviewApi/processApiErrors'
// styles
import { createUseStyles } from 'react-jss'
import styles from './BackOffice.styles'
import { Typography, Result, Divider, Checkbox } from 'antd'
import { CommentOutlined } from '@ant-design/icons'
// components
import LoadingView from 'components/LoadingView'
import ErrorView from 'components/ErrorView'

const useStyles = createUseStyles(styles)

const BackOffice: React.FC = () => {
  const classes = useStyles()
  const { Text } = Typography

  // pull language content
  const { t } = useTranslation()

  // pull globalentity ID from session context
  const {
    sessionState: { orderId, globalEntityId },
  } = useContext(SessionContext)

  // useRefreshPermissions fetches new token, if current is expired
  const { isCurrentTokenValid, isRefreshTokenExpired } = useRefreshPermissions([
    orderId,
    globalEntityId,
  ])

  // if current refresh token is expired, redirect user to the root
  useEffect(() => {
    if (isRefreshTokenExpired) {
      window.location.reload()
    }
  }, [isRefreshTokenExpired])

  // get previous orders of customer
  // reRenderWidget is passed in dep arr to trigger re-fire of the hook upon fetch failure
  const [reRenderWidget, setReRenderWidget] = useState(false)
  const { isLoading, error, data } = useFetchData(
    `${environment().oneviewApiRoot}${
      environment().oneviewOrderApiRoot
    }/orders/${globalEntityId}/${orderId}/comment`,
    null,
    [reRenderWidget, isCurrentTokenValid],
  )

  // set comments to data context
  const [modifiedComments, setModifiedComments] = useState<string[]>([])
  useEffect(() => {
    if (data) {
      const comments = data[0].comment.split('\n')
      if (comments?.length > 0 && comments[0] !== '') {
        setModifiedComments(comments.filter((com: string) => com.length > 1))
      }
    }
  }, [data])

  const [hideOneviewEvents, setHideOneviewEvents] = useState(false)
  const handleCheckboxChange = () => {
    if (hideOneviewEvents) {
      const comments = data[0].comment.split('\n')
      if (comments?.length > 0 && comments[0] !== '') {
        setModifiedComments(comments.filter((com: string) => com.length > 1))
      }
    } else {
      setModifiedComments(
        modifiedComments.filter((com: string) => !com.includes('added by oneview')),
      )
    }
    setHideOneviewEvents(!hideOneviewEvents)
  }

  return (
    <div>
      {/* return loading spinner while fetching data */}
      {isLoading && (
        <div className={classes.loadingHolder}>
          <LoadingView text={`${t('Interface.Loading Back Office Comments')}...`} />
        </div>
      )}
      {/* return err message if data fetch fails */}
      {error && !isLoading && (
        <ErrorView
          errLogEntry={processApiErrors(
            t,
            error,
            'GetCommentCollections',
            orderId,
            globalEntityId,
            true,
          )}
          buttonText={t('Interface.Try Again')}
          buttonCallBack={() => setReRenderWidget(!reRenderWidget)}
        />
      )}
      {!error && !isLoading && (
        <div className={classes.commentsContainer}>
          <div className={classes.filterContainer}>
            <Checkbox
              className={classes.checkbox}
              checked={hideOneviewEvents}
              onChange={handleCheckboxChange}
            >
              {t('History of Events Widget.Hide Oneview Events')}
            </Checkbox>
          </div>

          {modifiedComments?.length > 0 && (
            <div className={classes.comments}>
              {modifiedComments.map((com: string, idx: number) => {
                // find out if comment added by Oneview
                const addedByOneView = com.includes('added by oneview')
                // remove onewview expression from comment
                const comment = com.split('(added by oneview)')

                return (
                  <React.Fragment key={idx}>
                    <div
                      className={
                        addedByOneView
                          ? classes.singleCommentContainerOneview
                          : classes.singleCommentContainer
                      }
                    >
                      {addedByOneView ? (
                        <React.Fragment>
                          <Text>{comment}</Text>
                          <Text className={classes.addedByOneviewHighlight}>
                            {`(${t('History of Events Widget.Added by Oneview')})`}
                          </Text>
                        </React.Fragment>
                      ) : (
                        <Text>{comment}</Text>
                      )}
                    </div>
                    <Divider className={classes.divider} />
                  </React.Fragment>
                )
              })}
            </div>
          )}
        </div>
      )}
      {!error && !isLoading && (!modifiedComments || modifiedComments?.length < 1) && (
        <Result
          icon={<CommentOutlined />}
          title={t('History of Events Widget.No Back Office Comment Found')}
          subTitle={t('History of Events Widget.There is no comment associated with the order')}
        />
      )}
    </div>
  )
}

export default BackOffice
