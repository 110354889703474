/**
 * LastOrdersView: pulls last orders of customer from data context
 * renders each order as a line through AnchorView
 * */

// libs
import React, { useContext } from 'react'
// contexts and types
import { DataContext } from 'contexts/data/DataContext'
import { LinesOfBusiness } from 'types/session/linesOfBusiness'
// hooks
import { useTranslation } from 'hooks/useTranslation'
import useFixNumber from 'hooks/useFixNumber'
import { useEntityUtcZone } from 'hooks/useEntityUtcZone'

// utils

// styles
import { createUseStyles } from 'react-jss'
import styles from './LastOrdersView.styles'
import { Typography, Tooltip } from 'antd'
// components
import AnchorView from 'components/AnchorView'
import { StatusDot } from './StatusDot/StatusDot'
// utils
import modifyUTC from 'utils/modifyUTC'
import { constructUrl } from 'utils/getSearchParams'
import { useCaptureUserAction } from 'hooks/events/useCaptureUserAction'
import { getOrderPaidValue } from 'utils/getters/getOrderPaidValue'

const useStyles = createUseStyles(styles)

type LastOrdersViewProps = {
  lob?: LinesOfBusiness
}

const LastOrdersView: React.FC<LastOrdersViewProps> = ({ lob = LinesOfBusiness.customer }) => {
  const classes = useStyles()
  const { Text } = Typography

  // pull language content
  const { t } = useTranslation()
  const captureUserAction = useCaptureUserAction()

  // pull data state and dispatch from data context
  const {
    dataState: { previousOrders, order: pageOrder },
  } = useContext(DataContext)
  const utc_zone = useEntityUtcZone()

  const fixNumber = useFixNumber()
  return (
    <div className={classes.prevOrderContainer}>
      <div className={classes.titlesHolder}>
        <Text strong className={`${classes.dateText} ${classes.columnHeader}`}>
          {t('Last Orders Widget.Date')}
        </Text>
        {lob === LinesOfBusiness.vendor ? (
          <Text strong className={`${classes.columnHeader} ${classes.vendorHeader}`}>
            {t('Last Orders Widget.Customer')}
          </Text>
        ) : (
          <Text strong className={`${classes.columnHeader} ${classes.vendorHeader}`}>
            {t('Last Orders Widget.Vendor')}
          </Text>
        )}
        <Text strong className={`${classes.priceText} ${classes.columnHeader}`}>
          {t('Last Orders Widget.Price')}
        </Text>
        <Text className={`${classes.columnHeader} ${classes.idHeader}`}>
          {t('Order Widget.Order ID')}
        </Text>
      </div>

      {/* render 10 previous orders of customer */}
      {previousOrders?.length > 0 && (
        <div className='lastOrdersList'>
          {previousOrders.slice(0, 5).map((order, index: number) => {
            // pull display data
            const orderId = order.order_id
            const globalEntityId = order.global_entity_id
            const orderValue = fixNumber(getOrderPaidValue({ order }))
            const orderDate = modifyUTC(order.place_timestamp, utc_zone, 'D.M.YY')

            const isCurrentOrder = orderId === pageOrder?.order_id

            const handleLastOrderClickedCallback = (orderId: string, globalEntityId: string) => {
              captureUserAction('LastOrderListItemClicked')
            }

            const customerName =
              order.customer.profile?.name ??
              [order.customer.profile?.first_name, order.customer.profile?.last_name].join(' ')

            return (
              <div key={orderId} style={{ display: 'flex' }}>
                <div style={{ display: 'inline-block' }}>
                  <StatusDot order={order} />
                </div>
                <div className={isCurrentOrder ? classes.currentOrderHolder : classes.orderHolder}>
                  <Text className={`${classes.dateText} ${classes.greyText}`}>{orderDate}</Text>

                  {lob === LinesOfBusiness.vendor ? (
                    <Tooltip title={order.customer.profile?.name}>
                      <Text className={`${classes.text} ${classes.vendorText} ${classes.greyText}`}>
                        {customerName}
                      </Text>
                    </Tooltip>
                  ) : order.vendor?.name ? (
                    <Tooltip title={order.vendor.name}>
                      <Text className={`${classes.text} ${classes.vendorText} ${classes.greyText}`}>
                        {order.vendor.name}
                      </Text>
                    </Tooltip>
                  ) : (
                    <Text className={`${classes.text} ${classes.vendorText} ${classes.greyText}`}>
                      -
                    </Text>
                  )}
                  {/* order value with currency */}
                  <Tooltip title={`${order.currency} ${orderValue}`}>
                    <Text className={`${classes.priceText} ${classes.greyText}`}>{orderValue}</Text>
                  </Tooltip>

                  <AnchorView
                    style={{ width: '23%', display: 'inline-block' }}
                    text={order.order_id}
                    href={constructUrl({
                      params: {
                        userId: order.customer.customer_id,
                        orderId: order.order_id,
                        globalEntityId: order.global_entity_id,
                      },
                    })}
                    clickCallBack={() => handleLastOrderClickedCallback(orderId, globalEntityId)}
                  />
                </div>
              </div>
            )
          })}
        </div>
      )}
    </div>
  )
}

export default LastOrdersView
