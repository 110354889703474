import { ApiService } from 'services/authApi/getPermissions'
import { OrderFlagsApiResponse } from 'types/api/orderApi/order'

export const getOrderFlags: ApiService<
  {
    entityId: string
    orderId: string
  },
  OrderFlagsApiResponse
> = (createClient, options) => {
  const { entityId, orderId, clientParams } = options

  const uri = `OrdersAPI/v1/orders/${entityId}/${orderId}/flags`

  const { tParams = {}, ...otherClientParams } = clientParams || {}

  return createClient({
    endpointName: 'getOrderFlags',
    expectedResponseStatusCode: 200,
    tParams: {
      orderId,
      countryCode: entityId.split('_')[1],
      ...tParams,
    },
    ...otherClientParams,
  }).get(uri)
}
