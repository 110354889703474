export default {
  itemsList: {
    listStyle: 'none',
    padding: 0,
    margin: 0,
  },

  itemRow: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    height: '40px',
    borderBottom: '1px solid #F0F0F0',
    padding: '5px 0px',
  },

  itemName: ({ spacing }) => {
    return {
      width: '65%',
      paddingLeft: spacing,
    }
  },

  itemPrice: {
    width: '20%',
  },

  itemQantity: {
    width: '15%',
  },

  itemPriceText: {
    fontSize: '12px',
    color: 'rgba(0, 0, 0, 0.85)',
    '&.selected, &.disabled': {
      color: 'rgba(0, 0, 0, 0.35)',
    },
  },
}
