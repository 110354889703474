import { useContext, useCallback } from 'react'

import { EntityContext } from 'contexts/entity/EntityContext'

import fixNumber from 'utils/fixNumber'

function useFixNumber() {
  const {
    entityState: {
      entityConfig: { currency_digits_after_comma },
    },
  } = useContext(EntityContext)

  return useCallback((number: number | string) => fixNumber(number, currency_digits_after_comma), [
    currency_digits_after_comma,
  ])
}

export default useFixNumber
