export default {
  header: {
    fontSize: '13px',
    'th.ant-descriptions-item-label&': {
      background: 'transparent',
      border: '0px',
    },
    '& > span > div > div': {
      fontSize: '13px',
      padding: '8px',
    },
    '&.ant-descriptions-item-label': {
      padding: '0px 12px',
    },
    '&.ant-descriptions-item-content': {
      padding: '0px',
    },
  },
  dataPoint: {
    '& > span > div > div': {
      fontSize: '13px',
      padding: '8px',
    },
    '&.ant-descriptions-item-label': {
      padding: '0px 12px',
      borderRight: '0px',
    },
    '&.ant-descriptions-item-content': {
      padding: '0px',
    },
  },
  headingContainer: {
    '&.ant-descriptions-bordered > div': {
      border: '0',
    },
  },

  colorDotValid: {
    background: '#52C41A',
    display: 'inline-flex',
    borderRadius: '50%',
    width: '8px',
    height: '8px',
    marginRight: '4px',
  },

  colorDotInvalid: {
    background: 'rgba(0, 0, 0, 0.25)',
    display: 'inline-flex',
    borderRadius: '50%',
    width: '8px',
    height: '8px',
    marginRight: '4px',
  },

  checked: {
    background: '#E6F7FF;',
  },
}
