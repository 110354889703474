import React from 'react'
import { Typography } from 'antd'
import { createUseStyles } from 'react-jss'
import { useTranslation } from 'hooks/useTranslation'
import { SingleComment } from 'types/api/commentApi/getOrderComments'
import styles from './Comments.styles'
import { commentTagType, CommentView } from './CommentView'
import { WidgetSize } from 'types/widgets/historyOfEvents/tabs'

const { Text } = Typography

const useStyles = createUseStyles(styles)

type Props = {
  comment: SingleComment
  shouldCommentBeHighlighted: boolean
  mode: WidgetSize
}

const PaymentCompensationComment = ({ comment, shouldCommentBeHighlighted, mode }: Props) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <CommentView
      mode={mode}
      comment={comment}
      shouldCommentBeHighlighted={shouldCommentBeHighlighted}
      tag={{
        type: commentTagType.thunderbolt,
        text: t('History of Events Widget.Event Types.Payment Compensation'),
      }}
    >
      <Text className={classes.commentText}>
        <Text>{` ${t('History of Events Widget.Content.Issued payment compensation')} `}</Text>

        {comment?.additional_data?.payment_compensation?.compensation_amount.toString() &&
        comment?.additional_data?.payment_compensation?.compensation_currency &&
        comment?.additional_data?.payment_compensation?.compensation_target ? (
          <React.Fragment>
            <Text strong>
              {`${comment.additional_data.payment_compensation.compensation_currency} ${comment.additional_data.payment_compensation.compensation_amount} `}
            </Text>
            <Text>{`${t('History of Events Widget.Content.To')} `}</Text>
            <Text strong>
              {`${comment.additional_data.payment_compensation.compensation_target.toLowerCase()}.`}
            </Text>
          </React.Fragment>
        ) : null}
      </Text>
    </CommentView>
  )
}

export default PaymentCompensationComment
