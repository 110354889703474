import { UnifiedIconName } from 'contexts/IconPacksProvider'
import { useCallback } from 'react'
import { OrderApiResponse } from 'types/herocare'
import { getMainPaymentMethod } from 'utils/getters/getMainPaymentMethod'
import { getSecondaryPaymentMethods } from 'utils/getters/getSecondaryPaymentMethods'

export interface PaymentMethodTextAndIcon {
  text: string
  icon: UnifiedIconName
}

export const useGetOrderPaymentMethodsTextsAndIcons = () => {
  return useCallback(({ order }: { order: OrderApiResponse }): PaymentMethodTextAndIcon[] => {
    const result: PaymentMethodTextAndIcon[] = []
    if (!order?.customer?.payment) {
      return result
    }

    const mainPaymentMethod = getMainPaymentMethod(order)

    const secondaryPaymentMethods = getSecondaryPaymentMethods(order)

    result.push({
      icon: mainPaymentMethod.icon,
      text: mainPaymentMethod.label,
    })

    secondaryPaymentMethods.forEach((secondaryPaymentMethod) => {
      result.push({
        icon: secondaryPaymentMethod.icon,
        text: secondaryPaymentMethod.label,
      })
    })
    return result
  }, [])
}
