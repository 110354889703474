export default {
  chatContainer: {
    display: 'flex',
    flexDirection: 'column',

    '&.isCustomer': {
      alignItems: 'flex-start',
    },
    '&.isRider': {
      alignItems: 'flex-end',
    },
  },

  /** this defines the width of the inner container */
  chatInnerContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '16px',
    width: '250px',
    '&.isCustomer': {
      alignItems: 'flex-start',
    },
    '&.isRider': {
      alignItems: 'flex-end',
    },
  },

  senderName: {
    fontSize: '11px',
    color: 'rgba(0, 0, 0, 0.45)',
  },

  messageBox: {
    display: 'flex',
    flexDirection: 'column',

    borderRadius: '10px',
    padding: '7px 28px 7px 7px',

    '&.isCustomer': {
      backgroundColor: '#F7F7F7',
    },
    '&.isRider': {
      backgroundColor: '#434343',
    },
  },

  messageText: {
    fontSize: '14px',
    '&.isCustomer': {
      color: 'rgba(0, 0, 0, 0.65)',
    },
    '&.isRider': {
      color: '#FFFFFF',
    },
  },

  translationDivider: {
    margin: '5px 0px 5px 0px',
    padding: 0,
    '&.isRider': {
      borderColor: '#fff',
    },
  },

  chatMessageFooter: {
    display: 'flex',
    alignItems: 'baseline',
    justifyContent: 'space-between',
    width: '100%',
  },

  messageDate: {
    fontSize: '11px',
    marginTop: '8px',
    '&.isCustomer': {
      color: 'rgba(0, 0, 0, 0.45)',
    },
    '&.isRider': {
      color: '#FFFFFF',
    },
  },

  icon: {
    width: '12px',
    height: '7px',
    marginLeft: '5px',
    marginRight: -22,
  },

  imageBox: {
    width: '100%',
    maxWidth: '100%',
  },

  imageFailedBox: {
    width: '100%',
    maxWidth: '100%',
  },

  imageNotAvailableText: {
    fontSize: '12px',
  },
}
