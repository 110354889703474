export default {
  container: {
    width: '100%',
    minWidth: 770,
    backgroundColor: '#E5E5E5',
  },

  navbar: {
    display: 'flex',
    width: '100%',
    height: '50px',
    backgroundColor: '#ffffff',
    position: 'fixed',
    top: 0,
    left: 0,
    zIndex: 1,
    padding: '5px 0px 5px 0px',
    borderBottom: '1px solid #e3e3e3',
    marginBottom: '50px',
  },

  navbarFill: {
    height: '50px',
  },

  navbarList: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '63%',
    minWidth: 450,
    listStyleType: 'none',
  },

  navbarItem: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '40px',
    textAlign: 'center',
    fontSize: '14px',
    color: '#696969',
    '&.active': {
      color: '#40a9ff',
    },
  },

  navbarItemIcon: {
    marginRight: '5px',
    width: '13px',
    height: '13px',
  },

  widgetsContainer: {
    display: 'flex',
    margin: '0px 10px',
    columnGap: '10px',
  },

  scrollableWidgetsContainer: {
    flexBasis: '65%',
    overflow: 'auto',

    '& > .widget': {
      borderRadius: '5px',
      backgroundColor: '#fff',
      marginTop: '10px',
      padding: '10px',
    },
  },

  fixedWidgetsContainer: function () {
    return {
      flexBasis: '35%',
      minWidth: '300px',
      marginTop: '10px',
      height: 'calc(100vh - 70px)',
      minHeight: '1000px',
      display: 'flex',
      flexDirection: 'column',
      rowGap: '10px',

      '& > .widget': {
        borderRadius: '5px',
        backgroundColor: '#fff',
      },
    }
  },

  fixedWidgetContainer: {
    border: '1px solid #e8e8e8',
    borderRadius: '5px',
    padding: '10px',
    backgroundColor: '#ffffff',
    boxShadow: '0.5px 0.5px 5px rgba(0, 0, 0, 0.134124)',
  },
  actionsContainer: {
    composes: '$fixedWidgetContainer',
    minHeight: '100px',
  },
  historyOfEventsContainer: {
    composes: '$fixedWidgetContainer',
    flex: 'auto',
    overflow: 'hidden',
  },
  lastOrdersContainer: {
    composes: '$fixedWidgetContainer',
  },
}
