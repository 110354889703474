// libs
import React, { FC, useCallback, useContext, useEffect, useMemo } from 'react'
import { createUseStyles } from 'react-jss'
import { Button } from 'antd'
// components
import { SmartToolTip } from 'shared/SmartTooltip'
// contexts and types
import { RefundIssueType } from 'contexts/entity/types'
import { SessionContext } from 'contexts/session/SessionContext'
// hooks
import { useTranslation } from 'hooks/useTranslation'
import { useCaptureUserAction } from 'hooks/events/useCaptureUserAction'
// utils
import { filterByCcrWhitelist } from 'utils/ccrWhitelistFilter'
// styles
import { IssueTypeSelect } from './IssueTypeSelect'
import styles from './Issue.styles'

interface Props {
  issueTypes: RefundIssueType[]
  selectedIssueType: RefundIssueType
  useCcrWhitelistForIssues?: boolean
  setCurrentStepIndex: (arg: number) => void
  setSelectedIssueType: (issueType: RefundIssueType) => void
  actionName: 'PartialRefund' | 'FullRefund'
}

const useStyles = createUseStyles(styles)

const Issue: FC<Props> = ({
  setCurrentStepIndex,
  useCcrWhitelistForIssues,
  issueTypes,
  selectedIssueType,
  setSelectedIssueType,
  actionName,
}) => {
  const classes = useStyles()

  const EventLookUp = useMemo(
    () => ({
      ISSUE_SELECTED: `${actionName}ActionIssueTypeSelected`,
      DROPDOWN_CLICKED: `${actionName}ActionIssueTypeDropdownClicked`,
      CONTINUE_BUTTON_CLICKED: `${actionName}ActionFirstStepContinueClicked`,
    }),
    [actionName],
  )

  const {
    sessionState: { ccrCode },
  } = useContext(SessionContext)
  // pull translations
  const { t } = useTranslation()
  const captureUserAction = useCaptureUserAction()

  const allowedIssues = useMemo(() => {
    return filterByCcrWhitelist<RefundIssueType>(useCcrWhitelistForIssues, issueTypes, ccrCode)
  }, [useCcrWhitelistForIssues, ccrCode, issueTypes])

  useEffect(() => {
    if (!selectedIssueType && allowedIssues.length === 1) {
      setSelectedIssueType(allowedIssues[0])
    }
  }, [allowedIssues, selectedIssueType, setSelectedIssueType])

  const handleDropdownClick = useCallback(
    (isVisible) => {
      if (isVisible) {
        captureUserAction(EventLookUp.DROPDOWN_CLICKED)
      }

      return isVisible
    },
    [captureUserAction, EventLookUp],
  )

  const handleSelectIssue = useCallback(
    (issue) => {
      captureUserAction(EventLookUp.ISSUE_SELECTED, {
        eventDetails: {
          selected_issue_type: issue,
        },
      })

      return setSelectedIssueType(issue)
    },
    [captureUserAction, EventLookUp, setSelectedIssueType],
  )

  return (
    <div>
      <IssueTypeSelect
        selectedIssueType={selectedIssueType}
        issueTypes={allowedIssues}
        onSelect={handleSelectIssue}
        onClick={handleDropdownClick}
      />

      <div className={classes.buttonsHolder}>
        <SmartToolTip
          title={
            selectedIssueType
              ? ''
              : t(
                  `Actions Widget.Actions.Partial Refund.Please select an issue type before continuing`,
                )
          }
        >
          <Button
            type='primary'
            disabled={!selectedIssueType}
            onClick={() => {
              captureUserAction(EventLookUp.CONTINUE_BUTTON_CLICKED, {
                eventDetails: {
                  selected_issue_type: selectedIssueType.issue,
                  next_step: selectedIssueType.use_flexible_amount
                    ? 'flexible_partial_refund'
                    : 'item_selection',
                },
              })
              setCurrentStepIndex(1)
            }}
          >
            {t('Interface.Continue')}
          </Button>
        </SmartToolTip>
      </div>
    </div>
  )
}

export default Issue
