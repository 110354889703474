import { useApiClientCreator } from 'contexts/apiClientCreator/ApiClientCreatorContext'
import { getBaseUrl } from 'contexts/apiClientCreator/getBaseUrl'
import { useMemo } from 'react'
import { SdkConstructorParams } from 'types/unitedUiConfig'
import { readAuthPayload } from 'utils/authHelpers'
import { useSessionState } from './useSessionState'

export const useSdkConstructParams = () => {
  const { createClient } = useApiClientCreator()
  const {
    utcZone,
    globalEntityId,
    userId,
    caseId,
    globalVendorId,
    vendorId,
    riderId,
    orderId,
    lineOfBusiness,
    ccrCode,
    platform,
    lang,
  } = useSessionState()

  const sdkConstructParams = useMemo((): SdkConstructorParams => {
    return {
      accessToken: readAuthPayload().jwt,
      baseUrl: getBaseUrl({ globalEntityId }),
      clientCreator: createClient,
      session: {
        entityId: globalEntityId,
        customerId: userId,
        utc_zone: utcZone,
        caseId,
        globalVendorId,
        vendorId,
        riderId,
        orderId,
        lineOfBusiness,
        ccrCode,
        platform,
        lang,
      },
    }
  }, [
    createClient,
    globalEntityId,
    userId,
    caseId,
    utcZone,
    globalVendorId,
    vendorId,
    riderId,
    orderId,
    lineOfBusiness,
    ccrCode,
    platform,
    lang,
  ])

  return sdkConstructParams
}
