import React, { useCallback, useContext, useLayoutEffect, useEffect, useMemo } from 'react'
import { createUseStyles } from 'react-jss'
import { differenceInMinutes } from 'date-fns'
import { Badge, Tabs } from 'antd'
import { UnifiedIcon } from 'shared/UnifiedIcon'
import { useTranslation } from 'hooks/useTranslation'
import {
  LobTabsDataRequirements,
  PluggableWidgetDefinition,
  ScreenName,
  TabDefinition,
  TabName,
  WidgetSubjectsResolutionStates,
  builtinWidgetNames,
  widgetTypes,
} from 'types/unitedUiConfig'

import styles from './uuiLayout.styles'
import { RootWidgetView } from 'components/RootWidgetView'
import { LinesOfBusiness } from 'types/session/linesOfBusiness'
import { useEntityConfig } from 'hooks/useEntityConfig'
import { TabbedWidgets } from './TabbedWidgets'

import { useTabsStyles } from 'hooks/styles/useTabsStyles'
import { gray, primary, text, white } from 'theme'
import { WidgetActionHandlers } from 'shared/WidgetActionHandlers'
import { WidgetDataRequirementsValidation } from 'components/WidgetDataRequirementsValidation/WidgetDataRequirementsValidation'
import { useInitData } from 'hooks/apiHooks/useInitData'
import { useWidgetSubjects } from 'hooks/widgetView/useWidgetSubjects'
import { useValidateWidgetSubjectsRequirements } from 'hooks/widgetView/useValidateWidgetSubjectsRequirements'
import { customerLobTabsDataRequirements } from './tabDataRequirements/customerLobTabsDataRequirements'
import { riderV2LobTabsDataRequirements } from './tabDataRequirements/riderV2LobTabsDataRequirements'
import { vendorLobTabsDataRequirements } from './tabDataRequirements/vendorLobTabsDataRequirements'
import { SessionContext } from 'contexts/session/SessionContext'
import {
  UseWidgetContainerStylesOptions,
  useWidgetContainerStyles,
} from 'hooks/styles/useWidgetContainerStyles'
import classNames from 'classnames'
import { DataContext } from 'contexts/data/DataContext'
import { useCaptureUserAction } from 'hooks/events/useCaptureUserAction'
import { useCheckDisplayRules } from 'hooks/useCheckDisplayRules'
import { ViewScreenAndSdkProvider } from 'contexts/ViewScreenAndSdkProvider'
import { useEventEmitter } from 'hooks/useEventEmitter'
import { useLoadOrderComments } from 'hooks/apiHooks/useLoadOrderComments'

const useStyles = createUseStyles(styles)

const lobsTabsDataRequirements = new Map<LinesOfBusiness, LobTabsDataRequirements>([
  [LinesOfBusiness.customer, customerLobTabsDataRequirements],
  [LinesOfBusiness.riderV2, riderV2LobTabsDataRequirements],
  [LinesOfBusiness.vendor, vendorLobTabsDataRequirements],
])

const TabContent = ({
  tabDefinition,
  lineOfBusiness,
  subjectsResolutionStates,
  screenName,
}: {
  screenName: ScreenName
  tabDefinition: TabDefinition
  lineOfBusiness: LinesOfBusiness
  subjectsResolutionStates: WidgetSubjectsResolutionStates
}) => {
  const { tab_name, widgets, action_handlers, elevate_widgets } = tabDefinition
  const validateWidgetSubjectsRequirements = useValidateWidgetSubjectsRequirements()

  const checkDisplayRules = useCheckDisplayRules()

  const tabWidgetsContainerStyles = useWidgetContainerStyles({
    paddingBottom: 16,
  } as UseWidgetContainerStylesOptions)

  const tabWidgetsContainerWithElevationStyles = useWidgetContainerStyles({
    withBoxShadow: true,
  } as UseWidgetContainerStylesOptions)

  const lobTabsDataRequirements = lobsTabsDataRequirements.get(lineOfBusiness)
  const dataRequirements = lobTabsDataRequirements[tab_name] || {}

  const tabVisibleWidgets = widgets.filter(
    (widget) => checkDisplayRules(widget.display_rules).visible,
  )

  const tabDataRequirementsValidation = validateWidgetSubjectsRequirements(dataRequirements, {
    subjectsResolutionStates,
  })

  return (
    <div className='main-tab-content' data-tab-name={tab_name}>
      {/* show tab action handlers */}
      <WidgetActionHandlers
        marginTop={24}
        actionHandlers={action_handlers}
        justifyContent='end'
        className='main-tab-action-handlers'
        data-tab-name={tab_name}
      />
      <div
        className={classNames(tabWidgetsContainerStyles.styles, {
          [tabWidgetsContainerWithElevationStyles.styles]: Boolean(elevate_widgets),
        })}
      >
        <WidgetDataRequirementsValidation
          validationResult={tabDataRequirementsValidation}
          childCount={tabVisibleWidgets.length}
        >
          <div className='main-tab-content-widgets' data-tab-name={tab_name}>
            {tabVisibleWidgets.map((widget) => {
              switch (widget.type) {
                case widgetTypes.builtin:
                case widgetTypes.plugin:
                  return (
                    <RootWidgetView
                      widgetDefinition={widget}
                      key={widget.label.label_translation_key}
                      screenName={screenName}
                      tabContainerLabel={undefined}
                    />
                  )

                case widgetTypes.tabbedWidgetsContainer:
                  return (
                    <TabbedWidgets
                      widgetDefinition={widget}
                      key={widget.label.label_translation_key}
                      screenName={screenName}
                    />
                  )

                default:
                  return null
              }
            })}
          </div>
        </WidgetDataRequirementsValidation>
      </div>
    </div>
  )
}

export const UUILayout = () => {
  const cx = useStyles()

  const tabsStyles = useTabsStyles({
    tabBorderRadius: 6,
    backgroundColor: gray.gray3,
    activeBackgroundColor: white,
    flexItems: true,
    marginTop: 10,
    yPadding: 8,
  })

  const {
    entityConfig: { layout_v2: config },
  } = useEntityConfig()

  const {
    sessionState: { lineOfBusiness, activeTab },
    setActiveTab,
  } = useContext(SessionContext)

  const { t } = useTranslation()

  const eventEmitter = useEventEmitter('global')
  const captureUserAction = useCaptureUserAction()
  const checkDisplayRules = useCheckDisplayRules()

  const handleTabChange = useCallback(
    (tabName: TabName) => {
      const eventName = `OPEN_TAB_${tabName.toUpperCase()}`
      captureUserAction(eventName)
      setActiveTab(tabName)
    },
    [setActiveTab, captureUserAction],
  )

  useEffect(() => {
    const listener = eventEmitter.addEventListener({
      name: 'COMMENT_TAG_CLICKED',
      callback: () => {
        handleTabChange('activity')
      },
    })
    return () => {
      eventEmitter.removeEventListener(listener)
    }
  }, [handleTabChange, eventEmitter])

  const {
    orderInit: {
      status: loadOrderStatus,
      error: loadOrderError,
      data: order,
      loadService: retryOrderLoad,
    },
    ticketInit: {
      data: ticket,
      status: loadTicketStatus,
      error: loadTicketError,
      loadService: retryTicketLoad,
    },
    riderInit: {
      data: rider,
      status: loadRiderStatus,
      error: loadRiderError,
      loadService: retryRiderLoad,
    },
    vendorInit: {
      data: vendor,
      status: loadVendorStatus,
      error: loadVendorError,
      loadService: retryVendorLoad,
    },
    riderCurrentOrderInit: {
      data: riderCurrentOrder,
      status: loadRiderCurrentOrderStatus,
      error: loadRiderCurrentOrderError,
      loadService: retryRiderCurrentOrderLoad,
    },
    riderCurrentOrderVendorInit: {
      data: riderCurrentVendor,
      status: loadRiderCurrentVendorStatus,
      error: loadRiderCurrentVendorError,
      loadService: retryRiderCurrentVendorLoad,
    },

    orderFulfillmentInit: {
      data: orderFulfillment,
      status: loadOrderFulfillmentStatus,
      error: loadOrderFulfillmentError,
      loadService: retryOrderFulfillmentLoad,
    },
  } = useInitData()

  const { data } = useLoadOrderComments()

  // start; IVU-2935 adding custom action to check difference in PDT from DF and Hurrier
  useEffect(() => {
    const bothPromisedTimestampsExist = Boolean(
      orderFulfillment?.promised_delivery_at && order?.promised_customer_timestamp,
    )

    if (!bothPromisedTimestampsExist) return

    const diffByMinutes = differenceInMinutes(
      new Date(order?.promised_customer_timestamp),
      new Date(orderFulfillment?.promised_delivery_at),
    )

    if (diffByMinutes) {
      captureUserAction('PROMISED_TIME_CHANGE', {
        reportToEts: false,
        eventDetails: {
          order_pdt: order?.promised_customer_timestamp,
          fulfillment_pdt: orderFulfillment?.promised_delivery_at,
          diff_in_mins: diffByMinutes,
        },
      })
    }
  }, [
    orderFulfillment?.promised_delivery_at,
    order?.promised_customer_timestamp,
    captureUserAction,
  ])
  // end; IVU-2935

  const {
    dataState: { customer },
  } = useContext(DataContext)

  const { subjectsResolutionStates } = useWidgetSubjects()

  subjectsResolutionStates.order = {
    status: loadOrderStatus,
    value: order,
    errorPayload: loadOrderError?.errorPayload,
    onRetry: retryOrderLoad,
  }

  subjectsResolutionStates.customer = {
    status: loadOrderStatus,
    value: customer,
    errorPayload: loadOrderError?.errorPayload,
  }

  subjectsResolutionStates.ticket = {
    status: loadTicketStatus,
    value: ticket,
    errorPayload: loadTicketError?.errorPayload,
    onRetry: retryTicketLoad,
  }

  subjectsResolutionStates.vendor = {
    status: loadVendorStatus,
    value: vendor,
    errorPayload: loadVendorError?.errorPayload,
    onRetry: retryVendorLoad,
  }

  subjectsResolutionStates.rider = {
    status: loadRiderStatus,
    value: rider,
    errorPayload: loadRiderError?.errorPayload,
    onRetry: retryRiderLoad,
  }

  subjectsResolutionStates.riderCurrentOrder = {
    status: loadRiderCurrentOrderStatus,
    value: riderCurrentOrder,
    errorPayload: loadRiderCurrentOrderError?.errorPayload,
    onRetry: retryRiderCurrentOrderLoad,
  }

  subjectsResolutionStates.riderCurrentVendor = {
    status: loadRiderCurrentVendorStatus,
    value: riderCurrentVendor,
    errorPayload: loadRiderCurrentVendorError?.errorPayload,
    onRetry: retryRiderCurrentVendorLoad,
  }

  subjectsResolutionStates.orderFulfillment = {
    status: loadOrderFulfillmentStatus,
    value: orderFulfillment,
    errorPayload: loadOrderFulfillmentError?.errorPayload,
    onRetry: retryOrderFulfillmentLoad,
  }

  const {
    tabs_panel: { tabs },
    summary_panel: { display_rules: summary_panel_display_rules },
  } = config

  const visibleTabs = useMemo(() => {
    return tabs.filter((tab) => checkDisplayRules(tab.display_rules).visible)
  }, [tabs, checkDisplayRules])

  // sync active tab before render
  useLayoutEffect(() => {
    if (!activeTab || !visibleTabs.find((tab) => tab.tab_name === activeTab)) {
      setActiveTab(visibleTabs[0]?.tab_name || null)
    }
  }, [visibleTabs, activeTab, setActiveTab])

  // widget definitions must be memoized before passing to root widget view
  const summaryPanelWidgetDefinition = useMemo((): PluggableWidgetDefinition => {
    return {
      type: widgetTypes.builtin,
      // plugin code will be replaced by summary-panel widget once migration is completed and validated
      widget_name: builtinWidgetNames.summaryPanel,
      display_rules: summary_panel_display_rules,
      label: {
        label_translation_key: 'widget_labels.summary_panel',
        display_rules: {
          hide: true,
        },
      },
    }
  }, [summary_panel_display_rules])

  return (
    <div className={cx.container}>
      {/* sticky header */}
      <div className={cx.sticky}>
        {/* resolution bar */}
        <div className={cx.resolutionBar}>
          <RootWidgetView
            widgetDefinition={{
              type: widgetTypes.builtin,
              widget_name: builtinWidgetNames.resolutionBar,
            }}
            screenName='main'
            paddingTop={0}
            marginTop={0}
          />
        </div>
      </div>

      {/* summary pannel */}
      <div className={cx.summaryPanel}>
        <RootWidgetView
          widgetDefinition={summaryPanelWidgetDefinition}
          paddingTop={0}
          marginTop={0}
          onStateChange={null}
          screenName='main'
          collapseState='disabled'
        />
      </div>

      {visibleTabs.length > 0 && (
        <Tabs
          type='card'
          className={tabsStyles.tabs}
          activeKey={activeTab}
          onChange={handleTabChange}
          tabBarGutter={4}
        >
          {visibleTabs.map((tab) => {
            const { tab_name, icon_name, label_translation_key } = tab
            const tabLabel = t(label_translation_key)

            const screenName = (tab_name + '_tab') as ScreenName

            return (
              <Tabs.TabPane
                className={cx.tabsPane}
                tab={
                  <div
                    className={classNames('main-tab-button', tabsStyles.tabButton)}
                    data-tab-name={tab_name}
                    role='button'
                  >
                    <UnifiedIcon size={14} icon={icon_name} /> {tabLabel}
                    {tab_name === 'activity' ? (
                      <Badge
                        style={{ backgroundColor: primary.primary1, color: text.link }}
                        count={data?.total || 0}
                        showZero
                      />
                    ) : null}
                  </div>
                }
                key={tab_name}
              >
                <ViewScreenAndSdkProvider screenName={screenName} label={tabLabel}>
                  <TabContent
                    screenName={screenName}
                    tabDefinition={tab}
                    key={tab.tab_name}
                    subjectsResolutionStates={subjectsResolutionStates}
                    lineOfBusiness={lineOfBusiness}
                  />
                </ViewScreenAndSdkProvider>
              </Tabs.TabPane>
            )
          })}
        </Tabs>
      )}
    </div>
  )
}
