// libs
import React from 'react'
// styles
import { createUseStyles } from 'react-jss'
import styles from './SingleChatInfoView.styles'
import { Typography } from 'antd'

interface Props {
  info: string
  applyTopMargin: boolean
}

const useStyles = createUseStyles(styles)

const SingleChatInfoView: React.FC<Props> = ({ info, applyTopMargin }) => {
  const classes = useStyles()
  const { Text } = Typography

  return (
    <div
      className={
        applyTopMargin ? classes.infoHolderWithTopMargin : classes.infoHolderWithoutTopMargin
      }
    >
      <Text className={classes.info}>{info}</Text>
    </div>
  )
}

export default SingleChatInfoView
