import { TabDataPointConfigs } from './orderSummaryTabDataPointConfigs'

/**
 * these are datapoint configuration keys of displayable fields in the order invoice tab additional charges section
 */
export const orderInvoiceTabAdditionalChargesDataPointConfigs: TabDataPointConfigs<
  | 'subscriptionsFee'
  | 'riderTip'
  | 'deliveryFee'
  | 'priorityDeliveryFee'
  | 'minimumOrderValueFee'
  | 'packagingFees'
  | 'serviceFee'
> = {
  subscriptionsFee: {
    key: 'subscriptions_fee',
    labelTranslationKey: 'Order Widget.Tabs.Invoice.Subscription',
  },
  riderTip: {
    key: 'rider_tip',
    labelTranslationKey: 'Order Widget.Tabs.Invoice.Rider Tip',
  },
  deliveryFee: {
    key: 'delivery_fee',
    labelTranslationKey: 'Order Widget.Tabs.Invoice.Delivery Fee',
  },
  priorityDeliveryFee: {
    key: 'priority_delivery_fee',
    labelTranslationKey: 'Order Widget.Tabs.Invoice.Priority Delivery Fee',
  },
  minimumOrderValueFee: {
    key: 'minimum_order_value_fee',
    labelTranslationKey: 'Order Widget.Tabs.Invoice.Minimum Order Value Fee',
  },
  packagingFees: {
    key: 'packaging_fees',
    labelTranslationKey: 'Order Widget.Tabs.Invoice.Packaging Fees',
  },
  serviceFee: {
    key: 'service_fee',
    labelTranslationKey: 'Order Widget.Tabs.Invoice.Service Fee',
  },
}
