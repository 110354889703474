import React from 'react'

// Types
import { LinesOfBusiness } from 'types/session/linesOfBusiness'
import { OrderWidgetSummary } from 'contexts/entity/types'

// Utils
import { createUseStyles } from 'react-jss'

// Components
import { List } from 'antd'

// Hooks
import { useTranslation } from 'hooks/useTranslation'
import { useSessionState } from 'hooks/useSessionState'
import { useIsDataPointValid } from 'hooks/useGetValidFeatures'

// Relative Path
import styles from './ProofOfDeliveryListItem.styles'
import ProofOfDelivery from '../ProofOfDelivery'

const useStyles = createUseStyles(styles)

type ProofOfDeliveryListItemProps = {
  summary: OrderWidgetSummary
}

function ProofOfDeliveryListItem({ summary }: ProofOfDeliveryListItemProps) {
  const { t } = useTranslation()
  const classes = useStyles()
  const { caseId, lineOfBusiness } = useSessionState()
  const isDataPointValid = useIsDataPointValid()

  const podConfig = summary.proof_of_delivery
  const isPod =
    caseId &&
    lineOfBusiness === LinesOfBusiness.riderV2 &&
    podConfig &&
    isDataPointValid(podConfig.betaRequirement)
  if (isPod) {
    return (
      <List.Item className={classes.list}>
        <List.Item.Meta
          title={
            <div>
              <ProofOfDelivery paddingOffset={true} />
            </div>
          }
          description={t('Order Widget.Tabs.Summary.Proof Of Delivery')}
        />
      </List.Item>
    )
  }

  return null
}

export default ProofOfDeliveryListItem
