import { OrderApiResponse } from 'types/herocare'

export const transformIntoTagStructure = (values: string[]) => {
  return values.map((value) => ({
    key: value,
    value: value,
  }))
}

export const getOrderEligibilities = ({ order }: { order: OrderApiResponse }) => {
  return {
    orderEligibilities: transformIntoTagStructure(order?.eligibilities || []),
    customerEligibilities: transformIntoTagStructure(order?.customer?.eligibilities || []),
    vendorEligibilities: transformIntoTagStructure(order?.vendor?.eligibilities || []),
  }
}
