export default {
  collapse: {
    backgroundColor: '#fff',
    marginRight: -36,
    marginLeft: -36,

    '& .ant-collapse-item-disabled > .ant-collapse-header': {
      cursor: 'default',
    },

    '& .ant-collapse-header > .ant-collapse-header-text': {
      flex: 1,
    },
  },
  collapseND: {
    '& .ant-collapse-item': {
      borderBottom: '1px solid #ededed',
    },
    '& .ant-collapse-content-box': {
      padding: '4px 0 0 0',
      marginTop: '-4px',
    },
  },
  itemPanel: {
    paddingLeft: '30px',
  },
  toppingsHolder: {
    display: 'flex',
    padding: '8px 8px 8px 24px',
    borderBottom: '1px solid #ededed',
  },
  toppingsHolderND: {
    padding: '12px 16px 12px 12px',
  },
  toppingsHolderWithTopBorder: {
    borderTop: '1px solid #ededed',
  },
  toppingsHolderLastOne: {
    borderBottom: 0,
  },
  toppingName: {
    width: '38.4%',
    minWidth: '36.2%',
    fontSize: '14px',
  },
  toppingNameND: {
    width: '38.8%',
    minWidth: '38.8%',
    paddingLeft: '20px',
  },
  toppingPrice: {
    fontSize: '14px',
  },
  toppingPriceND: {
    width: '21%',
    minWidth: '21%',
  },
  itemPriceReplacer: {
    width: '21%',
    minWidth: '21%',
  },
  totalPriceReplacer: {
    width: '21%',
    minWidth: '21%',
  },
  actionButtonsContainer: {
    marginBottom: '16px',
  },
  toggleShowBtnContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 16,
  },
}
