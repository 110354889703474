import React, { useEffect, useMemo } from 'react'
import { useCaptureUserAction } from 'hooks/events/useCaptureUserAction'
import { RootWidgetView } from 'components/RootWidgetView'
import { PluggableWidgetDefinition, widgetTypes } from 'types/unitedUiConfig'

interface PluginContainerProps {
  pluginCode: string
}

function PluginContainer({ pluginCode }: PluginContainerProps) {
  const captureUserAction = useCaptureUserAction()

  useEffect(() => {
    captureUserAction('PluginClicked', {
      eventDetails: {
        plugin_code: pluginCode,
      },
    })
  }, [pluginCode, captureUserAction])

  const widgetDefinition = useMemo((): PluggableWidgetDefinition => {
    return {
      type: widgetTypes.plugin,
      plugin_code: pluginCode,
      label: {
        label_translation_key: pluginCode,
        display_rules: {
          hide: true,
        },
      },
    }
  }, [pluginCode])

  return (
    <RootWidgetView
      widgetDefinition={widgetDefinition}
      marginTop={0}
      screenName='main'
      collapseState='disabled'
    />
  )
}

export { PluginContainer }
