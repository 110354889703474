import { ReactNode } from 'react'
import { FC } from 'react'
import isFunction from 'lodash/isFunction'
import { useIsDataPointValid } from 'hooks/useGetValidFeatures'

type ReactFunction = () => ReactNode

const isReactFunction = (arg): arg is ReactFunction => isFunction(arg)

export interface NewDesignProps {
  config: {
    newDesign?: {
      betaRequirement: string[]
    }
  }

  /**
   * extra boolean  parameter if new design should be rendered
   */
  andIf?: boolean

  /**
   * extra boolean parameter indicating if fallback should be rendered.
   */
  andFallbackIf?: boolean

  /**
   * fallback to be rendered if newdesign is not allowed
   */
  fallback?: ReactNode

  children?: ReactFunction | ReactNode
}

/**
 * this method is a helper to render children if newDesign is allowed
 * else it renders fallback
 */
export const NewDesign: FC<NewDesignProps> = ({
  children,
  config,
  andIf = true,
  andFallbackIf = true,
  fallback = null,
}) => {
  const isDataPointValid = useIsDataPointValid()
  const isNewDesignAllowed = isDataPointValid(config?.newDesign?.betaRequirement)

  if (isNewDesignAllowed && andIf) {
    if (isReactFunction(children)) {
      return children()
    }
    return children || null
  }

  if (!isNewDesignAllowed && andFallbackIf) {
    return fallback as any
  }

  return null
}
