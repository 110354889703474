import { ApiService } from 'services/authApi/getPermissions'
import { readAuthPayload } from 'utils/authHelpers'

export const reportMissingItems: ApiService<
  {
    entityId: string
    orderId: string

    vendorName: string
    vendorId: string
    agentEmail?: string
    agentName?: string
    missingItems: string[]
    contactReason: string
    removedItems: any[]
  },
  { id: string }
> = (createClient, options) => {
  const authPayload = readAuthPayload()
  const {
    clientParams,
    entityId,
    orderId,
    vendorName,
    vendorId,
    agentName = authPayload.agentName,
    agentEmail = authPayload.agentEmail,
    missingItems,
    contactReason,
    removedItems,
  } = options

  const uri = `OrdersAPI/v1/orders/${entityId}/${orderId}/missing_items`

  return createClient({
    endpointName: 'reportMissingItems',
    expectedResponseStatusCode: 201,
    responseMustHaveData: true,
    ...clientParams,
  }).post(uri, {
    vendor_name: vendorName,
    vendor_id: vendorId,
    agent_email: agentEmail,
    agent_name: agentName,
    missing_items: missingItems,
    contact_reason: contactReason,
    removed_items: removedItems,
  })
}
