// created voucher details
export enum CreatedVoucherDetails {
  startDate = 'start_date',
  expDate = 'expiration_date',
  openingHour = 'opening_hour',
  closingHour = 'closing_hour',
  minOrderValue = 'minimum_order_value',
  maxOrderValue = 'maximum_order_value',
  paymentTypes = 'payment_types',
}

export interface CompensationPostBody {
  order_id: string
  globalEntityId: string
  customerId: string
  contact_id: string
  compensation_purpose: string
  proposal_event_id?: string
  voucher: CompensationPostBodyVoucher
}

interface CompensationPostBodyVoucher {
  constraints: Array<CompensationPostBodyConstrain>
  channel: string
  value: number
  currency: string
  type: string
  description: string
  purpose: string
}

export interface CompensationPostBodyConstrain {
  type: string
  value: string | number | Array<string | number>
}

// single comp method
export type CompensationMethod = {
  method: string
  translation_key: string
}
