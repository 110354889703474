import { text } from 'theme'

export default {
  popconfirmTitle: {
    margin: 0,
  },
  restoreNumberInput: {
    marginBottom: '4px',
  },
  treeParent: {
    // adding lines
    '& .ant-tree-list-holder-inner  div.ant-tree-treenode:nth-child(n+2) > span.ant-tree-switcher::after':
      {
        position: 'absolute',
        width: '10px',
        height: '14px',
        borderBottom: '1px solid #d9d9d9',
        content: "' '",
      },
    // adding lines
    '& .ant-tree-list-holder-inner  div.ant-tree-treenode:nth-child(n+2) > span.ant-tree-switcher::before':
      {
        position: 'absolute',
        top: '0',
        right: '12px',
        bottom: '-4px',
        marginLeft: '-1px',
        borderRight: '1px solid #d9d9d9',
        content: "' '",
      },
    // take care of line for last element
    '& .ant-tree-list-holder-inner  div.ant-tree-treenode:last-child > span.ant-tree-switcher::before':
      {
        bottom: '10px',
      },
    // fix space on children's left
    '& .ant-tree-indent': {
      display: 'none',
    },
    // fix space on root left
    '& .ant-tree-list-holder-inner  div.ant-tree-treenode:first-child > span.ant-tree-switcher': {
      display: 'none',
    },
    '& .ant-tree-list-holder-inner > .ant-tree-treenode:nth-child(n+2)': {
      fontSize: '12px',
      color: text.secondary,
    },
    '& .ant-tree-list-holder-inner > .ant-tree-treenode': {
      padding: 0,
    },
  },
}
