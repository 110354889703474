/**
 * ORDER Widget: fetches order data
 * renders available order widget tabs for the current entity
 * in case of client failure; renders error UI
 * */

// libs
import React, { useState, useEffect, useContext } from 'react'
// configs
import {
  allowedOrderWidgetTabs,
  allowedScrollablePanelWidgets,
} from 'entityConfig/allowedConfigValues'
// contexts and types
import { DataContext } from 'contexts/data/DataContext'
import OrderTabs from 'types/widgets/order/tabs'
import { LinesOfBusiness } from 'types/session/linesOfBusiness'
// hooks
import { useTranslation } from 'hooks/useTranslation'
import { useEntityConfig } from 'hooks/useEntityConfig'
import { useOrderAnalytics } from 'hooks/useOrderAnalytics'
import { useSessionState } from 'hooks/useSessionState'
//utils

//styles
import { Tabs, Tag } from 'antd'
//components
import Summary from './Summary'
import Status from './Status'
import Payment from './Payment'
import BasketUpdates from './BasketUpdates'
import { WidgetTitle } from 'components/Widget'
import WarningMessage from 'components/WarningMessage'
import WidgetId from 'components/WidgetId'

// Relative Path
import OrderNumber from './OrderID/OrderNumber'
import OrderStatusTag from './OrderStatusTag'
import { useActiveTab } from 'hooks/useActiveTab'
import { NewDesign } from 'components/NewDesign/NewDesign'
import { useGetValidFeatures } from 'hooks/useGetValidFeatures'
import { PluginContainer } from 'components/PluginContainer'
import { usePluginUtils } from 'hooks/usePluginUtils'
import { useUiV1TopLevelTabStyles } from 'hooks/styles/useUiV1TopLevelTabStyles'
import type { useApiService } from 'hooks/useApiService'
import { WidgetErrorHandler } from 'components/WidgetErrorHandler/widgetErrorHandler'
import { RootWidgetView } from 'components/RootWidgetView'
import { builtinWidgetNames, widgetTypes } from 'types'

const Order = ({
  handleRiderNameClick,
  initResult,
}: {
  handleRiderNameClick: (id: number) => void
  initResult: ReturnType<typeof useApiService>
}) => {
  const { TabPane } = Tabs

  // pull language content
  const { t } = useTranslation()

  // pull data state and dispatch from data context
  const {
    dataState: { autoChangeCookingInstComment, orderBasketUpdates, order },
  } = useContext(DataContext)

  const { lineOfBusiness } = useSessionState()

  const { entityConfig, availableActions: available_actions, getWidgetConfig } = useEntityConfig()

  const getValidFeatures = useGetValidFeatures()

  const { isTabAPlugin, getTabPluginConfig } = usePluginUtils()
  // run beta flag checks on tabs and set initial tab
  const [activeTab, setActiveTab] = useActiveTab<OrderTabs | string>('OrderTab')
  const [orderWidgetTabOrder, setOrderWidgetTabOrder] = useState<string[]>()

  const { show_split_deliveries, currency_digits_after_comma } = entityConfig ?? {}
  const orderConfig = getWidgetConfig('order')

  useEffect(() => {
    if (orderConfig?.tab_order) {
      // check for active only tabs
      const orderTabs = orderConfig.tab_order.filter((tab) => tab.active !== false)
      const tabs = getValidFeatures(orderTabs)
      setOrderWidgetTabOrder(tabs)

      if (autoChangeCookingInstComment && tabs.includes(OrderTabs.items)) {
        setActiveTab(OrderTabs.items)
      } else {
        setActiveTab(OrderTabs[tabs[0]])
      }
    }
  }, [autoChangeCookingInstComment, setActiveTab, getValidFeatures, orderConfig])

  // used for analytics capturing
  const { orderItemsCount, uniqueOrderItemsCount, orderPaymentMethod, orderStatus } =
    useOrderAnalytics(order)

  const [deliveryId, setDeliveryId] = useState<number>(0)

  const tabsStyles = useUiV1TopLevelTabStyles()

  const handleRiderStatusClick = (id: number) => {
    setDeliveryId(id)
    setActiveTab(OrderTabs.status, true, {
      order_status: orderStatus,
    })
  }

  const { error, loading, loadService: loadOrder, loadDisabled } = initResult

  return (
    <div>
      <WidgetTitle>
        <NewDesign
          config={orderConfig?.tab_configs.summary}
          fallback={
            <>
              {t('Order Widget.Order')}
              <Tag color='#64bd53'>{`${t('Order Widget.Order ID')}: ${order?.order_id}`}</Tag>
            </>
          }
        >
          <span>
            {t('Order Widget.Order')}
            {order?.order_id && (
              <WidgetId
                id={order?.order_id}
                successMessage={t('Widgets Common.Order ID Copied')}
                warningMessage={t('Widgets Common.Nothing to Copy')}
                errorMessage={t('Widgets Common.Cannot Copy')}
                idLabel={t('Order Widget.ID')}
              />
            )}

            {order?.ov_meta_data?.order_number && (
              <OrderNumber orderNumber={order?.ov_meta_data?.order_number} />
            )}
          </span>

          {order && <OrderStatusTag />}
        </NewDesign>
      </WidgetTitle>

      <WidgetErrorHandler
        onRetry={loadOrder}
        loading={loading}
        errorPayload={error?.errorPayload}
        loadingText={t('Interface.Loading Order')}
        dataUnavailable={loadDisabled}
      >
        {() => {
          return (
            <div>
              {lineOfBusiness === LinesOfBusiness.riderV2 && <WarningMessage />}
              <Tabs
                activeKey={activeTab}
                className={tabsStyles.tabs}
                onChange={(tab: string) => {
                  switch (tab) {
                    case OrderTabs.items:
                      setActiveTab(OrderTabs[tab], true, {
                        total_item_count: orderItemsCount,
                        unique_item_count: uniqueOrderItemsCount,
                      })
                      break

                    case OrderTabs.invoice:
                    case OrderTabs.payment:
                      setActiveTab(OrderTabs[tab], true, {
                        payment_method: orderPaymentMethod,
                      })
                      break

                    case OrderTabs.status:
                      setActiveTab(OrderTabs[tab], true, {
                        order_status: orderStatus,
                      })
                      break

                    default:
                      if (isTabAPlugin(allowedScrollablePanelWidgets.order, tab)) {
                        setActiveTab(tab, true)
                      } else {
                        setActiveTab(OrderTabs[tab], true)
                      }
                      break
                  }
                }}
                type='card'
                size={'small'}
              >
                {orderWidgetTabOrder
                  ? orderWidgetTabOrder.map((tab: string) => {
                      switch (tab) {
                        case allowedOrderWidgetTabs.summary:
                          return (
                            <TabPane tab={t('Widgets Common.Summary')} key={OrderTabs.summary}>
                              <Summary
                                summary={orderConfig?.tab_configs.summary}
                                availableActions={available_actions}
                                showSplitDeliveries={show_split_deliveries}
                                currencyDigitsAfterComma={currency_digits_after_comma}
                                onRiderNameClick={handleRiderNameClick}
                                onRiderStatusClick={handleRiderStatusClick}
                              />
                            </TabPane>
                          )
                        case allowedOrderWidgetTabs.items:
                          return (
                            <TabPane tab={t('Order Widget.Tabs.Items.Title')} key={OrderTabs.items}>
                              <RootWidgetView
                                widgetDefinition={{
                                  type: widgetTypes.builtin,
                                  widget_name: builtinWidgetNames.orderItems,
                                }}
                                screenName='order_widgets'
                              />
                            </TabPane>
                          )
                        case allowedOrderWidgetTabs.status:
                          return (
                            <TabPane
                              tab={t('Order Widget.Tabs.Status.Title')}
                              key={OrderTabs.status}
                            >
                              <Status
                                deliveryId={deliveryId}
                                status={orderConfig.tab_configs.status}
                                showSplitDeliveries={show_split_deliveries}
                              />
                            </TabPane>
                          )
                        case allowedOrderWidgetTabs.payment:
                          return (
                            <TabPane
                              tab={t('Order Widget.Tabs.Payment.Title')}
                              key={OrderTabs.payment}
                            >
                              <Payment
                                payment={orderConfig?.tab_configs.payment}
                                items={orderConfig?.tab_configs.items}
                              />
                            </TabPane>
                          )
                        case allowedOrderWidgetTabs.basket_updates:
                          return (
                            <TabPane
                              tab={
                                orderBasketUpdates?.length
                                  ? `${t('Order Widget.Tabs.Basket Updates.Title')} (${
                                      orderBasketUpdates.length
                                    })`
                                  : `${t('Order Widget.Tabs.Basket Updates.Title')}`
                              }
                              key={OrderTabs.basket_updates}
                            >
                              <BasketUpdates />
                            </TabPane>
                          )
                        case allowedOrderWidgetTabs.invoice:
                          return (
                            <TabPane
                              tab={t('Order Widget.Tabs.Invoice.Title')}
                              key={OrderTabs.invoice}
                            >
                              <RootWidgetView
                                widgetDefinition={{
                                  type: widgetTypes.builtin,
                                  widget_name: builtinWidgetNames.orderInvoice,
                                }}
                                screenName='order_widgets'
                              />
                            </TabPane>
                          )
                      }

                      if (isTabAPlugin(allowedScrollablePanelWidgets.order, tab)) {
                        const pluginTab = getTabPluginConfig(
                          allowedScrollablePanelWidgets.order,
                          tab,
                        )
                        return (
                          <TabPane tab={t(pluginTab.title)} key={pluginTab.plugin_code}>
                            <PluginContainer pluginCode={pluginTab.plugin_code} />
                          </TabPane>
                        )
                      }

                      return null
                    })
                  : null}
              </Tabs>
            </div>
          )
        }}
      </WidgetErrorHandler>
    </div>
  )
}

export default Order
