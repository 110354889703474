import { ApiService } from 'services/authApi/getPermissions'
import { TicketDetailsApiResponse } from 'types/api/ticketApi/getTicketDetails'

export const getTicketDetails: ApiService<
  {
    entityId: string
    caseId: string
  },
  TicketDetailsApiResponse
> = (createClient, options) => {
  const { entityId, caseId, clientParams } = options

  const uri = `/TicketAPI/v1/ticket/${entityId}/${caseId}`

  return createClient({
    endpointName: 'getTicketDetails',
    expectedResponseStatusCode: 200,
    ...clientParams,
  }).get(uri)
}
