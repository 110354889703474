import React, { useImperativeHandle, useMemo } from 'react'

import { Skeleton } from 'antd'
import { createUseStyles } from 'react-jss'
import { BuiltinWidgetConfigs } from 'types'
import { DataPointProps, DataPoints, Flex, Text } from 'shared'

import { logError } from 'utils/reporting/logError'
import { MapView } from 'components/MapView/MapView'
import { useTranslation } from 'hooks/useTranslation'
import { useLoadRider } from 'hooks/apiHooks/useLoadRider'
import transformOrder from 'utils/miniUtils/transformOrder'
import { createPluggableWidget } from 'factory/createPluggableWidget'
import { PlaceholderMap } from 'components/PlaceholderMap/PlaceholderMap'
import { transformRiderFulfillment } from 'utils/dataTransformers/transformRiderFulfillment'

const useStyles = createUseStyles({
  emptyMap: {
    maxWidth: 'none',
  },
})

export const LiveTracking = createPluggableWidget<BuiltinWidgetConfigs['live_tracking']>(
  ({ order, config, rider, sdk, riderId, ErrorRenderer }, ref) => {
    const { t } = useTranslation()
    const { captureUserAction, checkDisplayRules } = sdk
    const classes = useStyles()
    const riderFulfillment =
      rider && riderId ? transformRiderFulfillment(rider, riderId) : undefined

    const transformedOrder = order ? transformOrder(order) : undefined
    const transformedVendor = order && order.vendor ? order.vendor.name : undefined
    const activeOrderId = rider?.current_order?.order_id
    const heading = `${t('widgets.live_tracking.active_order')} : ${activeOrderId ?? '-'}`

    const dataPoints = useMemo(() => {
      return config.data_points.map((dataPoint): DataPointProps => {
        const label = t(dataPoint.label_translation_key)

        const { display_rules, name } = dataPoint

        const isDataPointEnabled = () => {
          return checkDisplayRules({
            displayRules: display_rules,
          }).visible
        }

        switch (name) {
          case 'city':
            if (isDataPointEnabled()) {
              return {
                type: 'default',
                value: order?.delivery?.location?.city,
                name,
                label,
                colSpan: 12,
              }
            }
            break

          case 'delivery_zone':
            if (isDataPointEnabled()) {
              return {
                type: 'default',
                value: rider?.courier?.starting_point?.name,
                name,
                label,
                colSpan: 12,
              }
            }
            break

          default:
            logError({
              type: 'missing-datapoint-handler',
              originalError: new Error(
                `${name} is configured as a dataPoint yet doesn't have a handling case`,
              ),
            })
        }
        return null
      })
    }, [
      config.data_points,
      order?.delivery?.location?.city,
      rider?.courier?.starting_point?.name,
      t,
      checkDisplayRules,
    ])

    const {
      loading: isLoadingFetchRiderData,
      loadService: refreshRider,
      error: errorLoadingRiderData,
    } = useLoadRider()

    // Show refresh action in header
    useImperativeHandle(
      ref,
      () => {
        return {
          onRefresh: async () => {
            captureUserAction('REFRESH_RIDER_LOCATION', { reportToEts: true })
            refreshRider()
          },
        }
      },
      [refreshRider, captureUserAction],
    )

    if (isLoadingFetchRiderData) {
      return <Skeleton />
    }

    return (
      <ErrorRenderer
        loading={isLoadingFetchRiderData}
        errorPayload={errorLoadingRiderData?.errorPayload}
      >
        {() => (
          <Flex flexDirection='column' gap={'20px'}>
            <DataPoints dataPoints={dataPoints} columnCount={2} w={'100%'} />
            {/* Show empty map in case of no active order */}
            {!activeOrderId && (
              <Flex flexDirection='column' gap={'8px'} pb={'32px'}>
                <Text.Text fontWeight={'500'}>{heading}</Text.Text>
                <PlaceholderMap
                  containerClassName={classes.emptyMap}
                  emptyMapMessage={t('widgets.live_tracking.empty_state')}
                />
              </Flex>
            )}
            {activeOrderId && (
              <Flex flexDirection='column' gap={'8px'} pb={'32px'}>
                <Text.Text fontWeight={'500'}>{heading}</Text.Text>
                <MapView
                  customerLocation={riderFulfillment?.currentCustomerLocation}
                  vendorLocation={riderFulfillment?.currentVendorLocation}
                  riderLocations={[riderFulfillment?.currentRiderLocation]}
                  deliveryInstructions={transformedOrder?.deliveryInstructions}
                  dropOffAddress={transformedOrder?.deliveryAddress}
                  vendorName={transformedVendor}
                  defaultZoom={13}
                  containerClassnameKey={'widgetContainer'}
                  showRefreshStatus
                  handleRefreshClick={refreshRider}
                />
              </Flex>
            )}
          </Flex>
        )}
      </ErrorRenderer>
    )
  },
  {
    deriveConfig({ entityConfig }) {
      return entityConfig.layout_v2.builtin_widgets_configs.live_tracking
    },
  },
)
