import React, { createContext, useReducer, Dispatch } from 'react'
import { riderServiceReducer } from './RiderServiceReducer'
import { InitialState, RiderServiceActions } from './types'

const initialState = {
  isNewComment: false,
  isCancelOrder: false,
  newCommentId: null,
  order: null,
  orderError: null,
  orderStatus: null,
  orderStatusError: null,
  orderFulfillment: null,
  orderFulfillmentError: null,
  rider: null,
  riderError: null,
  customer: null,
  customerError: null,
  vendor: null,
  vendorError: null,
  riderCustomerChatTranscript: null,
}

const RiderServiceContext = createContext<{
  riderServiceState: InitialState
  riderServiceDispatch: Dispatch<RiderServiceActions>
}>({
  riderServiceState: initialState,
  riderServiceDispatch: () => null,
})

const RiderServiceProvider: React.FC = ({ children }) => {
  const [riderServiceState, riderServiceDispatch] = useReducer(riderServiceReducer, initialState)
  return (
    <RiderServiceContext.Provider value={{ riderServiceState, riderServiceDispatch }}>
      {children}
    </RiderServiceContext.Provider>
  )
}

export { RiderServiceProvider, RiderServiceContext }
