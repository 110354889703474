import React from 'react'
import { Typography } from 'antd'
import { createUseStyles } from 'react-jss'
import { Trans } from 'react-i18next'
import { useTranslation } from 'hooks/useTranslation'
import { SingleComment } from 'types/api/commentApi/getOrderComments'
import styles from './Comments.styles'
import { getAgentNameFromId } from 'utils/string'
import { commentTagType, CommentView } from './CommentView'
import { WidgetSize } from 'types/widgets/historyOfEvents/tabs'

const { Text } = Typography

const useStyles = createUseStyles(styles)

type Props = {
  comment: SingleComment
  shouldCommentBeHighlighted: boolean
  mode: WidgetSize
}

export const ExecuteDeviceActionComment = ({
  comment,
  mode,
  shouldCommentBeHighlighted,
}: Props) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const details = comment.additional_data?.execute_device_action

  return (
    <CommentView
      mode={mode}
      comment={comment}
      shouldCommentBeHighlighted={shouldCommentBeHighlighted}
      tag={{
        type: commentTagType.thunderbolt,
        text: t('History of Events Widget.Event Types.Execute Device Action'),
      }}
    >
      <Text className={classes.commentText}>
        <Text>{`${getAgentNameFromId(comment.agent_id)}: `}</Text>

        <Text>
          <Trans t={t} i18nKey={'History of Events Widget.Content.Executed Action'}>
            Executed Action <strong>{{ action: details?.action }}</strong> on Device{' '}
            {{ device: details?.device_id }}
          </Trans>
        </Text>
      </Text>
    </CommentView>
  )
}
