import React, { useCallback, useContext } from 'react'
import { InfoCircleOutlined } from '@ant-design/icons'
import { OrderWidgetSummary } from 'contexts/entity/types'
import { useCreateFilterRenderableDataPoint } from 'hooks/filters/useCreateFilterRenderableDataPoint'
import { useDateTimeFormatter } from 'hooks/formatters/useDateTimeFormatter'
import { useNumberFormatter } from 'hooks/formatters/useNumberFormatter'
import { useTranslation } from 'hooks/useTranslation'

import { OrderApiResponse, OrderFlagsApiResponse } from 'types/api/orderApi/order'
import { TranformedTags, TransformedDataPoint } from 'types/dataTransformers/generic'
import { unknownDataPoint, unknownTime } from 'utils/dataTransformers/constants'

import { PaymentMethodListItemDataPoint } from 'components/DatapointListItemRenderers/PaymentMethodListItemDataPoint/PaymentMethodListItemDataPoint'
import { orderSummaryTabDataPointConfigs } from 'constants/tabDataPointConfigs/orderSummaryTabDataPointConfigs'
import { DataContext } from 'contexts/data/DataContext'
import { useGetOrderStatusMetadata } from './useGetOrderStatusMetadata'
import { getOrderPaidValue } from 'utils/getters/getOrderPaidValue'
import { useGetAddressDetailsToolTipContent } from 'hooks/useGetAddressDetailsToolTipContent'

import { DataPoint } from 'shared/DataPoint'
import { StatusColors } from 'theme'
import { Text } from 'shared/Text'

export const useTransformOrderSummary = () => {
  const { t } = useTranslation()
  const getOrderStatusMetadata = useGetOrderStatusMetadata()
  const numberFormatter = useNumberFormatter()
  const datetimeFormatter = useDateTimeFormatter()
  const createFilterRenderableDataPoint = useCreateFilterRenderableDataPoint()
  const {
    dataState: { modifiedDeliverySettings, autoChangeDeliveryTimeComment },
  } = useContext(DataContext)

  const getAddressDetailsToolTipContent = useGetAddressDetailsToolTipContent()

  return useCallback(
    (
      data: OrderApiResponse,
      orderFlags: OrderFlagsApiResponse | null,
      opts: {
        config: OrderWidgetSummary
        useNewDesign?: boolean
        displayPayableDataPoint?: boolean
      },
    ) => {
      const { useNewDesign = false, config, displayPayableDataPoint } = opts
      const filterRenderableDataPoint = createFilterRenderableDataPoint(config, {
        dropItemsWithEmptyValue: true,
      })
      const addressToolTipContent = getAddressDetailsToolTipContent(data?.delivery?.location)

      const { statusText, reason, reasonLabel, statusColor } = getOrderStatusMetadata(data)

      const transformedLeftColumnData: TransformedDataPoint[] = [
        {
          key: orderSummaryTabDataPointConfigs.numberOfItems.key,
          label: t(orderSummaryTabDataPointConfigs.numberOfItems.labelTranslationKey),
          value: data.order.items.length.toString() || unknownDataPoint,
        },
        {
          key: orderSummaryTabDataPointConfigs.paidAmount.key,
          label: t(orderSummaryTabDataPointConfigs.paidAmount.labelTranslationKey),
          value: numberFormatter.formatMoney({
            currency: data.currency,
            amount: getOrderPaidValue({ order: data }),
          }),
        },

        useNewDesign && {
          key: orderSummaryTabDataPointConfigs.paymentMethod.key,
          label: t(orderSummaryTabDataPointConfigs.paymentMethod.labelTranslationKey),
          value: data.customer.payment.payment_method || unknownDataPoint,
          Render: () => {
            return <PaymentMethodListItemDataPoint order={data} />
          },
        },

        !useNewDesign && {
          key: orderSummaryTabDataPointConfigs.currentStatus.key,
          label: t(orderSummaryTabDataPointConfigs.currentStatus.labelTranslationKey),
          value: statusText,
        },

        {
          key: orderSummaryTabDataPointConfigs.currentStatusReason.key,
          label: reasonLabel,
          value: reason,
          color: statusColor,
        },
      ].filter(filterRenderableDataPoint)

      const transformedRightColumnData: TransformedDataPoint[] = [
        !useNewDesign && {
          key: orderSummaryTabDataPointConfigs.timeOrderCreated.key,
          label: t(orderSummaryTabDataPointConfigs.timeOrderCreated.labelTranslationKey),
          value: datetimeFormatter.formatDateTime(data.place_timestamp) || unknownTime,
        },

        // promised delivery time
        !useNewDesign && {
          key: orderSummaryTabDataPointConfigs.promisedDeliveryTime.key,
          label: t(orderSummaryTabDataPointConfigs.promisedDeliveryTime.labelTranslationKey),
          value: datetimeFormatter.formatDateTime(data.promised_customer_timestamp) || unknownTime,
          highlight: Boolean(autoChangeDeliveryTimeComment),
        },

        // delivery address
        {
          key: orderSummaryTabDataPointConfigs.deliveryAddress.key,
          label: t(orderSummaryTabDataPointConfigs.deliveryAddress.labelTranslationKey),
          value: data.delivery.location.address_text || unknownDataPoint,
          EndIcon: InfoCircleOutlined,
          highlight: Boolean(modifiedDeliverySettings?.formatted_address),
          tooltip: addressToolTipContent,
          showTooltip: filterRenderableDataPoint({
            key: orderSummaryTabDataPointConfigs.fullAddressTooltip.key,
            value: 'true',
          }),
        },

        // delivery instruction
        {
          key: orderSummaryTabDataPointConfigs.deliveryInstructions.key,
          label: t(orderSummaryTabDataPointConfigs.deliveryInstructions.labelTranslationKey),
          value: data.delivery.location.description || unknownDataPoint,
          highlight: Boolean(modifiedDeliverySettings?.instructions),
        },
        displayPayableDataPoint && {
          key: orderSummaryTabDataPointConfigs.payoutStatus.key,
          label: t(orderSummaryTabDataPointConfigs.payoutStatus.labelTranslationKey),
          value: unknownDataPoint,

          Render: ({ item: datapoint }) => {
            if (!orderFlags) {
              return (
                <DataPoint
                  type='default'
                  value={datapoint?.value}
                  label={datapoint?.label}
                  key={datapoint?.key}
                  name={datapoint?.key}
                  display_rules={null}
                />
              )
            }
            return (
              <DataPoint
                type='status'
                value={{
                  statusColor: orderFlags?.is_billable ? StatusColors.green : StatusColors.red,
                  statusText: orderFlags?.is_billable
                    ? t('Order Widget.Payable')
                    : t('Order Widget.Not Payable'),
                }}
                key={datapoint?.key}
                label={datapoint.label}
                name={datapoint?.key}
              />
            )
          },
        },
        {
          key: orderSummaryTabDataPointConfigs.orderSource.key,
          label: t(orderSummaryTabDataPointConfigs.orderSource.labelTranslationKey),
          value: unknownDataPoint,
          Render: ({ item: datapoint }) => {
            // find the status history item with a status of 'ACCEPTED' AND an existing transmission object
            const statusItem = data.status_history?.find(
              (statusItem) =>
                statusItem.status === 'ACCEPTED' && statusItem?.accepted?.transmission,
            )

            // if all the fields are not found display a default datapoint
            if (
              [
                statusItem?.accepted?.source,
                statusItem?.accepted?.transmission?.provider,
                statusItem?.accepted?.transmission?.method,
                statusItem?.accepted?.transmission?.application_client,
              ].every((item) => !item)
            ) {
              return (
                <DataPoint
                  type='default'
                  value={datapoint?.value}
                  label={datapoint?.label}
                  key={datapoint?.key}
                  name={datapoint?.key}
                />
              )
            }
            return (
              <DataPoint
                type='custom'
                label={datapoint.label}
                name={datapoint?.key}
                render={
                  <Text.Primary>
                    <div>{statusItem?.accepted?.source ?? null}</div>
                    <div>{statusItem?.accepted?.transmission?.provider ?? null}</div>
                    <div>{statusItem?.accepted?.transmission?.method ?? null}</div>
                    <div>{statusItem?.accepted?.transmission?.application_client ?? null}</div>
                  </Text.Primary>
                }
              />
            )
          },
        },
      ].filter(filterRenderableDataPoint)

      const transformedTagsData: TranformedTags[] = [
        useNewDesign && {
          key: orderSummaryTabDataPointConfigs.joker.key,
          label: t(orderSummaryTabDataPointConfigs.joker.labelTranslationKey),
          value: data.vendor?.charges?.joker_discount_fee ? 'Joker' : false,
        },

        {
          key: orderSummaryTabDataPointConfigs.corporate.key,
          label: t(orderSummaryTabDataPointConfigs.corporate.labelTranslationKey),
          value: data.corporate ? 'Corporate' : false,
        },

        {
          key: orderSummaryTabDataPointConfigs.preOrder.key,
          label: t(orderSummaryTabDataPointConfigs.preOrder.labelTranslationKey),
          value: data.preorder ? 'Pre-Order' : false,
        },

        {
          key: orderSummaryTabDataPointConfigs.application.key,
          label: t(orderSummaryTabDataPointConfigs.application.labelTranslationKey),
          value:
            data.customer && data.customer.channel && data.customer.channel.type
              ? `${data.customer.channel.type}`
              : false,
        },

        {
          key: orderSummaryTabDataPointConfigs.deliveryProvider.key,
          label: t(orderSummaryTabDataPointConfigs.deliveryProvider.labelTranslationKey),
          value:
            data.delivery && data.delivery.provider
              ? data.delivery.provider.replace(/_/g, ' ')
              : unknownDataPoint,
        },
        !useNewDesign && {
          key: orderSummaryTabDataPointConfigs.platform.key,
          label: t(orderSummaryTabDataPointConfigs.platform.labelTranslationKey),
          value: `${data.brand_name} - ${data.country_code}` || unknownDataPoint,
        },
      ].filter(filterRenderableDataPoint)

      return {
        transformedLeftColumnData,
        transformedRightColumnData,
        transformedTagsData,
      }
    },
    [
      createFilterRenderableDataPoint,
      getAddressDetailsToolTipContent,
      getOrderStatusMetadata,
      t,
      numberFormatter,
      datetimeFormatter,
      autoChangeDeliveryTimeComment,
      modifiedDeliverySettings,
    ],
  )
}
