/**
 * InfoAlertView: renders a timeline of the passed compensations and refunds
 * */

// libs
import React, { useState, useMemo } from 'react'
// types
import { Compensation, Refund } from 'types/api/voucherApi/getCompensationsAndRefunds'
// hooks
import { useTranslation } from 'hooks/useTranslation'
// utils
import prettifyString from 'utils/prettifyString'
import modifyUTC from 'utils/modifyUTC'
import capitalizeFirstLetters from 'utils/capitalizeFirstLetters'
// styles
import { createUseStyles } from 'react-jss'
import styles from './InfoAlertView.styles'
import { Alert, Button, Typography, Timeline, Tag } from 'antd'
import { useCaptureUserAction } from 'hooks/events/useCaptureUserAction'

interface Props {
  utcZone: string
  compensations?: Compensation[] | null
  refunds?: Refund[] | null
  parent: string
}

const useStyles = createUseStyles(styles)

/**
 * cts banner
 */
const InfoAlertView: React.FC<Props> = ({ utcZone, compensations, refunds, parent }) => {
  const classes = useStyles()
  const { Text } = Typography

  const captureUserAction = useCaptureUserAction()

  // pull language content
  const { t } = useTranslation()

  const [isSeeAll, setIsSeeAll] = useState(false)

  const { numberOfCompensations, numberOfRefunds, compensationsAndRefundsSorted } = useMemo(() => {
    return {
      numberOfCompensations: compensations?.length || 0,
      numberOfRefunds: refunds?.length || 0,
      compensationsAndRefundsSorted: []
        .concat(compensations || [])
        .concat(refunds || [])
        .sort((a, b) => -a.processed_timestamp.localeCompare(b.processed_timestamp)) as Array<
        Compensation | Refund
      >,
    }
  }, [compensations, refunds])

  const handleSeeAllClick = () => {
    if (!isSeeAll) {
      captureUserAction(`Actions${parent}CtsSeeAllClicked`)
    }
    setIsSeeAll(!isSeeAll)
  }

  return (
    <div>
      <Alert
        showIcon
        message={
          <div>
            <div className={classes.textAndbuttonContainer}>
              <Text>
                {numberOfCompensations && numberOfRefunds
                  ? `${t('Messages.Order already compensated and refunded')}: ${t(
                      'Messages.Compensations',
                    )}: ${numberOfCompensations}, ${t('Messages.Refunds')}: ${numberOfRefunds}`
                  : numberOfCompensations
                  ? `${t('Messages.Order already compensated')}! ${t(
                      'Messages.Compensations',
                    )}: ${numberOfCompensations}`
                  : numberOfRefunds
                  ? `${t('Messages.Order already refunded')}! ${t(
                      'Messages.Refunds',
                    )}: ${numberOfRefunds}`
                  : null}
              </Text>
              <Button size='small' type='primary' onClick={handleSeeAllClick}>
                {isSeeAll ? t('Interface.Close') : t('Interface.See All')}
              </Button>
            </div>
            {!isSeeAll && (
              <Text className={classes.subMsg}>
                {t(
                  'Messages.Click to see all compensations and refunds issued for the current order',
                )}
              </Text>
            )}
          </div>
        }
        type='warning'
        banner={true}
        description={
          isSeeAll &&
          compensationsAndRefundsSorted.length > 0 && (
            <div className={classes.descContainer}>
              <Timeline className={classes.timeline}>
                {compensationsAndRefundsSorted.map((item: Compensation | Refund, idx: number) => {
                  if (item.compensation_value) {
                    return (
                      <Timeline.Item key={idx}>
                        <div>
                          <Tag color='blue'>
                            {t('History of Events Widget.Event Types.Compensation')}
                          </Tag>
                          <Text className={classes.timeText}>{`– ${modifyUTC(
                            item.processed_timestamp,
                            utcZone,
                          )}`}</Text>
                        </div>

                        <Text className={classes.boldText}>{`${t(
                          'Customer Widget.Tabs.Vouchers.Value',
                        )}:`}</Text>
                        <Text>{` ${item.currency_code} ${item.compensation_value}`}</Text>

                        {item.compensation_purpose ? (
                          <React.Fragment>
                            <Text className={classes.boldText}>{`, ${t(
                              'Order Widget.Tabs.Status.Reason',
                            )}:`}</Text>
                            <Text>{` ${prettifyString(item.compensation_purpose)}`}</Text>
                          </React.Fragment>
                        ) : null}

                        {item.contact_type ? (
                          <React.Fragment>
                            <Text className={classes.boldText}>{`, ${t(
                              'Actions Widget.Actions.Compensation.Source',
                            )}:`}</Text>
                            {item.contact_type === 'Autocomp' ? (
                              <Text>{` AutoComp`}</Text>
                            ) : item.contact_type === 'autoPartialRefund' ? (
                              <Text>{` HelpCenter`}</Text>
                            ) : item.contact_type === 'ChatSalesforce' ? (
                              <Text>{` Oneview`}</Text>
                            ) : (
                              <Text>{item.contact_type}</Text>
                            )}
                          </React.Fragment>
                        ) : null}
                      </Timeline.Item>
                    )
                  }

                  return (
                    <Timeline.Item key={idx}>
                      <div>
                        <Tag color='blue'>
                          {item.refund_purpose === 'order_refund'
                            ? t('History of Events Widget.Event Types.Full Refund')
                            : t('History of Events Widget.Event Types.Partial Refund')}
                        </Tag>
                        <Text className={classes.timeText}>{`- ${modifyUTC(
                          item.processed_timestamp,
                          utcZone,
                        )}`}</Text>
                      </div>

                      <Text className={classes.boldText}>{`${t('Widgets Common.Method')}: `}</Text>
                      <Text>{`${capitalizeFirstLetters(item.payment_medium)}, `}</Text>

                      {item.payment_medium === 'Voucher' && (
                        <React.Fragment>
                          <Text className={classes.boldText}>{`${t(
                            'Customer Widget.Tabs.Vouchers.Code',
                          )}:`}</Text>
                          <Text>{` ${item.voucher_id}, `}</Text>
                        </React.Fragment>
                      )}

                      <Text className={classes.boldText}>{`${t(
                        'Customer Widget.Tabs.Vouchers.Value',
                      )}:`}</Text>
                      <Text>{` ${item.currency_code} ${item.refund_value}`}</Text>

                      {item.refund_purpose ? (
                        <React.Fragment>
                          <Text className={classes.boldText}>{`, ${t(
                            'Order Widget.Tabs.Status.Reason',
                          )}:`}</Text>
                          <Text>{` ${prettifyString(item.refund_purpose)}`}</Text>
                        </React.Fragment>
                      ) : null}

                      {item.contact_type ? (
                        <React.Fragment>
                          <Text className={classes.boldText}>{`, ${t(
                            'Actions Widget.Actions.Compensation.Source',
                          )}:`}</Text>
                          {item.contact_type === 'Autocomp' ? (
                            <Text>{` AutoComp`}</Text>
                          ) : item.contact_type === 'autoPartialRefund' ? (
                            <Text>{` HelpCenter`}</Text>
                          ) : item.contact_type === 'ChatSalesforce' ? (
                            <Text>{` Oneview`}</Text>
                          ) : (
                            <Text>{item.contact_type}</Text>
                          )}
                        </React.Fragment>
                      ) : null}
                    </Timeline.Item>
                  )
                })}
              </Timeline>
            </div>
          )
        }
      />
    </div>
  )
}

export default InfoAlertView
