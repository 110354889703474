import { gold, green, red } from 'theme'
import { RefundStatus, TransactionStatusDisplay } from 'types/api/orderApi/order'

export const refundStatusesColorMappings: { [p in RefundStatus]: string } = {
  created: green.green6,
  success: green.green6,
  requested: gold.gold6,
  unknown: gold.gold6,
  failed: red.red6,
  reversed: green.green6,
  fraud_refusal: red.red6,
  invalid_amount: red.red6,
  invalid_status: red.red6,
  no_payments_to_refund: red.red6,
  rejected_by_gateway: red.red6,
  in_progress: gold.gold6,
  ask_user: gold.gold6,
  pending_manual: gold.gold6,
  pending_retry: gold.gold6,
}

export const paymentStatusesColorMappings: { [p in TransactionStatusDisplay]: string } = {
  paid: green.green6,
  pending: gold.gold6,
  failed: red.red6,
  aborted: red.red6,
  unknown: gold.gold6,
  reserved: gold.gold6,
}
