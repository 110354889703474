import React, { useMemo, useState } from 'react'
import { Space, Table, Tooltip } from 'antd'
import { Dot, Text } from 'shared'
import { createUseStyles } from 'react-jss'
import { OrderApiResponse } from 'types/api/orderApi/order'

import styles from './RiderStackedOrdersList.style'
import { Flex } from 'shared'
import { TagFilled } from 'Icons'
import { primary } from 'theme'
import classNames from 'classnames'
import { calculateOrderDeliveryTimestamps } from 'utils/order/calculateOrderDeliveryTimestamps'
import { useSdk } from 'contexts/SdkProvider'

const useStyles = createUseStyles(styles)

export interface RiderStackedOrdersListProps {
  title?: string
  loadedOrderId: string
  ticketOrderId: string
  pageSize?: number
  orders: OrderApiResponse[]
}

export const RiderStackedOrdersList = ({
  title,
  orders = [],
  loadedOrderId,
  ticketOrderId,
  pageSize = 10,
}: RiderStackedOrdersListProps) => {
  const classes = useStyles()
  const sdk = useSdk()
  const { t, datetimeFormatter, getCustomerName, getOrderStatusMetadata } = sdk

  const [page, setPage] = useState(1)

  const pageOrders = useMemo(() => {
    const sliceStart = (page - 1) * pageSize
    return orders.slice(sliceStart, sliceStart + pageSize)
  }, [orders, page, pageSize])

  const columns = useMemo(() => {
    return [
      {
        title: t('widgets.rider_stacked_orders.promised'),
        key: 'promised',
        render: (_, order: OrderApiResponse) => {
          const { delayTime, promisedDropOffTime } = calculateOrderDeliveryTimestamps({
            order,
            datetimeFormatter,
          })
          const estimatedDiff = parseInt(delayTime?.text)
          return (
            <div>
              <Text.Primary display='block'>
                {datetimeFormatter.formatTime(promisedDropOffTime, {
                  format: 'h:mm A',
                })}
              </Text.Primary>
              {/* Only show non zero diff b/w estimated and promised time */}
              {Boolean(estimatedDiff) && (
                <Text.Secondary>
                  {
                    /* Show a plus sign before positive value */
                    estimatedDiff > 0 ? `+` : ``
                  }
                  {`${estimatedDiff} ${t('widgets.rider_stacked_orders.min')}`}
                </Text.Secondary>
              )}
            </div>
          )
        },
      },
      {
        title: t('widgets.rider_stacked_orders.order'),
        key: 'order',
        render: (_, order: OrderApiResponse) => {
          const { statusColor, statusText, status } = getOrderStatusMetadata({ order })
          return (
            <div>
              <Text.Primary Component='span' display='block' fontWeight='500' whiteSpace='nowrap'>
                {order.order_id}
                {/* Show a ticket tag next to orderId where applicable */}
                {order.order_id === ticketOrderId && (
                  <Tooltip title={t('widgets.rider_stacked_orders.ticket_order')}>
                    <Text.Primary Component='span' color={primary.primary6} pl={4} fontSize={12}>
                      <TagFilled />
                    </Text.Primary>
                  </Tooltip>
                )}
              </Text.Primary>

              <Space size={8}>
                <Tooltip
                  title={datetimeFormatter.formatTime(status?.timestamp, {
                    format: 'h:mm A',
                  })}
                >
                  <Dot color={statusColor} />
                </Tooltip>
                <Text.Primary>{statusText}</Text.Primary>
              </Space>
            </div>
          )
        },
      },
      {
        title: t('widgets.rider_stacked_orders.vendor'),
        key: 'vendor',
        render: (_, order: OrderApiResponse) => (
          <div>
            <Text.Primary>{order.vendor?.name || '-'}</Text.Primary>
            <Text.Secondary>{order.vendor?.id || '-'}</Text.Secondary>
          </div>
        ),
      },
      {
        title: t('widgets.rider_stacked_orders.customer'),
        key: 'customer',
        render: (_, order: OrderApiResponse) => (
          <div>
            <Text.Primary>
              {getCustomerName({ customer: order.customer, fallbackValue: '-' })}
            </Text.Primary>
            <Text.Secondary>{order?.customer?.customer_id || '-'}</Text.Secondary>
          </div>
        ),
      },

      {
        title: t('widgets.rider_stacked_orders.created'),
        key: 'created',
        render: (_, order: OrderApiResponse) => (
          <div>
            <Text.Primary display='block' whiteSpace={'nowrap'}>
              {datetimeFormatter.formatTime(order.place_timestamp, {
                format: 'h:mm A',
              })}
            </Text.Primary>
            <Text.Secondary>{datetimeFormatter.formatDate(order.place_timestamp)}</Text.Secondary>
          </div>
        ),
      },
    ]
  }, [t, datetimeFormatter, getOrderStatusMetadata, getCustomerName, ticketOrderId])

  const ordersCount = orders.length

  return (
    <>
      <Flex justifyContent={'space-between'} alignItems={'center'} mb={24}>
        <div>
          <Space>
            <Text.Primary fontWeight='500'>
              {title || t(`widgets.orders_list.results.title`)}
            </Text.Primary>
            <Text.Secondary className={classes.itemsCountsTag}>{ordersCount}</Text.Secondary>
          </Space>
        </div>
      </Flex>

      {ordersCount > 0 && (
        <Table
          dataSource={pageOrders}
          columns={columns}
          className={classes.table}
          rowClassName={(order: OrderApiResponse) => {
            return classNames(order?.order_id === loadedOrderId ? 'active-order' : '')
          }}
          pagination={{
            pageSize,
            total: orders.length,
            current: page,
            showSizeChanger: false,
            onChange: (page) => {
              const hasPageResult = ordersCount > (page - 1) * pageSize
              if (hasPageResult) {
                setPage(page)
              }
            },
          }}
        />
      )}
    </>
  )
}
