/**
 * Order Widget Items Tab
 * runs config checks, renders items as Panel headers and payment details as description items
 * */

// libs
import React, { useState, useContext, useMemo, useCallback, MouseEventHandler } from 'react'
// configs
// contexts and types
import { DataContext } from 'contexts/data/DataContext'
// hooks
// utils
import transformOrderItems from 'utils/dataTransformers/transformOrderItems'

import { createUseStyles } from 'react-jss'

import { Button } from 'shared/Button'
import { useTranslation } from 'hooks/useTranslation'
import { WidgetActionHandlers } from 'shared/WidgetActionHandlers'
import { createPluggableWidget } from 'factory/createPluggableWidget'
import { Space, Table, Tooltip } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import { ArrayValue, BuiltinWidgetConfigs } from 'types'
import { Box } from 'shared/Box'
import { Text } from 'shared/Text'
import SingleTag from 'components/Tags/SingleTag'
import { ChefHatIcon, WeightIcon } from 'Icons'
import { ItemImageIcon } from './ItemImageIcon/ItemImageIcon'
import ItemRequirementIcon from '../ItemRequirementIcon'
import { DownOutlined, UpOutlined } from '@ant-design/icons'
import { gray, text } from 'theme'
import { UnifiedIcon } from 'shared/UnifiedIcon'

const useStyles = createUseStyles({
  table: {
    '& table > thead > tr > th:last-child': {
      paddingLeft: '12px !important',
    },
    '& table > tbody > tr > td:last-child': {
      paddingLeft: '12px !important',
    },
  },
  noBorderBottom: {
    '& > td, & > th': {
      borderBottom: '0px !important',
    },
  },
})

type ItemsConfig = {
  items: BuiltinWidgetConfigs['order_items']
  currencyDigitsAfterComma: number
}

type TransformedItem = Partial<ArrayValue<ReturnType<typeof transformOrderItems>>> & {
  expanded?: boolean
}

interface DataType {
  key: string
  item: TransformedItem
  children?: DataType[]
  indentLevel: number
  noBorderBottom: boolean | 'when-expanded'
}

/*
  @TODO: Remove isNewItemsOrderDesignAvailable when betaPermission_NewOrderItems
  is not required anymore. Check classes and styles file to cleanup.
*/

const ItemsV2 = createPluggableWidget<ItemsConfig>(
  ({ order, config, sdk, lang }) => {
    const { currencyDigitsAfterComma, items: itemsConfig } = config
    const { default_number_of_items, data_points = [] } = itemsConfig

    // pull data state from data context
    const {
      dataState: { currency },
    } = useContext(DataContext)

    const classes = useStyles()
    const { t } = useTranslation()

    const { checkDisplayRules } = sdk
    const [expandedRowKeys, setExpandedRowKeys] = useState<string[]>([])

    const transformedItems = useMemo(() => {
      return transformOrderItems(order?.order?.items || [], {
        isNewItemsOrderDesignAvailable: false,
        currency_digits_after_comma: currencyDigitsAfterComma,
        enabled_item_locales: itemsConfig.enabled_item_locales,
        locale: lang,
      })
    }, [order, currencyDigitsAfterComma, itemsConfig, lang])

    const maxNumberOfItemsToDisplay = default_number_of_items?.value || -1

    const enableToggleButtons =
      maxNumberOfItemsToDisplay !== -1 &&
      transformedItems.length > maxNumberOfItemsToDisplay &&
      checkDisplayRules({ displayRules: default_number_of_items?.display_rules }).enabled

    const [showAllItems, setShowAllItems] = useState(!enableToggleButtons)
    const [hoveredRow, setHoveredRow] = useState<string | null>(null)

    /**
     * toggle row expansion
     */
    const toggleRowExpansion = useCallback<MouseEventHandler<HTMLDivElement>>((e) => {
      const rowKey = e.currentTarget.dataset.rowKey
      setExpandedRowKeys((currentKeys) => {
        if (currentKeys.includes(rowKey)) {
          return currentKeys.filter((current) => current !== rowKey)
        } else {
          return currentKeys.concat(rowKey)
        }
      })
    }, [])

    const dataSource = useMemo<DataType[]>(() => {
      const structureItems = (items: TransformedItem[], indentLevel: number): DataType[] => {
        return items.map((item, index) => {
          const dataItem: DataType = {
            key: item.id,
            item,
            indentLevel,
            noBorderBottom: indentLevel > 0 && index < items.length - 1,
          }

          if (item.toppings.length) {
            if (indentLevel === 0) {
              dataItem.noBorderBottom = 'when-expanded'
            }
            dataItem.children = structureItems(item.toppings, indentLevel + 1)
          }
          return dataItem
        })
      }
      return structureItems(
        showAllItems ? transformedItems : transformedItems.slice(0, maxNumberOfItemsToDisplay),
        0,
      )
    }, [transformedItems, showAllItems, maxNumberOfItemsToDisplay])

    const { action_handlers: actionHandlers } = itemsConfig

    const toggleAllItemsShow = () => setShowAllItems((value) => !value)

    const columns = useMemo<ColumnsType<DataType>>(
      () => [
        {
          key: 'item',
          dataIndex: 'item',
          title: t('Order Widget.Tabs.Items.Item'),
          render: (_, { item, key, indentLevel }) => {
            const displayItemCategory = checkDisplayRules({
              displayRules: data_points.find((dp) => dp.name === 'item_category')?.display_rules,
              opts: {
                dataPointValue: item.category,
              },
            }).visible

            const displaySoldByWeightFeature = checkDisplayRules({
              displayRules: data_points.find((dp) => dp.name === 'sold_by_weight_feature')
                ?.display_rules,
              opts: {
                dataPointValue: item.isSoldByWeight,
              },
            }).visible

            const canExpand = item.toppings.length > 0 || item.isBundle
            const isExpanded = expandedRowKeys.includes(key)

            return (
              <Box
                data-row-key={key}
                onClick={canExpand ? toggleRowExpansion : null}
                pl={indentLevel * 18}
                cursor={canExpand ? 'pointer' : 'default'}
              >
                <Space align='center'>
                  <Text.Primary>{item.originalName}</Text.Primary>
                  {item.isBundle && <SingleTag value='Bundle' />}
                  {canExpand &&
                    (!isExpanded ? (
                      <UnifiedIcon icon={DownOutlined} size={12} />
                    ) : (
                      <UnifiedIcon icon={UpOutlined} size={12} />
                    ))}
                </Space>
                {item.localizedNames.length > 0 && (
                  <Box>
                    {item.localizedNames.map((name, index) => {
                      return (
                        <Text.Primary color={text.secondary} key={index} mt={4}>
                          {name}
                        </Text.Primary>
                      )
                    })}
                  </Box>
                )}

                {(item.category && displayItemCategory) ||
                (item.isSoldByWeight && displaySoldByWeightFeature) ? (
                  <Box fontSize={12} color={gray.gray7}>
                    <Space>
                      {item.category && displayItemCategory ? <span>{item.category}</span> : null}
                      {item.isSoldByWeight && displaySoldByWeightFeature ? (
                        <span>
                          <WeightIcon /> {t('OrderWidget.Tabs.Items.Sold by weight')}
                        </span>
                      ) : null}
                    </Space>
                  </Box>
                ) : null}
              </Box>
            )
          },
        },
        // quantity
        {
          key: 'quantity',
          title: t('Order Widget.Tabs.Items.Quantity'),
          align: 'right',
          render: (_, { item }) => item.quantity,
        },
        // price
        {
          key: 'price',
          title: t('Order Widget.Tabs.Items.Price'),
          render: (_, { item, key }) => {
            // Show tooltip over hovered row if item price and total price are different
            const isTooltipVisible = key === hoveredRow && item.totalPrice !== item.price
            const tooltipTitle = `${t('Order Widget.Tabs.Items.Item Price')} ${currency} ${
              item.price
            }`
            const isExpanded = expandedRowKeys.includes(key)

            return (
              <Tooltip title={tooltipTitle} placement='topRight' visible={isTooltipVisible}>
                <Text.Primary>
                  <Text.Secondary display='inline' pr={2}>
                    {currency}
                  </Text.Secondary>
                  {isExpanded ? item.price : item.totalPrice}
                </Text.Primary>
              </Tooltip>
            )
          },
          align: 'right',
        },

        // more column
        {
          key: 'more',
          title: t('Order Widget.Tabs.Items.More'),
          width: 84,
          render: (_, { item }) => {
            const displayCustomerNotes = checkDisplayRules({
              displayRules: data_points.find((dp) => dp.name === 'customer_notes')?.display_rules,
              opts: {
                dataPointValue: item.customerNotes,
              },
            }).visible

            const displayItemRequirementIcon = checkDisplayRules({
              displayRules: data_points.find((dp) => dp.name === 'item_requirement')?.display_rules,
              opts: {
                dataPointValue: item.requirement,
              },
            }).visible

            const displayItemImageIcon = checkDisplayRules({
              displayRules: data_points.find((dp) => dp.name === 'item_image')?.display_rules,
              opts: {
                dataPointValue: item.imageUrls.length,
              },
            }).visible

            return (
              <Box color={text.secondary}>
                <Space size={8}>
                  {displayItemImageIcon && (
                    <span>
                      <ItemImageIcon imageUrls={item.imageUrls} />
                    </span>
                  )}
                  {displayItemRequirementIcon && (
                    <span>
                      <ItemRequirementIcon requirement={item.requirement} />
                    </span>
                  )}
                  {item.customerNotes && displayCustomerNotes && (
                    <span>
                      <Tooltip title={item.customerNotes}>
                        <span>
                          <ChefHatIcon />
                        </span>
                      </Tooltip>
                    </span>
                  )}
                </Space>
              </Box>
            )
          },
        },
      ],
      [
        t,
        checkDisplayRules,
        data_points,
        expandedRowKeys,
        toggleRowExpansion,
        currency,
        hoveredRow,
      ],
    )

    return (
      <div>
        {/* Action buttons at the top */}
        {actionHandlers?.length > 0 && (
          <WidgetActionHandlers actionHandlers={actionHandlers} marginTop={0} marginBottom={20} />
        )}

        <Table
          size='small'
          columns={columns as any}
          dataSource={dataSource}
          pagination={false}
          indentSize={0}
          className={classes.table}
          expandable={{
            indentSize: 0,
            expandedRowKeys,
            expandIcon: () => null,
          }}
          rowClassName={({ key, noBorderBottom }) => {
            if (
              noBorderBottom === true ||
              (noBorderBottom === 'when-expanded' && expandedRowKeys.includes(key))
            ) {
              return classes.noBorderBottom
            }
            return ''
          }}
          onRow={(record) => ({
            onMouseEnter: () => setHoveredRow(record.key),
            onMouseLeave: () => setHoveredRow(null),
          })}
        />

        {/* Show all items / show less items btn */}
        {enableToggleButtons && (
          <Box display='flex' justifyContent='center' mt={16}>
            <Button type='link' color='primary' onClick={toggleAllItemsShow}>
              {t(showAllItems ? 'action_labels.show_less_items' : 'action_labels.show_all_items')}
            </Button>
          </Box>
        )}
      </div>
    )
  },
  {
    category: 'data_lookup',
    deriveConfig({ entityConfig }) {
      return {
        items: entityConfig.layout_v2.builtin_widgets_configs.order_items || {
          default_number_of_items: {
            value: 8,
          },
          data_points: [],
          action_handlers: [],
        },
        currencyDigitsAfterComma: entityConfig.layout_v2.currency_digits_after_comma,
      }
    },

    deriveInitialViewState({ t, titleTranslationKey, order }) {
      return {
        title: t(titleTranslationKey, {
          replace: { totalItems: order?.order?.items?.length ?? 0 },
        }),
      }
    },
  },
)

export default ItemsV2
