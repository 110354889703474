import React from 'react'
import { SearchType, SearchPanelHandler } from 'hooks/search/useSearchPanel'
import { replaceUrl } from 'utils/getSearchParams'
import { SearchPanel, SearchPanelProps } from './SearchPanel'
import { builtinWidgetNames } from 'types'
import { ViewScreenAndSdkProvider } from 'contexts/ViewScreenAndSdkProvider'

const searchOptions: SearchPanelProps['searchByOptions'] = new Map([
  [SearchType.VendorId, 'widgets.search.search_options.vendor_id'],
  [SearchType.GlobalVendorId, 'widgets.search.search_options.global_vendor_id'],
  [SearchType.OrderId, 'widgets.search.search_options.order_id'],
  [SearchType.CustomerPhoneNumber, 'widgets.search.search_options.customer_phone_number'],
])

export const SearchVendorPanel = (
  props: Omit<SearchPanelProps, 'defaultSearchType' | 'searchByOptions'>,
) => {
  const onSearch: SearchPanelHandler = (opts) => {
    const { selectedSearchType, defaultHandler, searchedOrder, selectedEntityId, searchValue } =
      opts

    switch (selectedSearchType) {
      case SearchType.OrderId:
        replaceUrl({
          params: {
            orderId: searchValue,
            vendorId: searchedOrder.vendor?.id,
            globalEntityId: selectedEntityId,
            tab: props.activeTab,
          },
        })
        break

      case SearchType.CustomerPhoneNumber:
        defaultHandler()
        break

      default:
        // search by vendor or global vendor id
        replaceUrl({
          params: {
            [selectedSearchType]: searchValue,
            globalEntityId: selectedEntityId,
            tab: props.activeTab,
          },
          remove: ['orderId', 'vendorId', 'globalVendorId'],
        })
    }
  }

  return (
    <ViewScreenAndSdkProvider screenName='main' widgetId={builtinWidgetNames.searchVendorPanel}>
      <SearchPanel
        {...props}
        searchByOptions={searchOptions}
        defaultSearchType={SearchType.VendorId}
        onSearch={onSearch}
        data-test-id='search-vendor-panel'
      />
    </ViewScreenAndSdkProvider>
  )
}
