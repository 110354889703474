import React, { useRef } from 'react'
import { ComponentProps, FunctionComponent } from 'react'
import { DatePicker as _DatePicker } from 'antd'
import { createUseStyles } from 'react-jss'
import classnames from 'classnames'

export const DatePicker = _DatePicker as unknown as FunctionComponent<
  ComponentProps<typeof _DatePicker>
>

export type CalendarProps = React.ComponentProps<typeof DatePicker> & {
  // Hides the Date Input to convert it into a "read-only" calendar
  readonly?: boolean
  inputContainerClassName?: string
  calendarContainerClassName?: string
}

const useStyles = createUseStyles({
  datePicker: (props: CalendarProps) => {
    if (props.readonly) {
      return {
        visibility: 'hidden',
        height: 0,
        padding: 0,
        width: 0,
        position: 'relative',
      }
    }
  },

  calendar: {
    '& div:first-child': {
      position: 'relative !important', // overrides inline styles set by Ant Design
    },

    '& .ant-picker-dropdown': {
      position: 'relative',
      top: '0 !important', // overrides inline styles set by Ant Design
      zIndex: 'initial',
    },

    '& .ant-picker-panel-container': {
      boxShadow: 'none',
    },

    '& .ant-picker-panel-container .ant-picker-panel-focused': {
      borderColor: 'transparent',
    },
    '& .ant-picker-date-panel': {
      width: '100%',
    },

    '& .ant-picker-date-panel .ant-picker-content': {
      width: '100%',
    },
  },
})

export const Calendar: React.FC<CalendarProps> = (props) => {
  const classes = useStyles(props)
  const ref = useRef(null)

  return (
    <>
      <div className={props.inputContainerClassName}>
        <DatePicker
          className={classnames(classes.datePicker, props.className)}
          getPopupContainer={() => ref.current}
          open={true}
          {...props}
        />
      </div>

      <section className={props.calendarContainerClassName}>
        <div ref={ref} className={classes.calendar}></div>
      </section>
    </>
  )
}
