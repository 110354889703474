import { OrderApiResponse } from 'types/api/orderApi/order'
import { getOrderAdditionalCharges } from './getOrderAdditionalCharges'

export const getOrderPaidValue = ({ order }: { order: OrderApiResponse }) => {
  if (!order) {
    return 0
  }

  const { order_value = 0 } = order?.order ?? {}

  const additionalCharges = getOrderAdditionalCharges(order)

  const { coupon = 0, discount = 0, loyalty_point_balance = 0 } = order?.customer?.payment ?? {}

  const { joker_discount_fee = 0 } = order?.vendor?.charges ?? {}

  const subtotal = order_value + additionalCharges.total - (discount || joker_discount_fee)

  const total = subtotal - coupon - loyalty_point_balance

  return total
}
