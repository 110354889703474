/**
 * expects fulfillment API's response
 * creates 2 arrays with appropriate key/value pairs to be used in Rider widget Summary Tab
 * */

import { unknownDataPoint } from './constants'
import { TransformedDataPoint } from 'types/dataTransformers/generic'
import { DeliveryItem, FulfillmentApiResponse } from 'types/api/fulfillmentApi/fulfillment'

function transformRiderSummary(data: FulfillmentApiResponse, t: any, delivery_id = 0) {
  // check if courier data exists
  let delivery: DeliveryItem = null
  const selectedDelivery = data.deliveries.find((delivery) => delivery.id === delivery_id)
  if (selectedDelivery) {
    delivery = selectedDelivery
  } else if (data && data.deliveries && data.deliveries.length > 0 && data.deliveries[0].courier) {
    delivery = data.deliveries[0]
  }

  if (!delivery || !delivery.courier) {
    return { transformedLeftColumnData: [], transformedRightColumnData: [] }
  }

  const issue = data.issues?.find((issue) => issue.delivery_id === delivery.id)

  // set columns' data accordingly
  const transformedLeftColumnData: TransformedDataPoint[] = [
    {
      key: t('Rider Widget.Rider Name'),
      value: delivery.courier.name ? delivery.courier.name : unknownDataPoint,
    },
    {
      key: t('Rider Widget.Phone Number'),
      value: delivery.courier.phone_number ? delivery.courier.phone_number : unknownDataPoint,
    },
    {
      key: t('Rider Widget.Delivery ID'),
      value: delivery.id ? delivery.id : unknownDataPoint,
    },
    {
      key: t('Rider Widget.Delivery issue'),
      value: issue?.type || unknownDataPoint,
    },
  ]

  const events = delivery?.courier_events || []

  const transformedRightColumnData: TransformedDataPoint[] = [
    {
      key: t('Rider Widget.Rider ID'),
      value: delivery.courier.id ? delivery.courier.id : unknownDataPoint,
    },
    {
      key: t('Rider Widget.Vehicle Type'),
      value: delivery.courier.vehicle_type ? delivery.courier.vehicle_type : unknownDataPoint,
    },
    {
      key: t('Rider Widget.Status'),
      value: events.length ? events[0]?.name : unknownDataPoint,
    },
  ]

  // return columns' data
  return { transformedLeftColumnData, transformedRightColumnData }
}

export default transformRiderSummary
