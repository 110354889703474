import React from 'react'
import { Col, Row } from 'antd'
import { ContactPerson } from '../types'
import { Text } from '../../../shared/Text'
import FraudStatusFlag from './FraudStatusFlag'
import { MinusCircleFilled } from '@ant-design/icons'

type Props = {
  contactPerson: ContactPerson
}

const ContactInfo = ({ contactPerson }: Props) => {
  const { name, business, fraudStatus, error } = contactPerson || {}
  return (
    <Row gutter={5}>
      <Col>
        <Text.Primary fontWeight='600'>{name}</Text.Primary>
      </Col>
      {business && (
        <Col>
          <Row align='middle' gutter={5}>
            <Col>
              <MinusCircleFilled style={{ color: 'gray', fontSize: '5px' }} />
            </Col>
            <Col>
              <Text.Primary color='gray' fontSize={12}>
                {business}
              </Text.Primary>
            </Col>
          </Row>
        </Col>
      )}
      {fraudStatus ? (
        <Col>
          <FraudStatusFlag status={fraudStatus} />
        </Col>
      ) : error ? (
        <Col>
          <Text.Primary color='red'>{error}</Text.Primary>
        </Col>
      ) : null}
    </Row>
  )
}

export default ContactInfo
