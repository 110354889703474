import { ApiService } from 'services/authApi/getPermissions'

export const getVendorInvoiceFile: ApiService<
  {
    globalEntityId: string
    vendorId: string
    apiVersion?: 'v1' | 'v2'
    fileName: string
    callBack?: (name: string) => void
  },
  { file: Blob; fileName: string }
> = (createClient, options) => {
  const { globalEntityId, vendorId, apiVersion = 'v1', fileName, callBack, clientParams } = options

  const uri = `/VendorAPI/${apiVersion}/vendors/${globalEntityId}/${vendorId}/invoices/${fileName}`

  return createClient({
    endpointName: 'getVendorInvoiceFile',
    expectedResponseStatusCode: 200,
    ...clientParams,
  })
    .get<Blob>(uri, { responseType: 'blob' })
    .then((response) => {
      return {
        ...response,
        data: { file: response.data, fileName },
      }
    })
    .finally(() => callBack(fileName))
}
