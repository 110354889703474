import { DataContext } from 'contexts/data/DataContext'
import { DataAction } from 'contexts/data/types'
import { useContext, useMemo } from 'react'
import { eventEmitter, EventEmitterNamespace } from 'utils/eventEmitter'

export const useEventEmitter = (namespace: string): EventEmitterNamespace => {
  const { dataDispatch: dispatch } = useContext(DataContext)

  return useMemo(() => {
    return new EventEmitterNamespace(namespace, eventEmitter, (event) => {
      dispatch({
        type: DataAction.ADD_EMITTED_EVENT,
        payload: event.name,
      })
    })
  }, [namespace, dispatch])
}
