import { createUseStyles } from 'react-jss'

export const useHistoryStyles = createUseStyles({
  tabs: {
    margin: 0,
  },
  container: {
    padding: '10px 0',
  },
  timeline: {
    marginTop: '30px',
  },
})
