import { TOptions } from 'i18next'

type DisabledFeatureArgs = {
  name: string
  message: string
  options?: TOptions
}

export class DisabledFeature {
  private _name: string
  private _message: string
  private _options: TOptions

  constructor({ name, message, options }: DisabledFeatureArgs) {
    this._name = name
    this._message = message
    this._options = options || {}
  }

  get name() {
    return this._name
  }

  get message() {
    return this._message
  }

  get options() {
    return this._options
  }
}

export type ActionButton = {
  name: string
  component: JSX.Element
  btnText: string
  visible: boolean
  disabled: DisabledFeature
}
