import React, { useMemo } from 'react'

// Types
import {
  RefundDetailsWithTransactions,
  OrderTransaction,
  TransactionStatusDisplay,
} from 'types/api/orderApi/order'

// Utils
import { createUseStyles } from 'react-jss'

// Types
import { RefundStatus, PaymentMedium } from 'types/api/orderApi/order'

// Hooks
import { useTranslation } from 'hooks/useTranslation'

// Relative Path
import styles from './StatusWidget.style'
import PopoverInfoWidget from './components/PopoverInfoWidget'
import { Dot, Text } from 'shared'
import {
  paymentStatusesColorMappings,
  refundStatusesColorMappings,
} from 'constants/statusColorMappings'
import capitalize from 'lodash/capitalize'

type StatusWidgetRefundProps = {
  refund: RefundDetailsWithTransactions
}

const useStyles = createUseStyles(styles)

export function StatusWidgetRefund({ refund }: StatusWidgetRefundProps) {
  const status = refund.status.toLowerCase() as RefundStatus
  const classes = useStyles()
  const { t } = useTranslation()

  const statusColor = refundStatusesColorMappings[status] || refundStatusesColorMappings.unknown
  const statusText = t(`statuses.refund_statuses.${status}`, { defaultValue: capitalize(status) })

  let details = ''
  const isSuccessRefundWithVoucher =
    refund.status === 'success' && refund.payment_medium === PaymentMedium.VOUCHER
  if (isSuccessRefundWithVoucher) {
    details = t('statuses.refund_statuses_details.success_refund') + refund.voucher_id
  } else {
    details = t(`statuses.refund_statuses_details.${status}`, {
      defaultValue: t(`statuses.refund_statuses_details.unknown`),
    })
  }

  const changesSince = useMemo(() => {
    return refund.transactions?.sort(
      (a, b) => new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime(),
    )
  }, [refund])

  return (
    <div className={classes.wrapper}>
      <Text.Primary display='flex' columnGap={'8px'} alignItems='center'>
        <Dot color={statusColor}>{statusText}</Dot>
        <PopoverInfoWidget
          details={details}
          changesSince={changesSince[0]?.timestamp || refund.processed_timestamp}
          title={<Dot color={statusColor}>{statusText}</Dot>}
        />
      </Text.Primary>
    </div>
  )
}

type StatusWidgetTransactionProps = {
  transaction?: OrderTransaction | null
  status: TransactionStatusDisplay
}

export function StatusWidgetTransaction({ transaction, status }: StatusWidgetTransactionProps) {
  const classes = useStyles()
  const { t } = useTranslation()

  const statusColor = paymentStatusesColorMappings[status] || paymentStatusesColorMappings.unknown
  const statusText = t(`statuses.payment_statuses.${status}`, { defaultValue: capitalize(status) })

  const details = t(`statuses.payment_statuses_details.${status}`, {
    defaultValue: t(`statuses.payment_statuses_details.unknown`),
  })

  return (
    <div className={classes.wrapper}>
      <Text.Primary display='flex' columnGap={'8px'} alignItems='center'>
        <Dot color={statusColor}>{statusText}</Dot>
        <PopoverInfoWidget
          details={details}
          changesSince={transaction?.timestamp}
          title={<Dot color={statusColor}>{statusText}</Dot>}
        />
      </Text.Primary>
    </div>
  )
}
