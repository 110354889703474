import React from 'react'
import { Typography } from 'antd'
import { createUseStyles } from 'react-jss'
import { useTranslation } from 'hooks/useTranslation'
import { SingleComment } from 'types/api/commentApi/getOrderComments'
import modifyUTC from 'utils/modifyUTC'
import styles from './Comments.styles'
import { commentTagType, CommentView } from './CommentView'
import { WidgetSize } from 'types/widgets/historyOfEvents/tabs'

const { Text } = Typography

const useStyles = createUseStyles(styles)

type Props = {
  utc_zone: string
  comment: SingleComment
  shouldCommentBeHighlighted: boolean
  mode: WidgetSize
}

const DeliveryTimeComment = ({ comment, utc_zone, shouldCommentBeHighlighted, mode }: Props) => {
  const classes = useStyles()
  const { t } = useTranslation()
  return (
    <CommentView
      mode={mode}
      comment={comment}
      shouldCommentBeHighlighted={shouldCommentBeHighlighted}
      tag={{
        type: commentTagType.thunderbolt,
        text: t('History of Events Widget.Event Types.Change Delivery Time'),
      }}
    >
      <Text className={classes.commentText}>
        <Text>{` ${t('History of Events Widget.Content.Changed delivery time to')}`}</Text>
        <Text strong>
          {` ${modifyUTC(comment.additional_data.modify_promised_delivery_time.new, utc_zone)}. `}
        </Text>
        <Text>{`${t('History of Events Widget.Content.Initial delivery time')}: `}</Text>
        <Text strong>
          {`${modifyUTC(comment.additional_data.modify_promised_delivery_time.old, utc_zone)}.`}
        </Text>
      </Text>
    </CommentView>
  )
}

export default DeliveryTimeComment
