/**
 * Summary tab for customer widget
 * renders two lists as columns each containing general info of customer
 * */

// libs
import React, { useMemo } from 'react'
// configs
import { summaryWidgetFields } from 'entityConfig/dataPoints/customer'
// contexts and types
import { TransformedDataPoint } from 'types/dataTransformers/generic'

// styles
import { createUseStyles } from 'react-jss'
import styles from './Summary.styles'
import { Col, Row } from 'antd'
import { CustomerWidgetSummary, FraudFlagWithBetaRequirement } from 'contexts/entity/types'

import SimpleDataPointListItem from 'components/DataPoint/SimpleDataPointListItem'
import WidgetTagsListItem from 'components/DataPoint/WidgetTagsListItem'
import PhoneDataPointListItem from '../../../components/DataPoint/PhoneDataPointListItem'
import { WidgetErrorHandler } from 'components/WidgetErrorHandler/widgetErrorHandler'
import useTransformCustomerSummary from 'hooks/dataTransformers/useTransformCustomerSummary'
import { DefaultListItemDataPoint } from 'components/DatapointListItemRenderers/DefaultListItemDataPoint/DefaultListItemDataPoint'
import { useCanDisplayDataPoint, useIsDataPointValid } from 'hooks/useGetValidFeatures'
import CustomerNameDataPointListItem from 'components/DataPoint/CustomerNameDataPointListItem'
import { useLoadFraudStatus } from '../../../hooks/useLoadFraudStatus'
import { useSessionState } from 'hooks/useSessionState'
import { createPluggableWidget } from 'factory/createPluggableWidget'
import { DataPoint } from 'shared/DataPoint'

const useStyles = createUseStyles(styles)

const Summary = createPluggableWidget<
  CustomerWidgetSummary & {
    enableCustomerEligibilities: boolean
    fraudConfig: FraudFlagWithBetaRequirement
  }
>(
  (props) => {
    const { config, customer, order, globalEntityId, sdk } = props
    const { captureUserAction } = sdk
    const { delivery, order_id } = order ?? {}
    const { uiVersion } = useSessionState()
    const isDataPointValid = useIsDataPointValid()

    const classes = useStyles()
    const canDisplayDataPoint = useCanDisplayDataPoint()
    const canCopy = canDisplayDataPoint(null, config.copy_to_clipboard)
    // pull language content
    const transformCustomerSummary = useTransformCustomerSummary()
    const dataSource = useMemo(() => {
      let items: TransformedDataPoint[] = []
      if (customer) {
        items = transformCustomerSummary({
          customer,
          delivery,
          config,
        })
      }
      return items
    }, [customer, transformCustomerSummary, config, delivery])

    const handlePhoneCopyClick = () => {
      captureUserAction('COPY_CUSTOMER_PHONE_NUMBER')
    }

    const handleEmailCopyClick = () => {
      captureUserAction('COPY_CUSTOMER_EMAIL')
    }

    const handleDialClick = () => {
      captureUserAction('DIAL_CUSTOMER_PHONE_NUMBER')
    }

    const handleIdCopyClick = () => {
      captureUserAction('COPY_CUSTOMER_ID')
    }

    const handleAddressCopyClick = () => {
      captureUserAction('COPY_CUSTOMER_ADDRESS')
    }

    //Only load fraudFlag in UI v2
    const shouldLoadFraudFlag =
      customer?.customer_id &&
      uiVersion === 'v2' &&
      config?.fraudConfig?.enabled &&
      isDataPointValid(config?.fraudConfig?.betaRequirement)

    const { fraudStatus, error } = useLoadFraudStatus({
      customerId: customer?.customer_id,
      globalEntityId: globalEntityId,
      orderId: order_id,
      apiVersion: config?.fraudConfig?.apiVersion,
      shouldLoadFraudFlag,
    })

    return (
      <WidgetErrorHandler errorPayload={null} missingParameters={[!customer && 'customer']}>
        {() => {
          return (
            <div className={classes.summaryContainer}>
              <div className={classes.listsContainer}>
                <div className={classes.listHolder}>
                  <Row gutter={[20, 20]}>
                    {dataSource.map((item: TransformedDataPoint) => {
                      const renderDataPoint = () => {
                        switch (item.key) {
                          case summaryWidgetFields.customerName:
                            return (
                              <CustomerNameDataPointListItem
                                item={item}
                                config={config.name}
                                fraudStatus={fraudStatus}
                                errorStatusCode={error?.errorPayload?.statusCode}
                              />
                            )
                          case summaryWidgetFields.phoneNumber:
                            return (
                              <PhoneDataPointListItem
                                item={item}
                                config={config.phone}
                                onDialClick={handleDialClick}
                                canCopy={canCopy}
                                onCopyClick={handlePhoneCopyClick}
                              />
                            )
                          case summaryWidgetFields.customerTags:
                            return <WidgetTagsListItem item={item} />
                          case summaryWidgetFields.customerId:
                            return (
                              <SimpleDataPointListItem
                                item={item}
                                canCopy={canCopy}
                                onCopyClick={handleIdCopyClick}
                                name={summaryWidgetFields.customerId}
                              />
                            )
                          case summaryWidgetFields.emailId:
                            return (
                              <SimpleDataPointListItem
                                item={item}
                                config={config.email}
                                canCopy={canCopy}
                                showTooltip
                                onCopyClick={handleEmailCopyClick}
                                name={summaryWidgetFields.emailId}
                              />
                            )
                          case summaryWidgetFields.address:
                            const canDisplayAddress = canDisplayDataPoint(
                              item.value,
                              config.address || { displayRule: [], betaRequirement: [] },
                            )
                            if (!canDisplayAddress) {
                              return (
                                <DataPoint
                                  type='default'
                                  label={item.label || item.key}
                                  value='-'
                                  name={summaryWidgetFields.address}
                                  display_rules={null}
                                />
                              )
                            }

                            return (
                              <DefaultListItemDataPoint
                                item={item}
                                onCopyClick={handleAddressCopyClick}
                                canCopy={canCopy}
                                name={summaryWidgetFields.address}
                              />
                            )
                          default:
                            return null
                        }
                      }
                      return (
                        <Col span={8} key={item.key}>
                          {renderDataPoint()}
                        </Col>
                      )
                    })}
                  </Row>
                </div>
              </div>
            </div>
          )
        }}
      </WidgetErrorHandler>
    )
  },
  {
    category: 'data_lookup',
    deriveConfig({ entityConfig }) {
      return {
        ...entityConfig.scrollable_panel_config.widgets_configs.customer.tab_configs.summary,
        enableCustomerEligibilities: entityConfig.enableCustomerEligibilities,
        fraudConfig: entityConfig.scrollable_panel_config.widgets_configs.customer.FraudFlag,
      }
    },
    deriveSubjectsRequirements() {
      return {
        all_of: ['customer'],
      }
    },
  },
)
export default Summary
