import React from 'react'
import { createCustomSvgIcon } from 'factory/createCustomSvgIcon'

export const VoucherIcon = createCustomSvgIcon({
  viewBoxWidth: 18,
  viewBoxHeight: 14,
  content: (
    <>
      <mask id='voucher_path-1-inside-1_2552_4194' fill='white'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M0 0.776123C0 0.485553 0.235553 0.25 0.526123 0.25H17.4739C17.7644 0.25 18 0.485553 18 0.776123V5.25307C17.9365 5.24612 17.872 5.24255 17.8066 5.24255C16.8358 5.24255 16.0488 6.02955 16.0488 7.00037C16.0488 7.97118 16.8358 8.75818 17.8066 8.75818C17.872 8.75818 17.9365 8.75461 18 8.74767V13.2239C18 13.5144 17.7644 13.75 17.4739 13.75H0.526123C0.235553 13.75 0 13.5144 0 13.2239V8.74346C0.074798 8.75317 0.151072 8.75818 0.228516 8.75818C1.19933 8.75818 1.98633 7.97118 1.98633 7.00037C1.98633 6.02955 1.19933 5.24255 0.228516 5.24255C0.151072 5.24255 0.074798 5.24756 0 5.25727V0.776123Z'
        />
      </mask>
      <path
        d='M18 5.25307L17.8423 6.69447L19.45 6.87037V5.25307H18ZM18 8.74767H19.45V7.13036L17.8423 7.30627L18 8.74767ZM0 8.74346L0.186673 7.30553L-1.45 7.09305V8.74346H0ZM0 5.25727H-1.45V6.90767L0.186664 6.69521L0 5.25727ZM0.526123 -1.2C-0.56526 -1.2 -1.45 -0.315259 -1.45 0.776123H1.45C1.45 1.28637 1.03637 1.7 0.526123 1.7V-1.2ZM17.4739 -1.2H0.526123V1.7H17.4739V-1.2ZM19.45 0.776123C19.45 -0.31526 18.5653 -1.2 17.4739 -1.2V1.7C16.9636 1.7 16.55 1.28637 16.55 0.776123H19.45ZM19.45 5.25307V0.776123H16.55V5.25307H19.45ZM17.8066 6.69255C17.8192 6.69255 17.8311 6.69324 17.8423 6.69447L18.1577 3.81167C18.0419 3.799 17.9248 3.79255 17.8066 3.79255V6.69255ZM17.4988 7.00037C17.4988 6.83037 17.6366 6.69255 17.8066 6.69255V3.79255C16.035 3.79255 14.5988 5.22874 14.5988 7.00037H17.4988ZM17.8066 7.30818C17.6366 7.30818 17.4988 7.17037 17.4988 7.00037H14.5988C14.5988 8.77199 16.035 10.2082 17.8066 10.2082V7.30818ZM17.8423 7.30627C17.8311 7.3075 17.8192 7.30818 17.8066 7.30818V10.2082C17.9248 10.2082 18.0419 10.2017 18.1577 10.1891L17.8423 7.30627ZM19.45 13.2239V8.74767H16.55V13.2239H19.45ZM17.4739 15.2C18.5653 15.2 19.45 14.3153 19.45 13.2239H16.55C16.55 12.7136 16.9636 12.3 17.4739 12.3V15.2ZM0.526123 15.2H17.4739V12.3H0.526123V15.2ZM-1.45 13.2239C-1.45 14.3153 -0.565261 15.2 0.526123 15.2V12.3C1.03637 12.3 1.45 12.7136 1.45 13.2239H-1.45ZM-1.45 8.74346V13.2239H1.45V8.74346H-1.45ZM0.228516 7.30818C0.213646 7.30818 0.199725 7.30722 0.186673 7.30553L-0.186673 10.1814C-0.0501292 10.1991 0.0884977 10.2082 0.228516 10.2082V7.30818ZM0.536328 7.00037C0.536328 7.17037 0.398517 7.30818 0.228516 7.30818V10.2082C2.00014 10.2082 3.43633 8.77199 3.43633 7.00037H0.536328ZM0.228516 6.69255C0.398516 6.69255 0.536328 6.83037 0.536328 7.00037H3.43633C3.43633 5.22874 2.00014 3.79255 0.228516 3.79255V6.69255ZM0.186664 6.69521C0.199728 6.69351 0.213652 6.69255 0.228516 6.69255V3.79255C0.0884917 3.79255 -0.0501322 3.80161 -0.186664 3.81934L0.186664 6.69521ZM-1.45 0.776123V5.25727H1.45V0.776123H-1.45Z'
        fill='currentColor'
        mask='url(#voucher_path-1-inside-1_2552_4194)'
      />
      <path
        d='M5.57498 1.03809L5.57497 12.75'
        stroke='currentColor'
        strokeWidth='1.15'
        strokeDasharray='1.89 1.89'
      />
    </>
  ),
})
