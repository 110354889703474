import { useContext, useState } from 'react'
import { useApiService } from './useApiService'
import {
  CustomerFraudStatusApiResponse,
  getCustomerFraudStatus,
} from '../services/customerApi/getCustomerFraudStatus'
import { DataContext } from 'contexts/data/DataContext'
import { DataAction } from 'contexts/data/types'
import { ApiVersionNumber, fraudStatusResults } from 'types/unitedUiConfig'
import { useCreateReportCustomerExploitStatus } from './events/useCreateReportCustomerExploitStatus'
import noop from 'lodash/noop'
import { FulFilledApiResponse } from 'services/authApi/getPermissions'
import { useSessionState } from './useSessionState'

type HookProps = {
  customerId: string
  orderId: string
  globalEntityId: string
  apiVersion: ApiVersionNumber
  shouldLoadFraudFlag?: boolean
  reportStatusToTrackingService?: boolean

  onSuccessCallBack?: (
    status: fraudStatusResults,
    res: FulFilledApiResponse<CustomerFraudStatusApiResponse>,
  ) => void
}

export const useLoadFraudStatus = ({
  customerId,
  orderId,
  globalEntityId,
  apiVersion = 1,
  shouldLoadFraudFlag = false,
  reportStatusToTrackingService = true,
  onSuccessCallBack = noop,
}: HookProps) => {
  const { ccrCode } = useSessionState()
  const { dataDispatch } = useContext(DataContext)
  const { SET_CUSTOMER_EXPLOIT_STATUS } = DataAction

  const [fraudStatus, setFraudStatus] = useState<fraudStatusResults>(null)
  const reportCustomerExploitStatus = useCreateReportCustomerExploitStatus()

  const {
    error,
    loading,
    loadService: refreshFraudStatus,
    data,
    status,
  } = useApiService({
    service: getCustomerFraudStatus,
    params: {
      customerId: customerId,
      entityId: globalEntityId,
      orderId: orderId,
      ccrCode: ccrCode,
      version: apiVersion,
    },
    shouldLoad: shouldLoadFraudFlag,

    onSuccess(res) {
      if (reportStatusToTrackingService) {
        reportCustomerExploitStatus(res.data, { apiVersion })
      }

      let status = fraudStatusResults.green
      if (typeof res.data.result === 'string') {
        status = res.data.result as fraudStatusResults
      } else {
        status = res.data.result ? fraudStatusResults.red : fraudStatusResults.green
      }

      dataDispatch({
        type: SET_CUSTOMER_EXPLOIT_STATUS,
        payload: { customerExploitStatus: res.data.result },
      })

      setFraudStatus(status)
      onSuccessCallBack(status, res)
    },
  })
  return { status, data, fraudStatus, error, loading, refreshFraudStatus }
}
