// libs
import React, { useState, useEffect, useContext } from 'react'
// contexts and types
import { SessionContext } from 'contexts/session/SessionContext'
import { EntityContext } from 'contexts/entity/EntityContext'
import { RiderServiceContext } from 'contexts/riderService/RiderServiceContext'
import { RiderServiceAction } from 'contexts/riderService/types'
// hooks
import useRefreshPermissions from 'hooks/useRefreshPermissions'
import { useTranslation } from 'hooks/useTranslation'
import { createClient } from 'hooks/useFetchData'
import useDataPointAvailabilityCheck from 'hooks/useDataPointAvailabilityCheck'
// utils
import environment from 'envConfig'
import ensureJwtIsValid from 'utils/oneviewApi/ensureJwtIsValid'
import processApiErrors from 'utils/oneviewApi/processApiErrors'
import createOneviewHeaders from 'utils/oneviewApi/createOneviewHeaders'
import transformOrderStatus from 'utils/miniUtils/transformOrderStatus'
import transformFulfillment from 'utils/miniUtils/transformFulfillment'
// styles
import { createUseStyles } from 'react-jss'
import styles from './Order.styles'
import { Typography, Tag, Divider, Empty, Button } from 'antd'
// assets
import orderErrorIcon from 'assets/error/orderError.png'
// components
import MiniErrorView from 'components/MiniErrorView'
import Content from './Content'
import { MapView } from 'components/MapView/MapView'
import { useCaptureUserAction } from 'hooks/events/useCaptureUserAction'

const useStyles = createUseStyles(styles)

const Order = () => {
  const classes = useStyles()
  const { Text } = Typography

  const [displayMap, setDisplayMap] = useState(false)

  const handleDisplayMap = () => {
    setDisplayMap(true)
    captureUserAction('MapRevealed')
  }
  // pull translations
  const { t } = useTranslation()

  // pull entity context
  const { entityState } = useContext(EntityContext)

  // pull session
  const {
    sessionState: { orderId, globalEntityId, caseId },
  } = useContext(SessionContext)

  const captureUserAction = useCaptureUserAction()

  // pull rider state
  const {
    riderServiceState: { order, orderError, vendor, orderFulfillment },
    riderServiceDispatch,
  } = useContext(RiderServiceContext)
  const {
    SET_ORDER_STATUS,
    SET_ORDER_STATUS_ERR,
    SET_ORDER_FULFILLMENT,
    SET_ORDER_FULFILLMENT_ERR,
  } = RiderServiceAction

  // useRefreshPermissions fetches new token, if current is expired
  const { isRefreshTokenExpired } = useRefreshPermissions([orderId, globalEntityId])

  // if current refresh token is expired, redirect user to the root
  useEffect(() => {
    if (isRefreshTokenExpired) {
      window.location.reload()
    }
  }, [isRefreshTokenExpired])

  // ORDER STATUS
  const [statusIntervalDuration] = useState(
    entityState.entityConfig.miniOrder.orderStatusReloadTime,
  )
  const [statusIntervalState, setStatusIntervalState] = useState(0)
  useEffect(() => {
    // set interval
    const interval = setInterval(() => {
      setStatusIntervalState(Math.floor(Math.random() * Math.floor(statusIntervalDuration)))
    }, statusIntervalDuration)

    // clean interval upon dismounting
    return () => clearInterval(interval)
  }, [statusIntervalState, statusIntervalDuration])

  const [isLoadingStatus, setIsLoadingStatus] = useState(false)
  const [isRefresh, setIsRefresh] = useState(false)
  useEffect(() => {
    // async func to get order status
    const getOrderStatusHistoryAsync = async (orderCode: string) => {
      setIsLoadingStatus(true)
      const uri = `${environment().oneviewApiRoot}${
        environment().oneviewOrderApiRoot
      }/orders/${globalEntityId}/${orderCode}/status_history`

      const headers = createOneviewHeaders()

      await createClient()
        .get(uri, headers)
        .then((value) => {
          if (value && value.status === 200 && value.data) {
            const orderStatusTransformed = transformOrderStatus(
              value.data,
              entityState.entityConfig.utc_zone,
            )
            riderServiceDispatch({
              type: SET_ORDER_STATUS,
              payload: { orderStatus: orderStatusTransformed },
            })
          }
        })
        .catch((err) => {
          const orderStatusError = processApiErrors(
            t,
            err,
            'GetOrderStatusHistory',
            orderId,
            globalEntityId,
            true,
          )

          riderServiceDispatch({
            type: SET_ORDER_STATUS_ERR,
            payload: { orderStatusError: orderStatusError },
          })
        })
        .finally(() => setIsLoadingStatus(false))
    }

    const isTokensValid = ensureJwtIsValid(caseId)

    if (isTokensValid && order?.orderId) {
      getOrderStatusHistoryAsync(order.orderId)
    }
  }, [
    order,
    isRefresh,
    SET_ORDER_STATUS,
    SET_ORDER_STATUS_ERR,
    riderServiceDispatch,
    statusIntervalState,
    caseId,
    orderId,
    globalEntityId,
    t,
    entityState.entityConfig.utc_zone,
  ])

  // FULFILLMENT
  const [fulfillmentIntervalDuration] = useState(
    entityState.entityConfig.miniOrder.mapReloadInterval,
  )
  const [fulfillmentIntervalState, setFulfillmentIntervalState] = useState(0)
  useEffect(() => {
    // set interval
    const interval = setInterval(() => {
      setFulfillmentIntervalState(
        Math.floor(Math.random() * Math.floor(fulfillmentIntervalDuration)),
      )
    }, fulfillmentIntervalDuration)

    // clean interval upon dismounting
    return () => clearInterval(interval)
  }, [fulfillmentIntervalState, fulfillmentIntervalDuration])

  const [isLoadingFulfillment, setIsLoadingFulfillment] = useState(false)
  useEffect(() => {
    // async func to get order fulfillment
    const getOrderFulfillmentyAsync = async (orderCode: string) => {
      setIsLoadingFulfillment(true)
      const uri = `${environment().oneviewApiRoot}${
        environment().oneviewFulfillmentApiRoot
      }/orders/${globalEntityId}/${orderCode}`

      const headers = createOneviewHeaders()

      await createClient()
        .get(uri, headers)
        .then((value) => {
          // when cancel is successful, set states for success, displayed msg, and loading spinner
          if (value && value.status === 200 && value.data) {
            const fulfillmentTransformed = transformFulfillment(
              value.data,
              entityState.entityConfig.utc_zone,
            )

            riderServiceDispatch({
              type: SET_ORDER_FULFILLMENT,
              payload: { orderFulfillment: fulfillmentTransformed },
            })
          }
        })
        .catch((err) => {
          const orderFulfillmentError = processApiErrors(
            t,
            err,
            'GetOrderFulfillment',
            orderId,
            globalEntityId,
            true,
          )
          riderServiceDispatch({
            type: SET_ORDER_FULFILLMENT_ERR,
            payload: { orderFulfillmentError: orderFulfillmentError },
          })
        })
        .finally(() => {
          setIsLoadingFulfillment(false)
        })
    }

    const isTokensValid = ensureJwtIsValid(caseId)

    if (isTokensValid && order?.orderId) {
      getOrderFulfillmentyAsync(order.orderId)
    }
  }, [
    isRefresh,
    order,
    SET_ORDER_FULFILLMENT,
    SET_ORDER_FULFILLMENT_ERR,
    riderServiceDispatch,
    fulfillmentIntervalState,
    caseId,
    orderId,
    globalEntityId,
    t,
    entityState.entityConfig.utc_zone,
  ])

  const handleRefreshClick = () => {
    captureUserAction('MapReloadClicked')
    setIsRefresh(!isRefresh)
  }

  const isVertialType = useDataPointAvailabilityCheck(
    entityState.entityConfig.miniOrder.vertical_type,
    vendor?.vertical_type ? true : false,
    [entityState, vendor],
  )

  return (
    <div className={classes.orderContainer}>
      {order && !orderError && (
        <React.Fragment>
          <div className={classes.titleHolder}>
            <Text className={classes.sectionTitle}>{t('Order Widget.Order')}</Text>
            {vendor?.vendorVerticalType && isVertialType ? (
              <Tag color='blue'>{vendor.vendorVerticalType}</Tag>
            ) : null}
          </div>
          <Content isLoadingStatus={isLoadingStatus} isLoadingFulfillment={isLoadingFulfillment} />

          <Divider className={classes.divider} />
          {displayMap ? (
            <MapView
              customerLocation={orderFulfillment?.currentCustomerLocation}
              vendorLocation={orderFulfillment?.currentVendorLocation}
              riderLocations={[orderFulfillment?.currentRiderLocation]}
              deliveryInstructions={order?.deliveryInstructions}
              dropOffAddress={order?.deliveryAddress}
              vendorName={vendor?.vendorName}
              defaultZoom={13}
              containerClassnameKey={'miniWidgetContainer'}
              showRefreshStatus
              handleRefreshClick={handleRefreshClick}
            />
          ) : (
            <Empty imageStyle={{ display: 'none' }} description={''}>
              <Button onClick={handleDisplayMap} type='primary'>
                {t('Messages.Click here to display map')}
              </Button>
            </Empty>
          )}
        </React.Fragment>
      )}

      {!order && orderError && (
        <MiniErrorView icon={orderErrorIcon} errorMessage={t('Messages.No Order Found')} />
      )}
    </div>
  )
}

export default Order
