import { gray } from 'theme'
import type { CollapseProps } from '.'

export default {
  collapseThing: {
    borderRadius: '6px',
  },
  collapsePrimary: (props: CollapseProps) => ({
    composes: '$collapseThing',

    '& .ant-collapse-header': {
      maxHeight: 'none', // override parent's max height
      paddingRight: props.offsetIcon ? '16px' : null,
    },

    '& .ant-collapse-content>.ant-collapse-content-box': {
      paddingTop: '18px',
      paddingLeft: '12px',
      paddingRight: props.offsetIcon ? '40px' : '12px',
      paddingBottom: '12px',
    },
  }),
  collapseSecondary: (props: CollapseProps) => ({
    composes: '$collapseThing',
    border: !props.removeBorders && `1px solid ${gray.gray4}`,

    '& .ant-collapse-item': {
      backgroundColor: props.contentBgColor || 'inherit',
    },

    '& .ant-collapse-content': {
      background: gray.gray2,
    },
    '& .ant-collapse-item>.ant-collapse-header': {
      paddingRight: props.offsetIcon ? '16px' : null,
      background: props.bgColor ?? gray.gray3,
      padding: '8px 12px',
      borderRadius: '4px !important',
      maxHeight: 'none', // override parent's max height
    },
    '& .ant-collapse-item>.ant-collapse-content>.ant-collapse-content-box': {
      paddingTop: '16px',
      paddingLeft: '12px',
      paddingRight: props.offsetIcon ? '40px' : '12px',
      paddingBottom: '12px',
    },
  }),
}
