/**
 * Cancel Order action
 * renders a button opening up a modal for cancellaing an order
 * */

// libs
import React, { useState, useEffect, useContext } from 'react'
// contexts and types
import { SessionContext } from 'contexts/session/SessionContext'
import { EntityContext } from 'contexts/entity/EntityContext'
import { RiderServiceContext } from 'contexts/riderService/RiderServiceContext'
import { RiderServiceAction } from 'contexts/riderService/types'
import { SingleCancellationReason } from 'types/actions/cancelOrder'
import { MiniOrderStatuses } from 'types/widgets/order/orderStatuses'
// hooks
import { useTranslation } from 'hooks/useTranslation'
// utils
import environment from 'envConfig'
// styles
import { createUseStyles } from 'react-jss'
import styles from './CancelOrder.styles'
import { Button } from 'antd'
// components
import Content from './Content'
import DisabledButtonView from 'components/DisabledButtonView'
import { getOrderAgeInHours } from '../../../utils/getDisabledActionsDueToOrderAge'
import { allowedActions } from '../../../entityConfig/allowedConfigValues'
import { useUserHaveAny } from 'hooks/useGetUserPermissions'
import { getOrderStage } from '../../../utils/order/getOrderStage'
import {
  createFilterByDeliveryProvider,
  createFilterByOrderStage,
} from '../../../utils/order/cancelReasonFilters'
import { transformReason } from '../../../utils/order/cancellationReasons'

const useStyles = createUseStyles(styles)

const CancelOrder: React.FC = () => {
  const classes = useStyles()

  // pull language content
  const { t } = useTranslation()

  const {
    entityState: {
      entityConfig: {
        utc_zone,
        fixed_panel_config: {
          widgets_configs: {
            actions: { available_actions, cancel_order },
          },
        },
      },
    },
  } = useContext(EntityContext)

  // pull order and global entity ID from session context
  const {
    sessionState: { globalEntityId },
  } = useContext(SessionContext)

  const userHaveAny = useUserHaveAny()

  const {
    riderServiceState: { order, orderStatus, isCancelOrder },
    riderServiceDispatch,
  } = useContext(RiderServiceContext)
  const { SET_IS_CANCEL_ORDER } = RiderServiceAction

  // figure out if order is already cancelled; if not check user's permissions
  const [isLastOrderStatusCancelled, setIsLastOrderStatusCancelled] = useState(false)
  const [doesUserHasCancelPermission, setDoesUserHasCancelPermission] = useState(false)
  const [isOrderAgeAllowedForCancellation, setIsOrderAgeAllowedForCancellation] = useState(true)
  const [tooltipMessage, setTooltipMessage] = useState<string>(t('Messages.No Order ID Found!'))
  const [availableCancellationReasons, setAvailableCancellationReasons] = useState<
    SingleCancellationReason[]
  >([])

  // Check cancel order action permission for current user
  useEffect(() => {
    // check permissions list and set the component accordingly
    if (
      userHaveAny(globalEntityId, [
        environment().cancelOrderPermissions,
        environment().roleApacDefaultView,
        environment().roleApacWalletView,
      ])
    ) {
      setDoesUserHasCancelPermission(true)
    } else {
      setTooltipMessage(
        t(
          'Actions Widget.Actions.Cancel Order.Order cancellation is not permitted for the current user',
        ),
      )
      setDoesUserHasCancelPermission(false)
    }
  }, [globalEntityId, t, userHaveAny])

  // update isLastOrderStatusCancelled
  useEffect(() => {
    if (orderStatus?.orderStatus && orderStatus.orderStatus?.length > 0) {
      let isOrderCancelled = false
      const statusCancelled = orderStatus.orderStatus.find(
        (status: any) => status.status === MiniOrderStatuses.cancelled,
      )

      if (statusCancelled) {
        isOrderCancelled = true
      }

      if (isOrderCancelled) {
        setIsLastOrderStatusCancelled(true)
        setTooltipMessage(
          t(
            'Actions Widget.Actions.Cancel Order.Order cancellation is not possible for a cancelled order',
          ),
        )
      }
    }
  }, [orderStatus, globalEntityId, t])

  // update isOrderAgeAllowedForCancellation
  useEffect(() => {
    if (order && available_actions) {
      const cancelOrderAction = available_actions.find(
        (action) => action.feature === allowedActions.cancelOrder,
      )
      const orderAgeInHours = getOrderAgeInHours({ order, utc_zone })
      const maxOrderAgeInHours = cancelOrderAction?.maxOrderAgeInHours

      if (maxOrderAgeInHours && orderAgeInHours >= maxOrderAgeInHours) {
        setIsOrderAgeAllowedForCancellation(false)
        setTooltipMessage(
          t('Messages.Current order age does not allow this action', {
            replace: { ageInHours: maxOrderAgeInHours },
          }),
        )
      }
    }
  }, [available_actions, cancel_order, isOrderAgeAllowedForCancellation, order, t, utc_zone])

  // update availableCancellationReasons through stage
  useEffect(() => {
    if (!isLastOrderStatusCancelled) {
      let reasons: Array<SingleCancellationReason>
      const deliveryProvider = order?.orderDeliveryProvider
      // mapping status list that can be used to get order stage
      const statuses = orderStatus?.orderStatus
        ?.map((status) => ({ status: status.code }))
        ?.reverse()
      // if stage is not used, pass all reasons and their IDs to availableCancellationReasons
      if (!cancel_order.useStage) {
        reasons = cancel_order.availableCancellationReasons
          .filter(createFilterByDeliveryProvider(deliveryProvider))
          .map(transformReason)
      } else {
        const stage = getOrderStage(statuses)
        reasons = cancel_order.availableCancellationReasons
          .filter(createFilterByDeliveryProvider(deliveryProvider))
          .filter(createFilterByOrderStage(stage))
          .map(transformReason)
      }
      setAvailableCancellationReasons(reasons)
    }
  }, [cancel_order, order, orderStatus, isLastOrderStatusCancelled])

  const handleOpenCancelOrder = () => {
    riderServiceDispatch({
      type: SET_IS_CANCEL_ORDER,
      payload: { isCancelOrder: true },
    })
  }

  return (
    <React.Fragment>
      {!order ||
      !orderStatus?.orderStatus ||
      isLastOrderStatusCancelled ||
      !doesUserHasCancelPermission ||
      !isOrderAgeAllowedForCancellation ? (
        <div>
          <DisabledButtonView
            tooltipMsg={tooltipMessage}
            buttonText={t('Actions Widget.Actions.Cancel Order.Title')}
          />
        </div>
      ) : isCancelOrder ? (
        <Content availableCancellationReasons={availableCancellationReasons} />
      ) : (
        <Button
          className={classes.cancelOrderButton}
          type='primary'
          size='small'
          disabled={isLastOrderStatusCancelled}
          onClick={handleOpenCancelOrder}
        >
          {t('Actions Widget.Actions.Cancel Order.Title')}
        </Button>
      )}
    </React.Fragment>
  )
}

export default CancelOrder
