import { green, red } from 'theme'
import { MessageStatus } from '../types'

type CommonProps = {
  status?: MessageStatus
}

const backgroundColors: Record<MessageStatus, string> = {
  success: green.green1,
  error: red.red1,
}

const borderColors: Record<MessageStatus, string> = {
  success: green.green3,
  error: red.red2,
}

export default {
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
  },
  actionButtons: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    paddingBlock: '8px',
  },
  statusMessageContainer: {
    display: 'flex',
    padding: '12px',
    borderRadius: '6px',
    background: (props: CommonProps) => backgroundColors[props?.status],
    border: (props: CommonProps) => `1px solid ${borderColors[props?.status]}`,
  },
}
