import { useCallback } from 'react'
import {
  WidgetSubject,
  WidgetSubjectRequirementValidationResult,
  WidgetSubjectResolutionState,
  WidgetSubjectsRequirementsDefinition,
  WidgetSubjectsResolutionStates,
} from 'types/unitedUiConfig'

export interface UseValidateWidgetSubjectsRequirementsOpts {
  subjectsResolutionStates: WidgetSubjectsResolutionStates
}

export const useValidateWidgetSubjectsRequirements = () => {
  const validateSubject = useCallback(
    (
      subject: WidgetSubject,
      subjectResolutionState: WidgetSubjectResolutionState,
    ): WidgetSubjectRequirementValidationResult => {
      if (!subjectResolutionState) {
        return {
          status: 'failed',
          errorPayload: null,
          subject,
        }
      }

      const value = subjectResolutionState.value

      if (!(value === null || value === undefined || value === '')) {
        return {
          status: 'success',
          errorPayload: null,
          subject,
        }
      }

      if (subjectResolutionState.status === 'loading') {
        return {
          status: 'pending',
          errorPayload: null,
          subject,
        }
      }

      return {
        status: 'failed',
        errorPayload: subjectResolutionState.errorPayload,
        subject,
        onRetry: subjectResolutionState.onRetry,
      }
    },
    [],
  )

  return useCallback(
    (
      subjectsRequirements: WidgetSubjectsRequirementsDefinition,
      opts: UseValidateWidgetSubjectsRequirementsOpts,
    ): WidgetSubjectRequirementValidationResult => {
      const { subjectsResolutionStates } = opts

      const allOfParamsValidations = (subjectsRequirements?.all_of || []).map((subject) => {
        return validateSubject(subject, subjectsResolutionStates[subject])
      })

      const oneOfParamsValidations = (subjectsRequirements?.one_of || []).map((subject) => {
        return validateSubject(subject, subjectsResolutionStates[subject])
      })

      // fail if any of the required has failed
      const failedButRequired = allOfParamsValidations.find(
        (current) => current.status === 'failed',
      )

      if (failedButRequired) {
        return failedButRequired
      }

      // fail if not any one of the validations succeeded
      if (
        oneOfParamsValidations.length &&
        oneOfParamsValidations.every((current) => current.status === 'failed')
      ) {
        return oneOfParamsValidations[0]
      }

      // wait for a pending field to resolve
      const pendingButRequired = allOfParamsValidations.find(
        (current) => current.status === 'pending',
      )
      if (pendingButRequired) {
        return pendingButRequired
      }

      //
      if (
        oneOfParamsValidations.length &&
        oneOfParamsValidations.every((current) => current.status === 'pending')
      ) {
        return oneOfParamsValidations[0]
      }

      return {
        status: 'success',
        errorPayload: null,
        subject: null,
      }
    },
    [validateSubject],
  )
}
