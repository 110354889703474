export default {
  panelHeaderContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  panelHeaderTitles: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  panelHeaderDataText: {
    fontSize: '13px',
    minWidth: '33%',
  },
}
