export default {
  item: {
    padding: 0,
  },
  topRowTargetContentText: {
    fontSize: '14px',
    color: 'rgba(0, 0, 0, 0.65)',
  },
  footer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    marginTop: -38,
  },
  performedByLabel: {
    fontSize: '11px',
    color: 'rgba(0, 0, 0, 0.45)',
  },
  divider: {
    margin: '10px 0px 12px 0px',
    padding: 0,
  },
}
