import moment from 'moment'
import {
  VendorAvailabilitySchedule,
  VendorAvailabilityScheduleShift,
} from 'services/vendorApi/getVendorSchedule'

export interface TransformedVendorSchedule {
  scheduleTimezone?: string
  /**
   * vendor's current shift
   */
  currentShift?: VendorAvailabilityScheduleShift
}

export const transformVendorSchedule = (
  schedule: VendorAvailabilitySchedule,
): TransformedVendorSchedule => {
  const scheduleTimezone = schedule.time_zone
  if (!scheduleTimezone) {
    return { currentShift: null, scheduleTimezone }
  }

  const vendorLocalDate = moment().tz(scheduleTimezone)

  // check special days
  let matchingSchedule = schedule.special_days?.find((day) => {
    const specialDate = moment(day.date)
    // compare day and month
    return (
      vendorLocalDate.date() === specialDate.date() &&
      vendorLocalDate.month() === specialDate.month()
    )
  })

  // check regular schedule by weekday
  if (!matchingSchedule) {
    const dayName = vendorLocalDate.format('dddd').toLowerCase()

    matchingSchedule = schedule.schedules[dayName]
  }

  // Mix delivery and pickup shifts
  const shifts = [...(matchingSchedule?.delivery ?? []), ...(matchingSchedule?.pickup ?? [])]

  // No shifts
  if (!shifts.length) {
    return {
      currentShift: null,
      scheduleTimezone,
    }
  }

  return {
    scheduleTimezone,
    currentShift:
      shifts.find((shift) => {
        const shiftStartTime = moment.tz(shift.from, 'HH:mm', schedule.time_zone)
        const shiftEndTime = moment.tz(shift.to, 'HH:mm', schedule.time_zone)

        // Limit granularity to minutes. Start inclusive, end exclusive
        return vendorLocalDate.isBetween(shiftStartTime, shiftEndTime, 'minute', '[)')
      }) || null,
  }
}
