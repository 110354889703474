/**
 * New action widget to put rider on break
 */
import React, { useMemo, useRef, useState } from 'react'
import { Radio, Skeleton, Form, Input, Select } from 'antd'
import { createPluggableWidget } from 'factory/createPluggableWidget'
import { useTranslation } from 'hooks/useTranslation'
import { Flex, Button, WidgetActionFail } from 'shared'
import { useApiService } from 'hooks/useApiService'
import { RiderBreakType, putRiderOnBreak } from 'services/fulfillmentApi/putRiderOnBreak'
import { generateIntervalsTillShiftEnd } from 'utils/generateIntervalsTillShiftEnd'
import { BuiltinWidgetConfigs } from 'types'
import { createUseStyles } from 'react-jss'
import { RiderBreakSuccess } from './RiderBreakSuccess/RiderBreakSuccess'
import { parseInt } from 'lodash'

const useStyles = createUseStyles({
  radioGroup: {
    /* Ant radio button override. Seems to be unavoidable. */
    '& > .ant-radio-button-wrapper': {
      height: '32px !important',
      lineHeight: '32px !important',
    },
  },
  selectElement: {
    marginBottom: '32px',
  },
})

export const RiderBreak = createPluggableWidget<BuiltinWidgetConfigs['rider_break']>(
  ({ riderId, globalEntityId, rider, onQuit, config, sdk }) => {
    const { courier } = rider

    const classes = useStyles()
    const { t } = useTranslation()

    // This to work around twice firing of dropdown 'open' event when dropdown is opened using just using keyboard. See below
    const isBeakReasonSelectOpen = useRef(false)
    const isBeakDurationSelectOpen = useRef(false)

    // some form vars
    const [duration, setDuration] = useState({ value: '', label: '' })
    const [reason, setReason] = useState(null)
    // if courier state if ENDING, set default as tmp and do not allow it to be changed subsequently
    const [type, setType] = useState<RiderBreakType>(
      courier?.courier_status === 'ENDING' ? 'tmp' : 'break',
    )
    const [comment, setComment] = useState<string>('')

    // the service to put rider on break
    const { loadService: executePutRiderOnBreak, status: putRiderOnBreakStatus } = useApiService({
      service: putRiderOnBreak,
      deps: [],
      autoLoad: false,
    })

    // e.g, convert 11 min to [0,5,11]
    const intervalOptions = useMemo(() => {
      const intervals = generateIntervalsTillShiftEnd(rider?.courier?.shifts?.[0]?.ended_at)
      if (!intervals?.length) {
        return []
      }
      const options = intervals.map(({ hour, min }) => {
        let label = ''
        if (hour) {
          label = `${hour}h `
        }
        label += `${min}m`
        return { label, value: String(hour * 60 + min) }
      })
      return options
    }, [rider?.courier?.shifts])

    // use temp break reason if shift is ending, otherwise use long break reasons
    const reasons = type === 'tmp' ? config?.temp_break_reasons : config?.long_break_reasons

    const submit = () => {
      executePutRiderOnBreak({
        entityId: globalEntityId,
        riderId,
        duration: parseInt(duration.value),
        reason,
        comment,
        type,
      }).then(() => {
        sdk.captureUserAction('CONFIRM_ON_BREAK_SUCCESS', {
          eventDetails: {
            break_duration: `${duration.value}m`,
            break_reason: reason,
          },
        })
        sdk.eventEmitter.dispatchEvent({
          name: 'RIDER_BREAK_STATUS_CHANGE',
          payload: { riderId: riderId },
        })
      })
    }

    /**
     * UI PART
     */

    // busy ( over network call )
    if (putRiderOnBreakStatus === 'loading') {
      return <Skeleton active />
    }

    // error screen
    if (putRiderOnBreakStatus === 'error') {
      return (
        <WidgetActionFail
          heading={t(`widgets.rider_break.fail.heading`)}
          subHeading={t(`widgets.rider_break.fail.message`)}
          onDone={() => {
            onQuit()
          }}
        />
      )
    }

    // success
    if (putRiderOnBreakStatus === 'success') {
      return (
        <RiderBreakSuccess
          duration={duration.label}
          reason={t(`widgets.rider_break.reasons.${reason}`)}
          comment={comment}
          onDone={() => {
            sdk.captureUserAction('ACKNOWLEDGE_ON_BREAK')
            onQuit(true)
          }}
        />
      )
    }

    // main flow

    return (
      <Form layout='vertical'>
        <Flex flexDirection='column'>
          {/* type of break */}
          <Form.Item label={t(`widgets.rider_break.labels.type_of_break`)}>
            <Radio.Group
              className={classes.radioGroup}
              defaultValue={type}
              size='small'
              onChange={({ target }) => {
                setReason(null)
                setType(target?.value)
              }}
            >
              <Radio.Button value='break' disabled={courier?.courier_status === 'ENDING'}>
                {t(`widgets.rider_break.labels.break`)}
              </Radio.Button>
              <Radio.Button value='tmp'>
                {t(`widgets.rider_break.labels.temporary_not_working`)}
              </Radio.Button>
            </Radio.Group>
          </Form.Item>

          {/* duration of break */}
          <Form.Item
            label={`${t('widgets.rider_break.labels.duration')} *`}
            className={classes.selectElement}
          >
            <Select
              data-test-id='duration-selector'
              labelInValue
              options={intervalOptions}
              placeholder={t(`widgets.rider_break.labels.select_an_option`)}
              onChange={(val) => {
                sdk.captureUserAction('SELECT_BREAK_DURATION')
                setDuration(val)
              }}
              onDropdownVisibleChange={(open) => {
                if (open && open !== isBeakDurationSelectOpen.current) {
                  // Only track opening of the dropdown.
                  sdk.captureUserAction('OPEN_DROPDOWN_BREAK_DURATION')
                }
                // keep track of open state. Since open event is trigger twice without a close event in b/w. ( When opened using enter )
                isBeakDurationSelectOpen.current = open
              }}
            />
          </Form.Item>

          {/* reason of break */}
          <Form.Item
            label={`${t('widgets.rider_break.labels.reason')} *`}
            className={classes.selectElement}
          >
            <Select
              data-test-id='reason-selector'
              value={reason}
              options={(Array.isArray(reasons) ? reasons : []).map((text) => {
                return { label: t(`widgets.rider_break.reasons.${text}`), value: text }
              })}
              placeholder={t(`widgets.rider_break.labels.select_an_option`)}
              onChange={(value: string) => {
                sdk.captureUserAction('SELECT_BREAK_REASON')
                setReason(value)
              }}
              onDropdownVisibleChange={(open) => {
                if (open && open !== isBeakReasonSelectOpen.current) {
                  // Only track opening of the dropdown.
                  sdk.captureUserAction('OPEN_DROPDOWN_BREAK_REASON')
                }
                // keep track of open state. Since open event is trigger twice without a close event in b/w. ( When opened using enter )
                isBeakReasonSelectOpen.current = open
              }}
            />
          </Form.Item>

          {/* optional additional comments */}
          <Form.Item label={t('widgets.rider_break.comment')}>
            <Input.TextArea
              data-test-id='comment-area'
              onChange={(e) => {
                setComment(e.target.value.trim())
              }}
            ></Input.TextArea>
          </Form.Item>

          {/* submit/cancel */}
          <Form.Item>
            <Flex gap='8px' justifyContent='flex-end'>
              <Button
                type='default'
                onClick={() => {
                  onQuit()
                }}
              >
                {t('Interface.Cancel')}
              </Button>
              <Button
                data-test-id='submit-btn'
                disabled={!duration || !reason}
                type='solid'
                onClick={(e) => {
                  sdk.captureUserAction('CONFIRM_ON_BREAK')
                  e.stopPropagation()
                  submit()
                }}
              >
                {t(`widgets.rider_break.labels.put_on_break`)}
              </Button>
            </Flex>
          </Form.Item>
        </Flex>
      </Form>
    )
  },
  {
    deriveConfig({ entityConfig }) {
      return entityConfig.layout_v2.builtin_widgets_configs?.rider_break
    },
    deriveSubjectsRequirements() {
      return {
        all_of: ['rider'],
      }
    },
  },
)
