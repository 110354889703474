import { red, text } from 'theme'

export default {
  container: {
    width: '100%',
  },
  select: {
    width: '100%',
    '& .ant-select-selector.ant-select-selector': {
      borderRadius: '4px',
    },
    '& .ant-select-item-option-content': {
      whiteSpace: 'normal',
    },
  },
  errorMessage: {
    color: red.red5,
  },
  label: {
    color: text.secondary,
  },
}
