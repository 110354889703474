import { useApiClientCreator } from 'contexts/apiClientCreator/ApiClientCreatorContext'
import { useCaptureUserAction } from 'hooks/events/useCaptureUserAction'
import { useApiService } from 'hooks/useApiService'
import { useTranslation } from 'hooks/useTranslation'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { getEntityConfig } from 'services/configApi/getEntityConfig'
import { GetOrderPropertyFilterParams } from 'services/ordersApi/getLastOrders'
import { getOrder } from 'services/ordersApi/getOrder'
import { getVendor } from 'services/vendorApi/getVendor'
import { OrderApiResponse } from 'types/herocare'
import { LinesOfBusiness } from 'types/session/linesOfBusiness'
import { orderPropertyFilter } from 'types/unitedUiConfig'
import { readAuthPayload } from 'utils/authHelpers'
import { getEntityByEntityId, getEntityNameByEntityId } from 'utils/countries'
import { logError } from 'utils/reporting/logError'

export enum SearchType {
  OrderId = 'orderId',
  CustomerEmail = 'customerEmail',
  CustomerPhoneNumber = 'customerPhoneNumber',
  VendorId = 'vendorId',
  GlobalVendorId = 'globalVendorId',
}

export type ErrorField = 'entity_selector' | 'search_type_selector' | 'search_input'

export type SearchPanelHandler = (opts: {
  selectedSearchType: SearchType
  selectedEntityId: string
  searchValue: string
  searchedOrder: OrderApiResponse | null
  defaultHandler: () => any
}) => any

export interface UseSearchPanelProps {
  defaultEntityId: string
  lob: LinesOfBusiness

  /**
   * you can define your custom handler,
   */
  onSearch?: SearchPanelHandler

  defaultSearchType: SearchType

  /**
   * called whenever there is a change
   */
  onChangeEvent?: (
    change:
      | { event: 'searchTypeChanged'; value: SearchType }
      | {
          event: 'entityChanged'
          value: string
        }
      | { event: 'searchValueChanged'; value: string },
  ) => void
}

export const useSearchPanel = ({
  defaultEntityId,
  lob,
  defaultSearchType,
  onSearch,
  onChangeEvent,
}: UseSearchPanelProps) => {
  const [selectedEntity, setSelectedEntity] = useState('')
  const [searchValue, setSearchValue] = useState('')
  const [selectedSearchType, setSelectedSearchType] = useState(defaultSearchType)

  const [errorField, _setErrorField] = useState<ErrorField>(null)
  const [errorMessage, _setErrorMessage] = useState('')
  const [loading, setLoading] = useState(false)

  const searchedOrderRef = useRef<OrderApiResponse>(null)

  const [showSearchResultsPage, setShowSearchResultsPage] = useState(false)

  const { data: entityConfig, loadService: loadEntityConfig } = useApiService({
    service: getEntityConfig,
    deps: [selectedEntity, lob],
    shouldLoad: Boolean(selectedEntity),
    autoLoad: false,
    params: {
      lineOfBusiness: lob,
      entityId: selectedEntity,
    },
  })

  const { t } = useTranslation()

  const captureUserAction = useCaptureUserAction()
  const { createClient } = useApiClientCreator()

  const setErrorMessage = useCallback((errorMessage: string, errorField: ErrorField) => {
    _setErrorMessage(errorMessage)
    _setErrorField(errorMessage ? errorField : null)
  }, [])

  const clearErrorMessage = useCallback(() => {
    if (errorMessage) {
      _setErrorMessage('')
      _setErrorField(null)
    }
  }, [errorMessage])

  let allowSearchByCustomerPhone = false
  let submitEventName = ''

  switch (lob) {
    case LinesOfBusiness.vendor:
      submitEventName = 'ActionsSearchVendorSubmitted'
      allowSearchByCustomerPhone = Boolean(entityConfig?.allow_search_by_customer_phone)
      break

    default:
      submitEventName = 'ActionsSearchOrderSubmitted'
      allowSearchByCustomerPhone = Boolean(entityConfig?.allow_search_order_by_phone)
  }

  // react to changes to in entity config and reset search type
  // if selected search type is not allowed
  useEffect(() => {
    if (selectedSearchType === SearchType.CustomerPhoneNumber && !allowSearchByCustomerPhone) {
      setSelectedSearchType(defaultSearchType)
    }
  }, [allowSearchByCustomerPhone, defaultSearchType, selectedSearchType])

  // compute search instruction when search type is by customer phone number
  const { placeholder, instruction: searchInstruction } = useMemo((): {
    placeholder: string
    instruction?: string
  } => {
    let placeholder: string
    let instruction: string

    const entity = selectedEntity && getEntityByEntityId(selectedEntity)

    switch (selectedSearchType) {
      case SearchType.OrderId:
        placeholder = t('input_placeholder_labels.enter_order_id')

        break
      case SearchType.CustomerEmail:
        placeholder = t('input_placeholder_labels.enter_customer_email')
        break

      case SearchType.VendorId:
        placeholder = t('input_placeholder_labels.enter_vendor_id')
        break

      case SearchType.GlobalVendorId:
        placeholder = t('input_placeholder_labels.enter_global_vendor_id')
        break

      case SearchType.CustomerPhoneNumber:
        placeholder = t('input_placeholder_labels.enter_customer_phone_number')
        if (entity && entity.callingCode) {
          instruction = t(`Messages.Include the country code and use {{callingCode}} formats`, {
            replace: { callingCode: `"+${entity.callingCode}" or "${entity.callingCode}"` },
          })
        } else if (!entity) {
          logError({
            type: 'missing-entity-country-details',
            entity_id: selectedEntity,
          })
        } else {
          logError({
            type: 'missing-entity-country-calling-code',
            entity_id: selectedEntity,
          })
        }
        break
    }

    return {
      placeholder,
      instruction,
    }
  }, [selectedEntity, selectedSearchType, t])

  const searchFilters = useMemo((): GetOrderPropertyFilterParams => {
    switch (selectedSearchType) {
      case SearchType.CustomerEmail:
        return {
          [orderPropertyFilter.CustomerEmail]: searchValue,
          include_pending_order: true,
        }

      case SearchType.CustomerPhoneNumber:
        return {
          [orderPropertyFilter.CustomerPhoneNumber]: searchValue,
          include_pending_order: true,
        }

      default:
        return {
          [orderPropertyFilter.CustomerCode]: searchValue,
          include_pending_order: true,
        }
    }
  }, [selectedSearchType, searchValue])

  const clearState = () => {
    clearErrorMessage()
    searchedOrderRef.current = null
  }

  const onEntityChange = (entityId: string) => {
    clearState()
    setSelectedEntity(entityId)
    setLoading(true)

    loadEntityConfig({
      entityId,
    })
      .then(() => {
        onChangeEvent?.({ event: 'entityChanged', value: entityId })
      })
      .catch(() => {
        setSelectedEntity(null)
        setErrorMessage(
          t('widgets.search.load_entity_config_failed', {
            replace: {
              country: getEntityNameByEntityId(entityId),
            },
          }),
          'entity_selector',
        )
      })
      .finally(() => setLoading(false))
  }

  const onSearchValueChange = (value: string) => {
    clearState()
    value = value.trim().replace(/[^-\w@+.]/gim, '')
    setSearchValue(value)
    onChangeEvent?.({ event: 'searchValueChanged', value })
  }

  const onSearchTypeChange = (value: SearchType) => {
    clearState()
    setSelectedSearchType(value)
    onChangeEvent?.({ event: 'searchTypeChanged', value })
  }

  const onSearchResultsPageClose = () => setShowSearchResultsPage(false)

  const validate = async () => {
    if (!searchValue) {
      setErrorMessage(placeholder, 'search_input')
      return false
    }

    if (!selectedEntity) {
      setErrorMessage(t('select_labels.select_search_country'), 'entity_selector')
      return false
    }

    if (!selectedSearchType) {
      setErrorMessage(t('select_labels.select_search_type'), 'search_type_selector')
      return false
    }

    switch (selectedSearchType) {
      case SearchType.OrderId:
        setLoading(true)
        return getOrder(createClient, { orderId: searchValue, entityId: selectedEntity })
          .then((res) => {
            searchedOrderRef.current = res.data
            return true
          })
          .catch((error) => {
            if (error.response.status === 404) {
              setErrorMessage(t('errors.order_not_found.title'), 'search_input')
            } else {
              setErrorMessage(
                error.response.data?.error || t('errors.search_results_load_error.title'),
                'search_input',
              )
            }
            return false
          })
          .finally(() => {
            setLoading(false)
          })

      case SearchType.VendorId:
        setLoading(true)
        return getVendor(createClient, { vendorId: searchValue, entityId: selectedEntity })
          .then((res) => true)
          .catch((error) => {
            if (error.response.status === 404) {
              setErrorMessage(t('errors.vendor_not_found.title'), 'search_input')
            } else {
              setErrorMessage(
                error.response.data?.error || t('errors.search_results_load_error.title'),
                'search_input',
              )
            }
            return false
          })
          .finally(() => {
            setLoading(false)
          })

      case SearchType.GlobalVendorId:
        setLoading(true)
        return getVendor(createClient, { globalVendorId: searchValue, entityId: selectedEntity })
          .then((res) => true)
          .catch((error) => {
            if (error.response.status === 404) {
              setErrorMessage(t('errors.global_vendor_not_found.title'), 'search_input')
            } else {
              setErrorMessage(
                error.response.data?.error || t('errors.search_results_load_error.title'),
                'search_input',
              )
            }
            return false
          })
          .finally(() => {
            setLoading(false)
          })
    }

    return true
  }

  const defaultSearchHandler = () => setShowSearchResultsPage(true)

  const handleSubmit = async () => {
    const validationSuccess = await validate()

    if (!validationSuccess) {
      return
    }

    captureUserAction(submitEventName, {
      eventDetails: {
        search_country: selectedEntity,
        current_country: defaultEntityId || '',
      },
    })

    if (onSearch) {
      onSearch({
        selectedEntityId: selectedEntity,
        selectedSearchType,
        searchValue,
        defaultHandler: defaultSearchHandler,
        searchedOrder: searchedOrderRef.current,
      })
    } else {
      defaultSearchHandler()
    }
  }

  const availableEntitiesToSearch = useMemo(() => {
    return readAuthPayload()
      .namespaces.filter((e) => e !== 'BETA-TEST')
      .filter((current) => !current.startsWith('ODR_'))
  }, [])

  // run once
  useEffect(
    () => {
      if (defaultEntityId) {
        onEntityChange(defaultEntityId)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  return {
    availableEntitiesToSearch,
    selectedEntity,
    selectedEntityCountryName: selectedEntity ? getEntityNameByEntityId(selectedEntity) : '',
    onEntityChange,
    onSearchTypeChange,
    onSearchValueChange,

    showSearchResultsPage,
    onSearchResultsPageClose,

    searchValue,
    selectedSearchType,
    searchType: selectedSearchType,
    handleSubmit,

    allowSearchByCustomerPhone,

    errorField,
    errorMessage,
    setErrorMessage,
    clearErrorMessage,

    loading,
    setLoading,

    searchFilters,

    entityConfig,

    placeholder,
    searchInstruction,
  }
}
