import React, { ReactElement, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { createUseStyles } from 'react-jss'
import { Empty, Typography } from 'antd'

import environment from 'envConfig'
import GoogleMapReact from 'google-map-react'
import { useQueryParams } from 'hooks/useQueryParams'
import LocationPin from 'components/LocationPin'
import { EntityContext } from 'contexts/entity/EntityContext'
import { allowedDataPointValues } from 'entityConfig/allowedConfigValues'

import styles from './MapView.styles'
import { useTranslation } from 'hooks/useTranslation'

import modifyUTC from 'utils/modifyUTC'
import LoadingView from 'components/LoadingView'
import { useIsDataPointValid } from 'hooks/useGetValidFeatures'
import { MapPolyline } from './MapPolyline'
import { useDateTimeFormatter } from 'hooks/formatters/useDateTimeFormatter'
import { CourierEventItem } from 'types/api/fulfillmentApi/fulfillment'
import { useCheckDisplayRules } from 'hooks/useCheckDisplayRules'
import { RiderStatuses } from 'types/widgets/rider/riderStatuses'
import { gray, primary } from 'theme'

const useStyles = createUseStyles(styles)

interface MapLocation {
  latitude: number
  longitude: number
  timestamp?: string
}

export interface MapViewProps {
  riderEvents?: CourierEventItem[]
  riderLocations: MapLocation[]
  customerLocation: MapLocation
  vendorLocation: MapLocation

  defaultZoom?: number

  vendorName: string
  deliveryInstructions: string
  dropOffAddress: string

  containerClassnameKey?: 'container' | 'smallContainer' | 'miniWidgetContainer' | 'widgetContainer'

  /**
   * if given, the map handles refresh click
   */
  handleRefreshClick?: () => void

  showRefreshStatus?: boolean
  showFullRefreshButton?: boolean

  emptyView?: ReactElement

  isRefreshing?: boolean
}

const shortenLocationKeys = (arg?: MapLocation) => {
  if (arg) {
    return {
      lat: arg.latitude,
      lng: arg.longitude,
    }
  }
  return null
}

export const MapView = ({
  riderLocations: givenRiderLocations = [],
  customerLocation: givenCustomerLocation,
  vendorLocation: givenVendorLocation,
  riderEvents = [],
  defaultZoom,
  vendorName,
  deliveryInstructions,
  dropOffAddress,
  containerClassnameKey,
  showRefreshStatus,
  emptyView,
  isRefreshing,
}: MapViewProps) => {
  const classes = useStyles()
  const [map, setMap] = useState<google.maps.Map>()
  const [maps, setMaps] = useState<google.maps.MapsLibrary>()

  const dateTimeFormatter = useDateTimeFormatter()

  const checkDisplayRules = useCheckDisplayRules()

  const isDataPointValid = useIsDataPointValid()

  const entityConfig = useContext(EntityContext).entityState.entityConfig

  const utc_zone = entityConfig?.utc_zone

  const status = entityConfig?.scrollable_panel_config?.widgets_configs?.order?.tab_configs?.status

  const [showDeliveryInstructions, setShowDeliveryInstructions] = useState(true)

  useEffect(() => {
    if (status?.delivery_instructions) {
      const isDataPointAllowed = isDataPointValid(status.delivery_instructions?.betaRequirement)

      if (
        !isDataPointAllowed ||
        status.delivery_instructions.displayRule.includes(allowedDataPointValues.never_display)
      ) {
        setShowDeliveryInstructions(false)
      }
    }
  }, [status, isDataPointValid])

  const [lastRefreshTime, setLastRefreshTime] = useState('')

  const updateLastRefreshTime = useCallback(() => {
    const now = new Date().toISOString()
    const nowInLocal = modifyUTC(now, utc_zone)
    setLastRefreshTime(nowInLocal)
  }, [utc_zone])

  useEffect(() => {
    updateLastRefreshTime()
  }, [updateLastRefreshTime])

  const queryParams = useQueryParams()

  const simulateMap = queryParams.has('simulateMap')

  const riderLocation = useMemo(() => {
    return simulateMap
      ? {
          lat: 52.5116108,
          lng: 13.4226783,
        }
      : shortenLocationKeys(givenRiderLocations[0])
  }, [givenRiderLocations, simulateMap])

  const vendorLocation = useMemo(() => {
    return simulateMap
      ? {
          lat: 52.5099665,
          lng: 13.4337453,
        }
      : shortenLocationKeys(givenVendorLocation)
  }, [givenVendorLocation, simulateMap])

  const customerLocation = useMemo(() => {
    return simulateMap
      ? {
          lat: 52.523125,
          lng: 13.4105769,
        }
      : shortenLocationKeys(givenCustomerLocation)
  }, [givenCustomerLocation, simulateMap])

  const { t } = useTranslation()

  const { Text } = Typography

  const showRiderTrajectory = useMemo(() => {
    const riderTrajectory = entityConfig?.layout_v2?.maps?.rider_trajectory
    return checkDisplayRules(riderTrajectory).visible
  }, [entityConfig?.layout_v2?.maps?.rider_trajectory, checkDisplayRules])

  const riderTrajectory = useMemo(() => {
    const hasPickedUp = riderEvents.find((event) => event.name === RiderStatuses.pickedUp)
    const hasDelivered = riderEvents.find((event) => event.name === RiderStatuses.delivered)

    const trajectory = [riderLocation]

    if (hasDelivered) {
      return trajectory
    }
    if (hasPickedUp) {
      return trajectory.concat(customerLocation)
    }
    return trajectory.concat(vendorLocation, customerLocation)
  }, [riderEvents, customerLocation, vendorLocation, riderLocation])

  if (!riderLocation || !customerLocation) {
    return emptyView ? (
      emptyView
    ) : (
      <Empty
        description={
          <Text className={classes.notFoundMsg}>{`${t(
            'Messages.No courier, customer or vendor location found',
          )}.`}</Text>
        }
      ></Empty>
    )
  }

  const handleApiLoaded = (gMap: google.maps.Map, gMaps: google.maps.MapsLibrary) => {
    setMap(gMap)
    setMaps(gMaps)
  }

  return (
    <div id='map-container'>
      <div>
        <div className={classes[containerClassnameKey]}>
          <GoogleMapReact
            yesIWantToUseGoogleMapApiInternals={true}
            bootstrapURLKeys={{ key: environment().googleMapsApiKey }}
            defaultCenter={riderLocation || vendorLocation || customerLocation}
            onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
            defaultZoom={defaultZoom}
          >
            {/* marker for vendor */}
            {vendorLocation && (
              <LocationPin
                {...vendorLocation}
                marker='vendor'
                tooltipText={vendorName}
                color={null}
              />
            )}
            <LocationPin {...vendorLocation} marker='vendorPoint' />
            {/* marker for courier */}
            <LocationPin
              {...riderLocation}
              marker='rider'
              tooltipText={showDeliveryInstructions ? deliveryInstructions : ''}
            />
            <LocationPin {...riderLocation} marker='riderPoint' />
            {/* marker for customer */}
            <LocationPin {...customerLocation} marker='customer' tooltipText={dropOffAddress} />
            <LocationPin {...customerLocation} marker='customerPoint' />

            {showRiderTrajectory &&
              givenRiderLocations
                .slice(1)
                .map((givenRiderLocation) => (
                  <LocationPin
                    {...shortenLocationKeys(givenRiderLocation)}
                    marker='trajectoryPoint'
                    key={givenRiderLocation.timestamp}
                    tooltipText={dateTimeFormatter.formatTime(givenRiderLocation.timestamp)}
                  />
                ))}
            {showRiderTrajectory && (
              <>
                <MapPolyline
                  map={map}
                  maps={maps}
                  strokeColor={primary.primary6}
                  strokeWeight={6}
                  markers={riderTrajectory}
                />
                <MapPolyline
                  map={map}
                  maps={maps}
                  strokeColor={gray.gray7}
                  strokeWeight={6}
                  markers={givenRiderLocations.map(shortenLocationKeys)}
                />
              </>
            )}
          </GoogleMapReact>
          {showRefreshStatus ? (
            <Text className={classes.lastUpdatedText}>{`${t('Interface.Last Updated')}: ${
              lastRefreshTime || 'loading'
            }`}</Text>
          ) : null}
        </div>
      </div>

      {isRefreshing ? <LoadingView text={'loading'} overlay /> : null}
    </div>
  )
}
