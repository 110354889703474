import { useApiClientCreator } from 'contexts/apiClientCreator/ApiClientCreatorContext'
import { useCallback, useContext, useEffect, useRef } from 'react'
import { readAuthPayload } from 'utils/authHelpers'
import { refreshTrackingToken } from 'utils/refreshTrackingToken'
import environment from 'envConfig'
import { useAuthProvider } from 'contexts/auth/AuthProvider'
import { EtsEventPayload, postEventToEts } from 'services/externalApi/postEventToEts'
import { logError } from 'utils/reporting/logError'
import { SessionContext } from 'contexts/session/SessionContext'
import { usePrepareEventScreenPayload } from './usePrepareEventScreenPayload'

enum EtsEventReportError {
  FAILED_TO_GET_ETS_TOKEN,
  TOKEN_REJECTED,
  UNKNOWN,
}

interface EtsEvent {
  eventName: string
  payload: Omit<EtsEventPayload, 'screen'> & { screenDetails?: Record<string, any> }
  timestamp: string
}

export const useSendEventToEvts = () => {
  const stackedEventsRef = useRef<Array<EtsEvent>>([])

  const statusRef = useRef<'sending' | 'not-sending'>('not-sending')

  const { isLoggedIn } = useAuthProvider()
  const prepareEventScreenPayload = usePrepareEventScreenPayload()

  const {
    readSession: readSessionState,
    sessionState: { globalEntityId, uiVersion },
  } = useContext(SessionContext)

  const { createClient } = useApiClientCreator()

  const trackEvents = useCallback(async () => {
    const send = async (
      event: EtsEvent,
      agentEmail: string,
      agentName: string,
      isRetry?: boolean,
    ) => {
      const { payload, timestamp, eventName } = event
      const {
        globalEntityId,
        lineOfBusiness,
        caseId,
        userId,
        orderId,
        riderId,
        vendorId,
        globalVendorId,
        uiVersion,
        sessionId,
      } = readSessionState()

      let trackingToken

      // get tracking token
      try {
        trackingToken = (
          await refreshTrackingToken(createClient, {
            globalEntityId,
            forceRefresh: isRetry,
          })
        ).trackingToken
      } catch (ex) {
        logError({
          type: 'get-ets-token-error',
          originalError: ex,
          message: ex.message || 'failed to get ets token',
        })
        return Promise.reject(EtsEventReportError.FAILED_TO_GET_ETS_TOKEN)
      }

      const { screenDetails, ...others } = payload

      try {
        await postEventToEts(createClient, {
          entityId: globalEntityId,
          customerId: userId,
          riderId,
          orderId,
          vendorId,
          globalVendorId,
          eventName,
          agentEmail,
          agentName,
          caseId,
          sessionId,
          lob: lineOfBusiness,
          timestamp,
          payload: {
            ...others,
            screen: prepareEventScreenPayload(screenDetails),
          },
          trackingToken,
          uiVersion,
        })
      } catch (ex) {
        logError({
          type: 'ets-event-report-error',
          message: ex.message || 'event report failed',
        })

        if (ex.response.status !== 401) {
          return Promise.reject(EtsEventReportError.UNKNOWN)
        }

        // retry by getting a fresh token
        if (!isRetry) {
          return send(event, agentEmail, agentName, true)
        }

        // token rejected even after refreshing the token
        return Promise.reject(EtsEventReportError.TOKEN_REJECTED)
      }
    }

    if (statusRef.current === 'sending') {
      return
    }

    const { agentEmail, agentName } = readAuthPayload()
    const { globalEntityId, uiVersion } = readSessionState()

    // we do not send events when there is no agentEmail, globalEntityId and ui version
    // because it will be rejected anyway
    if (!agentEmail || !globalEntityId || !uiVersion) {
      return
    }

    statusRef.current = 'sending'
    let event: EtsEvent = stackedEventsRef.current.pop()
    let abort = false

    while (event && !abort) {
      try {
        await send(event, agentEmail, agentName)
      } catch (ex) {
        switch (ex) {
          case EtsEventReportError.FAILED_TO_GET_ETS_TOKEN:
          case EtsEventReportError.TOKEN_REJECTED:
            stackedEventsRef.current.push(event)
            abort = true
            break
        }
      }
      event = stackedEventsRef.current.pop()
    }

    statusRef.current = 'not-sending'
  }, [createClient, readSessionState, prepareEventScreenPayload])

  // track all stacked events once agent logs in, and there is ui version and global entity id
  useEffect(() => {
    if (isLoggedIn && globalEntityId && uiVersion) {
      trackEvents()
    }
  }, [isLoggedIn, globalEntityId, uiVersion, trackEvents])

  return useCallback(
    (eventName: string, payload: EtsEvent['payload']) => {
      if (!environment().sendEventsToEvts) {
        return false
      }
      const timestamp = new Date().toISOString()
      stackedEventsRef.current.push({ eventName, payload, timestamp })

      trackEvents()
    },
    [trackEvents],
  )
}
