export default {
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '1em',
  },
  customerTitle: {
    fontSize: '17px',
    color: '#3d4a4f',
  },
  idText: {
    marginLeft: '10px',
    fontSize: '14px',
    fontWeight: '500',
  },
}
