import { ApiService } from 'services/authApi/getPermissions'

export const getProofOfDelivery: ApiService<
  {
    entityId: string
    orderId: string
  },
  { image_url: string }
> = (createClient, options) => {
  const {
    entityId,
    orderId,

    clientParams,
  } = options

  const uri = `/FulfillmentAPI/v1/orders/${entityId}/${orderId}/proof-of-delivery`

  return createClient({
    endpointName: 'getProofOfDelivery',
    validateData: (data) => {
      if (!data.image_url) {
        return 'No proof of delivery found'
      }
      return true
    },
    ...clientParams,
  }).get(uri)
}
