/**
 * MenuView: renders a menu with dropdown which includes the passed content as a list
 * */

// libs
import React, { useState, useEffect } from 'react'
// contexts and types
import OrderStatusModified from 'types/riderService/orderStatusModified'
// utils
import capitalizeFirstLetters from 'utils/capitalizeFirstLetters'
// styles
import { createUseStyles } from 'react-jss'
import styles from './MenuView.styles'
import { Menu, Typography } from 'antd'
import { ArrowUpOutlined } from '@ant-design/icons'
import { useCaptureUserAction } from 'hooks/events/useCaptureUserAction'

const useStyles = createUseStyles(styles)

interface Props {
  label: string
  dropwdownContent: OrderStatusModified[]
}

const MenuView: React.FC<Props> = ({ label, dropwdownContent }) => {
  const classes = useStyles()
  const { Text } = Typography
  const { SubMenu } = Menu

  const captureUserAction = useCaptureUserAction()

  const [openKeys, setOpenKeys] = useState<string[]>([])
  const handleMenuClick = () => {
    if (openKeys?.length) {
      setOpenKeys([])
    } else {
      setOpenKeys([label])
    }

    // report to DD with correct menu label
    if (label === 'Order Status') {
      return captureUserAction('OrderStatusExpanderClicked', {
        eventDetails: {
          isOpened: openKeys?.length ? 'false' : 'true',
        },
      })
    }

    return captureUserAction('RiderEventsExpanderClicked', {
      eventDetails: {
        isOpened: openKeys?.length ? 'false' : 'true',
      },
    })
  }

  const [currentStatus, setCurrentStatus] = useState<string>('')
  useEffect(() => {
    const lastStatus = dropwdownContent[0].status.replace(/_/g, ' ')
    const lastStatusPrettified = capitalizeFirstLetters(lastStatus)
    setCurrentStatus(lastStatusPrettified)
  }, [dropwdownContent])

  return (
    <Menu
      openKeys={openKeys}
      onOpenChange={handleMenuClick}
      style={{ width: '100%' }}
      mode='inline'
      inlineIndent={0}
      selectable={false}
    >
      <SubMenu
        key={label}
        title={
          <React.Fragment>
            <Text className={classes.labelText}>{`${label}: `}</Text>
            <Text className={classes.dataText}>{currentStatus}</Text>
          </React.Fragment>
        }
      >
        {dropwdownContent && dropwdownContent.length > 0
          ? dropwdownContent.map((status: OrderStatusModified, idx: number) => {
              return (
                <Menu.Item
                  key={idx}
                  icon={<ArrowUpOutlined className={classes.icon} />}
                  className={classes.menuItem}
                >
                  <div className={classes.menuItemCol}>
                    <Text className={classes.statusText}>{status.status}</Text>
                    <Text className={classes.timestampText}>{status.timestamp}</Text>
                  </div>
                </Menu.Item>
              )
            })
          : null}
      </SubMenu>
    </Menu>
  )
}

export default MenuView
