import React, { MouseEvent } from 'react'
import { Button } from 'antd'
import { PhoneOutlined } from '@ant-design/icons'
import { createUseStyles } from 'react-jss'
import classnames from 'classnames'
import { gray, primary } from 'theme'
import { PhoneDecorator } from 'types'
import { SmartToolTip } from 'shared/SmartTooltip'
import { useTranslation } from 'hooks/useTranslation'

interface DecoratePhoneProps {
  decorator: PhoneDecorator
  phoneNumber: string | number
  caseId?: string
}

const useStyles = createUseStyles({
  phoneButton: {
    padding: '4px',
    color: primary.primary6,
    background: '#fff',

    '&.ant-btn-icon-only.ant-btn-sm': {
      borderRadius: '4px',
      borderColor: gray.gray5,
    },
  },
})

const decoratePhone = ({ decorator, phoneNumber, caseId }: DecoratePhoneProps) => {
  if (decorator === 'microbase') {
    return `eccmcapi:dialout?__sfc2d_RecordType=Case&__sfc2d_RecordId=${caseId}&__sfc2d_Number=${phoneNumber}#${phoneNumber}`
  }
  if (decorator === 'tel-link') {
    const ticketId = caseId ? `#${caseId}` : ''
    return `tel:${phoneNumber}${ticketId}`
  }
}

export interface BasePhoneDecoratorProps {
  linkTarget?: string
  onClick?: (e: MouseEvent<HTMLAnchorElement>) => void
  phoneNumber: string | number
  decorator?: PhoneDecorator
  className?: string
  caseId?: string
}

export const PhoneDialButton: React.FC<BasePhoneDecoratorProps> = ({
  onClick,
  phoneNumber,
  caseId,
  decorator = 'tel-link',
  className,
  linkTarget = '_parent',
}) => {
  const classes = useStyles()
  const { t } = useTranslation()

  if (decorator === 'none') {
    return null
  }

  if (phoneNumber) {
    return (
      <SmartToolTip title={t('Widgets Common.Call')}>
        <Button
          ghost
          type='primary'
          target={linkTarget}
          rel='noopener noreferrer'
          href={decoratePhone({ decorator, phoneNumber, caseId })}
          onClick={onClick}
          data-cy='phoneNumber'
          className={classnames(classes.phoneButton, className)}
          icon={<PhoneOutlined />}
          size='small'
        />
      </SmartToolTip>
    )
  }

  return null
}
