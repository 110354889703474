import { SessionContext } from 'contexts/session/SessionContext'
import { useApiService } from 'hooks/useApiService'
import { useGetApiVersionConfig } from 'hooks/useApiVersionConfig'
import { useContext } from 'react'
import { getOrderComments } from 'services/commentApi/getOrderComments'

export function useLoadOrderComments() {
  const {
    sessionState: { globalEntityId, orderId },
  } = useContext(SessionContext)

  const getApiVersionConfig = useGetApiVersionConfig()

  const apiVersion = getApiVersionConfig('getOrderComments')

  const shouldLoad = Boolean(globalEntityId && orderId)

  return useApiService({
    service: getOrderComments,

    params: {
      entityId: globalEntityId,
      orderId,
      apiVersion,
      clientParams: {
        cacheable: true,
      },
    },

    shouldLoad,
  })
}
