import { Skeleton, SkeletonProps, Space } from 'antd'
import { WidgetErrorHandler } from 'components/WidgetErrorHandler/widgetErrorHandler'

import React, { FC } from 'react'
import { createUseStyles } from 'react-jss'
import { WidgetSubjectRequirementValidationResult } from 'types/unitedUiConfig'

const useStyles = createUseStyles({
  spacer: {
    display: 'flex',
  },
})

export const WidgetDataRequirementsValidation: FC<{
  validationResult: WidgetSubjectRequirementValidationResult
  childCount?: number
  loaderProps?: SkeletonProps
}> = ({ validationResult, children, childCount = 1, loaderProps }) => {
  const styles = useStyles()

  if (validationResult?.status === 'pending') {
    return (
      <Space direction='vertical' size={40} className={styles.spacer}>
        {new Array(childCount).fill(0).map((_, index) => {
          return <Skeleton active key={index + 1} {...loaderProps} />
        })}
      </Space>
    )
  }

  if (validationResult?.status === 'failed') {
    if (validationResult.errorPayload) {
      return (
        <WidgetErrorHandler
          errorPayload={validationResult.errorPayload}
          onRetry={validationResult.onRetry}
        />
      )
    } else {
      return (
        <WidgetErrorHandler
          missingParameters={[validationResult.subject || 'unknown']}
          onRetry={validationResult.onRetry}
        />
      )
    }
  }

  return <>{children || null}</>
}
