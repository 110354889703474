import React from 'react'
import { createCustomSvgIcon } from 'factory/createCustomSvgIcon'

export const LocationIcon = createCustomSvgIcon({
  viewBoxWidth: 15,
  viewBoxHeight: 16,
  content: (
    <>
      <path
        d='M13.6181 4.02076C13.2819 3.24555 12.7972 2.54365 12.1914 1.95469C11.5835 1.3604 10.8669 0.88872 10.0806 0.565402C9.26278 0.226116 8.39492 0.0546875 7.50028 0.0546875C6.60564 0.0546875 5.73778 0.226116 4.91992 0.563616C4.12885 0.890402 3.41992 1.35826 2.80921 1.9529C2.20371 2.5422 1.71906 3.24401 1.38242 4.01897C1.03421 4.82255 0.857422 5.67612 0.857422 6.55469C0.857422 7.8154 1.15921 9.07076 1.75206 10.2815C2.22885 11.2547 2.89492 12.2029 3.73421 13.1047C5.16814 14.644 6.67171 15.585 7.09849 15.8368C7.21964 15.9084 7.3578 15.946 7.49849 15.9458C7.63778 15.9458 7.77528 15.91 7.89849 15.8368C8.32528 15.585 9.82885 14.644 11.2628 13.1047C12.1021 12.2047 12.7681 11.2547 13.2449 10.2815C13.8414 9.07255 14.1431 7.81897 14.1431 6.55647C14.1431 5.6779 13.9664 4.82433 13.6181 4.02076ZM7.50028 14.5761C6.32349 13.8279 2.14314 10.8654 2.14314 6.55647C2.14314 5.1654 2.69849 3.85826 3.70742 2.87254C4.71992 1.88504 6.06635 1.3404 7.50028 1.3404C8.93421 1.3404 10.2806 1.88504 11.2931 2.87433C12.3021 3.85826 12.8574 5.1654 12.8574 6.55647C12.8574 10.8654 8.67706 13.8279 7.50028 14.5761ZM7.50028 3.55469C5.76456 3.55469 4.35742 4.96183 4.35742 6.69755C4.35742 8.43326 5.76456 9.8404 7.50028 9.8404C9.23599 9.8404 10.6431 8.43326 10.6431 6.69755C10.6431 4.96183 9.23599 3.55469 7.50028 3.55469ZM8.91457 8.11183C8.72907 8.29786 8.50862 8.44538 8.2659 8.5459C8.02319 8.64642 7.76299 8.69795 7.50028 8.69755C6.96635 8.69755 6.46456 8.48862 6.08599 8.11183C5.89996 7.92633 5.75245 7.70589 5.65193 7.46317C5.55141 7.22045 5.49987 6.96025 5.50028 6.69755C5.50028 6.16362 5.70921 5.66183 6.08599 5.28326C6.46456 4.90469 6.96635 4.69755 7.50028 4.69755C8.03421 4.69755 8.53599 4.90469 8.91457 5.28326C9.29314 5.66183 9.50028 6.16362 9.50028 6.69755C9.50028 7.23147 9.29314 7.73326 8.91457 8.11183Z'
        fillOpacity='0.85'
      />
    </>
  ),
})
