import { ItemStructured } from 'types/actions/partialRefund'
import { calculateMissingOrderItemTotalPrice } from './orderItemPricings'

interface Opts {
  partialItemRefundPercentage: number
  considerPartialSelection?: boolean
}

export const calculateSelectedMissingItemTotalPrice = (item: ItemStructured, opts: Opts) => {
  if (item.selected || item.partially_selected) {
    return calculateMissingOrderItemTotalPrice(item, opts)
  }

  return item.options.reduce((sum, item) => {
    return sum + calculateSelectedMissingItemTotalPrice(item, opts)
  }, 0)
}

export const calculateSelectedMissingItemsTotalPrice = (items: ItemStructured[], opts: Opts) => {
  return items.reduce((sum, item) => {
    return sum + calculateSelectedMissingItemTotalPrice(item, opts)
  }, 0)
}
