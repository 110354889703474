/**
 * Summary tab for order widget
 * renders two lists as columns each containing general info of customer
 * */

// libs
import React, { useState, useEffect, useContext } from 'react'
// configs

// contexts and types
import { DataContext } from 'contexts/data/DataContext'
import { TransformedDataPoint, TranformedTags } from 'types/dataTransformers/generic'

// hooks
import { useTranslation } from 'hooks/useTranslation'
import { useEntityUtcZone } from 'hooks/useEntityUtcZone'

// utils
import { getSplitDeliveryTags } from 'utils/getters/getSplitDeliveryTags'
import checkDataPointAvailability from 'utils/checkDataPointAvailability'
import classnames from 'classnames'

// styles
import { createUseStyles } from 'react-jss'

// Components
import { List } from 'antd'

// Relative Path
import Tags from './Tags'
import ChatTranscript from './ChatTranscript'
import styles from './Summary.styles'
import Timeline from './Timeline'
import ProofOfDeliveryListItem from './ProofOfDeliveryListItem/ProofOfDeliveryListItem'
import { NewDesign } from 'components/NewDesign/NewDesign'
import { useIsDataPointValid } from 'hooks/useGetValidFeatures'
import RidersInfo from './Riders/RidersInfo'
import { AvailableAction, OrderWidgetSummary } from 'contexts/entity/types'
import { Weight } from './Weight/Weight'
import { useEntityConfig } from '../../../hooks/useEntityConfig'
import { getOrderEligibilities } from '../../../utils/order/getOrderEligibilities'
import { useTransformOrderSummary } from 'hooks/dataTransformers/useTransformOrderSummary'
import { DefaultListItemDataPoint } from 'components/DatapointListItemRenderers/DefaultListItemDataPoint/DefaultListItemDataPoint'
import { useLoadOrderFlags } from 'hooks/apiHooks/useLoadOrderFlags'
import { dataPointRequirements } from 'types/unitedUiConfig'

const useStyles = createUseStyles(styles)

type Props = {
  summary: OrderWidgetSummary
  availableActions: AvailableAction[]
  showSplitDeliveries: {
    betaRequirement: string[]
  }
  currencyDigitsAfterComma: number
  onRiderNameClick: (id: number) => void
  onRiderStatusClick: (id: number) => void
}

const Summary = ({
  summary,
  showSplitDeliveries: show_split_deliveries,
  currencyDigitsAfterComma: currency_digits_after_comma,
  onRiderNameClick,
  onRiderStatusClick,
}: Props) => {
  const classes = useStyles()

  // pull language content
  const { t } = useTranslation()

  // pull data state and dispatch from data context
  const {
    dataState: { order, fulfillment, riderCustomerChatTranscript, vendor },
  } = useContext(DataContext)

  const isDataPointValid = useIsDataPointValid()
  const utc_zone = useEntityUtcZone()
  const {
    entityConfig: { enableOrderEligibilities },
  } = useEntityConfig()

  // transform data for the tab's 2 columns
  const [leftColumn, setLeftColumn] = useState<TransformedDataPoint[]>()
  const [rightColumn, setRightColumn] = useState<TransformedDataPoint[]>()
  const [tags, setTags] = useState<TranformedTags[]>()
  const isNewDesignAllowed = isDataPointValid(summary.newDesign?.betaRequirement)
  const displayPayableDataPoint = isDataPointValid(summary?.payout_status?.betaRequirement)
  const showSplitDeliveries = isDataPointValid(show_split_deliveries?.betaRequirement)
  const transformOrderSummary = useTransformOrderSummary()
  const { data: orderFlags } = useLoadOrderFlags({
    shouldLoad:
      displayPayableDataPoint &&
      [dataPointRequirements.always, dataPointRequirements.displayWhenDataFound].some((rule) =>
        summary?.payout_status?.displayRule?.includes(rule),
      ),
  })
  useEffect(() => {
    const { orderEligibilities } = getOrderEligibilities({ order })
    if (order) {
      const { transformedLeftColumnData, transformedRightColumnData, transformedTagsData } =
        transformOrderSummary(order, orderFlags, {
          useNewDesign: isNewDesignAllowed,
          displayPayableDataPoint,
          config: summary,
        })

      const eligibilities: TranformedTags[] = enableOrderEligibilities ? orderEligibilities : []

      setLeftColumn(transformedLeftColumnData)
      setRightColumn(transformedRightColumnData)
      setTags([
        ...eligibilities,
        ...transformedTagsData,
        ...getSplitDeliveryTags(fulfillment, t('Order Widget.Tabs.Summary.Split Delivery')),
      ])
    }
  }, [
    transformOrderSummary,
    fulfillment,
    order,
    orderFlags,
    utc_zone,
    t,
    enableOrderEligibilities,
    vendor,
    currency_digits_after_comma,
    isNewDesignAllowed,
    displayPayableDataPoint,
    summary,
  ])

  const [isRiderChatTranscript, setIsRiderChatTranscript] = useState(null)
  useEffect(() => {
    let isRiderChatTranscriptAllowed = false
    if (summary?.riderChatTranscript) {
      const isChatTranscriptBetaGranted = isDataPointValid(
        summary.riderChatTranscript.betaRequirement,
      )

      if (isChatTranscriptBetaGranted) {
        isRiderChatTranscriptAllowed = checkDataPointAvailability(
          summary.riderChatTranscript.displayRule,
          true,
        )
      }
      setIsRiderChatTranscript(isRiderChatTranscriptAllowed)
    }
  }, [summary, riderCustomerChatTranscript, isDataPointValid])

  return (
    <div className={classes.summaryContainer}>
      {/* @TODO: Temporary New Design in Beta */}
      <NewDesign config={summary}>
        <Timeline order={order} utc_zone={utc_zone} fulfillment={fulfillment} />
      </NewDesign>
      <div className={classes.listsContainer}>
        <div className={classes.listHolder}>
          <List
            className={classnames({ [classes.list]: isNewDesignAllowed })}
            size='small'
            itemLayout='horizontal'
            dataSource={leftColumn}
            renderItem={(item: TransformedDataPoint) => {
              const { Render = DefaultListItemDataPoint } = item
              return <Render item={item} />
            }}
          />
          <NewDesign config={summary}>
            <Weight order={order} config={summary.weight} />
          </NewDesign>
          <NewDesign config={summary}>
            <ProofOfDeliveryListItem summary={summary} />
          </NewDesign>
        </div>
        <div className={classes.listHolderFlex}>
          <List
            className={classnames({ [classes.list]: isNewDesignAllowed })}
            size='small'
            itemLayout='horizontal'
            dataSource={rightColumn}
            renderItem={(item: TransformedDataPoint) => {
              const { Render = DefaultListItemDataPoint } = item
              return <Render item={item} />
            }}
          />
          {/* Chat Transcript */}
          <NewDesign config={summary} andIf={isRiderChatTranscript}>
            <ChatTranscript />
          </NewDesign>
        </div>
        {showSplitDeliveries && (
          <RidersInfo
            fulfillment={fulfillment}
            onRiderNameClick={onRiderNameClick}
            onRiderStatusClick={onRiderStatusClick}
          />
        )}

        {/* Chat Transcript */}
        <NewDesign config={summary} fallback={<ChatTranscript />} />
        {/* Tags */}
        <Tags tags={tags} summary={summary} />
      </div>
    </div>
  )
}

export default Summary
