/**
 * Expects PaymentAPI's resposne and returns a new data structure to be used in order widget payment tab
 * */

// types
import {
  PaymentHistoryApiResponse,
  TransactionHistory,
  PaymentBreakDown,
} from 'types/api/paymentApi/getPaymentHistory'
import { PaymentStatusesApiResponse } from 'types/dataTransformers/paymentHistory'
// utils
import modifyUTC from 'utils/modifyUTC'
import sortArrayThroughIsoDate from 'utils/sortArrayThroughIsoDate'
import fixNumber from 'utils/fixNumber'
import { unknownDataPoint } from './constants'

function transformPaymentHistory(
  data: PaymentHistoryApiResponse,
  utc_zone: string,
  t: any,
  currency_digits_after_comma: number,
) {
  const returnData = {
    paymentHistory: [],
    paymentDetails: [],
  }

  if (data && data.transaction_history && data.transaction_history.length > 0) {
    // sort data's transaction history acc. to items' ISO date
    data.transaction_history = sortArrayThroughIsoDate(data.transaction_history)

    // create custom payment details arr
    returnData.paymentDetails = [
      {
        label: t('Order Widget.Tabs.Payment.Payment Method'),
        value: stringifyPaymentMethods(data.payment_methods),
      },
      {
        label: t('Order Widget.Tabs.Payment.Payment Status'),
        value:
          data.transaction_history && data.transaction_history.length > 0
            ? insertSpaceBeforeCapitals(data.transaction_history[0].type, t)
            : unknownDataPoint,
      },
      {
        label: t('Order Widget.Tabs.Payment.Paid'),
        value: `${data.total_paid.currency} ${fixNumber(
          data.total_paid.amount,
          currency_digits_after_comma,
        )}`,
      },
      {
        label: t('Order Widget.Tabs.Payment.Refunded to Wallet'),
        value: `${data.total_refunded.currency} ${fixNumber(
          data.total_refunded.amount,
          currency_digits_after_comma,
        )}`,
      },
      {
        label: t('Order Widget.Tabs.Payment.Total Paid'),
        // calculate total paid -> paid minus refunded
        value: `${data.total_paid.currency} ${fixNumber(
          data.total_paid.amount - data.total_refunded.amount,
          currency_digits_after_comma,
        )}`,
      },
    ]
    // map over transaction history and push each transformed item to returnData's payment history
    data.transaction_history.forEach((trans: TransactionHistory) => {
      const historyItem = {
        // modify date for entity
        date: modifyUTC(trans.creationDateTime, utc_zone),
        // insert space before capital letters
        status: insertSpaceBeforeCapitals(trans.type, t),
        amount: `${trans.totalAmount.currency} ${fixNumber(
          trans.totalAmount.amount,
          currency_digits_after_comma,
        )}`,
        breakDown: modifyTransactionBreakDown(trans.paymentBreakdown, currency_digits_after_comma),
      }
      returnData.paymentHistory.push(historyItem)
    })
  }

  return returnData
}

// maps over methods arr and returns a coma separated string of methods
function stringifyPaymentMethods(methods: Array<string>) {
  let methodStr = ''
  // map over methods arr and concanate each item into a str
  methods.forEach((method: string, idx: number) => {
    if (idx === 0) {
      methodStr = method
    } else {
      methodStr = methodStr + ', ' + method
    }
  })
  return methodStr
}

// maps over payment transaction breakdown and returns a modified data structure
function modifyTransactionBreakDown(
  breakdown: Array<PaymentBreakDown>,
  currency_digits_after_comma: number,
) {
  // return structure
  const modifiedBreakDown: Array<any> = []
  // map over breakdown and push altered structure to return arr
  breakdown.forEach((b: PaymentBreakDown) => {
    const breakdownItem = {
      method: b.description,
      amount: `${b.amount.currency} ${fixNumber(b.amount.amount, currency_digits_after_comma)}`,
    }
    modifiedBreakDown.push(breakdownItem)
  })
  return modifiedBreakDown
}

// expects a string and returns the same string with spaces added before capital letters
function insertSpaceBeforeCapitals(str: string, t: any) {
  if (str === PaymentStatusesApiResponse.refundShopToWallet) {
    return t('Order Widget.Tabs.Payment.Refund to Wallet')
  } else if (str === PaymentStatusesApiResponse.refundShopToSource) {
    return t('Order Widget.Tabs.Payment.Refund to Source')
  } else if (str === PaymentStatusesApiResponse.partialRefundShopToWallet) {
    return t('Order Widget.Tabs.Payment.Partial Refund to Wallet')
  } else if (str === PaymentStatusesApiResponse.partialRefundShopToSource) {
    return t('Order Widget.Tabs.Payment.Partial Refund to Source')
  } else {
    return str.replace(/([A-Z])/g, ' $1').trim()
  }
}

export default transformPaymentHistory
