export default {
  list: {
    '& .ant-list-item': {
      display: 'flex',
    },
    '& .ant-collapse-ghost > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box':
      {
        paddingTop: 0,
        paddingBottom: 0,
        paddingRight: 0,
      },
    '& .ant-collapse > .ant-collapse-item.ant-collapse-no-arrow > .ant-collapse-header': {
      padding: '8px !important',
    },
  },
  listItem: {
    '&.ant-list-item': {
      padding: '8px',
    },
  },
  listItemWithoutBorder: {
    '&.ant-list-item': {
      borderBottom: 'none',
      padding: '8px',
    },
  },
  total: {
    '&.ant-typography': {
      fontSize: '20px',
    },
  },
}
