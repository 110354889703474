import React from 'react'
import { useMemo } from 'react'
import { GetOrderPropertyFilterParams } from 'services/ordersApi/getLastOrders'
import { BuiltinWidgetConfigs } from 'types/unitedUiConfig'
import { useTranslation } from 'hooks/useTranslation'
import { OrdersList } from '../../components/OrdersList/OrdersList'
import { LinesOfBusiness, orderPropertyFilter } from 'types/herocare'
import { LAST_ORDERS_WIDGET_MAX_WIDTH, LAST_ORDERS_WIDGET_WIDTH } from 'constants/constants'
import { createPluggableWidget } from 'factory/createPluggableWidget'

export const LastOrdersV2 = createPluggableWidget<BuiltinWidgetConfigs['last_orders']>(
  ({ lob, globalEntityId, vendorId, customerId, order, activeTab, ErrorRenderer, config }) => {
    const isVendor = lob === LinesOfBusiness.vendor

    const filters = useMemo((): GetOrderPropertyFilterParams => {
      if (isVendor) {
        return {
          [orderPropertyFilter.VendorId]: vendorId,
          include_pending_order: true,
        }
      }
      return {
        [orderPropertyFilter.CustomerCode]: customerId,
        include_pending_order: true,
      }
    }, [vendorId, customerId, isVendor])

    const { t } = useTranslation()

    return (
      <OrdersList
        title={t(`widgets.last_orders.results.title`)}
        lob={lob}
        currentOrderId={order?.order_id}
        noOrdersMatchedFilterDescription={t('errors.no_orders_matched_filter.description')}
        filters={filters}
        entityId={globalEntityId}
        ErrorRenderer={ErrorRenderer}
        activeTab={activeTab}
        dataPointConfig={config?.data_points}
        pageSize={config?.page_size?.value}
      />
    )
  },
  {
    category: 'data_lookup',
    deriveConfig({ entityConfig }) {
      return entityConfig.layout_v2.builtin_widgets_configs.last_orders
    },

    deriveInitialViewState() {
      return {
        destroyable: false,
        width: LAST_ORDERS_WIDGET_WIDTH,
        maxWidth: LAST_ORDERS_WIDGET_MAX_WIDTH,
      }
    },
  },
)
