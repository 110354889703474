import { availableRefundMethods } from 'entityConfig/allowedConfigValues'

export * from './widgetIds'

export const EMPTY_CALLBACK = () => {
  // do nothing
}

export const WALLET_OR_PSP_REFUND_METHODS = [
  availableRefundMethods.source,
  availableRefundMethods.wallet,
]

export const EMPTY_ARRAY = []

export const EMPTY_CATCH_CALLBACK = () => {
  // do nothing
}

export const DEFAULT_DOT_COLOR = '#b8b4b4'

export const DEFAULT_PAGINATION_PAGE_SIZE = 10

export const SIDEDRAWER_WIDTH = 'calc(100vw - 32px)'

export const SIDEDRAWER_MAX_WIDTH = 800
/**
 * configures the maximum number of visible action buttons in the resolution bar,
 * that is not the refresh action or wrap up action
 */
export const MAX_VISIBLE_RESOLUTION_BAR_ACTIONS = 3

export const LAST_ORDERS_WIDGET_WIDTH = 'calc(100vw - 32px)'

export const LAST_ORDERS_WIDGET_MAX_WIDTH = 800

// all possible values that can be passed by herocare in the lang param
export const herocareLangTable = {
  spa: 'es', // Spanish
  eng: 'en', // English
  hun: 'hu', // Hungarian
  tur: 'tr', // Turkish
  jpn: 'ja', // Japanese
  tha: 'th', // Thai
  ara: 'ar', // Arabic
}

export const salesforceLangTable = {
  es: 'es', // Spanish
  en_US: 'en', //English
  en_GB: 'en', // English
  ko: 'ko', // Korean
  sv: 'sv', // Swedish
  fi: 'fi', // Finnish
  de: 'de', // German
  hu: 'hu', // Hungarian
  tr: 'tr', // Turkish
  cs: 'cs', // Czech
  ja: 'ja', // Japanese
  ar: 'ar', // Arabic
  th: 'th', // Thai
  gr: 'gr', // Greek
  vi: 'vi', // Vietnamese
}
