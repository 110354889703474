enum OrderTabs {
  summary = 'summary',
  items = 'items',
  status = 'status',
  payment = 'payment',
  basket_updates = 'basket_updates',
  invoice = 'invoice',
  plugin = 'plugin',
}

export default OrderTabs
