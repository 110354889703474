/**
 * expects a timestamp and entity UTC offset as strings
 * returns a modified local time for entity
 * */
import moment from 'moment-timezone'

function modifyUTC(
  timestamp: string | Date | number,
  utcZone: string,
  format = 'D.M.YYYY, H:mm',
): string {
  if (timestamp) {
    const timezoneModifiedWithUtcOffset = moment(timestamp).tz(utcZone).format(format)
    return timezoneModifiedWithUtcOffset
  }
  return ''
}

export default modifyUTC
