import React from 'react'
import { Tag } from 'antd'
import { RightCircleOutlined } from '@ant-design/icons'
import { FulfillmentApiResponse } from 'types/api/fulfillmentApi/fulfillment'
import { useGetRiderStatusTextAndColor } from 'hooks/useGetRiderStatusTextAndColor'
import { useStyle } from './Riders.style'
import { useTranslation } from 'hooks/useTranslation'
import { RiderStatuses } from 'types/widgets/rider/riderStatuses'

type Props = {
  fulfillment: FulfillmentApiResponse
  onRiderNameClick: (id: number) => void
  onRiderStatusClick: (id: number) => void
}

const RidersInfo = ({ fulfillment, onRiderNameClick, onRiderStatusClick }: Props) => {
  const classes = useStyle()
  const { t } = useTranslation()
  const deliveries = fulfillment?.deliveries || []
  const getDeliveryStatusTagParams = useGetRiderStatusTextAndColor()

  return (
    <div className={classes.container}>
      {deliveries.map((delivery, idx) => {
        const events = delivery.courier_events || []
        const status = events[0]?.name || RiderStatuses.unknown
        const { color, text } = getDeliveryStatusTagParams(status)

        return (
          <div className={classes.rider} key={delivery.id}>
            <h4 className={classes.label}>
              {`${t('Order Widget.Tabs.Summary.Rider')} ${deliveries.length > 1 ? idx + 1 : ''}`}{' '}
              {delivery.primary_delivery && deliveries.length > 1
                ? `(${t('Order Widget.Tabs.Summary.Primary Delivery')})`
                : ''}
            </h4>
            <div className={classes.info}>
              <span className={classes.name} onClick={() => onRiderNameClick(delivery.id)}>
                {delivery.courier?.name || t('Messages.Unknown')}
              </span>
              <Tag color={color} onClick={() => onRiderStatusClick(delivery.id)}>
                <span>{text}</span>
                <RightCircleOutlined />
              </Tag>
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default RidersInfo
