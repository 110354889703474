export default {
  widgetContainer: {
    border: '1px solid #e8e8e8',
    borderRadius: '5px',
    padding: '10px',
    backgroundColor: '#ffffff',
    boxShadow: '0.5px 0.5px 5px rgba(0, 0, 0, 0.134124)',
  },

  widgetTitle: {
    fontSize: '17px',
    color: '#3d4a4f',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
}
