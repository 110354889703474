import { ApiService } from 'services/authApi/getPermissions'
import {
  VendorAvailabilityStatusUpdatePayload,
  VendorAvailabilityStatusUpdateResponse,
} from 'types/api/vendorApi/vendorAvailabilityStatus'

export const putVendorAvailabilityStatus: ApiService<
  { entityId: string; vendorId?: string; data: VendorAvailabilityStatusUpdatePayload },
  VendorAvailabilityStatusUpdateResponse
> = (createClient, options) => {
  const { entityId, clientParams, vendorId, data } = options

  const uri = `/VendorAPI/v1/vendors/${entityId}/${vendorId}/availability/status`

  return createClient({
    endpointName: 'putVendorAvailabilityStatus',
    expectedResponseStatusCode: 200,
    ...clientParams,
  }).put(uri, {
    ...data,
  })
}
