import React from 'react'
import { Row, Col, Skeleton, Space } from 'antd'
import useStyles from './CommentsSummary.styles'

export const CommentsSkeleton = () => {
  const classes = useStyles()

  return (
    <Row className={classes.container} gutter={[10, 0]} wrap={false}>
      <Col>
        <Skeleton.Avatar size={'small'} />
      </Col>
      <Col>
        <Space size={3}>
          <Skeleton.Input size={'small'} />
          <Skeleton.Input size={'small'} />
          <Skeleton.Input size={'small'} />
        </Space>
      </Col>
    </Row>
  )
}
