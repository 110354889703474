/**
 * details section for order widget payment tab
 * runs a data point check for refund to source field for the entity
 * */

// libs
import React, { useState, useEffect } from 'react'
// configs
import { allowedDataPointValues } from 'entityConfig/allowedConfigValues'
// contexts and types
import { OrderWidgetItems, OrderWidgetPayment } from 'contexts/entity/types'
// hooks
import { useTranslation } from 'hooks/useTranslation'
// utils
// styles
import { Statistic, Descriptions } from 'antd'
import { useIsDataPointValid } from 'hooks/useGetValidFeatures'

interface Props {
  paymentDetails: any
  items: OrderWidgetItems
  payment: OrderWidgetPayment
}

function checkDataPointDisplayRule(displayRule: string[], label: string, paymentDetails): boolean {
  displayRule.forEach((config: string) => {
    if (config === allowedDataPointValues.never_display) {
      return false
    } else if (config === allowedDataPointValues.display_when_data_found) {
      paymentDetails.forEach((detail: any) => {
        if (detail.label === label && !detail.value) {
          return false
        }
      })
    }
  })
  return true
}

const PaymentDetails: React.FC<Props> = ({ paymentDetails, items, payment }) => {
  // pull translations
  const { t } = useTranslation()

  const isDataPointValid = useIsDataPointValid()

  // find out if refund to wallet field is available for the entity
  const [refundToWalletAvailability, setRefundToWalletAvailability] = useState(true)
  const [paymentStatusAvailability, setPaymentStatusAvailability] = useState(true)
  useEffect(() => {
    if (payment) {
      const isRefundToWallet = isDataPointValid(payment?.total_refund_to_wallet?.betaRequirement)
      const isPaymentStatus = isDataPointValid(items?.payment_status?.betaRequirement)

      if (isRefundToWallet) {
        const shouldRefundToWalletDisplayed = checkDataPointDisplayRule(
          payment.total_refund_to_wallet.displayRule,
          t('Order Widget.Tabs.Payment.Refunded to Wallet'),
          paymentDetails,
        )
        setRefundToWalletAvailability(shouldRefundToWalletDisplayed)
      } else {
        setRefundToWalletAvailability(false)
      }

      if (isPaymentStatus) {
        const shouldPaymentStatusDisplayed = checkDataPointDisplayRule(
          items.payment_status.displayRule,
          t('Order Widget.Tabs.Payment.Payment Status'),
          paymentDetails,
        )
        setPaymentStatusAvailability(shouldPaymentStatusDisplayed)
      } else {
        setPaymentStatusAvailability(false)
      }
    }
  }, [payment, items, paymentDetails, t, isDataPointValid])

  return (
    <Descriptions bordered size='small' column={2}>
      {/* map over and render each payment details as description item */}
      {paymentDetails.map((detail: any, idx: number) => {
        //   total field is checked, since it's wrapped by statistics
        if (detail.label === t('Order Widget.Tabs.Payment.Total Paid')) {
          return (
            <Descriptions.Item key={idx} label={detail.label}>
              <Statistic value={detail.value} valueStyle={{ color: '#3f8600' }} precision={2} />
            </Descriptions.Item>
          )
        } else if (detail.label === t('Order Widget.Tabs.Payment.Refunded to Wallet')) {
          if (refundToWalletAvailability) {
            return (
              <Descriptions.Item key={idx} label={detail.label}>
                {detail.value || '–'}
              </Descriptions.Item>
            )
          }
        } else if (detail.label === t('Order Widget.Tabs.Payment.Payment Status')) {
          if (paymentStatusAvailability) {
            return (
              <Descriptions.Item key={idx} label={detail.label}>
                {detail.value || '–'}
              </Descriptions.Item>
            )
          }
        } else {
          return (
            <Descriptions.Item key={idx} label={detail.label}>
              {detail.value || '–'}
            </Descriptions.Item>
          )
        }
        return null
      })}
    </Descriptions>
  )
}

export default PaymentDetails
