import { OrderWidgetItems } from 'contexts/entity/types'
import React from 'react'
import { ItemsItem } from 'types/api/orderApi/order'
import TableHeader from './TableHeader'
import TableRow from './TableRow'

type ItemsTableProps = {
  itemsConfig: OrderWidgetItems
  currency: string
  digitsAfterComa: number
  items: ItemsItem[]
  isToppingtotalPriceAvailable: boolean
  locale: string
}

const ItemsTable = ({
  items,
  isToppingtotalPriceAvailable,
  locale,
  itemsConfig,
  ...props
}: ItemsTableProps) => {
  return (
    <>
      <TableHeader isToppingtotalPriceAvailable={isToppingtotalPriceAvailable} />
      {items.map((orderItem) => {
        return (
          <TableRow
            key={orderItem.id}
            item={orderItem}
            isToppingtotalPriceAvailable={isToppingtotalPriceAvailable}
            locale={locale}
            itemsConfig={itemsConfig}
            {...props}
          />
        )
      })}
    </>
  )
}

export default ItemsTable
