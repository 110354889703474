import { gray, green } from 'theme'

export default {
  greenCheckCircledIconContainer: {
    backgroundColor: green.green2,
    width: '48px',
    height: '48px',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%',
  },
  greenCheckCircledIcon: {
    color: green.green6,
    backgroundColor: 'transparent',
    fontSize: '24px',
  },
  checkIcon: {
    color: gray.gray7,
    backgroundColor: gray.gray3,
    borderRadius: '50%',
    padding: '8px',
    fontSize: '10px',
  },
  dividerMargin: {
    margin: '2px',
  },
  infoIcon: {
    fontSize: '12px',
    padding: '4px',
  },
}
