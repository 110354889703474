export default {
  container: {
    width: '500px',
    height: '350px',
  },

  smallContainer: {
    maxWidth: '480px',
    height: '320px',
    overflow: 'hidden',
  },

  tooltipDivision: {
    display: 'flex',
    flexDirection: 'column',
    padding: '3px',
  },

  tooltipTitle: {
    color: '#f6f6f6',
    fontSize: '12px',
    marginBottom: '5px',
  },
  tooltipValue: {
    color: '#fff',
    fontSize: '13px',
  },

  /**
   * mini widget map styles
   */
  miniWidgetContainer: {
    width: '279px',
    height: '359px',
  },

  widgetContainer: {
    width: '100%',
    height: '320px',
  },

  lastUpdatedText: {
    fontSize: '13px',
    color: 'rgba(0, 0, 0, 0.45)',
    marginBottom: '10px',
    display: 'block',
    textAlign: 'right',
    margin: '5px 0',
  },
  notFoundMsg: {
    fontSize: '12px',
  },
  refresh: {
    color: '#fff',
    paddingRight: '4px',
  },
}
