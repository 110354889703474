export default {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100vh',
  },
  welcomeText: {
    fontSize: '16px',
    fontWeight: 800,
    marginBottom: '15px',
  },
  button: {
    marginTop: '16px',
    textTransform: 'uppercase',
    fontWeight: 600,
  },
}
