// libs
import React, { useContext } from 'react'
// contexts and types
import { EntityContext } from 'contexts/entity/EntityContext'
import { RiderServiceContext } from 'contexts/riderService/RiderServiceContext'
// hooks
import { useTranslation } from 'hooks/useTranslation'
import useDataPointAvailabilityCheck from 'hooks/useDataPointAvailabilityCheck'
// styles
import { createUseStyles } from 'react-jss'
import styles from './Vendor.styles'
import { Typography, Tag } from 'antd'
// assets
import orderErrorIcon from 'assets/error/orderError.png'
// components
import MiniErrorView from 'components/MiniErrorView'
import Content from './Content'

const useStyles = createUseStyles(styles)

const Vendor = () => {
  const classes = useStyles()
  const { Text } = Typography

  // pull translations
  const { t } = useTranslation()

  // pull entity context
  const { entityState } = useContext(EntityContext)

  // pull data state and dispatch from data context
  const {
    riderServiceState: { vendor, vendorError },
  } = useContext(RiderServiceContext)

  const isActiveAllowed = useDataPointAvailabilityCheck(
    entityState.entityConfig.miniVendor.is_active,
    vendor?.isVendorActive.toString() ? true : false,
    [entityState, vendor],
  )

  return (
    <div className={classes.vendorContainer}>
      {vendor && !vendorError && (
        <React.Fragment>
          <div className={classes.titleHolder}>
            <Text className={classes.sectionTitle}>{t('Vendor Widget.Vendor')}</Text>
            {vendor?.isVendorActive.toString() && isActiveAllowed ? (
              <Tag color='blue'>{vendor.isVendorActive ? 'Active' : 'Inactive'}</Tag>
            ) : null}
          </div>
          <Content />
        </React.Fragment>
      )}

      {!vendor && vendorError && (
        <MiniErrorView icon={orderErrorIcon} errorMessage={t('Messages.No Vendor Found')} />
      )}
    </div>
  )
}

export default Vendor
