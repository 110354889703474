import React from 'react'
import { createCustomSvgIcon } from 'factory/createCustomSvgIcon'

export const UserOneOutlined = createCustomSvgIcon({
  viewBoxWidth: 16,
  viewBoxHeight: 16,
  content: (
    <>
      <path
        d='M11.5556 5.79341C11.5556 3.57509 9.73428 1.77777 7.48638 1.77777C5.23849 1.77777 3.4172 3.57509 3.4172 5.79341C3.4172 7.13411 4.08336 8.32261 5.10722 9.05125C5.10066 9.05449 5.09574 9.05611 5.09246 9.05773C4.35902 9.36376 3.70106 9.80256 3.13498 10.3628C2.57218 10.9172 2.12411 11.5744 1.81578 12.2978C1.51242 13.0061 1.3487 13.765 1.33338 14.5339C1.33295 14.5512 1.33602 14.5684 1.34242 14.5845C1.34882 14.6005 1.35842 14.6152 1.37065 14.6276C1.38288 14.64 1.3975 14.6498 1.41365 14.6565C1.42979 14.6632 1.44713 14.6667 1.46465 14.6667H2.44749C2.51804 14.6667 2.57711 14.61 2.57875 14.5404C2.61157 13.2903 3.11857 12.1196 4.01609 11.2323C4.94314 10.3142 6.17374 9.80904 7.48638 9.80904C9.73428 9.80904 11.5556 8.01172 11.5556 5.79341ZM7.48638 8.57844C5.92762 8.57844 4.66421 7.33165 4.66421 5.79341C4.66421 4.25516 5.92762 3.00837 7.48638 3.00837C9.04514 3.00837 10.3086 4.25516 10.3086 5.79341C10.3086 7.33165 9.04514 8.57844 7.48638 8.57844Z'
        fill='black'
        fillOpacity='0.85'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M13.15 8.95933C13.326 9.06294 13.436 9.26469 13.436 9.48425V13.4759H14.1867C14.3547 13.4759 14.4387 13.4759 14.5029 13.5086C14.5593 13.5374 14.6052 13.5833 14.634 13.6397C14.6667 13.7039 14.6667 13.7879 14.6667 13.9559V14.1867C14.6667 14.3547 14.6667 14.4387 14.634 14.5029C14.6052 14.5593 14.5593 14.6052 14.5029 14.634C14.4387 14.6667 14.3547 14.6667 14.1867 14.6667H11.6022C11.4342 14.6667 11.3502 14.6667 11.286 14.634C11.2296 14.6052 11.1837 14.5593 11.1549 14.5029C11.1222 14.4387 11.1222 14.3547 11.1222 14.1867V13.9559C11.1222 13.7879 11.1222 13.7039 11.1549 13.6397C11.1837 13.5833 11.2296 13.5374 11.286 13.5086C11.3502 13.4759 11.4342 13.4759 11.6022 13.4759H12.3529V10.5967L11.69 11.0826C11.5434 11.1901 11.4701 11.2438 11.3945 11.2541C11.3282 11.2631 11.2608 11.2497 11.203 11.2159C11.1371 11.1775 11.09 11.0998 10.9958 10.9444L10.8947 10.7777C10.8142 10.645 10.774 10.5786 10.7672 10.5111C10.7612 10.4516 10.7732 10.3917 10.8015 10.339C10.8336 10.2793 10.8962 10.2334 11.0213 10.1416L12.5941 8.98888C12.7602 8.86708 12.9739 8.85573 13.15 8.95933Z'
        fill='black'
      />
    </>
  ),
})
