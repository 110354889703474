/**
 * capitalizeFirstLetters: expects a sentence and returns same sentence with all words' 1st letter capitalized & the rest lower cased
 * */

function capitalizeFirstLetters(sentence: string) {
  return sentence
    .toLowerCase()
    .split(' ')
    .map((word: string) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ')
}

export default capitalizeFirstLetters
