import React from 'react'
import { Typography } from 'antd'
import { createUseStyles } from 'react-jss'

import { SingleComment } from 'types/api/commentApi/getOrderComments'
import { useTranslation } from 'hooks/useTranslation'
import { commentTagType, CommentView } from 'components/Comments/CommentView'
import { getAgentNameFromId } from 'utils/string'
import styles from './Comments.styles'
import { WidgetSize } from 'types/widgets/historyOfEvents/tabs'

const { Text } = Typography

const useStyles = createUseStyles(styles)

type Props = {
  comment: SingleComment
  shouldCommentBeHighlighted: boolean
  mode: WidgetSize
}

const actionVerb = {
  delete: 'Deleted',
  create: 'Created',
  update: 'Updated',
}

export const UpdateVendorSpecialScheduleComment = ({
  comment,
  shouldCommentBeHighlighted,
  mode,
}: Props) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const additionalData = comment.additional_data?.update_vendor_special_schedule

  const action = additionalData?.action

  const verb = actionVerb[action] ?? action

  return (
    <CommentView
      mode={mode}
      comment={comment}
      shouldCommentBeHighlighted={shouldCommentBeHighlighted}
      tag={{
        type: commentTagType.message,
        text: t('History of Events Widget.Event Types.Update Vendor Special Schedule'),
      }}
    >
      <Text className={classes.commentText}>
        <Text>
          {getAgentNameFromId(comment.agent_id)} {t(`History of Events Widget.Content.${verb}`)}{' '}
        </Text>
        <Text>
          {t('History of Events Widget.Content.Special schedule for vendor')} {comment.vendor_id}{' '}
        </Text>

        {additionalData?.special_schedule_name ? (
          <Text>
            {t('History of Events Widget.Content.For special schedule name')}:{' '}
            {additionalData?.special_schedule_name}{' '}
          </Text>
        ) : null}

        <Text>
          {t('History of Events Widget.Content.And schedule ID')}:{' '}
          {additionalData?.special_schedule_id}{' '}
        </Text>

        {additionalData?.reason ? (
          <Text>
            {t('History of Events Widget.Content.With a reason')}: {additionalData?.reason}
          </Text>
        ) : null}
      </Text>
    </CommentView>
  )
}
