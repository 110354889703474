import React from 'react'
import { createUseStyles } from 'react-jss'

import { OrderApiResponse } from 'types/api/orderApi/order'
import { useTranslation } from 'hooks/useTranslation'
import { orderSummaryDataPoints } from 'entityConfig/dataPoints/order'
import { unknownDataPoint } from 'utils/dataTransformers/constants'
import listDataFieldReturnElement from 'utils/listDataFieldReturnElement'
import { DataPointWithBetaRequirementConfig } from 'contexts/entity/types'

import styles from './Wight.styles'

const useStyles = createUseStyles(styles)

export type WeightProps = {
  order?: OrderApiResponse
  config: DataPointWithBetaRequirementConfig
}

export function Weight({ order, config }: WeightProps) {
  const { t } = useTranslation()
  const classes = useStyles()

  return (
    <>
      {order && config?.displayRule ? (
        <div className={classes.listItem}>
          {listDataFieldReturnElement(config?.displayRule, {
            key: orderSummaryDataPoints.leftColumn.weight,
            label: t('Order Widget.Tabs.Summary.Weight'),
            value: order.order_weight
              ? `${order.order_weight.value} ${order.order_weight.unit}`
              : unknownDataPoint,
          })}
        </div>
      ) : null}
    </>
  )
}
