import { CustomerApiResponse } from 'types/api/orderApi/order'
import { unknownDataPoint } from './dataTransformers/constants'

export const getCustomerName = (customer: CustomerApiResponse, defaultValue = unknownDataPoint) => {
  // check if customer profile exists
  let profile: any = {}
  if (customer && customer.profile) {
    profile = customer.profile
  }

  let name = defaultValue
  if (profile?.name) {
    name = profile.name
  } else if (profile?.first_name) {
    name = [profile.first_name, profile.last_name].filter(Boolean).join(' ')
  }

  return name.trim()
}
