/**
 * HistoryOfEvents Customer Tab
 * pulls from API and displays order-related events with filtering options
 * */

// libs
import React, { useState, useEffect, useContext } from 'react'
// contexts and types
import { SessionContext } from 'contexts/session/SessionContext'
import { DataContext } from 'contexts/data/DataContext'
import {
  SingleComment,
  SingleCommentAction,
  ModifyCallBackStatuses,
  NewCookingInstruction,
} from 'types/api/commentApi/getOrderComments'
import { CancellationReason } from 'types/widgets/historyOfEvents/cancelActionComment'
import { CancelOrderActionConfig } from 'contexts/entity/types'
// hooks
import { useTranslation } from 'hooks/useTranslation'
import { useEntityUtcZone } from 'hooks/useEntityUtcZone'
//utils
import modifyUTC from 'utils/modifyUTC'
// styles
import { createUseStyles } from 'react-jss'
import styles from './CustomerHistory.styles'
import { Typography, Empty } from 'antd'
// components
import { PaymentRefundComment, TicketRefundComment } from '../../../components/Comments'
// utils
import { useApiService } from 'hooks/useApiService'
import { getCustomerComments } from 'services/commentApi/getCustomerComments'
import { WidgetErrorHandler } from 'components/WidgetErrorHandler/widgetErrorHandler'
import { useCaptureUserAction } from 'hooks/events/useCaptureUserAction'

import { commentTagType, CommentView } from 'components/Comments/CommentView'
import { AvailableFilterTimeFrames } from 'types/widgets/historyOfEvents/availableCommentActionsTypes'
import { useFilteredComments } from 'hooks/useFilteredComments'
import { WidgetSize } from 'types/widgets/historyOfEvents/tabs'
import { useGetApiVersionConfig } from 'hooks/useApiVersionConfig'
import { SelfServiceComment } from 'components/Comments/SelfServiceComment'

const useStyles = createUseStyles(styles)

interface Props {
  intervalState: number
  cancelOrder: CancelOrderActionConfig
  timeFilter: AvailableFilterTimeFrames
  mode: WidgetSize
}

const CustomerHistory: React.FC<Props> = (props) => {
  const { intervalState, cancelOrder: cancel_order, timeFilter, mode } = props

  const classes = useStyles()
  const { Text } = Typography

  const getApiVersionConfig = useGetApiVersionConfig()

  const apiVersion = getApiVersionConfig('getCustomerComments')

  // pull language content
  const { t } = useTranslation()
  const captureUserAction = useCaptureUserAction()

  const utc_zone = useEntityUtcZone()

  // pull orderId & globalentity ID from session context
  const {
    sessionState: { orderId, globalEntityId, userId },
  } = useContext(SessionContext)

  // pull dataState
  const { dataState } = useContext(DataContext)

  const {
    loading: isLoading,
    error,
    loadService: loadCustomerComments,
    data,
  } = useApiService({
    service: getCustomerComments,
    params: {
      entityId: globalEntityId,
      customerId: userId,
      apiVersion,
    },
    deps: [
      intervalState,
      dataState.newComment,
      dataState.autoChangeDeliveryTimeComment,
      dataState.autoMissingItemReportComment,
      dataState.autoCancelOrderComment,
      dataState.autoCompensationComment,
      dataState.autoPartialRefundComment,
      dataState.autoFullRefundComment,
      dataState.autoChangeAddressComment,
      dataState.autoChangeInstructionsComment,
      dataState.autoModifyCallbackComment,
      dataState.autoChangeCookingInstComment,
    ],
  })
  // unfiltered data result count
  const [totalUnfilteredItems, setTotalUnfilteredItems] = useState(0)

  useEffect(() => {
    setTotalUnfilteredItems(data?.comments?.length)
  }, [data])

  const handleCommentOrderIdClicked = (commentId: string) => {
    captureUserAction('HistoryOfEventsCustomerOrderIdClicked', {
      eventDetails: {
        comment_id: commentId,
      },
    })
  }

  // make sure only one comment highlighted at a time
  let highlightedCommentCount = 0

  const commentsDisplayed = useFilteredComments(data?.comments, timeFilter)

  return (
    <div className={classes.orderHistoryContainer}>
      <WidgetErrorHandler
        loading={isLoading}
        errorPayload={error?.errorPayload}
        loadingText={t('Interface.Loading Customer Events')}
        onRetry={loadCustomerComments}
      >
        {() => {
          if (totalUnfilteredItems === 0) {
            return (
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={t(
                  'History of Events Widget.There is no comment associated with the current customer',
                )}
              />
            )
          }
          return (
            <React.Fragment>
              {/* COMMENTS */}
              <div className={classes.commentsHolder}>
                {commentsDisplayed && commentsDisplayed.length > 0 ? (
                  commentsDisplayed.map((comment: SingleComment, idx: number) => {
                    let orderIdOfComment = ''
                    let shouldCommentBeHighlighted = false

                    if (comment.order_id) {
                      orderIdOfComment = comment.order_id
                    }

                    if (
                      dataState?.newComment?.comment &&
                      dataState.newComment.comment.id === comment.id
                    ) {
                      shouldCommentBeHighlighted = true
                    }

                    switch (comment.action) {
                      case SingleCommentAction.ticket_refund:
                        if (
                          dataState?.autoChangeInstructionsComment &&
                          comment?.order_id &&
                          dataState.autoChangeInstructionsComment === comment.order_id
                        ) {
                          shouldCommentBeHighlighted = true
                        }

                        return (
                          <TicketRefundComment
                            mode={mode}
                            key={idx}
                            utc_zone={utc_zone}
                            comment={comment}
                            shouldCommentBeHighlighted={shouldCommentBeHighlighted}
                            onOrderIdClick={handleCommentOrderIdClicked}
                            withOrderId
                          />
                        )
                      case SingleCommentAction.modify_promised_delivery_time:
                        if (
                          dataState?.autoChangeInstructionsComment &&
                          comment?.order_id &&
                          dataState.autoChangeInstructionsComment === comment.order_id
                        ) {
                          shouldCommentBeHighlighted = true
                        }

                        return (
                          <CommentView
                            mode={mode}
                            key={idx}
                            comment={comment}
                            shouldCommentBeHighlighted={shouldCommentBeHighlighted}
                            tag={{
                              type: commentTagType.thunderbolt,
                              text: t('History of Events Widget.Event Types.Change Delivery Time'),
                            }}
                          >
                            <Text className={classes.commentText}>
                              <Text>
                                {` ${t(
                                  'History of Events Widget.Content.Changed delivery time to',
                                )}`}
                              </Text>
                              <Text strong>
                                {`${modifyUTC(
                                  comment.additional_data.modify_promised_delivery_time.new,
                                  utc_zone,
                                )}. `}
                              </Text>
                              <Text>{`${t(
                                'History of Events Widget.Content.Initial delivery time',
                              )}: `}</Text>
                              <Text strong>
                                {`${modifyUTC(
                                  comment.additional_data.modify_promised_delivery_time.old,
                                  utc_zone,
                                )}.`}
                              </Text>
                            </Text>
                          </CommentView>
                        )
                      case SingleCommentAction.cancel_order:
                        if (
                          dataState?.autoCancelOrderComment &&
                          comment?.order_id &&
                          dataState.autoCancelOrderComment === comment.order_id
                        ) {
                          shouldCommentBeHighlighted = true
                        }

                        // set initial cancellation reason to what API returns
                        let cancellationReasonToDisplay =
                          comment.additional_data.cancel_order.reason

                        // check if entity wants to display the reason returned bu API
                        // useReasonToDisplayCancelComment being truthy means entity wants display reason to be pulled from config
                        if (cancel_order?.useReasonToDisplayCancelComment) {
                          // find matching id and update cancellationReasonToDisplay
                          const reasonFound = cancel_order.availableCancellationReasons.find(
                            (reason: CancellationReason) =>
                              reason.reason === comment.additional_data.cancel_order.reason,
                          )
                          if (reasonFound) {
                            cancellationReasonToDisplay = reasonFound.translation_key
                          }
                        }

                        return (
                          <CommentView
                            mode={mode}
                            key={idx}
                            comment={comment}
                            shouldCommentBeHighlighted={shouldCommentBeHighlighted}
                            tag={{
                              type: commentTagType.thunderbolt,
                              text: t('History of Events Widget.Event Types.Cancel Order'),
                            }}
                          >
                            <Text className={classes.commentText}>
                              <Text>
                                {` ${t(
                                  'History of Events Widget.Content.Cancelled order due to',
                                )} ${cancellationReasonToDisplay
                                  .replace(/_/g, ' ')
                                  .toLowerCase()}.`}
                              </Text>
                            </Text>
                            <br />
                          </CommentView>
                        )

                      case SingleCommentAction.compensation:
                        if (
                          (dataState?.autoCompensationComment &&
                            comment?.additional_data?.compensation?.voucher_code &&
                            dataState.autoCompensationComment.code ===
                              comment.additional_data.compensation.voucher_code) ||
                          (!highlightedCommentCount && dataState?.autoCompensationComment) ||
                          dataState?.compensatedVoucher?.code ===
                            comment?.additional_data?.compensation?.voucher_code
                        ) {
                          highlightedCommentCount = highlightedCommentCount + 1
                          shouldCommentBeHighlighted = true
                        }

                        return (
                          <CommentView
                            mode={mode}
                            key={idx}
                            comment={comment}
                            shouldCommentBeHighlighted={shouldCommentBeHighlighted}
                            tag={{
                              type: commentTagType.thunderbolt,
                              text: t('History of Events Widget.Event Types.Compensation'),
                            }}
                          >
                            <Text className={classes.commentText}>
                              {comment?.agent_id && (
                                <Text>
                                  {` ${t('History of Events Widget.Content.Created voucher')} `}
                                </Text>
                              )}

                              {comment?.additional_data?.compensation?.voucher_currency &&
                                comment?.additional_data?.compensation?.voucher_value?.toString() && (
                                  <Text strong>
                                    {`${comment.additional_data.compensation.voucher_currency} ${comment.additional_data.compensation.voucher_value}`}
                                  </Text>
                                )}

                              {comment?.additional_data?.compensation?.compensation_purpose && (
                                <React.Fragment>
                                  <Text>{` ${t(
                                    'History of Events Widget.Content.For reason',
                                  )} `}</Text>
                                  <Text strong>
                                    {`${comment.additional_data.compensation.compensation_purpose?.replace(
                                      /_/g,
                                      ' ',
                                    )}.`}
                                  </Text>
                                </React.Fragment>
                              )}

                              {comment?.additional_data?.compensation?.voucher_code && (
                                <React.Fragment>
                                  <Text>{` ${t(
                                    'History of Events Widget.Content.Voucher Code',
                                  )}: `}</Text>
                                  <Text strong>
                                    {`${comment.additional_data.compensation.voucher_code}.`}
                                  </Text>
                                </React.Fragment>
                              )}
                            </Text>
                          </CommentView>
                        )

                      case SingleCommentAction.modify_cooking_instructions:
                        if (
                          !highlightedCommentCount &&
                          dataState?.autoChangeCookingInstComment &&
                          comment?.order_id &&
                          dataState.autoChangeCookingInstComment === comment.order_id
                        ) {
                          highlightedCommentCount = highlightedCommentCount + 1
                          shouldCommentBeHighlighted = true
                        }

                        return (
                          <CommentView
                            mode={mode}
                            key={idx}
                            comment={comment}
                            shouldCommentBeHighlighted={shouldCommentBeHighlighted}
                            tag={{
                              type: commentTagType.thunderbolt,
                              text: t(
                                'History of Events Widget.Event Types.Change Cooking Instructions',
                              ),
                            }}
                          >
                            <Text className={classes.commentText}>
                              <Text>
                                {` ${`${t(
                                  'History of Events Widget.Content.Changed cooking instructions to',
                                )} `}`}
                              </Text>
                              <Text strong>
                                {comment.additional_data.modify_cooking_instructions.new.reduce(
                                  (acc: string, i: NewCookingInstruction, idx: number) => {
                                    if (idx === 0) return i.comment
                                    acc = `${acc}, ${i.comment}`
                                    return acc
                                  },
                                  '',
                                )}
                              </Text>
                            </Text>
                          </CommentView>
                        )

                      case SingleCommentAction.manual_comment:
                        return (
                          <CommentView
                            mode={mode}
                            key={idx}
                            comment={comment}
                            shouldCommentBeHighlighted={shouldCommentBeHighlighted}
                            tag={{
                              type: commentTagType.message,
                              text: t('History of Events Widget.Event Types.Comment'),
                            }}
                          >
                            <Text className={classes.commentText}>
                              <Text>{comment.additional_data.manual_comment.text}</Text>
                            </Text>
                            <br />
                          </CommentView>
                        )

                      case SingleCommentAction.modify_callback:
                        if (
                          comment?.additional_data?.modify_callback?.status ===
                            ModifyCallBackStatuses.CONFIRMED ||
                          comment?.additional_data?.modify_callback?.status ===
                            ModifyCallBackStatuses.MODIFIED ||
                          comment?.additional_data?.modify_callback?.status ===
                            ModifyCallBackStatuses.REQUESTED
                        ) {
                          return null
                        } else {
                          if (
                            dataState?.autoModifyCallbackComment &&
                            orderId &&
                            dataState.autoModifyCallbackComment === orderId
                          ) {
                            shouldCommentBeHighlighted = true
                          }

                          return (
                            <CommentView
                              mode={mode}
                              key={idx}
                              comment={comment}
                              shouldCommentBeHighlighted={shouldCommentBeHighlighted}
                              tag={{
                                type: commentTagType.thunderbolt,
                                text: t('History of Events Widget.Event Types.System Error'),
                              }}
                            >
                              <Text className={classes.commentText}>
                                <Text>
                                  {` ${t(
                                    'History of Events Widget.Content.Changed delivery address failed due to',
                                  )} `}
                                </Text>
                                <Text strong>{`${t(
                                  'History of Events Widget.Content.System Error',
                                )}.`}</Text>
                              </Text>
                              <br />
                              {orderIdOfComment ? (
                                <div
                                  className={classes.orderIdText}
                                  onClick={() => handleCommentOrderIdClicked(orderIdOfComment)}
                                >
                                  {`${t('Order Widget.Order ID')}: ${orderIdOfComment}`}
                                </div>
                              ) : null}
                            </CommentView>
                          )
                        }

                      case SingleCommentAction.modify_delivery_address:
                        if (
                          dataState?.autoChangeAddressComment &&
                          orderId &&
                          dataState.autoChangeAddressComment === orderId
                        ) {
                          shouldCommentBeHighlighted = true
                        }

                        return (
                          <CommentView
                            mode={mode}
                            key={idx}
                            comment={comment}
                            shouldCommentBeHighlighted={shouldCommentBeHighlighted}
                            tag={{
                              type: commentTagType.thunderbolt,
                              text: t('History of Events Widget.Event Types.Change Address'),
                            }}
                          >
                            <Text className={classes.commentText}>
                              <Text>
                                {` ${t(
                                  'History of Events Widget.Content.Changed delivery address to',
                                )} `}
                              </Text>
                              <Text strong>
                                {` ${comment.additional_data.modify_delivery_address.new.formatted_address}. `}
                              </Text>
                              <Text>{`${t(
                                'History of Events Widget.Content.Initial delivery address',
                              )}: `}</Text>
                              <Text strong>
                                {`${comment.additional_data.modify_delivery_address.old.formatted_address}.`}
                              </Text>
                            </Text>
                            <br />
                          </CommentView>
                        )

                      case SingleCommentAction.modify_delivery_instructions:
                        if (
                          dataState?.autoChangeInstructionsComment &&
                          orderId &&
                          dataState.autoChangeInstructionsComment === orderId
                        ) {
                          shouldCommentBeHighlighted = true
                        }

                        return (
                          <CommentView
                            mode={mode}
                            key={idx}
                            comment={comment}
                            shouldCommentBeHighlighted={shouldCommentBeHighlighted}
                            tag={{
                              type: commentTagType.thunderbolt,
                              text: t('History of Events Widget.Event Types.Change Instructions'),
                            }}
                          >
                            <Text className={classes.commentText}>
                              <Text>
                                {` ${t(
                                  'History of Events Widget.Content.Changed delivery instructions to',
                                )} `}
                              </Text>
                              <Text strong>
                                {`${comment.additional_data.modify_delivery_instructions.new}.`}
                              </Text>
                              <Text>{`${t(
                                'History of Events Widget.Content.Initial delivery instructions',
                              )}: `}</Text>
                              <Text strong>
                                {`${comment.additional_data.modify_delivery_instructions.old}.`}
                              </Text>
                            </Text>
                            <br />
                          </CommentView>
                        )

                      case SingleCommentAction.report_missing_items:
                        if (
                          dataState?.autoMissingItemReportComment &&
                          comment?.additional_data?.report_missing_items?.sf_case_id &&
                          dataState.autoMissingItemReportComment ===
                            comment.additional_data.report_missing_items.sf_case_id
                        ) {
                          shouldCommentBeHighlighted = true
                        }

                        return (
                          <CommentView
                            mode={mode}
                            key={idx}
                            comment={comment}
                            shouldCommentBeHighlighted={shouldCommentBeHighlighted}
                            tag={{
                              type: commentTagType.thunderbolt,
                              text: t('History of Events Widget.Event Types.Report Missing Items'),
                            }}
                          >
                            <Text className={classes.commentText}>
                              <Text>{` ${t('History of Events Widget.Content.Reported')} `}</Text>
                              <Text strong>
                                {/* @TODO: clear this mess */}
                                {`${
                                  comment?.additional_data?.report_missing_items?.missing_items?.join(
                                    ', ',
                                  ) || 'some items'
                                }. `}
                              </Text>
                              <Text>{`${t(
                                'History of Events Widget.Content.Wastage Case ID',
                              )}: `}</Text>
                              <Text strong>
                                {`${comment.additional_data.report_missing_items.sf_case_id}.`}
                              </Text>
                            </Text>
                            <br />
                          </CommentView>
                        )

                      case SingleCommentAction.voucher_refund:
                        if (
                          (dataState?.autoPartialRefundComment?.code &&
                            comment?.additional_data?.voucher_refund?.voucher_code &&
                            dataState.autoPartialRefundComment.code ===
                              comment.additional_data.voucher_refund.voucher_code) ||
                          (dataState?.autoFullRefundComment?.code &&
                            comment?.additional_data?.voucher_refund?.voucher_code &&
                            dataState.autoFullRefundComment.code ===
                              comment.additional_data.voucher_refund.voucher_code) ||
                          dataState.fullyRefundedVoucher?.code ===
                            comment?.additional_data?.voucher_refund?.voucher_code
                        ) {
                          shouldCommentBeHighlighted = true
                        }

                        return (
                          <CommentView
                            mode={mode}
                            key={idx}
                            comment={comment}
                            shouldCommentBeHighlighted={shouldCommentBeHighlighted}
                            tag={{
                              type: commentTagType.thunderbolt,
                              text:
                                comment.additional_data.voucher_refund.refund_purpose ===
                                'item_refund'
                                  ? t('History of Events Widget.Event Types.Partial Refund')
                                  : t('History of Events Widget.Event Types.Full Refund'),
                            }}
                          >
                            <Text className={classes.commentText}>
                              <Text>
                                {` did ${
                                  comment.additional_data.voucher_refund.refund_purpose ===
                                  'item_refund'
                                    ? t('History of Events Widget.Content.Partial')
                                    : t('History of Events Widget.Content.Full')
                                } ${t('History of Events Widget.Content.Refund')} `}
                              </Text>
                              <Text strong>
                                {`${comment.additional_data.voucher_refund.voucher_currency} ${
                                  comment.additional_data.voucher_refund.voucher_value
                                } ${t('History of Events Widget.Content.With voucher')}. `}
                              </Text>
                              <Text>{`${t(
                                'History of Events Widget.Content.Voucher Code',
                              )}: `}</Text>
                              <Text strong>
                                {`${comment.additional_data.voucher_refund.voucher_code}`}
                              </Text>
                            </Text>
                            <br />
                          </CommentView>
                        )

                      case SingleCommentAction.payment_refund:
                        if (
                          highlightedCommentCount < 1 &&
                          dataState?.autoPartialRefundComment &&
                          dataState.autoPartialRefundComment.amount?.amount.toString() &&
                          dataState.autoPartialRefundComment.amount?.currency
                        ) {
                          shouldCommentBeHighlighted = true
                          highlightedCommentCount++
                        }

                        return (
                          <PaymentRefundComment
                            mode={mode}
                            key={idx}
                            comment={comment}
                            shouldCommentBeHighlighted={shouldCommentBeHighlighted}
                          />
                        )

                      case SingleCommentAction.payment_compensation:
                        if (!highlightedCommentCount && dataState?.refundToWalletOrPspDetails) {
                          highlightedCommentCount = highlightedCommentCount + 1
                          shouldCommentBeHighlighted = true
                        }

                        return (
                          <CommentView
                            mode={mode}
                            key={idx}
                            comment={comment}
                            shouldCommentBeHighlighted={shouldCommentBeHighlighted}
                            tag={{
                              type: commentTagType.thunderbolt,
                              text: t('History of Events Widget.Event Types.Payment Compensation'),
                            }}
                          >
                            <Text className={classes.commentText}>
                              <Text>
                                {` ${t(
                                  'History of Events Widget.Content.Issued payment compensation',
                                )} `}
                              </Text>

                              {comment?.additional_data?.payment_compensation?.compensation_amount.toString() &&
                              comment?.additional_data?.payment_compensation
                                ?.compensation_currency &&
                              comment?.additional_data?.payment_compensation
                                ?.compensation_target ? (
                                <React.Fragment>
                                  <Text strong>
                                    {`${comment.additional_data.payment_compensation.compensation_currency} ${comment.additional_data.payment_compensation.compensation_amount} `}
                                  </Text>
                                  <Text>{`${t('History of Events Widget.Content.To')} `}</Text>
                                  <Text strong>
                                    {`${comment.additional_data.payment_compensation.compensation_target.toLowerCase()}.`}
                                  </Text>
                                </React.Fragment>
                              ) : null}

                              {orderIdOfComment ? (
                                <div
                                  className={classes.orderIdText}
                                  onClick={() => handleCommentOrderIdClicked(orderIdOfComment)}
                                >
                                  {`${t('Order Widget.Order ID')}: ${orderIdOfComment}`}
                                </div>
                              ) : null}
                            </Text>
                          </CommentView>
                        )

                      case SingleCommentAction.dispatcher_note:
                        return (
                          <CommentView
                            mode={mode}
                            key={idx}
                            comment={comment}
                            shouldCommentBeHighlighted={shouldCommentBeHighlighted}
                            tag={{
                              type: commentTagType.thunderbolt,
                              text: t('History of Events Widget.Event Types.Dispatcher Note'),
                            }}
                          >
                            <Text className={classes.commentText}>
                              <Text>{comment.additional_data.dispatcher_note.text}</Text>
                            </Text>
                          </CommentView>
                        )

                      case SingleCommentAction.self_service_refund:
                      case SingleCommentAction.self_service_compensation:
                        return (
                          <SelfServiceComment
                            mode={mode}
                            key={comment.id || idx}
                            comment={comment}
                          />
                        )

                      default:
                        return null
                    }
                  })
                ) : (
                  <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description={t(
                      'History of Events Widget.No comment found in the selected timeframe',
                    )}
                  />
                )}
              </div>
            </React.Fragment>
          )
        }}
      </WidgetErrorHandler>
    </div>
  )
}

export default CustomerHistory
