import React, { useMemo, useState } from 'react'
import { Col, Row } from 'antd'
import { FulfillmentApiResponse } from 'types/api/fulfillmentApi/fulfillment'
import { OrderApiResponse } from 'types/api/orderApi/order'
import { useOrderHistoryTimeline } from 'hooks/dataTransformers/useOrderHistoryTimeline'
import { Button } from 'shared/Button'
import { useTranslation } from 'hooks/useTranslation'
import { OrderStatuses } from 'types/widgets/order/orderStatuses'
import { HistoryTimeline } from '../HistoryTimeline/HistoryTimeline'
import { pickPrimaryDelivery } from 'utils/pickPrimaryDelivery'
import { HistoryMap } from '../HistoryMap/HistoryMap'
import { useHistoryStyles } from '../History.styles'
import { WidgetActionHandlers } from 'shared/WidgetActionHandlers'
import { Notifications } from '../Notifications/Notifications'
import { BuiltinWidgetConfigs, SDK } from 'types'
import { PlaceholderDeliveryMap } from '../HistoryMap/PlaceholderDeliveryMap'

type Props = {
  sdk: SDK
  order: OrderApiResponse
  isMapVisible: boolean
  onViewMap: () => void
  config: BuiltinWidgetConfigs['order_deliveries']['order_history_tab']
  fulfillment: FulfillmentApiResponse
}

const OrderHistory = ({ sdk, order, config, fulfillment, isMapVisible, onViewMap }: Props) => {
  const { t } = useTranslation()
  const classes = useHistoryStyles()
  const [showAll, setShowAll] = useState(false)
  const timeline = useOrderHistoryTimeline({ sdk, order, fulfillment })
  const primaryDelivery = pickPrimaryDelivery(fulfillment?.deliveries ?? [])

  const toggleShowAll = () => {
    setShowAll((value) => !value)
  }

  const customerNotifications = (config.data_points || []).find(
    (dp) => dp.name === 'customer_notifications',
  )

  const notificationsRules = sdk.checkDisplayRules({
    displayRules: customerNotifications?.display_rules,
  })

  const canDisplayMap = useMemo(() => {
    return (
      fulfillment &&
      order?.status_history?.some((item) => item.status === OrderStatuses.courierAcceptedDelivery)
    )
  }, [order, fulfillment])

  return (
    <div className={classes.container}>
      {!!config.action_handlers?.length && (
        <WidgetActionHandlers marginTop={0} actionHandlers={config.action_handlers} />
      )}
      {notificationsRules.enabled && (
        <Notifications notifications={fulfillment?.customer_notifications} />
      )}
      <Row justify='center' className={classes.timeline}>
        <Col span={14}>
          <HistoryTimeline timeline={timeline} reverse showAll={showAll} />
          <Row justify='center'>
            <Col span={14}>
              {timeline.length > 2 && (
                <Button type='link' onClick={toggleShowAll}>
                  {!showAll
                    ? t('action_labels.show_all_statuses')
                    : t('action_labels.hide_statuses')}
                </Button>
              )}
            </Col>
          </Row>
        </Col>
        <Col span={10}>
          {isMapVisible ? (
            <HistoryMap order={order} fulfillment={fulfillment} delivery={primaryDelivery} />
          ) : (
            <PlaceholderDeliveryMap canViewMap={canDisplayMap} onViewMap={onViewMap} />
          )}
        </Col>
      </Row>
    </div>
  )
}

export default OrderHistory
