/**
 * Rider widget
 * runs config checks and renders available tabs
 * */

// libs
import React, { useState, useEffect, useContext, useMemo } from 'react'
// configs
import {
  allowedDataPointValues,
  allowedScrollablePanelWidgets,
  allowedVendorRiderTabs,
} from 'entityConfig/allowedConfigValues'
// contexts and types
import { DataContext } from 'contexts/data/DataContext'
import { SessionContext } from 'contexts/session/SessionContext'
import RiderTabs from 'types/widgets/rider/tabs'
import { EMPTY_ARRAY } from 'constants/constants'
import { DeliveryItem } from 'types/api/fulfillmentApi/fulfillment'
import { RiderV2Config, RiderWidgetSummary } from 'contexts/entity/types'
import { LinesOfBusiness } from 'types/session/linesOfBusiness'
// hooks
import { useTranslation } from 'hooks/useTranslation'
import { useEntityConfig } from 'hooks/useEntityConfig'
import { useGetValidFeatures, useIsDataPointValid } from 'hooks/useGetValidFeatures'
import { usePluginUtils } from 'hooks/usePluginUtils'
// utils
import transformRider from 'utils/dataTransformers/transformRider'
// styles
import { createUseStyles } from 'react-jss'
import styles from './Rider.styles'
import { Result, Tabs, Tag } from 'antd'
// components
import CSWidgetHeading from 'components/CSWidgetHeading'
import Summary from './Summary'
import WidgetId from 'components/WidgetId'
import RiderV2Summary from './RiderV2Summary'

import { UserDeleteOutlined } from '@ant-design/icons'
import { NewDesign } from 'components/NewDesign/NewDesign'
import { PluginContainer } from 'components/PluginContainer'
import { WidgetTitle } from 'components/Widget'
import { useUiV1TopLevelTabStyles } from 'hooks/styles/useUiV1TopLevelTabStyles'

const useStyles = createUseStyles(styles)

const Rider = ({ deliveryId }: { deliveryId: number }) => {
  const classes = useStyles()
  const { TabPane } = Tabs

  // pull language content
  const { t } = useTranslation()

  // get lob from session context
  const {
    sessionState: { lineOfBusiness },
  } = useContext(SessionContext)

  // pull data state from data context
  const {
    dataState: { rider, fulfillment },
  } = useContext(DataContext)

  const { entityConfig, getWidgetConfig } = useEntityConfig()
  const isDataPointValid = useIsDataPointValid()
  const getValidFeatures = useGetValidFeatures()
  const showSplitDeliveries = isDataPointValid(entityConfig?.show_split_deliveries?.betaRequirement)
  const { isTabAPlugin, getTabPluginConfig } = usePluginUtils()

  const riderConfig = getWidgetConfig('rider')
  const orderConfig = getWidgetConfig('order')

  // @TODO: Temporary New Design in Beta
  // the double delcaration is to clear doubts on summary type (lob === 'rider' | 'riderV2)
  const riderSummary = riderConfig?.tab_configs.summary as RiderWidgetSummary
  const riderV2Summary = riderConfig?.tab_configs.summary as RiderV2Config
  const orderSummary = orderConfig?.tab_configs.summary

  // proof of delivery in v1 and v2 represents different logic
  const isProofOfDeliveryEnabled =
    lineOfBusiness === LinesOfBusiness.riderV2
      ? riderV2Summary?.proof_of_delivery.displayRule.includes(allowedDataPointValues.always) &&
        isDataPointValid(riderV2Summary.proof_of_delivery.betaRequirement)
      : riderSummary?.proof_of_delivery.displayRule.includes(allowedDataPointValues.always) &&
        isDataPointValid(riderSummary.proof_of_delivery.betaRequirement)

  // run beta flag checks on tabs and set initial tab
  const [activeTab, setActiveTab] = useState<RiderTabs | string>()

  const riderWidgetTabOrder = useMemo((): string[] => {
    if (riderConfig?.tab_order) {
      return getValidFeatures(riderConfig.tab_order)
    }
    return []
  }, [riderConfig, getValidFeatures])

  const deliveries = fulfillment?.deliveries || (EMPTY_ARRAY as DeliveryItem[])
  const ridertransformed = transformRider(rider, entityConfig?.utc_zone)

  const tabs = useMemo(() => {
    const tabs: Array<{
      type: 'rider' | 'plugin'
      riderNumber?: number
      riderTranslationKey?: string
      delivery?: DeliveryItem
      plugin?: string
    }> = []
    if (lineOfBusiness === LinesOfBusiness.riderV2) return tabs

    if (riderWidgetTabOrder)
      riderWidgetTabOrder.forEach((widget) => {
        if (widget === allowedVendorRiderTabs.summary) {
          if (deliveries.length >= 2 && showSplitDeliveries) {
            deliveries.forEach((delivery, index) => {
              tabs.push({
                type: 'rider',
                delivery,
                riderNumber: index + 1,
                riderTranslationKey:
                  deliveries.length < 5 ? 'Widgets Common.Rider' : 'Widgets Common.R',
              })
            })
          }
          return
        }

        if (isTabAPlugin(allowedScrollablePanelWidgets.rider, widget)) {
          tabs.push({
            type: 'plugin',
            plugin: widget,
          })
        }
      })

    return tabs
  }, [riderWidgetTabOrder, isTabAPlugin, showSplitDeliveries, deliveries, lineOfBusiness])

  useEffect(() => {
    if (tabs.length && !activeTab) {
      const firstTab = tabs[0]
      if (firstTab.type === 'rider') {
        setActiveTab(firstTab.delivery.id.toString())
      } else {
        setActiveTab(firstTab.plugin)
      }
    }
  }, [tabs, activeTab, deliveryId])

  useEffect(() => {
    if (deliveryId) {
      setActiveTab(String(deliveryId))
    }
  }, [deliveryId])

  const tabStyles = useUiV1TopLevelTabStyles()

  // if (orderError && lineOfBusiness !== LinesOfBusiness.riderV2) {
  //   return (
  //     <div className={classes.riderContainer}>
  //       <NewDesign
  //         config={orderSummary}
  //         fallback={<p className={classes.ridertitle}>{t('Rider Widget.Rider')}</p>}
  //       >
  //         <CSWidgetHeading title='Rider Widget.Rider' />
  //       </NewDesign>
  //       <WidgetErrorHandler errorPayload={orderError} loading={false} />
  //     </div>
  //   )
  // }

  if (!fulfillment && !isProofOfDeliveryEnabled && lineOfBusiness !== LinesOfBusiness.riderV2) {
    return (
      <div className={classes.riderContainer}>
        <NewDesign
          config={orderSummary}
          fallback={<p className={classes.ridertitle}>{t('Rider Widget.Rider')}</p>}
        >
          <CSWidgetHeading title='Rider Widget.Rider' />
        </NewDesign>
        <Result
          icon={<UserDeleteOutlined />}
          title={t('Messages.No Rider Found')}
          subTitle={t('Messages.There is no rider assigned to the order at the moment')}
        />
      </div>
    )
  }

  return (
    <div>
      {lineOfBusiness === LinesOfBusiness.riderV2 ? (
        <WidgetTitle>
          <NewDesign
            config={riderV2Summary}
            fallback={
              <>
                {t('Rider Widget.Rider')}
                <Tag color='#64bd53'>{`${t('Rider Widget.ID')}: ${ridertransformed?.id}`}</Tag>
              </>
            }
          >
            <span>
              {t('Rider Widget.Rider')}

              <WidgetId
                id={`${ridertransformed.id}`}
                successMessage={t('Rider Widget.Rider ID Copied')}
                warningMessage={t('Widgets Common.Nothing to Copy')}
                errorMessage={t('Widgets Common.Cannot Copy')}
                idLabel={t('Rider Widget.ID')}
              />
            </span>
          </NewDesign>
        </WidgetTitle>
      ) : (
        <NewDesign
          config={riderSummary}
          fallback={<p className={classes.ridertitle}>{t('Rider Widget.Rider')}</p>}
        >
          <CSWidgetHeading title='Rider Widget.Rider' />
        </NewDesign>
      )}

      {tabs.length === 0 ? (
        lineOfBusiness === LinesOfBusiness.riderV2 ? (
          <Tabs
            activeKey={activeTab}
            onChange={(tab: string) => {
              setActiveTab(
                isTabAPlugin(allowedScrollablePanelWidgets.rider, tab) ? tab : RiderTabs[tab],
              )
            }}
            type='card'
            size={'small'}
            className={tabStyles.tabs}
          >
            {riderWidgetTabOrder
              ? riderWidgetTabOrder.map((tab: string) => {
                  switch (tab) {
                    case allowedVendorRiderTabs.summary:
                      return (
                        <TabPane tab={t('Widgets Common.Summary')} key={RiderTabs.summary}>
                          <RiderV2Summary />
                        </TabPane>
                      )
                  }
                  if (isTabAPlugin(allowedScrollablePanelWidgets.rider, tab)) {
                    const pluginTab = getTabPluginConfig(allowedScrollablePanelWidgets.rider, tab)
                    return (
                      <TabPane tab={t(pluginTab.title)} key={pluginTab.plugin_code}>
                        <PluginContainer pluginCode={pluginTab.plugin_code} />
                      </TabPane>
                    )
                  }

                  return null
                })
              : null}
          </Tabs>
        ) : (
          <Summary riderSummary={riderSummary} noRiderData={!fulfillment} />
        )
      ) : (
        <Tabs
          activeKey={activeTab}
          onChange={setActiveTab}
          type='card'
          size={'small'}
          className={tabStyles.tabs}
        >
          {tabs.map(({ type, delivery, riderNumber, riderTranslationKey, plugin }) => {
            switch (type) {
              case 'rider':
                return (
                  <TabPane tab={t(riderTranslationKey) + ' ' + riderNumber} key={delivery.id}>
                    <Summary
                      riderSummary={riderSummary}
                      noRiderData={false}
                      deliveryId={delivery.id}
                    />
                  </TabPane>
                )

              case 'plugin':
                const pluginTab = getTabPluginConfig(allowedScrollablePanelWidgets.rider, plugin)
                return (
                  <TabPane tab={t(pluginTab.title)} key={plugin}>
                    <PluginContainer pluginCode={pluginTab.plugin_code} />
                  </TabPane>
                )

              default:
                return null
            }
          })}
        </Tabs>
      )}
    </div>
  )
}

export default Rider
