/**
 *  Custom EFFECT: checks token expiration upon mounting
 *  fires refresh permissions client if current token expired & sets periodical api calls if shouldSetInterval passed true
 * returns object of 2 booleans, indicating the usability of token in localStorage and interval state
 * */

// libs
import { useState, useEffect, useContext } from 'react'
// contexts and types
import { SessionContext } from 'contexts/session/SessionContext'
// utils
import ensureJwtIsValid from 'utils/oneviewApi/ensureJwtIsValid'

export default function useRefreshPermissions(deps: React.DependencyList = []) {
  // state to indicate usability of JW token
  const [isCurrentTokenValid, setIsCurrentTokenValid] = useState(false)
  // state to indicate usability of refresh token
  const [isRefreshTokenExpired, setIsRefreshTokenExpired] = useState(false)

  // pull caseId from context
  const {
    sessionState: { caseId },
  } = useContext(SessionContext)

  useEffect(() => {
    let isTokensValid = false
    // awaits for ensureJwtIsValid check token validity and refresh tokens if JWT is expired
    const ensureJwtIsValidAsync = async () => {
      isTokensValid = await ensureJwtIsValid(caseId)
      // if current JWT is valid, set token valid state (returned to the caller components)
      if (isTokensValid) {
        setIsCurrentTokenValid(true)
      } else {
        setIsRefreshTokenExpired(true)
      }
    }
    // call async ensureJwtIsValid
    ensureJwtIsValidAsync()

    // eslint-disable-next-line
  }, deps)

  // return JWT and refresh token validity
  return { isCurrentTokenValid, isRefreshTokenExpired }
}
