/**
 * creates and returns client headers with the passed JWT and case ID for all OneView APIs except auth
 * */
import { LinesOfBusiness } from 'types/session/linesOfBusiness'
import { readAuthPayload } from 'utils/authHelpers'
import { apiHeadersClientIdKey, apiHeadersContactIdKey, getApiHeaderClientIds } from './constants'

function createOneviewHeaders(options?: { jwt?: string; caseId?: string }) {
  const { jwt } = readAuthPayload()
  const search = window.location.search
  const params = new URLSearchParams(search)
  const lineOfBusiness = params.get('lob')
  const caseId = params.get('caseId')

  const { apiHeadersClientId, apiHeadersRsClientId } = getApiHeaderClientIds()

  return {
    headers: {
      [apiHeadersClientIdKey]:
        lineOfBusiness === LinesOfBusiness.rider ? apiHeadersRsClientId : apiHeadersClientId,
      [apiHeadersContactIdKey]: options?.caseId || caseId || 'null',
      Authorization: `Bearer ${options?.jwt || jwt}`,
    },
  }
}

export default createOneviewHeaders
