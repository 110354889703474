import React from 'react'
import { createUseStyles } from 'react-jss'
import { Divider, Typography } from 'antd'

import fixNumber from 'utils/fixNumber'
import VoucherDetails from './VoucherDetails'
import AdditionalDetails from './AdditionalDetails'
import { useTranslation } from 'hooks/useTranslation'
import { RefundIssueType } from 'contexts/entity/types'
import { ItemStructured } from 'types/actions/partialRefund'

import styles from './Refund.styles'

const useStyles = createUseStyles(styles)

type Props = {
  currency: string
  totalRefundAmount: number
  totalRemovalAmount: number
  compensationAmount: number
  refundAmountPrecision: number
  voucherMinOrderValue: number
  voucherPaymentTypes: string
  voucherBeginDate: string
  voucherEndDate: string
  additionalNotes: string
  selectedItems: ItemStructured[]
  partiallySelectedItems: ItemStructured[]
  selectedIssueType: RefundIssueType
  setAdditionalNotes: React.Dispatch<React.SetStateAction<string>>
}

const BankAccountRefundMethod = ({
  currency,
  totalRefundAmount,
  compensationAmount,
  totalRemovalAmount,
  refundAmountPrecision,
  voucherMinOrderValue,
  voucherPaymentTypes,
  voucherBeginDate,
  voucherEndDate,
  additionalNotes,
  selectedIssueType,
  setAdditionalNotes,
  partiallySelectedItems,
  selectedItems,
}: Props) => {
  const classes = useStyles()
  const { Text } = Typography

  // pull translations
  const { t } = useTranslation()

  return (
    <React.Fragment>
      {/* item info -> item, compensation and total refund amounts */}
      {!selectedIssueType?.use_flexible_amount ? (
        <div className={classes.textBlock}>
          <Text className={classes.titleText}>
            {`${t('Actions Widget.Actions.Full Refund.Items Amount')}:`}
          </Text>
          <Text className={classes.dataText}>{`${currency} ${fixNumber(
            totalRemovalAmount,
            refundAmountPrecision,
          )}`}</Text>
        </div>
      ) : null}
      {selectedIssueType?.use_flexible_amount ? (
        <div className={classes.textBlock}>
          <Text className={classes.titleText}>
            {`${t('Actions Widget.Actions.Full Refund.Refund Amount')}:`}
          </Text>
          <Text className={classes.dataText}>{`${currency} ${fixNumber(
            totalRefundAmount,
            refundAmountPrecision,
          )}`}</Text>
        </div>
      ) : null}
      <div className={classes.textBlock}>
        <Text className={classes.titleText}>
          {`${t('Actions Widget.Actions.Full Refund.Compensation Amount')}:`}
        </Text>
        <Text className={classes.dataText}>{`${currency} ${fixNumber(
          compensationAmount,
          refundAmountPrecision,
        )}`}</Text>
      </div>
      <div className={classes.textBlockTotalAmount}>
        <Text className={classes.titleText}>
          {`${t('Actions Widget.Actions.Partial Refund.Total Refund and Compensation Amount')}:`}
        </Text>
        <Text className={classes.dataTextTotalAmount}>{`${currency} ${fixNumber(
          totalRefundAmount + compensationAmount,
          refundAmountPrecision,
        )}`}</Text>
      </div>

      <Divider />
      <VoucherDetails
        currency={currency}
        totalRefundAmount={fixNumber(compensationAmount, refundAmountPrecision)}
        voucherMinOrderValue={fixNumber(voucherMinOrderValue, refundAmountPrecision)}
        voucherPaymentTypes={voucherPaymentTypes}
        validDates={`${voucherBeginDate} – ${voucherEndDate}`}
      />

      <Divider />
      <AdditionalDetails
        notes={additionalNotes}
        onNotesChange={setAdditionalNotes}
        selectedItems={selectedItems}
        partiallySelectedItems={partiallySelectedItems}
        selectedIssueType={selectedIssueType}
      />
    </React.Fragment>
  )
}

export default BankAccountRefundMethod
