// all available configurable data points in order widget

// summary tab
export const orderSummaryDataPoints = {
  leftColumn: {
    items: 'items',
    paidAmount: 'Paid Amount',
    paymentMethod: 'Payment Method',
    paymentStatus: 'Payment Status',
    currentStatus: 'Current Status',
    weight: 'Weight',
    currentStatusReason: 'Current Status Reason',
  },
  rightColumn: {
    promisedDeliveryTime: 'Promised Delivery Time',
    deliveryInstructions: 'Delivery Instructions',
    deliveryAddress: 'Delivery Address',
  },
  tags: {
    joker: 'joker',
    corporate: 'Corporate',
    preOrder: 'Pre-order',
    application: 'Application',
    deliveryProvider: 'Delivery Provider',
    platform: 'Platform',
    splitDelivery: 'Split Delivery',
  },
}

// items tab
export const orderItemsDataPoints = {
  paymentMethods: 'Payment Methods',
  paymentStatus: 'Payment Status',
  totalPrice: 'Total Price and Currency',
  specialInstructions: 'Special Instructions',
  toppingsTotal: 'Toppings Total Price',
  toppingName: 'Topping Name',
  toppingPrice: 'Topping Price',
  coupon: 'Coupon',
  discount: 'Discount',
  minOrderValue: 'Min. Order Value',
  deliveryFee: 'Delivery Fee',
  otherCharges: 'Other Charges',
}

// status tab
export const orderStatusDataPoints = {
  promisedDropoffTime: 'Promised Dropoff Time',
  estimatedDropoffTime: 'Estimated Dropoff Time',
  estimatedPickupTime: 'Estimated Pickup Time',
  deliveryInstructions: 'Delivery Instructions',
  statusHistory: 'Order Status History',
  dropoffLocation: 'Dropoff Location',
  pickupLocation: 'Pick Location',
  courierLocation: 'Courier Location',
}

// payment tab
export const orderPaymentDataPoints = {
  totalAmountRefundedToSource: 'Total Amount Refunded to Source',
  totalAmountRefundedToWallet: 'Total Amount Refunded to Wallet',
}
