import { createUseStyles } from 'react-jss'

export interface UseWidgetContainerStylesOptions {
  marginTop?: number
  paddingTop?: number
  paddingLeft?: number
  paddingRight?: number
  paddingBottom?: number
  withBoxShadow?: boolean
}

export const useWidgetContainerStyles = createUseStyles({
  styles: (opts: UseWidgetContainerStylesOptions) => {
    opts = opts || ({} as UseWidgetContainerStylesOptions)

    const { paddingBottom, marginTop = 16, withBoxShadow = false } = opts

    let { paddingLeft, paddingRight, paddingTop } = opts

    if (withBoxShadow) {
      paddingLeft = paddingLeft ?? 16
      paddingRight = paddingRight ?? 16
      paddingTop = paddingTop ?? 16
    }

    return {
      marginTop,
      paddingTop,
      paddingLeft,
      paddingRight,
      paddingBottom,
      border: withBoxShadow ? '1px solid #D9D9D9' : undefined,
      boxShadow: withBoxShadow ? '0px 2px 8px rgba(0, 0, 0, 0.15)' : undefined,
      borderRadius: withBoxShadow ? '6px' : undefined,

      '& > .widget:first-child': {
        marginTop: 0,
      },
    }
  },
})
