/**
 * expects Vendor API's response
 * creates 2 arrays with appropriate key/value pairs to be used in Vendor widget Summary Tab
 * */

import { unknownDataPoint } from './constants'
import { TransformedDataPoint, TranformedTags } from 'types/dataTransformers/generic'
import { VendorApiResponse, VendorContact, VendorContactTypes } from 'types/api/vendorApi/vendor'
import groupArrayByProperty from 'utils/groupArrayByProperty'

export interface PhoneNumbersColumnContent {
  key: VendorContactTypes
  value: string[]
}

function transformVendorSummary(
  data: VendorApiResponse,
  t: any,
  multiplePhoneNumbersEnabled: boolean,
  phoneNumberTypes: VendorContactTypes[],
) {
  const convertTransmissionContactsToColumnData = (
    contacts: VendorContact[],
    phoneNumberTypes: VendorContactTypes[],
  ) => {
    const grouped = groupArrayByProperty(contacts, (contact: VendorContact) => contact.type)
    const rightColumn = []

    grouped.forEach((value, key) => {
      if (phoneNumberTypes.includes(key)) {
        rightColumn.push({
          key: key,
          value: value.map((v: VendorContact) => v.phone),
        })
      }
    })
    return rightColumn
  }

  const transformedPhoneNumbersColumnData: PhoneNumbersColumnContent[] =
    multiplePhoneNumbersEnabled && data?.transmission?.contacts?.length > 0
      ? convertTransmissionContactsToColumnData(data.transmission.contacts, phoneNumberTypes)
      : [
          {
            key: t('Vendor Widget.Phone'),
            value: [data.contact && data.contact.phone ? data.contact.phone : unknownDataPoint],
          },
        ]
  // set columns' data accordingly
  const transformedLeftColumnData: TransformedDataPoint[] = [
    {
      key: t('Vendor Widget.Vendor Name'),
      value: data.name ? data.name : unknownDataPoint,
    },
    {
      key: t('Vendor Widget.Vendor Address'),
      value:
        data.location && data.location.address_text ? data.location.address_text : unknownDataPoint,
    },
  ]

  // prepare data for tags
  const tagsData: TranformedTags[] = [
    {
      key: 'is_active',
      value: data.active ? t('Vendor Widget.Active') : false,
    },
    {
      key: 'is_halal',
      value: data.halal ? t('Vendor Widget.Halal') : false,
    },
    {
      key: 'vertical_type',
      value: data.vertical_type ? `${data.vertical_type}` : `${unknownDataPoint}`,
    },
  ]

  // return columns' data
  return {
    transformedLeftColumnData,
    transformedRightColumnData: transformedPhoneNumbersColumnData,
    tagsData,
  }
}

export default transformVendorSummary
