import React, { useCallback } from 'react'
import { createPluggableWidget } from 'factory/createPluggableWidget'
import {
  CustomDataPointRenderProps,
  DataPointProps,
  DataPoints,
  StatusDataPointProps,
  SteppedProgressCircle,
} from 'shared'
import { BuiltinWidgetConfigs, DeliveryProviders } from 'types'
import {
  UnifiedVendorAvailabilityStatus,
  getVendorAvailability,
} from 'services/vendorApi/getVendorAvailability'
import { getUnifiedVendorAvailabilityStatus } from 'utils/vendor/getUnifiedVendorAvailabilityStatus'
import { green, gray, red } from 'theme'
import { getVendorSchedule } from 'services/vendorApi/getVendorSchedule'
import { getVendorAvailabilityStatus } from 'services/vendorApi/getVendorAvailabilityStatus'
import { VendorAvailabilityStatusItem } from 'types/api/vendorApi/vendorAvailabilityStatus'
import {
  TransformedVendorSchedule,
  transformVendorSchedule,
} from 'utils/vendor/transformVendorSchedule'
import { Space } from 'antd'
import moment from 'moment'
import { VendorPhoneNumbers } from './VendorPhoneNumbers/VendorPhoneNumbers'
import { useApiService } from 'hooks/useApiService'
import { getVendorSummary } from 'services/vendorApi/getVendorSummary'
import { CopyableText } from 'shared/CopyableText'
import { VendorSummaryHeaderCard } from './VendorSummaryHeaderCard/VendorSummaryHeaderCard'
import { createUseStyles } from 'react-jss'

const availabilityStatusToColorMap: {
  [p in UnifiedVendorAvailabilityStatus]: string
} = {
  Open: green.green6,
  Closed: red.red5,
  Offline: red.red5,
  Unavailable: gray.gray7,
}
const useStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: '32px',
  },
})

export const VendorSummary = createPluggableWidget<BuiltinWidgetConfigs['vendor_summary']>(
  ({ config, vendor, order, globalEntityId, sdk, ErrorRenderer }) => {
    const { createApiClient, checkDisplayRules, t } = sdk
    const deliveryTypeKey = {
      [DeliveryProviders.vendorDelivery]: t('Vendor Delivery'),
      [DeliveryProviders.pickDelivery]: t('Pickup'),
      [DeliveryProviders.platformDelivery]: t('Platform Delivery'),
      [DeliveryProviders.partnerDelivery]: t('Partner Delivery'),
      [DeliveryProviders.dineIn]: t('Dine-In'),
    }
    const classes = useStyles()

    const vendorId = vendor.vendor_id
    const showEligibilities = checkDisplayRules({
      displayRules: config.eligibilities,
    })
    const showNewDesign = checkDisplayRules({
      displayRules: config.showNewDesign,
    }).enabled

    const {
      loading: isVendorSummaryLoading,
      data: vendorSummaryData,
      error: getVendorSummaryError,
    } = useApiService({
      service: getVendorSummary,
      autoLoad: true,
      params: { globalEntityId, globalVendorId: vendor?.global_vendor_id },
      deps: [globalEntityId, vendor?.global_vendor_id, showNewDesign],
    })

    if (showNewDesign) {
      const newDesignDataPoints = config.data_points.map(
        ({ name, display_rules, label_translation_key }): DataPointProps => {
          switch (name) {
            case 'delivery_type':
              return {
                display_rules,
                type: 'default',
                value: (vendor.delivery_types || [])
                  .map((type: DeliveryProviders) => deliveryTypeKey[type] ?? type)
                  .join(', '),
                name,
                label: t(label_translation_key),
              }

            case 'website':
              return {
                display_rules,
                type: 'custom',
                render: () => (
                  <CopyableText
                    copyableText={vendorSummaryData?.website}
                    type='link'
                    copyEventName='COPY_VENDOR_WEBSITE'
                  />
                ),
                name,
                label: t(label_translation_key),
              }

            case 'address':
              return {
                display_rules,
                type: 'default',
                value: vendor.location?.address_text,
                name,
                label: t(label_translation_key),
              }

            case 'phone_number':
              return {
                display_rules,
                type: 'custom',
                name,
                label: t(label_translation_key),
                render: () => <VendorPhoneNumbers vendor={vendor} />,
              }

            case 'email':
              return {
                display_rules,
                type: 'default',
                value: vendor.owners?.[0]?.email,
                name,
                label: t(label_translation_key),
                allowCopy: true,
                copyEventName: 'COPY_VENDOR_EMAIL',
              }

            default:
              return null
          }
        },
      )
      return (
        <ErrorRenderer
          loading={isVendorSummaryLoading}
          errorPayload={getVendorSummaryError?.errorPayload}
        >
          {() => {
            return (
              <div className={classes.container}>
                <VendorSummaryHeaderCard vendor={vendor} vendorSummary={vendorSummaryData} />
                <DataPoints dataPoints={newDesignDataPoints} />
              </div>
            )
          }}
        </ErrorRenderer>
      )
    }

    const getAvailability = useCallback((): Promise<StatusDataPointProps['value']> => {
      return getVendorAvailability(createApiClient, {
        entityId: globalEntityId,
        vendorId,
      }).then((res) => {
        const availability = res.data
        const { offline_reason } = availability

        const availabilityStatus = getUnifiedVendorAvailabilityStatus(availability)

        return {
          statusColor: availabilityStatusToColorMap[availabilityStatus],
          statusText: availabilityStatus,
          reason: offline_reason
            ? t(`vendor_availability_offline_reasons.${offline_reason.toLowerCase()}`)
            : '',
        }
      })
    }, [createApiClient, globalEntityId, vendorId, t])

    const getAvailabilityStatus = useCallback(async (): Promise<VendorAvailabilityStatusItem> => {
      const { data } = await getVendorAvailabilityStatus(createApiClient, {
        entityId: globalEntityId,
        vendorId,
      })
      return data[0] ?? null
    }, [createApiClient, globalEntityId, vendorId])

    const getSchedule = useCallback((): Promise<TransformedVendorSchedule> => {
      return getVendorSchedule(createApiClient, {
        entityId: globalEntityId,
        vendorId,
      }).then((res) => {
        return transformVendorSchedule(res.data)
      })
    }, [createApiClient, globalEntityId, vendorId])

    const getTimeToClose = useCallback(async () => {
      const [scheduleResult, availabilityResult] = await Promise.allSettled([
        getSchedule(),
        getAvailabilityStatus(),
      ])

      if (scheduleResult.status === 'rejected') {
        throw scheduleResult.reason
      }

      return {
        schedule: scheduleResult.value,
        // Availability can be ignored if we don't have a status
        availability: availabilityResult.status === 'fulfilled' ? availabilityResult.value : null,
      }
    }, [getAvailabilityStatus, getSchedule])

    const oldDesignDataPoints = config.data_points.map(
      ({ name, display_rules, label_translation_key }): DataPointProps => {
        switch (name) {
          case 'vendor_name':
            return {
              display_rules,
              type: 'link',
              value: vendor.name,
              to: vendor.vendor_detail_page_slug,
              name,
              label: t(label_translation_key),
            }

          case 'vendor_id':
            return {
              display_rules,
              type: 'default',
              value: vendor.vendor_id,
              name,
              label: t(label_translation_key),
              allowCopy: true,
              copyEventName: 'COPY_VENDOR_ID',
            }

          case 'global_vendor_id':
            return {
              display_rules,
              type: 'default',
              value: vendor.global_vendor_id,
              name,
              label: t(label_translation_key),
              allowCopy: true,
              copyEventName: 'COPY_GLOBAL_VENDOR_ID',
            }

          case 'delivery_type':
            return {
              display_rules,
              type: 'default',
              value: (vendor.delivery_types || [])
                .map((type: DeliveryProviders) => deliveryTypeKey[type] ?? type)
                .join(', '),
              name,
              label: t(label_translation_key),
            }

          case 'address':
            return {
              display_rules,
              type: 'default',
              value: vendor.location?.address_text,
              name,
              label: t(label_translation_key),
            }

          case 'phone_number':
            return {
              display_rules,
              type: 'custom',
              name,
              label: t(label_translation_key),
              render: () => <VendorPhoneNumbers vendor={vendor} />,
            }

          case 'email':
            return {
              display_rules,
              type: 'default',
              value: vendor.owners?.[0]?.email,
              name,
              label: t(label_translation_key),
              allowCopy: true,
              copyEventName: 'COPY_VENDOR_EMAIL',
            }

          case 'availability':
            return {
              display_rules,
              type: 'status',
              value: null,
              loader: getAvailability,
              name,
              label: t(label_translation_key),
            }

          case 'vendor_tags':
            const tags = showEligibilities ? order?.vendor?.eligibilities || [] : []
            return {
              display_rules,
              type: 'tags',
              value: tags
                .concat([
                  vendor.active ? t(`vendor_tags.active`) : '',
                  vendor.halal ? t(`vendor_tags.halal`) : '',
                  vendor.vertical_type || '',
                ])
                .filter((current) => current !== ''),
              name,
              label: t(label_translation_key),
            }

          case 'time_left_to_close':
            return {
              display_rules,
              type: 'custom',
              value: null,
              loader: getTimeToClose,
              name,
              label: t(label_translation_key),
              render: ({
                value,
              }: CustomDataPointRenderProps<{
                schedule: TransformedVendorSchedule
                availability: VendorAvailabilityStatusItem
              }>) => {
                const { schedule, availability } = value
                const { currentShift, scheduleTimezone } = schedule ?? {}
                const isAvailable = !availability || availability.availabilityState === 'OPEN'

                if (!currentShift || !isAvailable) {
                  return '-'
                }

                const startOfShiftDate = moment.tz(currentShift.from, 'HH:mm', scheduleTimezone)
                const endOfShiftDate = moment.tz(currentShift.to, 'HH:mm', scheduleTimezone)
                const vendorLocalDate = moment.tz(scheduleTimezone)
                const shiftTotalTime = endOfShiftDate.diff(startOfShiftDate)
                const remainingTime = endOfShiftDate.diff(vendorLocalDate)

                const timeLeftToClose = moment.utc(remainingTime).format('HH [hr] mm [min]')

                return (
                  <Space size={8}>
                    <SteppedProgressCircle
                      total={shiftTotalTime}
                      progress={shiftTotalTime - remainingTime}
                      step={100 / 6}
                    />{' '}
                    {timeLeftToClose}
                  </Space>
                )
              },
            }

          default:
            return null
        }
      },
    )

    return <DataPoints dataPoints={oldDesignDataPoints} />
  },
  {
    deriveConfig({ entityConfig }) {
      return entityConfig.layout_v2.builtin_widgets_configs.vendor_summary
    },
    deriveSubjectsRequirements() {
      return {
        all_of: ['vendor'],
      }
    },
    category: 'data_lookup',
  },
)
