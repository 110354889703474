import React, { useState } from 'react'
import { Row, Col, Space, TagProps } from 'antd'
import { Button, Text, UnifiedIcon } from 'shared'
import SingleTag from 'components/Tags/SingleTag'
import { useTranslation } from 'hooks/useTranslation'
import useStyles from './CommentsSummary.styles'
import { SingleCommentSource } from 'types/api/commentApi/getOrderComments'
import { text } from 'theme'

const SourceColor: Record<CommentTag['source'], TagProps['color']> = {
  oneview: 'processing',
  autocomp: 'warning',
  helpcenter: 'default',
}

const SourceTooltip: Record<CommentTag['source'], string> = {
  oneview: 'IVU',
  autocomp: 'Autocomp',
  helpcenter: 'Self service',
}

export type CommentTag = {
  id: string
  label: string
  count: number
  source: SingleCommentSource
  timestamp: string
}

type Props = {
  tags: CommentTag[]
  showMin?: number
  hasError?: boolean
  onClick?: (tag: CommentTag) => void
}

export const CommentTags = ({ tags, showMin = 3, onClick, hasError }: Props) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const [showAll, setShowAll] = useState(false)

  if (tags.length < 1 && !hasError) return null

  const visibleTags = showAll ? tags : tags.slice(0, showMin)

  const ToggleButton = showAll ? (
    <Button type='link' className={classes.toggleBtn} onClick={() => setShowAll(false)}>
      {t('widgets.summary_panel.see_less')}
    </Button>
  ) : (
    <Button type='link' className={classes.toggleBtn} size='small' onClick={() => setShowAll(true)}>
      +{tags.length - showMin} {t('widgets.summary_panel.more')}
    </Button>
  )

  return (
    <Row className={classes.container} gutter={[10, 0]} wrap={false}>
      <Col>
        <UnifiedIcon mt={4} icon='ThunderboltOutlined' color={text.secondary} />
      </Col>
      <Col>
        {hasError ? (
          <Text.Primary color='red' className={classes.errorText}>
            {t('widgets.summary_panel.comments_load_error')}
          </Text.Primary>
        ) : (
          <Space wrap size={4}>
            {visibleTags.map((tag) => {
              const label = t(`widgets.summary_panel.comment_tags.${tag.label}`)
              const value = tag.count > 1 ? `${tag.count} x ${label}` : label
              return (
                <SingleTag
                  key={tag.id}
                  className={classes.tag}
                  value={value}
                  tooltip={SourceTooltip[tag.source]}
                  onClick={() => onClick?.(tag)}
                  color={SourceColor[tag.source]}
                />
              )
            })}
            {tags.length > showMin ? ToggleButton : null}
          </Space>
        )}
      </Col>
    </Row>
  )
}
