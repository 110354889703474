/**
 * TagsView: a dumb component rendering a label followed by the passed tags
 * */

// libs
import React from 'react'
// styles
import { createUseStyles } from 'react-jss'
import styles from './TagsView.styles'
import { Tag, Typography } from 'antd'

interface Props {
  label: string
  tags: string[]
}

const useStyles = createUseStyles(styles)

const TagsView: React.FC<Props> = ({ label, tags }) => {
  const classes = useStyles()
  const { Text } = Typography

  return (
    <div className={classes.container}>
      <Text className={classes.labelText}>{`${label}: `}</Text>
      {tags &&
        tags
          .filter((tag: string) => tag !== 'false')
          .map((tag: string, idx: number) => (
            <Tag key={idx} color='blue'>
              {tag}
            </Tag>
          ))}
    </div>
  )
}

export default TagsView
