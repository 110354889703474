export default {
  container: {
    width: '100%',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'baseline',
    width: '100%',
    backgroundColor: '#2A8FF7',
    padding: '6px 14px 6px 14px',
  },
  title: {
    fontSize: '18px',
    color: '#ffffff',
  },
  closeIcon: {
    color: '#ffffff',
  },
  commentBody: {
    marginTop: '20px',
    padding: '0px 10px 0px 10px',
  },
  commentTitle: {
    fontSize: '14px',
    color: '#000000',
  },
  textArea: {
    marginTop: '10px',
  },
  buttonHolder: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'baseline',
    marginTop: '20px',
  },
  explanationHolder: {
    padding: '15px 10px 15px 10px',
  },
  explanationForApac: {
    fontSize: '14px',
    color: 'rgba(0, 0, 0, 0.65)',
    // padding: '15px 10px 15px 10px',
  },
  checkboxesFornonApacHolder: {
    display: 'flex',
    flexDirection: 'column',
    padding: '15px 10px 15px 10px',
  },
  explanationForNonApac: {
    fontSize: '14px',
    color: 'rgba(0, 0, 0, 0.65)',
    marginBottom: '15px',
  },
  checkboxesContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '7px',
  },
  checkboxIds: {
    fontWeight: 600,
    color: 'rgba(0, 0, 0, 0.85)',
  },
  saveButtonHolder: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '7px',
  },
}
