import React, { useCallback } from 'react'
import GoogleMapReact from 'google-map-react'
import environment from 'envConfig'
import grayMarker from 'assets/mapMarkers/Gray.png'
import pinkMarker from 'assets/mapMarkers/Pink.png'

type Point = {
  lat: number
  lng: number
}

type MapsLocationPinChangeProps = Point & {
  onPinMoved: (e: Point) => void
}
const MapsPinChange: React.FC<MapsLocationPinChangeProps> = ({ lat, lng, onPinMoved }) => {
  const loadMap = useCallback(
    (map, maps) => {
      // original position marker
      new maps.Marker({
        position: { lat, lng },
        map,
        icon: grayMarker,
      })

      // movable marker
      const movableMarker = new maps.Marker({
        position: { lat, lng },
        map,
        draggable: true,
        icon: pinkMarker,
      })
      // move z index, otherwise the marker for existing position hinders movement of this marker
      movableMarker.setZIndex(maps.Marker.MAX_ZINDEX + 1)
      // finally, relay new lat,lng on drag-end of this marker.
      movableMarker.addListener('dragend', () => {
        const pos = movableMarker.getPosition()
        onPinMoved({
          lat: pos.lat(),
          lng: pos.lng(),
        })
      })
    },
    [lat, lng, onPinMoved],
  )

  return (
    <GoogleMapReact
      bootstrapURLKeys={{ key: environment().googleMapsApiKey }}
      defaultCenter={{ lat, lng }}
      defaultZoom={15}
      yesIWantToUseGoogleMapApiInternals
      onGoogleApiLoaded={({ map, maps }) => loadMap(map, maps)}
    />
  )
}

export default MapsPinChange
