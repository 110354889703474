export default {
  riderPanelHolder: {
    width: 280,
  },
  tabsHolder: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    minWidth: 280,
    marginTop: '7px',
  },
  navbarItemIcon: {
    marginRight: '5px',
    width: '13px',
    height: '13px',
  },
}
