export default {
  backOfficeContainer: {
    padding: '3px',
  },
  commentsContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    overflow: 'scroll',
  },
  comments: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '350px',
    maxHeight: '350px',
    overflow: 'scroll',
  },
  filterContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: '7px',
    marginBottom: '12px',
    backgroundColor: 'rgba(0, 0, 0, 0.02)',
  },
  eventsTitle: {
    fontSize: '14px',
    fontWeight: 600,
    color: 'rgba(0, 0, 0, 0.85)',
  },
  checkbox: {
    marginTop: '10px',
  },
  singleCommentContainerOneview: {
    backgroundColor: '#E6F7FF',
    padding: '3px',
    fontSize: '14px',
  },
  singleCommentContainer: {
    padding: '3px',
  },
  divider: {
    margin: '8px 0px 8px 0px',
  },
  addedByOneviewHighlight: {
    color: '#2A8FF7',
  },
}
