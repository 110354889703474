import React, { ReactNode } from 'react'
import styles from './SingleChatTranscript.styles'
import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles(styles)

interface SingleChatInfoParams {
  message: string
  time: string
  extra?: ReactNode
}

export const SingleChatInfo = ({ message, time, extra }: SingleChatInfoParams) => {
  const classes = useStyles()

  return (
    <div className={classes.infoContainer}>
      <p>{message}</p>
      <p>{time}</p>
      {extra}
    </div>
  )
}
