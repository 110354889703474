import React from 'react'
import { createUseStyles } from 'react-jss'
import { gray } from 'theme'
import { BuiltinWidgetConfigs } from 'types'
import { createPluggableWidget } from 'factory/createPluggableWidget'
import { transformVendorPayoutDataPoints } from 'utils/dataTransformers/transformVendorPayoutFields'
import { useApiService } from 'hooks/useApiService'
import { getOrderBilling } from 'services/ordersApi/getOrderBilling'

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '20px',
    paddingTop: '24px',
  },
  section: {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
    width: '100%',
    borderRadius: '4px',
    padding: '16px',
    border: `1px solid ${gray.gray4}`,
  },
  item: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItem: 'center',
  },
})

export const VendorPayout = createPluggableWidget<BuiltinWidgetConfigs['vendor_payout']>(
  ({ config, sdk, ErrorRenderer, globalEntityId, vendorId, orderId }) => {
    const classes = useStyles()
    const {
      data: orderBillingData,
      loading: isLoadingOrderBilling,
      error: orderBillingError,
    } = useApiService({
      service: getOrderBilling,
      autoLoad: true,
      params: {
        globalEntityId,
        orderId,
        vendorId,
      },
    })

    const newGroupedFields = transformVendorPayoutDataPoints(orderBillingData, config.data_points)
    const { t, checkDisplayRules } = sdk

    return (
      <ErrorRenderer loading={isLoadingOrderBilling} errorPayload={orderBillingError?.errorPayload}>
        {() => {
          return (
            <div className={classes.container}>
              <div className={classes.section}>
                {newGroupedFields?.map((group, index) => {
                  return (
                    <div key={index}>
                      {group?.map(({ name, label, value, display_rules }) => {
                        if (checkDisplayRules({ displayRules: display_rules })?.visible) {
                          return (
                            <div className={classes.item} key={name}>
                              <div>{t(label)}</div>
                              <div>{value}</div>
                            </div>
                          )
                        }
                        return null
                      })}
                    </div>
                  )
                })}
              </div>
            </div>
          )
        }}
      </ErrorRenderer>
    )
  },
  {
    deriveSubjectsRequirements() {
      return {
        all_of: ['vendorId', 'order'],
      }
    },
    deriveConfig: ({ entityConfig }) =>
      entityConfig.layout_v2.builtin_widgets_configs.vendor_payout,
  },
)
