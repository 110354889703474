import { createUseStyles } from 'react-jss'

export default createUseStyles({
  items: {
    listStyle: 'none',
  },
  row: {
    marginBottom: '15px',
  },
  alert: {
    margin: '25px 0px 25px 0px',
  },
  field: {
    fontWeight: 600,
    fontSize: '13px',
    color: 'rgba(0, 0, 0, 0.85)',
  },
  value: {
    color: 'rgba(0, 0, 0, 0.65)',
  },
  list: {
    listStyle: 'none',
    paddingLeft: 0,
  },
  listItem: {
    color: 'rgba(0, 0, 0, 0.65)',
  },
})
