import { InitialState, DataAction, DataActions } from './types'

const {
  ADD_EMITTED_EVENT,
  SET_ORDER,
  SET_ORDER_BASKET_UPDATES,
  SET_ORDER_ITEMS,
  SET_ORDER_ERR,
  SET_CURRENCY,
  SET_ORDER_STATUS,
  SET_CUSTOMER,
  SET_CUSTOMER_PROFILE,
  SET_VENDOR,
  SET_PREV_ORDERS,
  SET_FULFILLMENT,
  SET_PAYMENT,
  SET_VOUCHERS,
  SET_SEARCHED_VOUCHER,
  SET_COMPENSATED_VOUCHER,
  SET_FULLY_REFUNDED_VOUCHER,
  SET_REFUND_TO_WALLET_OR_PSP_DETAILS,
  SET_WALLET,
  SET_MODIFIED_ORDER_ADDRESS,
  SET_NEW_COMMENT,
  SET_AUTO_CHANGE_DELIVERY_TIME_COMMENT,
  SET_AUTO_MISSING_ITEM_COMMENT,
  SET_AUTO_CANCEL_ORDER_COMMENT,
  SET_AUTO_COMPENSATION_COMMENT,
  SET_AUTO_PARTIAL_REFUND_COMMENT,
  SET_AUTO_FULL_REFUND_COMMENT,
  SET_AUTO_CHANGE_ADDRESS_COMMENT,
  SET_AUTO_CHANGE_INSTRUCTIONS_COMMENT,
  SET_AUTO_ITEM_REMOVAL_COMMENT,
  SET_AUTO_CALLBACK_COMMENT,
  SET_AUTO_CHANGE_COOKING_INST_COMMENT,
  SET_RECOMMENDED_COMPENSATION,
  SET_RECEIVED_COMPENSATION_SUGGESTIONS,
  SET_CUSTOMER_COMPENSATIONS,
  SET_CUSTOMER_REFUNDS,
  SET_ITEMS_WITH_COOKING_INST_CHANGED,
  SET_IS_ORDER_CANCELLED,
  SET_RIDER_CUSTOMER_CHAT,
  SET_RE_FETCH_ORDER,
  SET_RIDER,
  SET_RIDER_CURRENT_ORDER,
  SET_RIDER_CURRENT_ORDER_VENDOR,

  SET_IS_LOADING_ORDER,
  SET_CUSTOMER_EXPLOIT_STATUS,
  SET_CASE_CUSTOMER,
  SET_TICKET,
} = DataAction

export const dataReducer = (dataState: InitialState, action: DataActions): InitialState => {
  switch (action.type) {
    case ADD_EMITTED_EVENT:
      return {
        ...dataState,
        emittedEvents: new Set([action.payload, ...dataState.emittedEvents]),
      }
    case SET_ORDER:
      return {
        ...dataState,
        order: action.payload.order,
      }
    case SET_ORDER_BASKET_UPDATES:
      return {
        ...dataState,
        orderBasketUpdates: action.payload.orderBasketUpdates,
      }
    case SET_ORDER_ITEMS:
      return {
        ...dataState,
        orderItems: action.payload.orderItems,
      }
    case SET_ORDER_ERR:
      return {
        ...dataState,
        orderError: action.payload.orderError,
      }
    case SET_CURRENCY:
      return {
        ...dataState,
        currency: action.payload.currency,
      }
    case SET_ORDER_STATUS:
      return {
        ...dataState,
        order: {
          ...dataState.order,
          status_history: action.payload.orderStatus,
        },
      }
    case SET_CUSTOMER:
      return {
        ...dataState,
        customer: action.payload.customer,
      }
    case SET_CASE_CUSTOMER:
      return {
        ...dataState,
        caseCustomer: action.payload.customer,
      }
    case SET_CUSTOMER_PROFILE:
      return {
        ...dataState,
        customerProfile: action.payload.customerProfile,
      }
    case SET_VENDOR:
      return {
        ...dataState,
        vendor: action.payload.vendor,
      }
    case SET_PREV_ORDERS:
      return {
        ...dataState,
        previousOrders: action.payload.previousOrders,
      }
    case SET_FULFILLMENT:
      const newState = {
        ...dataState,
        fulfillment: action.payload.fulfillment,
      }
      //also attached fulfillment to corresponding order.
      if (
        dataState?.order &&
        action.payload?.fulfillment?.order_id === dataState?.order?.order_id
      ) {
        newState.order.fulfillment = action.payload.fulfillment
      }
      return newState
    case SET_PAYMENT:
      return {
        ...dataState,
        payment: action.payload.payment,
      }
    case SET_VOUCHERS:
      return {
        ...dataState,
        vouchers: action.payload.vouchers,
      }
    case SET_CUSTOMER_EXPLOIT_STATUS:
      return {
        ...dataState,
        customerExploitStatus: action.payload.customerExploitStatus,
      }
    case SET_SEARCHED_VOUCHER:
      return {
        ...dataState,
        searchedVoucher: action.payload.searchedVoucher,
      }
    case SET_COMPENSATED_VOUCHER:
      return {
        ...dataState,
        compensatedVoucher: action.payload.compensatedVoucher,
      }
    case SET_FULLY_REFUNDED_VOUCHER:
      return {
        ...dataState,
        fullyRefundedVoucher: action.payload.fullyRefundedVoucher,
      }
    case SET_REFUND_TO_WALLET_OR_PSP_DETAILS:
      return {
        ...dataState,
        refundToWalletOrPspDetails: action.payload.refundToWalletOrPspDetails,
      }
    case SET_WALLET:
      return {
        ...dataState,
        wallet: action.payload.wallet,
      }
    case SET_MODIFIED_ORDER_ADDRESS:
      return {
        ...dataState,
        modifiedDeliverySettings: action.payload.modifiedDeliverySettings,
      }
    case SET_NEW_COMMENT:
      return {
        ...dataState,
        newComment: action.payload.newComment,
      }
    case SET_AUTO_CHANGE_DELIVERY_TIME_COMMENT:
      return {
        ...dataState,
        autoChangeDeliveryTimeComment: action.payload.autoChangeDeliveryTimeComment,
      }
    case SET_AUTO_MISSING_ITEM_COMMENT:
      return {
        ...dataState,
        autoMissingItemReportComment: action.payload.autoMissingItemReportComment,
      }
    case SET_AUTO_CANCEL_ORDER_COMMENT:
      return {
        ...dataState,
        autoCancelOrderComment: action.payload.autoCancelOrderComment,
      }
    case SET_AUTO_COMPENSATION_COMMENT:
      return {
        ...dataState,
        autoCompensationComment: action.payload.autoCompensationComment,
      }
    case SET_AUTO_PARTIAL_REFUND_COMMENT:
      return {
        ...dataState,
        autoPartialRefundComment: action.payload.autoPartialRefundComment,
      }
    case SET_AUTO_FULL_REFUND_COMMENT:
      return {
        ...dataState,
        autoPartialRefundComment: action.payload.autoFullRefundComment,
      }
    case SET_AUTO_CHANGE_ADDRESS_COMMENT:
      return {
        ...dataState,
        autoChangeAddressComment: action.payload.autoChangeAddressComment,
      }
    case SET_AUTO_CHANGE_INSTRUCTIONS_COMMENT:
      return {
        ...dataState,
        autoChangeInstructionsComment: action.payload.autoChangeInstructionsComment,
      }
    case SET_AUTO_ITEM_REMOVAL_COMMENT:
      return {
        ...dataState,
        autoRemoveItemsComment: action.payload.autoRemoveItemsComment,
      }
    case SET_AUTO_CALLBACK_COMMENT:
      return {
        ...dataState,
        autoModifyCallbackComment: action.payload.autoModifyCallbackComment,
      }
    case SET_AUTO_CHANGE_COOKING_INST_COMMENT:
      return {
        ...dataState,
        autoChangeCookingInstComment: action.payload.autoChangeCookingInstComment,
      }
    case SET_RECOMMENDED_COMPENSATION:
      return {
        ...dataState,
        recommendedCompensation: action.payload.recommendedCompensation,
      }
    case SET_RECEIVED_COMPENSATION_SUGGESTIONS:
      return {
        ...dataState,
        receivedCompensationSuggestions: action.payload.receivedCompensationSuggestions,
      }
    case SET_CUSTOMER_COMPENSATIONS:
      return {
        ...dataState,
        customerCompensations: action.payload.customerCompensations,
      }
    case SET_CUSTOMER_REFUNDS:
      return {
        ...dataState,
        customerRefunds: action.payload.customerRefunds,
      }
    case SET_ITEMS_WITH_COOKING_INST_CHANGED:
      return {
        ...dataState,
        itemsWithCookingInstructionChanged: action.payload.itemsWithCookingInstructionChanged,
      }
    case SET_IS_ORDER_CANCELLED:
      return {
        ...dataState,
        isOrderCancelled: action.payload.isOrderCancelled,
      }
    case SET_RIDER_CUSTOMER_CHAT:
      return {
        ...dataState,
        riderCustomerChatTranscript: action.payload.riderCustomerChatTranscript,
      }
    case SET_RE_FETCH_ORDER:
      return {
        ...dataState,
        reFetchOrder: action.payload.reFetchOrder,
      }
    case SET_RIDER:
      return {
        ...dataState,
        rider: action.payload.rider,
      }
    case SET_RIDER_CURRENT_ORDER:
      return {
        ...dataState,
        riderCurrentOrder: action.payload.riderCurrentOrder,
      }
    case SET_RIDER_CURRENT_ORDER_VENDOR:
      return {
        ...dataState,
        riderCurrentOrderVendor: action.payload.riderCurrentOrderVendor,
      }

    case SET_IS_LOADING_ORDER:
      return {
        ...dataState,
        isLoadingOrder: action.payload.isLoadingOrder,
      }
    case SET_TICKET:
      return {
        ...dataState,
        ticket: action.payload.ticket,
      }
    default:
      return dataState
  }
}
