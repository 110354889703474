export default {
  chatTranscriptHolder: {
    width: '100%',
  },
  button: {
    fontSize: '12px',
    margin: '0px 0px 15px 0px',
    padding: 0,
  },
  dropdown: {
    margin: '0px 0px 15px -10px',
    padding: 0,
  },
  dropdownMenu: {
    overflowX: 'scroll',
    overflowY: 'scroll',
    height: 'auto',
    maxHeight: 300,
    width: 200,
    maxWidth: 200,
  },
  dropdownMenuItem: {
    fontSize: '12px',
    color: 'rgba(0, 0, 0, 0.65)',
  },
}
