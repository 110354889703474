import { useContext, useEffect } from 'react'
import { DataContext } from 'contexts/data/DataContext'
import { DataAction } from 'contexts/data/types'
import { SessionContext } from 'contexts/session/SessionContext'
import { useApiService } from 'hooks/useApiService'
import { getRider } from 'services/fulfillmentApi/getRider'
import { SessionAction } from 'contexts/session/types'
import { useEventEmitter } from 'hooks/useEventEmitter'
import { EmittedEventName } from 'utils/eventEmitter'

const { SET_RIDER } = DataAction

export function useLoadRider() {
  const eventEmitter = useEventEmitter('global_order')
  const {
    sessionState: { globalEntityId, riderId, orderId },
    sessionDispatch,
  } = useContext(SessionContext)

  const { dataDispatch } = useContext(DataContext)

  const riderLoadInit = useApiService({
    service: getRider,
    params: { riderId, entityId: globalEntityId },
    shouldLoad: Boolean(riderId && globalEntityId),

    onSuccess: (res) => {
      const rider = res.data

      // set order id to rider current order id
      if (rider.current_order?.order_id) {
        sessionDispatch({
          type: SessionAction.SET_SESSION,
          payload: {
            riderCurrentOrderId: rider.current_order.order_id,
          },
        })
      }

      if (!orderId && rider?.current_order?.order_id) {
        sessionDispatch({
          type: SessionAction.SET_SESSION,
          payload: {
            orderId: rider.current_order.order_id,
          },
        })
      }

      dataDispatch({
        type: SET_RIDER,
        payload: { rider },
      })
    },
  })

  /** listen for rider break state change and reload */
  const { loadService: loadRider } = riderLoadInit
  useEffect(() => {
    const events: Array<EmittedEventName> = ['RIDER_BREAK_STATUS_CHANGE']
    eventEmitter.addEventsListener({
      names: events,
      callback: () => {
        if (globalEntityId && riderId) {
          loadRider({
            entityId: globalEntityId,
            riderId,
            clientParams: { cacheable: false },
          })
        }
      },
    })
    return () => {
      eventEmitter.removeAllListeners()
    }
  }, [eventEmitter, globalEntityId, riderId, loadRider])

  return riderLoadInit
}
