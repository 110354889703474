import React from 'react'
import { Typography } from 'antd'
import { createUseStyles } from 'react-jss'
import { useTranslation } from 'hooks/useTranslation'
import ReportMissingItems from '../../widgets/HistoryOfEvents/OrderHistory/ReportMissingItems'
import { SingleComment } from 'types/api/commentApi/getOrderComments'
import capitalizeFirstLetters from 'utils/capitalizeFirstLetters'
import styles from './Comments.styles'
import { commentTagType, CommentView } from './CommentView'
import { WidgetSize } from 'types/widgets/historyOfEvents/tabs'

const { Text } = Typography

const useStyles = createUseStyles(styles)

type Props = {
  comment: SingleComment
  shouldCommentBeHighlighted: boolean
  mode: WidgetSize
}

const MissingItemsComment = ({ comment, shouldCommentBeHighlighted, mode }: Props) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <CommentView
      mode={mode}
      comment={comment}
      shouldCommentBeHighlighted={shouldCommentBeHighlighted}
      tag={{
        type: commentTagType.thunderbolt,
        text: t('History of Events Widget.Event Types.Report Missing Items'),
      }}
    >
      <Text className={classes.commentText}>
        <Text>{` ${t('History of Events Widget.Content.Reported')} `}</Text>
        <ReportMissingItems report_missing_items={comment?.additional_data?.report_missing_items} />
        <Text>{`${t('History of Events Widget.Content.Wastage Case ID')}: `}</Text>
        <Text strong>{`${comment.additional_data.report_missing_items.sf_case_id}. `}</Text>
        <span>
          {comment?.additional_data?.report_missing_items?.contact_reason &&
            `${t('Actions Widget.Actions.Compensation.Issue Type')}: ${capitalizeFirstLetters(
              comment.additional_data.report_missing_items.contact_reason.replace(/_/g, ' '),
            )}.`}
        </span>
      </Text>
    </CommentView>
  )
}

export default MissingItemsComment
