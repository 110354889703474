import React, { createContext } from 'react'
import { ApiContextProps, ApiProviderProps } from './types'

export const ApiContext = createContext<ApiContextProps>({})

const ApiProvider: React.FC<ApiProviderProps> = ({ children, ...props }) => {
  return <ApiContext.Provider value={props}>{children}</ApiContext.Provider>
}

export default ApiProvider
