export const SELECT_COMP_ISSUE_TYPE_ID = 'select_comp_issue_type'

export const SELECT_COMP_METHOD_ID = 'select_comp_method'

export const COMP_ISSUE_TYPE_MENU_ID = 'comp_issue_type_menu'

export const COMP_METHOD_MENU_ID = 'comp_method_menu'

export const SHOW_COMP_VALUE_EDITOR = 'show_comp_value_editor'

export const EDIT_COMP_VALUE_ID = 'edit_comp_value'

export const COMP_VALUE_ID = 'comp_value'

export const COMP_VALUE_INPUT_ID = 'comp_value_input'

export const COMP_VALUE_EDIT_MSG_ID = 'comp_value_edit_msg'

export const COMP_MOV_ID = 'comp_mov'

export const CREATE_COMP_ID = 'create_comp'

export const COMP_CONFIRMATION_SCREEN_ID = 'COMP_CONFIRMATION_SCREEN_ID'

export const COMP_VOUCHER_VALIDITY_ID = 'COMP_VOUCHER_VALIDITY'
