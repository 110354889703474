import React, { useContext, useMemo } from 'react'

// Components
import { Tag } from 'antd'

// Context
import { DataContext } from 'contexts/data/DataContext'
import { useGetOrderStatusMetadata } from 'hooks/dataTransformers/useGetOrderStatusMetadata'

function OrderStatusTag() {
  const {
    dataState: { order },
  } = useContext(DataContext)

  const getOrderStatusMetadata = useGetOrderStatusMetadata()
  const { statusColor, statusText } = useMemo(
    () => getOrderStatusMetadata(order),
    [order, getOrderStatusMetadata],
  )

  return <Tag color={statusColor}>{statusText}</Tag>
}

export default OrderStatusTag
