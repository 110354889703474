export default {
  flex: {
    flex: 1,
  },
  container: {
    display: 'flex',
  },
  iconsAndLineContainerWithNew: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '20px',
    paddingTop: '2px',
    marginRight: '5px',
  },
  iconsAndLineContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '20px',
    paddingTop: '2px',
    marginRight: '5px',
  },
  inputDeliveryAddressIcon: {
    width: '13px',
    height: '13px',
  },
  lineHolder: {
    margin: '3px 0px 3px 0px',
    height: '88px',
    borderLeft: '1px solid #bfbfbf',
  },
  currentAndNewAddressHolder: {},
  inputTitleHolder: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '2px',
  },
  inputTitleHolderForNewCustomerService: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '2px',
    marginTop: '8px',
  },
  inputTitleHolderForNewRiderService: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '2px',
    marginTop: '8px',
  },
  deliveryAddressText: {
    fontSize: '13px',
    color: 'rgba(0, 0, 0, 0.45)',
  },
  currentDeliveryAddress: {
    fontSize: '14px',
    color: 'rgba(0, 0, 0, 0.85)',
  },
  currentAddressHolder: {
    height: '75px',
    minHeight: '75px',
    overflowY: 'scroll',
    marginLeft: '1px',
  },
  distanceHolder: {
    margin: '20px 0px 0px 1px',
  },
  distanceText: {
    fontSize: '11px',
    color: '#2A8FF7',
  },
  inputWrapper: {
    marginLeft: -18,
    marginTop: 4,
  },
  input: {
    minWidth: 240,
  },
  xButton: {
    color: 'rgba(0, 0, 0, 0.25)',
  },
  suggestionsHolder: {
    position: 'absolute',
    zIndex: 10,
    backgroundColor: '#fff',
    width: '48%',
    minWidth: 235,
    boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.15)',
    borderRadius: '2px',
    marginLeft: -15,
  },
  suggestionsUl: {
    listStyleType: 'none',
    margin: 0,
    padding: 0,
  },
  suggestionLi: {
    padding: '3px',
  },
  suggestionLiActive: {
    padding: '3px',
    backgroundColor: '#F6FFED',
    cursor: 'pointer',
  },
  suggestionText: {
    fontSize: '13px',
    color: '#000000',
    opacity: 0.65,
  },
}
