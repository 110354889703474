import React from 'react'
import fixNumber from 'utils/fixNumber'
import { Alert, Button, Result, Typography } from 'antd'
import { useTranslation } from 'hooks/useTranslation'
import { ExclamationCircleOutlined, CheckOutlined, CopyOutlined } from '@ant-design/icons'
import captureUserAction from 'browserMonitoring/captureUserAction'
import { createUseStyles } from 'react-jss'
import styles from '../FullRefundModalView.styles'
import VoucherApiSingleVoucher from 'types/api/voucherApi/getSingleVoucher'

const Text = Typography
const useStyles = createUseStyles(styles)

type Props = {
  onClose: () => void
  currency: string
  voucherAmount: number
  totalRefundAmount: number
  copyVoucher: (voucher: VoucherApiSingleVoucher, currency: string) => void
  createdVoucher: VoucherApiSingleVoucher
}

const BankRefundResponse = ({
  onClose,
  currency,
  voucherAmount,
  copyVoucher,
  createdVoucher,
  totalRefundAmount,
}: Props) => {
  const classes = useStyles()
  const { t } = useTranslation()
  return (
    <Result
      status={'success'}
      title={`${t('Widgets Common.Success')}!`}
      subTitle={
        <div className={classes.resultSubtextHolder}>
          <Text className={classes.subtitle}>
            <ExclamationCircleOutlined />{' '}
            <span>{t('Actions Widget.Actions.Full Refund.Nothing refunded yet')}</span>
          </Text>
          <Text className={classes.subtitle}>
            <CheckOutlined /> {t('Actions Widget.Actions.Full Refund.Refund case created for')}{' '}
            <b>
              {currency} {fixNumber(totalRefundAmount)}
            </b>
          </Text>
          {createdVoucher?.code && (
            <>
              <Text className={classes.subtitle}>
                <CheckOutlined /> {t('Actions Widget.Actions.Full Refund.Compensated')}{' '}
                <b>
                  {currency} {fixNumber(voucherAmount)}{' '}
                </b>
                {t('Actions Widget.Actions.Full Refund.with voucher')}
              </Text>
              <div className={classes.successfulVoucherContainer}>
                <Text className={classes.resultVoucherCode}>
                  {`${t('Widgets Common.Voucher Code')}: ${createdVoucher.code}`}
                </Text>

                <Text className={classes.sectionCopyText}>
                  <Button type='link' onClick={() => copyVoucher(createdVoucher, currency)}>
                    {t('Actions Widget.Actions.Full Refund.Copy Voucher Info')}
                    <CopyOutlined />
                  </Button>
                </Text>

                <Alert
                  className={classes.successfulVoucherAlert}
                  message={t(
                    'Actions Widget.Actions.Full Refund.The customer will receive the voucher immediately',
                  )}
                  type='info'
                  showIcon
                />
              </div>
            </>
          )}
          <Alert
            className={classes.successfulVoucherAlert}
            message={t(
              "Messages.Refund timeframe depends on number of cases in refund queue and customer's financial provider",
            )}
            type='info'
            showIcon
          />
          <div className={classes.successButtonContainer}>
            <Button
              type='primary'
              onClick={() => {
                captureUserAction('ActionsFullRefundOkButtonClicked', [])
                onClose()
              }}
            >
              {t('Interface.OK')}
            </Button>
          </div>
        </div>
      }
    />
  )
}

export default BankRefundResponse
