export default {
  chatTranscriptHolder: {
    minWidth: '200px',
    margin: '0px 2% 30px 1%',
  },
  chatTranscriptButton: {
    fontSize: '14px',
  },
  chatTranscriptIcon: {
    fontSize: '17px',
  },
  divider: {},
  modal: {
    display: 'flex',
    flexDirection: 'column',
    width: 300,
    minWidth: 300,
    height: '600px',
    maxHeight: '600px',
    overflowY: 'auto',
    paddingBottom: 0,
    '& .ant-modal-content': {
      position: 'absolute',
      top: 0,
      bottom: 0,
      right: 0,
      left: 0,
      overflowY: 'auto',
    },
  },
  titleSeparator: {
    marginTop: 20,
  },
  translationsButtonHolder: {
    marginLeft: -18,
  },
  titleHolder: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#1295e6',
    padding: 20,
    margin: -24,
  },
  modalTitle: {
    fontSize: '16px',
    fontWeight: 400,
    color: '#fff',
    marginTop: '7px',
  },
}
