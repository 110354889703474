import { VendorApiResponse } from 'types/api/vendorApi/vendor'

function transformVendor(vendor: VendorApiResponse) {
  const transformedVendor = {
    vendorId: vendor.vendor_id || '',
    vendorName: vendor.name || '',
    vendorAddress: vendor.location?.address_text || '',
    vendorPhone: vendor.contact?.phone || '',
    vendorVerticalType: vendor.vertical_type || '',
    isVendorActive: vendor.active || false,
    vendorFrontend: vendor.vendor_detail_page_slug || '',
    location: vendor.location,
  }

  return transformedVendor
}

export default transformVendor
