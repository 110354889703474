import { ApiService } from 'services/authApi/getPermissions'
import {
  PostItemRemovalBodySingleItem,
  EffectiveRefundTarget,
  RefundTarget,
  RefundBreakdown,
} from 'types/actions/partialRefund'

export const createRefund: ApiService<
  {
    entityId: string
    orderId: string
    description: string
    amount: number
    currency: string
    target: RefundTarget
    refundItems: string[]
    contactId: string
    contactReason: string
    removedItems: PostItemRemovalBodySingleItem[]
    withItemRemoval: boolean
    payment_medium: string
    additional_notes: string
    refundPurpose: string
  },
  {
    amount: {
      currency: string
      amount: number
    }
    voucherCode?: string
    effectiveRefundTarget: EffectiveRefundTarget

    refundBreakDown: RefundBreakdown[]

    withItemRemoval: boolean
  }
> = (createClient, options) => {
  const {
    entityId,
    orderId,
    description,
    amount,
    currency,
    target,
    refundItems,
    contactId,
    contactReason,
    removedItems,
    clientParams,
    payment_medium,
    additional_notes,
    withItemRemoval = false,
    refundPurpose = 'item_refund',
  } = options

  const body = {
    description,
    money: {
      amount,
      currency,
    },
    target,
    refundPurpose,
    refundItems,
    contactId,
    contact_reason: contactReason,
    removedItems,
    withItemRemoval,
    payment_medium,
    additional_notes,
  }

  // client details
  const uri = `/PaymentAPI/v1/order/${entityId}/${orderId}/refund`

  return createClient({
    tParams: {
      method: target,
    },
    endpointName: 'createRefund',
    expectedResponseStatusCode: [201, 202],
    ...clientParams,
  }).post(uri, body)
}
