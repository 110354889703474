/** Hook to filter  comments based on given time filter   */
import { useMemo } from 'react'
import {
  SingleComment,
  SingleCommentAction,
  ModifyCallBackStatuses,
} from 'types/api/commentApi/getOrderComments'
import { AvailableFilterTimeFrames } from 'types/widgets/historyOfEvents/availableCommentActionsTypes'

//utils
import compareDateWithNow from 'utils/compareDateWithNow'

export const useFilteredComments = (comments: SingleComment[], timeFrame: string) => {
  // comments that are displayed -> initially all comments and changes upon filter selection
  const filteredCommentsMemoized = useMemo(() => {
    let filteredComments = []
    if (Array.isArray(comments)) {
      filteredComments = comments.filter((comment: SingleComment) => {
        if (comment?.action === SingleCommentAction.modify_callback) {
          return (
            comment?.additional_data?.modify_callback?.status !== ModifyCallBackStatuses.MODIFIED &&
            comment?.additional_data?.modify_callback?.status !==
              ModifyCallBackStatuses.CONFIRMED &&
            comment?.additional_data?.modify_callback?.status !== ModifyCallBackStatuses.REQUESTED
          )
        }
        return comment
      })
      filteredComments.sort((a, b) => -a.created_at.localeCompare(b.created_at))

      if (!Boolean(timeFrame)) return filteredComments

      switch (timeFrame) {
        case AvailableFilterTimeFrames.last_seven:
          filteredComments = filteredComments?.filter((comment: SingleComment) =>
            compareDateWithNow(comment.created_at, 168),
          )
          break

        case AvailableFilterTimeFrames.last_fourteen:
          filteredComments = filteredComments?.filter((comment: SingleComment) =>
            compareDateWithNow(comment.created_at, 336),
          )
          break

        case AvailableFilterTimeFrames.last_thirty:
          filteredComments = filteredComments?.filter((comment: SingleComment) =>
            compareDateWithNow(comment.created_at, 720),
          )
          break

        case AvailableFilterTimeFrames.all:
          break

        default:
          break
      }
    }

    return filteredComments
  }, [comments, timeFrame])
  return filteredCommentsMemoized
}
