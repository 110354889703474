import { ApiService } from 'services/authApi/getPermissions'

export interface VendorSummaryResponse {
  global_vendor_id?: string
  invoice_frequency?: string
  name?: string
  parent_grid?: string
  parent_name?: string
  phone?: string
  status?: string
  vendor_grade?: string
  vendor_type?: string
  vertical?: string
  vertical_segment?: string
  website?: string
}

export const getVendorSummary: ApiService<
  { globalEntityId: string; globalVendorId: string },
  VendorSummaryResponse
> = (createClient, options) => {
  const { globalEntityId, clientParams, globalVendorId } = options
  const uri = `/VendorAPI/v1/vendors/${globalEntityId}/${globalVendorId}/summary`

  return createClient({
    endpointName: 'getVendorSummary',
    expectedResponseStatusCode: 200,
    ...clientParams,
  }).get<VendorSummaryResponse>(uri)
}
