import { Col, Row } from 'antd'
import React, { useMemo, useState } from 'react'
import { useTranslation } from 'hooks/useTranslation'
import { Button } from 'shared/Button'
import { OrderApiResponse } from 'types/herocare'
import { useRiderHistoryTimeline } from 'hooks/dataTransformers/useRiderHistoryTimeline'
import { DeliveryItem, FulfillmentApiResponse } from 'types/api/fulfillmentApi/fulfillment'
import { HistoryMap } from '../HistoryMap/HistoryMap'
import { HistoryTimeline } from '../HistoryTimeline/HistoryTimeline'
import { RiderDetails } from '../RiderDetails/RiderDetails'
import { useHistoryStyles } from '../History.styles'
import { ActionHandler } from 'types/unitedUiConfig'
import { ActionHandlerButtonProps } from 'shared/ActionHandlerButton'
import { WidgetActionHandlers } from 'shared/WidgetActionHandlers'
import { BuiltinWidgetConfigs, SDK } from 'types'
import { PlaceholderDeliveryMap } from '../HistoryMap/PlaceholderDeliveryMap'
import { useGetRiderStackedOrderCount } from 'hooks/useGetRiderStackedOrderCount'

type Props = {
  sdk: SDK
  delivery: DeliveryItem
  order: OrderApiResponse
  isMapVisible: boolean
  onViewMap: () => void
  config: BuiltinWidgetConfigs['order_deliveries']['riders_history_tab']
  fulfillment: FulfillmentApiResponse
}

const RiderHistory = ({
  sdk,
  order,
  fulfillment,
  config,
  delivery,
  isMapVisible,
  onViewMap,
}: Props) => {
  const { t } = useTranslation()
  const classes = useHistoryStyles()
  const [showAll, setShowAll] = useState(false)
  const timeline = useRiderHistoryTimeline({ sdk, order, fulfillment, delivery })

  const handleShowAllClick = () => {
    setShowAll((value) => !value)
  }

  const minShowTimelineItems = 5

  const currentRiderStackedOrdersCount = useGetRiderStackedOrderCount(delivery)

  // Show count of current rider's stacked orders as prefix to label of an action button. (Only for action button associated with Stacked order action )
  const actionHandlers: (ActionHandler & Pick<ActionHandlerButtonProps, 'label'>)[] =
    useMemo(() => {
      return config.action_handlers.map((action) => {
        if (action?.action?.label_translation_key === 'action_labels.rider_stacked_orders') {
          const prefix = currentRiderStackedOrdersCount
          const labelTranslation = t(action?.action.label_translation_key) ?? ''
          return {
            ...action,
            label: `${prefix} ${labelTranslation}`.trim(),
          }
        }
        return action
      })
    }, [config.action_handlers, currentRiderStackedOrdersCount, t])

  return (
    <div className={classes.container}>
      {Boolean(actionHandlers.length) && (
        <WidgetActionHandlers
          marginTop={0}
          actionHandlers={actionHandlers}
          activationSubjects={{
            delivery,
          }}
        />
      )}
      <RiderDetails
        order={order}
        delivery={delivery}
        fulfillment={fulfillment}
        data_points={config.data_points}
        sdk={sdk}
      />
      <Row justify='center' className={classes.timeline}>
        <Col span={14}>
          <HistoryTimeline
            timeline={timeline}
            showAll={showAll}
            minItemsVisible={minShowTimelineItems}
          />
          <Row justify='center'>
            <Col span={14}>
              {timeline.length > minShowTimelineItems && (
                <Button type='link' onClick={handleShowAllClick}>
                  {!showAll
                    ? t('action_labels.show_all_statuses')
                    : t('action_labels.hide_statuses')}
                </Button>
              )}
            </Col>
          </Row>
        </Col>
        <Col span={10}>
          {isMapVisible ? (
            <HistoryMap order={order} fulfillment={fulfillment} delivery={delivery} />
          ) : (
            <PlaceholderDeliveryMap canViewMap={Boolean(fulfillment)} onViewMap={onViewMap} />
          )}
        </Col>
      </Row>
    </div>
  )
}

export default RiderHistory
