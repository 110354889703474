export default {
  footer: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    marginTop: '40px',
  },
  cancelButton: {
    marginRight: '5px',
  },
}
