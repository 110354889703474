export default {
  container: {
    display: 'flex',
  },
  innerContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
  spinContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  buttonContainer: {
    paddingLeft: (props) => (props.paddingOffset ? 0 : 15),
    display: 'inline-block',
  },
  buttonContentContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    fontSize: '16px',
    marginRight: '7px',
  },
}
