import { herocareLangTable, salesforceLangTable } from 'constants/constants'
import { Host, Language, TabName, UiVersion } from 'types'
import { LinesOfBusiness } from 'types/session/linesOfBusiness'

export type SearchParamKey =
  // entity ids
  | 'globalEntityId'
  | 'orderId'
  | 'caseId'
  | 'sessionId'
  | 'lang'

  // vendor ids
  | 'globalVendorId'
  | 'vendorId'
  | 'riderId'
  | 'userId'
  | 'lob'
  | 'testError'

  // ccr codes
  | 'ccr1'
  | 'ccr2'
  | 'ccr3'
  | 'ccrCode'
  | 'host'
  | 'uiVersion'
  | 'tab'

  // cli
  | 'cliSso'
  | 'cliSsoPort'
  | 'e2e' // widget container strategy

class SearchParams {
  private _params: URLSearchParams

  constructor(searchString?: string) {
    let resolvedSearchString = searchString || window?.location?.search || ''
    if (resolvedSearchString.indexOf('?') > -1) {
      resolvedSearchString = resolvedSearchString.split('?').pop()
    }
    this._params = new URLSearchParams(resolvedSearchString)
  }

  public get<T extends string = string>(key: SearchParamKey, defaultValue: T = '' as T): T {
    const value = this._params.get(key)?.trim() as T
    if (value && !['0', 'null', 'undefined'].includes(value.toString().toLowerCase())) {
      return value
    }
    return defaultValue
  }

  public has(key: SearchParamKey) {
    return this._params.has(key)
  }

  public getGlobalEntityId() {
    return this.get('globalEntityId')
  }

  public getSessionId() {
    return this.get('sessionId')
  }

  /**
   * resolves the language and returns it
   */
  public getLang(): Language {
    const lang = this.get('lang')
    if (typeof herocareLangTable[lang] !== 'undefined') {
      return herocareLangTable[lang]
    }

    if (typeof salesforceLangTable[lang] !== 'undefined') {
      return salesforceLangTable[lang]
    }

    return (lang as Language) || 'en'
  }

  public getCaseId() {
    return this.get('caseId')
  }

  public getOrderId() {
    return this.get('orderId')
  }
  public getUserId() {
    return this.get('userId')
  }
  public getRiderId() {
    return this.get('riderId')
  }

  public getLineOfBusiness() {
    switch (this.get('lob')) {
      case LinesOfBusiness.vendor:
        return LinesOfBusiness.vendor
      case LinesOfBusiness.rider:
        return LinesOfBusiness.rider
      case LinesOfBusiness.riderV2:
        return LinesOfBusiness.riderV2

      default:
        return LinesOfBusiness.customer
    }
  }

  public getGlobalVendorId() {
    return this.get('globalVendorId')
  }
  public getVendorId() {
    return this.get('vendorId')
  }

  public getCcr1() {
    return this.get('ccr1')
  }
  public getCcr2() {
    return this.get('ccr2')
  }
  public getCcr3() {
    return this.get('ccr3')
  }
  public getCcrCode() {
    return this.get('ccrCode')
  }

  public isCliSso() {
    return this.get('cliSso') === 'true'
  }

  public getCliSsoPort() {
    return this.get('cliSsoPort') || '3001'
  }

  public getHost() {
    return this.get<Host>('host')
  }

  public getUiVersion() {
    const version = this.get<UiVersion>('uiVersion')
    switch (version) {
      case 'v1':
      case 'v2':
        return version

      default:
        return null
    }
  }

  public getActiveTab() {
    return this.get<TabName>('tab') || null
  }
}

export const getSearchParams = (searchString?: string) => new SearchParams(searchString)

export const constructUrl = (options: {
  /**
   * parameters to include or override if it exists
   */
  params?: Partial<Record<SearchParamKey, string | number>>

  /**
   * parameters to remove in new url
   */
  remove?: SearchParamKey[]
}) => {
  const params = new URLSearchParams(window.location.search)

  const { params: newParams = {}, remove = [] } = options

  remove.forEach((key) => params.delete(key))

  Object.keys(newParams).forEach((key) => {
    const value = newParams[key]
    if (value !== null && value !== undefined && value !== '') {
      const sanitizedValue = encodeURIComponent(value.toString())
      params.set(key, sanitizedValue)
    }
  })

  const searchParams = params.toString()
  const url = `${window.location.protocol}//${window.location.host}${window.location.pathname}`

  if (!searchParams) {
    return url
  }

  return `${url}?${searchParams}`
}

export const replaceUrl = (options: Parameters<typeof constructUrl>[0]) => {
  window.location.replace(constructUrl(options))
}
