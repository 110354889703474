/**
 * holder for customer voucher tags -> each tag contains detailed info in tooltip
 * */

// libs
import React, { useMemo } from 'react'
// contexts and types
import { Statuses } from 'types/dataTransformers/customerVouchers'
// hooks
import { useTranslation } from 'hooks/useTranslation'
//styles
import { Tooltip } from 'antd'

import styles from './VoucherDetails.styles'
import { createUseStyles } from 'react-jss'
import { VoucherStatusAndCount } from 'components/VoucherStatuses/VoucherStatuses'
import { Voucher } from 'types/api/voucherApi/voucher'

const useStyles = createUseStyles(styles)

const VoucherDetails: React.FC<{ vouchers: Voucher[] }> = ({ vouchers }) => {
  // pull data state and dispatch from data context
  const classes = useStyles()

  // pull language content
  const { t } = useTranslation()

  // calculate total issued, used, and applicable, expired and not applicable vouchers
  const [
    totalIssued = 0,
    totalApplicable = 0,
    totalUsed = 0,
    totalExpired = 0,
    totalNotApplicable = 0,
  ] = useMemo(() => {
    const usedAndApplicableVouchers = vouchers.reduce(
      (acc, cv) => {
        switch (cv.status) {
          case Statuses.applicable:
            acc.applicable++
            break
          case Statuses.used:
            acc.used++
            break
          case Statuses.expired:
            acc.expired++
            break
          case Statuses.notApplicable:
            acc.notApplicable++
            break
          default:
            break
        }
        return acc
      },
      { applicable: 0, used: 0, expired: 0, notApplicable: 0 },
    )

    return [
      vouchers.length,
      usedAndApplicableVouchers.applicable,
      usedAndApplicableVouchers.used,
      usedAndApplicableVouchers.expired,
      usedAndApplicableVouchers.notApplicable,
    ]
  }, [vouchers])

  return (
    <div className={classes.voucherCountContainer}>
      <Tooltip
        title={t('Customer Widget.Tabs.Vouchers.Total Number of Vouchers Issued to Customer')}
      >
        <span>{`${t('Customer Widget.Tabs.Vouchers.Total')} ${totalIssued} ${t(
          'Customer Widget.Tabs.Vouchers.issued',
        )}`}</span>
      </Tooltip>
      <Tooltip
        title={t('Customer Widget.Tabs.Vouchers.Number of Customer Vouchers Currently Applicable')}
      >
        <VoucherStatusAndCount status={Statuses.applicable} count={totalApplicable} />
      </Tooltip>
      <Tooltip title={t('Customer Widget.Tabs.Vouchers.Total Number of Vouchers Used by Customer')}>
        <VoucherStatusAndCount status={Statuses.used} count={totalUsed} />
      </Tooltip>
      <Tooltip title={t('Customer Widget.Tabs.Vouchers.Total Number of Expired Vouchers')}>
        <VoucherStatusAndCount status={Statuses.expired} count={totalExpired} />
      </Tooltip>

      {totalNotApplicable !== 0 && (
        <Tooltip
          title={t(
            'Customer Widget.Tabs.Vouchers.Number of Customer Vouchers Currently Not Applicable',
          )}
        >
          <VoucherStatusAndCount status={Statuses.notApplicable} count={totalNotApplicable} />
        </Tooltip>
      )}
    </div>
  )
}

export default VoucherDetails
