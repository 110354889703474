import { OrderHistory } from 'types/api/orderApi/order'

export const deduplicateStatusHistory = (history: OrderHistory): OrderHistory => {
  // sort the items in ascending order of datetime, that is, the earliest date comes first.
  const histories = [...history].reverse().sort((a, b) => {
    const dateA = new Date(a.timestamp)
    const dateB = new Date(b.timestamp)

    return dateA.getTime() - dateB.getTime()
  })

  return histories.reduce((result: typeof histories, current) => {
    if (result.length === 0) {
      result.push(current)
      return result
    }

    const lastIndex = result.length - 1
    const prev = result[lastIndex]

    if (prev.status !== current.status) {
      result.push(current)
    } else {
      result[lastIndex] = {
        ...current,
        timestamp: prev.timestamp,
      }
    }
    return result
  }, [])
}
