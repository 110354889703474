export default {
  lastOrdersHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '20px',
  },
  lastOrdersTitle: {
    fontSize: '20px',
    color: '#060607',
    fontWeight: 600,
    marginBottom: 0,
  },
}
