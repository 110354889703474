import { FileImageOutlined, LockOutlined } from '@ant-design/icons'
import { Image, Menu, Space } from 'antd'
import ChatTranscriptView from 'components/ChatTranscriptView'
import { Button } from 'shared/Button'
import { CopyButton } from 'shared/CopyButton'
import { CustomDataPointRenderProps, DataPointProps } from 'shared/DataPoint'
import { Link } from 'shared/Link'
import { PopupWidgetContainer } from 'shared/PopupWidgetContainer'
import { Text } from 'shared/Text'
import { UnifiedIcon } from 'shared/UnifiedIcon'
import { WidgetActionHandlers } from 'shared/WidgetActionHandlers'
import { useTranslation } from 'hooks/useTranslation'
import React, { useCallback, useMemo, useState } from 'react'
import { createUseStyles } from 'react-jss'
import { getRiderChats } from 'services/customerApi/getRiderChats'
import { getProofOfDelivery } from 'services/fulfillmentApi/getProofOfDelivery'
import { StatusColors, text } from 'theme'
import { BuiltinWidgetConfigs } from 'types/unitedUiConfig'
import { OrderStatuses } from 'types/widgets/order/orderStatuses'
import { POD_PIN_STATUS } from 'types/widgets/order/podPinStatus'
import { getOrderPaidValue } from 'utils/getters/getOrderPaidValue'
import { interpolate } from 'utils/string/interpolate'
import upperFirst from 'lodash/upperFirst'
import { logError } from 'utils/reporting/logError'
import { InlineDropdown } from 'shared/InlineDropdown'
import { createPluggableWidget } from 'factory/createPluggableWidget'
import { DataPoints } from 'shared'
import { getOrderTickets } from 'services/ticketApi/getOrderTickets'
import { CustomerTranscriptView } from 'components/CustomerTranscriptView/CustomerTranscriptView'
import { RiderChatsData } from 'types/api/riderApi/riderChats'
import { OrderTicketsResponse } from 'types/api/ticketApi/getOrderTickets'
import { getOrderDelayTime } from 'utils/getters/getOrderDelayTime'
import capitalizeFirstLetters from 'utils/capitalizeFirstLetters'
import { getOrderFlags } from 'services/ordersApi/getOrderFlags'

const useStyles = createUseStyles({
  headerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 32,
  },

  alignEnd: {
    alignItems: 'end',
    display: 'flex',
  },
})

export const SummaryV2 = createPluggableWidget<
  BuiltinWidgetConfigs['order_summary'] & {
    utcZone: string
  }
>(
  ({ order, vendor, globalEntityId, sdk, config, orderFulfillment, containerType }) => {
    const {
      data_points = [],
      action_handlers = [],
      context_menu = {
        menu_items: [],
      },
      utcZone,
    } = config
    const { t } = useTranslation()
    const styles = useStyles()

    const delayTime = getOrderDelayTime({
      order,
      fulfillment: orderFulfillment,
      datetimeFormatter: sdk.datetimeFormatter,
    })

    const {
      checkDisplayRules,
      captureUserAction,
      getOrderStatusMetadata,
      datetimeFormatter,
      numberFormatter,
      getPodPinStatus,
      getOrderPaymentMethodsTextsAndIcons,
      getOrderTags,
      createApiClient,
    } = sdk

    const [previewImage, setPreviewImage] = useState(false)

    // find the status history item with a status of 'ACCEPTED' AND an existing transmission object
    const statusItem = order.status_history?.find(
      (statusItem) => statusItem.status === 'ACCEPTED' && statusItem?.accepted?.transmission,
    )

    const getProofOfDeliveryImage = useCallback(() => {
      return getProofOfDelivery(createApiClient, {
        entityId: globalEntityId,
        orderId: order.order_id,
      }).then((res) => res.data.image_url)
    }, [globalEntityId, order.order_id, createApiClient])

    const getOrderPayoutStatus = useCallback(async () => {
      const { data: orderFlags } = await getOrderFlags(createApiClient, {
        entityId: globalEntityId,
        orderId: order.order_id,
      })

      if (orderFlags?.is_billable) {
        return {
          statusColor: StatusColors.green,
          statusText: t('Order Widget.Payable'),
        }
      } else {
        return {
          statusColor: StatusColors.red,
          statusText: t('Order Widget.Not Payable'),
        }
      }
    }, [createApiClient, globalEntityId, order.order_id, t])

    const getChatTranscripts = useCallback(() => {
      return getRiderChats(createApiClient, {
        entityId: globalEntityId,
        orderId: order.order_id,
      }).then((res) => res.data)
    }, [globalEntityId, order.order_id, createApiClient])

    const getCustomerChatList = useCallback(() => {
      return getOrderTickets(createApiClient, {
        entityId: globalEntityId,
        orderId: order.order_id,
        type: 'chat',
        department: 'CS',
      }).then((res) => res.data)
    }, [globalEntityId, order.order_id, createApiClient])

    const [showChatTranscripts, setShowChatTranscripts] = useState(false)
    const [showCustomerChatTranscripts, setShowCustomerChatTranscripts] = useState(false)

    const showContextMenu = checkDisplayRules({
      displayRules: context_menu.display_rules,
    }).visible

    const visibleContextMenuItems = context_menu.menu_items.filter((item) => {
      return checkDisplayRules({ displayRules: item.display_rules }).visible
    })

    const dataPoints = useMemo(() => {
      return data_points.map((dataPoint): DataPointProps => {
        const label = t(dataPoint.label_translation_key)

        const { display_rules, name } = dataPoint

        const orderStatusMetadata = getOrderStatusMetadata({ order })

        switch (name) {
          case 'vendor_name':
            return { type: 'default', value: order.vendor.name, label, name, display_rules }

          case 'created_time':
            return {
              type: 'default',
              value: datetimeFormatter.formatDateTime(order.place_timestamp),
              label,
              name,
              display_rules,
            }

          case 'order_status':
            return {
              type: 'status',
              value: {
                ...getOrderStatusMetadata({ order }),
                delayTime,
              },
              label,
              name,
              display_rules,
            }

          case 'amount_paid':
            return {
              type: 'default',
              value: numberFormatter.formatMoney({
                currency: order.currency,
                amount: getOrderPaidValue({ order }),
              }),
              label,
              name,
              display_rules,
            }

          case 'delivery_type':
            return {
              type: 'default',
              value: upperFirst(order.delivery?.provider || '').replace(/_/g, ' '),
              label,
              name,
              display_rules,
            }

          case 'vertical':
            return {
              type: 'default',
              value: upperFirst(vendor?.vertical_type),
              label,
              name,
              display_rules,
            }

          case 'proof_of_delivery':
            return {
              label,
              name,
              type: 'custom',
              loader: getProofOfDeliveryImage,
              display_rules,
              render: ({
                value: proofOfDeliveryImageUrl,
                setError,
              }: CustomDataPointRenderProps<string>) => {
                return (
                  <>
                    <Image
                      src={proofOfDeliveryImageUrl}
                      width={0}
                      preview={{
                        visible: previewImage,
                        onVisibleChange: (visible) => setPreviewImage(visible),
                      }}
                      onError={() => setError('failed_to_load')}
                    />
                    <Button type='link' onClick={() => setPreviewImage(true)} paddingLeft={0}>
                      <Space size={2}>
                        <FileImageOutlined /> {t(`action_labels.view_image`)}
                      </Space>
                    </Button>
                  </>
                )
              },
            }

          case 'proof_of_delivery_pin':
            const status = getPodPinStatus({ order })

            switch (status) {
              case POD_PIN_STATUS.notRequired:
                return {
                  type: 'default',
                  value: t('Order Widget.Tabs.Summary.Proof of Delivery PIN.Not required'),
                  label,
                  name,
                  display_rules,
                }

              case POD_PIN_STATUS.pendingDelivery:
                return {
                  type: 'default',
                  value: t('Order Widget.Tabs.Summary.Proof of Delivery PIN.Pending delivery'),
                  label,
                  name,
                  display_rules,
                }

              case POD_PIN_STATUS.validated:
                return {
                  type: 'custom',
                  render: (
                    <Space size={8}>
                      <LockOutlined />
                      {t('Order Widget.Tabs.Summary.Proof of Delivery PIN.PIN validated')}
                    </Space>
                  ),
                  label,
                  name,
                  display_rules,
                }

              default:
                return {
                  type: 'default',
                  value: t(`errors.not_available.title`),
                  label,
                  name,
                  display_rules,
                }
            }

          case 'chat_transcript':
            return {
              label,
              name,
              display_rules,
              type: 'custom',
              loader: getChatTranscripts,
              render: ({ value: chatTranscripts }: CustomDataPointRenderProps<RiderChatsData>) => {
                if (!chatTranscripts.events.length) {
                  return t(`errors.not_available.title`)
                }
                return (
                  <>
                    <Button
                      type='link'
                      paddingLeft={0}
                      onClick={() => {
                        captureUserAction('OrderTabViewChatTransriptClicked')
                        setShowChatTranscripts(true)
                      }}
                    >
                      {t(`action_labels.view_chat_transcript`)}
                    </Button>

                    {showChatTranscripts && (
                      <PopupWidgetContainer
                        visible
                        title={t('widget_labels.chat_transcript')}
                        onClose={() => setShowChatTranscripts(false)}
                        widgetContainerType={containerType}
                      >
                        <ChatTranscriptView
                          chatTranscripts={chatTranscripts}
                          utcZone={utcZone}
                          orderId={order.order_id}
                          isRiderService={false}
                        />
                      </PopupWidgetContainer>
                    )}
                  </>
                )
              },
            }

          case 'customer_chat_transcript':
            return {
              label,
              name,
              display_rules,
              type: 'custom',
              loader: getCustomerChatList,
              render: ({
                value: customerChatList,
              }: CustomDataPointRenderProps<OrderTicketsResponse>) => {
                if (!customerChatList.list.length) {
                  return t(`errors.not_available.title`)
                }
                return (
                  <>
                    <Button
                      type='link'
                      paddingLeft={0}
                      onClick={() => {
                        captureUserAction('OPEN_LIST_CUSTOMER_AGENT_TRANSCRIPT')
                        setShowCustomerChatTranscripts(true)
                      }}
                    >
                      {t(`action_labels.view_chat_transcript`)}
                    </Button>

                    {showCustomerChatTranscripts && (
                      <PopupWidgetContainer
                        visible
                        title={t('widget_labels.customer_chat_transcript')}
                        onClose={() => setShowCustomerChatTranscripts(false)}
                        widgetContainerType='drawer'
                      >
                        <CustomerTranscriptView
                          chatList={customerChatList?.list}
                          orderId={order.order_id}
                        />
                      </PopupWidgetContainer>
                    )}
                  </>
                )
              },
            }

          case 'payment_failure_reason':
            if (orderStatusMetadata.status.status !== OrderStatuses.pendingOrderFailed) {
              return null
            }

            return {
              type: 'reason',
              value: orderStatusMetadata.reason,
              label,
              name,
              display_rules,
              color: text.danger,
            }

          case 'payment_method':
            return {
              type: 'payment_methods',
              value: getOrderPaymentMethodsTextsAndIcons({
                order,
              }),
              label,
              name,
              display_rules,
            }

          case 'country':
            return {
              type: 'country',
              value: globalEntityId,
              label,
              name,
              display_rules,
            }

          case 'payout_status':
            return {
              type: 'status',
              value: null,
              label,
              name,
              display_rules,
              loader: getOrderPayoutStatus,
            }

          case 'tags':
            return {
              type: 'tags',
              label,
              name,
              value: getOrderTags({ order, fulfillment: orderFulfillment }),
              display_rules,
            }

          case 'order_source':
            return {
              type: 'custom',
              label,
              name,
              render: (
                <Text.Primary>
                  <div>{statusItem?.accepted?.source ?? null}</div>
                  <div>{statusItem?.accepted?.transmission?.provider ?? null}</div>
                  <div>{statusItem?.accepted?.transmission?.method ?? null}</div>
                  <div>{statusItem?.accepted?.transmission?.application_client ?? null}</div>
                </Text.Primary>
              ),
              display_rules,
            }

          case 'brand_name':
            return {
              type: 'default',
              name,
              value: upperFirst(order?.brand_name),
              label,
              display_rules,
            }

          default:
            logError({
              type: 'missing-datapoint-handler',
              originalError: new Error(
                `${name} is configured as a dataPoint yet doesn't have a handling case`,
              ),
            })
            return null
        }
      })
    }, [
      data_points,
      t,
      getOrderStatusMetadata,
      order,
      datetimeFormatter,
      numberFormatter,
      vendor?.vertical_type,
      getOrderPaymentMethodsTextsAndIcons,
      globalEntityId,
      getOrderPayoutStatus,
      getOrderTags,
      orderFulfillment,
      delayTime,
      statusItem?.accepted?.source,
      statusItem?.accepted?.transmission?.provider,
      statusItem?.accepted?.transmission?.method,
      statusItem?.accepted?.transmission?.application_client,
      previewImage,
      captureUserAction,
      getPodPinStatus,
      getProofOfDeliveryImage,
      getChatTranscripts,
      getCustomerChatList,
      showCustomerChatTranscripts,
      containerType,
      showChatTranscripts,
      utcZone,
    ])

    const handleCopyOrderId = () => {
      captureUserAction('COPY_ORDER_ID')
    }

    return (
      <div>
        <div className={styles.headerContainer}>
          <div>
            <Text.Secondary display='block'>{t('widgets.order_summary.order')}</Text.Secondary>
            <Space className={styles.alignEnd}>
              {/* order id */}
              <Text.Primary fontSize={20} lineHeight='28px' fontWeight='500'>
                {order.order_id}
              </Text.Primary>

              {/* copy button */}
              <CopyButton text={order.order_id} onCopy={handleCopyOrderId} />

              {/* order number */}
              {order.ov_meta_data?.order_number && (
                <Text.Primary color={text.secondary}>
                  #{order.ov_meta_data.order_number}
                </Text.Primary>
              )}
            </Space>
          </div>

          {/* more button */}
          {showContextMenu && visibleContextMenuItems.length > 0 && (
            <div>
              <InlineDropdown
                onVisibleChange={(open) => open && sdk.captureUserAction('OPEN_EXT_LINKS_LIST')}
                overlay={
                  <Menu>
                    {visibleContextMenuItems.map((current) => {
                      const url = interpolate(
                        current.link,
                        {
                          orderId: order.order_id,
                        },
                        { format: '{' },
                      )
                      return (
                        <Menu.Item key={current.label_translation_key}>
                          <Link
                            href={url}
                            target='_blank'
                            rel='noreferer'
                            onClick={() =>
                              sdk.captureUserAction('OPEN_EXT_LINK', {
                                eventDetails: {
                                  link: capitalizeFirstLetters(
                                    current.label_translation_key.substring(
                                      'action_labels.view_in_'.length,
                                    ),
                                  ),
                                },
                              })
                            }
                          >
                            <Space>
                              <UnifiedIcon icon='ExportIcon' size={12} />
                              {t(current.label_translation_key)}
                            </Space>
                          </Link>
                        </Menu.Item>
                      )
                    })}
                  </Menu>
                }
                trigger={['click']}
              >
                <Button type='link' icon='MoreOutlined' iconSize={20} />
              </InlineDropdown>
            </div>
          )}
        </div>

        {/* dataPoints */}
        <DataPoints dataPoints={dataPoints} />

        {/* action handlers */}
        <WidgetActionHandlers actionHandlers={action_handlers || []} />
      </div>
    )
  },
  {
    category: 'data_lookup',
    deriveConfig({ entityConfig }) {
      return {
        ...entityConfig.layout_v2.builtin_widgets_configs.order_summary,
        utcZone: entityConfig.utc_zone,
      }
    },
    deriveSubjectsRequirements() {
      return {
        all_of: ['order'],
      }
    },
  },
)
