export default {
  summaryContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  listsContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
  },
  listHolder: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    width: '44%',
    minWidth: '200px',
    marginRight: '2%',
  },
  clipboardButton: {
    backgroundColor: '#fff',
    width: '55px',
    color: '#1e76bd',
    fontSize: '11px',
    border: '1px solid #c9c7c7',
    borderRadius: '4px',
    marginLeft: '10px',
  },
  list: {
    '& .ant-list-item-meta-content': {
      display: 'flex',
      flexDirection: 'column-reverse',
    },
    '& .ant-list-item': {
      border: 'none',
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
}
