import { MicrobasePhoneDecorator } from 'components/PhoneDecorators/MicrobasePhoneDecorator'
import { TelPhoneDecorator } from 'components/PhoneDecorators/TelPhoneDecorator'
import { EntityContext } from 'contexts/entity/EntityContext'
import { PhoneDecorators } from 'entityConfig/allowedConfigValues'
import { useIsDataPointValid } from 'hooks/useGetValidFeatures'
import React, { useContext, MouseEvent } from 'react'
import { createUseStyles } from 'react-jss'
import { TransformedDataPoint } from 'types/dataTransformers/generic'
import styles from './PhoneNumber.styles'
import { useSessionState } from '../../hooks/useSessionState'

const useStyles = createUseStyles<any, { onlyIcon: boolean }>(styles)

interface Props {
  linkTarget?: string
  value: TransformedDataPoint['value']
  onDialClick?: (e: MouseEvent<HTMLAnchorElement>) => void
  onlyIcon?: boolean
}

const PhoneNumber: React.FC<Props> = ({ linkTarget = '_parent', value, onDialClick, onlyIcon }) => {
  const classes = useStyles({ onlyIcon })
  const {
    entityState: {
      entityConfig: { phoneDecorator },
    },
  } = useContext(EntityContext)

  const { caseId } = useSessionState()

  const isDataPointValid = useIsDataPointValid()

  const isBetaRequirementGranted = isDataPointValid(phoneDecorator.betaRequirement)

  if (isBetaRequirementGranted) {
    switch (phoneDecorator.decorator) {
      case PhoneDecorators.mircobase:
        return (
          <span>
            <MicrobasePhoneDecorator
              onlyIcon={onlyIcon}
              phoneNumber={value as string}
              onClick={onDialClick}
              className={classes.phoneNumber}
              linkTarget={linkTarget}
            />
          </span>
        )

      case PhoneDecorators.telLink:
        return (
          <span>
            <TelPhoneDecorator
              caseId={caseId}
              onlyIcon={onlyIcon}
              phoneNumber={value as string}
              onClick={onDialClick}
              className={classes.phoneNumber}
              linkTarget={linkTarget}
            />
          </span>
        )

      case PhoneDecorators.standard:
        return (
          <span>
            <TelPhoneDecorator
              caseId={null} // deliberately make caseId null
              onlyIcon={onlyIcon}
              phoneNumber={value as string}
              onClick={onDialClick}
              className={classes.phoneNumber}
              linkTarget={linkTarget}
            />
          </span>
        )
    }
  }

  if (onlyIcon) return null

  return <span data-cy='phoneNumber'>{value}</span>
}

export default PhoneNumber
