import { datadogRum, RumResourceEvent } from '@datadog/browser-rum'
import { datadogLogs } from '@datadog/browser-logs'
import environment from '../envConfig'
import { getSearchParams } from 'utils/getSearchParams'
import { readAuthPayload } from 'utils/authHelpers'
import getPlatformByGei from 'utils/getPlatformByGei'
import { effectiveLob } from 'constants/lineOfBusiness'

interface DatadogInitiator<TStartArgs, TReturn = unknown> {
  init: (args: TStartArgs) => TReturn
}

const datadogInitiator: DatadogInitiator<void, void> = {
  init() {
    const applicationId = environment().dd_applicationId
    const clientToken = environment().dd_clientToken
    const env = environment().dd_env_context
    const globalEntityId = getSearchParams()?.getGlobalEntityId()
    const host = getSearchParams()?.getHost()
    const uiVersion = getSearchParams()?.getUiVersion()
    const lineOfBusiness = getSearchParams()?.getLineOfBusiness()
    const agentEmail = readAuthPayload()?.agentEmail
    const site = 'datadoghq.eu'
    const sessionSampleRate = 100
    const sessionReplaySampleRate = 100
    const trackUserInteractions = true
    const forwardErrorsToLogs = true
    const useCrossSiteSessionCookie = true
    const defaultPrivacyLevel = 'mask'
    const platform = getPlatformByGei(lineOfBusiness, globalEntityId)

    if (!environment().reportEventsToDatadog) {
      return false
    }

    if (!applicationId || !clientToken) {
      return false
    }

    const version = environment().dataDogAppVersion
    datadogRum.init({
      applicationId,
      clientToken,
      env,
      site,
      trackUserInteractions,
      sessionSampleRate,
      sessionReplaySampleRate,
      useCrossSiteSessionCookie,
      service: 'gccov-mercury',
      defaultPrivacyLevel,
      version,
      allowedTracingUrls: [
        environment().oneviewApiRoot,
        environment().oneviewApiRootAWS !== undefined ? environment().oneviewApiRootAWS : '',
      ],
      trackResources: true,
      traceSampleRate: 100,
      beforeSend: (event) => {
        /* Add documentVisibilityState to every DD event */
        event.context = {
          ...event.context,
          documentVisibilityState: document?.visibilityState || 'N/A',
        }

        if (
          event.type === 'error' &&
          event.error.source === 'source' &&
          event.error.message.startsWith('Uncaught')
        ) {
          return false
        }

        // RUM >v3.0 no longer issues RUM errors for failed XHR and Fetch calls. This block allows us
        // to continue seeing the failed requests as RUM errors.
        // See https://docs.datadoghq.com/real_user_monitoring/guide/browser-sdk-upgrade/#rum-errors
        if (event.type === 'resource') {
          const resource = event.resource as RumResourceEvent['resource']
          if (resource.status_code >= 500) {
            datadogRum.addError(`${resource.method} ${resource.url} ${resource.status_code}`) // "GET https://www.example.com/ 504"
          }
        }

        if (event.type === 'resource' && event.resource.type === 'xhr') {
          // Enrich Plugin resource event to include the Plugin Code. It allows us to group events by plugin_code
          const result = event.resource.url.match(
            /\/(AdminAPI|ConfigAPI)\/v[\d+]\/plugin\/(?<pluginCode>(.)+?)\//i,
          )

          if (result?.groups?.pluginCode) {
            event.context = { ...event.context, plugin_code: result.groups.pluginCode }
          }
        }
      },
    })

    datadogRum.addRumGlobalContext('platform', platform)
    datadogRum.addRumGlobalContext('agentEmail', agentEmail)
    datadogRum.addRumGlobalContext('effectiveLob', effectiveLob[lineOfBusiness])
    datadogRum.addRumGlobalContext('effectiveUiVersion', uiVersion)
    datadogRum.addRumGlobalContext('effectiveHost', host || 'unknown')

    datadogRum.startSessionReplayRecording()

    datadogLogs.init({
      clientToken,
      env,
      site,
      forwardErrorsToLogs,
      sessionSampleRate,
      useCrossSiteSessionCookie,
      version,
    })

    datadogLogs.logger.setLevel('error' as any)
    datadogLogs.setLoggerGlobalContext({
      dh_env: environment().dd_env_context,
      dh_platform: 'gcc',
      dh_squad: 'oneview',
      dh_cc_id: '1001060220',
      dh_tribe: 'gcc',
      owner: 'david_latotzky',
      service: 'gccov-mercury',
    })
  },
}

export default datadogInitiator
