import { useLoadRiderCurrentOrder } from './useLoadRiderCurrentOrder'
import { useLoadOrder } from './useLoadOrder'
import { useLoadOrderFulfillment } from './useLoadOrderFulfillment'
import { useLoadRider } from './useLoadRider'
import { useLoadTicket } from './useLoadTicket'
import { useLoadVendor } from './useLoadVendor'
import { useLoadRiderCurrentOrderVendor } from './useLoadRiderCurrentOrderVendor'
import { useLoadOrderComments } from './useLoadOrderComments'

export const useInitData = () => {
  const orderInit = useLoadOrder()

  const vendorInit = useLoadVendor()

  const ticketInit = useLoadTicket()

  const riderInit = useLoadRider()

  const riderCurrentOrderInit = useLoadRiderCurrentOrder()

  const riderCurrentOrderVendorInit = useLoadRiderCurrentOrderVendor()

  const orderFulfillmentInit = useLoadOrderFulfillment()

  const orderCommentsInit = useLoadOrderComments()

  return {
    orderInit,
    vendorInit,
    ticketInit,
    riderInit,
    orderCommentsInit,
    orderFulfillmentInit,
    riderCurrentOrderInit,
    riderCurrentOrderVendorInit,
  }
}
