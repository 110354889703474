export interface CustomerVouchersT {
  code: string
  expiration: string
  validDates?: string
  type: string
  value: string
  status?: string
  details?: LabelValuePairT[]
}

interface LabelValuePairT {
  label: string
  value: string
}

export enum VoucherDetails {
  description = 'Description',
  valid = 'Valid',
  minOrderVal = 'Min. Order Value',
  maxOrderVal = 'Max. Order Value',
  openingHour = 'Opening Hour',
  closingHour = 'Closing Hour',
  schedules = 'Schedules',
  isNewCustomerOnly = 'New Customer Only',
  verticalTypes = 'Vertical Types',
  platforms = 'Platforms',
  expeditionTypes = 'Expedition Types',
  vendors = 'Vendors',
  vendorChains = 'Vendor Chains',
  paymentTypes = 'Payment Types',
  customerQuantity = 'Customer Quantity',
  quantity = 'Quantity',
}

export enum Statuses {
  applicable = 'APPLICABLE',
  notApplicable = 'NOT_APPLICABLE',
  expired = 'EXPIRED',
  used = 'USED',
}

export enum VoucherTypes {
  percentage = 'percentage',
  amount = 'amount',
  deliveryFee = 'delivery_fee',
  cashback_amount = 'cashback_amount',
  cashback_percentage = 'cashback_percentage',
}

export enum LetterStatuses {
  applicable = 'A',
  notApplicable = 'N',
  expired = 'E',
  used = 'U',
}
