/**
 * expects an array of features and an array of order statuses
 * checks if features are allowed according to current order status
 * returns an array of features that must be blocked due to order status
 * */

import { FeatureWithBetaRequirementConfig } from 'contexts/entity/types'
import { DisabledFeature } from 'types/actions/feature'
import { OrderApiResponse } from 'types/api/orderApi/order'
import modifyUTC from './modifyUTC'

export function getOrderAgeInHours({
  order,
  utc_zone,
}: {
  order: OrderApiResponse
  utc_zone: string
}) {
  const promisedTime = modifyUTC(order.promised_customer_timestamp, utc_zone, 'M.D.YYYY')
  const nowByEntity = modifyUTC(new Date().toISOString(), utc_zone, 'M.D.YYYY')

  return Math.floor(
    (new Date(nowByEntity).getTime() - new Date(promisedTime).getTime()) / (1000 * 3600),
  )
}

function getDisabledActionsDueToOrderAge(
  features: FeatureWithBetaRequirementConfig[],
  order: OrderApiResponse,
  utc_zone: string,
): DisabledFeature[] {
  if (!order) {
    return []
  }
  return features.reduce((list, { feature, maxOrderAgeInHours }) => {
    const orderAgeInHours = getOrderAgeInHours({ order, utc_zone })
    if (maxOrderAgeInHours && orderAgeInHours >= maxOrderAgeInHours) {
      list.push(
        new DisabledFeature({
          name: feature,
          message: 'Messages.Current order age does not allow this action',
          options: {
            replace: {
              ageInHours: maxOrderAgeInHours,
            },
          },
        }),
      )
    }
    return list
  }, [])
}

export default getDisabledActionsDueToOrderAge
