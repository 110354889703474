/**
 * parent component for order payment tab
 * pulls payment history and renders PaymentView when data received successfully
 * */

// libs
import React, { useContext } from 'react'
// contexts and types
import { SessionContext } from 'contexts/session/SessionContext'
import { EntityContext } from 'contexts/entity/EntityContext'
import { DataContext } from 'contexts/data/DataContext'
import { DataAction } from 'contexts/data/types'
// hooks
import { useTranslation } from 'hooks/useTranslation'
// utils
import transformPaymentHistory from 'utils/dataTransformers/transformPaymentHistory'
// components
import PaymentView from './PaymentView'
import { useApiService } from 'hooks/useApiService'
import { getCustomerOrderPayments } from 'services/paymentApi/getCustomerOrderPayments'
import { WidgetErrorHandler } from 'components/WidgetErrorHandler/widgetErrorHandler'
import { useEntityUtcZone } from 'hooks/useEntityUtcZone'
import { OrderWidgetItems, OrderWidgetPayment } from 'contexts/entity/types'

type Props = {
  items: OrderWidgetItems
  payment: OrderWidgetPayment
}

const Payment: React.FC<Props> = ({ payment, items }) => {
  // pull translations
  const { t } = useTranslation()

  // pull globalentity ID from session context
  const {
    sessionState: { orderId, globalEntityId, userId },
  } = useContext(SessionContext)

  // get entity's UTC offset
  const {
    entityState: {
      entityConfig: { currency_digits_after_comma },
    },
  } = useContext(EntityContext)
  const utc_zone = useEntityUtcZone()

  // pull data dispatch from data context
  const { dataDispatch } = useContext(DataContext)
  const { SET_PAYMENT } = DataAction

  const {
    loading: isLoading,
    error,
    loadService: reloadService,
  } = useApiService({
    service: getCustomerOrderPayments,
    params: {
      entityId: globalEntityId,
      orderId,
      customerId: userId,
    },
    deps: [globalEntityId, orderId, userId],

    onSuccess(res) {
      dataDispatch({
        type: SET_PAYMENT,
        payload: {
          payment: transformPaymentHistory(res.data, utc_zone, t, currency_digits_after_comma),
        },
      })
    },
  })

  return (
    <div>
      <WidgetErrorHandler
        loading={isLoading}
        errorPayload={error?.errorPayload}
        loadingText={t('Interface.Loading Payment History')}
        onRetry={reloadService}
      >
        {() => <PaymentView payment={payment} items={items} />}
      </WidgetErrorHandler>
    </div>
  )
}

export default Payment
