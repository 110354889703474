import { Col, Row } from 'antd'
import { useSdk } from 'contexts/SdkProvider'
import { useDeliveryHistoryTimeline } from 'hooks/dataTransformers/useDeliveryHistoryTimeline'
import { useTranslation } from 'hooks/useTranslation'
import React, { useMemo, useState } from 'react'
import { OrderDeliveryConfigs } from 'types'
import { DeliveryItem, FulfillmentApiResponse } from 'types/api/fulfillmentApi/fulfillment'
import { OrderApiResponse } from 'types/herocare'
import { HistoryMap } from 'widgets/Deliveries/HistoryMap/HistoryMap'
import { PlaceholderDeliveryMap } from 'widgets/Deliveries/HistoryMap/PlaceholderDeliveryMap'
import { HistoryTimeline } from 'widgets/Deliveries/HistoryTimeline/HistoryTimeline'
import { useDeliveryPanelStyles } from './styles'
import { OrderStatuses } from 'types/widgets/order/orderStatuses'
import { Button } from 'shared'

type Props = {
  isMapVisible?: boolean
  onViewMap?: () => void
  delivery?: DeliveryItem
  order: OrderApiResponse
  config: OrderDeliveryConfigs
  fulfillment?: FulfillmentApiResponse
}

export const DeliveryPanelBody = ({
  delivery,
  order,
  fulfillment,
  isMapVisible,
  onViewMap,
}: Props) => {
  const sdk = useSdk()
  const { t } = useTranslation()
  const classes = useDeliveryPanelStyles()

  const MINIMUM_ITEMS_TO_SHOW_IN_TIMELINE = 4

  const [showAll, setShowAll] = useState(false)
  const timeline = useDeliveryHistoryTimeline({ delivery, order, sdk, fulfillment })

  const canDisplayMap = useMemo(() => {
    return (
      fulfillment &&
      order?.status_history?.some((item) => item.status === OrderStatuses.courierAcceptedDelivery)
    )
  }, [order, fulfillment])

  const toggleShowAll = () => {
    setShowAll((value) => !value)
  }

  return (
    <Row justify='center' className={classes.timeline}>
      <Col span={14}>
        <HistoryTimeline
          timeline={timeline}
          reverse
          showAll={showAll}
          minItemsVisible={!delivery?.primary_delivery && MINIMUM_ITEMS_TO_SHOW_IN_TIMELINE}
        />
        <Row justify='center'>
          <Col span={14}>
            {timeline.length > MINIMUM_ITEMS_TO_SHOW_IN_TIMELINE && (
              <Button type={'link'} onClick={toggleShowAll}>
                {!showAll ? t('action_labels.show_all_statuses') : t('action_labels.hide_statuses')}
              </Button>
            )}
          </Col>
        </Row>
      </Col>
      <Col span={10}>
        {isMapVisible ? (
          <HistoryMap order={order} fulfillment={fulfillment} delivery={delivery} />
        ) : (
          <PlaceholderDeliveryMap canViewMap={canDisplayMap} onViewMap={onViewMap} />
        )}
      </Col>
    </Row>
  )
}
