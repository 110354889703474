import classNames from 'classnames'
import React, { Children, cloneElement, FunctionComponent } from 'react'
import { createUseStyles } from 'react-jss'
import { SlimHtmlProps } from 'types'
import styles from './index.style'

export interface StackComponent
  extends FunctionComponent<
    SlimHtmlProps<{
      className?: string

      /**
       * widths to apply to each stack item
       */
      itemsWidth?: string

      direction?: 'row' | 'row-reverse' | 'column' | 'column-reverse'

      /**
     x spacings
     */
      xSpacing?: number

      /**
       * y spacing
       */
      ySpacing?: number

      /**
       * class name to assign to each item
       */
      itemsClassName?: string

      /**
       * width of a stack acting as a stack item
       */
      width?: string

      /**
       * a stack can be made a stack item
       */
      item?: boolean

      /**
       * by default, stacks wrap items on the next line
       */
      noWrap?: boolean
    }>
  > {
  Item: FunctionComponent<
    SlimHtmlProps<{
      className?: string

      /**
       * width of a stack acting as a stack item
       */
      width?: string
    }>
  >
}

const useStyles = createUseStyles(styles)

const StackItem: StackComponent['Item'] = ({ className, width, Component = 'div', ...props }) => {
  const cx = useStyles({ width })
  return <Component className={classNames('stack-item', cx.width, className)} {...props} />
}

export const Stack: StackComponent = ({
  className,
  children,
  xSpacing = 16,
  ySpacing = 16,
  itemsWidth,
  itemsClassName,
  item,
  width,
  noWrap,
  direction = 'row',
  Component = 'div',
  ...others
}) => {
  const cx = useStyles({
    noWrap,
    xSpacing,
    ySpacing,
    width,
  })

  return (
    <Component
      className={classNames(
        className,
        {
          [cx.stackRow]: direction === 'row',
          [cx.stackRowReverse]: direction === 'row-reverse',
          [cx.stackCol]: direction === 'column',
          [cx.stackColReverse]: direction === 'column-reverse',
          'stack-item': item,
        },
        cx.width,
        width,
      )}
      {...others}
    >
      {itemsWidth || itemsClassName
        ? Children.map(children, (child: any) => {
            if (!child) {
              return null
            }
            return cloneElement(child, {
              width: child.props.width || itemsWidth,
              className: classNames(itemsClassName, child.props.className),
            })
          })
        : children}
    </Component>
  )
}

Stack.Item = StackItem
