import React from 'react'
import { createUseStyles } from 'react-jss'

import { SingleComment } from 'types/api/commentApi/getOrderComments'
import { useTranslation } from 'hooks/useTranslation'
import { commentTagType, CommentView } from 'components/Comments/CommentView'
import styles from './Comments.styles'
import { WidgetSize } from 'types/widgets/historyOfEvents/tabs'
import { Typography } from 'antd'

const { Text } = Typography

const useStyles = createUseStyles(styles)

type Props = {
  comment: SingleComment
  shouldCommentBeHighlighted?: boolean
  mode: WidgetSize
}

export const EndRiderBreakComment = ({ comment, shouldCommentBeHighlighted, mode }: Props) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <CommentView
      mode={mode}
      comment={comment}
      shouldCommentBeHighlighted={shouldCommentBeHighlighted}
      tag={{
        type: commentTagType.thunderbolt,
        text: t('History of Events Widget.Rider break'),
      }}
    >
      <Text className={classes.commentText}>
        {`${t('History of Events Widget.Rider has been')} `}
        <Text strong>{t('History of Events Widget.put off break')}</Text>
      </Text>
    </CommentView>
  )
}
