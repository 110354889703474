export default {
  chatTranscriptHolder: {
    minWidth: '200px',
    marginLeft: -10,
  },
  chatTranscriptButton: {
    fontSize: '13px',
  },
  chatTranscriptIcon: {
    fontSize: '17px',
  },
  modal: {
    position: 'absolute',
    left: 0,
    top: 0,
    display: 'flex',
    flexDirection: 'column',
    width: 300,
    minWidth: 300,
    height: 'auto',
    minHeight: '1000px',
    maxHeight: '1000px',
    overflowY: 'auto',
    marginTop: 0,
    paddingBottom: 0,
    '& .ant-modal-content': {
      position: 'absolute',
      top: 0,
      bottom: 0,
      right: 0,
      left: 0,
      overflowY: 'auto',
    },
  },
  titleSeparator: {
    marginTop: 20,
  },
  dropdown: {
    margin: '0px 0px 15px -10px',
    padding: 0,
  },
  dropdownMenu: {
    overflowX: 'scroll',
    overflowY: 'scroll',
    height: 'auto',
    maxHeight: 300,
    width: 200,
    maxWidth: 200,
  },
  dropdownMenuItem: {
    fontSize: '12px',
    color: 'rgba(0, 0, 0, 0.65)',
  },
  button: {
    fontSize: '12px',
    margin: '0px 0px 0px -10px',
    padding: 0,
  },
}
