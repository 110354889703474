// libs
import React, { useContext } from 'react'
// contexts and types
import { DataContext } from 'contexts/data/DataContext'
import { availableRefundMethods } from 'entityConfig/allowedConfigValues'
// hooks
import { useTranslation } from 'hooks/useTranslation'
// utils
// styles
import { createUseStyles } from 'react-jss'
import styles from './Refund.styles'
import { Alert, Button, Divider, Typography } from 'antd'
import { AvailableRefundMethod } from 'types/actions/partialRefund'
import { RefundMethodSelect } from './RefundMethodSelector'
import RefundInfo from './RefundInfo'
import VoucherDetails from './VoucherDetails'
import captureUserAction from 'browserMonitoring/captureUserAction'
import LoadingView from 'components/LoadingView'
import BankAccountRefund from './BankAccountRefund'

const useStyles = createUseStyles(styles)

interface Props {
  useTicketAsFallback: boolean
  totalDamagedValue: number
  refundMethods: AvailableRefundMethod[]
  voucherAmount: number
  additionalNotes: string
  customerPaymentMethod: string
  setAdditionalNotes: React.Dispatch<React.SetStateAction<string>>
  setVoucherAmount: (arg: number) => void
  selectedRefundMethod: AvailableRefundMethod
  setSelectedRefundMethod: (arg: AvailableRefundMethod) => void
  handleRefundClick: () => void
  setTotalRefundAmount: (arg: number) => void
  totalRefundAmount: number
  voucherBeginDate: string
  voucherEndDate: string
  setCurrentStepIndex: (arg: number) => void
  voucherMinOrderValue: number
  setVoucherMinOrderValue: (aeg: number) => void
  voucherPaymentTypes: string
  isValidatingRefundMethod: boolean
  refundMethodValidationError: string
}

const Refund: React.FC<Props> = ({
  refundMethods,
  voucherAmount,
  additionalNotes,
  useTicketAsFallback,
  setAdditionalNotes,
  setCurrentStepIndex,
  customerPaymentMethod,
  selectedRefundMethod,
  setSelectedRefundMethod,
  handleRefundClick,
  totalRefundAmount,
  voucherBeginDate,
  voucherEndDate,
  voucherMinOrderValue,
  voucherPaymentTypes,
  totalDamagedValue,
  isValidatingRefundMethod,
  refundMethodValidationError,
}) => {
  const classes = useStyles()
  const { Text } = Typography

  // pull translations
  const { t } = useTranslation()

  // pull order from data context
  const {
    dataState: { currency },
  } = useContext(DataContext)

  let disableBtn = false
  let btnText = t('Widgets Common.Refund')

  switch (selectedRefundMethod?.method) {
    case availableRefundMethods.wallet:
    case availableRefundMethods.source:
      disableBtn = isValidatingRefundMethod || Boolean(refundMethodValidationError)
      if (useTicketAsFallback) {
        disableBtn = false
        btnText = t('Actions Widget.Actions.Partial Refund.Submit')
      }
      break
    case availableRefundMethods.bankAccount:
      disableBtn = !additionalNotes
      btnText = t('Actions Widget.Actions.Partial Refund.Submit')
      break
  }

  if (isValidatingRefundMethod) {
    return <LoadingView text={`${t('Interface.Validating refund method')}...`} />
  }

  const useBankAccountMethod =
    useTicketAsFallback || selectedRefundMethod?.method === availableRefundMethods.bankAccount

  return (
    <div className={classes.container}>
      {/* payment method */}
      <div className={classes.textBlock}>
        <Text className={classes.titleText}>
          {`${t('Actions Widget.Actions.Full Refund.Original Payment Method')}:`}
        </Text>
        <Text className={classes.dataText}>{customerPaymentMethod}</Text>
      </div>

      <Divider />

      {/* api error */}
      {!isValidatingRefundMethod && refundMethodValidationError && (
        <Alert message={refundMethodValidationError} type='warning' showIcon />
      )}
      {/* refund method selection through dropdown */}
      <RefundMethodSelect
        selectedRefundMethod={selectedRefundMethod}
        refundMethods={refundMethods}
        setRefundMethod={setSelectedRefundMethod}
      />
      <Divider />

      {selectedRefundMethod && !isValidatingRefundMethod && (
        <React.Fragment>
          {/* item info -> item, compensation and total refund amounts */}
          <RefundInfo
            refundMethod={selectedRefundMethod.method}
            currency={currency}
            voucherAmount={voucherAmount}
            totalRefundAmount={totalRefundAmount}
            totalDamagedValue={totalDamagedValue}
          />
          {Boolean(voucherAmount) && (
            <>
              <Divider />
              <VoucherDetails
                currency={currency}
                voucherAmount={voucherAmount}
                voucherMinOrderValue={voucherMinOrderValue}
                voucherBeginDate={voucherBeginDate}
                voucherEndDate={voucherEndDate}
                voucherPaymentTypes={voucherPaymentTypes}
              />
            </>
          )}

          {useBankAccountMethod && (
            <BankAccountRefund
              additionalNotes={additionalNotes}
              setAdditionalNotes={setAdditionalNotes}
            />
          )}
        </React.Fragment>
      )}
      <div className={classes.buttonsContainer}>
        <Button
          type='default'
          onClick={() => {
            captureUserAction('ActionsFullRefundBackButtonClicked', [])
            setCurrentStepIndex(0)
          }}
          className={classes.backButton}
        >
          {t('Interface.Go Back')}
        </Button>
        {!isValidatingRefundMethod && selectedRefundMethod?.method && (
          <Button type='primary' onClick={handleRefundClick} disabled={disableBtn}>
            {btnText}
          </Button>
        )}
      </div>
    </div>
  )
}

export default Refund
