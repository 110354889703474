/**
 * Google Maps rendering locationPin-s
 * */

// libs
import React from 'react'
import GoogleMapReact from 'google-map-react'
// types
import { LinesOfBusiness } from 'types/session/linesOfBusiness'
// utils
import environment from 'envConfig'
// styles
import { createUseStyles } from 'react-jss'
import styles from './GoogleMap.styles'
// components
import LocationPin from 'components/LocationPin'

const useStyles = createUseStyles(styles)

interface Props {
  centerLat: number
  centerLng: number
  currentAddressLat: number
  currentAddressLng: number
  newAddressLat?: number
  newAddressLng?: number
  lineOfBusiness: string
}

const GoogleMap: React.FC<Props> = ({
  centerLat,
  centerLng,
  currentAddressLat,
  currentAddressLng,
  newAddressLat,
  newAddressLng,
  lineOfBusiness,
}) => {
  const classes = useStyles()

  return (
    <div
      className={
        lineOfBusiness === LinesOfBusiness.rider
          ? classes.riderServiceMapHolder
          : classes.customerServiceMapHolder
      }
    >
      <GoogleMapReact
        bootstrapURLKeys={{ key: environment().googleMapsApiKey }}
        defaultCenter={{
          lat: centerLat,
          lng: centerLng,
        }}
        defaultZoom={14}
      >
        <LocationPin
          lat={currentAddressLat}
          lng={currentAddressLng}
          marker={null}
          tooltipText={null}
          color='pink'
        />

        {newAddressLat && newAddressLng && (
          <LocationPin
            lat={newAddressLat}
            lng={newAddressLng}
            marker={null}
            tooltipText={null}
            color='purple'
          />
        )}
      </GoogleMapReact>
    </div>
  )
}

export default GoogleMap
