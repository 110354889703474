import { OrderApiResponse } from 'types/api/orderApi/order'
import escapeRegExp from 'lodash/escapeRegExp'
export type FilterOrdersBy = 'vendor' | 'orderId'

export const filterOrders = (
  orders: OrderApiResponse[],
  options: { filterBy: FilterOrdersBy[]; filterText: string },
) => {
  const { filterBy, filterText } = options

  if (!filterText) {
    return orders
  }

  if (filterBy.length === 0) {
    return []
  }

  const filterTextRegex = new RegExp(escapeRegExp(filterText), 'i')

  return orders.filter((order) => {
    return filterBy.some((currentFilterBy) => {
      switch (currentFilterBy) {
        case 'orderId':
          return filterTextRegex.test(order.order_id)

        case 'vendor':
          return Boolean(order.vendor) && filterTextRegex.test(order.vendor.name)

        default:
          return false
      }
    })
  })
}
