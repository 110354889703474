import React, { useEffect, useMemo, useState } from 'react'

// Components
import { List, Typography } from 'antd'

// Utils
import checkDataPointAvailability from 'utils/checkDataPointAvailability'
import { createUseStyles } from 'react-jss'

// Relative Path
import AdditionalCharges from './AdditionalCharges'
import PaymentMethod from './PaymentMethod'
import styles from './Invoice.styles'
import { useTranslation } from 'hooks/useTranslation'
import { OrderWidgetInvoice } from 'contexts/entity/types'
import InvoiceVoucherInfo from './InvoiceVoucherInfo'
import { useTransformOrderInvoiceData } from 'hooks/dataTransformers/useTransformOrderInvoiceData'
import { MonetarySign, useNumberFormatter } from 'hooks/formatters/useNumberFormatter'

import { createPluggableWidget } from 'factory/createPluggableWidget'

const { Title, Text } = Typography
const useStyles = createUseStyles(styles)

type Config = {
  invoice: OrderWidgetInvoice
}

const Invoice = createPluggableWidget<Config>(
  ({ config: { invoice }, order }) => {
    const { t } = useTranslation()
    const classes = useStyles()

    const transformOrderInvoiceData = useTransformOrderInvoiceData()
    const numberFormatter = useNumberFormatter()

    const [voucherCode, setVoucherCode] = useState(null)

    useEffect(() => {
      if (order?.customer?.payment?.coupons_info?.length) {
        const { incentive_code } = order?.customer?.payment?.coupons_info[0]
        setVoucherCode(incentive_code)
      }
    }, [order])

    const { fields, additionalCharges } = useMemo(
      () => transformOrderInvoiceData(order, invoice),
      [order, transformOrderInvoiceData, invoice],
    )

    const isDiscountAvailable = checkDataPointAvailability(
      invoice.discount.displayRule,
      !!order?.customer.payment.discount,
    )

    const isDiscountJokerAvailable = checkDataPointAvailability(
      invoice.discount_joker?.displayRule,
      !!order?.vendor?.charges?.joker_discount_fee,
    )

    const isAdditionalChargesAvailable = checkDataPointAvailability(
      invoice.additional_charges.displayRule,
      !!additionalCharges.totalAmount,
    )

    const isVoucherAvailable = checkDataPointAvailability(
      invoice.voucher.displayRule,
      !!order?.customer?.payment.coupon,
    )

    const isLoyalityPointsAvailable = checkDataPointAvailability(
      invoice.loyality_points.displayRule,
      !!order?.customer?.payment.loyalty_point_balance,
    )

    const currency = order?.currency

    return (
      <>
        <List itemLayout='horizontal' size='small' className={classes.list} bordered={false}>
          <List.Item className={classes.listItem} data-test-id='order-invoice-basket-value'>
            <span>
              {fields.basketValue.label}
              {!!order?.order?.tax?.amount ? (
                <Text type='secondary'>{` (${t(
                  'Order Widget.Tabs.Invoice.Incl VAT',
                )} ${numberFormatter.formatMoney({
                  amount: order.order.tax.amount,
                  currency,
                })})`}</Text>
              ) : null}
            </span>
            <Text>
              {numberFormatter.formatMoney({ amount: fields.basketValue.value, currency })}
            </Text>
          </List.Item>
          {isDiscountAvailable && (
            <List.Item className={classes.listItem} data-test-id='order-invoice-discount'>
              {fields.discount.label}{' '}
              <Text>
                {numberFormatter.formatMoney({
                  amount: fields.discount.value,
                  currency,
                  sign: MonetarySign.Auto,
                })}
              </Text>
            </List.Item>
          )}

          {isDiscountJokerAvailable && (
            <List.Item className={classes.listItem} data-test-id='order-invoice-discount-joker'>
              {fields.discount_joker.label}{' '}
              <Text>
                {numberFormatter.formatMoney({
                  amount: fields.discount_joker.value,
                  currency,
                  sign: MonetarySign.Auto,
                })}
              </Text>
            </List.Item>
          )}

          <AdditionalCharges
            label={fields.additionalCharges.label}
            isAdditionalChargesAvailable={isAdditionalChargesAvailable}
            currency={order.currency}
            totalAmount={additionalCharges.totalAmount}
            dataPoints={additionalCharges.dataPoints}
          />
          <List.Item className={classes.listItem} data-test-id='order-invoice-subtotal'>
            {fields.subtotal.label}{' '}
            <Text strong>
              {numberFormatter.formatMoney({ amount: fields.subtotal.value, currency })}
            </Text>
          </List.Item>
          {isVoucherAvailable && (
            <List.Item className={classes.listItem} data-test-id='order-invoice-voucher'>
              <InvoiceVoucherInfo
                label={fields.voucher.label}
                value={numberFormatter.formatMoney({
                  amount: fields.voucher.value,
                  sign: MonetarySign.Auto,
                  currency,
                })}
                code={voucherCode}
              />
            </List.Item>
          )}
          {isLoyalityPointsAvailable && (
            <List.Item className={classes.listItem} data-test-id='order-invoice-loyality-points'>
              {fields.loyalityPoints.label}
              <Text>
                {numberFormatter.formatMoney({
                  amount: fields.loyalityPoints.value,
                  sign: MonetarySign.Auto,
                  currency,
                })}
              </Text>
            </List.Item>
          )}
          <List.Item className={classes.listItem} data-test-id='order-invoice-total'>
            {fields.total.label}{' '}
            <Title level={5} className={classes.total}>
              {numberFormatter.formatMoney({ amount: fields.total.value, currency })}
            </Title>
          </List.Item>
        </List>
        {order ? (
          <PaymentMethod order={order} maxPendingTimeInHours={invoice.maxPendingTimeInHours} />
        ) : null}
      </>
    )
  },
  {
    category: 'data_lookup',
    deriveConfig({ entityConfig }) {
      return {
        invoice: entityConfig.scrollable_panel_config.widgets_configs.order.tab_configs.invoice,
      }
    },
  },
)

export default Invoice
