import { ApiService } from 'services/authApi/getPermissions'
import VoucherApiSingleVoucher from '../../types/api/voucherApi/getSingleVoucher'

export const getVoucherByCode: ApiService<
  {
    entityId: string
    voucherCode: string
  },
  VoucherApiSingleVoucher
> = (createClient, options) => {
  const { entityId, voucherCode, clientParams } = options

  const uri = `/VoucherAPI/v1/voucher/by_code/${entityId}/${voucherCode}`

  return createClient({
    endpointName: 'getVoucherByCode',
    expectedResponseStatusCode: 200,
    ...clientParams,
  }).get(uri)
}
