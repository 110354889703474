import { Dispatch, Reducer, useCallback, useMemo, useRef } from 'react'
import { useForceUpdate } from './useForceUpdate'

/**
 * this is a custom implementation of react useReducer,
 * it providers a readState that can be used to access state value asynchronously
 */
export const useReadableReducer = <S, A>(
  initialState: S,
  reducer: Reducer<S, A>,
  initializer?: (initialState: Partial<S>) => S,
) => {
  const resolvedInitialState = useMemo(
    () => {
      if (initializer) {
        return initializer(initialState)
      }
      return initialState
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  const stateRef = useRef(resolvedInitialState)
  const forceUpdate = useForceUpdate()

  const dispatch = useCallback<Dispatch<A>>(
    (action) => {
      const currentState = stateRef.current
      const newState = reducer(currentState, action)

      if (currentState !== newState) {
        stateRef.current = newState
        forceUpdate()
      }
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  const readState = useCallback(() => stateRef.current, [])

  return { state: stateRef.current, readState, dispatch }
}
