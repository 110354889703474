import React from 'react'
import { Col, Row, Space } from 'antd'
import { Text } from 'shared/Text'
import { TimelineMeta } from '../types'
import clx from 'classnames'
import { useTimelineStyles } from './Timeline.styles'

type Props = {
  isCurrent?: boolean
  isDiffDay?: boolean
  time: {
    extra?: TimelineMeta
    main: TimelineMeta
    secondary?: TimelineMeta[]
  }
}

export const TimelineTime = ({ time, isCurrent, isDiffDay }: Props) => {
  const classes = useTimelineStyles()
  const fontWeight = isCurrent ? '600' : '400'
  const containerStyles = clx(classes.time, { 'extra-fix': !!time.extra })
  const canShowDiffInDays = Boolean(isDiffDay && time.main.diffInDays)

  return (
    <Row className={containerStyles}>
      <Col span={24}>
        <Space>
          <Text.Text fontSize={14} color={time.main.color} fontWeight={fontWeight}>
            {canShowDiffInDays && <span className={classes.addDays}>+{time.main.diffInDays}d</span>}{' '}
            {time.main.text}
          </Text.Text>
        </Space>
      </Col>
      {time.secondary?.map((secondary) => (
        <Col span={24} key={secondary.text}>
          <Space>
            <Text.Text fontSize={12} color={secondary.color}>
              {secondary.text}
            </Text.Text>
          </Space>
        </Col>
      ))}
    </Row>
  )
}
