export default {
  modalContent: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 24,
    marginBottom: 24,
    width: '100%',
    height: 'auto',
  },
  alertHolder: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  pickersWrapper: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    marginTop: 30,
  },
  pickers: {
    display: 'flex',
    width: '75%',
  },
  dateHolder: {
    display: 'flex',
    flexDirection: 'column',
    width: '46%',
    marginRight: '2%',
  },
  timeHolder: {
    display: 'flex',
    flexDirection: 'column',
    width: '31%',
    marginRight: '2%',
  },
  asapHolder: {
    display: 'flex',
    alignItems: 'flex-end',
    width: '15%',
  },
  separator: {
    fontSize: '24px',
    color: 'rgba(0, 0, 0, 0.25)',
    marginRight: '13%',
  },
  texts: {
    fontSize: '14px',
    color: 'rgba(0, 0, 0, 0.65)',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    marginTop: 24,
  },
  wrongTimeAlertHolder: {
    display: 'flex',
    justifyContent: 'center',
    padding: 10,
    margin: '0px 0px 15px 0px',
  },
}
