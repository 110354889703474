import { OrderApiResponse } from 'types/api/orderApi/order'
import { getCustomerName } from 'utils/getCustomerName'

function transformCustomer(order: OrderApiResponse) {
  const transformedCustomer = {
    customerId: order?.customer?.customer_id || '',
    customerName: getCustomerName(order?.customer),
    customerAddress: order?.delivery?.location?.address_text || '',
    customerPhone: order?.customer?.profile?.phone || '',
  }

  return transformedCustomer
}

export default transformCustomer
