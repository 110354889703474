import React from 'react'
import { createUseStyles } from 'react-jss'
import { Select, Space, SelectProps } from 'antd'
import noop from 'lodash/noop'
import { Text } from '../Text'
import { getInlineAntdPopupNode } from 'utils/antd/getInlinePopupNode'
import styles from './index.styles'

const useStyles = createUseStyles(styles)

const OPTION_HEIGHT = 32 // depends on Select.size property
const VISIBLE_OPTIONS_COUNT = 12

export interface SelectOption {
  value: string
  label: string
}

interface SelectBoxProps extends SelectProps<SelectOption> {
  label?: string
  options: SelectOption[]
  placeholder: string
  defaultValue?: SelectOption
  status?: '' | 'warning' | 'error'
  errorMessage?: string

  /**
   * Applied as data-test-id to the wrapping element
   */
  testId?: string

  /**
   * Option selection handler.
   * Returns string if only `labelInValue` is set to false.
   *
   * @param {SelectOption} value
   * @returns SelectOption
   */
  onSelectChange?: (value: SelectOption | string) => void
  onDropdownVisibleChange?: (isVisible: boolean) => void
  onSearch?: (inputValue: string) => void
}

const Label: React.FC = ({ children }) => {
  const classes = useStyles()

  if (!children) return null

  return <Text.Primary className={classes.label}>{children}</Text.Primary>
}

export const SelectBox = ({
  label,
  options,
  placeholder,
  defaultValue,
  status,
  errorMessage,
  testId,
  onSelectChange = noop,
  onDropdownVisibleChange = noop,
  onSearch = noop,
  ...rest
}: SelectBoxProps) => {
  const classes = useStyles()

  const handleFilterOption = (inputValue: string, option: SelectOption) => {
    return option.label.toLowerCase().includes(inputValue.toLowerCase())
  }

  return (
    <Space size={4} direction='vertical' className={classes.container} data-test-id={testId}>
      <Label>{label}</Label>
      <Select
        showSearch
        labelInValue
        virtual={false} // virtual scroll breaks with multiline options
        dropdownMatchSelectWidth={false}
        className={classes.select}
        options={options}
        placeholder={placeholder}
        status={status}
        defaultValue={defaultValue}
        listHeight={OPTION_HEIGHT * VISIBLE_OPTIONS_COUNT}
        filterOption={handleFilterOption}
        onChange={onSelectChange}
        onSearch={onSearch}
        getPopupContainer={getInlineAntdPopupNode}
        onDropdownVisibleChange={onDropdownVisibleChange}
        {...rest}
      />
      {status === 'error' && (
        <Text.Primary className={classes.errorMessage}>{errorMessage}</Text.Primary>
      )}
    </Space>
  )
}
