import { InitialEntityState, EntityAction, EntityActions } from './types'

const { SET_ENTITY_CONFIG } = EntityAction

export const entityReducer = (entityState: InitialEntityState, action: EntityActions) => {
  switch (action.type) {
    case SET_ENTITY_CONFIG:
      if (entityState.entityConfig !== action.payload.entityConfig) {
        return {
          entityConfig: action.payload.entityConfig,
        }
      }
      break
  }
  return entityState
}
