import React from 'react'
import { StatusColors, primary, text } from 'theme'
import { notification as originalNotification } from 'antd'
import { CheckCircleFilled, CloseCircleFilled } from '@ant-design/icons'

/**
 * Displays notification popup
 */
export const Notification: typeof originalNotification = {
  ...originalNotification,
  success(args) {
    return originalNotification.success({
      ...args,
      icon: <CheckCircleFilled style={{ color: StatusColors.green }} />,
    })
  },
  error(args) {
    return originalNotification.error({
      ...args,
      icon: <CloseCircleFilled style={{ color: text.danger }} />,
    })
  },
  info(args) {
    return originalNotification.info({
      ...args,
      icon: <CloseCircleFilled style={{ color: primary.primary6 }} />,
    })
  },
}
