export interface VendorApiResponse {
  vendor_id: string
  salesforce_id: string
  chain_id?: string
  chain_name?: string
  billing_parent_id: string
  global_vendor_id: string
  name: string
  names: Array<NamesAndDescriptionsItem>
  description: string
  descriptions: Array<NamesAndDescriptionsItem>
  global_entity_id: string
  creation_date: string
  business_type: string
  vertical_id: string
  vertical_type: string
  locale: string
  timestamp: string
  currency: string
  active: boolean
  key_account: boolean
  halal: boolean
  test_vendor: boolean
  white_label_domain: string
  images_urls: {
    logo: string
    header: string
  }
  vendor_detail_page_slug: string
  tags: [string]
  attributes: Array<AttributesItem>
  delivery_types: [string]
  location: VendorLocation
  transmission: {
    contact: string
    contacts?: Array<VendorContact>
    locale: string
    methods: Array<MethodsItem>
  }
  owners: Array<OwnersItem>
  contact: {
    phone: string
  }
  accepts_online_payment: boolean
  is_contracted: boolean
  timezone: string
  customer_types: [string]
  joker: {
    minimum_promotion_definition_shift_enabled: boolean
  }
  activation_date: string
}

export enum VendorContactTypes {
  Primary = 'primary',
  Secondary = 'secondary',
  Manager = 'manager',
  Hotline = 'hotline',
  Kitchen = 'kitchen',
}

export interface VendorContact {
  type: VendorContactTypes
  phone: string
}

export interface VendorLocation {
  address_text: string
  rider_instructions: string
  street: string
  street_number: string
  building: string
  district: string
  postal_code: string
  city: string
  country_code: string
  latitude: number
  longitude: number
  city_id: string
  area_id: string
}

interface NamesAndDescriptionsItem {
  locale: string
  value: string
}

interface AttributesItem {
  external_id: string
  name: string
  names: Array<NamesAndDescriptionsItem>
  type: string
}

interface MethodsItem {
  global_transmission_method_id: string
  type: string
  is_primary: boolean
}

interface OwnersItem {
  name: string
  email: string
}

export interface AddressInVendorDeliveryAreaApiResponse {
  is_deliverable: boolean
}
