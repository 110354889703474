import { ApiClientCreator } from 'contexts/apiClientCreator/ApiClientCreatorContext'
import { readTrackingAuthPayload, writeTrackingAuthPayload } from './authHelpers'
import { getTrackingToken } from 'services/authApi/getTrackingToken'

let trackingToken = ''
let refreshTrackingTokenAfter = 0

let refreshTokenPromise: Promise<{ trackingToken: string }>
let refreshStart = 0

const setRefreshStart = (time: number) => {
  refreshStart = time
  window.localStorage.setItem('refresh-tracking-token-start', time.toString())
}

const getRefreshStart = () => {
  return Number.parseInt(window.localStorage.getItem('refresh-tracking-token-start') || '0', 10)
}

/**
 * we will get a fresh token for every browser session,
 * @returns
 */
export const refreshGlobalTrackingToken = (
  createClient: ApiClientCreator,
  opts: {
    globalEntityId: string
    forceRefresh?: boolean
  },
): typeof refreshTokenPromise => {
  const { globalEntityId, forceRefresh } = opts

  // if this tab is refreshing, return the promise
  if (refreshStart > 0) {
    return refreshTokenPromise
  }

  const refreshStartTime = getRefreshStart()

  // if another tab is refreshing, return another promise
  if (refreshStartTime > 0) {
    return new Promise((resolve, reject) => {
      /**
       * will check on the refresh status every 1s, it will abort if refresh takes longer than 3 seconds
       */
      const checkBack = () => {
        const diff = Date.now() - refreshStartTime

        // done refreshing
        if (getRefreshStart() === 0) {
          const payload = readTrackingAuthPayload()

          // successfully refreshed
          if (payload.trackingToken) {
            return resolve({ trackingToken: payload.trackingToken })
          }

          return reject(false)
        }

        // refresh is taking longer than allowed (1 minute), this tab will take over and try to refresh
        if (diff >= 60 * 1000) {
          setRefreshStart(0)
          return resolve(refreshGlobalTrackingToken(createClient, opts))
        }
        // check back again in 1s
        window.setTimeout(checkBack, 1000)
      }

      // start checking back
      checkBack()
    })
  }

  const { refreshAfter, trackingToken } = readTrackingAuthPayload()
  const now = Date.now()

  const shouldRefresh = forceRefresh || !trackingToken || now >= refreshAfter

  if (!shouldRefresh) {
    return Promise.resolve({ trackingToken })
  }

  setRefreshStart(now)
  refreshTokenPromise = getTrackingToken(createClient, {
    globalEntityId,
  })
    .then((res) => {
      writeTrackingAuthPayload(res.data)
      return {
        trackingToken: res.data.trackingToken,
      }
    })
    .finally(() => setRefreshStart(0))

  return refreshTokenPromise
}

/**
 * we will get a fresh token for every browser session,
 * @returns
 */
export const refreshTrackingToken = (
  createClient: ApiClientCreator,
  opts: {
    globalEntityId: string
    forceRefresh?: boolean
  },
): typeof refreshTokenPromise => {
  const { globalEntityId, forceRefresh } = opts

  // it is currently refreshing
  if (refreshStart > 0) {
    return refreshTokenPromise
  }

  const now = Date.now()

  if (!forceRefresh && trackingToken && now <= refreshTrackingTokenAfter) {
    return Promise.resolve({
      trackingToken,
    })
  }

  refreshStart = now
  refreshTokenPromise = getTrackingToken(createClient, {
    globalEntityId,
  })
    .then((res) => {
      refreshTrackingTokenAfter = res.data.refreshAfter
      trackingToken = res.data.trackingToken

      return {
        trackingToken: res.data.trackingToken,
      }
    })
    .finally(() => {
      refreshStart = 0
    })

  return refreshTokenPromise
}
