export default {
  flex: {
    display: 'flex',
  },
  flexAuto: {
    flex: 'auto',
  },
  flexCol: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },

  itemsEnd: {
    alignItems: 'end',
  },

  mlAuto: {
    marginLeft: 'auto',
  },

  ml34: {
    marginLeft: '34px',
  },

  ml6: {
    marginLeft: '6px',
  },

  block: {
    display: 'block',
  },

  minW80: {
    minWidth: '80px',
  },

  minW160: {
    minWidth: '160px',
  },

  textAlignRight: {
    textAlign: 'right',
  },

  icon: {
    color: 'rgba(0,0,0,.85)',
  },

  textSubtitle: {
    color: 'rgba(0, 0, 0, 0.85)',
    fontSize: '14px',
    lineHeight: '22px',
  },

  textLabel: {
    color: 'rgba(0, 0, 0, 0.55)',
    fontSize: '12px',
    lineHeight: '20px',
  },
}
