type Props = {
  map: google.maps.Map
  maps: google.maps.MapsLibrary
  markers: google.maps.PolylineOptions['path']
  geodesic?: boolean
  strokeColor?: string
  strokeOpacity?: number
  strokeWeight?: number
}

export const MapPolyline = ({
  map,
  maps,
  markers,
  geodesic,
  strokeColor,
  strokeOpacity,
  strokeWeight,
}: Props) => {
  if (map && maps) {
    const polyline = new maps.Polyline({
      path: markers,
      geodesic,
      strokeColor,
      strokeOpacity,
      strokeWeight,
    })

    polyline.setMap(map)
  }
  return null
}
