import { AxiosResponse } from 'axios'
import {
  ApiClientCreator,
  ApiClientCreatorParams,
} from 'contexts/apiClientCreator/ApiClientCreatorContext'
import { ApiErrorPayload } from 'types/error'
import environment from 'envConfig'
import { AuthPayload } from 'contexts/auth/AuthProvider'
import jwtDecode from 'jwt-decode'

export type ApiServiceParams<ParamsType, DataType> = ParamsType & {
  clientParams?: Partial<ApiClientCreatorParams<DataType>>
}

export type FulFilledApiResponse<DataType> = AxiosResponse<DataType>
export type RejectedApiResponse<DataType> = AxiosResponse<DataType> & {
  errorPayload?: ApiErrorPayload
}

export type ApiService<ParamsType, DataType = {}> = (
  createClient: ApiClientCreator<DataType>,
  opts: ApiServiceParams<ParamsType, DataType>,
) => Promise<FulFilledApiResponse<DataType>>

export type PickApiServiceParams<S extends ApiService<any>> = S extends ApiService<infer P>
  ? P
  : unknown

export interface GetPermissionResponse {
  jwt: string
  refresh: string
  resolved_permissions: AuthPayload['permissions']
}

export const validatePermissionData = (data) => {
  if (!data?.jwt) {
    return 'No jwt data found in response body'
  }
  if (!data.refresh) {
    return 'No refresh token found in response body'
  }
  return true
}

/**
 * we use new time values in reference to the users
 * local time
 */
export const transformPermissionData = (
  response: AxiosResponse<GetPermissionResponse>,
  agentEmail?: string,
  agentName?: string,
): AxiosResponse<AuthPayload> => {
  const { resolved_permissions, jwt, refresh } = response.data

  return {
    ...response,
    data: {
      jwt,
      refresh,

      /**
       * refresh after 10 minutes
       */
      refreshAfter: Date.now() + 10 * 60 * 1000,

      permissions: resolved_permissions,
      namespaces: Object.keys(resolved_permissions),

      agentEmail,
      agentName,
    },
  }
}

export const getPermissions: ApiService<
  {
    globalEntityId: string
    googleUser: {
      tokenId: string
      profileObj: {
        email: string
        name: string
      }
    }
  },
  AuthPayload
> = (createClient, opts) => {
  const { googleUser, globalEntityId, clientParams } = opts
  const uri = `${
    environment().oneviewAuthApiRoot
  }/get-permissions?global_entity_id=${globalEntityId}`

  return createClient({
    endpointName: 'getPermissions',
    validateJwt: false,
    agentEmail: googleUser.profileObj.email,
    ...clientParams,
    expectedResponseStatusCode: 200,
    validateData: validatePermissionData,
    useAwsApi: environment().oneviewAuthApiAWSEnabled === true ? true : undefined,
  })
    .post(uri, googleUser)
    .then((res) =>
      transformPermissionData(res, googleUser.profileObj.email, googleUser.profileObj.name),
    )
}

export const getPermissionsV2: ApiService<
  {
    globalEntityId: string
    tokenId: string
  },
  AuthPayload
> = (createClient, opts) => {
  const { globalEntityId, tokenId, clientParams } = opts
  const uri = `${
    environment().oneviewAuthApiRootV2
  }/get-permissions?global_entity_id=${globalEntityId}`

  return createClient({
    endpointName: 'getPermissions',
    validateJwt: false,
    ...clientParams,
    expectedResponseStatusCode: 200,
    validateData: validatePermissionData,
    useAwsApi: environment().oneviewAuthApiAWSEnabled === true ? true : undefined,
  })
    .post(uri, { tokenId })
    .then((res) => {
      const { data } = res
      let agentEmail = data.agentEmail
      let agentName = data.agentName

      if (!agentEmail && !agentName) {
        /* We get name and email when logging in via Google.
        For Okta, we need to parse email and name from jwt for now. */
        const { sub, name } = jwtDecode(data.jwt) as OktaCredentialJwtPayload
        agentEmail = sub
        agentName = name
      }

      return transformPermissionData(res, agentEmail, agentName)
    })
}
