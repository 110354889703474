import {
  OrderApiResponse,
  OrderHistoryItem,
  CustomerApiResponse,
  ModifyAddressPostBody,
} from 'types/api/orderApi/order'
import { OrderItemT, PatchItemT } from 'types/widgets/order/orderItem'
import { VendorApiResponse } from 'types/api/vendorApi/vendor'
import { FulfillmentApiResponse } from 'types/api/fulfillmentApi/fulfillment'
import { PaymentT } from 'types/dataTransformers/paymentHistory'
import { CustomerVouchersT } from 'types/dataTransformers/customerVouchers'
import VoucherApiSingleVoucher from 'types/api/voucherApi/getSingleVoucher'
import CustomerWalletApiResponse from 'types/api/paymentApi/getCustomerWallet'
import { Compensation, Refund } from 'types/api/voucherApi/getCompensationsAndRefunds'
import { SingleCommentBody } from 'types/api/commentApi/getOrderComments'
import { OrderSingleBasketUpdate } from 'types/api/orderApi/orderBasketUpdate'
import { GetCustomerProfileResponse } from 'types/api/customerApi/getCustomerProfile'
import { Voucher } from '../../types/api/voucherApi/voucher'
import { FulfillmentApiRiderResponse } from 'types/api/riderApi/rider'
import { RiderChatsData } from 'types/api/riderApi/riderChats'
import { GetProposedCompensationApiResponse } from 'services/VoucherApi/getProposedCompensationValue'
import { TicketDetailsApiResponse } from 'types/api/ticketApi/getTicketDetails'
import { EmittedEventName } from 'utils/eventEmitter'

// state types
export type InitialState = {
  order: OrderApiResponse
  customer: CustomerApiResponse
  vendor: VendorApiResponse
  ticket?: TicketDetailsApiResponse

  rider?: FulfillmentApiRiderResponse
  riderCurrentOrder?: OrderApiResponse
  riderCurrentOrderVendor?: VendorApiResponse
  orderBasketUpdates: OrderSingleBasketUpdate[]
  orderItems: OrderItemT[]
  orderError: any
  currency: string
  /**
   *  application events
   */
  emittedEvents: Set<EmittedEventName>

  /**
   * the case customer is the customer that is provided
   * in the url
   */
  caseCustomer?: CustomerApiResponse | null

  customerProfile: GetCustomerProfileResponse

  previousOrders: OrderApiResponse[]
  fulfillment: FulfillmentApiResponse
  payment: PaymentT
  vouchers: Voucher[]
  searchedVoucher: any
  compensatedVoucher: VoucherApiSingleVoucher
  fullyRefundedVoucher: any
  wallet: CustomerWalletApiResponse
  modifiedDeliverySettings: ModifyAddressPostBody
  newComment: SingleCommentBody
  autoChangeDeliveryTimeComment: string
  autoMissingItemReportComment: string
  autoCancelOrderComment: string
  autoCompensationComment: any
  autoPartialRefundComment: any
  autoFullRefundComment: any
  autoChangeAddressComment: string
  autoChangeInstructionsComment: string
  autoModifyCallbackComment: string
  autoChangeCookingInstComment: string
  autoRemoveItemsComment: any
  recommendedCompensation: GetProposedCompensationApiResponse
  receivedCompensationSuggestions: any
  customerCompensations: Compensation[]
  customerRefunds: Refund[]
  itemsWithCookingInstructionChanged: PatchItemT[]
  isOrderCancelled: boolean
  refundToWalletOrPspDetails: any
  riderCustomerChatTranscript: RiderChatsData
  reFetchOrder: boolean

  isLoadingOrder: boolean
  customerExploitStatus: boolean | string
}

type Order = {
  order: OrderApiResponse
}

type OrderBasketUpdates = {
  orderBasketUpdates: OrderSingleBasketUpdate[]
}

type OrderItems = {
  orderItems: OrderItemT[]
}

type OrderErr = {
  orderError: any
}

type Currency = {
  currency: string
}

type OrderStatus = {
  orderStatus: OrderHistoryItem[]
}

type Customer = {
  customer: CustomerApiResponse
}

type CustomerProfile = {
  customerProfile: GetCustomerProfileResponse
}

type Vendor = {
  vendor: VendorApiResponse
}

type PreviousOrders = {
  previousOrders: OrderApiResponse[]
}

type Fulfillment = {
  fulfillment: FulfillmentApiResponse
}

type Payment = {
  payment: PaymentT
}

type Vouchers = {
  vouchers: Voucher[]
}

type SearchedVoucher = {
  searchedVoucher: CustomerVouchersT | VoucherApiSingleVoucher
}

type CompensatedVoucher = {
  compensatedVoucher: VoucherApiSingleVoucher
}

type FullyRefundedVoucher = {
  fullyRefundedVoucher: any
}

type Wallet = {
  wallet: CustomerWalletApiResponse
}

type ModifiedDeliverySettings = {
  modifiedDeliverySettings: ModifyAddressPostBody
}

type NewComment = {
  newComment: SingleCommentBody
}

type AutoChangeDeliveryTimeComment = {
  autoChangeDeliveryTimeComment: string
}

type AutoMissingItemReportComment = {
  autoMissingItemReportComment: string
}

type AutoCancelOrderComment = {
  autoCancelOrderComment: string
}

type AutoCompensationComment = {
  autoCompensationComment: any
}

type AutoPartialRefundComment = {
  autoPartialRefundComment: any
}

type AutoFullRefundComment = {
  autoFullRefundComment: any
}

type AutoChangeAddressComment = {
  autoChangeAddressComment: string
}

type AutoChangeInstructionsComment = {
  autoChangeInstructionsComment: string
}

type AutoModifyCallbackComment = {
  autoModifyCallbackComment: string
}

type AutoChangeCookingInstComment = {
  autoChangeCookingInstComment: string
}

type AutoRemoveItemsComment = {
  autoRemoveItemsComment: any
}

type RecommendedCompensation = {
  recommendedCompensation: any
}

type ReceivedCompensationSuggestions = {
  receivedCompensationSuggestions: any
}

type CustomerCompensations = {
  customerCompensations: Compensation[]
}

type CustomerRefunds = {
  customerRefunds: Refund[]
}

type ItemsWithCookingInstructionChanged = {
  itemsWithCookingInstructionChanged: PatchItemT[]
}

type IsOrderCancelled = {
  isOrderCancelled: boolean
}
type ReFetchOrder = {
  reFetchOrder: boolean
}

type RiderCustomerChatTranscript = {
  riderCustomerChatTranscript: any
}

type RefundToWalletOrPspDetails = {
  refundToWalletOrPspDetails: any
}

type Rider = {
  rider: FulfillmentApiRiderResponse
}

type RiderCurrentOrder = {
  riderCurrentOrder: OrderApiResponse
}

type RiderCurrentOrderVendor = {
  riderCurrentOrderVendor: VendorApiResponse
}

type isLoadingOrder = {
  isLoadingOrder: boolean
}

type CustomerExploitStatus = {
  customerExploitStatus: boolean | string
}

type Ticket = {
  ticket: TicketDetailsApiResponse
}

// action types
export enum DataAction {
  ADD_EMITTED_EVENT = 'ADD_EMITTED_EVENT',
  SET_ORDER = 'SET_ORDER',
  SET_ORDER_BASKET_UPDATES = 'SET_ORDER_BASKET_UPDATES',
  SET_ORDER_ITEMS = 'SET_ORDER_ITEMS',
  SET_ORDER_ERR = 'SET_ORDER_ERR',
  SET_CURRENCY = 'SET_CURRENCY',
  SET_ORDER_STATUS = 'SET_ORDER_STATUS',
  SET_CUSTOMER = 'SET_CUSTOMER',
  SET_CASE_CUSTOMER = 'SET_CASE_CUSTOMER',
  SET_CUSTOMER_PROFILE = 'SET_CUSTOMER_PROFILE',
  SET_VENDOR = 'SET_VENDOR',
  SET_PREV_ORDERS = 'SET_PREV_ORDERS',
  SET_FULFILLMENT = 'SET_FULFILLMENT',
  SET_PAYMENT = 'SET_PAYMENT',
  SET_VOUCHERS = 'SET_VOUCHERS',
  SET_SEARCHED_VOUCHER = 'SET_SEARCHED_VOUCHER',
  SET_COMPENSATED_VOUCHER = 'SET_COMPENSATED_VOUCHER',
  SET_FULLY_REFUNDED_VOUCHER = 'SET_FULLY_REFUNDED_VOUCHER',
  SET_WALLET = 'SET_WALLET',
  SET_MODIFIED_ORDER_ADDRESS = 'SET_MODIFIED_ORDER_ADDRESS',
  SET_NEW_COMMENT = 'SET_NEW_COMMENT',
  SET_AUTO_CHANGE_DELIVERY_TIME_COMMENT = 'SET_AUTO_CHANGE_DELIVERY_TIME_COMMENT',
  SET_AUTO_MISSING_ITEM_COMMENT = 'SET_AUTO_MISSING_ITEM_COMMENT',
  SET_AUTO_CANCEL_ORDER_COMMENT = 'SET_AUTO_CANCEL_ORDER_COMMENT',
  SET_AUTO_COMPENSATION_COMMENT = 'SET_AUTO_COMPENSATION_COMMENT',
  SET_AUTO_PARTIAL_REFUND_COMMENT = 'SET_AUTO_PARTIAL_REFUND_COMMENT',
  SET_AUTO_FULL_REFUND_COMMENT = 'SET_AUTO_FULL_REFUND_COMMENT',
  SET_AUTO_CHANGE_ADDRESS_COMMENT = 'SET_AUTO_CHANGE_ADDRESS_COMMENT',
  SET_AUTO_CHANGE_INSTRUCTIONS_COMMENT = 'SET_AUTO_CHANGE_INSTRUCTIONS_COMMENT',
  SET_AUTO_ITEM_REMOVAL_COMMENT = 'SET_AUTO_ITEM_REMOVAL_COMMENT',
  SET_AUTO_CALLBACK_COMMENT = 'SET_AUTO_CALLBACK_COMMENT',
  SET_AUTO_CHANGE_COOKING_INST_COMMENT = 'SET_AUTO_CHANGE_COOKING_INST_COMMENT',
  SET_RECOMMENDED_COMPENSATION = 'SET_RECOMMENDED_COMPENSATION',
  SET_RECEIVED_COMPENSATION_SUGGESTIONS = 'SET_RECEIVED_COMPENSATION_SUGGESTIONS',
  SET_CUSTOMER_COMPENSATIONS = 'SET_CUSTOMER_COMPENSATIONS',
  SET_CUSTOMER_REFUNDS = 'SET_CUSTOMER_REFUNDS',
  SET_ITEMS_WITH_COOKING_INST_CHANGED = 'SET_ITEMS_WITH_COOKING_INST_CHANGED',
  SET_IS_ORDER_CANCELLED = 'SET_IS_ORDER_CANCELLED',
  SET_REFUND_TO_WALLET_OR_PSP_DETAILS = 'SET_REFUND_TO_WALLET_OR_PSP_DETAILS',
  SET_RIDER_CUSTOMER_CHAT = 'SET_RIDER_CUSTOMER_CHAT',
  SET_RE_FETCH_ORDER = 'SET_RE_FETCH_ORDER',
  SET_RIDER = 'SET_RIDER',

  SET_RIDER_CURRENT_ORDER = 'SET_RIDER_CURRENT_ORDER',
  SET_RIDER_CURRENT_ORDER_VENDOR = 'SET_RIDER_CURRENT_ORDER_VENDOR',

  SET_IS_LOADING_ORDER = 'SET_IS_LOADING_ORDER',
  SET_CUSTOMER_EXPLOIT_STATUS = 'SET_CUSTOMER_EXPLOIT_STATUS',
  SET_TICKET = 'SET_TICKET',
}

type ActionMap<M extends { [index: string]: any }> = {
  [Key in keyof M]: M[Key] extends undefined
    ? { type: Key }
    : {
        type: Key
        payload: M[Key]
      }
}

export type DataActions = ActionMap<DataPayload>[keyof ActionMap<DataPayload>]

export type DataPayload = {
  [DataAction.ADD_EMITTED_EVENT]: EmittedEventName
  [DataAction.SET_ORDER]: Order
  [DataAction.SET_ORDER_BASKET_UPDATES]: OrderBasketUpdates
  [DataAction.SET_ORDER_ITEMS]: OrderItems
  [DataAction.SET_ORDER_ERR]: OrderErr
  [DataAction.SET_CURRENCY]: Currency
  [DataAction.SET_ORDER_STATUS]: OrderStatus
  [DataAction.SET_CUSTOMER]: Customer
  [DataAction.SET_CASE_CUSTOMER]: Customer
  [DataAction.SET_CUSTOMER_PROFILE]: CustomerProfile
  [DataAction.SET_VENDOR]: Vendor
  [DataAction.SET_PREV_ORDERS]: PreviousOrders
  [DataAction.SET_FULFILLMENT]: Fulfillment
  [DataAction.SET_PAYMENT]: Payment
  [DataAction.SET_VOUCHERS]: Vouchers
  [DataAction.SET_SEARCHED_VOUCHER]: SearchedVoucher
  [DataAction.SET_COMPENSATED_VOUCHER]: CompensatedVoucher
  [DataAction.SET_FULLY_REFUNDED_VOUCHER]: FullyRefundedVoucher
  [DataAction.SET_WALLET]: Wallet
  [DataAction.SET_MODIFIED_ORDER_ADDRESS]: ModifiedDeliverySettings
  [DataAction.SET_NEW_COMMENT]: NewComment
  [DataAction.SET_AUTO_CHANGE_DELIVERY_TIME_COMMENT]: AutoChangeDeliveryTimeComment
  [DataAction.SET_AUTO_MISSING_ITEM_COMMENT]: AutoMissingItemReportComment
  [DataAction.SET_AUTO_CANCEL_ORDER_COMMENT]: AutoCancelOrderComment
  [DataAction.SET_AUTO_COMPENSATION_COMMENT]: AutoCompensationComment
  [DataAction.SET_AUTO_PARTIAL_REFUND_COMMENT]: AutoPartialRefundComment
  [DataAction.SET_AUTO_FULL_REFUND_COMMENT]: AutoFullRefundComment
  [DataAction.SET_AUTO_CHANGE_ADDRESS_COMMENT]: AutoChangeAddressComment
  [DataAction.SET_AUTO_CHANGE_INSTRUCTIONS_COMMENT]: AutoChangeInstructionsComment
  [DataAction.SET_AUTO_CALLBACK_COMMENT]: AutoModifyCallbackComment
  [DataAction.SET_AUTO_CHANGE_COOKING_INST_COMMENT]: AutoChangeCookingInstComment
  [DataAction.SET_AUTO_ITEM_REMOVAL_COMMENT]: AutoRemoveItemsComment
  [DataAction.SET_RECOMMENDED_COMPENSATION]: RecommendedCompensation
  [DataAction.SET_RECEIVED_COMPENSATION_SUGGESTIONS]: ReceivedCompensationSuggestions
  [DataAction.SET_CUSTOMER_COMPENSATIONS]: CustomerCompensations
  [DataAction.SET_CUSTOMER_REFUNDS]: CustomerRefunds
  [DataAction.SET_ITEMS_WITH_COOKING_INST_CHANGED]: ItemsWithCookingInstructionChanged
  [DataAction.SET_IS_ORDER_CANCELLED]: IsOrderCancelled
  [DataAction.SET_RE_FETCH_ORDER]: ReFetchOrder
  [DataAction.SET_REFUND_TO_WALLET_OR_PSP_DETAILS]: RefundToWalletOrPspDetails
  [DataAction.SET_RIDER_CUSTOMER_CHAT]: RiderCustomerChatTranscript
  [DataAction.SET_RIDER]: Rider
  [DataAction.SET_RIDER_CURRENT_ORDER]: RiderCurrentOrder
  [DataAction.SET_RIDER_CURRENT_ORDER_VENDOR]: RiderCurrentOrderVendor

  [DataAction.SET_IS_LOADING_ORDER]: isLoadingOrder
  [DataAction.SET_CUSTOMER_EXPLOIT_STATUS]: CustomerExploitStatus
  [DataAction.SET_TICKET]: Ticket
}
