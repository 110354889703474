import { ApiService } from 'services/authApi/getPermissions'
import { GetVendorCommentsResponse } from 'types/api/commentApi/getOrderComments'
import { buildCommentApiUrl } from './helper'
import { VendorCommentsApiParams } from './types'

export const getVendorComments: ApiService<VendorCommentsApiParams, GetVendorCommentsResponse> = (
  createClient,
  options,
) => {
  const { vendorId, clientParams, ...apiParams } = options

  const uri = buildCommentApiUrl(apiParams, 'vendor', vendorId)

  return createClient({
    endpointName: 'getVendorComments',
    expectedResponseStatusCode: 200,
    ...clientParams,
  }).get(uri)
}
