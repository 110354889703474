import { useCallback, useEffect, useRef, useState } from 'react'
import { useCaptureUserAction } from './events/useCaptureUserAction'
type ScrollingDirection = 'downward' | 'upward'

interface IntersectionObserverCustomOptions extends Omit<IntersectionObserverInit, 'threshold'> {
  threshold?: number
}
function computeFrameOffset(win, dims) {
  dims = typeof dims === 'undefined' ? { top: 0, left: 0 } : dims
  // eslint-disable-next-line no-restricted-globals
  if (win !== top) {
    const rect = win.frameElement.getBoundingClientRect()
    dims.left += rect.left
    dims.top += rect.top
    computeFrameOffset(win.parent, dims)
  }
  return dims
}
function currentFrameAbsolutePosition() {
  let currentWindow = window
  let currentParentWindow
  const positions: { x: number; y: number; scrollY: number }[] = []
  let rect
  let scrollY = 0

  while (currentWindow !== window.top) {
    currentParentWindow = currentWindow.parent
    for (let idx = 0; idx < currentParentWindow.frames.length; idx++)
      if (currentParentWindow.frames[idx] === currentWindow) {
        for (const frameElement of currentParentWindow.document.getElementsByTagName('iframe')) {
          if (frameElement.contentWindow === currentWindow) {
            rect = frameElement.getBoundingClientRect()
            scrollY = frameElement.scrollTop
            positions.push({ x: rect.x, y: rect.y, scrollY })
          }
        }
        currentWindow = currentParentWindow
        break
      }
  }

  return positions.reduce(
    (accumulator, currentValue) => {
      return {
        x: accumulator.x + currentValue.x,
        y: accumulator.y + currentValue.y,
        scrollY: accumulator.scrollY + currentValue.scrollY,
      }
    },
    { x: 0, y: 0, scrollY: 0 },
  )
}

function getElementStats(element: Element, options: IntersectionObserverCustomOptions) {
  const viewportHeight = window.innerHeight

  if (!element) {
    return {
      viewportHeight,
      elementY: 0,
      elementHeight: 0,
      widgetInitiallyInView: false,
    }
  }

  const windowScrollY = window.scrollY
  const elementRect = element.getBoundingClientRect()
  const elementY = elementRect?.y || 0
  const elementHeight = elementRect?.height || 0

  // if viewportHeight is bigger than the element top + the threshold percentage of element height + offsetted by the windowScrollY position
  const widgetInitiallyInView =
    viewportHeight > elementY + windowScrollY + elementHeight * options.threshold

  return {
    viewportHeight,
    elementY,
    elementHeight,
    widgetInitiallyInView,
  }
}

interface ScrolledInViewParams {
  options: IntersectionObserverCustomOptions
  widgetId: string
  blacklistedWidgets?: string[]
  scrollCallback?: () => void
}

export function useScrolledInView({
  options,
  widgetId,
  blacklistedWidgets,
  scrollCallback,
}: ScrolledInViewParams) {
  const [isObservable, setIsObservable] = useState<boolean>(false)
  const captureUserAction = useCaptureUserAction()

  const elementRef = useRef<HTMLDivElement>(null)
  const previousPosition = useRef<{
    y: number
    intersectionRatio: number
  }>({ y: 0, intersectionRatio: 0 })

  const whitelistedWidgetId = !blacklistedWidgets?.includes(widgetId)

  // the callback that is being triggered once the widget crosses the threshold set in the options provided to the Intersection Observer
  const handleIntersection = useCallback((entries) => {
    const [entry] = entries
    const currentY = entry.boundingClientRect.y
    const currentRatio = entry.intersectionRatio
    const isIntersecting = entry.isIntersecting

    let scrollingDirection: ScrollingDirection

    // Scrolling down
    if (
      // the current upper bound position -relative to root- of the element is less than the prev upper bound position before scrolling
      currentY < previousPosition.current.y &&
      // the ratio of the widget intersecting with the root is bigger than the prev value
      currentRatio > previousPosition.current.intersectionRatio &&
      // is intersecting with the root element
      isIntersecting
    ) {
      scrollingDirection = 'downward'
    }

    switch (scrollingDirection) {
      case 'downward':
        scrollCallback?.()
        break
      default:
        break
    }

    // updating the current y position and intersection ratio values
    previousPosition.current.y = currentY
    previousPosition.current.intersectionRatio = currentRatio
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    // Intersection Observer Options can be modified and can also be configurable

    if (whitelistedWidgetId && isObservable && elementRef.current) {
      const observer = new IntersectionObserver(handleIntersection, options)
      // trigger the observation of the widget if it is:
      // 1. in the DOM
      // 2. isObservable => it was not in view on mount
      // 3. is whitelisted
      observer.observe(elementRef.current)

      return () => {
        // cleanup the intersection Observer when the component is unmounted
        observer.disconnect()
      }
    }

    //adding the elementRef.current to the dependency array as switching tabs in a tabbed-widget changes the ref of the element manually so we need to track that
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isObservable, elementRef.current])

  useEffect(() => {
    const { elementY, widgetInitiallyInView, elementHeight } = getElementStats(
      elementRef.current,
      options,
    )
    const currentFramePosition = currentFrameAbsolutePosition()
    const hamada = computeFrameOffset(window, { top: 0, left: 0 })
    console.log({ currentFramePosition, hamada })

    previousPosition.current.y = elementY

    const eventDetails = {
      widget: {
        id: widgetId,
        yPosition: elementY,
        height: elementHeight,
      },
      windowViewport: {
        height: window.innerHeight,
        width: window.innerWidth,
        scrollPosition: window.scrollY,
      },
      options: {
        threshold: options.threshold,
      },
      iframePosition: {
        yPosition: currentFramePosition.y,
        xPosition: currentFramePosition.x,
        scrollY: currentFramePosition.scrollY,
      },
    }

    // it should be observable
    if (!widgetInitiallyInView) {
      setIsObservable(true)
      //TODO: for testing purposes, will be removed later, only send to DD but not ETS
      captureUserAction('IS_OBSERVABLE_WIDGET', { reportToEts: false, eventDetails })
    }

    // it should NOT observable
    if (widgetInitiallyInView) {
      //TODO: for testing purposes, will be removed later, only send to DD but not ETS
      captureUserAction('IS_NOT_OBSERVABLE_WIDGET', { reportToEts: false, eventDetails })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return { elementRef }
}
