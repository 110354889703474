import { gray, primary } from 'theme'

export default {
  itemsCountsTag: {
    display: 'inline-flex',
    minWidth: 31,
    backgroundColor: gray.gray4,
    padding: '0px 8px',
    borderRadius: '100px',
    justifyContent: 'center',
    alignItems: 'center',
  },

  table: {
    '& .ant-table-tbody > tr > td': {
      padding: '12px',
    },
    '& .ant-table-tbody > tr.active-order, & .ant-table-tbody > tr.active-order:hover, & .ant-table-tbody > tr.active-order:hover > td':
      {
        backgroundColor: primary.primary1,
      },
  },
}
