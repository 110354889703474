import { WidgetRenderState, WidgetSubjectRequirementValidationResultStatus } from 'types'

export const computeWidgetRenderState = (opts?: {
  loading?: boolean
  loadError?: any
  subjectsValidationStatus?: WidgetSubjectRequirementValidationResultStatus
  crashed?: boolean
  rendered?: boolean
}): WidgetRenderState => {
  if (opts.loading) {
    return 'loading'
  }

  if (opts.loadError) {
    return 'load-error'
  }

  switch (opts.subjectsValidationStatus) {
    case 'failed':
      return 'failed-requirements'
    case 'pending':
      return 'pending-requirements'
  }

  if (opts.crashed) {
    return 'crashed'
  }

  return 'rendered'
}
