import React from 'react'
import { Typography } from 'antd'
import { createUseStyles } from 'react-jss'
import { useTranslation } from 'hooks/useTranslation'
import { SingleComment } from 'types/api/commentApi/getOrderComments'
import styles from './Comments.styles'
import { commentTagType, CommentView } from './CommentView'
import { WidgetSize } from 'types/widgets/historyOfEvents/tabs'

const { Text } = Typography

const useStyles = createUseStyles(styles)

type Props = {
  comment: SingleComment
  shouldCommentBeHighlighted: boolean
  mode: WidgetSize
}

const CompensationComment = ({ comment, shouldCommentBeHighlighted, mode }: Props) => {
  const classes = useStyles()
  const { t } = useTranslation()
  return (
    <CommentView
      mode={mode}
      comment={comment}
      shouldCommentBeHighlighted={shouldCommentBeHighlighted}
      tag={{
        type: commentTagType.thunderbolt,
        text: t('History of Events Widget.Event Types.Compensation'),
      }}
    >
      <Text className={classes.commentText}>
        {comment?.agent_id && (
          <Text>{` ${t('History of Events Widget.Content.Created voucher')} `}</Text>
        )}

        {comment?.additional_data?.compensation?.voucher_currency &&
          comment?.additional_data?.compensation?.voucher_value?.toString() && (
            <Text strong>
              {`${comment.additional_data.compensation.voucher_currency} ${comment.additional_data.compensation.voucher_value}`}
            </Text>
          )}

        {comment?.additional_data?.compensation?.compensation_purpose && (
          <React.Fragment>
            <Text>{` ${t('History of Events Widget.Content.For reason')} `}</Text>
            <Text strong>
              {`${comment.additional_data.compensation.compensation_purpose?.replace(/_/g, ' ')}.`}
            </Text>
          </React.Fragment>
        )}

        {comment?.additional_data?.compensation?.voucher_code && (
          <React.Fragment>
            <Text>{` ${t('History of Events Widget.Content.Voucher Code')}: `}</Text>
            <Text strong>{`${comment.additional_data.compensation.voucher_code}.`}</Text>
          </React.Fragment>
        )}
      </Text>
    </CommentView>
  )
}

export default CompensationComment
