import { useState, useCallback } from 'react'

export const useForceUpdate = () => {
  const [, setState] = useState(1)

  const callback = useCallback(() => {
    setState((value) => (value === 1 ? -1 : 1))
  }, [])

  return callback
}
