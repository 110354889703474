import React from 'react'

// Utils
import { createUseStyles } from 'react-jss'

// Types
import { OrderApiResponse } from 'types/api/orderApi/order'

// Components
import { Text } from 'shared/Text'
import { DataPoint } from 'shared/DataPoint'

import { useTranslation } from 'hooks/useTranslation'

// Relative Path
import styles from './PaymentMethodListItemDataPoint.styles'
import { getMainPaymentMethod } from 'utils/getters/getMainPaymentMethod'
import { getSecondaryPaymentMethods } from 'utils/getters/getSecondaryPaymentMethods'
import { UnifiedIcon } from 'shared'

export type PaymentMethodProps = {
  order?: OrderApiResponse
}

const useStyles = createUseStyles(styles)

export const PaymentMethodListItemDataPoint = ({ order }: PaymentMethodProps) => {
  const classes = useStyles()
  const { t } = useTranslation()

  if (!order) return null

  const mainPaymentMethod = getMainPaymentMethod(order)

  const secondaryPaymentMethods = getSecondaryPaymentMethods(order)

  const label = t('Order Widget.Tabs.Summary.Payment Method')

  const dataPointValue = (
    <div className={classes.wrapper}>
      <div className={classes.paymentMethodWrapper}>
        <UnifiedIcon icon={mainPaymentMethod.icon} />
        <Text.Primary className={classes.text}>{mainPaymentMethod.label}</Text.Primary>
      </div>
      {secondaryPaymentMethods.map((secondaryPaymentMethod) => (
        <div className={classes.paymentMethodWrapper} key={secondaryPaymentMethod.payment_method}>
          <UnifiedIcon icon={secondaryPaymentMethod.icon} />
          <Text.Primary className={classes.text}>{secondaryPaymentMethod.label}</Text.Primary>
        </div>
      ))}
    </div>
  )

  return (
    <DataPoint
      type='custom'
      label={label}
      render={dataPointValue}
      name={mainPaymentMethod.payment_method}
    />
  )
}
