export default {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 20,
  },
  title: {
    fontSize: 23,
    padding: 16,
    color: 'rgba(0,0,0,0.85)',
  },
  content: {
    color: 'rgba(0,0,0,0.65)',
  },
  idText: {
    fontSize: '14px',
    marginLeft: '10px',
    fontWeight: '500',
  },
  button: {
    paddingLeft: '4px',
    paddingRight: '4px',
  },
}
