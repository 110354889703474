import { createUseStyles } from 'react-jss'

export const useTimelineStyles = createUseStyles({
  time: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
  },
  addDays: {
    marginRight: '5px',
    color: 'rgba(0, 0, 0, 0.30)',
  },
  delay: {
    position: 'absolute',
    width: 75,
    right: 70,
    top: 10,
  },
  item: {
    paddingBottom: '25px !important',
    '&:last-child': {
      paddingBottom: '0 !important',
    },
    '& .ant-timeline-item-label': {
      width: 'calc(29% - 20px) !important',
    },
    '& .ant-timeline-item-label:has(> div.extra-fix)': {
      top: -12,
      '& .ant-col-24:not(&.extra-fix)': {
        display: 'block',
        marginTop: '1px',
      },
    },
    '& .ant-timeline-item-head': {
      backgroundColor: 'transparent',
      left: '29% !important',
    },
    '& .ant-timeline-item-tail': {
      left: '29% !important',
    },
    '& .ant-timeline-item-content': {
      left: '29% !important',
      width: '60% !important',
    },
  },
})
