// Types
import { OrderApiResponse } from 'types/api/orderApi/order'
import { OrderWidgetInvoice } from 'contexts/entity/types'

import checkDataPointAvailability from 'utils/checkDataPointAvailability'

// Relative Path
import { useCallback } from 'react'
import { useTranslation } from 'hooks/useTranslation'
import { useTransformOrderAdditionalCharges } from './useTransformOrderAdditionalCharges'

export const useTransformOrderInvoiceData = () => {
  const transformOrderAdditionalCharges = useTransformOrderAdditionalCharges()
  const { t } = useTranslation()

  return useCallback(
    (data: OrderApiResponse, invoice: OrderWidgetInvoice) => {
      const basketValue = data?.order?.order_value || 0

      // all are subtractions
      const loyalityPointsValue = 0 - (data?.customer?.payment?.loyalty_point_balance || 0)
      const voucherValue = 0 - (data?.customer?.payment?.coupon || 0)
      let discountValue = 0 - (data?.customer?.payment?.discount || 0)
      let discountJokerValue = 0 - (data?.vendor?.charges?.joker_discount_fee || 0)

      const isDiscountJokerAvailable = checkDataPointAvailability(
        invoice.discount_joker?.displayRule,
        !!discountJokerValue,
      )

      if (isDiscountJokerAvailable) {
        // We are excluding discount joker value from discount value to show it seperately when discount joker is active
        discountValue -= discountJokerValue
      } else {
        // We are setting discount joker value to 0 because discountValue already includes discount joker value
        // and we don't want to exclude it twice from sub total value.
        discountJokerValue = 0
      }

      const additionalCharges = transformOrderAdditionalCharges(data, invoice.additional_charges)

      const subtotalValue =
        basketValue + discountValue + discountJokerValue + additionalCharges.totalAmount

      const totalValue = subtotalValue + voucherValue + loyalityPointsValue

      const fields = {
        basketValue: {
          label: t('Order Widget.Tabs.Invoice.Basket Value'),
          value: basketValue,
        },

        discount: {
          label: t('Order Widget.Tabs.Invoice.Discount'),
          value: discountValue,
        },

        discount_joker: {
          label: t('Order Widget.Tabs.Invoice.Discount (Joker)'),
          value: discountJokerValue,
        },

        additionalCharges: {
          label: t('Order Widget.Tabs.Invoice.Additional Charges'),
          value: additionalCharges.totalAmount,
        },

        subtotal: {
          label: t('Order Widget.Tabs.Invoice.Subtotal'),
          value: subtotalValue,
        },

        voucher: {
          label: t('Order Widget.Tabs.Invoice.Voucher'),
          value: voucherValue,
        },

        total: {
          label: t('Order Widget.Tabs.Invoice.Total'),
          value: totalValue,
        },

        loyalityPoints: {
          label: t('Order Widget.Tabs.Invoice.Loyalty Points'),
          value: loyalityPointsValue,
        },
      }

      return {
        fields,
        additionalCharges,
      }
    },
    [transformOrderAdditionalCharges, t],
  )
}
