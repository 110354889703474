import { OrderApiResponse } from 'types/api/orderApi/order'

function transformOrder(order: OrderApiResponse) {
  let orderStatus = []

  if (order.status_history && order.status_history.length > 0) {
    orderStatus = order.status_history.reverse().map(({ status, timestamp }) => {
      return {
        status,
        timestamp,
      }
    })
  }

  const transformedOrder = {
    orderId: order.order_id || '',
    currency: order.currency,
    orderTags: {
      corporate: order.corporate ? 'Corporate' : false,
      preOrder: order.preorder ? 'Pre-Order' : false,
    },
    paymentMethod: order.customer.payment.payment_method || '',
    orderDeliveryProvider: order?.delivery?.provider,
    deliveryAddress: order.delivery.location.address_text || '',
    delivery: order.delivery,
    deliveryInstructions: order.delivery.location.description || '',
    orderStatus,
    items: order.order.items?.length || 0,
    totalPrice: order.customer.payment.total_order_value || 0,
    vendor: order.vendor,
  }

  return transformedOrder
}

export default transformOrder
