import React, { useCallback, useMemo } from 'react'
import { createUseStyles } from 'react-jss'
import { Typography } from 'antd'
import { SelectBox } from 'shared'
import type { SelectOption } from 'shared'
import { COMP_ISSUE_TYPE_MENU_ID } from 'constants/widgetIds'
import { useTranslation } from 'hooks/useTranslation'
import styles from '../CompensationModalView.styles'
import { IssueType } from 'contexts/entity/types'

const useStyles = createUseStyles(styles)
const { Text } = Typography

type Props = {
  selectedIssueType: IssueType
  issueTypes: IssueType[]
  setIssueType: (issue: IssueType) => void
}

export const IssueTypeSelect = ({ selectedIssueType, issueTypes, setIssueType }: Props) => {
  const classes = useStyles()
  const { t } = useTranslation()

  // Preselect single available issue type
  const isOnlyOneIssueAvailable = issueTypes.length === 1

  const mapIssueToOption = useCallback(
    (issue: IssueType): SelectOption => ({
      value: issue.issue,
      label: t(`Actions Widget.Actions.Compensation.Issue Types.${issue.issue_translation_key}`),
    }),
    [t],
  )

  const selectedIssueOption = selectedIssueType ? mapIssueToOption(selectedIssueType) : undefined

  const availableIssueTypeOptions = useMemo(() => {
    return issueTypes.map(mapIssueToOption)
  }, [issueTypes, mapIssueToOption])

  const handleSelectIssueType = useCallback(
    (issueTypeOption) => {
      const selectedIssue = issueTypes.find(
        (issueType) => issueType.issue === issueTypeOption.value,
      )

      return setIssueType(selectedIssue)
    },
    [issueTypes, setIssueType],
  )

  const label = t('Actions Widget.Actions.Compensation.Issue Type')
  const placeholder = t('Actions Widget.Actions.Compensation.Select an option')

  return (
    <div className={classes.dropdownHolder}>
      <Text className={classes.dropdownLabels}>{label}:</Text>
      <SelectBox
        disabled={isOnlyOneIssueAvailable}
        testId={COMP_ISSUE_TYPE_MENU_ID}
        options={availableIssueTypeOptions}
        value={selectedIssueOption}
        placeholder={placeholder}
        onSelectChange={handleSelectIssueType}
      />
    </div>
  )
}
