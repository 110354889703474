export default {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'middle',
    padding: (props) => (props.forMini ? '24px 5px' : '24px 0'),
    gap: '16px',
  },
  label: {
    marginRight: '5px',
  },
  select: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  orderInfo: {
    display: 'flex',
  },

  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
}
