import React from 'react'

// Types
import { RefundDetailsWithTransactions, PaymentMedium } from 'types/api/orderApi/order'

// Hooks
import { useTranslation } from 'hooks/useTranslation'

// Relative Path
import SinglePaymentWidget from './SinglePaymentWidget'
import { getPaymentMediumMeta } from 'utils/getPaymentMediumMeta'
import { PaymentMethods } from 'contexts/entity/types'
import { getPaymentMethodMeta } from 'utils/getPaymentMethodMeta'

type RefundSinglePaymentWidgetProps = {
  refund: RefundDetailsWithTransactions
  paymentMethod: PaymentMethods
}

function RefundSinglePaymentWidget({ refund, paymentMethod }: RefundSinglePaymentWidgetProps) {
  const paymentMediumCap = refund.payment_medium?.toUpperCase()
  const { t } = useTranslation()

  if (paymentMediumCap === PaymentMedium.PSP_REFUND) {
    const { icon, label } = getPaymentMethodMeta(paymentMethod)

    return <SinglePaymentWidget icon={icon} label={t(`Order Widget.Tabs.Invoice.${label}`)} />
  }

  const { icon, label } = getPaymentMediumMeta(paymentMediumCap as PaymentMedium)

  return <SinglePaymentWidget icon={icon} label={t(`Order Widget.Tabs.Invoice.${label}`)} />
}

export default RefundSinglePaymentWidget
