import moment from 'moment'
import { FulfillmentApiResponse, OrderApiResponse } from 'types/herocare'
import { SDK } from 'types'

type Props = {
  sdk: SDK
  order: OrderApiResponse
  fulfillment: FulfillmentApiResponse
}

export const getUpdatedDeliveryTime = ({ sdk, order, fulfillment }: Props) => {
  const promisedDeliveryTimestamp =
    fulfillment?.promised_delivery_at || order.promised_customer_timestamp

  // check for new delivery time from order history accepted status
  const lastAcceptedStatus = order.status_history?.find((status) => status.status === 'ACCEPTED')
  // New delivery time
  const newDeliveryTimestamp = lastAcceptedStatus?.accepted?.estimated_delivery_time

  // show new delivery time
  let showNewDeliveryDateTime = false
  let newDeliveryDateTime = newDeliveryTimestamp // default

  if (promisedDeliveryTimestamp && newDeliveryTimestamp) {
    newDeliveryDateTime = sdk.datetimeFormatter.formatTime(newDeliveryTimestamp)
    // only show new delivery time if it's different from initial promised delivery time
    showNewDeliveryDateTime = !moment(newDeliveryTimestamp).isSame(
      promisedDeliveryTimestamp,
      'minute',
    )
  }

  return showNewDeliveryDateTime ? newDeliveryDateTime : null
}
