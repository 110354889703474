/**
 * Voucher Tab
 * makes a call to VouicherAPI and renders VoucherView when data received
 * */

// libs
import React, { useContext, useEffect } from 'react'
// contexts and typers
import { DataContext } from 'contexts/data/DataContext'
import { DataAction } from 'contexts/data/types'
// hooks
import { useTranslation } from 'hooks/useTranslation'
// components
import VoucherView from './VoucherView'
import { useApiService } from 'hooks/useApiService'
import { getCustomerVouchers } from 'services/VoucherApi/getCustomerVouchers'
import { WidgetErrorHandler } from 'components/WidgetErrorHandler/widgetErrorHandler'

import { createPluggableWidget } from 'factory/createPluggableWidget'
import { EmittedEventName } from 'utils/eventEmitter'

const Voucher = createPluggableWidget(
  ({ globalEntityId, customerId, containerType, sdk }) => {
    // pull language content
    const { t } = useTranslation()

    // pull data state and dispatch from data context
    const { dataDispatch, dataState } = useContext(DataContext)
    const { SET_VOUCHERS } = DataAction
    const { fullyRefundedVoucher, compensatedVoucher } = dataState

    const hasCustomer = Boolean(customerId)

    const {
      loading: isLoading,
      error,
      loadService: refresh,
      data: vouchers,
    } = useApiService({
      service: getCustomerVouchers,
      params: {
        customerId,
        entityId: globalEntityId,
      },
      shouldLoad: hasCustomer,
      deps: [
        customerId,
        globalEntityId,

        // maintain this for old layout.
        fullyRefundedVoucher,
        compensatedVoucher,
      ],
      onSuccess(res) {
        dataDispatch({
          type: SET_VOUCHERS,
          payload: { vouchers: res.data },
        })
      },
    })

    useEffect(() => {
      const callback = () => refresh()

      const events: Array<EmittedEventName> = [
        'PARTIAL_REFUND_SUCCESS',
        'FULL_REFUND_SUCCESS',
        'COMPENSATION_SUCCESS',
      ]

      const listeners = sdk.eventEmitter.addEventsListener({ names: events, callback })

      return () => {
        sdk.eventEmitter.removeEventListeners(listeners)
      }
    }, [refresh, sdk.eventEmitter])

    return (
      <WidgetErrorHandler
        loading={isLoading}
        errorPayload={error?.errorPayload}
        loadingText={t('Interface.Loading Vouchers')}
        onRetry={refresh}
        missingParameters={[!hasCustomer && 'customerId']}
      >
        {() => <VoucherView vouchers={vouchers || []} containerType={containerType} />}
      </WidgetErrorHandler>
    )
  },
  {
    category: 'data_lookup',
    deriveConfig() {
      return {}
    },
    deriveSubjectsRequirements() {
      return {
        all_of: ['customerId'],
      }
    },
  },
)

export default Voucher
