/**
 * creates an error log entry: logs the entry to console and returns it
 * */
import capitalizeFirstLetters from 'utils/capitalizeFirstLetters'
import { logError } from 'utils/reporting/logError'
import { unknownMessage } from './constants'

function processApiErrors(
  t: any,
  err: any,
  api: string,
  orderId?: string,
  globalEntityId?: string,
  shouldLogError?: boolean,
) {
  // create custom entry
  let userMessage: string = t('Messages.The requested resource could not be found')

  // message for logging -> if found, set to API err msg, otherwise generic msg
  let logMessege: string = t('Messages.There has been a problem with retrieving data')
  if (err && err.response && err.response.data && err.response.data.error) {
    logMessege = err.response.data.error
  }

  if (err.response) {
    if (err.response.status === 400) {
      if (err.response.data.error === 'voucher value limit exceeded') {
        userMessage = t(
          'Messages.The limit value of a voucher is exceeded, please try again with a lower voucher value',
        )
      } else if (
        err.response.data.error === 'modify delivery time can be applied only for pre-order'
      ) {
        userMessage = t(
          'Actions Widget.Actions.Change Delivery Time.Changing delivery time is possible only for unaccepted pre-orders',
        )
      } else {
        userMessage = t('Messages.Bad request, please check if everything is correct')
      }
    } else if (err.response.status === 401) {
      if (err.response.data.error === 'agent email not found') {
        userMessage = t(
          'Messages.Current user is not registered in local database, please contact your supervisor',
        )
      } else {
        userMessage = t('Messages.Token expired, please refresh the page and try again')
      }
    } else if (err.response.status === 403) {
      userMessage = t(
        'Messages.The requested resource is forbidden for the user, please contact a supervisor',
      )
    } else if (err.response.status === 404) {
      userMessage = t('Messages.The requested resource could not be found')
    } else if (err.response.status === 409) {
      if (api === 'payment-api-refund-to-wallet-psp') {
        userMessage =
          err?.response?.data?.error &&
          capitalizeFirstLetters(err?.response.data.error.replace(/_/g, ' '))
      } else if (err.response.data.error === 'voucher for the order already exists') {
        userMessage = t('Messages.Voucher for the order already exists')
      } else {
        userMessage = t('Messages.The resource is changed in the server, please try again')
      }
    } else if (err.response.status === 422) {
      userMessage = t('Messages.Voucher wallet run out of budget, please contact supervisor')
    } else if (err.response.status === 503) {
      userMessage = t(
        'Messages.The requested resource is temporarily unavailable. Please try again',
      )
    }
  }

  // log error for tracking
  if (shouldLogError) {
    logError({
      type: 'process-api-error',
      api_message: logMessege,
      responsible_api: api,
      status: err.response && err.response.status ? err.response.status : unknownMessage,
      order_id: orderId ? orderId : unknownMessage,
      global_entity_id: globalEntityId ? globalEntityId : unknownMessage,
    })
  }

  const errLog = {
    api,
    status: err.response && err.response.status ? err.response.status : 404,
    message: userMessage,
    orderId: orderId ? orderId : unknownMessage,
    globalEntityId: globalEntityId ? globalEntityId : unknownMessage,
  }

  // return err
  return errLog
}

export default processApiErrors
