import { Input, InputRef, Space, Spin, Tooltip } from 'antd'
import React, { FC, ReactNode, useEffect, useRef, useState } from 'react'
import { createUseStyles } from 'react-jss'
import { primary, text, gray } from 'theme'
import { Text } from '../Text'
import { UnifiedIcon } from '../UnifiedIcon'
import { getEntityNameByEntityId } from 'utils/countries'
import ReactCountryFlag from 'react-country-flag'
import { getEntityCountryCode } from 'utils/getEntityCountryCode'
import SingleTag from 'components/Tags/SingleTag'
import type { PaymentMethodTextAndIcon } from 'hooks/useGetOrderPaymentMethodsTextsAndIcons'
import { CopyButton } from '../CopyButton'
import { PhoneDialButton } from '../PhoneDialButton'
import { Box } from 'shared/Box'
import { Button } from 'shared/Button'
import { useTranslation } from 'hooks/useTranslation'
import { CloseOutlined, InfoCircleOutlined } from '@ant-design/icons'
import { Notification } from 'shared/Notification'
import { ApiErrorPayload } from 'types/error'
import { DisplayRules, PhoneDecorator } from 'types'
import { Link } from 'shared/Link'
import { useSdk } from 'contexts/SdkProvider'
import { getInlineAntdPopupNode } from 'utils/antd/getInlinePopupNode'
import { isCallable } from 'utils/is/isCallable'
import { Status } from './Status'

export const Spacing = {
  HORIZONTAL: 8,
  VERTICAL: 2,
}

interface CommonDataPointProps<DataPointValueType = any> {
  allowCopy?: boolean
  onCopy?: () => void

  copyEventName?: string

  label: string
  type: string
  /**
   * data point value font size
   */
  color?: string
  /**
   * data point value font size
   */
  size?: number

  /**
   * the datapoint name
   */
  name: string

  display_rules?: DisplayRules

  alignment?: 'vertical' | 'horizontal'

  /**
   * overrides the column span of this data point
   */
  colSpan?: number

  /**
   * the loader can be used to load the data point value asynchronously from the network
   * loaded value has higher priority than the data point value
   *
   * this component is responsible for handling the loading of the datapoint, and displaying load errors,
   * handling retries and other things
   */
  loader?: () => Promise<DataPointValueType>

  /**
   * you can define a custom render function to handle rendering of error message when error occurs during loading
   * of data point value from the network
   */
  loadErrorRender?:
    | ReactNode
    | ((props: {
        /**
         * the api error payload as caught from the api loader
         */
        error: ApiErrorPayload | string
      }) => ReactNode)

  /**
   * tooltip to display on the value
   */
  tooltip?: ReactNode

  /**
   * postfix to display after the value and buttons
   */
  postfix?: string
}

export interface DefaultDataPointProps extends CommonDataPointProps<string> {
  type: 'default'
  value: string

  editable?: boolean
  onSave?: (value: string) => Promise<boolean | string>
}

export interface TelDataPointProps extends CommonDataPointProps<string> {
  type: 'tel'
  /**
   * the telephone number
   */
  value: string

  onDial?: () => void
  dialEventName?: string

  /**
   * telephone decorator to apply to the telephone number, defaults to the one set on the
   * entity config
   */
  decorator?: PhoneDecorator
}

export interface ReasonDataPointProps extends CommonDataPointProps<string> {
  type: 'reason'
  value: string
}

export interface StatusDataPointProps
  extends CommonDataPointProps<{ statusText: string; statusColor: string; reason?: string }> {
  type: 'status'
  value: {
    statusText: string
    statusColor: string
    delayTime?: { text: string; color: string }
    cancellationReason?: {
      title: string
      description: string
    }
  }
}

export interface PaymentMethodsDataPointProps
  extends CommonDataPointProps<PaymentMethodTextAndIcon[]> {
  type: 'payment_methods'
  value: PaymentMethodTextAndIcon[]
}

export interface CountryDataPointProps extends CommonDataPointProps<string> {
  type: 'country'
  /**
   * the country's global entity id
   */
  value: string
}

export interface TagsDataPointProps extends CommonDataPointProps<string[]> {
  type: 'tags'
  /**
   * array of tags
   */
  value: string[]
}

export interface LinkDataPointProps extends CommonDataPointProps<string[]> {
  type: 'link'
  to: string
  /**
   * array of tags
   */
  value: string

  onClick?: (e) => void
}

export interface CustomDataPointRenderProps<ValueType> {
  /**
   * value provided or loaded by the api loader
   */
  value: ValueType

  /**
   * changes the value of the loaded dataPoint
   */
  setValue: (value: ValueType) => void

  /**
   * sets a custom error for the datapoint
   */
  setError: (error: ApiErrorPayload | string) => void
}

export interface CustomDataPointProps extends CommonDataPointProps {
  type: 'custom'

  /**
   * renders the custom data point value
   */
  render: ReactNode | ((props: CustomDataPointRenderProps<any>) => ReactNode)

  value?: any

  renderError?:
    | ReactNode
    | ((props: { error: any; setValue: (value) => void; setError: (error) => void }) => ReactNode)
}

export type DataPointProps =
  | DefaultDataPointProps
  | TelDataPointProps
  | ReasonDataPointProps
  | StatusDataPointProps
  | PaymentMethodsDataPointProps
  | CountryDataPointProps
  | TagsDataPointProps
  | CustomDataPointProps
  | LinkDataPointProps

const useStyles = createUseStyles({
  flex: {
    display: 'flex',
  },
  alignCenter: {
    display: 'flex',
    alignItems: 'center',
    lineHeight: '1px',
  },
  postfix: {
    paddingLeft: Spacing.HORIZONTAL,
    borderLeft: `1px solid ${gray.gray5}`,
  },
})

const DataPointValue: FC<
  DataPointProps & {
    loadedValue?: any
    setLoadedValue: CustomDataPointRenderProps<any>['setValue']
    setLoadError: CustomDataPointRenderProps<any>['setError']
  }
> = (props) => {
  const styles = useStyles()
  const { t } = useTranslation()

  const { captureUserAction, caseId, phoneDecorator } = useSdk()

  const { allowCopy, tooltip, onCopy, loadedValue, setLoadError, setLoadedValue, copyEventName } =
    props

  const isEditable = props.type === 'default' && props.editable

  const [edit, setEdit] = useState(false)

  const [editErrorMessage, setEditErrorMessage] = useState('')
  const [isSavingEditValue, setIsSavingEditValue] = useState(false)

  const inputRef = useRef<InputRef>()

  const toggleEdit = () => setEdit((prev) => !prev)

  const handleCopy = () => {
    if (copyEventName) {
      captureUserAction(copyEventName)
    }
    onCopy?.()
  }

  const handleDial = () => {
    const { dialEventName, onDial } = props as TelDataPointProps
    if (dialEventName) {
      captureUserAction(dialEventName)
    }
    onDial?.()
  }

  const onEditTextChange = () => {
    if (editErrorMessage) {
      setEditErrorMessage('')
    }
  }

  const saveEditValue = () => {
    const { onSave = () => Promise.resolve(true) } = props as DefaultDataPointProps
    setIsSavingEditValue(true)
    const value = (inputRef.current?.input?.value || '').trim()

    onSave(value)
      .then((result) => {
        if (result === true) {
          Notification.success({
            message: t('Widgets Common.Saved'),
          })
        } else {
          const errorMessage =
            typeof result === 'string' ? result : t('Widgets Common.Failed to save')
          setEditErrorMessage(errorMessage)
          Notification.error({
            message: errorMessage,
          })
        }
      })
      .finally(() => setIsSavingEditValue(false))
  }

  const givenValue = loadedValue ?? props.value
  let value

  switch (props.type) {
    case 'status':
      value = givenValue?.statusText
      break

    case 'custom':
      value = givenValue
      break

    case 'reason':
      value = givenValue
      break

    case 'country':
      value = getEntityNameByEntityId(givenValue)
      break

    case 'tags':
      value = givenValue.join(',')
      break

    case 'tel':
      value = givenValue
      break

    case 'link':
      value = props.to
      break

    case 'default':
      value = givenValue
      break
  }

  const render = () => {
    switch (props.type) {
      case 'status':
        return (
          <Status
            cancellationReason={givenValue.cancellationReason}
            statusColor={givenValue.statusColor}
            value={value}
            delayTime={givenValue.delayTime}
          />
        )

      case 'custom':
        return isCallable(props.render)
          ? props.render({ value: loadedValue, setValue: setLoadedValue, setError: setLoadError })
          : props.render

      case 'reason':
        return value

      case 'payment_methods':
        return (
          <Space size={4} wrap>
            {givenValue.map(({ text, icon }, indx) => {
              return (
                <Space className={styles.alignCenter} size={2} key={text || indx}>
                  <UnifiedIcon icon={icon} /> {text}
                </Space>
              )
            })}
          </Space>
        )

      case 'country':
        return (
          <Space className={styles.alignCenter}>
            <ReactCountryFlag
              svg
              countryCode={getEntityCountryCode(givenValue) || ''}
              style={{
                width: '100%',
                maxWidth: '21px',
                height: 'auto',
                border: `1px solid ${gray.gray7}`,
                borderRadius: '2px',
              }}
            />
            {value}
          </Space>
        )

      case 'tags':
        return (
          <Space size={Spacing.HORIZONTAL}>
            {givenValue.map((value) => (
              <SingleTag key={value} value={value} />
            ))}
          </Space>
        )

      case 'link':
        return (
          <Link href={props.to} onClick={props.onClick} target='_blank' rel='noopener'>
            <Space size={Spacing.HORIZONTAL}>{givenValue}</Space>
          </Link>
        )

      case 'tel':
        return value

      default:
        return value
    }
  }

  const content = render()

  return (
    <Space className={styles.flex} size={Spacing.HORIZONTAL} align='baseline'>
      {edit && (
        <Space size={Spacing.HORIZONTAL}>
          <Input
            size='small'
            ref={inputRef}
            defaultValue={value as string}
            status={editErrorMessage ? 'error' : undefined}
            onChange={onEditTextChange}
            disabled={isSavingEditValue}
          />

          {!isSavingEditValue && <CloseOutlined onClick={toggleEdit} />}

          {!isSavingEditValue && (
            <UnifiedIcon icon='CheckOutlined' onClick={saveEditValue} size={14} />
          )}

          {isSavingEditValue && <UnifiedIcon icon='LoadingOutlined' size={12} />}
        </Space>
      )}

      {!edit && (
        <Text.Primary color={props.color || undefined} fontSize={props.size || 14}>
          {content === '' || content === undefined || content === null ? '-' : content}
        </Text.Primary>
      )}

      {/* edit button */}
      {isEditable && !edit && (
        <Button
          tooltip={t('Widgets Common.Edit')}
          aria-label={t('Widgets Common.Edit')}
          icon='EditOutlined'
          type='outlined'
          color='primary'
          borderColor={gray.gray5}
          size='small'
          borderRadius={4}
          onClick={toggleEdit}
        />
      )}

      {/* copy button */}
      {allowCopy && typeof value === 'string' && value !== '' && !edit ? (
        <CopyButton text={value} onCopy={handleCopy} />
      ) : null}

      {/* dial button */}
      {props.type === 'tel' && !edit && (
        <PhoneDialButton
          decorator={props.decorator || phoneDecorator}
          phoneNumber={givenValue}
          onClick={handleDial}
          caseId={caseId}
        />
      )}

      {props.postfix && (
        <div className={styles.postfix}>
          <Text.Primary>{props.postfix}</Text.Primary>
        </div>
      )}

      {tooltip && (
        <Tooltip getPopupContainer={getInlineAntdPopupNode} title={tooltip}>
          <span>
            <UnifiedIcon icon={InfoCircleOutlined} color={primary.primary6} size={12} />
          </span>
        </Tooltip>
      )}
    </Space>
  )
}

export const DataPoint: FC<DataPointProps> = (props) => {
  const { name, alignment = 'vertical', loader } = props
  const { t } = useSdk()

  const [isLoading, setIsLoading] = useState(Boolean(loader))
  const [loadedValue, setLoadedValue] = useState<any>(undefined)

  const [loadError, setLoadError] = useState<ApiErrorPayload | string>('')

  useEffect(() => {
    if (loader) {
      setIsLoading(true)
      loader()
        .then(setLoadedValue)
        .catch(setLoadError)
        .finally(() => setIsLoading(false))
    }
  }, [loader])

  const ShowError: FC = () => {
    if (props.loadErrorRender) {
      if (isCallable(props.loadErrorRender)) {
        return props.loadErrorRender({ error: loadError })
      }
      return props.loadErrorRender
    }

    return t(`errors.not_available.title`) as any
  }

  return (
    <Box data-datapoint={name} display={alignment === 'vertical' ? 'block' : 'inline-flex'}>
      {/* label */}
      <Text.Secondary
        data-label={props.label}
        color={text.secondary}
        Component='p'
        mb={alignment === 'vertical' ? Spacing.VERTICAL : 0}
        mr={alignment === 'horizontal' ? Spacing.HORIZONTAL : 0}
        data-datapoint-label
      >
        {props.label}
      </Text.Secondary>

      {/* datapoint */}
      <div data-datapoint-value>
        {isLoading && <Spin size='small' />}

        {!isLoading && loadError ? <ShowError /> : null}

        {!isLoading && !loadError && (
          <DataPointValue
            {...props}
            loadedValue={loadedValue}
            setLoadedValue={setLoadedValue}
            setLoadError={setLoadError}
          />
        )}
      </div>
    </Box>
  )
}
