import { ApiErrorConfig } from './voucherApiErrorConfig'

export const paymentApiErrorConfig: ApiErrorConfig<'PaymentApi'> = {
  PaymentApi: {
    createCompensationToWallet: {
      400: {
        '*': {
          screen: 'error',
          heading: "Couldn't compensate to wallet",
          copyText: 'An error has occured',
        },

        METHOD_NOT_ALLOWED: {
          screen: 'error',
          heading: 'Method not allowed',
          copyText: "Couldn't compensate to wallet",
        },
      },

      401: {
        '*': {
          screen: 'warning',
          heading: "Couldn't compensate to wallet",
          copyText: 'An error has occured{{dot}} Go back to try again or select Quit to exit',
        },
      },

      403: {
        '*': {
          screen: 'instruct',
          heading: 'Permission required',
          copyText:
            'Compensation not created{{dot}} Ask a supervisor to check your Oneview permissions and try again',
        },
      },
      409: {
        ALREADY_COMPENSATED: {
          screen: 'error',
          heading: 'Already compensated',
          copyText: 'Compensation already issued for this reason',
        },
      },
      422: {
        OUT_OF_BUDGET: {
          screen: 'error',
          heading: 'Out of budget',
          copyText: 'Refund or compensation is out of budget',
        },
      },
      429: {
        TOO_MANY_REQUESTS: {
          screen: 'error',
          heading: 'Too many requests',
          copyText: 'Too many requests, please try again later',
        },
      },
      500: {
        '*': {
          screen: 'error',
          heading: "Couldn't compensate to wallet",
          copyText: 'An error has occured',
        },
      },
      503: {
        UPSTREAM_SERVICE_UNAVAILABLE: {
          screen: 'error',
          heading: 'Upstream service unavailable',
          copyText: 'Upstream service is unable to process the request',
        },
      },
    },
    createRefund: {
      '400': {
        '*': {
          screen: 'alert',
          heading: `Create refund case`,
          copyText:
            'A technical error has occured{{dot}} Go back and create refund case to proceed',
        },
        INVALID_REFUND_REASON: {
          screen: 'alert',
          heading: `Selected issue type is not valid`,
          copyText: 'Refund not created because the refund reason provided is invalid',
        },
        FAILED_TO_CREATE_REFUND_CASE: {
          screen: 'alert',
          heading: `Couldn't create refund case`,
          copyText: 'A technical error has occured{{dot}} Go back and try different refund method',
        },
      },
      '409': {
        '*': {
          screen: 'alert',
          heading: `Create refund case`,
          copyText:
            'A technical error has occured{{dot}} Go back and create refund case to proceed',
        },
        FAILED_TO_CREATE_REFUND_CASE: {
          screen: 'error',
          heading: `Couldn't create refund case`,
          copyText: `Couldn't create refund case due to error`,
        },
      },
      '401': {
        '*': {
          screen: 'warning',
          heading: `Couldn't refund to {{method}}`,
          copyText: 'An error has occured{{dot}} Go back o try again',
        },
      },
      '403': {
        '*': {
          screen: 'instruct',
          heading: 'Permission required',
          copyText:
            "Couldn't create refund{{dot}} Ask a supervisor to check Oneview permissions, then select Retry to try again",
        },
      },
      429: {
        TOO_MANY_REQUESTS: {
          screen: 'error',
          heading: 'Too many requests',
          copyText: 'Too many requests, please try again later',
        },
      },
      '500': {
        '*': {
          screen: 'error',
          heading: `Couldn't refund to {{method}}`,
          copyText: 'An error has occured',
        },
      },
      503: {
        UPSTREAM_SERVICE_UNAVAILABLE: {
          screen: 'error',
          heading: 'Upstream service unavailable',
          copyText: 'Upstream service is unable to process the request',
        },
      },

      '422': {
        OUT_OF_BUDGET: {
          screen: 'error',
          heading: "Couldn't create refund",
          copyText: 'Refund or compensation is out of budget',
        },
        METHOD_NOT_ALLOWED: {
          screen: 'error',
          heading: `Refund Method not allowed`,
          copyText: 'An error has occured{{dot}} Come back later',
        },
        DAILY_CUSTOMER_REFUNDS_EXCEEDED: {
          screen: 'alert',
          heading: `Daily limit reached`,
          copyText:
            "Refund not created{{dot}} Customer's daily refund to {{method}} limit reached{{dot}} Select another refund method",
        },
        FRAUD_POSITIVE: {
          screen: 'error',
          heading: 'Fraudulent customer suspected',
          copyText: "Couldn't refund",
        },
        NO_PAYMENTS_TO_REFUND: {
          screen: 'error',
          heading: 'No payments to refund',
          copyText: "Couldn't refund",
        },
        WRONG_REASON_FOR_ALL_ITEM_SELECTED: {
          screen: 'alert',
          heading: 'Wrong reason for all items selected',
          copyText: 'All items cannot be selected for the selected reason',
        },
        NOT_ALL_ITEMS_SELECTED: {
          screen: 'alert',
          heading: 'Not all items were selected',
          copyText: 'Not all items were selected',
        },
        ALREADY_REFUNDED: {
          screen: 'error',
          heading: 'Order has a refund',
          copyText: 'Order has a refund',
        },
        ORDER_HAS_REFUND: {
          screen: 'error',
          heading: 'Order has a refund',
          copyText: 'Order has a refund',
        },
        ORDER_HAS_REDEMPTION_COMPENSATION: {
          screen: 'error',
          heading: 'Order has redemption compensation',
          copyText: 'Order already compensated',
        },
        NOT_ALLOW_REFUND: {
          screen: 'error',
          heading: 'Refund not allowed',
          copyText: 'Error creating the refund',
        },
        MISSING_ITEMS_CASE_DISABLED: {
          screen: 'error',
          heading: 'Missing items case is disabled',
          copyText: 'Refund allowed in own delivery orders',
        },
        ZERO_AMOUNT_TO_REFUND: {
          screen: 'error',
          heading: 'Zero amount available for refund',
          copyText: 'Zero amount is available for refund for this request',
        },
      },
    },
    getCustomerOrderPayments: {
      '400': {
        '*': {
          screen: 'error',
          heading: 'Payment data unavailable',
          copyText: 'An error has occured',
        },
      },
      '401': {
        '*': {
          screen: 'warning',
          heading: 'Payment data unavailable',
          copyText: 'An error has occured{{dot}} Select refresh to try again',
        },
      },
      '500': {
        '*': {
          screen: 'error',
          heading: 'Payment data unavailable',
          copyText: 'An error has occured',
        },
      },
    },
    getOrderTransactions: {
      '400': {
        '*': {
          screen: 'error',
          heading: 'Transaction data unavailable',
          copyText: 'GetOrderTransactions HTTP401',
        },
      },
      '401': {
        '*': {
          screen: 'warning',
          heading: 'Transaction data unavailable',
          copyText: 'GetOrderTransactions HTTP401',
        },
      },
      '404': {
        '*': {
          screen: 'no-data',
          heading: 'No transaction data found',
          copyText: 'An error has occured',
        },
      },
      '500': {
        '*': {
          screen: 'error',
          heading: 'Transaction data unavailable',
          copyText: 'An error has occured',
        },
      },
    },

    getCustomerWallet: {
      404: {
        '*': {
          screen: 'no-data',
          heading: 'No wallet found',
          copyText: '',
        },
      },
      500: {
        '*': {
          screen: 'error',
          heading: 'Wallet unavailable',
          copyText: 'An error has occured',
        },
      },
    },
  },
}
