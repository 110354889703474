/**
 * ModifyDeliveryAddress action
 * */

// libs
import React from 'react'
// hooks
import { useTranslation } from 'hooks/useTranslation'
// components
import ModalButtonView from 'components/ModalButtonView'
import { useCaptureUserAction } from 'hooks/events/useCaptureUserAction'
import { useWidgetViewManager } from 'contexts/widgetViewManager'
import { builtinWidgetNames, widgetTypes } from 'types/unitedUiConfig'
import { allowedActions } from 'entityConfig/allowedConfigValues'
// utils

const ModifyDeliveryAddress: React.FC = () => {
  // pull language content
  const { t } = useTranslation()

  // modal state -> modal is rendered when true
  const captureUserAction = useCaptureUserAction()

  const { activateWidgetView } = useWidgetViewManager()

  return (
    <ModalButtonView
      data-test-id={allowedActions.modifyDeliveryAddress}
      buttonText={t('Actions Widget.Actions.Change Address.Title')}
      callBack={() => {
        captureUserAction('ActionsChangeAddressOpenButtonClicked')
        activateWidgetView({
          type: widgetTypes.builtin,
          widget_name: builtinWidgetNames.modifyDeliveryAddress,
          label: {
            label_translation_key: 'Actions Widget.Actions.Change Address.Title',
          },
        })
      }}
    />
  )
}

export default ModifyDeliveryAddress
