/**
 * MiniErrorView: a generic error display component used for rider service
 * renders the passed messages as columns
 * */

// libs
import React from 'react'
// styles
import { createUseStyles } from 'react-jss'
import styles from './MiniErrorView.styles'
import { Typography } from 'antd'

interface Props {
  icon: any
  errorMessage: string
  errorSubtext?: string
}

const useStyles = createUseStyles(styles)

const MiniErrorView: React.FC<Props> = ({ icon, errorMessage, errorSubtext }) => {
  const classes = useStyles()
  const { Text } = Typography

  return (
    <div className={classes.errorContainer}>
      <img className={classes.icon} src={icon} alt='error icon' />
      <Text className={classes.noOrderMessage}>{errorMessage}</Text>
      {errorSubtext && <Text className={classes.noOrderSubMessage}>{errorSubtext}</Text>}
    </div>
  )
}

export default MiniErrorView
