export default {
  prevOrderContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: '100%',
    marginTop: '1%',
  },
  titlesHolder: {
    width: '100%',
    paddingBottom: '5px',
    marginBottom: '5px',
    marginLeft: '12px',
    paddingRight: '10px',
    boxShadow: 'inset 0px -1px 0px #F0F0F0',
  },
  orderHolder: {
    display: 'flex',
    width: 'calc(100% - 12px)',
  },
  currentOrderHolder: {
    display: 'flex',
    width: 'calc(100% - 12px)',
    backgroundColor: '#f0f5ff',
    borderRadius: '3px',
  },
  columnHeader: {
    fontWeight: 500,
    display: 'inline-block',
  },
  idHeader: {
    width: '23%',
  },
  text: {
    width: '35%',
  },
  dateText: {
    width: '29%',
    paddingLeft: '4px',
    display: 'inline-block',
  },
  priceText: {
    width: '22%',
    display: 'inline-block',
  },
  greyText: {
    color: '#414141',
  },
  statusHeader: {
    width: '12px',
    display: 'inline-block',
  },
  vendorHeader: {
    display: 'inline-block',
    width: '26%',
  },
  vendorText: {
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    display: 'inline-block',
    width: '26%',
    marginBottom: '-6px',
    paddingRight: '10px',
  },
  usedVoucherText: {
    width: '35%',
    color: '#ff5c5c',
  },
  noVoucherText: {
    width: '35%',
    color: '#ababab',
  },
  buttonHolder: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    marginTop: '5%',
  },
  button: {
    width: '50%',
  },
  '@media (min-width: 991px)': {
    dateText: {
      width: '25%',
    },
    vendorHeader: {
      width: '30%',
    },
    vendorText: {
      width: '30%',
    },
  },
  '@media (min-width: 1200px)': {
    dateText: {
      width: '75px',
    },
  },
}
