import { ApiService } from 'services/authApi/getPermissions'
import { OrderApiResponse } from 'types/api/orderApi/order'
import { GetOrderPropertyFilterParams } from './getLastOrders'

export type OrderApiV2Response = {
  orders: OrderApiResponse[]
  next_key: string
}

export const getLastOrdersV2: ApiService<
  {
    entityId: string
    propertyFilters: GetOrderPropertyFilterParams
    nextKey?: string
    pageSize?: number
    metadata?: boolean
  },
  OrderApiV2Response
> = (createClient, options) => {
  const {
    entityId,

    propertyFilters = {},
    pageSize,
    nextKey,
    metadata = false,

    clientParams,
  } = options

  const queryParams: typeof propertyFilters & {
    pagesize?: number
    next_key?: string
    metadata?: boolean
  } = {
    metadata,
    next_key: nextKey,
    ...propertyFilters,
  }

  if (pageSize) {
    queryParams.pagesize = pageSize
  }

  const uri = `OrdersAPI/v2/orders/${entityId}`

  return createClient({
    endpointName: 'getLastOrders',
    expectedResponseStatusCode: 200,
    ...clientParams,
  }).get(uri, {
    params: Object.keys(queryParams).reduce((result, key) => {
      const value = queryParams[key]
      if (typeof value !== 'undefined' && value !== '' && value !== null) {
        result[key] = value
      }
      return result
    }, {} as typeof queryParams),
  })
}
