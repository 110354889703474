import React, { useState } from 'react'

import { Tooltip } from 'antd'
import { createUseStyles } from 'react-jss'
import {
  VendorAvailabilityStatusItem,
  ClosingReason,
  VendorAvailabilityStatusUpdatePayload,
} from 'types/api/vendorApi/vendorAvailabilityStatus'
import { useCaptureUserAction } from 'hooks/events/useCaptureUserAction'
import { CloseVendorForm } from './CloseVendorForm/CloseVendorForm'
import { useSdk } from 'contexts/SdkProvider'
import { useSessionState } from 'hooks/useSessionState'
import { PopupWidgetContainer, Button } from 'shared'

enum AVAILABILITY_STATUS {
  CLOSED_TODAY = 'CLOSED_TODAY',
  CLOSED_UNTIL = 'CLOSED_UNTIL',
  OPEN = 'OPEN',
  CLOSED = 'CLOSED',
}

enum STATUS_STATE {
  CLOSE = 'CLOSE',
  CONFIRM_CLOSE = 'CONFIRM_CLOSE',
  OPEN = 'OPEN',
  CONFIRM_OPEN = 'CONFIRM_OPEN',
}

interface AvailabilityStatusWrapperProps {
  availabilityStatus: VendorAvailabilityStatusItem
  onStatusUpdate?: (data: VendorAvailabilityStatusUpdatePayload) => Promise<void>
}

export type CloseVendorState = {
  closedReason?: ClosingReason
  closedUntil?: string
}

const useStyles = createUseStyles({
  wrapperContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
  },
  actionButtonsContainer: {
    display: 'flex',
    justifyContent: 'right',
    gap: '8px',
  },
})

export const AvailabilityStatusWrapper: React.FC<AvailabilityStatusWrapperProps> = ({
  availabilityStatus,
  onStatusUpdate,
  children,
}) => {
  const classes = useStyles()
  const { t } = useSdk()
  const { uiVersion } = useSessionState()
  const [statusState, setStatusState] = useState<STATUS_STATE>(null)
  const [closeVendorState, setCloseVendorState] = useState<CloseVendorState>({
    closedReason: null,
    closedUntil: null,
  })

  const captureUserAction = useCaptureUserAction()

  const disableConfirmClose = Object.values(closeVendorState).some((property) => !property)
  const tooltipTitle = !availabilityStatus?.changeable
    ? !availabilityStatus?.closedReason
      ? t('widgets.vendor_availability.outside_working_hours')
      : t('widgets.vendor_availability.closed_by_vendor_monitor')
    : null

  const updateCloseVendorState = (newState: CloseVendorState) => {
    setCloseVendorState({ ...closeVendorState, ...newState })
  }

  const handleClose = () => {
    setStatusState(STATUS_STATE.CONFIRM_CLOSE)
    setCloseVendorState({
      closedReason: null,
      closedUntil: null,
    })
    if (uiVersion === 'v2') {
      captureUserAction('OPEN_WIDGET', {
        reportToEts: true,
        eventDetails: {
          switch_to: 'Close',
        },
      })
    }
  }

  const handleConfirmClose = () => {
    const data: VendorAvailabilityStatusUpdatePayload = {
      changeable: true,
      closedReason: closeVendorState?.closedReason,
    }
    if (closeVendorState.closedUntil === 'EOD') {
      data.availabilityState = 'CLOSED_TODAY'
    } else {
      data.availabilityState = 'CLOSED_UNTIL'
      data.closedUntil = closeVendorState.closedUntil
    }

    onStatusUpdate(data).then(() => {
      setStatusState(STATUS_STATE.OPEN)
      captureUserAction('CONFIRM_CHANGE_AVAILABILITY', {
        reportToEts: true,
        eventDetails: {
          switch_to: 'Close',
        },
      })
    })
  }

  const handleOpen = () => {
    setStatusState(STATUS_STATE.CONFIRM_OPEN)
    if (uiVersion === 'v2') {
      captureUserAction('OPEN_WIDGET', {
        reportToEts: true,
        eventDetails: {
          switch_to: 'Open',
        },
      })
    }
  }

  const handleConfirmOpen = () => {
    onStatusUpdate({
      changeable: true,
      availabilityState: 'OPEN',
    }).then(() => {
      setStatusState(STATUS_STATE.CLOSE)
      captureUserAction('CONFIRM_CHANGE_AVAILABILITY', {
        reportToEts: true,
        eventDetails: {
          switch_to: 'Open',
        },
      })
    })
  }

  const handleCancel = () => {
    setStatusState((prevState) => {
      if (prevState === STATUS_STATE.CONFIRM_OPEN) return STATUS_STATE.OPEN
      if (prevState === STATUS_STATE.CONFIRM_CLOSE) return STATUS_STATE.CLOSE
      return null
    })
    captureUserAction('TERMINATE_WIDGET', {
      reportToEts: true,
      eventDetails: {
        button_label: 'Cancel',
        switch_to: statusState === STATUS_STATE.CONFIRM_OPEN ? 'Close' : 'Open',
      },
    })
  }

  switch (availabilityStatus?.availabilityState) {
    case AVAILABILITY_STATUS.CLOSED:
    case AVAILABILITY_STATUS.CLOSED_TODAY:
    case AVAILABILITY_STATUS.CLOSED_UNTIL:
      return (
        <div className={classes.wrapperContainer}>
          {children}
          <div className={classes.actionButtonsContainer}>
            {statusState === STATUS_STATE.CONFIRM_OPEN ? (
              <>
                <Button type='outlined' onClick={handleCancel}>
                  {t('Cancel')}
                </Button>
                <Button onClick={handleConfirmOpen} type='solid'>
                  {t('widgets.vendor_availability.confirm_open_vendor')}
                </Button>
              </>
            ) : (
              <Tooltip title={tooltipTitle} placement='left'>
                <Button
                  type='outlined'
                  onClick={handleOpen}
                  disabled={!availabilityStatus?.changeable}
                >
                  {t('widgets.vendor_availability.open_vendor')}
                </Button>
              </Tooltip>
            )}
          </div>
        </div>
      )
    case AVAILABILITY_STATUS.OPEN:
      return (
        <>
          <div className={classes.wrapperContainer}>
            {children}
            <div className={classes.actionButtonsContainer}>
              <Button
                type='outlined'
                onClick={(e) => {
                  e.stopPropagation()
                  handleClose()
                }}
              >
                {t('widgets.vendor_availability.close_vendor')}
              </Button>
            </div>
          </div>

          <PopupWidgetContainer
            visible={statusState === STATUS_STATE.CONFIRM_CLOSE}
            widgetContainerType='drawer'
            onClose={handleCancel}
            onHide={handleCancel}
            title={t('widgets.vendor_availability.confirm_close_vendor')}
          >
            <div className={classes.wrapperContainer}>
              <CloseVendorForm updateCloseVendorState={updateCloseVendorState} />
              <div className={classes.actionButtonsContainer}>
                <Button type='outlined' onClick={handleCancel}>
                  {t('Cancel')}
                </Button>
                <Button onClick={handleConfirmClose} disabled={disableConfirmClose} type='solid'>
                  {t('widgets.vendor_availability.confirm_close_vendor')}
                </Button>
              </div>
            </div>
          </PopupWidgetContainer>
        </>
      )
  }

  return <>{children}</>
}
