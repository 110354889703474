// libs
import React from 'react'
// contexts and types
import { SingleChatEventLocal } from 'types/components/chatTranscriptView'
// hooks
import { useTranslation } from 'hooks/useTranslation'
// utils
import capitalizeFirstLetters from 'utils/capitalizeFirstLetters'

// styles
import { Button, Menu } from 'antd'
import { createUseStyles } from 'react-jss'
import styles from './ChatTranscriptView.styles'
// components
import SingleChatView from './SingleChatView'
import SingleChatInfoView from './SingleChatInfoView'
import { ChatEventType, RiderChatsData } from 'types/api/riderApi/riderChats'
import { useCaptureUserAction } from 'hooks/events/useCaptureUserAction'
import { useRiderChatsStates } from 'hooks/useRiderChatsState'
import { InlineDropdown } from 'shared/InlineDropdown'

const useStyles = createUseStyles(styles)

interface Props {
  isRiderService: boolean
  orderId: string

  chatTranscripts: RiderChatsData

  utcZone: string
}

const ChatTranscriptView: React.FC<Props> = ({
  isRiderService,
  orderId,
  utcZone,
  chatTranscripts,
}) => {
  const classes = useStyles()

  // pull language content
  const { t } = useTranslation()
  const captureUserAction = useCaptureUserAction()

  const {
    selectedLanguage,
    setSelectedLanguage,
    chatCreatedDateTime,
    chatFrozenDateTime,
    availableTranslatedLanguages,
    allChatEvents,
  } = useRiderChatsStates(chatTranscripts, utcZone)

  // fired when a translations is selected or hide translations clicked
  const handleCancelReasonSelection = (language: string) => {
    if (language) {
      setSelectedLanguage(language)
      return
    }
    setSelectedLanguage('')
  }

  const handleClickShowTranslation = () => {
    captureUserAction('OrderTabChatTranscriptShowTranslationClicked')
  }

  const handleClickHideTranslation = () => {
    captureUserAction('OrderTabChatTranscriptHideTranslationClicked')
    handleCancelReasonSelection('')
  }

  return (
    <div className={classes.chatTranscriptHolder}>
      {/* translations dropdown */}
      {!isRiderService && availableTranslatedLanguages.length > 0 && (
        <InlineDropdown
          className={classes.dropdown}
          overlay={
            <Menu className={classes.dropdownMenu}>
              {/* display a hide translations option, if there is already a selected one */}
              {selectedLanguage && (
                <Menu.Item
                  key={0}
                  className={classes.dropdownMenuItem}
                  onClick={handleClickHideTranslation}
                >
                  {t('Order Widget.Tabs.Summary.Hide translations')}
                </Menu.Item>
              )}

              {/* map over and display each translation key */}
              {availableTranslatedLanguages.map((lang: string, idx: number) => (
                <Menu.Item
                  className={classes.dropdownMenuItem}
                  key={idx + 1}
                  onClick={(e) => handleCancelReasonSelection(lang)}
                >
                  {capitalizeFirstLetters(lang)}
                </Menu.Item>
              ))}
            </Menu>
          }
          trigger={['click']}
        >
          <Button
            type='link'
            size='small'
            className={classes.button}
            onClick={handleClickShowTranslation}
          >
            {/* dropdown button text is either selected translation language or show translations */}
            {selectedLanguage
              ? `${t('Order Widget.Tabs.Summary.Translation')}: ${capitalizeFirstLetters(
                  selectedLanguage,
                )}`
              : t('Order Widget.Tabs.Summary.Show Translations')}
          </Button>
        </InlineDropdown>
      )}

      {/* chat started event  timestamp */}
      <SingleChatInfoView info={chatCreatedDateTime} applyTopMargin={false} />

      {/* chat events looped */}
      {allChatEvents.map((chatEvent: SingleChatEventLocal, idx: number) => {
        // message event
        switch (chatEvent.eventType) {
          case ChatEventType.image:
          case ChatEventType.message:
            return (
              <SingleChatView
                key={idx}
                chatEvent={chatEvent}
                selectedLanguage={selectedLanguage}
                rider={
                  chatEvent?.rider?.id
                    ? `ID: ${chatEvent?.rider?.id?.replace(/COURIER-/g, '')} | ${
                        chatEvent?.rider?.nickName
                      }`
                    : ''
                }
              />
            )

          default:
            return null
        }
      })}

      {/* chat ended event & timestamp */}
      <SingleChatInfoView info={t('Order Widget.Tabs.Summary.Chat ended')} applyTopMargin={true} />
      <SingleChatInfoView info={chatFrozenDateTime} applyTopMargin={false} />

      <SingleChatInfoView info={'•'} applyTopMargin={false} />

      {/* order id */}
      <SingleChatInfoView
        info={`${t('Order Widget.Order ID')}: ${orderId}`}
        applyTopMargin={false}
      />
    </div>
  )
}

export default ChatTranscriptView
