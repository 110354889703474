import { Skeleton } from 'antd'
import { WidgetErrorBoundary } from 'components/ErrorBoundary/WidgetErrorBoundary'
import { PluggableWidgetProps, ScreenName, WidgetView } from 'types/unitedUiConfig'

import { useWidgetViewState, UseWidgetViewStateOpts } from 'hooks/widgetView/useWidgetViewState'
import { EMPTY_CALLBACK } from 'constants/constants'
import React, { FC, useEffect, useState } from 'react'
import { PluggableWidgetDefinition } from 'types/unitedUiConfig'
import { createUseStyles } from 'react-jss'
import { UnifiedIcon } from 'shared/UnifiedIcon'
import { WidgetErrorHandler } from 'components/WidgetErrorHandler/widgetErrorHandler'
import { WidgetDataRequirementsValidation } from 'components/WidgetDataRequirementsValidation/WidgetDataRequirementsValidation'
import { getWidgetId } from 'utils/getters/getWidgetId'
import { RootWidgetContainerProps, RootWidgetContainer } from './RootWidgetContainer'
import { useCheckDisplayRules } from 'hooks/useCheckDisplayRules'
import { ViewScreenProvider } from 'contexts/ViewScreenProvider'
import { useIconPacks } from 'contexts/IconPacksProvider'

import { useWidgetViewManager } from 'contexts/widgetViewManager'
import { useScrolledInView } from 'hooks/useScrolledIntoView'
import { useCaptureUserAction } from 'hooks/events/useCaptureUserAction'
import { useWidgetLabelTranslator } from 'hooks/widgetView/useWidgetLabelTranslator'
import { computeWidgetRenderState } from 'utils/widgets/computeWidgetRenderState'

const useStyles = createUseStyles({
  errorContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0px 16px',
  },
})

type RenderViewProps = Omit<RootWidgetContainerProps, 'renderState'> & {
  /**
   * the view
   */
  view: WidgetView

  onStateChange?: UseWidgetViewStateOpts<any>['onStateChange']
}

const RenderView = (props: RenderViewProps) => {
  const {
    view,
    onStateChange,
    actionHandlers,
    activationSubjects,
    iconName,
    ...widgetContainerProps
  } = props

  const captureUserAction = useCaptureUserAction()

  const { Widget, metadata, widgetId, implementsHandle, labelTranslationKey, elevated } = view

  const { elementRef } = useScrolledInView({
    options: {
      // the root element we are checking intersection for is the viewPort
      root: null,
      // no margin from the root element
      rootMargin: '0px',
      // trigger when 50% of the element is intersecting with the root element (viewport)
      threshold: 0.5,
    },
    widgetId,
    scrollCallback: () =>
      captureUserAction('SHOW_WIDGET', {
        reportToEts: true,
        eventDetails: {
          target_widget: widgetId,
          category: Widget.category,
        },
      }),
  })

  const {
    viewState,
    subjects,
    subjectsValidationResult,
    sdkConstructParams,
    handle,
    onViewCrash,
    sdk,
  } = useWidgetViewState({
    labelTranslationKey,
    subjects: activationSubjects,
    config: metadata.config,
    deriveInitialViewState: Widget.deriveInitialViewState,
    deriveSubjectsRequirements: Widget.deriveSubjectsRequirements,
    onStateChange,
    elevated,
  })

  const iconPacks = useIconPacks()

  const { title, destroyable, width, actionState, crashed, ...widgetContainerStyleProps } =
    viewState

  const widgetParams: PluggableWidgetProps<{}> = {
    onQuit: EMPTY_CALLBACK,
    onHide: EMPTY_CALLBACK,
    parentViewId: null,
    sdkConstructParams,
    ...subjects,
    ...metadata,
    sdk,
    iconPacks,
    widgetId: view.widgetId,
    IconRenderer: UnifiedIcon,
    ErrorRenderer: WidgetErrorHandler,
  }

  return (
    <RootWidgetContainer
      {...widgetContainerStyleProps}
      // style props passed on overrides those on widget state
      {...widgetContainerProps}
      label={title}
      iconName={iconName}
      onRefresh={handle.current?.onRefresh}
      actionHandlers={actionHandlers}
      activationSubjects={subjects}
      renderState={computeWidgetRenderState({
        crashed: viewState.crashed,
        subjectsValidationStatus: subjectsValidationResult.status,
      })}
    >
      <WidgetErrorBoundary
        widgetId={widgetId}
        supportSlackChannel={metadata.supportSlackChannel}
        contactEmail={metadata.authorEmail}
        onCrash={onViewCrash}
      >
        <WidgetDataRequirementsValidation validationResult={subjectsValidationResult}>
          <div ref={elementRef}>
            <Widget {...widgetParams} ref={implementsHandle ? handle : undefined} />
          </div>
        </WidgetDataRequirementsValidation>
      </WidgetErrorBoundary>
    </RootWidgetContainer>
  )
}

type RootWidgetViewProps = Omit<
  RootWidgetContainerProps,
  | 'label'
  | 'actionHandlers'
  | 'iconName'
  | 'onRefresh'
  | 'loading'
  | 'crashed'
  | 'widgetId'
  | 'renderState'
> & {
  onStateChange?: UseWidgetViewStateOpts<any>['onStateChange']
  widgetDefinition?: PluggableWidgetDefinition
  screenName: ScreenName
  tabContainerLabel?: string
}

export const RootWidgetView: FC<RootWidgetViewProps> = (props) => {
  const checkDisplayRules = useCheckDisplayRules()

  const classes = useStyles()
  const { activateWidgetView } = useWidgetViewManager()
  const widgetLabelTranslator = useWidgetLabelTranslator()

  const widgetId = getWidgetId(props.widgetDefinition)

  const [view, setView] = useState<WidgetView>(null)
  const [isLoadingView, setIsLoadingView] = useState(false)
  const [error, setError] = useState(null)

  const { widgetDefinition, onStateChange, screenName, tabContainerLabel, ...extras } = props

  const widgetLabel = widgetLabelTranslator.translate(widgetDefinition.label?.label_translation_key)

  useEffect(
    () => {
      setIsLoadingView(true)

      activateWidgetView(widgetDefinition, { isARootView: true })
        .then(setView)
        .catch(setError)
        .finally(() => {
          setIsLoadingView(false)
        })
    },

    // disabling hook dependency as this will be loaded once
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [activateWidgetView],
  )

  const commonProps = {
    label: widgetLabel,
    iconName: widgetDefinition.label?.icon_name,
    actionHandlers: widgetDefinition.label?.action_handlers,
    ...extras,
  }

  const containerProps: Omit<RootWidgetContainerProps, 'renderState'> = {
    ...commonProps,
    collapseState: props.collapseState ?? widgetDefinition.collapse_state,
    hideLabel:
      !widgetLabel ||
      props.hideLabel ||
      !checkDisplayRules(widgetDefinition.label?.display_rules).visible,
    widgetId,
  }

  return (
    <ViewScreenProvider
      screenName={screenName}
      label={widgetLabel}
      widgetId={widgetId}
      tabContainerLabel={tabContainerLabel}
    >
      {isLoadingView && (
        <RootWidgetContainer {...containerProps} renderState='loading'>
          <Skeleton active />
        </RootWidgetContainer>
      )}

      {!isLoadingView && error ? (
        <RootWidgetContainer {...containerProps} renderState='load-error'>
          <div className={classes.errorContainer}>
            <p>Failed to load widget</p>
          </div>
        </RootWidgetContainer>
      ) : null}

      {!isLoadingView && !error && view ? (
        <RenderView {...containerProps} view={view} onStateChange={onStateChange} />
      ) : null}
    </ViewScreenProvider>
  )
}
