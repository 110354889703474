import { createUseStyles } from 'react-jss'

export const useDeliveriesStyles = createUseStyles({
  container: {
    paddingTop: '16px',
  },
  addressSection: {
    margin: '16px 0 24px 0',
  },
  notificationSection: {
    marginBottom: '24px',
  },
})
