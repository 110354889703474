import classNames from 'classnames'
import React, { forwardRef, PropsWithChildren } from 'react'
import { createUseStyles } from 'react-jss'

import styles from './Widget.styles'

const useStyles = createUseStyles(styles)

type WidgetTitleProps = {
  className?: string
}

export const WidgetTitle: React.FC<WidgetTitleProps> = ({ children, className }) => {
  const classes = useStyles()

  return <header className={classNames(classes.widgetTitle, className)}>{children}</header>
}

type WidgetProps = {
  className?: string
}

export const Widget = forwardRef<HTMLElement, PropsWithChildren<WidgetProps>>(
  ({ children, className }, ref) => {
    const classes = useStyles()

    return (
      <section className={classNames(classes.widgetContainer, className)} ref={ref}>
        {children}
      </section>
    )
  },
)
