export default {
  modalContent: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    marginTop: '5px',
  },
  sectionCopyText: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    fontSize: '13px',
    fontWeight: 400,
    color: '#2A8FF7',
    marginTop: '10px',
  },
  dropdownHolder: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '15px',
  },
  dropdownLabels: {
    fontSize: '14px',
    color: 'rgba(0, 0, 0, 0.85)',
    fontWeight: 600,
    marginRight: '5px',
    flexShrink: 0,
  },
  dropdownLabelsInactive: {
    fontSize: '14px',
    fontWeight: 600,
    color: 'rgba(0, 0, 0, 0.25)',
    marginRight: '5px',
  },
  dataActive: {
    fontSize: '14px',
    color: 'rgba(0, 0, 0, 0.85)',
    marginRight: '5px',
  },
  dataInactive: {
    fontSize: '14px',
    color: 'rgba(0, 0, 0, 0.25)',
    marginRight: '5px',
  },
  dropdownButton: {
    fontSize: '14px',
    color: 'rgba(0, 0, 0, 0.65)',
  },
  dropdownIcon: {
    color: 'rgba(0, 0, 0, 0.25)',
  },
  dropdownMenu: {
    maxHeight: '350px',
    overflowY: 'scroll',
  },
  rowContainer: {
    display: 'flex',
  },
  editIcon: {
    marginLeft: '5px',
  },
  bodyTitleInactive: {
    fontSize: '14px',
    fontWeight: 600,
    color: 'rgba(0, 0, 0, 0.25)',
    marginTop: '15px',
  },
  bodyTitleActive: {
    fontSize: '14px',
    fontWeight: 600,
    color: 'rgba(0, 0, 0, 0.85)',
    marginTop: '15px',
  },
  bodyDataInactive: {
    fontSize: '14px',
    color: 'rgba(0, 0, 0, 0.25)',
    margin: '15px 0px 0px 5px',
  },
  bodyDataActive: {
    fontSize: '14px',
    color: 'rgba(0, 0, 0, 0.65)',
    margin: '15px 0px 0px 5px',
  },
  buttonHolder: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    marginTop: '50px',
  },
  editSectionContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  editSection: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '10px 0px 0px 5px',
  },
  editTextCurrency: {
    fontSize: '14px',
    color: 'rgba(0, 0, 0, 0.65)',
    margin: '0px 5px 0px 0px',
  },
  capValueContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: '35px',
  },
  editInputNumber: {
    width: '180px',
    margin: '0px 5px 0px 0px',
  },
  inputFieldCapValue: {
    fontSize: '11px',
    color: 'rgba(0, 0, 0, 0.45)',
    margin: '0px 0px 0px 5px',
  },
  inputFieldCapValueAlert: {
    fontSize: '11px',
    color: '#FF4D4F',
    margin: '0px 0px 0px 5px',
  },
}
