/**
 * New components to show set of basket update changes
 */
import React from 'react'
import { Result } from 'antd'
import { text } from 'theme'
import { Text } from '../../../shared/Text'
import ChangeSetView from './ChangeSetView'
import useTransformBasketUpdatesV2 from 'hooks/useTransformBasketUpdatesV2'
import { ShoppingCartOutlined } from '@ant-design/icons'
import { useTranslation } from 'hooks/useTranslation'
import { Flex } from 'shared/Flex'
import { createPluggableWidget } from 'factory/createPluggableWidget'
import { BuiltinWidgetConfigs } from 'types/unitedUiConfig'
import { useCheckDisplayRules } from 'hooks/useCheckDisplayRules'

const BasketUpdatesV2 = createPluggableWidget<BuiltinWidgetConfigs['basket_updates']>(
  ({ order, sdk, config }) => {
    const { t } = useTranslation()
    const checkDisplayRules = useCheckDisplayRules()

    const allUpdates = useTransformBasketUpdatesV2({ order, sdk, currency: order?.currency })
    const changeSetLength = allUpdates.changeSets.length
    const canDisplayRestore = config?.restore_items
      ? checkDisplayRules(config.restore_items?.display_rules).enabled
      : false

    const sourcesWhitelist = config?.basket_restoration_source_whitelist ?? []

    if (changeSetLength === 0) {
      /* NO Basket Update Message */
      return (
        <Result
          icon={<ShoppingCartOutlined />}
          title={t('Order Widget.Tabs.Basket Updates.No basket updates')}
          subTitle={`${t('Order Widget.Tabs.Basket Updates.There has not been any changes')}.`}
        />
      )
    }
    /* Basket updates rendered through ChangeSetView */
    return (
      <Flex flexDirection='column' rowGap={'16px'}>
        {/* Change sets */}
        {allUpdates.changeSets.map((update, idx) => {
          const isSourceAllowed = sourcesWhitelist?.length
            ? sourcesWhitelist?.includes(update?.sourceKey)
            : // fallback if there are no whitelisted sources
              update?.sourceKey === 'customer_contact_center'

          return (
            <ChangeSetView
              key={idx}
              sdk={sdk}
              allowRestore={canDisplayRestore && isSourceAllowed}
              update={update}
              currency={order?.currency}
            />
          )
        })}
        {/* Total of every change */}
        {changeSetLength > 1 && (
          <Flex justifyContent='end' alignItems='center' gap={'4px'}>
            <Text.Secondary color={text.secondary}>
              Total reduction in order price {order?.currency}
            </Text.Secondary>
            <Text.Primary color={text.primary}>
              {sdk.numberFormatter.formatDigit(allUpdates.grandTotalPriceChange.amount)}
            </Text.Primary>
          </Flex>
        )}
      </Flex>
    )
  },
  {
    category: 'data_lookup',
    deriveInitialViewState({ t, order, titleTranslationKey }) {
      return {
        title: t(titleTranslationKey, {
          replace: { totalUpdates: order?.basket_updates?.length ?? 0 },
        }),
      }
    },
    deriveConfig({ entityConfig }) {
      return entityConfig.layout_v2.builtin_widgets_configs.basket_updates
    },
  },
)

export default BasketUpdatesV2
