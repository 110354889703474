import { PaymentMethods } from 'contexts/entity/types'
import { OrderApiResponse } from 'types/herocare'
import { getOrderPaymentBreakdown } from './getOrderPaymentBreakdown'
import { UnifiedIconName } from 'contexts/IconPacksProvider'
import { getPaymentMethodMeta } from 'utils/getPaymentMethodMeta'

type PaymentInfo = {
  label: string
  payment_method: string
  icon: UnifiedIconName
  amount: number
}

export const getMainPaymentMethod = (order: OrderApiResponse): PaymentInfo => {
  const { walletValue, originalOrderValue, giftCardValue, allowanceValue, currentOrderValue } =
    getOrderPaymentBreakdown(order)

  const { payment_method } = order.customer?.payment ?? {}

  let mainPaymentMethodAmount = 0

  switch (payment_method) {
    case PaymentMethods.COD:
    case PaymentMethods.CASH:
      mainPaymentMethodAmount = currentOrderValue
      break
    case PaymentMethods.balance:
    case PaymentMethods.WALLET:
      mainPaymentMethodAmount = walletValue
      break
    case PaymentMethods.gift_card:
      mainPaymentMethodAmount = giftCardValue
      break
    case PaymentMethods.Invoice:
      mainPaymentMethodAmount = allowanceValue
      break
    default:
      mainPaymentMethodAmount = originalOrderValue
  }

  return {
    amount: mainPaymentMethodAmount,
    payment_method: payment_method,
    ...getPaymentMethodMeta(payment_method as PaymentMethods),
  }
}
