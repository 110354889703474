import React, { MouseEventHandler } from 'react'
import { gray } from 'theme'
import { Notification } from 'shared/Notification'
import { useTranslation } from 'hooks/useTranslation'
import { logCopyError } from 'utils/reporting/logCopyError'
import { ButtonProps, Button } from '../Button'
import captureUserAction from '../../browserMonitoring/captureUserAction'

export interface CopyButtonProps extends Omit<ButtonProps, 'onClick'> {
  text: string | (() => string | Promise<string>)
  onCopy?: () => void
  eventName?: string
}

export const CopyButton = ({ text, onCopy, eventName, ...others }: CopyButtonProps) => {
  const { t } = useTranslation()
  const handleCopy: MouseEventHandler<HTMLButtonElement> = async (event) => {
    event.stopPropagation()
    let textContent: string

    if (typeof text === 'string') {
      textContent = text
    } else {
      textContent = await text()
    }

    if (!textContent) {
      Notification.warning({
        message: t('Widgets Common.Nothing to Copy'),
      })
    }

    navigator.clipboard
      .writeText(textContent)
      .then(() => {
        Notification.success({
          message: t('Widgets Common.Copied'),
        })
        if (eventName) {
          captureUserAction(eventName, [])
        }
        if (onCopy) {
          onCopy()
        }
      })
      .catch((err) => {
        logCopyError(err)
        Notification.error({
          message: t('Widgets Common.Failed to copy'),
        })
      })
  }

  return (
    <Button
      tooltip={t('Widgets Common.Copy')}
      aria-label={t('Widgets Common.Copy')}
      icon={'CopyOutlined'}
      type='outlined'
      color='primary'
      borderColor={gray.gray5}
      size='small'
      borderRadius={4}
      onClick={handleCopy}
      {...others}
    />
  )
}
