import { TabDataPointConfigs } from './orderSummaryTabDataPointConfigs'

/**
 * these are datapoint configuration keys of displayable fields in the order widget summary tab
 *
 * these keys exist in the summmary tab config to configure if a field should be displayed or not
 */
export const orderStatusTabDataPointConfigs: TabDataPointConfigs<
  | 'pendingOrderFailed'
  | 'estimatedPickUpTime'
  | 'estimatedDropOffTime'
  | 'promisedDropOffTime'
  | 'pending'
> = {
  pending: {
    key: 'payment_pending',
  },
  pendingOrderFailed: {
    key: 'pending_order_failed',
  },
  estimatedPickUpTime: {
    key: 'estimated_pickup_time',
  },
  estimatedDropOffTime: {
    key: 'estimated_dropoff_time',
  },
  promisedDropOffTime: {
    key: 'promised_dropoff_time',
  },
}
