import { ApiService } from 'services/authApi/getPermissions'

export const postSsoLogin: ApiService<
  {
    port: string
    status: 'error' | 'success'
    token: string
  },
  {}
> = (createClient, opts) => {
  const { status, clientParams, port, token } = opts
  const uri = `http://localhost:${port}/sso`

  return createClient({
    endpointName: 'herocareCliSso',
    validateJwt: false,
    ...clientParams,
    expectedResponseStatusCode: 200,
  }).post(uri, { status, token })
}
