export default {
  orderHistoryContainer: {
    padding: '3px',
  },
  commentsHolder: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    overflowY: 'auto',
  },
}
