import { useCallback } from 'react'
import { logCopyError } from 'utils/reporting/logCopyError'
import { Notification } from 'shared/Notification'
import { useTranslation } from './useTranslation'
import fixNumber from 'utils/fixNumber'
import { CreatedVoucherDetails } from 'types/actions/compensation'
import { useCaptureUserAction } from './events/useCaptureUserAction'

export const useCopyVoucher = () => {
  const { t } = useTranslation()

  const captureUserAction = useCaptureUserAction()

  return useCallback(
    (createdVoucher, currency: string) => {
      // copy messages
      const success = () => {
        Notification.success({
          message: t('Widgets Common.Voucher Information Copied'),
        })
        captureUserAction(`ActionsFullRefundCopyVoucherClicked`)
      }

      const error = () => {
        logCopyError()
        Notification.error({
          message: t('Widgets Common.Cannot Copy'),
        })
      }

      const warning = () => {
        Notification.warning({
          message: t('Widgets Common.Nothing to Copy'),
        })
      }

      const copyArray: Array<string> = [
        `${t('Customer Widget.Tabs.Vouchers.Code')}: ${createdVoucher.code}`,
        `${t('Widgets Common.Voucher Value')}: ${currency} ${fixNumber(createdVoucher.value)}`,
        `${t('Customer Widget.Tabs.Vouchers.Voucher Details.Description')}: ${
          createdVoucher.description
        }`,
      ]

      createdVoucher.constraints.forEach((c: any) => {
        switch (c.type) {
          case CreatedVoucherDetails.startDate:
            copyArray.push(
              `${t('Customer Widget.Tabs.Vouchers.Voucher Details.Start Date')}: ${c.value}`,
            )
            break
          case CreatedVoucherDetails.expDate:
            copyArray.push(
              `${t('Customer Widget.Tabs.Vouchers.Voucher Details.End Date')}: ${c.value}`,
            )
            break
          case CreatedVoucherDetails.openingHour:
            copyArray.push(
              `${t('Customer Widget.Tabs.Vouchers.Voucher Details.Opening Hour')}: ${c.value}`,
            )
            break
          case CreatedVoucherDetails.closingHour:
            copyArray.push(
              `${t('Customer Widget.Tabs.Vouchers.Voucher Details.Closing Hour')}: ${c.value}`,
            )
            break
          case CreatedVoucherDetails.minOrderValue:
            copyArray.push(
              `${t(
                'Customer Widget.Tabs.Vouchers.Voucher Details.Min Order Value',
              )}: ${currency} ${fixNumber(c.value)}`,
            )
            break
          case CreatedVoucherDetails.paymentTypes:
            copyArray.push(
              `${t(
                'Customer Widget.Tabs.Vouchers.Voucher Details.Payment Types',
              )}: ${c.value.reduce((acc: string, cv: any, idx: number) => {
                if (idx === 0) {
                  return cv.name
                } else {
                  return acc + ', ' + cv.name
                }
              }, '')}`,
            )
            break
          default:
            break
        }
      })

      navigator.clipboard.writeText(copyArray.join(',\n')).then(
        () => (copyArray?.length > 0 ? success() : warning()),
        () => error(),
      )
    },
    [t, captureUserAction],
  )
}
