import { allowedScrollablePanelWidgets } from 'entityConfig/allowedConfigValues'
import React, { createContext, Dispatch, useMemo, useCallback, useContext, useEffect } from 'react'
import getPlatformByGei from 'utils/getPlatformByGei'
import { getSearchParams } from 'utils/getSearchParams'
import { sessionReducer } from './SessionReducer'
import { SessionState, SessionActions, SessionAction } from './types'
import { DataContext } from 'contexts/data/DataContext'
import { useReadableReducer } from 'contexts/useReadableReducer'
import { LinesOfBusiness, TabName } from 'types'
import { getCcrItemByName } from 'utils/ccr/getCcrItemByName'

export interface SessionContextValue {
  sessionState: SessionState
  sessionDispatch: Dispatch<SessionActions>

  /**
   * this is added because it will forever not change in the application, but still allows you to
   * read current session state.
   *
   * this helps prevent rendering of ui because of event tracking dependency on session
   */
  readSession: () => SessionState

  setActiveScrollablePanelWidget: (
    widget: allowedScrollablePanelWidgets,
    options?: boolean | ScrollIntoViewOptions,
  ) => void

  /**
   * sets the active tab in oneview v2.
   */
  setActiveTab: (tabName: TabName) => void
}

const SessionContext = createContext<SessionContextValue>(undefined)

const readInitialState = () => {
  const searchParams = getSearchParams()

  const lineOfBusiness = searchParams.getLineOfBusiness()
  const ccr1 = searchParams.getCcr1()
  const ccr2 = searchParams.getCcr2()
  const ccr3 = searchParams.getCcr3()
  const ccrCode = searchParams.getCcrCode()

  const session: SessionState = {
    riderId: searchParams.getRiderId(),
    userId: searchParams.getUserId(),
    orderId: searchParams.getOrderId(),

    caseId: searchParams.getCaseId(),
    sessionId: searchParams.getSessionId(),
    globalEntityId: searchParams.getGlobalEntityId(),
    entityConfigId: searchParams.getGlobalEntityId(),

    vendorId: searchParams.getVendorId(),
    globalVendorId: searchParams.getGlobalVendorId(),

    lineOfBusiness,
    lang: searchParams.getLang(),
    ccrCode: ccrCode || getCcrItemByName({ ccr1, ccr2, ccr3, lob: lineOfBusiness })?.code || '',
    platform: getPlatformByGei(lineOfBusiness, searchParams.getGlobalEntityId()),

    widgetContainerType: 'modal',
    host: searchParams.getHost() || 'salesforce',

    utcZone: Intl?.DateTimeFormat ? Intl.DateTimeFormat().resolvedOptions().timeZone : null,
    activeScrollablePanelWidget: null,

    uiVersion:
      searchParams.getUiVersion() || (lineOfBusiness === LinesOfBusiness.rider ? 'v1' : 'v2'),
    activeTab: searchParams.getActiveTab(),

    riderCurrentOrderId: '',
    riderCurrentOrderVendorId: '',
  }

  return session
}

const SessionProvider: React.FC<{ sessionState?: SessionState }> = ({
  children,
  sessionState: givenState,
}) => {
  const { state, dispatch, readState } = useReadableReducer(
    givenState,
    sessionReducer,
    (givenState) => {
      const mergedState = { ...readInitialState(), ...givenState }
      mergedState.entityConfigId = mergedState.entityConfigId || mergedState.globalEntityId
      return mergedState
    },
  )

  const {
    dataState: { ticket, customer },
  } = useContext(DataContext)

  const setActiveScrollablePanelWidget = useCallback<
    SessionContextValue['setActiveScrollablePanelWidget']
  >(
    (widget, options) => {
      if (readState().activeScrollablePanelWidget !== widget) {
        dispatch({
          type: SessionAction.SET_SESSION,
          payload: {
            activeScrollablePanelWidget: widget,
          },
        })
        if (options) {
          const resolvedOptions: ScrollIntoViewOptions =
            typeof options === 'boolean' ? { behavior: 'smooth', block: 'center' } : options

          const widgetContainer = document.getElementById(widget)
          if (widgetContainer && widgetContainer.scrollIntoView) {
            widgetContainer.scrollIntoView(resolvedOptions)
          }
        }
      }
    },
    [dispatch, readState],
  )

  const setActiveTab = useCallback<SessionContextValue['setActiveTab']>(
    (tabName) => {
      if (readState().activeTab !== tabName) {
        dispatch({
          type: SessionAction.SET_SESSION,
          payload: {
            activeTab: tabName,
          },
        })
      }
    },
    [dispatch, readState],
  )

  const value = useMemo(() => {
    return {
      sessionState: state,
      sessionDispatch: dispatch,
      readSession: readState,
      setActiveScrollablePanelWidget,
      setActiveTab,
    }
  }, [dispatch, readState, state, setActiveScrollablePanelWidget, setActiveTab])

  useEffect(() => {
    // update ccr code based on ticket information,
    if (ticket?.metadata?.ccr && ticket.metadata.ccr !== readState().ccrCode) {
      dispatch({
        type: SessionAction.SET_SESSION,
        payload: {
          ccrCode: ticket.metadata.ccr,
        },
      })
    }

    // update customer id from customer information
    if (customer?.customer_id && readState().userId !== customer.customer_id) {
      dispatch({
        type: SessionAction.SET_SESSION,
        payload: {
          userId: customer.customer_id,
        },
      })
    }
  }, [ticket, dispatch, readState, customer])

  return <SessionContext.Provider value={value}>{children}</SessionContext.Provider>
}

export { SessionProvider, SessionContext }
