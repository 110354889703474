import { Typography } from 'antd'
import { useTranslation } from 'hooks/useTranslation'
import React from 'react'
import { createUseStyles } from 'react-jss'
import { SingleComment } from 'types/api/commentApi/getOrderComments'
import { WidgetSize } from 'types/widgets/historyOfEvents/tabs'
import { constructUrl } from 'utils/getSearchParams'
import styles from './Comments.styles'
import { commentTagType, CommentView } from './CommentView'

const { Text } = Typography

const useStyles = createUseStyles(styles)

type Props = {
  comment: SingleComment
  shouldCommentBeHighlighted?: boolean
  mode: WidgetSize
}

export const SetPrimaryDeliveryComment = ({ comment, shouldCommentBeHighlighted, mode }: Props) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const additionalData = comment.additional_data?.set_primary_delivery

  const newRiderUrl = constructUrl({
    params: {
      riderId: additionalData.new_rider_id,
    },
  })

  const oldRiderUrl = constructUrl({
    params: {
      riderId: additionalData.old_rider_id,
    },
  })

  return (
    <CommentView
      mode={mode}
      comment={comment}
      shouldCommentBeHighlighted={shouldCommentBeHighlighted}
      tag={{
        type: commentTagType.thunderbolt,
        text: t('History of Events Widget.Event Types.Set Primary Rider'),
      }}
    >
      <Text className={classes.commentText}>
        <Text>{` ${t('History of Events Widget.Content.Primary rider was changed from')} `}</Text>
        <a
          href={oldRiderUrl}
          target='_blank'
          rel='noreferrer'
        >{`${additionalData.old_rider_id}`}</a>
        <Text>{` ${t('History of Events Widget.Content.To')} `}</Text>
        <a
          href={newRiderUrl}
          target='_blank'
          rel='noopener noreferrer'
        >{` ${additionalData.new_rider_id}`}</a>
      </Text>
    </CommentView>
  )
}
