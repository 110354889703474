import { gray, primary } from 'theme'

type ContactCardProps = {
  editable: boolean
}

export default {
  cardContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'start',
    borderBottom: `1px solid ${gray.gray3}`,
    fontSize: '14px',
    fontWeight: '400',
    '& p': {
      margin: 0,
    },

    '&:hover .editButton': {
      opacity: 1,
    },
  },
  cardBody: {
    display: 'flex',
    flexDirection: 'row',
    padding: '16px 0',
    width: '100%',
  },
  cardBodyGroup: {
    display: 'flex',
    width: '50%',
    flexDirection: 'column',
    gap: '6px',
  },
  actionButtonsContainer: {
    margin: '16px',
    minWidth: '60px',
  },
  editButton: {
    opacity: 0,
    display: (props: ContactCardProps) => (props.editable ? 'inline-block' : 'none'),
    border: 'none',
    color: primary.primary6,
  },
}
