import moment from 'moment'
import { differenceInMinutes } from 'date-fns'
import { ORANGE, POLAR_GREEN, RED } from 'constants/antColours'
import { DeliveryItem } from 'types/api/fulfillmentApi/fulfillment'
import { OrderApiResponse, FulfillmentApiResponse } from 'types/herocare'
import { SDK } from 'types'
import { RiderStatuses } from 'types/widgets/rider/riderStatuses'
import { getLastValue } from 'utils/array'
import { OrderStatuses } from 'types/widgets/order/orderStatuses'

type GetEstimatedDropOffTimeArgs = {
  fulfillment: FulfillmentApiResponse
  delivery: DeliveryItem
  order: OrderApiResponse
  datetimeFormatter: SDK['datetimeFormatter']
}

const getEstimatedDropOffTime = ({
  fulfillment,
  datetimeFormatter,
  delivery,
  order,
}: GetEstimatedDropOffTimeArgs) => {
  const lastRiderEvent = delivery?.courier_events?.[0]
  const hasRiderDelivered = lastRiderEvent?.name === RiderStatuses.delivered

  if (hasRiderDelivered) {
    return lastRiderEvent?.timestamp
  }

  if (fulfillment?.model_prediction_median_bound_minutes) {
    return datetimeFormatter.formatDateTime(
      new Date().getTime() + fulfillment.model_prediction_median_bound_minutes * 60000,
      { format: moment.defaultFormat },
    )
  }

  if (delivery?.latest_estimated_dropoff_times?.arriving_at) {
    return delivery?.latest_estimated_dropoff_times?.arriving_at
  }

  const lastOrderStatus = getLastValue(order?.status_history || [])
  const hasOrderDelivered = lastOrderStatus?.status === OrderStatuses.delivered

  if (hasOrderDelivered) {
    return lastOrderStatus?.timestamp
  }

  // estimated time from order status history
  const lastAcceptedStatus = order?.status_history?.find((status) => status.status === 'ACCEPTED')
  return lastAcceptedStatus?.accepted?.estimated_delivery_time
}

export const formatDelayTime = (delayInMinutes: number) => {
  let text = ''
  let color = POLAR_GREEN

  if (delayInMinutes === 0) {
    text = ''
  } else if (delayInMinutes < 60) {
    text = `${delayInMinutes}m`
  } else {
    const minutes = delayInMinutes % 60
    const hours = Math.floor(delayInMinutes / 60)
    text = `${hours}h ${minutes}m`
  }

  if (delayInMinutes <= 0) {
    color = POLAR_GREEN
  } else if (delayInMinutes <= 9) {
    color = ORANGE
  } else {
    color = RED
  }

  return { text, color }
}

export const getPromisedDropoffTime = ({
  order,
  fulfillment,
}: {
  order: OrderApiResponse
  fulfillment: FulfillmentApiResponse
}) => {
  return fulfillment?.promised_delivery_at || order?.promised_customer_timestamp || ''
}

export const calculateOrderDeliveryTimestamps = ({
  order,
  fulfillment,
  delivery,
  datetimeFormatter,
}: {
  order: OrderApiResponse
  fulfillment?: FulfillmentApiResponse
  delivery?: DeliveryItem
  datetimeFormatter: SDK['datetimeFormatter']
}) => {
  const promisedDropOffTime = getPromisedDropoffTime({ order, fulfillment })

  const estimatedDropOffTime = getEstimatedDropOffTime({
    fulfillment,
    datetimeFormatter,
    delivery,
    order,
  })

  let delayInMinutes = 0

  if (estimatedDropOffTime && promisedDropOffTime) {
    delayInMinutes = differenceInMinutes(
      new Date(estimatedDropOffTime),
      new Date(promisedDropOffTime),
    )
  }

  const { color, text } = formatDelayTime(delayInMinutes)

  return { delayInMinutes, delayTime: { color, text }, promisedDropOffTime, estimatedDropOffTime }
}
