import React from 'react'
import { Tooltip } from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons'
import Text from 'antd/lib/typography/Text'
import { createUseStyles } from 'react-jss'
import { useTranslation } from 'hooks/useTranslation'

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    alignItems: 'center',
    gap: 5,
  },
  icon: { color: '#1890ff' },
})

type Props = {
  label: any
  value: string
  code?: string
}

const InvoiceVoucherInfo = ({ label, value, code }: Props) => {
  const classes = useStyles()
  const { t } = useTranslation()
  return (
    <>
      <Text className={classes.container}>
        <Text>{label}</Text>
        {code && (
          <Tooltip
            title={`${t('Order Widget.Tabs.Invoice.Voucher code', {
              replace: { code },
            })}`}
            placement={'right'}
          >
            <InfoCircleOutlined className={classes.icon} />
          </Tooltip>
        )}
      </Text>
      <Text>{value}</Text>
    </>
  )
}

export default InvoiceVoucherInfo
