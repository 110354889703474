import React from 'react'
import { createCustomSvgIcon } from 'factory/createCustomSvgIcon'

export const SelfServiceIcon = createCustomSvgIcon({
  viewBoxWidth: 12,
  viewBoxHeight: 12,
  content: (
    <>
      <g clip-path='url(#clip0_811_2610)'>
        <path
          d='M3.16092 3.53571C3.16092 3.74883 3.24558 3.95323 3.39628 4.10393C3.54698 4.25462 3.75137 4.33929 3.9645 4.33929C4.17762 4.33929 4.38201 4.25462 4.53271 4.10393C4.6834 3.95323 4.76807 3.74883 4.76807 3.53571C4.76807 3.32259 4.6834 3.1182 4.53271 2.9675C4.38201 2.8168 4.17762 2.73214 3.9645 2.73214C3.75137 2.73214 3.54698 2.8168 3.39628 2.9675C3.24558 3.1182 3.16092 3.32259 3.16092 3.53571ZM10.5538 0H1.44664C1.20958 0 1.01807 0.191518 1.01807 0.428571V9.26786C1.01807 9.50491 1.20958 9.69643 1.44664 9.69643H10.5538C10.7908 9.69643 10.9824 9.50491 10.9824 9.26786V0.428571C10.9824 0.191518 10.7908 0 10.5538 0ZM10.1252 8.83929H1.87521V0.857143H10.1252V8.83929ZM7.23235 3.53571C7.23235 3.74883 7.31701 3.95323 7.46771 4.10393C7.61841 4.25462 7.8228 4.33929 8.03592 4.33929C8.24904 4.33929 8.45344 4.25462 8.60413 4.10393C8.75483 3.95323 8.83949 3.74883 8.83949 3.53571C8.83949 3.32259 8.75483 3.1182 8.60413 2.9675C8.45344 2.8168 8.24904 2.73214 8.03592 2.73214C7.8228 2.73214 7.61841 2.8168 7.46771 2.9675C7.31701 3.1182 7.23235 3.32259 7.23235 3.53571ZM10.5832 10.9821H1.41717C1.19619 10.9821 1.01807 11.1737 1.01807 11.4107V11.8929C1.01807 11.9518 1.06226 12 1.11717 12H10.8819C10.9368 12 10.981 11.9518 10.981 11.8929V11.4107C10.9824 11.1737 10.8042 10.9821 10.5832 10.9821Z'
          fill='currentColor'
        />
        <path
          d='M8.21649 5.66666H7.51507C7.45383 5.66666 7.40133 5.70666 7.39695 5.75916C7.34154 6.37791 6.73637 6.86666 5.99996 6.86666C5.26355 6.86666 4.65692 6.37791 4.60296 5.75916C4.59859 5.70666 4.54609 5.66666 4.48485 5.66666H3.78343C3.76761 5.66665 3.75195 5.66939 3.7374 5.67472C3.72285 5.68006 3.70972 5.68788 3.69881 5.6977C3.6879 5.70752 3.67943 5.71914 3.67392 5.73186C3.66841 5.74457 3.66598 5.75812 3.66677 5.77166C3.73093 6.82541 4.75316 7.66666 5.99996 7.66666C7.24676 7.66666 8.26898 6.82541 8.33315 5.77166C8.33394 5.75812 8.33151 5.74457 8.326 5.73186C8.32049 5.71914 8.31202 5.70752 8.30111 5.6977C8.2902 5.68788 8.27707 5.68006 8.26252 5.67472C8.24797 5.66939 8.23231 5.66665 8.21649 5.66666Z'
          fill='currentColor'
        />
      </g>
      <defs>
        <clipPath id='clip0_811_2610'>
          <rect width='12' height='12' fill='white' />
        </clipPath>
      </defs>
    </>
  ),
})
