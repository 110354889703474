// Types
import { OrderApiResponse } from 'types/api/orderApi/order'
import { DeliveryItem, FulfillmentApiResponse } from 'types/api/fulfillmentApi/fulfillment'

// Utils
import moment from 'moment-timezone'
import modifyUTC from 'utils/modifyUTC'

// Types
import { TimeDiffStatusTypes, TimelineItem } from 'types/dataTransformers/orderStatus'
import { DANGER, SUCCESS, WARNING } from 'constants/antTextStatusTypes'

import { RiderStatuses } from '../../types/widgets/rider/riderStatuses'
import { OrderStatuses } from 'types/widgets/order/orderStatuses'
import { TranslateFunction } from 'hooks/useTranslation'

export const DATE_FORMAT = 'D.M.YYYY'
export const TIME_FORMAT = 'H:mm'

export const isPartiallyDelivered = (deliveries: DeliveryItem[]): boolean => {
  // Filter out cancelled deliveries
  const activeDeliveries = deliveries.filter((delivery) => {
    return !!delivery.courier_events.find((event) => event.name !== RiderStatuses.canceled)
  })
  // Filter completed deliveries
  const totalDelivered = activeDeliveries.filter((delivery) => {
    return !!delivery.courier_events.find((event) => event.name === RiderStatuses.delivered)
  })
  // Check if there are more than 1 active deliveries and if all of them are delivered
  return activeDeliveries.length > 1 ? totalDelivered.length !== activeDeliveries.length : false
}

export const calculateDelay = (fulfillment, order, estimatedTime) => {
  let delayInMinutes = 0
  let delayDisplay = ''
  let delayColorType: TimeDiffStatusTypes = SUCCESS

  const promisedTime = fulfillment?.promised_delivery_at || order?.promised_customer_timestamp
  if (promisedTime && estimatedTime) {
    const calculatedDelay = moment(estimatedTime).diff(moment(promisedTime), 'minutes')

    delayInMinutes = calculatedDelay
    delayDisplay = `${calculatedDelay} mins`

    if (calculatedDelay <= 0) {
      delayColorType = SUCCESS
    } else if (calculatedDelay <= 9) {
      delayColorType = WARNING
    } else {
      delayColorType = DANGER
    }
  }

  return {
    delayNumber: delayInMinutes,
    delay: delayDisplay,
    delayTextType: delayColorType,
  }
}

export const thirdStepData = (
  order: OrderApiResponse,
  fulfillment: FulfillmentApiResponse,
  utc_zone: string,
  t: TranslateFunction,
): TimelineItem => {
  const lastStatusHistory = order?.status_history?.[order.status_history.length - 1]
  const primaryDelivery = fulfillment?.deliveries?.find((d) => d.primary_delivery)

  const estimatedPrediction =
    fulfillment?.model_prediction_median_bound_minutes > 0
      ? moment(new Date().getTime() + fulfillment.model_prediction_median_bound_minutes * 60000)
          .tz(utc_zone)
          .format()
      : primaryDelivery?.latest_estimated_dropoff_times?.arriving_at

  // handle pending order failed
  if (lastStatusHistory?.status === OrderStatuses.pendingOrderFailed) {
    const timestamp = lastStatusHistory.timestamp
    return {
      label: t('Order Widget.Tabs.Summary.Payment failed'),
      date: timestamp ? modifyUTC(timestamp, utc_zone, DATE_FORMAT) : '-',
      time: timestamp ? modifyUTC(timestamp, utc_zone, TIME_FORMAT) : '-',
    }
  }

  // if latest status is ('DELIVERED' or 'PICKED_UP') and delivery provider is pickup then show label as Completed
  // if latest status is ('DELIVERED') and delivery provider is not pickup then
  // show Partially Delivered if partially delivered else
  // show Delivered if fully delivered
  if (['DELIVERED', 'PICKED_UP'].includes(lastStatusHistory?.status)) {
    const date = lastStatusHistory?.timestamp
    const item: TimelineItem = {
      label: '',
      date: date ? modifyUTC(date, utc_zone, DATE_FORMAT) : '-',
      time: date ? modifyUTC(date, utc_zone, TIME_FORMAT) : '-',
      ...calculateDelay(fulfillment, order, date),
    }
    if (order.delivery.provider === 'pickup') {
      item.label = t('Order Widget.Tabs.Summary.Completed')
      return item
    } else if (lastStatusHistory?.status === 'DELIVERED') {
      item.label = isPartiallyDelivered(fulfillment?.deliveries || [])
        ? t('Order Widget.Tabs.Summary.Partially Delivered')
        : t('Order Widget.Tabs.Summary.Delivered')
      return item
    }
  }

  if (lastStatusHistory?.status === OrderStatuses.cancelled) {
    const date = lastStatusHistory?.timestamp
    return {
      label: t('Order Widget.Tabs.Summary.Cancelled'),
      date: date ? modifyUTC(date, utc_zone, DATE_FORMAT) : '-',
      time: date ? modifyUTC(date, utc_zone, TIME_FORMAT) : '-',
    }
  }

  return {
    label: t('Order Widget.Tabs.Summary.Estimated'),
    date: !!estimatedPrediction ? modifyUTC(estimatedPrediction, utc_zone, DATE_FORMAT) : undefined,
    time: !!estimatedPrediction ? modifyUTC(estimatedPrediction, utc_zone, TIME_FORMAT) : undefined,
    info: !estimatedPrediction
      ? order.delivery.provider === 'platform_delivery'
        ? t('Order Widget.Tabs.Summary.Available when the Vendor accepts the order')
        : t('Order Widget.Tabs.Summary.Not available for Vendor Delivery or Pick-up orders')
      : undefined,
    ...calculateDelay(fulfillment, order, estimatedPrediction),
  }
}

export const transformOrderTimeline = (
  order: OrderApiResponse,
  fulfillment: FulfillmentApiResponse,
  utc_zone: string,
  t: TranslateFunction,
) => {
  if (!order) {
    return []
  }
  const promisedDeliveryTimestamp =
    fulfillment?.promised_delivery_at || order.promised_customer_timestamp
  // formatted promised delivery date
  const promisedDeliveryDate = promisedDeliveryTimestamp
    ? modifyUTC(promisedDeliveryTimestamp, utc_zone, DATE_FORMAT)
    : '-'
  // formatted promised delivery time
  const promisedDeliveryTime = promisedDeliveryTimestamp
    ? modifyUTC(promisedDeliveryTimestamp, utc_zone, TIME_FORMAT)
    : '-'

  // check for new delivery time from order history accepted status
  const lastAcceptedStatus = order.status_history?.find((status) => status.status === 'ACCEPTED')
  // New delivery time
  const newDeliveryTimestamp = lastAcceptedStatus?.accepted?.estimated_delivery_time

  // show new delivery time
  let showNewDeliveryDateTime = false
  let newDeliveryDateTime = newDeliveryTimestamp // default

  if (promisedDeliveryTimestamp && newDeliveryTimestamp) {
    newDeliveryDateTime = modifyUTC(
      newDeliveryTimestamp,
      utc_zone,
      `${DATE_FORMAT}, ${TIME_FORMAT}`,
    )
    // only show new delivery time if it's different from initial promised delivery time
    showNewDeliveryDateTime = !moment(newDeliveryTimestamp).isSame(
      promisedDeliveryTimestamp,
      'minute',
    )
  }

  return [
    {
      label: t('Order Widget.Tabs.Summary.Created'),
      date: order.place_timestamp ? modifyUTC(order.place_timestamp, utc_zone, DATE_FORMAT) : '-',
      time: order.place_timestamp ? modifyUTC(order.place_timestamp, utc_zone, TIME_FORMAT) : '-',
    },
    {
      label: t('Order Widget.Tabs.Summary.Promised'),
      date: promisedDeliveryDate,
      time: promisedDeliveryTime,
      newDeliveryTime: showNewDeliveryDateTime ? newDeliveryDateTime : null,
    },
    thirdStepData(order, fulfillment, utc_zone, t),
  ]
}
