import { ApiService } from 'services/authApi/getPermissions'
import { ApiVersionNumber } from 'types/unitedUiConfig'

export interface CustomerFraudStatusApiResponse {
  result: boolean | string
  recommendation_id: string
  customer_id: string
  order_id: string
  filter_ids: number[]
  is_conclusive: boolean
  error: string
}

export const getCustomerFraudStatus: ApiService<
  {
    entityId: string
    version?: ApiVersionNumber
    customerId: string
    orderId?: string
    ccrCode?: string
  },
  CustomerFraudStatusApiResponse
> = (createClient, options) => {
  const { version = 1, entityId, customerId, clientParams, orderId = '', ccrCode } = options

  const uri = `/CustomerAPI/v${version}/customers/${entityId}/${customerId}/fraudstatus`

  let endpointName: string

  switch (version) {
    case 2:
      endpointName = 'getCustomerFraudStatusV2'
      break

    default:
      endpointName = 'getCustomerFraudStatus'
  }

  return createClient({
    endpointName: endpointName,
    expectedResponseStatusCode: 200,
    ...clientParams,
  }).post(uri, { order_id: orderId, reason_code: ccrCode })
}
