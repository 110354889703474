export default {
  contentHolder: {
    width: 'max-content',
    display: 'flex',
    flexDirection: 'column',
    height: '30px',
    justifyContent: 'center',
    alignItems: 'center',
    border: '1px solid #D9D9D9',
    borderRadius: '4px',
  },
  menuItem: {
    fontSize: '13px',
    borderBottom: '1px #F0F0F0 solid',
  },
  dropdownButton: {
    color: 'rgba(0, 0, 0, 0.65)',
    display: 'flex',
    fontSize: '14px',
    alignItems: 'center',
    justifyContent: 'space-between',
    border: '0px',
  },
  dropdownIcon: {
    color: 'rgba(0, 0, 0, 0.25)',
  },
}
