import { Tabs } from 'antd'
import { DataContext } from 'contexts/data/DataContext'
import { DataAction } from 'contexts/data/types'
import { SessionContext } from 'contexts/session/SessionContext'
import { useApiService } from 'hooks/useApiService'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'hooks/useTranslation'
import { getOrderFulfillment } from 'services/fulfillmentApi/getOrderFulfillment'
import { getOrderStatusHistory } from 'services/ordersApi/getOrderStatusHistory'
import { DeliveryItem } from 'types/api/fulfillmentApi/fulfillment'

import StatusDot from './StatusDot/StatusDot'
import { DeliveryStatus } from './DeliveryStatus'
import { pickPrimaryDelivery } from 'utils/pickPrimaryDelivery'
import { OrderApiResponse } from 'types/api/orderApi/order'
import { WidgetErrorHandler } from 'components/WidgetErrorHandler/widgetErrorHandler'
import { useTransformOrderStatusHistory } from 'hooks/dataTransformers/useTransfromOrderStatusHistory'
import { useTransformRiderStatusHistory } from 'hooks/dataTransformers/useTransfromRiderStatusHistory'
import { useIsDataPointValid } from 'hooks/useGetValidFeatures'
import { OrderWidgetStatus } from 'contexts/entity/types'
import { useEntityUtcZone } from 'hooks/useEntityUtcZone'
import { EMPTY_CATCH_CALLBACK } from 'constants/constants'

type RiderTabProps = {
  delivery: DeliveryItem
  title: string
}

interface DeliveryProps {
  deliveries: DeliveryItem[]
  order: OrderApiResponse
  status: OrderWidgetStatus

  deliveryId?: number

  onHardRefresh: () => void
}

const PrimaryDelivery = ({ deliveries = [], order, onHardRefresh, status }: DeliveryProps) => {
  const delivery = pickPrimaryDelivery(deliveries)

  // pull data state and dispatch from data context
  const {
    dataState: { fulfillment },
  } = useContext(DataContext)

  const transformOrderStatusHistory = useTransformOrderStatusHistory()

  const transformedHistory = useMemo(() => {
    // when orderStatus found, tranform it for components needs
    if (order?.status_history) {
      const { transformedHistory } = transformOrderStatusHistory(fulfillment, order, status)
      return transformedHistory
    }
    return null
  }, [order, fulfillment, status, transformOrderStatusHistory])

  return (
    <DeliveryStatus
      delivery={delivery}
      riderLocations={delivery?.courier?.locations}
      vendorLocation={
        deliveries.find((delivery) => Boolean(delivery.pickup_location))?.pickup_location
      }
      customerLocation={
        deliveries.find((delivery) => Boolean(delivery.dropoff_location))?.dropoff_location
      }
      vendorName={order?.vendor?.name}
      deliveryInstructions={order?.delivery?.location?.description}
      dropOffAddress={order?.delivery?.location?.address_text}
      transformedStatusHistory={transformedHistory}
      status={status}
      onHardRefresh={onHardRefresh}
    />
  )
}

const SplitDelivery = (props: DeliveryProps) => {
  const [activeTab, setActiveTab] = useState('orderStatus')
  const handleTabChange = (activeKey: string) => {
    setActiveTab(activeKey)
  }

  const { deliveries, order, deliveryId, onHardRefresh, status } = props

  const { t } = useTranslation()

  useEffect(() => {
    if (deliveryId) {
      setActiveTab(deliveryId.toString())
    }
  }, [deliveryId])

  // pull data state and dispatch from data context
  const {
    dataState: { fulfillment },
  } = useContext(DataContext)
  const utc_zone = useEntityUtcZone()

  const transformRiderStatusHistory = useTransformRiderStatusHistory()

  const riderTranslationKey =
    deliveries.length < 5 ? 'Order Widget.Tabs.Status.Rider' : 'Order Widget.Tabs.Status.R'

  return (
    <Tabs activeKey={activeTab} onChange={handleTabChange} size={'small'}>
      <Tabs.TabPane key={'orderStatus'} tab={t('Order Widget.Tabs.Status.Order History')}>
        <PrimaryDelivery {...props} />
      </Tabs.TabPane>
      {deliveries.map((delivery, idx) => {
        const { transformedHistory } = transformRiderStatusHistory(
          delivery,
          fulfillment,
          order,
          utc_zone,
          status,
        )

        let tabTitle = t(riderTranslationKey)

        if (deliveries.length > 1) {
          tabTitle += idx + 1
        }

        return (
          <Tabs.TabPane key={delivery.id} tab={<RiderTab delivery={delivery} title={tabTitle} />}>
            <DeliveryStatus
              riderLocations={delivery?.courier?.locations}
              vendorLocation={delivery?.pickup_location}
              customerLocation={delivery?.dropoff_location}
              vendorName={order?.vendor?.name}
              deliveryInstructions={order?.delivery?.location?.description}
              dropOffAddress={order?.delivery?.location?.address_text}
              delivery={delivery}
              onHardRefresh={onHardRefresh}
              transformedStatusHistory={transformedHistory}
              status={status}
            />
          </Tabs.TabPane>
        )
      })}
    </Tabs>
  )
}

const RiderTab = ({ delivery, title }: RiderTabProps) => {
  const events = delivery?.courier_events || []
  return (
    <div style={{ display: 'flex' }}>
      <span style={{ marginRight: '5px' }}>
        <StatusDot event={events.length ? events[0] : null} />
      </span>
      <span>{title}</span>
    </div>
  )
}

type Props = {
  deliveryId: number
  status: OrderWidgetStatus
  showSplitDeliveries: {
    betaRequirement: string[]
  }
}

const Status = ({ deliveryId, status, showSplitDeliveries: show_split_deliveries }: Props) => {
  const {
    dataState: { fulfillment, order },
    dataDispatch,
  } = useContext(DataContext)

  const {
    sessionState: { orderId, globalEntityId },
  } = useContext(SessionContext)

  const { SET_ORDER_STATUS, SET_FULFILLMENT } = DataAction

  const isDataPointValid = useIsDataPointValid()

  const showSplitDeliveries = isDataPointValid(show_split_deliveries?.betaRequirement)

  const {
    loading: isLoadingStatus,
    error: statusLoadError,

    loadService: refreshStatusHistory,
  } = useApiService({
    service: getOrderStatusHistory,
    params: {
      entityId: globalEntityId,
      orderId,
    },
    deps: [globalEntityId, orderId],

    onSuccess: ({ data }) => {
      dataDispatch({
        type: SET_ORDER_STATUS,
        payload: { orderStatus: data },
      })
    },
  })

  const { loading: isLoadingFulfillment, loadService: refreshFulfillment } = useApiService({
    service: getOrderFulfillment,
    params: {
      entityId: globalEntityId,
      orderId,
    },
    deps: [globalEntityId, orderId],
    onSuccess({ data }) {
      dataDispatch({
        type: SET_FULFILLMENT,
        payload: { fulfillment: data },
      })
    },
  })

  // refresh status history and fulfillment
  useEffect(() => {
    if (isLoadingStatus || isLoadingFulfillment) {
      return
    }

    const interval = setInterval(() => {
      Promise.all([refreshStatusHistory(), refreshFulfillment()]).catch(EMPTY_CATCH_CALLBACK)
    }, 60 * 1000)

    return () => clearInterval(interval)
  }, [refreshFulfillment, refreshStatusHistory, isLoadingFulfillment, isLoadingStatus])

  const deliveries = fulfillment?.deliveries || []

  const onHardRefresh = () => {
    refreshFulfillment()
    refreshStatusHistory()
  }

  return (
    <div>
      <WidgetErrorHandler
        loading={isLoadingFulfillment || isLoadingStatus}
        errorPayload={statusLoadError?.errorPayload}
        missingParameters={[!orderId && 'orderId']}
        onRetry={refreshStatusHistory}
        displayType='regular'
      >
        {() =>
          showSplitDeliveries ? (
            <SplitDelivery
              deliveries={deliveries}
              order={order}
              status={status}
              deliveryId={deliveryId}
              onHardRefresh={onHardRefresh}
            />
          ) : (
            <PrimaryDelivery
              deliveries={deliveries}
              order={order}
              status={status}
              onHardRefresh={onHardRefresh}
            />
          )
        }
      </WidgetErrorHandler>
    </div>
  )
}

export default Status
