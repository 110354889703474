import { useWidgetScreen } from 'contexts/ViewScreenProvider'
import { useCallback } from 'react'

export const usePrepareEventScreenPayload = () => {
  const { screenName, widgetId, label, tabContainerLabel, widgetCategory } = useWidgetScreen()
  return useCallback(
    (screenDetails: Record<string, any>) => {
      const screen = {
        name: screenName,
        details: {
          source_widget: widgetId || null,
          source_widget_label: label || null,
          source_widget_category: widgetCategory || null,
          source_widget_tab_container_label: tabContainerLabel || null,
          ...screenDetails,
        },
      }

      return screen
    },
    [screenName, widgetId, label, tabContainerLabel, widgetCategory],
  )
}
