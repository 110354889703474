import { SingleComment } from 'types/api/commentApi/getOrderComments'
import { WidgetSize } from 'types/widgets/historyOfEvents/tabs'
import React, { useMemo } from 'react'
import { CommentView } from './CommentView'
import { useCreateCommentTransformer } from 'hooks/dataTransformers/useTransformComment'

export interface SelfServiceCommentProps {
  mode: WidgetSize
  comment: SingleComment
}

export const SelfServiceComment = ({ comment, mode }: SelfServiceCommentProps) => {
  const transformComment = useCreateCommentTransformer()
  const transformedComment = useMemo(() => {
    return transformComment(comment)
  }, [comment, transformComment])

  return (
    <CommentView
      mode={mode}
      comment={comment}
      Icon={transformedComment.Icon}
      notes={transformedComment.notes}
      tag={transformedComment.tag}
      author={transformedComment.author}
      fallbackCaseIds={transformedComment.fallbackCaseIds}
      issueType={transformedComment.issueType}
      voucherCode={transformedComment.voucherCode}
    >
      <div>{transformedComment.comment}</div>
    </CommentView>
  )
}
