import { SUCCESS, WARNING, DANGER } from 'constants/antTextStatusTypes'

export enum Times {
  dropOff = 'Promised to be Delivered',
  estDeliver = 'Estimated to be Delivered',
  estPick = 'Estimated to be Picked-up',
}

export type TimeDiffStatusTypes = typeof SUCCESS | typeof WARNING | typeof DANGER

export interface TimelineItem {
  label: string
  date?: string
  time?: string
  delayNumber?: number
  newDeliveryTime?: string
  delay?: string
  info?: string
  delayTextType?: TimeDiffStatusTypes
}
