import { SessionContext } from 'contexts/session/SessionContext'
import { useApiService } from 'hooks/useApiService'
import { useContext } from 'react'
import { getOrderFlags } from 'services/ordersApi/getOrderFlags'

export function useLoadOrderFlags({ shouldLoad }: { shouldLoad: boolean }) {
  const {
    sessionState: { orderId, globalEntityId },
  } = useContext(SessionContext)

  return useApiService({
    service: getOrderFlags,
    params: {
      entityId: globalEntityId,
      orderId,
    },
    shouldLoad: Boolean(orderId && globalEntityId && shouldLoad),
  })
}
