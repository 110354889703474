import { ApiService } from 'services/authApi/getPermissions'
import { Compensation, Refund } from 'types/api/voucherApi/getCompensationsAndRefunds'

export const createCompensationToWallet: ApiService<
  {
    caseId: string
    entityId: string
    customerId: string

    orderId: string

    ccrCode?: string
    proposalEventId: string
    compensationToken?: string

    currency: string

    value: string | number

    compensationPurpose: string
  },
  {
    compensations: Compensation[]
    refunds: Refund[]
  }
> = (
  createClient,

  options,
) => {
  const {
    entityId,
    orderId,
    customerId,
    caseId,
    currency,
    ccrCode,
    proposalEventId,
    compensationToken,
    value,
    compensationPurpose,
    clientParams,
  } = options

  const body = {
    money: {
      amount: value,
      currency,
    },
    customerId,
    target: 'WALLET',
    contactId: caseId,
    ccrCode: ccrCode,
    proposalCompensationToken: compensationToken,
    compensationPurpose: compensationPurpose,
    proposalEventID: proposalEventId,
  }

  // client details
  const uri = `/PaymentAPI/v1/order/${entityId}/${orderId}/compensation`

  return createClient({
    endpointName: 'createCompensationToWallet',
    expectedResponseStatusCode: 201,
    ...clientParams,
  }).post(uri, body)
}
