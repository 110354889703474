import React from 'react'
import { Button, Menu } from 'antd'
import { useTranslation } from 'hooks/useTranslation'
import { MoreOutlined } from '@ant-design/icons'
import { ActionHandler } from 'types/unitedUiConfig'
import { InlineDropdown } from 'shared/InlineDropdown'

type Props = {
  actionHandlers: ActionHandler[]
  onClick: (handler: ActionHandler['handler']) => void
}

const MoreActionMenu = ({ actionHandlers, onClick }: Props) => {
  const { t } = useTranslation()
  const items = actionHandlers.map(({ action, handler }) => {
    return (
      <Menu.Item
        onClick={({ domEvent }) => {
          domEvent.stopPropagation()
          onClick(handler)
        }}
        key={action.label_translation_key}
      >
        {t(action.label_translation_key)}
      </Menu.Item>
    )
  })

  return (
    <InlineDropdown overlay={<Menu>{items}</Menu>} placement='bottomRight'>
      <Button type='primary' ghost icon={<MoreOutlined />} />
    </InlineDropdown>
  )
}

export default MoreActionMenu
