const getSessionData = () => {
  const session = new URLSearchParams(window.location.search)
  return [
    ['global_entity_id', 'globalEntityId'],
    ['order_id', 'orderId'],
    ['case_id', 'caseId'],
    ['lob', 'lob'],
  ].reduce((result: {}, item) => {
    if (session.get(item[1])) {
      result[item[0]] = session.get(item[1])
    }
    return result
  }, {})
}

const getCapturedData = (data: string[][]) => {
  return data.reduce((result, item) => {
    if (item.length !== 2) {
      throw new Error('data parameter should be only array of two elements')
    }
    const value = item[1]
    result[item[0].toString()] = value === null ? 'null' : value
    return result
  }, {})
}

export const getSessionAndCapturedData = (data: string[][]) => {
  const capturedData = getCapturedData(data)
  const sessionData = getSessionData()
  return { sessionData, capturedData }
}
