import { useMemo } from 'react'
import { GetOrderPropertyFilterParams } from 'services/ordersApi/getLastOrders'
import { LinesOfBusiness } from 'types/session/linesOfBusiness'
import { orderPropertyFilter } from 'types/unitedUiConfig'
import { useSessionState } from './useSessionState'

export const useOrderPropertyFilterByLob = (
  extraPropertyFilters?: GetOrderPropertyFilterParams,
) => {
  const { lineOfBusiness, vendorId, userId } = useSessionState()

  const vendorFilter = useMemo(() => {
    if (vendorId) {
      return {
        [orderPropertyFilter.VendorId]: vendorId,
        ...extraPropertyFilters,
      }
    }

    return null
  }, [vendorId, extraPropertyFilters])

  const defaultFilter = useMemo(() => {
    if (userId) {
      return {
        [orderPropertyFilter.CustomerCode]: userId,
        ...extraPropertyFilters,
      }
    }
    return null
  }, [userId, extraPropertyFilters])

  switch (lineOfBusiness) {
    case LinesOfBusiness.vendor:
      return vendorFilter
    default:
      return defaultFilter
  }
}
