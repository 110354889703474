/**
 * calculates compensation amount of the passed selectem item amount by compensation percent
 * rounds the total amount to the passed granularity upwards
 * */

export const calculateRefundAmountForVoucherOrAuto = (
  selectedItemsTotalAmount: number,
  compensation_percent: number,
  granularity_value: number,
  min_voucher_value: number,
) => {
  let totalAmountRounded: number = null
  let compAmount: number = null

  if (selectedItemsTotalAmount <= 0) {
    return { compAmount: 0, totalAmountRounded: 0 }
  }

  // calculate compensation amount and total amount
  compAmount = (selectedItemsTotalAmount / 100) * compensation_percent
  const totalAmount = compAmount + selectedItemsTotalAmount

  // if total amount is smaller than granularity; set granularity as total amount
  if (totalAmount < granularity_value) {
    totalAmountRounded = granularity_value
  } else if (granularity_value > 0) {
    totalAmountRounded =
      granularity_value * (1 + Math.ceil((totalAmount - granularity_value) / granularity_value))
  } else {
    totalAmountRounded = totalAmount
  }

  // set total amount as min voucher val, if total amount is smaller
  if (totalAmountRounded < min_voucher_value) {
    totalAmountRounded = min_voucher_value
  }

  return { compAmount, totalAmountRounded }
}
