export enum LinesOfBusiness {
  rider = 'rider',
  customer = 'customer',
  vendor = 'vendor',
  riderV2 = 'riderV2',
}

export const LineOfBusinessApiMap = {
  [LinesOfBusiness.customer]: 'customerservice',
  [LinesOfBusiness.vendor]: 'vendorservice',
  [LinesOfBusiness.rider]: 'riderservice',
  [LinesOfBusiness.riderV2]: 'riderservice_v2',
}
