import environment from 'envConfig'
import { ApiService } from 'services/authApi/getPermissions'
import { LinesOfBusiness } from 'types/session/linesOfBusiness'
import { ScreenName, UiVersion } from 'types/unitedUiConfig'
import { v4 as uuid } from 'uuid'

export interface EtsEventPayload {
  screen?: {
    name?: ScreenName
    details?: Record<string, any>
  }

  /**
   * the event details
   */
  eventDetails?: Record<string, any>

  /**
   * the contact details
   */
  contactDetails?: Record<string, any>

  /**
   * event financial specific details
   */
  financial?: {
    id?: string
    refundValue?: number
    compensationValue?: number
    currencyCode?: string
    paymentMedium?: string
    details?: Record<string, string | number | boolean>
    // totalDamagedValue?: number
    withVoucher?: boolean
  }
}

export const postEventToEts: ApiService<
  {
    uiVersion: UiVersion
    trackingToken: string
    entityId: string
    eventName: string
    payload: EtsEventPayload
    timestamp: string
    agentEmail: string
    agentName: string
    lob: LinesOfBusiness
    orderId: string
    caseId: string
    sessionId: string
    customerId: string
    vendorId: string
    globalVendorId: string
    riderId: string
  },
  any
> = (createClient, options) => {
  const {
    entityId,
    clientParams,
    riderId,
    vendorId,
    customerId,
    globalVendorId,
    orderId,
    lob,
    sessionId,
    timestamp,
    eventName,
    agentEmail,
    trackingToken,
    uiVersion,
    // agentName,
    payload: { eventDetails = {}, screen, contactDetails = {}, financial = null },
  } = options

  const caseId = options.caseId || 'missing'

  const eventBody: Record<string, any> = {
    globalEntityId: entityId,
    timestamp,

    event: {
      id: uuid(),
      name: eventName,
      details: {
        ...eventDetails,
      },
    },

    product: {
      name: 'oneview',
      version: uiVersion,
    },
  }

  if (orderId) {
    eventBody.order = {
      id: orderId,
      details: {},
    }
  }

  // add financials if given
  if (financial) {
    eventBody.financial = { id: uuid(), ...financial }
  }

  // assign agent info
  eventBody.user = {
    id: agentEmail,
  }

  // assign session id
  contactDetails.work_item_id = sessionId?.trim() || 'missing'

  // assign contact
  switch (lob) {
    case LinesOfBusiness.customer:
      eventBody.contact = {
        id: caseId,
        details: { requester_id: customerId, lob, ...contactDetails },
      }
      break

    case LinesOfBusiness.rider:
      eventBody.contact = {
        id: caseId,
        details: { requester_id: riderId, lob },
      }
      break

    case LinesOfBusiness.vendor:
      eventBody.contact = {
        id: caseId,
        details: { requester_id: vendorId || globalVendorId, lob },
      }
      break

    case LinesOfBusiness.riderV2:
      eventBody.contact = {
        id: caseId,
        details: { requester_id: riderId, lob },
      }
      break
  }

  // assign screen
  if (screen) {
    eventBody.screen = {
      name: screen.name,
      details: screen.details || {},
    }
  }

  return createClient({
    baseUrl: environment().etsBaseUrl,
    endpointName: 'eventToEvts',
    ...clientParams,
    attachHeaders: false,
    validateJwt: false,
    headers: {
      Authorization: `Bearer ${trackingToken}`,
    },
  }).post('/events/v1/events', eventBody)
}
