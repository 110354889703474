import { OrderApiResponse } from 'types/herocare'
import { getLastValue } from 'utils/array'

type PaymentsBreakdown = {
  walletValue: number
  voucherValue: number
  giftCardValue: number
  allowanceValue: number
  currentOrderValue: number
  originalOrderValue: number
}

export const getOrderPaymentBreakdown = (order: OrderApiResponse): PaymentsBreakdown => {
  const {
    coupon = 0,
    total_value = 0,
    wallet_balance = 0,
    giftcard_amount = 0,
    allowance_amount = 0,
    total_order_value = 0,
    original_total_order_value = 0,
  } = order.customer?.payment ?? {}

  const { wallet_amount = 0 } = order?.addons ?? {}

  const lastBasketUpdate = getLastValue(order?.basket_updates ?? [])

  const beforeFirstBasketUpdateOrderValue = lastBasketUpdate?.price_before?.total_order_value

  return {
    voucherValue: coupon,
    giftCardValue: giftcard_amount,
    allowanceValue: allowance_amount,
    walletValue: wallet_amount + wallet_balance,
    currentOrderValue: total_order_value ?? total_value,
    originalOrderValue:
      original_total_order_value ||
      beforeFirstBasketUpdateOrderValue ||
      total_order_value ||
      total_value ||
      0,
  }
}
