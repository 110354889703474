import React from 'react'
import { createPluggableWidget } from 'factory/createPluggableWidget'
import { BuiltinWidgetConfigs, LinesOfBusiness } from 'types'
import GenericSummaryPanel from './components/GenericSummaryPanel/GenericSummaryPanel'

export const SummaryPanel = createPluggableWidget<BuiltinWidgetConfigs['summary_panel']>(
  (props) => {
    const { lob, sdk, rider, config, IconRenderer, orderFulfillment } = props

    let order = props?.order
    let vendor = props?.vendor

    // For riderV2 LOB, replace order and vendor with rider's current order and vendor
    if (lob === LinesOfBusiness.riderV2) {
      order = props?.riderCurrentOrder
      vendor = props?.riderCurrentVendor
    }
    return (
      <GenericSummaryPanel
        lob={lob}
        config={config}
        order={order}
        rider={rider}
        vendor={vendor}
        sdk={sdk}
        orderFulfillment={orderFulfillment}
        IconRenderer={IconRenderer}
      />
    )
  },
  {
    deriveConfig({ entityConfig }) {
      return entityConfig.layout_v2.builtin_widgets_configs.summary_panel
    },
    category: 'data_lookup',
  },
)
