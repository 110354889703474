export default {
  riderServiceMapHolder: {
    height: '309px',
    width: '250px',
    minWidth: '250px',
  },
  customerServiceMapHolder: {
    height: '309px',
    minWidth: '250px',
  },
  latAndLngContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    marginTop: '5px',
  },
  latandLngTexts: {
    fontSize: '10px',
    color: 'rgba(0, 0, 0, 0.45)',
  },
  latandLngTextsNew: {
    fontSize: '10px',
    color: '#f55b5b',
  },
  latandLngData: {
    fontSize: '10px',
    color: 'rgba(0, 0, 0, 0.85)',
  },
}
