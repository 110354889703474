import { voucherApiErrorConfig, ApiErrorConfigs } from './voucherApiErrorConfig'

import { paymentApiErrorConfig } from './paymentApiErrorConfig'
import { authApiErrorConfig } from './authApiErrorConfig'
import { ordersApiErrorConfig } from './ordersApiErrorConfig'
import { commentApiErrorConfig } from './commentApiErrorConfig'
import { vendorApiErrorConfig } from './vendorApiErrorConfigs'
import { fulfillmentApiErrorConfig } from './fulfillmentApiErrorConfigs'
import { customerApiErrorConfig } from './customerApiErrorConfig'
import { adminApiErrorConfig } from './adminApiErrorConfigs'

export const apiErrorConfigs: ApiErrorConfigs = {
  ...adminApiErrorConfig,
  ...voucherApiErrorConfig,
  ...paymentApiErrorConfig,
  ...authApiErrorConfig,
  ...ordersApiErrorConfig,
  ...commentApiErrorConfig,
  ...vendorApiErrorConfig,
  ...fulfillmentApiErrorConfig,
  ...customerApiErrorConfig,
}
