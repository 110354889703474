import { ApiService } from 'services/authApi/getPermissions'

export interface GetProposedCompensationApiResponse {
  value: number
  compensation_token: string
  minimum_order_value: number
  maximum_order_value: number
  expired_at: string
  event_id: string
  trigger_id: string
}

export const getProposedCompensationValue: ApiService<
  {
    entityId: string
    customerId: string
    orderId: string
    totalOrderValue: number
    damagedValue: number
    issueType: { ccr_code: string; issue: string }
  },
  GetProposedCompensationApiResponse
> = (createClient, options) => {
  const { entityId, orderId, customerId, issueType, totalOrderValue, clientParams, damagedValue } =
    options
  const uri = `/VoucherAPI/v2/order/${entityId}/${orderId}/compensation/propose`

  const body = {
    customer_id: customerId,
    ccr_code: issueType.ccr_code,
    type: issueType.issue,
    order_value: totalOrderValue,
    damaged_value: damagedValue,
  }

  return createClient({
    endpointName: 'getProposedCompensationValue',
    expectedResponseStatusCode: 200,
    responseMustHaveData: true,

    validateData: (data) => {
      if (typeof data.event_id === 'undefined') {
        return `Success response data is missing event_id field`
      } else if (typeof data.value === 'undefined') {
        return `Success response data is missing value field`
      }

      return true
    },
    ...clientParams,
  }).post(uri, body)
}
