import { divider } from 'theme'
import { WidgetSize } from 'types/widgets/historyOfEvents/tabs'

const singleCommentCommon = {
  padding: '5px 0px 16px 5px',
  marginTop: '5px',
  alignItems: 'center',
  borderBottom: '1px solid #F0F0F0',
  rowGap: '8px',
  display: 'flex',
  flexDirection: 'column',
}

const singleCommentHolder = singleCommentCommon

const singleCommentHolderHighlighted = {
  ...singleCommentCommon,
  backgroundColor: '#F6FFED',
}

export default {
  contentHolder: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.02)',
    padding: '7px',
  },
  headerHolder: {
    display: 'flex',
    alignItems: 'baseline',
    justifyContent: 'space-between',
  },
  eventsTitle: {
    fontSize: '14px',
    fontWeight: 600,
    color: 'rgba(0, 0, 0, 0.85)',
  },
  filterTitle: {
    fontSize: '12px',
    color: 'rgba(0, 0, 0, 0.45)',
    marginTop: '7px',
  },
  menuItem: {
    fontSize: '13px',
    borderBottom: '1px #F0F0F0 solid',
  },
  dropdownButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    fontSize: '14px',
    color: 'rgba(0, 0, 0, 0.65)',
    width: '70%',
  },
  dropdownIcon: {
    color: 'rgba(0, 0, 0, 0.25)',
  },
  commentsHolder: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    overflowY: 'auto',
  },
  singleCommentHolder,
  singleCommentHolderHighlighted,
  commentHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'baseline',
    marginBottom: '5px',
  },
  commentDateText: {
    color: 'rgba(0, 0, 0, 0.45)',
    fontSize: '11px',
    display: 'flex',
    justifyContent: 'flex-end',
    whiteSpace: 'nowrap',
  },
  orderIdText: {
    color: '#1890FF',
    marginTop: '10px',
  },
  commentText: {
    color: 'rgba(0, 0, 0, 0.85)',
    fontSize: '14px',
    padding: '4px 0px',
  },
  agentIcon: {
    width: '24px',
    height: '24px',
    display: 'inline-flex',
    fontSize: '12px',
    lineHeight: '100%',
    verticalAlign: 'middle',
  },
  orderIdDisplay: {
    display: 'inline-flex',
    justifyItems: 'center',
  },
  header: {
    display: 'flex',
    gap: '14px',
    flexDirection: 'row',
    width: '100%',
    columnGap: '12px',
    alignItems: 'center',
  },
  headerContent: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    alignItems: 'center',
  },
  leftCell: {
    display: 'flex',
    flexDirection: 'row',
    columnGap: '10px',
    alignItems: 'center',
    justifyContent: 'center',
  },
  content: (mode: WidgetSize) => {
    return {
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
      paddingLeft: mode === 'mini' ? 0 : '34px',
    }
  },
  tagContainer: { marginLeft: '6px' },
  divider: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '15px',
    borderLeft: '1px solid rgba(0, 0, 0, 0.55)',
  },
  footer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },

  issueType: (mode: WidgetSize) => {
    return {
      display: 'flex',
      paddingLeft: mode === 'mini' ? 0 : '34px',
      alignSelf: mode === 'mini' ? 'center' : 'start',
    }
  },

  notes: (mode: WidgetSize) => {
    return {
      display: 'flex',
      alignSelf: mode === 'mini' ? 'center' : 'start',
      columnGap: '12px',
      paddingLeft: mode === 'mini' ? 0 : '34px',
      marginTop: '2px',
    }
  },

  fallbackCases: (mode: WidgetSize) => {
    return {
      border: `1px solid ${divider}`,
      borderRadius: '6px',
      display: 'flex',
      alignSelf: mode === 'mini' ? 'center' : 'start',
      marginLeft: mode === 'mini' ? 0 : '34px',
      padding: '6px 12px',
      marginTop: '4px',
    }
  },

  caseDivider: {
    height: '24px',
    borderLeft: '1px solid rgba(0, 0, 0, 0.06)',
  },
}
