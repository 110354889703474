import { ApiService } from 'services/authApi/getPermissions'
import { CustomerChatHistoryApiResponse } from 'types/api/ticketApi/getCustomerChatHistory'

export const getCustomerChatHistory: ApiService<
  {
    entityId: string
    roomID: string
    department: string
    roomName: string
  },
  CustomerChatHistoryApiResponse
> = (createClient, options) => {
  const { entityId, roomID, department, roomName, clientParams } = options

  const uri = `/TicketAPI/v1/chat/${entityId}/rooms/${roomID}/history?department=${department}&room_name=${roomName}`

  return createClient({
    endpointName: 'getCustomerChatHistory',
    expectedResponseStatusCode: 200,
    ...clientParams,
  }).get(uri)
}
