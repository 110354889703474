import { ApiService } from 'services/authApi/getPermissions'
import { PaymentHistoryApiResponse } from 'types/api/paymentApi/getPaymentHistory'

export const getCustomerOrderPayments: ApiService<
  {
    entityId: string
    orderId: string
    customerId: string
  },
  PaymentHistoryApiResponse
> = (createClient, options) => {
  const { entityId, orderId, clientParams, customerId } = options

  const uri = `/PaymentAPI/v1/order/${entityId}/${orderId}/payments?customer_id=${customerId}`

  return createClient({
    endpointName: 'getCustomerOrderPayments',
    expectedResponseStatusCode: [200],
    ...clientParams,
  }).get(uri)
}
