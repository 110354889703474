import { WidgetSubjects, WidgetView } from 'types/unitedUiConfig'

export const getMatchingViewForSubjects = (
  widgetId: string,
  views: WidgetView[],
  subjects: WidgetSubjects,
) => {
  const subjectKeys = Object.keys(subjects) as Array<keyof WidgetSubjects>

  return views.find((view) => {
    if (view.widgetId !== widgetId) {
      return false
    }

    const currentViewSubjectKeys = Object.keys(view.subjects)
    return (
      currentViewSubjectKeys.length === subjectKeys.length &&
      subjectKeys.every((subjectKey) => view.subjects[subjectKey] === subjects[subjectKey])
    )
  })
}
