// libs
import React, { useState, useEffect, useContext, FunctionComponentElement } from 'react'
// configs
import { allowedActions } from 'entityConfig/allowedConfigValues'
// context and types
import { RiderServiceContext } from 'contexts/riderService/RiderServiceContext'
import { RiderServiceAction } from 'contexts/riderService/types'
import { EntityContext } from 'contexts/entity/EntityContext'
import { DisabledButtonViewProps } from 'types/components/disabledButtonView'
// hooks
import { useTranslation } from 'hooks/useTranslation'
// utils
// styles
import { createUseStyles } from 'react-jss'
import styles from './Actions.styles'
import { Typography, Button } from 'antd'
// components
import ModifyDeliveryAddress from 'widgets/Actions/ModifyDeliveryAddress'
import CancelOrder from './CancelOrder'
import DisabledButtonView from 'components/DisabledButtonView'
import { SessionContext } from 'contexts/session/SessionContext'
import getDisabledActionsDueToCcrWhitelist from 'utils/getDisabledActionsDueToCcrWhitelist'
import { DisabledFeature } from 'types/actions/feature'
import { useIsDataPointValid } from 'hooks/useGetValidFeatures'

const useStyles = createUseStyles(styles)

const Actions = () => {
  const classes = useStyles()
  const { Text } = Typography

  // pull translations
  const { t } = useTranslation()
  const isDataPointValid = useIsDataPointValid()

  // pull actions config from context
  const {
    entityState: {
      entityConfig: {
        useCcrWhitelistForActionButtons,
        fixed_panel_config: {
          widgets_configs: {
            actions: { available_actions, change_delivery_address },
          },
        },
      },
    },
  } = useContext(EntityContext)

  const {
    sessionState: { ccrCode },
  } = useContext(SessionContext)

  // pull rider context
  const {
    riderServiceState: { order, orderStatus, vendor, rider, orderError },
    riderServiceDispatch,
  } = useContext(RiderServiceContext)
  const { SET_IS_NEW_COMMENT } = RiderServiceAction

  const [actionsPermitted, setActionsPermitted] = useState<string[]>([])
  useEffect(() => {
    let actions: string[] = []

    if (available_actions && available_actions.length > 0) {
      for (const el of available_actions) {
        const isActionAvailable = isDataPointValid(el.betaRequirement)
        if (isActionAvailable) {
          actions.push(el.feature)
        }
      }

      const disabledActionsDueToCcrWhitelist = useCcrWhitelistForActionButtons
        ? getDisabledActionsDueToCcrWhitelist(ccrCode)(available_actions)
        : ([] as DisabledFeature[])

      actions = actions.filter((action) => {
        return !disabledActionsDueToCcrWhitelist.find(
          (disabledAction) => disabledAction.name === action,
        )
      })
    }

    setActionsPermitted(actions)
  }, [available_actions, ccrCode, useCcrWhitelistForActionButtons, isDataPointValid])

  const handleOpenNewComment = () => {
    riderServiceDispatch({
      type: SET_IS_NEW_COMMENT,
      payload: { isNewComment: true },
    })
  }

  // determine whether Delivery Settings should be displayed to the user
  const [modifyDeliveryAddressComponent, setModifyDeliveryAddressComponent] =
    useState<FunctionComponentElement<DisabledButtonViewProps>>(null)
  useEffect(() => {
    // set availableComponent to a disabled button with a tooltip
    const availableComponent = <ModifyDeliveryAddress />
    const disabledComponentWithGenericMessage = (
      <DisabledButtonView
        tooltipMsg={t(
          'Actions Widget.Actions.Change Address.Changing delivery address and instructions is not possible',
        )}
        buttonText={t('Actions Widget.Actions.Change Address.Title')}
      />
    )

    const lastStatus =
      orderStatus?.length && orderStatus[orderStatus.length - 1].status
        ? orderStatus[orderStatus.length - 1].status
        : t('Order Widget.Tabs.Status.Order Statuses.Placed')
    const provider = order?.delivery?.provider ? order.delivery.provider : null
    if (lastStatus && provider) {
      if (
        lastStatus !== 'CANCELLED' &&
        lastStatus !== 'DELIVERED' &&
        lastStatus !== 'REJECTED' &&
        provider !== 'pickup' &&
        provider !== 'vendor_delivery' &&
        provider !== 'partner_delivery'
      ) {
        setModifyDeliveryAddressComponent(availableComponent)
      } else if (
        provider === 'pickup' ||
        provider === 'vendor_delivery' ||
        provider === 'partner_delivery'
      ) {
        setModifyDeliveryAddressComponent(
          <DisabledButtonView
            tooltipMsg={t(
              'Actions Widget.Actions.Change Address.Changing order delivery address or instructions is not possible due to the provider',
            )}
            buttonText={t('Actions Widget.Actions.Change Address.Title')}
          />,
        )
      } else if (
        lastStatus === 'CANCELLED' ||
        lastStatus === 'DELIVERED' ||
        lastStatus === 'REJECTED'
      ) {
        setModifyDeliveryAddressComponent(
          <DisabledButtonView
            tooltipMsg={t(
              'Actions Widget.Actions.Change Address.Changing order delivery address or instructions is not possible due to the order status',
            )}
            buttonText={t('Actions Widget.Actions.Change Address.Title')}
          />,
        )
      } else {
        setModifyDeliveryAddressComponent(disabledComponentWithGenericMessage)
      }
    } else {
      setModifyDeliveryAddressComponent(disabledComponentWithGenericMessage)
    }
    setModifyDeliveryAddressComponent(availableComponent)
    // eslint-disable-next-line
  }, [order, vendor, rider, change_delivery_address, t, orderStatus])

  return (
    <div data-test-id='actions-container' className={classes.actionsContainer}>
      <Text className={classes.sectionTitle}>{t('Actions Widget.Title')}</Text>

      <div className={classes.buttonsContainer}>
        {/* New Comment */}
        {!actionsPermitted.includes(allowedActions.newComment) ? null : orderError && !rider ? (
          <DisabledButtonView
            tooltipMsg={`${t('Messages.No Order ID Found')}!`}
            buttonText={t('Actions Widget.Actions.New Comment.Title')}
          />
        ) : (
          <Button type='primary' size='small' onClick={handleOpenNewComment}>
            {t('Actions Widget.Actions.New Comment.Title')}
          </Button>
        )}

        {/* Cancel Order */}
        {actionsPermitted.includes(allowedActions.cancelOrder) && <CancelOrder />}

        {/* Modify Delivery Address */}
        {actionsPermitted.includes(allowedActions.modifyDeliveryAddress) &&
          modifyDeliveryAddressComponent}
      </div>
    </div>
  )
}

export default Actions
