import React, { MouseEventHandler, useState } from 'react'
import { createUseStyles } from 'react-jss'
import { Alert, Col, Row } from 'antd'
import { ExpandOrCollapseButton } from './ExpandOrCollapseButton'
import { SingleIssue } from './SingleIssue'
import { DeliveryIssue } from 'types/api/fulfillmentApi/fulfillment'
import { gold } from 'theme'
import { UnifiedIcon } from 'shared'

const useStyles = createUseStyles({
  container: {
    padding: '4px 15px 4px 5px',
  },
  issues: {
    paddingTop: '3px',
    flexDirection: 'column',
  },
})

type Props = {
  issues?: DeliveryIssue[]
}

export const DeliveryIssues = ({ issues = [] }: Props) => {
  const styles = useStyles()
  const [isExpanded, setIsExpanded] = useState(false)
  const visibleIssues = isExpanded ? issues : issues.slice(0, 2)

  const handleToggleClick: MouseEventHandler = (event) => {
    event.stopPropagation()
    setIsExpanded((currentState) => !currentState)
  }

  if (!issues.length) return null

  return (
    <Alert
      className={styles.container}
      message={
        <Row gutter={[5, 0]}>
          <Col span={'auto'}>
            <UnifiedIcon icon={'NotificationIcon'} size={26} color={gold.gold6} />
          </Col>
          <Col flex={1}>
            <Row className={styles.issues} gutter={[0, 5]}>
              {visibleIssues.map((issue) => (
                <Col key={issue.id}>
                  <SingleIssue issue={issue} />
                </Col>
              ))}
              {issues.length > 2 && (
                <Col>
                  <ExpandOrCollapseButton isExpanded={isExpanded} onClick={handleToggleClick} />
                </Col>
              )}
            </Row>
          </Col>
        </Row>
      }
      type='warning'
    />
  )
}
