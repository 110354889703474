import { DataContext } from 'contexts/data/DataContext'
import { DataAction } from 'contexts/data/types'
import { useCallback, useContext } from 'react'

export const useClearCommentsFromDataContext = () => {
  const { dataDispatch } = useContext(DataContext)
  return useCallback(() => {
    dataDispatch({
      type: DataAction.SET_AUTO_CHANGE_DELIVERY_TIME_COMMENT,
      payload: { autoChangeDeliveryTimeComment: undefined },
    })
    dataDispatch({
      type: DataAction.SET_AUTO_CHANGE_ADDRESS_COMMENT,
      payload: { autoChangeAddressComment: undefined },
    })
    dataDispatch({
      type: DataAction.SET_AUTO_CHANGE_INSTRUCTIONS_COMMENT,
      payload: { autoChangeInstructionsComment: undefined },
    })
    dataDispatch({
      type: DataAction.SET_AUTO_CALLBACK_COMMENT,
      payload: { autoModifyCallbackComment: undefined },
    })
    dataDispatch({
      type: DataAction.SET_AUTO_COMPENSATION_COMMENT,
      payload: { autoCompensationComment: undefined },
    })
    dataDispatch({
      type: DataAction.SET_AUTO_FULL_REFUND_COMMENT,
      payload: { autoFullRefundComment: undefined },
    })
    dataDispatch({
      type: DataAction.SET_AUTO_MISSING_ITEM_COMMENT,
      payload: { autoMissingItemReportComment: undefined },
    })
    dataDispatch({
      type: DataAction.SET_AUTO_PARTIAL_REFUND_COMMENT,
      payload: { autoPartialRefundComment: undefined },
    })
    dataDispatch({
      type: DataAction.SET_AUTO_ITEM_REMOVAL_COMMENT,
      payload: { autoRemoveItemsComment: undefined },
    })
  }, [dataDispatch])
}
