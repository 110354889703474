import { VoucherDataPoint } from 'types/api/voucherApi/voucher'

//May become configurable in future.
const defaultSelectedFields = [
  'description',
  'code',
  'valid_dates',
  'value',
  'min_order_value',
  'max_order_value',
  'expedition_types',
  'payment_types',
  'vertical_types',
]

/** Return object with default selected voucher fields */
export const setDefaultSelection = (
  voucherDataPoints: VoucherDataPoint[],
  selectedFields = defaultSelectedFields,
) => {
  const voucherDataPointsMap = new Map()
  voucherDataPoints.forEach((datum) => voucherDataPointsMap.set(datum.id, datum))

  const selected = Object.create(null)
  selectedFields.forEach((field) => {
    const dp = voucherDataPointsMap.get(field)
    if (dp) {
      selected[dp.label] = dp.children || dp.value
    }
  })
  return selected
}
