import { ApiService } from 'services/authApi/getPermissions'
import CustomerWalletApiResponse from 'types/api/paymentApi/getCustomerWallet'

export const getCustomerWallet: ApiService<
  {
    entityId: string
    customerId: string
  },
  CustomerWalletApiResponse
> = (createClient, options) => {
  const { entityId, customerId, clientParams } = options

  const uri = `/PaymentAPI/v1/customer/${entityId}/${customerId}/wallet`

  return createClient({
    endpointName: 'getCustomerWallet',
    expectedResponseStatusCode: 200,
    ...clientParams,
  }).get(uri)
}
