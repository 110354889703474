// libs
import React from 'react'
// hooks
import { useTranslation } from 'hooks/useTranslation'
// utils
// styles
import { createUseStyles } from 'react-jss'
import styles from './NewAddress.styles'
import { Typography, Input, Collapse, Alert, Divider } from 'antd'
import { useCaptureUserAction } from 'hooks/events/useCaptureUserAction'

const useStyles = createUseStyles(styles)

interface Props {
  isNewAddressDeliverable: boolean
  newAddressFloor: string
  newAddressCompany: string
  newAddressApartment: string
  newAddressStreetNumber: string
  newAddressStreet: string
  newAddressPostalcode: string
  handleExtraInputFieldChange: (arg1: any, arg2: string) => void
  validateAddress: boolean
}

const NewAddress: React.FC<Props> = ({
  isNewAddressDeliverable,
  newAddressFloor,
  newAddressCompany,
  newAddressApartment,
  newAddressStreetNumber,
  newAddressStreet,
  newAddressPostalcode,
  handleExtraInputFieldChange,
  validateAddress,
}) => {
  const classes = useStyles()
  const { Text } = Typography
  const { Panel } = Collapse

  // pull translations
  const { t } = useTranslation()
  const captureUserAction = useCaptureUserAction()

  return (
    <div className={classes.extraInputsContainer}>
      <div className={classes.newInputFieldRow}>
        <div className={classes.extraInput}>
          <Text className={classes.extraInputText}>
            {t('Actions Widget.Actions.Change Address.Number')}
          </Text>
          <Input
            className={classes.disabledInput}
            placeholder={`${t('Actions Widget.Actions.Change Address.Number')}...`}
            value={newAddressStreetNumber ? newAddressStreetNumber : ''}
            disabled={true}
          />
        </div>
        <div className={classes.extraInput}>
          <Text className={classes.extraInputText}>
            {`${t('Actions Widget.Actions.Change Address.Postcode')}`}
          </Text>
          <Input
            className={classes.disabledInput}
            placeholder={`${t('Actions Widget.Actions.Change Address.Postcode')}...`}
            value={newAddressPostalcode ? newAddressPostalcode : ''}
            disabled={true}
          />
        </div>
      </div>

      <div className={classes.newInputFieldRow}>
        <div className={classes.extraInputFullWidth}>
          <Text className={classes.extraInputText}>
            {t('Actions Widget.Actions.Change Address.Street')}
          </Text>
          <Input
            className={classes.disabledInput}
            placeholder={`${t('Actions Widget.Actions.Change Address.Street')}...`}
            value={newAddressStreet ? newAddressStreet : ''}
            disabled={true}
          />
        </div>
      </div>

      <Collapse
        onChange={() => captureUserAction('ActionsChangeAddressAdditionalDetailsClicked')}
        className={classes.collapse}
        accordion={true}
        expandIconPosition='end'
        ghost
      >
        <Panel
          header={
            <Text className={classes.additionalDetailsText}>
              {t('Actions Widget.Actions.Change Address.Additional Address Details')}
            </Text>
          }
          key='1'
        >
          <Divider style={{ padding: 0, margin: 0, marginTop: -12 }} />
          <div className={classes.newInputFieldRow}>
            <div className={classes.extraInputFullWidth}>
              <Text className={classes.extraInputText}>
                {`${t('Actions Widget.Actions.Change Address.Company')}`}
              </Text>
              <Input
                placeholder={t('Messages.Optional')}
                value={newAddressCompany ? newAddressCompany : ''}
                onChange={(e) => handleExtraInputFieldChange(e, 'company')}
              />
            </div>
          </div>

          <div className={classes.newInputFieldRow}>
            <div className={classes.extraInput}>
              <Text className={classes.extraInputText}>
                {`${t('Actions Widget.Actions.Change Address.Apartment')}`}
              </Text>
              <Input
                placeholder={t('Messages.Optional')}
                value={newAddressApartment ? newAddressApartment : ''}
                onChange={(e) => handleExtraInputFieldChange(e, 'apartment')}
              />
            </div>
            <div className={classes.extraInput}>
              <Text className={classes.extraInputText}>
                {`${t('Actions Widget.Actions.Change Address.Floor / Unit')}`}
              </Text>
              <Input
                placeholder={t('Messages.Optional')}
                value={newAddressFloor ? newAddressFloor : ''}
                onChange={(e) => handleExtraInputFieldChange(e, 'floor')}
              />
            </div>
          </div>
        </Panel>
      </Collapse>

      {/* Address Valid alert */}
      <Alert
        className={classes.alert}
        message={
          <Text className={classes.alertText}>
            {!validateAddress
              ? t(
                  'Actions Widget.Actions.Change Address.Address not verified against delivery zone',
                )
              : isNewAddressDeliverable
              ? t('Actions Widget.Actions.Change Address.Address within delivery zone')
              : t('Actions Widget.Actions.Change Address.Address not within delivery zone')}
          </Text>
        }
        type={isNewAddressDeliverable ? 'success' : 'warning'}
        showIcon
      />
    </div>
  )
}
export default NewAddress
