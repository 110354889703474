import { useApiClientCreator } from 'contexts/apiClientCreator/ApiClientCreatorContext'
import { DataContext } from 'contexts/data/DataContext'
import { DataAction } from 'contexts/data/types'
import { SessionContext } from 'contexts/session/SessionContext'
import { SessionAction } from 'contexts/session/types'
import { useApiService } from 'hooks/useApiService'
import { useEventEmitter } from 'hooks/useEventEmitter'
import noop from 'lodash/noop'
import { useContext, useEffect, useState } from 'react'
import { getLatestVendorOrder } from 'services/ordersApi/getLatestVendorOrder'
import { getOrder } from 'services/ordersApi/getOrder'
import { OrderSingleBasketUpdate } from 'types/api/orderApi/orderBasketUpdate'
import { LinesOfBusiness } from 'types/herocare'
import { OrderItemT } from 'types/widgets/order/orderItem'
import { EmittedEventName } from 'utils/eventEmitter'
import createOrderItemAndToppingIndexes from 'utils/oneviewApi/createOrderItemAndToppingIndexes'

const {
  SET_ORDER,
  SET_ORDER_BASKET_UPDATES,
  SET_ORDER_ITEMS,
  SET_ORDER_ERR,
  SET_CURRENCY,
  SET_CUSTOMER,
  SET_IS_LOADING_ORDER,
} = DataAction

export function useLoadOrder() {
  const { createClient } = useApiClientCreator()
  const eventEmitter = useEventEmitter('global_order')

  const {
    sessionState: { orderId, globalEntityId, lineOfBusiness, vendorId, globalVendorId },
    sessionDispatch,
  } = useContext(SessionContext)
  const { dataDispatch } = useContext(DataContext)

  const hasOrderId = Boolean(orderId)

  const loadOderResults = useApiService({
    service: getOrder,
    autoLoad: true,
    shouldLoad: Boolean(globalEntityId && orderId),
    params: {
      entityId: globalEntityId,
      orderId,
      clientParams: {
        cacheable: true,
      },
    },

    onBeforeLoad() {
      dataDispatch({
        type: SET_IS_LOADING_ORDER,
        payload: { isLoadingOrder: true },
      })
    },

    onSuccess(res) {
      const order = createOrderItemAndToppingIndexes(res.data)

      const { customer, vendor, basket_updates } = order
      const { items } = order?.order || {}

      // Clean up error message after a successful request/retry
      dataDispatch({ type: SET_ORDER_ERR, payload: { orderError: null } })

      // update entity config to odr entity
      if (
        [LinesOfBusiness.customer, LinesOfBusiness.riderV2].includes(lineOfBusiness) &&
        order?.ov_meta_data?.config_id
      ) {
        sessionDispatch({
          type: SessionAction.SET_SESSION,
          payload: {
            entityConfigId: order?.ov_meta_data?.config_id,
          },
        })
      }

      // update vendor id
      if (vendor?.id && !vendorId) {
        sessionDispatch({
          type: SessionAction.SET_SESSION,
          payload: {
            vendorId: vendor.id,
          },
        })
      }

      // set order items
      dataDispatch({
        type: SET_ORDER_ITEMS,
        payload: { orderItems: (items || []) as OrderItemT[] },
      })

      // set order updates, after sorting newest to oldest, to data context when available
      const updatesSortedNewestToOldest = (basket_updates || []).sort(
        (a: OrderSingleBasketUpdate, b: OrderSingleBasketUpdate) =>
          b.timestamp < a.timestamp ? -1 : b.timestamp > a.timestamp ? 1 : 0,
      )
      dataDispatch({
        type: SET_ORDER_BASKET_UPDATES,
        payload: { orderBasketUpdates: updatesSortedNewestToOldest },
      })

      // set currency to data context
      if (order.currency) {
        dataDispatch({
          type: SET_CURRENCY,
          payload: { currency: order.currency },
        })
      }

      // set customer to data context
      dataDispatch({
        type: SET_CUSTOMER,
        payload: { customer: customer || null },
      })

      // set order
      dataDispatch({
        type: SET_ORDER,
        payload: { order: { ...order, status_history: order.status_history || [] } },
      })
    },

    onError(res) {
      dataDispatch({ type: SET_ORDER_ERR, payload: { orderError: res.errorPayload } })
    },
  })

  const [initializingOrderId, setInitializingOrderId] = useState(!hasOrderId)

  // initialize order id
  useEffect(() => {
    // OrderID fetching is only relevant for Vendor LOB
    if (hasOrderId || lineOfBusiness !== LinesOfBusiness.vendor) {
      setInitializingOrderId(false)
      return
    }

    // If none of the Vendor IDs is available we cannot fetch vendor orders. We display "Data Unavailable" instead
    if (!vendorId && !globalVendorId) {
      setInitializingOrderId(false)
      return
    }

    // There is a GlobalVendorId in the context, we keep waiting until vendorId is available.
    if (!initializingOrderId || !vendorId) {
      return
    }

    getLatestVendorOrder(createClient, {
      entityId: globalEntityId,
      vendorId: vendorId,
    })
      .then((res) => {
        sessionDispatch({
          type: SessionAction.SET_SESSION,
          payload: {
            orderId: res.data.order_id,
          },
        })
      })
      .catch(noop)
      .finally(() => setInitializingOrderId(false))
  }, [
    lineOfBusiness,
    globalEntityId,
    vendorId,
    globalVendorId,
    createClient,
    sessionDispatch,
    initializingOrderId,
    hasOrderId,
  ])

  if (initializingOrderId) {
    loadOderResults.loading = true
    loadOderResults.status = 'loading'
  }

  const { loadService: loadOrder } = loadOderResults

  // register event listener for refreshing of order
  useEffect(() => {
    if (globalEntityId && orderId) {
      const callback = () =>
        loadOrder({ entityId: globalEntityId, orderId, clientParams: { cacheable: false } })

      const events: Array<EmittedEventName> = [
        'PARTIAL_REFUND_SUCCESS',
        'FULL_REFUND_SUCCESS',
        'CHANGE_ADDRESS_SUCCESS',
        'CHANGE_DELIVERY_INSTRUCTIONS_SUCCESS',
        'CANCEL_ORDER_SUCCESS',
        'CHANGE_COOKING_INSTRUCTIONS_SUCCESS',
      ]

      eventEmitter.addEventsListener({ names: events, callback })
      return () => {
        eventEmitter.removeAllListeners()
      }
    }
  }, [eventEmitter, loadOrder, globalEntityId, orderId])

  return loadOderResults
}
