type PartType = 'link' | 'text' | 'image'

export interface MessagePart {
  type: PartType
  content: string
}

export const parseChatMessageString = (string: string): MessagePart[] => {
  const imageLinkRegex =
    /\b(?<image>https?:\/\/[^\s]*\.(?:png|jpe?g|gif))\b|\b(?<link>https?:\/\/[^\s]*)\b/g

  const splittedStringArray = string.split(/(\s+)/g)
  let textBuffer = ''
  const messageParts: MessagePart[] = []

  while (splittedStringArray.length) {
    const part = splittedStringArray.shift()
    const match = imageLinkRegex.exec(part)
    const matchType: PartType = match?.groups.image ? 'image' : 'link'

    if (match) {
      // if there is any text in text buffer, push it as a part
      if (textBuffer) {
        messageParts.push({ type: 'text', content: textBuffer })
      }

      // if the match doesn't match the whole part
      if (match[0] !== match.input) {
        //get the difference between the part and the match
        const remainingText = match.input.split(match[0])

        //if there is any preceding characters, add them to the last text message part
        if (remainingText[0].length) {
          const indexOfLastMessagePart = messageParts.length - 1

          messageParts[indexOfLastMessagePart].content += remainingText[0]
        }

        // add the trailing characters to the text buffer
        textBuffer = remainingText[1]
      } else {
        //reset text buffer
        textBuffer = ''
      }

      // push part as a link/image
      messageParts.push({ type: matchType, content: match[0] })
    } else {
      //concatenate part to existing text buffer value
      textBuffer += part
    }
  }

  // Push remaining text if there is any
  if (textBuffer) {
    messageParts.push({ type: 'text', content: textBuffer })
  }
  return messageParts
}
