import React from 'react'
import { Typography } from 'antd'
import { createUseStyles } from 'react-jss'
import { useTranslation } from 'hooks/useTranslation'
import capitalizeFirstLetters from 'utils/capitalizeFirstLetters'
import { SingleComment } from 'types/api/commentApi/getOrderComments'
import ReportVoucherPaymentRefundItems from '../../widgets/HistoryOfEvents/OrderHistory/ReportVoucherPaymentRefundItems'
import styles from './Comments.styles'
import { commentTagType, CommentView } from './CommentView'
import { WidgetSize } from 'types/widgets/historyOfEvents/tabs'

const { Text } = Typography

const useStyles = createUseStyles(styles)

type Props = {
  comment: SingleComment
  shouldCommentBeHighlighted: boolean
  mode: WidgetSize
}

const RefundRequestComment = ({ comment, shouldCommentBeHighlighted, mode }: Props) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <CommentView
      mode={mode}
      comment={comment}
      shouldCommentBeHighlighted={shouldCommentBeHighlighted}
      tag={{
        type: commentTagType.thunderbolt,
        text: t('History of Events Widget.Event Types.Refund Request'),
      }}
    >
      {' '}
      <Text className={classes.commentText}>
        <Text>
          {` ${t('History of Events Widget.Content.Issued')} ${t(
            'History of Events Widget.Content.Refund request',
          )} `}
        </Text>
        <Text strong>
          {`${comment.additional_data.refund_request.refund_currency} ${comment.additional_data.refund_request.refund_amount}`}
        </Text>
        <ReportVoucherPaymentRefundItems data={comment?.additional_data?.refund_request} />
        <span>
          {comment?.additional_data?.refund_request?.contact_reason &&
            ` ${t('Actions Widget.Actions.Compensation.Issue Type')}: ${capitalizeFirstLetters(
              comment.additional_data.refund_request.contact_reason.replace(/_/g, ' '),
            )}.`}
        </span>
      </Text>
    </CommentView>
  )
}

export default RefundRequestComment
