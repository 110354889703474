import React, { useCallback } from 'react'

// Components
import { Typography, Button } from 'antd'
import { CopyOutlined } from '@ant-design/icons'
import { Notification } from 'shared/Notification'

// Hooks
import { createUseStyles } from 'react-jss'

// Relative path
import styles from './WidgetId.styles'
import { logCopyError } from 'utils/reporting/logCopyError'

const useStyles = createUseStyles(styles)
const { Text } = Typography

type Props = {
  id: string
  successMessage: string
  warningMessage: string
  errorMessage: string
  idLabel: string
}

function WidgetId({ id, successMessage, warningMessage, errorMessage, idLabel }: Props) {
  const classes = useStyles()

  const handleCopyToClipboard = useCallback(() => {
    // copy messages
    const success = () => {
      Notification.success({ message: successMessage })
    }

    const warning = () => {
      Notification.warning({ message: warningMessage })
    }

    const error = () => {
      logCopyError()
      Notification.error({ message: errorMessage })
    }

    navigator.clipboard.writeText(id).then(
      () => (id?.length > 0 ? success() : warning()),
      () => error(),
    )
  }, [id, successMessage, warningMessage, errorMessage])

  return (
    <>
      <Text type='secondary' className={classes.idText}>{`${idLabel}: ${id}`}</Text>
      <Button className={classes.button} type='link' onClick={handleCopyToClipboard}>
        <CopyOutlined />
      </Button>
    </>
  )
}

export default WidgetId
