import { useForceUpdate } from 'contexts/useForceUpdate'
import { useSdkConstructParams } from 'hooks/useSdkConstructParams'
import { useTranslation } from 'hooks/useTranslation'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import {
  PluggableWidget,
  PluggableWidgetImperativeHandles,
  WidgetSubjectRequirementValidationResult,
  WidgetViewActionState,
  WidgetViewState,
} from 'types/unitedUiConfig'
import { useWidgetSubjects } from './useWidgetSubjects'
import { useValidateWidgetSubjectsRequirements } from './useValidateWidgetSubjectsRequirements'
import { useUpdatedRef } from 'hooks/useUpdatedRef'
import { useSdkConstruct } from 'hooks/sdkConstruct/useSdkConstruct'

export interface UseWidgetViewStateOpts<C> {
  config: C
  width?: number
  destroyable?: boolean
  actionState?: WidgetViewActionState
  labelTranslationKey?: string
  subjects: Parameters<typeof useWidgetSubjects>[0]
  deriveInitialViewState: PluggableWidget<C>['deriveInitialViewState']
  deriveSubjectsRequirements: PluggableWidget<C>['deriveSubjectsRequirements']
  onStateChange?: (newState: WidgetViewState) => void
  elevated: boolean
}

export const useWidgetViewState = <C>(opts: UseWidgetViewStateOpts<C>) => {
  const {
    subjects: givenSubjects,
    config,
    width = 650,
    labelTranslationKey,
    deriveInitialViewState,
    deriveSubjectsRequirements,
    onStateChange,
    destroyable = true,
    actionState = 'no-change',
    elevated = false,
  } = opts

  const { subjects, subjectsResolutionStates } = useWidgetSubjects(givenSubjects)
  const forceUpdate = useForceUpdate()
  const { t } = useTranslation()
  const label = t(labelTranslationKey)

  // default state
  const defaultState = useMemo(
    (): WidgetViewState => {
      let derivedInitialState: WidgetViewState

      if (deriveInitialViewState) {
        derivedInitialState = deriveInitialViewState({
          titleTranslationKey: labelTranslationKey,
          ...subjects,
          config,
          t,
        })
      }

      return {
        width,
        title: label,
        destroyable,
        actionState,
        elevated,
        ...derivedInitialState,
        crashed: false,
      }
    },
    // it is default state
    [
      elevated,
      label,
      deriveInitialViewState,
      t,
      width,
      config,
      destroyable,
      actionState,
      labelTranslationKey,
      subjects,
    ],
  )

  const [viewState, _setViewState] = useState(defaultState)

  const setViewState = useCallback(
    (newState: WidgetViewState) => {
      // reset state if newState is null
      if (newState === null) {
        _setViewState(defaultState)
      } else {
        _setViewState((prevState) => ({ ...prevState, ...newState }))
      }
    },
    [_setViewState, defaultState],
  )

  const onViewCrash = useCallback(() => setViewState({ crashed: true }), [setViewState])

  const onStateChangeRef = useUpdatedRef(onStateChange)

  const _handle = useRef<PluggableWidgetImperativeHandles>({})

  const handle = useMemo(() => {
    //Whenever the refresh handler is set, re-render core UI to show the refresh button in section header
    return new Proxy(_handle, {
      set(target, property, value) {
        if (target?.current?.onRefresh !== value?.onRefresh) {
          forceUpdate()
        }
        target[property] = value
        return true
      },
    })
  }, [forceUpdate])

  // deprecated, because of sdk
  const sdkConstructParams = useSdkConstructParams()

  const sdk = useSdkConstruct({
    setViewState,
  })

  const validateWidgetSubjectsRequirements = useValidateWidgetSubjectsRequirements()

  const subjectsValidationResult = useMemo((): WidgetSubjectRequirementValidationResult => {
    if (deriveSubjectsRequirements) {
      const subjectRequirements = deriveSubjectsRequirements({
        config,
        lob: subjects.lob,
      })
      return validateWidgetSubjectsRequirements(subjectRequirements, {
        subjectsResolutionStates,
      })
    }
    return {
      status: 'success',
      subject: null,
    }
  }, [
    subjects,
    subjectsResolutionStates,
    deriveSubjectsRequirements,
    config,
    validateWidgetSubjectsRequirements,
  ])

  // report state changes to any onStateChange handler
  useEffect(() => {
    if (onStateChangeRef.current) {
      onStateChangeRef.current(viewState)
    }
  }, [viewState, onStateChangeRef])

  // remove all event listeners from the widget
  useEffect(() => {
    return () => sdk.eventEmitter.removeAllListeners()
  }, [sdk.eventEmitter])

  return {
    viewState,
    setViewState,
    onViewCrash,
    subjects: subjects,
    sdkConstructParams,

    subjectsValidationResult,
    // pass this handle as ref to widgets that forward ref,
    handle,
    sdk,
  }
}
