/**
 * FallBackView: a UI component rendering whatever passed to it as a message
 * */

// libs
import React from 'react'
// styles
import styles from './FallBackView.styles'
import { createUseStyles } from 'react-jss'
import { Result } from 'antd'

const useStyles = createUseStyles(styles)

interface Props {
  title: string
  subTitle: string
}

const FallBackView: React.FC<Props> = ({ title, subTitle }) => {
  const classes = useStyles()

  return (
    <div className={classes.container}>
      <Result title={title} subTitle={subTitle}></Result>
    </div>
  )
}

export default FallBackView
