import React from 'react'
import { Space, TooltipProps } from 'antd'
import { createUseStyles } from 'react-jss'
import { InfoCircleOutlined } from '@ant-design/icons'
import { text } from 'theme'
import { getInlineAntdPopupNode } from 'utils/antd/getInlinePopupNode'
import { Dot } from '../Dot'
import { Text } from '../Text'
import { UnifiedIcon } from '../UnifiedIcon'
import { SmartToolTip } from '../SmartTooltip'
import { Spacing } from '.'

const useStyles = createUseStyles({
  root: {
    '& .ant-space-item': {
      lineHeight: '22px', // override the override.css
    },
  },
  shift: {
    marginLeft: '16px',
  },
})

const OverlayStyle: React.CSSProperties = {
  maxWidth: 280,
}

const OverlayInnerStyle: React.CSSProperties = {
  whiteSpace: 'pre-line',
}

/**
 * Monkeypatch topRight positioning. Fixed in antd v.5
 * @see {@link https://github.com/ant-design/ant-design/issues/7038}
 */
const TooltipAlignment: TooltipProps['align'] = { points: ['br', 'tc'], offset: [24, 6] }

type StatusProps = {
  cancellationReason?: {
    title: string
    description: string
  }
  statusColor: string
  value: string
  delayTime?: {
    color: string
    text: string
  }
}

export function Status({ statusColor, cancellationReason, value, delayTime }: StatusProps) {
  const styles = useStyles()
  const hasCancellationReason = cancellationReason?.title

  return (
    <Space direction='vertical' size={0} className={styles.root}>
      <Space size={Spacing.HORIZONTAL}>
        <Dot color={statusColor} />
        {value}
      </Space>
      {hasCancellationReason ? (
        <Space size={Spacing.HORIZONTAL} className={styles.shift}>
          <Text.Primary color={text.secondary}>{cancellationReason.title}</Text.Primary>
          <SmartToolTip
            placement='topRight'
            overlayStyle={OverlayStyle}
            overlayInnerStyle={OverlayInnerStyle}
            arrowPointAtCenter={true}
            align={TooltipAlignment}
            title={cancellationReason.description}
            getPopupContainer={getInlineAntdPopupNode}
          >
            <span>
              <UnifiedIcon icon={InfoCircleOutlined} color={text.secondary} size={12} />
            </span>
          </SmartToolTip>
        </Space>
      ) : null}
      {delayTime ? (
        <Text.Text color={delayTime.color} className={styles.shift}>
          {delayTime.text}
        </Text.Text>
      ) : null}
    </Space>
  )
}
