import { UnknownTimeDurationUnitError } from 'Errors/UnknownTimeDurationUnitError'
import { TimeUnits } from 'types/unitedUiConfig'

/**
 * given a time duration with unit, for instance 20h, it will parse the value
 * and return its seconds equivalent
 */
export const timeDurationToNumericSeconds = (
  timeDuration: number,
  unit: TimeUnits = TimeUnits.secs,
): number => {
  switch (unit) {
    case TimeUnits.yrs:
      return timeDuration * 12 * 4 * 7 * 24 * 60 * 60

    case TimeUnits.mons:
      return timeDuration * 4 * 7 * 24 * 60 * 60

    case TimeUnits.wks:
      return timeDuration * 7 * 24 * 60 * 60

    case TimeUnits.days:
      return timeDuration * 24 * 60 * 60

    case TimeUnits.hrs:
      return timeDuration * 60 * 60

    case TimeUnits.mins:
      return timeDuration * 60

    case TimeUnits.secs:
      return timeDuration

    default:
      throw new UnknownTimeDurationUnitError({ unit: unit || '' })
  }
}
