import { ApiService } from 'services/authApi/getPermissions'
import { OrderFlagsApiResponse } from 'types/api/orderApi/order'

export const updateOrderFlags: ApiService<
  { entityId: string; orderId: string; isBillable: boolean },
  OrderFlagsApiResponse
> = (createClient, options) => {
  const { entityId, orderId, clientParams, isBillable } = options

  const uri = `OrdersAPI/v1/orders/${entityId}/${orderId}/flags`

  return createClient({
    endpointName: 'updateOrderFlags',
    expectedResponseStatusCode: 200,
    ...clientParams,
  }).post(uri, { billable: isBillable })
}
