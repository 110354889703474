import { WidgetCategory, WidgetName } from 'types'

export const widgetsInfoConfig: {
  [p in WidgetName]: {
    category: WidgetCategory
  }
} = {
  activity: {
    category: 'data_lookup',
  },

  // action widgets
  cancel_order: {
    category: 'action',
  },
  change_cooking_instructions: {
    category: 'action',
  },
  change_delivery_time: {
    category: 'action',
  },
  change_delivery_status: {
    category: 'action',
  },
  compensation: {
    category: 'action',
  },
  partial_refund: {
    category: 'action',
  },
  full_refund: {
    category: 'action',
  },
  new_comment: {
    category: 'comment',
  },
  modify_delivery_address: {
    category: 'action',
  },
  move_delivery_pending_to_queue: {
    category: 'action',
  },
  switch_payable: {
    category: 'action',
  },
  resolution_bar: {
    category: 'data_lookup',
  },
  rider_break: {
    category: 'action',
  },
  'dh.herocare.summary-panel-plugin': {
    category: 'data_lookup',
  },

  // search widgets
  search_order: {
    category: 'data_lookup',
  },
  search_vendor: {
    category: 'data_lookup',
  },
  search_voucher: {
    category: 'data_lookup',
  },
  last_orders: {
    category: 'data_lookup',
  },
  search_order_panel: {
    category: 'data_lookup',
  },
  search_vendor_panel: {
    category: 'data_lookup',
  },

  // customer widgets
  customer_attributes: {
    category: 'data_lookup',
  },
  customer_details: {
    category: 'data_lookup',
  },
  customer_vouchers: {
    category: 'data_lookup',
  },
  customer_wallet: {
    category: 'data_lookup',
  },

  // order widgets
  order_deliveries: {
    category: 'data_lookup',
  },
  order_deliveries_v2: {
    category: 'data_lookup',
  },
  order_items: {
    category: 'data_lookup',
  },
  order_invoice: {
    category: 'data_lookup',
  },
  order_items_v2: {
    category: 'data_lookup',
  },
  order_summary: {
    category: 'data_lookup',
  },
  order_updates: {
    category: 'data_lookup',
  },
  change_dropoff_pin: {
    category: 'action',
  },
  summary_panel: {
    category: 'data_lookup',
  },

  // rider widgets
  live_tracking: {
    category: 'data_lookup',
  },

  // vendor plugins
  vendor_summary: {
    category: 'data_lookup',
  },
  vendor_devices: {
    category: 'data_lookup',
  },
  vendor_addresses: {
    category: 'data_lookup',
  },
  'dh.herocare.vendor-availability-logs-plugin': {
    category: 'data_lookup',
  },
  vendor_contacts: {
    category: 'data_lookup',
  },
  vendor_invoice: {
    category: 'data_lookup',
  },
  'dh.herocare.vendor-contacts-plugin': {
    category: 'data_lookup',
  },
  'dh.herocare.vendor-device-plugin': {
    category: 'data_lookup',
  },
  'dh.herocare.vendor-payment-plugin': {
    category: 'data_lookup',
  },
  'dh.herocare.vendor-schedule-plugin': {
    category: 'data_lookup',
  },
  'dh.herocare.vendor-summary-plugin': {
    category: 'data_lookup',
  },
  'dh.herocare.vendor-update-availability-plugin': {
    category: 'action',
  },
  vendor_availability: {
    category: 'data_lookup',
  },
  vendor_payout: {
    category: 'data_lookup',
  },

  // rider plugins
  'dh.herocare.rider-events-plugin': {
    category: 'data_lookup',
  },
  'dh.herocare.add-rider-plugin': {
    category: 'action',
  },
  'dh.herocare.rider-summary-plugin': {
    category: 'data_lookup',
  },
  'dh.herocare.reassign-order-plugin': {
    category: 'action',
  },

  //
  'dh.herocare.update-order-payable': {
    category: 'action',
  },
  'dh.talabat.cancel-order': {
    category: 'action',
  },

  'dh.herocare.ticket-escalate-plugin': {
    category: 'ticket',
  },
  'dh.herocare.wrap-up-plugin': {
    category: 'ticket',
  },
  'dh.herocare.live-tracking-plugin': {
    category: 'data_lookup',
  },
  'dh.herocare.other-tickets-plugin': {
    category: 'ticket',
  },
  'dh.herocare.ticket-details-plugin': {
    category: 'ticket',
  },
  'dh.pandora.location-customer-address': {
    category: 'data_lookup',
  },
  'dh.pandago-c2c.recipient': {
    category: 'data_lookup',
  },
  'dh.pandora.subscription-plugin': {
    category: 'data_lookup',
  },
}
