import React from 'react'
import Icon, { MinusCircleFilled } from '@ant-design/icons'
import { createUseStyles } from 'react-jss'
import styles from './PanelHeader.style'
import { Col, Row, Typography } from 'antd'
import classNames from 'classnames'
import { TransformedCustomerWalletTransaction } from 'hooks/dataTransformers/useTransformCustomerWalletTransactions'

export interface PanelHeaderProps {
  transaction: TransformedCustomerWalletTransaction
}

const useStyles = createUseStyles(styles)

export const PanelHeader = ({ transaction }: PanelHeaderProps) => {
  const cx = useStyles()
  const canShowOrderId = transaction.platformReferenceId && transaction.title !== 'cashback'

  return (
    <div className={classNames(cx.flex, cx.flexAuto)}>
      {/* date and time section */}
      <div className={cx.minW80}>
        <Typography.Text className={cx.textSubtitle}>{transaction.transactionDate}</Typography.Text>
        <Typography.Text className={classNames(cx.block, cx.textLabel)}>
          {transaction.transactionTime}
        </Typography.Text>
      </div>

      {/* transaction type and label */}
      <div className={classNames(cx.ml34, cx.minW160)}>
        <div>
          <Icon component={transaction.icon} className={cx.icon} />
          <Typography.Text className={classNames(cx.ml6, cx.textSubtitle)}>
            {transaction.title}
          </Typography.Text>
        </div>

        {/* description */}
        <Typography.Text className={classNames(cx.textLabel)}>
          {transaction.description}
        </Typography.Text>
      </div>

      {/* transaction amount */}
      <div className={classNames(cx.mlAuto, cx.flexCol, cx.itemsEnd)}>
        <Typography.Text className={cx.textSubtitle}>
          <Typography.Text className={cx.textLabel}>
            {transaction.totalAmount.currency}{' '}
          </Typography.Text>
          {transaction.amountFormated}
        </Typography.Text>
        <Typography.Text className={cx.textLabel}>
          <Row gutter={5} align='middle' justify='end'>
            {canShowOrderId ? <Col>Order {transaction.platformReferenceId}</Col> : null}
            {transaction.vendor && canShowOrderId ? (
              <Col>
                <MinusCircleFilled style={{ display: 'block', fontSize: '4px' }} />
              </Col>
            ) : null}
            {transaction.vendor ? (
              <Col className={cx.textAlignRight}>{transaction.vendor}</Col>
            ) : null}
          </Row>
        </Typography.Text>
      </div>
    </div>
  )
}
