import React, { useState } from 'react'
import { NotificationFilled } from '@ant-design/icons'
import { useStyles } from './DeliveryInstruction.styles'
import truncate from 'lodash/truncate'
import { useTranslation } from 'hooks/useTranslation'

type Props = {
  instruction?: string
}

export const DeliveryInstruction = ({ instruction }: Props) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const [isShowMore, setIsShowMore] = useState(false)
  const MAX_LENGTH_TO_SHOW = 40
  const isTextLong = instruction?.length > MAX_LENGTH_TO_SHOW
  const truncatedText = truncate(instruction, { length: MAX_LENGTH_TO_SHOW })

  const toggleText = isShowMore
    ? t('widgets.order_deliveries.addresses.show_less')
    : t('widgets.order_deliveries.addresses.show_more')

  const handleToggle = (event) => {
    event.preventDefault()
    setIsShowMore((prev) => !prev)
  }

  if (!instruction) return null

  return (
    <div className={classes.container}>
      <div className={classes.icon}>
        <NotificationFilled />
      </div>
      {isTextLong ? (
        <div>
          {isShowMore ? instruction : truncatedText}{' '}
          <a onClick={handleToggle} href='/'>
            {toggleText}
          </a>
        </div>
      ) : (
        <div>{instruction}</div>
      )}
    </div>
  )
}
