import React from 'react'
import { Box, Button, Flex, Text } from 'shared'
import { useTranslation } from 'hooks/useTranslation'
import { CloseOutlined } from 'Icons'
import { red } from 'theme'

type WidgetActionFailProps = {
  heading: string
  subHeading: string
  onDone: () => void
}

export const WidgetActionFail: React.FC<WidgetActionFailProps> = ({
  heading,
  subHeading,
  onDone,
}) => {
  const { t } = useTranslation()
  return (
    <Flex flexDirection='column' rowGap='24px'>
      <Flex columnGap='24px'>
        <Flex>
          <Flex
            w='60px'
            h='60px'
            alignItems='center'
            justifyContent='center'
            bgColor={red.red2}
            borderRadius='50%'
          >
            <Box fontSize='24px' bgColor='transparent' color={red.red5} display='inline'>
              <CloseOutlined />
            </Box>
          </Flex>
        </Flex>
        <Flex flexDirection='column' rowGap='8px' pt='16px'>
          <Text.Primary fontSize='24px' fontWeight='500' lineHeight='32px'>
            {heading}
          </Text.Primary>
          <Text.Secondary fontSize='16px' lineHeight='24px'>
            {subHeading}
          </Text.Secondary>
          <Flex></Flex>
        </Flex>
      </Flex>
      <Flex justifyContent='flex-end'>
        <Button type='solid' onClick={onDone}>
          {t('Interface.Done')}
        </Button>
      </Flex>
    </Flex>
  )
}
