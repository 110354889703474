import { createUseStyles } from 'react-jss'

export const useFlexibleAmountStyles = createUseStyles({
  container: {
    marginTop: '50px',
  },
  numberInput: {
    width: '100%',
  },
  actionBtns: {
    display: 'flex',
    justifyContent: 'end',
  },
})
