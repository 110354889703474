import React from 'react'
import { Col, Row, Space, Tooltip } from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { Text } from 'shared/Text'
import { TimelineMeta } from '../types'

type Props = {
  status: {
    main: TimelineMeta
    secondary?: TimelineMeta[]
  }
}

export const TimelineText = ({ status }: Props) => {
  const fontWeight = status.main.fontWeight || '400'

  return (
    <Row style={{ display: 'flex', flexDirection: 'column' }}>
      <Col>
        <Space>
          <Text.Text fontSize={14} color={status.main.text} fontWeight={fontWeight}>
            {status.main.text}
          </Text.Text>
          {status.main.tooltip && (
            <Tooltip title={status.main.tooltip} placement='right'>
              <ExclamationCircleOutlined />
            </Tooltip>
          )}
        </Space>
      </Col>
      {status.secondary?.map((secondary) => (
        <Col key={secondary.text}>
          <Space>
            <Text.Text fontSize={12} color={secondary.color}>
              {secondary.text}
            </Text.Text>
            {secondary.tooltip && (
              <Tooltip title={secondary.tooltip} placement='right'>
                <ExclamationCircleOutlined />
              </Tooltip>
            )}
          </Space>
        </Col>
      ))}
    </Row>
  )
}
