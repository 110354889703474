import { Result } from 'antd'
import { useTranslation } from 'hooks/useTranslation'
import React from 'react'
import { Button } from 'shared/Button'

interface SuccessScreenProps {
  orderId: string
  title: string
  onDone?: () => void
}

export const SuccessScreen: React.FC<SuccessScreenProps> = ({ orderId, title, onDone }) => {
  const { t } = useTranslation()

  return (
    <Result
      status='success'
      title={title}
      subTitle={`${t('Widgets Common.Order Widget.Order ID')}: ${orderId}`}
      extra={[
        <Button key='done' type='solid' onClick={() => onDone?.()}>
          {t('Interface.Done')}
        </Button>,
      ]}
    />
  )
}
