export default {
  container: {
    backgroundColor: '#FAFAFA',
    '& .ant-table': {
      background: 'transparent !important',
    },

    '& .ant-table-cell': {
      border: '0px !important',
    },
  },

  header: {
    display: 'flex',
    boxShadow: 'inset 0px -1px 0px #F0F0F0',
    padding: '10px 14px 10px 16px',
    justifyContent: 'space-between',
  },

  flex: {
    display: 'flex',
  },

  itemsCenter: {
    alignItems: 'center',
  },

  ml8: {
    marginLeft: '8px',
  },

  textSubtitle: {
    color: 'rgba(0, 0, 0, 0.85)',
    fontSize: '14px',
    lineHeight: '22px',
  },

  textLabel: {
    color: 'rgba(0, 0, 0, 0.55)',
    fontSize: '12px',
    lineHeight: '20px',
  },
}
