export enum RiderStatuses {
  // value is what we are getting from api response
  delivered = 'DELIVERED',
  nearCustomer = 'NEAR_CUSTOMER',
  pickupTimeUpdated = 'PICKUP_TIME_UPDATED',
  leftPickup = 'LEFT_PICKUP',
  pickedUp = 'PICKED_UP',
  nearVendor = 'NEAR_VENDOR',
  accepted = 'ACCEPTED',
  undispatched = 'UNDISPATCHED',
  manualUndispatched = 'MANUAL_UNDISPATCHED',
  notified = 'NOTIFIED',
  rejected = 'REJECTED',
  canceled = 'CANCELED',
  unknown = 'UNKNOWN',
}
