export default {
  errorContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    width: '100%',
    marginTop: '25px',
  },
  icon: {
    width: '75px',
    height: '65px',
    marginBottom: '27px',
  },
  noOrderMessage: {
    fontSize: '18px',
    color: '#000000',
    marginBottom: '12px',
  },
  noOrderSubMessage: {
    fontSize: '14px',
    color: 'rgba(0, 0, 0, 0.65)',
  },
}
