/**
 * calculates compensation amount for refund to wallet / source
 * calculates voucher values, if voucher to be created in addition to wallet / source
 * */

function calculateRefundAmountForPspAndWallet(
  selectedItemsTotalAmount: number,
  compensation_percent: number,
  granularity_value: number,
  min_voucher_value: number,
) {
  const refundAmountToWalletOrPsp = selectedItemsTotalAmount
  let refundAmountWithVoucher = 0

  // calculate voucher value if compensation percent is greater than 0
  if (compensation_percent > 0) {
    refundAmountWithVoucher = (selectedItemsTotalAmount / 100) * compensation_percent

    if (refundAmountWithVoucher < granularity_value) {
      refundAmountWithVoucher = granularity_value
    } else if (granularity_value > 0) {
      refundAmountWithVoucher =
        granularity_value *
        (1 + Math.ceil((refundAmountWithVoucher - granularity_value) / granularity_value))
    }
  }

  // if voucher amount is smaller than minimum voucher value, set min voucher value as voucher amount
  if (refundAmountWithVoucher < min_voucher_value) {
    refundAmountWithVoucher = min_voucher_value
  }

  return {
    refundAmountToWalletOrPsp,
    refundAmountWithVoucher,
  }
}

export default calculateRefundAmountForPspAndWallet
