import { useCallback } from 'react'
import { OrderApiResponse } from '../../types/api/orderApi/order'
/**
 * expects Order API's response and its Customer obj separately
 * creates 2 arrays with appropriate key/value pairs to be used in Customer widget Summary Tab
 * */

import { unknownDataPoint } from '../../utils/dataTransformers/constants'
import { TransformedDataPoint } from 'types/dataTransformers/generic'
import { CustomerApiResponse } from 'types/api/orderApi/order'
import { getCustomerName } from 'utils/getCustomerName'
import { transformIntoTagStructure } from 'utils/order/getOrderEligibilities'
import { useTranslation } from 'hooks/useTranslation'
import { fieldsConfig, summaryWidgetFields } from 'entityConfig/dataPoints/customer'
import { useCanDisplayDataPoint } from 'hooks/useGetValidFeatures'
import { CustomerWidgetSummary } from 'contexts/entity/types'
import { useGetAddressDetailsToolTipContent } from 'hooks/useGetAddressDetailsToolTipContent'

type ParamType = {
  customer: CustomerApiResponse
  delivery: OrderApiResponse['delivery']
  config: CustomerWidgetSummary & { enableCustomerEligibilities: boolean }
}
type transformedDataType = TransformedDataPoint[] | null

const useTransformCustomerSummary = () => {
  const canDisplayDataPoint = useCanDisplayDataPoint()
  const { t } = useTranslation()
  const getAddressDetailsToolTipContent = useGetAddressDetailsToolTipContent()

  return useCallback(
    ({ customer, delivery, config }: ParamType) => {
      const eligibilities = transformIntoTagStructure(customer?.eligibilities || [])
      const addressToolTipContent = getAddressDetailsToolTipContent(delivery?.location)
      // check if customer profile exists
      const profile: any = customer?.profile ?? {}
      const id = customer?.customer_id ?? ''

      const showTooltip = canDisplayDataPoint(
        delivery?.location,
        config.full_address_tooltip || { displayRule: [], betaRequirement: [] },
      )

      const fields = {
        [summaryWidgetFields.customerName]: {
          label: t('Customer Widget.Tabs.Summary.Customer name'),
          value: getCustomerName(customer),
        },
        [summaryWidgetFields.phoneNumber]: {
          label: t('Customer Widget.Tabs.Summary.Phone number'),
          value: profile && profile.phone ? profile.phone : unknownDataPoint,
        },
        [summaryWidgetFields.customerId]: {
          label: t('Customer Widget.Tabs.Summary.Customer ID'),
          value: id ? id : unknownDataPoint,
        },
        [summaryWidgetFields.emailId]: {
          label: t('Customer Widget.Tabs.Summary.Email ID'),
          value: profile && profile.email ? profile.email : unknownDataPoint,
        },
        [summaryWidgetFields.address]: {
          label: t('Customer Widget.Tabs.Summary.Address'),
          value: delivery?.location.address_text || unknownDataPoint,
          tooltip: addressToolTipContent,
          showTooltip,
        },
      }

      if (config.enableCustomerEligibilities) {
        fields[summaryWidgetFields.customerTags] = {
          label: t('Customer Widget.Tabs.Summary.Customer tags'),
          value: eligibilities,
        }
      }

      const transformedItems: transformedDataType = fieldsConfig
        .map((key: summaryWidgetFields) => {
          return { key, ...fields[key] }
        })
        .filter((item) => item.value)

      // return columns' data
      return transformedItems
    },
    [canDisplayDataPoint, getAddressDetailsToolTipContent, t],
  )
}

export default useTransformCustomerSummary
