import React, { useContext, useState } from 'react'
import { useTranslation } from 'hooks/useTranslation'
import { createUseStyles } from 'react-jss'
import { Button, Image } from 'antd'
import { ShoppingOutlined } from '@ant-design/icons'

import { SessionContext } from 'contexts/session/SessionContext'

import styles from './ProofOfDelivery.styles'
import { useApiService } from 'hooks/useApiService'
import { getProofOfDelivery } from 'services/fulfillmentApi/getProofOfDelivery'

const useStyles = createUseStyles(styles)

interface ProofOfDeliveryProps {
  /**
   * this is to offset the padding in the button so that when it's consumed as
   * a List.Item.Meta, the title is aligned with the description.
   */
  paddingOffset?: boolean
}

const ProofOfDelivery = ({ paddingOffset }: ProofOfDeliveryProps) => {
  const [imageUrl, setImageUrl] = useState('')
  const [isPreviewVisible, setPreviewVisible] = useState(false)

  const { t } = useTranslation()
  const classes = useStyles({ paddingOffset: paddingOffset || false })

  const {
    sessionState: { orderId, globalEntityId },
  } = useContext(SessionContext)

  useApiService({
    service: getProofOfDelivery,
    params: {
      entityId: globalEntityId,
      orderId,
    },
    deps: [],
    shouldLoad: Boolean(globalEntityId && orderId),

    onSuccess: (res) => {
      setImageUrl(res.data.image_url || '')
    },
  })

  if (imageUrl) {
    return (
      <div>
        <Image
          src={imageUrl}
          width={0}
          preview={{
            visible: isPreviewVisible,
            onVisibleChange: (visible, prevVisible) => setPreviewVisible(visible),
          }}
          onError={() => setImageUrl('')}
        />
        <Button
          type='link'
          data-testid='proof-of-delivery-btn'
          className={classes.buttonContainer}
          onClick={() => setPreviewVisible(true)}
        >
          <div className={classes.buttonContentContainer}>
            <ShoppingOutlined className={classes.icon} />
            {t('Order Widget.Tabs.Summary.Proof of delivery')}
          </div>
        </Button>
      </div>
    )
  } else {
    return (
      <Button
        type='link'
        disabled
        data-testid='proof-of-delivery-btn'
        className={classes.buttonContainer}
      >
        <div className={classes.buttonContentContainer}>
          <ShoppingOutlined className={classes.icon} />
          {t('Order Widget.Tabs.Summary.Proof of delivery')}
        </div>
      </Button>
    )
  }
}

export default ProofOfDelivery
