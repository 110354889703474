import React from 'react'
import { createCustomSvgIcon } from 'factory/createCustomSvgIcon'

export const ArrowUpIcon = createCustomSvgIcon({
  viewBoxWidth: 10,
  viewBoxHeight: 10,
  content: (
    <>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M5 5.641L2.41451 3.05551C2.02399 2.66499 1.46997 2.58584 1.17707 2.87873C0.88418 3.17163 0.963325 3.72565 1.35385 4.11617L4.18228 6.9446C4.42557 7.18789 4.73231 7.31033 4.99985 7.29726C5.26744 7.31043 5.57432 7.18799 5.8177 6.94461L8.64613 4.11618C9.03665 3.72566 9.1158 3.17164 8.82291 2.87875C8.53001 2.58586 7.97599 2.665 7.58547 3.05552L5 5.641Z'
        fill='currentColor'
        fillOpacity='0.55'
      />
    </>
  ),
})
