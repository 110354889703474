import { availableRefundMethods } from 'entityConfig/allowedConfigValues'
import { ItemOption, ItemsItem } from 'types/api/orderApi/order'

export interface ItemOptionStructured extends Omit<ItemOption, 'options'> {
  missing_quantity?: number
  max_missing_quantity?: number

  selected?: boolean
  missing_price?: number
  options?: ItemOptionStructured[]
}

export interface ItemStructured extends Omit<ItemsItem, 'options'> {
  type:
    | 'bundle'
    | 'item'
    | 'bundle_item'
    | 'bundle_topping'
    | 'item_topping'
    | 'bundle_item_topping'

  parent_type: 'bundle' | 'item' | 'bundle_item'

  max_missing_quantity?: number
  missing_quantity?: number

  selected?: boolean
  parent_item_selected: boolean
  parent_item_partially_selected: boolean

  options?: ItemStructured[]

  partially_selected?: boolean
  activate_partial_input?: boolean
  partial_description?: string

  /**
   * total quantity is the unit quantity multiplied by parent total quantity
   */
  total_quantity?: number
}

export interface SingleItemStructured {
  immutable_unit_price?: number
  immutable_quantity?: number

  unit_price?: number

  price?: number

  /**
   * this is the quanity that was ordered
   */
  quantity?: number

  /**
   * this is the quantity that is missing
   */
  missing_quantity?: number

  display_name?: string
  id?: string
  line_item_id?: string
  name?: string
  product_parent?: any
  toppings?: SingleOptionStructured[]
  options?: SingleOptionStructured[]
  selected?: boolean
  partially_selected?: boolean
  has_toppings?: boolean

  partial_description?: string
  partial_input_active?: boolean
}

export interface SingleOptionStructured {
  immutable_unit_price?: number
  immutable_quantity?: number

  parentItemQuantity?: number

  price?: number
  unit_price?: number

  /**
   * this is the quantity of ordered topping per item
   */
  quantity_per_item?: number

  /**
   * this is the total ordered topping for all item
   */
  quantity_for_all_item?: number

  /**
   *
   */
  missing_quantity_per_item?: number

  /**
   * this is the total topping that are missing for all item
   */
  missing_quantity_for_all_item?: number

  /**
   * the is the maximum number of missing total topping for all item
   */
  max_missing_quantity_for_all_item?: number

  name?: string
  type?: string
  id?: string
  selected?: boolean

  parentItemId?: string

  quantity?: number
}

export interface PartialRefundPostBody {
  order_id: string
  contact_id: string
  compensation_purpose?: string
  refund_purpose: string
  refund_items: string[]
  included_compensated_value: number
  voucher: PartialRefundPostBodyVoucher
  contact_reason?: string
  removed_items_detailed?: PostItemRemovalBodySingleItem[]
  with_item_removal?: boolean
  removed_items?: PostItemRemovalBodySingleItem[]
}

interface PartialRefundPostBodyVoucher {
  constraints: Array<PartialRefundPostBodyConstrain>
  channel: string
  value: number
  currency: string
  type: string
  customerId: string
  globalEntityId: string
  description: string
  purpose: string
}

export interface PartialRefundPostBodyConstrain {
  type: string
  value: string | number | Array<string | number | { id: number; name: string | number }>
}

export type AvailableRefundMethod = {
  active: boolean
  method: availableRefundMethods
  translationKey: string
  useTicketAsFallback?: boolean
  refundable_payment_types: string[]
  betaRequirement?: string[]
  issues_blacklist?: string[]
  disable_for_gift_cards?: boolean
}
// DEPRECATED: Please use AvailableRefundMethod instead
export type AvailablePartialRefundMethod = AvailableRefundMethod

export interface PostItemRemovalBody {
  items: PostItemRemovalBodySingleItem[]
  itemsWithToppings: PostItemRemovalBodySingleItem[]
  contact_reason: string
}

export interface PartialItemIssue {
  description: string
  quantity: number
  value: number
}

export interface PostItemRemovalBodySingleItem {
  id: string
  line_item_id?: string
  parent_product_id?: string
  quantity: number
  unit_price: number
  name?: string
  display_name?: string
  partial_item_issue?: PartialItemIssue
  toppings: PostItemRemovalBodySingleTopping[]
  bundle_items?: PostItemRemovalBodySingleItem[]
}

interface PostItemRemovalBodySingleTopping {
  id?: string
  name?: string
  quantity?: number
  unit_price?: number
}

export enum EffectiveRefundTarget {
  Source = 'SOURCE',
  Wallet = 'WALLET',
  Voucher = 'VOUCHER',
  NotDefinedYet = 'NOT_DEFINED_YET',
  Mixed = 'MIXED',
}

export enum RefundTarget {
  Source = 'SOURCE',
  Wallet = 'WALLET',
  Ticket = 'TICKET',
  Auto = 'AUTO',
}

export interface RefundBreakdown {
  method: 'SOURCE' | 'WALLET' | 'VOUCHER' | 'TICKET' | 'GIFT_CARD'

  /**
   * ExternalReferenceID is the reference id for current refund breakdown item.
   * For Voucher it is Voucher Code.
   * For Wallet/Source refund it is payment identifier
   */
  externalReferenceID?: string

  externalReferenceType?: 'PAYMENTS' | 'VOUCHER'

  amount: {
    amount: number
    currency: string
  }

  status?: 'COMPLETED' | 'IN_PROGRESS'
}
