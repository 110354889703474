/**
 * HistoryOfEvents Widget
 * renders tabs of order, customer and Back Office with relevant histories
 * */

// libs
import React, { useState, useEffect, useContext } from 'react'
// contexts and types
import { EntityContext } from 'contexts/entity/EntityContext'
import { RiderServiceContext } from 'contexts/riderService/RiderServiceContext'
import { SectionFilter } from 'types/riderService/historyOfEvents'
// hooks
import { useTranslation } from 'hooks/useTranslation'
// utils
// styles
import { createUseStyles } from 'react-jss'
import styles from './HistoryOfEvents.styles'
import { Button, Typography, Menu } from 'antd'
import { DownOutlined, UpOutlined, PlusOutlined, MinusOutlined } from '@ant-design/icons'
// components
import MiniErrorView from 'components/MiniErrorView'
import OrderHistory from './OrderHistory'
import CustomerHistory from './CustomerHistory'
import RiderHistory from './RiderHistory'
import BackOffice from './BackOffice'
// assets
import orderErrorIcon from 'assets/error/orderError.png'
import { useGetValidFeatures } from 'hooks/useGetValidFeatures'
import { useCaptureUserAction } from 'hooks/events/useCaptureUserAction'
import { InlineDropdown } from 'shared/InlineDropdown'

const useStyles = createUseStyles(styles)

const HistoryOfEvents: React.FC = () => {
  const classes = useStyles()
  const { Text } = Typography

  // pull language content
  const { t } = useTranslation()

  const captureUserAction = useCaptureUserAction()

  // pull entity config from context
  const {
    entityState: {
      entityConfig: {
        fixed_panel_config: {
          widgets_configs: {
            history_of_events: { tab_order },
          },
        },
      },
    },
  } = useContext(EntityContext)

  const getValidFeatures = useGetValidFeatures()

  // pull rider context
  const {
    riderServiceState: { orderError, rider },
  } = useContext(RiderServiceContext)

  // set interval to periodically call getOrderComments and getCustomerComments
  const [intervalDuration] = useState(60000)
  const [intervalState, setIntervalState] = useState(0)
  useEffect(() => {
    // set interval
    const interval = setInterval(() => {
      setIntervalState(Math.floor(Math.random() * Math.floor(intervalDuration)))
    }, intervalDuration)

    // clean interval upon dismounting
    return () => clearInterval(interval)
  }, [intervalState, intervalDuration])

  const [historyOfEventsTabOrder, setHistoryOfEventsTabOrder] = useState<string[]>()
  useEffect(() => {
    if (tab_order) {
      const tabs = getValidFeatures(tab_order)
      setHistoryOfEventsTabOrder(tabs)

      if (orderError && rider) {
        setSelectedFilter(SectionFilter.rider)
      }
    }
  }, [tab_order, orderError, rider, getValidFeatures])

  // toggles main dropdown activity
  const [isMainDropdownActive, setIsMainDropdownActive] = useState(false)
  const handleMainDropdownButtonClick = (e: any) => {
    e.preventDefault()
    setIsMainDropdownActive(!isMainDropdownActive)
  }

  // sets main filter upon selection
  const [selectedFilter, setSelectedFilter] = useState<string>(SectionFilter.order)
  const handleNewFilterSelection = (filter: string) => {
    switch (filter) {
      case SectionFilter.order:
        captureUserAction('HistoryOfEventsTabOrderClicked')
        break
      case SectionFilter.customer:
        captureUserAction('HistoryOfEventsTabCustomerClicked')
        break
      case SectionFilter.rider:
        captureUserAction('HistoryOfEventsTabRiderClicked')
        break
      case SectionFilter.back_office:
        captureUserAction('HistoryOfEventsTabBackOfficeClicked')
        break
    }

    setSelectedFilter(filter)
  }

  // toggles optional filter selection
  const [isOptionalFilters, setIsOptionalFilters] = useState(false)
  const handleOptionalFiltersClick = () => {
    if (isOptionalFilters) {
      switch (selectedFilter) {
        case SectionFilter.order:
          captureUserAction('HistoryOfEventsOrderCollapseFilterClicked')
          break
        case SectionFilter.customer:
          captureUserAction('HistoryOfEventsCustomerCollapseFilterClicked')
          break
        case SectionFilter.rider:
          captureUserAction('HistoryOfEventsRiderCollapseFilterClicked')
          break
        case SectionFilter.back_office:
          captureUserAction('HistoryOfEventsBackOfficeCollapseFilterClicked')
          break
      }
    } else {
      switch (selectedFilter) {
        case SectionFilter.order:
          captureUserAction('HistoryOfEventsOrderExpandFilterClicked')
          break
        case SectionFilter.customer:
          captureUserAction('HistoryOfEventsCustomerExpandFilterClicked')
          break
        case SectionFilter.rider:
          captureUserAction('HistoryOfEventsRiderExpandFilterClicked')
          break
        case SectionFilter.back_office:
          captureUserAction('HistoryOfEventsBackOfficeExpandFilterClicked')
          break
      }
    }

    setIsOptionalFilters(!isOptionalFilters)
  }

  // handles clear button behaviour
  const [isAnOptionalFilterSelected, setIsAnOptionalFilterSelected] = useState(false)
  const handleOptionalFilterClear = () => {
    switch (selectedFilter) {
      case SectionFilter.order:
        captureUserAction('HistoryOfEventsOrderClearFilterClicked')
        break
      case SectionFilter.customer:
        captureUserAction('HistoryOfEventsCustomeClearFilterClicked')
        break
      case SectionFilter.rider:
        captureUserAction('HistoryOfEventsRiderClearFilterClicked')
        break
    }

    setIsAnOptionalFilterSelected(false)
  }

  return (
    <div>
      {orderError && !rider ? (
        <MiniErrorView icon={orderErrorIcon} errorMessage={t('Messages.No Order Found')} />
      ) : (
        <React.Fragment>
          <Text className={classes.sectionTitle}>
            {t('History of Events Widget.History of Events')}
          </Text>
          <div className={classes.filtersContainer}>
            {/* main filter dropdown */}
            {historyOfEventsTabOrder ? (
              <InlineDropdown
                arrow={true}
                overlay={
                  <Menu>
                    {historyOfEventsTabOrder.map((tab: string, idx: number) => (
                      <Menu.Item
                        key={idx}
                        onClick={() => handleNewFilterSelection(SectionFilter[tab])}
                      >
                        {SectionFilter[tab]}
                      </Menu.Item>
                    ))}
                  </Menu>
                }
                trigger={['click']}
              >
                <Button
                  type='default'
                  size='small'
                  onClick={(e) => handleMainDropdownButtonClick(e)}
                  className={classes.dropdownButton}
                >
                  {selectedFilter}

                  {isMainDropdownActive ? (
                    <UpOutlined className={classes.dropdownIcon} />
                  ) : (
                    <DownOutlined className={classes.dropdownIcon} />
                  )}
                </Button>
              </InlineDropdown>
            ) : null}

            {selectedFilter !== SectionFilter.back_office && (
              <div className={classes.optionalFiltersContainer}>
                <Text className={classes.filtersText}>{t('History of Events Widget.Filters')}</Text>

                {isAnOptionalFilterSelected ? (
                  <Button
                    className={classes.clearButton}
                    type='link'
                    onClick={handleOptionalFilterClear}
                  >
                    {t('History of Events Widget.Clear')}
                  </Button>
                ) : null}

                <Button type='text' onClick={handleOptionalFiltersClick}>
                  {isOptionalFilters ? <MinusOutlined /> : <PlusOutlined />}
                </Button>
              </div>
            )}
          </div>
          {/* return relevant history */}
          {selectedFilter === SectionFilter.order ? (
            <OrderHistory
              isOptionalFilters={isOptionalFilters}
              isAnOptionalFilterSelected={isAnOptionalFilterSelected}
              setIsAnOptionalFilterSelected={setIsAnOptionalFilterSelected}
              intervalState={intervalState}
            />
          ) : selectedFilter === SectionFilter.customer ? (
            <CustomerHistory
              isOptionalFilters={isOptionalFilters}
              isAnOptionalFilterSelected={isAnOptionalFilterSelected}
              setIsAnOptionalFilterSelected={setIsAnOptionalFilterSelected}
              intervalState={intervalState}
            />
          ) : selectedFilter === SectionFilter.rider ? (
            <RiderHistory
              isOptionalFilters={isOptionalFilters}
              isAnOptionalFilterSelected={isAnOptionalFilterSelected}
              setIsAnOptionalFilterSelected={setIsAnOptionalFilterSelected}
              intervalState={intervalState}
            />
          ) : selectedFilter === SectionFilter.back_office ? (
            <BackOffice />
          ) : null}
        </React.Fragment>
      )}
    </div>
  )
}

export default HistoryOfEvents
