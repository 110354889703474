import { ItemStructured } from 'types/actions/partialRefund'

interface Opts {
  /**
   * if true, then a partially selected item's total price will be 33% of the calculated price
   */
  partialItemRefundPercentage: number
  considerPartialSelection?: boolean
}

/**
 * * calculates an order item total price.
 * order_item_total_price = order_item_price + price_of_order_item_options
 */
export const calculateOrderItemTotalPrice = (item: ItemStructured) => {
  const unit_price = item.unit_price || 0
  const quantity = item.quantity || 1

  const basePrice = unit_price * quantity

  const options = item.options || []

  return (
    basePrice +
    quantity *
      options.reduce((sum, item) => {
        return sum + calculateOrderItemTotalPrice(item)
      }, 0)
  )
}

/**
 * calculates a missing order item total price.
 * missing_order_item_total_price = missing_order_item_price + price_of_missing_order_item_options
 */
export const calculateMissingOrderItemTotalPrice = (item: ItemStructured, opts: Opts) => {
  let totalPrice = item.unit_price * item.missing_quantity
  const partialItemRefundPercentage = opts.partialItemRefundPercentage

  totalPrice =
    totalPrice +
    item.options.reduce((sum, item) => sum + calculateMissingOrderItemTotalPrice(item, opts), 0)

  // the refund amount for a partially selected item is {partialItemRefundPercentage} of the item total price
  if (item.partially_selected && opts?.considerPartialSelection) {
    totalPrice = totalPrice * (partialItemRefundPercentage / 100)
  }

  return totalPrice
}
