/**
 * titles row holder for payment table
 * */

// libs
import React from 'react'
// hooks
import { useTranslation } from 'hooks/useTranslation'
// styles
import { createUseStyles } from 'react-jss'
import styles from './Titles.styles'
import { Typography } from 'antd'

const useStyles = createUseStyles(styles)

const Titles = () => {
  const classes = useStyles()
  const { Text } = Typography

  // pull translations
  const { t } = useTranslation()

  return (
    <div className={classes.panelTitlesContainer}>
      <Text strong className={classes.paneTitleText}>
        {t('Order Widget.Tabs.Payment.Date')}
      </Text>
      <Text strong className={classes.paneTitleText}>
        {t('Order Widget.Tabs.Payment.Status')}
      </Text>
      <Text strong className={classes.paneTitleText}>
        {t('Order Widget.Tabs.Payment.Amount')}
      </Text>
    </div>
  )
}

export default Titles
