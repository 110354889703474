import React from 'react'
import { Row, Col, Empty } from 'antd'
import useSummaryPanelMessageStyles from './SummaryPanelMessage.styles'

type Props = {
  message?: string
}

const SummaryPanelMessage = ({ message }: Props) => {
  const classes = useSummaryPanelMessageStyles()

  return (
    <Row justify='center'>
      <Col>
        <Empty
          description={message}
          className={classes.emptyStyles}
          image={Empty.PRESENTED_IMAGE_SIMPLE}
        />
      </Col>
    </Row>
  )
}

export default SummaryPanelMessage
