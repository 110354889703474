/**
 * Partial Refund Modal, rendered upon click in actions
 * renders a modal with 3 steps for issue type selection, reporting and refunding
 * */

// libs
import React, {
  useCallback,
  useContext,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react'
import { datadogRum } from '@datadog/browser-rum'
import flatten from 'lodash/flatten'

// contexts and types
import { DataContext } from 'contexts/data/DataContext'
import { DataAction } from 'contexts/data/types'
import { SessionContext } from 'contexts/session/SessionContext'
import { PartialRefund, RefundCommon, RefundIssueType } from 'contexts/entity/types'
import {
  AvailableRefundMethod,
  ItemStructured,
  PostItemRemovalBody,
  PostItemRemovalBodySingleItem,
  RefundBreakdown,
  RefundTarget,
} from 'types/actions/partialRefund'
// hooks
import { useTranslation } from 'hooks/useTranslation'
// utils
import { createCompensationStartAndEndDates } from 'utils/createCompensationStartAndEndDates'
import fixNumber from 'utils/fixNumber'
import { useCaptureUserAction } from 'hooks/events/useCaptureUserAction'
// styles
import { createUseStyles } from 'react-jss'
import styles from './PartialRefundModalView.styles'
import { Steps } from 'antd'
// components
import Issue from '../../RefundCommon/Issue/Issue'
import Remove from './Remove/Remove'
import Refund from './Refund/Refund'
import InfoAlertView from 'components/InfoAlertView'
import { useGetCustomerCompensationsAndRefunds } from 'hooks/apiHooks/useGetCustomerCompensationsAndRefunds'
import { availableRefundMethods } from 'entityConfig/allowedConfigValues'
import calculateRefundAmountForPspAndWallet from 'utils/calculateRefundAmountForPspAndWallet'
import { useClearCommentsFromDataContext } from 'hooks/useClearCommentsFromDataContext'
import { createVoucher, VoucherPurpose } from 'services/VoucherApi/createVoucher'
import { useApiClientCreator } from 'contexts/apiClientCreator/ApiClientCreatorContext'
import { reportMissingItems } from 'services/ordersApi/reportMissingItems'
import { removeOrderItems } from 'services/ordersApi/removeOrderItems'
import { getOrderPurchaseDetails } from 'services/paymentApi/getOrderPurchaseDetails'
import { calculateRefundAmountForVoucherOrAuto } from 'utils/calculateRefundAmountForVoucherOrAuto'
import { createRefund } from 'services/paymentApi/createRefund'
import { useIsDataPointValid } from 'hooks/useGetValidFeatures'
import { WidgetErrorHandler } from 'components/WidgetErrorHandler/widgetErrorHandler'
import { useForceUpdate } from 'contexts/useForceUpdate'
import { ApiResult, apiResultReporter, Result } from './Result/Result'
import { useApiService } from 'hooks/useApiService'
import {
  GetProposedCompensationApiResponse,
  getProposedCompensationValue,
} from 'services/VoucherApi/getProposedCompensationValue'
import { useCopyVoucher } from 'hooks/useCopyVoucher'
import { ORDER_ID_PLACEHOLDER } from 'constants/translation'
import { calculateOrderTotalPrice } from 'utils/order/orderPrice'
import {
  getFromStructuredItems,
  structureOrderItem,
  itemHasSelectedOrPartiallySelectedItems,
} from 'utils/orderItems/structureOrderItem'
import { calculateSelectedMissingItemsTotalPrice } from 'utils/orderItems/missingItemPricings'

import { EMPTY_CATCH_CALLBACK } from 'constants/constants'
import { useSendEventToEvts } from 'hooks/events/useSendEventToEvts'
import { calculateMissingOrderItemTotalPrice } from 'utils/orderItems/orderItemPricings'
import { handleOrderPurchaseError, handleOrderPurchaseResponse } from 'utils/handleOrderPurchaseApi'
import isCOD from 'utils/order/isCOD'
import { createPluggableWidget } from 'factory/createPluggableWidget'
import { FlexibleAmount } from './FlexibleAmount'

const useStyles = createUseStyles(styles)

export const PartialRefundView = createPluggableWidget<{
  utc_zone: string
  useCcrWhitelistForPartialRefundIssues: boolean
  refund_common: RefundCommon
  partial_refund: PartialRefund
}>(
  ({ onQuit, config, sdk, ccrCode }, ref) => {
    const classes = useStyles()
    const { Step } = Steps

    // pull translations
    const { t } = useTranslation()
    const captureUserAction = useCaptureUserAction()

    const { createClient } = useApiClientCreator()

    const apiResultsRef = useRef<{ results: ApiResult[]; refundBreakdown: RefundBreakdown[] }>({
      results: [],
      refundBreakdown: [],
    })

    const forceUpdate = useForceUpdate()

    const [isLoading, setIsLoading] = useState(false)
    const [createdRefundStatus, setCreatedRefundStatus] = useState(0)
    const sendEventToEvts = useSendEventToEvts()
    // pull session context
    const {
      sessionState: { orderId, caseId, globalEntityId, userId, platform },
    } = useContext(SessionContext)

    const isDataPointValid = useIsDataPointValid()

    // pull partial refund configs
    const {
      useCcrWhitelistForPartialRefundIssues,
      utc_zone,

      partial_refund: {
        allowFlexibleAmountRefund,
        partialItemRefundPercentage,
        defaultCompensationIssue,
        isMissingItemReportAllowed,
        refundAmountPrecision,
        available_partial_refund_methods,
        availableIssueTypes,
        alwaysUpdateBackend,
        enableItemRemoval,
        excludedVendors,
        excludedCCRs,
        missingItemReportCRRWhitelist,
        voucherCompensationPercent,
        voucherGranularity,
        voucherMinCompensationAmount,

        pspAndWalletCompensationPercent,
        pspAndWalletGranularity,
        pspAndWalletMinCompensationAmount,

        autoCompensationPercent,
        autoGranularity,
        minAutoValue,
      },
      refund_common,
    } = config

    // pull data state and dispatch
    const {
      dataDispatch,
      dataState: {
        order,
        customer,
        vendor,
        currency,
        customerRefunds,
        customerCompensations,
        customerExploitStatus,
      },
    } = useContext(DataContext)
    const {
      SET_COMPENSATED_VOUCHER,
      SET_REFUND_TO_WALLET_OR_PSP_DETAILS,
      SET_AUTO_MISSING_ITEM_COMMENT,
      SET_AUTO_PARTIAL_REFUND_COMMENT,
      SET_FULLY_REFUNDED_VOUCHER,
    } = DataAction

    // set payment method
    const customerPaymentMethod = order?.customer?.payment?.payment_method ?? ''

    // --- memoized values ---

    // calculate total order value
    const totalOrderValue = useMemo(() => {
      return calculateOrderTotalPrice(order?.order?.items || [])
    }, [order])

    // entity config is checked to determine which API calls to be made, if any at all
    const [doItemRemoval, setDoItemRemoval] = useState(false) // should items be removed
    const [doMissingItemReport, setDoMissingItemReport] = useState(false) // should items be reported

    const [totalRemovalAmount, setTotalRemovalAmount] = useState(0.0)
    const [compensationAmount, setCompensationAmount] = useState(0.0)
    const [totalRefundAmount, setTotalRefundAmount] = useState(0.0)

    const [proposedCompensation, setProposedCompensation] =
      useState<GetProposedCompensationApiResponse>(null)

    // state
    const [currentStepIndex, _setCurrentStepIndex] = useState(0)
    const [selectedIssueType, setSelectedIssueType] = useState<RefundIssueType>(null)
    const [selectedRefundMethod, _setSelectedRefundMethod] = useState<AvailableRefundMethod>(null)
    // Additional notes for partial refund
    const [additionalNotes, setAdditionalNotes] = useState('')

    const [voucherMinOrderValue, setVoucherMinOrderValue] = useState(1.0)
    const [voucherMaxOrderValue, setVoucherMaxOrderValue] = useState(0)
    const [refundMethodValidationError, setRefundMethodValidationError] = useState('')

    // structured items;
    const [structuredOrderItems, _setStructuredOrderItems] = useState<ItemStructured[]>([])

    const [selectedBundles, setSelectedBundles] = useState<ItemStructured[]>([])
    const [selectedItems, setSelectedItems] = useState<ItemStructured[]>([])
    const [partiallySelectedItems, setPartiallySelectedItems] = useState<ItemStructured[]>([])
    const [selectedToppings, setSelectedToppings] = useState<ItemStructured[]>([])
    const [createdVoucher, setCreatedVoucher] = useState<any>(null)
    const [createdRefundToWalletOrPsp, setCreatedRefundToWalletOrPsp] = useState(false)

    // check if flexible amount can be used
    const useFlexibleAmountRefund = useMemo(() => {
      const isAllowedForUser = isDataPointValid(allowFlexibleAmountRefund?.betaRequirement ?? [])
      return selectedIssueType?.use_flexible_amount && isAllowedForUser
    }, [
      allowFlexibleAmountRefund?.betaRequirement,
      selectedIssueType?.use_flexible_amount,
      isDataPointValid,
    ])

    // initialize available refund methods
    const refundMethods = useMemo(() => {
      const customerPaymentMethod = customer?.payment?.payment_method
      const hasGiftCardPayment = customer?.payment?.giftcard_amount > 0

      return available_partial_refund_methods.filter((method) => {
        if (!method.active) {
          return false
        }

        if (
          method.issues_blacklist?.length &&
          method.issues_blacklist.includes(selectedIssueType?.issue)
        ) {
          return false
        }

        if (hasGiftCardPayment && method.disable_for_gift_cards) {
          return false
        }

        const isAllowedPaymentType = method.refundable_payment_types?.length
          ? method.refundable_payment_types.includes(customerPaymentMethod)
          : true

        if (!method?.betaRequirement?.length) {
          return isAllowedPaymentType
        }

        return isDataPointValid(method.betaRequirement) && isAllowedPaymentType
      })
    }, [customer, available_partial_refund_methods, isDataPointValid, selectedIssueType?.issue])

    // calculate voucher details
    const { voucherBeginDate, voucherBodyDescription, voucherEndDate, voucherPaymentTypes } =
      useMemo(() => {
        const result = {
          voucherBeginDate: '',
          voucherEndDate: '',
          voucherBodyDescription: '',
          voucherPaymentTypes: t('Customer Widget.Tabs.Vouchers.Voucher Details.All Payment Types'),
        }

        if (refund_common?.description_prefix_partial) {
          const { voucherValidity, description_prefix_partial } = refund_common
          const { nowText, laterText } = createCompensationStartAndEndDates(
            voucherValidity.value,
            voucherValidity.unit,
          )
          result.voucherBeginDate = nowText
          result.voucherEndDate = laterText
          result.voucherBodyDescription = description_prefix_partial
        }

        if (refund_common?.voucher_payment_types?.length > 0) {
          result.voucherPaymentTypes = refund_common.voucher_payment_types
            .map((current) => current.display_name)
            .join(', ')
        }

        if (selectedIssueType?.use_auto_comp_proposed_validity_dates && proposedCompensation) {
          result.voucherEndDate = proposedCompensation.expired_at
        }

        return result
      }, [refund_common, t, selectedIssueType, proposedCompensation])

    const selectedCountsAndValues = useMemo(() => {
      const countSelectedMissingItems = (items: ItemStructured[]) => {
        return items.reduce((sum, item) => sum + item.missing_quantity, 0)
      }

      const getSelectedMissingItemsValues = (
        items: ItemStructured[],
        opts?: Parameters<typeof calculateMissingOrderItemTotalPrice>[1],
      ) => {
        return items.reduce((sum, item) => sum + calculateMissingOrderItemTotalPrice(item, opts), 0)
      }

      return {
        itemsCount: countSelectedMissingItems(selectedItems),
        partialItemsCount: countSelectedMissingItems(partiallySelectedItems),
        bundlesCount: countSelectedMissingItems(selectedBundles),
        toppingsCount: countSelectedMissingItems(selectedToppings),

        itemsValue: getSelectedMissingItemsValues(selectedItems, { partialItemRefundPercentage }),
        partialItemsValue: getSelectedMissingItemsValues(partiallySelectedItems, {
          considerPartialSelection: true,
          partialItemRefundPercentage,
        }),
        bundlesValue: getSelectedMissingItemsValues(selectedBundles, {
          partialItemRefundPercentage,
        }),
        toppingsValue: getSelectedMissingItemsValues(selectedToppings, {
          partialItemRefundPercentage,
        }),
      }
    }, [
      selectedBundles,
      selectedItems,
      selectedToppings,
      partiallySelectedItems,
      partialItemRefundPercentage,
    ])

    /**
     * order items selection proxy. sets selected items (non partially), partially selected items
     * and selected toppings
     *
     * at the same, enable or disable item removal based on if
     * (partial items where selected, or bundle items where selected, or bundle toppings where selected or bundle item toppings where selected)
     * @param items
     */
    const setStructuredOrderItems = useCallback(
      (items: ItemStructured[]) => {
        _setStructuredOrderItems(items)

        // set selected bundle
        const selectedBundles = getFromStructuredItems(
          items,
          (item) => item.selected && item.type === 'bundle',
        )
        setSelectedBundles(selectedBundles)

        // set selected items
        const selectedItems = getFromStructuredItems(
          items,
          (item) => item.selected && ['item', 'bundle_item'].includes(item.type),
        )
        setSelectedItems(selectedItems)

        // set partially selected items
        const partiallySelectedItems = getFromStructuredItems(
          items,
          (item) => item.partially_selected && ['item', 'bundle_item'].includes(item.type),
        )
        setPartiallySelectedItems(partiallySelectedItems)

        // set selected toppings
        const selectedToppings = getFromStructuredItems(
          items,
          (item) => item.selected && item.type.endsWith('_topping'),
        )
        setSelectedToppings(selectedToppings)

        // TODO: improve this
        const totalRemovalAmount = calculateSelectedMissingItemsTotalPrice(items, {
          considerPartialSelection: true,
          partialItemRefundPercentage,
        })
        setTotalRemovalAmount(totalRemovalAmount)

        const isBundleItemSelected = Boolean(
          selectedItems.find((item) => item.type === 'bundle_item'),
        )
        const isBundleToppingSelected = Boolean(
          selectedToppings.find((item) => item.type === 'bundle_topping'),
        )
        const isBundleItemToppingSelected = Boolean(
          selectedToppings.find((item) => item.type === 'bundle_item_topping'),
        )
        const isPartialItemSelected = partiallySelectedItems.length > 0

        let doUpdateBackends = true

        const provider = order?.delivery?.provider
        const paymentMethod = order?.customer?.payment?.payment_method

        // if always update backend is false, we have to check vendor and issue type details
        if (!alwaysUpdateBackend) {
          const issueTypeConditionMet =
            !excludedCCRs.includes(selectedIssueType?.issue) || provider === 'vendor_delivery'

          const vendorCodeConditionMet = !excludedVendors.includes(vendor?.vendor_id)

          const paymentMethodConditionMet =
            !isCOD(paymentMethod) || provider === 'platform_delivery'

          doUpdateBackends =
            issueTypeConditionMet && vendorCodeConditionMet && paymentMethodConditionMet
        }

        if (
          isBundleToppingSelected ||
          isBundleItemSelected ||
          isBundleItemToppingSelected ||
          isPartialItemSelected
        ) {
          doUpdateBackends = false
        }

        // enable or disable do update backends

        // enable or disable do item removal
        setDoItemRemoval(
          enableItemRemoval &&
            doUpdateBackends &&
            (selectedBundles.length > 0 || selectedItems.length > 0 || selectedToppings.length > 0),
        )

        // enable or disable missing item report
        setDoMissingItemReport(
          doUpdateBackends &&
            isMissingItemReportAllowed &&
            (missingItemReportCRRWhitelist.length === 0 ||
              missingItemReportCRRWhitelist.includes(selectedIssueType?.issue)) &&
            (selectedBundles.length > 0 || selectedItems.length > 0 || selectedToppings.length > 0),
        )
      },
      [
        enableItemRemoval,
        vendor,
        selectedIssueType,
        alwaysUpdateBackend,
        order?.customer,
        order?.delivery,
        excludedCCRs,
        partialItemRefundPercentage,
        excludedVendors,
        isMissingItemReportAllowed,
        missingItemReportCRRWhitelist,
      ],
    )

    // structure order items
    useEffect(() => {
      const items = order?.order?.items || []
      const structuredItems = items.map(structureOrderItem)

      setStructuredOrderItems(structuredItems)
    }, [order?.order?.items, setStructuredOrderItems])

    // get customer compensations and refunds on mount
    const { error: getCustomerCompensationsAndRefundsError } =
      useGetCustomerCompensationsAndRefunds()
    const clearCommentsFromDataContext = useClearCommentsFromDataContext()
    const copyVoucher = useCopyVoucher()

    const {
      loadService: executeGetProposedCompensationValue,
      status: getProposedCompensationValueStatus,
      error: getProposedCompensationValueError,
      clearError: clearGetProposedCompensationValueError,
    } = useApiService({
      service: getProposedCompensationValue,
      deps: [],
      autoLoad: false,
    })

    const { loadService: executeGetOrderPurchaseDetails, loading: isValidatingRefundMethod } =
      useApiService({
        service: getOrderPurchaseDetails,
        deps: [],
        autoLoad: false,
      })

    const isUsingTicketAsFallback = useMemo<boolean>(() => {
      return (
        !isValidatingRefundMethod &&
        refundMethodValidationError &&
        selectedRefundMethod?.useTicketAsFallback
      )
    }, [isValidatingRefundMethod, refundMethodValidationError, selectedRefundMethod])

    /**
     * because this is a stepped process,
     * we must do/undue stuffs in each step for state to be consitent
     * @param index
     */
    const setCurrentStepIndex = (index: number) => {
      switch (index) {
        // issue type selection stage
        case 0:
          _setCurrentStepIndex(index)
          break

        // items selection stage
        case 1:
          _setSelectedRefundMethod(null)
          setTotalRefundAmount(0)
          setCompensationAmount(0)
          setProposedCompensation(null)
          _setCurrentStepIndex(index)
          clearGetProposedCompensationValueError()
          break

        // refund stage
        case 2:
          if (
            !useFlexibleAmountRefund &&
            selectedIssueType.use_auto_comp_proposed_value &&
            selectedIssueType.ccr_code
          ) {
            executeGetProposedCompensationValue({
              customerId: userId,
              entityId: globalEntityId,
              orderId,
              totalOrderValue,
              damagedValue: totalRemovalAmount,
              issueType: selectedIssueType as any,
            })
              .then(({ data }) => {
                setProposedCompensation(data)
              })
              .catch(EMPTY_CATCH_CALLBACK)
              .finally(() => _setCurrentStepIndex(index))
          } else {
            _setCurrentStepIndex(index)
          }
          break
      }
    }

    const setSelectedRefundMethod = (refundMethod: AvailableRefundMethod) => {
      if (refundMethod.method === selectedRefundMethod?.method) {
        return
      }

      setRefundMethodValidationError('')
      captureUserAction('ActionsPartialRefundRefundStepMethodSelected', {
        eventDetails: {
          method: refundMethod.method,
        },
      })

      // set the selected refund method
      _setSelectedRefundMethod(refundMethod)

      let calculatedTotalAmount = 0.0
      let calculatedCompAmount = 0.0

      let result: {
        totalAmountRounded?: number
        compAmount?: number

        refundAmountToWalletOrPsp?: number
        refundAmountWithVoucher?: number
      }

      if (proposedCompensation) {
        switch (refundMethod.method) {
          case availableRefundMethods.noRefund:
            calculatedCompAmount = proposedCompensation.value
            calculatedTotalAmount = 0
            break
          case availableRefundMethods.voucher:
          case availableRefundMethods.auto:
            calculatedCompAmount = proposedCompensation.value
            calculatedTotalAmount = totalRemovalAmount + proposedCompensation.value
            break
          case availableRefundMethods.source:
          case availableRefundMethods.wallet:
          case availableRefundMethods.bankAccount:
            calculatedCompAmount = proposedCompensation.value
            calculatedTotalAmount = totalRemovalAmount
            break
        }
      }

      switch (refundMethod.method) {
        case availableRefundMethods.voucher:
          if (!proposedCompensation) {
            result = calculateRefundAmountForVoucherOrAuto(
              totalRemovalAmount,
              voucherCompensationPercent,
              voucherGranularity,
              voucherMinCompensationAmount,
            )

            calculatedTotalAmount = result.totalAmountRounded
            calculatedCompAmount = result.compAmount
          }
          break
        case availableRefundMethods.auto:
          if (!proposedCompensation) {
            result = calculateRefundAmountForVoucherOrAuto(
              totalRemovalAmount,
              autoCompensationPercent,
              autoGranularity,
              minAutoValue,
            )

            calculatedTotalAmount = result.totalAmountRounded
            calculatedCompAmount = result.compAmount
          }
          break
        case availableRefundMethods.noRefund:
        case availableRefundMethods.bankAccount:
          if (!proposedCompensation) {
            result = calculateRefundAmountForPspAndWallet(
              totalRemovalAmount,
              pspAndWalletCompensationPercent,
              pspAndWalletGranularity,
              pspAndWalletMinCompensationAmount,
            )

            // if method is Wallet or Source -> set reported items' amount as total amount of refund to wallet or psp
            calculatedTotalAmount = result.refundAmountToWalletOrPsp
            calculatedCompAmount = result.refundAmountWithVoucher
          }
          break
        case availableRefundMethods.wallet:
        case availableRefundMethods.source:
          if (!proposedCompensation) {
            result = calculateRefundAmountForPspAndWallet(
              totalRemovalAmount,
              pspAndWalletCompensationPercent,
              pspAndWalletGranularity,
              pspAndWalletMinCompensationAmount,
            )

            // if method is Wallet or Source -> set reported items' amount as total amount of refund to wallet or psp
            calculatedTotalAmount = result.refundAmountToWalletOrPsp
            calculatedCompAmount = result.refundAmountWithVoucher
          }

          // check if api allows refund to wallet or psp
          executeGetOrderPurchaseDetails({
            entityId: globalEntityId,
            orderId,
            customerId: userId,
          })
            .then(
              handleOrderPurchaseResponse({
                t,
                refundMethod,
                action: 'partialRefund',
                calculatedTotalAmount,
                setRefundMethodValidationError,
              }),
            )
            .catch(
              handleOrderPurchaseError({
                t,
                refundMethod,
                setRefundMethodValidationError,
              }),
            )
          break
      }

      if (useFlexibleAmountRefund) {
        // Refund amount get set in second step and compensation on top for flexible amount is not allowed
        return
      }

      // round the values and set to state
      setTotalRefundAmount(Number(fixNumber(calculatedTotalAmount, refundAmountPrecision)))
      setCompensationAmount(Number(fixNumber(calculatedCompAmount, refundAmountPrecision)))

      // use proposed value if it's enabled else if it's static use config value else calculate dynamic value
      if (selectedIssueType?.use_auto_comp_proposed_min_order_value && proposedCompensation) {
        setVoucherMinOrderValue(Number(fixNumber(proposedCompensation.minimum_order_value)))
      } else if (refund_common.isMinOrderValueStatic) {
        setVoucherMinOrderValue(
          Number(
            refund_common.minOrderValue
              ? fixNumber(refund_common.minOrderValue, refundAmountPrecision)
              : 1.0,
          ),
        )
      } else {
        // if MOV is not static, set it to min voucher value + min order val
        setVoucherMinOrderValue(
          Number(
            fixNumber(calculatedTotalAmount + refund_common.minOrderValue, refundAmountPrecision),
          ),
        )
      }

      if (selectedIssueType?.use_auto_comp_proposed_max_order_value && proposedCompensation) {
        setVoucherMaxOrderValue(Number(fixNumber(proposedCompensation.maximum_order_value)))
      }
    }

    const constructRemovedItems = (
      items: ItemStructured[],
      removeSelectedItemToppings = false,
    ): PostItemRemovalBodySingleItem[] => {
      // only include items that are selected, or at least, has one of its childs selected.
      return items
        .filter(
          (item) =>
            item.selected ||
            item.partially_selected ||
            itemHasSelectedOrPartiallySelectedItems(item),
        )
        .map((item) => {
          const shouldRemoveSelectedItemToppings = item.selected && removeSelectedItemToppings
          const removedItem: PostItemRemovalBodySingleItem = {
            id: item.id.split('-').pop(),
            quantity: item.selected ? item.missing_quantity : 0,
            unit_price: item.unit_price,
            toppings: item.options
              .filter(
                (item) =>
                  item.selected ||
                  (shouldRemoveSelectedItemToppings && item.type.endsWith('topping')),
              )
              .map((item) => {
                return {
                  id: item.id.split('-').pop(),
                  quantity: item.missing_quantity,
                  unit_price: item.unit_price,
                  name: item.name,
                }
              }),
          }

          if (item.partially_selected) {
            removedItem.quantity = 0
            removedItem.partial_item_issue = {
              description: item.partial_description || item.name || item.display_name,
              quantity: item.missing_quantity,
              value: item.unit_price * 0.3333,
            }
          }

          if (item.type === 'bundle_item') {
            removedItem.name = item.name || item.display_name
          } else {
            removedItem.display_name = item.display_name
          }

          // only items and bundles should have line_item_id and parent_product_id assigned
          if (item.type === 'item' || item.type === 'bundle') {
            removedItem.line_item_id = item.line_item_id
            removedItem.parent_product_id = item.product_parent?.product_parent_id || ''
          }

          if (item.type === 'bundle') {
            removedItem.bundle_items = constructRemovedItems(
              item.options.filter((current) => current.type === 'bundle_item'),
            )
          }

          return removedItem
        })
    }

    const refundAmountWithoutCompensation = useMemo(() => {
      switch (selectedRefundMethod?.method) {
        case availableRefundMethods.auto:
        case availableRefundMethods.voucher:
          return totalRefundAmount - compensationAmount
        default:
          return totalRefundAmount
      }
    }, [totalRefundAmount, selectedRefundMethod, compensationAmount])

    // fired when Refund clicked -> makes API calls to refund to wallet/psp, create voucher, and report missing item
    const handleRefundClick = async () => {
      let voucherCreated = false
      let succeeded = false
      captureUserAction('ActionsPartialRefundButtonRefundClicked')

      // keep vars in async func to control which client to fire and comment to set
      let refundSuccessDetails = null
      let voucherSuccessDetails = null
      let missingItemsSuccessId = null

      const missingItems = flatten(
        getFromStructuredItems(
          structuredOrderItems,
          (item) => item.selected || item.partially_selected,
        ).map((item) => {
          let description = `${item.type} ${item.display_name || item.name}`
          if (item.partially_selected) {
            if (item.partial_description) {
              description = `${item.partial_description} in ${description}`
            } else {
              description = `Partially missing item in ${description}`
            }
          }
          return new Array(item.missing_quantity).fill(description)
        }),
      )

      const allItems = flatten(
        getFromStructuredItems(structuredOrderItems, (item) => true).map((item) => {
          const description = `${item.type} ${item.display_name || item.name}`
          return new Array(item.total_quantity).fill(description)
        }),
      )

      // async func posting voucher
      const postVoucherAsync = async (removeItemsBody: PostItemRemovalBody) => {
        const includedCompensationValue = Number(
          fixNumber(compensationAmount, refundAmountPrecision),
        )

        let withItemRemoval = doItemRemoval
        let voucherPurpose: VoucherPurpose
        let compensationPurpose = selectedIssueType.issue
        let voucherValue: number

        switch (selectedRefundMethod.method) {
          case availableRefundMethods.voucher:
            // we are refunding total refund amount in this case;
            voucherValue = totalRefundAmount
            voucherPurpose = includedCompensationValue > 0 ? 'refund_compensate' : 'refund'
            break

          case availableRefundMethods.source:
          case availableRefundMethods.wallet:
          case availableRefundMethods.bankAccount:
            // we are only refunding compensation amount
            if (!compensationAmount) {
              return Promise.resolve(true)
            }
            withItemRemoval = false
            voucherPurpose = 'compensate'
            compensationPurpose = selectedIssueType.compensation_issue || defaultCompensationIssue
            voucherValue = compensationAmount
            break

          default:
            // voucher is not created for other methods
            return Promise.resolve(true)
        }

        const getRefundItems = () => {
          if (useFlexibleAmountRefund) {
            return []
          }
          return includedCompensationValue > 0 ? missingItems : allItems
        }

        return createVoucher(createClient, {
          customerId: userId,
          entityId: globalEntityId,
          orderId,
          caseId,
          ccrCode: selectedIssueType.ccr_code,
          platform,

          withItemRemoval,

          compensationPurpose,
          refundPurpose: 'item_refund',
          refundItems: getRefundItems(),

          includedCompensationValue: includedCompensationValue,
          contactReason: removeItemsBody.contact_reason,
          removedItems: useFlexibleAmountRefund ? [] : removeItemsBody.items,

          proposalEventId: proposedCompensation?.event_id || '',
          compensationToken: proposedCompensation?.compensation_token || '',

          voucherDetails: {
            purpose: voucherPurpose,
            channel: 'channel_customer_service',
            beginDate: voucherBeginDate,
            endDate: voucherEndDate,
            minOrderValue: voucherMinOrderValue,
            maxOrderValue: voucherMaxOrderValue,
            value: voucherValue,
            currency: currency,
            type: 'amount',
            description: voucherBodyDescription.replace(ORDER_ID_PLACEHOLDER, orderId),
            paymentTypes: refund_common?.voucher_payment_types,
          },

          clientParams: {
            context: 'Refund',
          },
        })
          .then(({ data }) => {
            voucherCreated = true
            voucherSuccessDetails = data
            setCreatedVoucher(data)

            apiResultReporter.reportVoucherCreation(apiResultsRef.current.results, {
              status: 'success',
              details: {
                voucherCode: data.code,
                currency,
                refundAmount: voucherValue,
              },

              copyDetailsHandler() {
                copyVoucher(data, currency)
              },
            })

            if (withItemRemoval && data.with_item_removal) {
              apiResultReporter.reportItemRemoval(apiResultsRef.current.results, {
                status: 'success',
                details: {
                  ...selectedCountsAndValues,
                },
              })
            } else if (withItemRemoval && !data.with_item_removal) {
              apiResultReporter.reportItemRemoval(apiResultsRef.current.results, {
                status: 'failed',
                details: {
                  ...selectedCountsAndValues,
                },
              })
            }
          })
          .catch((ex) => {
            apiResultReporter.reportVoucherCreation(apiResultsRef.current.results, {
              status: 'failed',
              errorPayload: ex.errorPayload,
            })
            throw ex
          })
      }

      // async function creating missing item report
      const postMissingItemReportAsync = async (removeItemsBody: PostItemRemovalBody) => {
        if (!doMissingItemReport) {
          return Promise.resolve(true)
        }

        return reportMissingItems(createClient, {
          entityId: globalEntityId,
          orderId,
          vendorId: vendor.vendor_id,
          vendorName: vendor.name,
          missingItems,
          contactReason: removeItemsBody.contact_reason,
          removedItems: removeItemsBody.itemsWithToppings,
        })
          .then(({ data, status }) => {
            missingItemsSuccessId = data.id

            apiResultReporter.reportMissingItemsReport(apiResultsRef.current.results, {
              status: 'success',
              details: {
                caseId: data.id,
              },
            })

            datadogRum.addAction('ReportMissingItemsSuccess', {
              data: {
                successfully_reported: true,
                api_response: status,
                selectedBundlesCount: selectedCountsAndValues.bundlesCount,
                selectedToppingsCount: selectedCountsAndValues.toppingsCount,
                selectedItemCount: selectedCountsAndValues.itemsCount,
                totalPartialItemCount: selectedCountsAndValues.partialItemsCount,
                order_id: orderId,
                contact_id: caseId,
              },
            })
          })
          .catch((ex) => {
            apiResultReporter.reportMissingItemsReport(apiResultsRef.current.results, {
              status: 'failed',
              errorPayload: ex.errorPayload,
            })
            throw ex
          })
      }

      // async function removing order items
      const postRemoveOrderItemsAsync = async (removeItemsBody: PostItemRemovalBody) => {
        if (!doItemRemoval) {
          return Promise.resolve(true)
        }
        return removeOrderItems(createClient, {
          entityId: globalEntityId,
          orderId,
          ...removeItemsBody,
        })
          .then(() => {
            apiResultReporter.reportItemRemoval(apiResultsRef.current.results, {
              status: 'success',
              details: {
                ...selectedCountsAndValues,
              },
            })
          })
          .catch((ex) => {
            apiResultReporter.reportItemRemoval(apiResultsRef.current.results, {
              status: 'failed',
              errorPayload: ex.errorPayload,
              details: {
                ...selectedCountsAndValues,
              },
            })
            throw ex
          })
      }

      // async function creating refund to wallet or source
      const postRefundAsync = async (
        target: RefundTarget,
        removeItemsBody: PostItemRemovalBody,
      ) => {
        const amount = Number(fixNumber(totalRefundAmount, refundAmountPrecision))
        return createRefund(createClient, {
          entityId: globalEntityId,
          orderId,
          currency,
          amount,
          description: `Refund to ${target} for order ${orderId}`,

          refundPurpose: 'item_refund',
          target,
          refundItems: useFlexibleAmountRefund ? [] : missingItems,
          contactId: caseId,
          contactReason: removeItemsBody.contact_reason,
          removedItems: useFlexibleAmountRefund ? [] : removeItemsBody.items,
          withItemRemoval: doItemRemoval,
          payment_medium: isCOD(customerPaymentMethod) ? 'Bank Account' : customerPaymentMethod,
          additional_notes: additionalNotes,
          clientParams: {
            tParams: {
              method: selectedRefundMethod.method.toLowerCase(),
            },
          },
        })
          .then(async ({ status, data }) => {
            const refundBreakdown = data.refundBreakDown || []
            apiResultsRef.current.refundBreakdown = refundBreakdown

            apiResultReporter.reportRefund(apiResultsRef.current.results, {
              status: 'success',
              refundBreakdown,
              refundTarget: target,
            })

            setCreatedRefundToWalletOrPsp(true)
            setCreatedRefundStatus(status)
            refundSuccessDetails = data

            if (doItemRemoval) {
              apiResultReporter.reportItemRemoval(apiResultsRef.current.results, {
                status: 'success',
                details: {
                  ...selectedCountsAndValues,
                },
              })
            }
          })
          .catch((ex) => {
            if (target === 'TICKET') {
              if (ex.errorPayload) {
                ex.errorPayload.enableGoBack = false
                ex.errorPayload.enableRetry = false
              }
            }
            setRefundMethodValidationError(
              t(
                `Actions Widget.Actions.Partial Refund.Couldn't refund to {{method}} due to an error`,
                {
                  useLastKeyAsFallback: true,
                  replace: {
                    method: selectedRefundMethod?.method,
                  },
                },
              ),
            )

            apiResultReporter.reportRefund(apiResultsRef.current.results, {
              status: 'failed',
              errorPayload: ex.errorPayload,
              refundBreakdown: [],
              refundTarget: target,
            })
            throw ex
          })
      }

      // body for post item removal request; passed to all relevant api calls to be attached in their bodies
      // at the moment, we only include bundles and normal items + normal item toppings

      const removeItemsBody: PostItemRemovalBody = {
        items: constructRemovedItems(structuredOrderItems),
        itemsWithToppings: constructRemovedItems(structuredOrderItems, true),
        contact_reason: selectedIssueType?.issue,
      }

      apiResultsRef.current.results = []
      apiResultsRef.current.refundBreakdown = []
      setIsLoading(true)

      // depending on selected method, either create voucher or refund via wallet/source, OR just report items
      try {
        if (isUsingTicketAsFallback) {
          await postRefundAsync(RefundTarget.Ticket, removeItemsBody)
          await postVoucherAsync(removeItemsBody)
          await postMissingItemReportAsync(removeItemsBody)
        } else {
          switch (selectedRefundMethod.method) {
            case availableRefundMethods.noRefund:
              apiResultReporter.noRefund(apiResultsRef.current.results, {
                status: 'success',
                refundTarget: null,
              })
              await postRemoveOrderItemsAsync(removeItemsBody)
              await postMissingItemReportAsync(removeItemsBody)
              break
            case availableRefundMethods.voucher:
              await postVoucherAsync(removeItemsBody)
              await postMissingItemReportAsync(removeItemsBody)
              break
            case availableRefundMethods.bankAccount:
              await postRefundAsync(RefundTarget.Ticket, removeItemsBody)
              await postVoucherAsync(removeItemsBody)
              await postMissingItemReportAsync(removeItemsBody)
              break
            case availableRefundMethods.wallet:
            case availableRefundMethods.source:
            case availableRefundMethods.auto:
              const method = selectedRefundMethod.method.toUpperCase() as any
              await postRefundAsync(method, removeItemsBody)

              await postVoucherAsync(removeItemsBody)
              await postMissingItemReportAsync(removeItemsBody)

              break

            default:
              await postRemoveOrderItemsAsync(removeItemsBody)
              await postMissingItemReportAsync(removeItemsBody)
              break
          }
        }

        // everything is successful;
        succeeded = true
        clearCommentsFromDataContext()

        if (missingItemsSuccessId) {
          dataDispatch({
            type: SET_AUTO_MISSING_ITEM_COMMENT,
            payload: { autoMissingItemReportComment: missingItemsSuccessId },
          })
        }

        if (voucherSuccessDetails || refundSuccessDetails) {
          dataDispatch({
            type: SET_AUTO_PARTIAL_REFUND_COMMENT,
            payload: { autoPartialRefundComment: voucherSuccessDetails || refundSuccessDetails },
          })
        }
      } catch (ex) {
        console.log(ex)
        // do nothing
      }

      if (succeeded) {
        sdk.eventEmitter.dispatchEvent({
          name: 'PARTIAL_REFUND_SUCCESS',
          payload: { orderId },
        })
      }

      // send 'ActionsPartialRefundDone' event to ETS,
      const refundBreakdown = apiResultsRef.current.refundBreakdown
      const isSelectedMethodNotVoucher =
        selectedRefundMethod?.method !== availableRefundMethods.voucher // used to only send voucherCreated if refund method is not voucher

      let refundValue = refundAmountWithoutCompensation
      let paymentMedium: string = selectedRefundMethod.method
      let refundDetails = null

      if (refundBreakdown.length) {
        paymentMedium = refundBreakdown.length > 1 ? 'MIXED' : refundBreakdown[0].method
        refundValue = refundBreakdown.reduce((result, current) => {
          return result + current.amount.amount
        }, 0)

        refundDetails = refundBreakdown.reduce((result, current) => {
          result[current.method] = current.amount.amount
          return result
        }, {})
      }

      sendEventToEvts('ActionsPartialRefundDone', {
        eventDetails: {
          actionFailed: !succeeded,
          issue_type: selectedIssueType?.issue,
        },
        contactDetails: {
          customer_exploit_status: customerExploitStatus,
        },
        financial: {
          paymentMedium,
          currencyCode: currency,
          compensationValue: Number(compensationAmount),
          refundValue: Number(fixNumber(refundValue, refundAmountPrecision)),
          details: {
            proposed_value: proposedCompensation?.value,
            voucherCreated: isSelectedMethodNotVoucher && voucherCreated,
            voucher_code: voucherSuccessDetails?.code,
            trigger_id: proposedCompensation?.trigger_id,
            missing_item_value: Number(
              fixNumber(selectedCountsAndValues.itemsValue, refundAmountPrecision),
            ),
            missing_partial_value: Number(
              fixNumber(selectedCountsAndValues.partialItemsValue, refundAmountPrecision),
            ),
            missing_topping_value: Number(
              fixNumber(selectedCountsAndValues.toppingsValue, refundAmountPrecision),
            ),
            missing_item_count: selectedCountsAndValues.itemsCount,
            missing_partial_count: selectedCountsAndValues.partialItemsCount,
            missing_topping_count: selectedCountsAndValues.toppingsCount,

            refund_details: refundDetails,
          },
        },
      })

      setIsLoading(false)
    }

    const apiResults = apiResultsRef.current.results
    const hasResults = apiResults.length > 0

    useImperativeHandle(
      ref,
      () => {
        return {
          onXButtonClick: () => {
            return hasResults || selectedIssueType === null
          },

          onBeforeClose: () => {
            if (createdVoucher) {
              dataDispatch({
                type: SET_COMPENSATED_VOUCHER,
                payload: { compensatedVoucher: createdVoucher },
              })
              dataDispatch({
                type: SET_FULLY_REFUNDED_VOUCHER,
                payload: { fullyRefundedVoucher: undefined },
              })
            }

            if (createdRefundToWalletOrPsp) {
              dataDispatch({
                type: SET_REFUND_TO_WALLET_OR_PSP_DETAILS,
                payload: { refundToWalletOrPspDetails: true },
              })
            }
          },
        }
      },
      [
        selectedIssueType,
        createdVoucher,
        createdRefundToWalletOrPsp,
        dataDispatch,
        SET_COMPENSATED_VOUCHER,
        SET_FULLY_REFUNDED_VOUCHER,
        SET_REFUND_TO_WALLET_OR_PSP_DETAILS,
        hasResults,
      ],
    )

    return (
      <WidgetErrorHandler
        errorPayload={apiResults[0]?.errorPayload}
        displayType='overlay'
        onQuit={onQuit}
        onBack={() => {
          apiResultsRef.current.results = []
          forceUpdate()
        }}
        onRetry={handleRefundClick}
        loading={{
          [`${t('Interface.Getting proposed voucher value from autocomp')}`]:
            getProposedCompensationValueStatus === 'loading',
          '': isLoading,
        }}
      >
        {() => {
          if (hasResults) {
            return (
              <Result
                onClose={onQuit}
                results={apiResults}
                selectedRefundMethod={selectedRefundMethod}
                createdRefundStatus={createdRefundStatus}
                refundBreakdown={apiResultsRef.current.refundBreakdown}
              />
            )
          }

          return (
            <>
              {/* handle errors relating to getCustomerCompensationsAndRefunds */}
              <WidgetErrorHandler
                errorPayload={getCustomerCompensationsAndRefundsError?.errorPayload}
                displayType={'mini'}
              />

              {/* handle errors relating to getProposedVoucherValue */}
              <WidgetErrorHandler
                errorPayload={getProposedCompensationValueError?.errorPayload}
                displayType={'mini'}
              />

              {/* CONTENT as STEPPER -> controlled by current index, stepper has 2 steps, Remove and Refund */}

              {/* Banner */}
              {(customerCompensations?.length > 0 || customerRefunds?.length > 0) && (
                <InfoAlertView
                  utcZone={utc_zone}
                  compensations={customerCompensations ? customerCompensations : null}
                  refunds={customerRefunds ? customerRefunds : null}
                  parent='PartialRefund'
                />
              )}

              <div className={classes.stepsContainer}>
                <Steps current={currentStepIndex}>
                  <Step
                    title={t('Actions Widget.Actions.Partial Refund.Issue')}
                    description={t(
                      selectedIssueType
                        ? `Actions Widget.Actions.Compensation.Issue Types.${selectedIssueType.issue_translation_key}`
                        : ``,
                    )}
                  />
                  {useFlexibleAmountRefund ? (
                    <Step title={t('Actions Widget.Actions.Partial Refund.Value')} />
                  ) : (
                    <Step
                      title={t('Actions Widget.Actions.Partial Refund.Remove')}
                      // displays number of items/toppings; adds an 's' for numbers > 1
                      description={[
                        `${selectedCountsAndValues.bundlesCount} ${t(
                          'Actions Widget.Actions.Partial Refund.Bundle',
                        )}`,
                        `${selectedCountsAndValues.itemsCount} ${t(
                          'Actions Widget.Actions.Partial Refund.Item',
                        )}`,
                        `${selectedCountsAndValues.toppingsCount} ${t(
                          'Actions Widget.Actions.Partial Refund.Topping',
                        )}`,
                      ].join(', ')}
                    />
                  )}

                  <Step
                    title={t('Actions Widget.Actions.Partial Refund.Refund')}
                    description={
                      selectedRefundMethod?.method === availableRefundMethods.noRefund ? (
                        <span style={{ textDecoration: 'line-through' }}>
                          ${currency} {fixNumber(totalRefundAmount, refundAmountPrecision)}
                        </span>
                      ) : (
                        `${currency} ${fixNumber(totalRefundAmount, refundAmountPrecision)}`
                      )
                    }
                  />
                </Steps>
              </div>

              {/* Step components -> rendered by current index, it is either remove or refund */}
              <div className={classes.contentContainer}>
                {currentStepIndex === 0 ? (
                  <Issue
                    actionName={'PartialRefund'}
                    useCcrWhitelistForIssues={useCcrWhitelistForPartialRefundIssues}
                    setCurrentStepIndex={setCurrentStepIndex}
                    issueTypes={availableIssueTypes}
                    selectedIssueType={selectedIssueType}
                    setSelectedIssueType={setSelectedIssueType}
                  />
                ) : null}

                {currentStepIndex === 1 ? (
                  useFlexibleAmountRefund ? (
                    <FlexibleAmount
                      currency={currency}
                      refundIssueType={selectedIssueType}
                      onGoBack={() => setCurrentStepIndex(0)}
                      onContinue={() => setCurrentStepIndex(2)}
                      refundAmount={totalRefundAmount}
                      onChange={(value) =>
                        setTotalRefundAmount(Number(fixNumber(value, refundAmountPrecision)))
                      }
                    />
                  ) : (
                    <Remove
                      structuredOrderItems={structuredOrderItems}
                      setStructuredOrderItems={setStructuredOrderItems}
                      setCurrentStepIndex={setCurrentStepIndex}
                      totalOrderValue={totalOrderValue}
                      totalRemovalAmount={totalRemovalAmount}
                      numberOfRemovalBundles={selectedCountsAndValues.bundlesCount}
                      numberOfRemovalItems={selectedCountsAndValues.itemsCount}
                      numberOfRemovalToppings={selectedCountsAndValues.toppingsCount}
                    />
                  )
                ) : null}

                {currentStepIndex === 2 ? (
                  <Refund
                    useTicketAsFallback={isUsingTicketAsFallback}
                    customerPaymentMethod={customerPaymentMethod}
                    additionalNotes={additionalNotes}
                    setAdditionalNotes={setAdditionalNotes}
                    selectedIssueType={selectedIssueType}
                    selectedItems={selectedItems}
                    partiallySelectedItems={partiallySelectedItems}
                    refundMethods={refundMethods}
                    selectedRefundMethod={selectedRefundMethod}
                    setSelectedRefundMethod={setSelectedRefundMethod}
                    handleRefundClick={handleRefundClick}
                    setCurrentStepIndex={setCurrentStepIndex}
                    totalRemovalAmount={totalRemovalAmount}
                    totalRefundAmount={totalRefundAmount}
                    compensationAmount={compensationAmount}
                    voucherBeginDate={voucherBeginDate}
                    voucherEndDate={voucherEndDate}
                    voucherPaymentTypes={voucherPaymentTypes}
                    voucherMinOrderValue={voucherMinOrderValue}
                    doMissingItemReport={doMissingItemReport}
                    doItemRemoval={doItemRemoval}
                    isValidatingRefundMethod={isValidatingRefundMethod}
                    refundMethodValidationError={refundMethodValidationError}
                  />
                ) : null}
              </div>
            </>
          )
        }}
      </WidgetErrorHandler>
    )
  },
  {
    category: 'action',
    deriveConfig({ entityConfig }) {
      return {
        utc_zone: entityConfig.utc_zone,
        useCcrWhitelistForPartialRefundIssues: entityConfig.useCcrWhitelistForPartialRefundIssues,
        refund_common: entityConfig.fixed_panel_config.widgets_configs.actions.refund_common,
        partial_refund: entityConfig.fixed_panel_config.widgets_configs.actions.partial_refund,
      }
    },
  },
)
