// Converts numeric degrees to radians
function toRad(Value) {
  return (Value * Math.PI) / 180
}

//This function takes in latitude and longitude of two location and returns the distance between them as the crow flies (in km)
function getDifferenceInCoordinates(lat1: number, lon1: number, lat2: number, lon2: number) {
  const R = 6371 // km
  const dLat = toRad(lat2 - lat1)
  const dLon = toRad(lon2 - lon1)
  const lat1Radded = toRad(lat1)
  const lat2Radded = toRad(lat2)

  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1Radded) * Math.cos(lat2Radded)
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
  const distance = R * c
  return distance
}

export default getDifferenceInCoordinates
