import { ApiErrorScreenType } from 'apiErrorConfigs/voucherApiErrorConfig'

export const apiErrorScreenTypeToAlertType = (errorScreenType: ApiErrorScreenType) => {
  switch (errorScreenType) {
    case 'warning':
    case 'alert':
    case 'timeout':
      return 'warning'

    case 'info':
    case 'instruct':
      return 'info'

    default:
      return 'error'
  }
}
