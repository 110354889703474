/**
 * HistoryOfEvents Order Tab
 * pulls from API and displays order-related events with filtering options
 * */

// libs
import React, { useState, useEffect, useContext } from 'react'
// contexts and types
import { SessionContext } from 'contexts/session/SessionContext'
import { useTranslation } from 'hooks/useTranslation'
//utils
// styles
import { createUseStyles } from 'react-jss'
import styles from './BackOffice.styles'
import { Typography, Result, Divider, Checkbox } from 'antd'
import { CommentOutlined } from '@ant-design/icons'
// components
import { useApiService } from 'hooks/useApiService'
import { getBackOfficeOrderComments } from 'services/ordersApi/getBackOfficeOrderComments'
import { WidgetErrorHandler } from 'components/WidgetErrorHandler/widgetErrorHandler'
import { useCaptureUserAction } from 'hooks/events/useCaptureUserAction'

const useStyles = createUseStyles(styles)

const BackOffice: React.FC = () => {
  const classes = useStyles()
  const { Text } = Typography

  // pull language content
  const { t } = useTranslation()
  const captureUserAction = useCaptureUserAction()

  // pull globalentity ID from session context
  const {
    sessionState: { orderId, globalEntityId },
  } = useContext(SessionContext)

  const {
    loadService: loadBackOfficeOrderComments,
    loading: isLoading,
    data,
    error,
  } = useApiService({
    service: getBackOfficeOrderComments,
    params: {
      entityId: globalEntityId,
      orderId,
    },
    deps: [globalEntityId, orderId],
  })

  // set comments to data context
  const [modifiedComments, setModifiedComments] = useState<string[]>([])
  useEffect(() => {
    if (data) {
      const comments = data[0].comment.split('\n')
      if (comments?.length > 0 && comments[0] !== '') {
        setModifiedComments(comments.filter((com: string) => com.length > 1))
      }
    }
  }, [data])

  const [hideOneviewEvents, setHideOneviewEvents] = useState(false)
  const handleCheckboxChange = () => {
    if (hideOneviewEvents) {
      const comments = data[0].comment.split('\n')
      if (comments?.length > 0 && comments[0] !== '') {
        setModifiedComments(comments.filter((com: string) => com.length > 1))
      }
    } else {
      setModifiedComments(
        modifiedComments.filter((com: string) => !com.includes('added by oneview')),
      )
    }
    setHideOneviewEvents(!hideOneviewEvents)
    captureUserAction('HistoryOfEventsBackofficeHideOneviewCommentsClicked', {
      eventDetails: {
        state: !hideOneviewEvents ? 'enabled' : 'disabled',
      },
    })
  }

  return (
    <div className={classes.backOfficeContainer}>
      <WidgetErrorHandler
        errorPayload={error?.errorPayload}
        loading={isLoading}
        loadingText={t('Interface.Loading Back Office Comments')}
        onRetry={loadBackOfficeOrderComments}
      >
        {() => {
          if (!modifiedComments || modifiedComments.length < 1) {
            return (
              <Result
                icon={<CommentOutlined />}
                title={t('History of Events Widget.No Back Office Comment Found')}
                subTitle={t(
                  'History of Events Widget.There is no comment associated with the order',
                )}
              />
            )
          }

          return (
            <div className={classes.commentsContainer}>
              <div className={classes.filterContainer}>
                <Text className={classes.eventsTitle}>
                  {t('History of Events Widget.Back Office')}
                </Text>
                <Checkbox
                  className={classes.checkbox}
                  checked={hideOneviewEvents}
                  onChange={handleCheckboxChange}
                >
                  {t('History of Events Widget.Hide Oneview Events')}
                </Checkbox>
              </div>

              {modifiedComments?.length > 0 && (
                <div className={classes.comments}>
                  {modifiedComments.map((com: string, idx: number) => {
                    // find out if comment added by Oneview
                    const addedByOneView = com.includes('added by oneview')
                    // remove onewview expression from comment
                    const comment = com.split('(added by oneview)')

                    return (
                      <React.Fragment key={idx}>
                        <div
                          className={
                            addedByOneView
                              ? classes.singleCommentContainerOneview
                              : classes.singleCommentContainer
                          }
                        >
                          {addedByOneView ? (
                            <React.Fragment>
                              <Text>{comment}</Text>
                              <Text className={classes.addedByOneviewHighlight}>
                                {`(${t('History of Events Widget.Added by Oneview')})`}
                              </Text>
                            </React.Fragment>
                          ) : (
                            <Text>{comment}</Text>
                          )}
                        </div>
                        <Divider className={classes.divider} />
                      </React.Fragment>
                    )
                  })}
                </div>
              )}
            </div>
          )
        }}
      </WidgetErrorHandler>
    </div>
  )
}

export default BackOffice
