import React, { useEffect, useImperativeHandle, useState } from 'react'
import { createPluggableWidget } from 'factory/createPluggableWidget'
import { useApiService } from 'hooks/useApiService'
import { getVendorInvoices } from 'services/vendorApi/getVendorInvoices'
import { BuiltinWidgetConfigs } from 'types'
import { InvoicesFilters } from './InvoicesFilters'
import { VendorInvoices } from './VendorInvoices'
import { getVendorInvoiceFile } from 'services/vendorApi/getVendorInvoiceFile'
import { downloadFileBlob } from 'utils/downloadFileBlob'

export const VendorInvoice = createPluggableWidget<BuiltinWidgetConfigs['vendor_invoice']>(
  ({ config, globalEntityId, vendorId, ErrorRenderer }, ref) => {
    const [selectedDate, setSelectedDate] = useState<Date>(null)
    const [filesDownloading, setFileDownloading] = useState<string[]>([])

    const {
      data: vendorInvoices,
      loading: checkVendorInvoiceLoading,
      error: getVendorInvoiceError,
      loadService: fetchVendorInvoices,
    } = useApiService({
      service: getVendorInvoices,
      autoLoad: false,
      params: {
        globalEntityId,
        vendorId,
        apiVersion: config.api_version,
        date: { month: null, year: null },
      },
    })

    const { loadService: fetchVendorInvoiceFile } = useApiService({
      service: getVendorInvoiceFile,
      autoLoad: false,
      params: {
        globalEntityId,
        vendorId,
        apiVersion: config.api_version,
        fileName: '',
        callBack: (name) => setFileDownloading((prev) => prev.filter((item) => item !== name)),
      },
      onSuccess: ({ data }) => {
        downloadFileBlob(data)
      },
    })

    function handleDateChange(date: Date) {
      setSelectedDate(date)
      fetchVendorInvoices({ date: { month: date.getMonth() + 1, year: date.getFullYear() } })
    }

    function handleDownloadFile(fileName: string) {
      fetchVendorInvoiceFile({ fileName })
      setFileDownloading((prev) => [...prev, fileName])
    }

    useEffect(() => {
      fetchVendorInvoices({
        date: {
          month: new Date().getMonth() + 1,
          year: new Date().getFullYear(),
        },
      })
    }, [fetchVendorInvoices])

    useImperativeHandle(ref, () => ({ onRefresh: fetchVendorInvoices }), [fetchVendorInvoices])

    return (
      <ErrorRenderer
        loading={checkVendorInvoiceLoading}
        errorPayload={getVendorInvoiceError?.errorPayload}
      >
        {() => {
          return (
            <div>
              <InvoicesFilters date={selectedDate?.toISOString()} onDateChange={handleDateChange} />
              <VendorInvoices
                invoices={vendorInvoices}
                selectedDate={selectedDate?.toISOString()}
                filesDownloading={filesDownloading}
                onDownloadInvoice={handleDownloadFile}
                pageSize={config.page_size}
              />
            </div>
          )
        }}
      </ErrorRenderer>
    )
  },
  {
    deriveConfig: ({ entityConfig }) => {
      return entityConfig.layout_v2.builtin_widgets_configs.vendor_invoice
    },
    deriveSubjectsRequirements() {
      return {
        all_of: ['vendorId'],
      }
    },
  },
)
