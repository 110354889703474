import { ApiService } from 'services/authApi/getPermissions'

type VendorInvoicesResponse = {
  data: VendorInvoice[]
}

type VendorInvoiceFiles = {
  pdf?: string
  xls?: string
}
export type VendorInvoiceFileType = keyof VendorInvoiceFiles

export type VendorInvoice = {
  id?: string
  generated_at?: string
  year?: number
  month?: number
  files?: VendorInvoiceFiles
}

export const getVendorInvoices: ApiService<
  {
    globalEntityId: string
    vendorId: string
    date: { year: number; month: number }
    apiVersion?: 'v1' | 'v2'
  },
  VendorInvoice[]
> = (createClient, options) => {
  const { globalEntityId, vendorId, apiVersion = 'v1', date, clientParams } = options
  const uri = `/VendorAPI/${apiVersion}/vendors/${globalEntityId}/${vendorId}/invoices`

  return createClient({
    endpointName: 'getVendorInvoices',
    expectedResponseStatusCode: 200,
    ...clientParams,
  })
    .get<VendorInvoicesResponse>(uri, { params: date })
    .then((res) => {
      return { ...res, data: res.data.data }
    })
}
