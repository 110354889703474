import React from 'react'
import { Typography } from 'antd'
import { createUseStyles } from 'react-jss'
import { useTranslation } from 'hooks/useTranslation'

import styles from './UpdatedDeliveryTime.styles'

const useStyles = createUseStyles(styles)

const { Text } = Typography

type Props = {
  deliveryTime: string
}
const UpdatedDeliveryTime = ({ deliveryTime }: Props) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <div data-testid='updated-delivery-time'>
      <Text className={classes.text}>
        {t('Messages.The vendor has adjusted the promised delivery time of this order to')}:
      </Text>
      <Text className={classes.datetime}>{deliveryTime}</Text>
    </div>
  )
}

export default UpdatedDeliveryTime
