export default {
  riderContainer: {
    display: 'flex',
    width: 280,
    height: 680,
    backgroundColor: '#ffffff',
  },
  searchPageContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
}
