export default {
  actionsContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: 280,
    height: 'auto',
    padding: '5px 0px 5px 0px',
    boxShadow: '0 4px 2px -2px rgba(0, 0, 0, 0.05)',
  },
  sectionTitle: {
    fontSize: '18px',
    color: 'rgba(0, 0, 0, 0.85)',
    marginBottom: '10px',
  },
  buttonsContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'baseline',
  },
}
