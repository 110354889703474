import { LinesOfBusiness } from 'types/session/linesOfBusiness'
import { getSearchParams } from 'utils/getSearchParams'
import { readAuthPayload } from 'utils/authHelpers'
import { datadogRum } from '@datadog/browser-rum'

type ErrorTypes =
  | 'missing-entity-country-details'
  | 'missing-country-calling-code'
  | 'generic-error'
  | 'white-screen-error'
  | 'missing-api-error-config'
  | 'missing-configured-icon'
  | 'api-error'
  | 'unknown-customer-wallet-transaction'
  | 'unknown-order-status'
  | 'unknown-rider-status'
  | 'missing-translation-key'
  | 'process-api-error'
  | 'unknwown-customer-profile-field'
  | 'basket-update-error'
  | 'summary-error'
  | 'unknown-customer-wallet-transaction-type'
  | 'unknown-item-requirement'
  | 'geocode-error'
  | 'missing-product-item-translation-key'
  | 'local-storage-permission-error'
  | 'cannot-copy'
  | 'plugin-error'
  | 'widget-error'
  | 'get-ets-token-error'
  | 'ets-event-report-error'
  | 'widget_runtime_error'
  | 'missing-entity-country-calling-code'
  | 'missing-datapoint-handler'

  // this error tracks unknown product action type in order items basket updates
  | 'unknown-basket-update-product-action'

export type ErrorLog = {
  originalError?: Error
  type: ErrorTypes

  /**
   * the widget where the error is originating from
   */
  widget_id?: string

  [p: string]: string | number | boolean | Error
}

export const logError = (errorLog: ErrorLog) => {
  const searchParams = getSearchParams()

  const extraLogParams: {
    order_id?: string
    vendor_id?: string
    rider_id?: string
  } = {}

  switch (searchParams.getLineOfBusiness()) {
    case LinesOfBusiness.customer:
      extraLogParams.order_id = searchParams.getOrderId()
      break

    case LinesOfBusiness.vendor:
      extraLogParams.vendor_id = searchParams.getVendorId()
      break

    case LinesOfBusiness.rider:
      extraLogParams.order_id = searchParams.getOrderId()
      extraLogParams.rider_id = searchParams.getRiderId()
      break
  }

  const { originalError, type = 'generic-error', ...context } = errorLog

  const errObject =
    originalError && originalError instanceof Error ? originalError : new Error(type)

  datadogRum.addError(errObject, {
    global_entity_id: searchParams.getGlobalEntityId(),
    lob: searchParams.getLineOfBusiness(),
    agent_email: readAuthPayload().agentEmail,
    lang: searchParams.getLang(),
    ...extraLogParams,
    ...context,
  })
}
