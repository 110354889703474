import React, { PropsWithChildren, useMemo } from 'react'
import { Space, Typography } from 'antd'
import { createUseStyles } from 'react-jss'

import { SingleComment } from 'types/api/commentApi/getOrderComments'
import styles from './Comments.styles'
import { AgentName } from 'components/AgentName/AgentName'
import { OrderIdDisplay } from 'components/OrderIdDisplay/OrderIdDisplay'
import { MessageTag, ThunderboltTag } from 'components/Tags/CustomTags'
import cx from 'classnames'
import { getLOBFromClentID } from 'utils/string'

import { ReactComponent as CSIcon } from 'assets/lob/CustomerServiceAgent.svg'
import { ReactComponent as RSIcon } from 'assets/lob/RiderServiceAgent.svg'
import { ReactComponent as VSIcon } from 'assets/lob/VendorServiceAgent.svg'
import { LinesOfBusiness } from 'types/session/linesOfBusiness'
import { useDateTimeFormatter } from 'hooks/formatters/useDateTimeFormatter'
import { WidgetSize } from 'types/widgets/historyOfEvents/tabs'
import { CustomIcon } from 'factory/createCustomSvgIcon'
import { CheckOutlined, CloseOutlined, InfoCircleOutlined } from '@ant-design/icons'
import { green, red, text as textColor } from 'theme'
import { SmartToolTip, Text as DSText, CopyButton } from 'shared'
import { TransformedComment } from 'hooks/dataTransformers/useTransformComment'
import { useTranslation } from 'hooks/useTranslation'
const { Text } = Typography

const useStyles = createUseStyles(styles)

export interface CommentViewNote {
  type: 'errorNote' | 'successNote'
  note: string

  details?: string
}

export enum commentTagType {
  thunderbolt = 'thunderbolt',
  message = 'message',
}

type Props = {
  comment: SingleComment

  shouldCommentBeHighlighted?: boolean

  tag?: {
    type?: commentTagType
    text: string
  }
  mode: WidgetSize

  author?: string

  Icon?: CustomIcon

  notes?: TransformedComment['notes']

  fallbackCaseIds?: TransformedComment['fallbackCaseIds']

  issueType?: string

  voucherCode?: string
}

export const CommentView: React.FC<PropsWithChildren<Props>> = ({
  comment,
  shouldCommentBeHighlighted,
  tag,
  children,
  mode = WidgetSize.mini,
  Icon,
  author,
  notes = [],
  fallbackCaseIds = [],
  issueType,
  voucherCode,
}) => {
  const dateFormatter = useDateTimeFormatter()
  const classes = useStyles(mode)
  const { t } = useTranslation()

  const { text, type = commentTagType.thunderbolt } = tag || {}
  const lob = useMemo(() => {
    return getLOBFromClentID(comment?.client_id)
  }, [comment])

  if (!Icon) {
    switch (lob) {
      case LinesOfBusiness.customer:
        Icon = CSIcon
        break

      case LinesOfBusiness.rider:
        Icon = RSIcon
        break

      case LinesOfBusiness.vendor:
        Icon = VSIcon
        break
    }
  }

  return (
    <div
      className={cx(
        'comment-container',
        shouldCommentBeHighlighted
          ? classes.singleCommentHolderHighlighted
          : classes.singleCommentHolder,
      )}
    >
      <div className={classes.header}>
        <div className={classes.agentIcon}>{Icon ? <Icon /> : null}</div>
        <div className={classes.headerContent}>
          <div className={classes.leftCell}>
            <AgentName agentId={author || comment.agent_id} />
            {mode === WidgetSize.max && <span className={classes.divider}></span>}
            {mode === WidgetSize.max &&
              (comment?.order_id ? <OrderIdDisplay orderId={comment?.order_id} /> : '-')}
          </div>
          <div>
            <Text className={classes.commentDateText}>
              {dateFormatter.formatDateTime(comment.created_at, { format: 'DD.MM.YYYY h:mm A' })}
            </Text>
          </div>
        </div>
      </div>
      <div className={classes.content}>
        <span>{children}</span>
        {mode === WidgetSize.max && (
          <span className={classes.tagContainer}>
            {type === commentTagType.thunderbolt && <ThunderboltTag>{text}</ThunderboltTag>}
            {type === commentTagType.message && <MessageTag>{text}</MessageTag>}
          </span>
        )}
      </div>
      {mode === WidgetSize.mini && (
        <div className={classes.footer}>
          {comment?.order_id && <OrderIdDisplay orderId={comment?.order_id} />}
          <span className={classes.tagContainer}>
            {type === commentTagType.thunderbolt && <ThunderboltTag>{text}</ThunderboltTag>}
            {type === commentTagType.message && <MessageTag>{text}</MessageTag>}
          </span>
        </div>
      )}

      {/* voucher code */}
      {voucherCode && (
        <div className={classes.issueType}>
          <DSText.Primary>{t('widgets.activity.voucher_code')}:</DSText.Primary>
          <DSText.Primary fontWeight='700' pl={2}>
            {voucherCode}
          </DSText.Primary>
        </div>
      )}

      {/* issue type */}
      {issueType && (
        <div className={classes.issueType}>
          <DSText.Primary>{t('widgets.activity.issue_type')}:</DSText.Primary>
          <DSText.Primary fontWeight='700' pl={2}>
            {issueType}
          </DSText.Primary>
        </div>
      )}

      {/* notes */}
      {notes.length > 0 && (
        <div className={classes.notes}>
          {notes.map((note) => {
            let Icon = CloseOutlined
            let color = red.red6
            switch (note.type) {
              case 'successNote':
                Icon = CheckOutlined
                color = green.green6
                break
            }
            return (
              <Space size={4} key={note.note}>
                <Icon style={{ color }} /> <DSText.Primary>{note.note}</DSText.Primary>{' '}
                {note.details ? (
                  <SmartToolTip title={note.details}>
                    <InfoCircleOutlined style={{ color: textColor.secondary }} />
                  </SmartToolTip>
                ) : null}
              </Space>
            )
          })}
        </div>
      )}

      {fallbackCaseIds.length > 0 && (
        <div className={classes.fallbackCases}>
          <Space size={8}>
            <DSText.Primary>{t(`widgets.activity.fallback_case_created`)}</DSText.Primary>
            <span className={classes.caseDivider} />
            <Space size={16}>
              {fallbackCaseIds.map(({ label, caseId }) => {
                return (
                  <DSText.Primary color={textColor.secondary} key={caseId}>
                    <Space>
                      <span>{label}</span>
                      <CopyButton text={caseId} />
                    </Space>
                  </DSText.Primary>
                )
              })}
            </Space>
          </Space>
        </div>
      )}
    </div>
  )
}
