/**
 * VENDOR Widget: fetches vendor data
 * renders available vendor widget tabs for the current entity
 * in case of client failure; renders error UI
 * */

// libs
import React, { useState, useEffect } from 'react'
// configs
import {
  allowedScrollablePanelWidgets,
  allowedVendorWidgetTabs,
} from 'entityConfig/allowedConfigValues'
// contexts and types
import VendorTabs from 'types/widgets/vendor/tabs'
import { LinesOfBusiness } from 'types/session/linesOfBusiness'

// hooks
import { useTranslation } from 'hooks/useTranslation'
import { useEntityConfig } from 'hooks/useEntityConfig'
import { useSessionState } from 'hooks/useSessionState'
// style
import { Tabs, Tag } from 'antd'
// components
import CSWidgetHeading from 'components/CSWidgetHeading'
import Summary from './Summary'
import WarningMessage from 'components/WarningMessage'
import { useActiveTab } from 'hooks/useActiveTab'
import { WidgetErrorHandler } from 'components/WidgetErrorHandler/widgetErrorHandler'
import { NewDesign } from 'components/NewDesign/NewDesign'
import { useGetValidFeatures } from 'hooks/useGetValidFeatures'
import { usePluginUtils } from 'hooks/usePluginUtils'
import { PluginContainer } from 'components/PluginContainer'
import { WidgetTitle } from 'components/Widget'
import { useUiV1TopLevelTabStyles } from 'hooks/styles/useUiV1TopLevelTabStyles'
import type { useApiService } from 'hooks/useApiService'
import { RootWidgetView } from 'components/RootWidgetView'
import { builtinWidgetNames, widgetTypes } from 'types'

const Vendor = ({ initResult }: { initResult: ReturnType<typeof useApiService> }) => {
  const { TabPane } = Tabs

  // pull language content
  const { t } = useTranslation()

  // pull data state and dispatch from data context
  const { vendorId, lineOfBusiness } = useSessionState()

  const getValidFeatures = useGetValidFeatures()

  const { getWidgetConfig } = useEntityConfig()

  const { isTabAPlugin, getTabPluginConfig } = usePluginUtils()

  // @TODO: Temporary New Design in Beta
  const vendorConfig = getWidgetConfig('vendor')
  const orderConfig = getWidgetConfig('order')

  // run beta flag checks on tabs and set initial tab
  const [activeTab, setActiveTab] = useActiveTab<VendorTabs | string>('VendorTab')
  const [vendorWidgetTabOrder, setVendorWidgetTabOrder] = useState<string[]>()

  useEffect(() => {
    if (vendorConfig?.tab_order) {
      const tabs = getValidFeatures(vendorConfig.tab_order)
      setVendorWidgetTabOrder(tabs)
      setActiveTab(VendorTabs[tabs[0]])
    }
  }, [setActiveTab, getValidFeatures, vendorConfig])

  const tabStyles = useUiV1TopLevelTabStyles()

  const { error, loading, loadService: loadVendor, loadDisabled } = initResult

  return (
    <div>
      <NewDesign
        config={orderConfig?.tab_configs.summary}
        fallback={
          <WidgetTitle>
            {t('Vendor Widget.Vendor')}
            {vendorId ? (
              <Tag color='#64bd53'>{`${t('Vendor Widget.Vendor ID')}: ${vendorId}`}</Tag>
            ) : null}
          </WidgetTitle>
        }
      >
        <CSWidgetHeading title='Vendor Widget.Vendor' id={vendorId} />
      </NewDesign>

      <WidgetErrorHandler
        errorPayload={error?.errorPayload}
        onRetry={loadVendor}
        loading={loading}
        loadingText={t(`Interface.Loading Vendor`)}
        dataUnavailable={loadDisabled}
      >
        {() => {
          return (
            <div>
              {lineOfBusiness === LinesOfBusiness.riderV2 && <WarningMessage />}
              <Tabs
                className={tabStyles.tabs}
                activeKey={activeTab}
                onChange={(tab: string) => {
                  switch (tab) {
                    case VendorTabs.summary:
                      setActiveTab(VendorTabs[tab])
                      break
                    default:
                      if (isTabAPlugin(allowedScrollablePanelWidgets.vendor, tab)) {
                        setActiveTab(tab, true)
                      } else {
                        setActiveTab(VendorTabs[tab], true)
                      }
                  }
                }}
                type='card'
                size={'small'}
              >
                {vendorWidgetTabOrder
                  ? vendorWidgetTabOrder.map((tab: string) => {
                      switch (tab) {
                        case allowedVendorWidgetTabs.summary:
                          return (
                            <TabPane tab={t('Widgets Common.Summary')} key='summary'>
                              <Summary summary={vendorConfig?.tab_configs.summary} />
                            </TabPane>
                          )
                        case allowedVendorWidgetTabs.vendor_summary:
                          return (
                            <TabPane
                              tab={t('Widgets Common.Summary')}
                              key={allowedVendorWidgetTabs.vendor_summary}
                            >
                              <RootWidgetView
                                widgetDefinition={{
                                  type: widgetTypes.builtin,
                                  widget_name: builtinWidgetNames.vendorSummary,
                                }}
                                screenName='vendor_widgets'
                                key={allowedVendorWidgetTabs.vendor_summary}
                                tabContainerLabel={undefined}
                              />
                            </TabPane>
                          )
                      }

                      if (isTabAPlugin(allowedScrollablePanelWidgets.vendor, tab)) {
                        const pluginTab = getTabPluginConfig(
                          allowedScrollablePanelWidgets.vendor,
                          tab,
                        )
                        return (
                          <TabPane tab={t(pluginTab.title)} key={pluginTab.plugin_code}>
                            <PluginContainer pluginCode={pluginTab.plugin_code} />
                          </TabPane>
                        )
                      }

                      return null
                    })
                  : null}
              </Tabs>
            </div>
          )
        }}
      </WidgetErrorHandler>
    </div>
  )
}

export default Vendor
