import { ApiService } from 'services/authApi/getPermissions'

export const postOrderCancellation: ApiService<
  {
    entityId: string
    orderId: string
    cancelReason: string
  },
  {}
> = (createClient, options) => {
  const {
    entityId,
    orderId,
    cancelReason,

    clientParams,
  } = options

  const uri = `OrdersAPI/v1/orders/${entityId}/${orderId}/cancellation?reason=${cancelReason}`

  return createClient({
    endpointName: 'postOrderCancellation',

    expectedResponseStatusCode: 200,
    ...clientParams,
  }).post(uri, null)
}
