import { DataContext } from 'contexts/data/DataContext'
import { useTranslation } from 'hooks/useTranslation'
import { useContext, useMemo } from 'react'

export const useWidgetLabelTranslator = () => {
  const { t } = useTranslation()
  const { order } = useContext(DataContext).dataState

  return useMemo(() => {
    return {
      translate: (labelTranslationKey: string) => {
        const totalItems = order?.order?.items?.length || 0
        const totalUpdates = order?.basket_updates?.length ?? 0

        return t(labelTranslationKey, {
          replace: { totalItems, totalUpdates },
          fallbackReplaceValue: '?',
        })
      },
    }
  }, [order, t])
}
