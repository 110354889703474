import React from 'react'
import { createUseStyles } from 'react-jss'
import { Flex, Text, Button } from 'shared'
import { CheckOutlined, InfoCircleFilled } from 'Icons'
import { Divider, Timeline } from 'antd'
import { gray } from 'theme'
import styles from './ChangeDropoffPinSuccess.styles'
import { useTranslation } from 'hooks/useTranslation'

const useStyles = createUseStyles(styles)

type MapsLocationPinChangeProps = {
  lat: string
  lng: string
  onDone: () => void
}
const RiderChangePinSuccess: React.FC<MapsLocationPinChangeProps> = ({ lat, lng, onDone }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <Flex flexDirection='column' rowGap='24px' py='24px' pl='24px'>
      <Timeline>
        <Timeline.Item
          dot={
            <Flex className={classes.greenCheckCircledIconContainer}>
              <CheckOutlined className={classes.greenCheckCircledIcon} />
            </Flex>
          }
        >
          <Flex columnGap='24px' ml='24px'>
            <Flex flexDirection='column' rowGap='8px'>
              <Text.Primary fontSize='24px' fontWeight='500' lineHeight='32px'>
                {t('widgets.change_dropoff_pin.success.heading')}
              </Text.Primary>
              <Text.Secondary fontSize='16px' lineHeight='24px'>
                {t('widgets.change_dropoff_pin.success.confirmation')}
              </Text.Secondary>
              <Text.Secondary fontSize='16px' lineHeight='24px'>
                {t('widgets.change_dropoff_pin.success.info')}
              </Text.Secondary>
              <Flex></Flex>
            </Flex>
          </Flex>
        </Timeline.Item>
        {/* Second row */}
        <Timeline.Item
          dot={
            <Flex>
              <CheckOutlined className={classes.checkIcon} />
            </Flex>
          }
        >
          <Flex flexDirection='column' rowGap='8px' ml='24px'>
            <Text.Primary fontSize='14px' fontWeight='400'>
              {t('widgets.change_dropoff_pin.updated_coordinates')}
            </Text.Primary>
            <Divider className={classes.dividerMargin} />
            <Flex columnGap='24px'>
              <Flex flexDirection='column'>
                <Flex>
                  <Text.Secondary>{t('widgets.change_dropoff_pin.latitude')}</Text.Secondary>
                </Flex>
                <Flex>{lat}</Flex>
              </Flex>
              <Flex flexDirection='column'>
                <Flex>
                  <Text.Secondary>{t('widgets.change_dropoff_pin.longitude')}</Text.Secondary>
                </Flex>
                <Flex>{lng}</Flex>
              </Flex>
            </Flex>
          </Flex>
        </Timeline.Item>
      </Timeline>

      <Flex b={`1px solid ${gray.gray4}`} ml='48px' px='12px' py='8px' borderRadius='6px'>
        <Text.Secondary>
          <InfoCircleFilled className={classes.infoIcon} />
        </Text.Secondary>
        <Text.Secondary>{t('widgets.change_dropoff_pin.info')}</Text.Secondary>
      </Flex>

      <Flex justifyContent='flex-end'>
        <Button type='solid' onClick={onDone}>
          {t('Interface.Done')}
        </Button>
      </Flex>
    </Flex>
  )
}

export default RiderChangePinSuccess
