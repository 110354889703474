import { OrderHistoryItem } from 'types/api/orderApi/order'
import { orderStages } from 'types/unitedUiConfig'
import { OrderStatuses } from 'types/widgets/order/orderStatuses'
import { getLastValue } from '../array'

export const getOrderStage = (history: OrderHistoryItem[]): orderStages => {
  const { delivered, pickedUp, accepted } = OrderStatuses
  const { delivery, postDelivery, preparation, confirmation } = orderStages

  if (!history?.length) {
    return confirmation
  }

  const statuses = history.map((event) => event.status)
  const lastStatus = getLastValue(statuses)

  if (statuses.includes(delivered) && ![pickedUp, accepted].includes(lastStatus)) {
    return postDelivery
  } else if (statuses.includes(pickedUp) && ![delivered, accepted].includes(lastStatus)) {
    return delivery
  } else if (statuses.includes(accepted) && ![delivered, pickedUp].includes(lastStatus)) {
    return preparation
  } else {
    return confirmation
  }
}
