import React from 'react'
import { useCanDisplayDataPoint } from 'hooks/useGetValidFeatures'
import { DataPointWithBetaRequirementConfig } from 'contexts/entity/types'
import { TransformedDataPoint } from 'types/dataTransformers/generic'
import PhoneNumber from '../PhoneNumber/PhoneNumber'
import { Text } from '../../shared/Text'
import { CopyButton } from '../../shared/CopyButton'
import { DataPoint } from '../../shared/DataPoint'
import { summaryWidgetFields } from 'entityConfig/dataPoints/customer'

type Props = {
  canCopy?: boolean
  onCopyClick?: () => void
  onDialClick?: () => void
  item: TransformedDataPoint
  config?: DataPointWithBetaRequirementConfig
}

const PhoneDataPointListItem = ({ canCopy, item, config, onCopyClick, onDialClick }: Props) => {
  const canDisplayDataPoint = useCanDisplayDataPoint()

  const canDisplay = canDisplayDataPoint(
    item.value,
    config || { displayRule: [], betaRequirement: [] },
  )

  if (!canDisplay) {
    return (
      <DataPoint
        type='default'
        label={item.label || item.key}
        value='-'
        name={summaryWidgetFields.phoneNumber}
      />
    )
  }

  const dataPointValue = (
    <Text.Primary>
      <PhoneNumber value={item.value} onDialClick={onDialClick} />
      {canCopy && <CopyButton text={item.value as string} onCopy={onCopyClick} />}
    </Text.Primary>
  )

  return (
    <DataPoint
      type='custom'
      label={item.label || item.key}
      render={dataPointValue}
      name={summaryWidgetFields.phoneNumber}
    />
  )
}

export default PhoneDataPointListItem
