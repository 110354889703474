import React from 'react'
import { createCustomSvgIcon } from 'factory/createCustomSvgIcon'

export const CashIcon = createCustomSvgIcon({
  viewBoxWidth: 36,
  viewBoxHeight: 36,
  content: (
    <>
      <g clipPath='url(#clip0_168026_173787)'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M2.9 28.6V7.4H33.1V28.6H2.9ZM0 5.6765C0 5.02674 0.526737 4.5 1.1765 4.5H34.8235C35.4733 4.5 36 5.02674 36 5.6765V30.3235C36 30.9733 35.4733 31.5 34.8235 31.5H1.1765C0.526739 31.5 0 30.9733 0 30.3235V5.6765ZM22.1336 18.0179C22.1336 20.2911 20.2908 22.1339 18.0176 22.1339C15.7444 22.1339 13.9016 20.2911 13.9016 18.0179C13.9016 15.7447 15.7444 13.9019 18.0176 13.9019C20.2908 13.9019 22.1336 15.7447 22.1336 18.0179ZM24.4336 18.0179C24.4336 21.5614 21.561 24.4339 18.0176 24.4339C14.4741 24.4339 11.6016 21.5614 11.6016 18.0179C11.6016 14.4745 14.4741 11.6019 18.0176 11.6019C21.561 11.6019 24.4336 14.4745 24.4336 18.0179Z'
        />
      </g>
      <defs>
        <clipPath id='clip0_168026_173787'>
          <rect width='100%' height='100%' fill='white' />
        </clipPath>
      </defs>
    </>
  ),
})
