import React, { MouseEventHandler } from 'react'
import type { AccountBookFilled as AntdIcon } from '@ant-design/icons'
import { CustomIcon, CustomIconProps } from 'factory/createCustomSvgIcon'
import { createDeclarativeUseStyle } from 'factory/createDeclarativeUseStyle'
import classNames from 'classnames'
import { UnifiedIconName, useIconPacks } from 'contexts/IconPacksProvider'

export interface UnifiedIconStyleProps {
  m?: string | number
  ml?: string | number
  mr?: string | number
  mt?: string | number
  mb?: string | number
}

const useStyles = createDeclarativeUseStyle<keyof UnifiedIconStyleProps>([
  {
    key: 'm',
    properties: 'margin',
  },
  {
    key: 'mb',
    properties: 'marginBottom',
  },
  {
    key: 'ml',
    properties: 'marginLeft',
  },
  {
    key: 'mt',
    properties: 'marginTop',
  },
  {
    key: 'mr',
    properties: 'marginRight',
    important: true,
  },
])

export type UnifiedIconType = CustomIcon | UnifiedIconName | typeof AntdIcon

export interface UnifiedIconProps extends CustomIconProps, UnifiedIconStyleProps {
  icon: UnifiedIconType
  onClick?: MouseEventHandler
}

/**
 * unified icon unifies our custom icons with builtin antd icons.
 * our custom icons are created and defined inside src/Icons using the factory/createCustomIcon helper.
 *
 * this component accepts passing in just an icon name, and it will pick the icon accordingly,
 * and render it.
 *
 * this allows us to custom tab icons in united ui configuration
 * @returns
 */
export const UnifiedIcon = ({
  icon,
  color,
  size = 16,
  ml,
  m,
  mr,
  mb,
  mt,
  onClick,
  style,
  className: additionalClassName,
}: UnifiedIconProps) => {
  const { className } = useStyles({ m, mb, ml, mr, mt })
  const Icons = useIconPacks()

  if (!icon) {
    return null
  }

  let Icon: CustomIcon

  // rendering of icon based on icon name
  if (typeof icon === 'string') {
    Icon = Icons?.[icon] || Icons.PlaceholderIcon
  } else {
    Icon = icon
  }

  // rendering of custom icon based on the icon
  if (Icon.isCustomIcon) {
    return (
      <span className={classNames('anticon', className, additionalClassName)}>
        <Icon size={size} color={color} onClick={onClick} style={style} />
      </span>
    )
  }

  return (
    <Icon
      style={{ fontSize: size, color, ...style }}
      className={classNames(className, additionalClassName)}
      onClick={onClick}
    />
  )
}
