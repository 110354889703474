export default {
  containerTable: {
    width: '100%',
    maxWidth: '100%',
    '& > thead > tr': {
      height: '60px',
    },
    '& > tbody > tr': {
      height: '60px',
      borderBottom: '1px solid #D9D9D9',
    },
    '&  table > tbody > tr:hover': {
      cursor: 'pointer',
    },
    '&  tr > td,&  tr > th': {
      padding: '9px 9px',
      background: 'transparent',
      fontSize: '12px',
    },
    '&  tr > th': {
      padding: '8px 9px',
      background: 'transparent',
      color: '#0000008c',
      border: 0,
      fontWeight: '400',
    },
    '&  tr > td:before,&  tr > th:before': {
      display: 'none',
    },
  },
  collapse: {
    backgroundColor: '#fff',
    '& .ant-collapse-item-disabled > .ant-collapse-header': {
      cursor: 'default',
    },
  },
  minOrder: {
    width: '70px',
    '& > div': {
      width: '80px',
    },
  },
  validDates: {
    width: '95px',
  },
  voucherValue: {
    width: '95px',
  },
  panelHeaderTitles: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#fafafa',
    },
  },
  panelHeaderTitlesHighlight: {
    cursor: 'pointer',
    backgroundColor: '#F6FFED',
    borderBottom: '1px solid #ededed',
  },
  applicable: {
    color: '#000000d9',
  },
  notApplicable: {
    color: '#0000008c',
  },
}
