import React from 'react'
import { createUseStyles } from 'react-jss'
import { Collapse as AntCollapse } from 'antd'
import type { CollapsePanelProps as AntCollapsePanelProps } from 'antd'

import { gray } from 'theme'

export const useStylesPanel = createUseStyles({
  panelHeader: (props: CollapsePanelProps) => ({
    '& .ant-collapse-header': {
      background: props.bgColor ?? gray.gray3,
    },
  }),
})

export interface CollapsePanelProps extends AntCollapsePanelProps {
  bgColor?: string
}

export const Panel: React.FC<CollapsePanelProps> = (props) => {
  const classes = useStylesPanel(props)

  return <AntCollapse.Panel className={classes.panelHeader} {...props} />
}
