import environment from 'envConfig'
import { LineOfBusinessApiMap, LinesOfBusiness } from 'types/session/linesOfBusiness'
import { plugins as localPlugins } from 'plugins'
import { ApiService } from 'services/authApi/getPermissions'
import { AxiosResponse } from 'axios'
import { WidgetMetadata } from 'types'

export type GetPluginMetadataResponse = {
  data: {
    download_url: string
    author_email: string
    support_slack_channel: string
  }
  plugin_configuration: unknown
}

export const getPluginMetadata: ApiService<
  {
    pluginCode: string
    globalEntityId: string
    lineOfBusiness: LinesOfBusiness
  },
  WidgetMetadata<any>
> = (createClient, opts) => {
  const { pluginCode, globalEntityId, lineOfBusiness } = opts

  // this loads built plugin code in plugin dev mode
  if (environment().pluginDevelopment && localPlugins.has(pluginCode)) {
    const pluginDetails = localPlugins.get(pluginCode)
    return Promise.resolve({
      status: 200,
      data: {
        scriptUrl: `http://localhost:3001/local-plugin?codeName=${pluginCode}&${Date.now()}`,
        authorEmail: pluginDetails.manifest.authorEmail,
        supportSlackChannel: pluginDetails.manifest.slackChannel,
        config: pluginDetails.config,
      },
    } as AxiosResponse<WidgetMetadata<any>>)
  }

  return createClient({
    endpointName: 'getPluginForRender',
  })
    .get<GetPluginMetadataResponse>(
      `${environment().oneviewConfigApiRoot}/plugin/${pluginCode}/render/${
        LineOfBusinessApiMap[lineOfBusiness]
      }/${globalEntityId}`,
    )
    .then(({ data: { data, plugin_configuration }, ...others }) => ({
      ...others,
      data: {
        scriptUrl: data.download_url,
        authorEmail: data.author_email,
        supportSlackChannel: data.support_slack_channel,
        config: plugin_configuration,
      },
    }))
}
