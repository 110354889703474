import React from 'react'
import { Typography } from 'antd'
import { useTranslation } from 'react-i18next'

const { Text, Title, Paragraph } = Typography

export const WipeDataDescription = () => {
  const { t } = useTranslation()

  return (
    <>
      <Paragraph>
        <Text>{t('widgets.vendor_devices.reset.warning')}</Text>
      </Paragraph>

      <Title level={5}>{t('widgets.vendor_devices.when_to_do')}</Title>

      <Text>{t('widgets.vendor_devices.reset.description')}</Text>
    </>
  )
}

export const ReinstallDataDescription = () => {
  const { t } = useTranslation()

  return (
    <>
      <Paragraph>
        <Text>{t('widgets.vendor_devices.restart.warning')}</Text>
      </Paragraph>

      <Title level={5}>{t('widgets.vendor_devices.when_to_do')}</Title>
      <Text>{t('widgets.vendor_devices.restart.description')}</Text>
    </>
  )
}

export const RemoteControlDescription = () => {
  const { t } = useTranslation()

  return (
    <Paragraph>
      <Text>{t('widgets.vendor_devices.remote_control.warning')}</Text>
    </Paragraph>
  )
}

export const ResetDescription = () => {
  const { t } = useTranslation()

  return (
    <>
      <Paragraph>
        <Text>{t('widgets.vendor_devices.install.warning')}</Text>
      </Paragraph>

      <Title level={5}>{t('widgets.vendor_devices.when_to_do')}</Title>

      <Text>{t('widgets.vendor_devices.install.description')}</Text>
    </>
  )
}
