import { ItemsItem } from 'types/api/orderApi/order'

export const calculateOrderItemTotalPrice = (item: ItemsItem) => {
  const unit_price = item.unit_price || 0
  const quantity = item.quantity || 1

  const basePrice = unit_price * quantity

  const options = item.options || []

  return (
    basePrice +
    quantity *
      options.reduce((sum, item) => {
        return sum + calculateOrderItemTotalPrice(item as any as ItemsItem)
      }, 0)
  )
}

export const calculateOrderTotalPrice = (items: ItemsItem[]) => {
  return (items || []).reduce((sum, item) => {
    return sum + calculateOrderItemTotalPrice(item)
  }, 0)
}
