import { OrderApiResponse } from 'types/api/orderApi/order'
import { OrderStatuses } from 'types/widgets/order/orderStatuses'
import { POD_PIN_STATUS } from 'types/widgets/order/podPinStatus'

/**
 * Retrieve the order's POD PIN status.
 */
export const getPodPinStatus = (order: OrderApiResponse) => {
  const statusHistory = order?.status_history || []

  // if primary_delivery_pin_acknowledged does not exist in status history, POD PIN is not required
  if (
    !statusHistory.some(({ status }) => status === OrderStatuses.primaryDeliveryPinAcknowledged)
  ) {
    return POD_PIN_STATUS.notRequired
  }

  // if primary_delivery_pin_validated exists in status history, POD PIN is validated
  if (statusHistory.some(({ status }) => status === OrderStatuses.primaryDeliveryPinValidated)) {
    return POD_PIN_STATUS.validated
  }

  // else POD PIN is pending
  return POD_PIN_STATUS.pendingDelivery
}
