export default {
  container: {
    width: '100%',
  },
  contentContainer: {
    width: '100%',
    height: '400px',
    maxHeight: '400px',
    marginBottom: '20px',
    overflow: 'scroll',
  },
  // TITLES
  titlesRow: {
    display: 'flex',
    width: '100%',
    backgroundColor: '#fafafa',
    borderBottom: '1px solid #F0F0F0',
    padding: '10px 15px 10px 15px',
  },
  titlesRowDescriptionHolder: {
    width: '65%',
  },
  titlesRowPriceHolder: {
    width: '20%',
  },
  titlesRowQuantityHolder: {
    width: '15%',
  },
  titleTexts: {
    fontsize: '13px',
    color: 'rgba(0, 0, 0, 0.85)',
  },

  // ITEMS
  itemHolder: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    height: '40px',
    borderBottom: '1px solid #F0F0F0',
    padding: '5px 0px',
  },
  itemRowNameHolder: {
    display: 'flex',
    alignItems: 'baseline',
    width: '65%',
  },
  itemRowNameText: {
    fontSize: '12px',
    color: 'rgba(0, 0, 0, 0.85)',
    margin: '0px 7px 0px 7px',
  },
  itemRowPriceHolder: {
    width: '20%',
  },
  itemRowPriceText: {
    fontSize: '12px',
    color: 'rgba(0, 0, 0, 0.85)',
  },
  itemRowQuantityHolder: {
    width: '15%',
  },
  allCheckboxes: {
    fontSize: '12px',
    color: 'rgba(0, 0, 0, 0.85)',
  },

  // PARTIAL
  partialHolder: {
    display: 'flex',
    flexDirection: 'column',
    borderBottom: '1px solid #F0F0F0',
  },
  partialContainerWithoutInput: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    height: '40px',
    padding: '5px 15px 0px 15px',
  },
  partialRowNameHolder: {
    display: 'flex',
    flexDirection: 'column',
    width: '60%',
    marginLeft: '5%',
  },
  partialRowPriceHolder: {
    display: 'flex',
    flexDirection: 'column',
    width: '35%',
  },
  partialDescriptionTextForName: {
    fontSize: '10px',
    color: '#8C8C8C',
    marginLeft: '25px',
  },
  partialDescriptionTextForNameGrayedOut: {
    fontSize: '10px',
    color: 'rgba(0, 0, 0, 0.35)',
    marginLeft: '25px',
  },
  partialDescriptionTextForPrice: {
    fontSize: '10px',
    color: '#8C8C8C',
  },
  partialDescriptionTextForPriceGrayedOut: {
    fontSize: '10px',
    color: 'rgba(0, 0, 0, 0.35)',
  },
  partialDescriptionIcon: {
    marginRight: '3px',
  },
  partialRowPriceText: {
    fontSize: '12px',
    color: 'rgba(0, 0, 0, 0.85)',
  },
  partialDescriptionHolder: {
    display: 'flex',
    flexDirection: 'column',
    width: '57%',
    margin: '0px 0px 10px 8%',
  },
  descriptionText: {
    fontSize: '12px',
    color: 'rgba(0, 0, 0, 0.85)',
  },
  partialInputHolder: {
    display: 'flex',
  },
  partialDescriptionInput: {
    fontSize: '12px',
    width: '250px',
    marginRight: '5px',
  },
  partialInputAndRequiredholder: {
    display: 'flex',
    flexDirection: 'column',
  },
  requiredText: {
    fontSize: '10px',
    color: '#1890FF',
    marginTop: '3px',
  },
  partialCompletedDescriptionHolder: {
    display: 'flex',
    width: '57%',
    margin: '5px 0px 10px 8%',
  },
  editDescriptionIcon: {
    color: '#1890FF',
    marginLeft: '3px',
  },
  partialOkButton: {
    marginLeft: '5px',
    fontSize: '12px',
  },

  // TOPPINGS
  toppingHolder: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    height: '40px',
    borderBottom: '1px solid #F0F0F0',
    padding: '5px 15px 5px 15px',
  },
  toppingRowNameHolder: {
    width: '60%',
    marginLeft: '5%',
  },
  toppingRowPriceHolder: {
    width: '20%',
  },
  toppingRowQuantityHolder: {
    width: '15%',
  },
  toppingRowPriceText: {
    fontSize: '12px',
    color: 'rgba(0, 0, 0, 0.85)',
  },
  toppingRowPriceTextGrayedOut: {
    fontSize: '12px',
    color: 'rgba(0, 0, 0, 0.35)',
  },

  // TOTALS
  totalsHolder: {
    display: 'flex',
    width: '100%',
    borderTop: '1px solid #e6e6e6',
  },

  totalsCounter: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '48%',
    alignItems: 'center',

    '& > div': {
      display: 'flex',
      alignItems: 'center',
    },
  },
  totalsCounterTitle: {
    fontSize: '14px',
    color: 'rgba(0, 0, 0, 0.65)',
    marginRight: '3px',
  },
  totalsCounterValue: {
    fontSize: '24px',
    color: 'rgba(0, 0, 0, 0.65)',
  },
  totalItemsHolder: {
    display: 'flex',
    alignItems: 'center',
    width: '45%',
    marginLeft: '5%',
  },
  totalToppingsHolder: {
    display: 'flex',
    alignItems: 'center',
    width: '45%',
  },
  totalItemsAndToppingsText: {
    fontSize: '14px',
    color: 'rgba(0, 0, 0, 0.65)',
    marginRight: '3px',
  },
  totalItemsAndToppingsValue: {
    fontSize: '24px',
    color: 'rgba(0, 0, 0, 0.65)',
  },
  totalsSeparatorHolder: {
    width: '6%',
  },
  totalPriceHolder: {
    display: 'flex',
    alignItems: 'center',
    width: '45%',
  },
  // BUTTONS
  buttonsContainer: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    margin: '10px 0px 10px 0px',
  },
  backButton: {
    marginRight: '5px',
  },

  input: {
    maxWidth: '100%',
  },
}
