import { EntityConfig } from 'contexts/entity/types'
import environment from 'envConfig'
import { ApiService } from 'services/authApi/getPermissions'
import { LinesOfBusiness } from 'types/session/linesOfBusiness'
import { plugins as localPlugins } from 'plugins'
import { widgetTypes } from 'types'

const injectLocalPluginIntoConfig = async (config: EntityConfig) => {
  localPlugins.forEach((localPlugin) => {
    const { type, widget, title, codeName } = localPlugin.manifest

    switch (type) {
      case 'tab':
        const tabOrderItem = {
          feature: codeName,
          betaRequirement: [],
          active: true,
        }

        const tabConfigItem = {
          type: 'plugin',
          plugin_code: codeName,
          title: title,
        }

        config.scrollable_panel_config.widgets_configs[widget].tab_order.push(tabOrderItem)
        config.scrollable_panel_config.widgets_configs[widget].tab_configs[codeName] = tabConfigItem

        if (config.layout_v2) {
          const pluginTab = config.layout_v2.tabs_panel.tabs.find(
            (tab) => tab.tab_name === 'plugins',
          )
          if (pluginTab) {
            pluginTab.widgets.push({
              label: { label_translation_key: title },
              type: widgetTypes.plugin,
              plugin_code: codeName,
            })
          } else {
            config.layout_v2.tabs_panel.tabs.push({
              tab_name: 'plugins',
              label_translation_key: 'tab_labels.plugins',
              widgets: [
                {
                  label: { label_translation_key: title },
                  type: widgetTypes.plugin,
                  plugin_code: codeName,
                  collapse_state: 'expanded',
                },
              ],
            })
          }
        }
        break

      case 'action':
        const actionItem = {
          type: 'plugin',
          plugin_code: codeName,
          feature: title,
          betaRequirement: [],
        }

        config.fixed_panel_config.widgets_configs.actions.available_actions.push(actionItem)

        if (config.layout_v2) {
          config.layout_v2.resolution_bar.action_handlers.push({
            action: {
              button_color: 'primary',
              button_type: 'outlined',
              label_translation_key: title,
            },
            handler: {
              label: { label_translation_key: title },
              type: widgetTypes.plugin,
              plugin_code: codeName,
            },
          })
        }
        break
    }
  })

  return config
}

export const getEntityConfig: ApiService<
  {
    entityId: string
    lineOfBusiness?: LinesOfBusiness
  },
  EntityConfig
> = (createClient, options) => {
  const { entityId, clientParams, lineOfBusiness = LinesOfBusiness.customer } = options

  if (!entityId) {
    return Promise.resolve({} as any)
  }

  // customer service uri
  let uri = `${environment().oneviewConfigApiRoot}/config/${entityId}/customerservice/frontend`

  if (lineOfBusiness === LinesOfBusiness.rider) {
    uri = `${environment().oneviewConfigApiRoot}/config/${entityId}/riderservice/frontend`
  } else if (lineOfBusiness === LinesOfBusiness.vendor) {
    uri = `${environment().oneviewConfigApiRoot}/config/${entityId}/vendorservice/frontend`
  } else if (lineOfBusiness === LinesOfBusiness.riderV2) {
    uri = `${environment().oneviewConfigApiRoot}/config/${entityId}/riderservice_v2/frontend`
  }

  return createClient({
    endpointName: 'getEntityConfig',
    cacheable: true,
    ...clientParams,
  })
    .get<EntityConfig>(uri)
    .then(async (res) => {
      let config = res.data

      if (environment().pluginDevelopment) {
        config = await injectLocalPluginIntoConfig(config)
      }

      return {
        ...res,
        data: config,
      }
    })
}
