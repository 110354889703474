import { DataPointDefinition, DisplayRules } from 'types'
import { OrderBilling } from 'types/api/orderApi/orderBilling'
import fixNumber from 'utils/fixNumber'

type EarningsDataPoints =
  | 'basket_value_gross'
  | 'container_charges_gross'
  | 'delivery_fee_gross'
  | 'mov_surcharge_fee_gross'

type FeesDataPoints = 'payment_fee_net' | 'commission_amount_net' | 'tax_charge' | 'joker_fee_net'

type DiscountsDataPoints = 'total_discount' | 'platform_discount' | 'partner_discount'

type VendorPayoutDataPoint = EarningsDataPoints | FeesDataPoints | DiscountsDataPoints

interface ListItem {
  type: 'earning' | 'fee' | 'discount'
  name: VendorPayoutDataPoint
  label: string
  value: string
  display_rules: DisplayRules
}

type TransformAmountParams = {
  amount: number | string
  currency: string
  deductible?: boolean
}

function transformAmount({ amount, currency, deductible = false }: TransformAmountParams) {
  const value = Number(amount)
  const isNegative = (value > 0 && deductible) || value < 0 ? '-' : ''

  if (!value || isNaN(value)) return '-'

  return `${currency} ${isNegative}${fixNumber(Math.abs(value))}`
}

export function transformVendorPayoutDataPoints(
  billingOrder: OrderBilling,
  dataPoints: DataPointDefinition<VendorPayoutDataPoint>[],
) {
  const currency = billingOrder?.currency || ''

  const transformedDataPoints: ListItem[] = dataPoints?.map(
    ({ name, label_translation_key, display_rules }): ListItem | null => {
      switch (name) {
        // earnings
        case 'basket_value_gross':
          return {
            type: 'earning',
            name,
            display_rules,
            label: label_translation_key,
            value: transformAmount({
              currency,
              amount: billingOrder?.order_values.basket_value_gross,
            }),
          }
        case 'container_charges_gross':
          return {
            type: 'earning',
            name,
            display_rules,
            label: label_translation_key,
            value: transformAmount({
              currency,
              amount: billingOrder?.order_values.container_charges_gross,
            }),
          }
        case 'delivery_fee_gross':
          return {
            type: 'earning',
            name,
            display_rules,
            label: label_translation_key,
            value: transformAmount({
              currency,
              amount: billingOrder?.order_values.delivery_fee_gross,
            }),
          }
        case 'mov_surcharge_fee_gross':
          return {
            type: 'earning',
            name,
            display_rules,
            label: label_translation_key,
            value: transformAmount({
              currency,
              amount: billingOrder?.order_values.mov_surcharge_fee_gross,
            }),
          }

        // fees
        case 'payment_fee_net':
          return {
            type: 'fee',
            name,
            display_rules,
            label: label_translation_key,
            value: transformAmount({
              currency,
              amount: billingOrder?.revenue.payment_fee_net,
              deductible: true,
            }),
          }
        case 'commission_amount_net':
          return {
            type: 'fee',
            name,
            display_rules,
            label: label_translation_key,
            value: transformAmount({
              currency,
              amount: billingOrder?.revenue.commission_amount_net,
              deductible: true,
            }),
          }
        case 'tax_charge':
          return {
            type: 'fee',
            name,
            display_rules,
            label: label_translation_key,
            value: transformAmount({
              currency,
              amount: billingOrder?.revenue.tax_charge,
              deductible: true,
            }),
          }
        case 'joker_fee_net':
          return {
            type: 'fee',
            name,
            display_rules,
            label: label_translation_key,
            value: transformAmount({
              currency,
              amount: billingOrder?.revenue.joker_fee_net,
              deductible: true,
            }),
          }

        //discounts
        case 'total_discount':
          return {
            type: 'discount',
            name,
            display_rules,
            label: label_translation_key,
            value: transformAmount({
              currency,
              amount: billingOrder?.order_values.total_order_value_gross,
            }),
          }
        case 'platform_discount':
          return {
            type: 'discount',
            name,
            display_rules,
            label: label_translation_key,
            value: transformAmount({
              currency,
              amount: billingOrder?.order_values.incentive_value_gross_platform,
            }),
          }
        case 'partner_discount':
          return {
            type: 'discount',
            name,
            display_rules,
            label: label_translation_key,
            value: transformAmount({
              currency,
              amount: billingOrder?.order_values.incentive_value_gross_partner,
            }),
          }
        default:
          return null
      }
    },
  )

  const earnings = transformedDataPoints?.filter((dataPoint) => dataPoint?.type === 'earning')
  const fees = transformedDataPoints?.filter((dataPoint) => dataPoint?.type === 'fee')
  const discounts = transformedDataPoints?.filter((dataPoint) => dataPoint?.type === 'discount')

  return [earnings, fees, discounts]
}
