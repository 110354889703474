export type TabDataPointConfigs<Fields extends string = string> = {
  [p in Fields]: {
    /**
     * the config key for the datapoint
     */
    key: string

    /**
     * label translation key for the field
     */
    labelTranslationKey?: string
  }
}

/**
 * these are datapoint configuration keys of displayable fields in the order widget summary tab
 *
 * these keys exist in the summary tab config to configure if a field should be displayed or not
 */
export const orderSummaryTabDataPointConfigs: TabDataPointConfigs<
  | 'numberOfItems'
  | 'paidAmount'
  | 'paymentMethod'
  | 'payoutStatus'
  | 'orderSource'
  | 'currentStatus'
  | 'currentStatusReason'
  | 'timeOrderCreated'
  | 'promisedDeliveryTime'
  | 'deliveryAddress'
  | 'fullAddressTooltip'
  | 'deliveryInstructions'
  | 'joker'
  | 'corporate'
  | 'preOrder'
  | 'application'
  | 'deliveryProvider'
  | 'platform'
> = {
  numberOfItems: {
    key: 'number_of_items',
    labelTranslationKey: 'Order Widget.Tabs.Summary.Items',
  },
  paidAmount: {
    key: 'paid_amount',
    labelTranslationKey: 'Order Widget.Tabs.Summary.Paid Amount',
  },
  paymentMethod: {
    key: 'payment_method',
    labelTranslationKey: 'Order Widget.Tabs.Summary.Payment Method',
  },
  payoutStatus: {
    key: 'payout_status',
    labelTranslationKey: 'Order Widget.Tabs.Summary.Payout Status',
  },
  orderSource: {
    key: 'order_source',
    labelTranslationKey: 'Order Widget.Tabs.Summary.Order Source',
  },
  currentStatus: {
    key: 'current_status',
    labelTranslationKey: 'Order Widget.Tabs.Summary.Current Status',
  },
  currentStatusReason: {
    key: 'current_status_reason',
    labelTranslationKey: 'Order Widget.Tabs.Summary.Status Reason',
  },
  timeOrderCreated: {
    key: 'time_order_created',
    labelTranslationKey: 'Order Widget.Tabs.Summary.Time Order Created',
  },

  promisedDeliveryTime: {
    key: 'promised_delivery_time',
    labelTranslationKey: 'Order Widget.Tabs.Summary.Promised Delivery Time',
  },

  deliveryAddress: {
    key: 'delivery_address',
    labelTranslationKey: 'Order Widget.Tabs.Summary.Delivery Address',
  },

  fullAddressTooltip: {
    key: 'full_address_tooltip',
  },

  deliveryInstructions: {
    key: 'delivery_instructions',
    labelTranslationKey: 'Order Widget.Tabs.Summary.Delivery Instructions',
  },

  joker: {
    key: 'joker',
    labelTranslationKey: 'Order Widget.Tabs.Summary.Joker',
  },

  corporate: {
    key: 'corporate',
    labelTranslationKey: 'Order Widget.Tabs.Summary.Corporate',
  },
  preOrder: {
    key: 'preorder',
    labelTranslationKey: 'Order Widget.Tabs.Summary.Pre-Order',
  },
  application: {
    key: 'application',
    labelTranslationKey: 'Order Widget.Tabs.Summary.Application',
  },
  deliveryProvider: {
    key: 'delivery_provider',
    labelTranslationKey: 'Order Widget.Tabs.Summary.Delivery Provider',
  },
  platform: {
    key: 'platform',
    labelTranslationKey: 'Order Widget.Tabs.Summary.Platform',
  },
}
