// libs
import React from 'react'
// hooks
import { useTranslation } from 'hooks/useTranslation'
// utils
// styles
import { createUseStyles } from 'react-jss'
import styles from './DeliveryInstructions.styles'
import { Typography, Input, Collapse, Divider } from 'antd'
import { useCaptureUserAction } from 'hooks/events/useCaptureUserAction'

const useStyles = createUseStyles(styles)

interface Props {
  setIsDeliveryInstructionsInputActive: (arg: boolean) => void
  newDeliveryInstructions: string
  handleDeliveryInstructionsChange: (arg: any) => void
  shouldDisplayAccordion: boolean
}

const DeliveryInstructions: React.FC<Props> = ({
  setIsDeliveryInstructionsInputActive,
  newDeliveryInstructions,
  handleDeliveryInstructionsChange,
  shouldDisplayAccordion,
}) => {
  const classes = useStyles()
  const { Text } = Typography
  const { TextArea } = Input
  const { Panel } = Collapse

  // pull translations
  const { t } = useTranslation()
  const captureUserAction = useCaptureUserAction()

  const handleTextAreaClick = () => {
    captureUserAction('ActionsChangeAddressDeliveryInstructionsClicked')
    setIsDeliveryInstructionsInputActive(true)
  }

  return shouldDisplayAccordion ? (
    <Collapse className={classes.collapse} accordion={true} expandIconPosition='end' ghost>
      <Panel
        data-test-id='delivery-instructions-collapse'
        header={
          <Text className={classes.deliveryInstructionText}>
            {t('Actions Widget.Actions.Change Address.Delivery Instructions')}
          </Text>
        }
        key='1'
      >
        <Divider style={{ padding: 0, marginTop: -12 }} />
        <TextArea
          onClick={handleTextAreaClick}
          rows={4}
          value={newDeliveryInstructions}
          onChange={handleDeliveryInstructionsChange}
        />
      </Panel>
    </Collapse>
  ) : (
    <div className={classes.deliveryInstructionsHolder}>
      <Text className={classes.deliveryInstructionTextWithoutAccordion}>
        {t('Actions Widget.Actions.Change Address.Delivery Instructions')}
      </Text>

      <TextArea
        className={classes.textAreaWithoutAccordion}
        onClick={handleTextAreaClick}
        rows={4}
        value={newDeliveryInstructions}
        onChange={handleDeliveryInstructionsChange}
      />
    </div>
  )
}

export default DeliveryInstructions
