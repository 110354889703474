// libs
import React, { useContext, useState } from 'react'
// contexts and types
import { EntityContext } from 'contexts/entity/EntityContext'
import { DataContext } from 'contexts/data/DataContext'
// hooks
import { useTranslation } from 'hooks/useTranslation'
// utils
// styles
import { createUseStyles } from 'react-jss'
import styles from './Refund.styles'
import { Alert, Button, Divider, Menu, Typography } from 'antd'
import { DownOutlined, UpOutlined } from '@ant-design/icons'
// components
import LoadingView from 'components/LoadingView'
import { AvailableRefundMethod, ItemStructured } from 'types/actions/partialRefund'
import { SmartToolTip } from 'shared/SmartTooltip'
import { availableRefundMethods } from 'entityConfig/allowedConfigValues'
import { WALLET_OR_PSP_REFUND_METHODS } from 'constants/constants'
import { RefundIssueType } from 'contexts/entity/types'
import BankAccountRefundMethod from './BankAccountRefundMethod'
import PspWalletRefundMethod from './PspWalletRefundMethod'
import VoucherRefundMethod from './VoucherRefundMethod'
import AutoRefundMethod from './AutoRefundMethod'
import { useCaptureUserAction } from 'hooks/events/useCaptureUserAction'
import { InlineDropdown } from 'shared/InlineDropdown'

const useStyles = createUseStyles(styles)

interface Props {
  useTicketAsFallback: boolean
  customerPaymentMethod: string
  additionalNotes: string
  setAdditionalNotes: React.Dispatch<React.SetStateAction<string>>
  selectedIssueType: RefundIssueType
  selectedItems: ItemStructured[]
  partiallySelectedItems: ItemStructured[]
  refundMethods: AvailableRefundMethod[]

  selectedRefundMethod: AvailableRefundMethod | null
  setSelectedRefundMethod: (refundMethod: AvailableRefundMethod) => void

  isValidatingRefundMethod: boolean
  refundMethodValidationError: string

  handleRefundClick: () => void
  setCurrentStepIndex: (arg: number) => void
  totalRemovalAmount: number
  totalRefundAmount: number
  compensationAmount: number

  voucherBeginDate: string
  voucherEndDate: string
  voucherPaymentTypes: string
  voucherMinOrderValue: number

  doItemRemoval: boolean
  doMissingItemReport: boolean
}

const Refund: React.FC<Props> = ({
  customerPaymentMethod,
  additionalNotes,
  setAdditionalNotes,
  useTicketAsFallback,
  selectedIssueType,
  selectedItems,
  partiallySelectedItems,
  refundMethods,
  compensationAmount,
  selectedRefundMethod,
  setSelectedRefundMethod,
  handleRefundClick,
  setCurrentStepIndex,
  totalRemovalAmount,
  totalRefundAmount,
  voucherBeginDate,
  voucherEndDate,
  voucherPaymentTypes,
  voucherMinOrderValue,

  doItemRemoval,
  doMissingItemReport,

  isValidatingRefundMethod,
  refundMethodValidationError,
}) => {
  const classes = useStyles()
  const { Text } = Typography

  // pull translations
  const { t } = useTranslation()
  const captureUserAction = useCaptureUserAction()

  // pull actions config from context
  const {
    entityState: {
      entityConfig: {
        fixed_panel_config: {
          widgets_configs: {
            actions: {
              partial_refund: { refundAmountPrecision },
            },
          },
        },
      },
    },
  } = useContext(EntityContext)

  // pull order from data context
  const {
    dataState: { currency },
  } = useContext(DataContext)

  // fired when refund method selected in dropdown: sets the selected method and de-activates dropdown button
  const handleRefundMethodSelection = async (refundMethod: AvailableRefundMethod) => {
    //  set selected refund method and deactivate dropdown
    setSelectedRefundMethod(refundMethod)
    setIsDropdownButtonActive(false)
  }

  // fired when dropdown button is clicked -> activates the dropdown
  const [isDropdownButtonActive, setIsDropdownButtonActive] = useState(false)
  const handleDropdownButtonClick = (e: any) => {
    e.preventDefault()
    setIsDropdownButtonActive(!isDropdownButtonActive)
  }

  let disableButton = false
  let buttonText = t('Interface.Continue')
  let tooltipTitle = ''

  if (!selectedRefundMethod) {
    disableButton = true
    tooltipTitle = t(`Actions Widget.Actions.Partial Refund.Select a refund method to continue`)
  } else if (selectedRefundMethod.method === availableRefundMethods.bankAccount) {
    disableButton = !additionalNotes
    tooltipTitle = t(
      `Actions Widget.Actions.Partial Refund.Please provide bank account details to continue`,
    )
  } else if (
    selectedRefundMethod.method === availableRefundMethods.noRefund &&
    !doMissingItemReport &&
    !doItemRemoval
  ) {
    disableButton = true
    tooltipTitle = t(
      `Actions Widget.Actions.Partial Refund.There is no Report missing item to be triggered for this use-case and nothing to be removed from Backoffice`,
    )
  } else if (refundMethodValidationError) {
    disableButton = true
    buttonText = refundMethodValidationError
  }

  if (selectedRefundMethod && !useTicketAsFallback) {
    switch (selectedRefundMethod.method) {
      case availableRefundMethods.source:
      case availableRefundMethods.voucher:
      case availableRefundMethods.wallet:
        buttonText = t('Actions Widget.Actions.Partial Refund.Refund')
        break
      case availableRefundMethods.bankAccount:
      case availableRefundMethods.auto:
      case availableRefundMethods.noRefund:
        buttonText = t('Actions Widget.Actions.Partial Refund.Submit')
        break
    }
  } else if (selectedRefundMethod && useTicketAsFallback) {
    disableButton = false
    buttonText = t('Actions Widget.Actions.Partial Refund.Submit')
  }

  return (
    <div>
      {/* payment method -> always displayed */}
      <div className={classes.textBlock}>
        <Text className={classes.titleText}>
          {`${t('Actions Widget.Actions.Full Refund.Original Payment Method')}:`}
        </Text>
        <Text className={classes.dataText}>{customerPaymentMethod}</Text>
      </div>
      <Divider />

      {/* refund method selection through dropdown -> always displayed */}
      <div className={classes.textBlock}>
        <Text className={classes.titleText}>
          {`${t('Actions Widget.Actions.Full Refund.Refund Method')}:`}
        </Text>
        <InlineDropdown
          arrow={true}
          overlay={
            <Menu>
              {refundMethods.map((refundMethod, idx: number) => (
                <Menu.Item key={idx} onClick={() => handleRefundMethodSelection(refundMethod)}>
                  {t(
                    `Actions Widget.Actions.Partial Refund.Refund Methods.${refundMethod.translationKey}`,
                  )}
                </Menu.Item>
              ))}
            </Menu>
          }
          trigger={['click']}
        >
          <Button
            type='default'
            size='small'
            onClick={(e) => handleDropdownButtonClick(e)}
            className={classes.dropdownButton}
          >
            {t(
              selectedRefundMethod
                ? `Actions Widget.Actions.Partial Refund.Refund Methods.${selectedRefundMethod.translationKey}`
                : `Actions Widget.Actions.Partial Refund.Select a refund method`,
            )}
            {isDropdownButtonActive ? (
              <UpOutlined className={classes.dropdownIcon} />
            ) : (
              <DownOutlined className={classes.dropdownIcon} />
            )}
          </Button>
        </InlineDropdown>
      </div>

      <Divider />

      {/* Loading spinner */}
      {isValidatingRefundMethod && (
        <LoadingView text={`${t('Interface.Validating refund method')}...`} />
      )}

      {/* api error */}
      {!isValidatingRefundMethod && refundMethodValidationError && (
        <Alert message={refundMethodValidationError} type='warning' showIcon />
      )}

      {useTicketAsFallback && (
        <BankAccountRefundMethod
          currency={currency}
          totalRefundAmount={totalRefundAmount}
          totalRemovalAmount={totalRemovalAmount}
          compensationAmount={compensationAmount}
          refundAmountPrecision={refundAmountPrecision}
          voucherMinOrderValue={voucherMinOrderValue}
          voucherPaymentTypes={voucherPaymentTypes}
          voucherBeginDate={voucherBeginDate}
          voucherEndDate={voucherEndDate}
          selectedItems={selectedItems}
          partiallySelectedItems={partiallySelectedItems}
          additionalNotes={additionalNotes}
          selectedIssueType={selectedIssueType}
          setAdditionalNotes={setAdditionalNotes}
        />
      )}

      {!isValidatingRefundMethod && !refundMethodValidationError ? (
        <div>
          {/* wallet or source */}
          {WALLET_OR_PSP_REFUND_METHODS.includes(selectedRefundMethod?.method) && (
            <PspWalletRefundMethod
              currency={currency}
              useFlexibleAmount={selectedIssueType?.use_flexible_amount}
              totalRefundAmount={totalRefundAmount}
              totalRemovalAmount={totalRemovalAmount}
              compensationAmount={compensationAmount}
              refundAmountPrecision={refundAmountPrecision}
              voucherMinOrderValue={voucherMinOrderValue}
              voucherPaymentTypes={voucherPaymentTypes}
              voucherBeginDate={voucherBeginDate}
              voucherEndDate={voucherEndDate}
            />
          )}

          {/* voucher */}
          {selectedRefundMethod?.method === availableRefundMethods.voucher && (
            <VoucherRefundMethod
              currency={currency}
              useFlexibleAmount={selectedIssueType?.use_flexible_amount}
              totalRefundAmount={totalRefundAmount}
              totalRemovalAmount={totalRemovalAmount}
              compensationAmount={compensationAmount}
              refundAmountPrecision={refundAmountPrecision}
              voucherMinOrderValue={voucherMinOrderValue}
              voucherPaymentTypes={voucherPaymentTypes}
              voucherBeginDate={voucherBeginDate}
              voucherEndDate={voucherEndDate}
            />
          )}

          {/* bank account */}
          {selectedRefundMethod?.method === availableRefundMethods.bankAccount && (
            <BankAccountRefundMethod
              currency={currency}
              totalRefundAmount={totalRefundAmount}
              totalRemovalAmount={totalRemovalAmount}
              compensationAmount={compensationAmount}
              refundAmountPrecision={refundAmountPrecision}
              voucherMinOrderValue={voucherMinOrderValue}
              voucherPaymentTypes={voucherPaymentTypes}
              voucherBeginDate={voucherBeginDate}
              voucherEndDate={voucherEndDate}
              selectedItems={selectedItems}
              partiallySelectedItems={partiallySelectedItems}
              additionalNotes={additionalNotes}
              selectedIssueType={selectedIssueType}
              setAdditionalNotes={setAdditionalNotes}
            />
          )}

          {/* auto */}
          {selectedRefundMethod && selectedRefundMethod?.method === availableRefundMethods.auto && (
            <AutoRefundMethod
              currency={currency}
              useFlexibleAmount={selectedIssueType?.use_flexible_amount}
              totalRefundAmount={totalRefundAmount}
              totalRemovalAmount={totalRemovalAmount}
              refundAmountPrecision={refundAmountPrecision}
            />
          )}

          {selectedRefundMethod && doItemRemoval && (
            <div className={classes.alertContainer}>
              <Alert
                message={`${t(
                  'Messages.Selected items and toppings will be removed from the order',
                )}!`}
                type='info'
                showIcon
              />
            </div>
          )}
        </div>
      ) : null}

      {/* action buttons */}
      {!isValidatingRefundMethod && (
        <div className={classes.buttonsContainer}>
          <Button
            type='default'
            onClick={() => {
              captureUserAction('ActionsPartialRefundBackButtonClicked')
              setCurrentStepIndex(1)
            }}
            className={classes.backButton}
          >
            {t('Interface.Go Back')}
          </Button>

          <SmartToolTip title={tooltipTitle}>
            <Button type='primary' onClick={handleRefundClick} disabled={disableButton}>
              {buttonText}
            </Button>
          </SmartToolTip>
        </div>
      )}
    </div>
  )
}

export default Refund
