import React from 'react'
import { createUseStyles } from 'react-jss'

import { SingleComment } from 'types/api/commentApi/getOrderComments'
import { useTranslation } from 'hooks/useTranslation'
import { commentTagType, CommentView } from 'components/Comments/CommentView'
import styles from './Comments.styles'
import { WidgetSize } from 'types/widgets/historyOfEvents/tabs'
import { Typography } from 'antd'

const { Text } = Typography

const useStyles = createUseStyles(styles)

type Props = {
  comment: SingleComment
  shouldCommentBeHighlighted?: boolean
  mode: WidgetSize
}

export const StartRiderBreakComment = ({ comment, shouldCommentBeHighlighted, mode }: Props) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const additionalData = comment.additional_data?.start_rider_break
  const hours = Math.floor(additionalData?.duration / 60)
  const mins = additionalData?.duration % 60

  return (
    <CommentView
      mode={mode}
      comment={comment}
      shouldCommentBeHighlighted={shouldCommentBeHighlighted}
      tag={{
        type: commentTagType.thunderbolt,
        text: t('History of Events Widget.Rider break'),
      }}
    >
      <Text className={classes.commentText}>
        {`${t('History of Events Widget.Rider has been')} `}
        <Text strong>
          {additionalData?.type === 'break' && `${t('History of Events Widget.put on break')} `}
          {additionalData?.type === 'tmp' &&
            `${t('History of Events Widget.put on temporary break')} `}
        </Text>
        {`${t('History of Events Widget.for')} `}
        <Text strong>
          {hours > 0 && `${hours}h `}
          {mins > 0 && `${mins}m `}
        </Text>
        {`${t('History of Events Widget.due to')} `}
        <Text strong>{additionalData?.reason}</Text>
        {additionalData?.comment && (
          <>
            {` ${t('History of Events Widget.Agent comment')} `}
            <Text strong>“{additionalData?.comment}”</Text>
          </>
        )}
      </Text>
    </CommentView>
  )
}
