import { primary } from 'theme'

export default {
  infoIcon: {
    color: primary.primary6,
  },
  popoverContent: {
    maxWidth: '269px',
  },
  popover: {
    '& .ant-popover-title, .ant-popover-inner-content': {
      padding: '8px',
    },
    '& .ant-popover-inner-content': {
      paddingBottom: '12px',
    },
  },
  textStatusInfo: {
    display: 'block',
    marginBottom: '4px',
    fontWeight: 500,
  },
  fontWeight500: {
    fontWeight: 500,
  },
  paragraph: {
    '&.ant-typography': {
      marginBottom: '12px',
    },
  },
  button: {
    padding: 0,
    height: 'auto',
  },
}
