import React from 'react'
import { Tooltip } from 'antd'
import { ExclamationOutlined, PhoneOutlined, SyncOutlined } from '@ant-design/icons'
import { logError } from 'utils/reporting/logError'
import { useTranslation } from 'hooks/useTranslation'

type Props = {
  requirement: string
}

const ItemRequirementIcon = ({ requirement }: Props) => {
  const { t } = useTranslation()

  if (!requirement) {
    return null
  }

  switch (requirement) {
    case 'optional':
      return (
        <Tooltip title={t('Order Widget.Tabs.Items.Optional item')}>
          <SyncOutlined data-testid='optional-item-icon' />
        </Tooltip>
      )
    case 'mandatory':
      return (
        <Tooltip title={t('Order Widget.Tabs.Items.Mandatory item')}>
          <ExclamationOutlined data-testid='mandatory-item-icon' />
        </Tooltip>
      )
    case 'callback':
      return (
        <Tooltip title={t('Order Widget.Tabs.Items.Call me item')}>
          <PhoneOutlined data-testid='call-me-item-icon' />
        </Tooltip>
      )
    default:
      logError({
        type: 'unknown-item-requirement',
        requirement,
      })
      return null
  }
}

export default ItemRequirementIcon
