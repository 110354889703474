import { useRef } from 'react'

/**
 * this always makes sure that the ref current is upto date
 * @param arg
 */
export const useUpdatedRef = <T>(arg: T) => {
  const ref = useRef(arg)
  ref.current = arg
  return ref
}
