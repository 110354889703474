/**
 *  Custom EFFECT
 *  checks if the passed data point config satisfies both beta permission and data point availability requirements
 * */

// libs
import { useState, useEffect } from 'react'
// context and types
import { DataPointWithBetaRequirementConfig } from 'contexts/entity/types'
// utils
import checkDataPointAvailability from 'utils/checkDataPointAvailability'
import { useIsDataPointValid } from './useGetValidFeatures'

export default function useDataPointAvailabilityCheck(
  dataPoint: DataPointWithBetaRequirementConfig,
  isDataReceived: boolean,
  deps: React.DependencyList = [],
) {
  const [shouldDisplayDataPoint, setShouldDisplayDataPoint] = useState(false)
  const isDataPointValid = useIsDataPointValid()

  useEffect(() => {
    if (dataPoint?.betaRequirement && dataPoint?.displayRule) {
      const isAllowedInTermsOfBetaPerm = isDataPointValid(dataPoint.betaRequirement)

      if (isAllowedInTermsOfBetaPerm) {
        const isAllowedInTermsOfDataConfig = checkDataPointAvailability(
          dataPoint?.displayRule,
          isDataReceived,
        )
        setShouldDisplayDataPoint(isAllowedInTermsOfDataConfig)
      }
    }
    // eslint-disable-next-line
  }, [isDataPointValid, ...deps])

  return shouldDisplayDataPoint
}
