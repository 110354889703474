import { ApiService } from 'services/authApi/getPermissions'

export type VendorDeviceList = {
  lpvId: string
  deviceStatuses: Record<string, DeviceStatus>
  ignoredDevices: unknown
}

export type DeviceStatus = {
  mdmDeviceId: string
  lastCheckIn: string
  applicationPath: string
  status?: 'ONLINE' | 'OFFLINE'
  battery?: number
  networkInfo?: DeviceNetworkInfo
  iccid?: string
}

export type DeviceNetworkInfo = {
  type?: string
  strength?: number
  carrier?: string
}

export const getVendorDevices: ApiService<
  { entityId: string; vendorId: string },
  VendorDeviceList
> = (createClient, options) => {
  const { entityId, clientParams, vendorId } = options
  const uri = `/VendorAPI/v1/vendors/${entityId}/${vendorId}/devices`

  return createClient({
    endpointName: 'getVendorDevices',
    expectedResponseStatusCode: 200,
    ...clientParams,
  }).get(uri)
}
