import React from 'react'
import { useTranslation } from 'hooks/useTranslation'
import { Alert, Col, Divider, Row, Typography, Input } from 'antd'
import styles from './Refund.styles'
import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles(styles)

type Props = {
  additionalNotes: string
  setAdditionalNotes: React.Dispatch<React.SetStateAction<string>>
}

const BankAccountRefund = ({ additionalNotes, setAdditionalNotes }: Props) => {
  const { t } = useTranslation()
  const { Text } = Typography

  const classes = useStyles()

  return (
    <React.Fragment>
      <Divider />
      <div className={classes.alertContainer}>
        <Alert
          message={`${t(
            'Messages.All information above is automatically added to refund case with order ID and customer ID',
          )}`}
          type='info'
          showIcon
        />
      </div>
      <Row gutter={2}>
        <Col xs={6}>
          <Text className={classes.field}>{`${t(
            'Widgets Common.Additional notes to refund team',
          )}:`}</Text>
        </Col>
        <Col xs={18}>
          <Input.TextArea
            rows={3}
            data-test-id='refund-additional-details'
            value={additionalNotes}
            onChange={(e) => setAdditionalNotes(e.target.value)}
            placeholder={t('Widgets Common.Bank account details')}
          />
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default BankAccountRefund
