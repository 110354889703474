/**
 * New widget to show stacked orders associated with a rider
 */
import { Skeleton } from 'antd'
import { useLoadLastOrders } from 'hooks/apiHooks/useLoadLastOrders'
import React, { useMemo } from 'react'
import { useTranslation } from 'hooks/useTranslation'
import { orderPropertyFilter, PluggableWidget } from 'types/herocare'
import { RiderStackedOrdersList } from './RiderStackedOrdersList/RiderStackedOrdersList'
import { MessageCard } from 'shared/MessageCard'
import problemImg from 'assets/problem.png'
import noEmailImg from 'assets/noEmail.png'
import { DEFAULT_PAGINATION_PAGE_SIZE } from 'constants/constants'
import { createPluggableWidget } from 'factory/createPluggableWidget'
import { useApiService } from 'hooks/useApiService'
import { getRider } from 'services/fulfillmentApi/getRider'

const RiderStackedOrders: PluggableWidget<{}> = createPluggableWidget(
  ({ riderId, globalEntityId, lob, order, ticket }) => {
    const { t } = useTranslation()

    /*
     * Get list of routes, then from routes extract order ids. Use this list of order ids as filter to orders collection api.
     */

    // Get rider, we are specifically interested in routes.
    const {
      data: riderData,
      loading: riderLoading,
      error: riderLoadingError,
      loadService: refreshRider,
    } = useApiService({
      service: getRider,
      params: {
        entityId: globalEntityId,
        riderId: String(riderId ?? ''),
      },
      shouldLoad: Boolean(String(riderId ?? '') && globalEntityId),
    })

    // Use the routes above to get list of orderId to be retrieved.
    const filters = useMemo(() => {
      const routes = riderData?.courier?.routes || []
      if (routes.length) {
        return {
          [orderPropertyFilter.OrderIds]: riderData?.courier?.routes
            .map(({ order_id }) => order_id)
            .join(','),
        }
      }
      // Send filter value as null to avoid making orders call ( that too for the whole collection! )
      return null
    }, [riderData?.courier?.routes])

    // Get orders from order collection api, using above ordersIds as filter.
    const {
      lastOrders,
      loading: ordersCollectionLoading,
      error: ordersCollectionError,
    } = useLoadLastOrders({
      entityId: globalEntityId,
      lob: lob,
      includeMetadata: false,
      filters,
    })

    /**
     * The UI part follows
     */

    // Show loading indicator, if there are network calls in transit.
    if (riderLoading || ordersCollectionLoading) {
      return <Skeleton active />
    }

    // Show error message, if either of state or order collection api fails
    if (riderLoadingError || ordersCollectionError) {
      return (
        <MessageCard
          title={t('widgets.rider_stacked_orders.messages.problem')}
          message={t('widgets.rider_stacked_orders.messages.problem_details')}
          imageURL={problemImg}
          onRefreshBtnClick={refreshRider} // back to square 1 of data retrival
        />
      )
    }

    // Show message if no orders are returned by order collection api
    if (lastOrders?.length === 0) {
      return (
        <MessageCard
          title={t('widgets.rider_stacked_orders.messages.no_active_order')}
          message={t('widgets.rider_stacked_orders.messages.no_active_order_details')}
          imageURL={noEmailImg}
          onRefreshBtnClick={refreshRider} // back to square 1 of data retrival
        />
      )
    }

    return (
      <RiderStackedOrdersList
        loadedOrderId={order?.order_id} //orderId associated with loaded order
        ticketOrderId={ticket?.metadata?.order_id} //orderId associated with loaded ticket
        orders={lastOrders ?? []}
        pageSize={DEFAULT_PAGINATION_PAGE_SIZE}
        title={t('widgets.rider_stacked_orders.active_orders')}
      />
    )
  },
  {
    category: 'data_lookup',
  },
)

export default RiderStackedOrders
