import { VoucherPaymentType } from 'contexts/entity/types'
import { ApiService } from 'services/authApi/getPermissions'

import { CreatedVoucherDetails } from 'types/actions/compensation'
import VoucherApiSingleVoucher from 'types/api/voucherApi/getSingleVoucher'
import { AgentPlatform } from 'types/session/platforms'

export type VoucherPurpose = 'compensate' | 'refund_compensate' | 'refund'

export const createVoucher: ApiService<
  {
    caseId: string
    entityId: string
    customerId: string
    orderId: string

    compensationPurpose?: string
    refundPurpose?: string

    ccrCode?: string
    platform?: string
    proposalEventId?: string
    compensationToken?: string

    includedCompensationValue?: number
    refundItems?: string[]
    removedItems?: any[]

    contactReason?: string

    withItemRemoval?: boolean

    voucherDetails: {
      type?: string
      purpose: VoucherPurpose
      channel: 'channel_customer_service'
      currency: string
      description: string
      value: number
      beginDate: string
      endDate: string
      paymentTypes: VoucherPaymentType[]
      minOrderValue: number
      maxOrderValue: number
    }
  },
  VoucherApiSingleVoucher
> = (createClient, options) => {
  const {
    entityId,
    orderId,
    customerId,
    caseId,
    platform,

    compensationPurpose,
    refundPurpose,

    includedCompensationValue,
    refundItems,
    removedItems,

    ccrCode,
    proposalEventId,
    compensationToken,
    contactReason,

    withItemRemoval = false,

    voucherDetails: {
      description,
      currency,
      purpose,
      value,

      channel,

      beginDate,
      endDate,

      minOrderValue,
      maxOrderValue,

      paymentTypes = [],

      type = 'amount',
    },
    clientParams = {},
  } = options

  const body: any = {
    globalEntityId: entityId,
    customerId: customerId,

    order_id: orderId,
    contact_id: caseId,

    compensation_purpose: compensationPurpose,
    refund_purpose: refundPurpose,

    included_compensated_value: includedCompensationValue,

    ccr_code: ccrCode,

    proposal_event_id: proposalEventId,

    proposal_compensation_token: compensationToken,

    refund_items: refundItems || [],
    removed_items: removedItems || [],

    contact_reason: contactReason,

    voucher: {
      constraints: [
        {
          type: CreatedVoucherDetails.openingHour,
          value: '00:00',
        },
        {
          type: CreatedVoucherDetails.closingHour,
          value: '23:59',
        },
      ] as any[],
      currency: currency,
      channel,
      description,
      value,
      purpose,
      type,
    },
  }

  if (withItemRemoval) {
    body.with_item_removal = true
  }

  // attach begin and end dates to the voucher constraints, if applicable
  if (beginDate && endDate) {
    body.voucher.constraints.unshift({
      type: CreatedVoucherDetails.startDate,
      value: beginDate,
    })
    body.voucher.constraints.unshift({
      type: CreatedVoucherDetails.expDate,
      value: endDate,
    })
  }

  if (platform !== AgentPlatform.peya) {
    // attach min order value
    if (minOrderValue) {
      body.voucher.constraints.push({
        type: CreatedVoucherDetails.minOrderValue,
        value: minOrderValue,
      })
    }

    // attach max order value
    if (maxOrderValue) {
      body.voucher.constraints.push({
        type: CreatedVoucherDetails.maxOrderValue,
        value: maxOrderValue,
      })
    }

    // attach payment types
    body.voucher.constraints.push({
      type: CreatedVoucherDetails.paymentTypes,
      value: paymentTypes.map(({ id, type }) => {
        return { id, name: type }
      }) as any,
    })
  }

  const uri = `VoucherAPI/v2/customer/${entityId}/${customerId}/wallet/voucher`

  return createClient({
    endpointName: 'createVoucher',
    expectedResponseStatusCode: 201,
    responseMustHaveData: true,

    context: 'Compensation',
    ...clientParams,
  }).post(uri, body)
}
