interface CCRWhitelist {
  ccrWhitelist?: string[]
}

export const filterByCcrWhitelist = <ItemType extends CCRWhitelist>(
  ccrEnabled: boolean,
  items: ItemType[],
  givenCCR: string,
): ItemType[] => {
  return items.filter(
    (item) => !ccrEnabled || !item.ccrWhitelist?.length || item.ccrWhitelist.includes(givenCCR),
  )
}
