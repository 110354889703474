import React from 'react'
import { createCustomSvgIcon } from 'factory/createCustomSvgIcon'

export const AppVersionIcon = createCustomSvgIcon({
  viewBoxWidth: 14,
  viewBoxHeight: 20,
  content: (
    <>
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M10.1592 5.44833C10.0035 5.38372 9.82425 5.41924 9.705 5.53833L9.3 5.94417C7.99127 4.87477 6.16619 4.70186 4.67992 5.50646C3.19365 6.31107 2.34063 7.93377 2.52057 9.61425C2.7005 11.2947 3.87772 12.7001 5.50065 13.1718C7.12358 13.6435 8.87068 13.0881 9.92333 11.7658C10.1184 11.5342 10.1724 11.2149 10.0645 10.932C9.95652 10.6491 9.70357 10.447 9.40377 10.4041C9.10398 10.3613 8.80455 10.4844 8.62167 10.7258C7.77449 11.8031 6.21954 12.0009 5.12956 11.1702C4.03958 10.3395 3.8181 8.78774 4.63217 7.68527C5.44623 6.5828 6.99445 6.33777 8.10917 7.135L7.205 8.03833C7.08552 8.15751 7.04973 8.33699 7.11435 8.49289C7.17897 8.64878 7.33124 8.7503 7.5 8.75H10C10.2301 8.75 10.4167 8.56345 10.4167 8.33333V5.83333C10.4166 5.66472 10.315 5.51274 10.1592 5.44833ZM2.5 0H10.8333C12.214 0 13.3333 1.11929 13.3333 2.5V17.5C13.3333 18.8807 12.214 20 10.8333 20H2.5C1.11929 20 0 18.8807 0 17.5V2.5C0 1.11929 1.11929 0 2.5 0ZM11.25 16.25C11.4801 16.25 11.6667 16.0635 11.6667 15.8333V2.91667C11.6667 2.68655 11.4801 2.5 11.25 2.5H2.08333C1.85321 2.5 1.66667 2.68655 1.66667 2.91667V15.8333C1.66667 16.0635 1.85321 16.25 2.08333 16.25H11.25Z'
      />
    </>
  ),
})
