import { FulfillmentApiResponse } from 'types/api/fulfillmentApi/fulfillment'
import modifyUTC from 'utils/modifyUTC'
import capitalizeFirstLetters from 'utils/capitalizeFirstLetters'

function transformFulfillment(fulfillment: FulfillmentApiResponse, utc_zone: string) {
  let lastUpdateOfFulfillment = ''
  if (fulfillment?.timestamp) {
    lastUpdateOfFulfillment = modifyUTC(fulfillment.timestamp, utc_zone)
  }

  let riderEventsTransformed = []
  const currentRiderLocation = {
    latitude: 0,
    longitude: 0,
  }
  const currentVendorLocation = {
    latitude: 0,
    longitude: 0,
  }
  const currentCustomerLocation = {
    latitude: 0,
    longitude: 0,
  }

  if (
    fulfillment?.deliveries &&
    fulfillment?.deliveries.length > 0 &&
    fulfillment?.deliveries[0].courier_events &&
    fulfillment?.deliveries[0].courier_events.length > 0
  ) {
    riderEventsTransformed = fulfillment.deliveries[0].courier_events.map(({ name, timestamp }) => {
      const timestampModified = modifyUTC(timestamp, utc_zone)
      const nameModified = capitalizeFirstLetters(name.replace(/_/g, ' '))

      return {
        status: nameModified,
        timestamp: timestampModified,
      }
    })
  }

  if (fulfillment?.deliveries && fulfillment.deliveries.length > 0) {
    const delivery = fulfillment.deliveries[0]

    // rider
    if (delivery?.courier?.locations && delivery.courier.locations.length > 0) {
      currentRiderLocation.latitude =
        delivery.courier.locations[delivery.courier.locations.length - 1].latitude
      currentRiderLocation.longitude =
        delivery.courier.locations[delivery.courier.locations.length - 1].longitude
    }

    // vendor
    if (delivery.pickup_location?.latitude && delivery.pickup_location?.longitude) {
      currentVendorLocation.latitude = delivery.pickup_location.latitude
      currentVendorLocation.longitude = delivery.pickup_location.longitude
    }

    // customer
    if (delivery.dropoff_location?.latitude && delivery.dropoff_location?.longitude) {
      currentCustomerLocation.latitude = delivery.dropoff_location.latitude
      currentCustomerLocation.longitude = delivery.dropoff_location.longitude
    }
  }

  const transformedFulfillment = {
    lastUpdateOfFulfillment,
    riderEvents: riderEventsTransformed,
    currentRiderLocation,
    currentVendorLocation,
    currentCustomerLocation,
  }

  return transformedFulfillment
}

export default transformFulfillment
