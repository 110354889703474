import { ItemStructured } from 'types/actions/partialRefund'

export type RestructureOrderItemEvent =
  | { type: 'toggle_item_selection' }
  | { type: 'parent_item_selected' }
  | { type: 'parent_item_deselected' }
  | { type: 'toggle_partial_item_selection' }
  | { type: 'parent_item_partially_selected' }
  | { type: 'parent_item_partially_deselected' }
  | { type: 'change_item_missing_quantity'; value: number }
  | { type: 'parent_item_missing_quantity_changed'; value: number }
  | { type: 'toggle_partial_item_description_input' }
  | { type: 'change_partial_item_description'; value: string }

export const restructureOrderItem = (
  item: ItemStructured,
  event: RestructureOrderItemEvent,
): ItemStructured => {
  let new_item_missing_quantity: number

  switch (event.type) {
    case 'toggle_item_selection':
      if (!item.selected) {
        return {
          ...item,
          selected: true,
          partially_selected: false,
          options: item.options.map((item) =>
            restructureOrderItem(item, {
              type: 'parent_item_selected',
            }),
          ),
        }
      } else {
        return {
          ...item,
          selected: false,
          options: item.options.map((item) =>
            restructureOrderItem(item, {
              type: 'parent_item_deselected',
            }),
          ),
        }
      }

    case 'parent_item_selected':
      return {
        ...item,
        parent_item_selected: true,
        parent_item_partially_selected: false,

        selected: false,
        partially_selected: false,

        missing_quantity: item.max_missing_quantity,
        options: item.options.map((item) =>
          restructureOrderItem(item, {
            type: 'parent_item_selected',
          }),
        ),
      }

    case 'parent_item_deselected':
      return {
        ...item,
        parent_item_selected: false,
        options: item.options.map((item) =>
          restructureOrderItem(item, {
            type: 'parent_item_deselected',
          }),
        ),
      }

    case 'toggle_partial_item_selection':
      if (!item.partially_selected) {
        return {
          ...item,
          selected: false,
          partially_selected: true,
          activate_partial_input: true,
          options: item.options.map((item) =>
            restructureOrderItem(item, {
              type: 'parent_item_partially_selected',
            }),
          ),
        }
      } else {
        return {
          ...item,
          partially_selected: false,
          activate_partial_input: false,
          options: item.options.map((item) =>
            restructureOrderItem(item, {
              type: 'parent_item_partially_deselected',
            }),
          ),
        }
      }

    case 'parent_item_partially_selected':
      return {
        ...item,
        parent_item_selected: false,
        parent_item_partially_selected: true,

        selected: false,
        partially_selected: false,

        missing_quantity: item.max_missing_quantity,
        options: item.options.map((item) =>
          restructureOrderItem(item, {
            type: 'parent_item_partially_selected',
          }),
        ),
      }

    case 'parent_item_partially_deselected':
      return {
        ...item,
        parent_item_partially_selected: false,
        options: item.options.map((item) =>
          restructureOrderItem(item, {
            type: 'parent_item_partially_deselected',
          }),
        ),
      }

    case 'change_item_missing_quantity':
      return {
        ...item,
        missing_quantity: event.value,
        options: item.options.map((item) =>
          restructureOrderItem(item, {
            type: 'parent_item_missing_quantity_changed',
            value: event.value,
          }),
        ),
      }

    case 'parent_item_missing_quantity_changed':
      new_item_missing_quantity = item.quantity * event.value
      return {
        ...item,
        max_missing_quantity: new_item_missing_quantity,
        missing_quantity: new_item_missing_quantity,
        options: item.options.map((item) => {
          return restructureOrderItem(item, {
            type: 'parent_item_missing_quantity_changed',
            value: new_item_missing_quantity,
          })
        }),
      }

    case 'toggle_partial_item_description_input':
      return {
        ...item,
        activate_partial_input: !item.activate_partial_input,
      }

    case 'change_partial_item_description':
      return {
        ...item,
        partial_description: event.value,
      }

    default:
      return item
  }
}
