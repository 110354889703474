// libs
import React, { useContext, useMemo } from 'react'
// contexts and types
import { EntityContext } from 'contexts/entity/EntityContext'
import { DataContext } from 'contexts/data/DataContext'
import { TransformedDataPoint } from 'types/dataTransformers/generic'
import { RiderV2Config } from 'contexts/entity/types'
// hooks
import { useTranslation } from 'hooks/useTranslation'
import useDataPointAvailabilityCheck from 'hooks/useDataPointAvailabilityCheck'
import { useNewDesign } from 'hooks/useNewDesign'
// utils
import captureUserAction from 'browserMonitoring/captureUserAction'
import transformRiderSummary from 'utils/dataTransformers/transformRiderV2Summary'
import listDataFieldReturnElement from 'utils/listDataFieldReturnElement'
import classnames from 'classnames'
import { unknownDataPoint } from 'utils/dataTransformers/constants'
import transformRider from 'utils/dataTransformers/transformRider'
// styles
import { createUseStyles } from 'react-jss'
import styles from './Summary.styles'
import { Button, List } from 'antd'
import { CopyOutlined } from '@ant-design/icons'
// components
import PhoneNumber from 'components/PhoneNumber/PhoneNumber'
import { Notification } from 'shared/Notification'
import { logCopyError } from 'utils/reporting/logCopyError'

const useStyles = createUseStyles(styles)

const Summary: React.FC = () => {
  const classes = useStyles()
  const { isNewDesignAllowed } = useNewDesign()
  // pull translations
  const { t } = useTranslation()

  // pull entity context
  const { entityState } = useContext(EntityContext)
  const summary = entityState.entityConfig.scrollable_panel_config.widgets_configs.rider.tab_configs
    .summary as RiderV2Config

  // pull vendor and rider state from data context
  const {
    dataState: { vendor, rider },
  } = useContext(DataContext)

  const ridertransformed = useMemo(() => {
    return transformRider(rider, entityState?.entityConfig?.utc_zone)
  }, [rider, entityState])

  const { leftColumn, rightColumn } = useMemo((): {
    leftColumn: TransformedDataPoint[]
    rightColumn: TransformedDataPoint[]
  } => {
    const { transformedLeftColumnData, transformedRightColumnData } = transformRiderSummary(
      { ...ridertransformed, vertical_type: vendor?.vertical_type },
      t,
    )
    return { leftColumn: transformedLeftColumnData, rightColumn: transformedRightColumnData }
  }, [ridertransformed, vendor, t])

  const handleCopyToClipboard = (item: any) => {
    // copy messages
    const success = () => {
      Notification.success({
        message: t('Widgets Common.Number Copied'),
      })
      captureUserAction('RiderSummaryMobileNumberCopyClicked', [])
    }

    const warning = () => {
      Notification.warning({
        message: t('Widgets Common.Nothing to Copy'),
      })
    }

    const error = () => {
      logCopyError()
      Notification.error({
        message: t('Widgets Common.Cannot Copy'),
      })
    }

    navigator.clipboard.writeText(item.value.toString()).then(
      () => (item.value.toString()?.length > 0 ? success() : warning()),
      () => error(),
    )
  }

  const isNameAllowed = useDataPointAvailabilityCheck(
    summary.riderName,
    ridertransformed?.name ? true : false,
    [entityState, ridertransformed],
  )

  const isStatusAllowed = useDataPointAvailabilityCheck(
    summary.riderStatus,
    ridertransformed?.status ? true : false,
    [entityState, ridertransformed],
  )

  const isContractTypeAllowed = useDataPointAvailabilityCheck(
    summary.riderContractType,
    ridertransformed?.contractType ? true : false,
    [entityState, ridertransformed],
  )

  const isCityAllowed = useDataPointAvailabilityCheck(
    summary.riderCity,
    ridertransformed?.city ? true : false,
    [entityState, ridertransformed],
  )

  const isVehicleAllowed = useDataPointAvailabilityCheck(
    summary.riderVehicle,
    ridertransformed?.vehicle ? true : false,
    [entityState, ridertransformed],
  )
  const isCurrentShiftAllowed = useDataPointAvailabilityCheck(
    summary.riderCurrentShift,
    ridertransformed?.currentShift ? true : false,
    [entityState, ridertransformed],
  )
  const isPhoneAllowed = useDataPointAvailabilityCheck(
    summary.riderPhone,
    ridertransformed?.phone ? true : false,
    [entityState, ridertransformed],
  )

  const isVerticalTypeAllowed = useDataPointAvailabilityCheck(
    summary.verticalType,
    vendor?.vertical_type ? true : false,
    [entityState, vendor],
  )

  const isCopyToClipboard = useDataPointAvailabilityCheck(summary.copy_to_clipboard, true, [
    entityState,
  ])

  return (
    <div className={classes.listsContainer}>
      <div className={classes.listHolder}>
        <List
          className={classnames({ [classes.list]: isNewDesignAllowed })}
          size='small'
          itemLayout='horizontal'
          dataSource={leftColumn}
          renderItem={(item: TransformedDataPoint) => {
            switch (item.key) {
              case t('Rider Widget.Rider Name'):
                if (isNameAllowed) {
                  return listDataFieldReturnElement(summary.riderName.displayRule, item)
                }
                return null

              case t('Rider Widget.Phone Number'):
                return isPhoneAllowed ? (
                  <List.Item>
                    <List.Item.Meta
                      title={
                        <React.Fragment>
                          <PhoneNumber
                            value={item.value}
                            onDialClick={() =>
                              captureUserAction('RiderWidgetRiderPhoneClicked', [])
                            }
                          />
                          {isCopyToClipboard && item.value !== unknownDataPoint ? (
                            <Button type='link' onClick={() => handleCopyToClipboard(item)}>
                              <CopyOutlined />
                              {t('Widgets Common.Copy')}
                            </Button>
                          ) : null}
                        </React.Fragment>
                      }
                      description={item.key}
                    />
                  </List.Item>
                ) : null

              case t('Rider Widget.Status'):
                if (isStatusAllowed) {
                  return listDataFieldReturnElement(summary.riderStatus.displayRule, item)
                }
                return null

              case t('Rider Widget.City'):
                if (isCityAllowed) {
                  return listDataFieldReturnElement(summary.riderCity.displayRule, item)
                }
                return null

              case t('Rider Widget.Contract Type'):
                if (isContractTypeAllowed) {
                  return listDataFieldReturnElement(summary.riderContractType.displayRule, item)
                }
                return null

              default:
                return listDataFieldReturnElement([], item)
            }
          }}
        />
      </div>
      <div className={classes.listHolder}>
        <List
          className={classnames({ [classes.list]: isNewDesignAllowed })}
          size='small'
          itemLayout='horizontal'
          dataSource={rightColumn}
          renderItem={(item: TransformedDataPoint) => {
            switch (item.key) {
              case t('Rider Widget.Vehicle Type'):
                if (isVehicleAllowed) {
                  return listDataFieldReturnElement(summary.riderVehicle.displayRule, item)
                }
                return null

              case t('Rider Widget.Current Shift'):
                if (isCurrentShiftAllowed) {
                  return listDataFieldReturnElement(summary.riderCurrentShift.displayRule, item)
                }
                return null

              case t('Rider Widget.Vertical Type'):
                if (isVerticalTypeAllowed) {
                  return listDataFieldReturnElement(summary.verticalType.displayRule, item)
                }
                return null

              default:
                return listDataFieldReturnElement([], item)
            }
          }}
        />
      </div>
    </div>
  )
}

export default Summary
