import { useEntityConfig } from './useEntityConfig'
import { useIsDataPointValid } from './useGetValidFeatures'

export function useNewDesign() {
  const { getWidgetConfig } = useEntityConfig()
  const isDataPointValid = useIsDataPointValid()

  const summary = getWidgetConfig('order')?.tab_configs.summary
  const isNewDesignAllowed = isDataPointValid(summary?.newDesign?.betaRequirement)

  return { isNewDesignAllowed }
}
