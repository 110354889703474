/**
 * runs checks against passed config array and returns appropriate react node
 * */

import React from 'react'
import { allowedDataPointValues } from '../entityConfig/allowedConfigValues'
import { List } from 'antd'
import { TransformedDataPoint } from 'types/dataTransformers/generic'
import { InfoCircleOutlined } from '@ant-design/icons'
import { t } from 'i18next'

// @expects: config array with strings and item with key & value
// @returns: a react node
function listDataFieldReturnElement(
  config: Array<string>,
  item: TransformedDataPoint,
  shouldColorItem?: boolean,
) {
  const description = item.label || item.key
  // if field is not shown in entity
  if (config.includes(allowedDataPointValues.never_display)) {
    return <React.Fragment />
  } else if (config.includes(allowedDataPointValues.display_when_data_found)) {
    // if field is available, check if data exists
    if (item.value) {
      // return item if data found
      return (
        <List.Item>
          <List.Item.Meta title={item.value} description={description} />
        </List.Item>
      )
    }
    return <React.Fragment />
  } else {
    // field is shown in entity and item always visible -> check if an anchor is needed
    if (item.key === 'Link to FE') {
      return (
        <List.Item>
          {item.value ? (
            <a href={item.value.toString()} target='_blank' rel='noopener noreferrer'>
              <List.Item.Meta title='Go to Vendor FE' description={description} />
            </a>
          ) : (
            <List.Item.Meta title='Vendor FE not available' description={description} />
          )}
        </List.Item>
      )
    } else if (item.key === 'Delivery Address') {
      return (
        <List.Item style={{ backgroundColor: shouldColorItem ? '#F6FFED' : '' }}>
          <List.Item.Meta
            title={
              <React.Fragment>
                {item.value || t('errors.no_data_available.title')}
                <InfoCircleOutlined style={{ marginLeft: '5px' }} />
              </React.Fragment>
            }
            description={item.label}
          />
        </List.Item>
      )
    }
    return (
      <List.Item style={{ backgroundColor: shouldColorItem ? '#F6FFED' : '' }}>
        <List.Item.Meta
          title={item.value || t('errors.no_data_available.title')}
          description={description}
        />
      </List.Item>
    )
  }
}

export default listDataFieldReturnElement
