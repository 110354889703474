import React from 'react'
import moment from 'moment'
import { TFunction } from 'react-i18next'
import { Space, Select, Row, Col } from 'antd'
import { createUseStyles } from 'react-jss'
import styles from './CloseVendorForm.styles'
import { DataPoint } from 'shared'
import { ClosingReason } from 'types/api/vendorApi/vendorAvailabilityStatus'
import { useSdk } from 'contexts/SdkProvider'
import { CLOSING_REASON } from 'constants/closingReason'

interface TimeOption {
  label: string
  value: string
  until?: string
}

interface ClosingReasonOption {
  value: ClosingReason
  label: string
}

interface GenerateOptionsParams {
  now?: moment.Moment
  timeGap?: number
  t: TFunction
}

export type CloseVendorState = {
  closedReason?: ClosingReason
  closedUntil?: string
}

interface CloseVendorFormProps {
  updateCloseVendorState: (newState: CloseVendorState) => void
}

const useStyles = createUseStyles(styles)

function generateOptions({ now = moment(), timeGap = 15, t }: GenerateOptionsParams) {
  const timeSlotsOptions: TimeOption[] = [
    {
      label: t('EOD'),
      value: 'EOD',
    },
  ]

  for (let hour = 0; hour <= 24; hour++) {
    for (let minute = 0; minute < 60; minute += timeGap) {
      if (!(hour === 0 && minute === 0)) {
        const formattedHour = hour.toString().padStart(2, '0')
        const formattedMinute = minute.toString().padStart(2, '0')
        const value = now.clone().add(hour, 'hours').add(minute, 'minutes').format()

        timeSlotsOptions.push({
          label: `${formattedHour}:${formattedMinute}`,
          value: value,
          until: `${t('Until')}: ${moment(value).format('DD.MM.YYYY, hh:mm a')}`,
        })
      }
    }
  }
  timeSlotsOptions.splice(-3)

  const closingReasonsOptions: ClosingReasonOption[] = Object.values(CLOSING_REASON).map(
    (reason) => ({
      label: t(reason),
      value: reason,
    }),
  )
  return { timeSlotsOptions, closingReasonsOptions }
}

export const CloseVendorForm = ({ updateCloseVendorState }: CloseVendorFormProps) => {
  const { t, captureUserAction } = useSdk()
  const { timeSlotsOptions, closingReasonsOptions } = generateOptions({ t })

  const classes = useStyles()

  return (
    <Space className={classes.container}>
      <Row gutter={8}>
        <Col span={12} className={classes.descriptionItem}>
          <DataPoint
            name='closing-duration'
            label={t('widgets.vendor_availability.closing_duration')}
            type='custom'
            render={
              <Select
                placeholder={t('closing_duration_format')}
                optionLabelProp='label'
                onSelect={(closedUntil) => {
                  updateCloseVendorState({ closedUntil })
                  captureUserAction('SELECT_CLOSING_DURATION', {
                    reportToEts: true,
                    eventDetails: {
                      switch_to: 'Close',
                    },
                  })
                }}
                onDropdownVisibleChange={(open) => {
                  if (open) {
                    captureUserAction('OPEN_DROPDOWN_CLOSING_DURATION', {
                      reportToEts: true,
                      eventDetails: {
                        switch_to: 'Close',
                      },
                    })
                  }
                }}
                dropdownMatchSelectWidth={true}
              >
                {timeSlotsOptions.map((option) => (
                  <Select.Option
                    key={option.value}
                    value={option.value}
                    label={
                      <div>
                        <div className={classes.customLabelDuration}>{option.label}</div>
                        {option.until && (
                          <div className={classes.customLabelAdditionalInfo}>{option.until}</div>
                        )}
                      </div>
                    }
                  >
                    {option.label}
                  </Select.Option>
                ))}
              </Select>
            }
          />
        </Col>
        <Col span={12} className={classes.descriptionItem}>
          <DataPoint
            name='closing-reason'
            label={t('widgets.vendor_availability.closing_reason')}
            type='custom'
            render={
              <Select
                showSearch
                placeholder={t('widgets.vendor_availability.please_select')}
                onChange={(closedReason) => {
                  updateCloseVendorState({ closedReason })
                  captureUserAction('SELECT_CLOSING_REASON', {
                    reportToEts: true,
                    eventDetails: {
                      switch_to: 'Close',
                    },
                  })
                }}
                onDropdownVisibleChange={(open) => {
                  if (open) {
                    captureUserAction('OPEN_DROPDOWN_CLOSING_REASON', {
                      reportToEts: true,
                      eventDetails: {
                        switch_to: 'Close',
                      },
                    })
                  }
                }}
                options={closingReasonsOptions}
              />
            }
          />
        </Col>
      </Row>
    </Space>
  )
}
