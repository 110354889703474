export default {
  resultHolder: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  buttonHolder: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
}
