import React from 'react'
import { createCustomSvgIcon } from 'factory/createCustomSvgIcon'

export const BikerIcon = createCustomSvgIcon({
  viewBoxWidth: 12,
  viewBoxHeight: 11,
  content: (
    <>
      <path
        d='M7.25 2.375C7.90584 2.375 8.4375 1.84334 8.4375 1.1875C8.4375 0.531662 7.90584 0 7.25 0C6.59416 0 6.0625 0.531662 6.0625 1.1875C6.0625 1.84334 6.59416 2.375 7.25 2.375Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M0 8.4375C0 7.19486 1.00736 6.1875 2.25 6.1875C3.49264 6.1875 4.5 7.19486 4.5 8.4375C4.5 9.68014 3.49264 10.6875 2.25 10.6875C1.00736 10.6875 0 9.68014 0 8.4375ZM1 8.4375C1 9.12786 1.55964 9.6875 2.25 9.6875C2.94036 9.6875 3.5 9.12786 3.5 8.4375C3.5 7.74714 2.94036 7.1875 2.25 7.1875C1.55964 7.1875 1 7.74714 1 8.4375Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M7.5 8.4375C7.5 7.19486 8.50736 6.1875 9.75 6.1875C10.9926 6.1875 12 7.19486 12 8.4375C12 9.68014 10.9926 10.6875 9.75 10.6875C8.50736 10.6875 7.5 9.68014 7.5 8.4375ZM8.5 8.4375C8.5 9.12786 9.05964 9.6875 9.75 9.6875C10.4404 9.6875 11 9.12786 11 8.4375C11 7.74714 10.4404 7.1875 9.75 7.1875C9.05964 7.1875 8.5 7.74714 8.5 8.4375Z'
        fill='currentColor'
      />
      <path
        d='M5.361 5.057L6.0865 4.2275C6.77349 4.6924 7.58449 4.9398 8.414 4.9375H8.75C9.16421 4.9375 9.5 4.60171 9.5 4.1875C9.5 3.77329 9.16421 3.4375 8.75 3.4375H8.414C7.70786 3.43563 7.03099 3.15514 6.5305 2.657C6.3868 2.50549 6.18343 2.42532 5.975 2.438C5.76748 2.44498 5.57214 2.53767 5.4355 2.694L3.6855 4.694C3.54359 4.85673 3.47869 5.07272 3.50741 5.28672C3.53614 5.50072 3.6557 5.69195 3.8355 5.8115L4.1675 6.0325C5.06125 6.63509 5.49714 7.72182 5.2675 8.775C5.20278 9.03905 5.28603 9.31745 5.48507 9.50263C5.68411 9.68782 5.96778 9.75079 6.22648 9.66722C6.48518 9.58365 6.67841 9.36663 6.7315 9.1C7.05736 7.60259 6.53024 6.04759 5.361 5.057Z'
        fill='currentColor'
      />
    </>
  ),
})
