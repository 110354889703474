import React, { useMemo } from 'react'
import { createUseStyles } from 'react-jss'
import styles from './PanelDetails.style'
import { Space, Table, Typography } from 'antd'
import { useTranslation } from 'hooks/useTranslation'
import classNames from 'classnames'
import { useNumberFormatter } from 'hooks/formatters/useNumberFormatter'
import { CopyButton } from 'shared/CopyButton'
import { ArrayValue } from 'types'
import padStart from 'lodash/padStart'
import { TransformedCustomerWalletTransaction } from 'hooks/dataTransformers/useTransformCustomerWalletTransactions'

export interface PanelHeaderProps {
  transaction: TransformedCustomerWalletTransaction
}

const useStyles = createUseStyles(styles)

export const PanelDetails = ({ transaction }: PanelHeaderProps) => {
  const cx = useStyles()

  const numberFormatter = useNumberFormatter()
  const { t } = useTranslation()

  const { columns } = useMemo(() => {
    const columns = [
      {
        title: 'Description',
        key: 'description',
        render: (_, paymentBreakdown: ArrayValue<typeof transaction['paymentBreakdown']>) => {
          return (
            <Typography.Text className={classNames(cx.textSubtitle, cx.ml8)}>
              {paymentBreakdown.description}
            </Typography.Text>
          )
        },
        width: '40%',
      },
      {
        title: 'Amount',
        key: 'amount',
        render: (_, paymentBreakdown: ArrayValue<typeof transaction['paymentBreakdown']>) => {
          return (
            <Typography.Text className={cx.textSubtitle}>
              {numberFormatter.formatMoney(paymentBreakdown.amount)}
            </Typography.Text>
          )
        },
      },
      {
        title: 'Card',
        key: 'card',
        render: (_, paymentBreakdown: ArrayValue<typeof transaction['paymentBreakdown']>) => {
          if (!paymentBreakdown.card?.scheme) {
            return null
          }
          const { card } = paymentBreakdown
          return (
            <Typography.Text className={cx.textSubtitle}>
              ***{card.maskedCardNumber}, {padStart(card.validToMonth.toString(10), 2, '0')}/
              {card.validToYear}
            </Typography.Text>
          )
        },
      },
    ]
    return { columns }
  }, [cx, numberFormatter])

  return (
    <div className={classNames(cx.container)}>
      {/* transaction id and details */}
      <div className={classNames(cx.header)}>
        <Typography.Text className={cx.textSubtitle}>
          {t('Customer Widget.Tabs.Wallet.Transaction details')}
        </Typography.Text>
        <Typography.Text type='secondary' className={classNames(cx.flex, cx.itemsCenter)}>
          <Space>
            <span>ID: {transaction.id}</span>
            <CopyButton text={transaction.id} />
          </Space>
        </Typography.Text>
      </div>

      <Table
        dataSource={transaction.paymentBreakdown}
        columns={columns}
        showHeader={false}
        pagination={false}
      />
    </div>
  )
}
