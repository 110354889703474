/**
 * Search Order modal, rendered upon click in actions
 * renders a modal with a choice to search by order and global entity ID
 * */

import React from 'react'
import { SearchOrderPanel } from 'App/SearchPanel/SearchOrderPanel'
import { LAST_ORDERS_WIDGET_MAX_WIDTH, LAST_ORDERS_WIDGET_WIDTH } from 'constants/constants'
import { createPluggableWidget } from 'factory/createPluggableWidget'

export const SearchOrderView = createPluggableWidget<{}>(
  ({ lob, order, globalEntityId, activeTab, ErrorRenderer }) => {
    return (
      <SearchOrderPanel
        lob={lob}
        defaultEntityId={globalEntityId}
        ErrorRenderer={ErrorRenderer}
        currentOrderId={order?.order_id}
        activeTab={activeTab}
      />
    )
  },
  {
    category: 'data_lookup',
    deriveInitialViewState() {
      return {
        width: LAST_ORDERS_WIDGET_WIDTH,
        maxWidth: LAST_ORDERS_WIDGET_MAX_WIDTH,
      }
    },
  },
)
