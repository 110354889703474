export default {
  textAndbuttonContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'baseline',
  },
  subMsg: {
    fontSize: '12px',
    marginBottom: '15px',
  },
  descContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: 'auto',
    maxHeight: '350px',
    overflow: 'scroll',
    padding: 10,
  },
  timeline: {
    marginTop: '25px',
  },
  boldText: {
    fontWeight: 600,
  },
  timeText: {
    fontSize: '12px',
    color: 'rgba(0, 0, 0, 0.45)',
  },
}
