import { useTranslation } from 'react-i18next'
import styles from './SingleChatTranscript.styles'
import { createUseStyles } from 'react-jss'
import React from 'react'
import { Button } from 'antd'
import { UnifiedIcon } from 'shared'
const useStyles = createUseStyles(styles)

interface SingleChatTranscriptHeaderParams {
  orderId: string
  roomTitle: string
  onBack: () => void
}
export const SingleChatHeader = ({
  orderId,
  roomTitle,
  onBack,
}: SingleChatTranscriptHeaderParams) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <div className={classes.headerContainer}>
      <Button
        icon={<UnifiedIcon icon='ArrowLeftOutlined' />}
        className={classes.headerBackIcon}
        onClick={onBack}
      >
        <span className={classes.bold}>{roomTitle}</span>
      </Button>
      <div>
        <span>{`${t('Order')}: ${orderId}`}</span>
      </div>
    </div>
  )
}
