import { Typography } from 'antd'
import React from 'react'
import { createUseStyles } from 'react-jss'

import { commentTagType, CommentView } from 'components/Comments/CommentView'
import { useTranslation } from 'hooks/useTranslation'
import { SingleComment } from 'types/api/commentApi/getOrderComments'
import { WidgetSize } from 'types/widgets/historyOfEvents/tabs'
import styles from './Comments.styles'

const { Text } = Typography

const useStyles = createUseStyles(styles)

type Props = {
  comment: SingleComment
  shouldCommentBeHighlighted?: boolean
  mode: WidgetSize
}

export const ChangeDeliveryStatusComment = ({
  comment,
  shouldCommentBeHighlighted,
  mode,
}: Props) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const additionalData = comment.additional_data?.delivery_status_update

  return (
    <CommentView
      mode={mode}
      comment={comment}
      shouldCommentBeHighlighted={shouldCommentBeHighlighted}
      tag={{
        type: commentTagType.thunderbolt,
        text: t('History of Events Widget.Event Types.Update status'),
      }}
    >
      <Text className={classes.commentText}>
        <Text>{`${t('History of Events Widget.Content.Order status was updated to')} `}</Text>
        <Text strong>{additionalData.to_status}</Text>
        <Text>{` ${t('History of Events Widget.due to')} `}</Text>
        <Text strong>{additionalData.reason}</Text>
      </Text>
    </CommentView>
  )
}
