import React, { useState, useContext, ReactNode } from 'react'

// Components
import { Typography, Popover, Button } from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons'

// Contexts
import { EntityContext } from 'contexts/entity/EntityContext'

// Utils
import { createUseStyles } from 'react-jss'
import modifyUTC from 'utils/modifyUTC'

// Hooks
import { useTranslation } from 'hooks/useTranslation'

// Relative Path
import styles from './PopoverInfoWidget.style'

const useStyles = createUseStyles(styles)

const { Text, Paragraph } = Typography

type PopoverInfoWidgetProps = {
  changesSince?: string
  details?: string
  title?: ReactNode
}

function PopoverInfoWidget({ details, changesSince, title }: PopoverInfoWidgetProps) {
  const classes = useStyles()
  const { t } = useTranslation()

  const [visible, setVisible] = useState(false)

  const handleVisibleChange = () => setVisible((prev) => !prev)

  const {
    entityState: {
      entityConfig: { utc_zone },
    },
  } = useContext(EntityContext)

  const content = (
    <div className={classes.popoverContent}>
      <Text className={`${classes.textStatusInfo} ${classes.fontWeight500}`}>{details || ''}</Text>
      {changesSince && (
        <Paragraph className={classes.paragraph}>
          <Text>{t('Interface.Status since')}: </Text>
          <Text className={classes.fontWeight500}>
            {modifyUTC(changesSince, utc_zone, 'D.M.YYYY, h:mm A')}
          </Text>
        </Paragraph>
      )}
      <div>
        <Button className={classes.button} type='link' onClick={handleVisibleChange}>
          {t('Interface.Close')}
        </Button>
      </div>
    </div>
  )

  return (
    <Popover
      overlayClassName={classes.popover}
      content={content}
      title={title}
      trigger='click'
      visible={visible}
      onVisibleChange={handleVisibleChange}
    >
      <InfoCircleOutlined className={classes.infoIcon} />
    </Popover>
  )
}

export default PopoverInfoWidget
