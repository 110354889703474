import React, { useState, useEffect } from 'react'
// types
import { OrderItemT } from 'types/widgets/order/orderItem'
// hooks
import { useTranslation } from 'hooks/useTranslation'
// styles
import { Input } from 'antd'

const ItemCookingInstructionInput: React.FC<{
  item: OrderItemT
  itemsWithInstructions: Array<OrderItemT>
  setItemsWithInstructions: (arg: Array<OrderItemT>) => void
}> = ({ item, itemsWithInstructions, setItemsWithInstructions }) => {
  const { TextArea } = Input

  // pull language content
  const { t } = useTranslation()

  const [itemCookingInst, setItemCookingInst] = useState('')
  useEffect(() => {
    if (item?.customer_notes) {
      setItemCookingInst(item.customer_notes)
    }
  }, [item])

  // fired when an input value changes
  const handleInstructionChange = (e) => {
    e.preventDefault()

    // set new value to local state
    setItemCookingInst(e.target.value)

    // check if current item is already in itemsWithInstructions
    const currentItemFound = itemsWithInstructions.find((i: OrderItemT) => i.id === item.id)

    // set current item to be added to itemsWithInstructions
    let currentItem: OrderItemT = null
    if (currentItemFound) {
      currentItemFound.customer_notes = e.target.value
      currentItem = currentItemFound
      // filter itemsWithInstructions if current item found & its value changed
      itemsWithInstructions = itemsWithInstructions.filter((i: OrderItemT) => i.id !== item.id)
    } else {
      currentItem = {
        ...item,
      }
      currentItem.customer_notes = e.target.value
    }

    // set item with new value to itemsWithInstructions
    setItemsWithInstructions([...itemsWithInstructions, currentItem])
  }

  return (
    <TextArea
      rows={2}
      autoSize={{ minRows: 2, maxRows: 4 }}
      defaultValue={
        item.customer_notes
          ? item.customer_notes
          : `${t('Actions Widget.Actions.Change Cooking Instructions.Cooking Instruction')}...`
      }
      value={itemCookingInst}
      onChange={(e) => handleInstructionChange(e)}
    />
  )
}

export default ItemCookingInstructionInput
