import React from 'react'
import fixNumber from 'utils/fixNumber'
import { Alert, Button, Result, Typography } from 'antd'
import { useTranslation } from 'hooks/useTranslation'
import { CopyOutlined } from '@ant-design/icons'
import captureUserAction from 'browserMonitoring/captureUserAction'
import { createUseStyles } from 'react-jss'
import styles from '../FullRefundModalView.styles'
import VoucherApiSingleVoucher from 'types/api/voucherApi/getSingleVoucher'

const Text = Typography
const useStyles = createUseStyles(styles)

type Props = {
  onClose: () => void
  currency: string
  voucherAmount: number
  copyVoucher: (voucher: VoucherApiSingleVoucher, currency: string) => void
  createdVoucher: VoucherApiSingleVoucher
}

const VoucherRefundResponse = ({
  onClose,
  copyVoucher,
  currency,
  voucherAmount,
  createdVoucher,
}: Props) => {
  const classes = useStyles()
  const { t } = useTranslation()
  return (
    <Result
      status={'success'}
      title={`${t('Widgets Common.Refund successfully')}!`}
      subTitle={
        <div className={classes.resultSubtextHolder}>
          <Text className={classes.subtitle}>
            {t('Actions Widget.Actions.Full Refund.Full refund')}
          </Text>
          <Text className={classes.resultVoucherSubtext}>
            {`${t('Actions Widget.Actions.Full Refund.Refunded')}: ${currency} ${fixNumber(
              voucherAmount,
            )}`}{' '}
            {t('Actions Widget.Actions.Full Refund.with voucher')}
          </Text>

          {createdVoucher?.code && (
            <div className={classes.successfulVoucherContainer}>
              <Text className={classes.resultVoucherCode}>
                {`${t('Widgets Common.Voucher Code')}: ${createdVoucher.code}`}
              </Text>

              <Text className={classes.sectionCopyText}>
                <Button type='link' onClick={() => copyVoucher(createdVoucher, currency)}>
                  {t('Actions Widget.Actions.Full Refund.Copy Voucher Info')}
                  <CopyOutlined />
                </Button>
              </Text>

              <Alert
                className={classes.successfulVoucherAlert}
                message={t(
                  'Actions Widget.Actions.Full Refund.The customer will receive the voucher immediately',
                )}
                type='info'
                showIcon
              />
            </div>
          )}
          <div className={classes.successButtonContainer}>
            <Button
              type='primary'
              onClick={() => {
                captureUserAction('ActionsFullRefundOkButtonClicked', [])
                onClose()
              }}
            >
              {t('Interface.OK')}
            </Button>
          </div>
        </div>
      }
    />
  )
}

export default VoucherRefundResponse
