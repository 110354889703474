/**
 * New widget to show rider's upcoming shifts
 */
import React, { useImperativeHandle } from 'react'
import { Skeleton } from 'antd'
import problemImg from 'assets/problem.png'
import noEmailImg from 'assets/noEmail.png'
import { createPluggableWidget } from 'factory/createPluggableWidget'
import { useTranformRiderShifts } from 'hooks/dataTransformers/useRiderShifts'
import { useEntityConfig } from 'hooks/useEntityConfig'
import { useTranslation } from 'hooks/useTranslation'
import { InfoCircleOutlined } from 'Icons'
import { Flex, MessageCard, Text } from 'shared'
import { gray } from 'theme'
import { PluggableWidget } from 'types/herocare'
import { useLoadRider } from 'hooks/apiHooks/useLoadRider'

const RiderUpcomingShifts: PluggableWidget<{}> = createPluggableWidget(
  ({ rider }, ref) => {
    const { t } = useTranslation()
    const {
      entityConfig: { utc_zone },
    } = useEntityConfig()

    const riderShiftsTransformed = useTranformRiderShifts({
      riderShifts: rider?.courier?.shifts || [],
      t,
      utcZone: utc_zone,
    })

    // Header refresh btn
    const {
      loading: riderLoading,
      error: riderLoadingError,
      loadService: refreshRider,
    } = useLoadRider()

    useImperativeHandle(
      ref,
      () => {
        return {
          onRefresh: () => {
            refreshRider()
          },
        }
      },
      [refreshRider],
    )

    /**
     * UI part
     */

    // Loading
    if (riderLoading) {
      return <Skeleton active />
    }

    // Error
    if (riderLoadingError) {
      return (
        <MessageCard
          size='min'
          title={t('widgets.rider_upcoming_shifts.error_title')}
          message={t('widgets.rider_upcoming_shifts.error_message')}
          imageURL={problemImg}
          onRefreshBtnClick={refreshRider} // re-fetch rider
        />
      )
    }

    // No rider shift
    if (riderShiftsTransformed.length === 0) {
      return (
        <MessageCard
          size='min'
          title={t('widgets.rider_upcoming_shifts.no_shifts_title')}
          message={t('widgets.rider_upcoming_shifts.no_shifts_message')}
          imageURL={noEmailImg}
        />
      )
    }

    return (
      <Flex rowGap={'8px'} flexDirection='column'>
        <Flex flexDirection='column' rowGap={'16px'}>
          {riderShiftsTransformed.map(({ id, reminder, start, end, zone }, index) => {
            return (
              <Flex
                flexDirection='column'
                rowGap={'4px'}
                pb={'16px'}
                key={id || index}
                bb={`1px solid ${gray.gray14}`}
              >
                {Boolean(reminder) && (
                  <Flex>
                    <Text.Secondary fontSize={14}>{reminder}</Text.Secondary>
                  </Flex>
                )}
                <Flex>
                  <Flex flexDirection='row' columnGap={'16px'} w={'250px'}>
                    <Flex flexDirection='column'>
                      <Flex>
                        <Text.Primary>{start.time}</Text.Primary>
                      </Flex>
                      <Flex>
                        <Text.Secondary>{start.date}</Text.Secondary>
                      </Flex>
                    </Flex>
                    <Flex alignItems={'flex-start'}>→</Flex>
                    <Flex flexDirection='column'>
                      <Flex>
                        <Text.Primary>{end.time}</Text.Primary>
                      </Flex>
                      <Flex>
                        <Text.Secondary>{end.date}</Text.Secondary>
                      </Flex>
                    </Flex>
                  </Flex>
                  <Flex>
                    <Flex>
                      <Text.Secondary fontSize={14} lineHeight={'22px'} pr={'2px'}>
                        {t('widgets.rider_upcoming_shifts.zone')}:
                      </Text.Secondary>
                    </Flex>
                    <Flex>
                      <Text.Primary>{zone}</Text.Primary>
                    </Flex>
                  </Flex>
                </Flex>
              </Flex>
            )
          })}
        </Flex>
        <Flex flexDirection='row' columnGap={'4px'} alignItems={'center'}>
          <Text.Secondary>
            <InfoCircleOutlined style={{ fontSize: '12px', padding: '4px' }} />
          </Text.Secondary>
          <Text.Secondary>
            {t('widgets.rider_upcoming_shifts.current_shifts_info_delay_message')}
          </Text.Secondary>
        </Flex>
      </Flex>
    )
  },
  {
    deriveSubjectsRequirements() {
      return {
        all_of: ['rider'],
      }
    },
  },
)

export default RiderUpcomingShifts
