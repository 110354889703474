/**
 * returns fixed-digit number of the passed number
 * */

function fixNumber(number: number | string, precision = 2): string {
  if (typeof number === 'number') {
    return number.toFixed(precision)
  } else {
    const numberized = Number(number)
    return numberized.toFixed(precision)
  }
}

export default fixNumber
