import { ApiService } from 'services/authApi/getPermissions'
import { GetCustomerProfileResponse } from 'types/api/customerApi/getCustomerProfile'

export const getCustomerProfile: ApiService<
  {
    entityId: string
    customerId: string
  },
  GetCustomerProfileResponse
> = (createClient, options) => {
  const { entityId, customerId, clientParams } = options

  const uri = `/CustomerAPI/v2/customers/${entityId}/${customerId}/profile`

  return createClient({
    endpointName: 'getCustomerProfile',
    expectedResponseStatusCode: 200,
    ...clientParams,
  }).get(uri)
}
