import React from 'react'
import { createUseStyles } from 'react-jss'
import { useTranslation } from 'hooks/useTranslation'
import { Button } from 'shared/Button'
import { ExclamationCircleFilled, EyeOutlined } from '@ant-design/icons'
import { Text } from 'shared/Text'
import { UnifiedIcon } from 'shared/UnifiedIcon'
import { gray } from 'theme'
import { getPublicUrl } from 'utils/getPublicUrl'
import classNames from 'classnames'

const mapPlaceholderUrl = getPublicUrl('imgs/MapPlaceholder.svg')
const useStyles = createUseStyles({
  container: {
    maxWidth: '480px',
    height: '320px',
    position: 'relative',
    alignItems: 'center',
    backgroundImage: `url("${mapPlaceholderUrl}")`,
    justifyContent: 'space-between',
  },
  button: {
    top: '50%',
    left: '50%',
    position: 'absolute',
    backgroundColor: 'white !important',
    transform: 'translate(-50%, -50%)',
  },
  message: {
    top: '50%',
    left: '50%',
    gap: '10px',
    display: 'flex',
    alignItems: 'center',
    position: 'absolute',
    padding: '6px 20px',
    borderRadius: '8px',
    textWrap: 'nowrap',
    border: '5px white solid',
    backgroundColor: gray.gray3,
    transform: 'translate(-50%, -50%)',
  },
})

type Props = {
  onViewMap?: () => void
  containerClassName?: string
  canViewMap?: boolean
  emptyMapMessage?: string
}

export const PlaceholderMap = ({
  onViewMap,
  canViewMap,
  containerClassName,
  emptyMapMessage,
}: Props) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <div className={classNames(containerClassName, classes.container)}>
      {canViewMap ? (
        <Button type='outlined' className={classes.button} icon={EyeOutlined} onClick={onViewMap}>
          {t('widgets.order_deliveries.map.view_map')}
        </Button>
      ) : (
        <Text.Secondary className={classes.message}>
          <UnifiedIcon icon={ExclamationCircleFilled} />
          {emptyMapMessage ? emptyMapMessage : t('widgets.order_deliveries.map.map_unavailable')}
        </Text.Secondary>
      )}
    </div>
  )
}
