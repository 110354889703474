import React from 'react'
import { createCustomSvgIcon } from 'factory/createCustomSvgIcon'

export const ProfileIcon = createCustomSvgIcon({
  viewBoxWidth: 15,
  viewBoxHeight: 14,
  content: (
    <>
      <g clipPath='url(#profileicon-clip1)'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M6.91667 4.66665C6.91667 5.74054 6.04611 6.6111 4.97222 6.6111C3.89834 6.6111 3.02778 5.74054 3.02778 4.66665C3.02778 3.59277 3.89834 2.72221 4.97222 2.72221C6.04611 2.72221 6.91667 3.59277 6.91667 4.66665ZM6.4499 7.40512C7.42259 6.87915 8.08333 5.85007 8.08333 4.66665C8.08333 2.94843 6.69044 1.55554 4.97222 1.55554C3.254 1.55554 1.86111 2.94843 1.86111 4.66665C1.86111 5.87805 2.55348 6.92774 3.56408 7.44159C1.78588 8.04783 0.5 9.70311 0.5 11.6667H1.66667C1.66667 9.85359 3.17432 8.3611 5.06244 8.3611C5.92794 8.3611 6.715 8.67549 7.31343 9.19169L8.07546 8.30828C7.60828 7.90529 7.05636 7.59471 6.4499 7.40512ZM14.1111 3.49999H8.66667V2.33332H14.1111V3.49999ZM8.66667 6.22221H14.1111V5.05554H8.66667V6.22221ZM14.1111 8.94443H8.66667V7.77776H14.1111V8.94443ZM6.72222 11.6667L14.1111 11.6667V10.5L6.72222 10.5V11.6667Z'
        />
      </g>
      <defs>
        <clipPath id='profileicon-clip1'>
          <rect width='14' height='14' fill='white' transform='translate(0.5)' />
        </clipPath>
      </defs>
    </>
  ),
})
