// libs
import React from 'react'
import { useTranslation } from 'hooks/useTranslation'
import { Tooltip } from 'antd'

// components
import { CheckCircleFilled, ExclamationCircleFilled, QuestionCircleFilled } from '@ant-design/icons'

interface Props {
  status?: string
}

const FraudFlagIcon: React.FC<Props> = ({ status }) => {
  const { t } = useTranslation()
  if (!status) return null

  let tooltip
  let icon

  switch (status) {
    case 'orange':
      tooltip = t('Customer Widget.Potential fraudulent customer')
      icon = (
        <QuestionCircleFilled
          style={{ color: '#FAAD14' }}
          data-testid='potential-fraudulent-customer-icon'
        />
      )
      break
    case 'red':
      tooltip = t('Customer Widget.Fraudulent customer')
      icon = (
        <ExclamationCircleFilled
          style={{ color: '#FF4D4F' }}
          data-testid='fraudulent-customer-icon'
        />
      )
      break
    default:
      tooltip = t('Customer Widget.Trusted customer')
      icon = <CheckCircleFilled style={{ color: '#64bd53' }} data-testid='trusted-customer-icon' />
  }

  return <Tooltip title={tooltip}>{icon}</Tooltip>
}

export default FraudFlagIcon
