import React, { useMemo } from 'react'
import { Typography } from 'antd'
import fixNumber from 'utils/fixNumber'
import { createUseStyles } from 'react-jss'
import { useTranslation } from 'hooks/useTranslation'

import styles from './Refund.styles'
import { availableRefundMethods } from 'entityConfig/allowedConfigValues'

const useStyles = createUseStyles(styles)

type Props = {
  refundMethod: availableRefundMethods
  currency: string
  voucherAmount: number
  totalRefundAmount: number
  totalDamagedValue: number
}

const RefundInfo = ({
  refundMethod,
  currency,
  totalRefundAmount,
  voucherAmount,
  totalDamagedValue,
}: Props) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { Text } = Typography

  const voucherValue = useMemo(() => {
    switch (refundMethod) {
      case availableRefundMethods.voucher:
        return totalRefundAmount - totalDamagedValue
      default:
        return voucherAmount
    }
  }, [refundMethod, totalRefundAmount, totalDamagedValue, voucherAmount])

  const refundValue = useMemo(() => {
    switch (refundMethod) {
      case availableRefundMethods.voucher:
        return totalRefundAmount
      default:
        return totalRefundAmount + voucherAmount
    }
  }, [refundMethod, totalRefundAmount, voucherAmount])

  return (
    <>
      <div className={classes.textBlock}>
        <Text className={classes.titleText}>
          {`${t('Actions Widget.Actions.Full Refund.Order Amount')}:`}
        </Text>
        <Text className={classes.dataText}>{`${currency} ${fixNumber(totalDamagedValue)}`}</Text>
      </div>
      {Boolean(voucherAmount) && (
        <div className={classes.textBlock}>
          <Text className={classes.titleText}>
            {`${t('Actions Widget.Actions.Full Refund.Compensation Amount')}:`}
          </Text>
          <Text className={classes.dataText}>{`${currency} ${fixNumber(voucherValue)}`}</Text>
        </div>
      )}
      <div className={classes.textBlockTotalAmount}>
        <Text className={classes.titleText}>
          {Boolean(voucherAmount)
            ? t('Actions Widget.Actions.Full Refund.Total Refund and Compensation Amount')
            : t('Actions Widget.Actions.Full Refund.Total Refund')}
          :
        </Text>
        <Text className={classes.dataTextTotalAmount}>{`${currency} ${fixNumber(
          refundValue,
        )}`}</Text>
      </div>
    </>
  )
}

export default RefundInfo
