import { AuthPayload } from 'contexts/auth/AuthProvider'
import environment from 'envConfig'
import { ApiService, transformPermissionData, validatePermissionData } from './getPermissions'

export const refreshPermissions: ApiService<
  {
    globalEntityId: string
    refreshToken: string

    agentName: string
    agentEmail: string
  },
  AuthPayload
> = (createClient, opts) => {
  const { refreshToken, globalEntityId, clientParams, agentEmail, agentName } = opts
  const uri = `${
    environment().oneviewAuthApiRoot
  }/refresh-permissions?global_entity_id=${globalEntityId}`

  return createClient({
    endpointName: 'refreshPermissions',
    validateJwt: false,
    ...clientParams,
    expectedResponseStatusCode: 200,
    validateData: validatePermissionData,
    useAwsApi: environment().oneviewAuthApiAWSEnabled === true ? true : undefined,
  })
    .post(uri, { refresh: refreshToken })
    .then((res) => transformPermissionData(res, agentEmail, agentName))
}
