/**
 * HistoryOfEvents Widget
 * renders tabs of order, customer and Back Office with relevant histories
 * */

// libs
import React, { useState, useEffect, useContext } from 'react'
// configs
import { allowedNewCommentWidgetTabs } from 'entityConfig/allowedConfigValues'
// contexts and types
import { SessionContext } from 'contexts/session/SessionContext'
import HistoryOfEventsTabs, { WidgetSize } from 'types/widgets/historyOfEvents/tabs'
// hooks
import { useTranslation } from 'hooks/useTranslation'
import { useEntityConfig } from 'hooks/useEntityConfig'
// styles
import { createUseStyles } from 'react-jss'
import styles from './HistoryOfEvents.styles'
import { Radio, Empty } from 'antd'
// assets
// components
import RiderHistory from './RiderHistory'
import OrderHistory from './OrderHistory'
import CustomerHistory from './CustomerHistory'
import BackOffice from './BackOffice'
// utils
import { useGetValidFeatures } from 'hooks/useGetValidFeatures'
import { VendorHistory } from './VendorHistory'
import { useCaptureUserAction } from 'hooks/events/useCaptureUserAction'
import { CommentTimeFilter } from './CommentTimeFilter'

import { AvailableFilterTimeFrames } from 'types/widgets/historyOfEvents/availableCommentActionsTypes'

import { createPluggableWidget } from 'factory/createPluggableWidget'

const useStyles = createUseStyles(styles)

const HistoryOfEvents = createPluggableWidget<{}>(
  ({ sdk }) => {
    const classes = useStyles()

    // pull language content
    const { t } = useTranslation()
    const captureUserAction = useCaptureUserAction()

    const { getWidgetConfig } = useEntityConfig()
    const historyConfig = getWidgetConfig('history_of_events')
    const actionsConfig = getWidgetConfig('actions')

    // pull orderId & globalentity ID from session context
    const {
      sessionState: { orderId, vendorId, uiVersion },
    } = useContext(SessionContext)

    const getValidFeatures = useGetValidFeatures()

    // set interval to periodically call getOrderComments, getCustomerComments and getRiderComments
    const [intervalDuration] = useState(60000)
    const [intervalState, setIntervalState] = useState(-1)

    useEffect(() => {
      const listener = sdk.eventEmitter.addEventListener({
        name: 'NEW_COMMENT_SUCCESS',
        callback: (event) => {
          setIntervalState((prevState) => (prevState < 0 ? 1 : -1))
        },
      })

      return () => {
        sdk.eventEmitter.removeEventListener(listener)
      }
    }, [sdk.eventEmitter])

    useEffect(() => {
      // set interval
      const interval = setInterval(() => {
        const newState = intervalState < 0 ? 1 : -1
        setIntervalState(newState)
      }, intervalDuration)
      // clean interval upon dismounting
      return () => clearInterval(interval)
    }, [intervalState, intervalDuration])

    // figure out if beta flag needed for any tab and set initial active tab according to entity config
    const [activeTab, setActiveTab] = useState<HistoryOfEventsTabs>(HistoryOfEventsTabs.order)
    const [historyOfEventsTabOrder, setHistoryOfEventsTabOrder] = useState<string[]>()

    // If widget size is configured, use that, otherwise use mini in v1 and max in v2
    const widgetSize =
      historyConfig?.widget_size || (uiVersion === 'v1' ? WidgetSize.mini : WidgetSize.max)

    useEffect(() => {
      if (historyConfig?.tab_order) {
        const tabs = getValidFeatures(historyConfig.tab_order)
        setHistoryOfEventsTabOrder(tabs)
        setActiveTab(HistoryOfEventsTabs[tabs[0]])
      }
    }, [historyConfig, getValidFeatures])

    const handleTabChange = (tab: string) => {
      captureUserAction('HistoryOfEventsTab' + tab.replace(/_(\w)/, `$1`) + 'Clicked', {
        eventDetails: {
          new_tab: tab,
          active_tab: activeTab,
        },
      })
      setActiveTab(HistoryOfEventsTabs[tab.toLowerCase()])
    }
    let contentContainer = classes.tabsContainerMini
    let headerContainer = classes.headerContainerMini

    if (activeTab !== HistoryOfEventsTabs.back_office) {
      if (widgetSize === WidgetSize.mini) {
        contentContainer = classes.tabsContainerMini
        headerContainer = classes.headerContainerMini
      } else {
        contentContainer = classes.tabsContainerMax
        headerContainer = classes.headerContainerMax
      }
    } else {
      //TODO , headercontainer class for BO ?
      contentContainer = classes.tabContainerBO
    }

    const [timeFilter, setTimeFilter] = useState<AvailableFilterTimeFrames>(
      AvailableFilterTimeFrames.all,
    )

    return (
      <div className={classes.historyOfEventsWidgetShell}>
        {
          //Show header only in v1
          uiVersion === 'v1' && (
            <p className={classes.historyOfEventsTitle}>{t('History of Events Widget.Activity')}</p>
          )
        }
        <div className={classes.tabsContainer}>
          <div className={headerContainer}>
            <div className={classes.radioContainer}>
              <Radio.Group
                defaultValue={activeTab}
                value={activeTab}
                onChange={({ target }) => {
                  handleTabChange(target?.value)
                }}
              >
                {historyOfEventsTabOrder
                  ? historyOfEventsTabOrder.map((tab: string) => {
                      switch (tab) {
                        case allowedNewCommentWidgetTabs.rider:
                          return (
                            <Radio.Button
                              key={HistoryOfEventsTabs.rider}
                              value={HistoryOfEventsTabs.rider}
                            >
                              {t('History of Events Widget.Rider')}
                            </Radio.Button>
                          )
                        case allowedNewCommentWidgetTabs.order:
                          return (
                            <Radio.Button
                              key={HistoryOfEventsTabs.order}
                              value={HistoryOfEventsTabs.order}
                            >
                              {t('History of Events Widget.This order')}
                            </Radio.Button>
                          )
                        case allowedNewCommentWidgetTabs.customer:
                          return (
                            <Radio.Button
                              key={HistoryOfEventsTabs.customer}
                              value={HistoryOfEventsTabs.customer}
                            >
                              {t('History of Events Widget.Customer')}
                            </Radio.Button>
                          )
                        case allowedNewCommentWidgetTabs.back_office:
                          return (
                            <Radio.Button
                              key={HistoryOfEventsTabs.back_office}
                              value={HistoryOfEventsTabs.back_office}
                            >
                              {t('History of Events Widget.BO')}
                            </Radio.Button>
                          )
                        case allowedNewCommentWidgetTabs.vendor:
                          return (
                            <Radio.Button
                              key={HistoryOfEventsTabs.vendor}
                              value={HistoryOfEventsTabs.vendor}
                            >
                              {t('History of Events Widget.Vendor')}
                            </Radio.Button>
                          )
                      }
                      return null
                    })
                  : null}
              </Radio.Group>
            </div>
            {activeTab !== HistoryOfEventsTabs.back_office && (
              <div className='time-filter'>
                <CommentTimeFilter
                  activeTab={activeTab}
                  onFilterChange={setTimeFilter}
                  onFilterSelected={(selectedTimeFrameInLocal) => {
                    if (activeTab === HistoryOfEventsTabs.customer) {
                      captureUserAction(`HistoryOfEventsCustomerTimeframeFilterClicked`, {
                        eventDetails: {
                          filter: selectedTimeFrameInLocal,
                        },
                      })
                    } else if (activeTab === HistoryOfEventsTabs.vendor) {
                      captureUserAction(`HistoryOfEventsVendorTimeframeFilterClicked`, {
                        eventDetails: {
                          filter: selectedTimeFrameInLocal,
                        },
                      })
                    }
                  }}
                />
              </div>
            )}
          </div>
          <div className={contentContainer}>
            {historyOfEventsTabOrder
              ? historyOfEventsTabOrder.map((tab: string) => {
                  switch (tab) {
                    case allowedNewCommentWidgetTabs.rider:
                      return (
                        activeTab === HistoryOfEventsTabs.rider && (
                          <div key={HistoryOfEventsTabs.rider}>
                            <RiderHistory
                              timeFilter={timeFilter}
                              intervalState={intervalState}
                              cancelOrder={actionsConfig.cancel_order}
                              mode={widgetSize}
                            />
                          </div>
                        )
                      )
                    case allowedNewCommentWidgetTabs.order:
                      return (
                        activeTab === HistoryOfEventsTabs.order && (
                          <div key={HistoryOfEventsTabs.order}>
                            {orderId ? (
                              <OrderHistory
                                timeFilter={timeFilter}
                                intervalState={intervalState}
                                cancelOrder={actionsConfig.cancel_order}
                                mode={widgetSize}
                              />
                            ) : (
                              <Empty
                                image={Empty.PRESENTED_IMAGE_SIMPLE}
                                description={t(
                                  'History of Events Widget.There is no comment associated with the current order',
                                )}
                              />
                            )}
                          </div>
                        )
                      )
                    case allowedNewCommentWidgetTabs.customer:
                      return (
                        activeTab === HistoryOfEventsTabs.customer && (
                          <div key={HistoryOfEventsTabs.customer}>
                            <CustomerHistory
                              timeFilter={timeFilter}
                              intervalState={intervalState}
                              cancelOrder={actionsConfig.cancel_order}
                              mode={widgetSize}
                            />
                          </div>
                        )
                      )
                    case allowedNewCommentWidgetTabs.back_office:
                      return (
                        activeTab === HistoryOfEventsTabs.back_office && (
                          <div key={HistoryOfEventsTabs.back_office}>
                            <BackOffice />
                          </div>
                        )
                      )
                    case allowedNewCommentWidgetTabs.vendor:
                      return (
                        activeTab === HistoryOfEventsTabs.vendor && (
                          <div key={HistoryOfEventsTabs.vendor}>
                            {vendorId ? (
                              <VendorHistory
                                timeFilter={timeFilter}
                                intervalState={intervalState}
                                cancelOrder={actionsConfig.cancel_order}
                                mode={widgetSize}
                              />
                            ) : (
                              <Empty
                                image={Empty.PRESENTED_IMAGE_SIMPLE}
                                description={t(
                                  'History of Events Widget.There is no comment associated with the current vendor',
                                )}
                              />
                            )}
                          </div>
                        )
                      )
                  }
                  return null
                })
              : null}
          </div>
        </div>
      </div>
    )
  },
  {
    category: 'data_lookup',
  },
)

export default HistoryOfEvents
