/**
 * dumb component rendering a disabled button with the passed msg as tooltip
 * */

import React, { FC } from 'react'
// types
import { DisabledButtonViewProps } from 'types/components/disabledButtonView'
// styles
import { createUseStyles } from 'react-jss'
import styles from './DisabledButtonView.styles'
import { Button, Tooltip } from 'antd'

const useStyles = createUseStyles(styles)

const DisabledButtonView: FC<DisabledButtonViewProps> = ({ tooltipMsg, buttonText }) => {
  const classes = useStyles()

  return (
    <div className={classes.disabledButtonContainer}>
      <Tooltip title={tooltipMsg}>
        <Button type='default' disabled size='small'>
          {buttonText}
        </Button>
      </Tooltip>
    </div>
  )
}

export default DisabledButtonView
