export default {
  voucherViewContainer: {
    color: '#0000008c',
  },

  voucherTabFooter: {
    gap: '17px',
    width: '100%',
    display: 'flex',
    padding: '20px 0',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row-reverse',
  },
  paginatorContainer: {
    width: 'fit-content',
    display: 'flex',
    flexWrap: 'wrap',
  },
}
