export const closingReason: Record<CLOSING_REASON, string> = {
  AREA_DISRUPTION: 'Area disruption',
  BAD_WEATHER: 'Bad weather',
  CHECK_IN_FAILED: 'Check in failed',
  CHECK_IN_REQUIRED: 'Check in required',
  CHECK_OUT: 'Check out',
  CLOSED: 'Closed',
  COMPLIANCE_ISSUES: 'Compliance issues',
  COURIER_DELAYED_AT_PICKUP: 'Courier delayed at pickup',
  FOOD_HYGIENE: 'Food hygiene',
  FRAUD: 'Fraud',
  HOLIDAY_SPECIAL_DAY: 'Holiday special day',
  OFFBOARDING: 'Offboarding',
  ONBOARDING: 'Onboarding',
  ORDER_FAILURE: 'Order failure',
  OTHER: 'Other',
  OWNERSHIP_CHANGE: 'Ownership change',
  READY_TO_GO_ONLINE: 'Ready to go online',
  REFURBISHMENT: 'Refurbishment',
  RELIGIOUS_OBSERVANCE: 'Religious observance',
  RESTRICTED_VISIBILITY: 'Restricted visibility',
  RETENTION: 'Retention',
  TECHNICAL_PROBLEM: 'Technical problem',
  TOO_BUSY_KITCHEN: 'Too busy kitchen',
  TOO_BUSY_NO_DRIVERS: 'Too busy no drivers',
  TOO_MANY_REJECTED_ORDERS: 'Too many rejected orders',
  UNREACHABLE: 'Unreachable',
  UPDATES_IN_MENU: 'Updates in menu',
}

export enum CLOSING_REASON {
  AREA_DISRUPTION = 'AREA_DISRUPTION',
  BAD_WEATHER = 'BAD_WEATHER',
  CHECK_IN_FAILED = 'CHECK_IN_FAILED',
  CHECK_IN_REQUIRED = 'CHECK_IN_REQUIRED',
  CHECK_OUT = 'CHECK_OUT',
  CLOSED = 'CLOSED',
  COMPLIANCE_ISSUES = 'COMPLIANCE_ISSUES',
  COURIER_DELAYED_AT_PICKUP = 'COURIER_DELAYED_AT_PICKUP',
  FOOD_HYGIENE = 'FOOD_HYGIENE',
  FRAUD = 'FRAUD',
  HOLIDAY_SPECIAL_DAY = 'HOLIDAY_SPECIAL_DAY',
  OFFBOARDING = 'OFFBOARDING',
  ONBOARDING = 'ONBOARDING',
  ORDER_FAILURE = 'ORDER_FAILURE',
  OTHER = 'OTHER',
  OWNERSHIP_CHANGE = 'OWNERSHIP_CHANGE',
  READY_TO_GO_ONLINE = 'READY_TO_GO_ONLINE',
  REFURBISHMENT = 'REFURBISHMENT',
  RELIGIOUS_OBSERVANCE = 'RELIGIOUS_OBSERVANCE',
  RESTRICTED_VISIBILITY = 'RESTRICTED_VISIBILITY',
  RETENTION = 'RETENTION',
  TECHNICAL_PROBLEM = 'TECHNICAL_PROBLEM',
  TOO_BUSY_KITCHEN = 'TOO_BUSY_KITCHEN',
  TOO_BUSY_NO_DRIVERS = 'TOO_BUSY_NO_DRIVERS',
  TOO_MANY_REJECTED_ORDERS = 'TOO_MANY_REJECTED_ORDERS',
  UNREACHABLE = 'UNREACHABLE',
  UPDATES_IN_MENU = 'UPDATES_IN_MENU',
}
