import React from 'react'
import { Alert, Typography } from 'antd'
import { createUseStyles } from 'react-jss'

import fixNumber from 'utils/fixNumber'
import { useTranslation } from 'hooks/useTranslation'
import styles from './Refund.styles'

const useStyles = createUseStyles(styles)

type Props = {
  currency: string
  totalRefundAmount: number
  totalRemovalAmount: number
  useFlexibleAmount: boolean
  refundAmountPrecision: number
}
const AutoRefundMethod = ({
  currency,
  totalRefundAmount,
  totalRemovalAmount,
  useFlexibleAmount,
  refundAmountPrecision,
}: Props) => {
  const classes = useStyles()
  const { Text } = Typography

  // pull translations
  const { t } = useTranslation()
  return (
    <React.Fragment>
      {!useFlexibleAmount ? (
        <div className={classes.textBlock}>
          <Text className={classes.titleText}>
            {`${t('Actions Widget.Actions.Full Refund.Items Amount')}:`}
          </Text>
          <Text className={classes.dataText}>{`${currency} ${fixNumber(
            totalRemovalAmount,
            refundAmountPrecision,
          )}`}</Text>
        </div>
      ) : null}
      <div className={classes.textBlockTotalAmount}>
        <Text className={classes.titleText}>
          {`${t('Actions Widget.Actions.Full Refund.Total Refund Amount')}:`}
        </Text>
        <Text className={classes.dataTextTotalAmount}>{`${currency} ${fixNumber(
          totalRefundAmount,
          refundAmountPrecision,
        )}`}</Text>
      </div>

      <div className={classes.alertContainer}>
        <Alert
          message={`${t('Messages.Refund will be requested immediately')}!`}
          type='info'
          showIcon
        />
      </div>
    </React.Fragment>
  )
}

export default AutoRefundMethod
