import React from 'react'
import { createUseStyles } from 'react-jss'

import FraudFlagIcon from './FraudFlagIcon'
import CSWidgetHeading from '../../components/CSWidgetHeading'
import styles from './Customer.styles'
import { FraudFlagWithBetaRequirement } from '../../contexts/entity/types'
import { WidgetErrorHandler } from '../../components/WidgetErrorHandler/widgetErrorHandler'
import { useLoadFraudStatus } from '../../hooks/useLoadFraudStatus'
import { useIsDataPointValid } from 'hooks/useGetValidFeatures'
import { fraudStatusResults } from 'types'

// type FunctionParamType = ReturnType<typeof getCustomerFraudStatus>

// type ParamType = FunctionParamType extends Promise<infer U> ? U : FunctionParamType

const useStyles = createUseStyles(styles)

type Props = {
  customerId: string
  orderId: string
  globalEntityId: string
  fraudConfig: FraudFlagWithBetaRequirement
  onFraudCheck: (result: boolean) => void
}

const CustomerWidgetHeader = ({
  orderId,
  globalEntityId,
  customerId,
  fraudConfig,
  onFraudCheck,
}: Props) => {
  const classes = useStyles()
  const isDataPointValid = useIsDataPointValid()

  const shouldLoadFraudFlag =
    customerId && fraudConfig?.enabled && isDataPointValid(fraudConfig?.betaRequirement)

  const { fraudStatus, error, loading, refreshFraudStatus } = useLoadFraudStatus({
    orderId,
    globalEntityId,
    customerId,
    apiVersion: fraudConfig?.apiVersion,
    shouldLoadFraudFlag: shouldLoadFraudFlag,
    onSuccessCallBack(status) {
      onFraudCheck(status === fraudStatusResults.red)
    },
  })

  return (
    <>
      <div className={classes.flexWrapper}>
        <CSWidgetHeading title='Customer Widget.Customer' />
        <div className={classes.fraudFlagWrapperWithNewDesignHeading}>
          <FraudFlagIcon status={fraudStatus} />
        </div>
      </div>
      <WidgetErrorHandler
        errorPayload={error?.errorPayload}
        loading={loading}
        onRetry={refreshFraudStatus}
        displayType={'mini'}
        showLoader={false}
      />
    </>
  )
}

export default CustomerWidgetHeader
