import React from 'react'

// Components
import { Space, Typography } from 'antd'

// Types
// Relative Path
import { UnifiedIconName } from 'contexts/IconPacksProvider'
import { UnifiedIcon } from 'shared'

const { Text } = Typography

interface SinglePaymentWidgetProps {
  label: string
  icon?: UnifiedIconName
}

function SinglePaymentWidget({ label, icon }: SinglePaymentWidgetProps) {
  return (
    <Space>
      <UnifiedIcon icon={icon} />
      <Text>{label}</Text>
    </Space>
  )
}

export default SinglePaymentWidget
