import React, { useContext } from 'react'
// contexts and types
import { DataContext } from 'contexts/data/DataContext'
import { EntityContext } from 'contexts/entity/EntityContext'
// hooks
import { useTranslation } from 'hooks/useTranslation'
// utils
import fixNumber from 'utils/fixNumber'
// styles
import { createUseStyles } from 'react-jss'
import styles from './Remove.styles'
import { Button, Typography } from 'antd'
import { ItemStructured } from 'types/actions/partialRefund'
import { SmartToolTip } from 'shared/SmartTooltip'
import { RemovalItems } from './RemovalItems/RemovalItems'
import classNames from 'classnames'
import { useCheckDisplayRules } from 'hooks/useCheckDisplayRules'

const useStyles = createUseStyles(styles)

interface Props {
  structuredOrderItems: ItemStructured[]
  setStructuredOrderItems: (items: ItemStructured[]) => void

  setCurrentStepIndex: (arg: number) => void

  totalOrderValue: number
  totalRemovalAmount: number

  numberOfRemovalBundles: number
  numberOfRemovalItems: number
  numberOfRemovalToppings: number
}

const Remove: React.FC<Props> = ({
  setCurrentStepIndex,

  totalOrderValue,
  totalRemovalAmount,

  numberOfRemovalBundles,
  numberOfRemovalItems,
  numberOfRemovalToppings,

  structuredOrderItems,
  setStructuredOrderItems,
}) => {
  const classes = useStyles()
  const { Text } = Typography

  // pull translations
  const { t } = useTranslation()

  // pull partial refund configs
  const {
    entityState: {
      entityConfig: {
        fixed_panel_config: {
          widgets_configs: {
            actions: {
              partial_refund: {
                allowIndeterminateItemCheck,
                partialItemRefundPercentage,
                allowPartialRefundWithAllItems,
                refundAmountPrecision,
                isToppingSelectionAllowed,
                isChoiceSelectionAllowed,
                isPartialItemSelectionAllowed,
              },
            },
          },
        },
      },
    },
  } = useContext(EntityContext)

  // pull order and curreny from data context
  const {
    dataState: { currency },
  } = useContext(DataContext)

  const checkDisplayRules = useCheckDisplayRules()
  const isUiImprovementsAllowed =
    checkDisplayRules({
      beta_requirements: { value: allowIndeterminateItemCheck?.betaRequirement },
    })?.visible ?? false

  const setItem = (item: ItemStructured) => {
    setStructuredOrderItems(
      structuredOrderItems.map((current) => {
        if (current.id === item.id) {
          return item
        }
        return current
      }),
    )
  }

  let canContinue = true
  let cannotContinueReason = ''

  if (totalRemovalAmount <= 0) {
    canContinue = false
    cannotContinueReason = t(
      'Actions Widget.Actions.Partial Refund.You have to select a refundable item with a price to continue',
    )
  } else if (totalRemovalAmount === totalOrderValue && !allowPartialRefundWithAllItems) {
    canContinue = false
    cannotContinueReason = t(
      'Actions Widget.Actions.Partial Refund.You cannot refund all items of an order',
    )
  }

  return (
    <div className={classes.container}>
      <div className={classes.contentContainer}>
        {/* TABLE TITLES */}
        <div className={classes.titlesRow}>
          <div className={classes.titlesRowDescriptionHolder}>
            <Text className={classes.titleTexts}>
              {t('Actions Widget.Actions.Partial Refund.Description')}
            </Text>
          </div>
          <div className={classes.titlesRowPriceHolder}>
            <Text className={classes.titleTexts}>
              {t('Actions Widget.Actions.Partial Refund.Total Price')}
            </Text>
          </div>
          <div className={classes.titlesRowQuantityHolder}>
            <Text className={classes.titleTexts}>
              {t('Actions Widget.Actions.Partial Refund.Quantity')}
            </Text>
          </div>
        </div>

        {/* ITEMS */}
        <RemovalItems
          isUiImprovementsAllowed={isUiImprovementsAllowed}
          items={structuredOrderItems}
          partialItemRefundPercentage={partialItemRefundPercentage}
          isToppingSelectionAllowed={isToppingSelectionAllowed}
          isChoiceSelectionAllowed={isChoiceSelectionAllowed}
          checkboxClassName={classes.allCheckboxes}
          currency={currency}
          refundAmountPrecision={refundAmountPrecision}
          setItem={setItem}
          inputClassName={classes.input}
          isPartialItemSelectionAllowed={isPartialItemSelectionAllowed}
        />
      </div>

      {/* TOTAL VALUES */}
      <div className={classes.totalsHolder}>
        <div className={classes.totalsCounter}>
          <div className={'bundles-count'}>
            <Text className={classes.totalsCounterTitle}>
              {`${t('Actions Widget.Actions.Partial Refund.Bundles')}:`}
            </Text>
            <Text className={classes.totalsCounterValue}>{numberOfRemovalBundles}</Text>
          </div>
          <div className={'items-count'}>
            <Text className={classes.totalsCounterTitle}>
              {`${t('Actions Widget.Actions.Partial Refund.Items')}:`}
            </Text>
            <Text className={classes.totalsCounterValue}>{numberOfRemovalItems}</Text>
          </div>
          <div className={'toppings-count'}>
            <Text className={classes.totalsCounterTitle}>
              {`${t('Actions Widget.Actions.Partial Refund.Toppings')}:`}
            </Text>
            <Text className={classes.totalsCounterValue}>{numberOfRemovalToppings}</Text>
          </div>
        </div>
        <div className={classes.totalsSeparatorHolder}>
          <hr
            style={{
              height: '50px',
              width: '1px',
              backgroundColor: 'rgba(0, 0, 0, 0.25)',
              border: 'none',
            }}
          />
        </div>

        <div className={classNames(classes.totalPriceHolder, 'total-price')}>
          <Text className={classes.totalsCounterTitle}>
            {`${t('Actions Widget.Actions.Partial Refund.Total Amount')}:`}
          </Text>
          <Text className={classes.totalsCounterValue}>{`${currency} ${fixNumber(
            totalRemovalAmount,
            refundAmountPrecision,
          )}`}</Text>
        </div>
      </div>

      {/* BUTTON(S) */}
      <div className={classes.buttonsContainer}>
        <Button
          type='default'
          onClick={() => setCurrentStepIndex(0)}
          className={classes.backButton}
        >
          {t('Interface.Go Back')}
        </Button>

        <SmartToolTip title={cannotContinueReason} disabled={canContinue}>
          <Button type='primary' disabled={!canContinue} onClick={() => setCurrentStepIndex(2)}>
            {t('Interface.Continue')}
          </Button>
        </SmartToolTip>
      </div>
    </div>
  )
}

export default Remove
