import { useCallback } from 'react'
import { allowedScrollablePanelWidgets } from 'entityConfig/allowedConfigValues'
import { useEntityConfig } from 'hooks/useEntityConfig'
import { FeatureWithBetaRequirementConfig, WidgetPlugin } from 'contexts/entity/types'

const isActionWidgetPlugin = (
  action: FeatureWithBetaRequirementConfig | (FeatureWithBetaRequirementConfig & WidgetPlugin),
): action is FeatureWithBetaRequirementConfig & WidgetPlugin => {
  return (action as FeatureWithBetaRequirementConfig & WidgetPlugin).type === 'plugin'
}

const usePluginUtils = () => {
  const { getWidgetConfig, availableActions } = useEntityConfig()

  const isTabAPlugin = useCallback(
    (widgetName: allowedScrollablePanelWidgets, featureName: string) => {
      return getWidgetConfig(widgetName).tab_configs[featureName]?.type === 'plugin'
    },
    [getWidgetConfig],
  )

  const getTabPluginConfig = useCallback(
    (widgetName: allowedScrollablePanelWidgets, featureName: string) => {
      return getWidgetConfig(widgetName).tab_configs[featureName]
    },
    [getWidgetConfig],
  )

  const isActionAPlugin = useCallback(
    (actionName: string) => {
      const targetAction = availableActions.find((action) => action.feature === actionName)
      if (!targetAction) return false
      return isActionWidgetPlugin(targetAction)
    },
    [availableActions],
  )
  const getActionModalPluginConfig = useCallback(
    (actionName: string): FeatureWithBetaRequirementConfig & WidgetPlugin => {
      const targetAction = availableActions.find((action) => action.feature === actionName)
      if (isActionWidgetPlugin(targetAction)) return targetAction
    },
    [availableActions],
  )

  return { isTabAPlugin, getTabPluginConfig, isActionAPlugin, getActionModalPluginConfig }
}

export { usePluginUtils }
