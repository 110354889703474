import { createUseStyles } from 'react-jss'
import { gray, shadow, white } from 'theme'

export const useDeliveryPanelStyles = createUseStyles<string, { expanded: boolean }>({
  container: {
    borderRadius: '4px !important',
    '& .ant-collapse-item.panel': {
      '& .ant-collapse-header': {
        display: 'flex',
        maxHeight: '100%',
        background: `${white}`,
        padding: '16px 0 16px 14px !important',
        borderRadius: '4px 4px 0 0 !important',
        borderBottom: `1px solid ${gray.gray5}`,
      },
      '& .ant-collapse-content': {
        background: gray.gray2,
        margin: '30px 0',
      },
    },
  },
  body: {
    padding: '10px 0',
  },
  timeline: {
    marginTop: '30px',
  },
  header: {
    width: '100%',
    '& .delivery-meta': {
      display: 'flex',
      flexDirection: 'column',
    },
  },
  riders: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '8px',
  },
  deliveries: {
    boxShadow: shadow.level1,
    marginBottom: '16px',
  },
  figureTag: {
    borderRadius: '50%',
    marginRight: '5px',
  },
  primaryTag: {
    borderRadius: '10px',
  },
  dropdownMenu: {
    whiteSpace: 'nowrap',
    maxWidth: '500px',
  },
  dropdownMenuItem: {
    '& > span': {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
})
