// all widgets allowed to be passed to fixed_panel's widget_order
export enum allowedFixedPanelWidgets {
  actions = 'actions',
  lastOrders = 'lastOrders',
  newComment = 'newComment',
}

// all actions allowed to be passed to Actions widget
export enum allowedActions {
  searchOrder = 'searchOrder',
  cancelOrder = 'cancelOrder',
  searchVoucher = 'searchVoucher',
  compensation = 'compensation',
  newComment = 'newComment',
  modifyDeliveryAddress = 'modifyDeliveryAddress',
  partialRefund = 'partialRefund',
  fullRefund = 'fullRefund',
  changeCookingInstructions = 'changeCookingInstructions',
  changeDeliveryTime = 'changeDeliveryTime',
  searchVendor = 'searchVendor',
  moveDeliveryPendingToQueue = 'move_delivery_pending_to_queue',
  vendor_availability = 'vendor_availability',
  switch_payable = 'switch_payable',
}

// all refund methods allowed to be passed to available_refund_methods of refund_common
export enum availableRefundMethods {
  auto = 'AUTO',
  wallet = 'WALLET',
  source = 'SOURCE',
  voucher = 'Voucher',
  noRefund = 'NO_REFUND',
  bankAccount = 'BANK_ACCOUNT',
}

// translation keys for all allowed refund methods
export enum availableRefundMethodsTranslationKeys {
  voucher = 'Voucher',
  source = 'Online Payment',
  bankAccount = 'Bank Account',
  wallet = 'Wallet',
  auto = 'AUTO',
  noRefund = 'No Refund',
}

// payment types to which refundToWallet is allowed
export enum refundablePaymentTypesForRefundToWallet {
  cod = 'COD',
  credit_card = 'Credit Card',
  mol_pay = 'MolPay',
  apple_pay = 'ApplePay',
  no_payment_zero_amount = 'No Payment, Zero Payable Amount',
  cyber_source_credit_card = 'cybersource_creditcard',
  payPal = 'PayPal',
  balance = 'balance',
  reddot_paylah = 'reddot_paylah',
  google_pay = 'GooglePay',
  ant_financial_true_money = 'antfinancial_truemoney',
}

// payment types to which refundToSource is allowed
export enum refundablePaymentTypesForRefundToSource {
  credit_card = 'Credit Card',
  mol_pay = 'MolPay',
  apple_pay = 'ApplePay',
  cyber_source_credit_card = 'cybersource_creditcard',
  payPal = 'PayPal',
  reddot_paylah = 'reddot_paylah',
  google_pay = 'GooglePay',
  antfinancial_gcash = 'antfinancial_gcash',
  ant_financial_true_money = 'antfinancial_truemoney',
  payu_twisto = 'payu_twisto',
  checkout_psp_mobilepay = 'checkout_psp_mobilepay',
  checkout_psp_online_banking = 'checkout_psp_online_banking',
  cod = 'COD',
}

// all possible voucher description prefixes for partial and full refund actions
export enum availableRefundDescriptionPrefixes {
  partial_refund_for_order = 'Partial refund for order',
  full_refund_for_order = 'Full refund for order',
}

// all compensation methods allowed to be passed to available_compensation_methods
export enum AvailableCompensationMethods {
  wallet = 'Wallet',
  voucher = 'Voucher',
}

// all compensation methods translation keys allowed to be passed to available_compensation_methods
export enum availableCompensationMethodsTranslationKeys {
  wallet = 'Wallet',
  voucher = 'Voucher',
}

// all possible issue types for compensation action
export enum availableRefundIssueTypes {
  damaged_item = 'damaged_item',
  damaged_food = 'damaged_food',
  spilled_food = 'spilled_food',
  food_quality = 'food_quality',
  cooking_instructions_not_followed = 'cooking_instructions_not_followed',
  food_portion = 'food_portion',
  food_temperature = 'food_temperature',
  early_delivery = 'early_delivery',
  moderate_delay = 'moderate_delay',
  short_delay = 'short_delay',
  extreme_delay = 'extreme_delay',
  severe_delay = 'severe_delay',
  delayed_delivery = 'delayed_delivery',
  inappropriate_behaviour = 'inappropriate_behaviour',
  missing_equipment_uniform = 'missing_equipment_uniform',
  not_delivered_to_doorstep = 'not_delivered_to_doorstep',
  delivery_instructions_not_followed = 'delivery_instructions_not_followed',
  negative_feedback = 'negative_feedback',
  money_collection_issue = 'money_collection_issue',
  missing_item = 'missing_item',
  wrong_item_or_order = 'wrong_item_or_order',
  wrong_item = 'wrong_item',
  wrong_missing_item = 'wrong_missing_item',
  wrong_order = 'wrong_order',
  order_marked_as_delivered_but_customer_never_received_it = 'order_marked_as_delivered_but_customer_never_received_it',
  rider_issue = 'rider_issue',
  voucher_issue = 'voucher_issue',
  order_never_arrived = 'order_never_arrived',
  logistics_cancellation = 'logistics_cancellation',
  food_poisoning = 'food_poisoning',
  live_complain_about_late_order = 'live_complain_about_late_order',
  menu_price_discrepancy = 'menu_price_discrepancy',
  voucher_already_used = 'voucher_already_used',
  rider_tips = 'rider_tips',
  special_recovery_compensation = 'special_recovery_compensation',
  promotion_discount_not_applied = 'promotion_discount_not_applied',
  positive = 'positive',
  negative = 'negative',
  manual_decision = 'manual_decision',
  food_allergens_complain = 'food_allergens_complain',
  request_partner_closed_for_pickup = 'request_partner_closed_for_pickup',
  request_order_late_does_not_want_to_wait = 'request_order_late_does_not_want_to_wait',
  request_order_will_take_longer_than_expected = 'request_order_will_take_longer_than_expected',
  eta_is_stuck_or_increasing = 'eta_is_stuck_or_increasing',
  foreign_object = 'foreign_object',
  expired_item = 'expired_item',
  issue_with_item_replacement_process = 'issue_with_item_replacement_process',
  item_unavailable_for_pickup = 'Item unavailable for pickup',
  calidad_de_la_comida = 'Calidad de la comida',
  no_sigue_las_instrucciones_de_preparacion = 'No sigue las instrucciones de preparacion',
  entrega_fuera_de_plazos_estimados = 'Entrega fuera de plazos estimados',
  no_entregado_por_el_cadete = 'No entregado por el cadete',
  problemas_con_el_cadete = 'Problemas con el cadete',
  problemas_con_cupon = 'Problemas con cupon',
  falta_de_cambio = 'Falta de cambio',
  comida_derramada = 'Comida derramada',
  refund_request = 'refund_request',
}

export enum availableRefundIssueTypesTranslationKeys {
  damaged_item = 'Damaged item',
  damaged_food = 'Damaged food',
  spilled_food = 'Spilled food',
  food_quality = 'Food quality',
  cooking_instructions_not_followed = 'Cooking instructions were not followed',
  live_complain_about_late_order = 'Live Complain About Late Order',
  food_portion = 'Food portion',
  food_temperature = 'Food temperature',
  early_delivery = 'Early delivery',
  moderate_delay = 'Moderate delay',
  short_delay = 'Short delay',
  extreme_delay = 'Extreme delay',
  severe_delay = 'Severe delay',
  delayed_delivery = 'Delayed delivery',
  inappropriate_behaviour = 'Inappropriate behaviour',
  missing_equipment_uniform = 'Missing equipment uniform',
  not_delivered_to_doorstep = 'Not delivered to doorstep',
  delivery_instructions_not_followed = 'Delivery instructions not followed',
  negative_feedback = 'Negative feedback',
  money_collection_issue = 'Money collection issue',
  missing_item = 'Missing item',
  wrong_item_or_order = 'Wrong order or item',
  wrong_item = 'Wrong item',
  wrong_missing_item = 'Wrong / Missing Item',
  wrong_order = 'Wrong order',
  order_marked_as_delivered_but_customer_never_received_it = 'Order marked as delivered but customer never received it',
  rider_issue = 'Rider issue',
  voucher_issue = 'Voucher issue',
  order_never_arrived = 'Order never arrived',
  logistics_cancellation = 'Logistics cancellation',
  food_poisoning = 'Food poisoning',
  menu_price_discrepancy = 'Menu price discrepancy',
  voucher_already_used = 'Voucher already used',
  rider_tips = 'Rider tips',
  special_recovery_compensation = 'Special recovery compensation',
  promotion_discount_not_applied = 'Promotion/discount not applied',
  positive = 'Positive',
  negative = 'Negative',
  manual_decision = 'Manual Decision',
  food_allergens_complain = 'Food Allergens – Complain',
  request_partner_closed_for_pickup = 'Request - Partner closed for pickup',
  request_order_late_does_not_want_to_wait = 'Request - Order is late, does not want to wait',
  request_order_will_take_longer_than_expected = 'Request - Order will take longer than expected',
  eta_is_stuck_or_increasing = 'ETA is stuck or increasing',
  foreign_object = 'Foreign Object',
  expired_item = 'Expired Item',
  issue_with_item_replacement_process = 'Issue with item replacement process',
  item_unavailable_for_pickup = 'Item unavailable for pickup',
  calidad_de_la_comida = 'Calidad de la comida',
  no_sigue_las_instrucciones_de_preparacion = 'No sigue las instrucciones de preparacion',
  entrega_fuera_de_plazos_estimados = 'Entrega fuera de plazos estimados',
  no_entregado_por_el_cadete = 'No entregado por el cadete',
  problemas_con_el_cadete = 'Problemas con el cadete',
  problemas_con_cupon = 'Problemas con cupon',
  falta_de_cambio = 'Falta de cambio',
  comida_derramada = 'Comida derramada',
  refund_request = 'Refund Request',
  cold_item = 'Cold Item',
  food_item = 'Food Item',
}

export enum TalabatAvailableCompensationIssueTypes {
  complain_about_late_order_Delay_from_11_20_mins = '200',
  complain_about_late_order_Delay_from_21_30_mins = '201',
  complain_about_late_order_Delay_30_mins = '202',
  eta_is_stuck_or_increasing = '203',
  order_marked_as_delivered_but_did_not_receive = '204',
  request_order_will_take_longer_than_expected = '205',
  request_voucher_not_applied = '206',
  request_order_is_late_does_not_want_to_wait = '207',
  missing_item_Main = '208',
  missing_item_Side = '209',
  missing_item_Dessert_Drink = '210',
  wrong_item_Main = '211',
  wrong_item_Side = '212',
  wrong_item_Dessert_rink = '213',
  wong_order = '214',
  order_never_arrived = '215',
  spilled_food_Main = '216',
  spilled_food_Side = '217',
  spilled_food_Dessert_Drink = '218',
  food_quality_Food_not_edible_Main = '219',
  food_quality_Food_not_edible_Side = '220',
  food_quality_Taste_issue_Main = '221',
  food_quality_Taste_issue_Side = '222',
  cooking_instructions_were_not_followed = '223',
  food_portion = '224',
  food_temperature = '225',
  food_poisoning = '226',
  moderate_delay = '227',
  severe_delay = '228',
  extreme_delay = '229',
  early_delivery = '230',
  inappropriate_behaviour = '231',
  missing_equipment_uniform = '232',
  order_not_delivered_to_doorstep = '233',
  money_collection_issue = '234',
  delivery_instructions_not_followed = '235',
  rider_wes_not_compliant_with_contactless_ruless = '236',
  other_issues_related_to_Contactless_Delivery = '237',
  promotion_discount_not_applied = '238',
}

export enum TalabatAvailableCompensationIssueTypesTranslationKeys {
  complain_about_late_order_Delay_from_11_20_mins = 'Complain about late order - Delay from 11-20 mins',
  complain_about_late_order_Delay_from_21_30_mins = 'Complain about late order - Delay from 21-30 mins',
  complain_about_late_order_Delay_30_mins = 'Complain about late order - Delay > 30 mins',
  eta_is_stuck_or_increasing = 'ETA is stuck or increasing',
  order_marked_as_delivered_but_did_not_receive = 'Order marked as delivered but did not receive',
  request_order_will_take_longer_than_expected = 'Request - order will take longer than expected',
  request_voucher_not_applied = 'Request - voucher not applied',
  request_order_is_late_does_not_want_to_wait = 'Request - order is late, does not want to wait',
  missing_item_Main = 'Missing item - Main',
  missing_item_Side = 'Missing item - Side',
  missing_item_Dessert_Drink = 'Missing item - Dessert/Drink',
  wrong_item_Main = 'Wrong item - Main',
  wrong_item_Side = 'Wrong item - Side',
  wrong_item_Dessert_rink = 'Wrong item - Dessert/Drink',
  wong_order = 'Wrong order',
  order_never_arrived = 'Order never arrived',
  spilled_food_Main = 'Spilled food - Main',
  spilled_food_Side = 'Spilled food - Side',
  spilled_food_Dessert_Drink = 'Spilled food - Dessert/Drink',
  food_quality_Food_not_edible_Main = 'Food quality - Food not edible (Main)',
  food_quality_Food_not_edible_Side = 'Food quality - Food not edible (Side)',
  food_quality_Taste_issue_Main = 'Food quality - Taste issue (Main)',
  food_quality_Taste_issue_Side = 'Food quality - Taste issue (Side)',
  cooking_instructions_were_not_followed = 'Cooking instructions were not followed',
  food_portion = 'Food portion',
  food_temperature = 'Food temperature',
  food_poisoning = 'Food poisoning',
  moderate_delay = 'Moderate delay',
  severe_delay = 'Severe delay',
  extreme_delay = 'Extreme delay',
  early_delivery = 'Early delivery',
  inappropriate_behaviour = 'Inappropriate behaviour',
  missing_equipment_uniform = 'Missing equipment/uniform',
  order_not_delivered_to_doorstep = 'Order not delivered to doorstep',
  money_collection_issue = 'Money collection issue',
  delivery_instructions_not_followed = 'Delivery instructions not followed',
  rider_wes_not_compliant_with_contactless_ruless = 'Rider was not compliant with contactless rules',
  other_issues_related_to_Contactless_Delivery = 'Other issues related to Contactless Delivery',
  promotion_discount_not_applied = 'Promotion/discount not applied',
}

// all possible CRRs for auto-comp
export enum availableCompensationCcrs {
  missing_item = 'Missing Item (compensation)',
  missing_item_DE = 'Missing item',
  wrong_item = 'Wrong Item (compensation)',
  wrong_item_DE = 'Wrong item',
  order_marked_as_delivered_but_customer_never_received_it = 'Order marked as delivered but cust never receive (compensation)',
  order_marked_as_delivered_but_customer_never_received_it_DE = "Order marked as delivered but didn't receive",
  severe_delay = 'Severe Delay',
  severe_delay_DE = 'Severe delay',
  damaged_item = 'Damaged Item',
  damaged_item_DE = 'Damaged item',
  extreme_delay = 'Extreme Delay',
  extreme_delay_DE = 'Extreme delay',
  food_poisoning = 'Food Poisoning',
  food_poisoning_DE = 'Food poisoning',
  live_complain_about_late_order = 'Live: Complain about late order',
  live_complain_about_late_order_DE = 'Complain about late order',
  spilled_food = 'Spilled food',
  cooking_instructions_not_followed = 'Cooking instructions were not followed',
  moderate_delay = 'Moderate delay',
  food_quality = 'Food quality',
  food_portion = 'Food portion',
  food_temperature = 'Food temperature',
  positive = 'Positive',
  negative = 'Negative',
  wrong_order = 'Wrong order',
  order_never_arrived = 'Order never arrived',
  early_delivery = 'Early delivery',
  food_allergens_complain = 'Food allergens - complain',
  short_delay = 'Short delay',
  request_partner_closed_for_pickup = 'Request: Partner closed for pickup',
  delivery_instructions_not_followed = 'Delivery instructions not followed',
  request_order_late_does_not_want_to_wait = 'Request: order is late, does not want to wait',
  request_order_will_take_longer_than_expected = 'Request: order will take longer than expected',
  eta_is_stuck_or_increasing = 'ETA is stuck or increasing',
  foreign_object = 'Foreign Object',
  expired_item = 'Expired Item',
  inappropriate_behaviour = 'Inappropriate behaviour',
  not_delivered_to_doorstep = 'Order not delivered to doorstep',
  issue_with_item_replacement_process = 'Issue with item replacement process',
  item_unavailable_for_pickup = 'Item unavailable for pickup',
}

// all possible CRR codes for auto-comp
export enum availableCompensationCcrCodes {
  missing_item = '2A.1',
  wrong_item = '2A.2',
  order_marked_as_delivered_but_customer_never_received_it = '1A.4',
  spilled_food = '2C.1',
  cooking_instructions_not_followed = '2C.3',
  live_complain_about_late_order = '1A.2',
  severe_delay = '2D.3',
  moderate_delay = '2D.2',
  extreme_delay = '2D.4',
  food_quality = '2C.2',
  food_portion = '2C.4',
  food_temperature = '2C.5',
  food_poisoning = '2C.6',
  damaged_item = '2H.2',
  positive = '3F.1',
  negative = '3F.2',
  wrong_order = '2B.1',
  order_never_arrived = '2B.2',
  early_delivery = '2D.5',
  food_allergens_complain = '2C.7',
  short_delay = '2D.1',
  request_partner_closed_for_pickup = '1C.11',
  delivery_instructions_not_followed = '2E.5',
  request_order_late_does_not_want_to_wait = '1C.5',
  request_order_will_take_longer_than_expected = '1C.1',
  eta_is_stuck_or_increasing = '1A.3',
  foreign_object = '2C.8',
  expired_item = '2H.1',
  inappropriate_behaviour = '2E.1',
  not_delivered_to_doorstep = '2E.3',
  issue_with_item_replacement_process = '2A.3',
  item_unavailable_for_pickup = '2A.4',
}

// all possible voucher description prefixes for compensation action
export enum availableCompensationVoucherDescriptionPrefixes {
  we_are_sorry_voucher = 'We are sorry voucher',
  apology_voucher = 'Apology voucher',
  very_sorry = 'Very sorry',
}

// all possible payment types for compensation action
export enum availableVoucherPaymentTypes {
  adyen = 'adyen',
  apple_pay = 'apple_pay',
  google_pay = 'google_pay',
  online_card_payment = 'online_card_payment',
  online_payment = 'online_payment',
  online_banking = 'online_banking',
  twisto = 'twisto',
  paypal_payment = 'paypal_payment',
  sofort = 'sofort',
}

// all possible display names of payment types in compensation action
export enum availablePaymentTypeDisplayNames {
  plateste_online = 'Plătește Online',
  apple_pay = 'Apple Pay',
  google_pay = 'Google Pay',
  online_bulgaria_local = 'Online Плащане',
  card_online = 'Card Online',
  online_card_payment = 'Online Card Payment',
  online_payment = 'Online Payment',
  twisto = 'Twisto',
  simple_pay = 'Simple Pay',
  online_kartyas_fizetes = 'Online kártyás fizetés',
  kh_szep = 'KH SZÉP',
  mkb_szep = 'MKB SZÉP',
  otp_szep = 'OTP SZÉP',
  invoice = 'Invoice',
  verkkopankki = 'Verkkopankki',
  korttimaksu = 'Korttimaksu',
  mobile_pay = 'MobilePay',
  paypal_payment = 'Paypal Payment',
  sofort = 'Sofort',
  online_banking = 'Online Banking',
}

// all values that can be passed to the fields in new_comment_config
export enum NewCommentFieldValues {
  showChecked = 'showChecked',
  showUnchecked = 'showUnchecked',
  noShowLinked = 'noShowLinked',
  noShowUnlinked = 'noShowUnlinked',
}

export enum allowedDefaultPaymentLogo {
  Cache = 'cod.png',
  Digital_payment = 'digital_payment.png',
}

// all values allowed to be passed to new comment widget's tab_order
export enum allowedNewCommentWidgetTabs {
  order = 'order',
  customer = 'customer',
  vendor = 'vendor',
  rider = 'rider',
  back_office = 'back_office',
}

// all widgets allowed to be passed to scrollable_panel's widget_order
export enum allowedScrollablePanelWidgets {
  order = 'order',
  customer = 'customer',
  vendor = 'vendor',
  rider = 'rider',
}

// all values allowed to be passed to order widget's tab_order
export enum allowedOrderWidgetTabs {
  summary = 'summary',
  items = 'items',
  status = 'status',
  payment = 'payment',
  basket_updates = 'basket_updates',
  invoice = 'invoice',
}

// all values allowed to be passed to customer widget's tab_order
export enum allowedCustomerWidgetTabs {
  summary = 'summary',
  profile = 'profile',
  vouchers = 'vouchers',
  wallet = 'wallet',
}

// all values allowed to be passed to vendor widget's tab_order
export enum allowedVendorWidgetTabs {
  summary = 'summary',
  vendor_summary = 'vendor_summary',
  vendor_devices = 'vendor_devices',
  vendor_contacts = 'vendor_contacts',
}

// all values allowed to be passed to rider widget's tab_order
export enum allowedVendorRiderTabs {
  summary = 'summary',
  rider_events = 'rider_events',
  live_tracking = 'live_tracking', // live tracking for riderV2
}

// all values allowed to be passed to a data point in any widget
export enum allowedDataPointValues {
  never_display = 'never_display',
  display_when_data_found = 'display_when_data_found',
  always = 'always',
}

// all values allowed to be passed profile tab of customer widget
export enum allowedProfileWidgetDatapoints {
  mktech_customer_segment_discount_group_dh_last_6_months = 'mktech_customer_segment_discount_group_dh_last_6_months',
  segment_customer_experience_score = 'segment_customer_experience_score',
  mktech_customer_loyalty_status = 'mktech_customer_loyalty_status',
  visit_attributes_unique_addresses_used_last_4_weeks = 'visit_attributes_unique_addresses_used_last_4_weeks',
  order_attributes_voucher_order_rate_last_4_weeks = 'order_attributes_voucher_order_rate_last_4_weeks',
  compensations_last_4_weeks = 'compensations_last_4_weeks',
  total_compensation_last_4_weeks = 'total_compensation_last_4_weeks',
  refunds_last_4_weeks = 'refunds_last_4_weeks',
  total_refund_last_4_weeks = 'total_refund_last_4_weeks',
  is_corporate = 'is_corporate',
}

// translation keys corresponding to profile tab's data points -> all used as labels
export enum allowedProfileTabLabelTranslationKeys {
  segment_discount_last_six_months = 'Segment Discount Last 6 Months',
  experience_score = 'Experience Score',
  loyality = 'Loyalty',
  addresses_used = 'Addresses Used in the Last 4 Weeks',
  order_voucher_rate = 'Order/Voucher Rate',
  compensations_last_4_weeks = 'Compensations Last 4 Weeks',
  total_compensation_last_4_weeks = 'Total Compensation Last 4 Weeks',
  refunds_last_4_weeks = 'Refunds Last 4 Weeks',
  total_refund_last_4_weeks = 'Total Refund Last 4 Weeks',
  is_corporate = 'Corporate',
}

// potential customer profile loyality responses -> each response is mapped to a tag color and label in entity configs
export enum customerProfileLoyalityApiResponses {
  acquisition = 'Acquisition',
  loyal = 'Loyal',
  active_frequent = 'Active Frequent',
  active_steady = 'Active Steady',
  active = 'Active',
  churning_royal = 'Churning Loyal',
  churning_one_timer = 'Churning 1 timer',
  churning_multiple_orders = 'Churning multiple orders',
  inactive_loyal = 'Inactive Loyal',
  inactive_multiple_orders = 'Inactive Multiple Orders',
  inactive = 'Inactive',
}

// potential customer profile customer segment responses
export enum customerProfileDiscountGroupApiResponses {
  n = 'N',
  m = 'M',
  h = 'H',
}

// all tag colours used in configs
export enum customerProfileTagColors {
  blue = 'blue',
  green = 'green',
  yellow = 'yellow',
  red = 'red',
  orange = 'orange',
  lime = 'lime',
  lightGrey = '#cfcfcf',
}

// tag translation keys used for loyality data point
export enum customerProfileLoyalityDisplayTagKeys {
  new = 'New',
  loyal = 'Loyal',
  normal = 'Normal',
  churning = 'Churning',
}

// tag translation keys used for Experience Score data point
export enum customerProfileExperienceScoraTagKeys {
  very_happy = 'Very Happy',
  happy = 'Happy',
  sad = 'Sad',
  angry = 'Angry',
}

// tag translation keys used for Experience Score data point
export enum customerCorporateProfileKeys {
  true = 'True',
  false = 'False',
}

// tag translation keys used for several data points in profile tab configs
export enum customerProfileTagAttributes {
  good = 'Good',
  neutral = 'Neutral',
  regular = 'Regular',
  bad = 'Bad',
  very_bad = 'Very Bad',
}

// apac cancellation stages
export enum ApacCancellationStages {
  post_delivery = 'POST_DELIVERY',
  delivery = 'DELIVERY',
  preparation = 'PREPARATION',
  confirmation = 'CONFIRMATION',
}

// apac cancellation reasons
export enum ApacCancellationReasons {
  never_delivered = 'NEVER_DELIVERED',
  food_quality_spillage = 'FOOD_QUALITY_SPILLAGE',
  wrong_order_items_delivered = 'WRONG_ORDER_ITEMS_DELIVERED',
  mistake_error = 'MISTAKE_ERROR',
  delivery_eta_too_long = 'DELIVERY_ETA_TOO_LONG',
  no_courier = 'NO_COURIER',
  voucher_not_applied = 'VOUCHER_NOT_APPLIED',
  order_modification_not_possible = 'ORDER_MODIFICATION_NOT_POSSIBLE',
  dublicate_order = 'DUPLICATE_ORDER',
}

// apac cancellation reasons translation keys
export enum ApacCancellationReasonsTranslationKeys {
  never_delivered = 'Never Delivered',
  food_quality_spillage = 'Food Quality Spillage',
  wrong_order_items_delivered = 'Wrong Order Items Delivered',
  mistake_error = 'Change of mind - not accepted by vendor',
  delivery_eta_too_long = 'Delivery ETA Too Long',
  no_courier = 'No Courier',
  voucher_not_applied = 'Voucher Not Applied',
  order_modification_not_possible = 'Order Modification Not Possible',
  dublicate_order = 'Duplicate Order',
}

// apac cancellation reasons
export enum ApacCancellationReasonsLocal {
  customer_voucher_not_applied = 'CUSTOMER_VOUCHER_NOT_APPLIED',
  customer_order_modification = 'CUSTOMER_ORDER_MODIFICATION',
  customer_does_not_want_to_wait = 'CUSTOMER_DOES_NOT_WANT_TO_WAIT',
  customer_wait_longer_than_expected = 'CUSTOMER_WAIT_LONGER_THAN_EXPECTED',
  customer_accidental_order = 'CUSTOMER_ACCIDENTAL_ORDER',
  customer_never_received_the_order = 'CUSTOMER_NEVER_RECEIVED_THE_ORDER',
  customer_duplicate_order = 'CUSTOMER_DUPLICATE_ORDER',
  customer_changed_mind = 'CUSTOMER_CHANGED_MIND',
  customer_changed_mind_contact_after_5_min = 'CUSTOMER_CHANGED_MIND_CONTACT_AFTER_5_MIN',
  customer_refused_to_pay = 'CUSTOMER_REFUSED_TO_PAY',
  customer_unprofessional_behaviour = 'CUSTOMER_UNPROFESSIONAL_BEHAVIOUR',
  post_delivery_inedible_expired_condition = 'POST_DELIVERY_INEDIBLE_EXPIRED_CONDITION',
  post_delivery_item_damaged = 'POST_DELIVERY_ITEM_DAMAGED',
  post_delivery_spilled_food = 'POST_DELIVERY_SPILLED_FOOD',
  post_delivery_too_late = 'POST_DELIVERY_TOO_LATE',
  post_delivery_wrong_order = 'POST_DELIVERY_WRONG_ORDER',
  post_delivery_content_wrong_misleading = 'POST_DELIVERY_CONTENT_WRONG_MISLEADING',
}

// apac cancellation reasons translation keys
export enum ApacCancellationReasonsTranslationKeysLocal {
  customer_voucher_not_applied = 'Promotion/voucher not applied',
  customer_order_modification = 'Order modification not possible',
  customer_does_not_want_to_wait = 'Order is late, customer does not want to wait',
  customer_wait_longer_than_expected = 'Order will take longer than expected',
  customer_accidental_order = 'Accidental order or preorder by mistake',
  customer_never_received_the_order = 'Customer never received the order',
  customer_duplicate_order = 'Duplicate order',
  customer_changed_mind = 'Change of mind - not accepted by vendor',
  customer_changed_mind_contact_after_5_min = 'Change of mind - accepted by vendor (NO RF)',
  customer_refused_to_pay = 'Customer refuses/is unable to pay for the order',
  customer_unprofessional_behaviour = 'Courier or vendor were unprofessional',
  post_delivery_inedible_expired_condition = 'Customer received food in inedible/expired condition',
  post_delivery_item_damaged = 'Customer received damaged item',
  post_delivery_spilled_food = 'Customer received food totally spilled',
  post_delivery_too_late = 'Customer received order too late',
  post_delivery_wrong_order = 'Customer received wrong order',
  post_delivery_content_wrong_misleading = 'Images of the food were misleading',
}

// apac cancellation reasons
export enum ApacCancellationReasonsForRiderService {
  traffic_manager_incorrect_address = 'TRAFFIC_MANAGER_INCORRECT_ADDRESS',
  traffic_manager_no_rider = 'TRAFFIC_MANAGER_NO_RIDER',
  traffic_manager_outside_delivery_area = 'TRAFFIC_MANAGER_OUTSIDE_DELIVERY_AREA',
  traffic_manager_outside_of_service_hours = 'TRAFFIC_MANAGER_OUTSIDE_OF_SERVICE_HOURS',
  traffic_manager_rider_accident = 'TRAFFIC_MANAGER_RIDER_ACCIDENT',
  traffic_manager_rider_unreachable = 'TRAFFIC_MANAGER_RIDER_UNREACHABLE',
  traffic_manager_technical_issue = 'TRAFFIC_MANAGER_TECHNICAL_ISSUE',
  traffic_manager_unable_find_customer = 'TRAFFIC_MANAGER_UNABLE_FIND_CUSTOMER',
  traffic_manager_vendor_closed = 'TRAFFIC_MANAGER_VENDOR_CLOSED',
  traffic_manager_weather_issue = 'TRAFFIC_MANAGER_WEATHER_ISSUE',
  traffic_manager_unsafe_delivery_area = 'TRAFFIC_MANAGER_UNSAFE_DELIVERY_AREA',
  // RESTAURANT - 63
  vendor_deal_problems = 'VENDOR_DEAL_PROBLEMS',
  vendor_product_unavailable = 'VENDOR_PRODUCT_UNAVAILABLE',
  vendor_technical_problem = 'VENDOR_TECHNICAL_PROBLEM',
  vendor_unreachable = 'VENDOR_UNREACHABLE',
  vendor_not_responding = 'VENDOR_NOT_RESPONDING',
  vendor_too_busy = 'VENDOR_TOO_BUSY',
  vendor_wrong_menu = 'VENDOR_WRONG_MENU',
  vendor_prank_fraud = 'VENDOR_PRANK_FRAUD',
  vendor_will_not_work_with_platform = 'VENDOR_WILL_NOT_WORK_WITH_PLATFORM',
  // CUSTOMER - 64
  customer_voucher_not_applied = 'CUSTOMER_VOUCHER_NOT_APPLIED',
  customer_order_modification = 'CUSTOMER_ORDER_MODIFICATION',
  customer_does_not_want_to_wait = 'CUSTOMER_DOES_NOT_WANT_TO_WAIT',
  customer_accidental_order = 'CUSTOMER_ACCIDENTAL_ORDER',
  customer_duplicate_order = 'CUSTOMER_DUPLICATE_ORDER',
  customer_changed_mind = 'CUSTOMER_CHANGED_MIND',
  customer_changed_mind_contact_after_5_min = 'CUSTOMER_CHANGED_MIND_CONTACT_AFTER_5_MIN',
  customer_refused_to_pay = 'CUSTOMER_REFUSED_TO_PAY',
  post_delivery_inedible_expired_condition = 'POST_DELIVERY_INEDIBLE_EXPIRED_CONDITION',
  post_delivery_spilled_food = 'POST_DELIVERY_SPILLED_FOOD',
  post_delivery_wrong_order = 'POST_DELIVERY_WRONG_ORDER',
  customer_unprofessional_behaviour = 'CUSTOMER_UNPROFESSIONAL_BEHAVIOUR',
}

// apac cancellation reasons translation keys
export enum ApacCancellationReasonsTranslationKeysForRiderService {
  traffic_manager_incorrect_address = 'Incorrect address',
  traffic_manager_no_rider = 'No rider available',
  traffic_manager_outside_delivery_area = 'Outside of delivery area',
  traffic_manager_outside_of_service_hours = 'Outside service hours',
  traffic_manager_rider_accident = 'Rider accident',
  traffic_manager_rider_unreachable = 'Rider is unreachable',
  traffic_manager_technical_issue = 'Technical issue',
  traffic_manager_unable_find_customer = 'Unable to find or reach customer',
  traffic_manager_vendor_closed = 'Vendor closed',
  traffic_manager_weather_issue = 'Weather problems',
  traffic_manager_unsafe_delivery_area = 'Unsafe delivery area',
  // RESTAURANT - 63
  vendor_deal_problems = 'Deal problems',
  vendor_product_unavailable = 'Product unavailable',
  vendor_technical_problem = 'Technical problem',
  vendor_unreachable = 'Vendor unreachable/no heartbeat',
  vendor_not_responding = 'Vendor did not respond to order',
  vendor_too_busy = 'Restaurant too busy',
  vendor_wrong_menu = 'Wrong menu',
  vendor_prank_fraud = 'Fraud/Prank Order',
  vendor_will_not_work_with_platform = "Vendor doesn't want to work with the platform anymore",
  // CUSTOMER - 64
  customer_voucher_not_applied = 'Promotion/voucher not applied',
  customer_order_modification = 'Order modification not possible',
  customer_does_not_want_to_wait = 'Order is late, customer does not want to wait',
  customer_accidental_order = 'Accidental order or preorder by mistake',
  customer_duplicate_order = 'Duplicate order',
  customer_changed_mind = 'Change of mind - not accepted by vendor',
  customer_changed_mind_contact_after_5_min = 'Change of mind - accepted by vendor (NO RF)',
  customer_refused_to_pay = 'Customer refuses/is unable to pay for the order',
  post_delivery_inedible_expired_condition = 'Customer received food in inedible condition',
  post_delivery_spilled_food = 'Customer received food totally spilled',
  post_delivery_wrong_order = 'Customer received wrong order',
  customer_unprofessional_behaviour = 'Courier or vendor were unprofessional',
}

// cancellation reasons for PEYA
export enum PeyaCancellationReasons {
  horario_de_entrega_retrasado = 'Horario de entrega retrasado',
  pedido_no_entregado = 'Pedido no entregado',
  falta_de_productos_para_el_pedido = 'Falta de productos para el pedido',
  no_hay_cadete_disponible = 'No hay cadete disponible',
  se_requiere_cargo_extra = 'Se requiere cargo extra',
  el_local_no_entrega = 'El local no entrega en la dirección',
  contenido_inexacto = 'Contenido inexacto',
  pedido_duplicado = 'Pedido duplicado',
  baja_calidad_del_pedido = 'Baja calidad del pedido',
  el_usuario_no_puede_pagar_el_pedido = 'El usuario no puede pagar el pedido',
  no_aplico_el_cupon = 'No aplicó el cupón',
  error_en_los_productos_entregados = 'Error en los productos entregados',
  error_al_realizar_el_pedido = 'Error al realizar el pedido',
  conducta_inapropiada = 'Conducta inapropiada',
}

// cancellation reasons' IDs for PEYA
export enum PeyaCancellationReasonIds {
  horario_de_entrega_retrasado = '34',
  pedido_no_entregado = '29',
  falta_de_productos_para_el_pedido = '7',
  no_hay_cadete_disponible = '16',
  se_requiere_cargo_extra = '24',
  el_local_no_entrega = '1',
  contenido_inexacto = '33',
  pedido_duplicado = '18',
  baja_calidad_del_pedido = '26',
  el_usuario_no_puede_pagar_el_pedido = '30',
  no_aplico_el_cupon = '31',
  error_en_los_productos_entregados = '32',
  error_al_realizar_el_pedido = '28',
  conducta_inapropiada = '36',
}

export enum PeyaCancellationReasonsForRiderService {
  accidente_del_cadete = 'Accidente del cadete',
  baja_calidad_del_pedido = 'Baja calidad del pedido',
  cliente_sospechoso_o_o_deseado = 'Cliente sospechoso o no deseado',
  condiciones_climaticas_adversas = 'Condiciones climáticas adversas',
  conducta_inapropiada = 'Conducta inapropiada',
  contenido_inexacto = 'Contenido inexacto',
  el_local_no_entrega_en_la_direccion = 'El local no entrega en la dirección',
  el_local_no_puede_cumplir_con_las_aclaraciones = 'El local no puede cumplir con las aclaraciones',
  el_local_no_quiere_ser_parte_de_la_plataforma = 'El local no quiere ser parte de la plataforma',
  el_local_no_tiene_lector_de_tarjetas = 'El local no tiene lector de tarjetas',
  el_local_ya_no_esta_disponible_en_la_aplicacion = 'El local ya no está disponible en la aplicación',
  el_pedido_no_llega_al_monto_minimo = 'El pedido no llega al monto mínimo',
  el_usuario_no_puede_pagar_el_pedido = 'El usuario no puede pagar el pedido',
  error_al_realizar_el_pedido = 'Error al realizar el pedido',
  error_en_los_productos_entregados = 'Error en los productos entregados',
  falta_de_productos_para_el_pedido = 'Falta de productos para el pedido',
  faltan_datos_de_la_direccion = 'Faltan datos de la dirección',
  fuera_de_horario = 'Fuera de horario',
  hay_mucha_demanda_en_el_local = 'Hay mucha demanda en el local',
  horario_de_entrega_retrasado = 'Horario de entrega retrasado',
  local_cerrado = 'Local cerrado',
  motivo_desconocido = 'Motivo desconocido',
  no_aplico_el_cupon = 'No aplicó el cupón',
  no_es_posible_contactar_al_cliente = 'No es posible contactar al cliente',
  no_hay_cadete_disponible = 'No hay cadete disponible',
  no_hay_shopper_disponible = 'No hay shopper disponible',
  pedido_de_prueba = 'Pedido de prueba',
  pedido_duplicado = 'Pedido duplicado',
  pedido_entregado_tarde = 'Pedido entregado tarde',
  pedido_falso = 'Pedido falso',
  pedido_no_entregado = 'Pedido no entregado',
  perfil_del_local_desactualizado = 'Perfil del local desactualizado',
  problemas_con_la_facturación = 'Problemas con la facturación',
  problemas_tecnicos = 'Problemas técnicos',
  repartidor_incontactable = 'Repartidor incontactable',
  se_requiere_cargo_extra = 'Se requiere cargo extra',
}

export enum PeyaCancellationReasonIdsForRiderService {
  accidente_del_cadete = '25',
  baja_calidad_del_pedido = '26',
  cliente_sospechoso_o_o_deseado = '19',
  condiciones_climaticas_adversas = '17',
  conducta_inapropiada = '36',
  contenido_inexacto = '33',
  el_local_no_entrega_en_la_direccion = '1',
  el_local_no_puede_cumplir_con_las_aclaraciones = '23',
  el_local_no_quiere_ser_parte_de_la_plataforma = '46',
  el_local_no_tiene_lector_de_tarjetas = '21',
  el_local_ya_no_esta_disponible_en_la_aplicacion = '11',
  el_pedido_no_llega_al_monto_minimo = '2',
  el_usuario_no_puede_pagar_el_pedido = '30',
  error_al_realizar_el_pedido = '28',
  error_en_los_productos_entregados = '32',
  falta_de_productos_para_el_pedido = '7',
  faltan_datos_de_la_direccion = '4',
  fuera_de_horario = '44',
  hay_mucha_demanda_en_el_local = '15',
  horario_de_entrega_retrasado = '34',
  local_cerrado = '3',
  motivo_desconocido = '35',
  no_aplico_el_cupon = '31',
  no_es_posible_contactar_al_cliente = '20',
  no_hay_cadete_disponible = '16',
  no_hay_shopper_disponible = '43',
  pedido_de_prueba = '45',
  pedido_duplicado = '18',
  pedido_entregado_tarde = '27',
  pedido_falso = '38',
  pedido_no_entregado = '29',
  perfil_del_local_desactualizado = '39',
  problemas_con_la_facturación = '41',
  problemas_tecnicos = '12',
  repartidor_incontactable = '42',
  se_requiere_cargo_extra = '24',
}

export enum AllowedDeliveryProviders {
  platform_delivery = 'platform_delivery',
  pickup = 'pickup',
  vendor_delivery = 'vendor_delivery',
}

// RIDER SERVICE
export enum allowedRiderServiceNavbarTabs {
  order = 'order',
  rider = 'rider',
  vendor = 'vendor',
  customer = 'customer',
  historyOfEvents = 'historyOfEvents',
}

// mena possible providers
export enum MenaProviders {
  tgo = 'platform_delivery',
  tmp = 'vendor_delivery',
}

// cancellation reasons for MENA
export enum MenaCancellationReasons {
  duplicate_order = 'Duplicate Order',
  changed_mind_no_reason = 'Changed mind - No reason',
  changed_mind_mistake_error = 'Changed mind - Mistake / Error',
  application_issue_it = 'Application issue / IT',
  late_delivery = 'Late delivery',
  cold_food_spilt_delivery = 'Cold food & spilt delivery',
  food_preparation_quality = 'Food preparation quality',
  missing_wrong_order = 'Missing / Wrong Order',
  unprofessional_behaviour_from_the_driver = 'Unprofessional behaviour from the driver',
  lack_of_delivery_men = 'Lack of delivery men',
  test_order = 'Test Order',
  fake_order = 'Fake Order',
  unavailable_menu_items = 'Unavailable menu items',
  no_response_from_the_customer_upon_delivery = 'No response from the customer (Upon Delivery)',
  wrong_misleading_content = 'Wrong / misleading content',
  too_busy = 'Too busy',
  difference_in_order_amount = 'Difference in order amount',
  late_preparation_from_the_restaurant = 'Late preparation from the restaurant (TGO)',
  incomplete_wrong_address = 'Incomplete / Wrong Address',
  do_not_deliver_to_this_area = 'Do not deliver to this area',
  order_modification_not_possible_or_extra_charge = 'Order modification not possible or extra charge',
  card_reader_not_available = 'Card Reader not available',
  bad_weather = 'Bad Weather',
  courier_accident = 'Courier Accident',
  delivery_eta_too_long = 'Delivery ETA too long',
  never_delivered = 'Never Delivered',
  non_food_vertical_vendor_cancellation = 'Non Food Vertical - Vendor Cancellation',
  vendor_closed = 'Vendor Closed',
  internet_issue = 'Electricity/Internet issue',
  vendor_device_issue = 'Vendor device issue',
  vendor_refused_to_prepare_the_order = 'Vendor refused to prepare the order',
  vendor_location_is_incorrect = 'Vendor location is incorrect',
  voucher_not_applied = 'Voucher not applied',
}

export enum MenaCancellationReasonsTranslationKeys {
  duplicate_order = 'Duplicate Order',
  changed_mind_no_reason = 'Changed mind - No reason',
  changed_mind_mistake_error = 'Changed mind - Mistake / Error',
  application_issue_it = 'Application issue / IT',
  late_delivery = 'Late delivery',
  cold_food_spilt_delivery = 'Cold food & spilt delivery',
  food_preparation_quality = 'Food preparation quality',
  missing_wrong_order = 'Missing / Wrong Order',
  unprofessional_behaviour_from_the_driver = 'Unprofessional behaviour from the driver',
  lack_of_delivery_men = 'Lack of delivery men',
  test_order = 'Test Order',
  fake_order = 'Fake Order',
  unavailable_menu_items = 'Unavailable menu items',
  no_response_from_the_customer_upon_delivery = 'No response from the customer (Upon Delivery)',
  wrong_misleading_content = 'Wrong / misleading content',
  too_busy = 'Too busy',
  difference_in_order_amount = 'Difference in order amount',
  late_preparation_from_the_restaurant = 'Late preparation from the restaurant (TGO)',
  incomplete_wrong_address = 'Incomplete / Wrong Address',
  do_not_deliver_to_this_area = 'Do not deliver to this area',
  order_modification_not_possible_or_extra_charge = 'Order modification not possible or extra charge',
  card_reader_not_available = 'Card Reader not available',
  bad_weather = 'Bad Weather',
  courier_accident = 'Courier Accident',
  delivery_eta_too_long = 'Delivery ETA too long',
  never_delivered = 'Never Delivered',
  non_food_vertical_vendor_cancellation = 'Non Food Vertical - Vendor Cancellation',
  vendor_closed = 'Vendor Closed',
  internet_issue = 'Electricity/Internet issue',
  vendor_device_issue = 'Vendor device issue',
  vendor_refused_to_prepare_the_order = 'Vendor refused to prepare the order',
  vendor_location_is_incorrect = 'Vendor location is incorrect',
  voucher_not_applied = 'Voucher not applied',
}

// tel-link is for Toku support
// standard is for phone number only with clickable icon
export enum PhoneDecorators {
  none = 'none',
  standard = 'standard',
  telLink = 'tel-link',
  mircobase = 'microbase',
}
