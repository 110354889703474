export default {
  overlay: {
    position: 'absolute',
    left: 0,
    top: 0,
    zIndex: 1,
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
  },

  spinner: {
    marginBottom: '18px',
  },
  loadingText: {
    color: '#828281',
    fontSize: '16px',
  },
}
