import { TOptionsBase } from 'i18next'
import { useMemo } from 'react'
import { useTranslation as useTranslationI8 } from 'react-i18next'
import { Translation } from 'types/translation'
import { logError } from 'utils/reporting/logError'
import { interpolate } from 'utils/string/interpolate'

interface TOptions extends TOptionsBase {
  /**
   * with this, it uses the last key as the fallback if translation
   * is not found
   */
  useLastKeyAsFallback?: boolean

  /**
   * when there is a missing replacer, this will be used
   */
  fallbackReplaceValue?: string | undefined | number
}

export type TranslateFunction = (key: keyof Translation | string, options?: TOptions) => string

const reportedKeys = new Set<string>()

const unusableFallbackKeys = new Set(['label', 'title', 'subtitle', 'description'])

/**
 * our typed translator
 * @returns
 */
export const useTranslation = () => {
  const { t, i18n } = useTranslationI8()

  const language = i18n.language

  return useMemo((): { i18n: typeof i18n; t: TranslateFunction } => {
    return {
      i18n,

      /**
       * if options is set as true, it will use the last of the key
       * as fallback value if translation is not found
       *
       * if options is a string, it will be used as the fallback value
       *
       * this method logs error to the console for reporting if translation
       * is not found and there is no fallback value
       */
      t: (key: keyof Translation | string, options?: TOptions) => {
        // if no key, return empty string
        if (!key) {
          return ''
        }

        const replacers = { dot: '.', ...options?.replace }

        const value = t(key, {
          ...options,
          defaultValue: null,
        })

        if (value !== null) {
          return interpolate(value, replacers, { defaultValue: options?.fallbackReplaceValue })
        }

        // report missing key
        if (!reportedKeys.has(key)) {
          reportedKeys.add(key)
          logError({
            type: 'missing-translation-key',
            key,
            language,
          })
        }

        if (typeof options?.defaultValue !== 'undefined') {
          return options.defaultValue
        }

        const keys = key.split('.')
        let fallbackKey = ''

        while (!fallbackKey && keys.length) {
          const key = keys.pop()
          if (key && !unusableFallbackKeys.has(key)) {
            fallbackKey = key
            break
          }
        }

        return interpolate(fallbackKey || key, replacers, {
          defaultValue: options?.fallbackReplaceValue,
        })
      },
    }
  }, [t, i18n, language])
}
