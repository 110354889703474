import React from 'react'
import { Dropdown, DropDownProps } from 'antd'
import { getInlineAntdPopupNode } from 'utils/antd/getInlinePopupNode'

export type InlineDropdownProps = DropDownProps

/**
 * @deprecated Use SelectBox
 */
export const InlineDropdown = (props: InlineDropdownProps) => (
  <Dropdown getPopupContainer={getInlineAntdPopupNode} {...props} />
)
