import {
  UnifiedVendorAvailabilityStatus,
  VendorAvailability,
} from 'services/vendorApi/getVendorAvailability'

export const getUnifiedVendorAvailabilityStatus = (
  availability: VendorAvailability,
): UnifiedVendorAvailabilityStatus => {
  switch (availability.schedule_status) {
    case 'VENDOR_SCHEDULE_STATUS_CLOSED':
      return 'Closed'

    case 'VENDOR_SCHEDULE_STATUS_OPEN':
      if (availability.offline_status === 'OFFLINE_STATUS_UNAVAILABLE') {
        return 'Offline'
      }

      // offline_status is AVAILABLE or INVALID
      return 'Open'

    default:
      return 'Unavailable'
  }
}
