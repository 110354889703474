import { logError } from './logError'

/**
 * Helper function to abstract repetitive logic of logging copy error
 */
export const logCopyError = (originalError?: Error): void => {
  logError({
    originalError,
    type: 'cannot-copy',
    message: 'Failed to copy',
  })
}
