import { ApiEndpointName, ApiNamespace } from 'contexts/apiClientCreator/ApiClientCreatorContext'
import { ApiErrorPayload } from 'types/error'

export type ApiErrorLevel = 'fatal' | 'warning'

export type ApiErrorScreenType =
  | 'warning'
  | 'error'
  | 'alert'
  | 'instruct'
  | 'budget'
  | 'timeout'
  | 'info'
  | 'no-data'
  | 'config'

export interface ApiEndpointErrorConfig
  extends Pick<ApiErrorPayload, 'context' | 'tParams' | 'id'> {
  /**
   * context past is simply context turned into a past tense form
   */
  contextPast?: string

  screen: ApiErrorScreenType

  heading: string

  copyText: string

  /**
   * causes the application to show retryButton
   */
  enableRetry?: boolean

  /**
   * causes the application to show a goback button
   */
  enableGoBack?: boolean

  /**
   * this will refresh the agents pemissions before retrying
   */
  refreshPermissionBeforeRetry?: boolean
}

export type ApiErrorConfig<Namespace extends ApiNamespace> = {
  [p in Namespace]: Partial<{
    [p in ApiEndpointName]: Partial<{
      [p in number]: Partial<{
        [p in string | '*']: ApiEndpointErrorConfig
      }>
    }>
  }>
}

export type ApiErrorConfigs = Partial<ApiErrorConfig<ApiNamespace>>

const continueWithoutRecommendValue: ApiEndpointErrorConfig = {
  screen: 'alert',
  heading: 'Continue without recommended value',
  copyText: "Couldn't get proposed value for this issue type",
}

export const voucherApiErrorConfig: ApiErrorConfig<'VoucherApi'> = {
  VoucherApi: {
    getCustomerCompensationsAndRefunds: {
      400: {
        '*': {
          screen: 'alert',
          heading: 'Pay attention to duplicate compensation',
          copyText: "Couldn't get past compensation and refunds due to error HTTP 400",
        },
      },
      401: {
        '*': {
          screen: 'alert',
          heading: 'Refunds and compensations unavailable due to error',
          copyText: 'GetRefundsCompensations HTTP 401',
        },
      },
      403: {
        '*': {
          screen: 'instruct',
          heading: 'Ask a supervisor to check Oneview permissions',
          copyText: 'Permission required to get past compensation and refunds',
        },
      },
      408: {
        '*': {
          screen: 'timeout',
          heading: 'Refunds and compensations unavailable due to timeout',
          copyText: 'GetRefundsCompensations HTTP 408',
        },
      },
      500: {
        '*': {
          screen: 'alert',
          heading: 'Pay attention to duplicate compensation',
          copyText: "Couldn't get past compensation and refunds due to error HTTP 500",
        },
      },
    },

    getProposedCompensationValue: {
      400: {
        '*': continueWithoutRecommendValue,
      },
      401: {
        '*': continueWithoutRecommendValue,
      },
      403: {
        '*': {
          screen: 'warning',
          heading: 'Ask a supervisor to check Oneview permissions',
          copyText: 'Permission required to get proposed value',
        },
      },
      404: {
        '*': {
          screen: 'warning',
          heading: 'Ask a supervisor to configure proposed value',
          copyText: 'Proposed value missing for this issue type',
        },
      },
      408: {
        '*': continueWithoutRecommendValue,
      },
      500: {
        '*': continueWithoutRecommendValue,
      },
    },

    createVoucher: {
      400: {
        '*': {
          screen: 'error',
          heading: "Couldn't create voucher",
          copyText: 'An error has occured{{dot}} Come back later',
        },
        'voucher value limit exceeded': {
          screen: 'alert',
          heading: 'Voucher value exceeds limit',
          copyText:
            "Couldn't create {{context}}{{dot}} Ask a supervisor to check Oneview value limit, then try again",
          enableRetry: true,
        },
        METHOD_NOT_ALLOWED: {
          screen: 'error',
          heading: '{{context}} to voucher not allowed',
          copyText: "Couldn't create voucher",
        },
        INVALID_REFUND_REASON: {
          screen: 'alert',
          heading: `Selected issue type is not valid`,
          copyText: 'Voucher not created because the refund reason provided is invalid',
        },
      },

      401: {
        '*': {
          screen: 'warning',
          heading: "Couldn't create voucher",
          copyText: 'An error has occured{{dot}} Select retry to try again',
        },
      },

      403: {
        '*': {
          screen: 'instruct',
          heading: 'Permission required',
          copyText:
            '{{context}} not created{{dot}} Ask a supervisor to check the Oneview permissions and try again',
        },
      },

      404: {
        '*': {
          screen: 'error',
          heading: "Couldn't create voucher",
          copyText: "Order doesn't exist in platform database",
        },
      },

      408: {
        '*': {
          screen: 'timeout',
          heading: 'Connection timeout',
          copyText: "Couldn't create voucher{{dot}} Select retry to try again",
        },
        INVALID_REFUND_REASON: {
          screen: 'error',
          heading: 'Invalid refund reason',
          copyText: 'Selected issue type is not valid',
        },
      },

      409: {
        ALREADY_COMPENSATED: {
          screen: 'error',
          heading: 'Already compensated',
          copyText: 'Compensation already issued for this reason',
        },
      },
      429: {
        TOO_MANY_REQUESTS: {
          screen: 'error',
          heading: 'Too many requests',
          copyText: 'Too many requests, please try again later',
        },
      },
      503: {
        UPSTREAM_SERVICE_UNAVAILABLE: {
          screen: 'error',
          heading: 'Upstream service unavailable',
          copyText: 'Upstream service is unable to process the request',
        },
      },

      412: {
        '*': {
          screen: 'instruct',
          heading: 'Permission required',
          copyText:
            "Couldn't create voucher{{dot}} Ask a supervisor to check Oneview permissions and select retry to try again",
        },
      },

      422: {
        PENDING_ORDER: {
          screen: 'alert',
          heading: 'Vendor must accept order',
          copyText:
            '{{context}} not issued due to order status{{dot}} Try again after Vendor accepts order',
        },
        AMOUNT_EXCEEDED: {
          screen: 'error',
          heading: "Couldn't create voucher",
          copyText: 'Order value exceeds limit set by external system',
        },
        FRAUD_POSITIVE: {
          screen: 'error',
          heading: 'Fraudulent customer suspected',
          copyText: "Couldn't create voucher",
        },
        REFUND_NOT_POSSIBLE: {
          screen: 'error',
          heading: 'Refund not possible',
          copyText: "Couldn't create voucher",
        },
        NO_PAYMENTS_TO_REFUND: {
          screen: 'error',
          heading: 'No payments to refund',
          copyText: "Couldn't create voucher",
        },
        OUT_OF_BUDGET: {
          screen: 'budget',
          heading: 'Out of budget',
          copyText: 'Unable to create more vouchers until midnight{{dot}} Contact a supervisor',
          enableGoBack: false,
        },
        WRONG_REASON_FOR_ALL_ITEM_SELECTED: {
          screen: 'error',
          heading: 'Wrong reason for all items selected',
          copyText: 'All items cannot be selected for the given reason',
        },
        NOT_ALL_ITEMS_SELECTED: {
          screen: 'error',
          heading: 'Not all items selected',
          copyText: 'Not all items selected',
        },
        ALREADY_REFUNDED: {
          screen: 'error',
          heading: 'Order already refunded',
          copyText: 'Order has a refund',
        },
        ORDER_HAS_REFUND: {
          screen: 'error',
          heading: 'Order has a refund',
          copyText: 'Order has a refund',
        },
        ORDER_HAS_REDEMPTION_COMPENSATION: {
          screen: 'error',
          heading: 'Order already compensated',
          copyText: 'Order already compensated',
        },
        NOT_ALLOW_REFUND: {
          screen: 'error',
          heading: 'Error creating the refund',
          copyText: "Can't create refund, refund not allowed",
        },
        MISSING_ITEMS_CASE_DISABLED: {
          screen: 'error',
          heading: 'Missing items case disabled',
          copyText: 'Refund allowed in own delivery orders',
        },
        ZERO_AMOUNT_TO_REFUND: {
          screen: 'error',
          heading: 'Zero amount to refund',
          copyText: 'Zero amount is available for refund for this request',
        },
        INVALID_REFUND_REASON: {
          screen: 'error',
          heading: 'Invalid refund reason',
          copyText: 'Selected issue type is not valid',
        },
      },

      500: {
        '*': {
          screen: 'error',
          heading: "Couldn't create voucher",
          copyText: 'An error has occured{{dot}} Come back later',
        },
      },
    },

    getOrderCompensationsAndRefunds: {
      400: {
        '*': {
          screen: 'error',
          heading: 'Refund data unavailable',
          copyText: 'GetOrderCompensationsAndRefunds HTTP400',
        },
      },
      401: {
        '*': {
          screen: 'warning',
          heading: 'Refund data unavailable',
          copyText: 'GetOrderCompensationsAndRefunds HTTP401',
        },
      },
      403: {
        '*': {
          screen: 'instruct',
          heading: 'Ask a supervisor to check Oneview permissions',
          copyText: 'Permission required to get refund data',
        },
      },
      408: {
        '*': {
          screen: 'timeout',
          heading: 'Refund data unavailable due to connection timeout',
          copyText: 'GetOrderCompensationsAndRefunds HTTP408',
        },
      },
      500: {
        '*': {
          screen: 'error',
          heading: 'Refund data unavailable due to error',
          copyText: 'GetOrderCompensationsAndRefunds HTTP500',
        },
      },
    },

    getCustomerVouchers: {
      400: {
        '*': {
          screen: 'error',
          heading: 'Vouchers unavailable',
          copyText: 'An error has occured',
        },
      },
      401: {
        '*': {
          screen: 'warning',
          heading: 'Vouchers unavailable',
          copyText: 'An error has occured{{dot}} Select refresh to try again',
        },
      },
      403: {
        '*': {
          screen: 'instruct',
          heading: 'Permission required',
          copyText: 'Ask a supervisor to check Oneview permissions',
        },
      },
      404: {
        '*': {
          screen: 'no-data',
          heading: 'No vouchers found',
          copyText: '',
        },
      },
      408: {
        '*': {
          screen: 'timeout',
          heading: 'Connection timeout',
          copyText: '',
        },
      },
      500: {
        '*': {
          screen: 'error',
          heading: 'Vouchers unavailable',
          copyText: 'An error has occured',
        },
      },
    },

    getVoucherByCode: {
      404: {
        '*': {
          screen: 'error',
          heading: 'Voucher Not Found',
          copyText: 'Searched voucher could not be found',
        },
      },
    },
  },
}
