import React, { useEffect, useMemo } from 'react'
import { useFlexibleAmountStyles } from './FlexibleAmount.style'
import { Button, Form, InputNumber, Space } from 'antd'
import { useTranslation } from 'hooks/useTranslation'
import { Text } from 'shared'
import { RefundIssueType } from 'contexts/entity/types'
import { useCaptureUserAction } from 'hooks/events/useCaptureUserAction'
import { AmountHelperText } from './AmountHelperText'

type Props = {
  currency: string
  refundAmount?: number
  refundIssueType: RefundIssueType
  onGoBack?: () => void
  onContinue?: () => void
  onChange?: (value: number) => void
}

export const FlexibleAmount = ({
  currency,
  refundAmount,
  refundIssueType,
  onGoBack,
  onContinue,
  onChange,
}: Props) => {
  const { t } = useTranslation()
  const [form] = Form.useForm()
  const classes = useFlexibleAmountStyles()
  const captureUserAction = useCaptureUserAction()

  const { min_flexible_amount = 0, max_flexible_amount = 0 } = refundIssueType

  const valueType = useMemo(() => {
    if (min_flexible_amount === max_flexible_amount) {
      return 'non-editable'
    } else if (min_flexible_amount > 0 && max_flexible_amount > 0) {
      return 'range'
    } else {
      return 'upper-limit'
    }
  }, [min_flexible_amount, max_flexible_amount])

  useEffect(() => {
    if (!refundAmount && min_flexible_amount) {
      onChange(min_flexible_amount)
    }
  }, [refundAmount, onChange, min_flexible_amount])

  const handleAmountChange = ({ amount }) => {
    onChange(Math.min(Math.max(amount, min_flexible_amount), max_flexible_amount))
  }

  const handleContinueClick = () => {
    captureUserAction('CONTINUE_FLEXIBLE_PARTIAL_REFUND', {
      eventDetails: {
        lower_limit: min_flexible_amount,
        upper_limit: max_flexible_amount,
        updated_amount: Number(form.getFieldValue('amount')),
        type: valueType,
      },
    })
    onContinue()
  }

  return (
    <div className={classes.container}>
      <Form
        layout={'vertical'}
        form={form}
        initialValues={{ amount: min_flexible_amount }}
        onValuesChange={handleAmountChange}
      >
        <Form.Item
          name='amount'
          help={
            <AmountHelperText
              currency={currency}
              type={valueType}
              maxValue={max_flexible_amount}
              minValue={min_flexible_amount}
            />
          }
          label={
            <Text.Secondary fontSize={'14px'}>
              {t('Actions Widget.Actions.Partial Refund.Refund amount')}
            </Text.Secondary>
          }
        >
          <InputNumber
            addonBefore={currency}
            className={classes.numberInput}
            min={min_flexible_amount}
            max={max_flexible_amount || undefined}
            disabled={valueType === 'non-editable'}
          />
        </Form.Item>
        <Form.Item wrapperCol={{ span: 24 }}>
          <Space className={classes.actionBtns} size={14}>
            <Button onClick={onGoBack}>{t('Interface.Go Back')}</Button>
            <Button type='primary' onClick={handleContinueClick} disabled={!refundAmount}>
              {t('Interface.Continue')}
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </div>
  )
}
