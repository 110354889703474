// libs
import React, { useContext } from 'react'
// types
import { DataContext } from 'contexts/data/DataContext'
// styles
import styles from './WarningMessage.styles'
import { createUseStyles } from 'react-jss'
import { Typography, Alert } from 'antd'
import { WarningOutlined } from '@ant-design/icons'
// hooks
import { useTranslation } from 'hooks/useTranslation'
// utils
import { getDeliveryByRiderId } from 'utils/getDeliveryByRiderId'

const useStyles = createUseStyles(styles)

const WarningMessage: React.FC = () => {
  const classes = useStyles()
  const { Text } = Typography
  const { t } = useTranslation()

  const {
    dataState: { rider, order, fulfillment },
  } = useContext(DataContext)

  if (!order) return null

  switch (true) {
    case !fulfillment || !rider?.courier.id:
      return (
        <div className={classes.container}>
          <Alert
            message={
              <>
                <WarningOutlined className={classes.icon} />
                <Text className={classes.text}>
                  {t('Widgets Common.This order may be associated with a different rider')}.
                </Text>
              </>
            }
            type={'warning'}
          ></Alert>
        </div>
      )
    case !getDeliveryByRiderId(fulfillment.deliveries, rider.courier.id):
      return (
        <div className={classes.container}>
          <Alert
            message={
              <>
                <WarningOutlined className={classes.icon} />
                <Text className={classes.text}>
                  {t('Widgets Common.This order is associated with a different rider')}.
                </Text>
              </>
            }
            type={'warning'}
          ></Alert>
        </div>
      )
    default:
      return null
  }
}

export default WarningMessage
