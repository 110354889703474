import React from 'react'
import { TranslateFunction, useTranslation } from 'hooks/useTranslation'
import { OrderApiResponse } from 'types/api/orderApi/order'
import { TimelineItem, TimelineMeta } from 'widgets/Deliveries/types'
import { DeliveryItem, FulfillmentApiResponse } from 'types/api/fulfillmentApi/fulfillment'
import UpdatedDeliveryTime from 'widgets/Deliveries/UpdatedDeliveryTime/UpdatedDeliveryTime'
import { RiderStatuses } from 'types/widgets/rider/riderStatuses'
import { getUpdatedDeliveryTime } from 'utils/order/getUpdatedDeliveryTime'
import { SDK } from 'types'
import { getOrderEstimationStatusForTimeline } from 'utils/order/getOrderEstimationStatusForTimeline'
import { text } from 'theme'
import { calculateOrderDeliveryTimestamps } from 'utils/order/calculateOrderDeliveryTimestamps'

type Props = {
  sdk: SDK
  t?: TranslateFunction
  delivery?: DeliveryItem
  order: OrderApiResponse
  fulfillment: FulfillmentApiResponse
}

export const getRiderEventHistoryForTimeline = ({
  t,
  sdk,
  order,
  fulfillment,
  delivery,
}: Props) => {
  const updatedDeliveryTime = getUpdatedDeliveryTime({ order, fulfillment, sdk })
  const orderPlaceDate = sdk.datetimeFormatter.formatDate(order.place_timestamp)
  const { promisedDropOffTime } = calculateOrderDeliveryTimestamps({
    order,
    fulfillment,
    delivery,
    datetimeFormatter: sdk.datetimeFormatter,
  })
  const formattedPromisedTime = sdk.datetimeFormatter.formatTime(promisedDropOffTime)

  const timeline: TimelineItem[] = []
  let trackStatusDate = orderPlaceDate

  delivery?.courier_events?.forEach((event, idx) => {
    const statusDate = sdk.datetimeFormatter.formatDate(event.timestamp)

    const secondaryTime: TimelineMeta[] = []
    const secondaryText: TimelineMeta[] = []
    const isCurrent = idx === 0

    let hasDateChanged = false
    // has date changed from last tracked date to show status time with new date.
    if (trackStatusDate !== statusDate) {
      trackStatusDate = statusDate
      hasDateChanged = true
    }

    if (hasDateChanged) {
      secondaryTime.push({
        text: sdk.datetimeFormatter.formatDate(event.timestamp),
        color: text.secondary,
      })
    }

    if (event.name === RiderStatuses.notified) {
      secondaryTime.push({
        text: sdk.datetimeFormatter.formatDate(event.timestamp),
      })
    }

    if (event.name === RiderStatuses.delivered) {
      secondaryText.push({
        text: t('Order Widget.Tabs.Status.Order Statuses.Promised'),
        tooltip: updatedDeliveryTime && <UpdatedDeliveryTime deliveryTime={updatedDeliveryTime} />,
      })
      secondaryTime.push({ text: formattedPromisedTime })
    }

    if (event.name === RiderStatuses.pickedUp) {
      secondaryText.push({
        text: t('Order Widget.Tabs.Status.Order Statuses.Commited pick up'),
      })

      secondaryTime.push({
        text: sdk.datetimeFormatter.formatTime(delivery.latest_estimated_pickup_time.arriving_at),
      })
    }

    const statusMeta = sdk.getRiderStatusTextAndColor({ riderStatus: event.name })

    timeline.push({
      key: `${event.timestamp}_${idx}`,
      isCurrent: isCurrent,
      isMilestone: true,
      icon: statusMeta.icon,
      status: {
        main: {
          color: isCurrent ? statusMeta.color : text.disabled,
          text: statusMeta.text,
          fontWeight: isCurrent ? '600' : '400',
        },
        secondary: secondaryText,
      },
      time: {
        main: {
          text: sdk.datetimeFormatter.formatTime(event.timestamp),
        },
        secondary: secondaryTime,
      },
    })
  })
  return timeline
}

export const useRiderHistoryTimeline = ({ sdk, order, fulfillment, delivery }: Props) => {
  const { t } = useTranslation()

  const timeline: TimelineItem[] = []

  if (!delivery.courier) {
    return timeline
  }

  const estimation = getOrderEstimationStatusForTimeline({
    t,
    sdk,
    order,
    delivery,
    fulfillment,
    forRiderHistory: true,
  })

  if (estimation) {
    timeline.push(estimation)
  }

  const riderEventForTimeline = getRiderEventHistoryForTimeline({
    t,
    sdk,
    order,
    delivery,
    fulfillment,
  })

  return timeline.concat(riderEventForTimeline)
}
