import React from 'react'
import { Col, Row } from 'antd'
import { Text } from 'shared/Text'
import { useTranslation } from 'hooks/useTranslation'
import { useDateTimeFormatter } from 'hooks/formatters/useDateTimeFormatter'
import { CustomerDeliveryNotification } from 'types/api/fulfillmentApi/fulfillment'

type Props = {
  notification: CustomerDeliveryNotification
}

export const NotificationItem = ({ notification }: Props) => {
  const { t } = useTranslation()
  const formatter = useDateTimeFormatter()
  const { type, timestamp } = notification
  return (
    <Row justify='space-between'>
      <Col>
        <Text.Text>{t(`widgets.order_deliveries.delivery_notification_types.${type}`)}</Text.Text>
      </Col>
      <Col>
        <Text.Secondary>
          {formatter.formatTime(timestamp)} {formatter.formatDate(timestamp)}
        </Text.Secondary>
      </Col>
    </Row>
  )
}
