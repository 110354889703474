import { ApiService } from 'services/authApi/getPermissions'

export type VendorAddress = {
  address_formatted: string
  address_line: string
  address_type: string
  area_name: string
  area_type: string
  country_code: string
  country_name: string
  grid: string
  id: string
  latitude: number
  longitude: number
  name: string
  postal_code: string
}

export const getVendorShippingAddresses: ApiService<
  { entityId: string; globalVendorId: string },
  VendorAddress[]
> = (createClient, options) => {
  const { entityId, clientParams, globalVendorId } = options
  const uri = `/VendorAPI/v1/vendors/${entityId}/${globalVendorId}/shipping_addresses`

  return createClient({
    endpointName: 'getVendorShippingAddresses',
    expectedResponseStatusCode: 200,
    ...clientParams,
  })
    .get(uri)
    .then((res) => ({
      ...res.data,
    }))
}
