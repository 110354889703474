import React, { useState } from 'react'
import { createUseStyles } from 'react-jss'
import { Alert, Col, Row, Space } from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons'
import { ExpandOrCollapseButton } from './ExpandOrCollapseButton'
import { Text } from 'shared/Text'
import { useTranslation } from 'hooks/useTranslation'
import { NotificationItem } from './NotificationItem'
import { UnifiedIcon } from 'shared/UnifiedIcon'
import { CustomerDeliveryNotification } from 'types/api/fulfillmentApi/fulfillment'

const useStyles = createUseStyles({
  container: {
    margin: '10px 0',
  },
  notifications: {
    paddingTop: '3px',
    flexDirection: 'column',
  },
  note: {
    marginTop: '10px',
  },
})

type Props = {
  notifications?: CustomerDeliveryNotification[]
}

export const Notifications = ({ notifications = [] }: Props) => {
  const { t } = useTranslation()
  const styles = useStyles()
  const [isExpanded, setIsExpanded] = useState(false)
  const visibleNotifications = isExpanded ? notifications : notifications.slice(0, 2)

  if (!notifications.length) return null

  return (
    <div className={styles.container}>
      <Alert
        message={
          <Row gutter={[5, 0]}>
            <Col span={'auto'}>
              <UnifiedIcon icon={'NotificationIcon'} size={26} color='#FAAD14' />
            </Col>
            <Col flex={1}>
              <Row className={styles.notifications} gutter={[0, 5]}>
                {visibleNotifications.map((notification) => (
                  <Col key={notification.id}>
                    <NotificationItem notification={notification} />
                  </Col>
                ))}
                {notifications.length > 2 && (
                  <Col>
                    <ExpandOrCollapseButton
                      isExpanded={isExpanded}
                      onClick={() => setIsExpanded(!isExpanded)}
                    />
                  </Col>
                )}
              </Row>
            </Col>
          </Row>
        }
        type='warning'
      />
      <Text.Secondary>
        <Space className={styles.note}>
          <InfoCircleOutlined />
          {t('widgets.order_deliveries.customer_notified_of_delay')}
        </Space>
      </Text.Secondary>
    </div>
  )
}
