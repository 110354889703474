import { useDateTimeFormatter } from 'hooks/formatters/useDateTimeFormatter'
import { useCallback } from 'react'
import {
  CustomerWalletTransaction,
  CustomerWalletTransactionBreakdown,
} from 'services/paymentApi/getCustomerTransactions'

import { CustomerWalletTransactionType } from 'services/paymentApi/getCustomerTransactions'
import { ReactComponent as PurchaseIcon } from 'Icons/Purchase.svg'
import { ReactComponent as RefundIcon } from 'Icons/Refund.svg'
import { ReactComponent as CashbackIcon } from 'Icons/Cashback.svg'
import { MonetarySign, useNumberFormatter } from 'hooks/formatters/useNumberFormatter'
import { useTranslation } from 'hooks/useTranslation'
import {
  ArrowRightOutlined,
  ArrowUpOutlined,
  MinusCircleOutlined,
  DollarCircleOutlined,
} from '@ant-design/icons'
import { logError } from 'utils/reporting/logError'
import startCase from 'lodash/startCase'

export enum CustomerWalletTransactionPaymentSourceOrDestination {
  WalletBalance = 'Wallet balance',
  COD = 'COD',
  CreditCard = 'Credit card',
  ChangeTransferToWallet = 'Change transfer to wallet',
  MarketingPromotion = 'Marketing promotion',
}

export interface TransformedCustomerWalletTransactionBreakdown
  extends CustomerWalletTransactionBreakdown {
  paymentSourceOrDestination: CustomerWalletTransactionPaymentSourceOrDestination
  key: string
}

export interface TransformedCustomerWalletTransaction
  extends Omit<CustomerWalletTransaction, 'creationDateTime' | 'paymentBreakdown'> {
  transactionDate: string
  transactionTime: string

  icon: typeof CashbackIcon
  title: string

  paymentBreakdown: TransformedCustomerWalletTransactionBreakdown[]

  /**
   * if true, the transaction allows displaying of transaction details
   */
  showDetails?: boolean

  monetarySign: MonetarySign

  amountFormated: string

  vendor: string
}

interface TransactionTypeConfig {
  icon: any
  title: string
  paymentBreakdownDescription: string
  monetarySign?: MonetarySign
}

const unknownTransactionTypeConfig: TransactionTypeConfig = {
  title: '{{transactionType}}',
  paymentBreakdownDescription: `{{paymentSourceOrDestination}}`,
  icon: MinusCircleOutlined,
}

export const transactionTypeConfigs: Partial<{
  [p in CustomerWalletTransactionType]: TransactionTypeConfig
}> = {
  // purchase
  [CustomerWalletTransactionType.Purchase]: {
    icon: PurchaseIcon,
    title: 'Customer Widget.Tabs.Wallet.Purchase',
    paymentBreakdownDescription: `Customer Widget.Tabs.Wallet.Paid with {{paymentSourceOrDestination}}`,
    monetarySign: MonetarySign.Debit,
  },

  // full refund
  [CustomerWalletTransactionType.RefundShopToSource]: {
    icon: RefundIcon,
    title: 'Customer Widget.Tabs.Wallet.Full refund',
    paymentBreakdownDescription: `Customer Widget.Tabs.Wallet.Refunded to {{paymentSourceOrDestination}}`,
    monetarySign: MonetarySign.Credit,
  },
  [CustomerWalletTransactionType.RefundShopToWallet]: {
    icon: RefundIcon,
    title: 'Customer Widget.Tabs.Wallet.Full refund',
    paymentBreakdownDescription: `Customer Widget.Tabs.Wallet.Refunded to {{paymentSourceOrDestination}}`,
    monetarySign: MonetarySign.Credit,
  },

  // partial refund
  [CustomerWalletTransactionType.PartialRefundShopToSource]: {
    icon: RefundIcon,
    title: 'Customer Widget.Tabs.Wallet.Partial refund',
    paymentBreakdownDescription: `Customer Widget.Tabs.Wallet.Refunded to {{paymentSourceOrDestination}}`,
    monetarySign: MonetarySign.Credit,
  },
  [CustomerWalletTransactionType.PartialRefundShopToWallet]: {
    icon: RefundIcon,
    title: 'Customer Widget.Tabs.Wallet.Partial refund',
    paymentBreakdownDescription: `Customer Widget.Tabs.Wallet.Refunded to {{paymentSourceOrDestination}}`,
    monetarySign: MonetarySign.Credit,
  },

  // cash back
  [CustomerWalletTransactionType.MarketingTopUp]: {
    icon: CashbackIcon,
    title: 'Customer Widget.Tabs.Wallet.Cashback',
    paymentBreakdownDescription: `Customer Widget.Tabs.Wallet.Marketing promotion`,
    monetarySign: MonetarySign.Credit,
  },

  // cash
  [CustomerWalletTransactionType.TopUp]: {
    icon: ArrowUpOutlined,
    title: 'Customer Widget.Tabs.Wallet.Top up',
    paymentBreakdownDescription: `Customer Widget.Tabs.Wallet.Top up with {{paymentSourceOrDestination}}`,
    monetarySign: MonetarySign.Credit,
  },

  // cash back due to expiry
  [CustomerWalletTransactionType.MarketingTopUpExpiration]: {
    icon: CashbackIcon,
    title: 'Customer Widget.Tabs.Wallet.Cashback expired',
    paymentBreakdownDescription: `Customer Widget.Tabs.Wallet.Marketing promotion`,
    monetarySign: MonetarySign.Debit,
  },

  // clawback
  [CustomerWalletTransactionType.MarketingTopUpClawback]: {
    icon: CashbackIcon,
    title: 'Customer Widget.Tabs.Wallet.Cashback clawback',
    paymentBreakdownDescription: `Customer Widget.Tabs.Wallet.Marketing promotion`,
    monetarySign: MonetarySign.Debit,
  },

  // cash change
  [CustomerWalletTransactionType.CashChange]: {
    icon: DollarCircleOutlined,
    title: 'Customer Widget.Tabs.Wallet.Cash Change',
    paymentBreakdownDescription: `Customer Widget.Tabs.Wallet.Extra change credited to your balance`,
    monetarySign: MonetarySign.Credit,
  },

  // transfer
  [CustomerWalletTransactionType.TransferWalletToSource]: {
    icon: ArrowRightOutlined,
    title: 'Customer Widget.Tabs.Wallet.Transfer',
    paymentBreakdownDescription: 'Transferred to {{paymentSourceOrDestination}}',
    monetarySign: MonetarySign.Debit,
  },
}

const cashPaymentMethods = new Set(['cash', 'cod'])

export const useTransformCustomerWalletTransactions = () => {
  const dateFormatter = useDateTimeFormatter()
  const numberFormatter = useNumberFormatter()
  const { t } = useTranslation()

  return useCallback(
    (transactions: CustomerWalletTransaction[]): TransformedCustomerWalletTransaction[] => {
      if (!transactions) {
        return [] as TransformedCustomerWalletTransaction[]
      }
      return (
        transactions
          // sort by date in descending order
          .sort((a, b) => Date.parse(b.creationDateTime) - Date.parse(a.creationDateTime))
          .map((transaction) => {
            // report error about unknown customer wallet transaction type
            if (!CustomerWalletTransactionType[transaction.type]) {
              logError({
                type: 'unknown-customer-wallet-transaction-type',
                unknownCustomerWalletTransactionType: transaction.type,
              })
            }

            const {
              id,
              platformReferenceId,
              creationDateTime,
              paymentBreakdown,
              description,
              ...others
            } = transaction
            const transactionTypeConfig =
              transactionTypeConfigs[transaction.type] || unknownTransactionTypeConfig

            const transformedPaymentBreakdown: TransformedCustomerWalletTransactionBreakdown[] =
              paymentBreakdown.map(
                (payment, index): TransformedCustomerWalletTransactionBreakdown => {
                  const { card, paymentMethodName } = payment
                  let paymentSourceOrDestination: any

                  // resolve transaction payment source or destination
                  if (card && card.scheme) {
                    paymentSourceOrDestination =
                      CustomerWalletTransactionPaymentSourceOrDestination.CreditCard
                  } else if (
                    paymentMethodName &&
                    cashPaymentMethods.has(paymentMethodName.toLowerCase())
                  ) {
                    paymentSourceOrDestination =
                      CustomerWalletTransactionPaymentSourceOrDestination.COD
                  }

                  // could not resolve, check transaction type
                  if (!paymentSourceOrDestination) {
                    switch (transaction.type) {
                      case CustomerWalletTransactionType.CashChange:
                        paymentSourceOrDestination =
                          CustomerWalletTransactionPaymentSourceOrDestination.ChangeTransferToWallet
                        break
                      case CustomerWalletTransactionType.TopUp:
                      case CustomerWalletTransactionType.TransferWalletToSource:
                        const name = transaction.paymentBreakdown?.length
                          ? transaction.paymentBreakdown[0].paymentMethodName
                          : CustomerWalletTransactionPaymentSourceOrDestination.CreditCard
                        paymentSourceOrDestination = name as any
                        break
                      case CustomerWalletTransactionType.MarketingTopUp:
                      case CustomerWalletTransactionType.MarketingTopUpExpiration:
                      case CustomerWalletTransactionType.MarketingTopUpClawback:
                        paymentSourceOrDestination =
                          CustomerWalletTransactionPaymentSourceOrDestination.MarketingPromotion
                        break

                      default:
                        paymentSourceOrDestination = paymentMethodName
                          ? startCase(paymentMethodName)
                          : CustomerWalletTransactionPaymentSourceOrDestination.WalletBalance
                        break
                    }
                  }

                  return {
                    ...payment,
                    paymentSourceOrDestination,
                    description: t(transactionTypeConfig.paymentBreakdownDescription, {
                      replace: {
                        paymentSourceOrDestination,
                      },
                      useLastKeyAsFallback: true,
                    }),
                    key: transaction.id + index,
                  }
                },
              )
            let transactionDescription = ''
            let showDetails = true
            let referenceId = platformReferenceId
            let vendor = transaction.description

            switch (transaction.type) {
              case CustomerWalletTransactionType.CashChange:
                referenceId = ''
                vendor = ''
                showDetails = false
                transactionDescription = t(
                  `Customer Widget.Tabs.Wallet.${transformedPaymentBreakdown
                    .map((t) => t.paymentSourceOrDestination)
                    .join(' and ')}`,
                )
                break
              case CustomerWalletTransactionType.TopUp:
                referenceId = ''
                vendor = ''
                transactionDescription = t(
                  `Customer Widget.Tabs.Wallet.${transformedPaymentBreakdown
                    .map((t) => t.paymentSourceOrDestination)
                    .join(' and ')}`,
                )
                break
              case CustomerWalletTransactionType.MarketingTopUp:
              case CustomerWalletTransactionType.MarketingTopUpExpiration:
              case CustomerWalletTransactionType.MarketingTopUpClawback:
                referenceId = ''
                transactionDescription = t('Customer Widget.Tabs.Wallet.Marketing promotion')
                showDetails = false
                break

              case CustomerWalletTransactionType.RefundShopToSource:
              case CustomerWalletTransactionType.RefundShopToWallet:
              case CustomerWalletTransactionType.PartialRefundShopToSource:
              case CustomerWalletTransactionType.PartialRefundShopToWallet:
              case CustomerWalletTransactionType.TransferWalletToSource:
                // don't show vendor for these transaction types because "transaction.description" does not contain vendor name
                vendor = ''
                showDetails = true
                transactionDescription = t(
                  `Customer Widget.Tabs.Wallet.${transformedPaymentBreakdown
                    .map((t) => t.paymentSourceOrDestination)
                    .join(' and ')}`,
                )
                break

              default:
                showDetails = true
                transactionDescription = t(
                  `Customer Widget.Tabs.Wallet.${transformedPaymentBreakdown
                    .map((t) => t.paymentSourceOrDestination)
                    .join(' and ')}`,
                )
                break
            }

            return {
              id,
              icon: transactionTypeConfig.icon,
              platformReferenceId: referenceId,
              transactionDate: dateFormatter.formatDate(creationDateTime),
              transactionTime: dateFormatter.formatTime(creationDateTime),
              title: t(transactionTypeConfig.title, {
                useLastKeyAsFallback: true,
                replace: {
                  transactionType: transaction.type,
                },
              }),
              description: transactionDescription,
              vendor: vendor,
              paymentBreakdown: transformedPaymentBreakdown,
              showDetails: Boolean(showDetails && paymentBreakdown.length),
              monetarySign: transactionTypeConfig.monetarySign,
              amountFormated: numberFormatter.formatDigit({
                value: transaction.totalAmount.amount,
                monetarySign: transactionTypeConfig.monetarySign,
              }),
              ...others,
            }
          })
      )
    },
    [numberFormatter, dateFormatter, t],
  )
}
