export default {
  modal: {
    display: 'flex',
    flexDirection: 'column',
    minWidth: 500,
  },
  titleHolder: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#1295e6',
    padding: 20,
    margin: -24,
  },
  modalTitle: {
    fontSize: '16px',
    color: '#fff',
    marginTop: '7px',
  },
  stepsContainer: {
    minWidth: '500px',
    margin: '10px 0px',
  },
  contentContainer: {
    minWidth: '500px',
    margin: '15px 0px',
  },
}
