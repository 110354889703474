import React, { FunctionComponent } from 'react'
import {
  ApiOutlined,
  DollarCircleOutlined,
  ExclamationCircleOutlined,
  FrownOutlined,
  LockOutlined,
  SettingOutlined,
  WarningOutlined,
} from '@ant-design/icons'
import { ApiEndpointErrorConfig } from 'apiErrorConfigs/voucherApiErrorConfig'

const warningColor = '#FAAD14'
const purpleColor = '#9254DE'
const dangerColor = '#FF4D4F'

type Icon = FunctionComponent<{
  fontSize: number
  color?: string
}>

const createIcon = (
  Icon: typeof SettingOutlined,
  options?: {
    color?: string
  },
): Icon => {
  const { color: defaultColor } = options || {}
  return ({ fontSize = 90, color = defaultColor }) => {
    return <Icon style={{ fontSize, color }} />
  }
}

const AlertIcon = createIcon(WarningOutlined, {
  color: warningColor,
})

const ConfigIcon = createIcon(SettingOutlined, {
  color: warningColor,
})

const PlugIcon = createIcon(ApiOutlined, {
  color: warningColor,
})

const FrownIcon = createIcon(FrownOutlined, {
  color: warningColor,
})

const ErrorIcon = createIcon(ExclamationCircleOutlined, {
  color: dangerColor,
})

const DollarIcon = createIcon(DollarCircleOutlined, {
  color: purpleColor,
})

const InstructIcon = createIcon(LockOutlined, {
  color: purpleColor,
})

export const getApiErrorIcon = (screen: ApiEndpointErrorConfig['screen']): Icon => {
  switch (screen) {
    case 'error':
      return ErrorIcon

    case 'warning':
      return FrownIcon

    case 'budget':
      return DollarIcon

    case 'instruct':
      return InstructIcon

    case 'alert':
      return AlertIcon

    case 'timeout':
      return PlugIcon

    case 'config':
      return ConfigIcon
    case 'no-data':
    default:
      return () => null
  }
}
