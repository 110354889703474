import { EntityContext } from 'contexts/entity/EntityContext'
import { useContext, useMemo } from 'react'
import fixNumber from 'utils/fixNumber'

export enum MonetarySign {
  Debit = '-',
  Credit = '+',
  Auto = 'auto',
}

const resolveMonetarySign = (value: number, sign: MonetarySign) => {
  switch (sign) {
    case MonetarySign.Auto:
      if (value > 0) {
        return '+'
      }
      return ''

    default:
      return sign || ''
  }
}

export const useNumberFormatter = () => {
  const entityConfig = useContext(EntityContext).entityState.entityConfig
  const { currency_digits_after_comma: defaultDigitsAfterComma = 2 } = entityConfig || {}

  return useMemo(() => {
    return {
      MONETARY_SIGN: MonetarySign,

      formatMoney: (value: {
        amount: number
        currency: string
        digitsAfterComma?: number
        sign?: MonetarySign
      }) => {
        const { amount, digitsAfterComma = defaultDigitsAfterComma, sign, currency } = value

        return `${currency} ${resolveMonetarySign(amount, sign)}${fixNumber(
          amount,
          digitsAfterComma,
        )}`.trim()
      },

      formatDigit: (
        value: number | { value: number; digitsAfterComma?: number; monetarySign?: MonetarySign },
      ) => {
        let resolvedValue: number
        let resolvedDigitsAfterComma: number
        let sign = ''

        if (typeof value === 'number') {
          resolvedValue = value
          resolvedDigitsAfterComma = defaultDigitsAfterComma
        } else {
          resolvedValue = value.value
          resolvedDigitsAfterComma = value.digitsAfterComma || defaultDigitsAfterComma
          sign = value.monetarySign || ''
        }
        return sign + fixNumber(resolvedValue, resolvedDigitsAfterComma)
      },
    }
  }, [defaultDigitsAfterComma])
}
