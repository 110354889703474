import { useCallback, useContext, useMemo } from 'react'
import { DeliveryItem } from 'types/api/fulfillmentApi/fulfillment'
import {
  builtinWidgetNames,
  dataPointRequirements,
  DeliveryProviders,
  DisplayRules,
  Params,
} from 'types/unitedUiConfig'
import { getOrderStage } from 'utils/order/getOrderStage'
import { timeDurationToNumericSeconds } from 'utils/string/timeDurationToNumericSeconds'
import { useDateTimeFormatter } from './formatters/useDateTimeFormatter'
import { useIsBetaPermissionGranted } from './useIsBetaPermissionGranted'
import { useSessionState } from './useSessionState'
import { useTranslation } from './useTranslation'
import { useEntityConfig } from './useEntityConfig'
import { useDataState } from './useDataState'
import { DataContext } from 'contexts/data/DataContext'
import findLast from 'lodash/findLast'
import {
  WidgetPermissionRequirement,
  requiredWidgetPermissions,
} from 'constants/requiredWidgetPermissions'
import { useAuthProvider } from 'contexts/auth/AuthProvider'

export interface CheckDisplayRulesOpts {
  dataPointValue?: any

  widgetName?: builtinWidgetNames

  /**
   * order delivery, this is needed for checking courier events rules
   */
  delivery?: DeliveryItem
}

export interface CheckDisplayRulesResult {
  reason?: string
  enabled: boolean
  visible: boolean
}

export const useCheckDisplayRules = () => {
  const isBetaPermissionGranted = useIsBetaPermissionGranted()
  const datetimeFormatter = useDateTimeFormatter()
  const {
    ccrCode,
    host: hostValue,
    uiVersion,
    orderId,
    userId: customerId,
    vendorId,
    riderId,
    caseId,
    lineOfBusiness,
  } = useSessionState()

  const { emittedEvents } = useDataState()
  const { entityPermissions } = useAuthProvider()
  const { entityConfig } = useEntityConfig()
  const { disabled_widget_buttons_by_events = [] } = entityConfig?.layout_v2 ?? {}

  const { t } = useTranslation()

  const {
    dataState: { order },
  } = useContext(DataContext)

  const paramsValues = useMemo(() => {
    return new Map<Params, string>([
      [Params.orderId, orderId],
      [Params.riderId, riderId],
      [Params.customerId, customerId],
      [Params.vendorId, vendorId],
      [Params.caseId, caseId],
    ])
  }, [orderId, customerId, vendorId, riderId, caseId])

  return useCallback(
    (displayRules: DisplayRules, opts?: CheckDisplayRulesOpts): CheckDisplayRulesResult => {
      const checkDisplayRules = (currentDisplayRules: DisplayRules): CheckDisplayRulesResult => {
        const { dataPointValue, delivery: courierDelivery = null, widgetName } = opts || {}

        const orderStatusHistory = order?.status_history || []

        const courierEvents = courierDelivery?.courier_events || []

        const deliveryProvider = order?.delivery?.provider as DeliveryProviders

        const paymentMethod = order?.customer?.payment?.payment_method

        const defaultState = { visible: false, enabled: false }

        if (!currentDisplayRules || currentDisplayRules.ignore) {
          return { ...defaultState, visible: true, enabled: true }
        }

        if (currentDisplayRules.hide) {
          return defaultState
        }

        const {
          beta_blacklist,
          beta_requirements,
          datapoint_requirement,
          order_stages,
          ccr_whitelist,
          delivery_providers,
          hosts,
          lobs,
          ui_versions,
          params,
          payment_methods,
          max_order_age,
          cancel_reasons,
          order_status_history_whitelist,
          order_status_history_blacklist,

          courier_events_blacklist,
          courier_events_whitelist,

          delivery_status_whitelist,
          delivery_providers_rules,
          disable,

          only_pre_orders,
        } = currentDisplayRules

        // check for host
        if (hosts && !hosts.ignore && hosts.value.length && !hosts.value.includes(hostValue)) {
          return defaultState
        }

        // check for lob
        if (lobs && !lobs.ignore && lobs.value.length && !lobs.value.includes(lineOfBusiness)) {
          return defaultState
        }

        // check for ui version
        if (
          ui_versions &&
          !ui_versions.ignore &&
          ui_versions.value.length &&
          !ui_versions.value.includes(uiVersion)
        ) {
          return defaultState
        }

        // check if it is explicitly disabled
        if (disable) {
          return {
            visible: true,
            enabled: false,
            reason: 'errors.display_rules_errors.explicit_disable',
          }
        }

        // check for beta permission blacklist
        if (
          beta_blacklist &&
          !beta_blacklist.ignore &&
          beta_blacklist.value &&
          isBetaPermissionGranted(beta_blacklist.value)
        ) {
          return defaultState
        }

        // disable widget button based on events and configs
        if (disabled_widget_buttons_by_events.length && widgetName) {
          const configuredEventsForWidget =
            disabled_widget_buttons_by_events.find((config) => config.widget_name === widgetName)
              ?.event_names ?? []

          const matchedEventName = configuredEventsForWidget.find((name) => emittedEvents.has(name))

          if (matchedEventName) {
            return {
              visible: true,
              enabled: false,
              reason: t(`errors.display_rules_errors.already_used`),
            }
          }
        }

        // disable widget button if missing required widget permissions
        if (widgetName) {
          const widgetPermissions: WidgetPermissionRequirement =
            requiredWidgetPermissions[widgetName]
          if (widgetPermissions) {
            const grantedAnyOfPermissions = widgetPermissions.anyOf?.length
              ? widgetPermissions.anyOf.filter((widgetPermission) =>
                  entityPermissions.has(widgetPermission),
                )
              : []

            const grantedAllOfPermissions = widgetPermissions.allOf?.length
              ? widgetPermissions.allOf.filter((widgetPermission) =>
                  entityPermissions.has(widgetPermission),
                )
              : []

            const fulfillAnyOfRequirement = widgetPermissions.anyOf?.length
              ? grantedAnyOfPermissions.length
              : true

            const fulfillAllOfRequirement = widgetPermissions.allOf?.length
              ? widgetPermissions.allOf.length === grantedAllOfPermissions.length
              : true

            if (!fulfillAnyOfRequirement || !fulfillAllOfRequirement) {
              return {
                visible: true,
                enabled: false,
                reason: t(`errors.display_rules_errors.missing_permissions`),
              }
            }
          }
        }

        // check for beta permission requirement
        if (
          beta_requirements &&
          !beta_requirements.ignore &&
          beta_requirements.value &&
          !isBetaPermissionGranted(beta_requirements.value)
        ) {
          return defaultState
        }

        // check data point requirement
        if (datapoint_requirement && !datapoint_requirement.ignore && datapoint_requirement.value) {
          switch (datapoint_requirement.value) {
            case dataPointRequirements.displayWhenDataFound:
              if (typeof dataPointValue === 'undefined' || dataPointValue === null) {
                return defaultState
              }
              break

            case dataPointRequirements.always:
              break

            case dataPointRequirements.neverDisplay:
            case dataPointRequirements.never:
              return defaultState
          }
        }

        // check for missing params
        if (params && !params.ignore && params.value.length) {
          for (let i = 0; i < params.value.length; i++) {
            const param = params.value[i]
            if (!Boolean(paramsValues.get(param))) {
              return {
                visible: true,
                enabled: false,
                reason: t(`errors.display_rules_errors.missing_param`, {
                  replace: {
                    param,
                  },
                }),
              }
            }
          }
        }

        // check pre orders
        if (only_pre_orders && !order?.preorder) {
          return {
            visible: true,
            enabled: false,
            reason: t('errors.display_rules_errors.only_pre_orders'),
          }
        }

        // check order stage
        if (order_stages && !order_stages.ignore && order_stages.value.length) {
          const orderStage = order && getOrderStage(order.status_history)
          if (!orderStage) {
            return {
              visible: true,
              enabled: false,
              reason: t('errors.display_rules_errors.no_order_stage'),
            }
          }

          if (!order_stages.value.includes(orderStage)) {
            return {
              visible: true,
              enabled: false,
              reason: t('errors.display_rules_errors.not_allowed_order_stage'),
            }
          }
        }

        // check for ccr whitelist
        if (ccr_whitelist && !ccr_whitelist.ignore && ccr_whitelist.value.length) {
          if (!ccrCode) {
            return {
              visible: true,
              enabled: false,
              reason: t('errors.display_rules_errors.no_ccrs'),
            }
          }
          if (!ccr_whitelist.value.includes(ccrCode)) {
            return {
              visible: true,
              enabled: false,
              reason: t('errors.display_rules_errors.not_allowed_ccrs'),
            }
          }
        }

        // check for delivery provider
        if (delivery_providers && !delivery_providers.ignore && delivery_providers.value.length) {
          if (!deliveryProvider) {
            return {
              visible: true,
              enabled: false,
              reason: t('errors.display_rules_errors.no_delivery_provider'),
            }
          }
          if (!delivery_providers.value.includes(deliveryProvider)) {
            return {
              visible: true,
              enabled: false,
              reason: t('errors.display_rules_errors.not_allowed_delivery_provider'),
            }
          }
        }

        // check order payment method
        if (payment_methods && !payment_methods.ignore && payment_methods.value.length) {
          if (!paymentMethod) {
            return {
              enabled: false,
              visible: true,
              reason: t('errors.display_rules_errors.no_payment_method'),
            }
          }
          if (!payment_methods.value.includes(paymentMethod)) {
            return {
              enabled: false,
              visible: true,
              reason: t('errors.display_rules_errors.not_allowed_payment_method'),
            }
          }
        }

        // check order cancel reason
        if (cancel_reasons && !cancel_reasons.ignore && cancel_reasons.value.length) {
          const cancellationStatus = findLast(orderStatusHistory, (current) =>
            Boolean(current.cancelled),
          )
          if (!cancellationStatus) {
            return {
              enabled: false,
              visible: true,
              reason: t('errors.display_rules_errors.no_cancel_reason'),
            }
          }

          const cancelReason =
            cancellationStatus.cancelled?.details?.reason || cancellationStatus.cancelled?.reason

          if (!cancel_reasons.value.includes(cancelReason)) {
            return {
              enabled: false,
              visible: true,
              reason: t('errors.display_rules_errors.not_allowed_cancel_reason'),
            }
          }
        }

        // check order status history whitelist
        if (
          order_status_history_whitelist &&
          !order_status_history_whitelist.ignore &&
          order_status_history_whitelist.value.length
        ) {
          if (!order) {
            return {
              enabled: false,
              visible: true,
              reason: t('errors.display_rules_errors.no_order_status_history'),
            }
          }

          const allowedStatuses = new Set(
            order_status_history_whitelist.value.map((current) => current.toUpperCase()),
          )

          const orderHasAllowedStatus = orderStatusHistory.some(
            (status) => status?.status && allowedStatuses.has(status.status.toUpperCase()),
          )

          if (!orderHasAllowedStatus) {
            return {
              enabled: false,
              visible: true,
              reason: t('errors.display_rules_errors.not_allowed_order_status_history'),
            }
          }
        }

        // check order status history blacklist
        if (
          order_status_history_blacklist &&
          !order_status_history_blacklist.ignore &&
          order_status_history_blacklist.value.length
        ) {
          if (!order) {
            return {
              enabled: false,
              visible: true,
              reason: t('errors.display_rules_errors.no_order_status_history'),
            }
          }
          const disallowedStatuses = new Set(
            order_status_history_blacklist.value.map((current) => current.toUpperCase()),
          )

          const orderHasDisallowedStatus = orderStatusHistory.some(
            (status) => status?.status && disallowedStatuses.has(status.status.toUpperCase()),
          )

          if (orderHasDisallowedStatus) {
            return {
              enabled: false,
              visible: true,
              reason: t('errors.display_rules_errors.not_allowed_order_status_history'),
            }
          }
        }

        // check courier events whitelist
        if (
          courier_events_whitelist &&
          !courier_events_whitelist.ignore &&
          courier_events_whitelist.value.length
        ) {
          if (!courierDelivery) {
            return {
              enabled: false,
              visible: true,
              reason: t('errors.display_rules_errors.no_courier_events'),
            }
          }

          const allowedCourierEvents = new Set(
            courier_events_whitelist.value.map((current) => current.toUpperCase()),
          )

          const courierHasAllowedEvent = courierEvents.some(
            (event) => event.name && allowedCourierEvents.has(event.name.toUpperCase()),
          )

          if (!courierHasAllowedEvent) {
            return {
              enabled: false,
              visible: true,
              reason: t('errors.display_rules_errors.not_allowed_courier_events'),
            }
          }
        }

        // check courier events blacklist
        if (
          courier_events_blacklist &&
          !courier_events_blacklist.ignore &&
          courier_events_blacklist.value.length
        ) {
          if (!courierDelivery) {
            return {
              enabled: false,
              visible: true,
              reason: t('errors.display_rules_errors.no_courier_events'),
            }
          }

          const disallowedCourierEvents = new Set(
            courier_events_blacklist.value.map((current) => current.toUpperCase()),
          )

          const courierHasDisallowedEvent = courierEvents.some(
            (event) => event.name && disallowedCourierEvents.has(event.name.toUpperCase()),
          )

          if (courierHasDisallowedEvent) {
            return {
              enabled: false,
              visible: true,
              reason: t('errors.display_rules_errors.not_allowed_courier_events'),
            }
          }
        }

        // check order age
        if (max_order_age && max_order_age.value && !max_order_age.ignore) {
          const maxOrderAgeInMilliSeconds =
            timeDurationToNumericSeconds(max_order_age.value, max_order_age.unit) * 1000
          if (!order?.promised_customer_timestamp) {
            return {
              enabled: false,
              visible: true,
              reason: t('errors.display_rules_errors.no_order_age'),
            }
          }

          const orderAgeInMilliSeconds = datetimeFormatter.getTimeDifference(
            order.promised_customer_timestamp,
          )

          if (orderAgeInMilliSeconds >= maxOrderAgeInMilliSeconds) {
            return {
              enabled: false,
              visible: true,
              reason: t('errors.display_rules_errors.not_allowed_order_age', {
                replace: {
                  age: max_order_age.value,
                  unit: max_order_age.unit,
                },
              }),
            }
          }
        }

        // validate delivery provider rules
        if (delivery_providers_rules && delivery_providers_rules.length) {
          for (const rule of delivery_providers_rules) {
            if (
              rule.delivery_providers &&
              !rule.delivery_providers.ignore &&
              rule.delivery_providers.value.length &&
              rule.delivery_providers.value.includes(deliveryProvider)
            ) {
              const result = checkDisplayRules(rule)
              if (!result.enabled || !result.visible) {
                return result
              }
            }
          }
        }

        if (
          delivery_status_whitelist &&
          !delivery_status_whitelist.ignore &&
          delivery_status_whitelist.value.length
        ) {
          const deliveryStatusIsWhiteListed =
            courierDelivery?.state &&
            delivery_status_whitelist?.value.includes(courierDelivery?.state)
          if (!deliveryStatusIsWhiteListed) {
            return {
              enabled: false,
              visible: false,
              reason: t('errors.display_rules_errors.no_allowed_delivery_status'),
            }
          }
        }

        return { ...defaultState, visible: true, enabled: true }
      }

      return checkDisplayRules(displayRules)
    },
    [
      t,
      ccrCode,
      uiVersion,
      hostValue,
      paramsValues,
      emittedEvents,
      lineOfBusiness,
      datetimeFormatter,
      entityPermissions,
      isBetaPermissionGranted,
      disabled_widget_buttons_by_events,
      order,
    ],
  )
}
