// content types
type OrderContent = any
type OrderErrContent = any
type OrderStatusContent = any
type OrderStatusErrContent = any
type OrderFulfillmentContent = any
type OrderFulfillmentErrContent = any
type RiderContent = any
type RiderErrorContent = any
type CustomerContent = any
type CustomerErrorContent = any
type VendorContent = any
type VendorErrorContent = any
type RiderCustomerChatTranscriptContent = any

// state types
export type InitialState = {
  newCommentId: string
  isNewComment: boolean
  isCancelOrder: boolean
  order: OrderContent
  orderError: any
  orderStatus: any
  orderStatusError: any
  orderFulfillment: any
  orderFulfillmentError: any
  rider: any
  riderError: any
  vendor: any
  vendorError: any
  customer: any
  customerError: any
  riderCustomerChatTranscript: any
}

type NewComment = {
  isNewComment: boolean
}

type CancelOrder = {
  isCancelOrder: boolean
}

type NewCommentId = {
  newCommentId: string
}

type Order = {
  order: OrderContent
}

type OrderErr = {
  orderError: OrderErrContent
}

type OrderStatus = {
  orderStatus: OrderStatusContent
}

type OrderStatusErr = {
  orderStatusError: OrderStatusErrContent
}

type OrderFulfillment = {
  orderFulfillment: OrderFulfillmentContent
}

type OrderFulfillmentErr = {
  orderFulfillmentError: OrderFulfillmentErrContent
}

type Rider = {
  rider: RiderContent
}

type RiderError = {
  riderError: RiderErrorContent
}

type Vendor = {
  vendor: VendorContent
}

type VendorError = {
  vendorError: VendorErrorContent
}

type Customer = {
  customer: CustomerContent
}

type CustomerError = {
  customerError: CustomerErrorContent
}

type RiderCustomerChatTranscript = {
  riderCustomerChatTranscript: RiderCustomerChatTranscriptContent
}

// action types
export enum RiderServiceAction {
  SET_IS_NEW_COMMENT = 'SET_IS_NEW_COMMENT',
  SET_IS_CANCEL_ORDER = 'SET_IS_CANCEL_ORDER',
  SET_NEW_COMMENT_ID = 'SET_NEW_COMMENT_ID',
  SET_ORDER = 'SET_ORDER',
  SET_ORDER_ERR = 'SET_ORDER_ERR',
  SET_ORDER_STATUS = 'SET_ORDER_STATUS',
  SET_ORDER_STATUS_ERR = 'SET_ORDER_STATUS_ERR',
  SET_ORDER_FULFILLMENT = 'SET_ORDER_FULFILLMENT',
  SET_ORDER_FULFILLMENT_ERR = 'SET_ORDER_FULFILLMENT_ERR',
  SET_RIDER = 'SET_RIDER',
  SET_RIDER_ERR = 'SET_RIDER_ERR',
  SET_VENDOR = 'SET_VENDOR',
  SET_VENDOR_ERR = 'SET_VENDOR_ERR',
  SET_CUSTOMER = 'SET_CUSTOMER',
  SET_CUSTOMER_ERR = 'SET_CUSTOMER_ERR',
  SET_RIDER_CUSTOMER_CHAT = 'SET_RIDER_CUSTOMER_CHAT',
}

type ActionMap<M extends { [index: string]: any }> = {
  [Key in keyof M]: M[Key] extends undefined
    ? { type: Key }
    : {
        type: Key
        payload: M[Key]
      }
}

export type RiderServiceActions =
  ActionMap<RiderServicePayload>[keyof ActionMap<RiderServicePayload>]

export type RiderServicePayload = {
  [RiderServiceAction.SET_IS_NEW_COMMENT]: NewComment
  [RiderServiceAction.SET_IS_CANCEL_ORDER]: CancelOrder
  [RiderServiceAction.SET_NEW_COMMENT_ID]: NewCommentId
  [RiderServiceAction.SET_ORDER]: Order
  [RiderServiceAction.SET_ORDER_ERR]: OrderErr
  [RiderServiceAction.SET_ORDER_STATUS]: OrderStatus
  [RiderServiceAction.SET_ORDER_STATUS_ERR]: OrderStatusErr
  [RiderServiceAction.SET_ORDER_FULFILLMENT]: OrderFulfillment
  [RiderServiceAction.SET_ORDER_FULFILLMENT_ERR]: OrderFulfillmentErr
  [RiderServiceAction.SET_RIDER]: Rider
  [RiderServiceAction.SET_RIDER_ERR]: RiderError
  [RiderServiceAction.SET_VENDOR]: Vendor
  [RiderServiceAction.SET_VENDOR_ERR]: VendorError
  [RiderServiceAction.SET_CUSTOMER]: Customer
  [RiderServiceAction.SET_CUSTOMER_ERR]: CustomerError
  [RiderServiceAction.SET_RIDER_CUSTOMER_CHAT]: RiderCustomerChatTranscript
}
