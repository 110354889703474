import { DataContext } from 'contexts/data/DataContext'
import { EntityContext } from 'contexts/entity/EntityContext'
import { SessionContext } from 'contexts/session/SessionContext'
import {
  allowedFixedPanelWidgets,
  allowedScrollablePanelWidgets,
} from 'entityConfig/allowedConfigValues'
import { useInitData } from 'hooks/apiHooks/useInitData'
import { useCaptureUserAction } from 'hooks/events/useCaptureUserAction'
import { useIsFeatureValid } from 'hooks/useGetValidFeatures'
import { useTranslation } from 'hooks/useTranslation'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { createUseStyles } from 'react-jss'
import styles from './V1Panel.styles'

import orderIconActive from 'assets/order/Blue.png'
import customerIconActive from 'assets/customer/Blue.png'
import riderIconActive from 'assets/rider/Blue.png'
import vendorIconActive from 'assets/vendor/Blue.png'
import orderIconInactive from 'assets/order/Gray.png'
import customerIconInactive from 'assets/customer/Gray.png'
import riderIconInactive from 'assets/rider/Gray.png'
import vendorIconInactive from 'assets/vendor/Gray.png'
import classNames from 'classnames'
import Order from 'widgets/Order'
import Customer from 'widgets/Customer'
import Vendor from 'widgets/Vendor'
import Rider from 'widgets/Rider'
import Actions from 'widgets/Actions'
import LastOrders from 'widgets/LastOrders'
import { useSessionState } from 'hooks/useSessionState'
import capitalize from 'lodash/capitalize'
import { RootWidgetView } from 'components/RootWidgetView'
import { builtinWidgetNames, widgetTypes } from 'types'

const layoutConfigs: {
  [p in allowedScrollablePanelWidgets]: {
    titleTranslationKey: string
    icon: any
    activeIcon: any
  }
} = {
  [allowedScrollablePanelWidgets.order]: {
    titleTranslationKey: 'Order Widget.Order',
    icon: orderIconInactive,
    activeIcon: orderIconActive,
  },
  [allowedScrollablePanelWidgets.customer]: {
    titleTranslationKey: 'Customer Widget.Customer',
    icon: customerIconInactive,
    activeIcon: customerIconActive,
  },
  [allowedScrollablePanelWidgets.vendor]: {
    titleTranslationKey: 'Vendor Widget.Vendor',
    icon: vendorIconInactive,
    activeIcon: vendorIconActive,
  },
  [allowedScrollablePanelWidgets.rider]: {
    titleTranslationKey: 'Rider Widget.Rider',
    icon: riderIconInactive,
    activeIcon: riderIconActive,
  },
}

const useStyles = createUseStyles(styles)

export const V1Panel = () => {
  // pull language content
  const { t } = useTranslation()
  const cx = useStyles()

  const dataInits = useInitData()

  const { lineOfBusiness } = useSessionState()

  const {
    entityState: { entityConfig },
  } = useContext(EntityContext)

  // pull currency from data context
  const {
    dataState: { compensatedVoucher, fullyRefundedVoucher },
  } = useContext(DataContext)

  const captureUserAction = useCaptureUserAction()
  const isFeatureValid = useIsFeatureValid()
  const {
    setActiveScrollablePanelWidget,
    sessionState: { activeScrollablePanelWidget },
  } = useContext(SessionContext)

  const [deliveryId, setDeliveryId] = useState(0)

  const scrollableWidgets = useMemo(() => {
    return (entityConfig?.scrollable_panel_config?.widget_order || []).filter((current) =>
      isFeatureValid(current.betaRequirement),
    )
  }, [entityConfig, isFeatureValid])

  const fixedWidgets = useMemo(() => {
    return (entityConfig?.fixed_panel_config?.widget_order || []).filter((current) =>
      isFeatureValid(current.betaRequirement),
    )
  }, [isFeatureValid, entityConfig])

  // set default widget
  useEffect(() => {
    if (scrollableWidgets.length) {
      setActiveScrollablePanelWidget(scrollableWidgets[0].feature, false)
    }
  }, [scrollableWidgets, setActiveScrollablePanelWidget])

  // scroll to customer widget on voucher compensation and refund
  useEffect(() => {
    if (
      (compensatedVoucher || fullyRefundedVoucher) &&
      scrollableWidgets.find(
        (current) => current.feature === allowedScrollablePanelWidgets.customer,
      )
    ) {
      setActiveScrollablePanelWidget(allowedScrollablePanelWidgets.customer, true)
    }
  }, [compensatedVoucher, fullyRefundedVoucher, scrollableWidgets, setActiveScrollablePanelWidget])

  const activateWidget = (e: React.MouseEvent) => {
    const currentTarget = e.currentTarget as HTMLLIElement
    e.preventDefault()
    e.stopPropagation()

    const widget = currentTarget.dataset['widget'] as allowedScrollablePanelWidgets

    if (activeScrollablePanelWidget !== widget) {
      setActiveScrollablePanelWidget(widget, true)
      captureUserAction(`Navbar${capitalize(widget)}Clicked`)
    }
  }

  const handleRiderNameClick = (id: number) => {
    setDeliveryId(id)
    setActiveScrollablePanelWidget(allowedScrollablePanelWidgets.rider, {
      behavior: 'smooth',
      block: 'start',
    })
  }

  return (
    <div className={cx.container}>
      <header>
        <nav className={cx.navbar}>
          <ul className={cx.navbarList}>
            {scrollableWidgets.map((widget) => {
              const widgetLayoutConfig = layoutConfigs[widget.feature]

              if (!widgetLayoutConfig) {
                return null
              }

              const title = t(widgetLayoutConfig.titleTranslationKey)
              const isActive = activeScrollablePanelWidget === widget.feature

              return (
                <li
                  key={widget.feature}
                  data-widget={widget.feature}
                  id={`${widget.feature}-navbar`}
                  onClick={activateWidget}
                >
                  <a
                    className={classNames(cx.navbarItem, {
                      active: isActive,
                    })}
                    href={'/'}
                  >
                    <img
                      className={cx.navbarItemIcon}
                      src={isActive ? widgetLayoutConfig.activeIcon : widgetLayoutConfig.icon}
                      alt={`${title} icon`}
                    />
                    {title}
                  </a>
                </li>
              )
            })}
          </ul>
        </nav>
        <div className={cx.navbarFill} />
      </header>
      <div className={cx.widgetsContainer}>
        <div className={cx.scrollableWidgetsContainer}>
          {scrollableWidgets.map((widget) => {
            const onMouseEnter = () => {
              setActiveScrollablePanelWidget(widget.feature, false)
            }
            const className = classNames(widget.feature + 'Container', 'widget')

            let content

            switch (widget.feature) {
              case allowedScrollablePanelWidgets.order:
                content = (
                  <Order
                    handleRiderNameClick={handleRiderNameClick}
                    initResult={dataInits.orderInit}
                  />
                )
                break

              case allowedScrollablePanelWidgets.customer:
                content = <Customer />
                break

              case allowedScrollablePanelWidgets.vendor:
                content = <Vendor initResult={dataInits.vendorInit} />
                break

              case allowedScrollablePanelWidgets.rider:
                content = <Rider deliveryId={deliveryId} />
                break
            }

            if (content) {
              return (
                <div
                  key={widget.feature}
                  className={className}
                  id={widget.feature}
                  onMouseEnter={onMouseEnter}
                >
                  {content}
                </div>
              )
            }

            return null
          })}
        </div>
        <div className={cx.fixedWidgetsContainer}>
          {fixedWidgets.map((widget) => {
            switch (widget.feature) {
              case allowedFixedPanelWidgets.actions:
                // return actions container
                return (
                  <div key={widget.feature}>
                    <div className={cx.actionsContainer}>
                      <Actions />
                    </div>
                  </div>
                )
              case allowedFixedPanelWidgets.lastOrders:
                // return lastOrders container, if customer Id is found either in the params or in order API response, and if widget is available for the entity
                return (
                  <div key={widget.feature}>
                    <div className={cx.lastOrdersContainer}>
                      <LastOrders lob={lineOfBusiness} />
                    </div>
                  </div>
                )
              case allowedFixedPanelWidgets.newComment:
                // return History of Events container, if widget is available for the entity
                return (
                  <div className={cx.historyOfEventsContainer} key={widget.feature}>
                    <RootWidgetView
                      widgetDefinition={{
                        type: widgetTypes.builtin,
                        widget_name: builtinWidgetNames.activity,
                        label: {
                          label_translation_key: 'History of events',
                        },
                      }}
                      screenName='main'
                      hideLabel
                    />
                  </div>
                )
            }
            return null
          })}
        </div>
      </div>
    </div>
  )
}
