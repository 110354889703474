/**
 * HistoryOfEvents Vendor Tab
 * pulls from API and displays order-related events with filtering options
 * */

// libs
import React, { useState, useEffect, useContext } from 'react'
// contexts and types
import { SessionContext } from 'contexts/session/SessionContext'
import { DataContext } from 'contexts/data/DataContext'
import {
  SingleComment,
  SingleCommentAction,
  ModifyCallBackStatuses,
} from 'types/api/commentApi/getOrderComments'
import { CancellationReason } from 'types/widgets/historyOfEvents/cancelActionComment'
import { CancelOrderActionConfig } from 'contexts/entity/types'
// hooks
import { useTranslation } from 'hooks/useTranslation'
// styles
import { createUseStyles } from 'react-jss'
import styles from './VendorHistory.styles'
import { Typography, Empty } from 'antd'
// components
// utils
import { useApiService } from 'hooks/useApiService'
import { getVendorComments } from 'services/commentApi/getVendorComments'
import { WidgetErrorHandler } from 'components/WidgetErrorHandler/widgetErrorHandler'
import { useCaptureUserAction } from 'hooks/events/useCaptureUserAction'

import { commentTagType, CommentView } from 'components/Comments/CommentView'
import { AvailableFilterTimeFrames } from 'types/widgets/historyOfEvents/availableCommentActionsTypes'
import { useFilteredComments } from 'hooks/useFilteredComments'
import {
  UpdateVendorScheduleComment,
  UpdateVendorSpecialScheduleComment,
  UpdateVendorAvailabilityComment,
  ExecuteDeviceActionComment,
  GetDeviceRemoteControlComment,
} from 'components/Comments'
import { WidgetSize } from 'types/widgets/historyOfEvents/tabs'
import { useGetApiVersionConfig } from 'hooks/useApiVersionConfig'

const useStyles = createUseStyles(styles)
interface Props {
  intervalState: number
  cancelOrder: CancelOrderActionConfig
  timeFilter: AvailableFilterTimeFrames
  mode: WidgetSize
}

const VendorHistory: React.FC<Props> = (props) => {
  const { intervalState, cancelOrder: cancel_order, timeFilter, mode } = props
  const classes = useStyles()
  const { Text } = Typography

  const getApiVersionConfig = useGetApiVersionConfig()

  const apiVersion = getApiVersionConfig('getVendorComments')

  // pull language content
  const { t } = useTranslation()
  const captureUserAction = useCaptureUserAction()

  // pull vendorId & globalentity ID from session context
  const {
    sessionState: { orderId, globalEntityId, vendorId },
  } = useContext(SessionContext)

  // pull dataState
  const { dataState } = useContext(DataContext)

  const {
    loading: isLoading,
    error,
    loadService: loadVendorComments,
    data,
  } = useApiService({
    service: getVendorComments,
    params: {
      entityId: globalEntityId,
      vendorId: vendorId,
      apiVersion,
    },
    deps: [
      vendorId,
      intervalState,
      dataState.newComment,
      dataState.autoChangeDeliveryTimeComment,
      dataState.autoMissingItemReportComment,
      dataState.autoCancelOrderComment,
      dataState.autoCompensationComment,
      dataState.autoPartialRefundComment,
      dataState.autoFullRefundComment,
      dataState.autoChangeAddressComment,
      dataState.autoChangeInstructionsComment,
      dataState.autoModifyCallbackComment,
      dataState.autoChangeCookingInstComment,
    ],
  })

  // the number displayed next to the default/selected filter in dropdown

  // unfiltered data result count
  const [totalUnfilteredItems, setTotalUnfilteredItems] = useState(0)

  useEffect(() => {
    setTotalUnfilteredItems(data?.comments?.length)
  }, [data])

  const handleCommentOrderIdClicked = (commentId: string) => {
    captureUserAction('HistoryOfEventsVendorOrderIdClicked', {
      eventDetails: {
        comment_id: commentId,
      },
    })
  }

  const commentsDisplayed = useFilteredComments(data?.comments, timeFilter)

  return (
    <div className={classes.orderHistoryContainer}>
      <WidgetErrorHandler
        loading={isLoading}
        errorPayload={error?.errorPayload}
        loadingText={t('Interface.Loading Vendor Events')}
        onRetry={loadVendorComments}
      >
        {() => {
          if (totalUnfilteredItems === 0) {
            return (
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={t(
                  'History of Events Widget.There is no comment associated with the current vendor',
                )}
              />
            )
          }
          return (
            <React.Fragment>
              {/* COMMENTS */}
              <div className={classes.commentsHolder}>
                {commentsDisplayed && commentsDisplayed.length > 0 ? (
                  commentsDisplayed.map((comment: SingleComment, idx: number) => {
                    let orderIdOfComment = ''
                    let shouldCommentBeHighlighted = false

                    if (comment.order_id) {
                      orderIdOfComment = comment.order_id
                    }

                    if (
                      dataState?.newComment?.comment &&
                      dataState.newComment.comment.id === comment.id
                    ) {
                      shouldCommentBeHighlighted = true
                    }

                    switch (comment.action) {
                      case SingleCommentAction.cancel_order:
                        if (
                          dataState?.autoCancelOrderComment &&
                          comment?.order_id &&
                          dataState.autoCancelOrderComment === comment.order_id
                        ) {
                          shouldCommentBeHighlighted = true
                        }

                        // set initial cancellation reason to what API returns
                        let cancellationReasonToDisplay =
                          comment.additional_data.cancel_order.reason

                        // check if entity wants to display the reason returned bu API
                        // useReasonToDisplayCancelComment being truthy means entity wants display reason to be pulled from config
                        if (cancel_order?.useReasonToDisplayCancelComment) {
                          // find matching id and update cancellationReasonToDisplay
                          const reasonFound = cancel_order.availableCancellationReasons.find(
                            (reason: CancellationReason) =>
                              reason.reason === comment.additional_data.cancel_order.reason,
                          )
                          if (reasonFound) {
                            cancellationReasonToDisplay = reasonFound.translation_key
                          }
                        }

                        return (
                          <CommentView
                            mode={mode}
                            key={idx}
                            comment={comment}
                            shouldCommentBeHighlighted={shouldCommentBeHighlighted}
                            tag={{
                              type: commentTagType.thunderbolt,
                              text: t('History of Events Widget.Event Types.Cancel Order'),
                            }}
                          >
                            <Text className={classes.commentText}>
                              <Text>
                                {` ${t(
                                  'History of Events Widget.Content.Cancelled order due to',
                                )} ${cancellationReasonToDisplay
                                  .replace(/_/g, ' ')
                                  .toLowerCase()}.`}
                              </Text>
                            </Text>
                            <br />
                          </CommentView>
                        )

                      case SingleCommentAction.manual_comment:
                        return (
                          <CommentView
                            mode={mode}
                            key={comment?.id || idx + 1}
                            comment={comment}
                            shouldCommentBeHighlighted={shouldCommentBeHighlighted}
                            tag={{
                              type: commentTagType.message,
                              text: t('History of Events Widget.Event Types.Comment'),
                            }}
                          >
                            <Text className={classes.commentText}>
                              <Text>{comment.additional_data.manual_comment.text}</Text>
                            </Text>
                            <br />
                          </CommentView>
                        )

                      case SingleCommentAction.update_vendor_availability:
                        return (
                          <UpdateVendorAvailabilityComment
                            mode={mode}
                            key={idx}
                            comment={comment}
                            shouldCommentBeHighlighted={shouldCommentBeHighlighted}
                          />
                        )

                      case SingleCommentAction.update_vendor_schedule:
                        return (
                          <UpdateVendorScheduleComment
                            mode={mode}
                            key={idx}
                            comment={comment}
                            shouldCommentBeHighlighted={shouldCommentBeHighlighted}
                          />
                        )

                      case SingleCommentAction.update_vendor_special_schedule:
                        return (
                          <UpdateVendorSpecialScheduleComment
                            mode={mode}
                            key={idx}
                            comment={comment}
                            shouldCommentBeHighlighted={shouldCommentBeHighlighted}
                          />
                        )

                      case SingleCommentAction.modify_callback:
                        if (
                          comment?.additional_data?.modify_callback?.status ===
                            ModifyCallBackStatuses.CONFIRMED ||
                          comment?.additional_data?.modify_callback?.status ===
                            ModifyCallBackStatuses.MODIFIED ||
                          comment?.additional_data?.modify_callback?.status ===
                            ModifyCallBackStatuses.REQUESTED
                        ) {
                          return null
                        } else {
                          if (
                            dataState?.autoModifyCallbackComment &&
                            orderId &&
                            dataState.autoModifyCallbackComment === orderId
                          ) {
                            shouldCommentBeHighlighted = true
                          }

                          return (
                            <CommentView
                              mode={mode}
                              key={comment?.id || idx + 1}
                              comment={comment}
                              shouldCommentBeHighlighted={shouldCommentBeHighlighted}
                              tag={{
                                type: commentTagType.thunderbolt,
                                text: t('History of Events Widget.Event Types.System Error'),
                              }}
                            >
                              <Text className={classes.commentText}>
                                <Text>
                                  {` ${t(
                                    'History of Events Widget.Content.Changed delivery address failed due to',
                                  )} `}
                                </Text>
                                <Text strong>{`${t(
                                  'History of Events Widget.Content.System Error',
                                )}.`}</Text>
                              </Text>
                              <br />
                              {orderIdOfComment ? (
                                <div
                                  className={classes.orderIdText}
                                  onClick={() => handleCommentOrderIdClicked(orderIdOfComment)}
                                >
                                  {`${t('Order Widget.Order ID')}: ${orderIdOfComment}`}
                                </div>
                              ) : null}
                            </CommentView>
                          )
                        }

                      case SingleCommentAction.execute_device_action:
                        return (
                          <ExecuteDeviceActionComment
                            mode={mode}
                            key={idx}
                            comment={comment}
                            shouldCommentBeHighlighted={shouldCommentBeHighlighted}
                          />
                        )

                      case SingleCommentAction.get_device_remote_control_link:
                        return (
                          <GetDeviceRemoteControlComment
                            mode={mode}
                            key={idx}
                            comment={comment}
                            shouldCommentBeHighlighted={shouldCommentBeHighlighted}
                          />
                        )

                      default:
                        return null
                    }
                  })
                ) : (
                  <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description={t(
                      'History of Events Widget.No comment found in the selected timeframe',
                    )}
                  />
                )}
              </div>
            </React.Fragment>
          )
        }}
      </WidgetErrorHandler>
    </div>
  )
}

export default VendorHistory
