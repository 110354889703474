import React, { useState } from 'react'

// Components
import { List, Collapse, Typography } from 'antd'
import { UpOutlined, DownOutlined } from '@ant-design/icons'

// Utils
import { createUseStyles } from 'react-jss'

// Hooks

import styles from './AdditionalCharges.styles'
import { TransformedDataPoint } from 'types/dataTransformers/generic'
import { MonetarySign, useNumberFormatter } from 'hooks/formatters/useNumberFormatter'

const { Panel } = Collapse
const { Text } = Typography

interface AdditionalChargesProps {
  label: string
  currency: string
  totalAmount: number

  isAdditionalChargesAvailable: boolean

  dataPoints: Array<TransformedDataPoint>
}

const defaultKey = '1'
const useStyles = createUseStyles(styles)

function AdditionalCharges({
  isAdditionalChargesAvailable,

  currency,
  totalAmount,
  dataPoints,
  label,
}: AdditionalChargesProps) {
  const [activeKey, setActiveKey] = useState<string>('')
  const classes = useStyles()

  const numberFormatter = useNumberFormatter()

  const handleOnChange = (key: string[]) => {
    setActiveKey(key[0])
  }

  if (!isAdditionalChargesAvailable) {
    return null
  }

  return (
    <Collapse
      onChange={handleOnChange}
      bordered={false}
      ghost={true}
      className={classes.antCollapse}
    >
      <Panel
        showArrow={false}
        header={
          <span>
            {label}
            {activeKey === defaultKey ? (
              <UpOutlined className={classes.arrow} />
            ) : (
              <DownOutlined className={classes.arrow} />
            )}
          </span>
        }
        key={defaultKey}
        extra={
          <Text>
            {numberFormatter.formatMoney({
              currency,
              amount: totalAmount,
              sign: MonetarySign.Auto,
            })}
          </Text>
        }
      >
        {dataPoints.map((dataPoint) => {
          return (
            <List.Item
              key={dataPoint.key}
              data-test-id={dataPoint.key}
              className={classes.listItem}
            >
              {dataPoint.label}
              <Text>
                {numberFormatter.formatMoney({
                  currency,
                  amount: dataPoint.value as number,
                  sign: MonetarySign.Auto,
                })}
              </Text>
            </List.Item>
          )
        })}
      </Panel>
    </Collapse>
  )
}

export default AdditionalCharges
