import { useContextProvider } from 'contexts/useContextProvider'
import { ScreenName, WidgetCategory, WidgetName } from 'types'
import React, { createContext, FC, useMemo } from 'react'
import { getWidgetCategory } from 'utils/getters/getWidgetCategory'

export interface ViewScreenContextValue {
  screenName: ScreenName
  widgetId?: string
  label?: string
  tabContainerLabel?: string
  widgetCategory: WidgetCategory
}

const ViewScreenContext = createContext<ViewScreenContextValue>({
  screenName: null,
  widgetCategory: null,
})

export type ViewScreenProviderProps = Omit<ViewScreenContextValue, 'widgetCategory'>

export const ViewScreenProvider: FC<ViewScreenProviderProps> = ({
  children,
  screenName,
  widgetId,
  label,
  tabContainerLabel,
}) => {
  const contextValue = useMemo((): ViewScreenContextValue => {
    return {
      screenName,
      widgetId,
      label,
      tabContainerLabel,
      widgetCategory: widgetId ? getWidgetCategory(widgetId as WidgetName) : null,
    }
  }, [screenName, label, tabContainerLabel, widgetId])

  return <ViewScreenContext.Provider value={contextValue}>{children}</ViewScreenContext.Provider>
}

export const useWidgetScreen = () => useContextProvider(ViewScreenContext, 'ViewScreenProvider')
