/**
 * Order Widget Items Tab
 * runs config checks, renders items as Panel headers and payment details as description items
 * */

// libs
import React, { useState, useContext, useMemo } from 'react'
// configs
// contexts and types
import { DataContext } from 'contexts/data/DataContext'
import { OrderWidgetItems } from 'contexts/entity/types'
// hooks
// utils
import transformOrderItems from 'utils/dataTransformers/transformOrderItems'

// styles
import ItemsTable from './ItemsTable'
import { dataPointRequirements } from 'types/unitedUiConfig'

import { createUseStyles } from 'react-jss'
import styles from './Items.styles'

import { Button } from 'shared/Button'
import { useTranslation } from 'hooks/useTranslation'
import { WidgetActionHandlers } from 'shared/WidgetActionHandlers'
import { createPluggableWidget } from 'factory/createPluggableWidget'

const useStyles = createUseStyles(styles)

type ItemsConfig = {
  items: OrderWidgetItems
  currencyDigitsAfterComma: number
}
/*
  @TODO: Remove isNewItemsOrderDesignAvailable when betaPermission_NewOrderItems
  is not required anymore. Check classes and styles file to cleanup.
*/

const Items = createPluggableWidget<ItemsConfig>(
  ({ order, config, sdk, lang }) => {
    const { currencyDigitsAfterComma, items: itemsConfig } = config
    const { default_number_of_items } = itemsConfig

    // pull data state from data context
    const {
      dataState: { currency },
    } = useContext(DataContext)

    const classes = useStyles()
    const { t } = useTranslation()

    const { checkDisplayRules } = sdk

    // number of items to show by default
    const isNewItemsOrderDesignAvailable = checkDisplayRules({
      displayRules: {
        beta_requirements: { value: itemsConfig.newOrderItemsDesign?.betaRequirement },
      },
    }).enabled

    const tabItems = useMemo(() => {
      return transformOrderItems(order?.order?.items || [], {
        isNewItemsOrderDesignAvailable,
        currency_digits_after_comma: currencyDigitsAfterComma,
        locale: lang,
        enabled_item_locales: itemsConfig.enabled_item_locales,
      })
    }, [order, lang, isNewItemsOrderDesignAvailable, currencyDigitsAfterComma, itemsConfig])

    // determine if toppings total value & topping details (name and price) should be displayed
    // price availability is passed to Titles and Item, as it is in the item line
    const isToppingtotalPriceAvailable = checkDisplayRules({
      displayRules: {
        beta_requirements: {
          value: itemsConfig?.topping_total_price?.betaRequirement,
        },
        datapoint_requirement: {
          value: (itemsConfig.topping_total_price.displayRule as dataPointRequirements[])?.[0],
        },
      },
      opts: {
        dataPointValue: tabItems.length ? tabItems[0].toppingsPrice : undefined,
      },
    }).visible

    const maxNumberOfItemsToDisplay = default_number_of_items?.value || -1

    const enableToggleButtons =
      maxNumberOfItemsToDisplay !== -1 &&
      tabItems.length > maxNumberOfItemsToDisplay &&
      checkDisplayRules({ displayRules: default_number_of_items?.display_rules }).enabled

    const [showAllItems, setShowAllItems] = useState(!enableToggleButtons)

    const tabItemsToDisplay = useMemo(() => {
      const orderItems = order?.order?.items ?? []
      return showAllItems ? orderItems : orderItems.slice(0, maxNumberOfItemsToDisplay)
    }, [maxNumberOfItemsToDisplay, order, showAllItems])

    const { action_handlers: actionHandlers } = itemsConfig

    const toggleAllItemsShow = () => setShowAllItems((value) => !value)

    return (
      <div>
        {/* Action buttons at the top */}
        {actionHandlers?.length > 0 && (
          <WidgetActionHandlers actionHandlers={actionHandlers} marginTop={0} marginBottom={20} />
        )}

        <ItemsTable
          items={tabItemsToDisplay}
          currency={currency}
          digitsAfterComa={currencyDigitsAfterComma}
          isToppingtotalPriceAvailable={isToppingtotalPriceAvailable}
          itemsConfig={itemsConfig}
          locale={lang}
        />
        {/* Show all items / show less items btn */}
        {enableToggleButtons && (
          <div className={classes.toggleShowBtnContainer}>
            <Button type='link' color='primary' onClick={toggleAllItemsShow}>
              {t(showAllItems ? 'action_labels.show_less_items' : 'action_labels.show_all_items')}
            </Button>
          </div>
        )}
      </div>
    )
  },
  {
    category: 'data_lookup',
    deriveConfig({ entityConfig }) {
      return {
        items: entityConfig.scrollable_panel_config.widgets_configs.order.tab_configs.items,
        currencyDigitsAfterComma: entityConfig.currency_digits_after_comma,
      }
    },
    deriveInitialViewState({ t, order, titleTranslationKey }) {
      return {
        title: t(titleTranslationKey, {
          replace: { totalItems: order?.order?.items?.length ?? 0 },
        }),
      }
    },
  },
)

export default Items
