import { SessionContext } from 'contexts/session/SessionContext'
import { useForceUpdate } from 'contexts/useForceUpdate'
import React, { createContext, useMemo, useContext, useCallback, useEffect, useRef } from 'react'
import { readAuthPayload, writeAuthPayload } from 'utils/authHelpers'

export interface AuthPayload {
  agentEmail?: string
  agentName?: string

  jwt?: string
  refresh?: string

  /**
   * timestamp in milliseconds after which auth token should be refreshed
   */
  refreshAfter?: number

  permissions?: Record<string, string[]>

  namespaces?: string[]
}

interface AuthState {
  isLoggedIn: boolean
  jwt: string
  entityPermissions: Set<string>
  agentName: string
  agentEmail: string
  globalEntityId: string
}

export interface TrackingAuthPayload {
  trackingToken?: string

  /**
   * timestamp in milliseconds after which tracking token should be refreshed
   */
  refreshAfter?: number
}

export type AuthDispatchActions =
  | {
      type: 'login'
      payload: AuthPayload
    }
  | {
      type: 'logout'
    }
  | {
      type: 'refresh'
      payload: AuthPayload
    }

export interface AuthContextValue {
  isLoggedIn: boolean
  agentName: string
  agentEmail: string
  entityPermissions: Set<string>
  authDispatch: (action: AuthDispatchActions) => void
  readAuthState: () => Omit<AuthContextValue, 'readAuthState' | 'authDispatch'> & { jwt: string }
}

const AuthContext = createContext<AuthContextValue>(undefined)

/**
 * if state is given, we use it
 * makes application testable
 * @returns
 */
export const AuthProvider: React.FC<{
  isLoggedIn?: boolean
}> = ({ children, isLoggedIn: testLoginState }) => {
  const {
    readSession,
    sessionState: { globalEntityId },
  } = useContext(SessionContext)
  const forceUpdate = useForceUpdate()

  const toState = useCallback(
    (authPayload: AuthPayload): AuthState => {
      const globalEntityId = readSession().globalEntityId
      const isLoggedIn = Boolean(
        authPayload.jwt &&
          authPayload.refresh &&
          authPayload.refreshAfter &&
          authPayload.permissions,
      )
      if (!isLoggedIn) {
        return {
          isLoggedIn,
          entityPermissions: new Set<string>(),
          jwt: '',
          agentName: '',
          agentEmail: '',
          globalEntityId,
        }
      }
      return {
        isLoggedIn,
        entityPermissions: new Set(authPayload.permissions[globalEntityId] ?? []),
        jwt: authPayload.jwt,
        agentEmail: authPayload.agentEmail,
        agentName: authPayload.agentName,
        globalEntityId,
      }
    },
    [readSession],
  )

  const initialStates = useMemo<AuthState>(() => {
    // For testing purpose to automatically login user
    if (typeof testLoginState !== 'undefined')
      return {
        isLoggedIn: testLoginState,
        entityPermissions: new Set<string>(),
        jwt: '',
        agentName: '',
        agentEmail: '',
        globalEntityId,
      }
    return toState(readAuthPayload())

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const stateRef = useRef(initialStates)

  // never changes
  const commitAuthState = useCallback(
    (authPayload: AuthPayload, writeToStorage = false) => {
      if (writeToStorage) {
        writeAuthPayload(authPayload)
      }
      stateRef.current = toState(authPayload)
      forceUpdate()
    },
    [forceUpdate, toState],
  )

  // never changes
  const readAuthState = useCallback(() => {
    return stateRef.current
  }, [])

  // never changes
  const authDispatch = useCallback<AuthContextValue['authDispatch']>(
    (action) => {
      switch (action.type) {
        case 'login':
        case 'refresh':
          commitAuthState(action.payload, true)
          break
        case 'logout':
          commitAuthState({}, true)
          break
      }
    },
    [commitAuthState],
  )

  const { agentEmail, agentName, entityPermissions, isLoggedIn } = stateRef.current

  const contextValue = useMemo<AuthContextValue>(() => {
    return {
      agentEmail,
      agentName,
      entityPermissions,
      isLoggedIn,
      readAuthState,
      authDispatch,
    }
  }, [readAuthState, agentEmail, agentName, entityPermissions, isLoggedIn, authDispatch])

  // This useEffect will react to local storage changes caused by other tabs
  useEffect(() => {
    const listener = (event: StorageEvent) => {
      if (event.key === 'auth-payload') {
        const authPayload = readAuthPayload(window.localStorage, event.newValue)
        commitAuthState(authPayload, false)
      }
    }
    window.addEventListener('storage', listener, false)
    return () => {
      window.removeEventListener('storage', listener, false)
    }
  }, [commitAuthState])

  // react to changes in global entity id
  useEffect(() => {
    if (stateRef.current.globalEntityId !== globalEntityId) {
      commitAuthState(readAuthPayload(), false)
    }
  }, [globalEntityId, commitAuthState])

  return <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
}

export const useAuthProvider = () => {
  const value = useContext(AuthContext)
  if (typeof value === 'undefined' && process.env.NODE_ENV !== 'production') {
    console.error(
      'New auth provider value is undefined. Please wrap your application in AuthProvider',
    )
  }

  return value
}
