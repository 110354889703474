/*
 *
 *  Side bar component based on Ant drawer component
 *  Refer : https://4x.ant.design/components/drawer/
 */

import React from 'react'
import { Button, Space, DrawerProps } from 'antd'
import classnames from 'classnames'
import { DeleteOutlined, CloseOutlined } from '@ant-design/icons'
import { BugFixedAntDrawer } from './BugFixedSideDrawer'
import { SIDEDRAWER_MAX_WIDTH, SIDEDRAWER_WIDTH } from 'constants/constants'
import { createUseStyles } from 'react-jss'
import { addUnitIfNeeded } from 'factory/createDeclarativeUseStyle'

const useStyles = createUseStyles({
  container: ({ width }: { width: string | number }) => {
    return {
      maxWidth: SIDEDRAWER_MAX_WIDTH,
      width: `${addUnitIfNeeded('width', width)} !important`,
    }
  },
})

type SideDrawerProps = Pick<
  DrawerProps,
  'placement' | 'visible' | 'children' | 'destroyOnClose' | 'getContainer' | 'mask' | 'maskClosable'
> & {
  title: string
  onHide?: () => void
  onClose?: () => void
  className?: string
  width?: string | number
}

export const SideDrawer: React.FC<SideDrawerProps> = ({
  onHide,
  onClose,
  title,
  placement = 'right',
  className,
  width = SIDEDRAWER_WIDTH,
  mask = false,
  maskClosable,
  ...remainingProps
}) => {
  const classes = useStyles({ width })

  return (
    <BugFixedAntDrawer
      className={classnames(classes.container, className)}
      {...remainingProps}
      title={
        /*  On empty title, the close button becomes invisible, since we have closable = false below */
        title || <span>&nbsp;</span>
      }
      placement={placement}
      contentWrapperStyle={{ boxShadow: '0px 2px 8px 0px #00000026' }}
      mask={mask}
      maskClosable={maskClosable}
      closable={false} /* disable default left close icon inside header */
      extra={
        <Space>
          {onClose && (
            <Button type='link' onClick={onClose}>
              <DeleteOutlined />
              Discard
            </Button>
          )}
          <span>
            <CloseOutlined onClick={onHide || onClose} />
          </span>
        </Space>
      }
    />
  )
}
