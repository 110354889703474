import { ApiErrorConfig } from './voucherApiErrorConfig'

export const adminApiErrorConfig: ApiErrorConfig<'AdminApi'> = {
  AdminApi: {
    getEntityConfig: {
      404: {
        '*': {
          screen: 'error',
          heading: 'No Entity Config Found',
          copyText: 'Entity configuration for country not found',
        },
      },
    },
  },
}
