import React, { useState } from 'react'
import { Box, Flex, Text } from 'shared'
import { Col, Row, Space } from 'antd'
import { createUseStyles } from 'react-jss'
import SingleTag from 'components/Tags/SingleTag'
import { PlaceholderMap } from 'components/PlaceholderMap/PlaceholderMap'
import { useTranslation } from 'hooks/useTranslation'
import { VendorAddress } from 'services/vendorApi/getVendorShippingAddresses'
import { useSdk } from 'contexts/SdkProvider'
import { gray } from 'theme'
import { AddressMap } from '../AddressMap/AddressMap'

export type VendorAddressType =
  | 'billing'
  | 'shipping'
  | 'restaurant'
  | VendorAddress['address_type']

export type Address = {
  id: string
  name: string
  addressLine: string
  addressTypes: VendorAddressType[]
  areaName: string
  areaType: string
  countryCode: string
  countryName: string
  grid: string
  latitude: number
  longitude: number
  postalCode: string
}

export type AddressCardProps = {
  address: Address
  showMap?: boolean
}

export const useStyles = createUseStyles({
  mapContainer: {
    border: `1px solid ${gray.gray5}`,
    borderRadius: '6px',
    overflow: 'hidden',
  },
  divider: {
    margin: '16px 0',
  },
  placeholder: {
    height: '140px',
    width: '200px',
  },
})

export function formatAddress(address: Address) {
  const formatted = [
    address.addressLine,
    `${address.postalCode} ${address.areaName}`,
    address.countryName,
  ]
    .filter((item) => !!item)
    .join(', ')

  return formatted
}

export const AddressCard: React.FC<AddressCardProps> = ({ address, showMap = false }) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const { captureUserAction } = useSdk()
  const [isMapVisible, setIsMapVisible] = useState(false)

  const formattedAddress = formatAddress(address)

  const handleViewMap = () => {
    setIsMapVisible(true)

    captureUserAction('OPEN_VIEW_MAP')
  }

  return (
    <article>
      <Box>
        <Row gutter={[20, 20]}>
          <Col span={16}>
            <Space direction='vertical'>
              <Text.Primary>{formattedAddress}</Text.Primary>

              <Space size={8}>
                {address.addressTypes.map((value) => (
                  <SingleTag key={value} value={t(`widgets.vendor_addresses.${value}`)} />
                ))}
              </Space>
            </Space>
          </Col>
          {showMap ? (
            <Col span={8}>
              <Flex flexDirection='column' alignItems='end'>
                <Space direction='vertical'>
                  <div className={classes.mapContainer}>
                    {isMapVisible ? (
                      <AddressMap latitude={address.latitude} longitude={address.longitude} />
                    ) : (
                      <PlaceholderMap
                        containerClassName={classes.placeholder}
                        canViewMap={true}
                        onViewMap={handleViewMap}
                      />
                    )}
                  </div>
                  <Text.Secondary>
                    {t(`widgets.vendor_addresses.lat`)}: {address.latitude},{' '}
                    {t(`widgets.vendor_addresses.long`)}: {address.longitude}{' '}
                  </Text.Secondary>
                </Space>
              </Flex>
            </Col>
          ) : null}
        </Row>
      </Box>
    </article>
  )
}
