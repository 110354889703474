/**
  Dropdown to show select time frame and sent the selected value back to parent
 */

import React, { useState, useEffect } from 'react'

import {
  SingleAvailableTimeFrame,
  AvailableFilterTimeFrames,
} from 'types/widgets/historyOfEvents/availableCommentActionsTypes'
// hooks
import { useTranslation } from 'hooks/useTranslation'
// styles
import { createUseStyles } from 'react-jss'
import styles from './CommentTimeFilter.styles'
import { Menu, Button } from 'antd'
import { DownOutlined, UpOutlined } from '@ant-design/icons'
import { InlineDropdown } from 'shared/InlineDropdown'

const useStyles = createUseStyles(styles)

const availableTimeFrames: SingleAvailableTimeFrame[] = [
  {
    value: AvailableFilterTimeFrames.last_seven,
    day_limit: 7,
  },
  {
    value: AvailableFilterTimeFrames.last_fourteen,
    day_limit: 14,
  },
  {
    value: AvailableFilterTimeFrames.last_thirty,
    day_limit: 30,
  },
  {
    value: AvailableFilterTimeFrames.all,
    day_limit: 90,
  },
]

type Props = {
  onFilterChange: (filter: AvailableFilterTimeFrames) => void
  onFilterSelected: (selectedTimeFrameInLocal: string) => void
  activeTab: string
}

export const CommentTimeFilter: React.FC<Props> = ({
  onFilterChange,
  activeTab,
  onFilterSelected,
}) => {
  const classes = useStyles()

  // pull language content
  const { t } = useTranslation()

  const [selectedTimeframeInLocal, setSelectedTimeframeInLocal] = useState<string>(
    t('History of Events Widget.Timeframes.All (Last 90 Days)'),
  )

  // selected filter -> intially sel to 'all' time frame. Reset everytime active tab changes.
  useEffect(() => {
    onFilterChange(AvailableFilterTimeFrames.all)
    setSelectedTimeframeInLocal(t('History of Events Widget.Timeframes.All (Last 90 Days)'))
  }, [activeTab, onFilterChange, t])

  const [isTimeframeDropdownButtonActive, setIsTimeframeDropdownButtonActive] = useState(false)

  const handleTimeframeDropdownButtonClick = (e: any) => {
    e.preventDefault()
    setIsTimeframeDropdownButtonActive(true)
  }

  const handleFilterSelection = (timeframe: SingleAvailableTimeFrame) => {
    setIsTimeframeDropdownButtonActive(false)

    //////////////// TIMEFRAME FILTER ////////////////
    let localSelectedTimeFrame = ''
    switch (timeframe.value) {
      case AvailableFilterTimeFrames.last_seven:
        localSelectedTimeFrame = t('History of Events Widget.Timeframes.Last 7 Days')
        onFilterChange(AvailableFilterTimeFrames.last_seven)
        break
      case AvailableFilterTimeFrames.last_fourteen:
        localSelectedTimeFrame = t('History of Events Widget.Timeframes.Last 14 Days')
        onFilterChange(AvailableFilterTimeFrames.last_fourteen)

        break
      case AvailableFilterTimeFrames.last_thirty:
        localSelectedTimeFrame = t('History of Events Widget.Timeframes.Last 30 Days')
        onFilterChange(AvailableFilterTimeFrames.last_thirty)

        break
      case AvailableFilterTimeFrames.all:
        localSelectedTimeFrame = t('History of Events Widget.Timeframes.All (Last 90 Days)')
        onFilterChange(AvailableFilterTimeFrames.all)
        break
      default:
        console.log('Error in deciding value for selectedTimeFrameInLocal')
        break
    }

    setSelectedTimeframeInLocal(localSelectedTimeFrame)
    onFilterSelected(localSelectedTimeFrame)
  }
  return (
    <div className={classes.contentHolder}>
      {/* DATE FILTER DROPDOWN */}
      <InlineDropdown
        arrow={true}
        overlay={
          // renders filtering options as menu items
          <Menu>
            {availableTimeFrames.map((timeframe: SingleAvailableTimeFrame, idx: number) => {
              switch (timeframe.value) {
                case AvailableFilterTimeFrames.last_seven:
                  return (
                    <Menu.Item
                      key={idx}
                      className={classes.menuItem}
                      onClick={() => handleFilterSelection(timeframe)}
                    >
                      {t('History of Events Widget.Timeframes.Last 7 Days')}
                    </Menu.Item>
                  )
                case AvailableFilterTimeFrames.last_fourteen:
                  return (
                    <Menu.Item
                      key={idx}
                      className={classes.menuItem}
                      onClick={() => handleFilterSelection(timeframe)}
                    >
                      {t('History of Events Widget.Timeframes.Last 14 Days')}
                    </Menu.Item>
                  )
                case AvailableFilterTimeFrames.last_thirty:
                  return (
                    <Menu.Item
                      key={idx}
                      className={classes.menuItem}
                      onClick={() => handleFilterSelection(timeframe)}
                    >
                      {t('History of Events Widget.Timeframes.Last 30 Days')}
                    </Menu.Item>
                  )
                case AvailableFilterTimeFrames.all:
                  return (
                    <Menu.Item
                      key={idx}
                      className={classes.menuItem}
                      onClick={() => handleFilterSelection(timeframe)}
                    >
                      {t('History of Events Widget.Timeframes.All (Last 90 Days)')}
                    </Menu.Item>
                  )
                default:
                  return null
              }
            })}
          </Menu>
        }
        trigger={['click']}
      >
        <Button
          type='default'
          size='small'
          onClick={(e) => handleTimeframeDropdownButtonClick(e)}
          className={classes.dropdownButton}
        >
          {selectedTimeframeInLocal}

          {isTimeframeDropdownButtonActive ? (
            <UpOutlined className={classes.dropdownIcon} />
          ) : (
            <DownOutlined className={classes.dropdownIcon} />
          )}
        </Button>
      </InlineDropdown>
    </div>
  )
}
