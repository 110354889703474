// libs
import React, { useState, useContext } from 'react'
// contexts and types
import { SessionContext } from 'contexts/session/SessionContext'
import { LinesOfBusiness } from 'types/session/linesOfBusiness'
// hooks
import usePlacesAutocomplete, { getGeocode, getLatLng } from 'use-places-autocomplete'
import { useTranslation } from 'hooks/useTranslation'
// utils

// styles
import { createUseStyles } from 'react-jss'
import styles from './MainInput.styles'
import { Typography, Input, Button, Divider } from 'antd'
// assets
import PinkIcon from 'assets/pins/Pink.png'
import PurpleIcon from 'assets/pins/Purple.png'
import { addressInVendorDeliveryArea } from 'services/vendorApi/addressInVendorDeliveryArea'
import { useApiService } from 'hooks/useApiService'
import { WidgetErrorHandler } from 'components/WidgetErrorHandler/widgetErrorHandler'
import { useCaptureUserAction } from 'hooks/events/useCaptureUserAction'
import { logError } from 'utils/reporting/logError'
import { VendorApiResponse } from 'types/api/vendorApi/vendor'

const useStyles = createUseStyles(styles)

interface Props {
  order: any
  vendor: VendorApiResponse
  currentDeliveryAddress: string
  isNewAddressSelected: boolean
  setisNewAddressSelected: (arg: boolean) => void
  differenceBetweenAddressesInKm: number
  handleClearAddressClick: () => void
  setNewAddressMain: (arg: string) => void
  setNewAddressLat: (arg: number) => void
  setNewAddressLng: (arg: number) => void
  setNewAddressStreetNumber: (arg: string) => void
  setNewAddressStreet: (arg: string) => void
  setNewAddressPostalcode: (arg: string) => void
  isDeliveryInstructionsInputActive: boolean
  setIsDeliveryInstructionsInputActive: (arg: boolean) => void
  currentCountryOfOrder: string
  showDistanceFromOldToNewAddress: boolean
  showDistanceFromSourceToNewAddress: boolean
  validateAddress: boolean
  setIsNewAddressDeliverable: (arg: boolean) => void
  lob: LinesOfBusiness

  setSuggestionsShown: () => any
}

const MainInput: React.FC<Props> = ({
  order,
  currentDeliveryAddress,
  isNewAddressSelected,
  setisNewAddressSelected,
  differenceBetweenAddressesInKm,
  handleClearAddressClick,
  setNewAddressMain,
  setNewAddressLat,
  setNewAddressLng,
  setNewAddressStreetNumber,
  setNewAddressStreet,
  setNewAddressPostalcode,
  isDeliveryInstructionsInputActive,
  setIsDeliveryInstructionsInputActive,
  currentCountryOfOrder,
  showDistanceFromOldToNewAddress,
  showDistanceFromSourceToNewAddress,
  validateAddress,
  setIsNewAddressDeliverable,
  lob,
  vendor,
  setSuggestionsShown,
}) => {
  const classes = useStyles()
  const { Text } = Typography

  // pull translations
  const { t } = useTranslation()
  const captureUserAction = useCaptureUserAction()

  // pull globalentity ID from session context
  const {
    sessionState: { globalEntityId },
  } = useContext(SessionContext)

  const {
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    debounce: 300,
    requestOptions:
      vendor?.location && window.google?.maps
        ? {
            location: new window.google.maps.LatLng(
              vendor.location.latitude,
              vendor.location.longitude,
            ),
            radius: 100 * 1000, // 100 km,
          }
        : ({} as any),
  })

  const handleAddressInputChange = (e: any) => {
    setisNewAddressSelected(false)
    setNewAddressMain('')
    setNewAddressStreetNumber('')
    setNewAddressStreet('')
    setNewAddressPostalcode('')
    setNewAddressLat(null)
    setNewAddressLng(null)
    setValue(e.target.value)
  }

  const {
    loadService: checkAddressInVendorDeliveryArea,
    error: checkAddressInVendorDeliveryAreaError,
  } = useApiService({
    service: addressInVendorDeliveryArea,
    deps: [],
    autoLoad: false,
  })

  // checks if passed lat and lon are within vendor's delivery area & updates state accordingly
  const checkVendorDeliveryAreaAsync = async (latitude: number, longitude: number) => {
    const checkVendorDeliveryArea = () => {
      // fire client
      return checkAddressInVendorDeliveryArea({
        entityId: globalEntityId,
        latitude,
        longitude,
        vendorId: order.vendor.id,
      })
        .then(({ data }) => {
          if (data.is_deliverable) {
            setIsNewAddressDeliverable(true)
          } else {
            return Promise.reject('address not deliverable')
          }
        })
        .catch((err) => {
          setIsNewAddressDeliverable(false)
        })
        .finally(() => {
          setNewAddressLat(latitude)
          setNewAddressLng(longitude)
        })
    }

    await checkVendorDeliveryArea()
  }

  const handleSelect =
    ({ description }) =>
    () => {
      setisNewAddressSelected(true)
      setNewAddressMain(description)

      // Track number of suggestions on select suggestion.
      captureUserAction('ActionsChangeAddressSuggestionSelected', {
        eventDetails: {
          'amount-suggestions': data.length,
        },
      })

      // When user selects a place, we can replace the keyword without request data from API
      // by setting the second parameter as "false"
      setValue(description, false)
      clearSuggestions()

      // Get latitude and longitude via utility functions
      getGeocode({ address: description })
        .then((results) => {
          // get address components from results
          results[0].address_components.forEach((addressComponent) => {
            if (addressComponent.types.includes('street_number')) {
              setNewAddressStreetNumber(addressComponent.long_name)
            } else if (addressComponent.types.includes('route')) {
              setNewAddressStreet(addressComponent.long_name)
            } else if (addressComponent.types.includes('postal_code')) {
              setNewAddressPostalcode(addressComponent.long_name)
            }
          })

          return getLatLng(results[0])
        })
        .then(({ lat, lng }) => {
          // when lat and lng found, check if it is within vendor's delivery area
          if (lat && lng) {
            if (validateAddress) {
              checkVendorDeliveryAreaAsync(lat, lng)
            } else {
              setNewAddressLat(lat)
              setNewAddressLng(lng)
            }
          }
        })
        .catch((error) => {
          logError({ type: 'geocode-error', originalError: error })
        })
    }

  const [suggestedItemCurrentlyHovered, setSuggestedItemCurrentlyHovered] = useState<number>(null)
  const handleMouseHoverOnItem = (itemIndex: number, event: string) => {
    if (event === 'enter') {
      setSuggestedItemCurrentlyHovered(itemIndex)
    } else {
      setSuggestedItemCurrentlyHovered(null)
    }
  }

  // renders goodle MAPs suggestions as list elements
  const renderSuggestions = () => {
    if (data.length) {
      setSuggestionsShown()
    }
    return data.map((suggestion, idx) => {
      const {
        structured_formatting: { main_text, secondary_text },
      } = suggestion
      return (
        <div
          style={{ minWidth: 240, paddingLeft: 5 }}
          key={idx}
          onMouseEnter={() => handleMouseHoverOnItem(idx, 'enter')}
          onMouseLeave={() => handleMouseHoverOnItem(idx, 'leave')}
        >
          <Divider style={{ margin: 3, padding: 0, width: '95%' }} />
          <li
            className={
              idx === suggestedItemCurrentlyHovered
                ? classes.suggestionLiActive
                : classes.suggestionLi
            }
            onClick={handleSelect(suggestion)}
          >
            <Text className={classes.suggestionText}>{`${main_text} ${secondary_text}`}</Text>
          </li>
        </div>
      )
    })
  }

  return (
    <div className={classes.container}>
      <div
        className={
          isNewAddressSelected && differenceBetweenAddressesInKm
            ? classes.iconsAndLineContainerWithNew
            : classes.iconsAndLineContainer
        }
      >
        <img className={classes.inputDeliveryAddressIcon} src={PinkIcon} alt='customer pin icon' />
        <div className={classes.lineHolder}></div>
        <img
          className={classes.inputDeliveryAddressIcon}
          src={PurpleIcon}
          alt='customer pin icon'
        />
      </div>

      <div className={classes.flex}>
        {/* CURRENT ADDRESS */}
        <div className={classes.currentAndNewAddressHolder}>
          <div className={classes.inputTitleHolder}>
            <Text className={classes.deliveryAddressText}>
              {t('Actions Widget.Actions.Change Address.Current Delivery address')}
            </Text>
          </div>

          <div className={classes.currentAddressHolder}>
            <Text className={classes.currentDeliveryAddress}>{currentDeliveryAddress}</Text>
          </div>

          {/* NEW ADDRESS FIELD */}
          <div
            className={
              lob === LinesOfBusiness.rider
                ? classes.inputTitleHolderForNewRiderService
                : classes.inputTitleHolderForNewCustomerService
            }
          >
            <Text className={classes.deliveryAddressText}>
              {t('Actions Widget.Actions.Change Address.New Delivery Address')}&nbsp;
            </Text>
            {/* the DIFF in KM between current and new addresses */}
            {isNewAddressSelected && differenceBetweenAddressesInKm && (
              <Text className={classes.distanceText}>
                {showDistanceFromOldToNewAddress
                  ? `${t(
                      'Actions Widget.Actions.Change Address.Distance from old address',
                    )}: ${differenceBetweenAddressesInKm.toFixed(2)}km`
                  : showDistanceFromSourceToNewAddress
                  ? `${t(
                      'Actions Widget.Actions.Change Address.Distance from pick up',
                    )}: ${differenceBetweenAddressesInKm.toFixed(2)}km`
                  : null}
              </Text>
            )}
          </div>
          <div className={classes.inputWrapper}>
            <Input
              onClick={() => setIsDeliveryInstructionsInputActive(true)}
              className={classes.input}
              value={value}
              onChange={handleAddressInputChange}
              suffix={
                <Button
                  type='text'
                  className={classes.xButton}
                  onClick={() => {
                    setValue('')
                    handleClearAddressClick()
                    setIsNewAddressDeliverable(false)
                  }}
                >
                  X
                </Button>
              }
            />

            <WidgetErrorHandler
              errorPayload={checkAddressInVendorDeliveryAreaError?.errorPayload}
              displayType='mini'
            />
          </div>

          {/* SUGGESTION SECTION -> RENDERED AS LIST OF SUGGESTED ADDRESSES */}
          {status === 'OK' && isDeliveryInstructionsInputActive && (
            <div data-test-id='suggestions-holder' className={classes.suggestionsHolder}>
              <ul className={classes.suggestionsUl}>{renderSuggestions()}</ul>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default MainInput
