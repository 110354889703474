import React, { ReactElement, useEffect, useState } from 'react'
import { createUseStyles } from 'react-jss'
import { ApiErrorPayload } from 'types/error'

import { useTranslation } from 'hooks/useTranslation'
import {
  ApiErrorConfigOverrideOpts,
  ApiErrorDisplayType,
  getApiErrorConfig,
} from './helpers/getApiErrorConfig'
import { getApiErrorIcon } from './helpers/getApiErrorIcon'
import { apiErrorScreenTypeToAlertType } from './helpers/errorScreenToAlertType'
import LoadingView from 'components/LoadingView'
import { Alert, Button, Empty, Result } from 'antd'
import styles from './widgetErrorHandler.styles'
import classNames from 'classnames'
import { ReloadOutlined } from '@ant-design/icons'
import { ProcessQuitConfirmation } from 'components/ProcessQuitConfirmation/ProcessQuitConfirmation'
import { refreshJwt } from 'utils/refreshJwt'
import { useApiClientCreator } from 'contexts/apiClientCreator/ApiClientCreatorContext'
import { useLogout } from 'hooks/auth/useLogout'
import { ButtonType } from 'antd/lib/button'
import { useAuthProvider } from 'contexts/auth/AuthProvider'
import noop from 'lodash/noop'

const useStyles = createUseStyles(styles)

export interface WidgetErrorHandlerProps extends ApiErrorConfigOverrideOpts {
  displayType?: ApiErrorDisplayType

  /**
   * indicates the widget is loading,
   */
  loading?: boolean | Record<string, boolean>

  /**
   * loading text to display
   */
  loadingText?: string

  /**
   * custom loader to display if you do not want to use the generic
   * loader
   */
  loader?: ReactElement

  /**
   * text to show in retry button
   */
  retryText?: string

  /**
   * error container className, helps you to style the error container
   */
  errorContainerClassName?: string

  /**
   * loader container className, helps you to style the loader container
   */
  loaderContainerClassName?: string

  extraActions?: Array<any>

  /**
   * children to render if there is no error and if api is not loading
   */
  children?: () => ReactElement

  /**
   * set to true if you want to show an iconic retry button
   */
  useIconRetryButton?: boolean

  /**
   * callback to retry api
   */
  onRetry?: () => void | Promise<any>

  /**
   * callback to quit widget, used by modal displays
   */
  onQuit?: () => void

  /**
   * callback to close modal and return back to action, used by modal displays
   */
  onBack?: () => void

  confirmBeforeErrorExit?: boolean

  /**
   * determines if loading screen should be displayed
   */
  showLoader?: boolean

  /**
   * list of missing api parameters
   */
  missingParameters?: string[]

  /**
   * default error payload config title,
   * or default title for missing parameters error
   */
  title?: string

  /**
   * default error payload config subtitle,
   * or default subtitle for missing parameters error
   */
  subtitle?: string

  /**
   * error payload to display
   */
  errorPayload?: ApiErrorPayload

  /**
   * if true, it means that data is unavailable because the loading of data was not allowed due to
   * missing parameters or for some unmet conditions
   */
  dataUnavailable?: boolean
}

export const WidgetErrorHandler = (opts: WidgetErrorHandlerProps) => {
  const {
    loading,
    loader,
    children,
    onRetry,
    retryText,
    displayType = 'regular',
    errorContainerClassName,
    loaderContainerClassName,
    useIconRetryButton,

    onBack,
    onQuit,

    confirmBeforeErrorExit = false,

    showLoader = true,

    extraActions = [],

    missingParameters: givenMissingParameters = [],

    errorPayload,

    title,

    subtitle,

    ...overrides
  } = opts

  const { t } = useTranslation()
  const cx = useStyles()

  const { createClient } = useApiClientCreator()
  const { authDispatch } = useAuthProvider()
  const logout = useLogout()

  const [confirmingBeforeErrorExit, setConfirmingBeforeErrorExit] = useState(false)
  const [refreshingPermissions, setRefreshingPermissions] = useState(false)

  // logout if error is refresh permission error
  useEffect(() => {
    if (errorPayload && errorPayload.endpointName === 'refreshPermissions') {
      logout()
    }
  }, [errorPayload, logout])

  const onRetryProxy = async () => {
    if (refreshPermissionBeforeRetry) {
      setRefreshingPermissions(true)
      await refreshJwt(createClient, authDispatch, {}, true)
        .catch(noop)
        .finally(() => setRefreshingPermissions(false))
    }
    try {
      await onRetry()
    } catch (ex) {
      // do nothing
    }
  }

  const missingParameters = givenMissingParameters.filter(Boolean)
  const dataUnavailable = missingParameters.length || opts.dataUnavailable || false

  let isLoading = false
  let loadingText = opts.loadingText
  let retryButtonType: ButtonType = 'primary'

  switch (displayType) {
    case 'mini':
      retryButtonType = 'link'
      break
  }

  if (typeof loading === 'boolean') {
    isLoading = loading
  } else if (loading) {
    const loadingTexts = Object.keys(loading)
    for (let i = 0; i < loadingTexts.length; i++) {
      const currentLoadingText = loadingTexts[i]
      if (loading[currentLoadingText]) {
        isLoading = true
        loadingText = currentLoadingText
        break
      }
    }
  }

  if (!errorPayload && !isLoading && !refreshingPermissions && !dataUnavailable) {
    return children?.() || null
  }

  // show loading screen
  if (isLoading || refreshingPermissions) {
    if (!showLoader) {
      return null
    }
    return (
      <div className={classNames(loaderContainerClassName, cx.wFull)}>
        {loader || (
          <div className={cx.loaderContainer}>
            <LoadingView
              text={`${
                refreshingPermissions
                  ? t('Interface.Refreshing permissions')
                  : loadingText || t('Interface.Loading')
              }...`}
            />
          </div>
        )}
      </div>
    )
  }

  const retryButton = onRetry ? (
    <Button type={retryButtonType || 'primary'} onClick={onRetryProxy} key='retry-button'>
      {useIconRetryButton ? (
        <ReloadOutlined className={cx.reloadIconButton} />
      ) : (
        `${retryText || t('Interface.Refresh')}`
      )}
    </Button>
  ) : null

  const goBackButton = onBack && (
    <Button type={'primary'} onClick={onBack} key='back-button'>
      {t('Interface.Go Back')}
    </Button>
  )

  const quitButton = onQuit && (
    <Button
      type={'default'}
      onClick={() => {
        if (confirmBeforeErrorExit) {
          return setConfirmingBeforeErrorExit(true)
        } else {
          onQuit?.()
        }
      }}
      key='quit-button'
    >
      {t('Interface.Ok')}
    </Button>
  )

  if (dataUnavailable) {
    return (
      <Result
        title={title || t('errors.api_data_unavailable.title')}
        subTitle={
          subtitle ||
          t('errors.api_data_unavailable.subtitle', {
            replace: {
              parameters: missingParameters.join(', '),
            },
          })
        }
        extra={[...extraActions].filter(Boolean)}
      />
    )
  }

  // render content if no error
  if (!errorPayload) {
    return children?.() || null
  }

  // handle error
  const {
    copyText,
    screen,
    heading,
    context,
    tParams,
    enableGoBack,
    enableRetry,
    refreshPermissionBeforeRetry,
    contextPast,
  } = getApiErrorConfig(errorPayload, displayType, overrides)

  const { statusCode, techRef } = errorPayload

  const Icon = getApiErrorIcon(screen)

  const extraParams = {
    status: undefined,
    icon: undefined,
  }

  const translatedTitle =
    title ||
    t(`apiErrors.heading.${heading}`, {
      replace: {
        context,
        contextPast,
        ...tParams,
      },
    })

  const translatedSubtitle =
    subtitle ||
    t(`apiErrors.copyText.${copyText}`, {
      replace: {
        context,
        contextPast,
        ...tParams,
      },
    })

  let titleClassName

  switch (displayType) {
    case 'full':
      titleClassName = cx.fullTitle
      if (statusCode === 404) {
        extraParams.status = '404'
      } else if (statusCode === 401 || statusCode === 403) {
        extraParams.status = '403'
      } else {
        extraParams.status = '500'
      }
      break

    case 'regular':
      titleClassName = cx.regularTitle
      extraParams.icon = <Icon fontSize={50} />
      break

    case 'mini':
      titleClassName = cx.miniTitle
      retryButtonType = 'link'
      break
  }

  const extras = [
    !enableGoBack && !enableRetry && displayType === 'overlay' && quitButton,

    enableGoBack && goBackButton,

    enableRetry && retryButton,

    ...extraActions,
  ].filter(Boolean)

  if (displayType === 'full' || displayType === 'regular') {
    switch (screen) {
      case 'no-data':
        return (
          <div className={classNames(errorContainerClassName, cx.errorContainer)}>
            <div>
              <Empty description={translatedTitle} image={Empty.PRESENTED_IMAGE_SIMPLE}>
                {extras}
              </Empty>
            </div>
          </div>
        )

      default:
        return (
          <div className={classNames(errorContainerClassName, cx.errorContainer)}>
            <div>
              <Result
                {...extraParams}
                title={<p className={titleClassName}>{translatedTitle}</p>}
                subTitle={
                  <>
                    {translatedSubtitle
                      .split('.')
                      .filter(Boolean)
                      .map((current) => current.trim() + '.')
                      .map((current, index) => (
                        <p className={cx.subtitle} key={index}>
                          {current}
                        </p>
                      ))}

                    <p className={cx.secondaryText}>{techRef}</p>
                  </>
                }
                extra={extras}
              />
            </div>
          </div>
        )
    }
  }

  if (displayType === 'mini') {
    switch (screen) {
      case 'no-data':
        return (
          <div className={classNames(errorContainerClassName)}>
            <div className={cx.miniNoDataContainer}>
              <div className={cx.miniNoDataIconHolder}>{Empty.PRESENTED_IMAGE_SIMPLE}</div>
              <p className={cx.miniNoDataTitle}>{translatedTitle}</p>
              {enableRetry && retryButton && <div className={cx.mlAuto}>{retryButton}</div>}
            </div>
          </div>
        )

      default:
        return (
          <Alert
            message={<p className={titleClassName}>{translatedTitle}</p>}
            description={<p className={cx.miniDescription}>{translatedSubtitle}</p>}
            type={apiErrorScreenTypeToAlertType(screen)}
            className={classNames(cx.miniAlert, errorContainerClassName)}
            showIcon
            action={enableRetry && retryButton}
            icon={
              <div className={cx.miniAlertIconHolder}>
                <Icon fontSize={16} />
              </div>
            }
          />
        )
    }
  }

  // content for modal and content display type

  return (
    <div className={cx.modalContent}>
      {confirmingBeforeErrorExit && (
        <ProcessQuitConfirmation process onQuit={onQuit} onStay={onBack} />
      )}

      {!confirmingBeforeErrorExit && (
        <>
          {/* icon */}
          <div className={cx.modalIcon}>
            <Icon fontSize={90} />
          </div>

          {/* heading */}
          <h3 className={cx.modalHeading}>{translatedTitle}</h3>

          {/* copy text */}
          <div>
            <p className={cx.modalCopyText}>{translatedSubtitle}</p>
          </div>

          {/* secondary text */}
          <p className={cx.modalSecondaryText}>{techRef}</p>

          <div className={cx.modalButtonContainer}>{extras}</div>
        </>
      )}
    </div>
  )
}
