/**
 * To calculate the granular value of a given value x with a given granularity g,
 * you would first divide x by g, and then round up to the nearest whole number.
 * You would then multiply this whole number by g to get the upper granular value.
 * For example if x = 12 and g = 5, you would divide 12 by 5 to get 2.4, round up to 3,
 * and then multiply 3 by 5 to get 15, which is the granular value.
 *
 * Here, we first convert given inputs to integer before proceeding with granular value calculation
 * to avoid pitfalls associated with decimal precision.
 *
 * Please refer test cases for limits of this method.
 */

const countDigitsAfterDecimal = (value: number): number => {
  if (String(value).indexOf('.') !== -1) {
    return String(value).split('.')[1].length
  }
  return 0
}

/**
 *Calculates the granular value of a given number based on a provided granularity.
 *@param {number} value - The value to calculate the granular value of.
 *@param {number} granularity - The granularity to use for the calculation.
 *@returns {number} The granular value of the given number.
 */
export const calculateGranularValue = (value: number, granularity: number): number => {
  if (granularity === 0) return value
  if (value < granularity) {
    return granularity
  }

  const valueNumberOfDigitsAfterDecimal = countDigitsAfterDecimal(value)
  const granularNumberofDigitsAfterDecimal = countDigitsAfterDecimal(granularity)

  const maxNumberofDigitsAfterDecimal = Math.max(
    valueNumberOfDigitsAfterDecimal,
    granularNumberofDigitsAfterDecimal,
  )
  const multiFactor = Math.pow(10, maxNumberofDigitsAfterDecimal)
  const scaledValue = Math.round(value * multiFactor)
  const scaledGranularity = Math.round(granularity * multiFactor)

  const remainder = Math.round(scaledValue % scaledGranularity)

  if (remainder) {
    const valueScaled = scaledGranularity - remainder + scaledValue
    return valueScaled / multiFactor
  }
  return value
}
