import React from 'react'
import { Space, Tag } from 'antd'
import { useTranslation } from 'hooks/useTranslation'
import prettifyString from 'utils/prettifyString'
import { useStyles } from './styles'

const SPACING = 8
const DEFAULT_TRANSLATION = 'no_translation_found'

export const DeliveryInstructionsTags: React.FC<{ tags: string[] }> = ({ tags }) => {
  const { t } = useTranslation()
  const classes = useStyles()

  if (tags.length === 0) return null

  return (
    <Space size={SPACING} wrap className={classes.root}>
      {tags.map((tag) => {
        let text = t(`widgets.order_deliveries.delivery_instructions_tags.${tag}`, {
          defaultValue: DEFAULT_TRANSLATION,
        })

        if (text === DEFAULT_TRANSLATION) {
          text = prettifyString(tag)
        }

        return (
          <Tag key={tag} className={classes.tag}>
            {text}
          </Tag>
        )
      })}
    </Space>
  )
}
