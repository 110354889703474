import React from 'react'
import { createCustomSvgIcon } from 'factory/createCustomSvgIcon'

export const RiderIcon = createCustomSvgIcon({
  viewBoxWidth: 36,
  viewBoxHeight: 36,
  content: (
    <>
      <svg xmlns='http://www.w3.org/2000/svg'>
        <path d='M25.1549 4C24.9861 4 24.8213 4.0169 24.662 4.0491C24.0604 4.1707 23.5385 4.51045 23.1829 4.98182H20.7183V7.92727H23.1829C23.5385 8.39864 24.0604 8.73839 24.662 8.85999C24.8213 8.89219 24.9861 8.90909 25.1549 8.90909H27.6197V4H25.1549Z' />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M25.2173 10.3818C23.5856 10.3818 22.2629 11.699 22.2629 13.3239V24.1273H18.1221V19.0434C18.1221 17.1065 16.5453 15.5364 14.6003 15.5364H12.4972C12.7019 15.3565 12.831 15.0932 12.831 14.8V7.92727C12.831 7.38503 12.3896 6.94545 11.8451 6.94545H1.98592C1.44141 6.94545 1 7.38503 1 7.92727V14.8C1 15.3422 1.44141 15.7818 1.98592 15.7818H9.26821C4.81668 16.7854 1.49296 20.7489 1.49296 25.4855C1.49296 26.3621 2.20657 27.0727 3.08685 27.0727H4.47778C4.723 29.2818 6.60367 31 8.88732 31C11.171 31 13.0516 29.2818 13.2969 27.0727H26.5658C26.7645 27.0727 26.9594 27.06 27.1499 27.0354C27.3779 29.2624 29.2669 31 31.5634 31C34.0137 31 36 29.0219 36 26.5818C36 24.1417 34.0137 22.1636 31.5634 22.1636C31.366 22.1636 31.1716 22.1765 30.981 22.2014C30.9509 21.9185 30.8926 21.6331 30.804 21.3482L28.0396 12.454C27.6566 11.2219 26.5125 10.3818 25.2173 10.3818ZM33.0423 26.5818C33.0423 25.7685 32.3801 25.1091 31.5634 25.1091C30.7466 25.1091 30.0845 25.7685 30.0845 26.5818C30.0845 27.3952 30.7466 28.0545 31.5634 28.0545C32.3801 28.0545 33.0423 27.3952 33.0423 26.5818ZM7.4926 27.0727C7.69562 27.6447 8.24341 28.0545 8.88732 28.0545C9.53124 28.0545 10.079 27.6447 10.282 27.0727H7.4926ZM26.5658 24.1273H25.2207V13.3461L27.9785 22.2191C28.273 23.1666 27.5618 24.1273 26.5658 24.1273ZM15.1643 19.0434V24.1273H4.58285C5.21787 20.9093 8.06605 18.4818 11.4836 18.4818H14.6003C14.9118 18.4818 15.1643 18.7333 15.1643 19.0434ZM3.95775 12.8364V9.89091H9.87324V12.8364H3.95775Z'
        />
        <path d='M11.8451 5.96364C12.3896 5.96364 12.831 5.52406 12.831 4.98182C12.831 4.43958 12.3896 4 11.8451 4H1.98592C1.44141 4 1 4.43958 1 4.98182C1 5.52406 1.44141 5.96364 1.98592 5.96364H11.8451Z' />
      </svg>
    </>
  ),
})
