import React, { useState } from 'react'
import { Button, Typography } from 'antd'
import { createUseStyles } from 'react-jss'
import { DownOutlined, UpOutlined } from '@ant-design/icons'
import styles from './Refund.styles'
import { useTranslation } from 'hooks/useTranslation'

const useStyles = createUseStyles(styles)

type Props = {
  currency: string
  totalRefundAmount: string
  voucherMinOrderValue: string
  voucherPaymentTypes: string
  validDates: string
}

const VoucherDetails = ({
  currency,
  totalRefundAmount,
  voucherMinOrderValue,
  validDates,
  voucherPaymentTypes,
}: Props) => {
  const classes = useStyles()
  const { Text } = Typography
  const { t } = useTranslation()

  const [showVoucherDetails, setShowVoucherDetails] = useState(false)

  const handleToggleVoucherDetails = () => {
    setShowVoucherDetails(!showVoucherDetails)
  }

  return (
    <div>
      <Button
        type={'text'}
        onClick={handleToggleVoucherDetails}
        className={classes.voucherDetailsBtn}
      >
        {showVoucherDetails
          ? t('Actions Widget.Actions.Partial Refund.Hide voucher details')
          : t('Actions Widget.Actions.Partial Refund.Show voucher details')}
        {showVoucherDetails ? (
          <UpOutlined className={classes.voucherDetailsBtnIcon} />
        ) : (
          <DownOutlined className={classes.voucherDetailsBtnIcon} />
        )}
      </Button>
      {showVoucherDetails && (
        <>
          {/* voucher info -> value, min. order value, validity and payment type */}
          <div className={classes.textBlock}>
            <Text className={classes.titleText}>{`${t('Widgets Common.Voucher Value')}:`}</Text>
            <Text className={classes.dataText}>{`${currency} ${totalRefundAmount}`}</Text>
          </div>

          <div className={classes.textBlock}>
            <Text className={classes.titleText}>
              {`${t('Customer Widget.Tabs.Vouchers.Voucher Details.Min Order Value')}:`}
            </Text>
            <Text className={classes.dataText}>{`${currency} ${voucherMinOrderValue}`}</Text>
          </div>

          <div className={classes.textBlock}>
            <Text className={classes.titleText}>
              {`${t('Customer Widget.Tabs.Vouchers.Valid Dates')}:`}
            </Text>
            <Text className={classes.dataText}>{validDates}</Text>
          </div>

          <div className={classes.textBlock}>
            <Text className={classes.titleText}>
              {`${t('Customer Widget.Tabs.Vouchers.Voucher Details.Payment Types')}:`}
            </Text>
            <Text className={classes.dataText}>{voucherPaymentTypes}</Text>
          </div>
        </>
      )}
    </div>
  )
}

export default VoucherDetails
