/**
 * Actions Widget
 * renders a button opening up a modal for each available OneView actions
 * */

// libs
import React, { FC, useContext, useMemo } from 'react'
// configs
import { allowedActions } from 'entityConfig/allowedConfigValues'
// contexts and types
import { SessionContext } from 'contexts/session/SessionContext'
import { DataContext } from 'contexts/data/DataContext'
// hooks
import { useTranslation } from 'hooks/useTranslation'
import { useEntityConfig } from 'hooks/useEntityConfig'
// styles
import { createUseStyles } from 'react-jss'
import styles from './Actions.styles'
import {
  CheckDisplayRulesOpts,
  CheckDisplayRulesResult,
  useCheckDisplayRules,
} from 'hooks/useCheckDisplayRules'
import { transformFeatureToDisplayRules } from 'utils/transformFeatureToDisplayRules'
import { builtinWidgetNames, DisplayRules, widgetTypes } from 'types/herocare'
import ModalButtonView from 'components/ModalButtonView'
import { useWidgetViewManager } from 'contexts/widgetViewManager'
import { useCaptureUserAction } from 'hooks/events/useCaptureUserAction'
import { pascalCase } from 'utils/pascalCase'
import { useUserHaveAny } from 'hooks/useGetUserPermissions'
import environment from 'envConfig'
import { OrderStatuses } from 'types/widgets/order/orderStatuses'
import { DeliveryProviders, WidgetSubjects } from 'types/unitedUiConfig'

const useStyles = createUseStyles(styles)

export const actionToComponentMap = new Map<
  allowedActions,
  {
    action_title: string
    widget_title?: string
    widget_name: builtinWidgetNames
    must_have_case_id?: boolean
    must_have_order?: boolean

    // allows you to define a custom display rules
    custom_display_rules?: DisplayRules
  }
>([
  [
    allowedActions.cancelOrder,
    {
      action_title: 'action_labels.cancel_order',
      widget_title: 'widget_labels.cancel_order',
      widget_name: builtinWidgetNames.cancelOrder,
      must_have_order: true,
    },
  ],
  [
    allowedActions.searchOrder,
    {
      action_title: 'action_labels.search_order',
      widget_title: 'widget_labels.search_order',
      widget_name: builtinWidgetNames.searchOrder,
    },
  ],
  [
    allowedActions.searchVendor,
    {
      action_title: 'action_labels.search_vendor',
      widget_title: 'widget_labels.search_vendor',
      widget_name: builtinWidgetNames.searchVendor,
    },
  ],
  [
    allowedActions.searchVoucher,
    {
      action_title: 'action_labels.search_voucher',
      widget_title: 'widget_labels.search_voucher',
      widget_name: builtinWidgetNames.searchVoucher,
    },
  ],
  [
    allowedActions.changeCookingInstructions,
    {
      action_title: 'action_labels.change_cooking_instructions',
      widget_title: 'widget_labels.change_cooking_instructions',
      widget_name: builtinWidgetNames.changeCookingInstructions,
      must_have_order: true,
    },
  ],
  [
    allowedActions.changeDeliveryTime,
    {
      action_title: 'action_labels.change_delivery_time',
      widget_title: 'widget_labels.change_delivery_time',
      widget_name: builtinWidgetNames.changeDeliveryTime,
      must_have_order: true,
    },
  ],
  [
    allowedActions.modifyDeliveryAddress,
    {
      action_title: 'action_labels.modify_delivery_address',
      widget_title: 'widget_labels.modify_delivery_address',
      widget_name: builtinWidgetNames.modifyDeliveryAddress,
      must_have_order: true,
    },
  ],
  [
    allowedActions.compensation,
    {
      action_title: 'action_labels.compensation',
      widget_title: 'widget_labels.compensation',
      widget_name: builtinWidgetNames.compensation,
      must_have_case_id: true,
      must_have_order: true,
    },
  ],
  [
    allowedActions.partialRefund,
    {
      action_title: 'action_labels.partial_refund',
      widget_title: 'widget_labels.partial_refund',
      widget_name: builtinWidgetNames.partialRefund,
      must_have_case_id: true,
      must_have_order: true,
    },
  ],
  [
    allowedActions.fullRefund,
    {
      action_title: 'action_labels.full_refund',
      widget_title: 'widget_labels.full_refund',
      widget_name: builtinWidgetNames.fullRefund,
      must_have_case_id: true,
      must_have_order: true,
    },
  ],
  [
    allowedActions.newComment,
    {
      action_title: 'action_labels.new_comment',
      widget_title: 'widget_labels.new_comment',
      widget_name: builtinWidgetNames.newComment,
      must_have_case_id: true,
    },
  ],
  [
    allowedActions.vendor_availability,
    {
      action_title: 'action_labels.vendor_availability',
      widget_title: 'widget_labels.vendor_availability',
      widget_name: builtinWidgetNames.vendorAvailability,
    },
  ],
  [
    allowedActions.switch_payable,
    {
      action_title: 'action_labels.switch_payable',
      widget_title: 'widget_labels.switch_payable',
      widget_name: builtinWidgetNames.switch_payable,
    },
  ],
])

const Actions: FC = () => {
  const classes = useStyles()

  // pull language content
  const { t } = useTranslation()

  const { availableActions: available_actions } = useEntityConfig()

  const userHaveAny = useUserHaveAny()

  // pull globalentity ID from session context
  const {
    sessionState: { globalEntityId, caseId, riderId },
  } = useContext(SessionContext)

  // pull dataState from data context
  const {
    dataState: {
      order,
      vendor,
      rider,
      ticket,
      customer,
      compensatedVoucher,
      refundToWalletOrPspDetails,
      fullyRefundedVoucher,
      fulfillment,
    },
  } = useContext(DataContext)

  const checkDisplayRules = useCheckDisplayRules()

  const currentRiderDelivery = useMemo(() => {
    return fulfillment?.deliveries?.find((delivery) => String(delivery?.courier?.id) === riderId)
  }, [fulfillment, riderId])

  const actions = useMemo(() => {
    const isPartiallyRefunded = compensatedVoucher && compensatedVoucher.purpose === 'refund'
    const isCompensation = compensatedVoucher && compensatedVoucher.purpose === 'compensate'

    const statusHistory = order?.status_history || []
    const lastStatusHistory = statusHistory[statusHistory.length - 1]

    const lastStatus = lastStatusHistory?.status || OrderStatuses.placed

    const deliveryProvider = order?.delivery?.provider || null

    return (available_actions || [])
      .map(
        (
          action,
        ): CheckDisplayRulesResult & {
          isPlugin: boolean
          widgetTitle: string
          actionTitle: string
          widgetName: string | builtinWidgetNames
          id: string
        } => {
          const displayRules = transformFeatureToDisplayRules(action)

          // Pass delivery corresponding to current rider ( old UI )
          const checkDisplayRulesOpts: CheckDisplayRulesOpts = {
            delivery: currentRiderDelivery,
          }

          const isPlugin = action.type === 'plugin'

          let result = checkDisplayRules(displayRules, checkDisplayRulesOpts)

          if (isPlugin) {
            return {
              ...result,
              isPlugin: true,
              widgetTitle: action.feature,
              actionTitle: action.feature,
              widgetName: action.plugin_code,
              id: action.plugin_code,
            }
          }

          if (!actionToComponentMap.get(action.feature as any)) {
            return null
          }

          const {
            widget_title,
            action_title,
            widget_name,
            must_have_order,
            must_have_case_id,
            custom_display_rules,
          } = actionToComponentMap.get(action.feature as any)

          if (result.visible && result.enabled && custom_display_rules) {
            result = checkDisplayRules(custom_display_rules, checkDisplayRulesOpts)
          }

          if (result.visible && result.enabled && must_have_order && !order) {
            result.enabled = false
            result.reason = t('Messages.No Order ID Found!')
          }

          if (result.visible && result.enabled && must_have_case_id && !caseId) {
            result.enabled = false
            result.reason = t('Messages.No Case ID Found!')
          }

          // add more specific validations
          if (result.visible && result.enabled) {
            switch (action.feature) {
              case allowedActions.cancelOrder:
                if (
                  !userHaveAny(globalEntityId, [
                    environment().cancelOrderPermissions,
                    environment().roleApacDefaultView,
                    environment().roleApacWalletView,
                  ])
                ) {
                  result.enabled = false
                  result.reason = t(
                    'Actions Widget.Actions.Cancel Order.Order cancellation is not permitted for the current user',
                  )
                }
                break

              case allowedActions.compensation:
                if (isCompensation) {
                  result.enabled = false
                  result.reason = t(
                    'Actions Widget.Actions.Compensation.Customer has already been compensated',
                  )
                }
                break

              case allowedActions.modifyDeliveryAddress:
                if (!lastStatusHistory || !deliveryProvider) {
                  result.enabled = false
                  result.reason = t(
                    'Actions Widget.Actions.Change Address.Changing delivery address and instructions is not possible',
                  )
                } else if (
                  lastStatus === OrderStatuses.cancelled ||
                  lastStatus === OrderStatuses.delivered ||
                  lastStatus === OrderStatuses.rejected
                ) {
                  result.enabled = false
                  result.reason = t(
                    'Actions Widget.Actions.Change Address.Changing order delivery address or instructions is not possible due to the order status',
                  )
                } else if (
                  deliveryProvider === 'pickup' ||
                  deliveryProvider === 'vendor_delivery' ||
                  deliveryProvider === 'partner_delivery'
                ) {
                  result.enabled = false
                  result.reason = t(
                    'Actions Widget.Actions.Change Address.Changing order delivery address or instructions is not possible due to the provider',
                  )
                }
                break

              case allowedActions.partialRefund:
                if (isPartiallyRefunded || refundToWalletOrPspDetails) {
                  result.enabled = false
                  result.reason = t(
                    'Actions Widget.Actions.Partial Refund.Customer has already been partially refunded',
                  )
                }
                break

              case allowedActions.fullRefund:
                if (fullyRefundedVoucher) {
                  result.enabled = false
                  result.reason = t(
                    'Actions Widget.Actions.Full Refund.The full refund has been already issued',
                  )
                }
                break

              case allowedActions.changeDeliveryTime:
                if (
                  statusHistory.length === 0 ||
                  statusHistory.find(
                    (current) =>
                      current.status === OrderStatuses.accepted ||
                      current.status === OrderStatuses.cancelled ||
                      current.status === OrderStatuses.rejected,
                  )
                ) {
                  result.enabled = false
                  result.reason = t(
                    `Actions Widget.Actions.Change Address.Changing order delivery address or instructions is not possible due to the order status`,
                  )
                } else if (!order?.preorder) {
                  result.enabled = false
                  result.reason = t(
                    `Actions Widget.Actions.Change Delivery Time.Changing delivery time is possible only for unaccepted pre-orders`,
                  )
                } else if (
                  deliveryProvider === DeliveryProviders.vendorDelivery ||
                  deliveryProvider === DeliveryProviders.partnerDelivery
                ) {
                  result.enabled = false
                  result.reason = t(
                    `Actions Widget.Actions.Change Delivery Time.Changing delivery time is not possible for vendor delivery orders`,
                  )
                }
                break
            }
          }

          return {
            ...result,
            actionTitle: action_title,
            widgetTitle: widget_title,
            widgetName: widget_name,
            isPlugin: false,
            id: widget_name,
          }
        },
      )
      .filter(Boolean)
  }, [
    available_actions,
    order,
    checkDisplayRules,
    t,
    userHaveAny,
    caseId,
    compensatedVoucher,
    fullyRefundedVoucher,
    globalEntityId,
    refundToWalletOrPspDetails,
    currentRiderDelivery,
  ])

  const { activateWidgetView } = useWidgetViewManager()
  const captureUserAction = useCaptureUserAction()

  const activationSubjects = useMemo((): WidgetSubjects => {
    return { order, rider, vendor, customer, ticket, delivery: currentRiderDelivery }
  }, [order, rider, vendor, customer, ticket, currentRiderDelivery])

  return (
    <div data-test-id='actions-container'>
      <p className={classes.actionsTitle}>{t('Actions Widget.Title')}</p>
      <div className={classes.componentsContainer}>
        {actions.map(
          ({ visible, widgetName, widgetTitle, id, actionTitle, isPlugin, enabled, reason }) => {
            if (!visible) {
              return null
            }
            return (
              <ModalButtonView
                data-test-id={id}
                buttonText={t(actionTitle)}
                callBack={() => {
                  const eventName = pascalCase(widgetName)
                  captureUserAction(`Actions${eventName}OpenButtonClicked`)

                  if (isPlugin) {
                    activateWidgetView(
                      {
                        type: widgetTypes.plugin,
                        plugin_code: widgetName,
                        label: {
                          label_translation_key: widgetTitle,
                        },
                      },
                      {
                        subjects: activationSubjects,
                      },
                    )
                  } else {
                    activateWidgetView(
                      {
                        type: widgetTypes.builtin,
                        widget_name: widgetName as builtinWidgetNames,
                        label: {
                          label_translation_key: widgetTitle,
                        },
                      },
                      {
                        subjects: activationSubjects,
                      },
                    )
                  }
                }}
                disabled={!enabled}
                reason={reason}
                key={id}
              />
            )
          },
        )}
      </div>
    </div>
  )
}

export default Actions
