import { Button, Result } from 'antd'
import LoadingView from 'components/LoadingView'
import { useTranslation } from 'hooks/useTranslation'
import React, { PropsWithChildren } from 'react'
import { createUseStyles } from 'react-jss'
import classNames from 'classnames'

const useStyles = createUseStyles({
  hide: {
    display: 'none',
  },
})

export interface ProcessQuitConfirmationProps {
  onQuit: (isAcknowledgement?: boolean) => void
  onStay: () => void

  process?: boolean

  isLoading?: boolean
  loadingText?: string
}

export const ProcessQuitConfirmation = ({
  process = false,
  isLoading = false,
  loadingText = '',
  children,
  onStay,
  onQuit,
}: PropsWithChildren<ProcessQuitConfirmationProps>) => {
  const { t } = useTranslation()
  const classes = useStyles()
  return (
    <>
      {isLoading && <LoadingView text={`${loadingText || t('Interface.Loading')}...`} />}
      {!isLoading && process && (
        <Result
          status='warning'
          title={t('Messages.Process Quit.Are you sure?')}
          subTitle={t(
            'Messages.Process Quit.If you exit right now you will loose all the progress you have done',
          )}
          extra={[
            <Button onClick={onStay} type='default' key='back'>
              {t('Interface.Go Back')}
            </Button>,
            <Button onClick={() => onQuit()} type='primary' key='quit'>
              {t('Messages.Process Quit.Quit')}
            </Button>,
          ]}
        />
      )}
      <div
        className={classNames({
          [classes.hide]: process || isLoading,
        })}
      >
        {children}
      </div>
    </>
  )
}
