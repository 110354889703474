import { ApiService } from 'services/authApi/getPermissions'
import { PatchItemT } from 'types/widgets/order/orderItem'
import { OrderStatuses } from 'types/widgets/order/orderStatuses'

export const patchCookingInstructions: ApiService<{
  entityId: string
  orderId: string

  products: Array<PatchItemT>

  orderStatus: OrderStatuses
}> = (createClient, options) => {
  const {
    entityId,
    orderId,

    clientParams,
    orderStatus,

    products,
  } = options

  const uri = `OrdersAPI/v1/orders/${entityId}/${orderId}/cooking-instructions`
  const { tParams, ...otherClientParams } = clientParams || {}

  return createClient({
    endpointName: 'patchCookingInstructions',
    expectedResponseStatusCode: [200],
    tParams: {
      orderStatus,
      ...tParams,
    },
    ...otherClientParams,
  }).patch(uri, {
    products,
  })
}
