import React, { PropsWithChildren, forwardRef, useEffect } from 'react'
import noop from 'lodash/noop'
import classNames from 'classnames'

export interface ClickAwayListenerProps {
  /**
   * callback to be executed when user clicks outside the component
   * @returns
   */
  onClickAway?: (e: MouseEvent) => void

  listen: boolean

  className?: string
}

export const ClickAwayListener = forwardRef<
  HTMLDivElement,
  PropsWithChildren<ClickAwayListenerProps>
>(({ children, className, listen = true, onClickAway = noop, ...others }, ref) => {
  const onClick = (e) => {
    e.stopPropagation()
  }

  useEffect(() => {
    if (listen) {
      const callback = (e: MouseEvent) => {
        onClickAway()
      }

      window.addEventListener('click', callback, false)
      return () => {
        window.removeEventListener('click', callback, false)
      }
    }
  }, [onClickAway, listen])

  return (
    <div
      onClick={listen ? onClick : undefined}
      ref={ref}
      className={classNames('clickaway-listener', className)}
      {...others}
    >
      {children}
    </div>
  )
})
