import React, { useCallback, useEffect, useMemo } from 'react'
import { createUseStyles } from 'react-jss'
import { Typography } from 'antd'
import { SelectBox } from 'shared'
import type { SelectOption } from 'shared'
import { useTranslation } from 'hooks/useTranslation'
import { AvailableRefundMethod } from 'types/actions/partialRefund'
import captureUserAction from 'browserMonitoring/captureUserAction'

import styles from './Refund.styles'

const useStyles = createUseStyles(styles)
const { Text } = Typography

type Props = {
  selectedRefundMethod: AvailableRefundMethod
  refundMethods: AvailableRefundMethod[]
  setRefundMethod: React.Dispatch<React.SetStateAction<AvailableRefundMethod>>
}

export const RefundMethodSelect = ({
  selectedRefundMethod,
  refundMethods,
  setRefundMethod,
}: Props) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const isOnlyOneMethodAvailable = refundMethods.length === 1

  useEffect(() => {
    if (isOnlyOneMethodAvailable) {
      setRefundMethod(refundMethods[0])
    }
  }, [setRefundMethod, refundMethods, isOnlyOneMethodAvailable])

  const handleSelectRefundMethod = useCallback(
    (refundMethodOption: SelectOption) => {
      const selectedMethod = refundMethods.find(
        (method) => method.method === refundMethodOption.value,
      )

      captureUserAction('ActionsFullRefundMethodSelected', [['method', selectedMethod.method]])

      return setRefundMethod(selectedMethod)
    },
    [refundMethods, setRefundMethod],
  )

  const mapMethodToOption = useCallback(
    (method: AvailableRefundMethod): SelectOption => ({
      value: method.method,
      label: t(`Actions Widget.Actions.Compensation.Issue Types.${method.translationKey}`),
    }),
    [t],
  )

  const selectedMethodOption = selectedRefundMethod
    ? mapMethodToOption(selectedRefundMethod)
    : undefined

  const availableMethodOptions = useMemo(
    () => refundMethods.map(mapMethodToOption),
    [refundMethods, mapMethodToOption],
  )

  const label = t('Actions Widget.Actions.Full Refund.Refund Method')
  const placeholder = t('`Actions Widget.Actions.Partial Refund.Select a refund method`')

  return (
    <div className={classes.textBlock}>
      <Text className={classes.titleText}>{label}:</Text>
      <SelectBox
        disabled={isOnlyOneMethodAvailable}
        testId='refund-method-dropdown'
        options={availableMethodOptions}
        value={selectedMethodOption}
        placeholder={placeholder}
        onSelectChange={handleSelectRefundMethod}
      />
    </div>
  )
}
