import React, { useRef } from 'react'
import { ComponentProps, FunctionComponent } from 'react'
import { DatePicker as _DatePicker } from 'antd'
import { createUseStyles } from 'react-jss'
import classnames from 'classnames'

const AntRangePicker = _DatePicker.RangePicker as unknown as FunctionComponent<
  ComponentProps<typeof _DatePicker['RangePicker']>
>

type RangePickerProps = React.ComponentProps<typeof AntRangePicker> & {
  inputContainerClassName?: string
  calendarContainerClassName?: string
}

const useStyles = createUseStyles({
  calendar: {
    '& > div': {
      position: 'relative !important', // overrides inline styles set by Ant Design
    },

    '& .ant-picker-dropdown': {
      position: 'relative',
      top: '0 !important', // overrides inline styles set by Ant Design
      padding: 0,
      zIndex: 'initial',
    },

    '& .ant-picker-panel-container': {
      boxShadow: 'none',
    },

    '& .ant-picker-panel-container .ant-picker-panel-focused': {
      borderColor: 'transparent',
    },
    '& .ant-picker-date-panel': {
      width: '100%',
    },

    '& .ant-picker-date-panel .ant-picker-content': {
      width: '100%',
    },
    '& .ant-picker-panels > *:first-child button.ant-picker-header-next-btn': {
      visibility: 'visible !important',
    },

    '& .ant-picker-panels > *:first-child button.ant-picker-header-super-next-btn': {
      visibility: 'visible !important',
    },

    '& .ant-picker-panels > *:last-child': {
      display: 'none !important',
    },

    '& .ant-picker-footer-extra > div': {
      flexWrap: 'wrap !important',
    },
  },
})

export const RangePicker: React.FC<RangePickerProps> = (props) => {
  const classes = useStyles()
  const ref = useRef(null)

  return (
    <>
      <div className={props.inputContainerClassName}>
        <AntRangePicker
          format='YYYY-MM-DD'
          className={classnames(props.className)}
          getPopupContainer={() => ref.current}
          open={true}
          {...props}
        />
      </div>

      <section className={props.calendarContainerClassName}>
        <div ref={ref} className={classnames(classes.calendar)}></div>
      </section>
    </>
  )
}
