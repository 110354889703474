export default {
  flex: {
    display: 'flex',
  },

  listHolder: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },

  singleDataPointHolder: {
    width: '50%',
    marginBottom: '10px',
  },

  viewMoreButtonHolder: {
    marginTop: '20px',
    display: 'flex',
    justifyContent: 'center',
  },

  profileFieldLabel: {
    color: 'rgba(0, 0, 0, 0.45)',
    marginBottom: '2px',
  },

  modalProfileItemRow: {
    width: '55%',
    '& + &': {
      width: '45%',
    },
  },

  modalProfileItemLabel: {
    fontWeight: '400',
    paddingLeft: '20px',
  },
}
