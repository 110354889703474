import { IconPacksProvider } from 'contexts/IconPacksProvider'
import { SdkProvider } from 'contexts/SdkProvider'
import { TranslationProvider, TranslationProviderProps } from 'contexts/TranslationProvider'
import React, { ComponentProps, PropsWithChildren, forwardRef } from 'react'
import { PluggableWidget, PluggableWidgetRenderFunction, PluggableWidgetStaticProps } from 'types'

/**
 * internal widgets don't need the translations and iconpacks provider
 */
const Wrapper = (
  props: PropsWithChildren<
    ComponentProps<PluggableWidget<any>> & {
      translations?: TranslationProviderProps['translations']
    }
  >,
) => {
  const { translations, iconPacks, children, widgetId, sdk } = props
  const isInternalWidget = !widgetId?.startsWith('dh.')

  if (isInternalWidget) {
    return <SdkProvider sdk={sdk}>{children}</SdkProvider>
  }

  return (
    <TranslationProvider lang={props.lang} translations={translations || {}}>
      <IconPacksProvider iconPacks={iconPacks}>
        <SdkProvider sdk={sdk}>{children}</SdkProvider>
      </IconPacksProvider>
    </TranslationProvider>
  )
}

export const createPluggableWidget: <C>(
  widget: PluggableWidgetRenderFunction<C>,
  opts?: PluggableWidgetStaticProps<C> & {
    translations?: TranslationProviderProps['translations']
  },
) => PluggableWidget<C> = (widget, opts) => {
  const forwardsRef = widget.length === 2
  const { translations, ...restOfOpts } = opts || {}

  let OuterComponent: PluggableWidget<any>
  if (forwardsRef) {
    const InnerComponent = forwardRef(widget)
    OuterComponent = forwardRef((props, ref) => {
      const { iconPacks, widgetId, ...others } = props
      return (
        <Wrapper {...props} translations={translations}>
          <InnerComponent {...others} ref={ref} />
        </Wrapper>
      )
    }) as PluggableWidget<any>
  } else {
    const InnerComponent = widget
    OuterComponent = ((props) => {
      const { iconPacks, widgetId, ...others } = props
      return (
        <Wrapper {...props} translations={translations}>
          <InnerComponent {...others} />
        </Wrapper>
      )
    }) as PluggableWidget<any>
  }

  Object.entries(restOfOpts).forEach(([key, value]) => {
    OuterComponent[key] = value
  })

  return OuterComponent
}
