import { useContext, useMemo } from 'react'
import { DeliveryItem } from 'types/api/fulfillmentApi/fulfillment'
import { useApiService } from 'hooks/useApiService'
import { SessionContext } from 'contexts/session/SessionContext'
import { getRider } from 'services/fulfillmentApi/getRider'

export const useGetRiderStackedOrderCount = (delivery: DeliveryItem): string => {
  const {
    sessionState: { globalEntityId },
  } = useContext(SessionContext)

  // Get rider state. We are only interested in length of routes arr
  const { data: riderStateData } = useApiService({
    service: getRider,
    params: {
      entityId: globalEntityId,
      riderId: String(delivery?.courier?.id ?? ''),
    },
    shouldLoad: Boolean(String(delivery?.courier?.id ?? '') && globalEntityId),
  })

  // count of stacked orders = routes length
  const currentRiderStackedOrdersCount = useMemo(() => {
    const count = riderStateData?.courier?.routes?.length
    if (count >= 0) {
      return String(count)
    }
    return ''
  }, [riderStateData?.courier?.routes?.length])
  return currentRiderStackedOrdersCount
}
