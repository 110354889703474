import { VendorContact } from 'services/vendorApi/getVendorContacts'
import { VendorApiResponse } from 'types/herocare'

export type TransformedVendorContact = {
  id?: string
  name: string
  email: string
  role: string
  phone: string
}

export type TransformedVendorContacts = TransformedVendorContact[]

interface transformVendorContactsParams {
  vendor: VendorApiResponse
  vendorContacts: VendorContact[]
}

const emptyField = '-'

export function transformVendorContacts({
  vendor,
  vendorContacts,
}: transformVendorContactsParams): TransformedVendorContacts {
  const noVendorContacts = !vendorContacts || vendorContacts?.length === 0
  const noVendorData = !vendor?.contact?.phone || !vendor?.owners || vendor?.owners?.length === 0

  if (noVendorContacts && noVendorData) {
    return null
  }

  if (noVendorContacts) {
    return vendor.owners.map((owner) => ({
      role: 'Owner',
      email: owner?.email,
      name: owner?.name,
      phone: vendor?.contact?.phone,
    }))
  }

  return vendorContacts.map((contact) => ({
    id: contact?.id || null,
    role: contact?.contact_role || emptyField,
    email: contact?.email || emptyField,
    name: [contact?.salutation, contact?.first_name, contact?.last_name, contact?.suffix].join(' '),
    phone: contact?.mobile_phone || contact.phone || emptyField,
  }))
}
