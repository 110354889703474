/**
 * header row for each payment history item
 * */

// libs
import React from 'react'
// styles
import { createUseStyles } from 'react-jss'
import styles from './PaymentRow.styles'
import { Typography } from 'antd'

const useStyles = createUseStyles(styles)

interface Props {
  payment: any
}

const PaymentRow: React.FC<Props> = ({ payment }) => {
  const classes = useStyles()
  const { Text } = Typography

  return (
    <div className={classes.panelHeaderContainer}>
      <div className={classes.panelHeaderTitles}>
        <Text className={classes.panelHeaderDataText}>{payment.date}</Text>
        <Text className={classes.panelHeaderDataText}>{payment.status}</Text>
        <Text className={classes.panelHeaderDataText}>{payment.amount}</Text>
      </div>
    </div>
  )
}

export default PaymentRow
