enum HistoryOfEventsTabs {
  order = 'Order',
  customer = 'Customer',
  vendor = 'Vendor',
  rider = 'Rider',
  back_office = 'Back_office',
}

export enum WidgetSize {
  mini = 'mini',
  max = 'max',
}
export default HistoryOfEventsTabs
