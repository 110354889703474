/**
 * Component to show collection of changes within a card.
 */
import { Card, Divider, Space } from 'antd'
import React from 'react'
import { text } from 'theme'

import { createUseStyles } from 'react-jss'
import { Text } from '../../../../shared/Text'

import styles from './ChangeSetView.styles'
import SingleChangeView from '../SingleChangeView/SingleChangeView'
import { Flex } from 'shared/Flex'
import { SDK } from 'types'
import { BasketUpdateTransformedChange, ChangeSet } from 'hooks/useTransformBasketUpdatesV2'
import { useTranslation } from 'hooks/useTranslation'
import { Button, Notification, Popconfirm } from 'shared'
import { ProductToRestore, restoreOrderItems } from 'services/ordersApi/restoreOrderItems'
import { useSessionState } from 'hooks/useSessionState'
import { useApiClientCreator } from 'contexts/apiClientCreator/ApiClientCreatorContext'
import { useCaptureUserAction } from 'hooks/events/useCaptureUserAction'
import { readAuthPayload } from 'utils/authHelpers'

const useStyles = createUseStyles(styles)

type Props = {
  sdk: SDK
  allowRestore?: boolean
  update: ChangeSet
  currency: string
}

const restoreAllTitle = (changes: BasketUpdateTransformedChange[]) => {
  if (changes.every((change) => change.target === 'item')) {
    return 'Order Widget.Tabs.Basket Updates.Actions.Restore all removed items to the basket?'
  }
  if (changes.every((change) => change.target === 'topping')) {
    return 'Order Widget.Tabs.Basket Updates.Actions.Restore all removed toppings to the basket?'
  }
  return 'Order Widget.Tabs.Basket Updates.Actions.Restore all removed items and toppings to the basket?'
}

const ChangeSetView: React.FC<Props> = ({ update, currency, sdk, allowRestore }) => {
  const { source, reason, changes, priceChange, time } = update

  const classes = useStyles()
  const { t } = useTranslation()
  const { orderId, globalEntityId } = useSessionState()
  const { createClient } = useApiClientCreator()
  const captureUserAction = useCaptureUserAction()
  const { agentEmail } = readAuthPayload()

  const isAnyItemRestorable = update?.changes?.some((item) => item?.maxRestorableQuantity > 0)

  function handleRestoration(items: ProductToRestore[]) {
    captureUserAction('CONFIRM_RESTORE_ITEMS', {
      eventDetails: {
        items_quantity: items?.length,
        button_label: t('Confirm'),
      },
      screenDetails: {
        source_widget_category: 'action',
      },
    })

    restoreOrderItems(createClient, {
      orderId,
      entityId: globalEntityId,
      productsToRestore: items,
      metadata: { email: agentEmail },
    })
      .then(() => {
        captureUserAction('CONFIRM_RESTORE_ITEMS_SUCCESS', {
          screenDetails: {
            source_widget_category: 'action',
          },
        })

        sdk.eventEmitter.dispatchEvent({
          name: 'PARTIAL_REFUND_SUCCESS',
          payload: { orderId },
        })

        Notification.success({
          message:
            items?.length > 1
              ? t('Order Widget.Tabs.Basket Updates.Actions.Items restored')
              : t('Order Widget.Tabs.Basket Updates.Actions.Item restored'),
        })
      })
      .catch(() => {
        Notification.error({
          message:
            items?.length > 1
              ? t('Order Widget.Tabs.Basket Updates.Actions.Items could not be restored. Try again')
              : t('Order Widget.Tabs.Basket Updates.Actions.Item could not be restored. Try again'),
        })
      })
  }

  function onRestoreAllItemsConfirm() {
    const allItems: ProductToRestore[] =
      changes?.map((change) => {
        const toppingsIds = change?.item_data?.toppings?.map((topping) => topping.id)

        return {
          line_item_uuid: change.lineItemId,
          quantity: change.maxRestorableQuantity + change.quantityInBasket,
          restore_toppings: {
            apply: Boolean(toppingsIds?.length),
            topping_ids: toppingsIds,
          },
        }
      }) || []

    handleRestoration(allItems)
  }

  function onRestoreSingleItemConfirm(item: ProductToRestore) {
    handleRestoration([item])
  }

  function handleRestoreClick() {
    captureUserAction('OPEN_RESTORE_ITEMS', {
      screenDetails: {
        source_widget_category: 'action',
      },
    })
  }

  return (
    <Card
      className={classes.setHeaderContainer}
      type='inner'
      // Header
      title={
        <Space size={8}>
          <Text.Primary Component={'span'} color={text.primary}>
            {source}
          </Text.Primary>
          <Text.Secondary Component={'span'} color={text.secondary}>
            {reason}
          </Text.Secondary>
        </Space>
      }
      extra={
        allowRestore && (
          <Popconfirm
            icon={null}
            placement='topLeft'
            title={t(restoreAllTitle(update.changes))}
            onConfirm={onRestoreAllItemsConfirm}
          >
            <Button type={'link'} onClick={handleRestoreClick} disabled={!isAnyItemRestorable}>
              {t('Order Widget.Tabs.Basket Updates.Actions.Restore all')}
            </Button>
          </Popconfirm>
        )
      }
    >
      {/* Content */}
      <Flex flexDirection='column' rowGap={'12px'}>
        {changes.map((change, idx) => {
          return (
            <Flex flexDirection='column' key={idx}>
              <SingleChangeView
                change={change}
                currency={currency}
                sdk={sdk}
                allowRestore={allowRestore}
                onRestore={onRestoreSingleItemConfirm}
                onRestoreClick={handleRestoreClick}
              />
            </Flex>
          )
        })}
        {changes.length > 1 && (
          <>
            <Divider style={{ margin: 0 }} />
            <Flex justifyContent='space-between' alignItems='center' gap={'4px'}>
              <Text.Secondary color={text.secondary}>{time}</Text.Secondary>

              <Flex justifyContent='space-between' alignItems='center' gap={'4px'}>
                <Text.Secondary color={text.secondary}>Total {currency} </Text.Secondary>
                <Text.Primary color={text.primary}>
                  {sdk.numberFormatter.formatDigit(priceChange)}
                </Text.Primary>
              </Flex>
            </Flex>
          </>
        )}
      </Flex>
    </Card>
  )
}

export default ChangeSetView
