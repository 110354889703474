/**
 * History component
 * runs config checks and renders order status history when available
 * */

// libs
import React from 'react'
// contexts and types
import { OrderStatuses } from 'types/widgets/order/orderStatuses'
// hooks
import { useTranslation } from 'hooks/useTranslation'
// utils
//styles
import { Steps, Typography } from 'antd'
import {
  CloseOutlined,
  CheckOutlined,
  ArrowUpOutlined,
  LoadingOutlined,
  EllipsisOutlined,
} from '@ant-design/icons'
// styles
import { createUseStyles } from 'react-jss'
import styles from './History.styles'
import { join } from 'utils/join'
const useStyles = createUseStyles(styles)

export interface HistoryProps {
  shouldCollapseSteps: boolean
  transformedStatusHistory: any
}

export const History: React.FC<HistoryProps> = ({
  shouldCollapseSteps,
  transformedStatusHistory,
}) => {
  const classes = useStyles()
  const { Step } = Steps
  const { Text } = Typography

  const { t } = useTranslation()

  const { statusHistory = [] } = transformedStatusHistory || {}

  if (statusHistory.length === 0) {
    return null
  }

  const items = [...statusHistory]
    .reverse()
    .slice(0, shouldCollapseSteps ? 4 : statusHistory.length)

  return (
    <Steps direction='vertical' size='small'>
      {items.map((step: any, idx: number) => {
        // TODO: these checks here are always going to be false, bring this to the notice of
        // david and natalia for revalidation
        return (
          <Step
            key={idx}
            icon={
              step.value &&
              (step.value === OrderStatuses.pickupEstimated ||
                step.value === OrderStatuses.estimated) ? (
                <LoadingOutlined />
              ) : step.value === OrderStatuses.promised ? (
                <EllipsisOutlined />
              ) : step.value === OrderStatuses.delivered ? (
                <CheckOutlined style={{ color: '#379456' }} />
              ) : step.value &&
                (step.value === OrderStatuses.rejected ||
                  step.value === OrderStatuses.cancelled) ? (
                <CloseOutlined style={{ color: '#a61919' }} />
              ) : (
                <ArrowUpOutlined style={{ color: '#2d80ad' }} />
              )
            }
            status={idx === 0 ? 'process' : 'finish'}
            title={
              step.value === OrderStatuses.mistake
                ? t('Widgets Common.Change of Mind - Not Acceptable by Vendor')
                : step.value
            }
            description={
              step.value === OrderStatuses.cancelled ? (
                join([step.cancelReason, step.date])
              ) : step.value === OrderStatuses.estimated ? (
                <Text className={classes.estimatedLateText}>
                  {join([step.date, step.timeDifference])}
                </Text>
              ) : (
                step.date || t('Order Widget.Tabs.Status.Currently not available')
              )
            }
          />
        )
      })}
    </Steps>
  )
}
