import { useCallback } from 'react'
import { ApiEndpointName } from 'contexts/apiClientCreator/ApiClientCreatorContext'
import { useCheckDisplayRules } from './useCheckDisplayRules'
import { useEntityConfig } from './useEntityConfig'

export const useGetApiVersionConfig = () => {
  const checkDisplayRules = useCheckDisplayRules()

  const { apiVersions } = useEntityConfig()

  return useCallback(
    (endpointName: ApiEndpointName) => {
      const apiVersionConfig = apiVersions[endpointName]

      if (!apiVersionConfig) return undefined

      const canUseApiVersionConfig = checkDisplayRules(apiVersionConfig.display_rules)

      if (!canUseApiVersionConfig.enabled) return undefined

      return apiVersionConfig.version
    },
    [checkDisplayRules, apiVersions],
  )
}
