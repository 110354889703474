enum riderDataPointIdentifiers {
  rider_id = 'rider_id',
  rider_status = 'rider_status',
  rider_contrat_type = 'rider_contrat_type',
  rider_city = 'rider_city',
  rider_vehicle = 'rider_vehicle',
  rider_phone = 'rider_phone',
  rider_current_shift = 'rider_current_shift',
}

export default riderDataPointIdentifiers
