import { gray, text } from 'theme'

export default {
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '8px',
    color: text.secondary,
    marginBottom: '8px',
  },
  bold: {
    fontWeight: '500',
    color: text.primary,
  },
  numberTag: {
    '& .ant-badge-count': {
      paddingInline: 8,
      color: text.secondary,
      background: '#F0F0F0',
    },
  },
  cardsContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  chatRoomCard: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    border: `1px solid ${gray.gray5}`,
    padding: '12px 16px',
    '&:first-child': {
      borderRadius: '10px 10px 0 0',
    },
    '&:not(:first-child)': {
      borderTop: 'none',
    },
    '&:last-child': {
      borderRadius: '0 0 10px 10px ',
    },
    '&:only-child': {
      borderRadius: '10px',
    },
    '&:hover': {
      background: gray.gray3,
      cursor: 'pointer',
    },
  },
  chatRoomCardTitle: {
    margin: 0,
  },
  chatRoomCardSubtext: {
    margin: 0,
    fontSize: '12px',
    color: text.secondary,
  },
  chatRoomCardIcon: {},
}
