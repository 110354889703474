import React from 'react'
import { Text } from 'shared'
import { useTranslation } from 'hooks/useTranslation'

type Props = {
  type: 'range' | 'non-editable' | 'upper-limit'
  currency: string
  maxValue: number
  minValue: number
}

export const AmountHelperText = ({ type, maxValue, minValue, currency }: Props) => {
  const { t } = useTranslation()

  if (type === 'non-editable') {
    return (
      <Text.Secondary mt={4}>
        {t('Interface.Compensation amount for this issue is non-editable')}
      </Text.Secondary>
    )
  }

  if (type === 'range') {
    return (
      <Text.Secondary mt={4}>
        {t('Interface.Value range')}: {currency} {minValue} - {currency} {maxValue}
      </Text.Secondary>
    )
  }

  return (
    <Text.Secondary mt={4}>
      {t('Interface.Max value')}: {currency} {maxValue}
    </Text.Secondary>
  )
}
