export default {
  textBlock: {
    margin: '15px 0px 15px 0px',
  },
  textBlockTotalAmount: {
    display: 'flex',
    alignItems: 'center',
    margin: '-10px 0px 13px 0px',
  },
  titleText: {
    fontSize: '13px',
    fontWeight: 600,
    color: 'rgba(0, 0, 0, 0.85)',
    marginRight: '3px',
  },
  dataText: {
    fontSize: '14px',
    color: 'rgba(0, 0, 0, 0.65)',
  },
  dataTextTotalAmount: {
    fontSize: '24px',
    color: 'rgba(0, 0, 0, 0.65)',
  },
  dropdownButton: {
    fontSize: '14px',
    color: 'rgba(0, 0, 0, 0.65)',
    marginLeft: '3px',
  },
  dropdownIcon: {
    color: 'rgba(0, 0, 0, 0.25)',
  },
  alertContainer: {
    margin: '25px 0px 25px 0px',
  },
  buttonsContainer: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    margin: '10px 0px 10px 0px',
  },
  backButton: {
    marginRight: '5px',
  },
  voucherDetailsBtn: {
    padding: 0,
    border: 'none',
    background: 'white',
    '&:focus': {
      boxShadow: 'none',
      color: 'black',
      background: 'white',
    },
    '&:hover': {
      boxShadow: 'none',
      color: 'black',
      background: 'white',
    },
  },
  voucherDetailsBtnIcon: {
    fontSize: '12px',
  },
}
