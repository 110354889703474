import React from 'react'
import { FulfillmentApiResponse, OrderApiResponse } from 'types/herocare'
import { Map } from 'widgets/Order/Status/Map'
import { DeliveryItem } from 'types/api/fulfillmentApi/fulfillment'

type Props = {
  order: OrderApiResponse
  delivery: DeliveryItem
  fulfillment: FulfillmentApiResponse
}

export const HistoryMap = ({ order, fulfillment, delivery }: Props) => {
  const isStatusHistoryAvailable = order?.status_history?.length > 0
  const vendorLocation = fulfillment?.deliveries.find((delivery) =>
    Boolean(delivery.pickup_location),
  )?.pickup_location
  const customerLocation = fulfillment?.deliveries.find((delivery) =>
    Boolean(delivery.dropoff_location),
  )?.dropoff_location

  return (
    <Map
      isStatusHistoryAvailable={isStatusHistoryAvailable}
      riderLocations={delivery?.courier?.locations}
      riderEvents={delivery.courier_events}
      showRefreshStatus
      vendorLocation={vendorLocation}
      customerLocation={customerLocation}
      vendorName={order?.vendor?.name}
      deliveryInstructions={order?.delivery?.location?.description}
      dropOffAddress={order?.delivery?.location?.address_text}
    />
  )
}
