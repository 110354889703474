import { ItemStructured } from 'types/actions/partialRefund'
import { ItemsItem } from 'types/api/orderApi/order'
import { structureOrderItemOption } from './structureOderItemOption'

export const structureOrderItem = (item: ItemsItem): ItemStructured => {
  const { quantity, unit_price, options, name, display_name, ...others } = item

  const result: ItemStructured = {
    type: item.is_bundle ? 'bundle' : 'item',
    parent_type: null,
    name: name,
    display_name: display_name || name,
    ...others,
    quantity: quantity || 1,
    total_quantity: quantity || 1,
    unit_price: unit_price || 0,
    partial_description: '',
    partially_selected: false,
    selected: false,

    parent_item_selected: false,
    parent_item_partially_selected: false,
  }

  // in the initial setup, we assume all items are missing
  result.total_quantity = result.quantity
  result.missing_quantity = result.quantity
  result.max_missing_quantity = result.missing_quantity

  result.options = (options || []).map((option) => {
    return structureOrderItemOption(option, result)
  })

  return result
}

export const getFromStructuredItems = (
  items: ItemStructured[],
  filter:
    | ItemStructured['type']
    | Array<ItemStructured['type']>
    | ((item: ItemStructured) => boolean),
): ItemStructured[] => {
  const results: ItemStructured[] = []

  items.forEach((item) => {
    if (typeof filter === 'string') {
      if (item.type === filter) {
        results.push(item)
      }
    } else if (Array.isArray(filter)) {
      if (filter.includes(item.type)) {
        results.push(item)
      }
    } else {
      if (filter(item)) {
        results.push(item)
      }
    }
    results.push(...getFromStructuredItems(item.options, filter))
  })

  return results
}

export const itemHasSelectedOrPartiallySelectedItems = (item: ItemStructured) => {
  return (
    getFromStructuredItems(item.options, (item) => item.selected || item.partially_selected)
      .length > 0
  )
}
