import { LinesOfBusiness } from 'types/session/linesOfBusiness'
import { getApiHeaderClientIds } from 'utils/oneviewApi/constants'

// client_od => Line of Business

export const getLOBFromClentID = (client_id: string): string => {
  if (!client_id) return ''

  const {
    apiHeadersClientId,
    apiHeadersClientIdV2,
    apiHeadersRsV2ClientIdV2,
    apiHeadersRsClientId,
    apiHeadersRsV2ClientId,
    apiHeadersVsClientId,
    apiHeadersVsClientIdV2,
  } = getApiHeaderClientIds()

  const LOBMap = new Map()
  LOBMap.set(apiHeadersClientId, LinesOfBusiness.customer)
  LOBMap.set('cs-oneview', LinesOfBusiness.customer)
  LOBMap.set(apiHeadersClientIdV2, LinesOfBusiness.customer)

  LOBMap.set(apiHeadersVsClientId, LinesOfBusiness.vendor)
  LOBMap.set('vs2-oneview', LinesOfBusiness.vendor)
  LOBMap.set(apiHeadersVsClientIdV2, LinesOfBusiness.vendor)

  LOBMap.set(apiHeadersRsClientId, LinesOfBusiness.rider)
  LOBMap.set(apiHeadersRsV2ClientId, LinesOfBusiness.rider)
  LOBMap.set(apiHeadersRsV2ClientIdV2, LinesOfBusiness.rider)

  return LOBMap.get(client_id?.trim().toLowerCase())
}
