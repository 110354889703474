import { ApiService } from 'services/authApi/getPermissions'
import { GetOrderCommentsResponse } from 'types/api/commentApi/getOrderComments'
import { buildCommentApiUrl } from './helper'
import { CustomerCommentsApiParams } from './types'

export const getCustomerComments: ApiService<
  CustomerCommentsApiParams,
  GetOrderCommentsResponse
> = (createClient, options) => {
  const { customerId, clientParams, ...apiParams } = options

  const uri = buildCommentApiUrl(apiParams, 'customer', customerId)

  return createClient({
    endpointName: 'getCustomerComments',
    expectedResponseStatusCode: 200,
    ...clientParams,
  }).get(uri)
}
