import { ApiService } from 'services/authApi/getPermissions'

export const getDeviceRemoteControlLink: ApiService<
  {
    entityId: string
    vendorId: string
    deviceId: string
  },
  {
    link: string
  }
> = (createClient, options) => {
  const { entityId, clientParams, vendorId, deviceId } = options
  const uri = `/VendorAPI/v1/vendors/${entityId}/${vendorId}/devices/${deviceId}/link`

  return createClient({
    endpointName: 'getDeviceRemoteControlLink',
    expectedResponseStatusCode: 200,
    ...clientParams,
  }).get(uri)
}
