import { orderInvoiceTabAdditionalChargesDataPointConfigs } from 'constants/tabDataPointConfigs/orderInvoiceTabDataPointConfigs'
import { OrderWidgetInvoiceAdditionalCharges } from 'contexts/entity/types'
import { useCreateFilterRenderableDataPoint } from 'hooks/filters/useCreateFilterRenderableDataPoint'
import { useTranslation } from 'hooks/useTranslation'
import { useCallback } from 'react'
import { OrderApiResponse } from 'types/api/orderApi/order'
import { TransformedDataPoint } from 'types/dataTransformers/generic'

export const useTransformOrderAdditionalCharges = () => {
  const { t } = useTranslation()
  const createFilterRenderableDataPoint = useCreateFilterRenderableDataPoint()

  return useCallback(
    (order: OrderApiResponse, config: OrderWidgetInvoiceAdditionalCharges) => {
      const filterRenderableDataPoint = createFilterRenderableDataPoint(config?.available_fields)
      const subscriptions = order?.addons?.subscriptions || []

      const dataPoints: TransformedDataPoint[] = [
        // subscriptions
        {
          label:
            t(
              orderInvoiceTabAdditionalChargesDataPointConfigs.subscriptionsFee.labelTranslationKey,
            ) + (subscriptions.length > 1 ? ` (${subscriptions.length})` : ''),
          key: orderInvoiceTabAdditionalChargesDataPointConfigs.subscriptionsFee.key,
          value: subscriptions.reduce((fee, subscription) => {
            const incentivesGross = subscription.amount_after_incentives_gross || 0
            const walletAmount = subscription.wallet_amount || 0
            return fee + incentivesGross + walletAmount
          }, 0),
        },

        // rider tip
        {
          label: t(orderInvoiceTabAdditionalChargesDataPointConfigs.riderTip.labelTranslationKey),
          key: orderInvoiceTabAdditionalChargesDataPointConfigs.riderTip.key,
          value: order?.delivery?.tip || 0,
        },

        // delivery fee
        {
          label: t(
            orderInvoiceTabAdditionalChargesDataPointConfigs.deliveryFee.labelTranslationKey,
          ),
          key: orderInvoiceTabAdditionalChargesDataPointConfigs.deliveryFee.key,
          value: order?.delivery?.delivery_fee || 0,
        },

        // priority delivery fee
        {
          label: t(
            orderInvoiceTabAdditionalChargesDataPointConfigs.priorityDeliveryFee
              .labelTranslationKey,
          ),
          key: orderInvoiceTabAdditionalChargesDataPointConfigs.priorityDeliveryFee.key,
          value: order?.delivery?.priority_fee || 0,
        },

        // minimum order value fee
        {
          label: t(
            orderInvoiceTabAdditionalChargesDataPointConfigs.minimumOrderValueFee
              .labelTranslationKey,
          ),
          key: orderInvoiceTabAdditionalChargesDataPointConfigs.minimumOrderValueFee.key,
          value: order?.customer?.payment?.minimum_order_value_fee || 0,
        },

        // packaging fees
        {
          label: t(
            orderInvoiceTabAdditionalChargesDataPointConfigs.packagingFees.labelTranslationKey,
          ),
          key: orderInvoiceTabAdditionalChargesDataPointConfigs.packagingFees.key,
          value: order?.delivery?.packaging_charges?.amount || 0,
        },

        // service fee
        {
          label: t(orderInvoiceTabAdditionalChargesDataPointConfigs.serviceFee.labelTranslationKey),
          key: orderInvoiceTabAdditionalChargesDataPointConfigs.serviceFee.key,
          value: order?.customer?.payment?.service_fee || 0,
        },
      ]

      return {
        totalAmount: dataPoints.reduce((sum, dataPoint) => sum + (dataPoint.value as number), 0),
        dataPoints: dataPoints.filter(filterRenderableDataPoint),
      }
    },
    [t, createFilterRenderableDataPoint],
  )
}
