/**
 * Reported Items Details of single comment
 * expects array of strings
 * */

import React from 'react'

// Components
import { Typography } from 'antd'

// Utils
import commaDotChain from 'utils/commaDotChain'

const { Text } = Typography

type Props = {
  items: string[]
  limit?: number
}

function SimpleItemDetails({ items, limit = 0 }: Props) {
  // In case of 'Partial item issue' api returns x items the same string. Here we limit representation.
  if (limit > 0) {
    const joindItems = items.join(', ')
    const slicedJoinedItems = joindItems.slice(0, items[0].length + limit).trim()
    return (
      <Text strong>
        {slicedJoinedItems}
        {joindItems.length > 0 ? '...' : '-'}
      </Text>
    )
  }

  return (
    <>
      {items.map((item, key) => (
        <Text strong key={`${item}${key}`}>
          {`${item}${commaDotChain(key + 1 !== items.length)}`}
        </Text>
      ))}
    </>
  )
}

export default SimpleItemDetails
