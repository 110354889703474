import { ApiService } from 'services/authApi/getPermissions'

export type VendorAvailabilityScheduleShift = {
  from: string
  to: string
  reason?: string
}

export type VendorAvailabilityScheduleDay = {
  delivery: VendorAvailabilityScheduleShift[]
  pickup: VendorAvailabilityScheduleShift[]
}

export type VendorAvailabilityScheduleWeek = {
  monday: VendorAvailabilityScheduleDay | null
  tuesday: VendorAvailabilityScheduleDay | null
  wednesday: VendorAvailabilityScheduleDay | null
  thursday: VendorAvailabilityScheduleDay | null
  friday: VendorAvailabilityScheduleDay | null
  saturday: VendorAvailabilityScheduleDay | null
  sunday: VendorAvailabilityScheduleDay | null
}

export type VendorAvailabilityScheduleSpecialDay = VendorAvailabilityScheduleDay & {
  date: string
}

export interface VendorAvailabilitySchedule {
  global_entity_id: string
  timestamp: string
  vendor: string
  time_zone: string
  schedules: VendorAvailabilityScheduleWeek
  special_days: VendorAvailabilityScheduleSpecialDay[]
}

export const getVendorSchedule: ApiService<
  { entityId: string; vendorId?: string },
  VendorAvailabilitySchedule
> = (createClient, options) => {
  const { entityId, clientParams, vendorId } = options
  const uri = `/VendorAPI/v1/vendors/${entityId}/${vendorId}/schedule`

  return createClient({
    endpointName: 'getVendorSchedule',
    expectedResponseStatusCode: 200,
    ...clientParams,
  }).get(uri)
}
