export default {
  statusContainer: {
    display: 'flex',
    width: '100%',
  },
  contentHolder: {
    display: 'flex',
    width: '100%',
  },
  stepsHolder: {
    width: '95%',
    marginLeft: '3%',
  },
  mapHolder: {
    width: '100%',
  },
  buttonsContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '75px',
  },
  button: {
    width: '150px',
  },
}
