import React from 'react'
import { Button, Text, Flex } from 'shared'
import { useTranslation } from 'hooks/useTranslation'
interface MessageViewProps {
  title: string
  message: string
  onRefreshBtnClick?: () => void
  imageURL: string
  size?: 'min' | 'max'
}

export const MessageCard: React.FC<MessageViewProps> = ({
  title = '',
  message = '',
  onRefreshBtnClick,
  imageURL,
  size = 'max',
}) => {
  const { t } = useTranslation()

  const isMin = size === 'min'

  return (
    <Flex
      flexDirection={isMin ? 'row' : 'column'}
      alignItems={'center'}
      gap={isMin ? '24px' : '16px'}
      p={24}
      justifyContent='center'
    >
      <img src={imageURL} alt={title} width={isMin ? '90px' : '120px'}></img>
      <Flex flexDirection='column' columnGap={isMin ? '16px' : '8px'} py={isMin ? '16px' : '0'}>
        <Flex
          flexDirection='column'
          alignItems={isMin ? 'flex-start' : 'center'}
          gap={isMin ? '4px' : '8px'}
        >
          <Text.Primary fontSize={20} fontWeight={'500'}>
            {title}
          </Text.Primary>
          <Text.Secondary fontSize={14} fontWeight={'400'}>
            {message}
          </Text.Secondary>
        </Flex>
        <Flex mt='16px' justifyContent={isMin ? 'flex-start' : 'center'}>
          {onRefreshBtnClick && (
            <Button onClick={onRefreshBtnClick}>{t('Interface.Refresh')}</Button>
          )}
        </Flex>
      </Flex>
    </Flex>
  )
}
