import { useSessionState } from 'hooks/useSessionState'
import React from 'react'
import { BasePhoneDecoratorProps } from './TelPhoneDecorator'
import Text from 'antd/lib/typography/Text'
import { PhoneIcon } from './PhoneIcon'

export const MicrobasePhoneDecorator: React.FC<BasePhoneDecoratorProps> = ({
  linkTarget = '_parent',
  phoneNumber,
  onClick,
  className,
  onlyIcon,
}) => {
  const { caseId } = useSessionState()
  if (phoneNumber) {
    return (
      <Text className={className}>
        {!onlyIcon ? phoneNumber : null}
        <a
          data-testid={'phoneNumber'}
          target={linkTarget}
          rel='noopener noreferrer'
          href={`eccmcapi:dialout?__sfc2d_RecordType=Case&__sfc2d_RecordId=${caseId}&__sfc2d_Number=${phoneNumber}#${phoneNumber}`}
          onClick={onClick}
          data-cy='microbase'
        >
          <PhoneIcon />
        </a>
      </Text>
    )
  }

  return null
}
