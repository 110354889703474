export default {
  customerTitleLeftSection: {
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
    marginBottom: '1em',
  },
  customerTitle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    fontSize: '17px',
    color: '#3d4a4f',
  },
  customerTitleParagraph: {
    marginBottom: 0,
  },
  fraudFlagSpace: {
    marginLeft: '4px',
  },
  flexWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  fraudFlagWrapperWithNewDesignHeading: {
    marginBottom: '1em',
    marginLeft: '4px',
  },
}
