/**
 * dumb component rendering an anchor link withing a ant text
 * renders a button with the passed buttonText and callBack func
 * */

// libs
import React from 'react'
// styles
import styles from './AnchorView.styles'
import { createUseStyles } from 'react-jss'
import { Typography } from 'antd'

const useStyles = createUseStyles(styles)

interface Props {
  href: string
  text: string | number
  target?: string
  clickCallBack?: () => void
  style?: React.CSSProperties
}

const AnchorView: React.FC<Props> = ({ href, target, text, clickCallBack, style }) => {
  const classes = useStyles()
  const { Text } = Typography

  return (
    <Text className={!style && classes.text} style={style && style}>
      <a rel='noopener noreferrer' target={target || undefined} href={href} onClick={clickCallBack}>
        {text}
      </a>
    </Text>
  )
}

export default AnchorView
