import React from 'react'
import { Tag, TagProps } from 'antd'
import { createUseStyles } from 'react-jss'

const useBadgeStyles = createUseStyles({
  main: {
    borderRadius: '15px',
    fontSize: '14px',
  },
})

export const Badge = ({ children, ...otherProps }: TagProps) => {
  const classes = useBadgeStyles()
  return (
    <Tag className={classes.main} {...otherProps}>
      {children}
    </Tag>
  )
}
