import React from 'react'
import { ItemStructured } from 'types/actions/partialRefund'
import { createUseStyles } from 'react-jss'
import styles from './RemovalItems.styles'
import { Checkbox, InputNumber, Typography } from 'antd'
import classNames from 'classnames'
import fixNumber from 'utils/fixNumber'
import { PartialItemSelection } from '../PartialItemSelection/PartialItemSelection'
import { restructureOrderItem } from 'utils/orderItems/restructureOrderItem'
import { calculateMissingOrderItemTotalPrice } from 'utils/orderItems/orderItemPricings'

const useStyles = createUseStyles(styles)

interface RemovalItemsProps {
  isUiImprovementsAllowed?: boolean
  items: ItemStructured[]
  partialItemRefundPercentage: number
  refundAmountPrecision: number
  isPartialItemSelectionAllowed: boolean

  isToppingSelectionAllowed: boolean
  isChoiceSelectionAllowed: boolean

  checkboxClassName: string
  inputClassName: string
  currency: string

  displayLevel?: number
  setItem: (item: ItemStructured) => void
}

export const RemovalItems = ({
  isUiImprovementsAllowed,
  items,
  isToppingSelectionAllowed,
  isChoiceSelectionAllowed,
  partialItemRefundPercentage,
  displayLevel = 0,
  checkboxClassName,
  currency,
  refundAmountPrecision,
  inputClassName,
  setItem,
  isPartialItemSelectionAllowed,
}: RemovalItemsProps) => {
  const space = 24

  const cx = useStyles({
    spacing: displayLevel * space,
  })

  const { Text } = Typography

  if (items.length === 0) {
    return null
  }

  return (
    <ul className={classNames(cx.itemsList, 'items-list', `items-list-level-${displayLevel}`)}>
      {items.map((item, index) => {
        const disabled =
          item.parent_item_selected ||
          item.parent_item_partially_selected ||
          (!isChoiceSelectionAllowed && item.type.includes('choice')) ||
          (!isToppingSelectionAllowed && item.type.endsWith('topping'))

        const someOptionsSelected = item?.options.some((option) => option.selected)

        return (
          <li
            className={classNames('item', item.type, `item-${index}`)}
            key={item.id}
            data-item-id={item.id}
          >
            <div className={classNames('item-selection', cx.itemRow)}>
              {/* name with checkbox */}
              <div className={cx.itemName}>
                <Checkbox
                  indeterminate={isUiImprovementsAllowed && someOptionsSelected && !item.selected}
                  disabled={disabled}
                  checked={item.selected || (isUiImprovementsAllowed && item.parent_item_selected)}
                  className={checkboxClassName}
                  onChange={() => {
                    setItem(
                      restructureOrderItem(item, {
                        type: 'toggle_item_selection',
                      }),
                    )
                  }}
                >
                  {`${item.missing_quantity} X ${item.display_name}`}
                </Checkbox>
              </div>

              {/* price */}
              <div className={cx.itemPrice}>
                <Text
                  className={classNames(cx.itemPriceText, {
                    disabled,
                    selected: item.selected,
                  })}
                >
                  {`${currency} ${fixNumber(
                    calculateMissingOrderItemTotalPrice(item, { partialItemRefundPercentage }),
                    refundAmountPrecision,
                  )}`}
                </Text>
              </div>

              {/* quantity */}
              <div className={cx.itemQantity}>
                <InputNumber
                  disabled={disabled || item.type.endsWith('topping')}
                  size='small'
                  min={0}
                  value={item.missing_quantity}
                  max={item.max_missing_quantity}
                  defaultValue={item.quantity}
                  className={inputClassName}
                  onChange={(e) => {
                    if (e === null) {
                      return
                    }

                    const value = Math.ceil(Number(e))
                    setItem(
                      restructureOrderItem(item, {
                        type: 'change_item_missing_quantity',
                        value,
                      }),
                    )
                  }}
                />
              </div>
            </div>

            {(item.type === 'bundle_item' || item.type === 'item') &&
            isPartialItemSelectionAllowed ? (
              <div className='item-partial-selection'>
                <PartialItemSelection
                  item={item}
                  partialItemRefundPercentage={partialItemRefundPercentage}
                  checkboxClassName={checkboxClassName}
                  currency={currency}
                  refundAmountPrecision={refundAmountPrecision}
                  setItem={setItem}
                  spacing={space * (displayLevel + 1)}
                />
              </div>
            ) : null}

            <RemovalItems
              isUiImprovementsAllowed={isUiImprovementsAllowed}
              partialItemRefundPercentage={partialItemRefundPercentage}
              items={item.options}
              displayLevel={displayLevel + 1}
              isPartialItemSelectionAllowed={isPartialItemSelectionAllowed}
              checkboxClassName={checkboxClassName}
              currency={currency}
              inputClassName={inputClassName}
              isToppingSelectionAllowed={isToppingSelectionAllowed}
              isChoiceSelectionAllowed={isChoiceSelectionAllowed}
              refundAmountPrecision={refundAmountPrecision}
              setItem={(newItem) => {
                setItem({
                  ...item,
                  options: item.options.map((current) => {
                    if (current.id === newItem.id) {
                      return newItem
                    }
                    return current
                  }),
                })
              }}
            />
          </li>
        )
      })}
    </ul>
  )
}
