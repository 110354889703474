import {
  DataPointWithBetaRequirementConfig,
  FeatureWithBetaRequirementConfig,
} from 'contexts/entity/types'
import { useCallback } from 'react'
import { DisplayRules } from 'types/unitedUiConfig'
import { useCheckDisplayRules } from './useCheckDisplayRules'
import { useIsBetaPermissionGranted } from './useIsBetaPermissionGranted'

export const useIsFeatureValid = () => {
  const isBetaPermissionGranted = useIsBetaPermissionGranted()
  return useCallback(
    (betaRequirements: string[]) => {
      if (betaRequirements && betaRequirements.length > 0) {
        return isBetaPermissionGranted(betaRequirements)
      }
      return true
    },
    [isBetaPermissionGranted],
  )
}

export const useGetValidFeatures = () => {
  const isFeatureValid = useIsFeatureValid()
  return useCallback(
    (features: FeatureWithBetaRequirementConfig[]) => {
      return features
        .filter(({ betaRequirement }) => isFeatureValid(betaRequirement))
        .map(({ feature }) => feature)
    },
    [isFeatureValid],
  )
}

export const useIsDataPointValid = () => {
  const isFeatureValid = useIsFeatureValid()
  return useCallback(
    (betaRequirement: string[]) => {
      return isFeatureValid(betaRequirement)
    },
    [isFeatureValid],
  )
}

export const useCanDisplayDataPoint = () => {
  const checkDisplayRules = useCheckDisplayRules()

  return useCallback(
    (value: any, config: DataPointWithBetaRequirementConfig) => {
      const { displayRule = [], betaRequirement = [] } = config || {}
      return checkDisplayRules(
        {
          datapoint_requirement: {
            value: displayRule[0] as unknown as DisplayRules['datapoint_requirement']['value'],
          },
          beta_requirements: {
            value: betaRequirement,
          },
        },
        { dataPointValue: value },
      ).enabled
    },
    [checkDisplayRules],
  )
}
