import React, { useCallback, useMemo } from 'react'
import { createUseStyles } from 'react-jss'
import { Typography } from 'antd'
import { SelectBox } from 'shared'
import type { SelectOption } from 'shared'
import { COMP_METHOD_MENU_ID } from 'constants/widgetIds'
import { useTranslation } from 'hooks/useTranslation'
import styles from '../CompensationModalView.styles'
import { CompensationMethod } from 'contexts/entity/types'
import { useCaptureUserAction } from 'hooks/events/useCaptureUserAction'

const useStyles = createUseStyles(styles)
const { Text } = Typography

type Props = {
  isIssueTypeSelected: boolean
  selectedCompensationMethod: CompensationMethod
  compensationMethods: CompensationMethod[]
  setCompensationMethod: (method: CompensationMethod) => void
}

export const CompensationMethodSelect = ({
  isIssueTypeSelected,
  selectedCompensationMethod,
  compensationMethods,
  setCompensationMethod,
}: Props) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const captureUserAction = useCaptureUserAction()

  // Preselect single available compensation method
  const isOnlyOneCompensationMethodAvailable = compensationMethods?.length === 1

  const mapMethodToOption = useCallback(
    (method: CompensationMethod): SelectOption => ({
      value: method.method,
      label: t(
        `Actions Widget.Actions.Compensation.Compensation Methods.${method.translation_key}`,
      ),
    }),
    [t],
  )

  const selectedCompensationMethodOption = selectedCompensationMethod
    ? mapMethodToOption(selectedCompensationMethod)
    : undefined

  const availableCompensationMethodOptions = useMemo(() => {
    return compensationMethods.map(mapMethodToOption)
  }, [compensationMethods, mapMethodToOption])

  const handleSelectCompensationMethod = useCallback(
    (methodOption: SelectOption) => {
      const selectedCompensationMethod = compensationMethods.find(
        (method) => method.method === methodOption.value,
      )

      captureUserAction('ActionsCompensationMethodSelected', {
        eventDetails: {
          selectedMethod: methodOption.value,
        },
      })

      return setCompensationMethod(selectedCompensationMethod)
    },
    [compensationMethods, setCompensationMethod, captureUserAction],
  )

  const label = t('Actions Widget.Actions.Compensation.Compensation Method')
  const placeholder = t('Actions Widget.Actions.Compensation.Select an option')
  const labelClass = isIssueTypeSelected ? classes.dropdownLabels : classes.dropdownLabelsInactive
  const isSelectDisabled = isOnlyOneCompensationMethodAvailable || !isIssueTypeSelected

  return (
    <div className={classes.dropdownHolder}>
      <Text className={labelClass}>{label}:</Text>
      <SelectBox
        disabled={isSelectDisabled}
        testId={COMP_METHOD_MENU_ID}
        options={availableCompensationMethodOptions}
        value={selectedCompensationMethodOption}
        placeholder={placeholder}
        onSelectChange={handleSelectCompensationMethod}
      />
    </div>
  )
}
