import { ApiService } from 'services/authApi/getPermissions'
import {
  PutUpdateDeliveryStatusPayload,
  UpdateDeliveryStatusResponse,
} from 'types/api/fulfillmentApi/fulfillment'
import { logUnknowRiderError } from 'utils/logUnknownRiderError'

export const putUpdateDeliveryStatus: ApiService<
  {
    entityId: string
    orderId: string
    deliveryId: number
    payload: PutUpdateDeliveryStatusPayload
  },
  UpdateDeliveryStatusResponse
> = (createClient, options) => {
  const {
    entityId,
    orderId,
    deliveryId,
    payload,

    clientParams,
  } = options

  const uri = `/FulfillmentAPI/v1/orders/${entityId}/${orderId}/delivery/${deliveryId}/status`

  return createClient({
    endpointName: 'putUpdateDeliveryStatus',
    expectedResponseStatusCode: 200,
    ...clientParams,
    onSuccess: logUnknowRiderError,
  }).put<UpdateDeliveryStatusResponse>(uri, payload)
}
