export default {
  dropdownHolder: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '50px',
  },
  dropdown: {
    width: '350px',
  },
  issueTypeText: {
    fontSize: '13px',
    color: 'rgba(0, 0, 0, 0.85)',
    fontWeight: 600,
    marginRight: '3px',
    width: '83px',
  },
  dropdownButton: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontSize: '14px',
    color: 'rgba(0, 0, 0, 0.65)',
  },
  dropdownIcon: {
    color: 'rgba(0, 0, 0, 0.25)',
  },
  buttonsHolder: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    marginTop: '50px',
  },
}
