import { datadogRum } from '@datadog/browser-rum'
import { getSessionAndCapturedData } from './helpers'

export default function captureUserAction(metricName: string, data: any[][]): void {
  try {
    const { sessionData, capturedData } = getSessionAndCapturedData(data)
    datadogRum.addAction(metricName, {
      data: { ...sessionData, ...capturedData },
    })
  } catch (err) {
    console.error(`${metricName} could not be captured:[${err}]`)
  }
}
