import { createUseStyles } from 'react-jss'

const useTableStyles = createUseStyles({
  tableHeader: {
    width: '100%',
    fontSize: 14,
    backgroundColor: '#FAFAFA',
    padding: '15px 5px',
    fontWeight: 500,
    borderBottom: '1px solid #EEEEEE',
  },
  tableRow: {
    width: '100%',
    fontSize: 14,
    padding: '15px 5px',
    borderBottom: '1px solid #EEEEEE',
    cursor: (props: any) => (props?.canExpand ? 'pointer' : 'auto'),
    '&:last-child': {
      borderBottom: 'none',
    },
  },
  itemQuanity: {
    marginRight: '4px',
  },
  itemNameBox: {
    paddingLeft: (props: any) => (props?.level ? `${props.level * 20}px` : '0'),
  },
})

export default useTableStyles
