/**
 *
 * Returns difference in days of given timestamp  and UTC zone from current date
 *
 * */
import moment from 'moment-timezone'

export const dayDifferenceFromNow = (
  timestamp: string | Date | number,
  utcZone: string,
): number | undefined => {
  try {
    if (timestamp) {
      const date = moment(timestamp).tz(utcZone)
      const now = moment().tz(utcZone)
      return date.diff(now, 'days')
    }
  } catch (error) {
    console.log('Error in calculating difference of days', error)
  }
}
