export enum AvailableCommentActionsTypes {
  ticket_refund = 'Refund Request',
  modify_promised_delivery_time = 'Change Delivery Time',
  report_missing_items = 'Report Missing Items',
  compensation = 'Compensation',
  cancel_order = 'Cancel Order',
  change_address = 'Change Address',
  change_instructions = 'Change Instructions',
  system_error = 'System Error',
  manual_comment = 'Manual Comment',
  full_refund = 'Full Refund',
  partial_refund = 'Partial Refund',
  modify_cooking_instructions = 'Change Cooking Instructions',
  payment_compensation = 'Payment Compensation',
  remove_items = 'Remove Items',
  refund_request = 'Refund Request',
  dispatcher_note = 'Dispatcher Note',
}

export enum AvailableFilterTimeFrames {
  last_seven = 'Last 7 Days',
  last_fourteen = 'Last 14 Days',
  last_thirty = 'Last 30 Days',
  all = 'All (Last 90 Days)',
}

export interface CurrentEvent {
  filterType: string
  displayedFilterType: string
  typeAmount: number
}

export interface AvailableTypesLocal {
  type: AvailableCommentActionsTypes
  amount: number
}

export interface SingleAvailableTimeFrame {
  value: string
  day_limit: number
}
