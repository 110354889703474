import { ApiService } from 'services/authApi/getPermissions'
import { VendorAvailabilityStatusResponse } from 'types/api/vendorApi/vendorAvailabilityStatus'

export const getVendorAvailabilityStatus: ApiService<
  { entityId: string; vendorId?: string },
  VendorAvailabilityStatusResponse
> = (createClient, options) => {
  const { entityId, clientParams, vendorId } = options

  const uri = `/VendorAPI/v1/vendors/${entityId}/${vendorId}/availability/status`

  return createClient({
    endpointName: 'getVendorAvailabilityStatus',
    expectedResponseStatusCode: 200,
    ...clientParams,
  })
    .get(uri)
    .then((res) => {
      return {
        ...res,
        data: res?.data?.items,
      }
    })
}
