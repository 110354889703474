export default {
  firstItemEntry: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '10px',
  },
  itemEntry: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '30px',
  },
  topRow: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  topRowTargetHolder: {
    display: 'flex',
    flexDirection: 'column',
  },
  displayNameAndTooltipHolder: {
    display: 'flex',
  },
  topRowTargetText: {
    fontSize: '14px',
    fontWeight: 500,
    color: 'rgba(0, 0, 0, 0.65)',
  },
  topRowTargetContentText: {
    fontSize: '14px',
    color: 'rgba(0, 0, 0, 0.65)',
    marginRight: '3px',
  },
  tooltip: {
    padding: '5px 10px 5px 10px',
  },
  topRowDateText: {
    fontSize: '13px',
    color: 'rgba(0, 0, 0, 0.65)',
  },
  contentRow: {
    display: 'flex',
    marginTop: '10px',
  },
  quantitiesHolder: {
    display: 'flex',
    flexDirection: 'column',
    width: '35%',
  },
  singleDataFieldHolder: {
    display: 'flex',
    alignItems: 'baseline',
  },
  bodyTextLabel: {
    fontSize: '13px',
    color: 'rgba(0, 0, 0, 0.45)',
  },
  bodyTextData: {
    fontSize: '13px',
    color: 'rgba(0, 0, 0, 0.65)',
    marginTop: '5px',
  },
  pricesHolder: {
    display: 'flex',
    flexDirection: 'column',
    width: '65%',
  },
}
