import React, { useState } from 'react'
import { createPluggableWidget } from 'factory/createPluggableWidget'
import { useApiService } from 'hooks/useApiService'
import { getVendorAvailabilityStatus } from 'services/vendorApi/getVendorAvailabilityStatus'
import { putVendorAvailabilityStatus } from 'services/vendorApi/putVendorAvailabilityStatus'
import {
  VendorAvailabilityStatusItem,
  VendorAvailabilityStatusUpdatePayload,
} from 'types/api/vendorApi/vendorAvailabilityStatus'
import { BuiltinWidgetConfigs } from 'types'
import { Availability } from './Availability'
import { AvailabilityStatusWrapper } from './AvailabilityStatusWrapper'

export const VendorAvailability = createPluggableWidget<
  BuiltinWidgetConfigs['vendor_availability']
>(
  ({ ErrorRenderer, config, globalEntityId, vendorId, sdk }) => {
    const [vendorAvailabilityStatus, setVendorAvailabilityStatus] =
      useState<VendorAvailabilityStatusItem>()

    const {
      loading: checkVendorAvailabilityStatusLoading,
      error: getVendorAvailabilityStatusError,
    } = useApiService({
      service: getVendorAvailabilityStatus,
      autoLoad: true,
      params: { entityId: globalEntityId, vendorId },
      onSuccess: (response) => {
        setVendorAvailabilityStatus(response.data?.[0])
      },
    })

    const {
      loading: checkUpdatingVendorAvailabilityStatusLoading,
      loadService: updateVendorAvailabilityStatus,
      error: updateVendorAvailabilityStatusError,
    } = useApiService({
      service: putVendorAvailabilityStatus,
      autoLoad: false,
      onSuccess: (response) => {
        setVendorAvailabilityStatus(response.data)
        sdk.captureUserAction('CONFIRM_CHANGE_AVAILABILITY_SUCCESS', {
          reportToEts: true,
          eventDetails: {
            switch_to: response.data.availabilityState === 'OPEN' ? 'Open' : 'Close',
          },
          screenDetails: {
            source_widget_category: 'action',
          },
        })
      },
    })

    const onStatusUpdate = async (data: VendorAvailabilityStatusUpdatePayload) => {
      await updateVendorAvailabilityStatus({ data, entityId: globalEntityId, vendorId })
    }

    return (
      <ErrorRenderer
        loading={
          checkVendorAvailabilityStatusLoading || checkUpdatingVendorAvailabilityStatusLoading
        }
        errorPayload={
          getVendorAvailabilityStatusError?.errorPayload ||
          updateVendorAvailabilityStatusError?.errorPayload
        }
      >
        {() => (
          <AvailabilityStatusWrapper
            availabilityStatus={vendorAvailabilityStatus}
            onStatusUpdate={onStatusUpdate}
          >
            <Availability config={config} latestStatus={vendorAvailabilityStatus} />
          </AvailabilityStatusWrapper>
        )}
      </ErrorRenderer>
    )
  },
  {
    deriveConfig: ({ entityConfig }) => {
      return entityConfig.layout_v2.builtin_widgets_configs.vendor_availability
    },
  },
)
