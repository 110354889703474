import React, { useImperativeHandle, useState } from 'react'
import { createPluggableWidget } from 'factory/createPluggableWidget'
import { Contacts } from './Contacts/Contacts'
import { PopupWidgetContainer } from 'shared'
import { ContactForm } from './ContactForm/ContactForm'
import { BuiltinWidgetConfigs } from 'types'
import { useLoadVendorContacts } from 'hooks/apiHooks/useLoadVendorContacts'
import {
  TransformedVendorContact,
  transformVendorContacts,
} from 'utils/dataTransformers/transformVendorContacts'

export const VendorContacts = createPluggableWidget<BuiltinWidgetConfigs['vendor_contacts']>(
  ({ sdk, config, ErrorRenderer, vendor }, ref) => {
    const { t } = sdk

    const [selectedContact, setSelectedContact] = useState<
      TransformedVendorContact | undefined | null
    >(null)

    const {
      data: vendorContactsData,
      error: vendorContactsError,
      loading: vendorContactsLoading,
      loadService: refreshVendorContacts,
    } = useLoadVendorContacts()

    const vendorContacts = transformVendorContacts({
      vendor,
      vendorContacts: vendorContactsData?.data,
    })

    const handleEditContact = (contactId: string) => {
      const contactToEdit = vendorContacts.find((contact) => contact.id === contactId)
      setSelectedContact(contactToEdit)
    }

    const handleAddContact = () => {
      setSelectedContact(undefined)
    }

    const handleCloseForm = () => {
      setSelectedContact(null)
    }

    // Add a refresh link in widget header
    useImperativeHandle(
      ref,
      () => {
        return {
          onRefresh: () => {
            refreshVendorContacts()
            sdk.captureUserAction('REFRESH_CONTACTS', { reportToEts: true })
          },
        }
      },
      [refreshVendorContacts, sdk],
    )

    return (
      <ErrorRenderer
        loading={vendorContactsLoading}
        errorPayload={vendorContactsError?.errorPayload}
      >
        {() => (
          <>
            <Contacts
              config={config}
              contacts={vendorContacts}
              handleAddContact={handleAddContact}
              handleEditContact={handleEditContact}
            />
            <PopupWidgetContainer
              visible={selectedContact !== null}
              widgetContainerType='drawer'
              title={
                (!selectedContact && t('Add new contact')) ||
                (typeof selectedContact === 'object' && t('Edit contact details'))
              }
              onClose={handleCloseForm}
            >
              <ContactForm
                contact={selectedContact}
                onCancel={handleCloseForm}
                onSubmit={(contact) => console.log({ contact })}
              />
            </PopupWidgetContainer>
          </>
        )}
      </ErrorRenderer>
    )
  },
  {
    category: 'data_lookup',
    deriveConfig: ({ entityConfig }) => {
      return entityConfig.layout_v2.builtin_widgets_configs.vendor_contacts
    },
    deriveSubjectsRequirements() {
      return {
        all_of: ['vendor'],
      }
    },
  },
)
