import React from 'react'
import { createCustomSvgIcon } from 'factory/createCustomSvgIcon'

export const GiftCardIcon = createCustomSvgIcon({
  viewBoxWidth: 14,
  viewBoxHeight: 14,
  content: (
    <>
      <g clipPath='url(#clip0_606_567)'>
        <rect width='14' height='14' fill='white' fillOpacity='0.01' />
        <g clipPath='url(#clip1_606_567)'>
          <path
            d='M13.5 1.49707H0.5C0.223437 1.49707 0 1.72051 0 1.99707V11.9971C0 12.2736 0.223437 12.4971 0.5 12.4971H13.5C13.7766 12.4971 14 12.2736 14 11.9971V1.99707C14 1.72051 13.7766 1.49707 13.5 1.49707ZM1.125 2.62207H12.875V6.02778H1.125V2.62207ZM12.875 11.3721H1.125V7.58333H12.875V11.3721Z'
            fill='black'
            fillOpacity='0.85'
          />
          <rect x='3.88892' y='2.625' width='1.55556' height='8.75' fill='black' />
          <path
            d='M1.94446 5.2344L2.05052 6.17319C2.899 5.92868 3.69092 6.24596 3.98082 6.43517L2.13537 8.88039L2.5384 10.7361L4.87173 7.11197L7.58688 10.889L7.77779 8.98956L5.78385 6.43517C6.45557 6.48611 7.62506 6.5618 7.69294 6.28234C7.76082 6.00289 7.60809 5.08884 7.52325 4.66675C7.18385 4.66675 6.38628 4.69731 5.91112 4.81957C5.43597 4.94184 4.8788 5.30716 4.65961 5.47455C4.17216 5.23234 3.73883 5.00528 3.19597 4.97241C2.47476 4.92874 2.26264 5.12524 1.94446 5.2344Z'
            fill='black'
          />
        </g>
      </g>
      <defs>
        <clipPath id='clip0_606_567'>
          <rect width='14' height='14' fill='white' />
        </clipPath>
        <clipPath id='clip1_606_567'>
          <rect width='14' height='14' fill='white' />
        </clipPath>
      </defs>
    </>
  ),
})
