import React, { FC } from 'react'
import { ViewScreenProvider, ViewScreenProviderProps } from 'contexts/ViewScreenProvider'
import { SdkProvider } from 'contexts/SdkProvider'
import { useSdkConstruct } from 'hooks/sdkConstruct/useSdkConstruct'

const SdkWrapper = ({ children }) => {
  const sdk = useSdkConstruct()
  return <SdkProvider sdk={sdk}>{children}</SdkProvider>
}

export const ViewScreenAndSdkProvider: FC<ViewScreenProviderProps> = ({
  children,
  ...contextValue
}) => {
  return (
    <ViewScreenProvider {...contextValue}>
      <SdkWrapper>{children}</SdkWrapper>
    </ViewScreenProvider>
  )
}
