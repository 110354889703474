const tabContainerCommon = {
  height: '100%',
  overflow: 'hidden',
  '&-nav': {
    display: 'flex',
    gap: '10px',
  },
  '&-nav-list-nav': {
    width: '100%',
  },
  '&-nav-list': {
    color: 'orange',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  '&-nav-operations': {
    display: 'none',
  },
  '&-tab': {
    whiteSpace: 'nowrap',
  },
  '&-nav-list> &-tab:first-child > div > label': {
    'border-start-start-radius': '4px',
    'border-end-start-radius': '4px',
  },
  '&-nav-list> &-tab:nth-last-child(2) > div > label': {
    'border-start-end-radius': '4px',
    'border-end-end-radius': '4px',
  },
  '& > div': {
    height: '100%',
    overflow: 'hidden',
    '& > div': {
      height: '100%',
      overflow: 'hidden',
    },
  },
}

const tabContaineMiniOvverides = {
  '&-nav': {
    display: 'flex',
    gap: '10px',
    flexDirection: 'column',
  },
}

const tabContaineMaxOvverides = {
  '&-nav': {
    gap: '10px',
    display: 'flex',
    justifyContent: 'space-between',
  },
}

const tabContainerMini = {
  ...tabContainerCommon,
  ...tabContaineMiniOvverides,
}
const tabContainerMax = {
  ...tabContainerCommon,
  ...tabContaineMaxOvverides,
}

export default {
  historyOfEventsContainer: {
    border: '1px solid #e8e8e8',
    borderRadius: '5px',
    padding: '10px',
    marginLeft: '2%',
    marginTop: '10px',
    backgroundColor: '#ffffff',
    boxShadow: '0.5px 0.5px 5px rgba(0, 0, 0, 0.134124)',
    height: 'auto',
  },
  historyOfEventsTitle: {
    fontSize: '17px',
    color: '#3d4a4f',
  },
  icons: {
    width: '15px',
    height: '15px',
  },
  backOfficeButton: {
    fontSize: '14px',
    padding: 0,
  },
  tabsContainerMini: tabContainerMini,
  tabsContainerMax: tabContainerMax,
  tabContainerBO: tabContainerCommon,
  radioContainer: {
    padding: '1px',
    display: 'flex',
    flexDirection: 'row',
    '& > div > label:first-child': {
      'border-start-start-radius': '4px',
      'border-end-start-radius': '4px',
    },
    '& > div > label:last-child': {
      'border-start-end-radius': '4px',
      'border-end-end-radius': '4px',
    },
  },
  headerContainerMini: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
  },
  headerContainerMax: {
    gap: '10px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  tabsContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    height: '100%',
    overflow: 'hidden',
  },
  historyOfEventsWidgetShell: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
}
