import React, { useContext, useMemo, useState } from 'react'
import cx from 'classnames'
import { Button, Checkbox, Col, Descriptions, Row } from 'antd'
import { Voucher, VoucherDataPoint } from 'types/api/voucherApi/voucher'
import useTransformCustomerVoucher from 'hooks/useTransformCustomerVoucher'
import Text from 'antd/lib/typography/Text'
import { useTranslation } from 'hooks/useTranslation'
import { CaretDownOutlined, CaretRightOutlined, CopyOutlined } from '@ant-design/icons'
import styles from './SingleVoucherView.styles'
import { createUseStyles } from 'react-jss'
import { VoucherStatusIcon } from 'components/VoucherStatuses'
import { sortVoucherFields } from 'utils/vouchers/sortVoucherFields'
import { setDefaultSelection } from 'utils/vouchers/setDefaultSelections'
import { DataContext } from 'contexts/data/DataContext'
import { Statuses } from 'types/dataTransformers/customerVouchers'
import { logCopyError } from 'utils/reporting/logCopyError'
import { Notification } from 'shared/Notification'

type Props = {
  voucher: Voucher
}

const useStyles = createUseStyles(styles)

type ValueColumnProps = {
  voucherType: string
  dp: VoucherDataPoint
  selected: Record<string, null | string | string[]>
  handleCheck: (dp: VoucherDataPoint, checked: boolean) => void
}

const ValueColumn: React.FC<ValueColumnProps> = ({
  dp,
  selected,
  handleCheck,
  voucherType = '',
}) => {
  const classes = useStyles()
  const showCurrency = voucherType.includes('amount') && dp.id === 'value'
  const {
    dataState: { currency },
  } = useContext(DataContext)

  const isSelected = Boolean(selected[dp.label])

  return (
    <React.Fragment>
      <Col>
        <Col>
          {dp?.dayDiff && (
            <span
              className={cx({
                [classes.colorDotValid]: dp?.dayDiff >= 0,
                [classes.colorDotInvalid]: dp?.dayDiff < 0,
              })}
            />
          )}
          {showCurrency && <span>{currency} </span>}
          <Text>{dp.value}</Text>
        </Col>
      </Col>
      <Col className={isSelected && classes.checked}>
        <Checkbox checked={isSelected} onChange={(e) => handleCheck(dp, e.target.checked)} />
      </Col>
    </React.Fragment>
  )
}

const SingleVoucherView: React.FC<Props> = ({ voucher }) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const transformCustomerVoucher = useTransformCustomerVoucher()
  const voucherDataPoints = transformCustomerVoucher(voucher)
  const sortedAllowedVoucherDataPoints = useMemo(
    () => sortVoucherFields(voucherDataPoints.filter((dp) => dp.canDisplay)),
    [voucherDataPoints],
  )
  sortVoucherFields(voucherDataPoints.filter((dp) => dp.canDisplay))

  const [expanded, setExpanded] = useState<Record<string, any>>({})

  const handleExpandClick = (dp: VoucherDataPoint) => {
    setExpanded((prevExpanded) => ({ ...prevExpanded, [dp.id]: !prevExpanded[dp.id] }))
  }

  const [selected, setSelected] = useState(setDefaultSelection(voucherDataPoints))

  const handleCopySelected = () => {
    const copyText = Object.keys(selected).reduce((text, key) => {
      const value = Array.isArray(selected[key]) ? selected[key].join(', \n') : selected[key]
      text += `${key}: ${value}\n`
      return text
    }, '')

    const success = () => {
      Notification.success({ message: t('Widgets Common.Voucher Information Copied') })
    }

    const error = () => {
      logCopyError()
      Notification.error({ message: t('Widgets Common.Cannot Copy') })
    }

    const warning = () => {
      Notification.warning({ message: t('Widgets Common.Nothing to Copy') })
    }

    navigator.clipboard.writeText(copyText).then(
      () => (copyText ? success() : warning()),
      () => error(),
    )
  }

  const handleCheckAll = (e) => {
    if (e.target.checked) {
      const values = sortedAllowedVoucherDataPoints.reduce((values, dp) => {
        values[dp.label] = dp.children || dp.value
        return values
      }, {})
      setSelected(values)
    } else {
      setSelected({})
    }
  }

  const isAllSelected = Object.keys(selected).length === sortedAllowedVoucherDataPoints.length

  const handleCheck = (dp: VoucherDataPoint, checked: boolean) => {
    if (checked) {
      setSelected({ ...selected, [dp.label]: dp.children || dp.value })
    } else {
      setSelected({ ...selected, [dp.label]: null })
    }
  }

  const handleChildCheck = (dp: VoucherDataPoint, value: string, checked: boolean) => {
    if (checked) {
      setSelected((prevSelected) => {
        const values = prevSelected[dp.label]

        if (values) {
          return { ...prevSelected, [dp.label]: [...values, value] }
        } else {
          return { ...prevSelected, [dp.label]: [value] }
        }
      })
    } else {
      setSelected((prevSelected) => {
        const values = prevSelected[dp.label]

        if (values) {
          return { ...prevSelected, [dp.label]: values.filter((val) => val !== value) }
        } else {
          return { ...prevSelected, [dp.label]: [] }
        }
      })
    }
  }

  return (
    <React.Fragment>
      <Descriptions className={classes.headingContainer} column={1} bordered>
        <Descriptions.Item
          className={classes.header}
          label={<VoucherStatusIcon status={voucher.status as Statuses} />}
        >
          <Row justify={'end'} align={'middle'}>
            <Col>
              <Button type='link' onClick={handleCopySelected}>
                {t('Widgets Common.Copy Selected')}
                <CopyOutlined />
              </Button>
            </Col>
            <Col>
              <Checkbox checked={isAllSelected} onChange={handleCheckAll} />
            </Col>
          </Row>
        </Descriptions.Item>
      </Descriptions>
      <Descriptions column={1} bordered>
        {sortedAllowedVoucherDataPoints.map((dp) => {
          const hasChildren = Boolean(dp.children)
          const showChildren = expanded[dp.id]
          return dp ? (
            <React.Fragment key={dp.id}>
              <Descriptions.Item
                className={classes.dataPoint}
                label={
                  <Row align={'middle'} gutter={2}>
                    <Col>
                      <Text>{dp.label}</Text>
                    </Col>
                    <Col>
                      {hasChildren ? (
                        showChildren ? (
                          <CaretDownOutlined onClick={() => handleExpandClick(dp)} />
                        ) : (
                          <CaretRightOutlined onClick={() => handleExpandClick(dp)} />
                        )
                      ) : null}
                    </Col>
                  </Row>
                }
              >
                <Row justify={'space-between'}>
                  <ValueColumn
                    voucherType={voucher.type}
                    dp={dp}
                    selected={selected}
                    handleCheck={handleCheck}
                  />
                </Row>
              </Descriptions.Item>
              {showChildren &&
                hasChildren &&
                dp.children.map((value) => (
                  <Descriptions.Item
                    className={classes.dataPoint}
                    key={dp.id + dp.value}
                    label={''}
                  >
                    <Row gutter={12}>
                      <Col>
                        <Checkbox
                          checked={selected[dp.label]?.some((val) => val === value)}
                          onChange={(e) => handleChildCheck(dp, value, e.target.checked)}
                        />
                      </Col>
                      <Col>
                        <Text>{value}</Text>
                      </Col>
                    </Row>
                  </Descriptions.Item>
                ))}
            </React.Fragment>
          ) : null
        })}
      </Descriptions>
    </React.Fragment>
  )
}

export default SingleVoucherView
