import React from 'react'
import { createCustomSvgIcon } from 'factory/createCustomSvgIcon'

export const UserSwitchIcon = createCustomSvgIcon({
  viewBoxWidth: 14,
  viewBoxHeight: 14,
  content: (
    <>
      <path d='M10.8594 4.23047C10.8594 2.08984 9.12504 0.355469 6.98441 0.355469C4.84379 0.355469 3.10941 2.08984 3.10941 4.23047C3.10941 5.52422 3.74379 6.67109 4.71879 7.37422C4.71254 7.37734 4.70785 7.37891 4.70473 7.38047C4.00629 7.67578 3.37973 8.09922 2.84066 8.63984C2.30471 9.17482 1.87803 9.80901 1.58441 10.507C1.29553 11.1906 1.13962 11.9229 1.12504 12.6648C1.12462 12.6815 1.12755 12.6981 1.13364 12.7136C1.13974 12.7292 1.14888 12.7433 1.16053 12.7553C1.17218 12.7672 1.1861 12.7767 1.20147 12.7832C1.21684 12.7896 1.23336 12.793 1.25004 12.793H2.18598C2.25316 12.793 2.30941 12.7383 2.31098 12.6711C2.34223 11.4648 2.82504 10.3352 3.67973 9.47891C4.56254 8.59297 5.73441 8.10547 6.98441 8.10547C9.12504 8.10547 10.8594 6.37109 10.8594 4.23047ZM6.98441 6.91797C5.50004 6.91797 4.29691 5.71484 4.29691 4.23047C4.29691 2.74609 5.50004 1.54297 6.98441 1.54297C8.46879 1.54297 9.67191 2.74609 9.67191 4.23047C9.67191 5.71484 8.46879 6.91797 6.98441 6.91797ZM8.62504 10.3711H12.75C12.8188 10.3711 12.875 10.3148 12.875 10.2461V9.37109C12.875 9.30234 12.8188 9.24609 12.75 9.24609H9.99223L10.7297 8.30703C10.7467 8.28511 10.7561 8.25821 10.7563 8.23047C10.7563 8.16172 10.7 8.10547 10.6313 8.10547H9.49691C9.42035 8.10547 9.34848 8.14141 9.30004 8.20078L8.22973 9.56172C8.16098 9.64922 8.12348 9.75859 8.12348 9.87109C8.12504 10.1477 8.34848 10.3711 8.62504 10.3711ZM12.375 11.3711H8.25004C8.18129 11.3711 8.12504 11.4273 8.12504 11.4961V12.3711C8.12504 12.4398 8.18129 12.4961 8.25004 12.4961H11.0079L10.2704 13.4352C10.2533 13.4571 10.244 13.484 10.2438 13.5117C10.2438 13.5805 10.3 13.6367 10.3688 13.6367H11.5032C11.5797 13.6367 11.6516 13.6008 11.7 13.5414L12.7704 12.1805C12.8391 12.093 12.8766 11.9836 12.8766 11.8711C12.875 11.5945 12.6516 11.3711 12.375 11.3711Z' />
    </>
  ),
})
