import { ApiService } from 'services/authApi/getPermissions'
import { RefundsCompensationsApiResponse } from 'types/api/orderApi/order'

import { Compensation, Refund } from 'types/api/voucherApi/getCompensationsAndRefunds'

export const getOrderCompensationsAndRefunds: ApiService<
  {
    entityId: string
    orderId: string
  },
  RefundsCompensationsApiResponse
> = (createClient, options) => {
  const { entityId, orderId, clientParams } = options

  // client details
  const uri = `/VoucherAPI/v2/order/${entityId}/${orderId}/refunds-compensations`

  return createClient({
    endpointName: 'getOrderCompensationsAndRefunds',
    expectedResponseStatusCode: 200,
    responseMustHaveData: true,
    ...clientParams,
  })
    .get(uri)
    .then((res) => {
      const compensations: Compensation[] = res.data.compensations || []
      const refunds: Refund[] = res.data.refunds || []

      return {
        ...res,
        data: {
          ...res.data,
          compensations,
          refunds,
        },
      }
    })
}
