import React from 'react'
import { createCustomSvgIcon } from 'factory/createCustomSvgIcon'

export const AllowanceIcon = createCustomSvgIcon({
  viewBoxWidth: 14,
  viewBoxHeight: 14,
  content: (
    <>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M11.6667 5.13856V1.60837C11.6667 1.14968 11.3033 0.777832 10.8551 0.777832H3.14497C2.69674 0.777832 2.33337 1.14968 2.33337 1.60837V5.27546L3.55077 6.22228V2.43892C3.55077 2.20957 3.73245 2.02364 3.95656 2.02364H10.0435C10.2676 2.02364 10.4493 2.20957 10.4493 2.43892V6.0407L11.6667 5.13856Z'
        fill='currentColor'
      />
      <ellipse cx='7.00001' cy='5.83339' rx='1.55556' ry='1.55556' fill='currentColor' />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M0 4.66669C0 4.23714 0.348223 3.88892 0.777778 3.88892H13.2222C13.6518 3.88892 14 4.23714 14 4.66669V12.8334C14 13.2629 13.6518 13.6111 13.2222 13.6111H0.777778C0.348223 13.6111 0 13.2629 0 12.8334V4.66669ZM1.16667 5.6874V12.0556C1.16667 12.2704 1.34078 12.4445 1.55556 12.4445H12.4444C12.6592 12.4445 12.8333 12.2704 12.8333 12.0556V5.68737L7.36167 9.79112C7.32043 9.82373 7.27548 9.85016 7.2282 9.8702C7.16302 9.89796 7.09435 9.91307 7.02559 9.91603C6.92847 9.92035 6.82983 9.90053 6.73932 9.85519C6.70464 9.83791 6.67147 9.81705 6.64038 9.79269L1.16667 5.6874ZM13.2222 3.88892H0.777778L6.99998 8.60405L13.2222 3.88892Z'
        fill='currentColor'
      />
    </>
  ),
})
