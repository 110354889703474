export default {
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
  },
  formContainer: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: '16px',
  },
  formField: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    width: '100%',
  },
  actionButtonsContainer: {
    display: 'flex',
    flexDirection: 'row-reverse',
    gap: '8px',
    paddingBlock: '8px',
  },
}
