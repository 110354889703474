export default {
  container: {
    display: 'flex',
    alignItems: 'flex-start',
    paddingLeft: '4px',
  },
  dot: {
    marginTop: '7px',
    width: '8px',
    height: '8px',
    borderRadius: '50%',
    backgroundColor: (props) => props.color,
  },
}
