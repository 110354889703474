import { DataContext } from 'contexts/data/DataContext'
import { DataAction } from 'contexts/data/types'
import { useCaptureUserAction } from 'hooks/events/useCaptureUserAction'
import { useApiService } from 'hooks/useApiService'
import { useFixedPanelActionsWidgetConfigs } from 'hooks/useFixedPanelActionsWidgetConfig'
import { useIsDataPointValid } from 'hooks/useGetValidFeatures'
import { useSessionState } from 'hooks/useSessionState'
import { useContext } from 'react'
import { getCustomerCompensationsAndRefunds } from 'services/VoucherApi/getCustomerCompensationsAndRefunds'

import checkDataPointAvailability from 'utils/checkDataPointAvailability'

export const useGetCustomerCompensationsAndRefunds = () => {
  // pull compensation values per entity from context
  const {
    common: { prev_compensations_and_refunds_banner },
  } = useFixedPanelActionsWidgetConfigs()

  const { orderId, userId, globalEntityId } = useSessionState()

  const captureUserAction = useCaptureUserAction()

  const { dataDispatch } = useContext(DataContext)

  const isDataPointValid = useIsDataPointValid()

  let shouldLoad = true

  const isFeatureAllowed = checkDataPointAvailability(
    prev_compensations_and_refunds_banner.displayRule,
    true,
  )

  const isBetaRequirementGranted = isDataPointValid(
    prev_compensations_and_refunds_banner.betaRequirement,
  )

  if (!isFeatureAllowed || !isBetaRequirementGranted) {
    shouldLoad = false
  }

  return useApiService({
    service: getCustomerCompensationsAndRefunds,
    params: {
      entityId: globalEntityId,
      customerId: userId,
      orderId,
    },
    deps: [globalEntityId, userId, orderId],
    shouldLoad,

    onSuccess(res) {
      const { compensations, refunds } = res.data
      if (compensations.length) {
        dataDispatch({
          type: DataAction.SET_CUSTOMER_COMPENSATIONS,
          payload: {
            customerCompensations: compensations,
          },
        })
      }

      if (refunds.length) {
        dataDispatch({
          type: DataAction.SET_CUSTOMER_REFUNDS,
          payload: {
            customerRefunds: refunds,
          },
        })
      }

      captureUserAction('ActionCompensationCTSDetailsLoaded', {
        eventDetails: {
          numberOfCompensations: compensations.length,
          numberOfRefunds: refunds.length,
        },
      })
    },
  })
}
