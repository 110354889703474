/**
 * dumb component rendering a spinner and passed text vertically
 * */

// libs
import React from 'react'
//styles
import styles from './LoadingView.styles'
import { createUseStyles } from 'react-jss'
import { Spin, Typography } from 'antd'
import cx from 'classnames'

const useStyles = createUseStyles(styles)

interface Props {
  text?: string
  overlay?: boolean
}

const LoadingView: React.FC<Props> = ({ text = '', overlay = false }) => {
  const classes = useStyles()
  const { Text } = Typography

  return (
    <div className={cx(classes.container, overlay ? classes.overlay : '')}>
      <Spin className={classes.spinner} size='large' />
      <Text className={classes.loadingText}>{text}</Text>
    </div>
  )
}

export default LoadingView
