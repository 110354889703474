import { ApiErrorConfig } from './voucherApiErrorConfig'

export const commentApiErrorConfig: ApiErrorConfig<'CommentApi'> = {
  CommentApi: {
    postNewComment: {
      400: {
        '*': {
          screen: 'error',
          heading: `Couldn't create a comment`,
          copyText: 'An error has occured{{dot}} Come back later',
        },
      },
      401: {
        '*': {
          screen: 'warning',
          heading: `Couldn't create comment`,
          copyText: 'An error has occured{{dot}} Go back to try again or select Quit to exit',
        },
      },
      422: {
        NO_EMAIL_FOUND: {
          screen: 'warning',
          heading: `Customer email address required`,
          copyText:
            "Comment not created{{dot}} In order to comply with GDPR regulation, customer's email address is mandatory to add a comment",
        },
      },
      500: {
        '*': {
          screen: 'error',
          heading: `Couldn't create a comment`,
          copyText: 'An error has occured{{dot}} Come back later',
        },
      },
      503: {
        '*': {
          screen: 'warning',
          heading: `Couldn't create a comment`,
          copyText: 'An error has occured{{dot}} Go back to try again or select Quit to exit',
        },
      },
    },
    getOrderComments: {
      400: {
        '*': {
          screen: 'error',
          heading: 'Order comments unavailable',
          copyText: 'An error has occured',
        },
      },
      401: {
        '*': {
          screen: 'warning',
          heading: 'Order comments unavailable',
          copyText: 'An error has occured{{dot}} Select refresh to try again',
        },
      },
      403: {
        '*': {
          screen: 'instruct',
          heading: 'Permission required',
          copyText: 'Ask a supervisor to check Oneview permissions',
        },
      },
      500: {
        '*': {
          screen: 'error',
          heading: 'Order comments unavailable',
          copyText: 'An error has occured',
        },
      },
    },
    getCustomerComments: {
      401: {
        '*': {
          screen: 'warning',
          heading: 'Customer comments unavailable',
          copyText: 'An error has occured{{dot}} Select refresh to try again',
        },
      },
      403: {
        '*': {
          screen: 'instruct',
          heading: 'Permission required',
          copyText: 'Ask a supervisor to check Oneview permissions',
        },
      },
    },
  },
}
