import React from 'react'
import { createCustomSvgIcon } from 'factory/createCustomSvgIcon'

export const CreditCardIcon = createCustomSvgIcon({
  viewBoxWidth: 18,
  viewBoxHeight: 14,
  content: (
    <>
      <mask id='path-1-inside-1_2729_3432' fill='white'>
        <rect y='0.25' width='18' height='13.5' rx='0.638021' />
      </mask>
      <rect
        y='0.25'
        width='18'
        height='13.5'
        rx='0.638021'
        stroke='currentColor'
        fill='white'
        strokeWidth='2.9'
        mask='url(#path-1-inside-1_2729_3432)'
      />
      <line
        x1='-9.22037e-08'
        y1='4.49609'
        x2='18'
        y2='4.49609'
        stroke='currentColor'
        strokeWidth='1.91406'
      />
    </>
  ),
})
