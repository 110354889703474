export default {
  actionsContainer: {
    border: '1px solid #e8e8e8',
    borderRadius: '5px',
    padding: '10px',
    marginLeft: '2%',
    marginTop: '15px',
    backgroundColor: '#ffffff',
    boxShadow: '0.5px 0.5px 5px rgba(0, 0, 0, 0.134124)',
    height: 'auto',
    minHeight: '100px',
  },
  componentsContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
  },
  compensationDisdabledText: {
    margin: '3px',
    color: '#f6f6f6',
  },
  actionsTitle: {
    fontSize: '17px',
    color: '#3d4a4f',
  },
}
