import React, { MouseEvent } from 'react'
import Text from 'antd/lib/typography/Text'
import { PhoneIcon } from './PhoneIcon'

export interface BasePhoneDecoratorProps {
  caseId?: string
  linkTarget?: string
  onClick: (e: MouseEvent<HTMLAnchorElement>) => void
  phoneNumber: string | number
  className?: string
  onlyIcon?: boolean
}

export const TelPhoneDecorator: React.FC<BasePhoneDecoratorProps> = ({
  caseId,
  linkTarget = '_parent',
  phoneNumber,
  onlyIcon,
  onClick,
  className,
}) => {
  const ticketId = caseId ? `#${caseId}` : ''
  if (phoneNumber) {
    return (
      <Text className={className}>
        {!onlyIcon ? phoneNumber : null}
        <a
          target={linkTarget}
          data-testid={'phoneNumber'}
          rel='noopener noreferrer'
          href={`tel:${phoneNumber}${ticketId}`}
          onClick={onClick}
          data-cy='phoneNumber'
        >
          <PhoneIcon />
        </a>
      </Text>
    )
  }

  return null
}
