import { ApiService } from 'services/authApi/getPermissions'
import { OrderStatuses } from 'types/widgets/order/orderStatuses'

export const patchDeliveryAddress: ApiService<
  {
    entityId: string
    orderId: string

    orderStatus: OrderStatuses

    latitude: number
    longitude: number
    formatted_address: string
    company: string
    street: string
    number: string
    floor: string
    suburb: string
    block: string
    building: string
    apartment: string
    entrance: string
    intercom: string
    zipcode: string
    skip_delivery_area_validation: boolean

    instructions?: string
  },
  any
> = (createClient, options): Promise<any> => {
  const {
    entityId,
    orderId,
    orderStatus,

    clientParams = {},

    ...body
  } = options

  const uri = `OrdersAPI/v1/orders/${entityId}/${orderId}/dropoff-address`
  const { tParams, ...otherClientParams } = clientParams
  return createClient({
    endpointName: 'patchDeliveryAddress',
    expectedResponseStatusCode: 200,
    validateData: (data) => {
      if (data.success !== 'true') {
        return `Success response data success field is not valid. Expected true but got ${data.success}`
      }
      return true
    },

    tParams: {
      orderStatus,
      ...tParams,
    },
    ...otherClientParams,
  }).patch(uri, body)
}
