export default {
  container: {
    width: '100%',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'baseline',
    width: '100%',
    backgroundColor: '#2A8FF7',
    padding: '6px 14px 6px 14px',
  },
  title: {
    fontSize: '18px',
    color: '#ffffff',
  },
  closeIcon: {
    color: '#ffffff',
  },
  buttonHolder: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'baseline',
    marginTop: '20px',
  },
  dropdownMenu: {
    overflowX: 'scroll',
    overflowY: 'scroll',
    height: 'auto',
    maxHeight: 300,
    width: 280,
    maxWidth: 280,
  },
  buttonWrapper: {
    display: 'flex',
  },
  resultHolder: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
}
