import React from 'react'

// Types
import { AdditionalDataReportMissingItems } from 'types/api/commentApi/getOrderComments'

// Components
import { Typography } from 'antd'

// Relative Path
import FullItemDetails from './ItemDetails/FullItemDetails'
import SimpleItemDetails from './ItemDetails/SimpleItemDetails'

type Props = {
  report_missing_items: AdditionalDataReportMissingItems
}

function ReportMissingItems({ report_missing_items }: Props) {
  const { Text } = Typography

  if (report_missing_items?.removed_items?.length) {
    return <FullItemDetails items={report_missing_items.removed_items} />
  }

  if (report_missing_items?.missing_items?.length) {
    return <SimpleItemDetails items={report_missing_items.missing_items} />
  }

  return <Text strong>some items</Text>
}

export default ReportMissingItems
