import { primary, gray } from 'theme'
import { CardProps } from '.'

export default {
  card: (props: CardProps) => {
    return {
      borderRadius: '5px',
      borderColor: props.highlighted ? primary.primary3 : null,

      '& .ant-card-head': {
        borderRadius: '5px',
        background: props.bgColor ?? gray.gray3,
      },

      '& .ant-card-body': {
        borderRadius: '5px',
        background: props.type === 'inner' ? gray.gray2 : gray.gray1,
      },

      '&.ant-card-hoverable:hover': {
        borderColor: props.highlighted ? primary.primary3 : 'transparent',
      },
    }
  },
}
