// libs
import React, { useMemo } from 'react'
import { createUseStyles } from 'react-jss'
// contexts and types
import { OrderApiResponse } from 'types/api/orderApi/order'
// styles
import { Tooltip } from 'antd'
import styles from './StatusDot.styles'
import { useGetOrderStatusMetadata } from 'hooks/dataTransformers/useGetOrderStatusMetadata'

interface Props {
  order: OrderApiResponse
}

const useStyles = createUseStyles(styles)

export const StatusDot: React.FC<Props> = ({ order }) => {
  const getOrderStatusMetadata = useGetOrderStatusMetadata()
  const { statusColor, description } = useMemo(
    () => getOrderStatusMetadata(order),
    [order, getOrderStatusMetadata],
  )

  const classes = useStyles({
    color: statusColor,
  })

  return (
    <Tooltip title={description}>
      <div className={classes.container}>
        <div className={classes.dot} data-testid='status-dot' />
      </div>
    </Tooltip>
  )
}
