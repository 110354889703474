export default {
  // PARTIAL
  partialHolder: {
    display: 'flex',
    flexDirection: 'column',
    borderBottom: '1px solid #F0F0F0',
  },
  partialContainerWithoutInput: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    height: '40px',
    padding: '5px 0px',
    // padding: '5px 15px 0px 15px',
  },
  partialRowNameHolder: ({ spacing }) => {
    return {
      display: 'flex',
      flexDirection: 'column',
      width: '65%',
      paddingLeft: spacing,
    }
  },
  partialRowPriceHolder: {
    display: 'flex',
    flexDirection: 'column',
    width: '30%',
  },

  partialDescriptionTextForName: {
    fontSize: '10px',
    color: '#8C8C8C',
    marginLeft: '25px',

    '&.selected, &.disabled': {
      color: 'rgba(0, 0, 0, 0.35)',
    },
  },

  partialDescriptionPriceText: {
    fontSize: '12px',
    color: 'rgba(0, 0, 0, 0.85)',
    '&.selected, &.disabled': {
      color: 'rgba(0, 0, 0, 0.35)',
    },
  },

  partialDescriptionTextForPrice: {
    fontSize: '10px',
    color: '#8C8C8C',
    '&.selected, &.disabled': {
      color: 'rgba(0, 0, 0, 0.35)',
    },
  },

  partialDescriptionIcon: {
    marginRight: '3px',
  },

  partialRowPriceText: {
    fontSize: '12px',
    color: 'rgba(0, 0, 0, 0.85)',
  },
  partialDescriptionHolder: ({ spacing }) => {
    return {
      display: 'flex',
      flexDirection: 'column',
      width: '57%',
      paddingLeft: spacing,
      // margin: '0px 0px 10px 8%',
    }
  },
  descriptionText: {
    fontSize: '12px',
    color: 'rgba(0, 0, 0, 0.85)',
  },
  partialInputHolder: {
    display: 'flex',
  },
  partialDescriptionInput: {
    fontSize: '12px',
    width: '250px',
    marginRight: '5px',
  },
  partialInputAndRequiredholder: {
    display: 'flex',
    flexDirection: 'column',
  },
  requiredText: {
    fontSize: '10px',
    color: '#1890FF',
    marginTop: '3px',
  },
  partialCompletedDescriptionHolder: {
    display: 'flex',
    width: '57%',
    margin: '5px 0px 10px 8%',
  },
  editDescriptionIcon: {
    color: '#1890FF',
    marginLeft: '3px',
  },
  partialOkButton: {
    marginLeft: '5px',
    fontSize: '12px',
  },
}
