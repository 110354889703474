import { ApiPermissions, WidgetName } from 'types'

export type WidgetPermissionRequirement = {
  anyOf?: Array<ApiPermissions>
  allOf?: Array<ApiPermissions>
}

export const requiredWidgetPermissions: Partial<Record<WidgetName, WidgetPermissionRequirement>> = {
  cancel_order: {
    allOf: ['order-api.GetOrder', 'order-api.CancelOrder'],
  },
  change_cooking_instructions: {
    allOf: ['order-api.GetOrder', 'order-api.ChangeCookingInstructions'],
  },
  modify_delivery_address: {
    allOf: ['order-api.GetOrder', 'vendor-api.SearchVendor'],
    anyOf: ['order-api.ChangeDropOffAddress', 'order-api.ChangeDropOffInstructions'],
  },
  change_delivery_status: {
    allOf: ['fulfillment-api.GetOrderFulfillment'],
  },
  change_delivery_time: {
    allOf: ['order-api.GetOrder', 'order-api.ChangeDeliveryTime'],
  },
  compensation: {
    allOf: ['order-api.GetOrder'],
    anyOf: ['payment-api.CreateCompensation', 'voucher-api.CreateVoucher'],
  },
  new_comment: {
    allOf: ['comment-api.CreateComment'],
  },
  full_refund: {
    allOf: ['order-api.GetOrder'],
    anyOf: ['voucher-api.CreateVoucher', 'payment-api.CreateRefund'],
  },
  partial_refund: {
    allOf: ['order-api.GetOrder', 'payment-api.GetPurchase'],
    anyOf: [
      'payment-api.CreateRefund',
      'voucher-api.CreateVoucher',
      'order-api.ReportMissingItems',
      'order-api.RemoveItems',
    ],
  },
  search_order: {
    allOf: ['order-api.GetOrder'],
    anyOf: ['order-api.GetOrderCollection', 'order-api.GetOrderCollectionV2'],
  },
  search_voucher: {
    allOf: ['order-api.GetOrder', 'voucher-api.GetVoucherByCode'],
  },
  search_vendor: {
    allOf: ['order-api.GetOrder', 'vendor-api.SearchVendor'],
  },
  'dh.herocare.ticket-escalate-plugin': {
    allOf: ['ticket-api.EscalateTicket', 'ticket-api.GetEscalationQueues'],
  },
  'dh.herocare.wrap-up-plugin': {
    allOf: [
      'ticket-api.PatchTicket',
      'ticket-api.GetContactReasonTree',
      'ticket-api.GetLocalContactReasons',
    ],
  },
  'dh.herocare.add-rider-plugin': {
    allOf: ['order-api.GetOrder'],
    anyOf: [
      'fulfillment-api.PostNewDeliveryOrder',
      'fulfillment-api.GetCreateNewDeliveryOrderReasons',
    ],
  },
  'dh.herocare.reassign-order-plugin': {
    allOf: [
      'order-api.GetOrder',
      'fulfillment-api.GetCourierDetails',
      'fulfillment-api.GetOrderFulfillment',
      'fulfillment-api.GetReassignDeliveryReasons',
      'fulfillment-api.PutReassignDelivery',
    ],
  },
  'dh.herocare.update-order-payable': {
    allOf: ['order-api.GetOrderFlags', 'order-api.UpdateOrderFlags'],
  },
  'dh.herocare.vendor-schedule-plugin': {
    allOf: [
      'vendor-api.GetVendorSchedule',
      'vendor-api.ListOpeningTimesCalendars',
      'vendor-api.ListOpeningTimesSpecialSchedules',
    ],
    anyOf: [
      'vendor-api.UpdateOpeningTimesCalendars',
      'vendor-api.CreateOpeningTimesSpecialSchedules',
      'vendor-api.UpdateOpeningTimesSpecialSchedules',
      'vendor-api.DeleteOpeningTimesSpecialSchedules',
    ],
  },
  'dh.herocare.vendor-update-availability-plugin': {
    allOf: [
      'vendor-api.GetVendorSchedule',
      'vendor-api.GetVendorAvailabilityStatus',
      'vendor-api.UpdateVendorAvailabilityStatus',
    ],
  },
  'dh.herocare.vendor-availability-logs-plugin': {
    allOf: ['vendor-api.GetVendorAvailabilityLogs'],
  },
}
