/**
 * expects a string
 * returns the same string with:
 * -> underscores replaced with spaces
 * -> all letters lower cased expect the first letter
 * */

function prettifyString(s: string): string {
  return s
    .replace(/_/g, ' ')
    .toLowerCase()
    .replace(/^\w/, (match) => match.toUpperCase())
}

export default prettifyString
