export default {
  modal: {
    display: 'flex',
    flexDirection: 'column',
    minWidth: 500,
  },
  titleHolder: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#1295e6',
    padding: 20,
    margin: -24,
  },
  modalTitle: {
    fontSize: '16px',
    color: '#fff',
    marginTop: '7px',
  },
  modalContent: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '150px',
  },
  searchContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginRight: '10px',
  },
  text: {
    marginBottom: '7px',
  },
  findVoucherSearch: {
    width: '200px',
    marginRight: '4px',
    backgroundColor: '#ffffff',
  },
  regionSelect: {
    width: '105px',
    marginRight: '4px',
    backgroundColor: '#ffffff',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },
}
