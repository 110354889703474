import React from 'react'
import { createUseStyles } from 'react-jss'
import orderIcon from 'assets/order/Gray.png'

const useStyle = createUseStyles({
  container: {
    paddingLeft: 25,
    display: 'flex',
  },
  icon: {
    marginRight: '10px',
  },
  text: {
    color: '#333333',
    marginTop: '3px',
  },
})

const SpecialInstructions = ({ instructions }: { instructions: string }) => {
  const classes = useStyle()
  return (
    <div className={classes.container}>
      <div className={classes.icon}>
        <img src={orderIcon} alt='order icon' />
      </div>
      <p className={classes.text}>{instructions}</p>
    </div>
  )
}

export default SpecialInstructions
