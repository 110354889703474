import React from 'react'
import { CustomerChatHistoryApiResponse } from 'types/api/ticketApi/getCustomerChatHistory'
import styles from './SingleChatTranscript.styles'
import { createUseStyles } from 'react-jss'
import { SingleChatInfo } from './SingleChatInfo'
import moment from 'moment'
import { ChatCard } from '../ChatCard/ChatCard'
import { useTranslation } from 'hooks/useTranslation'

const useStyles = createUseStyles(styles)

interface SingleChatTranscriptBodyParams {
  chatData: CustomerChatHistoryApiResponse
  customerEmail: string
  orderId: string
}

export const SingleChatBody = ({
  chatData,
  customerEmail,
  orderId,
}: SingleChatTranscriptBodyParams) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const sortedChatMessages = chatData.data
    ?.sort((a, b) => (a.sort_id < b.sort_id ? -1 : 1))
    .filter((message) => message.sort_id > 0)

  const chatStartTime = moment(sortedChatMessages?.[0].created_at)?.format('DD.MM.yyyy, LT')
  const chatEndTime = moment(
    sortedChatMessages?.[sortedChatMessages?.length - 1].created_at,
  )?.format('DD.MM.yyyy, LT')

  return (
    <div className={classes.bodyContainer}>
      <SingleChatInfo message={`${t('With')} ${customerEmail}`} time={chatStartTime} />
      <div className={classes.chatCards}>
        {sortedChatMessages?.map((message, index) => {
          const replyStart = message.sender !== sortedChatMessages[index - 1]?.sender
          return (
            <ChatCard
              key={`${index}-${message.created_at}`}
              message={message}
              replyStart={replyStart}
            />
          )
        })}
      </div>
      <SingleChatInfo
        message={t('Chat ended')}
        time={chatEndTime}
        extra={<div className={classes.orderBadge}>{`${t('Order ID')}: ${orderId}`}</div>}
      />
    </div>
  )
}
