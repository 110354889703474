import { ApiService } from 'services/authApi/getPermissions'
import { SingleComment } from 'types/api/commentApi/getOrderComments'

export const postNewComment: ApiService<
  {
    entityId: string
    comment: SingleComment
  },
  any
> = (createClient, options) => {
  const {
    entityId,

    comment,

    clientParams,
  } = options

  const uri = `/CommentAPI/v1/comment/${entityId}`

  return createClient({
    endpointName: 'postNewComment',
    ...clientParams,
  }).post(uri, {
    comment: {
      ...comment,
      global_entity_id: entityId,
    },
  })
}
