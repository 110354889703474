import React, { useState } from 'react'
import fixNumber from 'utils/fixNumber'
import { Col, Row, Space, Typography } from 'antd'
import useTableStyles from './table.styles'
import { DownOutlined, UpOutlined } from '@ant-design/icons'
import SpecialInstructions from './SpecialInstructions'
import { calculateItemCost } from 'utils/order/orderItems'
import ItemRequirementIcon from 'widgets/Order/ItemRequirementIcon'
import { useCanDisplayDataPoint } from '../../../../hooks/useGetValidFeatures'
import { OrderWidgetItems } from 'contexts/entity/types'
import { getItemLocalizedNames } from 'utils/dataTransformers/transformOrderItems'
import { Box } from 'shared'

const { Text } = Typography

type Props = {
  itemsConfig: OrderWidgetItems
  level?: number
  currency: string
  digitsAfterComa: number
  isToppingtotalPriceAvailable: boolean
  item: any
  locale: string
}

const TableRow = ({
  itemsConfig,
  item,
  currency,
  digitsAfterComa,
  level = 0,
  isToppingtotalPriceAvailable,
  locale,
}: Props) => {
  const isTopping = item.type && item.type === 'topping'
  const doesHaveOptions = Boolean(item?.options?.length)
  const classes = useTableStyles({ canExpand: doesHaveOptions, level } as any)
  const [expand, setExpand] = useState<boolean>(!!item.is_bundle)
  const { itemPrice, toppingsPrice, totalPrice } = calculateItemCost(item)

  const canDisplayDataPoint = useCanDisplayDataPoint()

  const displayItemRequirementIcon = canDisplayDataPoint(
    item.requirement,
    itemsConfig?.item_requirement,
  )

  /**
   * Bundles: We are only showing item price (unit_price) if available.
   *          Bundle row will always be expanded on load
   *          We are not showing bundle toppings price because calculation does make sense.
   */
  /**
   * Toppings: We are only showing toppings price (unit_price).
   */
  const { name, localizedNames } = getItemLocalizedNames(
    item,
    locale,
    itemsConfig.enabled_item_locales,
  )

  return (
    <>
      <Row
        className={classes.tableRow}
        onClick={() => setExpand(!expand)}
        data-test-id='order-item-row'
      >
        {/* item name */}
        <Col span={11}>
          <Row className={classes.itemNameBox}>
            <Col>
              <Text className={classes.itemQuanity}>{item.quantity} x </Text>
            </Col>
            <Col flex='auto'>
              <Space>
                <Text>{name}</Text>
                {doesHaveOptions && (!expand ? <DownOutlined /> : <UpOutlined />)}
              </Space>
              {localizedNames.length > 0 && (
                <Box>
                  {localizedNames.map((name) => {
                    return (
                      <div key={name}>
                        <Text>{name}</Text>
                      </div>
                    )
                  })}
                </Box>
              )}
            </Col>
          </Row>
          {item.customer_notes && <SpecialInstructions instructions={item.customer_notes} />}
        </Col>

        <Col span={4}>
          {isTopping || (item.is_bundle && !itemPrice) ? null : (
            <>
              {currency} {fixNumber(itemPrice, digitsAfterComa)}
            </>
          )}
        </Col>
        <Col span={4}>
          {isToppingtotalPriceAvailable && !item.is_bundle
            ? `${currency} ${fixNumber(isTopping ? itemPrice : toppingsPrice, digitsAfterComa)}`
            : ''}
        </Col>
        <Col span={4}>
          {!isTopping ? (
            <>
              {currency} {fixNumber(totalPrice, digitsAfterComa)}
            </>
          ) : null}
        </Col>
        <Col span={1}>
          {displayItemRequirementIcon && <ItemRequirementIcon requirement={item.requirement} />}
        </Col>
      </Row>
      {doesHaveOptions &&
        expand &&
        item.options.map((itemOption) => (
          <TableRow
            itemsConfig={itemsConfig}
            key={itemOption.id}
            item={itemOption}
            level={level + 1}
            currency={currency}
            isToppingtotalPriceAvailable={isToppingtotalPriceAvailable}
            digitsAfterComa={digitsAfterComa}
            locale={locale}
          />
        ))}
    </>
  )
}

export default TableRow
