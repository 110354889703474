import { EMPTY_CALLBACK } from 'constants/constants'
import moment from 'moment-timezone'
import { useMemo, useState } from 'react'
import { ChatEventType, ChatParticipant, RiderChatsData } from 'types/api/riderApi/riderChats'
import { SingleChatEventLocal } from 'types/components/chatTranscriptView'
import modifyUTC from 'utils/modifyUTC'

export interface RiderChatTranscriptState {
  chatCreatedDateTime: string
  chatFrozenDateTime: string
  availableTranslatedLanguages: string[]
  allChatEvents: SingleChatEventLocal[]

  selectedLanguage: string
  setSelectedLanguage: (lang: string) => void
}

export const useRiderChatsStates = (chatTranscript: RiderChatsData, utc_zone: string) => {
  const [selectedLanguage, setSelectedLanguage] = useState('')

  const result = useMemo((): RiderChatTranscriptState => {
    const result: RiderChatTranscriptState = {
      chatCreatedDateTime: '',
      chatFrozenDateTime: '',
      availableTranslatedLanguages: [],
      allChatEvents: [],
      selectedLanguage: '',
      setSelectedLanguage: EMPTY_CALLBACK,
    }

    if (!chatTranscript) {
      return result
    }

    const users = chatTranscript.users || []
    const channel = chatTranscript.channel || {}
    const events = chatTranscript.events || []

    const riders = users.filter((current) => current.type === ChatParticipant.rider)
    const riderMap = new Map(
      riders.map(({ id, nickname }) => {
        return [id, nickname]
      }),
    )

    if (channel.created_at) {
      result.chatCreatedDateTime = modifyUTC(channel.created_at, utc_zone)
    }
    if (channel.freeze_at) {
      result.chatFrozenDateTime = modifyUTC(channel.freeze_at, utc_zone)
    }

    const supportedLanguages = new Set<string>()

    const allChatEventsLocal = events.map((event) => {
      const { event_type, timestamp, status } = event
      const singleEvent: SingleChatEventLocal = {
        eventType: event_type,
        status,
      }

      // resolve timestamp
      if (timestamp) {
        if (event_type === ChatEventType.message) {
          const dateUTC = new Date(timestamp / 1000000).toISOString()
          const dateLocal = moment(dateUTC).tz(utc_zone).format('H:mm')
          singleEvent.timestamp = dateLocal
        } else {
          const dateUTC = new Date(timestamp / 1000000).toISOString()
          const dateLocal = modifyUTC(dateUTC, utc_zone)
          singleEvent.timestamp = dateLocal
        }
      }

      // resolve sender
      if (event.event_type === ChatEventType.joined) {
        singleEvent.sender = event.content.type
      } else {
        singleEvent.sender = event.sender_type
      }

      if (event.event_type === ChatEventType.message) {
        singleEvent.message = event.message || event.content
        singleEvent.translations = event.translations || {}

        const foundLanguages = event.supported_languages || []
        foundLanguages.forEach((current) => supportedLanguages.add(current))
      }

      if (event.event_type === ChatEventType.image) {
        singleEvent.image = {
          ...event.content,
          // image expires after 14h
          isExpired: timestamp && Date.now() > timestamp + 14 * 60 * 60 * 1000,
        }
      }

      // Add rider info to each message from rider
      switch (event.event_type) {
        case ChatEventType.message:
        case ChatEventType.image:
          if (event.sender_type === ChatParticipant.rider) {
            singleEvent.rider = {
              id: event.sender_id,
              nickName: riderMap.get(event.sender_id) ?? '',
            }
          }
          break
        default:
          break
      }

      return singleEvent
    })

    result.allChatEvents = allChatEventsLocal
    result.availableTranslatedLanguages = [...supportedLanguages]

    return result
  }, [chatTranscript, utc_zone])

  return {
    ...result,
    selectedLanguage,
    setSelectedLanguage,
  }
}
