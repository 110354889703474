import { AxiosResponse } from 'axios'
import { TrackingAuthPayload } from 'contexts/auth/AuthProvider'
import environment from 'envConfig'
import { ApiService } from './getPermissions'

export interface GetTrackingTokenResponse {
  access_token: string
}

/**
 * we use new time values in reference to the users
 * local time
 */
export const transformTrackingData = (
  response: AxiosResponse<GetTrackingTokenResponse>,
): AxiosResponse<TrackingAuthPayload> => {
  const { access_token } = response.data
  return {
    ...response,
    data: {
      trackingToken: access_token,
      /**
       * refresh tracking token 23 hours later
       */
      refreshAfter: Date.now() + 23 * 60 * 60 * 1000,
    },
  }
}

export const getTrackingToken: ApiService<
  {
    globalEntityId: string
  },
  TrackingAuthPayload
> = (createClient, opts) => {
  const { globalEntityId, clientParams } = opts
  const uri = `${environment().oneviewAuthApiRoot}/ets/${globalEntityId || 'null'}/new-session`

  return createClient({
    endpointName: 'getTrackingToken',
    ...clientParams,
    expectedResponseStatusCode: 200,
    useAwsApi: environment().oneviewAuthApiAWSEnabled === true ? true : undefined,
  })
    .post(uri)
    .then(transformTrackingData)
}
