export default {
  infoHolderWithTopMargin: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '18px',
  },
  infoHolderWithoutTopMargin: {
    display: 'flex',
    justifyContent: 'center',
  },
  info: {
    fontSize: '11px',
    color: 'rgba(0, 0, 0, 0.45)',
  },
}
