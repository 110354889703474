import { Context, useContext } from 'react'

export const useContextProvider = <T>(context: Context<T>, providerName: string) => {
  const value = useContext(context)
  if (typeof value === 'undefined' && process.env.NODE_ENV !== 'production') {
    throw new Error(
      `${providerName} value is undefined. Please wrap your application in a ${providerName}`,
    )
  }
  return value
}
