import { AxiosResponse } from 'axios'
import {
  ApiClientCreator,
  ApiClientCreatorParams,
} from 'contexts/apiClientCreator/ApiClientCreatorContext'
import { refreshPermissions } from 'services/authApi/refreshPermissions'
import { readAuthPayload } from './authHelpers'
import { getSearchParams } from './getSearchParams'
import { AuthContextValue, AuthPayload } from 'contexts/auth/AuthProvider'

/**
 * if the time is '0', it means the last refresh request succeded,
 * if the time is '-1',  it means that the last refresh request failed
 *
 * if the time is something else, a number, it means that one of the tabs is performing a refresh,
 * and the refresh started at the exact time value
 */
type JwtRefreshTime = number

let refreshingTokenPromise: Promise<boolean>
let refreshStart: JwtRefreshTime = 0

const setRefreshStart = (time: JwtRefreshTime) => {
  refreshStart = time
  window.localStorage.setItem('refresh-start', time.toString())
}

const getRefreshStart = () => {
  return Number.parseInt(window.localStorage.getItem('refresh-start') || '0', 10)
}

/**
 *
 * @returns
 */
export const refreshJwt = (
  createClient: ApiClientCreator,
  authDispatch: AuthContextValue['authDispatch'],
  clientParams?: Partial<ApiClientCreatorParams<AuthPayload>>,
  forceRefresh?: boolean,
): Promise<boolean> => {
  // if this tab is refreshing, return the promise
  if (refreshStart > 0) {
    return refreshingTokenPromise
  }

  const refreshStartTime = getRefreshStart()

  // another tab is refreshing, listen for storage event on refresh-start to detect when
  // refresh completes
  if (refreshStartTime > 0) {
    refreshingTokenPromise = new Promise((resolve, reject) => {
      // this is a safety measure, where refresh-start in the browser is greater than 0,
      // but the agent kills his/her browser or singleton oneview tab.
      const timeout = window.setTimeout(() => {
        setRefreshStart(0)
        refreshJwt(createClient, authDispatch, clientParams, forceRefresh)
          .then(resolve)
          .catch(reject)
      }, 15 * 1000)

      const listener = (event: StorageEvent) => {
        clearTimeout(timeout)
        window.removeEventListener('storage', listener, false)
        if (event.key === 'refresh-start') {
          const value = event.newValue
          if (value === '0') {
            resolve(true)
          } else {
            reject()
          }
        }
      }
      window.addEventListener('storage', listener, false)
    })

    return refreshingTokenPromise
  }

  const { refreshAfter, refresh, agentEmail, agentName } = readAuthPayload()
  const now = Date.now()

  // we will not refresh because it is not yet time to refresh
  if (!forceRefresh && now < refreshAfter) {
    return Promise.resolve(true)
  }

  // it is time to refresh but there is no refresh token found
  if (!refresh) {
    return Promise.reject('No refresh token found to carry out refresh')
  }

  setRefreshStart(now)
  refreshingTokenPromise = refreshPermissions(createClient, {
    refreshToken: refresh,
    globalEntityId: getSearchParams().getGlobalEntityId(),
    agentEmail,
    agentName,
    clientParams,
  })
    .then((res: AxiosResponse<AuthPayload>) => {
      authDispatch({ type: 'refresh', payload: res.data })
      setRefreshStart(0)
      return true
    })
    .catch(() => {
      // this means that the value
      setRefreshStart(-1)
      return Promise.reject()
    })

  return refreshingTokenPromise
}
