import React, { useImperativeHandle, useState } from 'react'
import { createPluggableWidget } from 'factory/createPluggableWidget'
import { Notification } from 'shared'
import { BuiltinWidgetConfigs } from 'types'
import { Empty, Space } from 'antd'
import { getVendorDevices } from 'services/vendorApi/getVendorDevices'
import { useApiService } from 'hooks/useApiService'
import { createUseStyles } from 'react-jss'
import { ActionLabelMap, DeviceActionName } from './DeviceActions/DeviceActions'
import { performDeviceAction } from 'services/vendorApi/performDeviceAction'
import { getDeviceRemoteControlLink } from 'services/vendorApi/getDeviceRemoteControlLink'
import { useApiClientCreator } from 'contexts/apiClientCreator/ApiClientCreatorContext'
import { ActionConfirmationPopup } from './ActionConfirmationPopup/ActionConfirmationPopup'
import { DeviceCard, VendorDevice } from './DeviceCard/DeviceCard'
import { transformDeviceList } from '../../utils/dataTransformers/transformDeviceList'

export const useStyles = createUseStyles({
  deviceList: {
    display: 'flex',
  },
})

const ActionSuccessMessageMap: Record<Exclude<DeviceActionName, 'REMOTE_CONTROL'>, string> = {
  RESTART: 'Soft reset has been performed',
  RESET: 'Wipe application data has been performed',
  INSTALL: 'App installation has been performed',
}

const ActionEventNameMap: Record<DeviceActionName, string> = {
  RESTART: 'soft_reset',
  RESET: 'wipe_data',
  INSTALL: 'app_reinstall',
  REMOTE_CONTROL: 'remote_control',
}

export const VendorDevices = createPluggableWidget<BuiltinWidgetConfigs['vendor_devices']>(
  ({ config, vendor, globalEntityId, sdk, ErrorRenderer }, ref) => {
    const classes = useStyles()
    const { t, captureUserAction } = sdk
    const { createClient } = useApiClientCreator()

    const [showConfirmation, setShowConfirmation] = useState(false)
    const [selectedAction, setSelectedAction] = useState<DeviceActionName>(null)
    const [selectedDevice, setSelectedDevice] = useState<VendorDevice>(null)

    const vendorId = vendor.vendor_id

    const {
      data: vendorDevices,
      error: vendorDevicesError,
      loading: isLoading,
      loadService: refresh,
    } = useApiService({
      service: getVendorDevices,
      params: {
        entityId: globalEntityId,
        vendorId,
      },

      shouldLoad: Boolean(vendorId && globalEntityId),
      deps: [vendorId],
    })

    // Add a refresh link in widget header
    useImperativeHandle(
      ref,
      () => {
        return {
          onRefresh: () => {
            refresh()
          },
        }
      },
      [refresh],
    )

    const performAction = ({ deviceId, action }) => {
      if (action === 'REMOTE_CONTROL') {
        return getDeviceRemoteControlLink(createClient, {
          vendorId,
          deviceId,
          entityId: globalEntityId,
        }).then(({ data }) => {
          window.open(data.link, '_blank')
        })
      }

      return performDeviceAction(createClient, {
        vendorId,
        deviceId,
        action,
        entityId: globalEntityId,
      })
    }

    const onClose = () => {
      setShowConfirmation(false)
      setSelectedAction(null)
    }

    const onConfirm = () => {
      captureUserAction('CONFIRM_DEVICE_ACTION', {
        reportToEts: true,
        eventDetails: {
          action: ActionEventNameMap[selectedAction],
          button_label: t('Widgets Common.Confirm'),
        },
        screenDetails: {
          source_widget_category: 'action',
        },
      })

      return performAction({ deviceId: selectedDevice.deviceId, action: selectedAction }).then(
        () => {
          setShowConfirmation(false)

          if (selectedAction !== 'REMOTE_CONTROL') {
            Notification.success({
              message: t(ActionSuccessMessageMap[selectedAction]),
            })
          }
          captureUserAction('CONFIRM_DEVICE_ACTION_SUCCESS', {
            reportToEts: true,
            eventDetails: {
              action: ActionEventNameMap[selectedAction],
              button_label: t('Widgets Common.Confirm'),
            },
            screenDetails: {
              source_widget_category: 'action',
            },
          })
        },
      )
    }
    const handlePerformAction = (device: VendorDevice, action: DeviceActionName) => {
      setShowConfirmation(true)
      setSelectedDevice(device)
      setSelectedAction(action)

      captureUserAction('OPEN_DEVICE_ACTION', {
        reportToEts: true,
        eventDetails: {
          action: ActionEventNameMap[action],
          button_label: t(ActionLabelMap[action]),
        },
        screenDetails: {
          source_widget_category: 'action',
        },
      })
    }

    const devices = vendorDevices ? transformDeviceList(vendorDevices) : []

    return (
      <ErrorRenderer errorPayload={vendorDevicesError?.errorPayload} loading={isLoading}>
        {() => (
          <div>
            {showConfirmation ? (
              <ActionConfirmationPopup
                action={selectedAction}
                onConfirm={onConfirm}
                onClose={onClose}
              />
            ) : null}

            <Space direction='vertical' size='small' className={classes.deviceList}>
              {devices.length ? (
                devices.map((device, index) => (
                  <DeviceCard
                    key={device.lpvId}
                    sdk={sdk}
                    config={config}
                    device={device}
                    expanded={index === 0}
                    isExpandable={devices.length !== 1}
                    onPerformAction={({ action }) => handlePerformAction(device, action)}
                  />
                ))
              ) : (
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description={t('widgets.vendor_devices.no_devices')}
                />
              )}
            </Space>
          </div>
        )}
      </ErrorRenderer>
    )
  },
  {
    deriveConfig({ entityConfig }) {
      return entityConfig.layout_v2.builtin_widgets_configs.vendor_devices
    },
    deriveSubjectsRequirements() {
      return {
        all_of: ['vendor'],
      }
    },
    category: 'data_lookup',
  },
)
