import React, { useState } from 'react'
import {
  DeliveryIssue,
  DeliveryItem,
  FulfillmentApiResponse,
} from 'types/api/fulfillmentApi/fulfillment'
import { useDeliveryPanelStyles } from './styles'
import { Collapse, Tag } from 'antd'
import { DeliveryPanelHeader } from './DeliveryPanelHeader'
import { DeliveryPanelBody } from './DeliveryPanelBody'
import { OrderApiResponse } from 'types/herocare'
import { OrderDeliveryConfigs } from 'types'
import { Text } from 'shared'

const { Panel } = Collapse

type Props = {
  label: string
  order: OrderApiResponse
  issues?: DeliveryIssue[]
  deliveries: DeliveryItem[]
  defaultActiveKey?: string[]
  config: OrderDeliveryConfigs
  isMapVisible?: boolean
  onViewMap?: () => void
  fulfillment: FulfillmentApiResponse
}

export const DeliveryPanelList = ({
  label,
  order,
  config,
  onViewMap,
  deliveries,
  issues = [],
  fulfillment,
  isMapVisible,
  defaultActiveKey,
}: Props) => {
  const classes = useDeliveryPanelStyles()
  const [activeKey, setActiveKey] = useState<string[]>(defaultActiveKey)

  const handleKeyChange = (key) => {
    setActiveKey(key)
  }

  return (
    <>
      <div className={classes.riders}>
        <Tag className={classes.figureTag}>{deliveries.length}</Tag>
        <Text.Secondary fontSize={14}>{label}</Text.Secondary>
      </div>
      <div className={classes.deliveries}>
        <Collapse
          defaultActiveKey={activeKey}
          className={classes.container}
          onChange={handleKeyChange}
        >
          {deliveries.map((delivery) => (
            <Panel
              className='panel'
              key={delivery.id}
              showArrow={false}
              header={
                <DeliveryPanelHeader
                  issues={issues.filter((issue) => issue.delivery_id === delivery.id)}
                  order={order}
                  config={config}
                  delivery={delivery}
                  active={activeKey.includes(String(delivery.id))}
                />
              }
            >
              <DeliveryPanelBody
                fulfillment={fulfillment}
                delivery={delivery}
                order={order}
                config={config}
                isMapVisible={isMapVisible && !!delivery.courier}
                onViewMap={onViewMap}
              />
            </Panel>
          ))}
        </Collapse>
      </div>
    </>
  )
}
