/**
 * expects orderStatus and order from Order API; fulfillment API response; and entity's UTC offset
 * returns a status history arr and delivery time objs with all UTC modified for the entity
 * */

import { differenceInMinutes, isFuture } from 'date-fns'
import { Times } from 'types/dataTransformers/orderStatus'
import { OrderApiResponse, OrderHistoryItem } from 'types/api/orderApi/order'
import { FulfillmentApiResponse } from 'types/api/fulfillmentApi/fulfillment'
import { OrderStatuses } from 'types/widgets/order/orderStatuses'
import { pickPrimaryDelivery } from 'utils/pickPrimaryDelivery'
import { useCallback } from 'react'
import { useTranslation } from 'hooks/useTranslation'
import { useGetOrderStatusTextAndColor } from '../getters/useGetOrderStatusTextAndColor'
import { useDateTimeFormatter } from 'hooks/formatters/useDateTimeFormatter'
import { useCreateFilterRenderableDataPoint } from 'hooks/filters/useCreateFilterRenderableDataPoint'
import { orderStatusTabDataPointConfigs } from 'constants/tabDataPointConfigs/orderStatusTabDataPointConfigs'
import { useGetOrderStatusMetadata } from './useGetOrderStatusMetadata'

export const useTransformOrderStatusHistory = () => {
  const getOrderStatusMetadata = useGetOrderStatusMetadata()
  const getOrderStatusTextAndColor = useGetOrderStatusTextAndColor()
  const dateTimeFormatter = useDateTimeFormatter()
  const createFilterRendereableDataPoint = useCreateFilterRenderableDataPoint()

  const { t } = useTranslation()

  return useCallback(
    (fulfillment: FulfillmentApiResponse, order: OrderApiResponse, statusConfig: any) => {
      // time now
      const now = new Date()

      const orderStatuses = order.status_history || []

      // returned data structure
      const transformedHistory = {
        statusHistory: [],
        promisedDropoffTime: {
          value: t(`Order Widget.Tabs.Status.Order Statuses.${Times.dropOff}`),
          date: '',
          isInFuture: true,
          thirtyMinsOrLongerInThePast: false,
        },
        estimatedDropoffTime: {
          value: t(`Order Widget.Tabs.Status.Order Statuses.${Times.estDeliver}`),
          date: '',
          timeDifference: '',
          key: orderStatusTabDataPointConfigs.estimatedDropOffTime,
        },
        estimatedPickupTime: {
          value: t(`Order Widget.Tabs.Status.Order Statuses.${Times.estPick}`),
          date: '',
        },
      }

      const filterRendereableDataPoint = createFilterRendereableDataPoint(statusConfig)
      const { statusText: orderStatusText, status: orderStatus } = getOrderStatusMetadata(order)

      // handle pending order
      if (orderStatus.status === OrderStatuses.pending) {
        transformedHistory.statusHistory.push({
          key: orderStatusTabDataPointConfigs.pending.key,
          value: orderStatusText,
          date: dateTimeFormatter.formatDateTime(order.timestamp),
        })

        return {
          transformedHistory: {
            statusHistory: transformedHistory.statusHistory.filter(filterRendereableDataPoint),
          },
        }
      }

      // handle pending order failed
      if (orderStatus.status === OrderStatuses.pendingOrderFailed) {
        transformedHistory.statusHistory.push({
          key: orderStatusTabDataPointConfigs.pendingOrderFailed.key,
          value: orderStatusText,
          date: dateTimeFormatter.formatDateTime(orderStatus.timestamp),
        })
        return {
          transformedHistory: {
            statusHistory: transformedHistory.statusHistory.filter(filterRendereableDataPoint),
          },
        }
      }

      // map over statuses and push them to transformedHistory.statusHistory
      orderStatuses.forEach((status: OrderHistoryItem) => {
        let cancelReason = ''
        let cancelReasonModified = ''

        const { text } = getOrderStatusTextAndColor(status.status)

        if (status.status === OrderStatuses.cancelled) {
          cancelReasonModified = status?.cancelled?.details?.reason
          if (cancelReasonModified === OrderStatuses.mistake) {
            cancelReasonModified = t('Widgets Common.Change of Mind - Not Acceptable by Vendor')
          }
          cancelReason = `${status?.cancelled?.details?.source}. ${t(
            'Order Widget.Tabs.Status.Reason',
          )}: ${cancelReasonModified}`
        }

        transformedHistory.statusHistory.push({
          value: text,
          date: dateTimeFormatter.formatDateTime(status.timestamp),
          cancelReason: cancelReason.replace(/_/g, ' '),
        })
      })

      const primaryDelivery = pickPrimaryDelivery(fulfillment?.deliveries)

      const estimatedPickUpTime = primaryDelivery?.latest_estimated_pickup_time?.arriving_at
      const promisedDropOffTime =
        fulfillment?.promised_delivery_at || order?.promised_customer_timestamp || ''

      let estimatedDropOffTime = primaryDelivery?.latest_estimated_dropoff_times?.arriving_at

      let isPromisedDropOffTimeInFuture = false
      let isPromisedDropOffTimeLaterThan30mins = false

      // use model_prediction_median_bound_minutes if it exists
      if (fulfillment?.model_prediction_median_bound_minutes) {
        estimatedDropOffTime = new Date(
          Date.now() + fulfillment?.model_prediction_median_bound_minutes * 60 * 1000,
        ).toUTCString()
      }

      // set in future and later than 30 minutes
      if (promisedDropOffTime) {
        isPromisedDropOffTimeInFuture = isFuture(new Date(promisedDropOffTime))
        isPromisedDropOffTimeLaterThan30mins =
          differenceInMinutes(now, new Date(promisedDropOffTime)) >= 30
      }

      // update properties on return obj
      transformedHistory.promisedDropoffTime.isInFuture = isPromisedDropOffTimeInFuture
      transformedHistory.promisedDropoffTime.thirtyMinsOrLongerInThePast =
        isPromisedDropOffTimeLaterThan30mins

      // update the time diff between estimated drop off time and promised drop off time
      if (estimatedDropOffTime && promisedDropOffTime) {
        const minuteDiffBetweenEstimatedVsPromisedDropOffTime = differenceInMinutes(
          new Date(estimatedDropOffTime),
          new Date(promisedDropOffTime),
        )

        if (minuteDiffBetweenEstimatedVsPromisedDropOffTime > 0) {
          if (minuteDiffBetweenEstimatedVsPromisedDropOffTime < 60) {
            transformedHistory.estimatedDropoffTime.timeDifference = `${t(
              'Order Widget.Tabs.Status.Delayed',
            )}: ${minuteDiffBetweenEstimatedVsPromisedDropOffTime} ${t(
              'Order Widget.Tabs.Status.Minute',
            )}`
          } else {
            const minutes = minuteDiffBetweenEstimatedVsPromisedDropOffTime % 60
            const hours = Math.floor(minuteDiffBetweenEstimatedVsPromisedDropOffTime / 60)

            transformedHistory.estimatedDropoffTime.timeDifference = `${t(
              'Order Widget.Tabs.Status.Delayed',
            )}: ${hours} hrs, ${minutes} mins`
          }
        }
      }

      // set estimated pick up and drop off time
      transformedHistory.estimatedPickupTime.date =
        dateTimeFormatter.formatDateTime(estimatedPickUpTime)
      transformedHistory.promisedDropoffTime.date =
        dateTimeFormatter.formatDateTime(promisedDropOffTime)
      transformedHistory.estimatedDropoffTime.date =
        dateTimeFormatter.formatDateTime(estimatedDropOffTime)

      transformedHistory.estimatedPickupTime.date = dateTimeFormatter.formatDateTime(
        primaryDelivery?.latest_estimated_pickup_time?.arriving_at,
      )

      const lastOrderStatus = orderStatuses[orderStatuses.length - 1]

      const orderIsInProgress = ![
        OrderStatuses.cancelled,
        OrderStatuses.delivered,
        OrderStatuses.rejected,
      ].includes(lastOrderStatus.status)

      if (
        estimatedPickUpTime &&
        !orderStatuses.find(({ status }) => status === OrderStatuses.pickedUp)
      ) {
        transformedHistory.statusHistory.push(transformedHistory.estimatedPickupTime)
      }

      if (estimatedDropOffTime && orderIsInProgress) {
        transformedHistory.statusHistory.push(transformedHistory.estimatedDropoffTime)
      }

      if (promisedDropOffTime && orderIsInProgress) {
        transformedHistory.statusHistory.push(transformedHistory.promisedDropoffTime)
      }

      return {
        transformedHistory: {
          statusHistory: transformedHistory.statusHistory.filter(filterRendereableDataPoint),
        },
      }
    },
    [
      t,
      getOrderStatusTextAndColor,
      getOrderStatusMetadata,
      dateTimeFormatter,
      createFilterRendereableDataPoint,
    ],
  )
}
