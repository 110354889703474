/**
 * VoucherView
 * pulls voucher data from data context and renders it through VoucherRow and Details
 * */

// libs
import React, { useState, useMemo } from 'react'
import { Empty, Pagination } from 'antd'
// contexts and types
// styles
import { createUseStyles } from 'react-jss'
import styles from './VoucherView.styles'
// components
import { VoucherTable } from './VoucherTable'
import { DEFAULT_PAGINATION_PAGE_SIZE } from 'constants/constants'
import VoucherDetails from './VoucherDetails'
import { useTranslation } from 'hooks/useTranslation'
import { Voucher } from 'types/api/voucherApi/voucher'
import { WidgetContainerType } from 'types'
const useStyles = createUseStyles(styles)

const VoucherView: React.FC<{ vouchers: Voucher[]; containerType: WidgetContainerType }> = ({
  vouchers,
  containerType,
}) => {
  const classes = useStyles()
  const { t } = useTranslation()

  // set current page upon paginator click -> passed to paginator as current page
  const [currentPage, setCurrentPage] = useState(1)
  const handlePaginationChange = (newPage: number) => setCurrentPage(newPage)

  // total number of vouchers passed to Paginator to set its page list
  const totalNumberOfVouchers = vouchers.length || 0

  // number of vouchers per page
  const [vouchersPerPage, setVouchersPerPage] = useState(DEFAULT_PAGINATION_PAGE_SIZE)

  // slice dataState's vouchers according to pagination (VOUCHER_PAGE_SIZE vouchers each) to be rendered in each page
  const vouchersSliced = useMemo(() => {
    // slice data state's vouchers as a chunk of VOUCHER_PAGE_SIZE (the chunk is determined by current page)
    const slicedData = vouchers.slice(
      currentPage * vouchersPerPage - vouchersPerPage,
      currentPage * vouchersPerPage,
    )
    return slicedData || []
  }, [currentPage, vouchers, vouchersPerPage])

  if (totalNumberOfVouchers === 0) {
    return (
      <Empty
        image={Empty.PRESENTED_IMAGE_SIMPLE}
        description={t('Customer Widget.Tabs.Vouchers.No vouchers found')}
      />
    )
  }

  return (
    <div className={classes.voucherViewContainer}>
      <VoucherTable listOfVouchers={vouchersSliced} containerType={containerType} />

      <div className={classes.voucherTabFooter}>
        {/* render a pagination, if there are more than VOUCHER_PAGE_SIZE vouchers */}

        <div className={classes.paginatorContainer}>
          <Pagination
            current={currentPage}
            defaultCurrent={currentPage}
            total={totalNumberOfVouchers}
            onChange={handlePaginationChange}
            showSizeChanger={true}
            onShowSizeChange={(_, size) => {
              setVouchersPerPage(size)
            }}
          />
        </div>

        {/*Voucher count summary by category*/}
        <VoucherDetails vouchers={vouchers} />
      </div>
    </div>
  )
}

export default VoucherView
