import { AxiosResponse } from 'axios'
import { ApiService } from 'services/authApi/getPermissions'

export enum CustomerWalletTransactionType {
  Purchase = 'Purchase',
  RefundShopToWallet = 'RefundShopToWallet',
  RefundShopToSource = 'RefundShopToSource',
  PartialRefundShopToWallet = 'PartialRefundShopToWallet',
  PartialRefundShopToSource = 'PartialRefundShopToSource',
  CashChange = 'CashChange',
  TopUp = 'TopUp',
  MarketingTopUp = 'MarketingTopUp',
  MarketingTopUpExpiration = 'MarketingTopUpExpiration',
  MarketingTopUpClawback = 'MarketingTopUpClawback',
  TransferWalletToSource = 'TransferWalletToSource',
}

export interface CustomerWalletTransactionBreakdown {
  amount: {
    amount: number
    currency: string
  }

  card?: {
    scheme: string
    maskedCardNumber: string
    validToYear: number
    validToMonth: number
  }

  /**
   * payment method name
   */
  paymentMethodName?: string

  /**
   * payment breakdown description
   */
  description?: string
}

export interface CustomerWalletTransaction {
  /**
   * transaction type
   */
  type: CustomerWalletTransactionType

  /**
   * creation date time of order
   */
  creationDateTime: string

  /**
   * transaction description
   */
  description: string

  /**
   * transaction id
   */
  id: string

  /**
   * the order id associated with the order
   */
  platformReferenceId: string

  /**
   * payment breakdown
   */
  paymentBreakdown: CustomerWalletTransactionBreakdown[]

  /**
   * transaction total amount
   */
  totalAmount: {
    amount: number
    currency: string
  }

  /**
   * transaction date
   */
  transactionDate?: string

  /**
   * the transaction owner
   */
  transactionOwner: {
    id: string
    type: string
  }
}

export interface GetCustomerWalletTransactionsResponse {
  data: CustomerWalletTransaction[]

  /**
   * pagination meta data
   */
  meta: {
    /**
     * total number of wallet transactions that exists for the customer
     */
    totalItems: number

    /**
     * count of currently returned items.
     */
    currentItemCount: number

    /**
     * starting index of paginated response. starts from 1.
     */
    startIndex: number
  }
}

export const getCustomerWalletTransactions: ApiService<
  {
    entityId: string
    customerId: string

    limit?: number
    offset?: number
  },
  GetCustomerWalletTransactionsResponse
> = (createClient, options) => {
  const { entityId, customerId, clientParams, limit = 1000 } = options

  const uri = `/PaymentAPI/v1/customer/${entityId}/${customerId}/wallet/transactions?limit=${limit}`

  return createClient({
    endpointName: 'getCustomerWalletTransactions',
    expectedResponseStatusCode: 200,
    ...clientParams,
  }).get<any, AxiosResponse<GetCustomerWalletTransactionsResponse>>(uri)
}
