import { DeliveryIssue, DeliveryItem } from 'types/api/fulfillmentApi/fulfillment'
import { useDeliveryPanelStyles } from './styles'
import { StatusColors, text } from 'theme'
import { ActionHandler, OrderDeliveryConfigs } from 'types'
import { PhoneFilled, PhoneOutlined } from '@ant-design/icons'
import {
  ArrowDownIcon,
  ArrowUpIcon,
  BikerIcon,
  CarIcon,
  ScootyIcon,
  TruckIcon,
  VehicleIcon,
} from 'Icons'
import { Col, Menu, Popover, Row, Space, Tag, Tooltip } from 'antd'
import PhoneNumber from 'components/PhoneNumber/PhoneNumber'
import { useSdk } from 'contexts/SdkProvider'
import { useCurrentDeliveryStatus } from 'hooks/dataTransformers/useCurrentDeliveryStatus'
import { useGetRiderStackedOrderCount } from 'hooks/useGetRiderStackedOrderCount'
import { useTranslation } from 'hooks/useTranslation'
import React, { useCallback, useMemo } from 'react'
import {
  ActionHandlerButtonProps,
  Button,
  CopyButton,
  Dot,
  InlineDropdown,
  Notification,
  Text,
  UnifiedIcon,
  WidgetActionHandlers,
} from 'shared'
import { OrderApiResponse, SDK, builtinWidgetNames } from 'types/herocare'
import { DeliveryIssues } from '../DeliveryIssues/DeliveryIssues'
import { useApiService } from 'hooks/useApiService'
import { putSetPrimaryDelivery } from 'services/fulfillmentApi/putSetPrimaryDelivery'
import { getWidgetId } from 'utils/getters/getWidgetId'

type Props = {
  order: OrderApiResponse
  delivery: DeliveryItem
  config: OrderDeliveryConfigs
  issues?: DeliveryIssue[]
  active?: boolean
}

const iconLookup = {
  Car: CarIcon,
  Bike: BikerIcon,
  Truck: TruckIcon,
  Bicycle: BikerIcon,
  Motorcycle: ScootyIcon,
  Other: VehicleIcon,
}

const iconTooltipLookup = {
  Car: 'widgets.order_deliveries.vehicle.car',
  Bike: 'widgets.order_deliveries.vehicle.bike',
  Truck: 'widgets.order_deliveries.vehicle.truck',
  Bicycle: 'widgets.order_deliveries.vehicle.bicycle',
  Motorcycle: 'widgets.order_deliveries.vehicle.motorcycle',
  Other: 'widgets.order_deliveries.vehicle.unknown',
}

const DeliveryActions = ({
  phoneNumber,
  delivery,
  config,
  sdk,
  order,
}: {
  phoneNumber?: string
  delivery: DeliveryItem
  config: OrderDeliveryConfigs
  sdk: SDK
  order: OrderApiResponse
}) => {
  const { t } = useTranslation()

  const classes = useDeliveryPanelStyles()

  const { loadService: executePutSetPrimaryDelivery } = useApiService({
    service: putSetPrimaryDelivery,
    deps: [],
    autoLoad: false,
  })

  const currentRiderStackedOrdersCount = useGetRiderStackedOrderCount(delivery)
  const { checkDisplayRules, activateWidgetView, widgetId } = sdk

  // Show count of current rider's stacked orders as prefix to label of an action button. (Only for action button associated with Stacked order action )
  // Do not display Rider stacked order and Reassign delivery action buttons if the delivery has no courier assosiated.
  const actionHandlers: (ActionHandler & Pick<ActionHandlerButtonProps, 'label'>)[] =
    useMemo(() => {
      return config.action_handlers.map((action) => {
        if (action?.action?.label_translation_key === 'action_labels.rider_stacked_orders') {
          if (!delivery.courier) return { ...action, display_rules: { hide: true } }
          const prefix = currentRiderStackedOrdersCount
          const labelTranslation = t(action?.action.label_translation_key) ?? ''
          return {
            ...action,
            label: `${prefix} ${labelTranslation}`.trim(),
          }
        }
        if (action?.action?.label_translation_key === 'action_labels.reassign_delivery') {
          if (!delivery.courier) return { ...action, display_rules: { hide: true } }
        }
        return action
      })
    }, [config.action_handlers, currentRiderStackedOrdersCount, t, delivery.courier])

  // Separate the visible action handlers from the invisible action handlers (Only for action button associated with Change delivery status action).
  const { visibleActionHandlers, invisibleActionHandlers } = useMemo(() => {
    const invisibleActionHandlers: (ActionHandler & Pick<ActionHandlerButtonProps, 'label'>)[] = []
    const activeActionHandlers = actionHandlers.filter(
      (action) =>
        checkDisplayRules({
          displayRules: action.display_rules,
          opts: { delivery, widgetName: widgetId as builtinWidgetNames },
        }).visible,
    )

    // All actions that are not to be in the more actions menu.
    const updatedVisibleActionHandlers = activeActionHandlers.filter(
      (action) =>
        action?.action?.label_translation_key !== 'action_labels.change_delivery_status' &&
        action?.action?.label_translation_key !== 'action_labels.set_primary_delivery',
    )

    const changeDeliveryStatusAction = activeActionHandlers.find(
      (action) => action?.action?.label_translation_key === 'action_labels.change_delivery_status',
    )
    // Corresponding label based on current delivery status (Only for action button associated with Change delivery status action).
    const changeDeliveryStatusLabels = {
      accepted: t('widgets.change_delivery_status.more_actions_menu_labels.courier_picked_up'),
      near_pickup: t('widgets.change_delivery_status.more_actions_menu_labels.courier_picked_up'),
      picked_up: t('widgets.change_delivery_status.more_actions_menu_labels.order_delivered'),
      near_dropoff: t('widgets.change_delivery_status.more_actions_menu_labels.order_delivered'),
      left_pickup: t('widgets.change_delivery_status.more_actions_menu_labels.order_delivered'),
    }

    const changeDeliveryStatusLabel = changeDeliveryStatusLabels[delivery.state] || ''

    // Update the label of Change delivery status
    if (changeDeliveryStatusAction) {
      invisibleActionHandlers.push({
        ...changeDeliveryStatusAction,
        action: {
          ...changeDeliveryStatusAction.action,
          label_translation_key: changeDeliveryStatusLabel,
        },
      })
    }

    // Only add Set primary delivery action if the delivery is not primary already.
    if (!delivery.primary_delivery) {
      const setPrimaryDeliveryAction = activeActionHandlers.find(
        (action) => action?.action?.label_translation_key === 'action_labels.set_primary_delivery',
      )
      setPrimaryDeliveryAction && invisibleActionHandlers.push(setPrimaryDeliveryAction)
    }

    return {
      visibleActionHandlers: updatedVisibleActionHandlers,
      invisibleActionHandlers: invisibleActionHandlers,
    }
  }, [checkDisplayRules, t, actionHandlers, widgetId, delivery])

  const handleSetPrimaryDelivery = useCallback(async () => {
    sdk.captureUserAction('CONFIRM_MARK_PRIMARY', {
      screenDetails: { source_widget_category: 'action' },
    })
    try {
      await executePutSetPrimaryDelivery({
        entityId: order.global_entity_id,
        deliveryId: delivery.id,
        orderId: order.order_id,
      })
      sdk.captureUserAction('CONFIRM_MARK_PRIMARY_SUCCESS', {
        screenDetails: { source_widget_category: 'action' },
      })
      sdk.eventEmitter.dispatchEvent({
        name: 'SET_PRIMARY_DELIVERY_SUCCESS',
        payload: { orderId: order.order_id },
      })
      Notification.success({ message: t('widgets.set_primary_delivery.messages.success') })
    } catch (error) {
      Notification.error({ message: t('widgets.set_primary_delivery.messages.error') })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    executePutSetPrimaryDelivery,
    order.global_entity_id,
    order.order_id,
    sdk.eventEmitter.dispatchEvent,
    sdk.captureUserAction,
    delivery.id,
    t,
  ])

  const onInvisibleActionHandlerClick = useCallback(
    (action: ActionHandler['action'], handler: ActionHandler['handler']) => {
      if (action.label_translation_key === 'action_labels.set_primary_delivery') {
        handleSetPrimaryDelivery()
      } else {
        activateWidgetView(handler, { subjects: { delivery } })
      }
    },
    [activateWidgetView, delivery, handleSetPrimaryDelivery],
  )

  const ignoreClickEvent = (event) => {
    event.stopPropagation()
  }

  const invisibleActionItems =
    invisibleActionHandlers?.length > 0 &&
    invisibleActionHandlers.map(({ action, handler }: ActionHandler) => {
      return (
        <Menu.Item
          className={classes.dropdownMenuItem}
          onClick={({ domEvent }) => {
            ignoreClickEvent(domEvent)
            onInvisibleActionHandlerClick(action, handler)
          }}
          data-test-id={getWidgetId(handler)}
          key={action.label_translation_key}
          icon={<UnifiedIcon mr={'8px'} icon={action.icon_name} />}
        >
          {t(action.label_translation_key)}
        </Menu.Item>
      )
    })

  return (
    <Row gutter={[7, 0]} align='middle'>
      {/* phone call action */}
      {phoneNumber && (
        <Col>
          <Popover
            placement='bottomRight'
            content={
              <Space>
                <UnifiedIcon icon={PhoneFilled} size={12} />
                <Text.Primary>{phoneNumber}</Text.Primary>
                <CopyButton text={phoneNumber} />
                <PhoneNumber onlyIcon value={phoneNumber} onDialClick={ignoreClickEvent} />
              </Space>
            }
          >
            <Button
              borderRadius={4}
              type='outlined'
              size='small'
              icon={PhoneOutlined}
              onClick={ignoreClickEvent}
            />
          </Popover>
        </Col>
      )}
      {/* visible actions */}
      <Col>
        <WidgetActionHandlers
          size='small'
          marginTop={0}
          actionHandlers={visibleActionHandlers}
          activationSubjects={{
            delivery,
          }}
        />
      </Col>
      {/* more actions menu */}
      <Col>
        {invisibleActionHandlers?.length > 0 && (
          <InlineDropdown
            trigger={['click']}
            overlay={<Menu className={classes.dropdownMenu}>{invisibleActionItems}</Menu>}
            placement='bottomRight'
          >
            <Button
              borderRadius={4}
              size={'small'}
              type='outlined'
              icon='MoreOutlined'
              onClick={ignoreClickEvent}
              data-test-id='more-actions-button'
            />
          </InlineDropdown>
        )}
      </Col>
    </Row>
  )
}

const DeliveryItemCourierInfo = ({
  courierName,
  courierId,
  extra,
}: {
  courierName: string
  courierId: number
  extra?: React.ReactNode
}) => {
  return (
    <Row gutter={[8, 0]}>
      <Col>
        <Text.Text fontWeight='500'>{courierName}</Text.Text>
      </Col>
      {courierId && (
        <>
          <Col>
            <Text.Text color={text.secondary}>{courierId}</Text.Text>
          </Col>
          <Col>
            <CopyButton text={String(courierId)} />
          </Col>
        </>
      )}
      {extra}
    </Row>
  )
}

export const DeliveryPanelHeader = ({ delivery, config, order, issues = [], active }: Props) => {
  const sdk = useSdk()
  const { t } = useTranslation()
  const classes = useDeliveryPanelStyles({ expanded: active })

  const currentDeliveryStatus = useCurrentDeliveryStatus({ order, delivery })

  const canDisplayDeliveryIssues = useMemo(() => {
    const deliveryIssuesDisplayRules = config?.data_points?.find(
      (data_point) => data_point.name === 'delivery_issues',
    )?.display_rules
    return sdk.checkDisplayRules({
      displayRules: deliveryIssuesDisplayRules,
    })
  }, [config?.data_points, sdk])

  const deliveryPanelHeaderData = {
    iconName: iconLookup[delivery?.courier?.vehicle_type] || VehicleIcon,
    iconTooltip: iconTooltipLookup[delivery?.courier?.vehicle_type] || iconTooltipLookup.Other,
    courierName:
      delivery?.courier?.name || t('widgets.order_deliveries.deliveries.no_rider_dispatched_yet'),
    courierId: delivery?.courier?.id || null,
    courierPhoneNumber: delivery?.courier?.phone_number || null,
    deliveryStatusColor: (delivery?.courier && currentDeliveryStatus?.color) || StatusColors.red,
    deliveryStatusText:
      (delivery?.courier && currentDeliveryStatus?.text) ||
      t('widgets.order_deliveries.deliveries.rider_info_not_available'),
  }

  switch (delivery?.state) {
    case 'pending':
      /**
       * @todo
       * to be either left here as is or to to be figured out by useCurrentDeliveryStatus hook
       */
      deliveryPanelHeaderData.deliveryStatusColor = StatusColors.orange
      deliveryPanelHeaderData.deliveryStatusText = 'Pending'
      break

    case 'queued':
      /**
       * @todo
       * to be either left here as is or to to be figured out by useCurrentDeliveryStatus hook
       */
      deliveryPanelHeaderData.deliveryStatusColor = StatusColors.green
      deliveryPanelHeaderData.deliveryStatusText = 'Queued'
      break
  }

  return (
    <Row className={classes.header} gutter={[15, 0]}>
      <Col>
        <Tooltip
          title={`${t('widgets.order_deliveries.deliveries.vehicle')}: ${t(
            deliveryPanelHeaderData.iconTooltip,
          )}`}
        >
          <span>
            <UnifiedIcon icon={deliveryPanelHeaderData.iconName} />
          </span>
        </Tooltip>
      </Col>
      <Col flex={1}>
        <Row className='delivery-meta' gutter={[0, 10]}>
          <Col>
            <Row align='middle' justify='space-between'>
              <Col>
                <DeliveryItemCourierInfo
                  courierName={deliveryPanelHeaderData.courierName}
                  courierId={deliveryPanelHeaderData.courierId}
                  extra={
                    delivery.primary_delivery && (
                      <Col>
                        <Tag className={classes.primaryTag}>
                          {t(`widgets.order_deliveries.deliveries.primary`)}
                        </Tag>
                      </Col>
                    )
                  }
                />
              </Col>
              <Col>
                {active ? (
                  <UnifiedIcon icon={ArrowDownIcon} size={12} />
                ) : (
                  <UnifiedIcon icon={ArrowUpIcon} size={12} />
                )}
              </Col>
            </Row>
          </Col>
          <Col>
            <Row justify='space-between' align='middle'>
              <Col>
                <Space align='center'>
                  <Dot color={deliveryPanelHeaderData.deliveryStatusColor} />
                  <Text.Primary>{deliveryPanelHeaderData.deliveryStatusText}</Text.Primary>
                </Space>
              </Col>
              <Col>
                <DeliveryActions
                  phoneNumber={deliveryPanelHeaderData.courierPhoneNumber}
                  delivery={delivery}
                  config={config}
                  sdk={sdk}
                  order={order}
                />
              </Col>
            </Row>
          </Col>
          {Boolean(issues.length) && canDisplayDeliveryIssues && (
            <Col>
              <DeliveryIssues issues={issues} />
            </Col>
          )}
        </Row>
      </Col>
    </Row>
  )
}
