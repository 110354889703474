export default {
  tagsContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    flexWrap: 'wrap',
    width: '100%',
    alignItems: 'center',
  },
  flagIcon: {
    marginTop: '10px',
    width: '29px !important',
    height: '22px !important',
    border: '0.5px solid rgba(0, 0, 0, 0.45)',
    borderRadius: '2px',
  },
  tag: {
    marginTop: '10px',
    textTransform: 'capitalize',
  },
}
