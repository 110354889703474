import React from 'react'
import { TranslateFunction } from 'hooks/useTranslation'
import { LocationIcon } from 'Icons/LocationIcon'
import { DeliveryItem } from 'types/api/fulfillmentApi/fulfillment'
import { FulfillmentApiResponse, OrderApiResponse } from 'types/herocare'
import { OrderStatuses } from 'types/widgets/order/orderStatuses'
import { TimelineItem } from 'widgets/Deliveries/types'
import UpdatedDeliveryTime from 'widgets/Deliveries/UpdatedDeliveryTime/UpdatedDeliveryTime'
import { calculateOrderDeliveryTimestamps } from 'utils/order/calculateOrderDeliveryTimestamps'
import { RiderStatuses } from 'types/widgets/rider/riderStatuses'
import { getUpdatedDeliveryTime } from 'utils/order/getUpdatedDeliveryTime'
import { text } from 'theme'
import { SDK } from 'types'
import { getLastValue } from 'utils/array'
import { differenceInDays, parseISO } from 'date-fns'

type Props = {
  sdk: SDK
  t: TranslateFunction
  delivery: DeliveryItem
  order: OrderApiResponse
  forRiderHistory?: boolean
  fulfillment: FulfillmentApiResponse
}

export const getOrderEstimationStatusForTimeline = ({
  t,
  sdk,
  order,
  delivery,
  fulfillment,
  forRiderHistory,
}: Props) => {
  const updatedDeliveryTime = getUpdatedDeliveryTime({ order, fulfillment, sdk })
  const orderDate = sdk.datetimeFormatter.formatDate(order.timestamp)
  const deliveryProvider = order.delivery.provider
  const { delayTime, promisedDropOffTime, estimatedDropOffTime } = calculateOrderDeliveryTimestamps(
    {
      order,
      fulfillment,
      delivery,
      datetimeFormatter: sdk.datetimeFormatter,
    },
  )
  const courierEvents = delivery?.courier_events || []

  const lastOrderStatus = getLastValue(order?.status_history)

  const lastEventName = courierEvents[0]?.name || lastOrderStatus?.status

  const isPickupDelivery = deliveryProvider === 'pickup'
  const isVendorDelivery = deliveryProvider === 'vendor_delivery'
  const isDeliveryEnded = [
    OrderStatuses.cancelled,
    RiderStatuses.canceled,
    RiderStatuses.delivered,
    RiderStatuses.rejected,
  ].includes(lastEventName as RiderStatuses)

  if (forRiderHistory && isDeliveryEnded) {
    return null
  }

  if (!estimatedDropOffTime || isPickupDelivery || isVendorDelivery) {
    return null
  }

  const promisedLabel = t('Order Widget.Tabs.Status.Order Statuses.Promised')
  const estimatedLabel = t('Order Widget.Tabs.Status.Order Statuses.Estimated')

  const formattedEstimatedTime = sdk.datetimeFormatter.formatTime(estimatedDropOffTime)
  const formattedPromisedTime = sdk.datetimeFormatter.formatTime(promisedDropOffTime)
  const formattedPromisedDate = sdk.datetimeFormatter.formatDate(promisedDropOffTime)

  const secondaryTime = [
    {
      text: formattedPromisedTime,
    },
  ]

  const diffInDays = differenceInDays(parseISO(promisedDropOffTime), parseISO(order.timestamp)) || 0

  const estimated: TimelineItem = {
    key: 'estimated_delivery',
    icon: LocationIcon,
    isMilestone: true,
    isDiffDay: formattedPromisedDate !== orderDate,
    status: {
      main: { text: estimatedLabel, fontWeight: '400', color: text.primary },
      secondary: [
        {
          text: promisedLabel,
          tooltip: updatedDeliveryTime && (
            <UpdatedDeliveryTime deliveryTime={updatedDeliveryTime} />
          ),
        },
      ],
    },
    time: {
      main: { diffInDays, text: formattedEstimatedTime },
      secondary: secondaryTime,
    },
  }

  if (delayTime) {
    estimated.status.secondary.push({ text: '' })
    estimated.time.secondary.push(delayTime)
  }

  const alreadyPickedup = delivery?.courier_events?.some(
    (event) => event.name === RiderStatuses.pickedUp,
  )

  if (!alreadyPickedup && forRiderHistory && delivery?.latest_estimated_pickup_time?.arriving_at) {
    estimated.status.secondary.push({
      text: t('Order Widget.Tabs.Status.Order Statuses.Commited pick up'),
    })

    estimated.time.secondary.push({
      text: sdk.datetimeFormatter.formatTime(delivery?.latest_estimated_pickup_time?.arriving_at),
    })
  }

  return estimated
}

export const getOrderPromisedStatusForTimeline = ({
  t,
  sdk,
  order,
  delivery,
  fulfillment,
}: Props) => {
  const deliveryProvider = order.delivery.provider
  const { delayTime, promisedDropOffTime } = calculateOrderDeliveryTimestamps({
    order,
    fulfillment,
    delivery,
    datetimeFormatter: sdk.datetimeFormatter,
  })
  const orderStatuses = order.status_history || []

  const promisedLabel = t('Order Widget.Tabs.Status.Order Statuses.Promised')

  const lastOrderStatus = getLastValue(orderStatuses)

  const isPickupDelivery = deliveryProvider === 'pickup'
  const isVendorDelivery = deliveryProvider === 'vendor_delivery'

  const orderIsInProgress = ![
    OrderStatuses.cancelled,
    OrderStatuses.delivered,
    OrderStatuses.rejected,
  ]
    .concat(isPickupDelivery ? [OrderStatuses.pickedUp] : [])
    .includes(lastOrderStatus.status)

  if (promisedDropOffTime && (isPickupDelivery || isVendorDelivery) && orderIsInProgress) {
    const formattedPromisedTime = sdk.datetimeFormatter.formatTime(promisedDropOffTime)
    const promised: TimelineItem = {
      key: 'promised_delivery',
      icon: LocationIcon,
      isCurrent: true,
      isMilestone: true,
      status: {
        main: { text: promisedLabel, color: text.primary },
        secondary: [
          {
            text: isVendorDelivery
              ? t('Order Widget.Tabs.Summary.Estimated not available for vendor delivery orders')
              : t('Order Widget.Tabs.Summary.Estimated not available for pickup orders'),
          },
        ],
      },
      time: {
        main: { text: formattedPromisedTime },
        secondary: [],
        extra: delayTime,
      },
    }
    return promised
  }

  return null
}
