export default {
  listItem: {
    border: 0,
  },
  collapsePanel: {
    background: '#fff',
    border: 0,
    marginLeft: '-15px',
  },
  collapsePanelHeaderContainer: {
    marginBottom: '-12px',
  },
  alternativeNumberContainer: {
    display: 'flex',
    flexDirection: 'column' as const,
    marginLeft: '24px',
    marginBottom: '-16px',
  },
}
