import React, { CSSProperties, MouseEventHandler } from 'react'
import { Button as AntButton } from 'antd'
import { UnifiedIcon, UnifiedIconProps } from '../UnifiedIcon'
import { ButtonType as AntdButtonType } from 'antd/lib/button'
import { ButtonType, ButtonColor } from 'types/unitedUiConfig'
import { SmartToolTip } from 'shared/SmartTooltip'
import { createUseStyles } from 'react-jss'
import classNames from 'classnames'

interface UseStylesOpts {
  borderRadius?: number
  borderColor?: string
  paddingLeft?: number | string
  marginLeft?: number | string

  height?: CSSProperties['height']
}

export interface ButtonProps extends UseStylesOpts {
  icon?: UnifiedIconProps['icon']
  type?: ButtonType
  color?: ButtonColor
  children?: React.ReactNode

  className?: string

  onClick?: MouseEventHandler<HTMLElement>

  disabled?: boolean

  tooltip?: string

  size?: 'large' | 'middle' | 'small'

  iconSize?: number

  loading?: boolean

  /**
   * turns the button display into block display
   */
  block?: boolean
}

const useStyles = createUseStyles({
  btn: (props?: UseStylesOpts) => {
    const { borderRadius, borderColor, paddingLeft, marginLeft, height } = props
    return {
      borderRadius:
        typeof borderRadius !== 'undefined' ? `${borderRadius}px !important` : undefined,
      borderColor: typeof borderColor !== 'undefined' ? `${borderColor} !important` : undefined,
      paddingLeft,
      marginLeft,
      height: height || undefined,
    }
  },

  linkBtn: {
    padding: 0,
  },
})

export const Button = ({
  children,
  type,
  color,
  icon,
  onClick,
  disabled,
  tooltip,
  className,
  size,
  borderColor,
  borderRadius,
  paddingLeft,
  marginLeft,
  iconSize = 14,
  loading,
  height,
  ...rest
}: ButtonProps) => {
  const styles = useStyles({ borderColor, borderRadius, paddingLeft, marginLeft, height })
  const config = {
    ghost: false,
    type: 'default' as AntdButtonType,
    danger: false,
  }

  switch (type) {
    case 'outlined':
      config.type = 'primary'
      config.ghost = true
      break

    case 'solid':
      config.type = 'primary'
      break

    case 'link':
      config.type = 'link'
      break
    case 'text':
      config.type = 'text'
      break
  }

  if (color === 'danger') {
    config.danger = true
  }

  return (
    <SmartToolTip title={tooltip}>
      <AntButton
        disabled={disabled}
        icon={<UnifiedIcon icon={icon} size={iconSize} />}
        {...config}
        onClick={onClick}
        className={classNames(styles.btn, className, {
          [styles.linkBtn]: type === 'link',
        })}
        children={children}
        size={size}
        loading={loading}
        {...rest}
      />
    </SmartToolTip>
  )
}
