import { UnifiedIconName } from 'contexts/IconPacksProvider'
import { PaymentMethods } from 'contexts/entity/types'
import { getPaymentMethodMeta } from 'utils/getPaymentMethodMeta'

export type PaymentType =
  | 'COD'
  | 'CASH'
  | 'balance'
  | 'Voucher'
  | 'credit_card'
  | 'reddot_paylah'
  | 'adyen_hpp_boost'
  | 'adyen_hpp_vipps'
  | 'adyen_hpp_swish'
  | 'antfinancial_bkash'
  | 'antfinancial_gcash'
  | 'cybersource_creditcard'
  | 'antfinancial_truemoney'
  | 'checkout_psp_online_banking'
  | 'No_Payment_Zero_Payable_Amount'

export const getPaymentTextAndIcon = (
  paymentType: PaymentType,
): { paymentIcon: UnifiedIconName; paymentLabel: string } => {
  const { icon, label } = getPaymentMethodMeta(paymentType as PaymentMethods)
  return { paymentLabel: label, paymentIcon: icon }
}
