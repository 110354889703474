const singleCommentCommon = {
  borderBottom: '1px solid #F0F0F0',
  padding: '5px 0px 16px 5px',
  marginTop: '5px',
  alignItems: 'center',
  display: 'grid',
  gridTemplateRows: '30px 1fr 30px',
  gridTemplateColumns: 'min-content 1fr min-content',
}

const singleCommentHolder = singleCommentCommon

const singleCommentHolderHighlighted = {
  ...singleCommentCommon,
  backgroundColor: '#F6FFED',
}

export default {
  orderHistoryContainer: {
    padding: '3px',
  },
  contentHolder: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.02)',
    padding: '7px',
  },
  headerHolder: {
    display: 'flex',
    alignItems: 'baseline',
    justifyContent: 'space-between',
  },
  eventsTitle: {
    fontSize: '14px',
    fontWeight: 600,
    color: 'rgba(0, 0, 0, 0.85)',
  },
  filterTitle: {
    fontSize: '12px',
    color: 'rgba(0, 0, 0, 0.45)',
    marginTop: '7px',
  },
  menuItem: {
    fontSize: '13px',
    borderBottom: '1px #F0F0F0 solid',
  },
  dropdownButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    fontSize: '14px',
    color: 'rgba(0, 0, 0, 0.65)',
    width: '70%',
  },
  dropdownIcon: {
    color: 'rgba(0, 0, 0, 0.25)',
  },
  commentsHolder: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    overflowY: 'auto',
  },
  singleCommentHolder,
  singleCommentHolderHighlighted,
  commentHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'baseline',
    marginBottom: '5px',
  },
  commentDateText: {
    color: 'rgba(0, 0, 0, 0.45)',
    fontSize: '11px',
    display: 'flex',
    justifyContent: 'flex-end',
    whiteSpace: 'nowrap',
  },
  commentText: {
    color: 'rgba(0, 0, 0, 0.85)',
    fontSize: '14px',
    padding: '4px 0px',
  },
  orderIdText: {
    color: '#1890FF',
    marginTop: '10px',
  },
}
