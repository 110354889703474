/**
 * initial entry point to React DOM
 * mounts App to the DOM tree and provides googleAuth, auth and api contexts
 *
 */

// libs
import React from 'react'
import ReactDOM from 'react-dom'
import { App } from 'App/App'
import { AppContainer } from 'Containers/AppContainer'

ReactDOM.render(
  <AppContainer>
    <App />
  </AppContainer>,
  document.getElementById('root'),
)
