import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios'
import adapter from 'axios/lib/adapters/http'
import React, { createContext, useMemo, useCallback } from 'react'
import { refreshJwt } from 'utils/refreshJwt'
import { attachRequestHeaders } from 'utils/oneviewApi/attachRequestHeaders'
import { logError } from 'utils/reporting/logError'
import { ApiErrorPayload } from 'types/error'
import { useReadSessionState } from 'hooks/useSessionState'
import { SessionState } from '../../contexts/session/types'
import { getBaseUrl } from './getBaseUrl'
import { useContextProvider } from 'contexts/useContextProvider'
import { AuthContextValue, useAuthProvider } from 'contexts/auth/AuthProvider'

import { setupCache } from 'axios-cache-adapter'

export type ApiNamespace =
  | 'AuthApi'
  | 'PaymentApi'
  | 'VoucherApi'
  | 'AdminApi'
  | 'OrdersApi'
  | 'CommentApi'
  | 'VendorApi'
  | 'FulfillmentApi'
  | 'CustomerApi'

export type ApiEndpointName =
  // admin api
  | 'getEntityConfig'
  | 'getPluginForRender'

  // auth apis
  | 'getPermissions'
  | 'getPermissionsV2'
  | 'refreshPermissions'
  | 'getTrackingToken'

  // voucher apis
  | 'getOrderCompensationsAndRefunds'
  | 'getCustomerCompensationsAndRefunds'
  | 'createVoucher'
  | 'getProposedCompensationValue'
  | 'getVoucherByCode'
  | 'getCustomerVouchers'

  // payment apis
  | 'createCompensationToWallet'
  | 'getOrderPurchaseDetails'
  | 'createRefund'
  | 'getOrderTransactions'
  | 'getCustomerOrderPayments'
  | 'getCustomerWallet'

  // orders apis
  | 'postOrderCancellation'
  | 'getOrder'
  | 'getOrderFlags'
  | 'getOrderStatusHistory'
  | 'reportMissingItems'
  | 'removeOrderItems'
  | 'patchDeliveryAddress'
  | 'patchDeliveryInstructions'
  | 'patchCookingInstructions'
  | 'patchDeliveryTime'
  | 'getBackOfficeOrderComments'
  | 'getLastOrders'

  // comment apis
  | 'postNewComment'
  | 'getCustomerComments'
  | 'getOrderComments'
  | 'getRiderComments'

  // vendor apis
  | 'addressInVendorDeliveryArea'
  | 'getVendor'

  // fulfillment api
  | 'getOrderFulfillment'
  | 'getProofOfDelivery'
  | 'putRiderOnBreak'
  | 'getChangeDeliveryStatusReasons'

  // customer api
  | 'getRiderChats'
  | 'getCustomerFraudStatus'
  | 'getCustomerFraudStatusV2'
  | 'getCustomerProfile'
  | string

  // 1000 is a custom one, used to reflect unexpected status code from apis
  | 1000

  // custom status code used to reflect unexpected data from api
  | 1001

export type ApiErrorType = 'internal' | 'global' | 'unknown' | 'widget'

export type ApiContext = 'Compensation' | 'Refund' | 'cancelOrder'

export interface ApiClientCreatorParams<DataType> {
  /**
   * request base url, if not given,
   * it defaults to oneview api root
   */
  baseUrl?: string

  /**
   * if explicitly set to true or false, aws api will be used or ignored
   */
  useAwsApi?: boolean

  config?: AxiosRequestConfig

  endpointName: ApiEndpointName

  /**
   * can be used to override the agent email that is attached to the request header
   */
  agentEmail?: string

  /**
   * defines if oneview header specifics should be attached to the request
   */
  attachHeaders?: boolean

  /**
   * indicates if refreshing of jwt should be carried out
   */
  validateJwt?: boolean

  /**
   * the context in which the api is being used.
   * this is used to target error messages as well keep
   * a collection of internal errors for the context
   *
   * if an internal error happens without an actionContext given,
   * it will not be recorded in the api
   */
  context?: ApiContext

  /**
   * translation parameters to be passed to translation
   * when constructing nice and readable messages for api errors
   */
  tParams?: Record<string, string | number>

  /**
   * used to mock the axios response
   */
  mockedResponse?: Partial<AxiosResponse<DataType>>

  /**
   * the expected response status code of the response, if expected code is not received,
   * it is treated as error
   */
  expectedResponseStatusCode?: number | number[]

  /**
   * if true, undefined or null values for res.data is treated as error
   */
  responseMustHaveData?: boolean

  /**
   * response validator
   * it should return true if validation succeeds,
   * error statusCode or error message if validation fails
   */
  validateData?: (data: DataType) => string | true | number

  onSuccess?: (data: Record<string, any>) => void

  /**
   * custom headers
   */
  headers?: Record<string, string>

  /**
   * by default, all api calls are not cacheable unless you say it is
   */
  cacheable?: boolean
}

export type ApiClientCreator<DataType = any> = (
  params: ApiClientCreatorParams<DataType>,
) => AxiosInstance

export type ApiClientCreatorFactory<DataType = any> = (opts?: {
  widgetId?: string
}) => ApiClientCreator<DataType>

export const ApiClientCreatorContext = createContext<{
  createClient: ApiClientCreator
  createClientFactory: ApiClientCreatorFactory
}>(undefined)

const clientCreatorFactory = (
  readSessionState: () => SessionState,
  readAuthState: AuthContextValue['readAuthState'],
  authDispatch: AuthContextValue['authDispatch'],
  opts?: {
    widgetId?: string
  },
): ApiClientCreator => {
  const { widgetId } = opts || {}
  const axiosCacheAdapter = setupCache({
    maxAge: 15 * 60 * 1000, // 15 minutes cache
    exclude: {
      // we do not exclude query parameters in the cache strategy
      query: false,
    },
  })

  return (params) => {
    const {
      attachHeaders = true,
      validateJwt: shouldValidateJwt = true,

      agentEmail: givenAgentEmail,

      context,
      tParams,

      config,

      mockedResponse,

      responseMustHaveData,
      expectedResponseStatusCode,
      validateData,

      baseUrl,
      headers,

      onSuccess,

      cacheable = false,

      useAwsApi,
    } = params

    const { uiVersion, globalEntityId } = readSessionState()
    const { agentEmail, jwt } = readAuthState()

    const onApiReject = (error: AxiosError) => {
      const { config = {}, message: errorMessage = '', response = {} as AxiosResponse } = error
      const { status = 400 } = response

      const data = response.data || ({} as any)

      const errorCode = data.code || ''
      const url = config.url

      let namespace: ApiNamespace

      if (/(\w+)API\//i.test(url)) {
        namespace = (RegExp.$1 + 'Api') as ApiNamespace
      }

      const payload: ApiErrorPayload = {
        namespace,
        endpointName: params.endpointName,
        statusCode: status,
        errorCode,
        errorMessage,
        context,

        tParams,
        techRef: '',
        id: Date.now().toString(),
      }

      payload.techRef =
        payload.namespace +
        '/' +
        payload.endpointName +
        ' HTTP ' +
        [payload.statusCode, payload.errorCode || ''].filter(Boolean).join('/')

      // Do not log 4xx errors except 400. ( IVU-2144 )
      if (payload.statusCode <= 400 || payload.statusCode >= 500) {
        logError({
          type: 'api-error',
          widget_id: widgetId,
          responsible_api: payload.namespace,
          responsible_api_endpoint: payload.endpointName,

          api_http_code: payload.statusCode,
          api_error_code: payload.errorCode || undefined,
          api_message: payload.errorMessage,

          // TODO: discuss what needs to be logged with manager
          // response_data: JSON.stringify(response.data || ''),

          // request_data: JSON.stringify(config.data || ''),
        })
      }

      return Promise.reject({
        errorPayload: payload,
        response: {
          ...response,
          status,
          statusText: errorMessage,
          config,
        },
      })
    }

    const client = axios.create({
      baseURL: baseUrl || getBaseUrl({ globalEntityId, useAwsApi }),

      adapter: (config) => {
        if (!mockedResponse) {
          return cacheable ? axiosCacheAdapter.adapter(config) : adapter(config)
        }

        mockedResponse.request = {
          responseURL: config.url,
        }

        const response: AxiosResponse = {
          config,
          request: mockedResponse.request || {},
          status: mockedResponse.status || 200,
          data: mockedResponse.data || {},
          headers: mockedResponse.headers || {},
          statusText: mockedResponse.statusText || 'OK',
        }

        if (response.status < 300) {
          return Promise.resolve(response)
        }

        return Promise.reject({ response, config, request: response.request })
      },

      ...config,
    })

    client.interceptors.request.use(async (config) => {
      if (attachHeaders) {
        attachRequestHeaders(config, {
          agentEmail: givenAgentEmail || agentEmail,
          jwt,
          uiVersion,
        })

        // to be renabled once issue is resolved
        // if (widgetId) {
        //   config.headers['X-WIDGET-ID'] = widgetId
        // }
      }

      if (headers) {
        config.headers = {
          ...config.headers,
          ...headers,
        }
      }

      // validate/refresh jwt if allowed
      if (shouldValidateJwt) {
        try {
          await refreshJwt(
            clientCreatorFactory(readSessionState, readAuthState, authDispatch, opts),
            authDispatch,
          )
        } catch (ex) {
          const error = new AxiosError(
            ex?.message || 'Token is not available. Do login, please.',
            '',
            config,
          )
          throw error
        }
      }
      return config
    })

    // process api errors
    client.interceptors.response.use((response) => {
      let code = ''
      let error = ''

      let status = response.status

      let allowedResponseStatusCodes: number[] = []

      if (response.request.fromCache) {
        // response came from our in memory cache
      }

      if (Array.isArray(expectedResponseStatusCode)) {
        allowedResponseStatusCodes = expectedResponseStatusCode
      } else if (expectedResponseStatusCode) {
        allowedResponseStatusCodes.push(expectedResponseStatusCode)
      }

      if (
        allowedResponseStatusCodes.length &&
        !allowedResponseStatusCodes.includes(response.status)
      ) {
        code = 'UNRECOGNIZED_SUCCESS_RESPONSE_STATUS'
        error = `Success response status is not recognised. Expected one of ${allowedResponseStatusCodes.join(
          ',',
        )} but got ${response.status}`
      } else if (responseMustHaveData && !response.data) {
        code = 'SUCCESS_RESPONSE_DATA_CANNOT_BE_EMPTY'
        error = 'Empty data found. Success response data cannot be empty'
      }

      if (!error && validateData) {
        const result = validateData(response.data)
        if (typeof result === 'string') {
          code = 'SUCCESS_RESPONSE_DATA_IS_NOT_VALID'
          error = result
        } else if (typeof result === 'number') {
          error = 'Failed with custom response status'
          status = result
        }
      }

      if (error) {
        return onApiReject({
          message: error,
          config: response.config,
          request: response.request,
          response: {
            status,
            statusText: response.statusText,
            headers: response.headers,
            config: response.config,
            request: response.request,
            data: {
              ...response.data,
              code,
            },
          },
        } as AxiosError)
      }

      if (onSuccess) {
        onSuccess(response?.data)
      }

      return response
    }, onApiReject)

    return client
  }
}

export const ApiClientCreatorProvider: React.FC = ({ children }) => {
  const readSessionState = useReadSessionState()
  const { readAuthState, authDispatch } = useAuthProvider()

  // never changes
  const creatorFactory = useCallback<ApiClientCreatorFactory>(
    (opts) => {
      return clientCreatorFactory(readSessionState, readAuthState, authDispatch, opts)
    },
    [readAuthState, readSessionState, authDispatch],
  )

  const creator = useMemo(() => {
    return clientCreatorFactory(readSessionState, readAuthState, authDispatch)
  }, [readAuthState, readSessionState, authDispatch])

  const value = useMemo(
    () => ({ createClient: creator, createClientFactory: creatorFactory }),
    [creator, creatorFactory],
  )

  return (
    <ApiClientCreatorContext.Provider value={value}>{children}</ApiClientCreatorContext.Provider>
  )
}

export const useApiClientCreator = () =>
  useContextProvider(ApiClientCreatorContext, 'ApiClientCreatorProvider')
