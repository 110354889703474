import {
  CheckCircleOutlined,
  CheckOutlined,
  CloseOutlined,
  CopyOutlined,
  WarningOutlined,
} from '@ant-design/icons'
import { Alert } from 'antd'
import classNames from 'classnames'
import { WidgetErrorHandler } from 'components/WidgetErrorHandler/widgetErrorHandler'

import { ApiErrorPayload } from 'types/error'
import { availableRefundMethods } from 'entityConfig/allowedConfigValues'
import { useTranslation } from 'hooks/useTranslation'
import React from 'react'
import { createUseStyles } from 'react-jss'
import { AvailableRefundMethod, RefundBreakdown, RefundTarget } from 'types/actions/partialRefund'
import styles from './Result.styles'
import { Box, Button, UnifiedIcon } from 'shared'

const useStyles = createUseStyles(styles)

interface BaseApiResult {
  type: 'voucher' | 'item-removal' | 'missing-items-report' | 'refund'
  status: 'success' | 'failed'
  title?: string
  icon?: React.ForwardRefExoticComponent<any>
  description: string
  errorPayload?: ApiErrorPayload

  /**
   * if provided, there will be a copyDetails button, which if clicked,
   * will trigger this handler
   * @returns
   */
  copyDetailsHandler?: () => void
}

export interface VoucherApiResult extends BaseApiResult {
  type: 'voucher'
  details?: {
    voucherCode: string
    currency: string
    refundAmount: number | string
  }
}

export interface ItemRemovalApiResult extends BaseApiResult {
  type: 'item-removal'
  details?: {
    bundlesCount: number
    itemsCount: number
    toppingsCount: number
  }
}

export interface MissingItemsReportApiResult extends BaseApiResult {
  type: 'missing-items-report'
  details?: {
    caseId: string
  }
}

export interface RefundApiResult extends BaseApiResult {
  type: 'refund'
  refundTarget: RefundTarget
  refundBreakdown?: RefundBreakdown[]
  details?: {
    refundAmount: string | number
    currency: string
  }
}

export type ApiResult =
  | VoucherApiResult
  | ItemRemovalApiResult
  | MissingItemsReportApiResult
  | RefundApiResult

export const apiResultReporter = {
  /**
   * construct voucher texts and report
   */
  reportVoucherCreation(
    results: ApiResult[],
    result: Pick<VoucherApiResult, 'errorPayload' | 'status' | 'details' | 'copyDetailsHandler'>,
  ) {
    if (result.status === 'failed') {
      results.push({
        type: 'voucher',
        ...result,
        title: `Could'nt create voucher`,
        description: `Could'nt create voucher due to error`,
      })
    } else {
      results.push({
        type: 'voucher',
        ...result,
        title: 'Voucher created successfully',
        description: `refunded_with_voucher`,
      })
    }
    return results
  },

  /**
   * construct refund report texts and report
   */
  reportRefund(
    results: ApiResult[],
    result: Pick<
      RefundApiResult,
      'errorPayload' | 'status' | 'refundBreakdown' | 'copyDetailsHandler' | 'refundTarget'
    >,
  ) {
    if (result.status === 'failed') {
      results.push({
        type: 'refund',
        ...result,
        title:
          result.refundTarget === RefundTarget.Ticket
            ? `create_refund_ticket_failed`
            : `create_refund_failed`,
        description:
          result.refundTarget === RefundTarget.Ticket
            ? `create_refund_ticket_failed`
            : `create_refund_failed`,
      })
    } else {
      ;(result.refundBreakdown || []).forEach((breakdown) => {
        const details = {
          currency: breakdown.amount.currency,
          refundAmount: breakdown.amount.amount,
        }

        switch (breakdown.method) {
          case 'SOURCE':
            results.push({
              type: 'refund',
              ...result,
              description: `refunded_to_source`,
              details,
            })
            break

          case 'WALLET':
            results.push({
              type: 'refund',
              ...result,
              description: `refunded_to_wallet`,
              details,
            })
            break

          case 'TICKET':
            results.push({
              type: 'refund',
              ...result,
              description: `created_refund_ticket`,
              details,
            })
            break

          case 'VOUCHER':
            results.push({
              type: 'refund',
              ...result,
              description: `refunded_with_voucher`,
              details,
            })
            break

          case 'GIFT_CARD':
            results.push({
              type: 'refund',
              ...result,
              description: `refunded_to_gift_card`,
              details,
            })
            break
        }
      })
    }
    return results
  },

  noRefund(
    results: ApiResult[],
    result: Pick<
      RefundApiResult,
      'errorPayload' | 'status' | 'details' | 'refundBreakdown' | 'refundTarget'
    >,
  ) {
    results.push({
      type: 'refund',
      ...result,
      title: result.status === 'failed' ? `Error during process` : 'Nothing was refunded',
      description: result.status === 'failed' ? `Error during process` : `Nothing was refunded`,
    })
    return results
  },

  /**
   * construct item removal texts and report
   */
  reportItemRemoval(
    results: ApiResult[],
    result: Pick<ItemRemovalApiResult, 'errorPayload' | 'status' | 'details'>,
  ) {
    results.push({
      type: 'item-removal',
      ...result,
      title: result.status === 'failed' ? `Couldn't remove items` : 'Items removed successfully',
      description:
        result.status === 'failed'
          ? `Couldn't remove {{itemsCount}} X items due to error`
          : `{{itemsCount}} X item and {{toppingsCount}} X topping removed`,
    })
    return results
  },

  /**
   * construct missing items report texts and report
   */
  reportMissingItemsReport(
    results: ApiResult[],
    result: Pick<MissingItemsReportApiResult, 'errorPayload' | 'status' | 'details'>,
  ) {
    results.push({
      type: 'missing-items-report',
      ...result,
      title:
        result.status === 'failed'
          ? `Couldn't create wastage case`
          : 'Created wastage case successfully',
      description:
        result.status === 'failed'
          ? `Couldn't create wastage case due to error`
          : `Vendor wastage case created`,
    })
    return results
  },
}

const Notice = ({ notice }: { notice: string }) => {
  const cx = useStyles({ width: 0 })
  return (
    <Alert
      message={notice}
      type='info'
      showIcon
      className={cx.notice}
      icon={<UnifiedIcon icon='InfoCircleOutlined' size={14} />}
    />
  )
}

export const Result = ({
  results,
  onClose,
  selectedRefundMethod,
  createdRefundStatus,
  refundBreakdown,
}: {
  results: ApiResult[]
  onClose: () => void
  selectedRefundMethod: AvailableRefundMethod
  createdRefundStatus: number
  refundBreakdown: RefundBreakdown[]
}) => {
  const errorCount = results.reduce((count, current) => {
    if (current.status === 'failed') {
      return count + 1
    }
    return count
  }, 0)

  const firstError = results.find((current) => current.status === 'failed')

  const isPartialSuccess = errorCount > 0

  const { t } = useTranslation()
  const cx = useStyles({
    width: isPartialSuccess ? 518 : 590,
  })

  // a ticket refund is not a proper refund, it basically creates a ticket to perform the refund later
  const properRefundsBreakdown = refundBreakdown.filter((current) => current.method !== 'TICKET')

  const partOfRefundDivertedToDifferentTarget =
    properRefundsBreakdown.length > 1 ||
    (properRefundsBreakdown.length === 1 &&
      properRefundsBreakdown[0].method !== selectedRefundMethod.method.toUpperCase())

  if (results.length === 0) {
    return null
  }

  let icon, heading

  if (isPartialSuccess) {
    icon = <WarningOutlined style={{ fontSize: '90px', color: '#FAAD14' }} />
    if (errorCount === 1) {
      heading = firstError.title
    } else {
      heading = `Couldn't complete multiple steps`
    }
  } else {
    icon = <CheckCircleOutlined style={{ fontSize: '90px', color: '#52C41A' }} />
    heading = 'Success'
  }

  const successCodes = [201, 202]

  const notices = []

  if (selectedRefundMethod?.method === availableRefundMethods.voucher) {
    notices.push(
      <Notice
        notice={t(
          'Actions Widget.Actions.Full Refund.The customer will receive the compensation voucher immediately',
        )}
        key={'voucher-notice'}
      />,
    )
  }

  if (
    selectedRefundMethod?.method === availableRefundMethods.bankAccount &&
    successCodes.includes(createdRefundStatus)
  ) {
    notices.push(
      <Notice
        notice={t(
          `Messages.Refund timeframe depends on number of cases in refund queue and customer's financial provider`,
        )}
        key={'refund-timefram-notice'}
      />,
      <Notice
        key={'bankaccount-voucher-notice'}
        notice={t(`Messages.The customer will receive the compensation voucher immediately`)}
      />,
    )
  }

  return (
    <div className={cx.container}>
      <div className={cx.header}>
        {icon}
        <h3 className={cx.heading}>{t(`Actions Widget.Actions.Partial Refund.${heading}`)}</h3>
      </div>

      {partOfRefundDivertedToDifferentTarget && (
        <div className={cx.mb16}>
          <Alert
            message={t(
              `Actions Widget.Actions.Partial Refund.part_of_refund_diverted_to_different_target`,
            )}
            showIcon
            type='warning'
          />
        </div>
      )}

      <Box
        display='flex'
        justifyContent={partOfRefundDivertedToDifferentTarget || notices.length ? 'left' : 'center'}
        mb={32}
      >
        <div>
          {results.map((result, index) => {
            const defaultIcon = result.status === 'success' ? CheckOutlined : CloseOutlined
            const Icon = result.icon ?? defaultIcon

            return (
              <p
                key={index}
                className={classNames(cx.itemDescription, {
                  error: result.status === 'failed',
                })}
              >
                <span className={cx.mr8}>
                  <Icon />
                </span>
                {t(`Actions Widget.Actions.Partial Refund.${result.description}`, {
                  useLastKeyAsFallback: true,
                  replace: result.details || {},
                })}

                {/* copy details button */}
                {result.copyDetailsHandler ? (
                  <Button
                    type='link'
                    onClick={result.copyDetailsHandler}
                    height={22}
                    marginLeft={8}
                  >
                    <CopyOutlined />
                    {t('Actions Widget.Actions.Full Refund.Copy Details')}
                  </Button>
                ) : null}
              </p>
            )
          })}
        </div>
      </Box>

      {isPartialSuccess && (
        <div>
          {results
            .filter((result) => result.status === 'failed' && result.errorPayload)
            .map((result, idx) => {
              return (
                <div key={idx}>
                  <WidgetErrorHandler errorPayload={result.errorPayload} displayType='mini' />
                </div>
              )
            })}
        </div>
      )}

      <div className={classNames(cx.mt8)}>{notices}</div>

      <div className={cx.buttonsContainer}>
        <Button type='solid' color='primary' onClick={onClose}>
          {t('Interface.OK')}
        </Button>
      </div>
    </div>
  )
}
