import { CSSProperties } from 'react'
import { createUseStyles } from 'react-jss'

import unitless from '@emotion/unitless'
import camelCase from 'lodash/camelCase'

export const addUnitIfNeeded = (name: string, value: any): any => {
  if (typeof value === 'number' && value !== 0 && !(camelCase(name) in unitless)) {
    return `${value}px` // Presumes implicit 'px' suffix for unitless numbers
  }
  return value
}

export const createDeclarativeUseStyle = <K extends string>(
  declarations: Array<
    | K
    | {
        key: K
        properties: Array<keyof CSSProperties> | keyof CSSProperties
        important?: boolean
      }
  >,
) => {
  return createUseStyles<'className', { [p in K]: string | number }>({
    className: (options) => {
      const result: CSSProperties = {}
      declarations.forEach((styleDescriptor) => {
        let key,
          properties = [],
          important = false

        if (typeof styleDescriptor === 'string') {
          key = styleDescriptor
          properties = [styleDescriptor]
        } else {
          key = styleDescriptor.key
          important = styleDescriptor.important

          properties = Array.isArray(styleDescriptor.properties)
            ? styleDescriptor.properties
            : [styleDescriptor.properties]
        }

        const value = options[key]

        if (value === undefined || value === null || value === '' || typeof value === 'boolean') {
          return
        }

        properties.forEach((property) => {
          result[property as any] =
            addUnitIfNeeded(property, value) + (important ? ' !important' : '')
        })
      })

      return result
    },
  })
}
