export default {
  list: {
    padding: '8px 16px',
    '& .ant-list-item-meta-content': {
      display: 'flex',
      flexDirection: 'column-reverse',
    },
    '& .ant-list-item-meta-title': {
      color: '#FF4D4F',
    },
  },
}
