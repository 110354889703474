import React from 'react'
import { Col, Row } from 'antd'
import useTableStyles from './table.styles'
import { useTranslation } from 'hooks/useTranslation'

const TableHeader = ({
  isToppingtotalPriceAvailable,
}: {
  isToppingtotalPriceAvailable: boolean
}) => {
  const { t } = useTranslation()
  const classes = useTableStyles()
  return (
    <Row className={classes.tableHeader} data-test-id='order-item-header'>
      <Col span={11}>{t('Order Widget.Tabs.Items.Item')}</Col>
      <Col span={4}>{t('Order Widget.Tabs.Items.Item Price')}</Col>
      <Col span={4}>
        {isToppingtotalPriceAvailable && t('Order Widget.Tabs.Items.Toppings Price')}
      </Col>
      <Col span={4}>{t('Order Widget.Tabs.Items.Total Price')}</Col>
      <Col span={1}></Col>
    </Row>
  )
}

export default TableHeader
