import { OrderStatuses } from 'types/widgets/order/orderStatuses'
import { FulfillmentApiResponse } from '../fulfillmentApi/fulfillment'
import { OrderSingleBasketUpdate } from './orderBasketUpdate'

export type OrderCollectionApiResponse = Array<OrderApiResponse>

export type OrderDeliveryProvider =
  | 'pickup'
  | 'platform_delivery'
  | 'vendor_delivery'
  | 'partner_delivery'

export type RestorableItems = Record<string, { quantity: number }>

export interface OrderApiResponse {
  /**
   * indicates order payment confirmation is still pending,
   *
   * i.e, payment has not been confirmed
   */
  pending?: boolean
  ov_meta_data?: {
    order_number?: string
    config_id?: string
  }
  order_id: string
  brand_name: string
  global_entity_id: string
  country_code: string
  locale: string
  timestamp: string
  place_timestamp: string
  eligibilities?: string[]
  promised_customer_timestamp: string
  currency: string
  business_type: string
  vendor: Vendor
  customer: CustomerApiResponse
  commission: string
  subsidies: any
  order: {
    minimum_order_value?: number
    deposit?: number
    order_value?: number
    tax: {
      tax_rate_percent?: number
      net_amount?: number
      amount: number
    }
    items: Array<ItemsItem>
    known_total_mismatch?: string
  }
  status_history: OrderHistory
  delivery: {
    tip?: number
    delivery_fee?: number
    priority_fee?: number
    tax?: number | DeliveryTax
    location: DeliveryLocation
    provider: OrderDeliveryProvider
    delivery_instructions_tags?: string[]
    packaging_charges?: {
      amount: number
    }
  }
  corporate: string
  preorder: string
  test_order: string
  basket_updates?: null | OrderSingleBasketUpdate[]
  restorable_items?: null | RestorableItems
  order_updates?: any
  order_weight?: {
    value: number
    unit: string
  }

  addons?: {
    wallet_amount?: number
    subscriptions?: Array<{
      subscription_id?: string
      wallet_amount?: number
      amount_after_incentives_gross?: number
    }>
  }

  fulfillment: FulfillmentApiResponse
}

export interface OrderFlagsApiResponse {
  status: number
  value: number
  is_billable: boolean
  is_wastage: boolean
  is_manual: boolean
  is_receiptable: boolean
}

export interface DeliveryTax {
  tax_rate_percent?: number
  net_amount?: number
  amount?: number
}

export interface CustomerApiResponse {
  customer_id: string
  profile: CustomerProfile
  channel: CustomerChannel
  payment: Payment
  eligibilities?: string[]
  notifications: {
    opted_out: string
    braze: {
      id: string
    }
  }
}

export interface CustomerProfile {
  id: string
  guest: string
  locale: string
  first_name: string
  last_name: string
  name: string
  email: string
  phone: string
}

export interface CustomerChannel {
  type: string
}

export interface Vendor {
  id: string
  name: string
  charges?: VendorCharges
  global_vendor_id: string
  eligibilities?: string[]
  location: {
    latitude?: number
    longitude?: number
  }
}

export interface VendorCharges {
  joker_fee?: number
  joker_discount_fee?: number
  service_fee?: number
  discount_fee?: number
  payment_fee?: number
}

export interface DeliveryLocation {
  id: string
  label?: string
  address_text: string
  street?: string
  street_number?: string
  building?: string
  district?: string
  description?: string
  postal_code: string
  city: string
  block?: string
  latitude: number
  longitude: number
  geohash: string
  entrance?: string
  unit?: string
  intercom?: string
  floor?: string
}

export interface OrderHistoryItem {
  order_id?: string
  timestamp?: string
  status: OrderStatuses
  transmitted?: {
    global_transmission_method_id?: string
    type?: string
    version?: string
  }
  accepted?: {
    estimated_delivery_time: string
    source?: string
    transmission?: {
      provider: string
      method: string
      application_client: string
      client?: {
        os_type: string
        os_version: string
        app_type: string
        app_version: string
        wrapper_type: string
        wrapper_version: string
      }
    }
    comment?: string
  }
  picked_up?: {
    timestamp: string
    stage?: string
  }
  delivered?: {
    timestamp: string
  }
  expired?: {
    reason: string
  }
  pending_order_failed?: {
    reason?: string
    source?: string
  }
  cancelled?: {
    reason?: string
    comment?: string
    details?: {
      owner: string
      reason?: string
      stage?: string
      source?: string
      is_compensatable?: boolean
      is_refundable?: boolean
    }
    transmission?: {
      provider: string
      method: string
      application_client: string
      client?: {
        os_type: string
        os_version: string
        app_type: string
        app_version: string
        wrapper_type: string
        wrapper_version: string
      }
    }
  }
  rejected?: {
    reason: string
    comment?: string
  }
  failed?: any
  delayed?: any
  sent_to_transmission?: any
  reaching_transmission_failed?: any
  sending_to_vendor?: any
  displayed_at_vendor?: any
  is_billable?: boolean
}

export type OrderHistory = Array<OrderHistoryItem>

export interface ItemImageUrl {
  url: string
  size: {
    width: number
    height: number
  }
}

export interface ItemAttributeName {
  value: string
  locale: string
}

export type ItemAttributeType =
  | 'taste'
  | 'choice'
  | 'cuisine'
  | 'dietary'
  | 'category'
  | 'food_type'
  | 'ingredient'
  | 'menu_category'
  | 'characteristic'
  | 'master_category'
  | 'preparation_style'

export interface ItemAttribute {
  name: string
  attribute_id: string
  names: ItemAttributeName[]
  attribute_type: ItemAttributeType
}

export type ItemFeature = 'sold_by_weight'

export interface OrderItemLocalizedName {
  locale: string
  value: string
}

export interface ItemsItem {
  id: string
  is_bundle?: boolean
  line_item_id?: string
  features?: ItemFeature[]
  original_unit_price?: number
  original_weight?: {
    unit?: string
    value?: number
  }
  weight?: {
    unit?: string
    value?: number
  }
  name: string
  names?: Array<OrderItemLocalizedName>
  display_name: string
  customer_notes: string
  unit_price: number
  requirement?: string
  image_urls?: ItemImageUrl[]
  attributes?: ItemAttribute[]
  product_parent?: {
    name: string
    product_parent_id: string
  }
  options?: Array<ItemOption>

  /**
   * unit quantity
   */
  quantity: number
}

interface Payment {
  payment_method: string
  paid: string
  transaction_number: string

  total_value?: number
  total_order_value: number
  original_total_order_value?: number
  total_tax: string
  coupon: number
  discount: number
  minimum_order_value_fee: number
  wallet_balance: number
  giftcard_amount: number
  loyalty_point_balance: number
  service_fee: number
  payment_provider: string
  allowance_amount: number
  coupons_info: any
}

export interface ItemOption {
  id: string
  name: string
  names?: Array<OrderItemLocalizedName>
  quantity: number
  customer_notes?: string
  type: 'bundle_item' | 'topping'
  unit_price?: number
  image_urls?: ItemImageUrl[]
  attributes?: ItemAttribute[]
  options?: Array<ItemOption>
}

// Modify address
export interface ModifyAddressPostBody {
  instructions?: string
  formatted_address?: string
}

export interface RefundsCompensationsApiResponse {
  refunds: RefundDetailsWithTransactions[]
  compensations: any[]
}

export interface RefundDetailsWithTransactions {
  description: string
  event_id: string
  order_id: string
  voucher_id: string
  processed_timestamp: string
  payment_medium: PaymentMedium
  refund_value: number
  currency_code: string
  refund_items_count?: number
  contact_id: string
  contact_type: string
  refund_purpose: string
  status: RefundStatus
  transactions: RefundTransaction[]
}

export enum PaymentMedium {
  PSP_REFUND = 'SOURCE',
  WALLET_REFUND = 'WALLET',
  GIFT_CARD = 'GIFT_CARD',
  VOUCHER = 'VOUCHER',
  NOT_DEFINED_YET = 'NOT_DEFINED_YET',
}

export enum RefundPurpose {
  ITEM_REFUND = 'item_refund',
  ORDER_REFUND = 'order_refund',
}

export type RefundStatus =
  | 'created'
  | 'success'
  | 'failed'
  | 'unknown'
  | 'requested'
  | 'reversed'
  | 'fraud_refusal'
  | 'invalid_amount'
  | 'invalid_status'
  | 'no_payments_to_refund'
  | 'rejected_by_gateway'
  | 'in_progress'
  | 'ask_user'
  | 'pending_manual'
  | 'pending_retry'

export type TransactionStatus = 'created' | 'pending' | 'success' | 'unknown' | 'failure'

/**
 * this is the displayed payment statuses
 */
export type TransactionStatusDisplay =
  | 'paid'
  | 'unknown'
  | 'failed'
  | 'aborted'
  | 'pending'
  | 'reserved'

export interface RefundTransaction {
  timestamp: string
  payment_id: string
  payment_type: PaymentType
  amount: TransactionAmount
  status: TransactionStatus
  parent_payment_id: string
  payment_method: string
  payment_service_provider: string
  payment_metadata: (string | number | boolean | object)[]
}

export enum PaymentType {
  REFUND = 'Refund',
  REVERSAL = 'Reversal',
}

export interface TransactionAmount {
  amount?: number
  currency?: string
}

export interface OrderTransactionApiResponse {
  transactions: OrderTransaction[]
}

export interface OrderTransaction {
  global_entity_id: string
  timestamp: string
  country_code: string
  payment_id: string
  payment_type: GlobalPaymentType
  payment_owner: PaymentOwner
  amount: AmountInterface
  status: TransactionStatus
  parent_payment_id?: string
  payment_method: string
  payment_service_provider: string
  payment_metadata?: object
}

export enum GlobalPaymentType {
  AUTHORIZATION = 'Authorization',
  CAPTURE = 'Capture',
  PAYMENT = 'Payment',
  REFUND = 'Refund',
  PREAUTHORIZATION = 'Preauthorization',
  REVERSAL = 'Reversal',
  CREDIT = 'Credit',
  EXTERNAL_PAYMENT = 'ExternalPayment',
}

export interface PaymentOwner {
  type: string
  id: string
}

export interface AmountInterface {
  amount?: number
  currency?: string
}
