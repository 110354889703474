import { PaymentMethods } from 'contexts/entity/types'
import { OrderApiResponse } from 'types/herocare'
import { getOrderPaymentBreakdown } from './getOrderPaymentBreakdown'
import { UnifiedIconName } from 'contexts/IconPacksProvider'
import { getPaymentMethodMeta } from 'utils/getPaymentMethodMeta'

type PaymentInfo = {
  label: string
  payment_method: string
  icon: UnifiedIconName
  amount: number
}

export const getSecondaryPaymentMethods = (order: OrderApiResponse): PaymentInfo[] => {
  const paymentMethods: PaymentInfo[] = []

  const { walletValue, giftCardValue, voucherValue, allowanceValue } =
    getOrderPaymentBreakdown(order)

  const { payment_method } = order?.customer?.payment ?? {}

  const mainPaymentMethodIsNotWallet =
    payment_method !== PaymentMethods.balance && payment_method !== PaymentMethods.WALLET

  if (walletValue && mainPaymentMethodIsNotWallet) {
    paymentMethods.push({
      amount: walletValue,
      payment_method: PaymentMethods.WALLET,
      ...getPaymentMethodMeta(PaymentMethods.WALLET),
    })
  }

  if (giftCardValue && payment_method !== PaymentMethods.gift_card) {
    paymentMethods.push({
      amount: giftCardValue,
      payment_method: PaymentMethods.gift_card,
      ...getPaymentMethodMeta(PaymentMethods.gift_card),
    })
  }

  if (voucherValue && payment_method !== PaymentMethods.No_Payment_Zero_Payable_Amount) {
    paymentMethods.push({
      amount: voucherValue,
      payment_method: PaymentMethods.No_Payment_Zero_Payable_Amount,
      ...getPaymentMethodMeta(PaymentMethods.No_Payment_Zero_Payable_Amount),
    })
  }

  if (allowanceValue && payment_method !== PaymentMethods.Invoice) {
    paymentMethods.push({
      amount: allowanceValue,
      payment_method: PaymentMethods.Invoice,
      ...getPaymentMethodMeta(PaymentMethods.Invoice),
    })
  }

  return paymentMethods
}
