import React from 'react'
import { Typography } from 'antd'
import { createUseStyles } from 'react-jss'
import { useTranslation } from 'hooks/useTranslation'
import { NewCookingInstruction, SingleComment } from 'types/api/commentApi/getOrderComments'
import styles from './Comments.styles'
import { commentTagType, CommentView } from './CommentView'
import { WidgetSize } from 'types/widgets/historyOfEvents/tabs'

const { Text } = Typography

const useStyles = createUseStyles(styles)

type Props = {
  comment: SingleComment
  shouldCommentBeHighlighted: boolean
  mode: WidgetSize
}

const CookingInstructionComment = ({ comment, shouldCommentBeHighlighted, mode }: Props) => {
  const classes = useStyles()
  const { t } = useTranslation()
  return (
    <CommentView
      mode={mode}
      comment={comment}
      shouldCommentBeHighlighted={shouldCommentBeHighlighted}
      tag={{
        type: commentTagType.thunderbolt,
        text: t('History of Events Widget.Event Types.Change Cooking Instructions'),
      }}
    >
      <Text className={classes.commentText}>
        <Text>
          {` ${`${t('History of Events Widget.Content.Changed cooking instructions to')} `}`}
        </Text>
        <Text strong>
          {comment.additional_data.modify_cooking_instructions.new.reduce(
            (acc: string, i: NewCookingInstruction, idx: number) => {
              if (idx === 0) return i.comment
              acc = `${acc}, ${i.comment}`
              return acc
            },
            '',
          )}
        </Text>
      </Text>
    </CommentView>
  )
}

export default CookingInstructionComment
