import React from 'react'
import { Typography } from 'antd'
import { createUseStyles } from 'react-jss'
import { useTranslation } from 'hooks/useTranslation'
import { SingleComment } from 'types/api/commentApi/getOrderComments'
import capitalizeFirstLetters from 'utils/capitalizeFirstLetters'
import FullItemDetails from '../../widgets/HistoryOfEvents/OrderHistory/ItemDetails/FullItemDetails'
import styles from './Comments.styles'
import { commentTagType, CommentView } from './CommentView'
import { WidgetSize } from 'types/widgets/historyOfEvents/tabs'

const { Text } = Typography

const useStyles = createUseStyles(styles)

type Props = {
  comment: SingleComment
  shouldCommentBeHighlighted: boolean
  mode: WidgetSize
}

const RemoveItemsComment = ({ comment, shouldCommentBeHighlighted, mode }: Props) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <CommentView
      mode={mode}
      comment={comment}
      shouldCommentBeHighlighted={shouldCommentBeHighlighted}
      tag={{
        type: commentTagType.thunderbolt,
        text: t('History of Events Widget.Event Types.Remove Items'),
      }}
    >
      <Text className={classes.commentText}>
        <Text>{` ${t('History of Events Widget.Content.Removed')} `}</Text>

        {!comment?.additional_data?.remove_items?.removed_items?.length ? null : (
          <FullItemDetails
            items={comment.additional_data.remove_items.removed_items}
            withoutQuantity
          />
        )}

        {comment?.additional_data?.remove_items?.contact_reason &&
          `${t('Actions Widget.Actions.Compensation.Issue Type')}: ${capitalizeFirstLetters(
            comment.additional_data.remove_items.contact_reason.replace(/_/g, ' '),
          )}.`}
      </Text>
    </CommentView>
  )
}

export default RemoveItemsComment
