export type CountryRegion = 'asia' | 'us' | 'eu' | 'mena' | 'talabat'
export type Country = {
  entityId: string
  entityName: string
  callingCode: string
  regions: CountryRegion[]
}
export const LAOS: Country = {
  entityId: 'FP_LA',
  entityName: 'Laos',
  regions: ['asia'],
  callingCode: '856',
}
export const PARAGUAY: Country = {
  entityId: 'PY_PY',
  entityName: 'Paraguay',
  regions: ['us'],
  callingCode: '595',
}
export const JORDAN: Country = {
  entityId: 'TB_JO',
  entityName: 'Jordan',
  regions: ['mena', 'eu', 'talabat'],
  callingCode: '962',
}
export const IRAQ: Country = {
  entityId: 'TB_IQ',
  entityName: 'Iraq',
  regions: ['mena', 'eu', 'talabat'],
  callingCode: '964',
}
export const KUWAIT: Country = {
  entityId: 'TB_KW',
  entityName: 'Kuwait',
  regions: ['mena', 'eu', 'talabat'],
  callingCode: '965',
}
export const OMAN: Country = {
  entityId: 'TB_OM',
  entityName: 'Oman',
  regions: ['mena', 'eu', 'talabat'],
  callingCode: '968',
}
export const HS_BAHRAIN: Country = {
  entityId: 'HS_BH',
  entityName: 'Bahrain',
  regions: ['mena', 'eu'],
  callingCode: '973',
}
export const TB_BAHRAIN: Country = {
  entityId: 'TB_BH',
  entityName: 'Bahrain',
  regions: ['mena', 'eu', 'talabat'],
  callingCode: '973',
}
const countries: Country[] = [
  {
    entityId: 'FP_JP',
    entityName: 'Japan',
    regions: ['asia'],
    callingCode: '81',
  },
  {
    entityId: 'FP_PK',
    entityName: 'Pakistan',
    regions: ['asia'],
    callingCode: '92',
  },
  {
    entityId: 'FP_BD',
    entityName: 'Bangladesh',
    regions: ['asia'],
    callingCode: '880',
  },
  {
    entityId: 'FP_MM',
    entityName: 'Myanmar',
    regions: ['asia'],
    callingCode: '95',
  },
  {
    entityId: 'FP_TH',
    entityName: 'Thailand',
    regions: ['asia'],
    callingCode: '66',
  },
  {
    entityId: 'FP_KH',
    entityName: 'Cambodia',
    regions: ['asia'],
    callingCode: '855',
  },
  { ...LAOS },
  {
    entityId: 'FP_MY',
    entityName: 'Malaysia',
    regions: ['asia'],
    callingCode: '60',
  },
  {
    entityId: 'FP_SG',
    entityName: 'Singapore',
    regions: ['asia'],
    callingCode: '65',
  },
  {
    entityId: 'TEST_VA',
    entityName: 'E2E Test Country',
    regions: ['asia'],
    callingCode: '65',
  },
  {
    entityId: 'FP_HK',
    entityName: 'Hong Kong',
    regions: ['asia'],
    callingCode: '852',
  },
  {
    entityId: 'FP_TW',
    entityName: 'Taiwan',
    regions: ['asia'],
    callingCode: '886',
  },
  {
    entityId: 'FP_PH',
    entityName: 'Philippines',
    regions: ['asia'],
    callingCode: '63',
  },
  {
    entityId: 'BM_VN',
    entityName: 'Vietnam',
    regions: ['asia'],
    callingCode: '84',
  },
  {
    entityId: 'AP_PA',
    entityName: 'Panama',
    regions: ['us'],
    callingCode: '507',
  },
  {
    entityId: 'PY_AR',
    entityName: 'Argentina',
    regions: ['us'],
    callingCode: '54',
  },
  {
    entityId: 'PY_BO',
    entityName: 'Bolivia',
    regions: ['us'],
    callingCode: '591',
  },
  {
    entityId: 'PY_CL',
    entityName: 'Chile',
    regions: ['us'],
    callingCode: '56',
  },
  {
    entityId: 'PY_CR',
    entityName: 'Costa Rica',
    regions: ['us'],
    callingCode: '506',
  },
  {
    entityId: 'PY_DO',
    entityName: 'Dominican Republic',
    regions: ['us'],
    callingCode: '1',
  },
  {
    entityId: 'PY_EC',
    entityName: 'Ecuador',
    regions: ['us'],
    callingCode: '593',
  },
  {
    entityId: 'PY_GT',
    entityName: 'Guatamela',
    regions: ['us'],
    callingCode: '502',
  },
  {
    entityId: 'PY_HN',
    entityName: 'Honduras',
    regions: ['us'],
    callingCode: '504',
  },
  {
    entityId: 'PY_NI',
    entityName: 'Nicaragua',
    regions: ['us'],
    callingCode: '505',
  },
  {
    entityId: 'PY_PE',
    entityName: 'Peru',
    regions: ['us'],
    callingCode: '51',
  },
  { ...PARAGUAY },
  {
    entityId: 'PY_SV',
    entityName: 'Salvador',
    regions: ['us'],
    callingCode: '503',
  },
  {
    entityId: 'PY_UY',
    entityName: 'Uruguay',
    regions: ['us'],
    callingCode: '598',
  },
  {
    entityId: 'PY_VE',
    entityName: 'Venezuela',
    regions: ['us'],
    callingCode: '58',
  },
  {
    entityId: 'DJ_CZ',
    entityName: 'Czech Republic',
    regions: ['eu'],
    callingCode: '420',
  },
  {
    entityId: 'FO_NO',
    entityName: 'Norway',
    regions: ['eu'],
    callingCode: '47',
  },
  {
    entityId: 'FP_BG',
    entityName: 'Bulgaria',
    regions: ['eu'],
    callingCode: '359',
  },
  {
    entityId: 'FP_DE',
    entityName: 'Germany',
    regions: ['eu'],
    callingCode: '49',
  },
  {
    entityId: 'FP_RO',
    entityName: 'Romania',
    regions: ['eu'],
    callingCode: '40',
  },
  {
    entityId: 'MJM_AT',
    entityName: 'Austria',
    regions: ['eu'],
    callingCode: '43',
  },
  {
    entityId: 'NP_HU',
    entityName: 'Hungary',
    regions: ['eu'],
    callingCode: '36',
  },
  {
    entityId: 'OP_SE',
    entityName: 'Sweden',
    regions: ['eu'],
    callingCode: '46',
  },
  {
    entityId: 'HN_DK',
    entityName: 'Denmark',
    regions: ['eu'],
    callingCode: '45',
  },
  {
    entityId: 'PO_FI',
    entityName: 'Finland',
    regions: ['eu'],
    callingCode: '358',
  },
  {
    entityId: 'EF_GR',
    entityName: 'Greece',
    regions: ['eu'],
    callingCode: '30',
  },
  {
    entityId: 'FY_CY',
    entityName: 'Cyprus',
    regions: ['eu'],
    callingCode: '357',
  },
  {
    entityId: 'FP_SK',
    entityName: 'Slovakia',
    regions: ['eu'],
    callingCode: '421',
  },
  {
    entityId: 'TB_AE',
    entityName: 'United Arab Emirates',
    regions: ['mena', 'eu', 'talabat'],
    callingCode: '971',
  },
  { ...TB_BAHRAIN },
  { ...JORDAN },
  { ...KUWAIT },
  { ...OMAN },
  {
    entityId: 'TB_QA',
    entityName: 'Qatar',
    regions: ['mena', 'eu', 'talabat'],
    callingCode: '974',
  },
  {
    entityId: 'TB_SA',
    entityName: 'Saudi Arabia',
    regions: ['mena', 'eu', 'talabat'],
    callingCode: '966',
  },
  { ...IRAQ },
  {
    entityId: 'HF_EG',
    entityName: 'Egypt',
    regions: ['mena', 'eu', 'talabat'],
    callingCode: '20',
  },
  { ...HS_BAHRAIN },
  {
    entityId: 'HS_SA',
    entityName: 'Saudi Arabia',
    regions: ['mena', 'eu'],
    callingCode: '966',
  },
  {
    entityId: 'YS_TR',
    entityName: 'Turkey',
    regions: ['mena', 'eu'],
    callingCode: '90',
  },
]

export default countries

export const getEntityByEntityId = (entityId: string) =>
  countries.find((country) => country.entityId === entityId)

export const getEntityNameByEntityId = (entityId: string) =>
  countries.find((country) => country.entityId === entityId)?.entityName

export const getEntitiesByRegion = (region: CountryRegion) =>
  countries.filter((country) => country.regions.includes(region))
