import { SearchOutlined } from '@ant-design/icons'
import { Empty, Input, Space, Table } from 'antd'
import { Dot } from 'shared/Dot'
import { Text } from 'shared/Text'
import { useLoadLastOrders, UseLoadLastOrdersProps } from 'hooks/apiHooks/useLoadLastOrders'
import { useTranslation } from 'hooks/useTranslation'
import React, { MouseEvent, useMemo, useState } from 'react'
import { createUseStyles } from 'react-jss'
import { BuiltinWidgetConfigs, TabName } from 'types'
import { gray, primary, text } from 'theme'
import { OrderApiResponse } from 'types/api/orderApi/order'
import { ErrorRenderer, LinesOfBusiness } from 'types/herocare'
import { filterOrders } from 'utils/filters/filterOrders'
import { constructUrl } from 'utils/getSearchParams'
import { getOrderPaidValue } from 'utils/getters/getOrderPaidValue'
import { useCheckDisplayRules } from 'hooks/useCheckDisplayRules'
import { useDateTimeFormatter } from 'hooks/formatters/useDateTimeFormatter'
import { useNumberFormatter } from 'hooks/formatters/useNumberFormatter'
import { useGetOrderStatusMetadata } from 'hooks/dataTransformers/useGetOrderStatusMetadata'
import { useCaptureUserAction } from 'hooks/events/useCaptureUserAction'
import { getCustomerName } from 'utils/getCustomerName'

const useStyles = createUseStyles({
  itemsCountsTag: {
    display: 'inline-flex',
    minWidth: 31,
    backgroundColor: gray.gray4,
    padding: '0px 8px',
    borderRadius: '100px',
    justifyContent: 'center',
    alignItems: 'center',
  },

  filterSection: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 24,
  },

  inputFilter: {
    minWidth: '240px',
  },

  noMatchingOrdersContainer: {
    width: 270,
    margin: '74px auto 0px auto',
  },

  table: {
    '& .ant-table-tbody > tr > td': {
      padding: '12px',
    },

    '& .ant-table-tbody > tr.active-order, & .ant-table-tbody > tr.active-order:hover, & .ant-table-tbody > tr.active-order:hover > td':
      {
        backgroundColor: primary.primary1,
      },
  },
})

export interface OrdersListProps extends UseLoadLastOrdersProps {
  title?: string
  noOrdersMatchedFilterDescription?: string

  currentOrderId: string

  pageSize?: number

  ErrorRenderer: ErrorRenderer

  activeTab: TabName

  dataPointConfig?: BuiltinWidgetConfigs['last_orders']['data_points']
}

export const OrdersList = ({
  title,
  lob,
  filters,
  entityId,
  currentOrderId,
  noOrdersMatchedFilterDescription,
  pageSize = 10,
  ErrorRenderer,
  activeTab,
  dataPointConfig,
}: OrdersListProps) => {
  const { t } = useTranslation()

  const [page, setPage] = useState(1)
  const [filterText, setFilterText] = useState('')

  const checkDisplayRules = useCheckDisplayRules()
  const datetimeFormatter = useDateTimeFormatter()
  const numberFormatter = useNumberFormatter()
  const getOrderStatusMetadata = useGetOrderStatusMetadata()

  const captureUserAction = useCaptureUserAction()

  const showOrdersCount = lob === LinesOfBusiness.customer
  const showFilterBox = lob === LinesOfBusiness.customer

  const styles = useStyles()

  const orderNumberDataPoint = dataPointConfig?.find(
    (dataPoint) => dataPoint.name === 'order_number',
  )
  const displayOrderNumber = orderNumberDataPoint
    ? checkDisplayRules(orderNumberDataPoint.display_rules).visible
    : false

  const {
    lastOrders,
    loading,
    error,
    loadOrders: retryLoadOrders,
    hasMorePages,
    loadMorePages,
  } = useLoadLastOrders({
    filters,
    entityId,
    lob,
    includeMetadata: displayOrderNumber,
  })

  const filteredOrders = useMemo(() => {
    return filterOrders(lastOrders, {
      filterText,
      filterBy: ['orderId', 'vendor'],
    }).map((order) => ({ ...order, key: order.order_id }))
  }, [lastOrders, filterText])

  const pageOrders = useMemo(() => {
    const sliceStart = (page - 1) * pageSize
    return filteredOrders.slice(sliceStart, sliceStart + pageSize)
  }, [filteredOrders, page, pageSize])

  const columns = useMemo(() => {
    return [
      {
        title: t('widgets.orders_list.table_col_labels.created'),
        key: 'created',
        render: (_, order: OrderApiResponse) => (
          <div>
            <Text.Primary display='block'>
              {datetimeFormatter.formatDate(order.place_timestamp)}
            </Text.Primary>
            <Text.Secondary>
              {datetimeFormatter.formatTime(order.place_timestamp, {
                format: 'h:mm A',
              })}
            </Text.Secondary>
          </div>
        ),
      },
      {
        title: t('widgets.orders_list.table_col_labels.vendor'),
        key: 'vendor',
        hidden: lob === LinesOfBusiness.vendor,
        render: (_, order: OrderApiResponse) => (
          <Text.Primary>{order.vendor?.name || '-'}</Text.Primary>
        ),
      },

      {
        title: t('widgets.orders_list.table_col_labels.customer'),
        key: 'customer',
        hidden: lob === LinesOfBusiness.customer,
        render: (_, order: OrderApiResponse) => (
          <Text.Primary>{getCustomerName(order.customer, '-')}</Text.Primary>
        ),
      },

      {
        title: t('widgets.orders_list.table_col_labels.status'),
        key: 'status',
        render: (_, order: OrderApiResponse) => {
          const { statusColor, statusText } = getOrderStatusMetadata(order)
          return (
            <Space size={8}>
              <Dot color={statusColor} />
              <Text.Primary>{statusText}</Text.Primary>
            </Space>
          )
        },
        width: 40,
      },

      {
        title: t('widgets.orders_list.table_col_labels.price'),
        key: 'price',
        render: (_, order: OrderApiResponse) => (
          <div>
            <Text.Secondary display='block'>{order.currency}</Text.Secondary>
            <Text.Primary>{numberFormatter.formatDigit(getOrderPaidValue({ order }))}</Text.Primary>
          </div>
        ),
      },

      {
        title: t('widgets.orders_list.table_col_labels.voucher'),
        key: 'voucher',
        render: (_, order: OrderApiResponse) => {
          if (order.customer?.payment?.coupon > 0) {
            return (
              <div>
                <Text.Secondary display='block'>{order.currency}</Text.Secondary>
                <Text.Primary>
                  {numberFormatter.formatDigit(order.customer.payment.coupon)}
                </Text.Primary>
              </div>
            )
          }
          return '-'
        },
      },

      {
        title: t('widgets.orders_list.table_col_labels.order'),
        key: 'order',
        render: (_, order: OrderApiResponse) => {
          const url = constructUrl({
            params: {
              userId: order.customer.customer_id,
              orderId: order.order_id,
              globalEntityId: order.global_entity_id,
              tab: activeTab,
            },
          })

          const orderNumber = order.ov_meta_data?.order_number

          const handleClick = (e: MouseEvent<HTMLAnchorElement>) => {
            e.preventDefault()
            e.stopPropagation()

            captureUserAction('OPEN_LINK_ORDER_ID').finally(() => (window.location.href = url))
          }

          return (
            <a href={url} onClick={handleClick}>
              <Text.Primary Component='span' color={primary.primary6}>
                {order.order_id}
              </Text.Primary>

              {displayOrderNumber && orderNumber ? (
                <Text.Secondary display='block' Component='span' color={text.secondary}>
                  #{orderNumber}
                </Text.Secondary>
              ) : null}
            </a>
          )
        },
      },
    ].filter((current) => !current.hidden)
  }, [
    t,
    lob,
    activeTab,
    captureUserAction,
    numberFormatter,
    datetimeFormatter,

    getOrderStatusMetadata,
    displayOrderNumber,
  ])

  const isDataLoaded = lastOrders.length > 0

  return (
    <ErrorRenderer
      loading={
        !isDataLoaded && {
          [t('Interface.Loading Last Orders of Customer')]:
            loading && lob === LinesOfBusiness.customer,
          [t('Interface.Loading Last Orders of Vendor')]: loading && lob === LinesOfBusiness.vendor,
        }
      }
      errorPayload={!isDataLoaded && error?.errorPayload}
      onRetry={retryLoadOrders}
    >
      {() => {
        if (lastOrders.length === 0) {
          return (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={t(`errors.no_orders_found.title`)}
            />
          )
        }

        return (
          <div>
            <div className={styles.filterSection}>
              <div>
                <Space>
                  <Text.Primary fontWeight='500'>
                    {title || t(`widgets.orders_list.results.title`)}
                  </Text.Primary>
                  {showOrdersCount && (
                    <Text.Secondary className={styles.itemsCountsTag}>
                      {lastOrders.length.toString()}
                      {hasMorePages ? '+' : ''}
                    </Text.Secondary>
                  )}
                </Space>
              </div>

              {showFilterBox && (
                <div>
                  <Input
                    prefix={<SearchOutlined />}
                    placeholder={t('widgets.orders_list.filter.placeholder_text')}
                    onChange={(e) => {
                      setFilterText(e.target.value.trim())
                      setPage(1)
                    }}
                    className={styles.inputFilter}
                    allowClear
                  />
                </div>
              )}
            </div>

            {filteredOrders.length === 0 && (
              <div className={styles.noMatchingOrdersContainer}>
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description={
                    <Text.Secondary>
                      {noOrdersMatchedFilterDescription ||
                        t('errors.no_orders_matched_filter.description')}
                    </Text.Secondary>
                  }
                />
              </div>
            )}

            {filteredOrders.length > 0 && (
              <Table
                dataSource={pageOrders}
                columns={columns}
                className={styles.table}
                rowClassName={(order: OrderApiResponse) => {
                  if (order.order_id === currentOrderId) {
                    return 'active-order'
                  }
                  return ''
                }}
                loading={loading}
                pagination={{
                  pageSize,
                  total: hasMorePages ? filteredOrders.length + pageSize : filteredOrders.length,
                  current: page,
                  showSizeChanger: false,
                  onChange: (page) => {
                    const hasPageResult = filteredOrders.length > (page - 1) * pageSize
                    if (hasPageResult) {
                      setPage(page)
                    } else if (hasMorePages) {
                      loadMorePages().then(() => setPage(page))
                    }
                  },
                }}
              />
            )}
          </div>
        )
      }}
    </ErrorRenderer>
  )
}
