import React, { createContext, Dispatch, useMemo } from 'react'
import { dataReducer } from './DataReducer'
import { InitialState, DataActions } from './types'
import { useReadableReducer } from 'contexts/useReadableReducer'
import { EmittedEventName } from 'utils/eventEmitter'

const initialState = {
  emittedEvents: new Set<EmittedEventName>(),
  order: undefined,
  ticket: null,
  rider: null,
  customer: undefined,
  vendor: null,

  riderCurrentOrder: null,
  riderCurrentOrderVendor: null,

  orderBasketUpdates: undefined,
  orderItems: undefined,
  orderError: null,
  currency: '',

  caseCustomer: null,
  customerProfile: undefined,
  previousOrders: undefined,
  fulfillment: undefined,
  payment: undefined,
  vouchers: undefined,
  searchedVoucher: undefined,
  compensatedVoucher: undefined,
  fullyRefundedVoucher: undefined,
  refundToWalletOrPspDetails: undefined,
  wallet: undefined,
  modifiedDeliverySettings: undefined,
  newComment: undefined,
  autoChangeDeliveryTimeComment: undefined,
  autoMissingItemReportComment: undefined,
  autoCancelOrderComment: undefined,
  autoCompensationComment: undefined,
  autoPartialRefundComment: undefined,
  autoFullRefundComment: undefined,
  autoChangeAddressComment: undefined,
  autoChangeInstructionsComment: undefined,
  autoModifyCallbackComment: undefined,
  autoChangeCookingInstComment: undefined,
  autoRemoveItemsComment: undefined,
  recommendedCompensation: undefined,
  receivedCompensationSuggestions: undefined,
  customerCompensations: undefined,
  customerRefunds: undefined,
  itemsWithCookingInstructionChanged: undefined,
  isOrderCancelled: undefined,
  riderCustomerChatTranscript: undefined,
  reFetchOrder: false,

  isLoadingOrder: false,
  customerExploitStatus: undefined,
}

const DataContext = createContext<{
  dataState: InitialState
  dataDispatch: Dispatch<DataActions>
  readDataState: () => InitialState
}>({
  dataState: initialState,
  dataDispatch: () => null,
  readDataState: () => null,
})

const DataProvider: React.FC = ({ children }) => {
  const { dispatch, readState, state } = useReadableReducer(
    initialState as InitialState,
    dataReducer,
  )

  const value = useMemo(() => {
    return { dataState: state, dataDispatch: dispatch, readDataState: readState }
  }, [state, dispatch, readState])

  return <DataContext.Provider value={value}>{children}</DataContext.Provider>
}

export { DataProvider, DataContext }
