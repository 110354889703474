export default {
  sectionTitle: {
    fontSize: '18px',
    color: 'rgba(0, 0, 0, 0.85)',
  },
  filtersContainer: {
    width: '270px',
    padding: '8px 8px 8px 8px',
    backgroundColor: 'rgba(0, 0, 0, 0.09)',
    marginTop: '15px',
  },
  dropdownButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    fontSize: '14px',
    color: 'rgba(0, 0, 0, 0.65)',
    width: '95%',
  },
  dropdownIcon: {
    color: 'rgba(0, 0, 0, 0.25)',
  },
  icons: {
    width: '15px',
    height: '15px',
  },
  backOfficeButton: {
    fontSize: '14px',
    padding: 0,
  },
  filtersText: {
    fontSize: '14px',
    color: 'rgba(0, 0, 0, 0.85)',
  },
  clearButton: {
    marginLeft: '105px',
  },
  typeDropdown: {
    marginTop: '7px',
  },
  optionalFiltersContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'baseline',
    marginTop: '10px',
    paddingLeft: '3px',
    width: '97%',
  },
}
