import { OrderApiResponse, OrderHistoryItem } from 'types/api/orderApi/order'
import { DeliveryItem } from 'types/api/fulfillmentApi/fulfillment'
import { OrderStatuses } from 'types/widgets/order/orderStatuses'
import { RiderStatuses } from 'types/widgets/rider/riderStatuses'
import { deduplicateStatusHistory } from './deduplicateStatusHistory'

type Props = {
  delivery?: DeliveryItem
  order: OrderApiResponse
}
export type DeliveryStatusItem = OrderHistoryItem & {
  status: any
  source: 'order' | 'rider'
}
/**
 * getOrderDeliveryStatuses return deduplicated status list based on following cases
 * 1. Delivery information is not available:
 *    In this case we will only return order statues.
 *
 * 2. Delivery is primary:
 *    In this case we will return order statuses with some courier events [undispatch, manual_undispatch and notified].
 *    The main reason is that order status history already includes most of the primary rider events so don't want duplicate them.
 *
 * 3. Delivery is not primary:
 *    In this case we will return delivery courier events only.
 */
export const getOrderDeliveryStatuses = ({ delivery, order }: Props): DeliveryStatusItem[] => {
  let list: DeliveryStatusItem[] = []
  const orderCreatedStatus = {
    status: OrderStatuses.created,
    timestamp: order.place_timestamp,
    source: 'order',
  }

  const isOrderPending = (order: OrderApiResponse) =>
    order.pending &&
    !order.status_history.some((item) => item.status === OrderStatuses.pendingOrderFailed)

  const allowedCourierEvents = [
    RiderStatuses.manualUndispatched,
    RiderStatuses.undispatched,
    RiderStatuses.notified,
  ]

  const pendingOrderStatus = isOrderPending(order)
    ? { status: OrderStatuses.pending, timestamp: order.place_timestamp, source: 'order' }
    : []

  if (!delivery?.courier) {
    list = order.status_history
      .map((status) => ({ ...status, source: 'order' }))
      .concat(pendingOrderStatus, orderCreatedStatus) as DeliveryStatusItem[]
  } else if (delivery.primary_delivery) {
    const riderEvents = delivery.courier_events.reduce((list, item) => {
      if (allowedCourierEvents.includes(item.name)) {
        list.push({
          status: item.name,
          timestamp: item.timestamp,
          source: 'rider',
        })
      }
      return list
    }, [])

    list = order.status_history
      .map((status) => ({ ...status, source: 'order' }))
      .concat(riderEvents, pendingOrderStatus, orderCreatedStatus) as DeliveryStatusItem[]
  } else {
    list = delivery.courier_events.map(({ name, timestamp }) => ({
      status: name,
      timestamp,
      source: 'rider',
    })) as DeliveryStatusItem[]
  }

  return deduplicateStatusHistory(list) as DeliveryStatusItem[]
}
