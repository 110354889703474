// libs
import React, { useState, useContext } from 'react'
// contexts and types
import { DataContext } from 'contexts/data/DataContext'
import { DataAction } from 'contexts/data/types'
import { SessionContext } from 'contexts/session/SessionContext'
import { EntityContext } from 'contexts/entity/EntityContext'
// hooks
import { useTranslation } from 'hooks/useTranslation'
// utils

// styles
import { createUseStyles } from 'react-jss'
import styles from './ChatTranscript.styles'
import { Button, Modal, Typography } from 'antd'
import { WechatOutlined } from '@ant-design/icons'
// components
import ChatTranscriptView from 'components/ChatTranscriptView'
import { useApiService } from 'hooks/useApiService'
import { getRiderChats } from 'services/customerApi/getRiderChats'
import { WidgetErrorHandler } from 'components/WidgetErrorHandler/widgetErrorHandler'
import { EMPTY_CATCH_CALLBACK } from 'constants/constants'
import { useCaptureUserAction } from 'hooks/events/useCaptureUserAction'

const useStyles = createUseStyles(styles)

const ChatTranscript: React.FC = () => {
  const classes = useStyles()
  const { Text } = Typography

  // pull language content
  const { t } = useTranslation()
  const captureUserAction = useCaptureUserAction()

  // pull orderId from session context
  const {
    sessionState: { orderId, globalEntityId },
  } = useContext(SessionContext)

  // pull partial refund configs
  const {
    entityState: {
      entityConfig: { utc_zone },
    },
  } = useContext(EntityContext)

  // pull riderCustomerChatTranscript and dispatch from data context
  const {
    dataState: { riderCustomerChatTranscript },
    dataDispatch,
  } = useContext(DataContext)
  const { SET_RIDER_CUSTOMER_CHAT } = DataAction

  const [isChatTrans, setIsChatTrans] = useState(false)
  const handleChatTranscriptClick = () => {
    if (error) {
      captureUserAction('OrderTabChatTranscriptErrorScreenXButtonClicked')
    } else {
      captureUserAction('OrderTabChatTranscriptXButtonClicked')
    }
    setIsChatTrans(false)
  }

  const {
    loadService: loadChats,
    loading: isLoading,
    data,
    error,
  } = useApiService({
    service: getRiderChats,
    autoLoad: false,
    params: {
      entityId: globalEntityId,
      orderId,
    },
    deps: [globalEntityId, orderId],

    onBeforeLoad: () => {
      setIsChatTrans(true)
      captureUserAction('OrderTabViewChatTransriptClicked')
    },

    onSuccess({ data }) {
      dataDispatch({
        type: SET_RIDER_CUSTOMER_CHAT,
        payload: { riderCustomerChatTranscript: data },
      })
    },
    onError() {
      dataDispatch({
        type: SET_RIDER_CUSTOMER_CHAT,
        payload: { riderCustomerChatTranscript: undefined },
      })
    },
  })

  return (
    <WidgetErrorHandler
      loading={isLoading}
      errorPayload={error?.errorPayload}
      onRetry={loadChats}
      displayType='mini'
      useIconRetryButton
    >
      {() => (
        <>
          <div className={classes.chatTranscriptHolder}>
            <Button
              type='link'
              className={classes.chatTranscriptButton}
              onClick={() => {
                captureUserAction('OrderTabChatTranscriptErrorScreenTryAgainButtonClicked')
                loadChats().catch(EMPTY_CATCH_CALLBACK)
              }}
            >
              <WechatOutlined className={classes.chatTranscriptIcon} />
              {t('Order Widget.Tabs.Summary.View Chat Transcript')}
            </Button>
          </div>
          {data ? (
            <Modal
              title={
                <div className={classes.titleHolder}>
                  <Text strong className={classes.modalTitle}>
                    {t('Order Widget.Tabs.Summary.Chat Transcript')}
                  </Text>
                </div>
              }
              className={classes.modal}
              visible={isChatTrans}
              destroyOnClose
              width={305}
              centered
              onCancel={handleChatTranscriptClick}
              footer={null}
              maskStyle={{ backgroundColor: '#04294a', opacity: 1 }}
            >
              <ChatTranscriptView
                chatTranscripts={riderCustomerChatTranscript}
                utcZone={utc_zone}
                isRiderService={false}
                orderId={orderId}
              />
            </Modal>
          ) : null}
        </>
      )}
    </WidgetErrorHandler>
  )
}

export default ChatTranscript
