import { availableRefundMethods } from 'entityConfig/allowedConfigValues'
import { ApiService } from 'services/authApi/getPermissions'

export interface OrderPurchaseDetails {
  compensation_targets: {
    [p in 'wallet' | 'voucher']: {
      allowed: boolean
    }
  }

  refund_targets: {
    [p in keyof typeof availableRefundMethods | 'psp']: {
      allowed: boolean
      reason?: string
    }
  }

  full_refund_targets: {
    [p in keyof typeof availableRefundMethods | 'psp']: {
      allowed: boolean
      reason?: string
    }
  }

  purchase?: {
    amount?: {
      amount?: number
    }
    refundedAmount?: {
      amount?: number
    }
  }
}

export const getOrderPurchaseDetails: ApiService<
  {
    entityId: string
    customerId: string
    orderId: string
  },
  OrderPurchaseDetails
> = (createClient, options) => {
  const { entityId, orderId, customerId, clientParams } = options

  const uri = `/PaymentAPI/v1/order/${entityId}/${orderId}/purchase?customer_id=${customerId}`

  return createClient({
    endpointName: 'getOrderPurchaseDetails',
    ...clientParams,
  }).get(uri)
}
