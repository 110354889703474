import React from 'react'
import { Tooltip } from 'antd'
import { useTranslation } from 'hooks/useTranslation'
import { CheckCircleFilled, ExclamationCircleFilled, QuestionCircleFilled } from '@ant-design/icons'
import { fraudStatusResults } from 'types/unitedUiConfig'
import { StatusColors } from 'theme'

const fraudStatusRenderInfo = {
  green: {
    tooltip: 'Customer Widget.Trusted customer',
    icon: <CheckCircleFilled style={{ color: StatusColors.green }} />,
  },
  red: {
    tooltip: 'Customer Widget.Fraudulent customer',
    icon: <ExclamationCircleFilled style={{ color: StatusColors.red }} />,
  },
  orange: {
    tooltip: 'Customer Widget.Potential fraudulent customer',
    icon: <QuestionCircleFilled style={{ color: StatusColors.orange }} />,
  },
}

type Props = {
  status: fraudStatusResults
}

const FraudStatusFlag = ({ status }: Props) => {
  const { t } = useTranslation()

  const { tooltip, icon } = fraudStatusRenderInfo[status]

  return (
    <Tooltip title={t(tooltip)} placement='right'>
      {icon}
    </Tooltip>
  )
}

export default FraudStatusFlag
