import React, { ReactEventHandler } from 'react'
import { ItemImageUrl } from 'types/api/orderApi/order'
import useItemImageStyles from './styles'

type Props = {
  image: ItemImageUrl
  onError?: () => void
}

export const ItemImage = ({ image, onError }: Props) => {
  const classes = useItemImageStyles()

  const handleError: ReactEventHandler<HTMLImageElement> = (event) => {
    onError?.()
  }

  return (
    <img src={image.url} className={classes.itemImage} alt='Order item' onError={handleError} />
  )
}
