import { ItemStructured } from 'types/actions/partialRefund'
import { ItemOption } from 'types/api/orderApi/order'

export const structureOrderItemOption = (
  option: ItemOption,
  parentItem: ItemStructured,
): ItemStructured => {
  const { options, type, id, quantity, name, unit_price, ...others } = option

  const result: ItemStructured = {
    type: type === 'bundle_item' ? 'bundle_item' : (`${parentItem.type}_${type}` as any),
    parent_type: parentItem.type as any,
    ...others,

    line_item_id: parentItem.line_item_id,
    customer_notes: parentItem.customer_notes,

    id: id,

    quantity: quantity || 1,
    unit_price: unit_price || 0,

    name,
    display_name: name,

    selected: false,
    parent_item_selected: false,
    parent_item_partially_selected: false,
  }

  // in the initial setup, we assume all options are missing too
  result.total_quantity = result.quantity * parentItem.quantity
  result.max_missing_quantity = result.quantity * parentItem.quantity
  result.missing_quantity = result.max_missing_quantity

  result.options = (options || []).map((option) => {
    return structureOrderItemOption(option, result)
  })

  return result
}
