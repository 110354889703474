export default {
  extraInputsContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    margin: '16px 0px 0px 6px',
  },
  newInputFieldRow: {
    display: 'flex',
    width: '100%',
    marginTop: '5px',
  },
  extraInput: {
    display: 'flex',
    flexDirection: 'column',
    width: '48%',
    marginRight: '1%',
  },
  extraInputFullWidth: {
    display: 'flex',
    flexDirection: 'column',
    width: '97%',
    marginRight: '2%',
  },
  extraInputText: {
    fontSize: '12px',
    color: 'rgba(0, 0, 0, 0.45)',
    marginBottom: '5px',
  },
  disabledInput: {
    color: 'rgba(0, 0, 0, 0.60)',
  },
  collapse: {
    padding: 0,
    marginTop: 10,
    marginLeft: -14,
    width: '101%',
    minWidth: 270,
  },
  additionalDetailsText: {
    fontSize: '13px',
    color: ' rgba(0, 0, 0, 0.45)',
  },
  alert: {
    padding: '3px 1px 3px 5px',
    width: '97%',
    margin: '0px 0px 12px 0px',
  },
  alertText: {
    fontSize: '13px',
    color: 'rgba(0, 0, 0, 0.85)',
  },
}
