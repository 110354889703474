import { Result } from 'antd'
import { useTranslation } from 'hooks/useTranslation'
import React from 'react'
import { Button } from 'shared/Button'

interface SuccessScreenProps {
  title: string
  subTitle?: string
  onDone?: () => void
}

export const ErrorScreen: React.FC<SuccessScreenProps> = ({ title, subTitle, onDone }) => {
  const { t } = useTranslation()
  return (
    <Result
      status='error'
      title={title}
      subTitle={subTitle}
      extra={[
        <Button key='done' type='solid' onClick={() => onDone?.()}>
          {t('Interface.Done')}
        </Button>,
      ]}
    />
  )
}
