import { WidgetViewPanel } from 'App/WidgetViewPanel'
import { ErrorBoundary } from 'components/ErrorBoundary/ErrorBoundary'
import ApiProvider from 'contexts/api/ApiContext'
import { ApiClientCreatorProvider } from 'contexts/apiClientCreator/ApiClientCreatorContext'
import { AuthProvider } from 'contexts/auth/AuthProvider'
import { DataProvider } from 'contexts/data/DataContext'
import { EntityProvider } from 'contexts/entity/EntityContext'
import { EntityConfig } from 'contexts/entity/types'
import { WidgetViewManager } from 'contexts/widgetViewManager'
import { SessionProvider } from 'contexts/session/SessionContext'
import { SessionState } from 'contexts/session/types'
import environment from 'envConfig'
import React, { FC, memo } from 'react'
import { BrowserRouter } from 'react-router-dom'

import { RiderServiceProvider } from 'contexts/riderService/RiderServiceContext'
import { TranslationProvider } from 'contexts/TranslationProvider'
import { useSessionState } from 'hooks/useSessionState'

import en from '../locales/en.json'
import es from '../locales/es.json'
import cs from '../locales/cs.json'
import ja from '../locales/ja.json'
import hu from '../locales/hu.json'
import th from '../locales/th.json'
import ar from '../locales/ar.json'
import gr from '../locales/gr.json'
import tr from '../locales/tr.json'
import vi from '../locales/vi.json'
import { IconPacks, IconPacksProvider } from 'contexts/IconPacksProvider'
import { ViewScreenAndSdkProvider } from 'contexts/ViewScreenAndSdkProvider'
import { ViewScreenProvider } from 'contexts/ViewScreenProvider'

const Translations = ({ children }) => {
  const { lang } = useSessionState()
  return (
    <TranslationProvider
      lang={lang}
      translations={{
        en,
        es,
        cs,
        ja,
        hu,
        th,
        ar,
        gr,
        tr,
        vi,
      }}
    >
      {children}
    </TranslationProvider>
  )
}

const StableUi = memo(
  ({ children }) => {
    return children as any
  },
  () => false,
)

export interface AppContainerProps {
  entityConfig?: Partial<EntityConfig>
  sessionState?: SessionState
  isLoggedIn?: boolean
  iconPacks?: IconPacks
}

export const AppContainer: FC<AppContainerProps> = ({
  children,
  entityConfig,
  sessionState,
  isLoggedIn,
  iconPacks,
}) => {
  return (
    <ErrorBoundary>
      <BrowserRouter>
        <DataProvider>
          <SessionProvider sessionState={sessionState}>
            <Translations>
              <EntityProvider entityConfig={entityConfig}>
                <RiderServiceProvider>
                  <AuthProvider isLoggedIn={isLoggedIn}>
                    <ApiClientCreatorProvider>
                      <ApiProvider host={environment().oneviewApiRoot}>
                        <ViewScreenProvider screenName='main'>
                          <WidgetViewManager>
                            <ViewScreenAndSdkProvider screenName='main'>
                              <IconPacksProvider
                                iconPacks={iconPacks}
                                loader={
                                  iconPacks
                                    ? undefined
                                    : () => import('Icons').then((res) => res as IconPacks)
                                }
                              >
                                <StableUi>
                                  {children}
                                  <WidgetViewPanel />
                                </StableUi>
                              </IconPacksProvider>
                            </ViewScreenAndSdkProvider>
                          </WidgetViewManager>
                        </ViewScreenProvider>
                      </ApiProvider>
                    </ApiClientCreatorProvider>
                  </AuthProvider>
                </RiderServiceProvider>
              </EntityProvider>
            </Translations>
          </SessionProvider>
        </DataProvider>
      </BrowserRouter>
    </ErrorBoundary>
  )
}
