// libs
import React, { ComponentProps, FunctionComponent } from 'react'
import { Moment } from 'moment'
// styles
import { DatePicker as _DatePicker } from 'antd'

const DatePicker = _DatePicker as any as FunctionComponent<ComponentProps<typeof _DatePicker>>
interface Props {
  handleDateChange: (arg: Moment, secArg: boolean) => void
  disabledDate: (arg: Moment) => boolean
  defaultValue: Moment
  selectedDeliveryDate: Moment
}

const DatePickerView: React.FC<Props> = ({
  handleDateChange,
  disabledDate,
  defaultValue,
  selectedDeliveryDate,
}) => {
  return (
    <DatePicker
      format='DD/MM/YYYY'
      allowClear={false}
      showToday={false}
      showNow={false}
      onChange={(date: Moment) => handleDateChange(date, false)}
      defaultValue={defaultValue}
      disabledDate={disabledDate}
      value={selectedDeliveryDate ? selectedDeliveryDate : null}
    />
  )
}

export default DatePickerView
