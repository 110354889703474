import React from 'react'
import { Tag } from 'antd'
import { ThunderboltOutlined, MessageOutlined } from '@ant-design/icons'
import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles({
  icon: {
    display: 'inline-flex',
    verticalAlign: 'middle',
    paddingRight: '8px',
    fontSize: '14px',
  },
  commentTag: {
    width: 'max-content',
    margin: '0',
    padding: '0 8px',
    justifySelf: 'end',
    borderRadius: '11px',
    display: 'flex',
    alignItems: 'center',
  },
})

const ThunderboltIcon: React.FC = () => {
  const classes = useStyles()
  return (
    <span className={classes.icon}>
      <ThunderboltOutlined />
    </span>
  )
}

const MessageIcon: React.FC = () => {
  const classes = useStyles()
  return (
    <span className={classes.icon}>
      <MessageOutlined />
    </span>
  )
}

export const ThunderboltTag: React.FC = ({ children }) => {
  const classes = useStyles()

  return (
    <Tag className={classes.commentTag} icon={<ThunderboltIcon />}>
      {children}
    </Tag>
  )
}

export const MessageTag: React.FC = ({ children }) => {
  const classes = useStyles()

  return (
    <Tag className={classes.commentTag} icon={<MessageIcon />}>
      {children}
    </Tag>
  )
}
