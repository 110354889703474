import { DataContext } from 'contexts/data/DataContext'
import { DataAction } from 'contexts/data/types'
import { SessionContext } from 'contexts/session/SessionContext'
import { useApiService } from 'hooks/useApiService'
import { useContext } from 'react'
import { getOrderStatusHistory } from 'services/ordersApi/getOrderStatusHistory'

const { SET_ORDER_STATUS } = DataAction

export function useLoadOrderStatusHistory() {
  const {
    dataState: { modifiedDeliverySettings, autoChangeDeliveryTimeComment },
    dataDispatch,
  } = useContext(DataContext)

  const {
    sessionState: { orderId, globalEntityId },
  } = useContext(SessionContext)

  const {
    loading: isLoading,
    data,
    loadService,
    status,
    error,
    clearError,
  } = useApiService({
    service: getOrderStatusHistory,
    params: {
      entityId: globalEntityId,
      orderId,
    },
    shouldLoad: Boolean(orderId && globalEntityId),
    deps: [globalEntityId, orderId, modifiedDeliverySettings, autoChangeDeliveryTimeComment],

    onSuccess: ({ data }) => {
      dataDispatch({
        type: SET_ORDER_STATUS,
        payload: { orderStatus: data },
      })
    },
  })

  return { isLoading, data, loadService, status, error, clearError }
}
