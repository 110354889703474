export default {
  confirmationContainer: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
  },
  resultTitleHolder: {
    display: 'flex',
    flexDirection: 'column',
  },
  resultVoucherSection: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  successText: {
    color: 'rgba(0, 0, 0, 0.85)',
    fontSize: '27px',
  },
  resultVoucherInfoTitle: {
    color: 'rgba(0, 0, 0, 0.45)',
    marginRight: '5px',
    fontSize: '16px',
    fontWeight: 600,
  },
  resultVoucherInfoData: {
    color: 'rgba(0, 0, 0, 0.85)',
    fontSize: '15px',
  },
  sectionCopyText: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '13px',
    fontWeight: 400,
    color: '#2A8FF7',
    margin: '20px 0px 20px 0px',
  },
  title: {
    color: 'rgba(0, 0, 0, 0.85)',
    fontSize: '18px',
    margin: '50px 0px 25px 0px',
  },
  message: {
    color: 'rgba(0, 0, 0, 0.65)',
    fontSize: '15px',
  },
  buttonsHolder: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    margin: '50px 0px 50px 0px',
  },
  continueButton: {
    marginLeft: '5px',
  },
}
