import { createUseStyles } from 'react-jss'

export const useStyle = createUseStyles({
  container: {
    width: '100%',
    display: 'flex',
    padding: '0 2.4%',
    flexWrap: 'wrap',
    '& > :nth-child(even)': {
      marginLeft: '-6px',
    },
  },
  rider: {
    width: '50%',
    marginBottom: '15px',
  },
  '@media (max-width: 1236px)': {
    container: {
      padding: '0 0 0 3.1%',
    },
  },
  label: {
    color: '#8C8C8C',
    fontSize: '14px',
    fontWeight: 'normal',
    marginBottom: '5px',
  },
  info: {
    display: 'flex',
    gap: '5px',
    cursor: 'pointer',
  },
  name: {
    color: '#1890FF',
    fontSize: '14px',
  },
  status: {
    fontSize: '12px',
  },
})
