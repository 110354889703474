/**
 * Set of components used to render table cells within a voucher row
 * */

// libs
import React, { useContext } from 'react'
// contexts and types
import { DataContext } from 'contexts/data/DataContext'
// styles
import { createUseStyles } from 'react-jss'
import styles from './VoucherTableCellComponents.styles'
// components
import {
  VoucherDataPoint,
  VoucherDateDataPoint,
  VoucherValueDataPoint,
} from 'types/api/voucherApi/voucher'
import { VoucherStatusIcon } from 'components/VoucherStatuses'
import { Statuses } from 'types/dataTransformers/customerVouchers'
import cashbackIconImg from 'assets/general/cashback.svg'
import { Tooltip } from 'antd'
import { useTranslation } from 'hooks/useTranslation'
import { Text } from 'shared/Text'

const useStyles = createUseStyles(styles)

interface DescriptionAndStatusProp {
  code: VoucherDataPoint
  description: VoucherDataPoint
  status: string
}
export const DescriptionAndStatus: React.FC<DescriptionAndStatusProp> = ({
  status,
  description,
  code,
}) => {
  const classes = useStyles()

  return (
    <div className={classes.descCol}>
      <Text.Primary>{description.canDisplay ? description.value : null}</Text.Primary>
      <div className={classes.cellStatusContainer}>
        <span className={classes.iconPaddingRight}>
          <VoucherStatusIcon status={status as Statuses} showStatusLabel={false} />
        </span>
        <span className={classes.voucherCode}>{code.canDisplay ? code.value : null}</span>
      </div>
    </div>
  )
}

interface ValidDatesProps {
  validDates: VoucherDateDataPoint
}
export const ValidDates: React.FC<ValidDatesProps> = ({ validDates }) => {
  const classes = useStyles()

  return (
    <div className={classes.validDatesCol}>
      <div className={classes.startDate}>{validDates.start_date} - </div>
      <div>{validDates.exp_date}</div>
    </div>
  )
}

interface MinimumOrderValueProps {
  minimumOrderValue: VoucherValueDataPoint
}
export const MinimumOrderValue: React.FC<MinimumOrderValueProps> = ({ minimumOrderValue }) => {
  const classes = useStyles()
  const {
    dataState: { currency },
  } = useContext(DataContext)
  const value = minimumOrderValue?.value
  const isValue = minimumOrderValue?.isValue

  return (
    value && (
      <div>
        {isValue && <div className={classes.currency}>{currency}</div>}
        <div>{minimumOrderValue?.value}</div>
      </div>
    )
  )
}

interface VoucherValueProps {
  value: VoucherValueDataPoint
}
export const VoucherValue: React.FC<VoucherValueProps> = ({ value }) => {
  const classes = useStyles()
  const {
    dataState: { currency },
  } = useContext(DataContext)
  const { t } = useTranslation()
  return (
    <div>
      {value?.isValue && <div className={classes.currency}>{currency}</div>}
      <div className={classes.voucherValue}>
        {value?.value}
        {value?.isCashback && (
          <span>
            <Tooltip title={t('Customer Widget.Tabs.Vouchers.Cashback voucher')}>
              <img
                className={classes.cashbackIcon}
                src={cashbackIconImg}
                alt={t('Customer Widget.Tabs.Vouchers.Cashback voucher')}
              />
            </Tooltip>
          </span>
        )}
      </div>
    </div>
  )
}
