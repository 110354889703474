interface StyleOptions {
  noWrap?: boolean
  xSpacing?: number
  ySpacing?: number
  width?: string | number
}

export default {
  width: {
    width: ({ width }: StyleOptions) => (width ? width : undefined),
  },

  stackRow: {
    display: 'flex',
    flexWrap: ({ noWrap }: StyleOptions) => (noWrap ? 'no-wrap' : 'wrap'),
    marginLeft: ({ xSpacing = 0 }: StyleOptions) => `${-1 * xSpacing}px`,
    marginTop: ({ ySpacing = 0 }: StyleOptions) => `${-1 * ySpacing}px`,

    '& > .stack-item': {
      paddingLeft: ({ xSpacing = 0 }: StyleOptions) => `${xSpacing}px`,
      paddingTop: ({ ySpacing = 0 }: StyleOptions) => `${ySpacing}px`,
    },
  },

  stackRowReverse: {
    display: 'flex',
    flexDirection: 'row-reverse',

    flexWrap: ({ noWrap }: StyleOptions) => (noWrap ? 'no-wrap' : 'wrap'),
    marginRight: ({ xSpacing = 0 }: StyleOptions) => `${-1 * xSpacing}px`,
    marginTop: ({ ySpacing = 0 }: StyleOptions) => `${-1 * ySpacing}px`,

    '& > .stack-item': {
      paddingLeft: ({ xSpacing = 0 }: StyleOptions) => `${xSpacing}px`,
      paddingTop: ({ ySpacing = 0 }: StyleOptions) => `${ySpacing}px`,
    },
  },

  stackCol: {
    display: 'flex',
    flexDirection: 'column',

    marginTop: ({ ySpacing = 0 }: StyleOptions) => `${-1 * ySpacing}px`,

    '& > .stack-item': {
      paddingTop: ({ ySpacing = 0 }: StyleOptions) => `${ySpacing}px`,
    },
  },

  stackColReverse: {
    display: 'flex',
    flexDirection: 'column-reverse',

    marginBottom: ({ ySpacing = 0 }: StyleOptions) => `${-1 * ySpacing}px`,

    '& > .stack-item': {
      paddingBottom: ({ ySpacing = 0 }: StyleOptions) => `${ySpacing}px`,
    },
  },
}
