import { OrderHistory } from 'types/api/orderApi/order'
import modifyUTC from 'utils/modifyUTC'
import capitalizeFirstLetters from 'utils/capitalizeFirstLetters'

function transformOrderStatus(orderStatus: OrderHistory, utc_zone: string) {
  let orderStatusTransformed = []
  if (orderStatus && orderStatus.length > 0) {
    orderStatusTransformed = orderStatus.reverse().map(({ status, timestamp }) => {
      const timestampModified = modifyUTC(timestamp, utc_zone)
      const statusModified = capitalizeFirstLetters(status.replace(/_/g, ' '))

      return {
        code: status,
        status: statusModified,
        timestamp: timestampModified,
      }
    })
  }

  const transformedOrderStatus = {
    orderStatus: orderStatusTransformed,
  }

  return transformedOrderStatus
}

export default transformOrderStatus
