import { DataContext } from 'contexts/data/DataContext'
import { useContext } from 'react'
import { OrderStatuses } from 'types/widgets/order/orderStatuses'

export const useOrderStatus = (): OrderStatuses => {
  const {
    dataState: { order },
  } = useContext(DataContext)

  const status = order?.status_history?.length
    ? order.status_history[order.status_history.length - 1].status
    : ''
  return status || OrderStatuses.unknown
}
