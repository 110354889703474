// all possible stages
export enum OrderStages {
  confirmation = 'CONFIRMATION',
  postDelivery = 'POST_DELIVERY',
  delivery = 'DELIVERY',
  preparation = 'PREPARATION',
}

// exact responses returned from cancelOrder endpoint
export enum CancellationApiResponses {
  success = 'success',
  cancelled = 'order already cancelled',
  ineligible = 'cancellation is ineligible',
  invalid = 'invalid reason',
  notFound = 'order not found',
  genericErr = 'Order cannot be cancelled at this time. Please try again.',
}

export type SingleCancellationReason = {
  reason: string
  reason_translation_key: string
  ccrWhitelist?: string[]
}
