export const red = {
  red1: '#FFF1F0',
  red2: '#FFCCC7',
  red3: '#FFA39E',
  red4: '#FF7875',
  red5: '#FF4D4F',
  red6: '#F5222D',
  red7: '#CF1322',
  red8: '#A8071A',
  red9: '#820014',
  red10: '#5C0011',
}

export const gold = {
  gold1: '#FFFBE6',
  gold2: '#FFF1B8',
  gold3: '#FFE58F',
  gold4: '#FFD666',
  gold5: '#FFC53D',
  gold6: '#FAAD14',
  gold7: '#D48806',
  gold8: '#AD6800',
  gold9: '#874D00',
  gold10: '#613400',
}

// Daybreak Blue
export const primary = {
  primary1: '#E6F7FF',
  primary2: '#BAE7FF',
  primary3: '#91D5FF',
  primary4: '#69c0ff',
  primary5: '#40A9FF',
  primary6: '#1890FF',
  primary7: '#096DD9',
  primary8: '#0050B3',
  primary9: '#003A8C',
  primary10: '#002766',
}

export const gray = {
  gray1: '#FFFFFF',
  gray2: '#FAFAFA',
  gray3: '#F5F5F5',
  gray4: '#F0F0F0',
  gray5: '#D9D9D9',
  gray6: '#BFBFBF',
  gray7: '#8C8C8C',
  gray8: '#595959',
  gray9: '#434343',
  gray10: '#262626',
  gray11: '#1F1F1F',
  gray12: '#141414',
  gray13: '#000000',
  gray14: '#0000000F',
  gray15: '#B8B8B8',
  gray16: '#6E6E6E',
}

export const green = {
  green1: '#F6FFED',
  green2: '#D9F7BE',
  green3: '#B7EB8F',
  green4: '#95DE64',
  green5: '#73D13D',
  green6: '#52C41A',
  green7: '#389E0D',
  green8: '#237804',
  green9: '#135200',
  green10: '#092B00',
}

export const pink = {
  pink1: '#FFF0F6',
  pink2: '#FFD6E7',
  pink3: '#FFADD2',
  pink4: '#FF85C0',
  pink5: '#F759AB',
  pink6: '#EB2F96',
  pink7: '#C41D7F',
  pink8: '#9E1068',
  pink9: '#780650',
  pink10: '#520339',
}

export const shadow = {
  level1: '0px 1px 2px 0px rgba(0,0,0,0.05)',
}

export const white = '#FFF'

export const divider = 'rgba(0, 0, 0, 0.06)'

export const text = {
  primary: 'rgba(0,0,0,0.85)',
  secondary: 'rgba(0,0,0,0.55)',
  danger: red.red5,
  link: primary.primary6,
  disabled: 'rgba(0, 0, 0, 0.25)',
}

export const fontWeights = {
  light: 300,
  normal: 400,
  medium: 500,
  semiBold: 600,
  bold: 700,
}

export const StatusColors = {
  green: green.green6,
  red: red.red5,
  orange: gold.gold6,
} as const
