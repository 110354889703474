import React from 'react'
import { Typography } from 'antd'
import { createUseStyles } from 'react-jss'

import { SingleComment } from 'types/api/commentApi/getOrderComments'
import { useTranslation } from 'hooks/useTranslation'
import { commentTagType, CommentView } from 'components/Comments/CommentView'
import { getAgentNameFromId } from 'utils/string'
import styles from './Comments.styles'
import { WidgetSize } from 'types/widgets/historyOfEvents/tabs'

const { Text } = Typography

const useStyles = createUseStyles(styles)

type Props = {
  comment: SingleComment
  shouldCommentBeHighlighted?: boolean
  mode: WidgetSize
}

export const UpdateOrderFlagComment = ({ comment, shouldCommentBeHighlighted, mode }: Props) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const additionalData = comment.additional_data?.update_order_flag

  return (
    <CommentView
      mode={mode}
      comment={comment}
      shouldCommentBeHighlighted={shouldCommentBeHighlighted}
      tag={{
        type: commentTagType.message,
        text: t('History of Events Widget.Event Types.Update Order Flag'),
      }}
    >
      <Text className={classes.commentText}>
        <Text>
          {getAgentNameFromId(comment.agent_id)}{' '}
          {t('History of Events Widget.Content.Updated order flag for order')} {comment.order_id},{' '}
        </Text>

        <Text>{t('History of Events Widget.Content.Updated flags are')} - </Text>
        <Text>
          {t('History of Events Widget.Content.Billable')}: {`${additionalData.is_billable}`},{' '}
          {t('History of Events Widget.Content.Wastage')}: {`${additionalData.is_wastage}`},{' '}
          {t('History of Events Widget.Content.Manual')}: {`${additionalData.is_manual}`},{' '}
          {t('History of Events Widget.Content.Receiptable')}: {`${additionalData.is_receiptable}`}{' '}
        </Text>

        {additionalData?.reason ? (
          <Text>
            {t('History of Events Widget.Content.With a reason')}: {additionalData?.reason}
          </Text>
        ) : null}
      </Text>
    </CommentView>
  )
}
