/*
 *
 *  Side bar with the bug fix for "white space gap" b/w panel's right side and page boundary etc.
 *
 */

import React, { useMemo } from 'react'
import { Drawer, DrawerProps } from 'antd'

export const BugFixedAntDrawer: React.FC<DrawerProps> = (props) => {
  const { placement, contentWrapperStyle = {}, style = {} } = props

  /* The style are not getting applied properly on .ant-drawer and .ant-drawer-content-wrapper, following two styles are fixing that*/
  const styleModified = useMemo(() => {
    switch (placement) {
      case 'right':
        return { left: 'unset' }
      case 'left':
        return { right: 'unset' }
      case 'bottom':
        return { top: 'unset' }
      case 'top':
        return { bottom: 'unset' }
      default:
        return {}
    }
  }, [placement])

  const contentWrapperStyleModified = useMemo(() => {
    if (placement === 'left' || placement === 'right') {
      return { width: '100%' }
    }
    if (placement === 'top' || placement === 'bottom') {
      return { height: '100%' }
    }
    return {}
  }, [placement])

  return (
    <Drawer
      {...props}
      style={{ ...style, ...styleModified }}
      contentWrapperStyle={{ ...contentWrapperStyle, ...contentWrapperStyleModified }}
    />
  )
}
