import { useMemo } from 'react'

// used for analytics capturing
// extracted from src/App/WidgetPanel/WidgetPanel.tsx
export function useOrderAnalytics(order) {
  return useMemo(() => {
    const status_history = order?.status_history || []
    const items = order?.order?.items || []
    const customer = order?.customer

    return {
      orderItemsCount: items.reduce((result, item) => {
        return result + item.quantity
      }, 0),
      uniqueOrderItemsCount: items.length,
      orderPaymentMethod: customer?.payment?.payment_method,
      orderStatus: status_history?.length ? status_history[status_history.length - 1]?.status : '',
    }
  }, [order])
}
