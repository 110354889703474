// libs
import React, { useContext } from 'react'
// contexts and types
import { EntityContext } from 'contexts/entity/EntityContext'
import { RiderServiceContext } from 'contexts/riderService/RiderServiceContext'
import riderDataPointIdentifiers from 'types/riderService/riderDataPointIdentifiers'
// hooks
import { useTranslation } from 'hooks/useTranslation'
import useDataPointAvailabilityCheck from 'hooks/useDataPointAvailabilityCheck'
// utils
// styles
import { createUseStyles } from 'react-jss'
import styles from './Content.styles'
import { Divider, Button } from 'antd'
import { CopyOutlined } from '@ant-design/icons'
// components
import TextBlockView from 'components/TextBlockView'
import { Notification } from 'shared/Notification'
import { useCaptureUserAction } from 'hooks/events/useCaptureUserAction'

const useStyles = createUseStyles(styles)

const Content: React.FC = () => {
  const classes = useStyles()

  // pull translations
  const { t } = useTranslation()

  // pull entity context
  const { entityState } = useContext(EntityContext)

  // pull data state and dispatch from data context
  const {
    riderServiceState: { rider },
  } = useContext(RiderServiceContext)

  const captureUserAction = useCaptureUserAction()

  const handleCopyToClipboard = (item: string) => {
    captureUserAction('RiderSummaryCopyMobileClicked')
    const success = () => Notification.success({ message: t('Messages.Copied') })
    const warning = () => Notification.warning({ message: t('Widgets Common.Cannot Copy') })

    navigator.clipboard.writeText(item.toString()).then(
      () => success(),
      () => warning(),
    )
  }

  const isIdAndLinkAllowed = useDataPointAvailabilityCheck(
    entityState.entityConfig.miniRider.rider_id,
    rider?.id ? true : false,
    [entityState, rider],
  )

  const isStatusAllowed = useDataPointAvailabilityCheck(
    entityState.entityConfig.miniRider.riderStatus,
    rider?.status ? true : false,
    [entityState, rider],
  )

  const isContractTypeAllowed = useDataPointAvailabilityCheck(
    entityState.entityConfig.miniRider.riderContractType,
    rider?.contractType ? true : false,
    [entityState, rider],
  )

  const isCityAllowed = useDataPointAvailabilityCheck(
    entityState.entityConfig.miniRider.riderCity,
    rider?.city ? true : false,
    [entityState, rider],
  )

  const isVehicleAllowed = useDataPointAvailabilityCheck(
    entityState.entityConfig.miniRider.riderVehicle,
    rider?.vehicle ? true : false,
    [entityState, rider],
  )
  const isCurrentShiftAllowed = useDataPointAvailabilityCheck(
    entityState.entityConfig.miniRider.riderCurrentShift,
    rider?.currentShift ? true : false,
    [entityState, rider],
  )
  const isPhoneAllowed = useDataPointAvailabilityCheck(
    entityState.entityConfig.miniRider.riderPhone,
    rider?.phone ? true : false,
    [entityState, rider],
  )

  const isCopyToClipboard = useDataPointAvailabilityCheck(
    entityState.entityConfig.miniRider.copy_to_clipboard,
    true,
    [entityState],
  )

  return (
    <div className={classes.riderBody}>
      {/* rider ID */}
      {rider?.id && isIdAndLinkAllowed && (
        <React.Fragment>
          <TextBlockView
            label={t('Rider Widget.Rider ID')}
            data={rider.id}
            identifier={riderDataPointIdentifiers.rider_id}
          />
          <Divider className={classes.divider} />
        </React.Fragment>
      )}

      {/* customer status */}
      {rider?.status && isStatusAllowed ? (
        <React.Fragment>
          <TextBlockView
            label={t('Last Orders Widget.Status')}
            data={rider.status}
            identifier={riderDataPointIdentifiers.rider_status}
          />

          <Divider className={classes.divider} />
        </React.Fragment>
      ) : null}

      {/* contract type*/}
      {rider?.contractType && isContractTypeAllowed ? (
        <React.Fragment>
          <TextBlockView
            label={t('Rider Widget.Contract Type')}
            data={rider.contractType}
            identifier={riderDataPointIdentifiers.rider_contrat_type}
          />
          <Divider className={classes.divider} />
        </React.Fragment>
      ) : null}

      {/* city */}
      {rider?.city && isCityAllowed ? (
        <React.Fragment>
          <TextBlockView
            label={t('Rider Widget.City')}
            data={rider.city}
            identifier={riderDataPointIdentifiers.rider_city}
          />
          <Divider className={classes.divider} />
        </React.Fragment>
      ) : null}

      {/* vehicle */}
      {rider?.vehicle && isVehicleAllowed ? (
        <React.Fragment>
          <TextBlockView
            label={t('Rider Widget.Vehicle Type')}
            data={rider.vehicle}
            identifier={riderDataPointIdentifiers.rider_vehicle}
          />
          <Divider className={classes.divider} />
        </React.Fragment>
      ) : null}

      {/* current shift */}
      {rider?.currentShift && isCurrentShiftAllowed ? (
        <React.Fragment>
          <TextBlockView
            label={t('Rider Widget.Current Shift')}
            data={rider.currentShift}
            identifier={riderDataPointIdentifiers.rider_current_shift}
          />
          <Divider className={classes.divider} />
        </React.Fragment>
      ) : null}

      {/* phone */}
      {rider?.phone && isPhoneAllowed ? (
        <React.Fragment>
          <div className={classes.riderPhoneHolder}>
            <div className={classes.textBlockHolder}>
              <TextBlockView
                label={t('Vendor Widget.Phone')}
                data={rider.phone}
                identifier={riderDataPointIdentifiers.rider_phone}
              />
            </div>

            {isCopyToClipboard ? (
              <Button type='link' size='large' onClick={() => handleCopyToClipboard(rider.phone)}>
                <CopyOutlined />
              </Button>
            ) : null}
          </div>
          <Divider className={classes.divider} />
        </React.Fragment>
      ) : null}
    </div>
  )
}

export default Content
