import React from 'react'
import { createCustomSvgIcon } from 'factory/createCustomSvgIcon'

export const DigitalPaymentIcon = createCustomSvgIcon({
  viewBoxWidth: 16,
  viewBoxHeight: 18,
  content: (
    <>
      <mask id='digipayment_path-1-inside-1_2552_3594' fill='white'>
        <rect width='13.5527' height='18' rx='0.742188' />
      </mask>
      <rect
        width='13.5527'
        height='18'
        rx='0.742188'
        strokeWidth='2.9'
        fill='white'
        stroke='currentColor'
        mask='url(#digipayment_path-1-inside-1_2552_3594)'
      />
      <mask id='digipayment_path-2-inside-2_2552_3594' fill='white'>
        <rect x='3.42773' y='4.5' width='12.375' height='9' rx='0.742188' />
      </mask>
      <rect
        x='3.42773'
        y='4.5'
        width='12.375'
        height='9'
        rx='0.742188'
        strokeWidth='2.9'
        mask='url(#digipayment_path-2-inside-2_2552_3594)'
        fill='white'
        stroke='currentColor'
      />
      <path
        d='M11.1904 9C11.1904 9.86996 10.4852 10.5752 9.61523 10.5752C8.74528 10.5752 8.04004 9.86996 8.04004 9C8.04004 8.13004 8.74528 7.4248 9.61523 7.4248C10.4852 7.4248 11.1904 8.13004 11.1904 9Z'
        strokeWidth='0.927734'
        stroke='currentColor'
      />
      <line
        x1='4.00273'
        y1='3.375'
        x2='4.00273'
        y2='14.625'
        strokeWidth='1.15'
        stroke='currentColor'
      />
    </>
  ),
})
