import { DataContext } from 'contexts/data/DataContext'
import { DataAction } from 'contexts/data/types'
import { SessionContext } from 'contexts/session/SessionContext'
import { useApiService } from 'hooks/useApiService'
import { useContext } from 'react'
import { getOrderFulfillment } from 'services/fulfillmentApi/getOrderFulfillment'

const { SET_FULFILLMENT } = DataAction

export function useLoadOrderFulfillment() {
  const {
    dataState: { modifiedDeliverySettings, autoChangeDeliveryTimeComment },
    dataDispatch,
  } = useContext(DataContext)

  const {
    sessionState: { orderId, globalEntityId },
  } = useContext(SessionContext)

  return useApiService({
    service: getOrderFulfillment,
    params: {
      entityId: globalEntityId,
      orderId,
    },
    shouldLoad: Boolean(orderId && globalEntityId),
    deps: [modifiedDeliverySettings, autoChangeDeliveryTimeComment],

    onSuccess: ({ data }) => {
      dataDispatch({
        type: SET_FULFILLMENT,
        payload: { fulfillment: data },
      })
    },
  })
}
