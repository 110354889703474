export default {
  loadingHolder: {
    marginTop: '25px',
  },
  commentsContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  comments: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '500px',
    maxHeight: '500px',
    overflow: 'scroll',
    marginTop: '5px',
  },
  filterContainer: {
    width: '270px',
    backgroundColor: 'rgba(0, 0, 0, 0.09)',
    padding: '0px 8px 8px 8px',
  },
  checkbox: {
    marginTop: '10px',
  },
  singleCommentContainerOneview: {
    backgroundColor: '#E6F7FF',
    padding: '3px',
  },
  singleCommentContainer: {
    padding: '3px',
  },
  divider: {
    margin: '8px 0px 8px 0px',
  },
  addedByOneviewHighlight: {
    color: '#2A8FF7',
  },
}
