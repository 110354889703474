import React from 'react'
import { Box } from 'shared/Box'
import { SmartToolTip } from 'shared/SmartTooltip'
import { CopyButton } from 'shared/CopyButton'
import { Text } from 'shared/Text'
import { TransformedDataPoint } from 'types/dataTransformers/generic'
import { DataPoint } from 'shared/DataPoint'

export const DefaultListItemDataPoint = ({
  item,
  canCopy = false,
  onCopyClick,
  name,
}: {
  item: TransformedDataPoint
  canCopy?: boolean
  onCopyClick?: () => void
  name?: string
}) => {
  const { label, value, StartIcon, EndIcon, tooltip, showTooltip = true, color } = item

  const dataPointValue = (
    <>
      {StartIcon && <StartIcon style={{ marginRight: '5px' }} />}
      <Text.Primary color={color} Component='span'>
        {value}
        {canCopy && <CopyButton marginLeft={4} text={`${item.value}`} onCopy={onCopyClick} />}
      </Text.Primary>
      {EndIcon && <EndIcon style={{ marginLeft: '5px' }} />}
    </>
  )

  return (
    <SmartToolTip
      title={
        tooltip?.length > 0 ? (
          <>
            {tooltip.map((field, idx) => {
              return (
                <Box key={idx} display='flex' p={5}>
                  <Text.Primary
                    w='100px'
                    minW='100px'
                    color='#f6f6f6'
                    fontWeight='600'
                  >{`${field.key}: `}</Text.Primary>
                  <Text.Primary color='#f6f6f6'>{field.value}</Text.Primary>
                </Box>
              )
            })}
          </>
        ) : null
      }
      disabled={!showTooltip}
    >
      {/* Wrapper is needed for tooltip to work, see https://github.com/ant-design/ant-design/issues/15909*/}
      <div>
        <DataPoint type='custom' label={label} render={dataPointValue} name={name} />
      </div>
    </SmartToolTip>
  )
}
