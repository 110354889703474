import React from 'react'
import { createUseStyles } from 'react-jss'
import { Tooltip } from 'antd'
import { useCanDisplayDataPoint } from 'hooks/useGetValidFeatures'
import { DataPointWithBetaRequirementConfig } from 'contexts/entity/types'
import { TransformedDataPoint } from 'types/dataTransformers/generic'
import { CopyButton } from '../../shared/CopyButton'
import { Text } from '../../shared/Text'
import { DataPoint } from '../../shared/DataPoint'
import styles from './SimpleDataPointListItem.styles'

const useStyles = createUseStyles(styles)

type Props = {
  canCopy?: boolean
  showTooltip?: boolean
  item: TransformedDataPoint
  config?: DataPointWithBetaRequirementConfig
  onCopyClick?: () => void
  name?: string
}

type ValueProps = Pick<Props, 'item' | 'showTooltip'>

type CopyProps = Pick<Props, 'item' | 'onCopyClick'>

const Value = ({ showTooltip, item }: ValueProps) => {
  const classes = useStyles()

  const value = (
    <Text.Primary className={`${classes.text} ${classes.textOverflow}`}>{item.value}</Text.Primary>
  )

  if (showTooltip) {
    return <Tooltip title={item.value}>{value}</Tooltip>
  }

  return value
}

const Copy = ({ item, onCopyClick }: CopyProps) => {
  const classes = useStyles()

  return (
    <Text.Primary className={`${classes.text} ${classes.copy}`}>
      <CopyButton text={`${item.value}`} onCopy={onCopyClick} />
    </Text.Primary>
  )
}

const SimpleDataPointListItem = ({
  canCopy,
  showTooltip,
  item,
  config,
  onCopyClick,
  name,
}: Props) => {
  const canDisplayDataPoint = useCanDisplayDataPoint()
  const classes = useStyles()
  const canDisplay = canDisplayDataPoint(
    item.value,
    config || { displayRule: [], betaRequirement: [] },
  )
  const label = item.label || item.key

  if (!canDisplay) {
    return <DataPoint type='default' label={label} value='-' name={name} />
  }

  const dataPointValue = (
    <div className={classes.title}>
      <Value showTooltip={showTooltip} item={item} />
      {canCopy && <Copy item={item} onCopyClick={onCopyClick} />}
    </div>
  )

  return <DataPoint type='custom' label={label} render={dataPointValue} name={name} />
}

export default SimpleDataPointListItem
