export default {
  orderBody: {
    marginTop: '20px',
  },
  divider: {
    padding: 0,
    margin: '12px 0px 12px 0px',
  },
  deliveryInstDivider: {
    padding: 0,
    margin: '12px 0px 0px 0px',
  },
  statusDivider: {
    padding: 0,
    margin: '0px 0px 0px 0px',
  },
  riderEventsDivider: {
    padding: 0,
    margin: '0px 0px 10px 0px',
  },
  idDivider: {
    padding: 0,
    margin: '0px 0px 12px 0px',
  },
  orderIdHolder: {
    display: 'flex',
    alignItems: 'baseline',
  },
  spinnerHolder: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  spinner: {
    marginBottom: '10px',
  },
  loadingText: {
    color: '#828281',
    fontSize: '12px',
  },
}
