import React, { FC } from 'react'
import { Popconfirm as AntPopconfirm, PopconfirmProps } from 'antd'
import { useTranslation } from 'hooks/useTranslation'
import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles({
  popconfirm: (props: PopconfirmProps) => {
    return {
      '& .ant-popover-inner-content': {
        width: '260px',
        paddingBlock: '12px',
        paddingInline: '16px',
      },
      '& .ant-popover-inner': {
        borderRadius: '4px',
      },
      '& .ant-popover-buttons': {
        display: 'flex',
        justifyContent: 'end',
        gap: '8px',
        marginBottom: 0,
      },
      '& .ant-popover-buttons > .ant-btn': {
        margin: 0,
      },
      '& .ant-popover-message': {
        padding: 0,
        paddingBottom: '12px',
      },
      '& .ant-popover-message-title': {
        display: 'flex',
        flexDirection: 'column',
        gap: '8px',
        paddingLeft: props.icon ? '22px' : 0,
      },
    }
  },
})

export const Popconfirm: FC<PopconfirmProps & { disableConfirm?: boolean }> = ({
  disableConfirm = false,
  ...rest
}) => {
  const { t } = useTranslation()
  const classes = useStyles(rest)

  return (
    <AntPopconfirm
      overlayClassName={classes.popconfirm}
      title={t('Are you sure you want to restore this item?')}
      okText={t('Confirm')}
      cancelText={t('Cancel')}
      okButtonProps={{
        size: 'middle',
        disabled: disableConfirm,
      }}
      cancelButtonProps={{
        size: 'middle',
      }}
      {...rest}
    />
  )
}
