import React, { useCallback, useMemo } from 'react'
import { Space } from 'antd'
import { ActionHandler } from 'types/unitedUiConfig'
import MoreActionMenu from './MoreActionsMenu'
import { MAX_VISIBLE_RESOLUTION_BAR_ACTIONS } from 'constants/constants'
import { ActionHandlerButton } from 'shared/ActionHandlerButton'
import { useSdk } from 'contexts/SdkProvider'

type Props = {
  wrapUpActionHandler: ActionHandler
  actionHandlers: ActionHandler[]
}

const ActionButtons = ({ actionHandlers, wrapUpActionHandler }: Props) => {
  const sdk = useSdk()
  const { activateWidgetView, checkDisplayRules } = sdk

  const canDisplayWrapUp = checkDisplayRules({
    displayRules: wrapUpActionHandler.display_rules,
  }).enabled

  const { visibleActionHandlers, invisibleActionHandlers } = useMemo(() => {
    const activeActionHandlers = actionHandlers.filter(
      (action) => checkDisplayRules({ displayRules: action.display_rules }).visible,
    )

    return {
      visibleActionHandlers: activeActionHandlers.slice(0, MAX_VISIBLE_RESOLUTION_BAR_ACTIONS),
      invisibleActionHandlers: activeActionHandlers.slice(MAX_VISIBLE_RESOLUTION_BAR_ACTIONS),
    }
  }, [checkDisplayRules, actionHandlers])

  const onClick = useCallback(
    (handler: ActionHandler['handler']) => activateWidgetView(handler),
    [activateWidgetView],
  )

  return (
    <Space>
      {visibleActionHandlers.map((actionHandler) => (
        <ActionHandlerButton
          actionHandler={actionHandler}
          key={actionHandler.action.label_translation_key}
        />
      ))}

      {invisibleActionHandlers.length > 0 && (
        <MoreActionMenu actionHandlers={invisibleActionHandlers} onClick={onClick} />
      )}

      {canDisplayWrapUp && <ActionHandlerButton actionHandler={wrapUpActionHandler} />}
    </Space>
  )
}

export default ActionButtons
