import { AuthPayload, TrackingAuthPayload } from 'contexts/auth/AuthProvider'

export const readExpires = () => {
  return Number.parseInt(window.localStorage.getItem('expires') || '0', 10)
}

export const writeAuthPayload = (
  payload: AuthPayload,
  localStorage: Storage = window.localStorage,
) => localStorage.setItem('auth-payload', JSON.stringify(payload || {}))

export const readAuthPayload = (
  localStorage: Storage = window.localStorage,
  payloadString?: string,
): AuthPayload => {
  if (typeof payloadString === 'undefined') {
    payloadString = localStorage.getItem('auth-payload') || '{}'
  }

  const result: AuthPayload = JSON.parse(payloadString) || {}
  result.namespaces = result.namespaces || []
  result.permissions = result.permissions || {}
  result.refreshAfter = result.refreshAfter || 0
  result.refresh = result.refresh || ''

  return result
}

export const writeTrackingAuthPayload = (
  payload: TrackingAuthPayload,
  localStorage: Storage = window.localStorage,
) => {
  localStorage.setItem('tracking-payload', JSON.stringify(payload || {}))
}

export const readTrackingAuthPayload = (
  localStorage: Storage = window.localStorage,
): TrackingAuthPayload => {
  const result: TrackingAuthPayload =
    JSON.parse(localStorage.getItem('tracking-payload') || '{}') || {}
  result.refreshAfter = result.refreshAfter || 0
  return result
}

/**
 * Retrieves a cookie by name, null if not found
 * @param {string} name target name of the cookie
 * @returns {string} cookie value
 */
export function getCookieByName(name: string) {
  const cookies = document.cookie.split('; ')
  const target = cookies.find((row) => row.startsWith(name))

  if (!target) {
    return null
  }

  const [, value] = target.split('=')
  return value
}
