export default {
  voucherCountContainer: {
    width: 'fit-content',
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    '& > span': {
      'white-space': 'nowrap',
    },
    '& > span:first-child': {
      paddingRight: '17px',
    },
    '& > *:nth-child(n+2)': {
      paddingRight: '12px',
    },
  },
}
