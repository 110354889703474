import React, { useMemo } from 'react'
import { Button, Col, Row, Space } from 'antd'
import { ReloadOutlined } from '@ant-design/icons'
import { ContactPerson } from './types'
import useStyles from './ResolutionBar.style'
import ContactInfo from './ContactInfo/ContactInfo'
import ActionButtons from './ActionButtons/ActionButtons'
import { LinesOfBusiness, UnitedUiConfig } from 'types'
import { createPluggableWidget } from 'factory/createPluggableWidget'
import { useLoadFraudStatus } from 'hooks/useLoadFraudStatus'

export const ResolutionBar = createPluggableWidget<
  UnitedUiConfig['resolution_bar'] & {
    fraudFlag: UnitedUiConfig['fraud_flag']
  }
>(
  ({ customer, lob, config, globalEntityId, orderId, vendor, rider, sdk }) => {
    const classes = useStyles()
    const { action_handlers, wrap_up_action_handler, fraudFlag } = config

    const { api_version, display_rules } = fraudFlag || {}

    const hasRequiredParams = Boolean(customer?.customer_id && globalEntityId)

    const { fraudStatus, error: loadFraudStatusError } = useLoadFraudStatus({
      orderId,
      globalEntityId,
      customerId: customer?.customer_id,
      apiVersion: api_version,
      // Only load if it's customer lob and meet config requirements
      shouldLoadFraudFlag:
        lob === LinesOfBusiness.customer &&
        sdk.checkDisplayRules({ displayRules: display_rules }).visible &&
        hasRequiredParams,
    })

    const contactPerson = useMemo((): ContactPerson => {
      switch (lob) {
        case LinesOfBusiness.customer:
          if (customer) {
            return {
              name:
                customer.profile?.name ||
                [customer.profile?.first_name, customer.profile?.last_name]
                  .filter(Boolean)
                  .join(' '),
              error: loadFraudStatusError?.errorPayload?.errorMessage,
              fraudStatus: fraudStatus,
            }
          }
          break
        case LinesOfBusiness.vendor:
          if (vendor) {
            return {
              name: vendor.name || vendor.names.join(' '),
            }
          }
          break
        case LinesOfBusiness.riderV2:
          if (rider) {
            return {
              name: rider.courier.name,
            }
          }
          break
      }
      return null
    }, [customer, lob, rider, vendor, fraudStatus, loadFraudStatusError])

    return (
      <Row justify='space-between' align='middle'>
        <Col>
          <ContactInfo contactPerson={contactPerson} />
        </Col>
        <Col>
          <Space>
            <Button
              ghost
              type='primary'
              onClick={() => sdk.reloadPage()}
              className={classes.reloadBtn}
              icon={<ReloadOutlined type='primary' />}
            />
            <ActionButtons
              actionHandlers={action_handlers}
              wrapUpActionHandler={wrap_up_action_handler}
            />
          </Space>
        </Col>
      </Row>
    )
  },
  {
    category: 'action',
    deriveConfig({ entityConfig }) {
      return {
        ...entityConfig.layout_v2.resolution_bar,
        fraudFlag: entityConfig.layout_v2.fraud_flag,
      }
    },
  },
)
