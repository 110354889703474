import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

/**
 * hook to use query params
 * @returns
 */
export const useQueryParams = () => {
  const location = useLocation()
  const [queryParams, setQueryParams] = useState<Map<string, string>>(new Map())

  const search = location?.search
  useEffect(() => {
    const newQueryParams = new Map()
    ;(search || '')
      .substring(1)
      .split('&')
      .forEach((token) => {
        const [name, value] = token.split('=')
        newQueryParams.set(name, value || '')
      })

    setQueryParams(newQueryParams)
  }, [search])
  return queryParams
}
