import { Alert, Col, Row, Typography, Input } from 'antd'
import { useTranslation } from 'hooks/useTranslation'
import React, { useContext, useMemo } from 'react'

import fixNumber from 'utils/fixNumber'
import { RefundIssueType } from 'contexts/entity/types'
import { DataContext } from 'contexts/data/DataContext'
import { EntityContext } from 'contexts/entity/EntityContext'
import { ItemStructured } from 'types/actions/partialRefund'
import useStyles from './AdditionalDetails.style'
import { calculateMissingOrderItemTotalPrice } from 'utils/orderItems/orderItemPricings'

type Props = {
  selectedIssueType: RefundIssueType
  notes: string
  onNotesChange: (value: string) => void
  selectedItems: ItemStructured[]
  partiallySelectedItems: ItemStructured[]
}

const AdditionalDetails = ({
  notes,
  onNotesChange,
  selectedItems,
  partiallySelectedItems,
  selectedIssueType,
}: Props) => {
  const classes = useStyles()
  const { Text } = Typography
  const { t } = useTranslation()
  // pull actions config from context
  const {
    entityState: {
      entityConfig: {
        fixed_panel_config: {
          widgets_configs: {
            actions: {
              partial_refund: { partialItemRefundPercentage, refundAmountPrecision },
            },
          },
        },
      },
    },
  } = useContext(EntityContext)

  // pull order from data context
  const {
    dataState: { currency },
  } = useContext(DataContext)

  const items = useMemo(() => {
    return [...selectedItems, ...partiallySelectedItems].map((item) => {
      const itemPrice = calculateMissingOrderItemTotalPrice(item, {
        considerPartialSelection: true,
        partialItemRefundPercentage,
      })
      const itemName = item.partially_selected ? item.partial_description : item.display_name
      const text = `${item.missing_quantity} X ${
        itemName || item.display_name
      } at ${currency} ${fixNumber(itemPrice, refundAmountPrecision)}`
      return { id: item.id, text }
    })
  }, [
    selectedItems,
    partiallySelectedItems,
    refundAmountPrecision,
    currency,
    partialItemRefundPercentage,
  ])

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    onNotesChange(event.target.value)
  }

  return (
    <div>
      {/* voucher info -> value, min. order value, validity and payment type */}
      <Row gutter={6} className={classes.row}>
        <Col>
          <Text className={classes.field}>{`${t('Widgets Common.Issue type')}:`}</Text>
        </Col>
        <Col>
          <Text className={classes.value}>
            {t(
              `Actions Widget.Actions.Compensation.Issue Types.${selectedIssueType.issue_translation_key}`,
            )}
          </Text>
        </Col>
      </Row>
      <Row gutter={6} className={classes.row}>
        <Col>
          <Text className={classes.field}>{`${t('Widgets Common.Affected items')}:`}</Text>
        </Col>
        <Col>
          <ul className={classes.list}>
            {items.map((item) => (
              <li className={classes.listItem} key={item.id}>
                {item.text}
              </li>
            ))}
          </ul>
        </Col>
      </Row>
      <div className={classes.alert}>
        <Alert
          message={`${t(
            'Messages.All information above is automatically added to refund case with order ID and customer ID',
          )}`}
          type='info'
          showIcon
        />
      </div>
      <Row gutter={2}>
        <Col xs={6}>
          <Text className={classes.field}>{`${t(
            'Widgets Common.Additional notes to refund team',
          )}:`}</Text>
        </Col>
        <Col xs={18}>
          <Input.TextArea
            data-test-id={'refund-additional-details'}
            rows={3}
            value={notes}
            onChange={handleChange}
            placeholder={t('Widgets Common.Bank account details')}
          />
        </Col>
      </Row>
    </div>
  )
}

export default AdditionalDetails
