import { createUseStyles } from 'react-jss'

export const useStyles = createUseStyles({
  root: {
    marginBottom: '8px',
  },
  tag: {
    borderRadius: '11px',
    marginRight: 0,
  },
})
