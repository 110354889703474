import { DeliveryProviders, orderStages } from 'types/unitedUiConfig'
import { CancellationReason } from '../../contexts/entity/types'

export const createFilterByDeliveryProvider =
  (deliveryProvider: DeliveryProviders) => (reason: CancellationReason) => {
    return !reason.providers?.length || reason.providers.includes(deliveryProvider)
  }

export const createFilterByOrderStage = (stage: orderStages) => (reason: CancellationReason) => {
  return !reason.stages?.length || reason.stages.includes(stage)
}
