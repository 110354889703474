export default {
  riderContainer: {
    margin: '0px 3px 0px 3px',
  },
  titleHolder: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'baseline',
  },
  sectionTitle: {
    fontSize: '18px',
    color: 'rgba(0, 0, 0, 0.85)',
  },
}
