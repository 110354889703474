import { DataContext } from 'contexts/data/DataContext'
import { RiderServiceContext } from 'contexts/riderService/RiderServiceContext'
import { useSessionState } from 'hooks/useSessionState'
import { useContext, useMemo } from 'react'
import { FulfillmentApiRiderResponse } from 'types/herocare'
import { LinesOfBusiness } from 'types/session/linesOfBusiness'
import {
  WidgetSubjects,
  WidgetSubject,
  WidgetSubjectsResolutionStates,
  OverridableWidgetSubjects,
} from 'types/unitedUiConfig'

export const useWidgetSubjects = (overrides?: OverridableWidgetSubjects) => {
  const {
    globalEntityId,
    lineOfBusiness,
    caseId,
    ccrCode,
    uiVersion,
    widgetContainerType,
    userId: customerId,
    vendorId,
    riderId,
    orderId,
    activeTab,
    globalVendorId,
    lang,
  } = useSessionState()

  const {
    dataState: {
      order,
      vendor,

      customer,
      ticket,

      rider,
      riderCurrentOrder,
      riderCurrentOrderVendor,

      fulfillment,
    },
  } = useContext(DataContext)

  const {
    riderServiceState: {
      order: orderFromRiderLob,
      vendor: vendorFromRiderLob,
      rider: riderFromRiderLob,
    },
  } = useContext(RiderServiceContext)

  const { containerType, delivery } = overrides || {}

  // default subjects
  const subjects = useMemo((): Required<WidgetSubjects> => {
    const isRiderLob = lineOfBusiness === LinesOfBusiness.rider

    /**
     * if the delivery's courier is different from the rider in url, override it
     */
    const resolvedRider: FulfillmentApiRiderResponse =
      delivery && delivery?.courier?.id !== rider?.courier?.id
        ? {
            courier: delivery.courier,
          }
        : isRiderLob
        ? riderFromRiderLob
        : rider

    const isDifferentRider = String(resolvedRider?.courier?.id) !== riderId

    return {
      globalEntityId,
      globalVendorId,
      lob: lineOfBusiness,
      lang,

      caseId,
      ccrCode,

      uiVersion,
      containerType: containerType || widgetContainerType,

      order: isRiderLob ? orderFromRiderLob : order,
      vendor: isRiderLob ? vendorFromRiderLob : vendor,

      rider: resolvedRider,
      riderId: String(resolvedRider?.courier?.id) || riderId,
      riderCurrentOrder: isDifferentRider ? null : riderCurrentOrder,
      riderCurrentVendor: isDifferentRider ? null : riderCurrentOrderVendor,
      customer,
      ticket,
      orderFulfillment: fulfillment,

      customerId,
      vendorId,

      orderId,
      activeTab,
      delivery,
    }
  }, [
    lineOfBusiness,
    delivery,
    rider,
    riderFromRiderLob,
    riderId,
    globalEntityId,
    globalVendorId,
    lang,
    caseId,
    ccrCode,
    uiVersion,
    containerType,
    widgetContainerType,
    orderFromRiderLob,
    order,
    vendorFromRiderLob,
    vendor,
    riderCurrentOrder,
    riderCurrentOrderVendor,
    customer,
    ticket,
    fulfillment,
    customerId,
    vendorId,
    orderId,
    activeTab,
  ])

  const subjectsResolutionStates = useMemo((): WidgetSubjectsResolutionStates => {
    const keys = Object.keys(subjects) as WidgetSubject[]
    return keys.reduce((result, key) => {
      result[key] = {
        status: 'success',
        errorPayload: null,
        value: subjects[key],
        onRetry: undefined,
      }
      return result
    }, {} as WidgetSubjectsResolutionStates)
  }, [subjects])

  return { subjects, subjectsResolutionStates }
}
