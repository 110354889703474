import { ApiService } from 'services/authApi/getPermissions'
import { VendorApiResponse } from 'types/api/vendorApi/vendor'

export const getVendor: ApiService<
  { entityId: string; vendorId?: string; globalVendorId?: string },
  VendorApiResponse
> = (createClient, options) => {
  const { entityId, clientParams, vendorId, globalVendorId } = options
  const uri = `/VendorAPI/v1/vendors/${entityId}/search`

  const params: {
    global_vendor_id?: string
    vendor_id?: string
  } = {}

  if (vendorId) {
    params.vendor_id = vendorId
  } else {
    params.global_vendor_id = globalVendorId
  }

  return createClient({
    endpointName: 'getVendor',
    expectedResponseStatusCode: 200,
    ...clientParams,
  })
    .get(uri, {
      params,
    })
    .then((response) => {
      return {
        ...response,
        data: response.data?.[0] || null,
      }
    })
}
