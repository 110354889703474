import moment from 'moment-timezone'

type Interval = {
  hour: number
  min: number
}

export const generateIntervalsTillShiftEnd = (currentShiftEndTime: string): Interval[] => {
  if (!currentShiftEndTime) {
    return []
  }
  // calculate number of min to shift end from now
  const now = moment()
  const endTime = moment(currentShiftEndTime)
  const timeToShiftEnd = endTime.diff(now, 'minute')

  // if input timestamp is in past
  if (timeToShiftEnd <= 0) {
    return []
  }

  // divide the time into 5 min intervals
  // e,g 11 => [5,10,11]
  const intervals: Interval[] = []
  for (let i = 5; i <= timeToShiftEnd; i += 5) {
    const hour = Math.floor(i / 60)
    intervals.push({
      hour,
      min: i - hour * 60, // remaining minutes, after carving out hours
    })
  }
  if (timeToShiftEnd % 5 !== 0) {
    const hour = Math.floor(timeToShiftEnd / 60)
    intervals.push({
      hour,
      min: timeToShiftEnd - hour * 60,
    })
  }

  return intervals
}
