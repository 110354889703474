import manifest from '../herocare-plugin-demo/manifest.json'
import { Plugin } from '../herocare-plugin-demo/src/Plugin'
import config from '../herocare-plugin-demo/config.json'
import { PluginTargetWidget, PluginType } from '../herocare-plugin-demo/src/Plugin'
import { PluggableWidget } from 'types/unitedUiConfig'

export const plugins: Map<
  string,
  {
    plugin: PluggableWidget<{}>

    manifest: {
      name: string
      scopedName: string
      codeName: string
      type: PluginType
      title: string
      widget?: PluginTargetWidget
      authorEmail: string
      slackChannel: string
      showLabel?: boolean
    }

    config: any
  }
> = new Map([[manifest.codeName, { plugin: Plugin, manifest: manifest as any, config }]])
