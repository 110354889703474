import React from 'react'
import GoogleMapReact from 'google-map-react'
import { createUseStyles } from 'react-jss'
import { MapMarkerIcon } from 'Icons'
import { pink } from 'theme'
import environment from 'envConfig'

export const useStyles = createUseStyles({
  map: {
    width: '200px',
    height: '140px',
  },
  pin: {
    width: '13px',
    height: '17px',
    position: 'absolute',
    bottom: 0,
    color: pink.pink6,
  },
})

const VendorLocationPin: React.FC<{ lat: number; lng: number }> = () => {
  const classes = useStyles()

  return <MapMarkerIcon className={classes.pin} />
}

type AddressMapProps = {
  latitude: number
  longitude: number
}

export const AddressMap: React.FC<AddressMapProps> = ({ latitude, longitude }) => {
  const classes = useStyles()

  const mapLocation = {
    lat: latitude,
    lng: longitude,
  }

  return (
    <div className={classes.map}>
      <GoogleMapReact
        defaultCenter={mapLocation}
        defaultZoom={14}
        bootstrapURLKeys={{ key: environment().googleMapsApiKey }}
      >
        <VendorLocationPin lat={mapLocation.lat} lng={mapLocation.lng} />
      </GoogleMapReact>
    </div>
  )
}
