import React, { useMemo } from 'react'
import { OrderApiResponse } from 'types/herocare'
import { DeliveryItem, FulfillmentApiResponse } from 'types/api/fulfillmentApi/fulfillment'
import { DataPointProps } from 'shared/DataPoint'
import { useTranslation } from 'hooks/useTranslation'
import { BuiltinWidgetConfigs, SDK } from 'types'
import { DataPoints } from 'shared'

type Props = {
  order?: OrderApiResponse
  delivery?: DeliveryItem
  fulfillment?: FulfillmentApiResponse
  data_points?: BuiltinWidgetConfigs['order_deliveries']['riders_history_tab']['data_points']
  sdk: SDK
}

export const RiderDetails = ({ delivery, order, fulfillment, data_points = [], sdk }: Props) => {
  const issue = (fulfillment?.issues || []).find((issue) => issue.delivery_id === delivery.id)
  const { t } = useTranslation()

  const { captureUserAction } = sdk

  const dataPoints = useMemo(
    () =>
      data_points.map((dataPoint): DataPointProps => {
        const { name, display_rules } = dataPoint

        const label = t(dataPoint.label_translation_key)

        switch (name) {
          case 'rider_id':
            return {
              label,
              type: 'default',
              allowCopy: true,
              value: String(delivery?.courier?.id || ''),
              onCopy: () => {
                captureUserAction('COPY_RIDER_ID', {
                  screenDetails: {
                    tab: 'Rider',
                  },
                })
              },
              name,
              display_rules,
            }

          case 'delivery_issue':
            return { label, type: 'default', value: issue?.type, name, display_rules }

          case 'delivery_instructions':
            return {
              label,
              type: 'default',
              value: order?.delivery?.location?.description,
              name,
              display_rules,
            }

          case 'phone_number':
            return {
              label,
              type: 'tel',
              value: delivery?.courier?.phone_number,
              allowCopy: true,
              onDial() {
                captureUserAction('DIAL_RIDER_PHONE_NUMBER', {
                  screenDetails: {
                    tab: 'Rider',
                  },
                })
              },
              name,
              display_rules,
            }

          case 'rider_name':
            return {
              label,
              type: 'default',
              value: delivery?.courier?.name,
              name,
              display_rules,
            }

          case 'vehicle':
            return {
              label,
              type: 'default',
              value: delivery?.courier?.vehicle_type,
              name,
              display_rules,
            }

          default:
            return null
        }
      }),
    [data_points, captureUserAction, t, delivery, order, issue],
  )

  return <DataPoints dataPoints={dataPoints} gutter={[0, 6]} mt={15} />
}
