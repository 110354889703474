import { ApiService } from 'services/authApi/getPermissions'
import { OrderStatuses } from 'types/widgets/order/orderStatuses'

export const patchDeliveryTime: ApiService<{
  entityId: string
  orderId: string
  orderStatus: OrderStatuses
  timings: {
    preordered_for: string
  }
}> = (createClient, options) => {
  const {
    entityId,
    orderId,
    orderStatus,

    timings,

    clientParams,
  } = options

  const uri = `OrdersAPI/v1/orders/${entityId}/${orderId}/delivery-time`
  const { tParams, ...otherClientParams } = clientParams || {}

  return createClient({
    endpointName: 'patchDeliveryTime',
    expectedResponseStatusCode: [200, 202],

    tParams: {
      orderStatus,
      ...tParams,
    },
    ...otherClientParams,
  }).patch(uri, { timings })
}
