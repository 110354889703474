import React, { ComponentPropsWithoutRef, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import SummaryPanelDataPoints from '../SummaryPanelDataPoints/SummaryPanelDataPoints'
import SummaryPanelMessage from '../SummaryPanelMessage/SummaryPanelMessage'
import { LinesOfBusiness, PluggableWidget } from 'types/herocare'
import { BuiltinWidgetConfigs } from 'types'
import { useCheckDisplayRules } from 'hooks/useCheckDisplayRules'
import { CommentsSummary } from '../CommentsSummary/CommentsSummary'

const GenericSummaryPanel = ({
  config,
  lob,
  order,
  orderFulfillment,
  vendor,
  rider,
  IconRenderer,
}: ComponentPropsWithoutRef<PluggableWidget<BuiltinWidgetConfigs['summary_panel']>>) => {
  const { t } = useTranslation()
  const checkDisplayRules = useCheckDisplayRules()

  const shouldHaveOrderInfo = [LinesOfBusiness.customer, LinesOfBusiness.vendor].includes(lob)

  const canDisplayCommentTags = useMemo(() => {
    const commentTagsDataPoint = config.data_points.find(
      (dataPoint) => dataPoint.name === 'comment_tags',
    )
    return checkDisplayRules(commentTagsDataPoint?.display_rules).visible
  }, [config.data_points, checkDisplayRules])

  if (!order && shouldHaveOrderInfo) {
    return <SummaryPanelMessage message={t('widgets.summary_panel.no_order')} />
  }

  return (
    <div>
      <SummaryPanelDataPoints
        IconRenderer={IconRenderer}
        config={config}
        order={order}
        rider={rider}
        fulfillment={orderFulfillment}
        vendor={vendor}
      />
      {canDisplayCommentTags && <CommentsSummary />}
    </div>
  )
}

export default GenericSummaryPanel
