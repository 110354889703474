// libs
import React, { useState, useContext, useImperativeHandle, useMemo } from 'react'
import moment, { Moment } from 'moment-timezone'
// contexts and types
import { DataContext } from 'contexts/data/DataContext'
import { SessionContext } from 'contexts/session/SessionContext'
import { DataAction } from 'contexts/data/types'
// hooks
import { useTranslation } from 'hooks/useTranslation'
// styles
import { createUseStyles } from 'react-jss'
import styles from './ModalContent.styles'
import { Typography, Alert, Button, Result } from 'antd'
// components
import DatePickerView from './DatePickerView'
import TimePickerView from './TimePickerView'
import { patchDeliveryTime } from 'services/ordersApi/patchDeliveryTime'
import { useClearCommentsFromDataContext } from 'hooks/useClearCommentsFromDataContext'
import { useOrderStatus } from 'hooks/useOrderStatus'
import { useApiService } from 'hooks/useApiService'
import { WidgetErrorHandler } from 'components/WidgetErrorHandler/widgetErrorHandler'
import { useCaptureUserAction } from 'hooks/events/useCaptureUserAction'
import { ChangeDeliveryTime } from 'contexts/entity/types'
import { createPluggableWidget } from 'factory/createPluggableWidget'

const useStyles = createUseStyles(styles)

function calculateDates(
  placementDate: string,
  promisedDate: string,
  utcZone: string,
): {
  placementDateTimestamp: string
  fourteenDaysFromPlacementTimestamp: string
  promisedDateTimestamp: string
} {
  // order placement with offset added
  const placementDateTimestamp = moment(placementDate).tz(utcZone).format()

  // 14 days in the future from order placement, with offset added
  const orderPlacedDate = new Date(placementDate)
  orderPlacedDate.setHours(orderPlacedDate.getHours() + 336)
  const fourteenDaysFromPlacementTimestamp = moment(orderPlacedDate).tz(utcZone).format()

  // promised del time with offset added
  const promisedDateTimestamp = moment(promisedDate).tz(utcZone).format()

  return {
    placementDateTimestamp,
    fourteenDaysFromPlacementTimestamp,
    promisedDateTimestamp,
  }
}

export const ChangeDeliveryTimeView = createPluggableWidget<
  {
    utc_zone: string
  } & ChangeDeliveryTime
>(
  ({ onQuit, order, vendor, config, sdk }, ref) => {
    const classes = useStyles()
    const { Text } = Typography

    // pull language content
    const { t } = useTranslation()

    const captureUserAction = useCaptureUserAction()

    // pull globalentity ID from session context
    const {
      sessionState: { orderId, globalEntityId },
    } = useContext(SessionContext)

    const clearCommentsFromDataContext = useClearCommentsFromDataContext()
    const orderStatus = useOrderStatus()

    const { dataDispatch } = useContext(DataContext)

    const { vertical_type_time_buffer, utc_zone } = config

    const { SET_AUTO_CHANGE_DELIVERY_TIME_COMMENT } = DataAction

    const { minutesBufferAccToProvider, isVerticalTypeRestaurant } = useMemo(() => {
      const { restaurants, other } = vertical_type_time_buffer
      const isRestaurant = Boolean(vendor?.vertical_type && vendor?.vertical_type === 'restaurants')
      return {
        isVerticalTypeRestaurant: isRestaurant,
        minutesBufferAccToProvider: isRestaurant ? restaurants : other,
      }
    }, [vertical_type_time_buffer, vendor])

    const { promisedDateTimestamp, fourteenDaysFromPlacementTimestamp, placementDateTimestamp } =
      useMemo(() => {
        return calculateDates(order.place_timestamp, order.promised_customer_timestamp, utc_zone)
      }, [order, utc_zone])

    const [hasAgentSelectedDate, setHasAgentSelectedDate] = useState(false)
    const [selectedDeliveryDate, setSelectedDeliveryDate] = useState<Moment>(
      moment(promisedDateTimestamp).tz(utc_zone),
    )
    const [currentlySelectedHour, setCurrentlySelectedHour] = useState(null)

    const [isSelectedTimeBeforeOrderPlacement, setIsSelectedTimeBeforeOrderPlacement] =
      useState(false)
    const [isSelectedTimeBeforeAsap, setIsSelectedTimeBeforeAsap] = useState(false)

    // returns ISO of the earliest possible delivery time according to provider in local time
    const getLocalAsapAccordingToProvider = (now: Date) => {
      // get now and add either entity buffer from entity config
      const { restaurants, other } = vertical_type_time_buffer

      const asapAccordingToProvider = now
      if (isVerticalTypeRestaurant) {
        asapAccordingToProvider.setMinutes(asapAccordingToProvider.getMinutes() + restaurants)
      } else {
        asapAccordingToProvider.setMinutes(asapAccordingToProvider.getMinutes() + other)
      }

      return moment(asapAccordingToProvider).tz(utc_zone).format()
    }

    // fired when a new date is selected
    const handleDateChange = (date: Moment, isOnlyTimeChanged: boolean) => {
      // clear error state upon every date selection
      setIsSelectedTimeBeforeOrderPlacement(false)
      setIsSelectedTimeBeforeAsap(false)

      // get ASAP in local
      const localAsap = getLocalAsapAccordingToProvider(new Date())

      // set selectedDate and its unformatted version from 'date'
      let currentlySelectedDate = moment(date).tz(utc_zone).format()
      let currentlySelectedDateUnformatted = date

      const hour = date.hour()
      const minute = date.minute()

      // if only time changed, pull time and hour from 'date' & add it to selectedDeliveryDate
      if (isOnlyTimeChanged) {
        const timeChanged = moment(selectedDeliveryDate).hour(hour).minute(minute)
        currentlySelectedDate = timeChanged.tz(utc_zone).format()
        currentlySelectedDateUnformatted = timeChanged
      }

      // disable button when selected time before ASAP
      if (moment(currentlySelectedDate).isBefore(localAsap)) {
        setIsSelectedTimeBeforeOrderPlacement(true)
      }

      // set selected date to local state
      const selectedDMoment = currentlySelectedDateUnformatted.tz(utc_zone)
      setSelectedDeliveryDate(selectedDMoment)
      setHasAgentSelectedDate(true)

      // set currently selected time to local state
      setCurrentlySelectedHour(hour)
      disabledMinutes()
    }

    // disable selection of dates before today and 14 days more than in the future
    const disabledDate = (currentDate: Moment): boolean => {
      // dates of currently looped date, order placement, 14 in the future from order placement, and now -> all in user local offset
      const currentD = currentDate.format().substring(0, 10)
      const placementD = placementDateTimestamp.substring(0, 10)
      const futureD = fourteenDaysFromPlacementTimestamp.substring(0, 10)

      const nowIso = new Date().toISOString()
      const nowInLocalIso = moment(nowIso).tz(utc_zone).format()
      const nowD = nowInLocalIso.substring(0, 10)

      // enable currently looped date date if:
      // it is same or after order placement date
      // and it is same or before 14 days in the future from order placement
      // and it is not before now
      if (
        moment(currentD).isSameOrAfter(placementD) &&
        moment(currentD).isSameOrBefore(futureD) &&
        moment(currentD).isSameOrAfter(nowD)
      ) {
        return false
      }

      return true
    }

    // disable selection of hours; cannot be before order placement and now
    const disabledHours = (): number[] => {
      const disabledHrs = []

      // dates of user selection, order placement, and now -> all in user local offset
      const currentD = selectedDeliveryDate.format().substring(0, 10)
      const placementD = placementDateTimestamp.substring(0, 10)

      const nowIso = new Date().toISOString()
      const nowInLocalIso = moment(nowIso).tz(utc_zone).format()
      const nowD = nowInLocalIso.substring(0, 10)

      // hours of order placement and now -> all in user local offset
      const placementH = Number(placementDateTimestamp.substring(11, 13))
      const placementMin = Number(placementDateTimestamp.substring(14, 16))

      const nowH = Number(nowInLocalIso.substring(11, 13))

      const hourAddition =
        placementMin + minutesBufferAccToProvider < 59
          ? 0
          : placementMin + minutesBufferAccToProvider < 177
          ? 1
          : 2

      // if user selected date, placement date and now are same date, disable hours before now and placement hour
      if (moment(currentD).isSame(nowD) && moment(currentD).isSame(placementD)) {
        const loopLength = nowH > placementH ? nowH : placementH
        for (let i = 0; i < loopLength + hourAddition; i++) disabledHrs.push(i)
        return disabledHrs
      }

      // if selected date and now are same, disable hours before now
      if (moment(currentD).isSame(nowD)) {
        for (let i = 0; i < nowH + hourAddition; i++) disabledHrs.push(i)
        return disabledHrs
      }

      // if selected date and placement date are same, disabled hours before placement hour
      if (moment(currentD).isSame(placementD)) {
        // if placement minute plus buffer is greater than an hour, increase disabled hours by 1
        for (let i = 0; i < placementH + hourAddition; i++) disabledHrs.push(i)
        return disabledHrs
      }

      // if selected date in the future, all hours enabled
      return disabledHrs
    }

    // disable selection of minutes; cannot be before order placement and now
    const disabledMinutes = (): number[] => {
      const disabledMins = []

      // format selected date and get earliest possible time acc to provider
      const currentlySelectedDate = moment(selectedDeliveryDate).tz(utc_zone).format()
      const currentlySelectedH = moment(currentlySelectedDate).hour()
      const localAsap = getLocalAsapAccordingToProvider(new Date())
      const asapHour = moment(localAsap).hour()

      // enable all minutes if selected date is after asap date AND selected hour is after asap hour
      if (moment(currentlySelectedDate).isAfter(localAsap) && currentlySelectedH > asapHour) {
        return disabledMins
      }

      // disable minutes before promised delivery minute, if no hour is selected yet
      if (currentlySelectedHour === null) {
        const promisedTime = promisedDateTimestamp.split('T')[1]
        const promisedMinute = promisedTime.split(':')[1]
        const disabledMinutesPeak = promisedMinute ? Number(promisedMinute) : 60

        for (let i = 0; i <= disabledMinutesPeak; i++) disabledMins.push(i)
        return disabledMins
      }

      // dates of user selection, order placement, and now -> all in user local offset
      const currentD = selectedDeliveryDate.format().substring(0, 10)
      const placementD = placementDateTimestamp.substring(0, 10)

      const nowIso = new Date().toISOString()
      const nowInLocalIso = moment(nowIso).tz(utc_zone).format()
      const nowD = nowInLocalIso.substring(0, 10)

      // hours and minutes of order placement and now -> all in user local offset
      const placementH = Number(placementDateTimestamp.substring(11, 13))
      const placementMin = Number(placementDateTimestamp.substring(14, 16))
      const nowH = Number(nowInLocalIso.substring(11, 13))
      const nowMin = Number(nowInLocalIso.substring(14, 16))

      let hourIncreasedBy = 0
      let placementMinWithBuffer = minutesBufferAccToProvider + placementMin
      if (placementMinWithBuffer > 177) {
        placementMinWithBuffer = placementMinWithBuffer - 177
        hourIncreasedBy = 2
      } else if (placementMinWithBuffer > 59) {
        placementMinWithBuffer = placementMinWithBuffer - 60
        hourIncreasedBy = 1
      }

      let nowMinWithBuffer = minutesBufferAccToProvider + nowMin
      let nowHourIncreasedBy = 0
      if (nowMinWithBuffer > 177) {
        nowMinWithBuffer = nowMinWithBuffer - 177
        nowHourIncreasedBy = 2
      } else if (nowMinWithBuffer > 59) {
        nowMinWithBuffer = nowMinWithBuffer - 60
        nowHourIncreasedBy = 1
      }

      // check if selectedDate is the same as now date & selected hour is same as now hour
      let nowHourIncreasedByBuffer = nowH + nowHourIncreasedBy
      if (nowHourIncreasedByBuffer === 24) nowHourIncreasedByBuffer = 0

      if (moment(currentD).isSame(nowD) && currentlySelectedHour === nowHourIncreasedByBuffer) {
        // if so, disable mins before now minute
        for (let i = 0; i < nowMinWithBuffer; i++) disabledMins.push(i)
        return disabledMins
      }

      // check if selectedDate is the same as order placement date & selected hour is same as order placement hour
      let placementHourIncreasedByBuffer = placementH + hourIncreasedBy
      if (placementHourIncreasedByBuffer === 24) placementHourIncreasedByBuffer = 0
      if (
        moment(currentD).isSame(placementD) &&
        currentlySelectedHour === placementHourIncreasedByBuffer
      ) {
        // if so, disable mins before order placement min
        for (let i = 0; i < placementMinWithBuffer; i++) disabledMins.push(i)
        return disabledMins
      }

      return disabledMins
    }

    // fired when ASAP is clicked; sets new time to the earliest possible time according to provider
    const handleAsapClick = () => {
      // capture the click
      captureUserAction('ActionsChangeDeliveryTimeAsapButtonClicked', {
        eventDetails: {
          is_vertical_restaurant: isVerticalTypeRestaurant ? '1' : '0',
        },
      })
      // get asap acc to provider and change selected date
      const asapAccordingToProvider = getLocalAsapAccordingToProvider(new Date())
      handleDateChange(moment(asapAccordingToProvider), false)
    }

    const {
      loadService: executePatchDeliveryTime,
      status: patchStatus,
      error: patchDeliveryTimeError,
      clearError: clearPatchDeliveryTimeError,
      loading: isPatchingDeliveryTime,
    } = useApiService({
      service: patchDeliveryTime,
      deps: [],
      autoLoad: false,
    })

    // fired when new date Save clicked
    const handleSaveClick = async () => {
      const postNewDeliveryDate = async () => {
        const changeInHours = moment
          .duration(moment(selectedDeliveryDate.format()).diff(placementDateTimestamp))
          .asHours()

        await executePatchDeliveryTime({
          entityId: globalEntityId,
          orderId,
          orderStatus,
          timings: {
            preordered_for: moment(selectedDeliveryDate).toISOString(),
          },
        })
          .then(() => {
            clearCommentsFromDataContext()
            dataDispatch({
              type: SET_AUTO_CHANGE_DELIVERY_TIME_COMMENT,
              payload: { autoChangeDeliveryTimeComment: orderId },
            })
            sdk.eventEmitter.dispatchEvent({
              name: 'CHANGE_DELIVERY_TIME_SUCCESS',
              payload: { orderId },
            })
            captureUserAction('ActionsChangeDeliveryTimeSuccess', {
              eventDetails: {
                changeInHours,
              },
            })
          })
          .catch((err) => {
            captureUserAction('ActionsChangeDeliveryTimeError', {
              eventDetails: {
                changeInHours,
                errorCode: err.response.status,
              },
            })
          })
      }

      // if current tokens valid, fire post voucher client
      // post new date only when selected date is after earliest possible time acc to provider
      const currentlySelectedDate = selectedDeliveryDate.tz(utc_zone).format()

      // add 30 sec buffer to now
      const now = new Date()
      now.setSeconds(now.getSeconds() - 30)
      const localAsap = getLocalAsapAccordingToProvider(now)

      if (moment(currentlySelectedDate).isAfter(localAsap)) {
        captureUserAction('ActionsChangeDeliveryTimeSubmitted')
        postNewDeliveryDate()
      } else {
        setIsSelectedTimeBeforeAsap(true)
      }
    }

    const isSuccess = patchStatus === 'success'

    useImperativeHandle(
      ref,
      () => {
        return {
          onXButtonClick: () => {
            return !hasAgentSelectedDate || isSuccess
          },
        }
      },
      [hasAgentSelectedDate, isSuccess],
    )

    return (
      <WidgetErrorHandler
        errorPayload={patchDeliveryTimeError?.errorPayload}
        displayType='overlay'
        onQuit={onQuit}
        onBack={clearPatchDeliveryTimeError}
        loading={isPatchingDeliveryTime}
      >
        {() => {
          if (isSuccess) {
            return (
              <Result
                status={'success'}
                title={t('Widgets Common.Success')}
                subTitle={`${t(
                  'Actions Widget.Actions.Change Delivery Time.New delivery date and time',
                )}: ${selectedDeliveryDate.format('D.M.YYYY, H:mm')}`}
                extra={[
                  <Button type='primary' key='success-ok' onClick={() => onQuit(true)}>
                    {t('Interface.OK')}
                  </Button>,
                ]}
              />
            )
          }

          return (
            <React.Fragment>
              <div className={classes.modalContent}>
                <div className={classes.alertHolder}>
                  <Alert
                    showIcon
                    message={
                      isVerticalTypeRestaurant
                        ? t(
                            `Actions Widget.Actions.Change Delivery Time.Restaurants need minimum time in minutes to prepare the order`,
                            { replace: { minutes: minutesBufferAccToProvider } },
                          )
                        : t(
                            `Actions Widget.Actions.Change Delivery Time.Non-Restaurants need minimum time in minutes to prepare the order`,
                            { replace: { minutes: minutesBufferAccToProvider } },
                          )
                    }
                    type='info'
                  />
                </div>
                <div className={classes.pickersWrapper}>
                  <div className={classes.pickers}>
                    <div className={classes.dateHolder}>
                      <Text className={classes.texts}>
                        {t('Actions Widget.Actions.Change Delivery Time.Date')}
                      </Text>

                      {promisedDateTimestamp && (
                        <DatePickerView
                          handleDateChange={handleDateChange}
                          disabledDate={disabledDate}
                          defaultValue={moment(promisedDateTimestamp).tz(utc_zone)}
                          selectedDeliveryDate={selectedDeliveryDate}
                        />
                      )}
                    </div>
                    <div className={classes.timeHolder}>
                      <Text className={classes.texts}>
                        {t('Actions Widget.Actions.Change Delivery Time.Time')}
                      </Text>
                      {promisedDateTimestamp && (
                        <TimePickerView
                          handleDateChange={handleDateChange}
                          defaultValue={moment(promisedDateTimestamp).tz(utc_zone)}
                          selectedDeliveryDate={selectedDeliveryDate}
                          disabledHours={disabledHours}
                          disabledMinutes={disabledMinutes}
                        />
                      )}
                    </div>
                    <div className={classes.asapHolder}>
                      <Text className={classes.separator}>|</Text>
                      <Button onClick={handleAsapClick}>
                        <Text className={classes.texts}>
                          {t('Actions Widget.Actions.Change Delivery Time.ASAP')}
                        </Text>
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
              {isSelectedTimeBeforeOrderPlacement ? (
                <div className={classes.wrongTimeAlertHolder}>
                  <Alert
                    message={t(
                      'Actions Widget.Actions.Change Delivery Time.Delivery date cannot be in the past or more than 14 days in the future',
                    )}
                    type='error'
                  />
                </div>
              ) : null}

              {isSelectedTimeBeforeAsap ? (
                <Alert
                  message={t(
                    'Messages.New delivery time should be in the future and not equal to original promised delivery time',
                  )}
                  type={'error'}
                />
              ) : null}

              <div className={classes.buttonContainer}>
                <Button
                  type='primary'
                  onClick={handleSaveClick}
                  disabled={
                    !hasAgentSelectedDate ||
                    isSelectedTimeBeforeOrderPlacement ||
                    isSelectedTimeBeforeAsap
                  }
                >
                  {t('Interface.Save')}
                </Button>
              </div>
            </React.Fragment>
          )
        }}
      </WidgetErrorHandler>
    )
  },
  {
    category: 'action',
    deriveSubjectsRequirements: () => {
      return {
        all_of: ['order'],
      }
    },
    deriveConfig({ entityConfig }) {
      return {
        utc_zone: entityConfig.utc_zone,
        ...entityConfig.fixed_panel_config.widgets_configs.actions.change_delivery_time,
      }
    },
  },
)
