import { PluggableWidget, WidgetCategory } from 'types'

const widgetCategoriesMap = new Map<string, WidgetCategory>([
  ['dh.pandora.location-customer-address', 'data_lookup'],
  ['dh.pandago-c2c.recipient', 'data_lookup'],
  ['dh.pandora.subscription-plugin', 'data_lookup'],
  ['dh.talabat.cancel-order', 'action'],
  ['dh.herocare.wrap-up-plugin', 'ticket'],
  ['dh.herocare.reassign-order-plugin', 'action'],
  ['dh.herocare.ticket-escalate-plugin', 'ticket'],
  ['dh.herocare.add-rider-plugin', 'action'],
  ['dh.herocare.update-order-payable', 'action'],
  ['dh.herocare.vendor-summary-plugin', 'data_lookup'],
  ['dh.herocare.vendor-schedule-plugin', 'data_lookup'],
  ['dh.herocare.vendor-update-availability-plugin', 'action'],
  ['dh.herocare.vendor-contacts-plugin', 'data_lookup'],
  ['dh.herocare.vendor-device-plugin', 'data_lookup'],
  ['dh.herocare.vendor-payment-plugin', 'data_lookup'],
  ['dh.herocare.rider-summary-plugin', 'data_lookup'],
  ['dh.herocare.live-tracking-plugin', 'data_lookup'],
  ['dh.herocare.rider-events-plugin', 'data_lookup'],
  ['dh.herocare.membership-plugin', 'data_lookup'],
  ['dh.herocare.vendor-availability-logs-plugin', 'data_lookup'],
])

export function assignWidgetCategory(widgetId: string, widget: PluggableWidget<any>) {
  if (widget.category) {
    return widget
  }

  if (widgetCategoriesMap.has(widgetId)) {
    widget.category = widgetCategoriesMap.get(widgetId)
    return widget
  }

  console.warn(
    `Plugin ${widgetId} does not have a defined widget category. Did you forget to use createPluggableWidget?`,
  )

  return widget
}
