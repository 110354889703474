// libs
import React, { memo, useMemo } from 'react'
import { createUseStyles } from 'react-jss'
// contexts and types
// styles
import { Tooltip } from 'antd'
import styles from './StatusDot.styles'
import { CourierEventItem } from 'types/api/fulfillmentApi/fulfillment'
import { useGetRiderStatusTextAndColor } from 'hooks/useGetRiderStatusTextAndColor'
import { RiderStatuses } from 'types/widgets/rider/riderStatuses'

interface Props {
  event?: CourierEventItem
}

const useStyles = createUseStyles(styles)

const StatusDot: React.FC<Props> = ({ event }) => {
  const getDeliveryStatusTagParams = useGetRiderStatusTextAndColor()
  const { color, text } = getDeliveryStatusTagParams(event?.name || RiderStatuses.unknown)

  const styleProps = useMemo(() => {
    const colorMap = {
      volcano: '#d4380d',
    }
    return {
      color: colorMap[color] || color,
    }
  }, [color])

  const classes = useStyles(styleProps)
  return (
    <Tooltip title={text}>
      <div className={classes.container}>
        <div className={classes.dot} data-testid='status-dot' />
      </div>
    </Tooltip>
  )
}

export default memo(StatusDot)
