import React from 'react'
import { Collapse, DataPointProps, DataPoints } from 'shared'
import { green, gray } from 'theme'
import { Divider } from 'antd'
import moment from 'moment'
import capitalize from 'lodash/capitalize'
import { DeviceActions, DeviceActionsProps } from '../DeviceActions/DeviceActions'
import { createUseStyles } from 'react-jss'
import { DeviceStatus } from 'services/vendorApi/getVendorDevices'
import { BuiltinWidgetConfigs, SDK } from 'types'

export type VendorDevice = DeviceStatus & {
  deviceId: string
  lpvId: string
}

export interface DeviceCardProps {
  device: VendorDevice
  sdk: SDK
  config: BuiltinWidgetConfigs['vendor_devices']
  expanded?: boolean
  isExpandable?: boolean
  onPerformAction?: DeviceActionsProps['onPerformAction']
}

export const useStyles = createUseStyles({
  cardHeader: {
    width: '100%',
  },
})

export const DeviceCard: React.FC<DeviceCardProps> = ({
  config,
  device,
  sdk,
  expanded,
  isExpandable,
  onPerformAction,
}) => {
  const actionsEnabled = [
    config.actions.restart,
    config.actions.install,
    config.actions.remote_control,
    config.actions.reset,
  ].filter((config) => {
    return sdk.checkDisplayRules({ displayRules: config }).visible
  })
  const { t } = sdk
  const classes = useStyles()

  const dataPoints = config.data_points.map(
    ({ name, display_rules, label_translation_key }): DataPointProps => {
      switch (name) {
        case 'device_id':
          return {
            display_rules,
            type: 'default',
            value: device.deviceId,
            name,
            label: t(label_translation_key),
          }

        case 'status':
          return {
            display_rules,
            type: 'status',
            name,
            label: t(label_translation_key),
            value: {
              statusColor: device.status === 'ONLINE' ? green.green6 : gray.gray5,
              statusText: capitalize(t(device.status)),
            },
          }

        case 'last_checkin':
          return {
            display_rules,
            type: 'default',
            value: moment(device.lastCheckIn).utc().fromNow(),
            name,
            label: t(label_translation_key),
          }

        case 'cellular_carrier':
          return {
            display_rules,
            type: 'default',
            value: device.networkInfo?.carrier,
            name,
            label: t(label_translation_key),
          }

        case 'network_type':
          return {
            display_rules,
            type: 'default',
            value: device.networkInfo?.type,
            name,
            label: t(label_translation_key),
          }

        case 'sim_iccid':
          return {
            display_rules,
            type: 'default',
            value: device.iccid,
            name,
            label: t(label_translation_key),
          }

        case 'battery':
          return {
            display_rules,
            type: 'default',
            value: device.battery || device.battery === 0 ? `${device.battery}%` : '',
            name,
            label: t(label_translation_key),
          }

        case 'signal_strength':
          return {
            display_rules,
            type: 'default',
            value:
              device.networkInfo?.strength || device.networkInfo?.strength === 0
                ? `${device.networkInfo?.strength}%`
                : '',
            name,
            label: t(label_translation_key),
          }

        case 'lpvid':
          return {
            display_rules,
            type: 'default',
            value: device.lpvId,
            name,
            label: t(label_translation_key),
          }

        case 'application_path':
          return {
            display_rules,
            type: 'default',
            colSpan: 24,
            value: device.applicationPath,
            name,
            label: t(label_translation_key),
          }

        default:
          return null
      }
    },
  )

  // display only the first three data points in the card's header
  const headerDataPoints = dataPoints.slice(0, 3)
  const bodyDataPoints = dataPoints.slice(3)

  return (
    <Collapse
      key={device.deviceId}
      type='secondary'
      offsetIcon={true}
      defaultActiveKey={expanded ? device.deviceId : null}
    >
      <Collapse.Panel
        key={device.deviceId}
        showArrow={isExpandable}
        header={
          <div className={classes.cardHeader}>
            <DataPoints dataPoints={headerDataPoints} />
          </div>
        }
      >
        <DataPoints dataPoints={bodyDataPoints} />

        {actionsEnabled.length > 0 ? (
          <>
            <Divider />

            <DeviceActions config={config} onPerformAction={onPerformAction} />
          </>
        ) : null}
      </Collapse.Panel>
    </Collapse>
  )
}
