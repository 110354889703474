import { createUseStyles } from 'react-jss'
import { gray } from 'theme'

const useItemImageStyles = createUseStyles({
  imageTooltip: {
    maxWidth: 'unset',
    '& .ant-tooltip-inner': {
      backgroundColor: 'white',
    },
    '& .ant-tooltip-arrow-content': {
      '&:before': {
        background: 'white',
      },
    },
  },
  itemImage: {
    objectFit: 'contain',
    width: 320,
    minHeight: 160,
  },
  itemImageFailed: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    color: gray.gray7,
    width: 320,
    height: 320,
    '& .placeholder': {
      marginBottom: 20,
    },
    '& .icon': {
      marginRight: 10,
    },
  },
})

export default useItemImageStyles
