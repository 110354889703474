import React, { useMemo } from 'react'
import { Timeline } from 'antd'
import { TimelineText } from './TimelineText'
import { TimelineTime } from './TimelineTime'
import { useTimelineStyles } from './Timeline.styles'
import { TimelineItem } from '../types'
import { UnifiedIcon } from 'shared/UnifiedIcon'
import { CheckCircleFilled } from '@ant-design/icons'

type Props = {
  timeline: TimelineItem[]
  reverse?: boolean
  showAll?: boolean
  minItemsVisible?: number
}

export const HistoryTimeline = ({ timeline, reverse, showAll, minItemsVisible }: Props) => {
  const classes = useTimelineStyles()

  const visibleTimeline = useMemo(() => {
    if (showAll) {
      return timeline
    }
    if (minItemsVisible) {
      return reverse ? timeline.slice(-minItemsVisible) : timeline.slice(0, minItemsVisible)
    }

    return timeline.filter((item) => item.isMilestone)
  }, [showAll, timeline, minItemsVisible, reverse])

  return (
    <Timeline mode='left' reverse={reverse}>
      {visibleTimeline.map(({ key, icon, status, time, isCurrent, isDiffDay }) => (
        <Timeline.Item
          key={key}
          className={classes.item}
          color={status.main.color}
          label={<TimelineTime time={time} isCurrent={isCurrent} isDiffDay={isDiffDay} />}
          dot={icon ? <UnifiedIcon icon={icon} /> : <CheckCircleFilled />}
        >
          <TimelineText status={status} />
        </Timeline.Item>
      ))}
    </Timeline>
  )
}
