import { pink, gray } from 'theme'

export default {
  pinIconGray: {
    width: '10px',
    color: gray.gray7,
  },
  pinIconPink: {
    width: '10px',
    color: pink.pink6,
  },
  alert: {
    width: '100%',
  },
  alertText: {
    fontSize: '13px',
    color: gray.gray10,
  },
}
