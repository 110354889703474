/**
 *  makes API calls to rider status and fulfillment; and runs entity checks for configurable fields
 * renders rider status history and a map
 * */

// libs
import React, { useState, useEffect, useContext } from 'react'
// configs
import { allowedDataPointValues } from 'entityConfig/allowedConfigValues'
// contexts and types
import { DataContext } from 'contexts/data/DataContext'
import { OrderStatuses } from 'types/widgets/order/orderStatuses'
// hooks
import { useTranslation } from 'hooks/useTranslation'
// utils
// styles
import { createUseStyles } from 'react-jss'
import styles from './Status.styles'
import { Button } from 'antd'
// components
import { useSimulateMap } from 'hooks/useSimulateMap'
import { useIsDataPointValid } from 'hooks/useGetValidFeatures'
import { DeliveryItem } from 'types/api/fulfillmentApi/fulfillment'
import { Map, MapProps } from './Map'
import { History, HistoryProps } from './History/History'
import { getLastValue } from 'utils/array'
import { OrderWidgetStatus } from 'contexts/entity/types'

const useStyles = createUseStyles(styles)

export interface DeliveryStatusProps
  extends Omit<HistoryProps, 'shouldCollapseSteps'>,
    Omit<MapProps, 'isStatusHistoryAvailable'> {
  delivery: DeliveryItem
  status: OrderWidgetStatus
  onHardRefresh: () => void
}

export const DeliveryStatus = ({
  delivery,
  transformedStatusHistory,
  onHardRefresh,
  status,
  ...mapProps
}: DeliveryStatusProps) => {
  const classes = useStyles()

  // pull language content
  const { t } = useTranslation()

  // pull data dispatch from data context
  const {
    dataState: { order, fulfillment },
  } = useContext(DataContext)

  const isDataPointValid = useIsDataPointValid()

  const statusHistories = order?.status_history

  // find out if status history available/found for the entity
  const [isStatusHistoryAvailable, setIsStatusHistoryAvailable] = useState(true)
  useEffect(() => {
    // set availability to true initially when effect fired
    setIsStatusHistoryAvailable(true)
    if (status?.status_history) {
      const isDataPointAllowed = isDataPointValid(status?.status_history?.betaRequirement)

      if (isDataPointAllowed) {
        // if never_display, set availability to false
        if (status.status_history.displayRule.includes(allowedDataPointValues.never_display)) {
          setIsStatusHistoryAvailable(false)
          // if display_when_data_found, check if data exists
        } else if (
          status.status_history.displayRule.includes(allowedDataPointValues.display_when_data_found)
        ) {
          // if not, set availability to false
          if (!statusHistories?.length) {
            setIsStatusHistoryAvailable(false)
          }
        }
      } else {
        setIsStatusHistoryAvailable(false)
      }
    }
  }, [status, statusHistories, isDataPointValid])

  // determine if order statuses should be collapsed (when more than 4)
  // shouldCollapseSteps is passed to History to arrange the steps; it is controlled here
  const [shouldCollapseSteps, setShouldCollapseSteps] = useState(false)
  useEffect(() => {
    if (statusHistories?.length > 4) {
      setShouldCollapseSteps(true)
    }
  }, [statusHistories])

  const [maxStatusLengthDisplayed] = useState(4)

  // check if entity allows the display of map after order is DELIVERED
  const [shouldDisplayMapAfterDelivery, setShouldDisplayMapAfterDelivery] = useState(true)
  useEffect(() => {
    if (status?.map_after_delivery) {
      const isDataPointAllowed = isDataPointValid(status?.map_after_delivery?.betaRequirement)

      if (isDataPointAllowed) {
        if (status.map_after_delivery.displayRule.includes(allowedDataPointValues.never_display)) {
          setShouldDisplayMapAfterDelivery(false)
        }
      } else {
        setShouldDisplayMapAfterDelivery(false)
      }
    }
  }, [status, isDataPointValid])

  const simulateMap = useSimulateMap()

  // update here
  let displayMapAndHistory = false

  const lastOrderStatus = statusHistories && getLastValue(statusHistories)

  if (simulateMap) {
    displayMapAndHistory = true
  } else if (!delivery?.courier || !isStatusHistoryAvailable) {
    displayMapAndHistory = false
  } else if ([OrderStatuses.cancelled, OrderStatuses.rejected].includes(lastOrderStatus.status)) {
    displayMapAndHistory = false
  } else if (lastOrderStatus.status === OrderStatuses.delivered && !shouldDisplayMapAfterDelivery) {
    displayMapAndHistory = false
  } else {
    displayMapAndHistory = true
  }

  return (
    <div className={classes.statusContainer}>
      {/* when data received" if history & fulfillment available, render both history and map */}
      {displayMapAndHistory ? (
        <div className={classes.contentHolder}>
          <Map {...mapProps} isStatusHistoryAvailable={isStatusHistoryAvailable} />
          <div className={classes.stepsHolder}>
            <History
              transformedStatusHistory={transformedStatusHistory}
              shouldCollapseSteps={shouldCollapseSteps}
            />
          </div>
        </div>
      ) : // otherwise check if status available
      isStatusHistoryAvailable ? (
        <div className={classes.stepsHolder}>
          <History
            transformedStatusHistory={transformedStatusHistory}
            shouldCollapseSteps={shouldCollapseSteps}
          />
        </div>
      ) : // check fulfillment
      fulfillment ? (
        <Map {...mapProps} isStatusHistoryAvailable={isStatusHistoryAvailable} />
      ) : null}

      <div className={classes.buttonsContainer}>
        <Button type='primary' className={classes.button} onClick={onHardRefresh}>
          {t('Order Widget.Tabs.Status.Refresh')}
        </Button>
        {/* render a show/hide all button, if there are more than 4 statuses */}
        {statusHistories?.length > maxStatusLengthDisplayed ? (
          <Button
            type='primary'
            className={classes.button}
            onClick={() => setShouldCollapseSteps(!shouldCollapseSteps)}
          >
            {shouldCollapseSteps
              ? t('Order Widget.Tabs.Status.Show All Statuses')
              : t('Order Widget.Tabs.Status.Hide Old Statuses')}
          </Button>
        ) : null}
      </div>
    </div>
  )
}
