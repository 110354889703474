import { OrderApiResponse } from 'types/herocare'

export const getOrderAdditionalCharges = (order: OrderApiResponse) => {
  const { subscriptions = [] } = order?.addons ?? {}
  const { tip = 0, delivery_fee = 0, priority_fee = 0, packaging_charges } = order?.delivery ?? {}

  const subscriptionFees = subscriptions.reduce((total, subscription) => {
    const { wallet_amount = 0, amount_after_incentives_gross = 0 } = subscription ?? {}
    return total + wallet_amount + amount_after_incentives_gross
  }, 0)

  const { service_fee = 0, minimum_order_value_fee = 0 } = order?.customer?.payment ?? {}

  const packagingFee = packaging_charges?.amount || 0

  return {
    riderTip: tip,
    subscriptionFees,
    serviceFee: service_fee,
    deliveryFee: delivery_fee,
    packagingFee: packagingFee,
    priorityDeliveryFee: priority_fee,
    minimumOrderValueFee: minimum_order_value_fee,
    total:
      tip +
      subscriptionFees +
      service_fee +
      delivery_fee +
      packagingFee +
      priority_fee +
      minimum_order_value_fee,
  }
}
