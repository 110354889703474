enum OrderDataPointIdentifiers {
  order_id = 'order_id',
  payment_method = 'payment_method',
  delivery_instructions = 'delivery_instructions',
  items = 'items',
  total_price = 'total_price',
  open_order_in = 'open_order_in',
}

export default OrderDataPointIdentifiers
