import { allowedScrollablePanelWidgets, PhoneDecorators } from 'entityConfig/allowedConfigValues'
import { AvailableRefundMethod } from 'types/actions/partialRefund'
import { VendorContactTypes } from 'types/api/vendorApi/vendor'
import {
  ActionHandler,
  DeliveryProviders,
  DisplayRules,
  UnitedUiConfig,
  WidgetContainerTypeDefinition,
} from 'types/unitedUiConfig'
import { WidgetSize } from 'types/widgets/historyOfEvents/tabs'
import {
  ProfileDataPointTagDisplayByDataEquality,
  ProfileDataPointTagDisplayByDataRange,
  ProfileDataPointTagDisplayByDataValue,
} from './types/customerWidgetProfile'

// state types
export type InitialEntityState = {
  entityConfig: EntityConfig
}

export interface EntityConfig {
  /**
   * defines the container type of widgets and actions
   */
  widget_container_type?: WidgetContainerTypeDefinition

  enableOrderEligibilities: boolean
  enableVendorEligibilities: boolean
  enableCustomerEligibilities: boolean
  useCcrWhitelistForCompensationIssues: boolean
  useCcrWhitelistForPartialRefundIssues: boolean
  useCcrWhitelistForFullRefundIssues: boolean
  useCcrWhitelistForCancelOrderIssues: boolean
  useCcrWhitelistForActionButtons: boolean
  show_split_deliveries: {
    betaRequirement: string[]
  }
  utc_zone: string
  currency_digits_after_comma: number

  /**
   * introduced by vendor line of business
   * @deprecated use allow_search_order_by_phone
   */
  allow_search_by_customer_phone?: boolean
  allow_search_order_by_phone?: boolean
  phoneDecorator: {
    decorator: PhoneDecorators
    betaRequirement: string[]
  }
  available_apis?: string[]
  fixed_panel_config: FixedPanelConfig
  scrollable_panel_config: ScrollablePanelConfig

  mininav?: MiniNavbarConfig
  miniOrder?: MiniOrderConfig
  miniVendor?: MiniVendorConfig
  miniCustomer?: MiniCustomerConfig
  miniRider?: MiniRiderConfig

  /**
   * integration of united ui config
   */
  layout_v2?: UnitedUiConfig

  widgets_configs?: EntityConfig['fixed_panel_config']['widgets_configs'] &
    EntityConfig['scrollable_panel_config']['widgets_configs']
}

export type FixedPanelConfig = {
  widget_order: FeatureWithBetaRequirementConfig[]
  widgets_configs: FixedPanelWidgetConfig
}

export type DataPointWithBetaRequirementConfig = {
  displayRule: string[]
  betaRequirement: string[]
}

export type FeatureWithBetaRequirementConfig<FeatureType = string> = {
  active?: boolean
  type?: string
  plugin_code?: string
  feature: FeatureType
  betaRequirement: string[]
  maxOrderAgeInHours?: number
  ccrWhitelist?: string[]
  paymentMethodMustBeOneOfRequirement?: string[]
  cancelReasonMustBeOneOfRequirement?: string[]
  orderStatusMustExistRequirement?: string[]
  orderStatusMustNotExistRequirement?: string[]
  delivery_provider_rules?: DeliveryProviderRule[]
  delivery_status_rule?: DeliveryStatusRule
}

export type DeliveryProviderRule = {
  provider: string
  paymentMethodMustBeOneOfRequirement?: string[]
  cancelReasonMustBeOneOfRequirement?: string[]
  orderStatusMustExistRequirement?: string[]
  orderStatusMustNotExistRequirement?: string[]
}

export type DeliveryStatusRule = {
  deliveryStatusMustExistRequirement?: string[]
  deliveryStatusMustNotExistRequirement?: string[]
}

export type FixedPanelWidgetConfig = {
  actions: FixedPanelActions
  history_of_events: FixedPanelNewComment
}

export type VoucherValidityType = {
  value: number
  unit: 'month' | 'day'
}

export type FixedPanelActions = {
  common: ActionsCommon
  available_actions: AvailableAction[]
  change_delivery_address: ChangeDeliveryAddress
  change_delivery_time: ChangeDeliveryTime
  partial_refund: PartialRefund
  refund_common: RefundCommon
  compensation: Compensation
  new_comment_config: NewCommentConfig
  cancel_order: CancelOrderActionConfig
}

export type AvailableAction =
  | FeatureWithBetaRequirementConfig
  | (FeatureWithBetaRequirementConfig & WidgetPlugin)

type ActionsCommon = {
  prev_compensations_and_refunds_banner: DataPointWithBetaRequirementConfig
}

export type ChangeDeliveryAddress = {
  showDistanceFromOldToNewAddress: boolean
  showDistanceFromSourceToNewAddress: boolean
  validateAddress: boolean
  shouldDisplayAccordion: boolean
  ignoreVendorDeliveryAreaValidation?: boolean
}

export type ChangeDeliveryTime = {
  vertical_type_time_buffer: VerticalTypeTimeBuffer
}

type VerticalTypeTimeBuffer = {
  restaurants: number
  other: number
}

export type PartialRefund = {
  allowFlexibleAmountRefund?: DataPointWithBetaRequirementConfig
  allowIndeterminateItemCheck: DataPointWithBetaRequirementConfig
  allowPartialRefundWithAllItems: boolean
  partialItemRefundPercentage: number
  defaultCompensationIssue: string
  isMissingItemReportAllowed: boolean
  refundAmountPrecision: number
  pspAndWalletCompensationPercent: number
  pspAndWalletMinCompensationAmount: number
  pspAndWalletGranularity: number
  voucherCompensationPercent: number
  voucherMinCompensationAmount: number
  voucherGranularity: number
  autoCompensationPercent: number
  autoGranularity: number
  minAutoValue: number
  available_partial_refund_methods: AvailableRefundMethod[]
  availableIssueTypes?: RefundIssueType[]
  alwaysUpdateBackend?: boolean
  enableItemRemoval?: boolean
  excludedVendors?: string[]
  excludedCCRs?: string[]
  missingItemReportCRRWhitelist?: string[]
  isToppingSelectionAllowed: boolean
  isChoiceSelectionAllowed: boolean
  isPartialItemSelectionAllowed: boolean
}

export type RefundIssueType = {
  issue: string
  compensation_issue?: string
  issue_translation_key: string
  ccrWhitelist?: string[]
  use_flexible_amount?: boolean
  min_flexible_amount?: number
  max_flexible_amount?: number
  use_auto_comp_proposed_value: boolean
  use_auto_comp_proposed_min_order_value: boolean
  use_auto_comp_proposed_validity_dates: boolean
  use_auto_comp_proposed_max_order_value: boolean
  ccr_code: string | null
}

type FixedPanelNewComment = {
  widget_size?: WidgetSize
  tab_order: FeatureWithBetaRequirementConfig[]
}

export type RefundCommon = {
  voucherValidityInMonths: number
  voucherValidity: VoucherValidityType
  isMinOrderValueStatic: boolean
  minOrderValue: number
  description_prefix_partial: string
  description_prefix_full: string
  voucher_payment_types: VoucherPaymentType[]
  available_issue_types: RefundIssueType[]
  available_refund_methods: AvailableRefundMethod[]
  compensation_percent: number
  granularity_value: number
  min_voucher_value: number
  pspAndWalletCompensationPercent: number
  pspAndWalletMinCompensationAmount: number
  pspAndWalletGranularity: number
  autoCompensationPercent: number
  autoGranularity: number
  minAutoValue: number
}

export type Compensation = {
  available_compensation_methods: CompensationMethod[]
  availableIssueTypes: IssueType[]
  voucherValidityInMonths: number
  voucherValidity: VoucherValidityType
  maxVoucherValue: number
  minVoucherValue: number
  isMinOrderValueStatic: boolean
  minOrderValue: number
  granularity: number
  descriptionPrefix: string
  voucher_payment_types: VoucherPaymentType[]
}

export type CompensationMethod = {
  method: string
  translation_key: string
}

export type IssueType = {
  issue: string
  issue_translation_key: string
  useGetProposedValue: boolean
  use_auto_comp_proposed_min_order_value: boolean
  use_auto_comp_proposed_validity_dates: boolean
  use_auto_comp_proposed_max_order_value: boolean
  ccr: string | null
  ccr_code: string | null
  ccrWhitelist?: string[]
  maxVoucherValue: number | null
  minVoucherValue: number | null
}

export type VoucherPaymentType = {
  id: number
  type: string
  display_name: string
}

export type NewCommentConfig = {
  order: string
  customer: string
  rider: string
  vendor: string
  back_office: string
}

export type CancelOrderActionConfig = {
  useStage: boolean
  useReasonToDisplayCancelComment: boolean
  availableAfterOrderCreationInHours: number
  availableCancellationReasons: CancellationReason[]
}

export type CancellationReason = {
  reason: string
  stages: null | string[]
  providers?: null | DeliveryProviders[]
  ccrWhitelist?: string[]
  id: string | null
  translation_key: string
}

export type ScrollablePanelConfig = {
  widget_order: FeatureWithBetaRequirementConfig<allowedScrollablePanelWidgets>[]
  widgets_configs: ScrollableWidgetConfigs
}

type MiniNavbarConfig = {
  tab_order: FeatureWithBetaRequirementConfig[]
}

type MiniOrderConfig = {
  mapReloadInterval: number
  orderStatusReloadTime: number
  vertical_type: DataPointWithBetaRequirementConfig
  copy_to_clipboard: DataPointWithBetaRequirementConfig
  corporate_tag: DataPointWithBetaRequirementConfig
  preorder_tag: DataPointWithBetaRequirementConfig
  payment_method: DataPointWithBetaRequirementConfig
  delivery_inst: DataPointWithBetaRequirementConfig
  order_status: DataPointWithBetaRequirementConfig
  rider_events: DataPointWithBetaRequirementConfig
  items: DataPointWithBetaRequirementConfig
  total_price: DataPointWithBetaRequirementConfig
  link_to_hurrier: DataPointWithBetaRequirementConfigForLinks
  link_to_backend: DataPointWithBetaRequirementConfigForLinks
  riderChatTranscript: DataPointWithBetaRequirementConfig
}

export type DataPointWithBetaRequirementConfigForLinks = {
  displayRule: string[]
  betaRequirement: string[]
  linkToSource: string
}

type MiniVendorConfig = {
  is_active: DataPointWithBetaRequirementConfig
  name: DataPointWithBetaRequirementConfig
  address: DataPointWithBetaRequirementConfig
  phone_number: DataPointWithBetaRequirementConfig
  copy_to_clipboard: DataPointWithBetaRequirementConfig
}

type MiniCustomerConfig = {
  customerId: DataPointWithBetaRequirementConfig
  customerName: DataPointWithBetaRequirementConfig
  customerAddress: DataPointWithBetaRequirementConfig
  customerPhone: DataPointWithBetaRequirementConfig
  copy_to_clipboard: DataPointWithBetaRequirementConfig
}

type MiniRiderConfig = {
  rider_id: DataPointWithBetaRequirementConfigForLinks
  riderStatus: DataPointWithBetaRequirementConfig
  riderContractType: DataPointWithBetaRequirementConfig
  riderCity: DataPointWithBetaRequirementConfig
  riderVehicle: DataPointWithBetaRequirementConfig
  riderPhone: DataPointWithBetaRequirementConfig
  riderCurrentShift: DataPointWithBetaRequirementConfig
  copy_to_clipboard: DataPointWithBetaRequirementConfig
}

export type ScrollableWidgetConfigs = {
  order: OrderWidget
  customer: CustomerWidget
  vendor: VendorWidget
  rider: RiderWidget
}

type WidgetTab = {
  active: boolean
  feature: string
  betaRequirement: string[]
  type?: string
  plugin_code?: string
}

// ORDER WIDGET //
type OrderWidget = {
  tab_order: WidgetTab[]
  tab_configs: OrderWidgetTabContent
}

export enum PaymentMethodsIcons {
  Apple = 'apple_pay.png',
  Google = 'google_pay.png',
  Wallet = 'wallet.png',
  Cache = 'cod.png',
  Credit_card = 'credit_card.png',
  Digital_payment = 'digital_payment.png',
  Voucher = 'voucher.png',
  Paypal = 'paypal.png',
}

export enum PaymentMethods {
  COD = 'COD',
  credit_card = 'credit_card',
  cybersource_creditcard = 'cybersource_creditcard',
  Molpay = 'Molpay',
  antfinancial_gcash = 'antfinancial_gcash',
  ApplePay = 'ApplePay',
  Efectivo = 'Efectivo',
  CASH = 'CASH',
  Paypal = 'Paypal',
  CREDITCARD = 'CREDITCARD',
  antfinancial_bkash = 'antfinancial_bkash',
  Spreedly_AR_VISA_DEBIT = 'Spreedly_AR_VISA_DEBIT',
  antfinancial_truemoney = 'antfinancial_truemoney',
  antfinancial_easypaisa = 'antfinancial_easypaisa',
  reddot_paylah = 'reddot_paylah',
  balance = 'balance',
  Spreedly_AR_VISA_CREDIT = 'Spreedly_AR_VISA_CREDIT',
  GooglePay = 'GooglePay',
  WebPay_CL = 'WebPay_CL',
  APPLE_PAY = 'APPLE_PAY',
  KNET = 'KNET',
  Spreedly_AR = 'Spreedly_AR',
  Spreedly_AR_MASTERCARD_CREDIT = 'Spreedly_AR_MASTERCARD_CREDIT',
  adyen_hpp_boost = 'adyen_hpp_boost',
  No_Payment_Zero_Payable_Amount = 'No_Payment_Zero_Payable_Amount',
  Spreedly_CL_MASTERCARD_CREDIT = 'Spreedly_CL_MASTERCARD_CREDIT',
  Invoice = 'Invoice',
  VisaNet_VISA_DEBIT = 'VisaNet_VISA_DEBIT',
  Spreedly_CL_VISA_CREDIT = 'Spreedly_CL_VISA_CREDIT',
  Spreedly_PE_VISA_DEBIT = 'Spreedly_PE_VISA_DEBIT',
  adyen_hpp_vipps = 'adyen_hpp_vipps',
  checkout_psp_mobilepay = 'checkout_psp_mobilepay',
  adyen_hpp_swish = 'adyen_hpp_swish',
  VisaNet_VISA_CREDIT = 'VisaNet_VISA_CREDIT',
  Spreedly_CL = 'Spreedly_CL',
  Visa = 'Visa',
  WALLET = 'WALLET',
  gift_card = 'gift_card',
  Spreedly_AR_AMERICAN_EXPRESS_CREDIT = 'Spreedly_AR_AMERICAN_EXPRESS_CREDIT',
  checkout_psp_online_banking = 'checkout_psp_online_banking',
  BENEFIT = 'BENEFIT',
  Klarna = 'Klarna',
  Sofort = 'Sofort',
  Mastercard = 'Mastercard',
  VisaNet_VISA_PREPAID = 'VisaNet_VISA_PREPAID',
}

export type WidgetPlugin = {
  type: 'plugin'
  plugin_code: string
}

interface OrderWidgetTabContent extends WidgetPlugin {
  summary: OrderWidgetSummary
  items: OrderWidgetItems
  status: OrderWidgetStatus
  payment: OrderWidgetPayment
  invoice: OrderWidgetInvoice
}

export type PaymentMethodLogo = {
  logo: PaymentMethodsIcons
  payment_method: PaymentMethods
}

export type OrderWidgetSummary = {
  riderChatTranscript: DataPointWithBetaRequirementConfig
  payment_status?: string[]
  payout_status: DataPointWithBetaRequirementConfig
  order_source: DataPointWithBetaRequirementConfig
  current_status: DataPointWithBetaRequirementConfig
  promised_dropoff_time: DataPointWithBetaRequirementConfig
  delivery_instructions: DataPointWithBetaRequirementConfig
  pre_order: DataPointWithBetaRequirementConfig
  corporate: DataPointWithBetaRequirementConfig
  discount_joker: DataPointWithBetaRequirementConfig
  split_delivery: DataPointWithBetaRequirementConfig
  application: DataPointWithBetaRequirementConfig
  delivery_provider: DataPointWithBetaRequirementConfig
  platform: DataPointWithBetaRequirementConfig
  full_address_tooltip: DataPointWithBetaRequirementConfig
  proof_of_delivery: DataPointWithBetaRequirementConfig
  newDesign: DataPointWithBetaRequirementConfig
  weight: DataPointWithBetaRequirementConfig
  order_number: DataPointWithBetaRequirementConfig
  number_of_items: DataPointWithBetaRequirementConfig
}

export type OrderWidgetItems = {
  newOrderItemsDesign: DataPointWithBetaRequirementConfig
  paid_with_wallet: DataPointWithBetaRequirementConfig
  payment_methods: DataPointWithBetaRequirementConfig
  payment_status: DataPointWithBetaRequirementConfig
  total_price: DataPointWithBetaRequirementConfig
  special_instructions: DataPointWithBetaRequirementConfig
  topping_total_price: DataPointWithBetaRequirementConfig
  topping_name: DataPointWithBetaRequirementConfig
  topping_price: DataPointWithBetaRequirementConfig
  coupon: DataPointWithBetaRequirementConfig
  discount: DataPointWithBetaRequirementConfig
  min_order_value: DataPointWithBetaRequirementConfig
  delivery_fee: DataPointWithBetaRequirementConfig
  other_charges: DataPointWithBetaRequirementConfig
  item_requirement: DataPointWithBetaRequirementConfig
  default_number_of_items?: {
    value: number
    display_rules?: DisplayRules
  }
  enabled_item_locales: string[]

  action_handlers?: ActionHandler[]
}

export type OrderWidgetStatus = {
  promised_dropoff_time: DataPointWithBetaRequirementConfig
  estimated_dropoff_time: DataPointWithBetaRequirementConfig
  estimated_pickup_time: DataPointWithBetaRequirementConfig
  delivery_instructions: DataPointWithBetaRequirementConfig
  status_history: DataPointWithBetaRequirementConfig
  dropoff_location: DataPointWithBetaRequirementConfig
  pickup_location: DataPointWithBetaRequirementConfig
  courier_location: DataPointWithBetaRequirementConfig
  map_after_delivery: DataPointWithBetaRequirementConfig
}

export type OrderWidgetPayment = {
  total_refund_to_source: DataPointWithBetaRequirementConfig
  total_refund_to_wallet: DataPointWithBetaRequirementConfig
}

export type OrderWidgetInvoice = {
  discount?: DataPointWithBetaRequirementConfig
  discount_joker?: DataPointWithBetaRequirementConfig
  additional_charges?: OrderWidgetInvoiceAdditionalCharges
  voucher?: DataPointWithBetaRequirementConfig
  loyality_points?: DataPointWithBetaRequirementConfig
  corporate_allowance?: DataPointWithBetaRequirementConfig
  maxPendingTimeInHours?: number
}

export interface OrderWidgetInvoiceAdditionalCharges extends DataPointWithBetaRequirementConfig {
  available_fields?: InvoiceAdditionalChargesAvailableFields
}

type InvoiceAdditionalChargesAvailableFields = {
  rider_tip?: DataPointWithBetaRequirementConfig
  delivery_fee?: DataPointWithBetaRequirementConfig
  minimum_order_value_fee?: DataPointWithBetaRequirementConfig
  packaging_fees?: DataPointWithBetaRequirementConfig
  service_fee?: DataPointWithBetaRequirementConfig
  subscriptions_fee?: DataPointWithBetaRequirementConfig
}

// CUSTOMER WIDGET //
type CustomerWidget = {
  FraudFlag: FraudFlagWithBetaRequirement
  tab_order: WidgetTab[]
  tab_configs: CustomerWidgetTabContent
}

export type FraudFlagWithBetaRequirement = {
  enabled: boolean
  apiVersion: 1 | 2
  betaRequirement: string[]
}

type CustomerWidgetTabWithPlugins = {
  [key in 'summary' | 'profile' | 'vouchers' | 'wallet' | string]:
    | CustomerWidgetSummary
    | CustomerWidgetProfile
    | CustomerWidgetVouchers
    | OrderWidgetPayment
    | CustomerWidgetWallet
    | WidgetPlugin
}

interface CustomerWidgetTabContent extends CustomerWidgetTabWithPlugins {
  summary: CustomerWidgetSummary
  profile: CustomerWidgetProfile
  vouchers: CustomerWidgetVouchers
  wallet: CustomerWidgetWallet
}

export type CustomerWidgetSummary = {
  name: DataPointWithBetaRequirementConfig
  phone: DataPointWithBetaRequirementConfig
  email: DataPointWithBetaRequirementConfig
  platform_and_country: DataPointWithBetaRequirementConfig
  copy_to_clipboard: DataPointWithBetaRequirementConfig
  customer_id: DataPointWithBetaRequirementConfig
  address?: DataPointWithBetaRequirementConfig
  tags?: DataPointWithBetaRequirementConfig
  full_address_tooltip?: DataPointWithBetaRequirementConfig
  layout_col_count?: number
}

export type CustomerWidgetProfile = {
  data_points: Array<
    | ProfileDataPointTagDisplayByDataEquality
    | ProfileDataPointTagDisplayByDataRange
    | ProfileDataPointTagDisplayByDataValue
  >
  layout_col_count?: number
}

export type CustomerWidgetVouchers = {
  code: DataPointWithBetaRequirementConfig
  description: DataPointWithBetaRequirementConfig
  valid_dates: DataPointWithBetaRequirementConfig
  schedules: DataPointWithBetaRequirementConfig
  type: DataPointWithBetaRequirementConfig
  value: DataPointWithBetaRequirementConfig
  min_order_value: DataPointWithBetaRequirementConfig
  max_order_value: DataPointWithBetaRequirementConfig
  max_discount_amount: DataPointWithBetaRequirementConfig
  vertical_types: DataPointWithBetaRequirementConfig
  payment_types: DataPointWithBetaRequirementConfig
  expedition_types: DataPointWithBetaRequirementConfig
  vendors: DataPointWithBetaRequirementConfig
  vendor_chains: DataPointWithBetaRequirementConfig
  customer_id: DataPointWithBetaRequirementConfig
  quantity: DataPointWithBetaRequirementConfig
  customer_quantity: DataPointWithBetaRequirementConfig
  status: DataPointWithBetaRequirementConfig
  is_new_customer_only: DataPointWithBetaRequirementConfig
  used: DataPointWithBetaRequirementConfig
  unlimited_usage: DataPointWithBetaRequirementConfig
  sponsor_ratio: DataPointWithBetaRequirementConfig
  // new attributes
  platforms: DataPointWithBetaRequirementConfig
  countries: DataPointWithBetaRequirementConfig
  cities: DataPointWithBetaRequirementConfig
  purpose: DataPointWithBetaRequirementConfig
  order_id: DataPointWithBetaRequirementConfig
  own_delivery_only: DataPointWithBetaRequirementConfig
  cuisine: DataPointWithBetaRequirementConfig
  times_per_user: DataPointWithBetaRequirementConfig
  paid_by: DataPointWithBetaRequirementConfig
}

type CustomerWidgetWallet = {}

// VENDOR WIDGET //
type VendorWidget = {
  tab_order: FeatureWithBetaRequirementConfig[]
  tab_configs: VendorWidgetTabContent
}

interface VendorWidgetTabContent {
  summary: VendorWidgetSummary
  profile: CustomerWidgetProfile
}

type MultiplePhoneNumbersDataPoint = DataPointWithBetaRequirementConfig & {
  phoneNumberTypes: VendorContactTypes[]
}

export type VendorWidgetSummary = {
  link_to_fe: DataPointWithBetaRequirementConfig
  is_active: DataPointWithBetaRequirementConfig
  is_halal: DataPointWithBetaRequirementConfig
  copy_to_clipboard: DataPointWithBetaRequirementConfig
  multiple_vendor_phone_numbers: MultiplePhoneNumbersDataPoint
}

// RIDER WIDGET //
type RiderWidget = {
  tab_order: FeatureWithBetaRequirementConfig[]
  tab_configs: RiderWidgetTabContent
}

export interface RiderV2Config extends Partial<MiniRiderConfig> {
  riderName: DataPointWithBetaRequirementConfig
  verticalType: DataPointWithBetaRequirementConfig
  proof_of_delivery: DataPointWithBetaRequirementConfig
  newDesign: {
    betaRequirement: string[]
  }
}

type RiderWidgetTabWithPlugins = {
  [key in 'summary' | string]: RiderWidgetSummary | RiderV2Config | WidgetPlugin
}

interface RiderWidgetTabContent extends RiderWidgetTabWithPlugins {
  summary: RiderWidgetSummary | RiderV2Config
}

export type RiderWidgetSummary = {
  id: DataPointWithBetaRequirementConfig
  name: DataPointWithBetaRequirementConfig
  phone: DataPointWithBetaRequirementConfig
  vehicle_type: DataPointWithBetaRequirementConfig
  copy_to_clipboard: DataPointWithBetaRequirementConfig
  proof_of_delivery: DataPointWithBetaRequirementConfig
  newDesign: {
    betaRequirement: string[]
  }
}

// action types
export enum EntityAction {
  SET_ENTITY_CONFIG = 'SET_ENTITY_CONFIG',
}

type ActionMap<M extends { [index: string]: any }> = {
  [Key in keyof M]: M[Key] extends undefined
    ? { type: Key }
    : {
        type: Key
        payload: M[Key]
      }
}

export type EntityActions = ActionMap<EntityPayload>[keyof ActionMap<EntityPayload>]

export type EntityPayload = {
  [EntityAction.SET_ENTITY_CONFIG]: InitialEntityState
}

export type WidgetName = 'order' | 'customer' | 'vendor' | 'rider' | 'history_of_events' | 'actions'

export type WidgetObjectType<T> = T extends 'order'
  ? OrderWidget
  : T extends 'customer'
  ? CustomerWidget
  : T extends 'vendor'
  ? VendorWidget
  : T extends 'rider'
  ? RiderWidget
  : T extends 'history_of_events'
  ? FixedPanelNewComment
  : T extends 'actions'
  ? FixedPanelActions
  : never
