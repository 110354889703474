import { ApiService } from 'services/authApi/getPermissions'
import { GetOrderCommentsResponse } from 'types/api/commentApi/getOrderComments'
import { buildCommentApiUrl } from './helper'
import { RiderCommentsApiParams } from './types'

export const getRiderComments: ApiService<RiderCommentsApiParams, GetOrderCommentsResponse> = (
  createClient,
  options,
) => {
  const { riderId, clientParams, ...apiParams } = options

  const uri = buildCommentApiUrl(apiParams, 'rider', riderId)

  return createClient({
    endpointName: 'getRiderComments',
    expectedResponseStatusCode: 200,
    ...clientParams,
  }).get(uri)
}
