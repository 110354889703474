/**
 * creates and returns client headers with the passed JWT and case ID for all OneView APIs except auth
 * */
import { AxiosRequestConfig } from 'axios'
import { LinesOfBusiness } from 'types/session/linesOfBusiness'
import { getSearchParams } from 'utils/getSearchParams'
import {
  apiHeadersClientIdKey,
  apiHeadersContactIdKey,
  apiHeadersUserIdKey,
  getApiHeaderClientIds,
} from './constants'
import { UiVersion } from 'types'

export const attachRequestHeaders = (
  config: AxiosRequestConfig,
  options: {
    agentEmail: string
    jwt: string
    uiVersion: UiVersion
  },
) => {
  const {
    apiHeadersClientId,
    apiHeadersClientIdV2,
    apiHeadersRsV2ClientIdV2,
    apiHeadersRsClientId,
    apiHeadersRsV2ClientId,
    apiHeadersVsClientId,
    apiHeadersVsClientIdV2,
  } = getApiHeaderClientIds()
  const serviceClientId = {
    [LinesOfBusiness.customer]: apiHeadersClientId,
    [LinesOfBusiness.rider]: apiHeadersRsClientId,
    [LinesOfBusiness.vendor]: apiHeadersVsClientId,
    [LinesOfBusiness.riderV2]: apiHeadersRsV2ClientId,
  }

  const serviceClientIdV2 = {
    [LinesOfBusiness.customer]: apiHeadersClientIdV2,
    [LinesOfBusiness.riderV2]: apiHeadersRsV2ClientIdV2,
    [LinesOfBusiness.vendor]: apiHeadersVsClientIdV2,
  }

  const searchParams = getSearchParams()
  const { jwt, agentEmail, uiVersion } = options

  const lob = searchParams.getLineOfBusiness()
  const caseId = searchParams.getCaseId()

  config.headers[apiHeadersClientIdKey] =
    uiVersion === 'v2' ? serviceClientIdV2[lob] : serviceClientId[lob]

  config.headers[apiHeadersContactIdKey] = caseId || 'null'
  config.headers[apiHeadersUserIdKey] = agentEmail || 'null'
  config.headers.Authorization = `Bearer ${jwt || ''}`

  return config
}
