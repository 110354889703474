import { useCaptureUserAction } from 'hooks/events/useCaptureUserAction'
import React from 'react'
import { createUseStyles } from 'react-jss'
import { CopyButton } from 'shared/CopyButton'
import { Text } from 'shared/Text'

interface CopyableTextProps {
  copyableText: string
  prefix?: string
  suffix?: string
  onCopy?: () => void
  className?: string
  type: 'text' | 'link'
  copyEventName?: string
}

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '6px',

    '& > *': {
      flexShrink: 0,
    },
  },
})
const NO_VALUE = '-'

export const CopyableText = ({
  prefix,
  suffix,
  copyableText,
  onCopy,
  className,
  type,
  copyEventName,
}: CopyableTextProps) => {
  const classes = useStyles()

  const captureUserAction = useCaptureUserAction()

  const handleCopy = () => {
    if (copyEventName) {
      captureUserAction(copyEventName, { reportToEts: true })
    }
    onCopy?.()
  }

  const displayedText = [prefix, copyableText, suffix].filter(Boolean).join(' ')

  if (displayedText) {
    return (
      <div className={classes.container}>
        <Text.Primary className={className}>
          {type === 'text' && <span>{displayedText}</span>}
          {type === 'link' && (
            <a href={copyableText} target='_blank' rel='noreferrer'>
              {displayedText}
            </a>
          )}
        </Text.Primary>
        {copyableText && <CopyButton text={copyableText} onCopy={handleCopy} />}
      </div>
    )
  }
  return <span>{NO_VALUE}</span>
}
