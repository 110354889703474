import environment from '../../envConfig'
export const apiHeadersClientIdKey = 'X-CLIENT-ID'
export const apiHeadersContactIdKey = 'X-CONTACT-ID'
export const apiHeadersUserIdKey = 'X-Agent-User-Id'
export const unknownMessage = 'Unknown'

export const getApiHeaderClientIds = () => {
  return {
    apiHeadersClientId: environment().clientId,
    apiHeadersRsClientId: environment().rsClientId,
    apiHeadersVsClientId: environment().vsClientId,
    apiHeadersRsV2ClientId: environment().rsV2ClientId,

    // United ui clientIds
    apiHeadersClientIdV2: environment().clientIdV2,
    apiHeadersRsV2ClientIdV2: environment().rsV2ClientIdV2,
    apiHeadersVsClientIdV2: environment().vsclientIdV2,
  }
}
