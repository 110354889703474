import { DeliveryItem, Location } from 'types/api/fulfillmentApi/fulfillment'
import { FulfillmentApiRiderResponse } from 'types/api/riderApi/rider'

const transformLocation = ({ latitude, longitude }: Location): Location => {
  if (!latitude || !longitude) return
  return { latitude, longitude }
}

const getDeliveryByRiderId = (
  deliveries?: DeliveryItem[],
  riderId?: number,
): DeliveryItem | undefined => {
  if (!deliveries || !riderId) return
  return deliveries.filter((delivery) => delivery?.courier?.id === riderId)[0]
}

export const transformRiderFulfillment = (rider: FulfillmentApiRiderResponse, riderId: string) => {
  const { deliveries } = rider.current_order || {}

  let currentRiderLocation: Location
  let currentVendorLocation: Location
  let currentCustomerLocation: Location

  const riderIdNumber = parseInt(riderId)

  if (deliveries?.length > 0 && riderIdNumber) {
    const { courier, pickup_location, dropoff_location } =
      getDeliveryByRiderId(deliveries, riderIdNumber) || {}

    if (courier?.locations?.length > 0) {
      const latestLocation = courier.locations[courier.locations.length - 1]
      currentRiderLocation = transformLocation(latestLocation)
    }

    if (pickup_location) currentVendorLocation = transformLocation(pickup_location)
    if (dropoff_location) currentCustomerLocation = transformLocation(dropoff_location)
  }

  return {
    currentRiderLocation,
    currentVendorLocation,
    currentCustomerLocation,
  }
}
