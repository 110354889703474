import React from 'react'
import { ContactCard } from '../ContactCard/ContactCard'
import { NoContactsCard } from './NoContactsCard'
import { createUseStyles } from 'react-jss'
import styles from './Contacts.styles'
import { Button } from 'shared'
import { BuiltinWidgetConfigs } from 'types'
import { useSdk } from 'contexts/SdkProvider'
import { TransformedVendorContacts } from 'utils/dataTransformers/transformVendorContacts'

const useStyles = createUseStyles(styles)

interface ContactsProps {
  contacts: TransformedVendorContacts
  config: BuiltinWidgetConfigs['vendor_contacts']
  handleAddContact: () => void
  handleEditContact: (contactId: string) => void
}

export const Contacts = ({
  contacts,
  config,
  handleEditContact,
  handleAddContact,
}: ContactsProps) => {
  const classes = useStyles()
  const { checkDisplayRules, t } = useSdk()

  const showAddContactButton = checkDisplayRules({
    displayRules: config.add_contact,
  })?.visible
  const showEditContactButton = checkDisplayRules({
    displayRules: config.edit_contact,
  })?.visible

  if (!contacts || contacts?.length === 0) {
    return <NoContactsCard />
  }

  return (
    <>
      {contacts?.map((contact, index) => (
        <ContactCard
          {...contact}
          key={index}
          showEditContactButton={showEditContactButton}
          handleEditContact={handleEditContact}
        />
      ))}
      <div className={classes.actionsContainer}>
        {showAddContactButton && (
          <Button
            className={classes.addActionButton}
            icon='PlusOutlined'
            onClick={handleAddContact}
          >
            {t('Add new contact')}
          </Button>
        )}
      </div>
    </>
  )
}
